import { gql } from '@apollo/client';
import { Box, Dialog } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  CommandHandler,
  CommandHandlerBaseContext,
} from 'contexts/commands/types';
import { GenerateId } from 'utils/generateId';
import { ContentIdeaDetailViewSkeleton } from 'features/contentIdea';
import { useCreateContentIdeaForCreateContentIdeaHandlerMutation } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation CreateContentIdeaForCreateContentIdeaHandler(
    $data: CreateContentIdeaInput!
  ) {
    createContentIdea(data: $data) {
      id
    }
  }
`;

export type CreateContentIdeaHandlerContext = {
  initialValues?: {
    dueDate?: Date;
  };
} & CommandHandlerBaseContext<COMMAND_TYPE.CREATE_CONTENT_IDEA>;

export const CreateContentIdeaHandler: CommandHandler<
  COMMAND_TYPE.CREATE_CONTENT_IDEA
> = (props) => {
  const { commandId, context: { initialValues } = {} } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { backgroundLocation } =
    (location.state as {
      backgroundLocation?: Location;
    }) || {};

  const { getActiveCommandById, updateActiveCommand } = useCommandContext();
  const command = getActiveCommandById(commandId);

  const [createContentIdea] =
    useCreateContentIdeaForCreateContentIdeaHandlerMutation();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (command?.status !== 'completed') {
      setIsLoading(true);

      createContentIdea({
        variables: {
          data: {
            contentIdeaId: GenerateId.create(),
            data: {
              dueDate: initialValues?.dueDate,
            },
          },
        },
      }).then((res) => {
        setIsLoading(false);

        if (res.data?.createContentIdea) {
          updateActiveCommand(commandId, {
            status: 'completed',
          });

          navigate(PlotRoutes.contentIdea(res.data.createContentIdea.id), {
            state: {
              backgroundLocation: backgroundLocation || location,
            },
          });
        }
      });
    }
  }, [command]); // eslint-disable-line -- triger flow on mount

  if (isLoading) {
    return (
      <Dialog
        open
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 850,
            borderRadius: 6,
            border: 'none',
          },
        }}
      >
        <Box p={theme.spacing(9, 12)}>
          <ContentIdeaDetailViewSkeleton />
        </Box>
      </Dialog>
    );
  }

  return null;
};
