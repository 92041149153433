import { Box, Popover, Typography, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    borderRadius: theme.spacing(2),
    boxShadow: `0px 1px 4px rgba(0, 0, 0, 0.16)`,
  },
}));

export const StyledCommentsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  display: 'flex',
  flexDirection: 'column',
  width: '380px',

  '& .comments-header': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.colors?.utility[300]}`,
  },
}));

export const StyledMenuOption = styled(Typography)(({ theme }) => ({
  color: theme.colors?.utility[900],
  '& .option-label': {
    fontWeight: 600,
  },
}));

export const StyledAnnotationAvatar = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.colors?.primary.maroon}`,
  height: 32,
  width: 32,
  backgroundColor: theme.colors?.primary.white,
  borderRadius: '100px 100px 100px 0px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0px 3px 12px -3px rgba(0, 0, 0, 0.16)`,
}));

export const StyledCommentRendererWrapper = styled(Box)(() => ({
  paddingTop: theme.spacing(3),
  '& .comment-renderer': {
    gap: theme.spacing(2),
    '& .comment-input-wrapper': {
      alignItems: 'flex-start !important',
      '& .comment-actions': {
        marginBottom: 5,
      },
      '& .comment-user': {
        marginTop: theme.spacing(2),
      },
    },
  },
}));

export const inputStyle = {
  backgroundColor: theme.colors?.utility[275],
  padding: theme.spacing(1, 2.5),
  borderRadius: theme.spacing(5),
  alignItems: 'flex-start !important',
};

export const editorStyle = {
  ...theme.typography['subhead-xl'],
  maxWidth: 260,
  color: theme.colors?.utility[900],
};

export const StyledCommentInputWrapper = styled(Box)(() => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(3),
  wordBreak: 'break-all',
  p: {
    margin: 0,
  },
  '& .comment-input-wrapper': {
    alignItems: 'flex-start !important',
    '& .comment-actions': {
      marginTop: 5,
      gap: theme.spacing(1),
    },
    '& .comment-user': {
      marginTop: theme.spacing(2),
    },
  },
}));
