import { Masonry } from '@mui/lab';
import { Box, CircularProgress } from '@mui/material';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { MasonryExtensionExternalAttrs } from '../types';
import { IframelyComponent } from './IframelyComponent';

export const MasonryComponent = ({
  node,
  selected,
  updateAttributes,
  getPos,
  editor,
  extension,
}: NodeViewProps) => {
  const components: {
    id: string;
    type: 'iframely' | 'image' | 'video';
    url: string;
    localUrl: string;
    loading: boolean;
    progress: number;
  }[] =
    typeof node.attrs['data-components'] === 'string'
      ? JSON.parse(node.attrs['data-components'] || '[]')
      : node.attrs['data-components'] || [];

  const { themeColor } = (node.attrs.externalAttrs ||
    extension.options.externalAttrs ||
    {}) as MasonryExtensionExternalAttrs;

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(9999);
  const debouncedSetContainerWidth = useMemo(
    () => debounce(setContainerWidth, 300),
    [],
  );

  useEffect(() => {
    if (containerRef?.current) {
      setContainerWidth(containerRef.current.offsetWidth);

      const observer = new ResizeObserver(() => {
        debouncedSetContainerWidth(containerRef.current?.clientWidth || 9999);
      });

      observer.observe(containerRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []); // eslint-disable-line

  const columns = useMemo(() => {
    const breakpoints = theme.breakpoints.values;

    if (containerWidth <= breakpoints.sm) {
      return 1;
    }

    if (containerWidth <= breakpoints.md) {
      return 2;
    }

    if (containerWidth <= breakpoints.xl) {
      return 3;
    }

    return 4;
  }, [containerWidth]);

  return (
    <NodeViewWrapper>
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flex: 1,
          position: 'relative',
        }}
      >
        <Masonry
          columns={columns}
          spacing={4}
          sx={{
            my: 0,
            borderRadius: '12px',
            backgroundColor: selected ? themeColor?.focusBgColor : undefined,
            alignContent: 'center',
            '&:hover': {
              backgroundColor: themeColor?.focusBgColor,
            },
          }}
        >
          {components
            .map((each) => {
              switch (each.type) {
                case 'iframely':
                  return <IframelyComponent id={each.id} url={each.url} />;
                case 'image':
                  return (
                    <img
                      alt=""
                      src={each?.url || each?.localUrl}
                      style={{ borderRadius: '12px', width: '100%' }}
                    />
                  );
                case 'video':
                  return (
                    <video
                      controls
                      style={{ borderRadius: '12px', width: '100%' }}
                      src={each.url || each.localUrl}
                    >
                      <track kind="captions" />
                      <source src={each.url || each.localUrl} />
                    </video>
                  );
                default:
                  return null;
              }
            })
            .map((each, index) => {
              const { id, loading, progress = 0 } = components[index];
              return each ? (
                <Box
                  key={id}
                  position="relative"
                  display="flex"
                  justifyContent="center"
                >
                  <IconButtonWithTooltip
                    onClick={() => {
                      if (components.length > 1) {
                        updateAttributes({
                          'data-components': components.filter(
                            (each) => each.id !== id,
                          ),
                        });
                      } else {
                        const transaction = editor.state.tr.deleteRange(
                          getPos(),
                          getPos() + node.nodeSize,
                        );
                        editor.view.dispatch(transaction);
                      }
                    }}
                    tooltip="Delete"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 1,
                    }}
                  >
                    <IconOutlineTrash size={16} />
                  </IconButtonWithTooltip>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    {each}
                    {loading && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 3,
                          backgroundColor: 'rgb(250 243 236 / 15%)',
                          position: 'absolute',
                          inset: 0,
                        }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={progress}
                          size={80}
                          thickness={6}
                          sx={{
                            boxShadow: `inset 0 0 0 6px rgba(255, 255, 255, 0.3)`,
                            color: theme.colors?.primary.white,
                            borderRadius: '50%',
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : null;
            })}
        </Masonry>
      </Box>
    </NodeViewWrapper>
  );
};
