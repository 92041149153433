import { gql } from '@apollo/client';
import {
  Platform,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragment,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentUseSocialListeningPostLinkGenerator on SocialPostModel {
    id
    platformId
    platform
    expiresAt
    creator {
      id
      handle
    }
  }
`;

export const useSocialListeningPostLinkGenerator = () => {
  const generateLink = (
    post: SocialPostFragmentUseSocialListeningPostLinkGeneratorFragment,
  ) => {
    if (post.platform === Platform.Tiktok) {
      return `https://www.tiktok.com/@${post.creator?.handle}/video/${post.platformId}`;
    }

    const postIsInstagramStory =
      post.platform === Platform.Instagram && post.expiresAt;

    if (postIsInstagramStory) {
      return `https://instagram.com/stories/${post.creator?.handle}/${post.platformId}`;
    }

    if (post.platform === Platform.Instagram) {
      return `https://instagram.com/p/${post.platformId}`;
    }

    return '';
  };

  const generateLinks = (
    posts: SocialPostFragmentUseSocialListeningPostLinkGeneratorFragment[],
  ) => {
    return posts.map(generateLink);
  };

  return { generateLinks, generateLink };
};
