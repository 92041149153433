import { Box, Button, Chip, Typography } from '@mui/material';
import { AdminUserPermissionGate } from 'components/common/PermissionGate';
import { IconBoldArrowDown2 } from 'components/icons/components/bold/IconBoldArrowDown2';
import { IconBoldArrowUp } from 'components/icons/components/bold/IconBoldArrowUp';
import { SUPPORT_EMAIL } from 'constants/support';
import { useUserContext } from 'contexts/users/User.context';
import {
  BillingCollectionLimitProgress,
  BillingFileStorageLimitProgress,
  BillingPlanOptions,
} from 'features/billing';
import { BillingHourUsageProgress } from 'features/billing/components/hourUsage';
import { BillingPlanName, BillingSubscriptionStatus } from 'graphql/generated';
import { usePageAnalytics } from 'hooks/useAnalytics';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme/theme';
import { DateUtils } from 'utils/date';
import { BillingInvoices } from './components/BillingInvoices/BillingInvoices';
import { CardDetails } from './components/CardDetails/CardDetails';

export const Billing = () => {
  usePageAnalytics('OrganizationBilling');

  const { orgBilling } = useUserContext();
  const isMobileView = useMediaQueryMobile();

  const renderPlanName = useMemo(() => {
    switch (orgBilling?.plan) {
      case BillingPlanName.Free:
        return 'Starter Plan';
      case BillingPlanName.Basic:
        return 'Basic Plan';
      case BillingPlanName.Standard:
        return 'Business Plan';
      case BillingPlanName.Enterprise:
        return 'Enterprise Plan';
      default:
        return `${orgBilling?.plan} Plan`;
    }
  }, [orgBilling?.plan]);

  const renderSubscriptionStatus = useMemo(() => {
    if (
      orgBilling?.plan === BillingPlanName.Free ||
      orgBilling?.subscription?.status === BillingSubscriptionStatus.Active
    ) {
      return 'Active';
    }

    if (
      orgBilling?.subscription?.status === BillingSubscriptionStatus.Trialing &&
      orgBilling.subscription?.trialEnd
    ) {
      return `Free trial • ${DateUtils.fromNowInDays(
        (orgBilling?.subscription?.trialEnd || 0) * 1000,
      )} left`;
    }
  }, [
    orgBilling?.plan,
    orgBilling?.subscription?.status,
    orgBilling?.subscription?.trialEnd,
  ]);

  const pricingSectionRef = useRef<HTMLDivElement | null>(null);
  const invoicesSectionRef = useRef<HTMLDivElement | null>(null);
  const [scrollNavigatorText, setScrollNavigatorText] = useState<
    'Pricing' | 'Invoices' | 'Back to Top'
  >('Pricing');

  useEffect(() => {
    const handleScroll = () => {
      const pricingRect = pricingSectionRef.current?.getBoundingClientRect();
      const invoicesRect = invoicesSectionRef.current?.getBoundingClientRect();
      const windowScrollTop = document.documentElement.scrollTop;

      if (
        windowScrollTop >= 0 &&
        pricingRect?.top &&
        windowScrollTop < pricingRect?.top
      ) {
        setScrollNavigatorText('Pricing');
      } else if (
        pricingRect?.top !== undefined &&
        windowScrollTop >= pricingRect?.top &&
        invoicesRect?.top !== undefined &&
        windowScrollTop <= invoicesRect?.top
      ) {
        setScrollNavigatorText('Invoices');
      } else {
        setScrollNavigatorText('Back to Top');
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener('scroll', debouncedHandleScroll);
    handleScroll(); // Check on initial render

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollNavigatorText]);

  const scrollToSection = () => {
    if (scrollNavigatorText === 'Invoices') {
      invoicesSectionRef.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    } else if (scrollNavigatorText === 'Back to Top') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      pricingSectionRef.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  };

  return !orgBilling ? null : (
    <Box
      display="flex"
      gap={theme.spacing(isMobileView ? 6 : 15)}
      flexDirection="column"
    >
      <Box display="flex" gap={theme.spacing(4)} flexDirection="column">
        <Typography variant="headline-md">Billing</Typography>

        <Box display="flex" gap={theme.spacing(4)} alignItems="center">
          <Typography variant="headline-lg" fontSize={28}>
            {renderPlanName}
          </Typography>
          <Chip
            label={renderSubscriptionStatus}
            size="small"
            sx={{
              bgcolor: theme.colors?.utility['teal-1'],
              color: theme.colors?.utility['teal-4'],
              fontWeight: 600,
            }}
          />
        </Box>
      </Box>

      <Box display="flex" gap={theme.spacing(4)} flexWrap="wrap">
        <BillingHourUsageProgress
          sx={{
            border: `1px solid ${theme.colors?.utility[400]}`,
            p: 4,
            width: theme.spacing(55),
            borderRadius: theme.spacing(2),
          }}
        />
        <BillingFileStorageLimitProgress
          organizationBilling={orgBilling}
          filledColor={theme.colors?.primary.black}
          sx={{
            border: `1px solid ${theme.colors?.utility[400]}`,
            p: 4,
            width: theme.spacing(55),
            borderRadius: theme.spacing(2),
          }}
        />
        <BillingCollectionLimitProgress
          organizationBilling={orgBilling}
          filledColor={theme.colors?.primary.black}
          sx={{
            border: `1px solid ${theme.colors?.utility[400]}`,
            p: 4,
            width: theme.spacing(55),
            borderRadius: theme.spacing(2),
          }}
        />

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            border: `1px solid ${theme.colors?.utility[400]}`,
            p: 4,
            borderRadius: theme.spacing(2),
          }}
        >
          <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
            Members
          </Typography>
          <Typography variant="headline-md">
            {orgBilling.organizationMemberUsage} members
          </Typography>

          <Link to={PlotRoutes.members()}>
            <Typography
              variant="headline-xs"
              sx={{ textDecoration: 'underline' }}
            >
              Manage
            </Typography>
          </Link>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            border: `1px solid ${theme.colors?.utility[400]}`,
            p: 4,
            borderRadius: theme.spacing(2),
          }}
        >
          <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
            Guests
          </Typography>
          <Typography variant="headline-md">
            {orgBilling.guestUsage} guests
          </Typography>

          <Link to={PlotRoutes.members()}>
            <Typography
              variant="headline-xs"
              sx={{ textDecoration: 'underline' }}
            >
              Manage
            </Typography>
          </Link>
        </Box>

        {orgBilling?.subscription?.upcomingInvoice?.periodEnd && (
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              border: `1px solid ${theme.colors?.utility[400]}`,
              p: 4,
              borderRadius: theme.spacing(2),
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              Next Invoice Due
            </Typography>
            <Typography variant="headline-md">
              {moment(
                (orgBilling?.subscription?.upcomingInvoice?.periodEnd || 0) *
                  1000,
              ).format('DD MMM, YYYY')}
            </Typography>
          </Box>
        )}

        {orgBilling?.subscription && (
          <AdminUserPermissionGate>
            <CardDetails />
          </AdminUserPermissionGate>
        )}
      </Box>

      <BillingPlanOptions ref={pricingSectionRef} title="Pricing" />

      <AdminUserPermissionGate>
        <Box ref={invoicesSectionRef}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant={isMobileView ? 'headline-md' : 'headline-lg'}>
              Invoices
            </Typography>
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[600]}
            >
              Something's not right?{' '}
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: theme.colors?.utility[1000],
                  textDecoration: 'underline',
                }}
              >
                Contact Us
              </a>
            </Typography>
          </Box>
          <BillingInvoices />
        </Box>
      </AdminUserPermissionGate>

      {isMobileView && (
        <Box
          sx={{
            position: 'fixed',
            bottom: theme.spacing(20),
            right: theme.spacing(4),
            zIndex: 1000,
          }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: theme.colors?.primary.black,
              borderRadius: theme.spacing(8),
              ':hover': {
                bgcolor: theme.colors?.primary.black,
              },
            }}
            endIcon={
              scrollNavigatorText !== 'Back to Top' ? (
                <IconBoldArrowDown2 />
              ) : (
                <IconBoldArrowUp />
              )
            }
            onClick={scrollToSection}
          >
            {scrollNavigatorText}
          </Button>
        </Box>
      )}
    </Box>
  );
};
