import { gql } from '@apollo/client';
import { Avatar, Chip, SxProps } from '@mui/material';
import { UserProfileFragmentUserProfileChipViewFragment } from 'graphql/generated';
import { getFullName } from 'utils/users';
import { stringToColor } from 'components/common/AvatarGroup/utils/avatar';

export const USER_PROFILE_FRAGMENT_USER_PROFILE_CHIP_VIEW = gql`
  fragment UserProfileFragmentUserProfileChipView on UserProfileModel {
    id
    firstName
    lastName
    avatarUrl
    email
  }
`;

type UserProfileChipViewProps = {
  userProfile: UserProfileFragmentUserProfileChipViewFragment;
  sx?: SxProps;
};

export const UserProfileChipView = (props: UserProfileChipViewProps) => {
  const { userProfile, sx } = props;

  const fullName = getFullName(userProfile);
  const representativeChar =
    userProfile.firstName === ''
      ? userProfile.email.charAt(0)
      : userProfile.firstName.charAt(0);
  const label = fullName === '' ? userProfile.email : fullName;

  return (
    <Chip
      sx={sx}
      avatar={
        <Avatar
          src={userProfile.avatarUrl ?? ''}
          sx={{
            backgroundColor: stringToColor(fullName || userProfile.email),
          }}
        >
          {representativeChar}
        </Avatar>
      }
      label={label}
    />
  );
};
