import { gql } from '@apollo/client';
import { toast } from 'components/common/Toast';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  DuplicatePostInput,
  PostFragmentPostMasonryCardViewFragmentDoc,
  PostType,
  RemovePostFromCollectionInput,
  useDeletePostForPostContextMenuMutation,
  useDuplicatePostForPostContextMenuMutation,
  useRemovePostFromCollectionForPostContextMenuMutation,
} from 'graphql/generated';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation RemovePostFromCollectionForPostContextMenu(
    $data: RemovePostFromCollectionInput!
  ) {
    removePostFromCollection(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeletePostForPostContextMenu($id: String!) {
    deletePost(id: $id) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DuplicatePostForPostContextMenu($data: DuplicatePostInput!) {
    duplicatePost(data: $data) {
      id
      collections {
        id
      }
      ...PostFragmentPostMasonryCardView
    }
  }
  ${PostFragmentPostMasonryCardViewFragmentDoc}
`;

export const usePostHandlers = () => {
  const { broadcastByCommandType } = useCommandContext();

  const [removePostFromCollection] =
    useRemovePostFromCollectionForPostContextMenuMutation();
  const [deletePost] = useDeletePostForPostContextMenuMutation();
  const [duplicatePost] = useDuplicatePostForPostContextMenuMutation();

  const onDeletePost = async (id: string) => {
    await deletePost({
      variables: {
        id,
      },
      update: (cache) => {
        evictObject(cache, id, 'PostModel');
      },
    });
  };

  const onRemovePostFromCollection = async (
    data: RemovePostFromCollectionInput,
  ) => {
    await removePostFromCollection({
      variables: {
        data,
      },
      update: (cache) => {
        evictObject(cache, data.postId, 'PostModel');
      },
    });
  };

  const onDuplicatePost = async (data: DuplicatePostInput) => {
    const response = await duplicatePost({
      variables: {
        data,
      },
    });
    const post = response.data?.duplicatePost;

    if (post) {
      let commandType = COMMAND_TYPE.POST_PASTE_A_LINK;
      if (post.type === PostType.Attachment) {
        commandType = COMMAND_TYPE.POST_UPLOAD_FILES;
      } else if (post.type === PostType.Note) {
        commandType = COMMAND_TYPE.POST_WRITE_A_NOTE;
      }

      broadcastByCommandType(commandType, [post]);
    }

    toast({
      type: 'success',
      message: 'Post duplicated',
    });
  };

  return {
    onDeletePost,
    onDuplicatePost,
    onRemovePostFromCollection,
  };
};
