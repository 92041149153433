import { Box, Dialog } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CommandHandler } from 'contexts/commands/types';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import { NoteRichTextEditorSkeleton, getRandomNoteColor } from 'features/note';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Parser } from 'utils/parser';
import { usePostHandlers } from '../hooks/usePostHandlers';

export type PostWriteANoteHandlerContext = {
  taskId?: string;
  collectionId?: string;
};

export const PostWriteANoteHandler: CommandHandler<
  COMMAND_TYPE.POST_WRITE_A_NOTE
> = (props) => {
  const { commandId, context } = props;

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const { backgroundLocation } =
    (location.state as { backgroundLocation: Location }) || {};

  const { getActiveCommandById, updateActiveCommand, broadcastByCommandType } =
    useCommandContext();
  const command = getActiveCommandById(commandId);

  const { collectionId: collectionIdFromParams } = useCollectionIdFromParams();
  const collectionId = context?.collectionId ?? collectionIdFromParams;

  const { onCreateNotePost } = usePostHandlers();

  const [isLoading, setIsLoading] = useState(false);

  const initHexColor = params.get('color');
  const randomColor = getRandomNoteColor().color;
  const noteColor = initHexColor ? `#${initHexColor}` : randomColor || '';

  useEffect(() => {
    if (command?.status !== 'completed') {
      setIsLoading(true);

      onCreateNotePost({
        collectionId,
        taskId: context?.taskId,
        title: 'Untitled',
        color: noteColor,
      }).then((post) => {
        setIsLoading(false);
        if (post) {
          updateActiveCommand(commandId, {
            status: 'completed',
          });
          navigate(PlotRoutes.juiceboxNote({ id: post.id }), {
            state: {
              backgroundLocation: backgroundLocation || location,
              collectionId:
                props.context?.collectionId ??
                Parser.getCollectionIdFromUrl(location.pathname),
            },
          });

          broadcastByCommandType(COMMAND_TYPE.POST_WRITE_A_NOTE, [post]);
        }
      });
    }
  }, [command]); // eslint-disable-line -- trigger flow on mount

  if (isLoading) {
    return (
      <Dialog
        open
        PaperProps={{
          sx: {
            width: '100vw',
            maxWidth: '100vw',
            height: '100vh',
            maxHeight: '100vh',
            border: 'none',
            display: 'flex',
            p: 0,
            m: 0,
          },
        }}
      >
        <Box
          sx={{
            m: 'auto',
          }}
        >
          <NoteRichTextEditorSkeleton />
        </Box>
      </Dialog>
    );
  }

  return null;
};
