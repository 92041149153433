import { useMemo } from 'react';
import { useZoomManager } from '../contexts/ZoomManager.context';

export const useNewCoordinates = ({
  x,
  y,
  customScale,
}: {
  x: number | null | undefined;
  y: number | null | undefined;
  customScale?: number;
}) => {
  const { scale: globalScale } = useZoomManager();

  const scale = customScale || globalScale;

  const { newX, newY } = useMemo(() => {
    if (x && y && scale) {
      const newX = x * scale;
      const newY = y * scale;

      return { newX, newY };
    }

    return { newX: 0, newY: 0 };
  }, [scale, x, y]);

  return { newX, newY };
};
