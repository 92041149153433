import { gql } from '@apollo/client';
import {
  CommentFragmentCommentContainerViewFragment,
  CommentFragmentCommentContentViewFragmentDoc,
  CommentInputData,
} from 'graphql/generated';

export const COMMENT_FRAGMENT_COMMENT_CONTAINER_VIEW = gql`
  fragment CommentFragmentCommentContainerView on CommentModel {
    id
    isRead
    parentThreadId
    postId
    taskId
    collectionId
    childComments {
      id
      postId
      taskId
      isRead
      parentThreadId
      ...CommentFragmentCommentContentView
    }
    ...CommentFragmentCommentContentView
  }
  ${CommentFragmentCommentContentViewFragmentDoc}
`;

export interface CommentContainerProps {
  canComment?: boolean;
  comment: CommentFragmentCommentContainerViewFragment;
  onReact: (commentId: string, emoji: string) => void;
  onReply: (comment: CommentInputData) => void;
}
