import { gql } from '@apollo/client';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  PostFilterType,
  PostFiltersForSmartSearch,
  PostFragmentPostCommandFragment,
  PostFragmentPostMasonryCardViewFragmentDoc,
  QueryPostsArgs,
  SearchType,
  useGetPostsForJuiceboxQuery,
  useGetSmartSearchPostsForJuiceboxQuery,
} from 'graphql/generated';
import { useUpdateQueryByCommand } from 'hooks/commands/useUpdateQueryByCommand';

// eslint-disable-next-line
gql`
  query GetPostsForJuicebox(
    $filters: PostFilters!
    $take: Int
    $after: String
  ) {
    posts(filters: $filters, take: $take, after: $after) {
      data {
        id
        ...PostFragmentPostMasonryCardView
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PostFragmentPostMasonryCardViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetSmartSearchPostsForJuicebox(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
  ) {
    postsSmartSearch(filters: $filters, take: $take, after: $after) {
      data {
        score
        type
        item {
          id
          ...PostFragmentPostMasonryCardView
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PostFragmentPostMasonryCardViewFragmentDoc}
`;

export const usePostQuery = (props: {
  args: QueryPostsArgs;
  smartSearchArgs?: PostFiltersForSmartSearch;
  searchType?: SearchType;
}) => {
  const { args, smartSearchArgs, searchType = SearchType.FullText } = props;

  const {
    data,
    updateQuery,
    fetchMore,
    loading: fetchingPosts,
    refetch,
  } = useGetPostsForJuiceboxQuery({
    variables: {
      ...args,
    },
    skip: searchType === SearchType.Hybrid,
    fetchPolicy: 'cache-and-network',
  });

  const fetchMorePosts = async () => {
    fetchMore({
      variables: {
        ...args,
        after: data?.posts.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          posts: {
            ...fetchMoreResult.posts,
            data: [
              ...(prev.posts.data || []),
              ...(fetchMoreResult.posts.data || []),
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.id === e.id),
            ),
          },
        };
      },
    });
  };

  const {
    data: smartSearchPosts,
    fetchMore: fetchMoreSmartSearch,
    loading: fetchingSmartSearchPosts,
  } = useGetSmartSearchPostsForJuiceboxQuery({
    variables: {
      filters: {
        query: smartSearchArgs?.query || '',
        filterType: args.filters.filterType || PostFilterType.OrganizationPosts,
      },
    },
    skip: searchType !== SearchType.Hybrid,
    fetchPolicy: 'cache-and-network',
  });

  const fetchMoreAiResults = async () => {
    if (fetchingSmartSearchPosts) {
      return;
    }

    fetchMoreSmartSearch({
      variables: {
        query: smartSearchArgs?.query || '',
        filterType: args.filters.filterType || PostFilterType.OrganizationPosts,
        after: String(smartSearchPosts?.postsSmartSearch.data.length),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          postsSmartSearch: {
            ...fetchMoreResult.postsSmartSearch,
            data: [
              ...(prev.postsSmartSearch.data || []),
              ...(fetchMoreResult.postsSmartSearch.data || []),
            ],
          },
        };
      },
    });
  };

  // Insert newly created posts from command flow into current query
  const updateQueryWithNewPost = (posts: PostFragmentPostCommandFragment[]) => {
    updateQuery((data) => {
      return {
        posts: {
          ...data?.posts,
          data: [...posts, ...(data?.posts?.data || [])],
        },
      };
    });
  };

  useUpdateQueryByCommand(
    COMMAND_TYPE.POST_WRITE_A_NOTE,
    updateQueryWithNewPost,
  );
  useUpdateQueryByCommand(
    COMMAND_TYPE.POST_PASTE_A_LINK,
    updateQueryWithNewPost,
  );
  useUpdateQueryByCommand(
    COMMAND_TYPE.POST_UPLOAD_FILES,
    updateQueryWithNewPost,
  );

  return {
    data,
    fetchMore: fetchMorePosts,
    smartSearchPosts,
    fetchMoreAiResults,
    loading: fetchingPosts,
    refetch,
  };
};
