import { gql, useApolloClient } from '@apollo/client';
import { useDeletePostAnnotationForCommentContainerViewMutation } from 'graphql/generated';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeletePostAnnotationForCommentContainerView(
    $data: DeletePostAnnotationInput!
  ) {
    deletePostAnnotation(data: $data) {
      message
      success
    }
  }
`;

export const usePostAnnotationHandlers = () => {
  const client = useApolloClient();

  const [deletePostAnnotation] =
    useDeletePostAnnotationForCommentContainerViewMutation();

  const onDeletePostAnnotation = (postAnnotationId: string) => {
    deletePostAnnotation({
      variables: {
        data: {
          postAnnotationId,
        },
      },
    });
    evictObject(client.cache, postAnnotationId, 'PostAnnotationModel');
  };

  return {
    onDeletePostAnnotation,
  };
};
