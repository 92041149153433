import { gql } from '@apollo/client';

export const URL_METADATA_FRAGMENT_IFRAMELY = gql`
  fragment URLMetadataFragmentIframely on URLMetadataModel {
    id
    url
    metadata {
      site
      medium
      type
      author
      title
      thumbnail {
        type
        href
        mediaSize {
          width
          height
        }
      }
      medias {
        type
        url
      }
    }
  }
`;
