import { Box } from '@mui/material';
import { PostPreview } from 'features/post/components';
import { PostCardViewProps } from './types';

export const PostCardView = (props: PostCardViewProps) => {
  const { post, sx, componentsProps = {}, onClick } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        ...sx,
      }}
    >
      <Box sx={{ position: 'relative' }} onClick={onClick}>
        <PostPreview
          post={post}
          sx={{ height: 100, borderRadius: 2 }}
          {...componentsProps.preview}
        />
      </Box>
    </Box>
  );
};
