import { Box, styled } from '@mui/material';

export const StyledToolBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.colors?.utility[300]}`,
  gap: theme.spacing(2.5),
}));
