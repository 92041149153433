import {
  Badge,
  Box,
  Button,
  Chip,
  Typography,
  chipClasses,
  styled,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { usePendingActions } from 'contexts/users/PendingActions';
import { useUserContext } from 'contexts/users/User.context';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { useCustomHeaderContext } from '../CustomHeader/contexts/CustomHeader.context';
import { OrganizationLayoutNameSection } from './OrganizationLayoutNameSection';
import { BillingMobileView } from './components/BillingMobileView';
import { OrganizationTabKey, generateTabsData } from './constants';

const StyledActionButton = styled(Button)<{
  selected: boolean;
  mobileView: boolean;
}>(({ selected, mobileView }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 12,
  padding: mobileView ? theme.spacing(1) : '11px 22px',
  marginBottom: mobileView ? 0 : 16,
  whiteSpace: 'nowrap',
  opacity: mobileView ? (selected ? 1 : 0.4) : 1,
  borderRadius: mobileView ? 0 : undefined,
  background:
    !mobileView && selected ? theme.colors?.utility[275] : 'transparent',
  borderBottom: mobileView
    ? selected
      ? `1px solid ${theme.colors?.utility[1000]}`
      : 'none'
    : 'none',
  '& svg': {
    color:
      !mobileView && selected
        ? theme.colors?.utility[1000]
        : theme.colors?.utility[900],
    height: 16,
    width: 16,
  },
  ':hover': {
    background: mobileView ? 'transparent' : theme.colors?.utility[275],
  },
}));

export const OrganizationLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgBilling } = useUserContext();
  const { user, isMobileAppWebView, isAlertBannerVisible } = useUserContext();
  const isMobileView = useMediaQueryMobile();

  const { setHeaderSx } = useCustomHeaderContext();
  const locationState = location.state as { backgroundLocation: Location };

  const [tab, setTab] = useState(OrganizationTabKey.MEMBERS);

  const { areTherePendingUserRequests } = usePendingActions();

  const pendingNotifications = useCallback(
    (tabName: string) => {
      if (tabName === 'Members') {
        return areTherePendingUserRequests;
      }
      return false;
    },
    [areTherePendingUserRequests],
  );

  useEffect(() => {
    setHeaderSx({
      backgroundColor: theme.colors?.primary.white,
    });

    return () => {
      setHeaderSx({});
    };
  }, [location.pathname]); // eslint-disable-line

  const { isFeatureEnabled } = useFeatureFlagContext();
  const finalTabsData = useMemo(() => {
    return generateTabsData({
      hideSocialListening:
        !orgBilling?.socialListeningEnabled &&
        !user?.socialListeningBrands.length,
    });
  }, [user, isFeatureEnabled]); // eslint-disable-line

  useEffect(() => {
    // Get the subpath from the location pathname
    const subpath = (
      locationState?.backgroundLocation
        ? locationState.backgroundLocation
        : location
    ).pathname
      .split('/')
      .pop();

    if (subpath && finalTabsData.find((t) => t.path === subpath)) {
      setTab(subpath as OrganizationTabKey);
    } else {
      navigate(PlotRoutes.account(), {
        replace: true,
      });
    }
  }, [location, finalTabsData]); // eslint-disable-line

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: isMobileAppWebView || isMobileView ? undefined : 1024,
      }}
    >
      <Box
        display="flex"
        marginTop={isMobileView ? 0 : theme.spacing(20)}
        flexDirection="column"
        gap={12}
      >
        {isMobileAppWebView ? (
          <BillingMobileView />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            gap={isMobileView ? 6 : 8}
            sx={{
              marginTop: isMobileView
                ? !isAlertBannerVisible
                  ? theme.spacing(15)
                  : 0
                : 0,
              padding: isMobileView
                ? theme.spacing(6, 4)
                : theme.spacing(10, 8),
            }}
          >
            <OrganizationLayoutNameSection />
            <Box
              sx={{
                boxShadow: isMobileView
                  ? 'none'
                  : `0px 1px 4px rgba(0, 0, 0, 0.16)`,
                borderRadius: isMobileView ? 0 : 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  gap: isMobileView ? theme.spacing(6) : 0,
                  flexDirection: isMobileView ? 'column' : 'row',
                }}
              >
                {/* Sidebar */}
                <Box
                  sx={
                    isMobileView
                      ? {
                          display: 'flex',
                          gap: theme.spacing(3),
                          overflow: 'auto',
                          flexWrap: 'nowrap',
                        }
                      : {
                          maxWidth: theme.spacing(69),
                          width: 'auto',
                          p: 3,
                          py: 8,
                          borderRight: `1px solid ${theme.colors?.utility[300]}`,
                        }
                  }
                >
                  {finalTabsData.map((tabData) => {
                    const isActive = tab === tabData.key;

                    return (
                      <Link
                        key={tabData.key}
                        to={`/org/settings/${tabData.path}`}
                      >
                        <StyledActionButton
                          mobileView={isMobileView}
                          selected={isActive}
                          fullWidth
                          onClick={() => {
                            setTab(tabData.key);
                          }}
                        >
                          <Badge
                            color="primary"
                            variant="dot"
                            badgeContent={
                              pendingNotifications(tabData.name) ? 1 : 0
                            }
                          >
                            {tabData.icon}
                          </Badge>

                          <Typography
                            variant="subhead-lg"
                            color={
                              isActive
                                ? theme.colors?.utility[1000]
                                : theme.colors?.utility[900]
                            }
                            fontWeight={isActive ? 600 : 500}
                          >
                            {tabData.name}
                          </Typography>

                          {tabData.name === 'Members' &&
                            areTherePendingUserRequests && (
                              <Chip
                                sx={{
                                  bgcolor: theme.colors?.utility['yellow-2'],
                                  color: theme.colors?.primary.white,
                                  fontSize: theme.spacing(2.5),
                                  padding: 0,
                                  height: theme.spacing(5.75),
                                  border: 'none',
                                  [`& .${chipClasses.label}`]: {
                                    paddingLeft: theme.spacing(2.25),
                                    paddingRight: theme.spacing(2.25),
                                  },
                                }}
                                label="Approval Pending"
                              />
                            )}
                        </StyledActionButton>
                      </Link>
                    );
                  })}
                </Box>
                {/* Content renderer */}
                <Box
                  sx={{
                    padding: isMobileView ? 0 : theme.spacing(5, 8),
                    width: '100%',
                    flexGrow: 1,
                  }}
                >
                  <Outlet />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
