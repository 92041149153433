import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { SocialPostFragmentPostVideoDialogVideoHighlightsFragment } from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentPostVideoDialogVideoHighlights on SocialPostModel {
    id
    __typename
    socialPostEngagementSuggestions(brandId: $brandId) {
      id
      oneLineSummary
      highlights {
        end
        start
        highlightSummary
      }
    }
  }
`;

type Props = {
  post: SocialPostFragmentPostVideoDialogVideoHighlightsFragment;
  onHighlightClicked?: (timeInSeconds: number) => void;
};

export const VideoHighlights = ({ post, onHighlightClicked }: Props) => {
  const highlights = post.socialPostEngagementSuggestions?.[0]?.highlights;
  const [selectedHighlight, setSelectedHighlight] = useState(-1);

  if (!highlights || highlights.length === 0) {
    return null;
  }

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Typography
        variant="body-lg"
        color={theme.colors?.utility[600]}
        fontWeight={600}
      >
        Highlights
      </Typography>

      <Box display="flex" gap={2} flexDirection="column">
        {post.socialPostEngagementSuggestions?.[0]?.highlights.map(
          (highlight, index) => (
            <Box
              display="flex"
              gap={3}
              sx={{
                cursor: 'pointer',
                borderRadius: theme.spacing(3),
                p: 3,
                backgroundColor:
                  index === selectedHighlight
                    ? 'rgba(248, 232, 202, 0.50)'
                    : 'transparent',
              }}
              // converting milliseconds to seconds
              onClick={() => {
                if (onHighlightClicked) {
                  setSelectedHighlight(index);
                  onHighlightClicked?.(highlight.start / 1000);
                }
              }}
            >
              <Typography
                minWidth={theme.spacing(10)}
                variant="body-xl"
                color={theme.colors?.utility['blue-3']}
                fontWeight={500}
              >
                {new Date(highlight.start).toISOString().slice(14, 19)}
              </Typography>
              <Typography
                variant="body-xl"
                color={theme.colors?.utility[800]}
                fontWeight={500}
              >
                {highlight.highlightSummary}
              </Typography>
            </Box>
          ),
        )}
      </Box>
    </Box>
  );
};
