import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import * as Y from 'yjs';
import { UserInfo } from 'features/collaboration';
import { Extensions } from '@tiptap/react';

export const generateCollaborationExtensions = (
  yDoc?: Y.Doc,
  provider?: any,
  userInfo?: UserInfo,
): Extensions => {
  return [
    Collaboration.configure({
      document: yDoc,
    }),
    CollaborationCursor.configure({
      provider,
      user: {
        name: `${userInfo?.firstName} ${userInfo?.lastName}`,
        color: userInfo?.color,
      },
      selectionRender(user: Record<string, any>) {
        /*
         * There is bug in CollaborationCursor extension. It does not render the selected
         * text correctly from other users. This is a temporary fix. There is an opened PR
         * that fixes this issue in the library:
         * https://github.com/yjs/y-prosemirror/pull/148
         */
        return {
          style: `background-color: color-mix(in srgb, ${user.color}, transparent 70%)`,
          class: 'ProseMirror-yjs-selection',
        };
      },
    }),
  ];
};
