/**
 * Format number to fixed decimal places
 */
export const formatNumber = (number: number, fix = 2) => {
  if (Number.isNaN(number)) {
    return String(number);
  }

  return Number(number.toFixed(fix));
};

/**
 * Format number to compact format with K, M, B, ...
 */
export const formatBigNumber = (number: number) => {
  if (Number.isNaN(number)) {
    return String(number);
  }

  if (number < 1000) {
    return String(formatNumber(number, 2));
  }

  const units = ['K', 'M', 'B', 'T'];
  const unit = Math.floor((number.toFixed(0).length - 1) / 3);
  const num = formatNumber(number / 1000 ** unit, 2);

  return num + units[unit - 1];
};

/**
 * Given the list size it will generate unique integers until the max size is reached
 * @param numberListSize - list of unique numbers to generate
 * @returns
 */
const generateUniqueRandomIntegers = (numberListSize: number) => {
  const randomIntegers = new Set<number>();

  while (randomIntegers.size < numberListSize) {
    const randomInteger = Math.floor(Math.random() * numberListSize);
    randomIntegers.add(randomInteger);
  }

  return Array.from(randomIntegers);
};

/**
 * Calculate a list of minimum repeating integers
 * Receive a size of how many unique integers to generate and
 * the size of the list that will contain those unique sets
 *
 * @param {Number} uniqueSetSize - Number of possible unique integer values
 * @param {Number} listActualSize - Size of a the list with mininum repeating values
 * @returns {Array} - list of integers with minimal repetition
 */
export const generateMinimumRepeatingSetOfIntegers = (
  uniqueSetSize: number,
  listActualSize: number,
) => {
  const minimumRepeatingNumbers: number[] = [];

  while (minimumRepeatingNumbers.length < listActualSize) {
    const uniqueRandomIntegers = generateUniqueRandomIntegers(uniqueSetSize);
    minimumRepeatingNumbers.push(...uniqueRandomIntegers);
  }

  return minimumRepeatingNumbers;
};
