import { useClipboard } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { toast } from 'components/common/Toast';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconCustomLightning } from 'components/icons/components/custom/IconCustomLightning';
import { IconCustomMobile } from 'components/icons/components/custom/IconCustomMobile';
import { IconLinearMusic } from 'components/icons/components/linear/IconLinearMusic';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineLogout1 } from 'components/icons/components/outline/IconOutlineLogout1';
import { IconOutlineMessage3 } from 'components/icons/components/outline/IconOutlineMessage3';
import { IconOutlineSetting2 } from 'components/icons/components/outline/IconOutlineSetting2';
import { IconOutlineTask } from 'components/icons/components/outline/IconOutlineTask';
import { SUPPORT_EMAIL } from 'constants/support';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { useAccessRequestHandlers } from 'features/accessRequest';
import { BillingTrendsPaywallIndicator } from 'features/billing';
import {
  BillingPlanName,
  InternalOrganizationRole,
  PlotFeature,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  DownloadMobileAppCTA,
  SocialListeningSidebarLink,
} from '../components';
import { DrawerTab, TabName } from '../types';

export const useDrawerTabs = () => {
  const { onCopy } = useClipboard(SUPPORT_EMAIL);
  const { user, orgBilling } = useUserContext();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const { isLoggingOut, logout } = useUserContext();
  const isSocialListeningUser =
    user?.role === InternalOrganizationRole.SocialListeningUser;

  const [isPendingRequest, setIsPendingRequest] = useState(false);
  const { onCheckIsAccessRequestExist, onCreateOrganizationAccessRequest } =
    useAccessRequestHandlers();

  useEffect(() => {
    if (isSocialListeningUser) {
      onCheckIsAccessRequestExist().then((result) => {
        if (result) {
          setIsPendingRequest(true);
        }
      });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const regularDrawerTopTabs: DrawerTab[] = useMemo(
    () => [
      {
        name: TabName.CREATIVE_JUICEBOX,
        type: 'link',
        icon: IconCustomCreativeJuiceBox,
        href: '/juicebox',
      },
      ...(isFeatureEnabled(PlotFeature.ContentCalendar)
        ? [
            {
              name: TabName.CONTENT_CALENDAR,
              type: 'link' as 'link',
              icon: IconOutlineCalendar,
              href: PlotRoutes.contentCalendar(),
              beta: orgBilling?.plan !== BillingPlanName.Enterprise ?? true,
            },
          ]
        : []),
      {
        name: TabName.ALL_TASKS,
        type: 'link',
        icon: IconOutlineTask,
        href: '/tasks',
        shortCut: 'T',
        tooltip: "Press 'T' to create a new Task",
      },
      {
        name: TabName.TRENDS,
        type: 'link',
        icon: IconCustomLightning,
        href: '/juicebox/trending',
        paywall:
          !orgBilling || orgBilling.trendsEnabled ? null : (
            <BillingTrendsPaywallIndicator organizationBilling={orgBilling} />
          ),
      },
      ...(orgBilling?.socialListeningEnabled ||
      user?.socialListeningBrands.length
        ? [
            {
              name: TabName.SOCIAL_LISTENING,
              type: 'component' as 'component',
              renderComponent: () => {
                return <SocialListeningSidebarLink />;
              },
            },
          ]
        : []),
      ...(isFeatureEnabled(PlotFeature.AudioTrendGroup)
        ? [
            {
              name: TabName.TOP_AUDIOS,
              type: 'link' as 'link',
              icon: IconLinearMusic,
              href: PlotRoutes.trendingAudio(),
              beta: true,
            },
          ]
        : []),
    ],
    [isFeatureEnabled, orgBilling],
  );

  const socialListeningDrawerTopTabs: DrawerTab[] = [
    {
      name: TabName.SOCIAL_LISTENING,
      type: 'component',
      renderComponent: () => {
        return <SocialListeningSidebarLink />;
      },
    },
    {
      name: TabName.CUSTOM_UI,
      renderComponent: () => {
        return (
          <Box
            sx={{ borderBottom: `1px solid ${theme.colors?.utility[500]}` }}
          />
        );
      },
      type: 'component',
    },
    {
      name: TabName.CREATIVE_JUICEBOX,
      type: 'link',
      icon: IconCustomCreativeJuiceBox,
      href: '/juicebox',
    },
    ...(isFeatureEnabled(PlotFeature.ContentCalendar)
      ? [
          {
            name: TabName.CONTENT_CALENDAR,
            type: 'link' as 'link',
            icon: IconOutlineCalendar,
            href: PlotRoutes.contentCalendar(),
          },
        ]
      : []),
    {
      name: TabName.ALL_TASKS,
      type: 'link',
      icon: IconOutlineTask,
      href: '/tasks',
      tooltip: "Press 'T' to create a new Task",
    },
    {
      name: TabName.TRENDS,
      type: 'link',
      icon: IconCustomLightning,
      href: '/juicebox/trending',
      paywall:
        !orgBilling || orgBilling.trendsEnabled ? null : (
          <BillingTrendsPaywallIndicator organizationBilling={orgBilling} />
        ),
    },
    {
      name: TabName.CUSTOM_UI,
      type: 'component',
      renderComponent: () => {
        if (!isSocialListeningUser) {
          return null;
        }

        return (
          <Box
            sx={{ backgroundColor: theme.colors?.utility[300], p: 2 }}
            color={theme.colors?.utility[700]}
          >
            {isPendingRequest ? (
              <Typography variant="body-md">
                <Box component="span" color={theme.colors?.utility[900]}>
                  Request Sent to Admin
                </Box>{' '}
                for access to all Plot features.
              </Typography>
            ) : (
              <Typography variant="body-md">
                <Box
                  onClick={() => {
                    onCreateOrganizationAccessRequest({});
                    setIsPendingRequest(true);
                  }}
                  color={theme.colors?.utility[900]}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  component="span"
                >
                  Request Admin
                </Box>{' '}
                for access to all Plot features.
              </Typography>
            )}
          </Box>
        );
      },
    },
  ];

  const bottomTabs: DrawerTab[] = [
    {
      name: TabName.REGISTER_TO_TEXT,
      type: 'link',
      icon: IconCustomMobile,
      href: PlotRoutes.registerText(),
      state: {
        variant: 'setting',
      },
      disabled: isSocialListeningUser,
    },
    {
      name: TabName.DOWNLOAD_THE_MOBILE_APP,
      type: 'component',
      renderComponent: () => {
        return <DownloadMobileAppCTA />;
      },
      disabled: isSocialListeningUser,
    },
    {
      name: TabName.SETTINGS,
      type: 'link',
      icon: IconOutlineSetting2,
      href: '/org/settings',
    },
    {
      name: TabName.SUPPORT,
      type: 'button',
      icon: IconOutlineMessage3,
      onClick: () => {
        onCopy();
        toast({
          type: 'success',
          message: 'Support email has been copied!',
        });

        window.open(`mailto:${SUPPORT_EMAIL}`, '_blank');
      },
    },
    {
      name: TabName.LOG_OUT,
      icon: IconOutlineLogout1,
      type: 'button',
      color: theme.colors?.utility['pink-3'],
      onClick: logout,
      disabled: isLoggingOut,
      loading: isLoggingOut,
    },
  ];

  return {
    topTabs: isSocialListeningUser
      ? socialListeningDrawerTopTabs
      : regularDrawerTopTabs,
    bottomTabs,
  };
};
