import { Checkbox, CheckboxProps } from '@mui/material';

export type CheckboxFieldProps = Omit<CheckboxProps, 'onChange'> & {
  onChange: (checked: boolean) => void;
  readOnly?: boolean;
};

export const CheckboxField = (props: CheckboxFieldProps) => {
  const { onChange, readOnly, ...rest } = props;

  return (
    <Checkbox
      onChange={(e) => onChange(e.target.checked)}
      sx={{
        width: 16,
        height: 16,
        p: 0,
        m: 0,
      }}
      disabled={readOnly}
      {...rest}
    />
  );
};
