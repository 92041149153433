import { Box } from '@mui/material';
import { useIframelyAPI } from 'components/common/Iframely/hooks/useIframelyAPI';
import { Image } from 'components/common/Image';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { LinkSearchHitFragmentItemThumbnailFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

type SearchThumbnailLinkProps = {
  searchHitLink: LinkSearchHitFragmentItemThumbnailFragment;
};
export const SearchThumbnailLink = (props: SearchThumbnailLinkProps) => {
  const { searchHitLink } = props;

  const { getUrlMetadata } = useIframelyAPI();

  const [url, setUrl] = useState('');
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    if (searchHitLink.item.urlMetadata.length) {
      const metadata = searchHitLink.item.urlMetadata[0];

      setUrl(metadata.url);
      if (metadata.metadata?.thumbnail?.length) {
        const lastThumbnailMetadata =
          metadata.metadata.thumbnail[metadata.metadata.thumbnail.length - 1];
        if (lastThumbnailMetadata.href) {
          return setThumbnailUrl(lastThumbnailMetadata.href);
        }
      } else if (metadata.url) {
        getUrlMetadata(metadata.url).then((res) => {
          if (
            res.links &&
            res.links?.thumbnail &&
            Array.isArray(res.links.thumbnail) &&
            res.links.thumbnail.length > 0
          ) {
            setThumbnailUrl(
              res.links?.thumbnail[res.links.thumbnail.length - 1].href || '',
            );
          }
        });
      }
    }
  }, [searchHitLink, thumbnailUrl, url, getUrlMetadata]);

  return thumbnailUrl ? (
    <Image
      sx={{
        borderRadius: theme.spacing(2),
        objectFit: 'cover',
        height: '100%',
        width: '100%',
      }}
      src={thumbnailUrl}
    />
  ) : (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: theme.colors?.utility[300],
        borderRadius: theme.spacing(2),
      }}
    >
      <IconOutlineLink />
    </Box>
  );
};
