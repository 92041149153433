/**
 * This component is also for displaying the attachments, but read-only, and only displaying the already-created ones.
 * This is used in the CommentContentView component, not to be confused with the CommentAttachmentList component,
 * which is used in the CommentInput component.
 *
 * The name sucks, but I can't think of anything else right now.
 */

import { useDisclosure } from '@dwarvesf/react-hooks';
import { Masonry } from '@mui/lab';
import { Box, SxProps, Typography } from '@mui/material';
import { IconOutlineArrowUp2 } from 'components/icons/components/outline/IconOutlineArrowUp2';
import { CommentFragmentCommentAttachmentListFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { CommentAttachment } from './CommentAttachment';

const MAX_ATTACHMENTS_TO_SHOW = 3;

export type CommentAttachmentListReadonlyProps = {
  comment: CommentFragmentCommentAttachmentListFragment;
  sx?: SxProps;
};

export const CommentAttachmentListReadonly = (
  props: CommentAttachmentListReadonlyProps,
) => {
  const { comment, sx } = props;

  const { isOpen: isExpanded, onToggle: toggleExpanded } = useDisclosure();

  return (
    <Masonry
      sx={{
        width: '100%',
        ...sx,
      }}
      columns={{ xs: 2 }}
      spacing={2}
    >
      {(isExpanded
        ? comment.attachments
        : comment.attachments.slice(0, MAX_ATTACHMENTS_TO_SHOW)
      ).map((attachment) => (
        <CommentAttachment
          key={attachment.id}
          attachment={attachment}
          sx={{
            width: 'calc(50% - 4px)',
            maxHeight: '120px',
            overflow: 'hidden',
            borderRadius: 2,
            img: {
              height: '120px !important',
            },
          }}
          componentsProps={{
            attachmentPreview: {
              sx: {
                width: '100%',
                height: '100%',
                maxWidth: 'unset',
              },
              componentsProps: {
                playIcon: {
                  size: 32,
                },
              },
            },
          }}
        />
      ))}
      {comment.attachments.length > MAX_ATTACHMENTS_TO_SHOW && (
        <Box>
          <Box
            component="button"
            sx={{
              borderRadius: 2,
              width: 32,
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: theme.colors?.utility[800],
              bgcolor: theme.colors?.utility[300],
            }}
            onClick={toggleExpanded}
          >
            {isExpanded ? (
              <IconOutlineArrowUp2 size={16} />
            ) : (
              <Typography variant="subhead-lg">
                +{comment.attachments.length - MAX_ATTACHMENTS_TO_SHOW}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Masonry>
  );
};
