import { Box, Skeleton, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { videoFrameCaptureQueue } from 'utils/video';

export const CustomVideoPreview = (props: { url: string; sx?: SxProps }) => {
  const { url, sx } = props;

  const [urlForPreview, setUrlForPreview] = useState<string | undefined>();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    videoFrameCaptureQueue.enqueue(url).then(
      (frameUrl) => {
        setUrlForPreview(frameUrl);
      },
      () => {
        setHasError(true);
      },
    );

    return () => {
      videoFrameCaptureQueue.cancel(url);
    };
  }, [url]);

  if (hasError) {
    throw new Error('Could not load video preview.');
  }

  if (!urlForPreview) {
    return (
      <Skeleton
        sx={{
          width: '100%',
          height: '100%',
          transform: 'scale(1)',
          ...sx,
        }}
      />
    );
  }

  return (
    <Box
      component="img"
      src={urlForPreview}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 'inherit',
        ...sx,
      }}
    />
  );
};
