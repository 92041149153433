import { useDisclosure } from '@dwarvesf/react-hooks';
import { CommandHandler } from 'contexts/commands/types';
import { useEffect, useState } from 'react';
import { TaskFragmentCreateTaskModalFragment } from 'graphql/generated';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CreateTaskModal } from './CreateTaskModal';

export const CreateTaskHandler: CommandHandler<COMMAND_TYPE.CREATE_TASK> = (
  props,
) => {
  const { commandId, context: { initialValues, onCreateTask } = {} } = props;

  const { updateActiveCommand } = useCommandContext();

  const [createdTask, setCreatedTask] =
    useState<TaskFragmentCreateTaskModalFragment>();

  const {
    isOpen: isCreateTaskModalOpen,
    onOpen: openCreateTaskModal,
    onClose: closeCreateTaskModal,
  } = useDisclosure();

  const onClose = () => {
    updateActiveCommand(commandId, {
      status: 'completed',
    });
    closeCreateTaskModal();
  };

  useEffect(() => {
    openCreateTaskModal();
  }, []); // eslint-disable-line -- triger flow on mount

  useEffect(() => {
    updateActiveCommand(commandId, {
      data: createdTask,
    });
  }, [createdTask]); // eslint-disable-line

  return (
    <CreateTaskModal
      open={isCreateTaskModalOpen}
      onClose={onClose}
      onTaskCreated={setCreatedTask}
      options={{
        initialValues,
      }}
      onCreateTask={onCreateTask}
    />
  );
};
