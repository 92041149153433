import { gql } from '@apollo/client';
import { URL_METADATA_FRAGMENT_IFRAMELY } from 'components/common/Iframely/types';
import {
  PostFragmentPostContextMenuFragmentDoc,
  PostFragmentPostCreatorFragmentDoc,
  PostFragmentPostNewBadgeFragmentDoc,
  PostFragmentPostPermissionSummaryFragmentDoc,
  PostFragmentPostPinFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostReactionListFragmentDoc,
  PostFragmentPostTitleFragmentDoc,
  PostFragmentPostUnreadCommentBadgeFragmentDoc,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';

export const URL_METADATA_FRAGMENT_POST_MASONRY_CARD_VIEW = gql`
  fragment URLMetadataFragmentPostMasonryCardView on URLMetadataModel {
    ...URLMetadataFragmentIframely
  }
  ${URL_METADATA_FRAGMENT_IFRAMELY}
`;

export const POST_FRAGMENT_POST_MASONRY_CARD_VIEW = gql`
  fragment PostFragmentPostMasonryCardView on PostModel {
    id
    description
    ...PostFragmentPostPreview
    ...PostFragmentPostUnreadCommentBadge
    ...PostFragmentPostNewBadge
    ...PostFragmentPostContextMenu
    ...PostFragmentPostReactionList
    ...PostFragmentPostCreator
    ...PostFragmentPostPin
    ...PostFragmentPostPermissionSummary
    ...PostFragmentPostTitle
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentPostUnreadCommentBadgeFragmentDoc}
  ${PostFragmentPostNewBadgeFragmentDoc}
  ${PostFragmentPostContextMenuFragmentDoc}
  ${PostFragmentPostReactionListFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
  ${PostFragmentPostCreatorFragmentDoc}
  ${PostFragmentPostPinFragmentDoc}
  ${PostFragmentPostPermissionSummaryFragmentDoc}
  ${PostFragmentPostTitleFragmentDoc}
`;
