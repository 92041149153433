import { gql, useApolloClient } from '@apollo/client';
import { POST_ANNOTATION_FRAGMENT_ANNOTATION } from 'components/common/Annotation/types';
import { useUserContext } from 'contexts/users/User.context';
import {
  CommentFragmentCommentContainerViewFragmentDoc,
  CreateCommentInput,
  CreatePostAnnotationInput,
  GetCommentsForPostCommentListViewDocument,
  GetCommentsForPostCommentListViewQuery,
  PostAnnotationFragmentAnnotationFragmentDoc,
  SortOrder,
  UpdateCommentInput,
  useCreateCommentForPostDetailContentViewMutation,
  useCreatePostAnnotationForPostDetailContentViewMutation,
  useDeleteCommentForPostDetailContentViewMutation,
  useDeletePostAnnotationForPostDetailContentViewMutation,
  useReactToCommentForPostDetailContentViewMutation,
  useUpdateCommentForPostDetailContentViewMutation,
} from 'graphql/generated';
import { useCallback } from 'react';
import { evictObject, modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreatePostAnnotationForPostDetailContentView(
    $data: CreatePostAnnotationInput!
  ) {
    createPostAnnotation(data: $data) {
      ...PostAnnotationFragmentAnnotation
    }
  }
  ${PostAnnotationFragmentAnnotationFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeletePostAnnotationForPostDetailContentView(
    $data: DeletePostAnnotationInput!
  ) {
    deletePostAnnotation(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation CreateCommentForPostDetailContentView($data: CreateCommentInput!) {
    createComment(data: $data) {
      ...CommentFragmentCommentContainerView
    }
  }
  ${CommentFragmentCommentContainerViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation ReactToCommentForPostDetailContentView($data: ReactInput!) {
    react(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCommentForPostDetailContentView($data: UpdateCommentInput!) {
    updateComment(data: $data) {
      ...CommentFragmentCommentContainerView
    }
  }
  ${CommentFragmentCommentContainerViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteCommentForPostDetailContentView($data: DeleteCommentInput!) {
    deleteComment(data: $data) {
      message
      success
    }
  }
`;

export const usePostAnnotationHandlers = () => {
  const { user } = useUserContext();
  const client = useApolloClient();
  const [createPostAnnotation] =
    useCreatePostAnnotationForPostDetailContentViewMutation();
  const [deletePostAnnotation] =
    useDeletePostAnnotationForPostDetailContentViewMutation();

  const [createCommentForPostAnnotation] =
    useCreateCommentForPostDetailContentViewMutation();
  const [reactToCommentForPostAnnotation] =
    useReactToCommentForPostDetailContentViewMutation();
  const [updateCommentForPostAnnotation] =
    useUpdateCommentForPostDetailContentViewMutation();
  const [deleteCommentForPostAnnotation] =
    useDeleteCommentForPostDetailContentViewMutation();

  const onCreatePostAnnotation = useCallback(
    (input: CreatePostAnnotationInput) => {
      createPostAnnotation({
        variables: {
          data: input,
        },
        update: (cache, { data }) => {
          const createdAnnotation = data?.createPostAnnotation;
          if (createdAnnotation) {
            // It will update the comment list
            cache.updateQuery(
              {
                query: GetCommentsForPostCommentListViewDocument,
                variables: {
                  filters: {
                    postId: input.postId,
                    query: '',
                    filterOnlyParentThreads: true,
                    sortBy: {
                      field: 'createdAt',
                      order: SortOrder.Asc,
                    },
                  },
                },
              },
              (data: GetCommentsForPostCommentListViewQuery | null) => {
                if (!data) {
                  return null;
                }

                return {
                  ...data,
                  comments: [...data.comments, ...createdAnnotation.comments],
                };
              },
            );
          }
          // It will update the post annotation list
          modifyObject(cache, input.postId, 'PostModel', {
            annotations: (cachedAnnotations = []) => {
              const newAnnotationRef = cache.writeFragment({
                data: createdAnnotation,
                fragment: POST_ANNOTATION_FRAGMENT_ANNOTATION,
                fragmentName: 'PostAnnotationFragmentAnnotation',
              });

              return [...cachedAnnotations, newAnnotationRef];
            },
          });
        },
        // FIXME: This should be removed soon
        refetchQueries: ['GetAssetComments'],
      });
    },
    [createPostAnnotation],
  );

  const onDeletePostAnnotation = async (postAnnotationId: string) => {
    deletePostAnnotation({
      variables: {
        data: {
          postAnnotationId,
        },
      },
    });
    evictObject(client.cache, postAnnotationId, 'PostAnnotationModel');
  };

  const onCreatePostAnnotationComment = (input: CreateCommentInput) => {
    createCommentForPostAnnotation({
      variables: {
        data: input,
      },
      update: (cache, data) => {
        const newCommentRef = cache.writeFragment({
          data: data.data?.createComment,
          fragment: CommentFragmentCommentContainerViewFragmentDoc,
          fragmentName: 'CommentFragmentCommentContainerView',
        });
        if (input.data.parentThreadId) {
          modifyObject(cache, input.data.parentThreadId, 'CommentModel', {
            childComments: (cachedComments = []) => [
              ...cachedComments,
              newCommentRef,
            ],
          });
        }
      },
    });
  };

  const onUpdatePostAnnotationComment = (input: UpdateCommentInput) => {
    updateCommentForPostAnnotation({
      variables: {
        data: input,
      },
      update: (cache, res) => {
        const updatedPostComment = res.data?.updateComment;
        if (updatedPostComment) {
          modifyObject(cache, input.commentId, 'CommentModel', {
            text: () => updatedPostComment.text,
          });
        }
      },
    });
  };

  const onDeletePostAnnotationComment = async (commentId: string) => {
    deleteCommentForPostAnnotation({
      variables: {
        data: {
          commentId,
        },
      },
    });
    evictObject(client.cache, commentId, 'CommentModel');
  };

  const onReactToPostAnnotationComment = (commentId: string, emoji: string) => {
    reactToCommentForPostAnnotation({
      variables: {
        data: {
          commentId,
          emoji,
        },
      },
      update: (cache, res) => {
        const createdPostComment = res.data?.react;

        if (createdPostComment) {
          modifyObject(cache, commentId, 'CommentModel', {
            userReactions: (cachedUserReactions) => {
              const reaction = cachedUserReactions.find(
                (c) => c.emoji === emoji && c.userId === user?.id,
              );
              if (reaction) {
                evictObject(cache, reaction.id, 'UserReactionModel');
              } else {
                return [
                  ...cachedUserReactions,
                  {
                    __typename: 'UserReactionModel',
                    user,
                    userId: user?.id,
                  },
                ];
              }
            },
          });
        }
      },
    });
  };

  return {
    onCreatePostAnnotation,
    onDeletePostAnnotation,
    onCreatePostAnnotationComment,
    onDeletePostAnnotationComment,
    onReactToPostAnnotationComment,
    onUpdatePostAnnotationComment,
  };
};
