import { Box, MenuItem, MenuItemProps, Radio, Typography } from '@mui/material';
import { TooltipForTruncatedText } from 'components/common/TooltipForTruncatedText';
import { forwardRef, ReactNode } from 'react';
import { theme } from 'styles/theme/theme';

type RadioMenuItemProps = MenuItemProps & {
  value: string | ReadonlyArray<string> | number | undefined;
  label: ReactNode;
  description?: string;
  checked?: boolean;
  radioPosition?: 'start' | 'end';
  renderRadio?: (checked?: boolean) => ReactNode;
};

export const RadioMenuItem = forwardRef<HTMLLIElement, RadioMenuItemProps>(
  (props: RadioMenuItemProps, ref) => {
    const {
      value,
      checked,
      label,
      description,
      radioPosition = 'start',
      renderRadio,
      ...rest
    } = props;

    const renderRadioButton = (checked?: boolean) => {
      return renderRadio ? (
        renderRadio(checked)
      ) : (
        <Radio
          size="small"
          checked={checked}
          sx={{
            p: 0,
            color: `${theme.colors?.primary.black} !important`,
          }}
        />
      );
    };

    return (
      <MenuItem
        ref={ref}
        value={value}
        {...rest}
        sx={{
          width: '100%',
          borderRadius: theme.spacing(3),
          padding: theme.spacing(2, 3),
          ...rest.sx,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={
            radioPosition === 'start' ? 'flex-start' : 'space-between'
          }
          width="100%"
          gap={2}
        >
          {radioPosition === 'start' && renderRadioButton(checked)}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              flex: 1,
              overflow: 'hidden',
            }}
          >
            {typeof label === 'string' ? (
              <TooltipForTruncatedText title={label}>
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.utility[900]}
                >
                  {label}
                </Typography>
              </TooltipForTruncatedText>
            ) : (
              label
            )}

            {description && (
              <Typography
                variant="subhead-sm"
                color={theme.colors?.utility[700]}
              >
                {description}
              </Typography>
            )}
          </Box>
          {radioPosition === 'end' && renderRadioButton(checked)}
        </Box>
      </MenuItem>
    );
  },
);
