import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { CollectionFragmentCollectionSummaryFragment } from 'graphql/generated';
import { Fragment } from 'react';
import { theme } from 'styles/theme';

export const COLLECTION_FRAGMENT_COLLECTION_SUMMARY = gql`
  fragment CollectionFragmentCollectionSummary on CollectionModel {
    id
    totalPosts
    totalChildCollections
    isDisabled
  }
`;

export type CollectionSummaryProps = {
  collection: CollectionFragmentCollectionSummaryFragment;
  sx?: SxProps;
};

export const CollectionSummary = (props: CollectionSummaryProps) => {
  const { collection, sx } = props;

  return (
    <Box
      sx={{
        minHeight: 20,
        ...sx,
      }}
    >
      {[
        collection.totalPosts ? (
          <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
            {collection.totalPosts} post{collection.totalPosts > 1 && 's'}
          </Typography>
        ) : null,
        collection.totalChildCollections ? (
          <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
            {collection.totalChildCollections} collection
            {collection.totalChildCollections > 1 && 's'}
          </Typography>
        ) : null,
        collection.isDisabled ? (
          <Typography variant="headline-xxs" color={theme.colors?.utility[900]}>
            Disabled
          </Typography>
        ) : null,
      ]
        .filter(Boolean)
        .map((render, index) => {
          return (
            <Fragment key={index}>
              {index > 0 && (
                <Typography
                  variant="headline-xxs"
                  color={theme.colors?.utility[700]}
                >
                  {' • '}
                </Typography>
              )}
              {render}
            </Fragment>
          );
        })}
    </Box>
  );
};
