import { Extension } from '@tiptap/react';
import { Plugin } from 'prosemirror-state';
import { EXTENSION_ENSURE_EMPTY_BOTTOM_PARAGRAPH } from '../constants';

export const EnsureEmptyBottomParagraph = Extension.create({
  name: EXTENSION_ENSURE_EMPTY_BOTTOM_PARAGRAPH,

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction(transactions, oldState, newState) {
          if (newState.doc === oldState.doc) {
            return;
          }

          const tr = newState.tr;
          const doc = newState.doc;
          const lastNode = doc.lastChild;

          if (lastNode && lastNode.type.name !== 'paragraph') {
            const paragraphNode = newState.schema.nodes.paragraph.create();
            tr.insert(doc.content.size, paragraphNode);

            return tr;
          }
        },
      }),
    ];
  },
});
