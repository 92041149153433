import { gql } from '@apollo/client';
import { useGetContentCalendarViewForUseContentCalendarViewQuery } from 'graphql/generated';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line
gql`
  query GetContentCalendarViewForUseContentCalendarView($id: String!) {
    contentCalendarView(id: $id) {
      id
      name
      filters
      myPermissions
    }
  }
`;

export const useContentCalendarView = (id = '') => {
  const { data } = useGetContentCalendarViewForUseContentCalendarViewQuery({
    variables: {
      id,
    },
    skip: !id,
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
    fetchPolicy: 'cache-and-network',
  });
  const contentCalendarView = data?.contentCalendarView;

  return {
    contentCalendarView,
  };
};
