import { gql } from '@apollo/client';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconCustomIceCubeFilled } from 'components/icons/components/custom/IconCustomIceCubeFilled';
import {
  ContentIdeaFragmentContentIdeaIceboxFragment,
  ContentIdeaPermission,
  Stage,
  useUpdateContentIdeaForContentIdeaIceboxMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_ICEBOX = gql`
  fragment ContentIdeaFragmentContentIdeaIcebox on ContentIdeaModel {
    id
    stage
    myPermissions
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaForContentIdeaIcebox(
    $data: UpdateContentIdeaInput!
  ) {
    updateContentIdea(data: $data) {
      id
      ...ContentIdeaFragmentContentIdeaIcebox
    }
  }
  ${CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_ICEBOX}
`;

export type ContentIdeaIceboxProps = {
  contentIdea: ContentIdeaFragmentContentIdeaIceboxFragment;
  onAfterMove?: () => void;
};

export const ContentIdeaIcebox = ({
  contentIdea,
  onAfterMove,
}: ContentIdeaIceboxProps) => {
  const [updateContentIdea] =
    useUpdateContentIdeaForContentIdeaIceboxMutation();

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  const stopEvents = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onMoveIdeaToIcebox = () => {
    updateContentIdea({
      variables: {
        data: {
          contentIdeaId: contentIdea.id,
          data: {
            stage: Stage.IceBox,
          },
        },
      },
      update: (cache, { data }) => {
        if (!data?.updateContentIdea) return;
        modifyObject(cache, contentIdea.id, 'ContentIdeaModel', {
          stage: () => data.updateContentIdea.stage,
        });
        onAfterMove?.();
      },
    });
  };

  return (
    <IconButtonWithTooltip
      tooltip="Move to Icebox"
      sx={{
        padding: 0,
      }}
      onClick={(e) => {
        stopEvents(e);
        onMoveIdeaToIcebox();
      }}
      disabled={!canEdit}
    >
      <IconCustomIceCubeFilled size={16} color={theme.colors?.utility[500]} />
    </IconButtonWithTooltip>
  );
};
