import { Box, Card, Typography } from '@mui/material';
import { SentimentIcon } from 'features/socialMediaListening/components';
import { useGraphDataMaker } from 'features/socialMediaListening/hooks';
import { Sentiment } from 'graphql/generated';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { theme } from 'styles/theme';
import { SentimentThemeCardMetricsTimeRangeProps } from './types';

export const SentimentThemeCardWithMetricsTimeRange = ({
  componentProps,
  sentiment,
  timeRange,
  chartData,
  renderTableData,
}: SentimentThemeCardMetricsTimeRangeProps) => {
  const { mainColor = theme.colors?.primary.black, highlightColor } =
    componentProps || {};
  const {
    title,
    subTitle,
    averageCount,
    chartDataPoints,
    averageCountHelperText,
  } = chartData;

  const { chartData: chartDataProcessed } = useGraphDataMaker({
    dailyChartData: chartDataPoints,
    timeRange,
  });

  const cardIcon =
    sentiment === Sentiment.Positive ? (
      <SentimentIcon size={20} sentiment={Sentiment.Positive} />
    ) : sentiment === Sentiment.Negative ? (
      <SentimentIcon size={20} sentiment={Sentiment.Negative} />
    ) : sentiment === Sentiment.Neutral ? (
      <SentimentIcon size={20} sentiment={Sentiment.Neutral} />
    ) : null;

  return (
    <Card
      sx={{
        flex: 1,
        borderRadius: 5,
        my: 2,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        ...componentProps?.containerSx,
      }}
    >
      <Box
        p={6}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={1}
        mb={3}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="headline-md" fontSize={theme.spacing(5)}>
            {title}
          </Typography>
          {!!cardIcon && cardIcon}
        </Box>
        <Typography variant="body-xl" color={theme.colors?.utility[700]}>
          {subTitle}
        </Typography>
        <Typography variant="headline-lg" fontSize={theme.spacing(8)}>
          {Number(averageCount) === 0
            ? '0'
            : parseFloat(Number(averageCount).toFixed(2)).toString()}
          {averageCountHelperText && (
            <Typography
              variant="body-xl"
              color={theme.colors?.utility[700]}
              ml={1}
            >
              {averageCountHelperText}
            </Typography>
          )}
        </Typography>
      </Box>
      <ResponsiveContainer
        width="100%"
        height={200}
        style={{ padding: theme.spacing(0, 0, 0, 4) }}
      >
        <AreaChart
          data={chartDataProcessed}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid vertical={false} stroke={theme.colors?.utility[300]} /> */}
          <XAxis dataKey="X" />
          {/* <YAxis /> */}
          <Tooltip
            formatter={(value, name) => [
              parseFloat(Number(value).toFixed(2)),
              'Mentions',
            ]}
          />
          <Area
            type="monotone"
            dataKey="Y"
            stroke={mainColor}
            strokeWidth={3}
            fill={highlightColor}
          />
        </AreaChart>
      </ResponsiveContainer>

      {renderTableData && (
        <>
          <Box
            sx={{ borderTop: `1px solid ${theme.colors?.utility[400]}`, mt: 4 }}
          />
          {renderTableData()}
        </>
      )}
    </Card>
  );
};
