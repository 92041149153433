import { gql } from '@apollo/client';
import {
  PostFragmentPostPermissionMasonryCardViewFragment,
  PostFragmentPostPreviewFragmentDoc,
} from 'graphql/generated';
import {
  POST_FRAGMENT_POST_PERMISSION_SUMMARY,
  PostPermissionSummary,
} from 'features/post-permission/components';
import { Box } from '@mui/material';
import { PostPreview } from 'features/post';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_POST_PERMISSION_MASONRY_CARD_VIEW = gql`
  fragment PostFragmentPostPermissionMasonryCardView on PostModel {
    id
    ...PostFragmentPostPreview
    ...PostFragmentPostPermissionSummary
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${POST_FRAGMENT_POST_PERMISSION_SUMMARY}
`;

type PostPermissionMasonryCardProps = {
  post: PostFragmentPostPermissionMasonryCardViewFragment;
};

export const PostPermissionMasonryCard = (
  props: PostPermissionMasonryCardProps,
) => {
  const { post } = props;

  return (
    <Box
      position="relative"
      boxShadow="0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)"
      borderRadius={theme.spacing(3)}
      overflow="hidden"
      width="100%"
      height="100%"
      minHeight={150}
    >
      <Box
        style={{
          height: '100%',
          display: 'flex',
          position: 'relative',
        }}
      >
        <PostPreview post={post} disableMouseEvents />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          px: 4,
          pb: 3,
          pt: 4.5,
          overflow: 'auto',
          backgroundImage:
            'linear-gradient(180deg, rgba(35, 6, 3, 0.00) 0%, rgba(35, 6, 3, 0.80) 100%)',
        }}
      >
        <PostPermissionSummary
          post={post}
          componentProps={{
            sx: {
              bgcolor: 'rgba(250, 243, 236, 0.2)',
              color: theme.colors?.primary.white,
            },
          }}
        />
      </Box>
    </Box>
  );
};
