import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import {
  AttachmentFragmentAttachmentPreviewFragmentDoc,
  CommentFragmentCommentAttachmentListFragment,
  useDeleteAttachmentForCommentAttachmentListMutation,
} from 'graphql/generated';
import { ResourceUploadItem } from 'hooks/useResourceUploadQueue';
import { evictObject } from 'utils/apollo';
import { CommentAttachment } from './CommentAttachment';
import { CommentPendingAttachment } from './CommentPendingAttachment';

export const ATTACHMENT_FRAGMENT_COMMENT_ATTACHMENT_LIST = gql`
  fragment AttachmentFragmentCommentAttachmentList on AttachmentModel {
    id
    ...AttachmentFragmentAttachmentPreview
  }
  ${AttachmentFragmentAttachmentPreviewFragmentDoc}
`;

export const COMMENT_FRAGMENT_COMMENT_ATTACHMENT_LIST = gql`
  fragment CommentFragmentCommentAttachmentList on CommentModel {
    id
    attachments {
      id
      ...AttachmentFragmentCommentAttachmentList
    }
  }
  ${ATTACHMENT_FRAGMENT_COMMENT_ATTACHMENT_LIST}
`;

// eslint-disable-next-line
gql`
  mutation DeleteAttachmentForCommentAttachmentList(
    $data: DeleteAttachmentInput!
  ) {
    deleteAttachment(data: $data) {
      success
      message
    }
  }
`;

export type CommentAttachmentListProps = {
  comment?: CommentFragmentCommentAttachmentListFragment;
  pendingAttachments?: ResourceUploadItem[];
  sx?: SxProps;
  deletePendingAttachment?: (index: number) => void;
};

export const CommentAttachmentList = (props: CommentAttachmentListProps) => {
  const {
    comment,
    pendingAttachments = [],
    sx,
    deletePendingAttachment,
  } = props;

  const [deleteAttachment] =
    useDeleteAttachmentForCommentAttachmentListMutation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        width: '100%',
        overflow: 'auto',
        pt: 2,
        ...sx,
      }}
    >
      {comment?.attachments.map((attachment) => (
        <CommentAttachment
          key={attachment.id}
          attachment={attachment}
          onRemove={() => {
            deleteAttachment({
              variables: {
                data: {
                  attachmentId: attachment.id,
                },
              },
              update: (cache) => {
                evictObject(cache, attachment.id, 'AttachmentModel');
              },
            });
          }}
        />
      ))}
      {pendingAttachments.map((resource, index) => (
        <CommentPendingAttachment
          key={resource.resource.id}
          resource={resource}
          onRemove={() => deletePendingAttachment?.(index)}
        />
      ))}
    </Box>
  );
};
