import { COMMAND_KEYS, COMMAND_TYPE } from '../../constants';
import { Command } from '../../types';
import { CreateTaskHandler } from './components/CreateTaskHandler';

export const useTaskCommands = (): Command[] => {
  return [
    {
      type: COMMAND_TYPE.CREATE_TASK,
      keys: COMMAND_KEYS[COMMAND_TYPE.CREATE_TASK],
      Handler: CreateTaskHandler as any,
    },
  ];
};
