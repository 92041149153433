/**
 * This file is for both resources & assets (& maybe attachments) for now
 * because they are mostly the same.
 */

import { getValidatedMimeType } from './mime';

export const isImage = (url: string, mimeType?: string) => {
  // First, we'll try mimeType if it's available
  if (mimeType) {
    const validatedMimeType = getValidatedMimeType(mimeType, url);

    if (/^image/.test(validatedMimeType)) {
      return Promise.resolve();
    }
  }

  // If mimeType is not available or couldn't help us identify if it's an image
  // we'll try to load the image and see if it's an image
  // TODO: Optimize this. This is REALLY INEFFICIENT because it would load the full image,
  // blocking the adaptive delivery functionality of Uploadcare if the image is from Uploadcare.
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = resolve;
    img.onerror = reject;
  });
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
