import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldNote2 } from 'components/icons/components/bold/IconBoldNote2';
import { IconBoldTag } from 'components/icons/components/bold/IconBoldTag';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { IconBoldVideo } from 'components/icons/components/bold/IconBoldVideo';
import { IconBoldVideoPlay } from 'components/icons/components/bold/IconBoldVideoPlay';
import { IconCustomChart3 } from 'components/icons/components/custom/IconCustomChart3';
import { SLAAnalyticsSummary } from 'features/socialListeningAnalytics';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  useGetBrandForSlaBrandInboundSummaryViewQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SLABrandInboundSummaryViewSkeleton } from './SLABrandInboundSummaryViewSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandForSLABrandInboundSummaryView(
    $brandId: String!
    $data: BrandInboundFiltersInput!
  ) {
    brand(id: $brandId) {
      id
      name
      __typename
      brandSummary(data: $data) {
        __typename
        averageLikes
        averageViewCount
        followers
        postsPerWeek
        totalEngagements
        totalPosts
        totalViewCount
      }
    }
  }
`;

type Props = {
  filters: BrandInboundFiltersInput;
};

export const SLABrandInboundSummaryView = (props: Props) => {
  const { filters } = props;

  const { contentType } = filters;

  const { data: brandData, loading: isLoadingData } =
    useGetBrandForSlaBrandInboundSummaryViewQuery({
      variables: {
        brandId: filters.brandId,
        data: filters,
      },
      skip: contentType === BrandContentType.Topic && !filters.topicIds?.length,
    });
  const summaryData = brandData?.brand?.brandSummary;

  if (
    isLoadingData ||
    (contentType === BrandContentType.Topic && !filters.topicIds?.length)
  ) {
    return <SLABrandInboundSummaryViewSkeleton />;
  }

  return (
    <SLAAnalyticsSummary
      title={
        contentType === BrandContentType.Owned
          ? 'Brand Summary'
          : contentType === BrandContentType.Ugc
          ? 'UGC Summary'
          : 'Topic Summary'
      }
      summaryData={[
        ...(contentType === BrandContentType.Owned
          ? [
              {
                icon: <IconBoldUser size={14} />,
                label: (
                  <Box display="flex" alignItems="center" gap={1}>
                    Followers •{' '}
                    <Typography
                      component="span"
                      variant="body-xl"
                      fontWeight={600}
                      color={theme.colors?.utility['pink-3']}
                    >
                      LIVE
                    </Typography>
                  </Box>
                ),
                value: formatBigNumber(summaryData?.followers || 0),
              },
            ]
          : []),
        {
          icon: <IconBoldNote2 size={14} />,
          label: 'Total number of posts',
          value: formatBigNumber(summaryData?.totalPosts || 0),
        },
        {
          icon: <IconBoldTag size={14} />,
          label:
            contentType === BrandContentType.Owned
              ? 'Number of posts/week'
              : contentType === BrandContentType.Ugc
              ? 'Brand mentions/week'
              : 'Number of posts/week',
          value: summaryData?.postsPerWeek
            ? summaryData.postsPerWeek.toFixed(1)
            : '0',
        },
        {
          icon: <IconCustomChart3 size={16} />,
          label: 'Total Engagement',
          value: summaryData?.totalEngagements || 0,
        },
        {
          icon: <IconBoldVideo size={14} />,
          label: 'Total view count',
          value: summaryData?.totalViewCount || 0,
        },
        {
          icon: <IconBoldHeart size={14} />,
          label: 'Average likes',
          value: formatBigNumber(summaryData?.averageLikes || 0),
        },
        {
          icon: <IconBoldVideoPlay size={14} />,
          label: 'Average view count',
          value: formatBigNumber(summaryData?.averageViewCount || 0),
        },
      ]}
    />
  );
};
