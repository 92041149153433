import { Box, Button, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  width: '100%',
}));

export const StyledCreateSubtaskButton = styled(Button)(() => ({
  borderRadius: theme.spacing(1),
  backgroundColor: theme.colors?.utility[300],
  border: `1px solid ${theme.colors?.utility[400]}`,
  ':hover': {
    backgroundColor: theme.colors?.utility[300],
  },
  ...theme.typography['headline-sm'],
  color: theme.colors?.utility[700],
}));

export const StyledEmptyStateWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(16),
  flexDirection: 'column',
  '.empty-state-title': {
    ...theme.typography['headline-xl'],
    fontWeight: 500,
    fontSize: 24,
    color: theme.colors?.utility[600],
  },
  '.empty-state-desc': {
    ...theme.typography['subhead-xl'],
    color: theme.colors?.utility[500],
  },
}));

export const StyledSubtaskContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  backgroundColor: theme.colors?.primary.white,
  marginTop: theme.spacing(3),
}));

export const iconStyles = (isNew?: boolean) => ({
  color: theme.colors?.utility[600],
  size: isNew ? 16 : 24,
});
