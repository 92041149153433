import { useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { toast } from 'components/common/Toast';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import {
  PlotFeatureType,
  useRoleBasedUserAccessContext,
} from 'contexts/RoleBasedUserAccess.context';
import { PostBookmarkPopover } from 'features/post/components/bookmarkButton/PostBookmarkPopover';
import { useCreatePostHandlerForSocialListening } from 'features/socialMediaListening/hooks';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { CreatePortalById } from 'utils/helpers';
import { SocialListeningPostSaveButtonProps } from './types';

export const SocialListeningPostSaveButton = (
  props: SocialListeningPostSaveButtonProps,
) => {
  const {
    socialListeningPost,
    socialPostEngagementSuggestionId,
    renderBtn,
    onPostCreated,
    componentsProps = {},
    containerId,
    onClickSavePost,
  } = props;

  const client = useApolloClient();
  const { checkCanAccess } = useRoleBasedUserAccessContext();
  const canAccess = checkCanAccess(PlotFeatureType.CreativeJuicebox);

  const [currentPostId, setCurrentPostId] = useState(
    socialListeningPost.savedPost?.id || '',
  );
  const { createPost } = useCreatePostHandlerForSocialListening({
    socialListeningPost,
    socialPostEngagementSuggestionId,
  });

  const anchorElRef = useRef<HTMLElement>(null);
  const {
    isOpen: isBookmarkPopoverOpen,
    onClose: closeBookmarkPopover,
    onOpen: openBookmarkPopover,
  } = useDisclosure();

  const handleCreatePost = async () => {
    if (!currentPostId) {
      const data = await createPost();
      toast({
        message: 'Post has been added to juicebox!!',
        position: 'bottom-center',
        shouldShowCloseButton: false,
        sx: {
          padding: theme.spacing(2, 4),
          bgcolor: theme.colors?.utility['blue-1'],
          color: theme.colors?.utility.blueberry,
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16)',
          span: {
            ...theme.typography['headline-sm'],
          },
        },
      });
      return data.data?.createPostForSocialListeningPost.id;
    }
    return currentPostId;
  };

  const handleClick = () => {
    handleCreatePost().then((id) => {
      if (id) {
        if (!currentPostId) {
          modifyObject(
            client.cache,
            socialListeningPost.id,
            'SocialPostModel',
            {
              totalPostSaves: () => socialListeningPost.totalPostSaves + 1,
              savedPostId: () => id,
              savedPost: () => ({
                id,
              }),
            },
          );
        }

        setCurrentPostId(id);
        onPostCreated?.(id);

        openBookmarkPopover();
      }
    });
  };

  const handleClose = () => {
    closeBookmarkPopover();
  };

  const content = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="fit-content"
      flexDirection="column"
      gap={2}
    >
      <IconButtonWithTooltip
        tooltip={currentPostId ? 'Saved' : 'Save this post'}
        size="small"
        ref={anchorElRef as any}
        sx={{
          width: 'fit-content',
          padding: 2.5,
          bgcolor: theme.colors?.utility['purple-1'],
          '&.Mui-disabled': {
            bgcolor: theme.colors?.utility[500],
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClickSavePost?.();
          if (currentPostId) {
            openBookmarkPopover();
          } else {
            handleClick();
          }
        }}
        disabled={!canAccess}
      >
        {!currentPostId ? (
          <IconLinearSave
            size={24}
            color={
              canAccess
                ? theme.colors?.primary.black
                : theme.colors?.utility[800]
            }
          />
        ) : (
          <IconBoldSave
            size={24}
            color={
              canAccess
                ? theme.colors?.utility['purple-4']
                : theme.colors?.utility[800]
            }
          />
        )}
      </IconButtonWithTooltip>
      <Typography variant="body-md" color={theme.colors?.utility[700]}>
        {socialListeningPost.totalPostSaves}{' '}
        {socialListeningPost.totalPostSaves > 1 ? 'saves' : 'save'}
      </Typography>
    </Box>
  );

  return (
    <>
      {renderBtn ? (
        <Box
          ref={anchorElRef}
          onClick={(e) => {
            e.stopPropagation();
            onClickSavePost?.();
            if (currentPostId) {
              openBookmarkPopover();
            } else {
              handleClick();
            }
          }}
        >
          {renderBtn()}
        </Box>
      ) : canAccess ? (
        content
      ) : (
        <Tooltip
          title={
            <Typography variant="body-md">
              <Link
                style={{ textDecoration: 'underline' }}
                to={PlotRoutes.juicebox()}
              >
                Request Admin{' '}
              </Link>
              for All Plot Features
              <br /> to save this post
            </Typography>
          }
          enterDelay={canAccess ? 999999 : 0}
        >
          {content}
        </Tooltip>
      )}
      <CreatePortalById containerId={containerId ?? ''}>
        {socialListeningPost.savedPost?.id && isBookmarkPopoverOpen && (
          <PostBookmarkPopover
            post={socialListeningPost.savedPost}
            open={isBookmarkPopoverOpen}
            anchorEl={anchorElRef.current}
            onClose={handleClose}
            {...componentsProps.popover}
          />
        )}
      </CreatePortalById>
    </>
  );
};
