import { Box, IconButton, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { useUserContext } from 'contexts/users/User.context';
import {
  PermissionLevel,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { PermissionSelector } from '../permissionSelector';

interface PermissionCreateRequestProps {
  entityOrganizationId: string;
  onRequestCreateAccess: VoidFunction;
  permissionLevel?: PermissionLevel;
  isRequestCreated?: boolean;
}

export const PermissionCreateRequest = (
  props: PermissionCreateRequestProps,
) => {
  const {
    entityOrganizationId,
    onRequestCreateAccess,
    permissionLevel,
    isRequestCreated,
  } = props;

  const { user } = useUserContext();
  if (!user) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Avatar user={user as UserFragmentAvatarGroupFragment} size={36} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          <Typography variant="headline-sm" color={theme.colors?.primary.black}>
            {getFullName(user)}
          </Typography>
          <Typography variant="subhead-lg" color={theme.colors?.utility[900]}>
            {user.email}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: 0.5,
        }}
      >
        {permissionLevel && (
          <PermissionSelector
            entityOrganizationId={entityOrganizationId}
            userOrganizationId={user.organization.id}
            initialValue={permissionLevel}
            readonly
          />
        )}
        {isRequestCreated ? (
          <Typography
            variant="subhead-lg"
            color={theme.colors?.utility[700]}
            fontStyle="italic"
          >
            Full Access Requested
          </Typography>
        ) : (
          <IconButton
            sx={{
              padding: 0,
            }}
            disableRipple
            onClick={onRequestCreateAccess}
          >
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
              sx={{
                textDecoration: 'underline',
              }}
            >
              Request full access
            </Typography>
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
