/**
 * NOTE: The options here are kind of similar to what we have in
 * client/src/features/socialListeningSettings/views/notifications/constants.ts
 * but the notification settings allow multiple select while this flow allows single select.
 *
 * We might need to re-view these two flows and see if we can make them more consistent.
 * Tracking ticket: https://linear.app/plot-workspace/issue/ENG-5373
 */

export const followerOptions = [
  {
    label: '0 followers',
    value: 0,
  },
  {
    label: '1000 followers',
    value: 1000,
  },
  {
    label: '10K followers',
    value: 10000,
  },
  {
    label: '50K followers',
    value: 50000,
  },
  {
    label: '100K followers',
    value: 100000,
  },
  {
    label: '500K followers',
    value: 500000,
  },
  {
    label: '1M followers',
    value: 1000000,
  },
];

export const impressionOptions = [
  {
    label: '0 impressions',
    value: 0,
  },
  {
    label: '1000 impressions',
    value: 1000,
  },
  {
    label: '10K impressions',
    value: 10000,
  },
  {
    label: '50K impressions',
    value: 50000,
  },
  {
    label: '100K impressions',
    value: 100000,
  },
  {
    label: '500K impressions',
    value: 500000,
  },
  {
    label: '1M impressions',
    value: 1000000,
  },
];

export const likeOptions = [
  {
    label: '0 likes',
    value: 0,
  },
  {
    label: '100 likes',
    value: 100,
  },
  {
    label: '1000 likes',
    value: 1000,
  },
  {
    label: '10K likes',
    value: 10000,
  },
  {
    label: '50K likes',
    value: 50000,
  },
  {
    label: '100K likes',
    value: 100000,
  },
  {
    label: '500K likes',
    value: 500000,
  },
];
