import { useUserContext } from 'contexts/users/User.context';
import { TrendingSaveButton } from 'features/trending/components';
import { TrendFragmentTrendingDetailViewFragment } from 'graphql/generated';
import { useCallback } from 'react';

type Props = {
  trend: TrendFragmentTrendingDetailViewFragment;
  urlMetadataId: string;
};

export const TrendingDetailCollectionsListView = ({
  trend,
  urlMetadataId,
}: Props) => {
  const { user } = useUserContext();

  const getUserCreatedPost = useCallback(
    (urlMetadataId: string) => {
      const post = trend.linkedPosts.find(
        (p) =>
          p.urlMetadata.find((m) => m?.id === urlMetadataId) &&
          p.creator.id === user?.id &&
          p.isFavoriteTrend,
      );

      return post;
    },
    [trend, user?.id],
  );

  const userCreatedPost = getUserCreatedPost(urlMetadataId);

  return (
    <TrendingSaveButton
      trendId={trend.id}
      urlMetadataId={urlMetadataId}
      postId={userCreatedPost?.id}
      isSavedToTrendsCollection={!!userCreatedPost?.isFavoriteTrend}
    />
  );
};
