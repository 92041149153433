import { gql } from '@apollo/client';
import { toast } from 'components/common/Toast';
import {
  ContentIdeaFieldOptionFragmentContentIdeaFieldOptionHandlersFragment,
  ContentIdeaFieldOptionFragmentMultiSelectFieldFragmentDoc,
  ContentIdeaFieldOptionFragmentSelectFieldFragmentDoc,
  CreateContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation,
  CreateContentIdeaFieldOptionInput,
  UpdateContentIdeaFieldOptionInput,
  useCreateContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation,
  useDeleteContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation,
  useUpdateContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation,
} from 'graphql/generated';
import {
  evictObject,
  getCustomOperationContext,
  modifyObject,
} from 'utils/apollo';

gql`
  fragment ContentIdeaFieldOptionFragmentContentIdeaFieldOptionHandlers on ContentIdeaFieldOptionModel {
    id
    value
  }
`;

// eslint-disable-next-line
gql`
  mutation CreateContentIdeaFieldOptionForContentIdeaFieldOptionHandlers(
    $data: CreateContentIdeaFieldOptionInput!
  ) {
    createContentIdeaFieldOption(data: $data) {
      ...ContentIdeaFieldOptionFragmentMultiSelectField
      ...ContentIdeaFieldOptionFragmentSelectField
    }
  }
  ${ContentIdeaFieldOptionFragmentMultiSelectFieldFragmentDoc}
  ${ContentIdeaFieldOptionFragmentSelectFieldFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteContentIdeaFieldOptionForContentIdeaFieldOptionHandlers(
    $data: DeleteContentIdeaFieldOptionInput!
  ) {
    deleteContentIdeaFieldOption(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaFieldOptionForContentIdeaFieldOptionHandlers(
    $data: UpdateContentIdeaFieldOptionInput!
  ) {
    updateContentIdeaFieldOption(data: $data) {
      ...ContentIdeaFieldOptionFragmentMultiSelectField
      ...ContentIdeaFieldOptionFragmentSelectField
    }
  }
  ${ContentIdeaFieldOptionFragmentMultiSelectFieldFragmentDoc}
  ${ContentIdeaFieldOptionFragmentSelectFieldFragmentDoc}
`;

interface Props {
  onAfterOptionCreated?: (
    option: CreateContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation['createContentIdeaFieldOption'],
  ) => void;
}

export const useContentIdeaFieldOptionHandlers = ({
  onAfterOptionCreated,
}: Props) => {
  const [createOption] =
    useCreateContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation();
  const [updateOption] =
    useUpdateContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation({
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  const [deleteOption] =
    useDeleteContentIdeaFieldOptionForContentIdeaFieldOptionHandlersMutation();

  const onCreateOption = async (data: CreateContentIdeaFieldOptionInput) => {
    const response = await createOption({
      variables: {
        data,
      },
    });

    response.data &&
      onAfterOptionCreated?.(response.data.createContentIdeaFieldOption);
  };

  const onDeleteOption = (contentIdeaFieldOptionId: string) => {
    deleteOption({
      variables: {
        data: {
          contentIdeaFieldOptionId,
        },
      },
      update: (cache) => {
        evictObject(
          cache,
          contentIdeaFieldOptionId,
          'ContentIdeaFieldOptionModel',
        );
      },
    });
  };

  const onUpdateOption = (
    data: UpdateContentIdeaFieldOptionInput,
    options: ContentIdeaFieldOptionFragmentContentIdeaFieldOptionHandlersFragment[],
  ) => {
    const {
      contentIdeaFieldOptionId,
      data: { value },
    } = data;
    try {
      const optionExistWithSameValue = options.find(
        (o) =>
          o.value.toLowerCase() === value.toLowerCase() &&
          o.id !== contentIdeaFieldOptionId,
      );
      if (optionExistWithSameValue) {
        toast({
          type: 'error',
          message: 'Option with same label already exist',
        });
      }

      // if the new label is different from the current label then update the option
      if (
        value.toLowerCase() !==
        options
          .find((o) => o.id === contentIdeaFieldOptionId)
          ?.value?.toLowerCase()
      ) {
        updateOption({
          variables: {
            data,
          },
          update: (cache, { data }) => {
            modifyObject(
              cache,
              contentIdeaFieldOptionId,
              'ContentIdeaFieldOptionModel',
              {
                value: () => data?.updateContentIdeaFieldOption.value,
                label: () => data?.updateContentIdeaFieldOption.label,
              },
            );
          },
        });
      }
    } catch (error: any) {
      toast({
        type: 'error',
        message: error?.message || 'Failed to update option',
      });
    }
  };
  return {
    onCreateOption,
    onDeleteOption,
    onUpdateOption,
  };
};
