import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconOutlineArrowUp2 } from 'components/icons/components/outline/IconOutlineArrowUp2';
import { KeyboardEventHandler, useId } from 'react';
import { theme } from 'styles/theme';
import { StyledIconContainer, StyledMenuItemContainer } from '../../../styles';
import {
  ContentCalendarFilterByOwnersInnerContent,
  ContentCalendarFilterByOwnersInnerContentProps,
} from './ContentCalendarFilterByOwnersInnerContent';

export type ContentCalendarFilterByOwnersProps = Omit<
  ContentCalendarFilterByOwnersInnerContentProps,
  'sx'
> & {};

export const ContentCalendarFilterByOwners = (
  props: ContentCalendarFilterByOwnersProps,
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItemId = useId();

  const handleToggleDropDown = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = (ev) => {
    if (
      (ev.key !== 'ArrowRight' && ev.key !== 'Enter') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    ev.stopPropagation();
    onOpen();
  };

  return (
    <>
      <MenuItem
        id={menuItemId}
        onKeyDown={handleItemKeyDown}
        onClick={handleToggleDropDown}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: theme.spacing(4),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minWidth: theme.spacing(50),
            width: '100%',
          }}
        >
          <Box sx={{ width: '90%' }}>
            <StyledMenuItemContainer>
              <StyledIconContainer>
                <IconLinearUser size={16} />
              </StyledIconContainer>

              <Typography variant="subhead-lg">
                Owners{' '}
                {props.selectedUserIds.length +
                  props.selectedOrganizationIds.length >
                0 ? (
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[600]}
                  >
                    (
                    {props.selectedUserIds.length +
                      props.selectedOrganizationIds.length}
                    )
                  </Typography>
                ) : null}
              </Typography>
            </StyledMenuItemContainer>
          </Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleToggleDropDown();
            }}
          >
            {isOpen ? (
              <IconOutlineArrowUp2 size={16} />
            ) : (
              <IconLinearArrowDown size={16} />
            )}
          </IconButton>
        </Box>
      </MenuItem>
      {isOpen && <ContentCalendarFilterByOwnersInnerContent {...props} />}
    </>
  );
};
