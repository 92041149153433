import { useAuthForMobileInappBrowser } from 'features/auth';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useLogRocket = () => {
  const [params] = useSearchParams();

  const isMobileAppWebView = useMemo(() => Boolean(params.get('isMobile')), []); // eslint-disable-line
  const { isInMobileInAppBrowser } = useAuthForMobileInappBrowser();

  const initLogRocket = () => {
    if (
      (isMobileAppWebView || isInMobileInAppBrowser) &&
      process.env.REACT_APP_LOG_ROCKET_ID_MOBILE
    ) {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID_MOBILE, {
        serverURL: process.env.REACT_APP_LOG_ROCKET_SERVER_URL,
      });

      setupLogRocketReact(LogRocket);
    } else if (process.env.REACT_APP_LOG_ROCKET_ID) {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
        serverURL: process.env.REACT_APP_LOG_ROCKET_SERVER_URL,
      });

      setupLogRocketReact(LogRocket);
    }
  };

  return { initLogRocket };
};
