import { Box, Button, Dialog, Input, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { toast } from 'components/common/Toast';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { usePermissionRequestHandler } from 'features/permission/hooks';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

interface PermissionCreateRequestDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  user?: UserFragmentAvatarGroupFragment;
  title?: string;
  postId?: string;
  collectionId?: string;
  headingText?: React.ReactNode | string;
  renderCustomTitle?: JSX.Element;
}

export const PermissionCreateRequestDialog = ({
  isOpen,
  onClose,
  title,
  user,
  postId,
  collectionId,
  headingText,
  renderCustomTitle,
}: PermissionCreateRequestDialogProps) => {
  const [note, setNote] = useState('');
  const [isRequestExist, setIsRequestExist] = useState(false);

  const { onCreatePermissionRequest, onCheckIsRequestExist } =
    usePermissionRequestHandler();

  useEffect(() => {
    onCheckIsRequestExist({
      postId,
      collectionId,
    }).then((result) => {
      setIsRequestExist(result as boolean);
    });
  }, [postId, collectionId]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        sx: {
          padding: 8,
          borderRadius: 6,
          background: isRequestExist
            ? theme.colors?.primary.white
            : `rgba(250, 243, 236, 0.80)`,
          backdropFilter: `blur(20px)`,
          maxWidth: isRequestExist ? 350 : 470,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          zIndex: 999,
        },
      }}
    >
      {isRequestExist ? (
        <>
          <Typography variant="headline-lg">
            You’ve already requested for Full Access!
          </Typography>
          <Typography
            variant="subhead-xl"
            color={theme.colors?.utility[700]}
            mt={-4}
          >
            You’ll be notified once the owner approves your request.
          </Typography>
          <Button
            variant="secondary"
            sx={{
              bgcolor: theme.colors?.primary.black,
              borderRadius: 2,
              border: 'none',
              color: theme.colors?.primary.parchment,
              padding: theme.spacing(2, 4),
              ':hover': {
                bgcolor: theme.colors?.primary.black,
              },
            }}
            onClick={onClose}
          >
            Sounds Good!
          </Button>
        </>
      ) : (
        <>
          {headingText &&
            (typeof headingText === 'string' ? (
              <Typography variant="headline-xl" letterSpacing="-0.96px">
                {headingText}
              </Typography>
            ) : (
              headingText
            ))}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: 2,
            }}
          >
            {renderCustomTitle || (
              <>
                <Typography
                  variant="subhead-xl"
                  sx={{ fontSize: 20, color: theme.colors?.primary.black }}
                >
                  Ask the owner,
                </Typography>
                {user && (
                  <>
                    <Avatar user={user} size={24} />
                    <Typography variant="headline-md" fontSize={20}>
                      {getFullName(user)},
                    </Typography>
                  </>
                )}
                <Typography
                  variant="subhead-xl"
                  sx={{ fontSize: 20, color: theme.colors?.primary.black }}
                >
                  to get full access to{' '}
                  <Typography variant="headline-md" fontSize={20}>
                    {title}
                  </Typography>
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              padding: 3,
              borderRadius: 2,
              bgcolor: 'rgba(35, 6, 3, 0.10)',
              height: 155,
            }}
          >
            <Input
              placeholder="Write a note..."
              multiline
              fullWidth
              disableUnderline
              onChange={(e) => setNote(e.target.value)}
              sx={{
                ...theme.typography['subhead-xl'],
                padding: 0,
                color: theme.colors?.utility[1100],
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="text"
              sx={{ color: theme.colors?.primary.black }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              sx={{
                bgcolor: theme.colors?.primary.black,
                borderRadius: 2,
                border: 'none',
                color: theme.colors?.primary.parchment,
                padding: theme.spacing(2, 4),
                ':hover': {
                  bgcolor: theme.colors?.primary.black,
                },
              }}
              onClick={() => {
                onCreatePermissionRequest({
                  input: {
                    collectionId,
                    postId,
                    note,
                  },
                });
                toast({
                  message: 'Access requested',
                  icon: (
                    <IconBoldTickCircle
                      color={theme.colors?.utility['orange-4']}
                    />
                  ),
                  position: 'bottom-center',
                  shouldShowCloseButton: false,
                  sx: {
                    bgcolor: theme.colors?.utility['orange-1'],
                    borderRadius: theme.spacing(3),
                    padding: theme.spacing(2, 4),
                    span: {
                      color: theme.colors?.utility['orange-4'],
                      ...theme.typography['headline-md'],
                    },
                  },
                });
                onClose();
              }}
            >
              Request access
            </Button>
          </Box>
        </>
      )}
    </Dialog>
  );
};
