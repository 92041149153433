import { gql } from '@apollo/client';
import { Box, Button, Divider, Menu, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconOutlineCloseCircle } from 'components/icons/components/outline/IconOutlineCloseCircle';
import { IconOutlineCrossXClose } from 'components/icons/components/outline/IconOutlineCrossXClose';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useUserContext } from 'contexts/users/User.context';
import {
  useCheckForUnsavedChanges,
  useContentCalendarView,
} from 'features/contentCalendarView';
import { FormattedContentIdeaPrimaryField } from 'features/contentIdea';
import {
  BillingPlanName,
  FieldType,
  useGetContentIdeaFieldsForContentCalendarSortQuery,
  useJoinContentCalendarViewForContentCalendarSortAndFilterButtonMutation,
} from 'graphql/generated';
import { useCallbackPrompt } from 'hooks/navigation/useCallbackPrompt';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { useImmer } from 'use-immer';
import { ContentCalendarFilterByOwnersButton } from '../filterByOwnersButton';
import { ContentCalendarFilterMenu, ContentCalendarSortMenu } from './options';
import { ApplyChangesPromptDialog } from './sections/manageViews/ApplyChangesPromptDialog';
import { ManageViewsSection } from './sections/manageViews/ManageViewsSection';
import { StyledIconContainer, StyledMenuItemContainer } from './styles';
import { ContentCalendarFilters, SelectedSort } from './types';
import { customSetSelectedFilters } from './utils';

// eslint-disable-next-line
gql`
  mutation JoinContentCalendarViewForContentCalendarSortAndFilterButton(
    $data: JoinContentCalendarViewInput!
  ) {
    joinContentCalendarView(data: $data) {
      id
    }
  }
`;

export type ContentCalendarSortAndFilterButtonProps = {
  onChange: (sort: SelectedSort, filters: ContentCalendarFilters) => void;
};

export const ContentCalendarSortAndFilterButton = (
  props: ContentCalendarSortAndFilterButtonProps,
) => {
  const { onChange } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const contentCalendarViewId = params.get('view') || '';

  const { data: contentIdeaFields } =
    useGetContentIdeaFieldsForContentCalendarSortQuery({
      variables: {
        filters: {
          query: FormattedContentIdeaPrimaryField.Status,
          isPrimaryField: true,
        },
      },
    });
  const statusDataDataField = contentIdeaFields?.contentIdeaFields[0] || {
    id: '',
    name: FormattedContentIdeaPrimaryField.Status,
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, orgBilling } = useUserContext();

  const isEnterpriseUser = useMemo(() => {
    return orgBilling && orgBilling.plan === BillingPlanName.Enterprise;
  }, [orgBilling]);

  const defaultFilters = {
    Organizations: {
      name: 'Organizations',
      type: FieldType.MultiSelect,
      selected: user ? [user.organization.id] : [],
    },
  };
  const defaultSort = {
    name: '',
    value: null,
  };

  const [selectedFilters, setSelectedFilters] =
    useImmer<ContentCalendarFilters>(defaultFilters);
  const [selectedSort, setSelectedSort] = useImmer<SelectedSort>(defaultSort);

  const { setRenderTitle } = useCustomHeaderContext();

  const { contentCalendarView } = useContentCalendarView(contentCalendarViewId);
  const [joinContentCalendarView] =
    useJoinContentCalendarViewForContentCalendarSortAndFilterButtonMutation();

  /**
   * When a content calendar view is being used,
   * set the selected filters, and re-render the page's header.
   */
  useEffect(() => {
    if (!contentCalendarView) {
      setSelectedFilters(defaultFilters);
      setSelectedSort(defaultSort);
    }

    if (contentCalendarView) {
      const viewFilters = JSON.parse(contentCalendarView.filters);
      setSelectedFilters(viewFilters?.selectedFilters || {});
      setSelectedSort(viewFilters?.selectedSort || { id: '', value: null });

      setTimeout(() => {
        setRenderTitle(() => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              variant="headline-sm"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: theme.colors?.utility[700],
              }}
            >
              📆 Content Calendar
            </Typography>
            {!isEnterpriseUser && (
              <Box
                sx={{
                  padding: theme.spacing(1, 2),
                  ...theme.typography['headline-xs'],
                  color: theme.colors?.utility['pink-4'],
                  borderRadius: 25,
                  background: theme.colors?.utility['pink-1'],
                  width: 'fit-content',
                }}
              >
                Limited Time Beta
              </Box>
            )}
            <Typography variant="headline-sm">·</Typography>
            <Typography variant="headline-sm">
              {contentCalendarView.name}
            </Typography>
          </Box>
        ));
      }, 300);

      // Also join the content calendar view by default
      joinContentCalendarView({
        variables: {
          data: {
            contentCalendarViewId,
          },
        },
      });
    }

    return () => {
      if (location.pathname === PlotRoutes.contentCalendar()) {
        setRenderTitle(() => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              variant="headline-sm"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: theme.colors?.primary.black,
              }}
            >
              📆 Content Calendar
            </Typography>
            {!isEnterpriseUser && (
              <Box
                sx={{
                  padding: theme.spacing(1, 2),
                  ...theme.typography['headline-xs'],
                  color: theme.colors?.utility['pink-4'],
                  borderRadius: 25,
                  background: theme.colors?.utility['pink-1'],
                  width: 'fit-content',
                }}
              >
                Limited Time Beta
              </Box>
            )}
          </Box>
        ));
      }
    };
  }, [contentCalendarView?.name, isEnterpriseUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeNumberFiltersAndSort, setActiveNumberFiltersAndSort] =
    useState<number>(0);

  // update CTA for number of active filters and sort
  useEffect(() => {
    let active = 0;

    Object.keys(selectedFilters).forEach((filter) => {
      if (
        (selectedFilters[filter]?.selected || []).length > 0 ||
        selectedFilters[filter]?.label
      ) {
        active++;
      }
    });

    if (selectedSort.value) {
      active++;
    }

    setActiveNumberFiltersAndSort(active);
    onChange(selectedSort, selectedFilters);
  }, [selectedFilters, selectedSort]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClearFilterAndSort = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setSelectedFilters({});

    setSelectedSort({
      name: '',
      value: null,
    });

    params.delete('view');
    navigate(
      { ...location, search: params.toString() },
      { replace: true, state: location.state },
    );
  };

  const renderRadioButton = (checked?: boolean) => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {checked ? (
          <IconBoldTickCircle size={20} />
        ) : (
          <Box
            component="span"
            sx={{
              margin: '2px',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              border: `solid 1px ${theme.colors?.primary.black}`,
            }}
          />
        )}
      </Box>
    );
  };

  /**
   * Handle unsaved changes from views section
   */

  const [isSavingConfiguration, setIsSavingConfiguration] = useState(false);

  const initialFilters = useMemo(() => {
    if (contentCalendarView?.filters) {
      return JSON.parse(contentCalendarView.filters);
    }

    return {
      selectedFilters,
      selectedSort,
    };
  }, [contentCalendarView?.filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const { hasUnsavedChanges } = useCheckForUnsavedChanges(initialFilters, {
    selectedFilters,
    selectedSort,
  });
  const {
    showPrompt: showPromptForApplyingViewChanges,
    confirmNavigation,
    cancelNavigation,
  } = useCallbackPrompt(
    hasUnsavedChanges &&
      Boolean(contentCalendarViewId) &&
      !isSavingConfiguration,
  );

  // ---

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <ContentCalendarFilterByOwnersButton
          // @ts-ignore
          selectedUserIds={selectedFilters.Owners?.selected || []}
          selectedOrganizationIds={
            // @ts-ignore
            selectedFilters.Organizations?.selected || []
          }
          onSelectUser={(selectedUserId) => {
            customSetSelectedFilters({
              setSelectedFilters,
              fieldType: FieldType.Users,
              fieldName: 'Owners',
              value: selectedUserId,
            });
          }}
          onSelectOrganization={(selectedOrganizationId) => {
            customSetSelectedFilters({
              setSelectedFilters,
              fieldType: FieldType.MultiSelect,
              fieldName: 'Organizations',
              value: selectedOrganizationId,
            });
          }}
        />

        <Button
          onClick={handleClick}
          sx={{
            bgcolor: theme.colors?.utility[300],
            borderRadius: theme.spacing(2),
            color: theme.colors?.utility[900],
            p: 2,
            ':hover': {
              bgcolor: theme.colors?.utility[300],
            },
          }}
          size="small"
          startIcon={
            <IconBoldSetting5 size={12} color={theme.colors?.utility[700]} />
          }
          endIcon={
            (contentCalendarView || activeNumberFiltersAndSort > 0) && (
              <IconOutlineCrossXClose
                size={12}
                onClick={handleClearFilterAndSort}
              />
            )
          }
        >
          {contentCalendarView
            ? contentCalendarView.name
            : activeNumberFiltersAndSort > 0
            ? `${activeNumberFiltersAndSort} active`
            : 'Sort & Filter'}
        </Button>
      </Box>

      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          sx: {
            p: '8px !important',
            width: 266,
          },
        }}
      >
        <ContentCalendarFilterMenu
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />

        <ContentCalendarSortMenu
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
        />

        <ManageViewsSection
          selectedFilters={{
            selectedFilters,
            selectedSort,
          }}
          onToggleSavingConfiguration={(v) => setIsSavingConfiguration(v)}
        />

        <Divider
          sx={{
            width: 'calc(100% - 24px)',
            mx: 'auto',
            my: 2,
          }}
        />

        <RadioMenuItem
          value="Cancelled"
          disableRipple
          label={
            <StyledMenuItemContainer>
              <StyledIconContainer>
                <IconOutlineCloseCircle size={16} />
              </StyledIconContainer>

              <Typography variant="subhead-lg">Show Cancelled Ideas</Typography>
            </StyledMenuItemContainer>
          }
          renderRadio={renderRadioButton}
          radioPosition="end"
          checked={(
            selectedFilters[statusDataDataField.name]?.selected || []
          ).some((s) => s === 'Cancelled')}
          onClick={() => {
            customSetSelectedFilters({
              setSelectedFilters,
              fieldType: FieldType.Select,
              fieldName: 'Status',
              value: 'Cancelled',
            });
          }}
        />
      </Menu>
      <ApplyChangesPromptDialog
        selectedFilters={{
          selectedFilters,
          selectedSort,
        }}
        open={showPromptForApplyingViewChanges}
        onAfterSave={() => {
          cancelNavigation();
        }}
        onClose={() => {
          confirmNavigation();
        }}
      />
    </>
  );
};
