import { makeStyles } from '@mui/styles';
import { theme } from 'styles/theme/theme';
import { typography } from 'components/common/Typography/styles';

export const useStyles = makeStyles({
  // Shared styles for date-related components
  default: {
    '& .MuiPickersDay-today': {
      backgroundColor: theme.colors?.secondary['pale-maroon'],
      border: '0 !important',
    },
    '& .Mui-selected': {
      backgroundColor: `${theme.colors?.primary.maroon} !important`,
      '&:focus': {
        backgroundColor: theme.colors?.primary.maroon,
      },
      '&:hover': {
        backgroundColor: theme.colors?.primary.maroon,
      },
    },
    '& .MuiButtonBase-root': {
      ...typography['body-lg'],
    },
  },
  // Styles for DatePicker
  date: {
    '& .MuiPickersCalendarHeader-root': {
      position: 'relative',
    },
    '& .MuiPickersCalendarHeader-labelContainer': {
      margin: 'auto',
    },
    '& .MuiPickersCalendarHeader-switchViewButton': {
      display: 'none',
    },
    '& .MuiPickersArrowSwitcher-root': {
      position: 'absolute',
      width: 'calc(100% - 48px)',
      display: 'flex',
      justifyContent: 'space-between',
      '& svg': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiDayPicker-header': {
      borderBottom: `1px solid ${theme.colors?.utility[300]}`,
    },
    '& .MuiDayPicker-weekDayLabel': {
      ...typography['body-xs'],
    },
    '& .MuiPickersCalendarHeader-label': {
      ...typography['headline-md'],
    },
    '& .MuiPickersDay-dayOutsideMonth': {
      color: theme.colors?.utility[700],
    },
  },
  // Styles for DateRangePicker
  dateRange: {
    '& .MuiPickersArrowSwitcher-root': {
      '& .MuiTypography-root': {
        ...typography['headline-md'],
      },
      '& svg': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiDayCalendar-weekDayLabel': {
      ...typography['body-xs'],
    },
    '& .MuiButtonBase-root': {
      border: 'none !important',
    },
    '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
      backgroundColor: theme.colors?.primary.maroon,
      '& .MuiDateRangePickerDay-dayInsideRangeInterval:not(.MuiPickersDay-today)':
        {
          color: theme.colors?.primary.white,
        },
      '&.MuiDateRangePickerDay-outsideCurrentMonth': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiDayCalendar-header': {
      borderBottom: `1px solid ${theme.colors?.utility[300]}`,
    },
  },
  // Styles for MonthPicker
  month: {
    width: 360,
    padding: 16,
    '& .MuiMonthPicker-root': {
      width: '100%',
      padding: '0 4px',
      margin: 0,
    },
    '& .MuiPickersCalendarHeader-root': {
      display: 'none',
    },
    '& .PrivatePickersMonth-root': {
      ...typography['body-lg'],
      'aspect-ratio': '1/1',
      flex: '1 0 25%',
      margin: 0,
      borderRadius: 24,
      height: 48,
      marginBottom: 10,
      textTransform: 'uppercase',
      fontWeight: 500,
    },
  },
});
