import { Box, SxProps } from '@mui/material';
import { Editor } from '@tiptap/react';
import { IconCustomHeading1 } from 'components/icons/components/custom/IconCustomHeading1';
import { IconCustomHeading2 } from 'components/icons/components/custom/IconCustomHeading2';
import { IconCustomHeading3 } from 'components/icons/components/custom/IconCustomHeading3';
import { IconLinearMessage } from 'components/icons/components/linear/IconLinearMessage';
import { IconOutlineBold } from 'components/icons/components/outline/IconOutlineBold';
import { IconOutlineBulletedList } from 'components/icons/components/outline/IconOutlineBulletedList';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { IconOutlineNumberedList } from 'components/icons/components/outline/IconOutlineNumberedList';
import { IconOutlineStrikethrough } from 'components/icons/components/outline/IconOutlineStrikethrough';
import { IconOutlineTask } from 'components/icons/components/outline/IconOutlineTask';
import { IconOutlineUnderline } from 'components/icons/components/outline/IconOutlineUnderline';
import { MOBILE_WIDTH_MD } from 'constants/mediaSizes';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { theme } from 'styles/theme/theme';
import { NoteRichTextEditorMode } from '../../types';
import { HyperlinkDialog } from './components/HyperlinkDialog';
import { StyledIconButton } from './styles';

export type BubbleToolBarProps = {
  sx?: SxProps;
  editor: Editor | null;
  mode?: NoteRichTextEditorMode;
};

export const BubbleToolBar = (props: BubbleToolBarProps) => {
  const { sx, editor, mode = NoteRichTextEditorMode.Full } = props;

  const isStrike = editor?.isActive('strike');
  const isBold = editor?.isActive('bold');
  const isUnderline = editor?.isActive('underline');

  const isMobileView = useMediaQueryMobile(MOBILE_WIDTH_MD);

  const iconSize = isMobileView ? 20 : 24;

  const options =
    mode === NoteRichTextEditorMode.Full
      ? [
          {
            icon: (
              <StyledIconButton>
                <IconOutlineBold size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () => editor?.chain().focus().toggleBold().run(),
            enabled: isBold,
          },
          {
            icon: (
              <StyledIconButton>
                <IconOutlineStrikethrough size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () => editor?.chain().focus().toggleStrike().run(),
            enabled: isStrike,
          },
          {
            icon: (
              <StyledIconButton>
                <IconOutlineUnderline size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () => editor?.chain().focus().toggleUnderline().run(),
            enabled: isUnderline,
          },
          {
            icon: (
              <StyledIconButton>
                <HyperlinkDialog editor={editor}>
                  <IconOutlineLink2 size={iconSize} />
                </HyperlinkDialog>
              </StyledIconButton>
            ),
            onClick: () => {},
            enabled: isUnderline,
          },
          {
            icon: (
              <Box
                sx={{
                  width: theme.spacing(0.5),
                  height: theme.spacing(6),
                  bgcolor: theme.colors?.utility[1000],
                  opacity: 0.15,
                  borderRadius: theme.spacing(1),
                }}
              />
            ),
          },
          {
            icon: (
              <StyledIconButton>
                <IconCustomHeading1 size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () =>
              editor?.chain().focus().toggleHeading({ level: 1 }).run(),
          },
          {
            icon: (
              <StyledIconButton>
                <IconCustomHeading2 size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () =>
              editor?.chain().focus().toggleHeading({ level: 2 }).run(),
          },
          {
            icon: (
              <StyledIconButton>
                <IconCustomHeading3 size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () =>
              editor?.chain().focus().toggleHeading({ level: 3 }).run(),
          },
          {
            icon: (
              <StyledIconButton tooltip="Task list">
                <IconOutlineTask size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () => editor?.chain().focus().toggleTaskList().run(),
          },
          {
            icon: (
              <StyledIconButton tooltip="Bulleted list">
                <IconOutlineBulletedList size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () => editor?.chain().focus().toggleBulletList().run(),
          },
          {
            icon: (
              <StyledIconButton tooltip="Numbered list">
                <IconOutlineNumberedList size={iconSize} />
              </StyledIconButton>
            ),
            onClick: () => editor?.chain().focus().toggleOrderedList().run(),
          },
          {
            icon: (
              <Box
                sx={{
                  width: theme.spacing(0.5),
                  height: theme.spacing(6),
                  bgcolor: theme.colors?.utility[1000],
                  opacity: 0.15,
                  borderRadius: theme.spacing(1),
                }}
              />
            ),
          },
        ]
      : [];

  options.push({
    icon: (
      <StyledIconButton tooltip="Add comment">
        <IconLinearMessage size={iconSize} />
      </StyledIconButton>
    ),
    onClick: () => editor?.chain().focus().addComment().run(),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        px: 4,
        py: 2.5,
        overflowX: 'auto',
        borderRadius: isMobileView ? 0 : theme.spacing(3),
        color: theme.colors?.utility[900],
        bgcolor: isMobileView
          ? 'rgba(250, 243, 236, 0.40)'
          : theme.colors?.utility[300],
        backdropFilter: 'blur(25px)',
        gap: theme.spacing(6),
        border: isMobileView
          ? `1.5px solid rgba(15, 15, 15, 0.10)`
          : `1px solid rgba(15, 15, 15, 0.01)`,
        maxWidth: '90vw',
        ...sx,
      }}
    >
      {options.map((item, idx) => {
        return (
          <Box
            key={idx}
            onClick={item?.onClick}
            sx={{
              cursor: 'pointer',
              ...(isMobileView
                ? {
                    '& svg': {
                      color: theme.colors?.utility[1000],
                    },
                  }
                : {}),
            }}
          >
            {item.icon}
          </Box>
        );
      })}
    </Box>
  );
};
