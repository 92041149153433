import { IconButton, Menu, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearDollarCircle } from 'components/icons/components/linear/IconLinearDollarCircle';
import { IconLinearSecurityUser } from 'components/icons/components/linear/IconLinearSecurityUser';
import {
  GenderDemographicsMap,
  GenerationDemographicsMap,
} from 'features/socialListeningAnalytics/constants';
import {
  BrandInboundFiltersInput,
  SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragment,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
} from 'graphql/generated';
import { useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  SLAAnalyticsFilterByCategory,
  SLAAnalyticsFilterByDemographics,
  SLAAnalyticsFilterBySource,
} from '../slaAnalyticsFilters';

type SLAAnalyticsSelectedFilterItemType = 'category' | 'demographic' | 'source';

interface SLAAnalyticsSelectedFilterItemProps {
  selectedFilters: Pick<
    BrandInboundFiltersInput,
    'signalCategoryOptionIds' | 'signalDefinitionIds' | 'sources'
  > & {
    gender?: SocialPostGenderDemographic[];
    generation?: SocialPostGenerationDemographic[];
  };
  onChange: (
    filters: Omit<
      Pick<
        BrandInboundFiltersInput,
        'signalCategoryOptionIds' | 'signalDefinitionIds' | 'sources'
      > & {
        gender?: SocialPostGenderDemographic[];
        generation?: SocialPostGenerationDemographic[];
      },
      'contentType'
    >,
  ) => void;
  type: SLAAnalyticsSelectedFilterItemType;
  signalDefinitions?: SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragment[];
}

export const SLAAnalyticsSelectedFilterItem = ({
  onChange,
  selectedFilters,
  type,
  signalDefinitions = [],
}: SLAAnalyticsSelectedFilterItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedFiltersLabel = useMemo(() => {
    if (type === 'demographic') {
      const genderLabels =
        selectedFilters.gender?.map((gender) => {
          return GenderDemographicsMap[gender];
        }) || [];
      const generationLabels =
        selectedFilters.generation?.map((generation) => {
          return GenerationDemographicsMap[generation];
        }) || [];
      return [...genderLabels, ...generationLabels].join(', ');
    }
    if (type === 'source') {
      return selectedFilters.sources?.join(', ');
    }
    if (type === 'category') {
      const categoryTitle =
        selectedFilters.signalDefinitionIds
          ?.map((id) => {
            const category = signalDefinitions.find((c) => c.id === id);
            return category ? category.title : null;
          })
          .filter(Boolean) ?? []; // Filter out any null values

      const categoryOptionTitle =
        selectedFilters.signalCategoryOptionIds
          ?.map((id) => {
            const option = signalDefinitions
              .flatMap((c) => c.options.find((option) => option.id === id))
              .find(Boolean);
            return option ? option.label : null;
          })
          .filter(Boolean) ?? [];

      return [...categoryTitle, ...categoryOptionTitle].join(', ');
    }
    return '';
  }, [
    selectedFilters.gender,
    selectedFilters.generation,
    selectedFilters.signalCategoryOptionIds,
    selectedFilters.signalDefinitionIds,
    selectedFilters.sources,
    signalDefinitions,
    type,
  ]);

  const Icon = useMemo(
    () =>
      type === 'category'
        ? IconCustomSparkles
        : type === 'demographic'
        ? IconLinearSecurityUser
        : IconLinearDollarCircle,
    [type],
  );

  const getOptionComponent = useCallback(() => {
    const variant = 'normal';
    const renderTitle = (title: string) => {
      return (
        // eslint-disable-next-line
        <>
          <Typography variant="subhead-lg">{title}</Typography>
        </>
      );
    };
    if (type === 'demographic') {
      return (
        <SLAAnalyticsFilterByDemographics
          selectedGender={selectedFilters.gender || []}
          selectedGeneration={selectedFilters.generation || []}
          onChange={({ selectedGender, selectedGeneration }) => {
            onChange({
              ...selectedFilters,
              gender: selectedGender,
              generation: selectedGeneration,
            });
          }}
          variant={variant}
          renderTitle={() => renderTitle('Demographics')}
        />
      );
    }
    if (type === 'source') {
      return (
        <SLAAnalyticsFilterBySource
          onChange={(sources) => {
            onChange({ ...selectedFilters, sources });
          }}
          selectedSources={selectedFilters.sources || []}
          variant={variant}
          renderTitle={() => renderTitle('Sources')}
        />
      );
    }
    if (type === 'category') {
      return (
        <SLAAnalyticsFilterByCategory
          onChange={(selectedCategoryIds, selectedCategoryOptionIds) => {
            onChange({
              ...selectedFilters,
              signalDefinitionIds: selectedCategoryIds,
              signalCategoryOptionIds: selectedCategoryOptionIds,
            });
          }}
          signalDefinitions={signalDefinitions}
          selectedCategoryIds={selectedFilters.signalDefinitionIds || []}
          selectedCategoryOptionIds={
            selectedFilters.signalCategoryOptionIds || []
          }
          variant={variant}
          renderTitle={() => renderTitle('Categories')}
        />
      );
    }
    return null;
  }, [JSON.stringify({ onChange, selectedFilters, signalDefinitions, type })]); // eslint-disable-line

  const onRemoveFilter = () => {
    let updatedFilters = { ...selectedFilters };

    const filterResetMap = {
      category: { signalCategoryOptionIds: [], signalDefinitionIds: [] },
      source: { sources: [] },
      demographic: { gender: [], generation: [] },
    };

    // Reset the relevant filters based on the type
    if (filterResetMap[type]) {
      updatedFilters = { ...updatedFilters, ...filterResetMap[type] };
    }

    onChange(updatedFilters);
  };

  return (
    <>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'center',
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(8),
          p: theme.spacing(1, 2),
          maxWidth: '100%',
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Icon
          size={16}
          color={theme.colors?.utility[900]}
          style={{
            flexShrink: 0,
          }}
        />
        <Typography variant="headline-xs" color={theme.colors?.utility[800]}>
          {selectedFiltersLabel}
        </Typography>
        <IconButton
          sx={{
            p: 0,
            color: theme.colors?.utility[600],
          }}
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            onRemoveFilter();
          }}
        >
          <IconBoldCloseCircle size={16} color={theme.colors?.utility[600]} />
        </IconButton>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 244,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            border: 'none',
            maxHeight: '60vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
            },
          },
        }}
      >
        {getOptionComponent()}
      </Menu>
    </>
  );
};
