import { Box, Typography } from '@mui/material';
import { MultipleTagInput } from 'components/common/MultipleTagInput';
import { typography } from 'components/common/Typography/styles';
import { useBrandDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks/onboarding';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingBrandKnownNamesProps = {
  brandId?: string;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingBrandKnownNames = ({
  brandId,
  onNext,
}: SocialMediaListeningOnboardingBrandKnownNamesProps) => {
  const { onboardingActions } = useBrandDataForSocialListeningOnboarding({
    brandId,
  });

  const [brandKnownNames, setBrandKnownNames] = useState<string[]>([]);

  const onSubmit = async () => {
    await onboardingActions.handleBrandKnownNamesUpdate(brandKnownNames);
    onNext();
  };

  return (
    <Box
      position="relative"
      width="60vw"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
        Does your brand use any alternative names or aliases?
      </Typography>
      <Typography variant="subhead-xl">
        This will help us track your brand holistically, including all possible
        variations across TikTok and Instagram.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          width: '100%',
          mt: 3,
          py: `${theme.spacing(6)} !important`,
          px: `${theme.spacing(6)} !important`,
          bgcolor: 'rgba(255, 255, 255, 0.10)',
          color: theme.colors?.primary.parchment,
          borderRadius: 4,
        }}
      >
        <MultipleTagInput
          value={brandKnownNames}
          onChange={setBrandKnownNames}
          componentsProps={{
            chip: {
              variant: 'filled-borderless',
              sx: {
                color: theme.colors?.primary.parchment,
                bgcolor: '#D9E3F34D !important',
                '&:hover': {
                  bgcolor: '#D9E3F380 !important',
                },
                span: {
                  ...typography['headline-md'],
                  color: theme.colors?.primary.parchment,
                },
              },
            },
            input: {
              autoFocus: true,
              placeholder:
                brandKnownNames.length === 0
                  ? 'enter an alternative name or aliases'
                  : 'add more',
              sx: {
                ...typography['headline-md'],
                color: theme.colors?.primary.parchment,
              },
            },
          }}
        />
      </Box>

      <RenderNextButton
        loading={onboardingActions.savingBrandData}
        onNextClick={onSubmit}
      />
    </Box>
  );
};
