import * as Yup from 'yup';

export const registerValidation = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
    .email('Please Enter valid email!')
    .label('Email'),
  firstName: Yup.string().trim().required().label('First Name'),
  lastName: Yup.string().trim().required().label('Last Name'),
  password: Yup.string()
    .required()
    .min(8, 'Password must be longer than or equal to 8 characters!')
    .label('Password'),
  userRoleDescription: Yup.array()
    .of(Yup.string().required())
    .min(1, 'Please select at least one role!')
    .label('User Role Description'),
});

export const loginValidation = Yup.object().shape({
  email: Yup.string().trim().required().label('Email'),
  password: Yup.string().required().label('Password'),
});

export const forgotPasswordValidation = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required()
    .email('Please Enter valid email!')
    .label('Email'),
});

export const resetPasswordValidation = Yup.object().shape({
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .label('Confirm Password'),
  password: Yup.string()
    .required()
    .min(8, 'Password must be longer than or equal to 8 characters!')
    .label('Password'),
});

export const emailValidation = Yup.string().email('Invalid email address');
