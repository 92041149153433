import { useEffect, useRef, useState } from 'react';
import { CarouselMedia } from 'graphql/generated';
import { Carousel } from './Carousel';

export const useCarousel = (medias: CarouselMedia[]) => {
  const carouselRef = useRef<HTMLElement>(null);
  const [renderedHeight, setRenderedHeight] = useState(0);

  // On swiper element resize recalculate height
  useEffect(() => {
    if (carouselRef.current) {
      setRenderedHeight(carouselRef.current.offsetHeight);

      const resizeObserver = new ResizeObserver((entries) => {
        setRenderedHeight(entries[0].contentRect.height);
      });
      resizeObserver.observe(carouselRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  // On window change resize recalculate height
  useEffect(() => {
    const handleResize = () => {
      if (carouselRef.current) {
        setRenderedHeight(carouselRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    renderedHeight,
    renderCarousel: () => <Carousel ref={carouselRef} medias={medias} />,
  };
};
