/**
 * This component renders a preview UI by using Iframely's iframe.
 * It contains a logic to listen to messages sent by Iframely's iframe for
 * some extra metadata like aspect-ratio, height (on resize), etc.
 */

import { Box, Skeleton, SxProps } from '@mui/material';
import { UrlMetadataFragmentIframelyFragment } from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { useIframelyAPI } from '../hooks/useIframelyAPI';
import { getCustomStyles } from '../utils';
import { NoPreviewUI, NoPreviewUIProps } from './NoPreviewUI';

export type IframelyEmbedWidgetProps = {
  url: string;
  urlMetadata?: UrlMetadataFragmentIframelyFragment;
  fullWidth?: boolean;
  componentsProps?: {
    noPreview?: Omit<NoPreviewUIProps, 'url'>;
  };
  onErrorOrNoPreview?: (error?: any) => void;
  onContentSizeUpdated?: (height: number, width: number) => void;
  sx?: SxProps;
  shouldShowSkeleton?: boolean;
  // this is used to pass isLoading state from here
  onLoadingContent?: (loading: boolean) => void;
};

export const IframelyEmbedWidget = (props: IframelyEmbedWidgetProps) => {
  const {
    url,
    urlMetadata,
    fullWidth,
    componentsProps = {},
    onErrorOrNoPreview,
    onContentSizeUpdated,
    sx = {},
    shouldShowSkeleton = true,
    onLoadingContent,
  } = props;

  const isMobileView = useMediaQueryMobile();

  const containerRef = useRef<HTMLDivElement>(null);
  const [iframeWidth, setIframeWidth] = useState<number | string>();
  const [iframeHeight, setIframeHeight] = useState<number | string>();

  useEffect(() => {
    onContentSizeUpdated?.(iframeHeight as number, iframeWidth as number);
  }, [iframeWidth, iframeHeight]);

  const site = urlMetadata?.metadata?.site || '';
  const medium = urlMetadata?.metadata?.medium || '';

  const { getUrlMetadata } = useIframelyAPI();
  const [html, setHtml] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<{ code: number; message: string } | null>(
    null,
  );
  const [hasNoPreviewUI, setHasNoPreviewUI] = useState(false);

  useEffect(() => {
    onLoadingContent?.(isLoading);
  }, [isLoading, onLoadingContent]);

  // Call iframely API to get the data we need (html) to render the embed UI
  useEffect(() => {
    if (url) {
      getUrlMetadata(url).then(
        (res) => {
          if (res.error) {
            setError({ code: res.error, message: res.message || '' });
          }

          if (res.html) {
            setHtml(res.html);
          } else {
            setIsLoading(false);
            setHasNoPreviewUI(true);
          }
        },
        (error) => {
          setError(error);
        },
      );
    } else {
      setError({ code: 400, message: 'Provide url attribute for the element' });
    }
  }, []); // eslint-disable-line -- run on mount

  useEffect(() => {
    // For dynamic & testing purpose, ignore the type
    if ((window as any).iframely) {
      (window as any).iframely.load();
    }

    // Listen to message sent by iframely self-hosted iframe
    // It contains the real height of the asynchronously loaded social media embed
    const onMessage = (event: MessageEvent) => {
      if (event.origin.includes('iframe.ly')) {
        const data = JSON.parse(event.data);

        // Do nothing if the message doesn't match current url
        if (data.url !== url) {
          return;
        }

        // If method is setIframelyEmbedData or resize, handle set iframe's sizes
        if (
          data.method === 'setIframelyEmbedData' ||
          data.method === 'resize'
        ) {
          let iframeWidth: number | string | undefined;
          let iframeHeight: number | string | undefined;
          let aspectRatio: number | undefined;

          // Right now we'll only get height from setIframelyEmbedData & resize method
          if (data.method === 'setIframelyEmbedData') {
            // Lift loading blocker when we receive this particular message from iframely's iframe
            setIsLoading(false);

            iframeHeight = data.data?.media?.height;
            aspectRatio = data.data?.media?.['aspect-ratio'] as number;

            // Sometimes height is not provided, but aspectRatio instead
            // So we need to calculate the height based on current container's WIDTH & the aspect ratio
            // However, in some cases like rendering a video, we might want to also want to take container's HEIGHT
            // into account, as we don't want the video to overflow the container vertically.
            // Overflowing makes sense for 3rd party embed like Tiktok, Instagram, etc. but for video best UX
            // would be rendering it wholely inside the container
            if (aspectRatio && !iframeHeight && containerRef.current) {
              iframeHeight = containerRef.current.clientWidth / aspectRatio;

              if (!site && !fullWidth) {
                iframeHeight = Math.min(
                  containerRef.current.clientHeight,
                  iframeHeight,
                );
              }

              iframeWidth = iframeHeight * aspectRatio;
            }
          } else if (data.method === 'resize') {
            iframeHeight = data.height as number;

            // If it's video, we don't want it to resize to sth more than container's height
            if (medium === 'video' && iframeHeight && containerRef.current) {
              iframeHeight = Math.min(
                containerRef.current.clientHeight,
                iframeHeight,
              );
            }
          }

          if (iframeHeight && !Number.isNaN(iframeHeight)) {
            setIframeHeight(iframeHeight);
          }

          if (iframeWidth && !Number.isNaN(iframeWidth)) {
            setIframeWidth(iframeWidth);
          }
        }

        // If method is open-href, handle open-href
        if (data.method === 'open-href') {
          window.open(data.href, '_blank');
        }
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [url, site, medium, fullWidth]);

  // Callback to inform parent component about the error
  // or the fact that Iframely cannot render a preview UI
  useEffect(() => {
    if (error || hasNoPreviewUI) {
      onErrorOrNoPreview?.(error);
    }
  }, [error, hasNoPreviewUI]); // eslint-disable-line

  if (error || hasNoPreviewUI) {
    return <NoPreviewUI url={url} {...componentsProps.noPreview} />;
  }

  return (
    <>
      <Box
        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: html }}
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
          overflow: 'auto',
          iframe: {
            border: 0,
            m: 'auto',
            // Refer to L109.
            width: fullWidth ? '100%' : iframeWidth,
            height: iframeHeight,
            ...getCustomStyles(site),
          },
          '.iframely-embed': {
            m: 'auto',
            width: '100%',
            height: '100%',
            minHeight: 'inherit',
          },
          '.iframely-responsive': {
            m: 'auto',
            display: 'flex',
            padding: '0 !important',
            width: isMobileView ? 'fit-content' : '100%',
            height: '100% !important',
            minHeight: 'inherit',
          },
          '.iframely-image': {
            maxWidth: 'unset !important',
          },
          ...sx,
        }}
      />
      {isLoading && shouldShowSkeleton && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            minHeight: 'inherit',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Skeleton
            sx={{
              width: theme.spacing(80),
              height: theme.spacing(120),
              bgcolor: theme.colors?.utility[400],
              transform: 'scale(1)',
            }}
          />
        </Box>
      )}
    </>
  );
};
