import { gql } from '@apollo/client';
import {
  ContentIdeaFieldValueFragmentContentIdeaFieldTextFragmentDoc,
  ContentIdeaFragmentContentIdeaCaptionFragment,
  ContentIdeaPermission,
  FieldType,
} from 'graphql/generated';
import { ContentIdeaField, TextFieldProps } from '../field';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_CAPTION = gql`
  fragment ContentIdeaFragmentContentIdeaCaption on ContentIdeaModel {
    id
    myPermissions
    caption {
      id
      contentIdeaField {
        id
      }
      ...ContentIdeaFieldValueFragmentContentIdeaFieldText
    }
  }
  ${ContentIdeaFieldValueFragmentContentIdeaFieldTextFragmentDoc}
`;

export type ContentIdeaCaptionProps = {
  contentIdea: ContentIdeaFragmentContentIdeaCaptionFragment;
  renderInput?: Omit<TextFieldProps, 'value' | 'onChange'>;
};

export const ContentIdeaCaption = (props: ContentIdeaCaptionProps) => {
  const { contentIdea, renderInput } = props;

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  return (
    <ContentIdeaField
      id={contentIdea.caption.contentIdeaField.id}
      valueId={contentIdea.caption.id}
      value={{
        text: contentIdea.caption.value?.text || '',
      }}
      type={FieldType.Text}
      placeholder={canEdit ? 'Add a caption...' : 'Empty'}
      readOnly={!canEdit}
      {...renderInput}
    />
  );
};
