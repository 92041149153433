import { gql } from '@apollo/client';
import { USER_FRAGMENT_AVATAR_GROUP } from 'components/common/AvatarGroup/types';

export const TASK_FRAGMENT_SEARCH_MODAL = gql`
  fragment TaskFragmentSearchModal on TaskModel {
    id
    name
    description
    taskMembers {
      ...UserFragmentAvatarGroup
    }
  }
  ${USER_FRAGMENT_AVATAR_GROUP}
`;

export const TASK_SEARCH_HIT_FRAGMENT_SEARCH_MODAL = gql`
  fragment TaskSearchHitFragmentSearchModal on TaskSearchHit {
    taskModel {
      ...TaskFragmentSearchModal
    }
    headline {
      column
      headline
    }
  }
  ${TASK_FRAGMENT_SEARCH_MODAL}
`;
