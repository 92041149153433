import { Box, Skeleton } from '@mui/material';

export const TrendingCardSkeletonView = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Skeleton variant="rounded" height={330} sx={{ borderRadius: 3 }} />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{ borderRadius: 24 }}
          height={30}
          width={130}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Skeleton variant="text" height={12} width={60} />
          <Skeleton variant="circular" height={24} width={24} />
        </Box>
      </Box>
      <Box>
        <Skeleton variant="text" height={18} width="100%" />
        <Skeleton variant="text" height={18} width="50%" />
      </Box>
    </Box>
  );
};
