import { OrganizationBillingFragmentTrendsPaywallFragment } from 'graphql/generated';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, SxProps, Typography } from '@mui/material';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingTrendsPaywallIndicatorProps = {
  organizationBilling: OrganizationBillingFragmentTrendsPaywallFragment;
  sx?: SxProps;
};

export const BillingTrendsPaywallIndicator = (
  props: BillingTrendsPaywallIndicatorProps,
) => {
  const { organizationBilling, sx } = props;

  const {
    isOpen: upgradeOpen,
    onOpen: upgradeOnOpen,
    onClose: upgradeOnClose,
  } = useDisclosure();

  return organizationBilling.trendsEnabled ? null : (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Typography variant="body-sm" sx={{ ...sx }}>
        <Typography
          variant="body-sm"
          sx={{
            fontWeight: 600,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={upgradeOnOpen}
        >
          {organizationBilling.hasUsedFreeTrial
            ? 'Upgrade Now'
            : 'Start Free Trial'}
        </Typography>{' '}
        for unlimited daily trends
      </Typography>

      <BillingUpgradeModal isOpen={upgradeOpen} onClose={upgradeOnClose} />
    </Box>
  );
};
