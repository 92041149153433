import { EditorState, Plugin, PluginKey, PluginView } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { Extension } from '@tiptap/react';
import { DropSide, MousePos } from 'features/tiptap/types';
import { throttle } from 'lodash';
import { theme } from 'styles/theme';
import { ATTR_BLOCK_ID, EXTENSION_DROPCURSOR } from '../constants';
import { getDroppedOnNode } from './utils';

export type DropcursorOptions = {
  /**
   * The color of the drop cursor
   * @default 'currentColor'
   * @example 'red'
   */
  color: string | undefined;

  /**
   * The width of the drop cursor
   * @default 1
   * @example 2
   */
  width: number | undefined;

  /**
   * The class of the drop cursor
   * @default undefined
   * @example 'drop-cursor'
   */
  class: string | undefined;
};

/**
 * This extension listens to drag event cursor position. Then it renders
 * and element that highlight where the user can drop according to the dragover
 * element DOMRect object (top, left, right, left, height, width)
 */
export const Dropcursor = Extension.create<DropcursorOptions>({
  name: EXTENSION_DROPCURSOR,

  addOptions() {
    return {
      color: 'currentColor',
      width: 1,
      class: undefined,
      allowedTypesForSidesDropping: {},
    };
  },

  addProseMirrorPlugins() {
    const dropcursorPlugin = new Plugin({
      key: new PluginKey('dropcursorPlugin'),
      view: (editorView): PluginView => {
        return new DropCursorView(editorView, this.options);
      },
    });

    return [dropcursorPlugin];
  },
});

class DropCursorView {
  width: number;
  color?: string;
  class?: string;

  mousePos: MousePos | null = null;
  docCursorPos: number | null = null;
  element: HTMLElement | null = null;
  dropSide: DropSide | null = null;

  timeout?: NodeJS.Timeout;
  handlers: { name: string; handler: (event: Event) => void }[];

  draggedOverBlockId: string | null = null;

  constructor(readonly editorView: EditorView, options: DropcursorOptions) {
    this.width = options.width ?? 2;
    this.color = options.color ?? theme.colors?.primary.black;
    this.class = options.class;

    // we overwrite these handlers
    this.handlers = ['dragover', 'dragend', 'drop', 'dragleave'].map((name) => {
      const handler = (e: Event) => {
        (this as any)[name](e);
      };

      editorView.dom.addEventListener(name, handler);
      return { name, handler };
    });
  }

  destroy() {
    this.handlers.forEach(({ name, handler }) =>
      this.editorView.dom.removeEventListener(name, handler),
    );
  }

  update(editorView: EditorView, prevState: EditorState) {
    if (this.docCursorPos != null && prevState.doc !== editorView.state.doc) {
      if (this.docCursorPos > editorView.state.doc.content.size) {
        this.setCursor(null);
        this.mousePos = null;
      } else {
        this.updateOverlay();
      }
    }
  }

  setCursor(pos: number | null) {
    this.docCursorPos = pos;

    if (pos == null && this.element) {
      this.element.parentNode?.removeChild(this.element);
      this.element = null;
    } else {
      this.updateOverlay();
    }
  }

  /**
   * This method creates the highlight element to highlight where the dragged element
   * is droppable.
   *
   * It gets the node from the current cursor's position. It then gets DOMRect value
   * to calculate where and how the highlight element should be displayed.
   */
  updateOverlay = throttle(this._updateOverlay, 100);
  _updateOverlay() {
    if (this.docCursorPos === null) {
      return;
    }

    const docPos = this.docCursorPos;
    const node = this.editorView.state.doc.nodeAt(docPos);
    const nodeDOM = this.editorView.nodeDOM(docPos);
    const dropSide = this.dropSide;

    if (!nodeDOM || !dropSide) {
      return;
    }

    if (node && ['left', 'right'].includes(dropSide)) {
      // Return & do nothing if node is not columnable
      if (node.attrs.columnable === 'false') {
        return;
      }
    }

    // Set data-block-id to tiptap meta for target that is being dragged over
    if (
      node &&
      node.isBlock &&
      node.attrs[ATTR_BLOCK_ID] &&
      node.attrs[ATTR_BLOCK_ID] !== this.draggedOverBlockId
    ) {
      this.draggedOverBlockId = node.attrs[ATTR_BLOCK_ID];

      this.editorView.dispatch(
        this.editorView.state.tr.setMeta(
          'draggedOverBlockId',
          node.attrs[ATTR_BLOCK_ID],
        ),
      );
    }

    const nodeRect = (nodeDOM as HTMLElement).getBoundingClientRect();
    const rect = {
      left: nodeRect.left,
      right: nodeRect.right,
      height: nodeRect.height,
      width: nodeRect.width,
      top: nodeRect.top,
      bottom: nodeRect.bottom,
    };

    const parent = this.editorView.dom.offsetParent as HTMLElement;

    if (!this.element) {
      /*
       * we initialize the highlight element here. This is an absolute element that
       * can be displayed on top of any element in this text editor
       */
      this.element = parent.appendChild(document.createElement('div'));
      if (this.class) {
        this.element.className = this.class;
      }

      this.element.style.cssText =
        'position: absolute; z-index: 50; pointer-events: none;';

      if (this.color) {
        this.element.style.backgroundColor = this.color;
      }
    }

    // we utilize ProseMirror predefined css for drop cursor
    this.element.classList.add('prosemirror-dropcursor-block');

    // now we get the DOMRect of the current node
    const domRect = parent.getBoundingClientRect();

    const parentScaleX = domRect.width / parent.offsetWidth;
    const parentScaleY = domRect.height / parent.offsetHeight;

    const parentLeft = domRect.left - parent.scrollLeft * parentScaleX;
    const parentRight = domRect.right - parent.scrollLeft * parentScaleX;
    const parentTop = domRect.top - parent.scrollTop * parentScaleY;
    const parentBottom = domRect.bottom - parent.scrollTop * parentScaleY;

    const editorDOM = this.editorView.dom;
    const editorRect = editorDOM.getBoundingClientRect();
    const scaleX = editorRect.width / editorDOM.offsetWidth;
    const scaleY = editorRect.height / editorDOM.offsetHeight;

    // based on the parent DOMRect to set the position of the highlight element
    const displayOffset = 8;
    switch (dropSide) {
      case 'left':
        this.element.style.width = `${this.width}px`;
        this.element.style.height = `${rect.height}px`;
        this.element.style.left = `${
          (rect.left - parentLeft) / scaleX - displayOffset
        }px`;
        this.element.style.right = 'unset';
        this.element.style.top = `${(rect.top - parentTop) / scaleY}px`;
        this.element.style.bottom = 'unset';

        break;
      case 'right':
        this.element.style.width = `${this.width}px`;
        this.element.style.height = `${rect.height}px`;
        this.element.style.left = 'unset';
        this.element.style.right = `${
          (parentRight - rect.right) / scaleX - displayOffset
        }px`;
        this.element.style.top = `${(rect.top - parentTop) / scaleY}px`;
        this.element.style.bottom = 'unset';

        break;
      case 'top':
        this.element.style.width = `${rect.width}px`;
        this.element.style.height = `${this.width}px`;
        this.element.style.left = `${(rect.left - parentLeft) / scaleX}px`;
        this.element.style.right = 'unset';
        this.element.style.top = `${
          (rect.top - parentTop) / scaleY - displayOffset
        }px`;
        this.element.style.bottom = 'unset';

        break;
      case 'bottom':
        this.element.style.width = `${rect.width}px`;
        this.element.style.height = `${this.width}px`;
        this.element.style.left = `${(rect.left - parentLeft) / scaleX}px`;
        this.element.style.right = 'unset';
        this.element.style.bottom = `${
          (parentBottom - rect.bottom) / scaleY - displayOffset
        }px`;
        this.element.style.top = 'unset';

        break;
      default: {
        break;
      }
    }
  }

  scheduleRemoval(timeout: number) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setCursor(null), timeout);
  }

  /**
   * When the user drags a node (A) over another node (B), we get the cursor of B
   * and determine which side of B that A is being dragged over.
   *
   * These values will help us to calculate the position of the highlight element
   *
   * @param event
   */
  dragover(event: DragEvent) {
    if (!this.editorView.editable) {
      return;
    }

    const { docPos, node, dropSide } = getDroppedOnNode(this.editorView, event);

    const disableDropCursor = node && node.type.spec.disableDropCursor;
    const disabled =
      typeof disableDropCursor === 'function'
        ? disableDropCursor(this.editorView, docPos, event)
        : disableDropCursor;

    if (docPos >= 0 && !disabled) {
      this.setCursor(docPos);
      this.scheduleRemoval(5000);
    }

    this.dropSide = dropSide || null;
  }

  dragend() {
    this.scheduleRemoval(20);
  }

  drop() {
    this.scheduleRemoval(20);
  }

  dragleave(event: DragEvent) {
    if (
      event.target === this.editorView.dom ||
      !this.editorView.dom.contains((event as any).relatedTarget)
    ) {
      this.setCursor(null);
    }
  }
}
