import { gql } from '@apollo/client';
import { Box, PopoverProps, SxProps } from '@mui/material';
import { SingleSelectFilter } from 'components/common/FilterSelection';
import { SingleSelectFilterProps } from 'components/common/MultiSelectFilter/types';
import { taskPriorityText } from 'features/task/constants';
import {
  TaskFragmentTaskPriorityComponentFragment,
  TaskPriority as TaskPriorityType,
  useUpdateTaskForTaskPriorityComponentMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { TaskPriorityButton } from './TaskPriorityButton';
import { TaskPriorityLabel } from './TaskPriorityLabel';

export const TASK_FRAGMENT_TASK_PRIORITY = gql`
  fragment TaskFragmentTaskPriorityComponent on TaskModel {
    id
    priority
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskPriorityComponent($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      ...TaskFragmentTaskPriorityComponent
    }
  }
  ${TASK_FRAGMENT_TASK_PRIORITY}
`;

const taskPriorities = [
  TaskPriorityType.Low,
  TaskPriorityType.Medium,
  TaskPriorityType.High,
];

export const taskPriorityOptions: SingleSelectFilterProps['options'] =
  taskPriorities.map((priority) => ({
    label: `${taskPriorityText[priority]} ${priority}`,
    render: () => (
      <Box m={theme.spacing(4, 2)}>
        <TaskPriorityLabel priority={priority as TaskPriorityType} />
      </Box>
    ),
    value: priority,
  }));

export type TaskPriorityProps = {
  task: TaskFragmentTaskPriorityComponentFragment;
  variant?: 'label' | 'button';
  sx?: SxProps;
  componentsProps?: {
    popover?: Partial<PopoverProps>;
    icon?: {
      size?: number;
      color?: string;
    };
  };
  renderTrigger?: () => React.ReactElement;
  onChange?: (value: TaskPriorityType) => void;
  readOnly?: boolean;
};

export const TaskPriority = (props: TaskPriorityProps) => {
  const {
    task,
    variant = 'label',
    sx,
    componentsProps = {},
    renderTrigger,
    onChange: _onChange,
    readOnly,
  } = props;

  const [updateTask] = useUpdateTaskForTaskPriorityComponentMutation();

  const onChange =
    _onChange ||
    ((priority: TaskPriorityType) => {
      updateTask({
        variables: {
          data: {
            taskId: task.id,
            data: {
              priority,
            },
          },
        },
        optimisticResponse: {
          updateTask: {
            ...task,
            priority,
          },
        },
      });
    });

  return (
    <SingleSelectFilter
      placeholder="Search by priority"
      key={task.id}
      readOnly={readOnly}
      renderTrigger={() =>
        renderTrigger ? (
          renderTrigger()
        ) : variant === 'label' ? (
          <TaskPriorityLabel
            priority={task.priority}
            sx={sx}
            useShortLabel
            componentsProps={{
              icon: componentsProps?.icon,
            }}
          />
        ) : (
          <TaskPriorityButton
            priority={task.priority}
            sx={sx}
            componentsProps={{
              icon: componentsProps?.icon,
            }}
          />
        )
      }
      options={taskPriorityOptions}
      onFilterUpdated={(priority) => onChange(priority as TaskPriorityType)}
      popoverProps={componentsProps.popover}
    />
  );
};
