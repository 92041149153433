import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { PostPreview } from 'features/post';
import {
  CollectionFragmentCollectionThumbnailFragment,
  PostFragmentPostPreviewFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_COLLECTION_THUMBNAIL = gql`
  fragment PostFragmentCollectionThumbnail on PostModel {
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentCollectionThumbnail on CollectionModel {
    id
    hasPreviewPost
    previewPost {
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

type CollectionThumbnailProps = {
  collection: CollectionFragmentCollectionThumbnailFragment;
  disableMouseEvents?: boolean;
  sx?: SxProps;
  showEmptyText?: boolean;
};

const Colors = [
  {
    backgroundColor: theme.colors?.utility['purple-1'],
    color: theme.colors?.utility['purple-4'],
  },
  {
    backgroundColor: theme.colors?.utility['teal-1'],
    color: theme.colors?.utility['teal-4'],
  },
  {
    backgroundColor: theme.colors?.utility['orange-1'],
    color: theme.colors?.utility['orange-4'],
  },
  {
    backgroundColor: theme.colors?.utility['yellow-1'],
    color: theme.colors?.utility['yellow-4'],
  },
];
const generateRandomCollectionColor = (id: string) => {
  const num = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return Colors[num % Colors.length];
};

export const CollectionThumbnail = (props: CollectionThumbnailProps) => {
  const {
    collection,
    sx = {},
    disableMouseEvents,
    showEmptyText = true,
  } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        aspectRatio: '4 / 3',
        display: 'flex',
        width: '100%',
        height: '100%',
        pointerEvents: disableMouseEvents ? 'none' : 'auto',
      }}
    >
      {disableMouseEvents && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            minHeight: 'inherit',
          }}
        />
      )}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 2,
          overflow: 'hidden',
          objectFit: 'cover',
          objectPosition: 'center',
          ...sx,
        }}
      >
        {collection.previewPost ? (
          <>
            <PostPreview
              key={collection.previewPost.id}
              post={collection.previewPost}
              sx={{ borderRadius: 'none' }}
            />
            <IconLinearNote />
          </>
        ) : (
          <Box
            width="100%"
            height="100%"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: generateRandomCollectionColor(collection.id)
                .backgroundColor,
              color: generateRandomCollectionColor(collection.id).color,
            }}
          >
            {showEmptyText && (
              <Typography variant="body-lg">No posts yet</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
