import { gql } from '@apollo/client';
import { createContext } from '@dwarvesf/react-utils';
import { PlotRoutes } from 'Routes';
import { GenerateId } from 'utils/generateId';
import { TaskFragmentTaskDetailViewNestedTaskNavigationContextFragment } from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';

export const TASK_FRAGMENT_TASK_DETAIL_VIEW_NESTED_TASK_NAVIGATION_CONTEXT = gql`
  fragment TaskFragmentTaskDetailViewNestedTaskNavigationContext on TaskModel {
    id
    name
  }
`;

export type LocationStackItem = {
  task: TaskFragmentTaskDetailViewNestedTaskNavigationContextFragment;
  location: any;
};

export type ContextValues = {
  locationStack: LocationStackItem[];
  navigateToIndex: (index: number) => void;
  pushToStack: (
    currentTask: TaskFragmentTaskDetailViewNestedTaskNavigationContextFragment,
    nextTask: TaskFragmentTaskDetailViewNestedTaskNavigationContextFragment,
  ) => void;
  goBackInStack: () => void;
};

const [Provider, useNestedTaskNavigationContext] =
  createContext<ContextValues>();

export type NestedTaskNavigationContextProviderProps = PropsWithChildren & {
  onGoBackOutOfStack?: () => void;
};

export const NestedTaskNavigationContextProvider = (
  props: NestedTaskNavigationContextProviderProps,
) => {
  const { children, onGoBackOutOfStack } = props;

  const navigate = useGuardNavigate();
  const location = useLocation();
  const locationState = (location.state as any) || {};
  const uniqueLocationStackId =
    locationState.uniqueLocationStackId || GenerateId.create();
  const locationStack =
    locationState[uniqueLocationStackId] || ([] as LocationStackItem[]);

  const navigateToIndex = (index: number) => {
    navigate(-1 * (locationStack.length - index));
  };

  const pushToStack = (
    currentTask: TaskFragmentTaskDetailViewNestedTaskNavigationContextFragment,
    nextTask: TaskFragmentTaskDetailViewNestedTaskNavigationContextFragment,
  ) => {
    navigate(PlotRoutes.task(nextTask.id), {
      state: {
        ...locationState,
        // Push current task & location to stack
        [uniqueLocationStackId]: [
          ...locationStack,
          { location, task: currentTask },
        ],
        uniqueLocationStackId,
      },
    });
  };

  const goBackInStack = () => {
    if (locationStack.length === 0 && onGoBackOutOfStack) {
      onGoBackOutOfStack();

      return;
    }

    navigate(-1);
  };

  return (
    <Provider
      value={{
        locationStack,
        pushToStack,
        navigateToIndex,
        goBackInStack,
      }}
    >
      {children}
    </Provider>
  );
};

export { useNestedTaskNavigationContext };
