import { useDebounce, useDisclosure } from '@dwarvesf/react-hooks';
import { useUserContext } from 'contexts/users/User.context';
import { SearchModal } from 'features/search/views/modal/SearchModal';
import {
  SearchType,
  SearchableEntityType,
  useGetAllRecentSearchesClickedQueryQuery,
  useGlobalSearchResultsQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useCallback, useEffect, useState } from 'react';

export const SearchHandler = () => {
  const modalDisclosure = useDisclosure();
  const analytics = useAnalytics();

  const { user } = useUserContext();

  const [searchType, setSearchType] = useState(SearchType.SmartFullText);

  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 250);

  useEffect(() => {
    setSearchType(SearchType.SmartFullText);
  }, [query]);

  const { data, loading } = useGlobalSearchResultsQuery({
    variables: {
      data: {
        text: debouncedQuery,
        entityTypes: [
          SearchableEntityType.Notes,
          SearchableEntityType.Links,
          SearchableEntityType.Attachments,
          SearchableEntityType.Tasks,
          SearchableEntityType.Collections,
        ],
        limit: 10,
        searchType,
      },
    },
    skip: !user,
  });

  const { data: recentData, refetch } =
    useGetAllRecentSearchesClickedQueryQuery({
      variables: {
        take: 5,
        searchableEntityTypes: [
          SearchableEntityType.Posts,
          SearchableEntityType.Collections,
          SearchableEntityType.Tasks,
        ],
      },
      skip: !user,
    });

  const onModalClose = useCallback(() => {
    setQuery('');
    modalDisclosure.onClose();
  }, [modalDisclosure]);

  useEffect(() => {
    if (modalDisclosure.isOpen) {
      analytics.track(EventName.SearchBarOpen, { userId: user?.id });
      refetch();
    }
  }, [modalDisclosure.isOpen, refetch]); // eslint-disable-line

  useEffect(() => {
    modalDisclosure.onOpen();
  }, []); // eslint-disable-line -- triger flow on mount

  return (
    <SearchModal
      placeholder="Try AI Search to search for blue & yellow socks..."
      open={modalDisclosure.isOpen}
      onClose={onModalClose}
      keyword={query}
      onKeywordChange={setQuery}
      recents={recentData?.getSearchesClicked.data}
      searchData={data?.universalSearch}
      loading={loading}
      searchType={searchType}
      setSearchType={setSearchType}
    />
  );
};
