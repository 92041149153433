/**
 * I temporarily moved this here.
 * The changes by Tushar made this component no longer reusable in other places.
 */

import {
  Box,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  MenuItemProps,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineAddCircle } from 'components/icons/components/outline/IconOutlineAddCircle';
import { getCustomStatusStyles } from 'features/contentIdea/utils';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import React, { useState } from 'react';
import { theme } from 'styles/theme';

export type ContentIdeaBadgeMenuItemProps = MenuItemProps & {
  label: string;
  Icon?: React.FC<any>;
  bgcolor?: string;
  showMoreOption?: boolean;
  selected?: boolean;
  multiSelect?: boolean;
  onDeleteOption?: VoidFunction;
  onUpdateOption?: (newLabel: string) => void;
};

export const ContentIdeaBadgeMenuItem = (
  props: ContentIdeaBadgeMenuItemProps,
) => {
  const {
    value,
    label,
    Icon,
    bgcolor,
    showMoreOption,
    onDeleteOption,
    selected,
    multiSelect,
    onUpdateOption,
    ...rest
  } = props;

  const [showMoreIcon, setShowMoreIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newLabel, setNewLabel] = useState<string>(label);

  const {
    dialog: deleteOptionDialog,
    onOpen: openDeleteOptionConfirmationDialog,
  } = useConfirmationDialog();

  // FIXME: THIS IS A HACK FOR STATUS COLORS
  const customStyles = getCustomStatusStyles(
    value?.toString() || '',
    bgcolor || '',
  );

  return (
    <>
      <MenuItem
        {...rest}
        value={value}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between !important',
          gap: 4,
          py: '8px !important',
          px: '12px !important',
          borderRadius: 2,
        }}
        {...(showMoreOption
          ? {
              onMouseEnter: () => setShowMoreIcon(true),
              onMouseLeave: () => setShowMoreIcon(false),
            }
          : {})}
      >
        <Chip
          icon={Icon && <Icon size={16} />}
          sx={{
            borderRadius: 1,
            ...(customStyles.color
              ? {
                  '.MuiChip-label': {
                    filter: 'none !important',
                    color: customStyles.color,
                  },
                }
              : {}),
            ...customStyles,
          }}
          label={<Typography variant="headline-xs">{label}</Typography>}
          variant="filled-borderless-color-dodge"
        />
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          {(showMoreIcon || anchorEl) && (
            <IconButton
              sx={{
                padding: 0,
              }}
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
              }}
            >
              <IconLinearMore size={16} color={theme.colors?.utility[600]} />
            </IconButton>
          )}
          {selected ? (
            <IconBoldTickCircle
              size={16}
              style={{
                flexShrink: 0,
              }}
            />
          ) : multiSelect ? (
            <IconOutlineAddCircle
              size={16}
              style={{
                flexShrink: 0,
              }}
            />
          ) : null}
        </Box>
      </MenuItem>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          if (!newLabel) {
            setNewLabel(label);
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 6,
            minWidth: 240,
            borderRadius: 4,
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(8px)`,
            boxShadow: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TextField
            defaultValue={label}
            placeholder="Option"
            autoFocus
            error={newLabel.trim() === ''}
            value={newLabel}
            onChange={(e) => {
              setNewLabel(e.target.value);
            }}
            sx={{
              flex: 1,
              input: {
                ...theme.typography['subhead-lg'],
                px: 2,
                py: 1,
              },
              '.MuiInputBase-root': {
                borderRadius: 1,
                bgcolor: theme.colors?.utility[300],
                borderColor: theme.colors?.utility[500],
                overflow: 'hidden',
              },
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && newLabel.trim()) {
                onUpdateOption?.(newLabel);
                setAnchorEl(null);
              }
            }}
          />
        </Box>
        <Divider sx={{ my: 3.5 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {[
            { label: 'Delete', Icon: IconLinearTrash, onClick: onDeleteOption },
          ].map(({ Icon, label, onClick }) => {
            return (
              <MenuItem
                key={label}
                sx={{ gap: 3, borderRadius: 2, px: 1 }}
                onClick={() => {
                  openDeleteOptionConfirmationDialog({
                    title: `Delete this Option?`,
                    subtitle: 'Are you sure you want to delete this?',
                    onConfirm: onClick,
                    confirmText: 'Delete Option',
                  });
                }}
              >
                <Icon size={16} />
                <Typography variant="subhead-lg">{label}</Typography>
              </MenuItem>
            );
          })}
        </Box>
      </Popover>
      {deleteOptionDialog}
    </>
  );
};
