import { gql } from '@apollo/client';
import { Avatar, Box, Checkbox, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconCustomEnvelope } from 'components/icons/components/custom/IconCustomEnvelope';
import { IconCustomMobile } from 'components/icons/components/custom/IconCustomMobile';
import { IconLinearMicrophone } from 'components/icons/components/linear/IconLinearMicrophone';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import { IconOutlineDirectInbox } from 'components/icons/components/outline/IconOutlineDirectInbox';
import { IconOutlineTask } from 'components/icons/components/outline/IconOutlineTask';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { NotificationsSection } from 'features/socialListeningSettings/views/notifications';
import {
  NotificationSettingFragmentUseNotificationSettingsFragmentDoc,
  PlotFeature,
  useUpdateNotificationSettingMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { useNotificationSettings } from './useNotificationSettings';

// eslint-disable-next-line
gql`
  mutation UpdateNotificationSetting($data: UpdateNotificationSettingInput!) {
    updateNotificationSetting(data: $data) {
      id
      ...NotificationSettingFragmentUseNotificationSettings
    }
  }
  ${NotificationSettingFragmentUseNotificationSettingsFragmentDoc}
`;

type Props = {};

export const Notifications = (props: Props) => {
  const { user, orgBilling } = useUserContext();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const userSlBrands = user?.socialListeningBrands || [];

  const [updateNotificationSetting] = useUpdateNotificationSettingMutation();
  const {
    taskNotificationSettings,
    cjbNotificationSettings,
    contentCalendarNotificationSettings,
  } = useNotificationSettings();

  const onUpdateNotificationSetting = async (
    id: string,
    key: string,
    value: boolean,
  ) => {
    const setting = [
      ...taskNotificationSettings,
      ...cjbNotificationSettings,
      ...contentCalendarNotificationSettings,
    ].find((s) => s.id === id);

    if (!setting) {
      return;
    }

    if (!setting.manageableSetting?.[key]) {
      return;
    }

    await updateNotificationSetting({
      variables: {
        data: {
          notificationSettingId: id,
          data: {
            [key]: value,
          },
        },
      },
      optimisticResponse: {
        updateNotificationSetting: {
          ...setting,
          [key]: value,
        },
      },
    });
  };

  return (
    <Box>
      <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
        Notifications
      </Typography>

      <Box
        height="1"
        my={4}
        sx={{
          borderBottom: `1px solid ${theme.colors?.utility[400]}`,
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4,
            py: 2,
          }}
        >
          <Box flex={2} />
          {[
            { Icon: IconOutlineDirectInbox, label: 'Inbox' },
            { Icon: IconCustomEnvelope, label: 'Email' },
            { Icon: IconCustomMobile, label: 'Mobile Push' },
          ].map(({ Icon, label }) => (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4,
              }}
              key={label}
            >
              <Icon />
              <Typography variant="subhead-xl">{label}</Typography>
            </Box>
          ))}
        </Box>
        {[
          {
            SectionIcon: IconOutlineTask,
            sectionTitle: 'Tasks',
            settings: taskNotificationSettings,
          },
          {
            SectionIcon: IconCustomCreativeJuiceBox,
            sectionTitle: 'Creative Juicebox',
            settings: cjbNotificationSettings,
          },
          {
            SectionIcon: IconOutlineCalendar1,
            sectionTitle: 'Content Calendar',
            settings: contentCalendarNotificationSettings,
          },
        ].map(({ SectionIcon, sectionTitle, settings }) => (
          <Box key={sectionTitle} mb={10}>
            <Typography
              variant="headline-md"
              display="flex"
              alignItems="center"
              gap={2}
              mb={4}
            >
              <SectionIcon size={24} />
              {sectionTitle}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                '.MuiCheckbox-indeterminate': {
                  color: `${theme.colors?.utility[400]} !important`,
                },
              }}
            >
              {settings.map((s) => (
                <Box display="flex" alignItems="center" gap={2} key={s.id}>
                  <Typography variant="subhead-xl" flex={2}>
                    {s.label}
                  </Typography>
                  <Box flex={1} textAlign="center">
                    <Tooltip
                      disableHoverListener={s.manageableSetting?.inbox}
                      title="Coming soon"
                    >
                      <Checkbox
                        checked={s.inbox}
                        indeterminate={!s.manageableSetting?.inbox}
                        size="small"
                        onChange={(e) =>
                          onUpdateNotificationSetting(
                            s.id,
                            'inbox',
                            e.target.checked,
                          )
                        }
                      />
                    </Tooltip>
                  </Box>
                  <Box flex={1} textAlign="center">
                    <Tooltip
                      disableHoverListener={s.manageableSetting?.email}
                      title="Coming soon"
                    >
                      <Checkbox
                        checked={s.email}
                        indeterminate={!s.manageableSetting?.email}
                        size="small"
                        onChange={(e) =>
                          onUpdateNotificationSetting(
                            s.id,
                            'email',
                            e.target.checked,
                          )
                        }
                      />
                    </Tooltip>
                  </Box>
                  <Box flex={1} textAlign="center">
                    <Tooltip
                      disableHoverListener={s.manageableSetting?.mobilePush}
                      title="Coming soon"
                    >
                      <Checkbox
                        checked={s.mobilePush}
                        indeterminate={!s.manageableSetting?.mobilePush}
                        size="small"
                        onChange={(e) =>
                          onUpdateNotificationSetting(
                            s.id,
                            'mobilePush',
                            e.target.checked,
                          )
                        }
                      />
                    </Tooltip>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>

      {orgBilling?.socialListeningEnabled && (
        <>
          <Typography
            variant="headline-md"
            display="flex"
            alignItems="center"
            gap={2}
            mb={6}
          >
            <IconLinearMicrophone size={24} />
            Social Listening
          </Typography>

          {userSlBrands.map((slBrand) => (
            <Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar
                  sx={{
                    width: theme.spacing(6),
                    height: theme.spacing(6),
                  }}
                  src={
                    slBrand.creators?.[0]?.profilePictureUrl ||
                    slBrand.creators?.[1]?.profilePictureUrl ||
                    ''
                  }
                />
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.primary.black}
                >
                  {slBrand.name}
                </Typography>
              </Box>
              {isFeatureEnabled(PlotFeature.SocialListeningNotifications) && (
                <NotificationsSection
                  currentBrandId={slBrand.id}
                  componentProps={{
                    hideLabels: true,
                  }}
                />
              )}

              <Box
                height="1"
                my={8}
                sx={{
                  borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                }}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};
