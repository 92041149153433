import { Box, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearSecurityUser } from 'components/icons/components/linear/IconLinearSecurityUser';
import {
  GenderDemographicsMap,
  GenerationDemographicsMap,
} from 'features/socialListeningAnalytics/constants';
import {
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

export interface SLAAnalyticsFilterByDemographicsProps {
  renderTitle?: () => React.ReactNode;
  selectedGender: SocialPostGenderDemographic[];
  selectedGeneration: SocialPostGenerationDemographic[];
  onChange: (data: {
    selectedGender: SocialPostGenderDemographic[];
    selectedGeneration: SocialPostGenerationDemographic[];
  }) => void;
  variant?: 'normal' | 'accordion';
}

export const SLAAnalyticsFilterByDemographics = ({
  renderTitle,
  selectedGender,
  selectedGeneration,
  onChange,
  variant = 'accordion',
}: SLAAnalyticsFilterByDemographicsProps) => {
  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconLinearSecurityUser
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-lg">Demographics</Typography>
      </Box>
    );
  }, [renderTitle]);

  const generationValues = Object.values(
    SocialPostGenerationDemographic,
  ).filter(
    (g) =>
      g !== SocialPostGenerationDemographic.GenAlpha &&
      g !== SocialPostGenerationDemographic.NotSpecified,
  );
  const genderValues = Object.values(SocialPostGenderDemographic);

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      defaultOpen={variant !== 'accordion'}
      sx={{
        ...(variant === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(variant === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      dropDownOptions={[
        {
          label: 'Gender',
          value: 'Gender',
          renderOption: () => (
            <Box>
              <DropDownMoreMenuItem
                renderHeaderAsOption
                label={<Typography variant="subhead-lg">Gender</Typography>}
                onClick={() => {
                  onChange({
                    selectedGender:
                      JSON.stringify(selectedGender) ===
                      JSON.stringify(genderValues)
                        ? []
                        : genderValues,
                    selectedGeneration,
                  });
                }}
                dropDownOptions={genderValues.map((g) => ({
                  value: g,
                  label: g,
                  isChecked: selectedGender.includes(g),
                  renderOption: () => (
                    <CheckboxMenuItem
                      disableRipple
                      value={g}
                      label={GenderDemographicsMap[g]}
                      onClick={() => {
                        onChange({
                          selectedGender: selectedGender.includes(g)
                            ? selectedGender.filter((o) => o !== g)
                            : [...selectedGender, g],
                          selectedGeneration,
                        });
                      }}
                      checked={selectedGender.includes(g)}
                      sx={{
                        pl: 6,
                      }}
                    />
                  ),
                }))}
              />
            </Box>
          ),
        },
        {
          label: 'Generation',
          value: 'Generation',
          renderOption: () => (
            <Box>
              <DropDownMoreMenuItem
                renderHeaderAsOption
                label={<Typography variant="subhead-lg">Generation</Typography>}
                onClick={() => {
                  onChange({
                    selectedGender,
                    selectedGeneration:
                      JSON.stringify(selectedGeneration) ===
                      JSON.stringify(generationValues)
                        ? []
                        : generationValues,
                  });
                }}
                dropDownOptions={generationValues.map((g) => ({
                  value: g,
                  label: g,
                  isChecked: selectedGeneration.includes(g),
                  renderOption: () => (
                    <CheckboxMenuItem
                      disableRipple
                      value={g}
                      label={GenerationDemographicsMap[g]}
                      onClick={() => {
                        onChange({
                          selectedGeneration: selectedGeneration.includes(g)
                            ? selectedGeneration.filter((o) => o !== g)
                            : [...selectedGeneration, g],
                          selectedGender,
                        });
                      }}
                      checked={selectedGeneration.includes(g)}
                      sx={{
                        pl: 6,
                      }}
                    />
                  ),
                }))}
              />
            </Box>
          ),
        },
      ]}
    />
  );
};
