import { gql } from '@apollo/client';
import { Box, SxProps, TableCell } from '@mui/material';
import { CollectionName } from 'features/collection/components';
import { JuiceboxTableBaseTableViewContext } from 'features/juiceboxTable/views/baseTable/types';
import {
  CollectionFragmentCollectionNameFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellNameFragment,
} from 'graphql/generated';
import { useEffect, useRef } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRowCellName on CollectionModel {
    id
    isDisabled
    ...CollectionFragmentCollectionName
  }
  ${CollectionFragmentCollectionNameFragmentDoc}
`;

export type JuiceboxTableCollectionTableRowCellNameProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowCellNameFragment;
  context?: JuiceboxTableBaseTableViewContext;
  sx?: SxProps;
  isRenaming: boolean;
  setIsRenaming: (value: boolean) => void;
};

export const JuiceboxTableCollectionTableRowCellName = (
  props: JuiceboxTableCollectionTableRowCellNameProps,
) => {
  const { collection, context, sx = {}, isRenaming, setIsRenaming } = props;

  const nameRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (
      context?.context.collection?.id === collection.id &&
      context?.context.isRenaming &&
      !isRenaming
    ) {
      setIsRenaming(true);
      setTimeout(() => {
        nameRef.current?.focus();
        nameRef.current?.select();
      });
    }
  }, [context, collection, isRenaming]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      context?.context.collection?.id !== collection.id ||
      !context?.context.isRenaming
    ) {
      setIsRenaming(false);
    }
  }, [context, collection]); // eslint-disable-line react-hooks/exhaustive-deps

  const onExitRenaming = () => {
    setIsRenaming(false);
    if (context) {
      context.setContext((draft) => {
        if (draft.isRenaming) {
          draft.isRenaming = false;
        }
      });
    }
  };

  return (
    <TableCell sx={{ ...sx }}>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
      >
        <CollectionName
          ref={nameRef}
          collection={collection}
          readOnly={!isRenaming}
          style={{
            ...theme.typography['headline-sm'],
            color: collection.isDisabled
              ? theme.colors?.utility[700]
              : theme.colors?.primary.black,
            height: theme.spacing(6),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          saveOnBlur
          onBlur={onExitRenaming}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </Box>
    </TableCell>
  );
};
