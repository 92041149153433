import { Plugin } from '@tiptap/pm/state';
import { getAttributes } from '@tiptap/react';

/**
 * This plugin is EXACTLY THE SAME as the one in the original tiptap's link extension.
 * But somehow the original plugin is not working, so I have to copy it here.
 *
 * @returns
 */
export const ClickPlugin = () => {
  return new Plugin({
    props: {
      handleClick: (view, pos, event) => {
        if (event.button !== 0) {
          return false;
        }

        let a = event.target as HTMLElement;
        const els: HTMLElement[] = [];

        while (a.nodeName !== 'DIV') {
          els.push(a);
          a = a.parentNode as HTMLElement;
        }

        if (!els.find((value) => value.nodeName === 'A')) {
          return false;
        }

        const attrs = getAttributes(view.state, 'link');
        const link = event.target as HTMLLinkElement;

        const href = link?.href ?? attrs.href;
        const target = link?.target ?? attrs.target;

        if (link && href) {
          window.open(href, target);

          return true;
        }

        return false;
      },
    },
  });
};
