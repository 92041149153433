import { gql } from '@apollo/client';
import {
  GetPostsForTrendingJuiceboxSavedTrendButtonDocument,
  GetPostsForTrendingJuiceboxSavedTrendButtonQuery,
  PostFilterType,
  PostFragmentPostPreviewFragmentDoc,
  useSaveTrendForTrendingPageMutation,
  useUnsaveTrendForTrendingPageMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation SaveTrendForTrendingPage($data: CreateTrendFavoriteInput!) {
    createTrendFavorite(data: $data) {
      id
      post {
        ...PostFragmentPostPreview
      }
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UnsaveTrendForTrendingPage($data: DeleteTrendFavoriteInput!) {
    deleteTrendFavorite(data: $data) {
      success
      message
    }
  }
`;

interface Props {
  postId: string;
  isSavedToTrend: boolean;
}

export const useSaveTrendHandler = ({ postId, isSavedToTrend }: Props) => {
  const [createPostFavoriteTrend] = useSaveTrendForTrendingPageMutation();
  const [deletePostFavoriteTrend] = useUnsaveTrendForTrendingPageMutation();

  const onSelectTrendCollection = () => {
    if (isSavedToTrend) {
      deletePostFavoriteTrend({
        variables: {
          data: {
            postId,
          },
        },
        update: (cache, res) => {
          cache.updateQuery(
            {
              query: GetPostsForTrendingJuiceboxSavedTrendButtonDocument,
              variables: {
                filters: {
                  filterType: PostFilterType.MySavedTrends,
                },
                take: 3,
              },
            },
            (data: GetPostsForTrendingJuiceboxSavedTrendButtonQuery | null) => {
              if (!data) return null;

              return {
                ...data,
                posts: {
                  ...data.posts,
                  data: [
                    ...data.posts.data.filter((post) => post.id !== postId),
                  ],
                  meta: {
                    totalCount: data.posts.meta.totalCount - 1,
                  },
                },
              };
            },
          );
          modifyObject(cache, postId, 'PostModel', {
            isFavoriteTrend: () => {
              return false;
            },
          });
        },
      });
    } else {
      createPostFavoriteTrend({
        variables: {
          data: {
            postId,
          },
        },
        update: (cache, { data }) => {
          const newPost = data?.createTrendFavorite.post;
          if (newPost) {
            cache.updateQuery(
              {
                query: GetPostsForTrendingJuiceboxSavedTrendButtonDocument,
                variables: {
                  filters: {
                    filterType: PostFilterType.MySavedTrends,
                  },
                  take: 3,
                },
              },
              (
                data: GetPostsForTrendingJuiceboxSavedTrendButtonQuery | null,
              ) => {
                if (!data) return null;

                return {
                  ...data,
                  posts: {
                    ...data.posts,
                    data: [...data.posts.data, newPost],
                    meta: {
                      totalCount: data.posts.meta.totalCount + 1,
                    },
                  },
                };
              },
            );
          }
          modifyObject(cache, postId, 'PostModel', {
            isFavoriteTrend: () => {
              return true;
            },
          });
        },
      });
    }
  };

  return {
    onSelectTrendCollection,
  };
};
