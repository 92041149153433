import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { useMoveToAnotherCollectionModal } from 'features/collection/hooks';
import { CollectionCardView } from 'features/collection/views/card/CollectionCardView';
import { DndDragItem, DndDragItemTypes, DndDropItem } from 'features/dnd';
import { useJuiceboxSelectContext } from 'features/juicebox';
import {
  CollectionFragmentCollectionCardViewFragmentDoc,
  CollectionFragmentCollectionPostDndCollectionCardFragment,
  CollectionPermission,
  useMovePostsToCollectionForCollectionPostDndCollectionCardMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionPostDndCollectionCard on CollectionModel {
    id
    ...CollectionFragmentCollectionCardView
  }
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation MovePostsToCollectionForCollectionPostDndCollectionCard(
    $data: MovePostsToCollectionInput!
  ) {
    movePostsToCollection(data: $data) {
      success
      message
    }
  }
`;

export const COLLECTION_DROPPABLE_PREFIX = 'droppable-collection';
export const COLLECTION_DRAGGABLE_PREFIX = 'draggable-collection';

export type CollectionPostDndCollectionCardProps = {
  collection: CollectionFragmentCollectionPostDndCollectionCardFragment;
  context?: 'parent' | 'root';
  sx?: SxProps;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const CollectionPostDndCollectionCard = (
  props: CollectionPostDndCollectionCardProps,
) => {
  const { collection, context = 'parent', sx, onClick } = props;

  const {
    isSelectModeActive,
    selectedPostIds,
    selectedCollectionIds,
    onSelectCollection,
    exitSelectMode,
  } = useJuiceboxSelectContext();

  const selected = selectedCollectionIds.includes(collection.id);

  const [movePostsToCollection] =
    useMovePostsToCollectionForCollectionPostDndCollectionCardMutation();
  const { onMoveCollection, renderMoveToAnotherCollectionModal } =
    useMoveToAnotherCollectionModal({
      currentCollectionId: collection.id,
      collectionIds: [collection.id],
    });

  const onDrop = async (item: {
    id: string;
    selectedCollectionIds: string[];
    selectedPostIds: string[];
  }) => {
    if (item.selectedPostIds.length > 0) {
      await movePostsToCollection({
        variables: {
          data: {
            postIds: item.selectedPostIds,
            toCollectionId: collection.id,
            fromCollectionId: collection.parentCollectionId,
          },
        },
        update: (cache) => {
          item.selectedPostIds.forEach((postId) => {
            evictObject(cache, postId, 'PostModel');
          });
        },
      });
    }

    if (item.selectedCollectionIds.length > 0) {
      onMoveCollection(item.selectedCollectionIds, collection);
    }

    exitSelectMode();
  };

  return (
    <>
      <DndDropItem
        item={{
          ...collection,
          selectedPostIds,
          selectedCollectionIds: [...selectedCollectionIds, collection.id],
        }}
        accept={[DndDragItemTypes.Post, DndDragItemTypes.Collection]}
        canDrop={(item) => {
          return (
            collection.myPermissions.includes(CollectionPermission.Read) &&
            !item.selectedCollectionIds.includes(collection.id)
          );
        }}
        sx={{
          position: 'relative',
          minHeight: 150,
          borderRadius: 4,
          ...(selectedPostIds.length + selectedCollectionIds.length > 0 && {
            color: theme.colors?.primary.black,
            outline: `2px solid ${
              selected
                ? theme.colors?.primary.black
                : theme.colors?.utility[300]
            }`,
            outlineOffset: 3,
            backgroundColor: theme.colors?.primary.white,
            overflow: 'hidden',
          }),
          ...sx,
        }}
        onDrop={onDrop}
      >
        <DndDragItem
          type={DndDragItemTypes.Collection}
          item={{
            ...collection,
            selectedPostIds,
            selectedCollectionIds: [...selectedCollectionIds, collection.id],
          }}
          isDragging={(monitor) =>
            // @ts-ignore
            [...selectedCollectionIds, monitor.getItem()?.id].includes(
              collection.id,
            )
          }
          useCustomDragLayer
          canDrag={collection.myPermissions.includes(
            CollectionPermission.Update,
          )}
        >
          <CollectionCardView
            collection={collection}
            context={context}
            componentsProps={{
              multiPostPreview: {
                variant: 'card-stack-alt',
              },
              disableContextMenu: isSelectModeActive,
            }}
            sx={{
              ...(isSelectModeActive && {
                '*': {
                  pointerEvents: 'none !important',
                },
              }),
            }}
            {...(isSelectModeActive && {
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelectCollection(collection, e.shiftKey);
                onClick?.(e);
              },
            })}
          />
          {selected && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                margin: 2,
                backgroundColor: theme.colors?.primary.white,
                borderRadius: '100%',
                height: 20,
                width: 20,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <IconBoldTickCircle size={16} />
            </Box>
          )}
        </DndDragItem>
      </DndDropItem>
      {renderMoveToAnotherCollectionModal()}
    </>
  );
};
