import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, SxProps, Typography } from '@mui/material';
import { DateRangePicker } from 'components/common/DatePicker';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import {
  TaskFragmentTaskDueDateRangeFragment,
  useUpdateTaskForTaskDueDateRangeMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useRef } from 'react';
import { theme } from 'styles/theme';
import { TaskDueDateRangeButton } from './TaskDueDateRangeButton';

export const TASK_FRAGMENT_TASK_DUE_DATE_RANGE = gql`
  fragment TaskFragmentTaskDueDateRange on TaskModel {
    id
    startDate
    endDate
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskDueDateRange($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      ...TaskFragmentTaskDueDateRange
    }
  }
  ${TASK_FRAGMENT_TASK_DUE_DATE_RANGE}
`;

export type TaskDueDateRangeProps = {
  task: TaskFragmentTaskDueDateRangeFragment;
  variant?: 'text' | 'button' | 'icon';
  sx?: SxProps;
  renderTrigger?: () => React.ReactNode;
  onChange?: (startDate: Date | null, endDate: Date | null) => void;
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
  };
};

export const TaskDueDateRange = (props: TaskDueDateRangeProps) => {
  const {
    task,
    variant = 'text',
    sx,
    renderTrigger,
    onChange: _onChange,
    componentsProps = {},
  } = props;

  const [updateTask] = useUpdateTaskForTaskDueDateRangeMutation();

  const onChange =
    _onChange ||
    ((startDate: Date | null, endDate: Date | null) => {
      updateTask({
        variables: {
          data: {
            taskId: task.id,
            data: {
              startDate,
              endDate,
            },
          },
        },
        optimisticResponse: {
          updateTask: {
            ...task,
            startDate,
            endDate,
          },
        },
      });
    });

  const triggerRef = useRef<HTMLDivElement | null>(null);
  const {
    isOpen: isPickerOpen,
    onOpen: openPicker,
    onClose: closePicker,
  } = useDisclosure();

  const renderTriggerText = () => {
    return (
      <>
        {!task.startDate && !task.endDate && (
          <IconBoldCalendar
            size={componentsProps?.icon?.size || 16}
            color={componentsProps?.icon?.color}
          />
        )}
        {(task.startDate || task.endDate) && (
          <Typography variant="subhead-sm" color={theme.colors?.utility[900]}>
            {`${
              task.startDate ? moment(task.startDate).format('MMM D') : 'N/A'
            } - ${task.endDate ? moment(task.endDate).format('MMM D') : 'N/A'}`}
          </Typography>
        )}
      </>
    );
  };
  const renderTriggerIcon = () => {
    return (
      <IconBoldCalendar
        size={componentsProps?.icon?.size || 16}
        color={componentsProps?.icon?.color}
      />
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        ...sx,
      }}
      onClick={openPicker}
      ref={triggerRef}
    >
      {renderTrigger ? (
        renderTrigger()
      ) : variant === 'icon' ? (
        renderTriggerIcon()
      ) : variant === 'text' ? (
        renderTriggerText()
      ) : (
        <TaskDueDateRangeButton
          startDate={task.startDate}
          endDate={task.endDate}
          componentsProps={{
            icon: componentsProps?.icon,
          }}
        />
      )}
      <DateRangePicker
        calendars={1}
        open={isPickerOpen}
        onClose={closePicker}
        value={[moment(task.startDate), moment(task.endDate)]}
        onChange={([startDate, endDate]) => {
          onChange(
            startDate ? startDate.toDate() : null,
            endDate ? endDate.toDate() : startDate ? startDate.toDate() : null,
          );
        }}
        sx={{ '& .MuiTypography-root': { display: 'none' } }}
        slots={{
          // eslint-disable-next-line
          textField: () => null,
        }}
        slotProps={{
          popper: {
            anchorEl: triggerRef.current,
            placement: 'bottom-start',
          },
        }}
      />
    </Box>
  );
};
