import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Dialog, Typography } from '@mui/material';
import { SentimentIcon } from 'features/socialMediaListening/components';
import { SentimentColorsSx } from 'features/socialMediaListening/views/postDetail/consts';
import {
  BrandTrendsBySentimentData,
  MetricsTimeRange,
  Sentiment,
  useGetAllBrandClaimsTrendsForAllCategoriesDialogQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { ThemeDialog } from '../themeDialog';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetAllBrandClaimsTrendsForAllCategoriesDialog(
    $data: AllSentimentTrendsInput!
  ) {
    brands {
      id
      allBrandTrends(data: $data) {
        score
        data {
          __typename
          sentiment
          name
          brandId
        }
      }
    }
  }
`;

type AllCategoriesDialogProps = {
  brandId: string;
  sentiment: Sentiment;
  isOpen: boolean;
  onClose: () => void;
};

export const AllCategoriesDialog = ({
  brandId,
  sentiment,
  onClose,
  isOpen,
}: AllCategoriesDialogProps) => {
  const [clickedSentimentName, setClickedSentimentName] = useState('');

  const themeDialogDisclosure = useDisclosure();

  const { data: allTrendsData } =
    useGetAllBrandClaimsTrendsForAllCategoriesDialogQuery({
      variables: {
        data: {
          timeRange: MetricsTimeRange.AllTime,
          sentiment,
          topicIds: [],
        },
      },
    });

  const trends = useMemo(
    () =>
      allTrendsData?.brands.find((x) => x.id === brandId)?.allBrandTrends || [],
    [allTrendsData?.brands, brandId],
  );

  const selectedTrend = useMemo(
    () => trends.find((x) => x.data.name === clickedSentimentName),
    [trends, clickedSentimentName],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        opacity: themeDialogDisclosure.isOpen ? 0 : 1,
        '& .MuiDialog-paper': {
          borderRadius: theme.spacing(6),
          minWidth: theme.spacing(200),
          minHeight: theme.spacing(150),
        },
      }}
    >
      <Box p={6}>
        <Typography
          variant="headline-lg"
          color={theme.colors?.primary.black}
          display="flex"
          alignItems="center"
          gap={1}
        >
          <>
            <SentimentIcon sentiment={sentiment} size={24} />
            {sentiment === Sentiment.Positive
              ? 'Positive'
              : sentiment === Sentiment.Negative
              ? 'Negative'
              : 'Neutral'}
          </>
        </Typography>
        <Box
          display="flex"
          gap={6}
          justifyContent="space-between"
          width="100%"
          mt={8}
        >
          <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
            Themes
          </Typography>
          <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
            Video count
          </Typography>
        </Box>
        {trends.map((trend) => (
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            my={3}
          >
            <Box
              display="flex"
              key={trend.data.name}
              gap={6}
              justifyContent="space-between"
              width="100%"
            >
              <Box flex={1}>
                <Box
                  onClick={() => {
                    setClickedSentimentName(trend.data.name);
                    themeDialogDisclosure.onOpen();
                  }}
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    fontWeight: 600,
                    backgroundColor:
                      SentimentColorsSx?.[sentiment]?.backgroundColor ||
                      SentimentColorsSx.Default.backgroundColor,
                    color:
                      SentimentColorsSx?.[sentiment]?.color ||
                      SentimentColorsSx.Default.color,
                    width: 'fit-content',
                    p: theme.spacing(1.5, 3),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <Typography variant="body-xl">{trend.data.name}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body-xl"
                  color={theme.colors?.utility[700]}
                  fontWeight={500}
                >
                  {trend.score}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
        {selectedTrend && selectedTrend.data.name === clickedSentimentName && (
          <ThemeDialog
            topicIds={[]}
            showBackButton
            {...themeDialogDisclosure}
            brandSentimentTrend={
              selectedTrend as unknown as BrandTrendsBySentimentData
            }
            timeRange={MetricsTimeRange.AllTime}
          />
        )}
      </Box>
    </Dialog>
  );
};
