import { Box, Divider } from '@mui/material';
import { JuiceboxFilter } from 'features/juicebox/hooks';
import { ShowMoreType } from 'features/juicebox/views';
import {
  GetFiltersSearchForJuiceboxFilterSuggestionViewQuery,
  GetRecentSearchQueriesForJuiceboxFilterSuggestionViewQuery,
  GetRecentlyOpenedQueriesForJuiceboxFilterSuggestionViewQuery,
  PostType,
} from 'graphql/generated';
import { MutableRefObject } from 'react';
import { theme } from 'styles/theme';
import {
  JuiceboxAttachmentSearchItem,
  JuiceboxCollectionSearchItem,
  JuiceboxLinkSearchItem,
  JuiceboxNoteSearchItem,
  JuiceboxRecentSearchItem,
} from '../searchItem';
import { JuiceboxSearchResultHeader } from '../searchResultHeader';
import { JuiceboxFilterSearchResult } from './JuiceboxFilterSearchResult';

interface Props {
  recentSearchData: GetRecentSearchQueriesForJuiceboxFilterSuggestionViewQuery['getRecentSearchQueries']['data'];
  recentlyOpenedData: GetRecentlyOpenedQueriesForJuiceboxFilterSuggestionViewQuery['getSearchesClicked']['data'];
  filtersSearchData: GetFiltersSearchForJuiceboxFilterSuggestionViewQuery['filtersSearch'];
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
  componentProps?: {
    filterSearchItem?: {
      onSelectFilter: (filter: JuiceboxFilter) => void;
    };
    recentSearchItem?: {
      onItemSelect: (query: string) => void;
    };
    searchResultHeader?: {
      onShowMore: (type: ShowMoreType) => void;
    };
  };
}

/*
  TODO: this component is incorrectly named. This is not rendering traditional search, but just rendered filter & recently opened search results.
  This DOES NOT render any actual search data
*/
export const JuiceboxTraditionalSearchResult = ({
  recentSearchData,
  recentlyOpenedData,
  elRefs,
  componentProps = {},
  filtersSearchData,
}: Props) => {
  const initialElIndex =
    (filtersSearchData.collections?.length || 0) +
    (filtersSearchData.createdBy?.length || 0) +
    (filtersSearchData.sharedBy?.length || 0) +
    (filtersSearchData.type?.length || 0) +
    (filtersSearchData.reaction?.length || 0) +
    (filtersSearchData.dateModified?.length || 0);

  let elIndex = initialElIndex;
  return (
    <>
      <JuiceboxFilterSearchResult
        elRefs={elRefs}
        filtersSearchData={filtersSearchData}
        componentProps={componentProps}
      />

      {recentSearchData.length && recentlyOpenedData.length ? (
        <Divider
          sx={{
            borderBottom: `1px solid ${theme.colors?.utility[400]}`,
            mx: 3,
          }}
        />
      ) : null}

      {recentSearchData.length > 0 && (
        <>
          <JuiceboxSearchResultHeader
            title="Recent Searches"
            onShowMore={() => {
              componentProps.searchResultHeader?.onShowMore(
                ShowMoreType.RecentSearch,
              );
            }}
            componentsProps={{
              button: {
                color: theme.colors?.primary.black,
              },
              icon: {
                color: theme.colors?.primary.black,
              },
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', my: -2 }}>
            {recentSearchData.map(({ query }) =>
              query ? (
                <JuiceboxRecentSearchItem
                  query={query}
                  ref={(el) => {
                    elRefs.current[elIndex] = el;
                    elIndex++;
                  }}
                  onItemSelect={(query) => {
                    componentProps.recentSearchItem?.onItemSelect(query);
                  }}
                />
              ) : null,
            )}
          </Box>
        </>
      )}

      {recentlyOpenedData.length > 0 && (
        <>
          <JuiceboxSearchResultHeader
            title="Recently Opened"
            onShowMore={() =>
              componentProps.searchResultHeader?.onShowMore(
                ShowMoreType.RecentOpened,
              )
            }
            componentsProps={{
              button: {
                color: theme.colors?.primary.black,
              },
              icon: {
                color: theme.colors?.primary.black,
              },
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', my: -2 }}>
            {recentlyOpenedData.map((data) => {
              if (data.collection) {
                return (
                  <JuiceboxCollectionSearchItem
                    collection={data.collection}
                    posts={data.collection.posts}
                    ref={(el) => {
                      elRefs.current[elIndex] = el;
                      elIndex++;
                    }}
                  />
                );
              }
              if (data.post && data.post.type === PostType.Note) {
                return (
                  <JuiceboxNoteSearchItem
                    note={data.post}
                    ref={(el) => {
                      elRefs.current[elIndex] = el;
                      elIndex++;
                    }}
                  />
                );
              }
              if (data.post && data.post.type === PostType.Attachment) {
                return (
                  <JuiceboxAttachmentSearchItem
                    attachment={data.post}
                    ref={(el) => {
                      elRefs.current[elIndex] = el;
                      elIndex++;
                    }}
                  />
                );
              }
              if (data.post && data.post.type === PostType.Link) {
                return (
                  <JuiceboxLinkSearchItem
                    link={data.post}
                    ref={(el) => {
                      elRefs.current[elIndex] = el;
                      elIndex++;
                    }}
                  />
                );
              }
            })}
          </Box>
        </>
      )}
    </>
  );
};
