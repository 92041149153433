import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Card, Chip, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select/RadioMenuItem';
import { Tooltip as CustomTooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearEyeSlash } from 'components/icons/components/linear/IconLinearEyeSlash';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { useGraphDataMakerNew } from 'features/socialMediaListening/hooks/useGraphDataMakerNew';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForGraphDataAggregationType,
  SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragmentDoc,
  useGetChartDataPointsForSlaBrandInboundGraphViewQuery,
  useGetTopicForSlaBrandInboundGraphViewQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SLAAnalyticsFilterByCategory } from '../../components/slaAnalyticsFilters/SLAAnalyticsFilterByCategory';
import { SLABrandInboundGraphPostsDialogView } from '../slaBrandInboundGraphPosts';
import {
  getColorByIndex,
  SLAMultiLineChartRenderer,
} from './SLAMultiLineChartRenderer';
import { SLABrandInboundGraphViewSkeleton } from './SLABrandInboundGraphViewSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetTopicForSLABrandInboundGraphView($topicId: String!) {
    topic(id: $topicId) {
      id
      topicSignalDefinitions {
        id
        signalDefinition {
          ...SignalDefinitionFragmentSLAAnalyticsFilterByCategory
        }
      }
    }
  }
  ${SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetChartDataPointsForSLABrandInboundGraphView(
    $data: BrandInboundFiltersInputForGraphData!
  ) {
    brandInboundGraphData(data: $data) {
      chartDataPoints {
        Y
        X
      }
      multiChartDataPoints {
        id
        label
        chartDataPoints {
          Y
          X
        }
      }
      totalCount
    }
  }
`;

type Props = {
  filters: BrandInboundFiltersInput;
};

export const SLABrandInboundGraphView = ({ filters }: Props) => {
  const [sortKey, setSortKey] = useState(
    BrandInboundFiltersInputForGraphDataAggregationType.TotalPostCount,
  );
  const [hiddenDatasets, setHiddenDatasets] = useState<string[]>([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [selectedCategoryOptionIds, setSelectedCategoryOptionIds] = useState<
    string[]
  >([]);

  const [selectedSignalInfo, setSelectedSignalInfo] = useState<
    | {
        id: string;
        type: 'signalDefinition' | 'signalCategoryOption';
      }
    | undefined
  >();
  const {
    isOpen: isPostsModalOpen,
    onOpen: onPostsModalOpen,
    onClose: onPostsModalClose,
  } = useDisclosure();

  useEffect(() => {
    setSelectedCategoryIds(filters.signalDefinitionIds || []);
    setSelectedCategoryOptionIds(filters.signalCategoryOptionIds || []);
  }, [
    filters.topicIds,
    filters.signalDefinitionIds,
    filters.signalCategoryOptionIds,
  ]);

  const { data: topicData } = useGetTopicForSlaBrandInboundGraphViewQuery({
    variables: {
      topicId: filters.topicIds?.[0] || '',
    },
    skip: !filters.topicIds?.[0],
  });
  const allSignalDefinitions = topicData?.topic?.topicSignalDefinitions || [];

  const { data: brandInboundGraphData, loading } =
    useGetChartDataPointsForSlaBrandInboundGraphViewQuery({
      variables: {
        data: {
          ...filters,
          signalDefinitionIds: selectedCategoryIds,
          signalCategoryOptionIds: selectedCategoryOptionIds,
          aggregationType: sortKey,
        },
      },
      skip:
        filters.contentType === BrandContentType.Topic &&
        !filters.topicIds?.length,
    });

  const graphData = brandInboundGraphData?.brandInboundGraphData;

  const { chartData: processedGraphData } = useGraphDataMakerNew({
    dailyChartData: graphData?.chartDataPoints || [],
    dateRange: [filters.dateRange.startDate, filters.dateRange.endDate],
  });

  const { data: signalDefinitionsData } =
    useGetTopicForSlaBrandInboundGraphViewQuery({
      variables: {
        topicId: filters.topicIds?.[0] || '',
      },
      skip: !filters.topicIds?.[0],
    });

  const handleItemClick = (id: string) => {
    const signalDefinition = allSignalDefinitions.find(
      (sd) => sd.signalDefinition.id === id,
    );
    setSelectedSignalInfo({
      id,
      type: signalDefinition ? 'signalDefinition' : 'signalCategoryOption',
    });
    onPostsModalOpen();
  };

  if (loading) {
    return <SLABrandInboundGraphViewSkeleton />;
  }

  return (
    <Card
      sx={{
        borderRadius: 5,
        my: 2,
        p: 6,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={1}
          mb={3}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Graph
            <CustomTooltip
              title={`The total count of ${
                sortKey ===
                BrandInboundFiltersInputForGraphDataAggregationType.TotalPostCount
                  ? 'posts'
                  : sortKey ===
                    BrandInboundFiltersInputForGraphDataAggregationType.TotalEngagement
                  ? 'engagement'
                  : 'impressions'
              } for the selected date range.`}
            >
              <Box display="flex" alignItems="center">
                <IconBoldInfoCircle size={16} />
              </Box>
            </CustomTooltip>
          </Typography>
          <Typography
            variant="headline-md"
            fontSize={theme.spacing(5)}
            color={theme.colors?.utility[700]}
          >
            {/* definition */}
          </Typography>
          <Typography variant="headline-lg" fontSize={theme.spacing(8)}>
            {formatBigNumber(graphData?.totalCount || 0)}{' '}
            <Box component="span" color={theme.colors?.utility[600]}>
              {sortKey ===
              BrandInboundFiltersInputForGraphDataAggregationType.TotalPostCount
                ? 'posts'
                : sortKey ===
                  BrandInboundFiltersInputForGraphDataAggregationType.TotalEngagement
                ? 'total engagement'
                : 'view count'}
            </Box>
          </Typography>
        </Box>
        <Box>
          <ContextMenu
            sx={{
              '& .context-menu-item': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={[
              {
                renderOption: () => (
                  <RadioMenuItem
                    label="Total post count"
                    value={
                      BrandInboundFiltersInputForGraphDataAggregationType.TotalPostCount
                    }
                    checked={
                      sortKey ===
                      BrandInboundFiltersInputForGraphDataAggregationType.TotalPostCount
                    }
                    sx={{
                      p: `${theme.spacing(2, 3)}`,
                    }}
                  />
                ),
                onClick: () => {
                  setSortKey(
                    BrandInboundFiltersInputForGraphDataAggregationType.TotalPostCount,
                  );
                },
              },
              {
                renderOption: () => (
                  <RadioMenuItem
                    label="Total engagement"
                    value={
                      BrandInboundFiltersInputForGraphDataAggregationType.TotalEngagement
                    }
                    checked={
                      sortKey ===
                      BrandInboundFiltersInputForGraphDataAggregationType.TotalEngagement
                    }
                    sx={{
                      p: `${theme.spacing(2, 3)}`,
                    }}
                  />
                ),
                onClick: () => {
                  setSortKey(
                    BrandInboundFiltersInputForGraphDataAggregationType.TotalEngagement,
                  );
                },
              },
              {
                renderOption: () => (
                  <RadioMenuItem
                    label="Total view count"
                    value={
                      BrandInboundFiltersInputForGraphDataAggregationType.TotalImpressions
                    }
                    checked={
                      sortKey ===
                      BrandInboundFiltersInputForGraphDataAggregationType.TotalImpressions
                    }
                    sx={{
                      p: `${theme.spacing(2, 3)}`,
                    }}
                  />
                ),
                onClick: () => {
                  setSortKey(
                    BrandInboundFiltersInputForGraphDataAggregationType.TotalImpressions,
                  );
                },
              },
            ]}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  height: 'fit-content',
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': { backgroundColor: theme.colors?.utility[275] },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  {sortKey ===
                    BrandInboundFiltersInputForGraphDataAggregationType.TotalPostCount &&
                    'Total post count'}
                  {sortKey ===
                    BrandInboundFiltersInputForGraphDataAggregationType.TotalEngagement &&
                    'Total engagement'}
                  {sortKey ===
                    BrandInboundFiltersInputForGraphDataAggregationType.TotalImpressions &&
                    'Total impressions'}
                </Typography>
              </Button>
            )}
          />
        </Box>
      </Box>
      {!!graphData?.chartDataPoints.length && (
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={processedGraphData || []}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke={theme.colors?.utility[300]}
            />
            <XAxis dataKey="X" />
            <YAxis
              domain={[
                Math.min(
                  ...((graphData?.chartDataPoints || []).map((x) =>
                    Math.floor(x.Y * 0.9),
                  ) || [0]),
                ),
                Math.max(
                  ...((graphData?.chartDataPoints || []).map((x) =>
                    Math.ceil(x.Y * 1.1),
                  ) || [0]),
                ),
              ]}
              tickFormatter={(value) => formatBigNumber(value)}
            />{' '}
            {/** Range on y axis - For showing All time high */}
            <Tooltip
              formatter={(value, name) => [
                parseFloat(Number(value).toFixed(2)),
                'Mentions',
              ]}
            />
            <Area
              type="monotone"
              dataKey="Y"
              stroke={theme.colors?.utility['yellow-4']}
              strokeWidth={3}
              fill={theme.colors?.utility['yellow-1']}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}

      {!!graphData?.multiChartDataPoints?.length && (
        <>
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            flexWrap="wrap"
            mt={4}
            mb={6}
          >
            {graphData.multiChartDataPoints.map((dataset, index) => (
              <Chip
                key={dataset.id}
                label={dataset.label}
                onClick={() => {
                  handleItemClick(dataset.id);
                }}
                onDelete={() => {
                  setHiddenDatasets((prev) =>
                    prev.includes(dataset.label)
                      ? prev.filter((label) => label !== dataset.label)
                      : [...prev, dataset.label],
                  );
                }}
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 600,
                  border: 'none',
                  p: theme.spacing(1, 2),
                  backgroundColor: getColorByIndex(index, true),
                  color: getColorByIndex(index, false),
                  opacity: hiddenDatasets.includes(dataset.label) ? 0.5 : 1,
                  cursor: 'pointer',
                }}
                deleteIcon={
                  hiddenDatasets.includes(dataset.label) ? (
                    <IconLinearEyeSlash
                      style={{
                        color: getColorByIndex(index, false),
                      }}
                      size={16}
                    />
                  ) : (
                    <IconBoldCloseCircle
                      style={{
                        color: getColorByIndex(index, false),
                      }}
                      size={16}
                    />
                  )
                }
              />
            ))}

            <ContextMenu
              renderButton={(open) => (
                <IconBoldAddCircle size={20} style={{ cursor: 'pointer' }} />
              )}
              options={[
                {
                  renderCustomComponent: () => (
                    <SLAAnalyticsFilterByCategory
                      variant="normal"
                      signalDefinitions={(
                        signalDefinitionsData?.topic?.topicSignalDefinitions ||
                        []
                      ).map((signal) => ({
                        id: signal.signalDefinition.id,
                        title: signal.signalDefinition.title,
                        options: signal.signalDefinition.options,
                      }))}
                      selectedCategoryIds={selectedCategoryIds}
                      selectedCategoryOptionIds={selectedCategoryOptionIds}
                      onChange={(categoryIds, optionIds) => {
                        setSelectedCategoryIds(categoryIds);
                        setSelectedCategoryOptionIds(optionIds);

                        const newDatasets =
                          graphData?.multiChartDataPoints?.filter(
                            (dataset) =>
                              categoryIds.includes(dataset.id) ||
                              optionIds.includes(dataset.id),
                          );

                        if (newDatasets) {
                          setHiddenDatasets(
                            graphData?.multiChartDataPoints
                              ?.filter(
                                (d) =>
                                  !newDatasets
                                    .map((nd) => nd.id)
                                    .includes(d.id),
                              )
                              .map((d) => d.label) || [],
                          );
                        }
                      }}
                    />
                  ),
                },
              ]}
            />
          </Box>

          <SLAMultiLineChartRenderer
            multiGraphData={graphData.multiChartDataPoints.filter(
              (dataset) => !hiddenDatasets.includes(dataset.label),
            )}
            dateRange={[filters.dateRange.startDate, filters.dateRange.endDate]}
            onClick={handleItemClick}
          />
        </>
      )}

      {selectedSignalInfo && allSignalDefinitions && (
        <SLABrandInboundGraphPostsDialogView
          signalDefinitionId={
            selectedSignalInfo?.type === 'signalDefinition'
              ? selectedSignalInfo?.id
              : undefined
          }
          signalCategoryOptionId={
            selectedSignalInfo?.type === 'signalCategoryOption'
              ? selectedSignalInfo?.id
              : undefined
          }
          allSignalDefinitions={allSignalDefinitions.map(
            (x) => x.signalDefinition,
          )}
          filters={filters}
          isOpen={isPostsModalOpen}
          onClose={onPostsModalClose}
        />
      )}
    </Card>
  );
};
