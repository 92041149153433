import React from 'react';
import { TextareaAutosize as MuiTextareaAutosize, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';
import { Props } from './types';

const StyledTextareaAutosize = styled(MuiTextareaAutosize)<Props>`
  width: 100%;
  border-color: ${theme.colors?.utility[400]};
  border-radius: 8px;
  padding: 12px;
  box-sizing: border-box;
  resize: none;
  font-size: 14px;
  transition: background-color 0.2s;

  ${(props) =>
    props.variant === 'inline'
      ? `
        padding: 0;
        outline: 0;
        border: 0;
        border-radius: 0;

        &:hover:not(:read-only) {
          background-color: ${theme.colors?.utility[300]}
        }
        
        &:disabled {
          background-color: transparent;
        }
      `
      : ''}
`;

export const TextareaAutosize = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
    return <StyledTextareaAutosize maxRows={2} {...props} ref={ref} />;
  },
);
