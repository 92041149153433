import { ApolloCache, Cache } from '@apollo/client';
import { CustomOperationContext } from 'hooks/useApolloClient';

/**
 * Util method to get custom operation context with the correct type
 */
export const getCustomOperationContext = (
  contextValues: CustomOperationContext,
) => {
  return contextValues;
};

export const modifyObject = (
  cache: ApolloCache<any>,
  id: string,
  __typename: string,
  fields: Cache.ModifyOptions['fields'],
) => {
  const normalizedId = cache.identify({ id, __typename });
  cache.modify({ id: normalizedId, fields });
};

export const evictObject = (
  cache: ApolloCache<any>,
  id: string,
  __typename: string,
) => {
  const normalizedId = cache.identify({ id, __typename });
  cache.evict({ id: normalizedId });

  // Custom evict for search hits
  switch (__typename) {
    case 'PostModel': {
      const normalizedId = cache.identify({
        __typename: 'PostSearchHit',
        item: {
          __typename: 'PostModel',
          id,
        },
      });

      cache.evict({ id: normalizedId });

      break;
    }
    case 'CollectionModel': {
      const normalizedId = cache.identify({
        __typename: 'CollectionSearchHit',
        item: {
          __typename: 'CollectionModel',
          id,
        },
      });

      cache.evict({ id: normalizedId });

      break;
    }
    default: {
      break;
    }
  }

  cache.gc();
};
