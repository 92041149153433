export const matchRoute = (path: string, route: string) => {
  const pathParts = path.split('/');
  const routeParts = route.split('/');

  if (routeParts.length !== pathParts.length) {
    return false;
  }

  for (let i = 0; i < routeParts.length; i++) {
    if (routeParts[i] !== pathParts[i] && !routeParts[i].startsWith(':')) {
      return false;
    }
  }

  return true;
};
