import { Box, TableCell, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledTableHeadCell = styled(TableCell)(() => ({
  borderWidth: 0,
  fontSize: 13,
  fontWeight: 600,
  paddingLeft: 0,
  paddingBottom: theme.spacing(2.5),
  color: theme.colors?.utility[700],
  fontFamily: theme.typography['headline-xxs'].fontFamily,
}));

export const StyledTableBodyCell = styled(TableCell)(() => ({
  borderColor: theme.colors?.utility[300],
  paddingLeft: 0,
}));

export const StyledContextMenuOptionWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
}));
