import { IconButton } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconCustomTodo } from 'components/icons/components/custom/IconCustomTodo';
import { PermissionMemberItem } from 'features/permission';
import { UserProfileFragmentSocialListeningPermissionMemberFragment } from 'graphql/generated';
import React from 'react';
import { theme } from 'styles/theme';

type Props = {
  user: UserProfileFragmentSocialListeningPermissionMemberFragment;
  onToggleSelect: (
    user: UserProfileFragmentSocialListeningPermissionMemberFragment,
  ) => void;
  isSelected: boolean;
  slOrgId: string;
};

export const SocialListeningPermissionMemberUnselected = ({
  user,
  onToggleSelect,
  isSelected,
  slOrgId,
}: Props) => {
  return (
    <PermissionMemberItem
      entityOrganizationId={slOrgId}
      userProfile={user}
      type="custom"
      readonly={false}
      endAdornmentProps={{
        custom: {
          endAdornment: (
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => onToggleSelect(user)}
            >
              {isSelected ? (
                <IconBoldTickCircle
                  size={20}
                  color={theme.colors?.primary.black}
                />
              ) : (
                <IconCustomTodo size={20} color={theme.colors?.primary.black} />
              )}
            </IconButton>
          ),
        },
        suggested: {
          toggleSelect: () => {},
          isSelected: false,
        },
      }}
    />
  );
};
