import { gql, useApolloClient } from '@apollo/client';
import {
  useAddPostsToCollectionForCollectionPostContextMenuMutation,
  useCreatePostsFavoriteForCollectionPostContextMenuMutation,
  useDeleteCollectionsForCollectionPostContextMenuMutation,
  useDeletePostsFavoriteForCollectionPostContextMenuMutation,
  useDeletePostsForCollectionPostContextMenuMutation,
} from 'graphql/generated';
import { evictObject, modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeletePostsForCollectionPostContextMenu($data: DeletePostsInput!) {
    deletePosts(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeleteCollectionsForCollectionPostContextMenu(
    $data: DeleteCollectionsInput!
  ) {
    deleteCollections(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation AddPostsToCollectionForCollectionPostContextMenu(
    $data: SavePostsToCollectionsInput!
  ) {
    savePostsToCollections(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation CreatePostsFavoriteForCollectionPostContextMenu(
    $data: CreatePostsFavoriteInput!
  ) {
    createPostsFavorite(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeletePostsFavoriteForCollectionPostContextMenu(
    $data: DeletePostsFavoriteInput!
  ) {
    deletePostsFavorite(data: $data) {
      success
      message
    }
  }
`;

export const useCollectionPostContextMenuHandlers = () => {
  const client = useApolloClient();

  const [deletePosts] = useDeletePostsForCollectionPostContextMenuMutation();
  const [deleteCollections] =
    useDeleteCollectionsForCollectionPostContextMenuMutation();
  const [addPostsToCollection] =
    useAddPostsToCollectionForCollectionPostContextMenuMutation();
  const [createPostsFavorite] =
    useCreatePostsFavoriteForCollectionPostContextMenuMutation();
  const [deletePostsFavorite] =
    useDeletePostsFavoriteForCollectionPostContextMenuMutation();

  const onDeletePosts = (postIds: string[]) => {
    deletePosts({
      variables: { data: { postIds } },
      update: (cache) => {
        postIds.forEach((id) => {
          evictObject(cache, id, 'PostModel');
        });
      },
    });
  };

  const onDeleteCollections = (collectionIds: string[]) => {
    deleteCollections({
      variables: { data: { collectionIds } },
      update: (cache) => {
        collectionIds.forEach((id) => {
          evictObject(cache, id, 'CollectionModel');
        });
      },
    });
  };

  const onRemovePostsFromCollection = (
    postIds: string[],
    collectionId: string,
  ) => {
    deletePosts({
      variables: { data: { postIds, collectionId } },
      update: (cache) => {
        postIds.forEach((id) => {
          evictObject(cache, id, 'PostModel');
        });
      },
    });
  };

  const onTogglePostsCollection = async (
    postIds: string[],
    collectionId: string,
    willAdd: boolean,
  ) => {
    if (willAdd) {
      await addPostsToCollection({
        variables: {
          data: {
            collectionIds: [collectionId],
            postIds,
          },
        },
      });
    } else {
      await deletePosts({
        variables: {
          data: {
            postIds,
            collectionId,
          },
        },
      });
    }
  };

  const onTogglePostsFavorite = async (
    postIds: string[],
    isFavorite: boolean,
  ) => {
    if (isFavorite) {
      createPostsFavorite({
        variables: {
          data: {
            postIds,
          },
        },
      });
    } else {
      deletePostsFavorite({
        variables: {
          data: {
            postIds,
          },
        },
      });
    }

    postIds.forEach((id) => {
      modifyObject(client.cache, id, 'PostModel', {
        isFavorite: () => isFavorite,
      });
    });
  };

  return {
    onDeletePosts,
    onDeleteCollections,
    onRemovePostsFromCollection,
    onTogglePostsCollection,
    onTogglePostsFavorite,
  };
};
