import { gql } from '@apollo/client';
import { useDeleteContentIdeaForContentIdeaContextMenuMutation } from 'graphql/generated';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeleteContentIdeaForContentIdeaContextMenu(
    $data: DeleteContentIdeaInput!
  ) {
    deleteContentIdea(data: $data) {
      success
      message
    }
  }
`;

export const useContentIdeaHandlers = () => {
  const [deleteContentIdea] =
    useDeleteContentIdeaForContentIdeaContextMenuMutation();

  const onDeleteContentIdea = (contentIdeaId: string) => {
    deleteContentIdea({
      variables: {
        data: {
          contentIdeaId,
        },
      },
      update: (cache) => {
        evictObject(cache, contentIdeaId, 'ContentIdeaModel');
      },
    });
  };

  return {
    onDeleteContentIdea,
  };
};
