import { UserInfo } from 'features/collaboration';
import { gql } from '@apollo/client';
import {
  ContentIdeaFragmentContentIdeaViewersFragmentDoc,
  useGetContentIdeaViewersQuery,
} from 'graphql/generated';
import { useEffect, useMemo } from 'react';
import { ContentIdeaViewers } from './ContentIdeaViewers';

// eslint-disable-next-line
gql`
  query GetContentIdeaViewers($id: String!) {
    contentIdea(id: $id) {
      ...ContentIdeaFragmentContentIdeaViewers
    }
  }
  ${ContentIdeaFragmentContentIdeaViewersFragmentDoc}
`;

type ContentIdeaCollaborativeViewersProps = {
  contentIdeaId: string;
  onlineUsers: UserInfo[];
};

export const ContentIdeaCollaborativeViewers = (
  props: ContentIdeaCollaborativeViewersProps,
) => {
  const { contentIdeaId, onlineUsers } = props;

  const { data, loading, refetch } = useGetContentIdeaViewersQuery({
    variables: {
      id: contentIdeaId,
    },
  });
  const isFirstLoading = loading && !data;

  useEffect(() => {
    // waiting about 3 seconds to make sure our webhooks was called and DB was updated
    setTimeout(() => {
      refetch();
    }, 3000);
  }, [onlineUsers, refetch]);

  const contentIdea = useMemo(() => {
    return (
      data?.contentIdea || {
        id: contentIdeaId,
        viewers: [],
      }
    );
  }, [contentIdeaId, data]);

  return isFirstLoading ? null : (
    <ContentIdeaViewers contentIdea={contentIdea} onlineUsers={onlineUsers} />
  );
};
