import { Box, Typography } from '@mui/material';
import { WordLimitedInputArea } from 'features/socialMediaListening/components';
import React, { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingCommunicationStrategyProps = {
  savingData?: boolean;
  value: string;
  setValue: (value: string) => void;
  onNext: () => void;
  type?: 'creator' | 'topic';
};

export const SocialMediaListeningOnboardingCommunicationStrategy = ({
  savingData,
  value,
  setValue,
  onNext,
  type,
}: SocialMediaListeningOnboardingCommunicationStrategyProps) => {
  const [hasError, setHasError] = useState(false);
  const validateAndProceed = () => {
    if (!value.trim().length) {
      setHasError(true);
    } else {
      setHasError(false);
      onNext();
    }
  };

  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      {type && (
        <Typography
          variant="body-lg"
          sx={{
            fontWeight: 600,
            width: 'fit-content',
            padding: theme.spacing(1, 3),
            bgcolor: 'rgba(250, 243, 236, 0.20)',
            borderRadius: theme.spacing(4),
          }}
        >
          {type === 'topic' && 'Topic Insights'}
          {type === 'creator' && 'Creator Focus'}
        </Typography>
      )}
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        What’s your communication strategy? 🖋️
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        Write how you would like to respond, what’s your brand voice
      </Typography>

      <WordLimitedInputArea
        maxWords={100}
        componentProps={{
          wordUsage: {
            sx: {
              color: theme.colors?.utility[500],
            },
          },
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            validateAndProceed();
          }
        }}
        autoFocus
        placeholder="Quirky, fun, succinct"
        multiline
        minRows={10}
        maxRows={16}
        sx={{
          mt: 4,
          '.MuiOutlinedInput-root': {
            ...(hasError
              ? { border: `2px solid ${theme.colors?.utility['pink-3']}` }
              : {}),
            borderRadius: theme.spacing(4),
            py: `${theme.spacing(8)} !important`,
            px: `${theme.spacing(8)} !important`,
            bgcolor: 'rgba(255, 255, 255, 0.10)',
            color: theme.colors?.primary.parchment,

            input: {
              p: '0 !important',
              ...theme.typography['body-xl'],
            },

            '.MuiOutlinedInput-notchedOutline': {
              display: 'none !important',
            },
          },
        }}
      />

      <RenderNextButton onNextClick={validateAndProceed} loading={savingData} />
    </Box>
  );
};
