import { gql } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { IconOutlineArrowLeft2 } from 'components/icons/components/outline/IconOutlineArrowLeft2';
import {
  SearchableEntityType,
  SearchType,
  usePaginatedLinksForLinkSearchResultsQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import {
  LINK_SEARCH_RESULT_FRAGMENT_SECTION_VIEW,
  SearchListItemView,
} from 'features/search';

type Props = {
  searchType: SearchType;
  searchStr: string;
  onNavigateBack: () => void;
  elRefs: React.MutableRefObject<(HTMLElement | null)[]>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query PaginatedLinksForLinkSearchResults($data: UniversalSearchInput!) {
    universalSearch(data: $data) {
      linkSearchResult {
        ...LinkSearchResultFragmentSectionView
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${LINK_SEARCH_RESULT_FRAGMENT_SECTION_VIEW}
`;

const PAGE_SIZE = 10;

export const LinkSearchResults = (props: Props) => {
  const { searchStr, searchType, onNavigateBack, elRefs } = props;
  const [hasMore, setHasMore] = useState(true);

  const variables = {
    entityTypes: [SearchableEntityType.Links],
    limit: PAGE_SIZE,
    searchType,
    text: searchStr,
  };

  const { data: linksData, fetchMore } =
    usePaginatedLinksForLinkSearchResultsQuery({
      variables: {
        data: {
          ...variables,
        },
      },
    });

  const fetchMoreLinks = () => {
    if (!hasMore || !linksData?.universalSearch.linkSearchResult.hits.length) {
      return;
    }

    fetchMore({
      variables: {
        data: {
          ...variables,
          after:
            String(linksData?.universalSearch.pageInfo.endCursor || 0) || '0',
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          fetchMoreResult.universalSearch.linkSearchResult.hits.length === 0
        ) {
          setHasMore(false);
        }
        return {
          ...fetchMoreResult,
          universalSearch: {
            ...fetchMoreResult.universalSearch,
            linkSearchResult: {
              ...fetchMoreResult.universalSearch.linkSearchResult,
              totalCount:
                fetchMoreResult.universalSearch.linkSearchResult.totalCount,
              hits: [
                ...(prev.universalSearch.linkSearchResult?.hits || []),
                ...(fetchMoreResult.universalSearch.linkSearchResult?.hits ||
                  []),
              ],
            },
          },
        };
      },
    });
  };

  useEffect(() => {
    setHasMore(true);
  }, [searchStr]);

  let itemIndex = 0;

  return (
    <Box>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        color={theme.colors?.utility[700]}
        mb={4}
        sx={{ cursor: 'pointer' }}
        onClick={onNavigateBack}
      >
        <IconOutlineArrowLeft2 size={12} />
        <Typography variant="body-xs" fontWeight={600}>
          Links ({linksData?.universalSearch.linkSearchResult.totalCount})
        </Typography>
      </Box>

      <Box maxHeight={450} sx={{ overflowY: 'auto' }} mt={0}>
        <InfiniteScroll
          threshold={100}
          loadMore={() => fetchMoreLinks()}
          useWindow={false}
          hasMore={hasMore}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress
                size={24}
                sx={{ color: theme.colors?.primary.maroon }}
              />
            </Box>
          }
        >
          <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={2}>
            {(linksData?.universalSearch.linkSearchResult?.hits || []).map(
              (link) => (
                <SearchListItemView
                  key={link.item.id}
                  ref={(el) => {
                    elRefs.current[itemIndex] = el;
                    itemIndex++;
                  }}
                  variant="preview"
                  searchHit={link}
                  type={SearchableEntityType.Links}
                  searchString={searchStr}
                />
              ),
            )}
          </Box>
        </InfiniteScroll>
      </Box>
    </Box>
  );
};
