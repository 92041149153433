import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { AssigneeList } from 'contexts/commands/hooks/useTaskCommands/components/CreateNewTask/components/AssigneeList';
import { DueDateSelectButton } from 'contexts/commands/hooks/useTaskCommands/components/CreateNewTask/components/DueDateSelectButton';
import { PrioritySelectButton } from 'contexts/commands/hooks/useTaskCommands/components/CreateNewTask/components/PrioritySelectButton';
import { StatusSelectButton } from 'contexts/commands/hooks/useTaskCommands/components/CreateNewTask/components/StatusSelectButton';
import {
  TaskFragmentCreateSubtaskFragment,
  UserProfileFragmentAssigneeListFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { StyledTaskNameInput } from '../../styles';

// eslint-disable-next-line
gql`
  fragment TaskFragmentCreateSubtask on TaskModel {
    id
    name
    startDate
    endDate
    status
    priority
    taskMembers {
      ...UserProfileFragmentAssigneeList
    }
  }
  ${UserProfileFragmentAssigneeListFragmentDoc}
`;

interface Props {
  task: TaskFragmentCreateSubtaskFragment;
  onUpdate: (task: TaskFragmentCreateSubtaskFragment) => void;
}

export const Subtask = ({ task, onUpdate }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <StatusSelectButton
          value={task.status}
          showOnlyIcon
          onChange={(status) => onUpdate({ ...task, status })}
          iconSize={16}
        />
        <StyledTaskNameInput
          value={task.name}
          disableUnderline
          placeholder="Task name"
          fullWidth
          autoFocus
          onChange={(e) => {
            onUpdate({ ...task, name: e.target.value });
          }}
          sx={{
            color: theme.colors?.utility[900],
            ...theme.typography['subhead-xl'],
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <DueDateSelectButton
          value={[task.startDate, task.endDate]}
          onChange={([startDate, endDate]) => {
            onUpdate({ ...task, startDate, endDate });
          }}
          showOnlyIcon
          dateTextVariant="due"
          componentsProps={{
            icon: {
              size: 16,
            },
            typography: {
              variant: 'subhead-sm',
            },
          }}
        />
        <PrioritySelectButton
          showIconOnly
          value={task.priority || undefined}
          onChange={(priority) => {
            onUpdate({ ...task, priority });
          }}
          componentsProps={{
            icon: {
              color: theme.colors?.utility[700],
            },
          }}
        />
        <AssigneeList
          assignees={task.taskMembers}
          onChange={(assignees) => {
            onUpdate({ ...task, taskMembers: assignees });
          }}
          componentsProps={{
            icon: {
              size: 16,
            },
            avatarGroup: {
              avatarSize: 16,
            },
          }}
        />
      </Box>
    </Box>
  );
};
