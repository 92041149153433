import { Box, SxProps, TextField } from '@mui/material';
import { InputProps } from '@mui/material/Input';
import { typography } from 'components/common/Typography/styles';
import { theme } from 'styles/theme';

export interface CommentSearchProps {
  sx?: SxProps;
  InputProps?: Partial<InputProps>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CommentSearch = (props: CommentSearchProps) => {
  const { sx, InputProps, onChange } = props;

  return (
    <Box sx={sx}>
      <TextField
        placeholder="Search"
        sx={{
          backgroundColor: theme.colors?.utility[250],
          borderRadius: 6,
          px: 4,
          width: '100%',
          '& input': {
            height: '36px',
            p: 0,
            ...typography['subhead-lg'],
          },
          '& fieldset': {
            display: 'none',
          },
          '& > div': {
            p: 0,
          },
        }}
        onChange={onChange}
        InputProps={InputProps}
      />
    </Box>
  );
};
