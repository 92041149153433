import { gql } from '@apollo/client';
import { SxProps, TableCell } from '@mui/material';
import { CollectionSummary } from 'features/collection/components';
import {
  CollectionFragmentCollectionSummaryFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellActivityFragment,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRowCellActivity on CollectionModel {
    id
    ...CollectionFragmentCollectionSummary
  }
  ${CollectionFragmentCollectionSummaryFragmentDoc}
`;

export type JuiceboxTableCollectionTableRowCellActivityProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowCellActivityFragment;
  sx?: SxProps;
};

export const JuiceboxTableCollectionTableRowCellActivity = (
  props: JuiceboxTableCollectionTableRowCellActivityProps,
) => {
  const { collection, sx = {} } = props;

  return (
    <TableCell sx={{ ...sx }}>
      <CollectionSummary collection={collection} />
    </TableCell>
  );
};
