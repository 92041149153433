import { Box, CircularProgress, Typography } from '@mui/material';
import { CUSTOM_COLLECTION } from 'features/collection/constants';
import { useCustomCollectionNestedTableData } from 'features/juiceboxTable/hooks';
import { CollectionFilterType, PostFilterType } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { JuiceboxTableBaseTableView } from '../baseTable';

export type JuiceboxTableCustomCollectionTableViewProps = {
  collectionId: string;
  nestedLevel: number;
  style?: React.CSSProperties;
  hideHeader?: boolean;
};

export const JuiceboxTableCustomCollectionTableView = ({
  collectionId,
  nestedLevel,
  style,
  hideHeader = true,
}: JuiceboxTableCustomCollectionTableViewProps) => {
  const [postFilterType, setPostFilterType] = useState<PostFilterType | null>();
  const [collectionFilterType, setCollectionFilterType] =
    useState<CollectionFilterType | null>();

  const { collections, posts, fetchNextPage, loading, hasMore, refetchPosts } =
    useCustomCollectionNestedTableData({
      postFilterType: postFilterType || PostFilterType.OrganizationPosts,
      collectionFilterType: collectionFilterType || undefined,
      skipCollectionFetch: collectionId !== CUSTOM_COLLECTION.CONTENT_CALENDAR,
    });

  useEffect(() => {
    if (collectionId === CUSTOM_COLLECTION.ALL_POSTS) {
      setPostFilterType(PostFilterType.OrganizationPosts);
    } else if (collectionId === CUSTOM_COLLECTION.MY_POSTS) {
      setPostFilterType(PostFilterType.MyPosts);
    } else if (collectionId === CUSTOM_COLLECTION.SAVED) {
      setPostFilterType(PostFilterType.MyFavoritePosts);
    } else if (collectionId === CUSTOM_COLLECTION.SAVED_TRENDS) {
      setPostFilterType(PostFilterType.MySavedTrends);
    } else if (collectionId === CUSTOM_COLLECTION.CONTENT_CALENDAR) {
      setPostFilterType(PostFilterType.ContentCalendar);
      setCollectionFilterType(CollectionFilterType.ContentCalendar);
    }
    refetchPosts();
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!posts.length && !collections.length) {
    return (
      <Box
        py={8}
        sx={{
          textAlign: 'center',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body-xl">
            No collections or posts found.
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <JuiceboxTableBaseTableView
      hideHeader={hideHeader}
      nestedLevel={nestedLevel + 1}
      collections={collections}
      posts={posts}
      hasMore={hasMore}
      loadMore={fetchNextPage}
      style={{
        maxHeight: 'calc(100vh - 452px)',
        ...style,
      }}
    />
  );
};
