import { gql } from '@apollo/client';
import { Box, Chip, IconButton, Menu, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import {
  GenderDemographicsBarColorMap,
  GenderDemographicsChipBackgroundColorMap,
  GenderDemographicsColorMap,
  GenderDemographicsMap,
  GenerationDemographicsChipBackgroundColorMap,
  GenerationDemographicsColorMap,
  GenerationDemographicsMap,
} from 'features/socialListeningAnalytics/constants';
import {
  SocialPostCreatorDemographicFragmentSlaCreatorDemographicsFragment,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import {
  SLAAnalyticsFilterByDemographics,
  SLAAnalyticsFilterByDemographicsProps,
} from '../slaAnalyticsFilters';

// eslint-disable-next-line
gql`
  fragment SocialPostCreatorDemographicFragmentSLACreatorDemographics on SocialPostCreatorDemographicModel {
    id
    generation
    gender
  }
`;

interface Props
  extends Pick<SLAAnalyticsFilterByDemographicsProps, 'onChange'> {
  creatorDemographics: SocialPostCreatorDemographicFragmentSlaCreatorDemographicsFragment[];
  selectedGender: SocialPostGenderDemographic[];
  selectedGeneration: SocialPostGenerationDemographic[];
}

const RoundedTopBar = (props: any) => {
  const radius = props?.radius ?? 16;
  const { fill, x, y, width, height } = props;

  // Function to generate path with border-radius on top-left and top-right
  const getPathWithTopRoundedCorners = (x, y, width, height, radius) => {
    return `
      M ${x + radius},${y} 
      L ${x + width - radius},${y} 
      Q ${x + width},${y} ${x + width},${y + radius}
      L ${x + width},${y + height}
      L ${x},${y + height}
      L ${x},${y + radius}
      Q ${x},${y} ${x + radius},${y}
      Z
    `;
  };

  return (
    <path
      d={getPathWithTopRoundedCorners(x, y, width, height, radius)}
      stroke="none"
      fill={fill}
    />
  );
};

export const SLACreatorDemographics = ({
  creatorDemographics,
  selectedGender,
  selectedGeneration,
  onChange,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // get the variant of the chart if both gender and generation are selected then we need to show stacked bar chart else show the bar chart
  const variant = useMemo(() => {
    if (selectedGender.length > 0 && selectedGeneration.length > 0) {
      return 'both';
    }
    return selectedGender.length > 0 ? 'gender' : 'generation';
  }, [selectedGender, selectedGeneration]);

  // process the data based on the variant
  const processedData = useMemo(() => {
    if (variant === 'gender') {
      return selectedGender.map((gender) => ({
        name: GenderDemographicsMap[gender],
        count: creatorDemographics.filter(({ gender: g }) => g === gender)
          .length,
        color: GenderDemographicsColorMap[gender],
      }));
    }
    if (variant === 'generation') {
      return selectedGeneration.map((generation) => ({
        name: GenerationDemographicsMap[generation],
        count: creatorDemographics.filter(
          ({ generation: g }) => g === generation,
        ).length,
      }));
    }

    return selectedGeneration.map((generation) => {
      const counts = creatorDemographics.reduce(
        (acc, { generation: g, gender: gg }) => {
          if (g === generation) {
            if (selectedGender.includes(gg)) {
              acc[gg] += 1;
            }
          }
          return acc;
        },
        {
          [SocialPostGenderDemographic.Female]: 0,
          [SocialPostGenderDemographic.Male]: 0,
          [SocialPostGenderDemographic.NotSpecified]: 0,
        },
      );

      return {
        name: GenerationDemographicsMap[generation],
        dataKey: generation,
        [SocialPostGenderDemographic.NotSpecified]:
          counts[SocialPostGenderDemographic.NotSpecified],
        [SocialPostGenderDemographic.Male]:
          counts[SocialPostGenderDemographic.Male],
        [SocialPostGenderDemographic.Female]:
          counts[SocialPostGenderDemographic.Female],
      };
    });
  }, [variant, selectedGeneration, selectedGender, creatorDemographics]);

  const getChipStyle = (backgroundColor: string, color: string) => {
    return {
      backgroundColor,
      color,
      ...theme.typography['headline-xs'],
      border: 'none',
      '& .MuiChip-label': {
        paddingLeft: theme.spacing(1),
      },
      '&:before': {
        content: '""',
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: color,
        marginLeft: theme.spacing(2),
      },
    };
  };

  const getDeleteIcon = (color: string) => {
    return (
      <IconBoldCloseCircle
        size={16}
        style={{ color, opacity: 0.7, marginRight: theme.spacing(2) }}
      />
    );
  };

  // check if the current segment is the top-most segment in the stack
  const checkIsTopSegment = (props, gender, genderKeys) => {
    // Check if the current segment (gender) has a positive value
    if (props[SocialPostGenderDemographic[gender]] > 0) {
      // Check if the current gender is the last non-zero segment
      let isTop = true;

      // Iterate over the keys and check if any segment above it is non-zero
      for (let i = 0; i < genderKeys.length; i++) {
        const key = genderKeys[i];
        if (key !== gender && props[SocialPostGenderDemographic[key]] > 0) {
          isTop = false;
          break; // Exit early if we find a non-zero value above the current gender
        }
      }

      return isTop;
    }
    return false;
  };

  const getBarRadius = (props, gender) => {
    // Generate the keys in the order of stacking (e.g., NotSpecified -> Male -> Female)
    const genderKeys = Object.values(SocialPostGenderDemographic);

    // Find the current gender's position in the stack
    const genderIndex = genderKeys.indexOf(gender);

    // Filter the gender keys to only include the ones above the current gender
    const genderKeysAbove = genderKeys.slice(0, genderIndex);

    // Check if the current segment is the top-most segment in the stack
    const isTopSegment = checkIsTopSegment(props, gender, genderKeysAbove);

    if (isTopSegment) {
      // Apply rounded top corners for the top-most segment
      return <RoundedTopBar {...props} />;
    }

    // If not the top-most segment, return with flat corners (radius=0)
    return <RoundedTopBar {...props} radius={0} />;
  };

  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box pb={1} display="flex" alignItems="center" gap={2}>
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Creator’s Demographic
        </Typography>
        <Tooltip
          title={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="subhead-sm">
                Creator’s Demographic
              </Typography>
              <Typography variant="subhead-sm">
                Gender and generational data
                <br /> (Generation G) of creators.
              </Typography>
            </Box>
          }
        >
          <Box display="flex" alignItems="center">
            <IconBoldInfoCircle size={16} color={theme.colors?.utility[700]} />
          </Box>
        </Tooltip>
      </Box>
      <Box display="flex" gap={2} flexWrap="wrap">
        {selectedGender.map((gender) => (
          <Chip
            key={gender}
            label={GenderDemographicsMap[gender]}
            sx={getChipStyle(
              GenderDemographicsChipBackgroundColorMap[gender],
              GenderDemographicsColorMap[gender] ?? '',
            )}
            onDelete={() => {
              onChange({
                selectedGeneration,
                selectedGender: selectedGender.filter((g) => g !== gender),
              });
            }}
            deleteIcon={getDeleteIcon(GenderDemographicsColorMap[gender] ?? '')}
          />
        ))}
        {selectedGeneration.map((generation) => (
          <Chip
            key={generation}
            label={GenerationDemographicsMap[generation]}
            sx={getChipStyle(
              GenerationDemographicsChipBackgroundColorMap[generation],
              GenerationDemographicsColorMap[generation],
            )}
            onDelete={() => {
              onChange({
                selectedGeneration: selectedGeneration.filter(
                  (g) => g !== generation,
                ),
                selectedGender,
              });
            }}
            deleteIcon={getDeleteIcon(
              GenerationDemographicsColorMap[generation],
            )}
          />
        ))}
        <IconButton
          sx={{ p: 0, color: theme.colors?.primary.black }}
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
        >
          <IconBoldAddCircle size={21} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            sx: {
              minWidth: 250,
              padding: theme.spacing(4, 2),
              background: 'rgba(255, 255, 255, 0.90)',
              backdropFilter: 'blur(20px)',
              boxShadow:
                '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
              border: 'none',
              '& ul > :first-child': {
                display: 'none',
              },
            },
          }}
        >
          <SLAAnalyticsFilterByDemographics
            variant="normal"
            // eslint-disable-next-line
            renderTitle={() => <></>}
            onChange={onChange}
            selectedGender={selectedGender}
            selectedGeneration={selectedGeneration}
          />
        </Menu>
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={processedData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            vertical={false}
            stroke={theme.colors?.utility[400]}
            strokeDasharray="4"
          />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
            style={{
              ...theme.typography['headline-sm'],
              color: theme.colors?.utility[700],
            }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            style={{
              ...theme.typography['headline-sm'],
              color: theme.colors?.utility[700],
            }}
            tickFormatter={(value) => formatBigNumber(value)}
          />
          {variant === 'both' ? (
            <>
              <RechartsTooltip
                cursor={false}
                // eslint-disable-next-line
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const counts = payload.reduce(
                      (acc, { dataKey, value }) => {
                        // Use the dataKey to determine which count to update
                        if (dataKey === SocialPostGenderDemographic.Female) {
                          acc.female = Number(value) || 0;
                        } else if (
                          dataKey === SocialPostGenderDemographic.Male
                        ) {
                          acc.male = Number(value) || 0;
                        } else if (
                          dataKey === SocialPostGenderDemographic.NotSpecified
                        ) {
                          acc.notSpecified = Number(value) || 0;
                        }
                        return acc;
                      },
                      { female: 0, male: 0, notSpecified: 0 },
                    );

                    const total =
                      counts.female + counts.male + counts.notSpecified;

                    if (total === 0) return null;

                    return (
                      <Box
                        sx={{
                          borderRadius: theme.spacing(2.5),
                          background: 'rgba(251, 247, 245, 0.80)',
                          boxShadow:
                            '0px 8px 28px -6px rgba(24, 39, 75, 0.10), 0px 18px 88px -4px rgba(24, 39, 75, 0.12)',
                          backdropFilter: 'blur(20px)',
                          padding: theme.spacing(8),
                          display: 'flex',
                          flexDirection: 'column',
                          gap: theme.spacing(4),
                          minWidth: 270,
                        }}
                      >
                        <Typography
                          variant="subhead-xl"
                          color={theme.colors?.utility[600]}
                        >
                          Out of{' '}
                          <Typography
                            variant="headline-sm"
                            color={
                              GenerationDemographicsColorMap[
                                payload[0]?.payload[
                                  'dataKey'
                                ] as SocialPostGenerationDemographic
                              ]
                            }
                          >
                            {total}
                          </Typography>
                          <Typography
                            variant="subhead-xl"
                            color={theme.colors?.utility[900]}
                          >
                            {' '}
                            {payload[0].payload.name}
                          </Typography>
                          :
                        </Typography>
                        {[
                          {
                            key: SocialPostGenderDemographic.Female,
                            count: counts.female,
                          },
                          {
                            key: SocialPostGenderDemographic.Male,
                            count: counts.male,
                          },
                          {
                            key: SocialPostGenderDemographic.NotSpecified,
                            count: counts.notSpecified,
                          },
                        ].map(({ key, count }) => (
                          <Box display="flex" gap={3}>
                            <Typography
                              variant="subhead-xl"
                              color={GenderDemographicsColorMap[key]}
                            >
                              {formatBigNumber(count)}
                            </Typography>
                            <Typography
                              variant="subhead-xl"
                              color={theme.colors?.utility[900]}
                            >
                              {GenderDemographicsMap[key]}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    );
                  }
                  return null;
                }}
              />
              {[
                SocialPostGenderDemographic.NotSpecified,
                SocialPostGenderDemographic.Male,
                SocialPostGenderDemographic.Female,
              ].map((gender) => (
                <Bar
                  key={gender}
                  dataKey={gender}
                  stackId="demographics"
                  fill={GenderDemographicsBarColorMap[gender]}
                  maxBarSize={180}
                  shape={(props) => getBarRadius(props, gender)}
                />
              ))}
            </>
          ) : (
            <>
              <RechartsTooltip
                cursor={false}
                // eslint-disable-next-line
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const { payload: innerPayload, value } = payload[0];

                    if (value === 0) return null;
                    return (
                      <Box
                        sx={{
                          borderRadius: theme.spacing(2.5),
                          border: `1.5px solid ${theme.colors?.utility[100]}`,
                          boxShadow: '0px 2.556px 12px 0px rgba(0, 0, 0, 0.08)',
                          padding: theme.spacing(4),
                          backgroundColor: theme.colors?.primary.white,
                        }}
                      >
                        <Typography
                          variant="headline-md"
                          fontSize={20}
                          color={
                            innerPayload?.color ??
                            theme.colors?.utility['teal-3']
                          }
                        >
                          {formatBigNumber(Number(value))}
                        </Typography>
                      </Box>
                    );
                  }
                  return null;
                }}
              />
              <Bar dataKey="count" shape={<RoundedTopBar />} maxBarSize={180}>
                {processedData.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.count > 0
                          ? entry?.['color'] ?? theme.colors?.utility['teal-3']
                          : 'transparent'
                      }
                    />
                  );
                })}
              </Bar>
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
