import { gql } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { IconOutlineArrowLeft2 } from 'components/icons/components/outline/IconOutlineArrowLeft2';
import {
  SearchType,
  SearchableEntityType,
  usePaginatedTasksForTaskSearchResultsQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import {
  SearchListItemView,
  TASK_SEARCH_RESULT_FRAGMENT_SECTION_VIEW,
} from 'features/search';

type Props = {
  searchStr: string;
  searchType: SearchType;
  onNavigateBack: () => void;
  elRefs: React.MutableRefObject<(HTMLElement | null)[]>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query PaginatedTasksForTaskSearchResults($data: UniversalSearchInput!) {
    universalSearch(data: $data) {
      taskSearchResult {
        ...TaskSearchResultFragmentSectionView
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${TASK_SEARCH_RESULT_FRAGMENT_SECTION_VIEW}
`;

const PAGE_SIZE = 10;

export const TaskSearchResults = (props: Props) => {
  const { searchStr, onNavigateBack, searchType, elRefs } = props;
  const [hasMore, setHasMore] = useState(true);

  const variables = {
    entityTypes: [SearchableEntityType.Tasks],
    limit: PAGE_SIZE,
    searchType,
    text: searchStr,
  };

  const { data: tasksData, fetchMore } =
    usePaginatedTasksForTaskSearchResultsQuery({
      variables: {
        data: {
          ...variables,
        },
      },
    });

  const fetchMoreTasks = () => {
    if (!hasMore || !tasksData?.universalSearch.taskSearchResult.hits.length) {
      return;
    }

    fetchMore({
      variables: {
        data: {
          ...variables,
          after:
            String(tasksData?.universalSearch.pageInfo.endCursor || 0) || '0',
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          fetchMoreResult.universalSearch.taskSearchResult.hits.length === 0
        ) {
          setHasMore(false);
        }
        return {
          ...fetchMoreResult,
          universalSearch: {
            ...fetchMoreResult.universalSearch,
            taskSearchResult: {
              ...fetchMoreResult.universalSearch.taskSearchResult,
              totalCount:
                fetchMoreResult.universalSearch.taskSearchResult.totalCount,
              hits: [
                ...(prev.universalSearch.taskSearchResult?.hits || []),
                ...(fetchMoreResult.universalSearch.taskSearchResult?.hits ||
                  []),
              ],
            },
          },
        };
      },
    });
  };

  useEffect(() => {
    setHasMore(true);
  }, [searchStr]);

  let itemIndex = 0;

  return (
    <Box>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        color={theme.colors?.utility[700]}
        mb={4}
        sx={{ cursor: 'pointer' }}
        onClick={onNavigateBack}
      >
        <IconOutlineArrowLeft2 size={12} />
        <Typography variant="body-xs" fontWeight={600}>
          Tasks ({tasksData?.universalSearch.taskSearchResult.totalCount})
        </Typography>
      </Box>
      <Box maxHeight={450} sx={{ overflowY: 'auto' }} mt={0}>
        <InfiniteScroll
          threshold={100}
          loadMore={() => fetchMoreTasks()}
          useWindow={false}
          hasMore={hasMore}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress
                size={24}
                sx={{ color: theme.colors?.primary.maroon }}
              />
            </Box>
          }
        >
          {(tasksData?.universalSearch.taskSearchResult?.hits || []).map(
            (task) => (
              <Box key={task.item.id}>
                <SearchListItemView
                  ref={(el) => {
                    elRefs.current[itemIndex] = el;
                    itemIndex++;
                  }}
                  searchHit={task}
                  type={SearchableEntityType.Tasks}
                  searchString={searchStr}
                />
              </Box>
            ),
          )}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};
