import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { CommandKey } from 'components/common/CommandKey';
import InfiniteScrollBoundary from 'components/common/InfiniteScrollBoundary/InfiniteScrollBoundary';
import {
  TaskListItemView,
  TaskListItemViewProps,
  TaskListItemViewSkeleton,
} from 'features/task';
import {
  TaskFilters,
  TaskFragmentUseTaskListWithKeyboardShortcutsFragment,
} from 'graphql/generated';
import { useEffect } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { theme } from 'styles/theme';
import {
  UseTaskListWithKeyboardShortcutsProps,
  useTaskListWithKeyboardShortcuts,
} from '../../hooks';

export type TaskListWithKeyboardShortcutsListViewProps = {
  filters?: TaskFilters;
  skipLoading?: boolean;
  sx?: SxProps;
  componentsProps?: {
    listItem?: TaskListItemViewProps;
  };
  renderEmptyState?: () => JSX.Element;
  onTaskListChange?: (
    tasks: TaskFragmentUseTaskListWithKeyboardShortcutsFragment[],
  ) => void;
  onFocusedTaskChange?: (
    task?: TaskFragmentUseTaskListWithKeyboardShortcutsFragment,
    canShiftOrUnShift?: boolean,
  ) => void;
} & Pick<
  UseTaskListWithKeyboardShortcutsProps,
  'shortcuts' | 'transformQueryTasks'
>;

export const TaskListWithKeyboardShortcutsListView = (
  props: TaskListWithKeyboardShortcutsListViewProps,
) => {
  const {
    filters,
    skipLoading,
    sx,
    componentsProps = {},
    shortcuts = {},
    transformQueryTasks,
    renderEmptyState,
    onTaskListChange,
    onFocusedTaskChange,
  } = props;

  const {
    tasks,
    pendingTasks,
    loading,
    focusedTaskId,
    hasNextPage,
    setFocusedTaskId,
    onPendingTaskChange,
    onTaskNameKeyDown,
    setIsTaskNameFocused,
    createNewTask,
    fetchMore,
    setPendingTasks,
    checkIfTaskIsStandaloneSubtask,
    checkIfTaskCanShiftOrUnshift,
  } = useTaskListWithKeyboardShortcuts({
    filters,
    skipLoading,
    shortcuts,
    transformQueryTasks,
  });

  // A bit hacky but we need to expose
  // task change events outside to trigger refetching if need to
  // 1 current flow that needs this is when we update a task's due date & need to refetch the task list
  // in `TaskCalendarSection`
  // TODO: Find a better way to do this
  useEffect(() => {
    onTaskListChange?.(tasks);
  }, [tasks]); // eslint-disable-line

  useEffect(() => {
    if (focusedTaskId) {
      const focusedTask = tasks.find((task) => task.id === focusedTaskId);
      onFocusedTaskChange?.(
        focusedTask,
        checkIfTaskCanShiftOrUnshift(focusedTaskId),
      );
    }
  }, [focusedTaskId, tasks]); // eslint-disable-line

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" py={3} px={6} gap={4}>
        <TaskListItemViewSkeleton />
        <TaskListItemViewSkeleton />
        <TaskListItemViewSkeleton />
      </Box>
    );
  }

  if (tasks.length === 0) {
    if (renderEmptyState) {
      return renderEmptyState();
    }

    return (
      <Box
        sx={{
          height: '100%',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="headline-sm" mb={2}>
          You have no tasks!
        </Typography>
        <Typography
          variant="subhead-lg"
          mb={4}
          color={theme.colors?.utility[800]}
        >
          Let's get things rolling
        </Typography>
        <Box
          component="button"
          onClick={() => createNewTask()}
          sx={{
            borderRadius: 1,
            color: theme.colors?.utility[700],
            bgcolor: theme.colors?.utility[300],
            border: `1px solid ${theme.colors?.utility[400]}`,
            py: 2,
            px: 4,
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Typography variant="headline-sm">Add a task</Typography>
          <CommandKey>T</CommandKey>
        </Box>
      </Box>
    );
  }

  // Animate item on exit
  // They will slide to the right & fade out
  const transitionStyles = {
    exiting: {
      opacity: 0,
      transform: 'translateX(50%)',
      transition: 'all 0.2s',
    },
    exited: { opacity: 0 },
  };

  return (
    <Box
      sx={{
        px: 4,
        py: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        ...sx,
      }}
    >
      <TransitionGroup
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}
      >
        {tasks.map((task) => {
          const isPending = pendingTasks.find((t) => t.id === task.id);
          const isSubtask = Boolean(task.parentTaskId);
          const isStandaloneSubtask = checkIfTaskIsStandaloneSubtask(task.id);

          return (
            <Transition timeout={200} key={task.id}>
              {(state) => {
                return (
                  <Box
                    position="relative"
                    style={{
                      ...transitionStyles[state],
                    }}
                  >
                    <TaskListItemView
                      task={task}
                      onChange={isPending ? onPendingTaskChange : undefined}
                      canNavigate={!isPending}
                      isFocused={task.id === focusedTaskId}
                      onFocus={() => setFocusedTaskId(task.id)}
                      onBlur={() => {
                        setFocusedTaskId(undefined);
                        setPendingTasks(
                          pendingTasks.filter((t) => t.name !== ''),
                        );
                      }}
                      {...componentsProps.listItem}
                      sx={{
                        pl: isSubtask && !isStandaloneSubtask ? 8 : undefined,
                        ...componentsProps.listItem?.sx,
                      }}
                      componentsProps={{
                        ...componentsProps.listItem?.componentsProps,
                        name: {
                          onFocus: () => setIsTaskNameFocused(true),
                          onBlur: () => setIsTaskNameFocused(false),
                          editorProps: {
                            handleKeyDown: onTaskNameKeyDown,
                          },
                          includeParentTaskName: isStandaloneSubtask,
                          ...componentsProps.listItem?.componentsProps?.name,
                        },
                      }}
                    />
                    {/* @ts-ignore */}
                    {isPending && task.loading && (
                      <CircularProgress
                        size={12}
                        sx={{
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          m: 3,
                          color: theme.colors?.primary.black,
                        }}
                      />
                    )}
                  </Box>
                );
              }}
            </Transition>
          );
        })}
      </TransitionGroup>

      {/* Infinite scrolling boundary trigger */}
      <InfiniteScrollBoundary
        disabled={loading || !hasNextPage}
        threshold={window.innerWidth / 2}
        onVisible={fetchMore}
      />
      {loading && (
        <CircularProgress
          size={16}
          sx={{
            right: 0,
            top: 0,
            my: 2,
            mx: 'auto',
            color: theme.colors?.primary.black,
          }}
        />
      )}
    </Box>
  );
};
