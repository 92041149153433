import { gql } from '@apollo/client';
import {
  GeneralPermission,
  PermissionLevel,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
  useUpdatePostPermissionsMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdatePostPermissions($data: UpdatePostPermissionsInputV2!) {
    updatePostPermissionsV2(data: $data) {
      message
      success
    }
  }
`;

export type UpdatePostPermissionsDataInput = {
  inviteMembers?: {
    members: {
      user: UserProfileFragmentUserProfilePermissionItemViewFragment;
      permissionLevel: PermissionLevel;
    }[];
  };
  removeMembers?: {
    userIds: string[];
  };
  generalPermission?: {
    generalPermission: GeneralPermission;
    permissionLevel?: PermissionLevel;
  };
  sendNotification?: boolean;
  note?: string;
  shouldCreateComment?: boolean;
};

type Props = {
  postIds: string[];
};

export const usePostPermissionMutations = ({ postIds }: Props) => {
  const [updatePostPermissions] = useUpdatePostPermissionsMutation();

  return {
    updatePostPermissions: async (
      data: UpdatePostPermissionsDataInput,
      refetchQueriesDefault?: string[],
    ) => {
      const refetchQueries = refetchQueriesDefault ?? [];

      // to update org billing usage
      refetchQueries.push('GetMe');

      if (data.note?.trim() && data.shouldCreateComment) {
        refetchQueries.push('GetCommentsForPostCommentListView');
      }
      await updatePostPermissions({
        variables: {
          data: {
            input: {
              inviteMembers: data.inviteMembers
                ? {
                    memberInvites: [],
                    shouldCreateComment: data.shouldCreateComment,
                    message: data.note,
                    emailInvites: data.inviteMembers.members.flatMap((member) =>
                      postIds.map((postId) => ({
                        email: member.user.email,
                        permission: member.permissionLevel,
                        postId,
                      })),
                    ),
                  }
                : undefined,
              removeMembers: data.removeMembers
                ? {
                    postIds,
                    userIds: data.removeMembers.userIds,
                  }
                : undefined,
              generalPermission: data.generalPermission
                ? {
                    postIds,
                    generalPermission: data.generalPermission.generalPermission,
                    permissionLevel: data.generalPermission.permissionLevel,
                  }
                : undefined,
              sendNotification: data.sendNotification
                ? data.sendNotification
                : undefined,
            },
          },
        },
        refetchQueries,
        update: (cache) => {
          postIds.forEach((postId) => {
            modifyObject(cache, postId, 'PostModel', {
              generalPermission: () =>
                data.generalPermission?.generalPermission,
              permissionLevel: () => data.generalPermission?.permissionLevel,
            });
          });
        },
      });
    },
  };
};
