import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Extension } from '@tiptap/react';
import { GenerateId } from 'utils/generateId';
import {
  ATTR_BLOCK_ID,
  EXTENSION_BLOCK_WRAPPER,
  NODE_COLUMN,
  NODE_COLUMN_LIST,
  NODE_EMBED,
  NODE_TASK_ITEM,
} from '../constants';

const allowedTypes = [
  'paragraph',
  'blockquote',
  'codeBlock',
  'heading',
  'orderedList',
  NODE_EMBED,
  NODE_COLUMN,
  NODE_COLUMN_LIST,
  'bulletList',
  'taskList',
  'horizontalRule',
];

const doNotCreateWrapperTypes = ['column'];

export type BlockWrapperExtensionOptions = {};

/**
 * This extension adds a unique ID to every node block if the node
 * does not have a unique ID already.
 */
export const BlockWrapper = Extension.create<BlockWrapperExtensionOptions>({
  name: EXTENSION_BLOCK_WRAPPER,

  priority: 1001,

  addGlobalAttributes() {
    return [
      {
        types: allowedTypes,
        attributes: {
          [ATTR_BLOCK_ID]: {
            default: null,
            keepOnSplit: false,
            renderHTML: (attributes) => {
              return {
                [ATTR_BLOCK_ID]: attributes[ATTR_BLOCK_ID],
              };
            },
            parseHTML: (element) => {
              return element.getAttribute(ATTR_BLOCK_ID) || GenerateId.create();
            },
          },
          columnable: {
            default: null,
            keepOnSplit: false,
            renderHTML: (attributes) => {
              return {
                columnable: attributes.columnable,
              };
            },
            parseHTML: (element) => {
              return element.getAttribute('columnable');
            },
          },
          draggable: {
            default: null,
            keepOnSplit: false,
            renderHTML: (attributes) => {
              return {
                draggable: attributes.draggable,
              };
            },
            parseHTML: (element) => {
              return element.getAttribute('draggable');
            },
          },
        },
      },
    ];
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('blockWrapperPlugin'),
        appendTransaction: (_transactions, oldState, newState) => {
          // no changes
          if (newState.doc === oldState.doc) {
            return;
          }

          const tr = newState.tr;

          newState.doc.descendants((node, pos) => {
            if (node.isBlock && allowedTypes.includes(node.type.name)) {
              let draggable = true;
              let columnable = true;

              // TODO: Better way to handle custom draggable/columnable items
              // If node type is paragraph, its parent is list item, and it's the first child, it would not be draggable
              if (
                [
                  node.type.name === 'paragraph' &&
                    tr.doc.resolve(pos).parent.type.name === 'listItem' &&
                    tr.doc.resolve(pos).parent.firstChild?.eq(node),
                  node.type.name === 'paragraph' &&
                    tr.doc.resolve(pos).parent.type.name === NODE_TASK_ITEM,
                ].some(Boolean)
              ) {
                draggable = false;
              }

              if (
                [
                  // If node is column-list, it would not be columnable
                  // TODO: Need to make it columnable to handle dropping next to it & merge columns
                  // See Drop.tsx
                  node.type.name === NODE_COLUMN_LIST,
                  // If node's parent is a column node, it would not be columnable
                  tr.doc.resolve(pos).parent.type.name === NODE_COLUMN,
                  // If node's parent is list item, it would not be columnable
                  tr.doc.resolve(pos).parent.type.name === 'listItem',
                  tr.doc.resolve(pos).parent.type.name === NODE_TASK_ITEM,
                ].some(Boolean)
              ) {
                columnable = false;
              }

              // If node type is column, it would not be draggable
              if (
                node.type.name === NODE_COLUMN ||
                node.type.name === NODE_COLUMN_LIST
              ) {
                draggable = false;
              }

              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                [ATTR_BLOCK_ID]:
                  node.attrs[ATTR_BLOCK_ID] || GenerateId.create(),
                draggable: draggable ? 'true' : 'false',
                columnable: columnable ? 'true' : 'false',
              });
            }
          });

          return tr;
        },
        state: {
          init(_, { doc }) {
            return {
              decorations: DecorationSet.create(doc, []),
              draggedOverBlockId: null,
            };
          },
          apply(tr, old) {
            const draggedOverBlockId = tr.getMeta('draggedOverBlockId');

            const shouldUpdate =
              tr.docChanged || draggedOverBlockId !== old.draggedOverBlockId;

            if (!shouldUpdate) {
              return old;
            }

            const decorations: Decoration[] = [];

            tr.doc.descendants((node, pos) => {
              if (node.isBlock && allowedTypes.includes(node.type.name)) {
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    nodeName: doNotCreateWrapperTypes.includes(node.type.name)
                      ? undefined
                      : 'div',
                    style: `
                      ${node.attrs.flex ? `flex: ${node.attrs.flex};` : ''}
                      width: ${node.attrs.width || 100}%;
                    `,
                    class: [
                      'block',
                      `node-${node.type.name}`,
                      draggedOverBlockId === node.attrs[ATTR_BLOCK_ID]
                        ? 'dragged-over'
                        : '',
                    ]
                      .filter(Boolean)
                      .join(' '),
                    [ATTR_BLOCK_ID]:
                      node.attrs[ATTR_BLOCK_ID] || GenerateId.create(),
                  }),
                );
              }
            });

            return {
              decorations: DecorationSet.create(tr.doc, decorations),
              draggedOverBlockId,
            };
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)?.decorations;
          },
        },
      }),
    ];
  },
});
