import { Box, SxProps } from '@mui/material';
import { CommandKey, CommandKeyProps } from './CommandKey';

export type CommandKeysProps = {
  keys: string[];
  sx?: SxProps;
  componentsProps?: {
    key?: Pick<CommandKeyProps, 'sx'>;
  };
};

export const CommandKeys = (props: CommandKeysProps) => {
  const { keys, sx, componentsProps = {} } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        ...sx,
      }}
    >
      {keys.map((key) => (
        <CommandKey key={key} {...componentsProps.key}>
          {key}
        </CommandKey>
      ))}
    </Box>
  );
};
