import { Box, Typography } from '@mui/material';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingPostHistoryPreferenceProps = {
  savingData?: boolean;
  onNext: (days: number) => void;
};

export const SocialMediaListeningOnboardingPostHistoryPreference = ({
  savingData,
  onNext,
}: SocialMediaListeningOnboardingPostHistoryPreferenceProps) => {
  const [selectedDays, setSelectedDays] = useState(1);

  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        How far back would you like to view posts?
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        Remember to watch the depth of video data parsing—going too far back
        will chip away at your monthly video credit hours.
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        mt={4}
        sx={{
          '& .selected': {
            border: `2px solid ${theme.colors?.primary.parchment}`,
          },
        }}
      >
        <Box
          onClick={() => setSelectedDays(1)}
          className={selectedDays === 1 ? 'selected' : ''}
          display="flex"
          gap={3}
          sx={{
            cursor: 'pointer',
            border: `2px solid transparent`,
            minWidth: 200,
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            borderRadius: 3,
            p: theme.spacing(3, 6),
            width: 'fit-content',
          }}
        >
          <Typography
            variant="headline-md"
            fontWeight={500}
            fontSize={theme.spacing(5)}
          >
            1 day
          </Typography>
        </Box>
        <Box
          onClick={() => setSelectedDays(7)}
          className={selectedDays === 7 ? 'selected' : ''}
          display="flex"
          gap={3}
          sx={{
            cursor: 'pointer',
            border: `2px solid transparent`,
            minWidth: 200,
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            borderRadius: 3,
            p: theme.spacing(3, 6),
            width: 'fit-content',
          }}
        >
          <Typography
            variant="headline-md"
            fontWeight={500}
            fontSize={theme.spacing(5)}
          >
            7 days
          </Typography>
        </Box>
      </Box>
      <RenderNextButton
        onNextClick={() => onNext(selectedDays)}
        actionLabel="Generate"
        actionBtnEndIcon={<IconCustomSparkles size={16} />}
        loading={savingData}
      />
    </Box>
  );
};
