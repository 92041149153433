import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { ContextMenu, ContextMenuOption } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconLinearTimerPause } from 'components/icons/components/linear/IconLinearTimerPause';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import {
  BrandFragmentBrandPauseContextMenuFragment,
  BrandStatus,
  usePauseBrandForBrandPauseContextMenuMutation,
  useResumeBrandForBrandPauseContextMenuMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

export const BRAND_FRAGMENT_BRAND_PAUSE_CONTEXT_MENU = gql`
  fragment BrandFragmentBrandPauseContextMenu on BrandModel {
    id
    status
    resetOnNextCycle
  }
`;

// eslint-disable-next-line
gql`
  mutation PauseBrandForBrandPauseContextMenu($data: PauseBrandInput!) {
    pauseBrand(data: $data) {
      id
      ...BrandFragmentBrandPauseContextMenu
    }
  }
  ${BRAND_FRAGMENT_BRAND_PAUSE_CONTEXT_MENU}
`;

// eslint-disable-next-line
gql`
  mutation ResumeBrandForBrandPauseContextMenu($data: ResumeBrandInput!) {
    resumeBrand(data: $data) {
      id
      ...BrandFragmentBrandPauseContextMenu
    }
  }
  ${BRAND_FRAGMENT_BRAND_PAUSE_CONTEXT_MENU}
`;

interface BrandPauseContextMenuProps
  extends Pick<ContextMenuProps, 'disablePortal' | 'sx'> {
  brand: BrandFragmentBrandPauseContextMenuFragment;
}

export const BrandPauseContextMenu = ({
  brand,
  ...rest
}: BrandPauseContextMenuProps) => {
  const [pauseBrand] = usePauseBrandForBrandPauseContextMenuMutation();
  const [resumeBrand] = useResumeBrandForBrandPauseContextMenuMutation();

  const isBrandPaused = useMemo(() => {
    return brand.status === BrandStatus.Paused;
  }, [brand.status]);

  const onResumeBrand = () => {
    resumeBrand({
      variables: {
        data: {
          brandId: brand.id,
        },
      },
      optimisticResponse: {
        resumeBrand: {
          id: brand.id,
          status: BrandStatus.Active,
          resetOnNextCycle: true,
        },
      },
    });
  };

  const onPauseBrand = (resetOnNextCycle?: boolean) => {
    pauseBrand({
      variables: {
        data: {
          brandId: brand.id,
          resetOnNextCycle,
        },
      },
      optimisticResponse: {
        pauseBrand: {
          id: brand.id,
          status: BrandStatus.Paused,
          resetOnNextCycle: resetOnNextCycle ?? brand.resetOnNextCycle,
        },
      },
    });
  };

  if (isBrandPaused) {
    return (
      <IconButtonWithTooltip
        tooltip="Resume"
        size="small"
        onClick={onResumeBrand}
      >
        <IconBoldPlayCircle size={24} />
      </IconButtonWithTooltip>
    );
  }

  return (
    <ContextMenu
      PaperProps={{
        sx: {
          background: 'rgba(255, 255, 255, 0.80)',
          backdropFilter: 'blur(20px)',
          boxShadow:
            '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12) !important',
          border: 'none',
        },
      }}
      renderButton={() => {
        return (
          <IconButtonWithTooltip tooltip="Pause" size="small">
            <IconBoldPauseCircle size={24} />
          </IconButtonWithTooltip>
        );
      }}
      options={[
        {
          renderOption: () => (
            <ContextMenuOption
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subhead-lg">
                    Pause until reset
                  </Typography>
                  <Typography
                    variant="subhead-sm"
                    color={theme.colors?.utility[800]}
                  >
                    (will reset on{' '}
                    {moment()
                      .add(1, 'month')
                      .startOf('month')
                      .format('MM-DD-YYYY')}
                    )
                  </Typography>
                </Box>
              }
              icon={<IconOutlineCalendar1 size={16} />}
            />
          ),
          onClick: onPauseBrand,
        },
        {
          renderOption: () => (
            <ContextMenuOption
              label="Pause indefinitely"
              icon={<IconLinearTimerPause size={16} />}
            />
          ),
          onClick: () => {
            onPauseBrand(false);
          },
        },
      ]}
      {...rest}
    />
  );
};
