import { gql } from '@apollo/client';
import { TrendFragmentTrendInfoFragment } from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const TREND_FRAGMENT_TREND_INFO = gql`
  fragment TrendFragmentTrendInfo on TrendModel {
    id
    __typename
    createdAt
    description
    title
    category
    status
    trendAudio {
      totalVideoCount
      changePercentage
    }
  }
`;

export type TrendInfoProps = {
  trendInfo: TrendFragmentTrendInfoFragment;
};
