import { Box } from '@mui/material';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { SearchHitType } from 'graphql/generated';
import { theme } from 'styles/theme';

type SearchItemSearchTypeIndicatorProps = {
  searchHitType: SearchHitType;
  size?: string | number;
};

export const SearchItemSearchTypeIndicator = (
  props: SearchItemSearchTypeIndicatorProps,
) => {
  const { searchHitType, size = 24 } = props;

  return searchHitType !== SearchHitType.Smart ? null : (
    <Box
      sx={{
        background: theme.colors?.primary.parchment,
        opacity: 0.7,
        padding: theme.spacing(1),
        border: `1px solid ${theme.colors?.primary.parchment}`,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box display="flex" sx={{ color: theme.colors?.primary.black }}>
        <IconCustomSparkles style={{ width: size, height: size }} />
      </Box>
    </Box>
  );
};
