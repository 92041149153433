import { Box, Skeleton } from '@mui/material';

export const CommentContainerViewSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        padding: 4,
        gap: 2,
      }}
    >
      <Skeleton variant="circular" width={24} height={24} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          '.MuiSkeleton-root': {
            borderRadius: 1,
          },
        }}
      >
        <Skeleton variant="rectangular" width={150} height={24} />
        <Skeleton variant="rectangular" width={300} height={24} />
        <Skeleton variant="rectangular" width={80} height={16} />
      </Box>
    </Box>
  );
};
