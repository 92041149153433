/**
 * NOTE: This hook works EXCLUSIVELY with TaskListItemView.
 * It contains all the logic to handle keyboard shortcuts for task list in the sidebar.
 */

import { gql } from '@apollo/client';
import { EditorView } from '@tiptap/pm/view';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import { FALLBACK_UNGROUP_ID } from 'features/task/constants';
import {
  SortOrder,
  TaskFilters,
  TaskFragmentTaskListItemViewFragmentDoc,
  TaskFragmentUseTaskListWithKeyboardShortcutsFragment,
  TaskStatus,
  useGetTasksForUseTaskListWithKeyboardShortcutsQuery,
  useUpdateTaskForUseTaskListWithKeyboardShortcutsMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const TASK_FRAGMENT_USE_TASK_LIST_WITH_KEYBOARD_SHORTCUTS = gql`
  fragment TaskFragmentUseTaskListWithKeyboardShortcuts on TaskModel {
    id
    parentTaskId
    sortOrder
    ...TaskFragmentTaskListItemView
  }
  ${TaskFragmentTaskListItemViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetTasksForUseTaskListWithKeyboardShortcuts(
    $filters: TaskFilters!
    $take: Int
    $after: String
  ) {
    tasks(filters: $filters, take: $take, after: $after) {
      data {
        id
        subtasks(args: { filters: $filters }) {
          id
          ...TaskFragmentUseTaskListWithKeyboardShortcuts
        }
        ...TaskFragmentUseTaskListWithKeyboardShortcuts
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${TASK_FRAGMENT_USE_TASK_LIST_WITH_KEYBOARD_SHORTCUTS}
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForUseTaskListWithKeyboardShortcuts(
    $data: UpdateTaskInput!
  ) {
    updateTask(data: $data) {
      id
      subtasks {
        id
        ...TaskFragmentUseTaskListWithKeyboardShortcuts
      }
      ...TaskFragmentUseTaskListWithKeyboardShortcuts
    }
  }
  ${TASK_FRAGMENT_USE_TASK_LIST_WITH_KEYBOARD_SHORTCUTS}
`;

type TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment =
  TaskFragmentUseTaskListWithKeyboardShortcutsFragment & {
    subtasks?: TaskFragmentUseTaskListWithKeyboardShortcutsFragment[];
  };

type PendingTaskFragmentUseTaskListWithKeyboardShortcutsFragment =
  TaskFragmentUseTaskListWithKeyboardShortcutsFragment & {
    loading?: boolean;
  };

// NOTE: This is just a temporary value. Probably need more thought
const SORT_ORDER_INCREMENT = 0.02;

// A very large number to make sure that new tasks will always be at the bottom of the list
// and unique enough so that we can check if a task has this sortOrder
// so we can push `null` to the BE instead (which will make the BE give the task a new sortOrder)
const INFINITE_SORT_ORDER = 999999;

export type UseTaskListWithKeyboardShortcutsProps = {
  filters?: TaskFilters;
  skipLoading?: boolean;

  /**
   * Quick access to disable keyboard shortcuts if needed.
   * NOTE: Temporary solution.
   */
  shortcuts?: {
    t?: false;
    tab?: false;
    enter?: false;
  };

  /**
   * Transform tasks before processing.
   * This gives us a chance to modify the tasks before they are used to render the task list.
   * E.g.: In TaskListSection, when users update a task to ToDo/Archived, we want to remove it from the list.
   * This kind of specific logic demands a way to modify the tasks like this.
   * TODO: Maybe write a full-blown set of callbacks for this hook? E.g: onTaskLoaded, onTaskCreated, onTaskUpdated, etc.
   */
  transformQueryTasks?: (
    tasks: TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment[],
  ) => TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment[];
};

export const useTaskListWithKeyboardShortcuts = (
  props: UseTaskListWithKeyboardShortcutsProps,
) => {
  const { filters, skipLoading, shortcuts = {}, transformQueryTasks } = props;

  // Disable task commands if we are in this tab
  const { disableCommands, enableCommands } = useCommandContext();
  useEffect(() => {
    disableCommands([COMMAND_TYPE.CREATE_TASK]);

    return () => {
      enableCommands([COMMAND_TYPE.CREATE_TASK]);
    };
  }, []); // eslint-disable-line

  const [updateTask] =
    useUpdateTaskForUseTaskListWithKeyboardShortcutsMutation();
  const debouncedUpdateTask = useMemo(
    () => debounce(updateTask, 300),
    [updateTask],
  );

  // Controls to keep track of currently focused task
  // It's used EXTENSIVELY to determine the position of new tasks/unshift/shifting tasks
  const [focusedTaskId, setFocusedTaskId] = useState<string>();
  const focusedTaskIdRef = useRef<string>();
  focusedTaskIdRef.current = focusedTaskId;

  // Array to hold new task & tasks that are being created
  const [pendingTasks, setPendingTasks] = useState<
    PendingTaskFragmentUseTaskListWithKeyboardShortcutsFragment[]
  >([]);
  const pendingTasksRef = useRef<
    PendingTaskFragmentUseTaskListWithKeyboardShortcutsFragment[]
  >([]);
  pendingTasksRef.current = pendingTasks;

  // Get tasks (including subtasks) from the BE
  const { data, loading, updateQuery, fetchMore } =
    useGetTasksForUseTaskListWithKeyboardShortcutsQuery({
      variables: {
        filters: {
          ...filters,
          includeSubtasks: true,
          sortBy: {
            field: 'sortOrder',
            order: SortOrder.Asc,
          },
        },
        take: 20,
      },
      skip: skipLoading,
      fetchPolicy: 'cache-and-network',
    });
  const isFirstLoading = !data && loading;

  const fetchMoreTasks = () => {
    fetchMore({
      variables: {
        ...filters,
        includeSubtasks: true,
        sortBy: {
          field: 'sortOrder',
          order: SortOrder.Asc,
        },
        after: data?.tasks.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          tasks: {
            ...fetchMoreResult.tasks,
            data: [
              ...(prev.tasks.data || []),
              ...(fetchMoreResult.tasks.data || []),
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.id === e.id),
            ),
          },
        };
      },
    });
  };

  /**
   * Construct list of tasks that are used to render the task list.
   */

  // First, we get list of top level tasks, which include:
  // 1. Tasks with no parent
  // 2. Tasks that are subtasks of other tasks but their parent tasks are NOT in the list (standalone subtasks)
  const topLevelTasks = useMemo(() => {
    let tasks = (data?.tasks?.data ||
      []) as TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment[];

    if (transformQueryTasks) {
      tasks = transformQueryTasks(tasks);
    }

    // Filter out tasks which are also subtasks of other tasks & their parent tasks are in the list
    // We run into this because:
    // 1. We are getting subtasks together with the tasks (see GetTasksForUseTaskListWithKeyboardShortcuts query)
    // 2. If the query has `includeSubtasks: true`, we'll also get subtasks as the same level as top level tasks
    // Sometimes a task will appear in the same level as a top level task AND a subtask of another task,
    // which causes duplication.
    return tasks.filter(
      (topLevelTask) =>
        !topLevelTask.parentTaskId ||
        !tasks.some((t) =>
          (t.subtasks || []).some((st) => st.id === topLevelTask.id),
        ),
    ) as TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment[];
  }, [JSON.stringify({ data, pendingTasks, transformQueryTasks })]); // eslint-disable-line
  const topLevelTasksRef = useRef<
    TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment[]
  >([]);
  topLevelTasksRef.current = topLevelTasks;

  // Construct list of final tasks that should be displayed.
  // This list contains both top level tasks & subtasks, sorted in a way that
  // subtasks will always appear right after their parent task.
  const finalTasks = useMemo(() => {
    const pendingTopLevelTasks = pendingTasks.filter(
      (t) => !t.parentTaskId,
    ) as TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment[];
    const sortedTopLevelTasks = [
      ...topLevelTasks,
      ...pendingTopLevelTasks,
    ].sort((a, b) => (a.sortOrder || 9999) - (b.sortOrder || 9999));

    // Final list of tasks contain:
    // 1. All top level tasks
    // 2. After each task, their respective subtasks & pending subtasks
    const final = sortedTopLevelTasks.reduce((acc, t) => {
      const subtasks = [
        ...(t.subtasks || []),
        // Add pending subtasks that are not in the subtasks list
        ...pendingTasks.filter(
          (pt) =>
            pt.parentTaskId === t.id &&
            !t.subtasks?.some((st) => st.id === pt.id),
        ),
      ];

      return [...acc, t, ...subtasks];
    }, [] as TaskFragmentUseTaskListWithKeyboardShortcutsFragment[]);

    return final;
    // eslint-disable-next-line
  }, [JSON.stringify({ topLevelTasks, pendingTasks })]);
  const finalTasksRef = useRef<
    TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment[]
  >([]);
  finalTasksRef.current = finalTasks;

  /**
   * Check if a task is a standalone subtask.
   * Standalone subtask is a subtask that is not immediately after its parent task
   * AND we cannot find its parent in the top level tasks list.
   *
   * NOTE: There's still a possibility that a subtask appears in the query BEFORE its parent task,
   * however, assuming that a subtask is created after its parent, the sortOrder should be higher than its parent.
   * Still, we can't fully rely on this assumption.
   */
  const checkIfTaskIsStandaloneSubtask = (taskId: string) => {
    const task = finalTasksRef.current.find((t) => t.id === taskId);

    if (!task) {
      return false;
    }

    // If task is a top level task, it's not a standalone subtask
    if (!task.parentTaskId) {
      return false;
    }

    // If task is a subtask but its parent task is not found in the top level tasks list, it's a standalone subtask
    const parentTask = finalTasksRef.current.find(
      (t) => t.id === task.parentTaskId,
    );

    if (!parentTask) {
      return true;
    }

    return false;
  };

  /**
   * Check if a task can be shifted or unshifted.
   * NOTE: Only allow pending tasks to be shifted or unshifted for now.
   * Shifting & unshifting tasks that are already created on the BE is complicated
   * & I can't figure out a good way to do it yet.
   */
  const checkIfTaskCanShiftOrUnshift = (taskId: string) => {
    const taskIndex = finalTasksRef.current.findIndex((t) => t.id === taskId);
    const isPendingTask = pendingTasksRef.current.some((t) => t.id === taskId);

    if (!isPendingTask) {
      return false;
    }

    const previousTask = finalTasksRef.current[taskIndex - 1];

    // We can't shift or unshift if
    // 1. There is no previous task
    // 2. Previous task is a standalone subtask
    if (
      !previousTask ||
      (previousTask && checkIfTaskIsStandaloneSubtask(previousTask.id))
    ) {
      return false;
    }

    return true;
  };

  /**
   * Use organization user data to pre-fill task's members (if any)
   */
  const { user } = useUserContext();
  const users = useMemo(() => {
    if (!user) {
      return [];
    }

    return [...user.organization.users, ...user.organization.externalUsers];
  }, [user]);

  const getNewTaskData = useCallback(
    (parentTaskId?: string | null) => {
      const taskMembers = [
        ...users.filter((u) => (filters?.memberIds || []).includes(u.id)),
        // If there is no member in the filter, we will add current user by default
        ...((filters?.memberIds || []).length === 0 ? [user!] : []),
      ].filter(
        // Dedupe by id
        (e, index, self) => index === self.findIndex((t) => t.id === e.id),
      );

      const newTask = {
        __typename: 'TaskModel',
        id: GenerateId.create(),
        name: '',
        status: filters?.statuses?.[0] || TaskStatus.ToDo,
        endDate: filters?.endDate?.equals || null,
        startDate: null,
        priority: filters?.priorities?.[0] || null,
        taskMembers,
        project: null,
        parentTaskId: parentTaskId || null,
        myPermissions: [],
        sortOrder: null,
      } as TaskFragmentUseTaskListWithKeyboardShortcutsFragment;

      return newTask;
    },
    [user, users, filters],
  );

  /**
   * Create a new task & focus on it.
   * Its position and subtask status will be determined by the current focused task.
   *
   * TODO: Refactor this method to accept params instead of using refs.
   * It's unreliable, hard to reuse and hard to maintain.
   */
  const createNewTask = useCallback(
    () => {
      let parentTaskId: string | null = null;
      let sortOrder = INFINITE_SORT_ORDER;

      if (focusedTaskIdRef.current) {
        const focusedTask = finalTasksRef.current.find(
          (t) => t.id === focusedTaskIdRef.current,
        );

        if (focusedTask) {
          // If focused task is a subtask and NOT a standalone subtask,
          // we will create the new task as a subtask of its parent task
          // sortOrder will be ignore. It will be calculated by the BE
          if (
            focusedTask.parentTaskId &&
            !checkIfTaskIsStandaloneSubtask(focusedTask.id)
          ) {
            parentTaskId = focusedTask.parentTaskId;

            // Do nothing with sortOrder here as it's already INFINITE_SORT_ORDER by default
            // ...
          } else {
            // If focused task is a top level task or standalone subtask,
            // we calculate sortOrder based on the focused task & the next top level task or standalone subtask
            const focusedTaskIndex = finalTasksRef.current.findIndex(
              (t) => t.id === focusedTaskIdRef.current,
            );

            // Find next top level task or standalone subtask
            const nextTopLevelTask = finalTasksRef.current
              .slice(focusedTaskIndex + 1)
              .find(
                (t) => !t.parentTaskId || t.parentTaskId !== focusedTask.id,
              );

            // If there is a next top level task after the focused task, we will create the new task in between
            // sortOrder is the average of the focused task & the next task
            if (nextTopLevelTask) {
              sortOrder =
                ((focusedTask.sortOrder || 0) +
                  (nextTopLevelTask.sortOrder || 0)) /
                2;
            } else {
              // If there is no next task, we will create the new task right after the focused task
              // Do nothing with sortOrder here as it's already INFINITE_SORT_ORDER by default
              // ...
            }
          }
        }
      } else {
        // If no task is currently focused, we will append the new task to the top of the list
        sortOrder =
          finalTasksRef.current.length === 0
            ? INFINITE_SORT_ORDER
            : (finalTasksRef.current[0].sortOrder || 0) - SORT_ORDER_INCREMENT;
      }

      const newTask = getNewTaskData(parentTaskId);

      setPendingTasks((o) => {
        // Remove any pending task that has no name or not loading
        // This will prevent having multiple "new tasks" at the same time
        const newO = o.filter((t) => t.name || t.loading);

        return [
          ...newO,
          {
            ...newTask,
            loading: false,
            sortOrder,
          },
        ];
      });
      setFocusedTaskId(newTask.id);
    },
    [getNewTaskData], // eslint-disable-line
  );

  const shiftOrUnshiftCurrentFocusedTask = useCallback(
    (unshift?: boolean) => {
      const focusedTask = finalTasksRef.current.find(
        (t) => t.id === focusedTaskIdRef.current,
      );
      let parentTaskId: string | null = null;
      let sortOrder: number | null = focusedTask?.sortOrder || null;

      // Do nothing if current task cannot shift or unshift
      if (
        focusedTaskIdRef.current &&
        !checkIfTaskCanShiftOrUnshift(focusedTaskIdRef.current)
      ) {
        return;
      }

      // If unshift, we'll care about the previous & next top level tasks
      if (unshift) {
        // Do nothing if focused tasks is already a top level task
        if (!focusedTask?.parentTaskId) {
          return;
        }

        // Remove parentTaskId (meaning task will be moved to top level)
        parentTaskId = null;

        // Focused task will now become a top level task. Calculate sortOrder
        // First, find the current task index in the finalTasks
        const focusedTaskIndexInFinalTasks = finalTasksRef.current.findIndex(
          (t) => t.id === focusedTaskIdRef.current,
        );

        // Next, find the previous top level task (skipping subtasks)
        const previousTopLevelTask = finalTasksRef.current
          .slice(0, focusedTaskIndexInFinalTasks)
          .reverse()
          .find((t) => !t.parentTaskId);

        // ... and next top level task or standalone subtask
        const nextTopLevelTask = finalTasksRef.current
          .slice(focusedTaskIndexInFinalTasks + 1)
          .find(
            (t) =>
              !t.parentTaskId || t.parentTaskId !== focusedTaskIdRef.current,
          );

        // If both previous & next top level tasks exist, we will calculate sortOrder based on them
        if (previousTopLevelTask && nextTopLevelTask) {
          sortOrder =
            ((previousTopLevelTask.sortOrder || 0) +
              (nextTopLevelTask.sortOrder || 0)) /
            2;
        } else if (previousTopLevelTask) {
          // If there is only previous task, give it INFINITE_SORT_ORDER
          sortOrder = INFINITE_SORT_ORDER;
        }
      } else {
        // If shift, we are converting currently focused task to a subtask
        // We'll care about the previous task (can be either top level or subtask) & next subtasks

        // Do nothing if focused task is already a subtask
        if (focusedTask?.parentTaskId) {
          return;
        }

        // Find the current task index in the finalTasks
        const focusedTaskIndexInFinalTasks = finalTasksRef.current.findIndex(
          (t) => t.id === focusedTaskIdRef.current,
        );

        // Find the previous task
        const previousTask =
          finalTasksRef.current[focusedTaskIndexInFinalTasks - 1];

        if (previousTask) {
          // If previous task is a top level task, we will use its id as parentTaskId
          // Otherwise, we will use the previous task's parentTaskId as parentTaskId
          parentTaskId = previousTask.parentTaskId || previousTask.id || null;

          // Give it INFINITE_SORT_ORDER
          sortOrder = INFINITE_SORT_ORDER;
        }
      }

      if (focusedTaskIdRef.current && focusedTask) {
        const pendingTask = pendingTasksRef.current.find(
          (t) => t.id === focusedTaskIdRef.current,
        );

        if (pendingTask) {
          setPendingTasks((o) =>
            o.map((t) => {
              if (t.id === focusedTaskIdRef.current) {
                return {
                  ...t,
                  parentTaskId,
                  sortOrder,
                };
              }

              return t;
            }),
          );

          // Then update the task with mutation if
          // 1. It's a pending task but it's already loading (being created on the BE)
          if (pendingTask.loading) {
            updateTask({
              variables: {
                data: {
                  taskId: focusedTaskIdRef.current,
                  data: {
                    parentTaskId,
                    sortOrder:
                      sortOrder === INFINITE_SORT_ORDER ? null : sortOrder,
                  },
                },
              },
            });
          }
        }
      }
    },
    [updateTask], // eslint-disable-line
  );

  /**
   * Listen to keyboard events & handle creating new tasks or converting tasks to subtasks.
   * Howewer, we will disable ALL handlers when a task name has been focused. TaskName right now is built
   * with tiptap & extensions so it's very messy.
   * TODO: Handle this more gracefully when we know how to control the event bubbling from tiptap.
   */

  // Track if task name is focused
  // Beware that this might be mismatching with focusedTaskId. focusedTaskId means the task is selected,
  // which expands the task to show more details. It doesn't necessarily mean the task name is focused.
  const [isTaskNameFocused, setIsTaskNameFocused] = useState(false);

  useEffect(() => {
    if (isTaskNameFocused) {
      return;
    }

    const onKeyDown = (event: KeyboardEvent) => {
      const activeElement = document?.activeElement as HTMLElement;
      const isFocusingOnInput =
        (activeElement &&
          (activeElement.tagName === 'INPUT' ||
            activeElement.tagName === 'TEXTAREA' ||
            activeElement.isContentEditable)) || // exclude text editor
        document?.activeElement?.className.includes('ProseMirror');

      if (
        // Users hit "T" when NOT focusing on any input AND no focused task
        event.key === 't' &&
        !isFocusingOnInput &&
        shortcuts.t !== false
      ) {
        event.preventDefault();
        event.stopPropagation();

        // We will render a new task & focus on it
        createNewTask();
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [
    isTaskNameFocused,
    shortcuts,
    createNewTask,
    shiftOrUnshiftCurrentFocusedTask,
  ]);

  const onTaskNameKeyDown = (view: EditorView, event: KeyboardEvent) => {
    // Handle Enter & Tab similar to how we handled them with global keydown listeners
    // and return true to prevent tiptap from handling it further (e.g. creating new line)

    // TODO: FIND A BETTER WAY
    const isMentionExtensionActive =
      view.state['mention$']?.active ||
      view.state['mention-collection$']?.active ||
      false;

    if (event.key === 'Enter') {
      if (!isMentionExtensionActive) {
        // We will render a new task & focus on it
        createNewTask();

        return true;
      }
    }

    if (event.key === 'Tab') {
      if (!isMentionExtensionActive) {
        shiftOrUnshiftCurrentFocusedTask(event.shiftKey);
      }

      return true;
    }

    return false;
  };

  // Callback that should be called when a pending task has been updated.
  const onPendingTaskChange = async (
    task: TaskFragmentUseTaskListWithKeyboardShortcutsTopLevelTaskFragment,
  ) => {
    setPendingTasks((o) => {
      const newO = [...o];
      const taskIndex = newO.findIndex((t) => t.id === task.id);

      newO[taskIndex] = task;

      // Call update mutation only when name is provided
      if (task.name) {
        debouncedUpdateTask({
          variables: {
            data: {
              taskId: task.id,
              data: {
                name: task.name,
                status: task.status,
                priority:
                  // @ts-ignore
                  task.priority === FALLBACK_UNGROUP_ID ? null : task.priority,
                memberIds:
                  task.taskMembers.length > 0
                    ? task.taskMembers.map((m) => m.id)
                    : filters?.memberIds?.[0]
                    ? [filters?.memberIds?.[0]]
                    : [],
                endDate: task.endDate,
                postIds: filters?.postIds || [],
                parentTaskId: task.parentTaskId,
                sortOrder:
                  task.sortOrder === INFINITE_SORT_ORDER
                    ? null
                    : task.sortOrder || undefined,
              },
            },
          },
          onCompleted: (data) => {
            // Manually inject the updated task to the cache
            updateQuery((prev) => {
              const updatedTask = data.updateTask;
              const isSubtask = Boolean(updatedTask.parentTaskId);

              const newPrev = {
                ...prev,
              };

              const existingIndex = prev.tasks.data.findIndex(
                (t) =>
                  t.id === updatedTask.id ||
                  t.subtasks?.some((st) => st.id === updatedTask.id),
              );
              const existingSubtaskIndex = prev.tasks.data[
                existingIndex
              ]?.subtasks?.findIndex((st) => st.id === updatedTask.id);

              if (existingIndex > -1 && existingSubtaskIndex > -1) {
                newPrev.tasks.data[existingIndex].subtasks[
                  existingSubtaskIndex
                ] = updatedTask;
              } else if (existingIndex > -1) {
                newPrev.tasks.data[existingIndex] = updatedTask;
              } else {
                newPrev.tasks = {
                  ...newPrev.tasks,
                  data: [
                    ...prev.tasks.data.map((t) => {
                      if (t.id === updatedTask.parentTaskId) {
                        return {
                          ...t,
                          subtasks: [...t.subtasks, updatedTask],
                        };
                      }

                      return t;
                    }),
                    ...(!isSubtask ? [updatedTask] : []),
                  ],
                };
              }

              return newPrev;
            });

            // Remove the pending task
            setPendingTasks((o) => o.filter((t) => t.id !== task.id));
          },
        });

        newO[taskIndex].loading = true;
      }

      return newO;
    });
  };

  return {
    tasks: finalTasks,
    hasNextPage: data?.tasks?.pageInfo?.hasNextPage || false,
    pendingTasks,
    loading: isFirstLoading,
    focusedTaskId,
    setFocusedTaskId,
    setPendingTasks,
    createNewTask,
    onTaskNameKeyDown,
    setIsTaskNameFocused,
    onPendingTaskChange,
    fetchMore: fetchMoreTasks,
    checkIfTaskIsStandaloneSubtask,
    checkIfTaskCanShiftOrUnshift,
  };
};
