import {
  Autocomplete,
  Box,
  Popover,
  PopoverProps,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import { useAutocompleteInfiniteCollections } from 'features/collection/hooks/useAutocompleteInfiniteCollections';
import {
  COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
  CollectionListItemSkeleton,
  CollectionMenuItemAddNewView,
  CollectionMenuItemView,
} from 'features/collection/views';
import {
  CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
  ContentCalendarCollectionMenuItem,
} from 'features/contentCalendar';
import {
  CollectionPermission,
  PlotFeature,
  SearchHitType,
} from 'graphql/generated';
import { useUpdateQueryByCommand } from 'hooks/commands/useUpdateQueryByCommand';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

const filter = createFilterOptions<{ value: string; label: string }>();

interface Props extends PopoverProps {
  value: string[];
  onSelectCollection: (value: string) => void;

  onTogglePostsFavorite?: VoidFunction;
  isPostAddedToFavorites?: boolean;

  /**
   * Flag to show header Saved section
   */
  showSavedSection?: boolean;

  /**
   * Custom method if we needs to control collection creation from outside
   */
  onAddNewCollectionTrigger?: (query: string) => void;

  /**
   * Object that contains props for smart search feature
   */
  smartSearchProps?: {
    /**
     * The ids of the posts that we are saving
     */
    postIds: string[];
  };
}

export const CollectionSelectPopover = ({
  onSelectCollection,
  onAddNewCollectionTrigger,
  smartSearchProps,
  value,
  isPostAddedToFavorites,
  onTogglePostsFavorite,
  showSavedSection = true,
  ...rest
}: Props) => {
  const { orgBilling } = useUserContext();

  const [searchStr, setSearchStr] = useState('');
  const {
    autoCompleteProps,
    collectionSearchHits = [],
    loadCollectionsData,
    refetchCollections,
  } = useAutocompleteInfiniteCollections({
    postIds: [...(smartSearchProps?.postIds || [])],
    searchStr,
  });

  const { isFeatureEnabled } = useFeatureFlagContext();

  /**
   * Collections & options that change based on the search string
   */

  // By default, this should only return parent-level collections
  const collections = useMemo(() => {
    return collectionSearchHits
      .map((h) => {
        return {
          ...h.item,
          searchHitType: h.type,
        };
      })
      .filter((c) => c.myPermissions.includes(CollectionPermission.Update));
  }, [collectionSearchHits]);

  const options = useMemo(() => {
    return collections
      .filter(
        (collection, index, self) =>
          self.findIndex((c) => c.id === collection.id) === index,
      )
      .map((collection) => ({
        value: collection.id,
        label: collection.name,
      }))
      .sort((a, b) => {
        // Push selected collections to the top
        const aIsSelected = value.includes(a.value) ? 1 : 0;
        const bIsSelected = value.includes(b.value) ? 1 : 0;

        return bIsSelected - aIsSelected;
      });
  }, [collections]); // eslint-disable-line -- we don't add value here because we don't want to re-sort the options when the value changes

  // Refetch the query when a new collection is created
  const { triggerCommand } = useCommandContext();
  useUpdateQueryByCommand(COMMAND_TYPE.CREATE_COLLECTION, () => {
    refetchCollections();
  });

  // If value is not empty on mount, load the collections data
  // Otherwise wait until users focus on the input
  useEffect(() => {
    loadCollectionsData();
  }, []); // eslint-disable-line

  return (
    <Popover
      anchorOrigin={{
        vertical: -16,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disablePortal
      PaperProps={{
        sx: {
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(24px)',
          boxShadow:
            '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
          border: 'none',
        },
      }}
      {...rest}
    >
      <Box
        sx={{
          width: 320,
          color: theme.colors?.primary.black,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {showSavedSection && (
          <Box
            sx={{
              py: 4,
              px: 6,
              bgcolor: 'rgba(35, 6, 3, 0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="subhead-lg">Saved</Typography>
            <IconButtonWithTooltip
              tooltip={`${
                isPostAddedToFavorites ? 'Unsave' : 'Save'
              } this post`}
              size="small"
              sx={{
                width: 24,
                height: 24,
                p: 0,
              }}
              onClick={onTogglePostsFavorite}
            >
              <IconBoldSave
                color={
                  isPostAddedToFavorites
                    ? theme.colors?.primary.black
                    : '#23060333'
                }
                size={24}
              />
            </IconButtonWithTooltip>
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="headline-xxs"
            color={theme.colors?.utility[700]}
            mb={2}
            px={2}
            display="block"
          >
            Collections
          </Typography>
          <Autocomplete
            ListboxProps={autoCompleteProps.ListboxProps}
            ListboxComponent={autoCompleteProps.ListBoxComponent}
            inputValue={searchStr}
            onInputChange={(_, value, reason) => {
              if (reason === 'input') {
                setSearchStr(value);
              }
            }}
            open
            multiple
            value={[]}
            options={options}
            loadingText={
              <Box display="flex" flexDirection="column" gap={4}>
                <CollectionListItemSkeleton />
                <CollectionListItemSkeleton />
              </Box>
            }
            disablePortal
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                placeholder="Search"
                sx={{
                  px: 2,
                  '.MuiOutlinedInput-root': {
                    height: '32px',
                    borderRadius: 100,
                    py: '6px !important',
                    px: '12px !important',
                    bgcolor: theme.colors?.primary.white,
                    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',

                    input: {
                      ...theme.typography['subhead-lg'],
                      p: '0 !important',
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                      display: 'none !important',
                    },
                  },
                }}
              />
            )}
            renderTags={() => null}
            renderOption={(props, option) => {
              if (option.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY) {
                return (
                  <CollectionMenuItemAddNewView
                    {...props}
                    key={option.value}
                    label={option.label}
                  />
                );
              }

              const commonMenuItemProps = {
                componentProps: {
                  listItem: {
                    sx: {
                      maxWidth: 300,
                    },
                  },
                  menu: {
                    sx: {
                      '& .MuiPaper-root': {
                        boxShadow:
                          'rgba(24, 39, 75, 0.12) 0px 12px 42px -4px, rgba(24, 39, 75, 0.12) 0px 8px 18px -6px !important',
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                        backdropFilter: 'blur(24px)',
                        width: 300,
                      },
                    },
                  },
                },
              };

              if (
                option.value === CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY
              ) {
                return (
                  <ContentCalendarCollectionMenuItem
                    key={option.value}
                    onClick={(selectedCollection) => {
                      if (selectedCollection) {
                        onSelectCollection(selectedCollection.id);
                      }
                    }}
                    selectedCollectionIds={value}
                    {...commonMenuItemProps}
                  />
                );
              }

              const collection = collections.find((c) => c.id === option.value);

              if (collection) {
                return (
                  <CollectionMenuItemView
                    key={collection.id}
                    collection={collection}
                    onClick={(selectedCollection) =>
                      onSelectCollection(selectedCollection.id)
                    }
                    selectedCollectionIds={value}
                    shouldShowBreadcrumbsForRoot
                    {...commonMenuItemProps}
                    componentProps={{
                      ...commonMenuItemProps.componentProps,
                      listItem: {
                        ...commonMenuItemProps.componentProps.listItem,
                        isSmartSearchResult:
                          collection.searchHitType === SearchHitType.Smart,
                      },
                    }}
                  />
                );
              }

              return null;
            }}
            onChange={(event, value, reason, details) => {
              const { option } = details || {};

              // Only handle when user selects the add new option
              // For other options, we handle them in renderOption & only accept click event
              // Reason: It's confusing to let default keyboard events work here, because users can randomly Enter
              // and toggle collection without knowing it
              const canCreate = orgBilling?.collectionUsageLimit
                ? orgBilling.collectionUsageCount <
                  orgBilling.collectionUsageLimit
                : true;
              if (
                canCreate &&
                option?.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY
              ) {
                setSearchStr('');
                triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
                  initialValues: {
                    name: option.label,
                    posts: [],
                  },
                  onCompleted: (collection) => {
                    onSelectCollection(collection.id);
                  },
                  onSelectExistingCollection: (collectionId) => {
                    onSelectCollection(collectionId);
                  },
                });
              }
            }}
            popupIcon={null}
            clearIcon={null}
            // @ts-ignore
            PopperComponent={Box}
            PaperComponent={Box}
            componentsProps={{
              popper: {
                sx: {
                  mt: 4,
                  flex: 1,
                  position: 'relative',
                  width: '100% !important',
                  overflow: 'auto',
                  height: '100%',
                  '.MuiAutocomplete-listbox': {
                    maxHeight: 'unset',
                  },
                },
              },
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const isOptionExist = options.some(
                (option) =>
                  option.label.trim().toLowerCase() ===
                  params.inputValue.trim().toLowerCase(),
              );

              if (params.inputValue !== '' && !isOptionExist) {
                filtered.push({
                  label: params.inputValue,
                  value: COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
                });
              } else if (isFeatureEnabled(PlotFeature.ContentCalendar)) {
                filtered.unshift({
                  label: 'Content Calendar',
                  value: CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
                });
              }

              return filtered;
            }}
          />
        </Box>
      </Box>
      {orgBilling && (
        <BillingCollectionLimitIndicator
          variant="compact"
          organizationBilling={orgBilling}
          sx={{ borderRadius: theme.spacing(0, 0, 3, 3), width: 320 }}
        />
      )}
    </Popover>
  );
};
