import { Box, Typography } from '@mui/material';
import { CommandKey } from 'components/common/CommandKey';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

export type TaskListWithKeyboardShortcutsCommandHelperProps = {
  state: {
    createTaskGlobal?: boolean;
    createTask?: boolean;
    createSubtask?: boolean;
    shiftTask?: boolean;
    unshiftSubtask?: boolean;
  };
};

export const TaskListWithKeyboardShortcutsCommandHelper = (
  props: TaskListWithKeyboardShortcutsCommandHelperProps,
) => {
  const {
    state = {
      createTaskGlobal: true,
      createTask: false,
      createSubtask: false,
      shiftTask: false,
      unshiftSubtask: false,
    },
  } = props;

  const commands = useMemo(() => {
    // Can create but can't shift -> create task with T (global)
    if (state.createTaskGlobal) {
      return [['T', 'Create a task']];
    }

    // Can create and can shift -> create top level task when focusing on a top level task
    if (state.createTask && state.shiftTask) {
      return [
        ['enter', 'Create a task'],
        ['tab', 'Create a subtask'],
      ];
    }

    // Can create subtask and can unshift -> create subtask when focusing on a subtask
    if (state.createSubtask && state.unshiftSubtask) {
      return [
        ['enter', 'Create a subtask'],
        ['shift + tab', 'Unshift subtask'],
      ];
    }

    // Can create task but can't shift -> create task when focusing on a standalone subtask
    // or a top level task that has standalone subtask as the previous task (standalone subtask can't be a parent task)
    if (state.createTask && !state.shiftTask) {
      return [['enter', 'Create a task']];
    }

    return [];
  }, [state]);

  return (
    <Box
      sx={{
        px: 6,
        py: 4,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 6,
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }}
    >
      {commands.map(([key, label]) => {
        return (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} key={key}>
            <CommandKey>{key}</CommandKey>
            <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
              {label}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
