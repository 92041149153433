import { Box, Button } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { theme } from 'styles/theme';

interface Props {
  onAddContent: VoidFunction;
  variant?: 'button' | 'default';
}

export const ContentIdeaCalendarEventViewSkeleton = ({
  onAddContent,
  variant = 'default',
}: Props) => {
  const renderAddContentBtn = () => {
    return (
      <Button
        size="small"
        sx={{
          width: '100%',
          bgcolor: theme.colors?.primary.black,
          color: theme.colors?.primary.white,
          ...theme.typography['headline-xxs'],
          borderRadius: 1.5,
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(1.5, 1),
          gap: 0.5,
          height: 'fit-content',
          ':hover': {
            bgcolor: theme.colors?.primary.black,
          },
        }}
        onClick={onAddContent}
      >
        <IconLinearAdd size={16} color={theme.colors?.primary.parchment} />
        Add Content
      </Button>
    );
  };

  if (variant === 'button') {
    return renderAddContentBtn();
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 1.5,
        bgcolor: theme.colors?.utility[275],
        borderRadius: 2,
        width: '100%',
        height: 'fit-content',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            bgcolor: theme.colors?.utility[400],
            borderRadius: '100%',
          }}
        />
        <Box
          sx={{
            width: 14,
            height: 14,
            borderRadius: 0.5,
            bgcolor: `rgba(35, 6, 3, 0.05)`,
          }}
        />
      </Box>
      <Box
        sx={{
          bgcolor: theme.colors?.utility[400],
          borderRadius: 0.5,
          height: 12,
          width: '90%',
        }}
      />
      <Box
        sx={{
          bgcolor: theme.colors?.utility[400],
          height: 75,
          borderRadius: 2,
        }}
      />
      {renderAddContentBtn()}
    </Box>
  );
};
