import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldArrowDown } from 'components/icons/components/bold/IconBoldArrowDown';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { BrandConnectedCreators } from 'features/brand';
import { BrandCompetitorsPlatformSelector } from 'features/brandCompetitors/components';
import { CreatorHandleChip } from 'features/creator';
import {
  SLPageBrandSelector,
  SocialMediaListeningFilterByDateRangeButton,
} from 'features/socialMediaListening';
import { BrandParsedHours } from 'features/socialMediaListening/components/brandParsedHours';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  AverageCompetitorSentiment,
  Platform,
  SortOrder,
  useGetBrandsForBrandCompetitorsViewQuery,
  useGetCompetitorFlatCreatorsListForBrandCompetitorsViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { BrandCompetitorsListViewSkeleton } from './BrandCompetitorsListViewSkeleton';
import { TableHeaders } from './consts';

const AverageSentimentLabels = {
  [AverageCompetitorSentiment.Positive]: 'Positive',
  [AverageCompetitorSentiment.SlightlyPositive]: 'Slightly Positive',
  [AverageCompetitorSentiment.Neutral]: 'Neutral',
  [AverageCompetitorSentiment.SlightlyNegative]: 'Slightly Negative',
  [AverageCompetitorSentiment.Negative]: 'Negative',
};

type Props = {
  brandId: string;
};

export const BrandCompetitorsView = ({ brandId }: Props) => {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(30, 'days').toDate(),
    moment().toDate(),
  ]);

  const { data: brands } = useGetBrandsForBrandCompetitorsViewQuery();
  const currentBrand = brands?.brands.find((brand) => brand.id === brandId);

  const [currentPlatform, setCurrentPlatform] = useState(Platform.Tiktok);
  const [sortData, setSortData] = useState<{
    key: string;
    order: SortOrder;
  }>();

  const { data: competitorsData, loading: loadingCompetitors } =
    useGetCompetitorFlatCreatorsListForBrandCompetitorsViewQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        data: {
          brandId,
          filters: {
            platforms: [currentPlatform],
            dateRange: {
              startDate: dateRange[0],
              endDate: dateRange[1],
            },
          },
          ...(sortData
            ? {
                sortBy: {
                  field: sortData?.key,
                  order: sortData?.order,
                },
              }
            : {}),
        },
        socialPostsData: {
          take: 3,
          dateRange: {
            startDate: dateRange[0],
            endDate: dateRange[1],
          },
        },
      },
    });
  const flatCompetitors =
    competitorsData?.searchCompetitorFlatCreators.data || [];

  return (
    <Box mt={16}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={4}
        px={8}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={2}
        >
          <SLPageBrandSelector
            generateUrl={(brandId) =>
              PlotRoutes.socialListeningCompetitors(brandId)
            }
          />
        </Box>
        <Box marginLeft="auto" display="flex" alignItems="center" columnGap={2}>
          <BrandParsedHours />
          {currentBrand && (
            <Box
              borderRadius={10}
              bgcolor={theme.colors?.utility[275]}
              padding={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 'center',
              }}
              component="button"
              onClick={() => {
                navigate(
                  PlotRoutes.socialListeningSettings({
                    brandId: currentBrand.id,
                  }),
                );
              }}
            >
              <IconBoldSettings2 size={22} color={theme.colors?.utility[600]} />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={8}
        pb={1}
      >
        <Typography
          variant="headline-xl"
          fontSize={24}
          fontWeight={500}
          letterSpacing="-0.96px"
          color={theme.colors?.utility[700]}
        >
          Competitor Analytics
        </Typography>
        <Box display="flex" gap={2} flexDirection="column">
          {currentBrand && (
            <BrandConnectedCreators
              brand={currentBrand}
              sx={{
                borderRadius: 2,
              }}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: `1px solid ${theme.colors?.utility[300]}`,
          borderTop: `1px solid ${theme.colors?.utility[300]}`,
          position: 'sticky',
          top: 64,
          zIndex: 10,
          backgroundColor: theme.colors?.utility[200],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          p: theme.spacing(4, 8),
          my: 4,
        }}
      >
        <BrandCompetitorsPlatformSelector
          onPlatformSelect={setCurrentPlatform}
        />
        <SocialMediaListeningFilterByDateRangeButton
          onChange={(range) => {
            if (range[0] && range[1]) {
              setDateRange(range as [Date, Date]);
            }
          }}
          selectedDateRange={dateRange}
        />
      </Box>
      <Box
        sx={{
          width: `calc(100vw - 0)`,
          overflowX: 'auto',
          mx: 8,
        }}
      >
        {loadingCompetitors ? (
          <BrandCompetitorsListViewSkeleton />
        ) : (
          <Table
            sx={{
              mt: 4,
              overflowX: 'auto',
              '& td': {
                border: 'none',
              },
            }}
          >
            <TableHead>
              <TableRow>
                {TableHeaders.map((header, index) => (
                  <TableCell
                    key={`${header.key}-${index}`}
                    sx={{
                      ...header.sx,
                      ...(header.canSort ? { cursor: 'pointer' } : {}),
                    }}
                    onClick={() => {
                      if (header.canSort) {
                        if (
                          sortData?.key === header.key &&
                          sortData?.order === SortOrder.Desc
                        ) {
                          setSortData(undefined);
                        } else {
                          setSortData({
                            key: header.key,
                            order:
                              sortData?.key === header.key
                                ? sortData?.order === SortOrder.Asc
                                  ? SortOrder.Desc
                                  : SortOrder.Asc
                                : SortOrder.Asc,
                          });
                        }
                      }
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                      component="span"
                    >
                      {header.label}

                      {header.canSort && (
                        <Box display="flex" gap={0} flexDirection="column">
                          {sortData?.key === header.key && (
                            <>
                              {sortData?.order === SortOrder.Asc && (
                                <IconBoldArrowDown
                                  size={16}
                                  style={{
                                    transform: 'rotate(180deg)',
                                  }}
                                />
                              )}
                              {sortData?.order === SortOrder.Desc && (
                                <IconBoldArrowDown size={16} />
                              )}
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {flatCompetitors.map((flatCompetitor, index) => {
                return (
                  <TableRow
                    key={flatCompetitor.id}
                    component={Link}
                    to={PlotRoutes.socialListeningCompetitor(
                      flatCompetitor.competitorId,
                      brandId,
                      currentPlatform,
                    )}
                    sx={{
                      '&:hover': {
                        backgroundColor: theme.colors?.utility[275],
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <TableCell>{index + 1}.</TableCell>
                    <TableCell>
                      <Avatar
                        src={
                          flatCompetitor.trackingCreator?.profilePictureUrl ||
                          ''
                        }
                        sx={{
                          width: theme.spacing(12),
                          height: theme.spacing(12),
                          border: `2px solid ${theme.colors?.utility[300]}`,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['headline-sm'],
                      }}
                    >
                      {flatCompetitor.trackingBrand?.name}
                      <CreatorHandleChip
                        creator={flatCompetitor.trackingCreator}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {formatBigNumber(
                        flatCompetitor.trackingCreator?.followerCount,
                      )}{' '}
                      followers
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {formatBigNumber(flatCompetitor.totalImpressions || 0)}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {Number(flatCompetitor.averageEngagementRate).toFixed(1)}%
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {formatBigNumber(flatCompetitor.averageVideoViews || 0)}{' '}
                      average views
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 600,
                      }}
                    >
                      {formatBigNumber(flatCompetitor.averageDigg || 0)} average
                      likes
                    </TableCell>
                    <TableCell>
                      <Box display="flex" gap={2} alignItems="center">
                        {flatCompetitor.socialPosts?.data.length ? (
                          flatCompetitor.socialPosts?.data.map((post) => (
                            <SocialPostThumbnail
                              socialPost={post}
                              sx={{
                                width: theme.spacing(10),
                                height: theme.spacing(14),
                                borderRadius: theme.spacing(1),
                                backgroundColor: theme.colors?.utility[300],
                                p: 0,
                              }}
                              componentProps={{
                                hideCreatedTime: true,
                                hidePlatform: true,
                                thumbnailFallback: (
                                  <IconOutlineImage size={24} />
                                ),
                              }}
                            />
                          ))
                        ) : (
                          <Typography
                            variant="body-lg"
                            color={theme.colors?.utility[700]}
                          >
                            -
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 500,
                      }}
                    >
                      <Box component="span" fontWeight={600}>
                        {formatBigNumber(
                          flatCompetitor?.averageSocialPostCreationPerWeek || 0,
                        )}
                      </Box>
                      {' / week'}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 500,
                      }}
                    >
                      <Box component="span" fontWeight={600}>
                        {formatBigNumber(
                          flatCompetitor?.averageBrandMentionedPerWeek || 0,
                        )}
                      </Box>
                      {' / week'}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...typography['body-lg'],
                        fontWeight: 500,
                      }}
                    >
                      {
                        AverageSentimentLabels[
                          flatCompetitor.averageCreatorSentiment
                        ]
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  );
};
