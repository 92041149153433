import { Box, Button, Radio, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { CreatorAvatarWithNameView } from 'features/creator';
import {
  CreatorTrackingList,
  SocialMediaListeningCreatorProfile,
  useSLBrandPermissions,
} from 'features/socialMediaListening';
import {
  CreatorFragmentCompetitorTopCreatorsFragment,
  SortOrder,
  useGetCreatorsThatMentionedCompetitorForCompetitorTopCreatorsQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { CompetitorTopCreatorsSkeleton } from './CompetitorTopCreatorsSkeleton';
import { SocialMediaListeningCompetitorDetailTopHashtagsProps } from './types';

type CreatorScoreType = [CreatorFragmentCompetitorTopCreatorsFragment, string];
enum SortKey {
  NumberOfBrandMentions = 'numberOfBrandMentions',
  FollowerCount = 'followerCount',
}

export const CompetitorTopCreators = ({
  brandId,
  filters,
  onCreatorIdSelect,
}: SocialMediaListeningCompetitorDetailTopHashtagsProps) => {
  const { hasFullAccess } = useSLBrandPermissions({ brandId });
  const [sortKey, setSortKey] = useState(SortKey.NumberOfBrandMentions);

  const { data: topCreatorsData, loading: loadingCreators } =
    useGetCreatorsThatMentionedCompetitorForCompetitorTopCreatorsQuery({
      variables: {
        data: {
          ...filters,
          take: 12,
          sortBy: {
            field: sortKey,
            order: SortOrder.Desc,
          },
        },
      },
      skip: !filters.creatorId,
      fetchPolicy: 'cache-and-network',
    });

  const renderData = useMemo(() => {
    return (topCreatorsData?.getCreatorsThatMentionedCompetitor.data || []).map(
      (creator) => {
        return [
          creator,
          sortKey === SortKey.NumberOfBrandMentions
            ? formatBigNumber(creator.numberOfBrandMentions || 0)
            : formatBigNumber(creator.followerCount),
        ] as CreatorScoreType;
      },
    );
  }, [sortKey, topCreatorsData?.getCreatorsThatMentionedCompetitor.data]);

  if (loadingCreators) {
    return <CompetitorTopCreatorsSkeleton />;
  }

  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="headline-sm"
          color={theme.colors?.utility[700]}
          pb={1}
          display="flex"
          alignItems="center"
          gap={2}
        >
          Top creators
          <Tooltip title="Top creators are ranked by the number of times they have mentioned the brand ">
            <Box display="flex" alignItems="center">
              <IconBoldInfoCircle size={16} />
            </Box>
          </Tooltip>
        </Typography>

        <ContextMenu
          sx={{
            '& .MuiMenuItem-root': {
              p: 0,
              color: theme.colors?.primary.black,
            },
          }}
          options={[
            {
              renderOption: () => (
                <Box display="flex" alignItems="center">
                  <Radio checked={sortKey === SortKey.NumberOfBrandMentions} />
                  <Typography variant="body-lg">
                    Number of brand mentions
                  </Typography>
                </Box>
              ),
              onClick: () => {
                setSortKey(SortKey.NumberOfBrandMentions);
              },
            },
            {
              renderOption: () => (
                <Box display="flex" alignItems="center">
                  <Radio checked={sortKey === SortKey.FollowerCount} />
                  <Typography variant="body-lg">Number of followers</Typography>
                </Box>
              ),
              onClick: () => {
                setSortKey(SortKey.FollowerCount);
              },
            },
          ]}
          renderButton={() => (
            <Button
              sx={{
                backgroundColor: theme.colors?.utility[275],
                color: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                display: 'flex',
                gap: 2,
                p: theme.spacing(1, 2),
                alignItems: 'center',
                '&:hover': { backgroundColor: theme.colors?.utility[275] },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(35, 6, 3, 0.05);',
                  p: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                }}
              >
                <IconLinearSort size={16} />
              </Box>
              <Typography fontWeight={500} variant="body-xl">
                {sortKey === SortKey.NumberOfBrandMentions &&
                  'Number of brand mentions'}
                {sortKey === SortKey.FollowerCount && 'Number of followers'}
              </Typography>
            </Button>
          )}
        />
      </Box>

      {!renderData.length && (
        <Typography
          variant="body-lg"
          color={theme.colors?.utility[700]}
          minHeight={100}
          width="100%"
          display="flex"
          justifyContent="center"
          mt={4}
        >
          No creators found
        </Typography>
      )}

      {!!renderData.length && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flexWrap="wrap"
        >
          {renderData.map((item, colIndex) => (
            <SocialMediaListeningCreatorProfile
              creator={item[0]}
              componentProps={{
                showDetailsOnHover: true,
                renderCreatorTrackingButton: () =>
                  hasFullAccess && (
                    <CreatorTrackingList brandId={brandId} creator={item[0]} />
                  ),
              }}
              renderCustomCreatorProfile={() => (
                <Box
                  onClick={() => onCreatorIdSelect(item[0].id)}
                  key={colIndex}
                  display="flex"
                  flex="1 1 25%"
                  boxSizing="border-box"
                  p={1}
                  width="25%"
                  maxWidth="25%"
                  overflow="hidden"
                >
                  <Box
                    pr={4}
                    py={2}
                    pl={0}
                    display="flex"
                    gap={3}
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <CreatorAvatarWithNameView
                      creator={item[0]}
                      sx={{
                        p: 1,
                      }}
                      componentsProps={{
                        avatar: {
                          sx: {
                            width: theme.spacing(11),
                            height: theme.spacing(11),
                          },
                        },
                        nickName: { sx: { ...typography['headline-md'] } },
                        handle: { sx: { ...typography['body-lg'] } },
                      }}
                    />
                    <Typography variant="headline-sm">{item[1]}</Typography>
                  </Box>
                </Box>
              )}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
