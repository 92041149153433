import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledCommentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 4,
  width: '100%',
}));

export const StyledRichTextWrapper = styled(Box)<{ isEditing: boolean }>(
  ({ isEditing }) => ({
    width: '100%',
    ...(isEditing
      ? {
          backgroundColor: theme.colors?.utility[250],
          borderRadius: 8,
        }
      : {}),
    '& p': {
      margin: 0,
    },
  }),
);
