import { Box, Typography } from '@mui/material';
import { BillingPlanOptions } from 'features/billing';
import { theme } from 'styles/theme/theme';

export const BillingMobileView = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        overflow: 'hidden',
        p: theme.spacing(6),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(6),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(4),
          }}
        >
          <Typography
            variant="headline-xl"
            fontSize={40}
            fontWeight="500"
            color={theme.colors?.primary.black}
          >
            Plot Pricing
          </Typography>
          <Typography
            variant="subhead-xl"
            textAlign="center"
            color={theme.colors?.primary.black}
          >
            Start for free and upgrade to
            <br />
            unlock more features.
          </Typography>
        </Box>
      </Box>
      <BillingPlanOptions title="Pricing" />
    </Box>
  );
};
