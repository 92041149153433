import { theme } from 'styles/theme/theme';

export type PostThemeColor = {
  color?: string;
  iconColor?: string;
  textColor?: string;
  textPlaceholderColor?: string;
  barBorderColor?: string;
  barBgColor?: string;
  itemBorderColor?: string;
  focusBgColor?: string;
  textMentionColor?: string;
  textSelectionBackgroundColor?: string;
  textCommentedBackgroundColor?: string;
  textCommentedBackgroundColorL2?: string;
  textCommentedBackgroundColorL3?: string;
  textCommentedHighlightedBackgroundColor?: string;
  dividerColor?: string;
  linkColor?: string;
};

export const NOTE_COLORS: PostThemeColor[] = [
  {
    color: theme.colors?.utility[250],
    iconColor: 'rgb(35 6 3 / 40%)',
    textColor: theme.colors?.primary.black,
    textPlaceholderColor: theme.colors?.utility[600],
    barBgColor: theme.colors?.utility[300],
    barBorderColor: 'transparent',
    itemBorderColor: theme.colors?.utility.blueberry,
    focusBgColor: 'rgb(100 100 100 / 5%)',
    textMentionColor: theme.colors?.primary.maroon,
    textSelectionBackgroundColor: 'rgba(0, 0, 0, 0.1)',
    textCommentedBackgroundColor: 'rgb(252, 232, 192, 0.4)',
    textCommentedBackgroundColorL2: 'rgb(252, 232, 192, 0.5)',
    textCommentedBackgroundColorL3: 'rgb(252, 232, 192, 0.6)',
    textCommentedHighlightedBackgroundColor: 'rgb(252, 232, 192, 0.7)',
    dividerColor: '#C9C9C9',
    linkColor: theme.colors?.primary.maroon,
  },
  {
    color: theme.colors?.utility['black-currant'],
    iconColor: theme.colors?.primary.parchment,
    textColor: theme.colors?.primary.parchment,
    textPlaceholderColor: theme.colors?.utility[600],
    // TODO: update the name for there colors
    barBgColor: 'rgba(255, 255, 255, 0.4)',
    barBorderColor: 'rgba(15, 15, 15, 0.1)',
    itemBorderColor: 'rgba(255, 255, 255, 0.7)',
    focusBgColor: 'rgb(250 243 236 / 15%)',
    textMentionColor: theme.colors?.utility['yellow-4'],
    textSelectionBackgroundColor: 'rgba(255, 255, 255, 0.2)',
    textCommentedBackgroundColor: 'rgb(252, 232, 192, 0.1)',
    textCommentedBackgroundColorL2: 'rgb(252, 232, 192, 0.2)',
    textCommentedBackgroundColorL3: 'rgb(252, 232, 192, 0.3)',
    textCommentedHighlightedBackgroundColor: 'rgb(252, 232, 192, 0.3)',
    dividerColor: '#8D8D8D',
    linkColor: theme.colors?.utility['yellow-1'],
  },
  {
    color: theme.colors?.utility.grape,
    iconColor: theme.colors?.primary.parchment,
    textColor: theme.colors?.primary.parchment,
    textPlaceholderColor: theme.colors?.primary.parchment,
    barBgColor: 'rgba(255, 255, 255, 0.4)',
    barBorderColor: 'rgba(15, 15, 15, 0.1)',
    itemBorderColor: 'rgba(255, 255, 255, 0.7)',
    focusBgColor: 'rgb(250 243 236 / 15%)',
    textMentionColor: theme.colors?.utility['yellow-4'],
    textSelectionBackgroundColor: 'rgba(255, 255, 255, 0.2)',
    textCommentedBackgroundColor: 'rgb(252, 232, 192, 0.1)',
    textCommentedBackgroundColorL2: 'rgb(252, 232, 192, 0.2)',
    textCommentedBackgroundColorL3: 'rgb(252, 232, 192, 0.3)',
    textCommentedHighlightedBackgroundColor: 'rgb(252, 232, 192, 0.3)',
    dividerColor: '#8D8D8D',
    linkColor: theme.colors?.utility['yellow-1'],
  },
  {
    color: theme.colors?.utility.blueberry,
    iconColor: theme.colors?.primary.parchment,
    textColor: theme.colors?.primary.parchment,
    textPlaceholderColor: theme.colors?.primary.parchment,
    barBgColor: 'rgba(255, 255, 255, 0.4)',
    barBorderColor: 'rgba(15, 15, 15, 0.1)',
    itemBorderColor: 'rgba(255, 255, 255, 0.7)',
    focusBgColor: 'rgb(250 243 236 / 15%)',
    textMentionColor: theme.colors?.primary.parchment,
    textSelectionBackgroundColor: 'rgba(255, 255, 255, 0.2)',
    textCommentedBackgroundColor: 'rgb(252, 232, 192, 0.1)',
    textCommentedBackgroundColorL2: 'rgb(252, 232, 192, 0.2)',
    textCommentedBackgroundColorL3: 'rgb(252, 232, 192, 0.3)',
    textCommentedHighlightedBackgroundColor: 'rgb(252, 232, 192, 0.3)',
    dividerColor: '#8D8D8D',
    linkColor: theme.colors?.utility['yellow-1'],
  },
  {
    color: theme.colors?.utility.olive,
    iconColor: theme.colors?.primary.parchment,
    textColor: theme.colors?.primary.parchment,
    textPlaceholderColor: theme.colors?.primary.parchment,
    barBgColor: 'rgba(255, 255, 255, 0.4)',
    barBorderColor: 'rgba(15, 15, 15, 0.1)',
    itemBorderColor: 'rgba(255, 255, 255, 0.7)',
    focusBgColor: 'rgb(250 243 236 / 10%)',
    textMentionColor: theme.colors?.utility['yellow-4'],
    textSelectionBackgroundColor: 'rgba(255, 255, 255, 0.2)',
    textCommentedBackgroundColor: 'rgb(252, 232, 192, 0.1)',
    textCommentedBackgroundColorL2: 'rgb(252, 232, 192, 0.2)',
    textCommentedBackgroundColorL3: 'rgb(252, 232, 192, 0.3)',
    textCommentedHighlightedBackgroundColor: 'rgb(252, 232, 192, 0.3)',
    dividerColor: '#8D8D8D',
    linkColor: theme.colors?.utility['yellow-1'],
  },
  {
    color: theme.colors?.utility.tamarind,
    iconColor: theme.colors?.primary.parchment,
    textColor: theme.colors?.primary.parchment,
    textPlaceholderColor: theme.colors?.primary.parchment,
    barBgColor: 'rgba(255, 255, 255, 0.4)',
    barBorderColor: 'rgba(15, 15, 15, 0.1)',
    itemBorderColor: 'rgba(255, 255, 255, 0.7)',
    focusBgColor: 'rgb(250 243 236 / 15%)',
    textMentionColor: theme.colors?.utility['yellow-4'],
    textSelectionBackgroundColor: 'rgba(255, 255, 255, 0.2)',
    textCommentedBackgroundColor: 'rgb(252, 232, 192, 0.1)',
    textCommentedBackgroundColorL2: 'rgb(252, 232, 192, 0.2)',
    textCommentedBackgroundColorL3: 'rgb(252, 232, 192, 0.3)',
    textCommentedHighlightedBackgroundColor: 'rgb(252, 232, 192, 0.3)',
    dividerColor: '#8D8D8D',
    linkColor: theme.colors?.utility['yellow-1'],
  },
  {
    color: theme.colors?.utility.turnip,
    iconColor: theme.colors?.primary.parchment,
    textColor: theme.colors?.primary.parchment,
    textPlaceholderColor: theme.colors?.primary.parchment,
    barBgColor: 'rgba(255, 255, 255, 0.4)',
    barBorderColor: 'rgba(15, 15, 15, 0.1)',
    itemBorderColor: 'rgba(255, 255, 255, 0.7)',
    focusBgColor: 'rgb(250 243 236 / 15%)',
    textMentionColor: theme.colors?.utility['yellow-4'],
    textSelectionBackgroundColor: 'rgba(255, 255, 255, 0.2)',
    textCommentedBackgroundColor: 'rgb(252, 232, 192, 0.1)',
    textCommentedBackgroundColorL2: 'rgb(252, 232, 192, 0.2)',
    textCommentedBackgroundColorL3: 'rgb(252, 232, 192, 0.3)',
    textCommentedHighlightedBackgroundColor: 'rgb(252, 232, 192, 0.3)',
    dividerColor: '#8D8D8D',
    linkColor: theme.colors?.utility['yellow-1'],
  },
];
