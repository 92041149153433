import { createContext } from '@dwarvesf/react-utils';
import { SxProps } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';

type CustomHeaderContextProps = {
  headerMenuOptions: {
    search?: boolean;
    copyLink?: boolean;
    inbox?: boolean;
  };
  setHeaderMenuOptions: (options: {
    search?: boolean;
    copyLink?: boolean;
    inbox?: boolean;
  }) => void;

  renderMenuExtraLeft?: ((() => React.ReactNode) | null)[];
  addRenderMenuExtraLeft: (
    render: () => React.ReactNode,
    slot?: number,
  ) => void;
  removeRenderMenuExtraLeft: (
    render: () => React.ReactNode,
    slot?: number,
  ) => void;

  renderMenuExtraRight?: ((() => React.ReactNode) | null)[];
  addRenderMenuExtraRight: (
    render: () => React.ReactNode,
    slot?: number,
  ) => void;
  removeRenderMenuExtraRight: (
    render: () => React.ReactNode,
    slot?: number,
  ) => void;

  renderTitle?: () => React.ReactNode;
  setRenderTitle: (render: () => React.ReactNode) => void;

  headerSx?: SxProps;
  setHeaderSx: (sx: SxProps) => void;
};

const [Provider, useCustomHeaderContext] =
  createContext<CustomHeaderContextProps>();

export const CustomHeaderContextProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const [headerMenuOptions, setHeaderMenuOptions] = useState<
    CustomHeaderContextProps['headerMenuOptions']
  >({
    search: true,
    copyLink: false,
    inbox: true,
  });

  const [renderMenuExtraLeft, setRenderMenuExtraLeft] = useState<
    ((() => React.ReactNode) | null)[]
  >([]);
  const addRenderMenuExtraLeft = (
    render: () => React.ReactNode,
    slot?: number,
  ) => {
    if (slot !== undefined) {
      setRenderMenuExtraLeft((prev) => {
        const next = [...prev];
        next[slot] = render;
        return next;
      });
    } else {
      setRenderMenuExtraLeft((prev) => [...prev, render]);
    }
  };
  const removeRenderMenuExtraLeft = (
    render: () => React.ReactNode,
    slot?: number,
  ) => {
    if (slot !== undefined) {
      setRenderMenuExtraLeft((prev) => {
        return [...prev].map((r, i) => (i === slot ? null : r));
      });
    } else {
      setRenderMenuExtraLeft((prev) => prev.filter((r) => r !== render));
    }
  };

  const [renderMenuExtraRight, setRenderMenuExtraRight] = useState<
    ((() => React.ReactNode) | null)[]
  >([]);
  const addRenderMenuExtraRight = (
    render: () => React.ReactNode,
    slot?: number,
  ) => {
    if (slot !== undefined) {
      setRenderMenuExtraRight((prev) => {
        const next = [...prev];
        next[slot] = render;
        return next;
      });
    } else {
      setRenderMenuExtraRight((prev) => [...prev, render]);
    }
  };
  const removeRenderMenuExtraRight = (
    render: () => React.ReactNode,
    slot?: number,
  ) => {
    if (slot !== undefined) {
      setRenderMenuExtraRight((prev) => {
        return [...prev].map((r, i) => (i === slot ? null : r));
      });
    } else {
      setRenderMenuExtraRight((prev) => prev.filter((r) => r !== render));
    }
  };

  const [renderTitle, setRenderTitle] = useState<() => React.ReactNode>();

  const [headerSx, setHeaderSx] = useState<SxProps>();

  return (
    <Provider
      value={{
        headerMenuOptions,
        setHeaderMenuOptions,

        renderMenuExtraLeft,
        addRenderMenuExtraLeft,
        removeRenderMenuExtraLeft,

        renderMenuExtraRight,
        addRenderMenuExtraRight,
        removeRenderMenuExtraRight,

        renderTitle,
        setRenderTitle,

        headerSx,
        setHeaderSx,
      }}
    >
      {children}
    </Provider>
  );
};

export { useCustomHeaderContext };
