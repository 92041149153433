import { Node } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';
import { MousePos } from 'features/tiptap/types';
import { determineWhichSideOfDocUserIsHoveringOver } from 'features/tiptap/utils';
import { ATTR_BLOCK_ID } from '../constants';

/**
 * This method aims to return the node that the mouse is hovering over.
 * This is different from `posAtCoords` (a built-in method of prosemirror),
 * because we only care about the block's bounding box, whereas `posAtCoords`
 * consider a lot of other things like caret position, inline nodes, etc.
 *
 * @param view
 * @param mousePos
 */
export const blockAtCoords = (
  view: EditorView,
  mousePos: MousePos,
  offset?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  },
) => {
  let deepestBlockPos = -1;
  let deepestBlockNode: Node | null = null;

  // If user is hovering outside the boundary of the whole doc
  // we'll need to handle things differently
  const sideFromDoc = determineWhichSideOfDocUserIsHoveringOver(
    mousePos,
    offset,
  );
  if (sideFromDoc && ['top', 'bottom'].includes(sideFromDoc)) {
    // Dragging on the top -> return the first block node
    if (sideFromDoc === 'top') {
      return {
        node: view.state.doc.firstChild as Node,
        docPos: 0,
      };
    }

    // Dragging on the bottom -> return the last block node
    if (sideFromDoc === 'bottom') {
      return {
        node: view.state.doc.lastChild as Node,
        docPos: view.state.doc.nodeSize - 2,
      };
    }

    return {
      node: deepestBlockNode as Node | null,
      docPos: deepestBlockPos,
    };
  }

  // Find the deepest block node that the mouse is hovering over
  view.state.doc.descendants((node, pos) => {
    const resolvedPosDepth = view.state.doc.resolve(pos).depth;
    const resolvedDeepestBlockPosDepth =
      deepestBlockPos >= 0 ? view.state.doc.resolve(deepestBlockPos).depth : 0;

    // Skip if the node has a lower depth than the current deepest block node
    if (resolvedPosDepth < resolvedDeepestBlockPosDepth) {
      return;
    }

    // Skip if the node is not a block node or doesn't have data-block-id
    if (!node.isBlock || !node.attrs[ATTR_BLOCK_ID]) {
      return;
    }

    const nodeDOM = view.nodeDOM(pos);

    if (!nodeDOM) {
      return;
    }

    const nodeRect = (nodeDOM as Element).getBoundingClientRect();

    // If resolvedPosDepth is 0, only check the horizontal axis
    if (
      resolvedPosDepth === 0 &&
      mousePos.clientY >= nodeRect.top &&
      mousePos.clientY <= nodeRect.bottom
    ) {
      deepestBlockPos = pos;
      deepestBlockNode = node;
    } else if (
      mousePos.clientX >= nodeRect.left + (offset?.left || 0) &&
      mousePos.clientX <= nodeRect.right + (offset?.right || 0) &&
      mousePos.clientY >= nodeRect.top + (offset?.top || 0) &&
      mousePos.clientY <= nodeRect.bottom + (offset?.bottom || 0)
    ) {
      deepestBlockPos = pos;
      deepestBlockNode = node;
    }
  });

  return {
    node: deepestBlockNode as Node | null,
    docPos: deepestBlockPos,
  };
};
