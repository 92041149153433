/**
 * TODO: Move this component to somewhere better.
 * It should be a part of the "content calendar" feature, but we don't have that rn.
 */

import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearGrid4 } from 'components/icons/components/linear/IconLinearGrid4';
import { IconLinearGrid9 } from 'components/icons/components/linear/IconLinearGrid9';
import { theme } from 'styles/theme';

export type ContentCalendarViewType = 'list' | 'split' | 'content';

export interface ContentCalendarViewSelectProps {
  selectedView: ContentCalendarViewType;
  onChange: (view: ContentCalendarViewType) => void;

  /**
   * Whether the user can switch to the content view.
   * If false, the content view option will be disabled.
   * NOTE: This is a work-around to address the issue where users want to switch to content view
   * but we don't have currentlyFocusedContentIdeaId available. Should circle back to this later.
   */
  canSwitchToContent?: boolean;
}

export const ContentCalendarViewSelect = ({
  selectedView,
  onChange,
  canSwitchToContent = true,
}: ContentCalendarViewSelectProps) => {
  const iconProps = {
    size: 18,
    color: theme.colors?.primary.black,
  };

  const options: {
    icon: JSX.Element;
    label: string;
    value: ContentCalendarViewType;
  }[] = [
    {
      icon: <IconLinearGrid4 {...iconProps} />,
      label: 'List',
      value: 'list',
    },
    {
      icon: <IconLinearGrid9 {...iconProps} />,
      label: 'Split',
      value: 'split',
    },
    ...(canSwitchToContent
      ? [
          {
            icon: (
              <IconLinearGrid4 {...iconProps} style={{ rotate: '180deg' }} />
            ),
            label: 'Content',
            value: 'content' as ContentCalendarViewType,
          },
        ]
      : []),
  ];

  return (
    <ContextMenu
      options={options.map((option) => ({
        renderOption: () => (
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            {option.icon}
            <Typography
              variant="headline-xs"
              color={theme.colors?.primary.black}
            >
              {option.label}
            </Typography>
          </Box>
        ),
        onClick: () => {
          onChange(option.value);
        },
      }))}
      anchorOrigin={{
        vertical: -16,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          borderRadius: theme.spacing(4.5),
          border: `1px solid ${theme.colors?.utility[300]}`,
          background: `rgba(255, 255, 255, 0.80)`,
          backdropFilter: `blur(10px)`,
          minWidth: 104,
        },
      }}
      MenuListProps={{
        sx: {
          gap: `${theme.spacing(4)} !important`,
        },
      }}
      renderButton={() => {
        const selectedOption = options.find(
          (option) => option.value === selectedView,
        );

        if (!selectedOption) {
          return null;
        }
        return (
          <IconButton sx={{ padding: 0 }}>
            <Box
              sx={{
                padding: theme.spacing(2, 3),
                borderRadius: 6,
                boxShadow: `0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)`,
                bgcolor: theme.colors?.utility[400],
                border: `1px solid ${theme.colors?.utility[500]}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
              }}
            >
              {selectedOption.icon}
              <Typography
                variant="headline-xs"
                color={theme.colors?.primary.black}
              >
                {selectedOption.label}
              </Typography>
            </Box>
          </IconButton>
        );
      }}
    />
  );
};
