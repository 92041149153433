import { typography } from 'components/common/Typography/styles';
import { Box, Popover, TableCell, Typography, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledTableHeadingCell = styled(TableCell)(() => ({
  ...typography['body-lg'],
  color: theme.colors?.utility[700],
  fontWeight: 600,
  verticalAlign: 'bottom',
  padding: theme.spacing(2, 4),
}));
