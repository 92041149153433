import { Masonry } from '@mui/lab';
import { Box } from '@mui/material';
import { MasonrySkeleton } from 'components/common/Skeleton';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { CollectionCardView } from 'features/collection';
import { PostMasonryCardView } from 'features/post';
import {
  CollectionModel,
  PostFilterType,
  PostFragmentPostCommandFragment,
  useGetCollectionForCollectionPageQuery,
} from 'graphql/generated';
import { usePostQueryForNormalCollection } from 'pages/collections/detail/hooks/usePostQueryForNormalCollection';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

type NormalCollectionPostMasonrySectionProps = {
  collectionId: string;
  selectedPostIds: string[];
  onPostClick: (post: PostFragmentPostCommandFragment) => void;
  onCollectionClick: (collection: Pick<CollectionModel, 'id' | 'name'>) => void;
};

export const NormalCollectionPostMasonrySection = (
  props: NormalCollectionPostMasonrySectionProps,
) => {
  const { collectionId, selectedPostIds, onPostClick, onCollectionClick } =
    props;

  // NOTE: Reusing query from Collection page
  const { data: collectionData, loading: isCollectionLoading } =
    useGetCollectionForCollectionPageQuery({
      variables: {
        collectionId,
      },
      fetchPolicy: 'cache-and-network',
    });

  const {
    data: postData,
    loading: isPostLoading,
    fetchMore: fetchMorePosts,
  } = usePostQueryForNormalCollection({
    filters: {
      collectionIds: [collectionId],
      filterType: PostFilterType.OrganizationPosts,
    },
  });

  if (isCollectionLoading || isPostLoading) {
    return <MasonrySkeleton />;
  }

  return (
    <InfiniteScroll
      hasMore={postData?.postsSmartSearch.pageInfo.hasNextPage || false}
      initialLoad={false}
      loadMore={fetchMorePosts}
      threshold={1024}
      useWindow={false}
      getScrollParent={() =>
        document.querySelector(
          '.collection-cjb-window-view-scrollable-container',
        )
      }
    >
      <Box sx={{ mr: -4 }}>
        <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={4}>
          {(collectionData?.collection.childCollections || []).map(
            (collection) => {
              return (
                <Box key={collection.id}>
                  <CollectionCardView
                    collection={collection}
                    onClick={() => onCollectionClick(collection)}
                    componentsProps={{
                      multiPostPreview: {
                        variant: 'card-stack-alt',
                      },
                      disableContextMenu: true,
                    }}
                  />
                </Box>
              );
            },
          )}
          {(postData?.postsSmartSearch.data || []).map(({ item: post }) => {
            const selected = selectedPostIds.includes(post.id);

            return (
              <Box
                key={post.id}
                sx={{
                  position: 'relative',
                  borderRadius: 3,
                  ...(selected && {
                    color: theme.colors?.primary.black,
                    outline: selected
                      ? `2px solid ${theme.colors?.primary.black}`
                      : 'none',
                    outlineOffset: 3,
                    backgroundColor: theme.colors?.primary.white,
                  }),
                }}
              >
                <PostMasonryCardView
                  post={post}
                  componentsProps={{
                    disableContextMenu: true,
                  }}
                  onClick={() => onPostClick(post)}
                />
                {selected && (
                  <IconBoldTickCircle
                    size={16}
                    style={{ position: 'absolute', top: 0, left: 0, margin: 8 }}
                  />
                )}
              </Box>
            );
          })}
        </Masonry>
      </Box>
    </InfiniteScroll>
  );
};
