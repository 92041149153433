import { SxProps } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomSmiley } from 'components/icons/components/custom/IconCustomSmiley';
import {
  JuiceboxFilterButton,
  JuiceboxFilterOption,
} from 'features/juicebox/components';
import { getReactionState } from 'features/juicebox/constants';
import { JuiceboxFilter, JuiceboxFilterType } from 'features/juicebox/hooks';
import { ReactionFilterType } from 'graphql/generated';
import { Dispatch, SetStateAction } from 'react';
import { theme } from 'styles/theme';

interface Props {
  selectedReactions: JuiceboxFilter[];
  setSelectedReactions: Dispatch<SetStateAction<JuiceboxFilter[]>>;
  componentsProps?: {
    filterButton?: {
      sx?: SxProps;
    };
  };
}

export const JuiceboxReactionSelectButtonFilter = ({
  selectedReactions,
  setSelectedReactions,
  componentsProps = {},
}: Props) => {
  const filterOptions = [
    ReactionFilterType.Like,
    ReactionFilterType.Dislike,
    ReactionFilterType.Confused,
    ReactionFilterType.Archive,
  ].map((reaction) => {
    const { emoji, label } = getReactionState(reaction);
    return {
      emoji,
      text: label,
    };
  });

  return (
    <ContextMenu
      renderButton={() => (
        <JuiceboxFilterButton
          Icon={IconCustomSmiley}
          text="Reaction"
          sx={componentsProps.filterButton?.sx}
        />
      )}
      options={filterOptions.map((option) => ({
        sx: {
          borderRadius: `${theme.spacing(3)} !important`,
          ':hover': {
            backgroundColor: `rgba(35, 6, 3, 0.1)`,
          },
        },
        onClick: () => {
          if (selectedReactions.some((r) => r.value === option.emoji)) {
            setSelectedReactions(
              selectedReactions.filter((f) => f.value !== option.emoji),
            );
          } else {
            setSelectedReactions([
              ...selectedReactions,
              {
                filterType: JuiceboxFilterType.Reactions,
                value: option.emoji,
                label: option.text,
              },
            ]);
          }
        },
        renderOption: () => (
          <JuiceboxFilterOption
            Icon={option.emoji}
            text={option.text}
            isChecked={selectedReactions.some((r) => r.value === option.emoji)}
          />
        ),
        closeOnClick: false,
      }))}
      PaperProps={{
        style: {
          minWidth: 266,
          borderRadius: theme.spacing(3),
          boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
        },
      }}
    />
  );
};
