import { Box, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

type SectionSkeletonProps = {
  numberOfRows?: number;
};

export const SectionSkeleton = (props: SectionSkeletonProps) => {
  const { numberOfRows = 1 } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Skeleton
          width="20%"
          height={theme.spacing(8)}
          sx={{
            background: 'rgba(186, 161, 205, 0.4)',
            borderRadius: theme.spacing(3),
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {Array(numberOfRows)
          .fill(0)
          .map((t, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(7),
                  height: theme.spacing(64),
                }}
              >
                <Skeleton
                  width="calc(93% / 4)"
                  height={theme.spacing(95)}
                  sx={{
                    background: 'rgba(230, 221, 237, 0.6)',
                    borderRadius: theme.spacing(3),
                  }}
                />

                <Skeleton
                  width="calc(93% / 4)"
                  height={theme.spacing(95)}
                  sx={{
                    background: 'rgba(230, 221, 237, 0.6)',
                    borderRadius: theme.spacing(3),
                  }}
                />

                <Skeleton
                  width="calc(93% / 4)"
                  height={theme.spacing(95)}
                  sx={{
                    background: 'rgba(230, 221, 237, 0.6)',
                    borderRadius: theme.spacing(3),
                  }}
                />

                <Skeleton
                  width="calc(93% / 4)"
                  height={theme.spacing(95)}
                  sx={{
                    background: 'rgba(230, 221, 237, 0.6)',
                    borderRadius: theme.spacing(3),
                  }}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};
