import { Box, Typography, styled } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import { PostFragmentPostDescriptionFragment } from 'graphql/generated';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';

const MAX_HEIGHT = 80;

const FadingContainer = styled('div')<{ backgroundColor?: string }>(
  ({ backgroundColor }) => ({
    position: 'absolute',
    left: 0,
    bottom: -5,
    width: '100%',
    height: 20,
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%,  ${
      backgroundColor || theme.colors?.primary.white
    } 63.67%)`,
  }),
);

export enum ContentMode {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
  None = 'none',
}

export type PostDescriptionReadonlyProps = {
  post: PostFragmentPostDescriptionFragment;
  defaultContentMode?: ContentMode;
  style?: CSSProperties;
};

export const PostDescriptionReadonly = (
  props: PostDescriptionReadonlyProps,
) => {
  const { post, style, defaultContentMode } = props;

  const [contentMode, setContentMode] = useState<ContentMode>(
    defaultContentMode || ContentMode.None,
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || defaultContentMode === ContentMode.None) return;

    setTimeout(() => {
      if (ref.current && ref.current.clientHeight > MAX_HEIGHT) {
        setContentMode(ContentMode.Collapsed);
      } else {
        setContentMode(ContentMode.None);
      }
    }, 50);
  }, []); // eslint-disable-line

  return (
    <div ref={ref}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          maxHeight:
            contentMode === ContentMode.Collapsed ? MAX_HEIGHT : undefined,
        }}
      >
        <RichTextEditor
          defaultContent={post.description}
          editable={false}
          style={style}
          placeholder="Description"
          placeholderContainerProps={{
            style: {
              padding: theme.spacing(3, 0),
            },
          }}
        />
        {contentMode === ContentMode.Collapsed && <FadingContainer />}
      </Box>
      {contentMode !== ContentMode.None && (
        <Box
          sx={{
            backgroundColor: theme.colors?.primary.white,
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <Typography
            component="button"
            sx={{
              ...typography['headline-xs'],
              color: theme.colors?.utility[700],
              textAlign: 'left',
            }}
            onClick={(e) => {
              e.preventDefault();
              setContentMode(
                contentMode === ContentMode.Collapsed
                  ? ContentMode.Expanded
                  : ContentMode.Collapsed,
              );
            }}
          >
            {contentMode === ContentMode.Collapsed ? 'See more' : 'See less'}
          </Typography>
        </Box>
      )}
    </div>
  );
};
