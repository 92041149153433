import { gql } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import {
  PostFragmentJuiceboxNoteSearchItemFragmentDoc,
  SearchType,
  SearchableEntityType,
  useGetNotesForJuiceboxLinkSearchResultQuery,
} from 'graphql/generated';
import { MutableRefObject, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { JuiceboxNoteSearchItem } from '../searchItem';
import { JuiceboxSearchResultHeader } from '../searchResultHeader';

interface Props {
  query: string;
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
}

// eslint-disable-next-line
gql`
  query GetNotesForJuiceboxLinkSearchResult($data: UniversalSearchInput!) {
    universalSearch(data: $data) {
      noteSearchResult {
        hits {
          item {
            ...PostFragmentJuiceboxNoteSearchItem
          }
        }
        totalCount
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PostFragmentJuiceboxNoteSearchItemFragmentDoc}
`;

export const JuiceboxNoteSearchResult = ({ query, elRefs }: Props) => {
  const [hasMoreNotes, setHasMoreNotes] = useState(true);
  const variables = {
    entityTypes: [SearchableEntityType.Notes],
    limit: 10,
    searchType: SearchType.SmartFullText,
    text: query,
  };

  const { data: notesData, fetchMore } =
    useGetNotesForJuiceboxLinkSearchResultQuery({
      variables: {
        data: {
          ...variables,
        },
      },
    });

  const totalNoteCount = useMemo(
    () => notesData?.universalSearch.noteSearchResult.totalCount || 0,
    [notesData?.universalSearch],
  );

  const notes = useMemo(
    () =>
      notesData?.universalSearch.noteSearchResult.hits.map(
        ({ item }) => item,
      ) || [],
    [notesData?.universalSearch.noteSearchResult.hits],
  );
  return (
    <>
      <JuiceboxSearchResultHeader title={`Notes(${totalNoteCount})`} />
      <InfiniteScroll
        style={{
          flexDirection: 'column',
          display: 'flex',
          gap: theme.spacing(1),
          marginTop: theme.spacing(-2),
        }}
        threshold={100}
        loadMore={() => {
          if (!hasMoreNotes || !notes.length) {
            return;
          }

          fetchMore({
            variables: {
              data: {
                ...variables,
                after:
                  String(notesData?.universalSearch.pageInfo.endCursor || 0) ||
                  '0',
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                fetchMoreResult.universalSearch.noteSearchResult.hits.length ===
                0
              ) {
                setHasMoreNotes(false);
              }
              return {
                ...fetchMoreResult,
                universalSearch: {
                  ...fetchMoreResult.universalSearch,
                  noteSearchResult: {
                    ...fetchMoreResult.universalSearch.noteSearchResult,
                    totalCount:
                      fetchMoreResult.universalSearch.noteSearchResult
                        .totalCount,
                    hits: [
                      ...(prev.universalSearch.noteSearchResult?.hits || []),
                      ...(fetchMoreResult.universalSearch.noteSearchResult
                        ?.hits || []),
                    ],
                  },
                },
              };
            },
          });
        }}
        useWindow={false}
        hasMore={hasMoreNotes}
        loader={
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress
              size={24}
              sx={{ color: theme.colors?.primary.maroon }}
            />
          </Box>
        }
      >
        {notes.map((note, index) => (
          <JuiceboxNoteSearchItem
            key={index}
            note={note}
            query={query}
            ref={(el: HTMLElement) => {
              elRefs.current[index] = el;
            }}
          />
        ))}
      </InfiniteScroll>
    </>
  );
};
