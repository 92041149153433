import { Components, Theme } from '@mui/material';

export const switchOverrides: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        '& .MuiSwitch-thumb': {
          color: theme.colors?.utility[600],
        },
        '& .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.colors?.utility[300],
        },
        '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
          color: theme.colors?.primary.black,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.colors?.utility[300],
        },
        '& .Mui-disabled': {
          cursor: 'not-allowed',
        },
        '& .Mui-disabled .MuiSwitch-thumb': {
          color: theme.colors?.utility[600],
        },
        '& .Mui-disabled + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.colors?.utility[300],
        },
      };
    },
  },
};
