import { gql } from '@apollo/client';
import { MenuItem, Typography } from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu';
import { IconLinearMainComponent } from 'components/icons/components/linear/IconLinearMainComponent';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineStatus } from 'components/icons/components/outline/IconOutlineStatus';
import { FormattedContentIdeaPrimaryField } from 'features/contentIdea';
import {
  ContentIdeaPrimaryField,
  useGetContentIdeaFieldsForContentCalendarSortQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { Updater } from 'use-immer';
import { StyledIconContainer, StyledMenuItemContainer } from '../styles';
import { SelectedSort } from '../types';

// eslint-disable-next-line
gql`
  query GetContentIdeaFieldsForContentCalendarSort(
    $filters: ContentIdeaFieldFilters
  ) {
    contentIdeaFields(filters: $filters) {
      id
      name
    }
  }
`;

type ContentCalendarSortMenuProps = {
  selectedSort: SelectedSort;
  setSelectedSort: Updater<SelectedSort>;
};

export const ContentCalendarSortMenu = (
  props: ContentCalendarSortMenuProps,
) => {
  const { selectedSort, setSelectedSort } = props;

  const { data: contentIdeaFieldsData } =
    useGetContentIdeaFieldsForContentCalendarSortQuery({
      variables: {
        filters: {
          isPrimaryField: true,
        },
      },
    });
  const contentIdeaFields = contentIdeaFieldsData?.contentIdeaFields || [];
  const sort = contentIdeaFields.filter((f) => {
    return [
      FormattedContentIdeaPrimaryField.Owners,
      FormattedContentIdeaPrimaryField.DueDate,
      FormattedContentIdeaPrimaryField.Pillars,
      FormattedContentIdeaPrimaryField.Platforms,
      FormattedContentIdeaPrimaryField.Status,
    ].includes(f.name as ContentIdeaPrimaryField);
  });

  const dueDateDataFieldName =
    sort.find((s) => s.name === FormattedContentIdeaPrimaryField.DueDate)
      ?.name || '';
  const statusDataDataFieldName =
    sort.find((s) => s.name === FormattedContentIdeaPrimaryField.Status)
      ?.name || '';
  const platformsDataFieldName =
    sort.find((s) => s.name === FormattedContentIdeaPrimaryField.Platforms)
      ?.name || '';
  const pillarsDataFieldName =
    sort.find((s) => s.name === FormattedContentIdeaPrimaryField.Pillars)
      ?.name || '';
  const assigneeDataFieldName =
    sort.find((s) => s.name === FormattedContentIdeaPrimaryField.Owners)
      ?.name || '';

  const handleSelectSort = (selected: SelectedSort) => {
    setSelectedSort((draft) => {
      if (draft.value && draft.value === selected.value) {
        return {
          name: '',
          value: null,
        };
      }

      return selected;
    });
  };

  return (
    <MoreMenuItem
      label={
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconLinearSort size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">Sort by</Typography>
        </StyledMenuItemContainer>
      }
      endIcon={
        selectedSort.value && (
          <Typography
            variant="subhead-lg"
            color={theme.colors?.utility[600]}
            sx={{
              textAlign: 'right',
              width: theme.spacing(24),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {selectedSort.value}
          </Typography>
        )
      }
      MenuProps={{
        sx: {
          minWidth: 266,
        },
        MenuListProps: {
          sx: {
            '.MuiMenuItem-root': {
              borderRadius: 3,
            },
          },
        },
      }}
    >
      <MenuItem
        onClick={() =>
          handleSelectSort({
            name: dueDateDataFieldName,
            value: 'Publishing Date',
          })
        }
      >
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconOutlineCalendar size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">Publishing Date</Typography>
        </StyledMenuItemContainer>
      </MenuItem>

      <MenuItem
        onClick={() =>
          handleSelectSort({ name: statusDataDataFieldName, value: 'Status' })
        }
      >
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconOutlineStatus size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">Status</Typography>
        </StyledMenuItemContainer>
      </MenuItem>

      <MenuItem
        onClick={() =>
          handleSelectSort({ name: platformsDataFieldName, value: 'Platforms' })
        }
      >
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconLinearMonitorMobile size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">Platform</Typography>
        </StyledMenuItemContainer>
      </MenuItem>

      <MenuItem
        onClick={() =>
          handleSelectSort({ name: pillarsDataFieldName, value: 'Pillars' })
        }
      >
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconLinearMainComponent size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">Pillars</Typography>
        </StyledMenuItemContainer>
      </MenuItem>

      <MenuItem
        onClick={() =>
          handleSelectSort({ name: assigneeDataFieldName, value: 'Assignee' })
        }
      >
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconLinearUser size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">Assignee</Typography>
        </StyledMenuItemContainer>
      </MenuItem>
    </MoreMenuItem>
  );
};
