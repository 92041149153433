/**
 * TODO: WE SHOULD BE REMOVING THIS SOON
 */

import { gql } from '@apollo/client';
import { Badge, Box, Button, IconButton } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldFlash } from 'components/icons/components/bold/IconBoldFlash';
import { IconLogoPlotLogo } from 'components/icons/components/logo/IconLogoPlotLogo';
import { IconOutlineDirectNormal } from 'components/icons/components/outline/IconOutlineDirectNormal';
import { IconOutlineMenu1 } from 'components/icons/components/outline/IconOutlineMenu1';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { MOBILE_WIDTH_SM } from 'constants/mediaSizes';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import {
  BillingPlanName,
  useGetNotificationUnreadCountV2Query,
  BillingSubscriptionStatus,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { useVariant } from './hooks/useVariant';
import { HeaderWrapper } from './styles';
import { SiteHeaderProps } from './types';
import { getHeaderColors } from './utils';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetNotificationUnreadCount {
    unreadNotifications
  }
`;

export const SiteHeader = ({
  variant: defaultVariant,
  sx = {},
  isOpenDrawer,
  onToggleDrawer,
}: SiteHeaderProps) => {
  const isMobileView = useMediaQueryMobile(MOBILE_WIDTH_SM);
  const navigate = useGuardNavigate();
  const location = useLocation();

  const { user, isMobileAppWebView } = useUserContext();
  const { triggerCommand } = useCommandContext();

  const { data: unreadNotificationDataV2 } =
    useGetNotificationUnreadCountV2Query();

  const { variant } = useVariant();
  const { bgColor, iconColor, logoColor, borderColor } = getHeaderColors(
    defaultVariant || variant,
  );
  const iconSize = isMobileView ? 20 : 24;

  const isInBillingPage = location.pathname.includes('settings/billing');

  // Show upgrade button if:
  // 1. User is in free plan
  // 2. User is in trial for standard plan
  const shouldShowUpgradeButton = useMemo(() => {
    if (!user) {
      return false;
    }

    // if user is viewing billing page in mobile webview => do not show upgrade button
    if (isMobileAppWebView) {
      return false;
    }

    return (
      user.organization.billing.plan === BillingPlanName.Free ||
      user.organization.billing.subscription?.status ===
        BillingSubscriptionStatus.Trialing
    );
  }, [isMobileAppWebView, user]);

  const renderCustomLayoutForBillingPageInMobileWebview = useCallback(() => {
    return (
      <HeaderWrapper
        sx={{
          height: 64,
          color: iconColor,
          backgroundColor: bgColor,
          borderColor,
          ...sx,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <IconLogoPlotLogo color={logoColor} size={24} />
        </Box>
      </HeaderWrapper>
    );
  }, [bgColor, borderColor, iconColor, logoColor, sx]);

  if (isMobileAppWebView && isInBillingPage) {
    return renderCustomLayoutForBillingPageInMobileWebview();
  }

  return (
    <HeaderWrapper
      sx={{
        height: 64,
        color: iconColor,
        backgroundColor: bgColor,
        borderColor,
        ...sx,
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            flexShrink: 0,
            width: '33.33%',
            [theme.breakpoints.down('sm')]: {
              width: 'auto',
            },
          }}
        >
          <IconButton
            onClick={onToggleDrawer}
            id="site-header-menu"
            sx={{
              height: 40,
              width: 40,
              visibility: isOpenDrawer ? 'hidden' : 'visible',
            }}
          >
            <IconOutlineMenu1 color={iconColor} size={iconSize} />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 2,
            flexShrink: 0,
            width: '33.33%',
            [theme.breakpoints.down('sm')]: {
              width: 'auto',
              flex: 1,
            },
          }}
        >
          <Link to="/">
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'flex-start', sm: 'center' },
              }}
            >
              <IconLogoPlotLogo color={logoColor} />
            </Box>
          </Link>
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            width: '33.33%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {shouldShowUpgradeButton && (
            <Button
              size="small"
              variant="contained"
              startIcon={isMobileView ? undefined : <IconBoldFlash size={14} />}
              sx={{
                bgcolor: theme.colors?.primary.black,
                color: theme.colors?.primary.white,
                borderRadius: theme.spacing(2),
                ':hover': {
                  bgcolor: theme.colors?.primary.black,
                  color: theme.colors?.primary.white,
                },
                mr: 2,
                flexShrink: 0,
                height: 36,
              }}
              onClick={() => navigate(PlotRoutes.billing())}
            >
              Upgrade
            </Button>
          )}
          <IconButton onClick={() => triggerCommand(COMMAND_TYPE.SEARCH)}>
            <IconOutlineSearchNormal1
              size={iconSize}
              color={theme.colors?.primary.black}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(PlotRoutes.inbox());
            }}
          >
            <Badge
              badgeContent={unreadNotificationDataV2?.unreadNotificationsV2}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: theme.colors?.primary.maroon,
                  color: theme.colors?.primary.white,
                },
              }}
            >
              <IconOutlineDirectNormal
                size={iconSize}
                color={theme.colors?.primary.black}
              />
            </Badge>
          </IconButton>
        </Box>
      </Box>
    </HeaderWrapper>
  );
};
