const FileIcons = {
  pdf: '/file-icons/PDF.svg',
  word: '/file-icons/Word.svg',
  excel: '/file-icons/Excel.svg',
  powerpoint: '/file-icons/Powerpoint.svg',
  photoshop: '/file-icons/Photoshop.svg',
};

const getFileTypeFromName = (fileName: string) => {
  const fileExtension = fileName.split('.').pop();
  switch (fileExtension) {
    case 'pdf':
      return FileIcons.pdf;
    case 'doc':
    case 'docx':
      return FileIcons.word;
    case 'xls':
    case 'xlsx':
      return FileIcons.excel;
    case 'ppt':
    case 'pptx':
      return FileIcons.powerpoint;
    case 'psd':
      return FileIcons.photoshop;
    default:
      return '';
  }
};

export const resolveFileIcon = (fileName: string, mimeType: string = '') => {
  if (!mimeType) return getFileTypeFromName(fileName);

  switch (mimeType) {
    case 'application/pdf':
      return FileIcons.pdf;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return FileIcons.word;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return FileIcons.excel;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return FileIcons.powerpoint;
    case 'image/vnd.adobe.photoshop':
      return FileIcons.photoshop;
    default:
      return getFileTypeFromName(fileName);
  }
};
