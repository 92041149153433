import { gql } from '@apollo/client';
import {
  NestedEntities,
  NestedEntitiesMode,
  useUpdateNestedCollectionPostPermissionsForUseCollectionPermissionsUpdateCollectionMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { NestedCollectionsPermissionType } from './useCollectionPermissionCollectionTreeSelectionState';
import { UpdateCollectionPermissionsDataInput } from './useCollectionPermissionMutations';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateNestedCollectionPostPermissionsForUseCollectionPermissionsUpdateCollection(
    $data: UpdateNestedCollectionPostPermissionsInput!
  ) {
    updateNestedCollectionPostPermissions(data: $data) {
      success
      message
    }
  }
`;

type Props = {
  collectionId: string;
  sendNotification?: boolean;
};

export const useCollectionPermissionUpdateNestedCollection = ({
  collectionId,
  sendNotification,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [updateNestedCollectionPostPermissions] =
    useUpdateNestedCollectionPostPermissionsForUseCollectionPermissionsUpdateCollectionMutation();

  const handleUpdateNestedCollectionPostPermissions = async (
    inputData: UpdateCollectionPermissionsDataInput,
    selectionData: NestedCollectionsPermissionType[],
  ) => {
    // Recursively parse selectionData into the format expected by the backend
    const format = (data: NestedCollectionsPermissionType): NestedEntities => {
      return {
        collectionId: data.collectionId,
        parentCollectionId: data.parentCollectionId,
        mode:
          data.mode === 'selectedAll'
            ? NestedEntitiesMode.Select
            : NestedEntitiesMode.Exclude,
        excludedChildCollectionIds: data.excludedChildCollectionIds,
        excludedPostIds: data.excludedPostIds,
        nestedEntities: data.nestedEntities.map(format),
      };
    };
    const nestedEntities = selectionData.map(format);

    setLoading(true);
    try {
      const { data } = await updateNestedCollectionPostPermissions({
        variables: {
          data: {
            collectionId,
            permissions: {
              inviteMembers: {
                ...(sendNotification
                  ? { inviteType: inputData.inviteType }
                  : {}),
                emailInvites: (inputData.inviteMembers?.members || []).map(
                  (m) => ({
                    email: m.email,
                    permission: m.permission,
                  }),
                ),
              },
              generalPermission: {
                generalPermission:
                  inputData.generalPermission?.generalPermission,
                permissionLevel: inputData.generalPermission?.permissionLevel,
              },
              removeMemberIds: inputData.removeMembers?.userIds || [],
            },
            nestedEntities,
          },
        },
      });

      if (data?.updateNestedCollectionPostPermissions?.success) {
        return data.updateNestedCollectionPostPermissions;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { handleUpdateNestedCollectionPostPermissions, savingData: loading };
};
