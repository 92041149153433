import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  Popover,
  PopoverProps,
  TextField,
  Typography,
} from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import {
  COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
  CollectionListItemView,
  CollectionMenuItemAddNewView,
  CollectionMenuItemWithPostPermissionAlertView,
} from 'features/collection';
import { useAutocompleteInfiniteCollections } from 'features/collection/hooks/useAutocompleteInfiniteCollections';
import {
  CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
  ContentCalendarCollectionMenuItem,
} from 'features/contentCalendar';
import { useCollectionPostContextMenuHandlers } from 'features/juicebox/hooks';
import {
  CollectionPermission,
  GeneralPermission,
  PlotFeature,
  SearchHitType,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

type AutocompleteOptionType = {
  value: string;
  label: string;
};

const filter = createFilterOptions<AutocompleteOptionType>();

export type JuiceboxPostOrganizePopoverProps = PopoverProps & {
  postIds: string[];
  currentCollectionId: string;
  isFavoritePosts?: boolean;
};

export const JuiceboxPostsOrganizePopover = (
  props: JuiceboxPostOrganizePopoverProps,
) => {
  const { postIds, currentCollectionId, isFavoritePosts, ...rest } = props;

  const { orgBilling } = useUserContext();

  const [selectedCollectionIds, setSelectedCollectionIds] = useState<string[]>(
    [],
  );

  const { onTogglePostsCollection, onTogglePostsFavorite } =
    useCollectionPostContextMenuHandlers();
  const [searchStr, setSearchStr] = useState('');
  const [isPostsFavorite, setIsPostsFavorite] = useState(isFavoritePosts);
  const {
    autoCompleteProps,
    collectionSearchHits = [],
    loadCollectionsData,
    refetchCollections,
    fetchingCollections: loading,
  } = useAutocompleteInfiniteCollections({
    postIds,
    searchStr,
  });

  const { isFeatureEnabled } = useFeatureFlagContext();

  useEffect(() => {
    loadCollectionsData();
  }, []); // eslint-disable-line

  const collections = useMemo(() => {
    return [
      ...collectionSearchHits
        .map((x) => ({
          ...x.item,
          searchHitType: x.type,
        }))
        .filter(
          (c) =>
            c.myPermissions.includes(CollectionPermission.Update) &&
            c.id !== currentCollectionId,
        ),
    ];
  }, [collectionSearchHits, currentCollectionId]);

  const options = useMemo(() => {
    return collections.map((collection) => ({
      value: collection.id,
      label: collection.name,
    }));
  }, [collections]);

  const analytics = useAnalytics();

  const onSelectCollection = async (collectionId: string) => {
    // === Analytics ===
    const collection = collections.find((c) => c.id === collectionId);
    if (collection?.searchHitType === SearchHitType.Smart) {
      // Find the position of the collection
      const collectionPosition = collections.findIndex(
        (c) => c.id === collection.id,
      );

      analytics.track(EventName.SuggestedCollectionSelected, {
        collectionId: collection.id,
        position: collectionPosition,
      });
    }
    // === End of Analytics ===

    onTogglePostsCollection(
      postIds,
      collectionId,
      !selectedCollectionIds.includes(collectionId),
    ).then(() => {
      if (selectedCollectionIds.includes(collectionId)) {
        setSelectedCollectionIds((prev) =>
          prev.filter((id) => id !== collectionId),
        );
      } else {
        setSelectedCollectionIds((prev) => [...prev, collectionId]);
      }
    });
  };

  const { triggerCommand } = useCommandContext();

  return (
    <Popover
      anchorOrigin={{ vertical: 'center', horizontal: 40 }} // offset 40px to the right
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(24px)',
          boxShadow:
            '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
          border: 'none',
        },
        // Stop event propagation in case this component is rendered
        // from within another component that handles click (e.g. link)
        onClick: (e) => e.stopPropagation(),
      }}
      {...rest}
      onClose={(e: SyntheticEvent, reason) => {
        e.preventDefault();
        e.stopPropagation();

        rest.onClose?.(e, reason);
      }}
    >
      <Box
        sx={{
          width: 320,
          color: theme.colors?.primary.black,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box
          sx={{
            py: 4,
            px: 6,
            bgcolor: 'rgba(35, 6, 3, 0.1)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CollectionListItemView
            collection={{
              id: 'saved',
              name: 'Saved',
              __typename: 'CollectionModel',
              generalPermission: GeneralPermission.OrganizationMembers,
              inviteMembers: [],
              hasPreviewPost: false,
              organizationName: '',
              breadcrumbsFromRoot: [],
            }}
            renderName={() => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="subhead-lg">Saved</Typography>
                <Typography
                  variant="subhead-sm"
                  color={theme.colors?.utility[700]}
                >
                  {postIds.length} post{postIds.length > 1 ? 's' : ''}
                </Typography>
              </Box>
            )}
            componentsProps={{
              name: {
                sx: {
                  fontWeight: 600,
                },
              },
            }}
          />
          <IconButtonWithTooltip
            tooltip={`Save ${postIds.length > 1 ? 'these' : 'this'} post${
              postIds.length > 1 ? 's' : ''
            }`}
            size="small"
            sx={{
              width: 24,
              height: 24,
              p: 0,
            }}
            onClick={() => {
              setIsPostsFavorite(!isPostsFavorite);
              onTogglePostsFavorite(postIds, !isPostsFavorite);
            }}
          >
            <IconBoldSave
              color={
                isPostsFavorite ? theme.colors?.primary.black : '#23060333'
              }
              size={24}
            />
          </IconButtonWithTooltip>
        </Box>

        <Box
          sx={{
            flex: 1,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="headline-xxs"
            color={theme.colors?.utility[700]}
            mb={2}
            px={2}
            display="block"
          >
            Collections
          </Typography>

          <Autocomplete
            ListboxProps={autoCompleteProps.ListboxProps}
            ListboxComponent={autoCompleteProps.ListBoxComponent}
            inputValue={searchStr}
            onInputChange={(_, value, reason) => {
              if (reason === 'input') {
                setSearchStr(value);
              }
            }}
            open
            multiple
            value={[
              ...options.filter((o) => selectedCollectionIds.includes(o.value)),
            ]}
            options={options}
            loading={loading}
            loadingText={
              <Box display="flex" gap={1}>
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.utility[600]}
                >
                  Looking for your collection
                </Typography>
                <CircularProgress
                  sx={{ color: theme.colors?.utility[700] }}
                  size={16}
                />
              </Box>
            }
            disablePortal
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                placeholder="Search"
                sx={{
                  px: 2,
                  '.MuiOutlinedInput-root': {
                    height: '32px',
                    borderRadius: 100,
                    py: '6px !important',
                    px: '12px !important',
                    bgcolor: theme.colors?.primary.white,
                    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',

                    input: {
                      ...typography['subhead-lg'],
                      p: '0 !important',
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                      display: 'none !important',
                    },
                  },
                }}
              />
            )}
            renderTags={() => null}
            renderOption={(props, _option) => {
              const option = _option as AutocompleteOptionType;

              if (option.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY) {
                return (
                  <CollectionMenuItemAddNewView
                    {...props}
                    key={option.value}
                    label={option.label}
                  />
                );
              }
              const commonMenuItemProps = {
                componentProps: {
                  listItem: {
                    sx: {
                      maxWidth: 240,
                    },
                  },
                  menu: {
                    sx: {
                      '& .MuiPaper-root': {
                        boxShadow:
                          'rgba(24, 39, 75, 0.12) 0px 12px 42px -4px, rgba(24, 39, 75, 0.12) 0px 8px 18px -6px !important',
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                        backdropFilter: 'blur(24px)',
                        width: 310,
                      },
                    },
                  },
                },
              };
              if (
                option.value === CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY
              ) {
                return (
                  <ContentCalendarCollectionMenuItem
                    key={option.value}
                    onClick={(selectedCollection) => {
                      if (selectedCollection) {
                        onSelectCollection(selectedCollection.id);
                      }
                    }}
                    selectedCollectionIds={selectedCollectionIds}
                    {...commonMenuItemProps}
                  />
                );
              }

              const collection = collections.find((c) => c.id === option.value);

              if (collection) {
                return (
                  <CollectionMenuItemWithPostPermissionAlertView
                    key={collection.id}
                    postIds={postIds}
                    collection={collection}
                    onClick={(selectedCollection) =>
                      onSelectCollection(selectedCollection.id)
                    }
                    selectedCollectionIds={selectedCollectionIds}
                    shouldShowBreadcrumbsForRoot
                    {...commonMenuItemProps}
                    componentProps={{
                      ...commonMenuItemProps.componentProps,
                      listItem: {
                        ...commonMenuItemProps.componentProps.listItem,
                        isSmartSearchResult:
                          collection.searchHitType === SearchHitType.Smart,
                      },
                    }}
                  />
                );
              }

              return null;
            }}
            onChange={(event, value, reason, details) => {
              const option = (details?.option as AutocompleteOptionType) || {};

              // Only handle when user selects the add new option
              // For other options, we handle them in renderOption & only accept click event
              // Reason: It's confusing to let default keyboard events work here, because users can randomly Enter
              // and toggle collection without knowing it
              const canCreate = orgBilling?.collectionUsageLimit
                ? orgBilling.collectionUsageCount <
                  orgBilling.collectionUsageLimit
                : true;
              if (
                canCreate &&
                option?.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY
              ) {
                setSearchStr('');
                triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
                  initialValues: {
                    name: option.label,
                    posts: [],
                  },
                  onCompleted: (collection) => {
                    onSelectCollection(collection.id).then(
                      () => refetchCollections(), // refetch to get the newly created collection
                    );
                  },
                  onSelectExistingCollection: (collectionId) => {
                    onSelectCollection(collectionId);
                  },
                });
              }
            }}
            popupIcon={null}
            clearIcon={null}
            // @ts-ignore
            PopperComponent={Box}
            PaperComponent={Box}
            componentsProps={{
              popper: {
                sx: {
                  mt: 4,
                  flex: 1,
                  position: 'relative',
                  width: '100% !important',
                  overflow: 'auto',
                  height: '100%',
                  '.MuiAutocomplete-listbox': {
                    maxHeight: 'unset',
                  },
                },
              },
            }}
            {...(loading
              ? {}
              : {
                  filterOptions: (
                    options: AutocompleteOptionType[],
                    params,
                  ) => {
                    const filtered = filter(options, params);
                    const isOptionExist = options.some(
                      (option) =>
                        option.label.trim().toLowerCase() ===
                        params.inputValue.trim().toLowerCase(),
                    );

                    if (params.inputValue !== '' && !isOptionExist) {
                      filtered.push({
                        label: params.inputValue,
                        value: COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
                      });
                    } else if (isFeatureEnabled(PlotFeature.ContentCalendar)) {
                      filtered.unshift({
                        label: 'Content Calendar',
                        value: CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
                      });
                    }

                    return filtered;
                  },
                })}
          />
        </Box>
      </Box>

      {orgBilling && (
        <BillingCollectionLimitIndicator
          variant="compact"
          organizationBilling={orgBilling}
          sx={{ borderRadius: theme.spacing(0, 0, 3, 3), width: 320 }}
        />
      )}
    </Popover>
  );
};
