import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { DatePicker } from 'components/common/DatePicker';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import {
  TaskFragmentTaskDueDateFragment,
  useUpdateTaskForTaskDueDateMutation,
} from 'graphql/generated';
import moment from 'moment';
import { theme } from 'styles/theme';

export const TASK_FRAGMENT_TASK_DUE_DATE = gql`
  fragment TaskFragmentTaskDueDate on TaskModel {
    id
    endDate
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskDueDate($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      ...TaskFragmentTaskDueDate
    }
  }
  ${TASK_FRAGMENT_TASK_DUE_DATE}
`;

export type TaskDueDateProps = {
  task: TaskFragmentTaskDueDateFragment;
  sx?: SxProps;
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
  };

  /**
   * If this is provided, it will be called when the task end date is changed
   * instead of the default mutation.
   */
  onChange?: (endDate: Date | null) => void;
  readOnly?: boolean;
};

export const TaskDueDate = (props: TaskDueDateProps) => {
  const {
    task,
    sx,
    onChange: _onChange,
    componentsProps = {},
    readOnly,
  } = props;

  const [updateTask] = useUpdateTaskForTaskDueDateMutation();

  const onChange =
    _onChange ||
    ((endDate: Date | null) => {
      updateTask({
        variables: {
          data: {
            taskId: task.id,
            data: {
              endDate,
            },
          },
        },
        optimisticResponse: {
          updateTask: {
            ...task,
            endDate,
          },
        },
      });
    });

  const stopEvent = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <DatePicker
      value={task.endDate ? moment(task.endDate) : null}
      onChange={(date) => {
        onChange(date ? date.toDate() : null);
      }}
      renderInput={(props) => {
        const { inputRef, openPicker } = props;

        const isTaskOverdue = Boolean(
          task.endDate && moment(task.endDate).isBefore(moment()),
        );

        return (
          <Tooltip
            title={
              !task.endDate
                ? 'Select due date'
                : `Due on ${moment(task.endDate).format('MMM D')}`
            }
            placement="top"
          >
            <Box
              component="button"
              ref={inputRef}
              display="flex"
              alignItems="center"
              onClick={(e) => {
                stopEvent(e);
                openPicker();
              }}
              color={
                isTaskOverdue
                  ? theme.colors?.primary.maroon
                  : theme.colors?.utility[900]
              }
              sx={sx}
              disabled={readOnly}
            >
              {task.endDate ? (
                <Typography variant="subhead-sm">
                  {moment(task.endDate).format('MMM D')}
                </Typography>
              ) : (
                <IconBoldCalendar
                  size={componentsProps?.icon?.size || 16}
                  color={componentsProps?.icon?.color}
                />
              )}
            </Box>
          </Tooltip>
        );
      }}
    />
  );
};
