import { Box, SxProps } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomFacebook } from 'components/icons/components/custom/IconCustomFacebook';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomLinkedin } from 'components/icons/components/custom/IconCustomLinkedin';
import { IconCustomPinterest } from 'components/icons/components/custom/IconCustomPinterest';
import { IconCustomThread } from 'components/icons/components/custom/IconCustomThread';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomUpload } from 'components/icons/components/custom/IconCustomUpload';
import { IconCustomX } from 'components/icons/components/custom/IconCustomX';
import { IconLinearDocumentNormal } from 'components/icons/components/linear/IconLinearDocumentNormal';
import { IconLinearGlobal } from 'components/icons/components/linear/IconLinearGlobal';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import { IconLinearVideoOctagon } from 'components/icons/components/linear/IconLinearVideoOctagon';
import { IconLinearYoutube } from 'components/icons/components/linear/IconLinearYoutube';
import { IconOutlineHashtag } from 'components/icons/components/outline/IconOutlineHashtag';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import {
  JuiceboxFilterButton,
  JuiceboxFilterOption,
} from 'features/juicebox/components';
import { JuiceboxFilter, JuiceboxFilterType } from 'features/juicebox/hooks';
import { FileFilterType, LinkFilterType } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

type OptionType = {
  Icon: React.FC<any>;
  text: string;
  disabled?: boolean;
  value: JuiceboxFilterType;
};

type ExtraFilterType = OptionType & {
  nestedOptions?: OptionType[];
  selectedNestedFilterCount?: number;
};

interface Props {
  selectedFilters: JuiceboxFilter[];
  setSelectedFilters: (filters: JuiceboxFilter[]) => void;
  canShowCollectionFilter?: boolean;
  extraFilters?: ExtraFilterType[];
  componentsProps?: {
    filterButton?: {
      sx?: SxProps;
    };
  };
}

export const JuiceboxTypeSelectButtonFilter = ({
  selectedFilters,
  setSelectedFilters,
  canShowCollectionFilter,
  extraFilters = [],
  componentsProps = {},
}: Props) => {
  const { filterOptions } = useMemo(() => {
    const linkFilters = [
      {
        Icon: IconCustomInstagram,
        text: 'Instagram',
        value: LinkFilterType.Instagram,
      },
      {
        Icon: IconCustomTiktok,
        text: 'TikTok',
        value: LinkFilterType.TikTok,
      },
      {
        Icon: IconCustomX,
        text: 'X',
        value: LinkFilterType.X,
      },
      {
        Icon: IconCustomLinkedin,
        text: 'Linkedin',
        value: LinkFilterType.Linkedin,
      },
      {
        Icon: IconCustomThread,
        text: 'Threads',
        value: LinkFilterType.Threads,
      },
      {
        Icon: IconCustomFacebook,
        text: 'Facebook',
        value: LinkFilterType.Facebook,
      },
      {
        Icon: IconCustomPinterest,
        text: 'Pinterest',
        value: LinkFilterType.Pinterest,
      },
      {
        Icon: IconLinearYoutube,
        text: 'Youtube',
        value: LinkFilterType.YouTube,
      },
      {
        Icon: IconLinearGlobal,
        text: 'Other',
        value: LinkFilterType.Other,
      },
    ];

    const selectedLinkFilterCount = linkFilters.filter((f) =>
      selectedFilters.some((sf) => sf.value === f.value),
    ).length;

    const uploadFilters = [
      {
        Icon: IconLinearDocumentNormal,
        text: 'Document',
        value: FileFilterType.Document,
      },
      {
        Icon: IconLinearVideoOctagon,
        text: 'Video',
        value: FileFilterType.Video,
      },
      {
        Icon: IconOutlineImage,
        text: 'Images',
        value: FileFilterType.Images,
      },
    ];

    const selectedUploadFilterCount = uploadFilters.filter((f) =>
      selectedFilters.some((sf) => sf.value === f.value),
    ).length;

    return {
      linkFilters,
      uploadFilters,
      filterOptions: [
        {
          Icon: IconLinearNote2,
          text: JuiceboxFilterType.Notes,
          value: JuiceboxFilterType.Notes,
        },
        {
          Icon: IconOutlineLink2,
          text: JuiceboxFilterType.Links,
          nestedOptions: linkFilters,
          selectedNestedFilterCount: selectedLinkFilterCount,
          value: JuiceboxFilterType.Links,
        },
        {
          Icon: IconCustomUpload,
          text: JuiceboxFilterType.Uploads,
          nestedOptions: uploadFilters,
          selectedNestedFilterCount: selectedUploadFilterCount,
          value: JuiceboxFilterType.Uploads,
        },
        {
          Icon: IconOutlineHashtag,
          text: JuiceboxFilterType.Collections,
          value: JuiceboxFilterType.Collections,
          disabled: !canShowCollectionFilter,
        },
        ...extraFilters,
      ],
    };
  }, [selectedFilters, canShowCollectionFilter, extraFilters]);

  const onFilterClick = (
    text: string,
    filterType: JuiceboxFilterType,
    filterValue: string,
  ) => {
    if (selectedFilters.some((f) => f.value === filterValue)) {
      setSelectedFilters(
        selectedFilters.filter((f) => f.value !== filterValue),
      );
    } else {
      setSelectedFilters([
        ...selectedFilters,
        { filterType, value: filterValue, label: text },
      ]);
    }
  };

  return (
    <ContextMenu
      renderButton={() => (
        <JuiceboxFilterButton
          Icon={IconLinearNote}
          text="Type"
          sx={componentsProps.filterButton?.sx}
        />
      )}
      PaperProps={{
        style: {
          minWidth: 266,
          borderRadius: theme.spacing(3),
          boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
        },
      }}
      options={filterOptions.map(
        ({
          Icon,
          text,
          disabled,
          value,
          nestedOptions = [],
          selectedNestedFilterCount,
        }) => ({
          onClick: () => {
            if (disabled) {
              return;
            }
            onFilterClick(text, value, value);
          },
          sx: {
            borderRadius: `${theme.spacing(3)} !important`,
            mt: 1,
            opacity: disabled ? 0.4 : 1,
            ':hover': {
              backgroundColor: disabled ? 'transparent' : `rgba(35, 6, 3, 0.1)`,
            },
          },
          ...(nestedOptions.length
            ? {
                tooltip: {
                  title: nestedOptions.map((nestedOption) => (
                    <Box
                      sx={{
                        padding: theme.spacing(3, 3),
                        borderRadius: 3,
                        cursor: 'pointer',
                        opacity: disabled ? 0.4 : 1,
                        ':hover': {
                          backgroundColor: disabled
                            ? 'transparent'
                            : `rgba(35, 6, 3, 0.1)`,
                        },
                      }}
                      onClick={() => {
                        if (disabled) {
                          return;
                        }
                        onFilterClick(
                          nestedOption.value,
                          value,
                          nestedOption.value,
                        );
                      }}
                    >
                      <JuiceboxFilterOption
                        Icon={nestedOption.Icon}
                        text={nestedOption.text}
                        disabled={disabled}
                        isChecked={selectedFilters.some(
                          (f) => f.value === nestedOption.value,
                        )}
                      />
                    </Box>
                  )),
                  placement: 'right-start',
                  componentsProps: {
                    tooltip: {
                      style: {
                        minWidth: 266,
                        borderRadius: theme.spacing(3),
                        backgroundColor: theme.colors?.primary.white,
                        padding: theme.spacing(2),
                        boxShadow:
                          '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
                      },
                    },
                  },
                },
              }
            : {}),
          renderOption: () => {
            return (
              <JuiceboxFilterOption
                Icon={Icon}
                text={text}
                disabled={disabled}
                hasNestedFilter={nestedOptions.length > 0}
                isChecked={selectedFilters.some((f) => f.value === value)}
                selectedNestedFilterCount={selectedNestedFilterCount}
              />
            );
          },
          closeOnClick: false,
        }),
      )}
    />
  );
};
