import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { CustomVideoPreview } from 'components/common/Iframely/components/CustomRenderingWidget/CustomVideoPreview';
import { useCheckRenderingWidget } from 'components/common/Iframely/hooks/useCheckRenderingWidget';
import { Image } from 'components/common/Image';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconOutlineDocument } from 'components/icons/components/outline/IconOutlineDocument';
import { AttachmentFragmentAttachmentPreviewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const ATTACHMENT_FRAGMENT_ATTACHMENT_PREVIEW = gql`
  fragment AttachmentFragmentAttachmentPreview on AttachmentModel {
    id
    url
    name
    metaData {
      mimeType
    }
  }
`;

export interface AttachmentPreviewProps {
  attachment: AttachmentFragmentAttachmentPreviewFragment;
  sx?: SxProps;
  componentsProps?: {
    playIcon?: {
      size?: number;
    };
  };
}

export const AttachmentPreview = (props: AttachmentPreviewProps) => {
  const { attachment, sx, componentsProps = {} } = props;

  const { customRenderingElement } = useCheckRenderingWidget({
    url: attachment.url,
    mimeType: attachment.metaData.mimeType ?? undefined,
  });

  if (customRenderingElement === 'video') {
    return (
      <Box
        sx={{
          position: 'relative',
          img: {
            display: 'flex',
          },
          ...sx,
        }}
      >
        <CustomVideoPreview
          url={attachment.url}
          sx={{
            // @ts-ignore
            objectFit: sx?.objectFit ?? 'cover',
          }}
        />
        <IconBoldPlayCircle
          size={componentsProps.playIcon?.size ?? 12}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: theme.colors?.primary.parchment,
          }}
        />
      </Box>
    );
  }

  // for image attachment in note and link thumbnails
  if (customRenderingElement === 'img') {
    return (
      <Image
        src={attachment.url}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          objectFit: 'cover',
          display: 'flex',
          ...sx,
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 1,
        gap: 1.5,
        maxWidth: 192,
        overflow: 'hidden',
        bgcolor: theme.colors?.utility[275],
        ...sx,
        width: 'auto',
      }}
    >
      <Box
        sx={{
          flexShrink: 0,
          width: 42,
          height: 42,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          bgcolor: theme.colors?.utility['orange-1'],
        }}
      >
        <IconOutlineDocument size={16} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="headline-xs"
          color={theme.colors?.utility[800]}
          sx={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          {attachment.name}
        </Typography>
        <Typography
          variant="subhead-lg"
          color={theme.colors?.utility[600]}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          {attachment.name.split('.').pop() || 'N/A'}
        </Typography>
      </Box>
    </Box>
  );
};
