import {
  CUSTOM_COLLECTION,
  CollectionCardView,
  CollectionCardViewProps,
} from 'features/collection';
import { GeneralPermission, PostFilterType } from 'graphql/generated';
import { usePreviewablePostQuery } from './usePreviewablePostQuery';

export const AllPostsCollectionCardView = (
  props: Omit<CollectionCardViewProps, 'collection'>,
) => {
  const { data, previewablePosts } = usePreviewablePostQuery({
    filters: {
      filterType: PostFilterType.OrganizationPosts,
    },
  });
  const posts = previewablePosts;
  const totalPosts = data?.posts.meta.totalCount || 0;

  return (
    <CollectionCardView
      {...props}
      collection={{
        id: CUSTOM_COLLECTION.ALL_POSTS,
        __typename: 'CollectionModel',
        name: 'All Posts',
        posts,
        totalPosts,
        totalChildCollections: 0,
        creatorId: '',
        generalPermission: GeneralPermission.OrganizationMembers,
        inviteMembers: [],
        hasPreviewPost: false,
        organizationName: '',
        myPermissions: [],
        isDisabled: false,
        isPinnedToRoot: false,
        isPinnedToParent: false,
        breadcrumbsFromRoot: [],
      }}
      componentsProps={{
        disableContextMenu: true,
        multiPostPreview: {
          variant: 'card-stack',
        },
        ...props.componentsProps,
      }}
    />
  );
};
