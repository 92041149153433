import React, { useLayoutEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { styled } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';

type TooltipForTruncatedTextProps = Omit<TooltipProps, 'children'> & {
  fadingEffect?: boolean;
  backgroundColor?: string;
  children: React.ReactNode;
  fadingWidth?: number;
};

const FadingContainer = styled('div')<{
  backgroundColor?: string;
  fadingWidth?: number;
}>(({ backgroundColor, fadingWidth = 50 }) => ({
  position: 'absolute',
  right: -5,
  top: 0,
  width: fadingWidth,
  height: '100%',
  background: `linear-gradient(90deg, rgba(255, 255, 255, 0) 0%,  ${
    backgroundColor || theme.colors?.primary.white
  } 63.67%)`,
  borderRadius: '0px 8px 8px 0px',
}));

export const TooltipForTruncatedText: React.FC<TooltipForTruncatedTextProps> = (
  props,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isEllipsing, setIsEllipsing] = useState(false);

  useLayoutEffect(() => {
    const container = ref.current;
    if (container) {
      setIsEllipsing(container.scrollWidth > container.clientWidth);
    }
  }, [props.title]);

  return (
    <Tooltip {...props} title={isEllipsing ? props.title : ''}>
      <div
        ref={ref}
        style={{
          position: 'relative',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: props.fadingEffect ? 'clip' : 'ellipsis',
          margin: 0,
        }}
      >
        {props.children}
        {isEllipsing && props.fadingEffect && (
          <FadingContainer
            backgroundColor={props.backgroundColor}
            fadingWidth={props?.fadingWidth}
          />
        )}
      </div>
    </Tooltip>
  );
};
