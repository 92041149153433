import LiveblocksProvider from '@liveblocks/yjs';
import { BoxProps, SxProps } from '@mui/material';
import {
  Editor,
  EditorContentProps,
  EditorOptions,
  Extensions,
  FocusPosition,
} from '@tiptap/react';
import { Presence, RoomEvent, UserMeta } from 'features/collaboration';
import { PostThemeColor } from 'features/note';
import type {
  MasonryExtensionExternalAttrs,
  TaskItemExtensionExternalAttrs,
  UseEditorExtensionsProps,
} from 'features/tiptap';
import { CommentInputData } from 'graphql/generated';
import { ReactNode } from 'react';

export type NoteRichTextEditorRef = {
  focus: (
    position?: FocusPosition,
    options?: {
      scrollIntoView?: boolean;
    },
  ) => void;
  clear: () => void;
  editor: Editor | null;
};

export type NoteRichTextEditorProps = Omit<
  EditorContentProps,
  'editor' | 'ref' | 'onChange' | 'content'
> & {
  provider?: LiveblocksProvider<Presence, Storage, UserMeta, RoomEvent>;
  extraExtensions?: Extensions;
  defaultContent?: string | null;
  editable?: boolean;
  placeholder?: string;
  themeColor?: PostThemeColor;
  componentsProps?: {
    container?: BoxProps;
    editor?: EditorOptions['editorProps'] & {
      /**
       * When the editor is ready
       */
      onCreate?: () => void;
    };
    toolbar?: {
      sx?: SxProps;
    };
  };
  customNodeAttrs?: {
    taskItem?: TaskItemExtensionExternalAttrs;
    masonry?: MasonryExtensionExternalAttrs;
  };
  customExtensionOptions?: {
    comment?: {
      onCreateComment: (
        comment: CommentInputData,
        noteCommentAnchorId: string,
      ) => Promise<void>;
    };
  };
  useEditorExtensionsProps?: UseEditorExtensionsProps;
  onArrowUp?: VoidFunction;

  /**
   * When the editor is ready and the content is set
   */
  onContentSet?: VoidFunction;
  onContentChange?: (body: string) => void;

  /**
   * TODO: Find a better name & a better approach.
   * This is to customize the editor's functionality depending on the mode.
   * 1. Full: Same as before. All features are enabled.
   * 2. Comment-only: Only comment features are enabled.
   * In both modes, the document is modify-able (due to the way comment extension works).
   */
  mode?: NoteRichTextEditorMode;

  renderToolbar?: (editor: Editor, isBubbleMenuVisible?: boolean) => ReactNode;
};

export enum NoteRichTextEditorMode {
  Full = 'full',
  CommentOnly = 'comment-only',
}
