import { useUserContext } from 'contexts/users/User.context';
import { NOTE_COLORS } from 'features/note/constants/noteColors';
import {
  PostCreationSource,
  useUpdateNoteColorMutation,
} from 'graphql/generated';
import { ReactNode, useCallback, useEffect } from 'react';

export const ToolbarCommand = {
  DocumentUpload: 'DocumentUpload',
  TaskList: 'TaskList',
  BulletedList: 'BulletedList',
  NumberedList: 'NumberedList',
  CodeBlock: 'CodeBlock',
  ChangeColor: 'ChangeColor',
};

type NoteToolBarOption = {
  icon: ReactNode;
  onClick?: VoidFunction;
  enabled?: boolean;
  command?: string;
};

// This hook is used to listen to messages from the mobile app
// to support note tool bar on the mobile app side
export const useNoteToolbarWithMobileApp = ({
  options,
  noteId,
  collectionId,
  enabled,
}: {
  options: NoteToolBarOption[];
  noteId: string;
  collectionId?: string;
  enabled?: boolean;
}) => {
  const [updateNoteColor] = useUpdateNoteColorMutation();
  const { isMobileAppWebView } = useUserContext();
  const onMessage = useCallback(
    (message: MessageEvent<any>) => {
      if (Object.keys(ToolbarCommand).includes(message.data)) {
        // find the toolbar command by the command key
        options?.find((item) => item?.command === message?.data)?.onClick?.();
      }
      if (
        typeof message.data === 'string' &&
        message.data?.includes('ChangeColor:')
      ) {
        const colorCode = message.data.split(':')?.[1];
        const colorMatched = NOTE_COLORS.find((item) => {
          if (item.color?.includes('var')) {
            const match = item.color?.match(/var\((--[^)]+)\)/);
            if (match) {
              const variableName = match[1];
              const style = getComputedStyle(document.body);
              const colorHex = style.getPropertyValue(variableName);
              return colorHex === colorCode;
            }
          }
          return item?.color === colorCode;
        });
        if (colorMatched && colorMatched.color) {
          updateNoteColor({
            variables: {
              data: {
                postId: noteId,
                data: {
                  collectionId,
                  color: colorMatched?.color,
                  source: isMobileAppWebView
                    ? PostCreationSource.Mobile
                    : PostCreationSource.Web,
                },
              },
            },
            optimisticResponse: {
              updatePost: {
                id: noteId,
                __typename: 'PostModel',
                color: colorMatched.color,
              },
            },
          });
        }
      }
    },
    [collectionId, noteId, options, updateNoteColor],
  );

  useEffect(() => {
    if (enabled) {
      // listen message from mobile app
      window.addEventListener('message', onMessage);
    }
  }, [enabled, onMessage]);

  return {};
};
