import {
  Box,
  Divider,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { IconBoldTick } from 'components/icons/components/bold/IconBoldTick';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { PermissionLevel } from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import { BillingUpgradeModal } from 'features/billing';
import { useDisclosure } from '@dwarvesf/react-hooks';

export const PermissionLevelMap = {
  [PermissionLevel.Full]: {
    label: 'Full access',
    info: 'Can upload, edit, comment, and invite.',
  },
  [PermissionLevel.Comment]: {
    label: 'Can comment',
    info: 'Can comment but not upload, edit, or invite.',
  },
  [PermissionLevel.View]: {
    label: 'Can View',
    info: 'Cannot upload, edit, comment, or invite.',
  },
};

type PermissionSelectorProps = {
  entityOrganizationId: string;
  userOrganizationId: string;
  isDisabledUser?: boolean;
  initialValue?: PermissionLevel;
  onPermissionChange?: (permission: PermissionLevel) => void;
  showRemove?: boolean;
  onRemove?: () => void;
  selectPaperSx?: SxProps;
  sx?: SxProps;
  readonly?: boolean;
  variant?: 'icon' | 'standard';
  visiblePermissions?: PermissionLevel[];
};

export const PermissionSelector = (props: PermissionSelectorProps) => {
  const {
    entityOrganizationId,
    userOrganizationId,
    isDisabledUser,
    onPermissionChange,
    initialValue,
    selectPaperSx = {},
    showRemove,
    onRemove,
    sx,
    readonly,
    variant = 'standard',
    visiblePermissions,
  } = props;

  const { orgBilling, joinedOrgBillings } = useUserContext();

  const organizationBilling = useMemo(() => {
    if (orgBilling?.organizationId === entityOrganizationId) {
      return orgBilling;
    }
    return joinedOrgBillings?.find(
      (j) => j.organizationId === entityOrganizationId,
    );
  }, [orgBilling, joinedOrgBillings, entityOrganizationId]);

  const isGuest = useMemo(() => {
    return userOrganizationId !== entityOrganizationId;
  }, [userOrganizationId, entityOrganizationId]);

  const highestLevelAllowed = useMemo(() => {
    if (isGuest && organizationBilling?.guestPermission) {
      return organizationBilling?.guestPermission;
    }

    return PermissionLevel.Full;
  }, [isGuest, organizationBilling]);

  const {
    isOpen: pricingModalOpen,
    onOpen: pricingModalOnOpen,
    onClose: pricingModalOnClose,
  } = useDisclosure();

  const [currentPermission, setCurrentPermission] = useState<
    PermissionLevel | undefined
  >(initialValue);

  useEffect(() => {
    setCurrentPermission(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (currentPermission) {
      onPermissionChange?.(currentPermission);
    }
  }, [currentPermission]);

  const { availableOptions, paidOptions } = useMemo(() => {
    const options = !visiblePermissions?.length
      ? Object.keys(PermissionLevelMap)
      : Object.keys(PermissionLevelMap).filter((key) =>
          visiblePermissions.includes(key as PermissionLevel),
        );

    let availableOptions: string[] = [];
    const paidOptions: string[] = [];

    if (isDisabledUser) {
      // for disabled users, no options are available
      return {
        availableOptions: [],
        paidOptions: options,
      };
    }

    for (const { index, option } of options.map((option, index) => ({
      index,
      option,
    }))) {
      if (highestLevelAllowed === option) {
        availableOptions = options.slice(index, options.length);
        break;
      }

      paidOptions.push(option);
    }

    return {
      availableOptions,
      paidOptions,
    };
  }, [highestLevelAllowed]);

  if (readonly) {
    return (
      <Typography variant="headline-xs" color={theme.colors?.utility[800]}>
        {isDisabledUser
          ? 'Disabled'
          : initialValue
          ? PermissionLevelMap[initialValue].label
          : 'No access'}
      </Typography>
    );
  }

  return (
    <Select
      size="small"
      fullWidth
      sx={{
        '& .MuiSelect-select': {
          padding: '4px 26px 2px 12px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        ...(variant === 'icon'
          ? {
              '& .MuiSelect-icon': {
                display: 'none',
              },
            }
          : {}),
        ...sx,
      }}
      value={isDisabledUser ? 'Disabled' : currentPermission}
      onClick={(e) => {
        e.stopPropagation();
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            boxShadow: '0px 6px 12px -2px rgba(0, 0, 0, 0.30)',
            p: 1,
            borderRadius: 4,
            ...selectPaperSx,
          },
        },
      }}
      onChange={(e) => {
        setCurrentPermission(e.target.value as PermissionLevel);
      }}
      renderValue={(value: string) =>
        variant === 'icon' ? (
          <IconLinearArrowDown
            size={14}
            color={theme.colors?.primary.parchment}
          />
        ) : (
          <Typography variant="headline-xs" color={theme.colors?.utility[800]}>
            {PermissionLevelMap[value]
              ? PermissionLevelMap[value].label
              : value || 'No access'}
          </Typography>
        )
      }
    >
      {availableOptions.map((permission) => (
        <MenuItem value={permission} key={permission}>
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              sx={{ opacity: currentPermission === permission ? 1 : 0 }}
            >
              <IconBoldTick size={16} />
            </Box>
            <Box display="flex" gap={1} flexDirection="column" ml={3}>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                sx={{
                  fontWeight: currentPermission === permission ? 600 : 500,
                }}
              >
                {PermissionLevelMap[permission].label}
              </Typography>

              <Typography
                variant="body-sm"
                color={theme.colors?.utility[800]}
                sx={{
                  fontWeight: currentPermission === permission ? 600 : 500,
                }}
              >
                {PermissionLevelMap[permission].info}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      ))}

      {showRemove && (
        <MenuItem value="" key={-1} onClick={onRemove}>
          <Box display="flex" alignItems="center">
            <Box display="flex" sx={{ opacity: 0 }}>
              <IconBoldTick size={16} />
            </Box>
            <Box display="flex" gap={1} flexDirection="column" ml={3}>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                sx={{}}
              >
                Remove
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      )}

      {paidOptions.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(4),
            padding: theme.spacing(2, 3),
          }}
        >
          <Divider />
          <Box>
            <Typography variant="headline-xs">
              <Typography
                variant="headline-xs"
                sx={{
                  fontWeight: 600,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={pricingModalOnOpen}
              >
                {organizationBilling?.hasUsedFreeTrial
                  ? 'Upgrade Now'
                  : 'Start Free Trial'}
              </Typography>{' '}
              to enable:
            </Typography>

            <BillingUpgradeModal
              isOpen={pricingModalOpen}
              onClose={pricingModalOnClose}
            />
          </Box>

          {paidOptions.map((option) => (
            <Box
              key={option}
              display="flex"
              gap={1}
              flexDirection="column"
              ml={7}
            >
              <Typography variant="body-lg" color={theme.colors?.utility[700]}>
                {PermissionLevelMap[option].label}
              </Typography>

              <Typography variant="body-sm" color={theme.colors?.utility[700]}>
                {PermissionLevelMap[option].info}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Select>
  );
};
