import { gql } from '@apollo/client';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import { useUserContext } from 'contexts/users/User.context';
import {
  AggregatedBrandDurationUsageFragmentBrandTableRowFragmentDoc,
  useGetAggregatedBrandDurationUsageForBrandBreakdownDialogQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
// eslint-disable-next-line
import { BrandTableRow } from './BrandTableRow';

// eslint-disable-next-line
gql`
  query GetAggregatedBrandDurationUsageForBrandBreakdownDialog(
    $filters: AggregatedBrandDurationUsageFilters!
  ) {
    aggregatedBrandDurationUsage(filters: $filters) {
      ...AggregatedBrandDurationUsageFragmentBrandTableRow
    }
  }
  ${AggregatedBrandDurationUsageFragmentBrandTableRowFragmentDoc}
`;

interface BrandBreakdownDialogViewProps {
  open: boolean;
  onClose: () => void;
}

export const brandBreakdownTableHeaders = [
  'Brand Name',
  'Total hours',
  'Total posts parsed',
  'Postively matched',
  '',
];

const generateDateRanges = () => {
  const today = moment();
  const ranges: {
    label: string;
    value: {
      startDate: Date;
      endDate: Date;
    };
  }[] = [];

  for (let i = 1; i < 6; i++) {
    const endDate = moment(today)
      .subtract(i - 1, 'months')
      .date(today.date());
    const startDate = moment(endDate).subtract(1, 'months');
    ranges.push({
      label: `${startDate.format('Do MMM YYYY')} - ${
        i === 1 ? 'Today' : endDate.format('Do MMM YYYY')
      }`,
      value: {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      },
    });
  }

  return ranges;
};

export const BrandBreakdownDialogView = ({
  open,
  onClose,
}: BrandBreakdownDialogViewProps) => {
  const dateRanges = generateDateRanges();

  const { orgBilling } = useUserContext();

  const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[0]);

  const { data } =
    useGetAggregatedBrandDurationUsageForBrandBreakdownDialogQuery({
      variables: {
        filters: {
          dateRange: selectedDateRange.value,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const aggregatedBrandDurationUsage = useMemo(
    () => data?.aggregatedBrandDurationUsage || [],
    [data],
  );

  const { totalHours, totalPostsParsed, totalPositivelyMatched } =
    useMemo(() => {
      const totalHours = aggregatedBrandDurationUsage.reduce(
        (acc, usage) => acc + Math.ceil(usage.durationUsed / 3600),
        0,
      );

      const totalPostsParsed = aggregatedBrandDurationUsage.reduce(
        (acc, usage) => acc + usage.totalParsedPostsCount,
        0,
      );
      const totalPositivelyMatched = aggregatedBrandDurationUsage.reduce(
        (acc, usage) => acc + usage.totalPositivelyParsedPostsCount,
        0,
      );

      return {
        totalHours,
        totalPostsParsed,
        totalPositivelyMatched,
      };
    }, [aggregatedBrandDurationUsage]);

  const isLimitReached = totalHours > (orgBilling?.brandHoursParseLimit || 0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{
        p: 6,
      }}
      PaperProps={{
        sx: {
          borderRadius: 6,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              padding: 0,
            }}
          >
            <IconBoldCloseCircle color={theme.colors?.utility[700]} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Typography
              variant="headline-xl"
              sx={{
                fontSize: 24,
              }}
            >
              Breakdown
            </Typography>
            <Typography variant="headline-sm">·</Typography>
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[600]}
            >
              resets in {moment(moment().endOf('month')).diff(moment(), 'days')}{' '}
              days
            </Typography>
          </Box>
          <ContextMenu
            PaperProps={{
              sx: {
                background: 'rgba(255, 255, 255, 0.80)',
                backdropFilter: 'blur(20px)',
                boxShadow:
                  '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12) !important',
                py: 3,
                border: 'none',
              },
            }}
            renderButton={() => (
              <IconButton
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  borderRadius: 2,
                  background: theme.colors?.utility[300],
                  color: theme.colors?.primary.black,
                }}
              >
                <IconOutlineCalendar1 size={16} />
                <Typography variant="subhead-xl">
                  {selectedDateRange.label}
                </Typography>
                <IconLinearArrowDown
                  size={16}
                  style={{
                    marginLeft: theme.spacing(1),
                  }}
                />
              </IconButton>
            )}
            options={dateRanges.reverse().map((range) => ({
              renderOption: () => (
                <RadioMenuItem
                  checked={range.label === selectedDateRange.label}
                  label={range.label}
                  value={range.label}
                  sx={{
                    padding: 0,
                    ':hover': {
                      background: 'transparent',
                    },
                    svg: {
                      color: `${theme.colors?.primary.black} !important`,
                    },
                  }}
                />
              ),
              onClick: () => setSelectedDateRange(range),
            }))}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              {brandBreakdownTableHeaders.map((header, index) => (
                <TableCell
                  key={header}
                  sx={{
                    padding: 2.5,
                    borderBottom: `1px solid ${theme.colors?.utility[250]}`,
                    width: index === 0 ? '30%' : '18%',
                  }}
                >
                  <Typography
                    variant="headline-xs"
                    color={theme.colors?.utility[700]}
                  >
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregatedBrandDurationUsage.map((brand) => (
              <BrandTableRow
                key={brand.brand.id}
                brand={brand}
                showTopicDirectly={aggregatedBrandDurationUsage.length === 1}
              />
            ))}
            <TableRow
              sx={{
                '& td': {
                  borderBottom: `1px solid ${theme.colors?.utility[250]}`,
                },
              }}
            >
              <TableCell>
                <Typography variant="headline-sm">Total</Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subhead-xl"
                  color={
                    isLimitReached
                      ? theme.colors?.utility['pink-3']
                      : theme.colors?.utility[700]
                  }
                  fontWeight={isLimitReached ? 700 : 400}
                >
                  {totalHours}
                </Typography>
                <Typography
                  variant="subhead-xl"
                  color={theme.colors?.utility[700]}
                >
                  {' '}
                  of {orgBilling?.brandHoursParseLimit}
                </Typography>
                {isLimitReached && (
                  <Typography
                    component="p"
                    variant="subhead-lg"
                    color={theme.colors?.utility[700]}
                  >
                    {/* TODO: update it with the actual price */}
                    Hours over your monthly limit are billed at $150 per 25-hour
                    block.
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <Typography
                  variant="subhead-xl"
                  color={theme.colors?.utility[800]}
                >
                  {totalPostsParsed}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="subhead-xl"
                  color={theme.colors?.utility[800]}
                >
                  {totalPositivelyMatched}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};
