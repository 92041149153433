import { Box, Button, Typography } from '@mui/material';
import { BottomSheet } from 'components/common/BottomSheet/BottomSheet';
import { theme } from 'styles/theme';
// eslint-disable-next-line -- cycle issue
import { MOBILE_APP_APP_STORE_URL } from './DownloadMobileAppCTA';

export type ContinueInMobileAppCTAProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ContinueInMobileAppCTA = (props: ContinueInMobileAppCTAProps) => {
  const { isOpen, onClose } = props;

  return (
    <BottomSheet
      // Don't close the modal when clicking outside of it
      // User must click on one of the buttons to close it
      isOpen={isOpen}
      sx={{
        '& .MuiPaper-root': {
          height: 'auto',
        },
      }}
    >
      <Box
        sx={{
          py: 8,
          px: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="headline-xl" fontSize={24} mb={2}>
          Continue in Mobile App?
        </Typography>
        <Typography
          variant="subhead-xl"
          color={theme.colors?.utility[700]}
          mb={6}
        >
          Plot is better when viewed in the app
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box
              component="img"
              src="/images/safari.png"
              alt="Safari"
              sx={{ width: 32, height: 32 }}
            />
            <Typography variant="headline-sm">Browser</Typography>
            <Button
              variant="secondary"
              sx={{
                ml: 'auto',
                width: 120,
                color: theme.colors?.primary.black,
                borderColor: theme.colors?.primary.black,
              }}
              onClick={onClose}
            >
              Continue
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box
              component="img"
              src="/logo192.png"
              alt="Safari"
              sx={{ width: 32, height: 32 }}
            />
            <Typography variant="headline-sm">Plot - A Creative Hub</Typography>
            <Button
              variant="primary-alt"
              sx={{
                ml: 'auto',
                width: 120,
              }}
              onClick={() => {
                window.open(MOBILE_APP_APP_STORE_URL, '_blank');
              }}
            >
              Open App
            </Button>
          </Box>
        </Box>
      </Box>
    </BottomSheet>
  );
};
