import { gql } from '@apollo/client';
import { URL_METADATA_FRAGMENT_IFRAMELY } from 'components/common/Iframely/types';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import { PostFragmentPostIconFragment, PostType } from 'graphql/generated';
import { CSSProperties } from 'react';
import { getValidatedMimeType } from 'utils/mime';
import { getPostIconTypeName } from './utils';

export const POST_FRAGMENT_POST_ICON = gql`
  fragment PostFragmentPostIcon on PostModel {
    id
    type
    urlMetadata {
      id
      ...URLMetadataFragmentIframely
    }
  }
  ${URL_METADATA_FRAGMENT_IFRAMELY}
`;

export type PostIconProps = {
  post: PostFragmentPostIconFragment;
  size?: number;
  style?: CSSProperties;
  mimeType?: string;
};

export const PostIcon = (props: PostIconProps) => {
  const { post, size = 20, style, mimeType: defaultMimeType } = props;

  const urlMetadata = post.urlMetadata?.[0];
  const site = urlMetadata?.metadata?.site || '';
  const medium = urlMetadata?.metadata?.medium || '';
  const mimeType = defaultMimeType || urlMetadata?.metadata?.type || '';
  const url = urlMetadata?.url || '';

  const { icon: PostIcon } = getPostIconTypeName({
    site,
    medium,
    mimeType: getValidatedMimeType(mimeType, url),
    url,
  });

  // Always return a note icon if the post is a note
  if (post.type === PostType.Note) {
    return <IconLinearNote2 size={size} style={style} />;
  }

  return <PostIcon size={size} style={style} />;
};
