import { gql } from '@apollo/client';
import { Box, Dialog } from '@mui/material';
import { useRenderSocialListeningPostVideo } from 'features/socialMediaListening/hooks/useRenderSocialListeningPostVideo';
import {
  SocialPostFragmentPostVideoDialogFragment,
  SocialPostFragmentSocialMediaListeningPostSummaryFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { SocialMediaListeningContentTabs } from './sections/SocialMediaListeningContentTabs';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentPostVideoDialog on SocialPostModel {
    ...SocialPostFragmentSocialMediaListeningPostSummary
  }
  ${SocialPostFragmentSocialMediaListeningPostSummaryFragmentDoc}
`;

type Props = {
  brandId: string;
  post: SocialPostFragmentPostVideoDialogFragment;
  isOpen: boolean;
  onClose: () => void;
};

export const PostVideoDialog = ({ brandId, post, isOpen, onClose }: Props) => {
  const {
    renderVideoPlayer,
    seekVideoTime,
    currentVideoTime,
    renderedVideoHeight,
  } = useRenderSocialListeningPostVideo({
    post,
  });
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: {
          minWidth: theme.spacing(220),
          borderRadius: 6,
          border: 'none',
        },
      }}
    >
      <Box
        display="flex"
        p={8}
        alignItems="stretch"
        gap={6}
        height="inherit"
        position="relative"
      >
        <Box height="100%" flex={2}>
          {renderVideoPlayer()}
        </Box>
        <Box
          flex={3}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          height="100%"
          width="100%"
          maxHeight={renderedVideoHeight}
          overflow="auto"
          gap={3}
        >
          <SocialMediaListeningContentTabs
            brandId={brandId}
            post={post}
            currentVideoTimeInSeconds={currentVideoTime}
            componentProps={{
              hideTabs: ['creator'],
              videoHighlights: {
                onHighlightClicked: (time) => {
                  seekVideoTime(time);
                },
              },
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
