import { Box, MenuItem, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { SortOption } from '../types';

type Props = {
  options: SortOption[];
  onOptionClick: (key: string) => void;
};

export const SortByOptions = ({ options, onOptionClick }: Props) => {
  return (
    <>
      {options.map((option) => {
        const Icon = option.icon;
        return (
          <MenuItem
            sx={{
              width: 270,
              borderRadius: 3,
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
            onClick={() => onOptionClick(option.key)}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              <Icon size={16} />
            </Box>
            <Box display="flex" flex={1}>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                fontWeight={500}
              >
                {option.label}
              </Typography>
            </Box>
          </MenuItem>
        );
      })}
    </>
  );
};
