import { useState } from 'react';

export const useTrendExampleNavigatorHandler = () => {
  // Handles mouse scroll events to change trend examples
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const onShowPreviousTrendExample = () => {
    const arrowKeyUpEvent = new KeyboardEvent('keydown', {
      key: 'ArrowUp',
      code: 'ArrowUp',
      keyCode: 38,
      which: 38,
      bubbles: true,
    });

    document.dispatchEvent(arrowKeyUpEvent);
  };

  const onShowNextTrendExample = () => {
    const arrowKeyDownEvent = new KeyboardEvent('keydown', {
      key: 'ArrowDown',
      code: 'ArrowDown',
      keyCode: 40,
      which: 40,
      bubbles: true,
    });

    document.dispatchEvent(arrowKeyDownEvent);
  };

  const onWheel = (event) => {
    clearTimeout(timeoutId);

    const newTimeoutId = setTimeout(() => {
      if (event.deltaY < 0) {
        onShowPreviousTrendExample();
      } else if (event.deltaY > 0) {
        onShowNextTrendExample();
      }
    }, 300);

    setTimeoutId(newTimeoutId);
  };

  return {
    onShowPreviousTrendExample,
    onShowNextTrendExample,
    onWheel,
  };
};
