import { gql } from '@apollo/client';
import { useUserContext } from 'contexts/users/User.context';
import {
  CollaborativeNoteView,
  CollaborativeNoteViewProps,
  getNoteColorFromColor,
} from 'features/note';
import { NOTE_COLORS } from 'features/note/constants/noteColors';
import { useNavigatePostSearchParams } from 'features/post/hooks/useNavigatePostSearchParams';
import { usePostAnnotationHandlers } from 'features/post/views/detail/content/usePostAnnotationHandlers';
import {
  PostAnnotationFragmentAnnotationFragmentDoc,
  PostFragmentCollaborativeNoteViewFragmentDoc,
  PostFragmentNoteDetailContentViewFragment,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect, useMemo } from 'react';
import { StyledContentContainer, StyledFormContainer } from './styles';

export const POST_FRAGMENT_NOTE_DETAIL_CONTENT_VIEW = gql`
  fragment PostFragmentNoteDetailContentView on PostModel {
    id
    annotations {
      id
      ...PostAnnotationFragmentAnnotation
    }
    ...PostFragmentCollaborativeNoteView
  }
  ${PostAnnotationFragmentAnnotationFragmentDoc}
  ${PostFragmentCollaborativeNoteViewFragmentDoc}
`;

export type NoteDetailContentViewProps = {
  note: PostFragmentNoteDetailContentViewFragment;

  // TODO: This is a temporary prop to disable next and prev navigation in post preview window
  // We'll remove this once we have handled passing in task id into this view as filters for prev/next navigation.
  allowNextAndPrevNavigation?: boolean;

  componentsProps?: {
    noteView?: Pick<CollaborativeNoteViewProps, 'componentsProps'>;
  };

  refetchData?: VoidFunction;
};

export const NoteDetailContentView = ({
  note,
  allowNextAndPrevNavigation = true,
  componentsProps = {},
  refetchData,
}: NoteDetailContentViewProps) => {
  const isMobileView = useMediaQueryMobile();
  const { isMobileAppWebView } = useUserContext();

  const analytics = useAnalytics();
  const { renderNextBtn, renderPrevBtn } = useNavigatePostSearchParams();

  useEffect(() => {
    analytics.track(EventName.NotePostOpened);
  }, []); // eslint-disable-line

  const themeColor = useMemo(() => {
    const match = getNoteColorFromColor(note.color);
    return note?.color ? match || NOTE_COLORS[0] : NOTE_COLORS[0];
  }, [note]);

  const { onCreatePostAnnotation } = usePostAnnotationHandlers();

  return (
    <StyledContentContainer
      bgcolor={themeColor?.color}
      borderRadius={isMobileView ? 0 : 6}
    >
      <StyledFormContainer className="note-view-container">
        <CollaborativeNoteView
          note={note}
          refetchData={refetchData}
          componentsProps={{
            content: {
              editable: true,
              customNodeAttrs: {
                taskItem: {
                  themeColor,
                },
                masonry: {
                  themeColor,
                },
              },
              customExtensionOptions: {
                comment: {
                  onCreateComment: async (comment, noteCommentAnchorId) => {
                    if (note?.id) {
                      onCreatePostAnnotation({
                        postId: note.id,
                        annotation: {
                          comment,
                          noteCommentAnchorId,
                        },
                      });
                    }
                  },
                },
              },
              useEditorExtensionsProps: {
                command: {
                  allowTasks: true,
                },
              },
              ...componentsProps?.noteView?.componentsProps?.content,
            },
            ...componentsProps?.noteView?.componentsProps,
          }}
        />
      </StyledFormContainer>
      {!isMobileAppWebView && allowNextAndPrevNavigation ? (
        <>
          {renderNextBtn()}
          {renderPrevBtn()}
        </>
      ) : null}
    </StyledContentContainer>
  );
};
