import { useApolloClient } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { IconOutlineInfoCircleAlt } from 'components/icons/components/outline/IconOutlineInfoCircleAlt';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  PlotFeature,
  TrendFragmentTrendingDetailViewFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { TrendingDetailCollectionsListView } from '../CollectionsList';
import { TrendSaveToContentCalendarButton } from '../saveToContentCalendarButton';

export interface TrendingDetailActionButtonsViewProps {
  trend: TrendFragmentTrendingDetailViewFragment;
  onShowInfo: () => void;
  hideInfo: boolean;
  urlMetadataId: string;
  totalCounts: {
    trends: number;
    contentCalendar: number;
  };
}

export const TrendingDetailActionButtonsView = ({
  trend,
  hideInfo,
  onShowInfo,
  urlMetadataId,
  totalCounts,
}: TrendingDetailActionButtonsViewProps) => {
  const { isFeatureEnabled } = useFeatureFlagContext();
  const client = useApolloClient();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'flex-end',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <TrendingDetailCollectionsListView
          trend={trend}
          urlMetadataId={urlMetadataId}
        />
        <Typography variant="subhead-md" color={theme.colors?.utility[700]}>
          {totalCounts.trends} Save
          {totalCounts.trends > 0 ? 's' : ''}
        </Typography>
      </Box>
      {isFeatureEnabled(PlotFeature.ContentCalendar) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TrendSaveToContentCalendarButton
            trendId={trend.id}
            urlMetadataId={urlMetadataId}
            onAfterAddedToContentCalendar={() => {
              client.refetchQueries({
                include: ['TrendQueryForTrendingDetailView'],
              });
            }}
          />
          <Typography variant="subhead-md" color={theme.colors?.utility[700]}>
            {totalCounts.contentCalendar} Save
            {totalCounts.contentCalendar > 0 ? 's' : ''}
          </Typography>
        </Box>
      )}
      {hideInfo && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{
              padding: 2.5,
              bgcolor: ` rgba(35, 6, 3, 0.10)`,
            }}
            onClick={onShowInfo}
          >
            <IconOutlineInfoCircleAlt color={theme.colors?.primary.black} />
          </IconButton>
          <Typography variant="subhead-md" color={theme.colors?.utility[700]}>
            Info
          </Typography>
        </Box>
      )}
    </Box>
  );
};
