import { gql } from '@apollo/client';
import { USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW } from 'features/user-profile';
import {
  UserProfileFragmentUserProfilePermissionItemViewFragment,
  useGetOrganizationUsersForUsePermissionUsersSearchQuery,
} from 'graphql/generated';
import { useMemo } from 'react';
import { getFullName } from 'utils/users';
import { emailValidation } from 'utils/validations';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetOrganizationUsersForUsePermissionUsersSearch {
    me {
      id
      organization {
        id
        domain
        externalUsers {
          ...UserProfileFragmentUserProfilePermissionItemView
        }
        users {
          ...UserProfileFragmentUserProfilePermissionItemView
        }
      }
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW}
`;

type Props = {
  searchStr: string;
  existingMembers?: UserProfileFragmentUserProfilePermissionItemViewFragment[];
};

export const usePermissionUserSearch = (props: Props) => {
  const { searchStr, existingMembers = [] } = props;
  const { data: meContext } =
    useGetOrganizationUsersForUsePermissionUsersSearchQuery();

  const allUsers = [
    ...(meContext?.me?.organization?.users || []),
    ...(meContext?.me.organization.externalUsers || []),
  ];
  const suggestedUsers = useMemo(() => {
    const filteredUsers = allUsers.filter(
      (user) =>
        (user.email.toLowerCase().includes(searchStr.toLowerCase()) ||
          getFullName(user).toLowerCase().includes(searchStr.toLowerCase())) &&
        !existingMembers.find((m) => m.id === user.id), // ignore existing members
    );

    if (filteredUsers.length === 0) {
      const newUsers: typeof allUsers = [
        searchStr,
        !searchStr || searchStr.includes('@') ? '' : `${searchStr}@gmail.com`,
        !searchStr || searchStr.includes('@') ? '' : `${searchStr}@hotmail.com`,
        !searchStr || searchStr.includes('@') ? '' : `${searchStr}@yahoo.com`,
      ]
        .filter((x) => !!x)
        .map((x, index) => ({
          id: index.toString(),
          email: x,
          firstName: '',
          lastName: '',
          title: '',
          /*
           * hasSignedUp and isDisabled need to have these values
           * so that we can bypass some use cases for rendering
           */
          hasSignedUp: true,
          isDisabled: false,
          organization: {
            id:
              meContext?.me.organization.domain &&
              searchStr.includes(meContext?.me.organization.domain) &&
              meContext?.me.organization.id
                ? meContext?.me.organization.id
                : '',
          },
        }));
      return newUsers.filter((u) => emailValidation.isValidSync(u.email));
    }
    return filteredUsers.filter((u) => emailValidation.isValidSync(u.email));
  }, [searchStr, existingMembers]); // eslint-disable-line react-hooks/exhaustive-deps

  return { allUsers, suggestedUsers };
};
