// get ref from the `useTaskListWithKeyboardShortcuts`
import { gql } from '@apollo/client';
import { EditorView } from '@tiptap/pm/view';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import {
  TaskFragmentTaskListItemViewFragmentDoc,
  TaskFragmentUseTasksFieldShortcutsFragment,
  TaskPermission,
  TaskStatus,
  useUpdateTaskForUseTasksFieldShortcutsMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const TASK_FRAGMENT_USE_TASKS_FIELD_SHORTCUTS = gql`
  fragment TaskFragmentUseTasksFieldShortcuts on TaskModel {
    id
    parentTaskId
    sortOrder
    ...TaskFragmentTaskListItemView
  }
  ${TaskFragmentTaskListItemViewFragmentDoc}
`;

gql`
  mutation UpdateTaskForUseTasksFieldShortcuts($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      id
      subtasks {
        id
        ...TaskFragmentUseTasksFieldShortcuts
      }
      ...TaskFragmentUseTasksFieldShortcuts
    }
  }
  ${TASK_FRAGMENT_USE_TASKS_FIELD_SHORTCUTS}
`;

type TaskFragmentUseTasksFieldShortcutsTopLevelTaskFragment =
  TaskFragmentUseTasksFieldShortcutsFragment & {
    subtasks?: TaskFragmentUseTasksFieldShortcutsFragment[];
  };

type PendingTaskFragmentUseTasksFieldShortcutsFragment =
  TaskFragmentUseTasksFieldShortcutsFragment & {
    loading?: boolean;
  };

const SORT_ORDER_INCREMENT = 0.02;

const INFINITE_SORT_ORDER = 999999;

type Props = {
  existingTasks: TaskFragmentUseTasksFieldShortcutsTopLevelTaskFragment[];
  onAfterTaskCreated: (taskId: string) => void;
};

export const useTasksFieldShortcuts = (props: Props) => {
  const { existingTasks, onAfterTaskCreated } = props;

  const { disableCommands, enableCommands } = useCommandContext();
  useEffect(() => {
    disableCommands([COMMAND_TYPE.CREATE_TASK]);

    return () => {
      enableCommands([COMMAND_TYPE.CREATE_TASK]);
    };
  }, []);

  const [updateTask] = useUpdateTaskForUseTasksFieldShortcutsMutation();
  const debouncedUpdateTask = useMemo(
    () => debounce(updateTask, 300),
    [updateTask],
  );

  const [focusedTaskId, setFocusedTaskId] = useState<string>();
  const focusedTaskIdRef = useRef<string>();
  focusedTaskIdRef.current = focusedTaskId;

  const [pendingTasks, setPendingTasks] = useState<
    PendingTaskFragmentUseTasksFieldShortcutsFragment[]
  >([]);
  const pendingTasksRef = useRef<
    PendingTaskFragmentUseTasksFieldShortcutsFragment[]
  >([]);
  pendingTasksRef.current = pendingTasks;

  const topLevelTasks = useMemo(() => {
    return existingTasks.filter(
      (topLevelTask) =>
        !topLevelTask.parentTaskId ||
        !existingTasks.some((t) =>
          (t.subtasks || []).some((st) => st.id === topLevelTask.id),
        ),
    );
  }, [JSON.stringify({ existingTasks })]);

  const topLevelTasksRef = useRef<
    TaskFragmentUseTasksFieldShortcutsTopLevelTaskFragment[]
  >([]);
  topLevelTasksRef.current = topLevelTasks;

  const finalTasks = useMemo(() => {
    const pendingTopLevelTasks = pendingTasks.filter(
      (t) => !t.parentTaskId,
    ) as TaskFragmentUseTasksFieldShortcutsTopLevelTaskFragment[];
    const sortedTopLevelTasks = [
      ...topLevelTasks,
      ...pendingTopLevelTasks,
    ].sort((a, b) => (a.sortOrder || 9999) - (b.sortOrder || 9999));

    const final = sortedTopLevelTasks.reduce((acc, t) => {
      const subtasks = [
        ...(t.subtasks || []),
        ...pendingTasks.filter(
          (pt) =>
            pt.parentTaskId === t.id &&
            !t.subtasks?.some((st) => st.id === pt.id),
        ),
      ];
      return [...acc, t, ...subtasks];
    }, [] as TaskFragmentUseTasksFieldShortcutsFragment[]);

    return final;
  }, [JSON.stringify({ topLevelTasks, pendingTasks })]);
  const finalTasksRef = useRef<
    TaskFragmentUseTasksFieldShortcutsTopLevelTaskFragment[]
  >([]);
  finalTasksRef.current = finalTasks;

  const checkIfTaskIsStandaloneSubtask = (taskId: string) => {
    const task = finalTasksRef.current.find((t) => t.id === taskId);

    if (!task) {
      return false;
    }

    if (!task.parentTaskId) {
      return false;
    }

    const parentTask = finalTasksRef.current.find(
      (t) => t.id === task.parentTaskId,
    );

    if (!parentTask) {
      return true;
    }

    return false;
  };

  const checkIfTaskCanShiftOrUnshift = (taskId: string) => {
    const taskIndex = finalTasksRef.current.findIndex((t) => t.id === taskId);
    const isPendingTask = pendingTasksRef.current.some((t) => t.id === taskId);

    if (!isPendingTask) {
      return false;
    }

    const previousTask = finalTasksRef.current[taskIndex - 1];

    if (
      !previousTask ||
      (previousTask && checkIfTaskIsStandaloneSubtask(previousTask.id))
    ) {
      return false;
    }

    return true;
  };

  const { user } = useUserContext();
  const users = useMemo(() => {
    if (!user) {
      return [];
    }

    return [...user.organization.users, ...user.organization.externalUsers];
  }, [user]);

  const getNewTaskData = useCallback(
    (parentTaskId?: string | null) => {
      const newTask = {
        __typename: 'TaskModel',
        id: GenerateId.create(),
        name: '',
        status: TaskStatus.ToDo,
        endDate: null,
        startDate: null,
        priority: null,
        taskMembers: [],
        project: null,
        parentTaskId: parentTaskId || null,
        myPermissions: [
          TaskPermission.Delete,
          TaskPermission.Update,
          TaskPermission.Read,
        ],
        sortOrder: null,
      } as TaskFragmentUseTasksFieldShortcutsFragment;

      return newTask;
    },
    [user, users],
  );

  const createNewTask = useCallback(() => {
    let parentTaskId: string | null = null;
    let sortOrder = INFINITE_SORT_ORDER;

    if (focusedTaskIdRef.current) {
      const focusedTask = finalTasksRef.current.find(
        (t) => t.id === focusedTaskIdRef.current,
      );

      if (focusedTask) {
        if (
          focusedTask.parentTaskId &&
          !checkIfTaskIsStandaloneSubtask(focusedTask.id)
        ) {
          parentTaskId = focusedTask.parentTaskId;
        } else {
          const focusedTaskIndex = finalTasksRef.current.findIndex(
            (t) => t.id === focusedTaskIdRef.current,
          );

          const nextTopLevelTask = finalTasksRef.current
            .slice(focusedTaskIndex + 1)
            .find((t) => !t.parentTaskId || t.parentTaskId !== focusedTask.id);

          if (nextTopLevelTask) {
            sortOrder =
              ((focusedTask.sortOrder || 0) +
                (nextTopLevelTask.sortOrder || 0)) /
              2;
          } else {
            sortOrder =
              finalTasksRef.current.length === 0
                ? INFINITE_SORT_ORDER
                : (finalTasksRef.current[0].sortOrder || 0) -
                  SORT_ORDER_INCREMENT;
          }
        }
      }
    } else {
      sortOrder =
        finalTasksRef.current.length === 0
          ? INFINITE_SORT_ORDER
          : (finalTasksRef.current[finalTasksRef.current.length - 1]
              .sortOrder || 0) + SORT_ORDER_INCREMENT;
    }

    const newTask = getNewTaskData(parentTaskId);

    setPendingTasks((o) => {
      const newO = o.filter((t) => t.name || t.loading);

      return [
        ...newO,
        {
          ...newTask,
          loading: false,
          sortOrder,
        },
      ];
    });
    setFocusedTaskId(newTask.id);
  }, [getNewTaskData]);

  const shiftOrUnshiftCurrentFocusedTask = useCallback(
    (unshift?: boolean) => {
      const focusedTask = finalTasksRef.current.find(
        (t) => t.id === focusedTaskIdRef.current,
      );
      let parentTaskId: string | null = null;
      let sortOrder: number | null = focusedTask?.sortOrder || null;

      if (
        focusedTaskIdRef.current &&
        !checkIfTaskCanShiftOrUnshift(focusedTaskIdRef.current)
      ) {
        return;
      }

      if (unshift) {
        if (!focusedTask?.parentTaskId) {
          return;
        }

        parentTaskId = null;

        const focusedTaskIndexInFinalTasks = finalTasksRef.current.findIndex(
          (t) => t.id === focusedTaskIdRef.current,
        );

        const previousTopLevelTask = finalTasksRef.current
          .slice(0, focusedTaskIndexInFinalTasks)
          .reverse()
          .find((t) => !t.parentTaskId);

        const nextTopLevelTask = finalTasksRef.current
          .slice(focusedTaskIndexInFinalTasks + 1)
          .find(
            (t) =>
              !t.parentTaskId || t.parentTaskId !== focusedTaskIdRef.current,
          );

        if (previousTopLevelTask && nextTopLevelTask) {
          sortOrder =
            ((previousTopLevelTask.sortOrder || 0) +
              (nextTopLevelTask.sortOrder || 0)) /
            2;
        } else if (previousTopLevelTask) {
          sortOrder = INFINITE_SORT_ORDER;
        }
      } else {
        if (focusedTask?.parentTaskId) {
          return;
        }

        const focusedTaskIndexInFinalTasks = finalTasksRef.current.findIndex(
          (t) => t.id === focusedTaskIdRef.current,
        );

        const previousTask =
          finalTasksRef.current[focusedTaskIndexInFinalTasks - 1];

        if (previousTask) {
          parentTaskId = previousTask.parentTaskId || previousTask.id || null;
          sortOrder = INFINITE_SORT_ORDER;
        }
      }

      if (focusedTaskIdRef.current && focusedTask) {
        const pendingTask = pendingTasksRef.current.find(
          (t) => t.id === focusedTaskIdRef.current,
        );

        if (pendingTask) {
          setPendingTasks((o) =>
            o.map((t) => {
              if (t.id === focusedTaskIdRef.current) {
                return {
                  ...t,
                  parentTaskId,
                  sortOrder,
                };
              }

              return t;
            }),
          );

          if (pendingTask.loading) {
            updateTask({
              variables: {
                data: {
                  taskId: focusedTaskIdRef.current,
                  data: {
                    parentTaskId,
                    sortOrder:
                      sortOrder === INFINITE_SORT_ORDER ? null : sortOrder,
                  },
                },
              },
            });
          }
        }
      }
    },
    [updateTask],
  );

  const onTaskNameKeyDown = (view: EditorView, event: KeyboardEvent) => {
    const isMentionExtensionActive =
      view.state['mention$']?.active ||
      view.state['mention-collection$']?.active ||
      false;

    if (event.key === 'Enter') {
      if (!isMentionExtensionActive) {
        createNewTask();

        return true;
      }
    }

    if (event.key === 'Tab') {
      if (!isMentionExtensionActive) {
        shiftOrUnshiftCurrentFocusedTask(event.shiftKey);
      }

      return true;
    }

    return false;
  };

  const onPendingTaskChange = async (
    task: TaskFragmentUseTasksFieldShortcutsTopLevelTaskFragment,
  ) => {
    setPendingTasks((o) => {
      const newO = [...o];
      const taskIndex = newO.findIndex((t) => t.id === task.id);

      newO[taskIndex] = task;

      if (task.name) {
        debouncedUpdateTask({
          variables: {
            data: {
              taskId: task.id,
              data: {
                name: task.name,
                status: task.status,
                priority: task.priority,
                memberIds: task.taskMembers.map((m) => m.id),
                endDate: task.endDate,
                parentTaskId: task.parentTaskId,
                sortOrder:
                  task.sortOrder === INFINITE_SORT_ORDER
                    ? null
                    : task.sortOrder || undefined,
              },
            },
          },
          onCompleted: (data) => {
            if (!existingTasks.some((t) => t.id === data.updateTask.id)) {
              onAfterTaskCreated(data.updateTask.id);
            }
          },
        });

        newO[taskIndex].loading = true;
      }

      return newO;
    });
  };

  return {
    finalTasks,
    pendingTasks,
    focusedTaskId,
    setFocusedTaskId,
    setPendingTasks,
    createNewTask,
    onTaskNameKeyDown,
    onPendingTaskChange,
  };
};
