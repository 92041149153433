import { gql, useApolloClient } from '@apollo/client';
import { usePrevious } from '@dwarvesf/react-hooks';
import { createContext } from '@dwarvesf/react-utils';
import { PlotRoutes } from 'Routes';
import { useAuthForMobileInappBrowser } from 'features/auth';
import { BillingFreeTrialEnded } from 'features/billing';
import { useInvitationThroughLink } from 'features/invitationThroughLink';
import {
  AuthFragmentUserContextFragment,
  BillingSubscriptionStatus,
  MeFragmentUserQueriesFragment,
  OrganizationBillingFragmentUserContextFragment,
  OrganizationType,
  useGetMeQuery,
  useRefreshTokenAppMutation,
} from 'graphql/generated';
import { useUserAnalytics } from 'hooks/useAnalytics';
import { useCheckOrgDomainUtils } from 'hooks/useCheckOrgDomain';
import { jwtDecode } from 'jwt-decode';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { USER_FRAGMENT_USER_CONTEXT } from './types';

const isDev = process.env.NODE_ENV === 'development';
export const cookieOptions = {
  path: '/',
  domain: isDev ? undefined : window.origin.replace('https://', '.'),
};

// eslint-disable-next-line
gql`
  query GetMe {
    me {
      ...MeFragmentUserQueries
    }
  }
  ${USER_FRAGMENT_USER_CONTEXT}
`;

// eslint-disable-next-line
gql`
  mutation RefreshTokenApp($token: JWT!) {
    refreshToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`;

export type UserContextType = {
  user: MeFragmentUserQueriesFragment | null;
  orgBilling?: OrganizationBillingFragmentUserContextFragment;
  joinedOrgBillings?: OrganizationBillingFragmentUserContextFragment[];
  setLoginData: (data: AuthFragmentUserContextFragment | null) => void;
  refreshLogin: () => void;
  logout: () => void;
  refetchUserData: () => Promise<void>;
  isMobileAppWebView: boolean;
  isLoadingUser?: boolean;
  isEnterpriseOrganization: boolean;
  isWorkOrganization?: boolean;
  // TODO: add this to the another generic context
  isAlertBannerVisible: boolean;
  setIsAlertBannerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isLoggingOut?: boolean;
} & ReturnType<typeof useInvitationThroughLink>;

const [Provider, useUserContext, Context] = createContext<UserContextType>();

export const UserContextConsumer = Context.Consumer;

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const client = useApolloClient();

  const { isInMobileInAppBrowser } = useAuthForMobileInappBrowser();

  const [cookies, setCookie, removeCookie] = useCookies([
    'token',
    'refreshToken',
    'user',
  ]);

  const [token, setToken] = useState(
    // If user is opening the web app from mobile inapp browser,
    // DO NOT LOAD TOKEN. We don't want to auto-login user in this case.
    () => {
      if (isInMobileInAppBrowser) {
        return '';
      }

      return params.get('token') || cookies.token;
    },
  );
  const previousToken = usePrevious(token);

  const isMobileAppWebView = useMemo(() => Boolean(params.get('isMobile')), []); // eslint-disable-line
  // NOTE: This is a quick hack
  // Hide intercom in mobile inapp browser
  useEffect(() => {
    if (isMobileAppWebView) {
      // @ts-ignore
      window.Intercom?.('shutdown');
    }
  }, [isMobileAppWebView]);

  const [refreshToken] = useRefreshTokenAppMutation();
  const [hasRefreshedToken, setHasRefreshedToken] = useState(false);
  const [isAlertBannerVisible, setIsAlertBannerVisible] = useState(false);

  const {
    data: userData,
    loading,
    refetch: refetchUser,
  } = useGetMeQuery({
    skip: !token,
    fetchPolicy: 'cache-and-network',
    onError: () => {
      removeCookie('user', cookieOptions);

      // NOTE: Quick hack to remove old token for domain plot.so
      removeCookie('user', { ...cookieOptions, domain: '.plot.so' });
    },
  });
  const user = useMemo(() => {
    return userData?.me || (cookies.user ? JSON.parse(cookies.user) : null);
  }, [cookies.user, userData]);
  const isLoadingUser = loading && !user;

  useUserAnalytics(user);

  const useInvitationThroughLinkValues = useInvitationThroughLink({ user });

  // If token changes, refetch user data
  useEffect(() => {
    if (previousToken && previousToken !== token) {
      refetchUser();
    }
  }, [token]); // eslint-disable-line

  const [
    hasRedirectedIntoTheDashboardForTheFirstTime,
    setHasRedirectedIntoTheDashboardForTheFirstTime,
  ] = useState(false);

  const updateTokens = useCallback(
    (accessToken: string, refreshToken: string) => {
      setToken(accessToken);

      setCookie('token', accessToken, cookieOptions);
      setCookie('refreshToken', refreshToken, cookieOptions);
    },
    [setCookie],
  );

  useEffect(() => {
    // Get token from params in case of opening web app from mobile webview
    const mobileAppAccessToken = params.get('token');
    if (isMobileAppWebView && mobileAppAccessToken) {
      updateTokens(mobileAppAccessToken, '');
    }
  }, [params, isMobileAppWebView]); // eslint-disable-line

  const userActiveButHasEmptyTitle =
    user && user.hasVerifiedEmail && user.title === '';

  useEffect(() => {
    // If user is not pending, has verified their email,
    // but has no title (it's a required field that can only be set in onboarding flow)
    // Redirect them to the onboarding page
    if (userActiveButHasEmptyTitle && user.hasBeenApprovedByAdmin) {
      navigate(PlotRoutes.onboarding(), {
        replace: true,
      });
    }
  }, [location.pathname, userActiveButHasEmptyTitle]); // eslint-disable-line

  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const logout = React.useCallback(async () => {
    setIsLoggingOut(true);

    removeCookie('token', cookieOptions);
    removeCookie('refreshToken', cookieOptions);
    removeCookie('user', cookieOptions);

    // NOTE: Quick hack to remove old token for domain plot.so
    removeCookie('token', { ...cookieOptions, domain: '.plot.so' });
    removeCookie('refreshToken', { ...cookieOptions, domain: '.plot.so' });
    removeCookie('user', { ...cookieOptions, domain: '.plot.so' });

    localStorage.removeItem('canShowMobileView');

    // If logout url is provided, make a request to that url to
    // ensure WorkOS revoke current user's session
    if (user?.logoutUrl) {
      // Using a try-catch here to suppress errors,
      // because we are not interested in the response
      try {
        await fetch(user.logoutUrl, {
          mode: 'no-cors',
        });
      } catch {
        // Do nothing
      }
    }

    setToken('');
    setHasRedirectedIntoTheDashboardForTheFirstTime(false);

    navigate(PlotRoutes.auth(), {
      replace: true,
    });

    client.cache.reset();

    setIsLoggingOut(false);
  }, [user, client.cache, navigate, removeCookie]);

  const { isWorkDomain } = useCheckOrgDomainUtils();
  const isWorkOrganization = useMemo(() => {
    return !!(user?.email && isWorkDomain(user.email.split('@')[1]));
  }, [user?.email, isWorkDomain]);

  const setLoginData = (data: AuthFragmentUserContextFragment | null) => {
    if (!data) {
      logout();
      return;
    }

    updateTokens(data.accessToken, data.refreshToken);
  };

  /**
   * Check if token has expired and logout user if it has
   */
  const checkIfTokenHasExpired = () => {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp && decodedToken.exp * 1000 < Date.now()) {
      logout();
    }
  };

  useEffect(() => {
    (async () => {
      if (user) {
        setCookie('user', JSON.stringify(user), cookieOptions);

        // this code refresh the token everytime a user refreshes the page
        // the refreshed token is then updated in the local storage
        if (!hasRefreshedToken && cookies.refreshToken) {
          try {
            const result = await refreshToken({
              variables: {
                token: cookies.refreshToken,
              },
            });

            // update tokens in cookies
            if (result.data?.refreshToken) {
              updateTokens(
                result.data.refreshToken.accessToken,
                result.data.refreshToken.refreshToken,
              );
            }

            setHasRefreshedToken(true);
          } catch (e) {
            // If refresh token fails, check if the token has expired
            checkIfTokenHasExpired();

            return;
          }
        }

        // If there is no refresh token, check if the token has expired
        if (!cookies.refreshToken) {
          checkIfTokenHasExpired();
        }

        // If user is not pending (e.g. invitation approved by admin), but has not verified
        // their email yet, navigate them to the verify page
        if (!user.hasVerifiedEmail) {
          const emailTokenParam = params.get('emailToken');
          if (emailTokenParam) {
            const pathWithToken = `/verify?emailToken=${emailTokenParam}`;
            navigate(pathWithToken, {
              replace: true,
            });

            return;
          }

          navigate(PlotRoutes.verify(), {
            replace: true,
          });

          return;
        }

        if (!user.hasBeenApprovedByAdmin) {
          navigate(PlotRoutes.accessRequest(), {
            replace: true,
          });
        }

        if (userActiveButHasEmptyTitle) {
          return;
        }

        // Once all the pending/verification checks are passed,
        // this part will handle redirection after login
        // This should only be triggered ONCE, after user data is FIRST PROVIDED
        // which we assume is to be when user logs in
        if (!hasRedirectedIntoTheDashboardForTheFirstTime) {
          setHasRedirectedIntoTheDashboardForTheFirstTime(true);
          navigate(
            params.get('redirect') ||
              `${window.location.pathname}${window.location.search}`,
            {
              replace: true,
            },
          );
        }
      }
    })();
  }, [JSON.stringify(user)]); // eslint-disable-line -- run on user change only

  const refetchUserData = async () => {
    try {
      await refetchUser();
    } catch (error) {
      console.error('Error while refetching user data:', error);
    }
  };

  const renderFreeTrialEndedOrNewPricing = useCallback(() => {
    // only consider showing Billing Trial Wrapped when user has gone through onboarding
    const userHasGoneThroughOnboarding =
      user && user.title !== '' && user.onboardingState?.haveSeenJuiceBoxIntro;
    // if open on mobile webview => hide both 2 popups
    if (isMobileAppWebView || !userHasGoneThroughOnboarding) {
      return null;
    }

    if (
      user.organization.billing.subscription?.status ===
      BillingSubscriptionStatus.Paused
    ) {
      return (
        <BillingFreeTrialEnded
          organizationBilling={user.organization.billing}
        />
      );
    }

    return null;
  }, [isMobileAppWebView, user]);

  return (
    <Provider
      value={{
        user,
        orgBilling: user?.organization.billing,
        joinedOrgBillings: user?.joinedOrganizations
          .map((j) => j.billing)
          .filter(Boolean),
        isEnterpriseOrganization:
          user?.organization.type === OrganizationType.EnterpriseLead,
        setLoginData,
        refreshLogin: () => {},
        logout,
        refetchUserData,
        isMobileAppWebView,
        isLoadingUser,
        isWorkOrganization,
        isAlertBannerVisible,
        setIsAlertBannerVisible,
        isLoggingOut,
        ...useInvitationThroughLinkValues,
      }}
    >
      {!isLoadingUser && children}
      {renderFreeTrialEndedOrNewPricing()}
    </Provider>
  );
};

export { useUserContext };
