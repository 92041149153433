import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Dialog } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  CommandHandler,
  CommandHandlerBaseContext,
} from 'contexts/commands/types';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import {
  CollectionCreateForm,
  CollectionCreateFormInitialValues,
  CollectionCreateFormValues,
  useCheckIfCustomCollection,
} from 'features/collection';
import {
  CollectionCreationSource,
  CollectionFragmentCollectionNameFragmentDoc,
  useCreateCollectionForCollectionCommandMutation,
  useGetCollectionForCreateCollectionDialogHandlerQuery,
} from 'graphql/generated';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { Parser } from 'utils/parser';
import { COLLECTION_FRAGMENT_COLLECTION_COMMAND } from '../types';

// eslint-disable-next-line
gql`
  mutation CreateCollectionForCollectionCommand($data: CreateCollectionInput!) {
    createCollection(data: $data) {
      id
      ...CollectionFragmentCollectionCommand
    }
  }
  ${COLLECTION_FRAGMENT_COLLECTION_COMMAND}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetCollectionForCreateCollectionDialogHandler($collectionId: String!) {
    collection(id: $collectionId) {
      id
      ...CollectionFragmentCollectionName
    }
  }
  ${CollectionFragmentCollectionNameFragmentDoc}
`;

export type CreateCollectionHandlerContext = {
  initialValues?: CollectionCreateFormInitialValues;
  movePostsFromParent?: boolean;
  onSelectExistingCollection?: (collectionId: string) => void;
} & CommandHandlerBaseContext<COMMAND_TYPE.CREATE_COLLECTION>;

export const CreateCollectionHandler: CommandHandler<
  COMMAND_TYPE.CREATE_COLLECTION
> = (props) => {
  const { orgBilling } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    commandId,
    context: {
      initialValues,
      movePostsFromParent,
      onSelectExistingCollection,
    } = {},
  } = props;

  const currentCollectionId =
    Parser.getCollectionIdFromUrl(location.pathname) || '';
  const isCustomCollection = useCheckIfCustomCollection(currentCollectionId);
  const { data: collectionData } =
    useGetCollectionForCreateCollectionDialogHandlerQuery({
      variables: {
        collectionId: currentCollectionId || '',
      },
      skip: !currentCollectionId || isCustomCollection,
    });

  const { getActiveCommandById, updateActiveCommand } = useCommandContext();
  const command = getActiveCommandById(commandId);

  const {
    isOpen: isCreateCollectionDialogOpen,
    onOpen: openCreateCollectionDialog,
    onClose: closeCreateCollectionDialog,
  } = useDisclosure();

  const [createCollection] = useCreateCollectionForCollectionCommandMutation();

  const onSubmit = async (values: CollectionCreateFormValues) => {
    try {
      const response = await createCollection({
        variables: {
          data: {
            name: values.name,
            parentCollectionId: values.parentCollectionId,
            postIds: values.postIds,
            movePostsFromParent,
            source: CollectionCreationSource.Web,
          },
        },
        update: (cache) => {
          if (orgBilling) {
            modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
              collectionUsageCount: (existingFieldValues) =>
                existingFieldValues + 1,
            });
          }
        },
      });

      const collection = response.data?.createCollection;

      if (collection) {
        updateActiveCommand(commandId, {
          status: 'completed',
          data: collection,
        });
        if (!initialValues) {
          navigate(PlotRoutes.collection(collection.id));
        }
      }

      closeCreateCollectionDialog();
    } catch (error) {
      console.log('ERROR HERE:: ', error);
      updateActiveCommand(commandId, {
        status: 'error',
      });
    }
  };

  useEffect(() => {
    if (command?.status !== 'completed') {
      openCreateCollectionDialog();
    }
  }, [command]); // eslint-disable-line -- triger flow on mount

  if (!isCreateCollectionDialogOpen) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={() => {
        closeCreateCollectionDialog();
      }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 450,
          borderRadius: 6,
          border: 'none',
          [theme.breakpoints.down('sm')]: {
            p: 6,
          },
        },
      }}
    >
      <Box p={16}>
        <CollectionCreateForm
          initialValues={
            initialValues ||
            (collectionData?.collection
              ? {
                  name: '',
                  parentCollection: collectionData.collection,
                  posts: [],
                }
              : undefined)
          }
          onSubmit={onSubmit}
          onSelectExistingCollection={(collectionId) => {
            if (onSelectExistingCollection) {
              onSelectExistingCollection(collectionId);
            } else {
              window.open(PlotRoutes.collection(collectionId), '_blank');
            }

            closeCreateCollectionDialog();
          }}
        />
      </Box>

      {orgBilling && (
        <BillingCollectionLimitIndicator
          variant="compact"
          organizationBilling={orgBilling}
          sx={{ textAlign: 'center' }}
        />
      )}
    </Dialog>
  );
};
