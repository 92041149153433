export const YOUTUBE_DOMAIN_REGEX: RegExp = /^https:\/\/www\.youtube\.com\//;
export const YOUTUBE_EXTRACT_ID_REGEX: RegExp =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

export const VIMEO_REGEX: RegExp = /https:\/\/vimeo\.com\/(\d+)/;
export const VIMEO_DOMAIN_REGEX: RegExp = /^https:\/\/vimeo\.com\//;

export const LOOM_DOMAIN_REGEX: RegExp = /^https:\/\/www\.loom\.com\//;
export const LOOM_REGEX: RegExp = /https:\/\/www\.loom\.com\/share\/(\w+)/;

export const FIGMA_URL_REGEX: RegExp =
  /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;
export const PARAMS_REGEX = /(?:\?|&)([^&=]*)=?([^&]*)/g;
