import { Box, Checkbox, Typography } from '@mui/material';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import { theme } from 'styles/theme';

interface Props {
  Icon:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement> & { size?: number }>
    | string;
  text: string;
  hasNestedFilter?: boolean;
  isChecked?: boolean;
  disabled?: boolean;
  selectedNestedFilterCount?: number;
}

export const JuiceboxFilterOption = ({
  Icon,
  text,
  hasNestedFilter,
  isChecked,
  disabled,
  selectedNestedFilterCount = 0,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Checkbox
          disabled={disabled}
          checked={isChecked}
          sx={{
            padding: 0,
            '& svg': {
              width: '16px',
              height: '16px',
            },
          }}
        />
        <Box
          sx={{
            borderRadius: 1,
            backgroundColor: theme.colors?.utility[400],
            width: 24,
            height: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {typeof Icon === 'string' ? (
            <Typography
              variant="subhead-sm"
              color={theme.colors?.primary.black}
            >
              {Icon}
            </Typography>
          ) : (
            <Icon size={16} color={theme.colors?.primary.black} />
          )}
        </Box>
        <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
          {text}
          {selectedNestedFilterCount > 0 && (
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              ({selectedNestedFilterCount})
            </Typography>
          )}
        </Typography>
      </Box>
      {hasNestedFilter && (
        <IconLinearArrowRight1 size={16} color={theme.colors?.primary.black} />
      )}
    </Box>
  );
};
