import { gql } from '@apollo/client';
import { useGetPostForPostPermissionsQuery } from 'graphql/generated';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetPostForPostPermissions($postId: String!) {
    post(id: $postId) {
      id
      myPermissions
    }
  }
`;

type Props = {
  postId?: string;
};

export const usePostPermissions = (props: Props) => {
  const { postId = '' } = props;

  const { data } = useGetPostForPostPermissionsQuery({
    variables: {
      postId,
    },
    skip: !postId,
    // Suppressing the top level toast message because this hook is mainly used for
    // internal logic & user should not be notified about the permission check
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });

  return {
    permissions: data?.post?.myPermissions || [],
  };
};
