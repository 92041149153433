import { SearchHandler } from './SearchHandler';
import { COMMAND_KEYS, COMMAND_TYPE } from '../../constants';
import { Command } from '../../types';

export const useSearchCommands = (): Command[] => {
  return [
    {
      type: COMMAND_TYPE.SEARCH,
      keys: COMMAND_KEYS[COMMAND_TYPE.SEARCH],
      Handler: SearchHandler,
    },
  ];
};
