import { gql } from '@apollo/client';
import { Box, Skeleton, SxProps } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import { useFallbackMetadata } from 'components/common/Iframely/hooks/useFallbackMetadata';
import { Image } from 'components/common/Image';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { useUserContext } from 'contexts/users/User.context';
import { PostPreview } from 'features/post';
import {
  PostFragmentPostPreviewFragmentDoc,
  TrendCategory,
  TrendFragmentTrendingPreviewFragment,
  UrlMetadataFragmentIframelyFragmentDoc,
} from 'graphql/generated';
import { ResourceUploadType } from 'hooks/useResourceUploadQueue';
import { useResolveResourcePreview } from 'hooks/useResourceUploadQueue/useResolveResourcePreview';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';

export const TREND_FRAGMENT_TRENDING_PREVIEW = gql`
  fragment TrendFragmentTrendingPreview on TrendModel {
    id
    category
    previewPost {
      id
      ...PostFragmentPostPreview
    }
    previewThumbnail
    previewUrlMetadata {
      id
      ...URLMetadataFragmentIframely
    }
  }
  ${UrlMetadataFragmentIframelyFragmentDoc}
  ${PostFragmentPostPreviewFragmentDoc}
`;

export type TrendingPreviewProps = {
  trend: TrendFragmentTrendingPreviewFragment;
  sx?: SxProps;
  disableMouseEvents?: boolean;
};

export const TrendingPreview = (props: TrendingPreviewProps) => {
  const { trend, sx, disableMouseEvents } = props;

  const { orgBilling } = useUserContext();

  const _urlMetadata = trend.previewUrlMetadata || undefined;

  const { fallbackUrlMetadata, shouldUseFallbackUrlMetadata } =
    useFallbackMetadata({
      url: _urlMetadata ? _urlMetadata.url : '',
      urlMetadata: _urlMetadata,
    });

  const urlMetadata = useMemo(() => {
    if (shouldUseFallbackUrlMetadata) {
      return fallbackUrlMetadata;
    }

    return _urlMetadata;
  }, [shouldUseFallbackUrlMetadata, fallbackUrlMetadata, _urlMetadata]);

  const thumbnail = useMemo(() => {
    const thumbnails = urlMetadata?.metadata?.thumbnail || [];
    return thumbnails.reduce((prev, current) => {
      if (
        prev.mediaSize &&
        current.mediaSize?.width &&
        prev.mediaSize.width < current.mediaSize.width
      ) {
        return current;
      }
      return prev;
    }, thumbnails[0]);
  }, [urlMetadata]);
  const thumbnailUrl = thumbnail?.href || '';
  const mimeType = urlMetadata?.metadata?.type || '';

  const {
    isLoading: isLoadingPreview,
    isImagePreview,
    previewUrl,
  } = useResolveResourcePreview({
    iconSize: 64,
    type: ResourceUploadType.Attachment,
    content: urlMetadata?.url || '',
    name: '',
    mimeType,
    skip: Boolean(thumbnailUrl),
  });

  const finalThumbnailUrl = thumbnailUrl || previewUrl;
  const isShowingCustomPreviewIcon =
    !thumbnailUrl && !!previewUrl && !isImagePreview;

  const renderCardContent = () => {
    // If the trend is a music and has a linked post, render the first post preview from the linked posts
    if (trend.category === TrendCategory.Music) {
      if (trend.previewThumbnail) {
        return (
          <Image
            src={trend.previewThumbnail}
            alt={trend.category}
            sx={{
              width: '100%',
              m: 'auto',
              bgcolor: theme.colors?.primary.parchment,
              outline: 'none',
              border: 'none',
              objectFit: 'cover',
              display: 'block',
              height: '100%',
              minHeight: 'inherit',
            }}
          />
        );
      }

      if (trend.previewPost) {
        return <PostPreview post={trend.previewPost} />;
      }
    }

    if (isLoadingPreview) {
      return (
        <Skeleton
          sx={{
            width: '100%',
            height: '100%',
            minHeight: 'inherit',
            bgcolor: theme.colors?.utility[500],
            transform: 'scale(1)',
          }}
        />
      );
    }

    // Skip PDF icon as thumbnail. We have custom logic to render it
    if (finalThumbnailUrl && finalThumbnailUrl !== '/file-icons/PDF.svg') {
      return (
        <Image
          src={finalThumbnailUrl}
          alt={trend.category}
          sx={{
            width: isShowingCustomPreviewIcon ? '64px' : '100%',
            m: 'auto',
            bgcolor: theme.colors?.primary.parchment,
            outline: 'none',
            border: 'none',
            objectFit: isShowingCustomPreviewIcon ? 'contain' : 'cover',
            display: 'block',
            height: '100%',
            minHeight: 'inherit',
          }}
        />
      );
    }

    return <Iframely urlMetadata={urlMetadata} url={urlMetadata?.url || ''} />;
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        minHeight: 'inherit',
        overflow: 'hidden',
        backgroundColor: theme.colors?.primary.parchment,
        position: 'relative',
        borderRadius: 'inherit',
        ...sx,
        pointerEvents: disableMouseEvents ? 'none' : 'auto',
      }}
    >
      {disableMouseEvents && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            minHeight: 'inherit',
          }}
        />
      )}
      <Box
        style={{
          height: '100%',
          minHeight: 'inherit',
          display: 'flex',
          position: 'relative',
        }}
      >
        {!orgBilling?.trendsEnabled && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backdropFilter: 'blur(4px)',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
          >
            <IconBoldLock size={40} color={theme.colors?.primary.white} />
          </Box>
        )}
        {renderCardContent()}
      </Box>
    </Box>
  );
};
