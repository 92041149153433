import { Box, Typography } from '@mui/material';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { TabSelector } from 'features/socialMediaListening';
import { Platform } from 'graphql/generated';
import React, { useEffect } from 'react';
import { theme } from 'styles/theme';

type Props = {
  defaultPlatform?: Platform;
  onPlatformSelect: (platform: Platform) => void;
  hiddenPlatforms?: Platform[];
};

const Platforms = [Platform.Tiktok, Platform.Instagram];

export const BrandCompetitorsPlatformSelector = ({
  defaultPlatform = Platform.Tiktok,
  onPlatformSelect,
  hiddenPlatforms = [],
}: Props) => {
  const [currentPlatform, setCurrentPlatform] = React.useState(defaultPlatform);

  useEffect(() => {
    onPlatformSelect(currentPlatform);
  }, [currentPlatform]);

  const visiblePlatforms = Platforms.filter(
    (platform) => !hiddenPlatforms.includes(platform),
  );

  return (
    <TabSelector
      componentProps={{
        sx: {
          borderRadius: theme.spacing(8),
          p: theme.spacing(1),
          height: theme.spacing(10),
          '& .tab-select-btn': {
            borderRadius: theme.spacing(8),
            p: theme.spacing(2, 4),
          },
          '& .tab-select-btn.selected': {
            color: theme.colors?.primary.parchment,
          },
        },
      }}
      selectedTabIndex={visiblePlatforms.indexOf(currentPlatform)}
      options={visiblePlatforms.map((platform) => (
        <Box display="flex" gap={2} alignItems="center" key={platform}>
          {platform === Platform.Tiktok && <IconCustomTiktok size={16} />}
          {platform === Platform.Instagram && <IconBoldInstagram size={16} />}
          <Typography variant="body-lg">{platform}</Typography>
        </Box>
      ))}
      onTabSelect={(index) => {
        setCurrentPlatform(visiblePlatforms[index]);
      }}
    />
  );
};
