import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Popover, SxProps, Typography } from '@mui/material';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { SortOrder } from 'graphql/generated';
import { useRef } from 'react';

export type SocialMediaListeningSortByEngagementButtonProps = {
  selectedSortOrder: SortOrder;
  onChange: (sortOrder: SortOrder) => void;
  componentProps?: {
    sx?: SxProps;
  };
};

export const SocialMediaListeningSortByEngagementButton = (
  props: SocialMediaListeningSortByEngagementButtonProps,
) => {
  const { selectedSortOrder, onChange, componentProps } = props;

  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  return (
    <>
      <Button
        ref={anchorElRef}
        variant="tertiary"
        startIcon={<IconLinearSort size={16} />}
        sx={{
          borderRadius: 2,
          ...componentProps?.sx,
        }}
        onClick={openPopover}
      >
        <Typography variant="subhead-xl">Sort by</Typography>
      </Button>
      <Popover
        open={isPopoverOpen}
        onClose={closePopover}
        anchorEl={anchorElRef.current}
        PaperProps={{
          sx: {
            mt: 2,
            p: 6,
            width: 270,
            display: 'flex',
            flexDirection: 'column',
            gap: 6,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography variant="headline-xs">Engagement</Typography>

        {[
          {
            label: 'High to Low',
            value: SortOrder.Desc,
          },
          {
            label: 'Low to High',
            value: SortOrder.Asc,
          },
        ].map(({ label, value }) => {
          const checked = selectedSortOrder === value;

          return (
            <RadioMenuItem
              key={value}
              value={value}
              checked={checked}
              onClick={() => {
                onChange(value);
              }}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subhead-lg">{label}</Typography>
                </Box>
              }
            />
          );
        })}
      </Popover>
    </>
  );
};
