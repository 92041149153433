import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu';
import { IconBoldCalendar1 } from 'components/icons/components/bold/IconBoldCalendar1';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearAddCircle } from 'components/icons/components/linear/IconLinearAddCircle';
import { CollectionMenuItemViewProps } from 'features/collection';
import { CollectionAutocompleteMenu } from 'features/collection/views/menuItem/CollectionAutocompleteMenu';
import {
  CollectionFilterType,
  CollectionFragmentCollectionMenuItemViewFragmentDoc,
  CollectionFragmentContentCalendarCollectionMenuItemFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY =
  'content-calendar';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const COLLECTION_FRAGMENT_CONTENT_CALENDAR_COLLECTION_MENU_ITEM = gql`
  fragment CollectionFragmentContentCalendarCollectionMenuItem on CollectionModel {
    id
    ...CollectionFragmentCollectionMenuItemView
  }
  ${CollectionFragmentCollectionMenuItemViewFragmentDoc}
`;

export type ContentCalendarCollectionMenuItemProps = Omit<
  CollectionMenuItemViewProps,
  'collection' | 'onClick'
> & {
  allowSelect?: boolean;
  onClick?: (
    collection?: CollectionFragmentContentCalendarCollectionMenuItemFragment,
  ) => void;
};

export const ContentCalendarCollectionMenuItem = (
  props: ContentCalendarCollectionMenuItemProps,
) => {
  const {
    selectedCollectionIds = [],
    onClick,
    selectIconPosition = 'end',
    hideSelectIcon,
    renderSelectIcon,
    componentProps,
    allowSelect = false,
  } = props;

  // Content Calendar is a special collection. It doesn't exist in the db.
  // It's only "selected" & selectable when we are in certain flows (e.g. selecting a root collection for a content idea)
  const isSelected = allowSelect && selectedCollectionIds.length === 0;

  const handleRenderSelectIcon = () => {
    return hideSelectIcon ? null : renderSelectIcon ? (
      renderSelectIcon(isSelected)
    ) : isSelected ? (
      <IconBoldTickCircle size={16} />
    ) : (
      <IconLinearAddCircle size={16} />
    );
  };

  return (
    <MoreMenuItem
      disableRipple
      label={
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 1.5,
              bgcolor: theme.colors?.primary.black,
              borderRadius: 1,
            }}
          >
            <IconBoldCalendar1
              color={theme.colors?.primary.parchment}
              style={{ flexShrink: 0 }}
            />
          </Box>
          <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
            Content Calendar
          </Typography>
        </Box>
      }
      onClick={() => {
        onClick?.(undefined);
      }}
      MenuProps={{
        sx: { ...componentProps?.menu?.sx },
      }}
      sx={{
        minWidth: 270,
        height: 56,
        borderRadius: theme.spacing(3),
        justifyContent: 'space-between',
        ...componentProps?.menuItems?.sx,
      }}
      {...(allowSelect
        ? {
            startIcon:
              selectIconPosition === 'start' && handleRenderSelectIcon(),
            endIcon: selectIconPosition === 'end' && handleRenderSelectIcon(),
          }
        : {})}
      hideMenu={allowSelect}
    >
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <CollectionAutocompleteMenu
          {...props}
          filters={{
            postIds: [],
            filterType: CollectionFilterType.ContentCalendar,
          }}
          // TODO: call mutation for create content idea with collection
          onChange={(event, value, reason, details) => {
            const { option } = details || {};
          }}
          noOptionsText={
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[600]}
                textAlign="center"
              >
                No collections found
              </Typography>
            </Box>
          }
          // TODO: remove this when we implement the create content idea with collection
          filterOptions={undefined}
        />
      </Box>
    </MoreMenuItem>
  );
};
