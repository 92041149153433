/**
 * This component is a custom (horizontal) stacked bar chart,
 * built using regular HTML elements (even though we have recharts).
 *
 * Right now it's mainly used in SL flows, such as displaying
 * parsed hours by brand/topic.
 */

import { Box, SxProps } from '@mui/material';
import { theme } from 'styles/theme';
import { Tooltip } from '../Tooltip';

// TODO: Move this to a constant?
// This is a set of commonly used color palettes in the app,
// but I'll keep them here for now. Used to cycle through colors of the bars.
export const colors = [
  theme.colors?.utility['purple-2'],
  theme.colors?.utility['blue-4'],
  theme.colors?.utility['teal-2'],
  theme.colors?.utility['green-2'],
  theme.colors?.utility['yellow-2'],
  theme.colors?.utility['orange-2'],
  theme.colors?.utility['pink-2'],
];

export type CustomStackedBarChartDataPoint<T> = {
  item?: T;
  label: string;
  value: number;
  color?: string;
};

export type CustomStackedBarChartProps<T> = {
  data: CustomStackedBarChartDataPoint<T>[];
  sx?: SxProps;
  highlightedIndex?: number;
  renderTooltipContent?: (
    data: CustomStackedBarChartDataPoint<T>,
  ) => React.ReactNode;
};

export const CustomStackedBarChart = <T,>(
  props: CustomStackedBarChartProps<T>,
) => {
  const { data, sx, highlightedIndex, renderTooltipContent } = props;

  const total = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <Box
      sx={{
        borderRadius: 1,
        overflow: 'hidden',
        height: 18,
        width: '100%',
        display: 'flex',
        backgroundColor: theme.colors?.utility[300],
        ...sx,
      }}
    >
      {data.map((i, index) => {
        const backgroundColor = i.color ?? colors[index % colors.length];
        const percentage = i.value / total;

        return (
          <Box
            key={index}
            sx={{
              height: '100%',
              width: `${percentage * 100}%`,
              backgroundColor,
              // Fade out the bar if highlightedIndex is set and it's not the current bar
              ...(highlightedIndex !== undefined &&
                highlightedIndex !== index && {
                  opacity: 0.3,
                }),
            }}
          >
            <Tooltip
              title={
                renderTooltipContent
                  ? renderTooltipContent(i) ?? i.label
                  : i.label
              }
            >
              <Box height="100%" width="100%" />
            </Tooltip>
          </Box>
        );
      })}
    </Box>
  );
};
