import { gql } from '@apollo/client';
import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import {
  useCreateSocialPostReportForSocialListeningReportPostViewMutation,
  useGetExistingSocialPostReportReasonsForSocialListeningReportPostViewQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetExistingSocialPostReportReasonsForSocialListeningReportPostView(
    $brandId: String!
  ) {
    existingSocialPostReportReasons(brandId: $brandId)
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateSocialPostReportForSocialListeningReportPostView(
    $data: CreateSocialPostReportInput!
  ) {
    createSocialPostReport(data: $data) {
      id
    }
  }
`;

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  postId: string;
  brandId: string;
  handleOnSubmit?: VoidFunction;
};

export const SocialListeningReportPostView = ({
  isOpen,
  onClose,
  postId,
  brandId,
  handleOnSubmit,
}: Props) => {
  const [createSocialPostReport] =
    useCreateSocialPostReportForSocialListeningReportPostViewMutation();
  const {
    data: existingReasonsData,
    loading: existingReasonsLoading,
    refetch: refetchExistingReasons,
  } = useGetExistingSocialPostReportReasonsForSocialListeningReportPostViewQuery(
    {
      variables: {
        brandId,
      },
    },
  );
  const previousReasons =
    existingReasonsData?.existingSocialPostReportReasons || [];

  const [reason, setReason] = useState('');

  const handleSubmit = async () => {
    await createSocialPostReport({
      variables: {
        data: {
          brandId,
          socialPostId: postId,
          reason,
        },
      },
      update: (cache) => {
        evictObject(cache, postId, 'SocialPostModel');
      },
    });
    setReason('');
    await refetchExistingReasons();
    handleOnSubmit?.();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 500,
          borderRadius: theme.spacing(6),
          p: 0,
          backgroundColor: '#FAF3ECEE',
        },
      }}
    >
      <Box
        sx={{
          p: 6,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography
          variant="headline-lg"
          fontSize={theme.spacing(12)}
          fontWeight={400}
        >
          Help us improve
        </Typography>
        <Typography variant="headline-md" fontWeight={400}>
          Let us know what’s inaccurate about this post so we can improve your
          feed.
        </Typography>
        <TextField
          variant="outlined"
          value={reason}
          autoFocus
          onChange={(e) => setReason(e.target.value)}
          placeholder="What’s wrong with the post? "
          sx={{
            my: theme.spacing(4),
            width: '100%',
            borderRadius: theme.spacing(5),
            backgroundColor: '#23060312',
            '& .MuiOutlinedInput-root': {
              borderRadius: `${theme.spacing(5)} !important`,
              border: 'none',
            },
            '& .MuiInputBase-input': {
              ...typography['headline-sm'],
              borderRadius: `${theme.spacing(5)} !important`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        {!!previousReasons.length && (
          <Box
            sx={{
              pt: 2,
              borderTop: `1px solid ${theme.colors?.utility[400]}`,
            }}
          >
            <Typography variant="headline-sm" px={2}>
              or select from previous feedbacks
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 2,
                maxHeight: 200,
                overflowY: 'auto',
              }}
            >
              {previousReasons.map((feedback, index) => (
                <Typography
                  key={index}
                  onClick={() => setReason(feedback)}
                  variant="headline-sm"
                  sx={{
                    cursor: 'pointer',
                    p: theme.spacing(3, 4),
                    mt: 2,
                    borderRadius: theme.spacing(5),
                    backgroundColor:
                      reason !== feedback
                        ? '#23060312'
                        : theme.colors?.primary.black,
                    color:
                      reason !== feedback
                        ? theme.colors?.utility[900]
                        : theme.colors?.primary.white,
                  }}
                >
                  {feedback}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          p: 6,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
          backgroundColor: '#23060312',
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="submit"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!reason}
          sx={{
            backgroundColor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
            border: 'none',
            '&:hover': {
              backgroundColor: theme.colors?.primary.black,
              color: theme.colors?.primary.white,
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Dialog>
  );
};
