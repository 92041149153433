import { gql } from '@apollo/client';
import { Box, SxProps, TableCell, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconCustomPin } from 'components/icons/components/custom/IconCustomPin';
import { isPostPinnedToCollection } from 'features/post';
import {
  PostFragmentJuiceboxTablePostTableRowCellNameFragment,
  PostFragmentPostPinFragmentDoc,
  PostFragmentPostTitleFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment PostFragmentJuiceboxTablePostTableRowCellName on PostModel {
    id
    ...PostFragmentPostTitle
    ...PostFragmentPostPin
  }
  ${PostFragmentPostTitleFragmentDoc}
  ${PostFragmentPostPinFragmentDoc}
`;

export type JuiceboxTablePostTableRowCellNameProps = {
  post: PostFragmentJuiceboxTablePostTableRowCellNameFragment;
  collectionId?: string;
  sx?: SxProps;
};

export const JuiceboxTablePostTableRowCellName = (
  props: JuiceboxTablePostTableRowCellNameProps,
) => {
  const { post, collectionId = '', sx = {} } = props;

  const isPostPinToCollection = isPostPinnedToCollection(post, collectionId);

  return (
    <TableCell sx={{ ...sx }}>
      <Box
        sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
      >
        {isPostPinToCollection && (
          <Tooltip title="Pinned Post">
            <Box width={16} height={16}>
              <IconCustomPin size={16} />
            </Box>
          </Tooltip>
        )}

        <Typography
          variant="headline-sm"
          sx={{
            height: theme.spacing(6),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {post.title ||
            (post.urlMetadata.length > 0
              ? post.urlMetadata[0].url
              : 'No title')}
        </Typography>
      </Box>
    </TableCell>
  );
};
