import { gql } from '@apollo/client';
import {
  TaskFragmentTaskCalendarEventViewFragment,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';

export const TASK_FRAGMENT_TASK_CALENDAR_EVENT_VIEW = gql`
  fragment TaskFragmentTaskCalendarEventView on TaskModel {
    id
    name
    startDate
    endDate
    status
    priority
    taskMembers {
      ...UserFragmentAvatarGroup
    }
    parentTask {
      id
      name
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export interface TaskCustomEvent extends Event {
  task: TaskFragmentTaskCalendarEventViewFragment;
  isLoading?: boolean;
  new?: boolean;
}
