import { useDisclosure } from '@dwarvesf/react-hooks';
import { noop } from '@dwarvesf/react-utils';
import { Box, ClickAwayListener, styled } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme/theme';

const StyledContainer = styled(Box)<{
  isEditing?: boolean;
  readOnly?: boolean;
}>`
  &:hover {
    background-color: ${(props) =>
      props.isEditing && !props.readOnly
        ? 'transparent'
        : theme.colors?.utility[300]};
  }
`;

interface Props {
  readOnly?: boolean;
  renderReadOnly: () => React.ReactNode;
  renderEdit: () => React.ReactNode;
  onStopEditing?: () => void;
}

export const EditableSection = (props: Props) => {
  const { readOnly, renderReadOnly, renderEdit, onStopEditing = noop } = props;

  const { isOpen: isEditing, onOpen: onEdit, onClose } = useDisclosure();

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isEditing) {
          onStopEditing();
        }

        onClose();
      }}
      mouseEvent="onMouseDown"
    >
      <StyledContainer
        isEditing={isEditing}
        readOnly={readOnly}
        onClick={(e) =>
          !e.defaultPrevented && !isEditing && !readOnly ? onEdit() : null
        }
      >
        {isEditing ? renderEdit() : renderReadOnly()}
      </StyledContainer>
    </ClickAwayListener>
  );
};
