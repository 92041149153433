import { Box, SxProps } from '@mui/material';
import moment, { Moment } from 'moment';
import { Tooltip } from '../Tooltip';
import { getShortHumanizedTimestamp } from './utils';

export type TimestampProps = {
  date: Moment;
  humanized?: boolean;
  humanizedVariant?: 'short' | 'long';
  format?: string;
  sx?: SxProps;
  children?: React.ReactNode;
};

export const Timestamp = (props: TimestampProps) => {
  const {
    date,
    humanized = true,
    humanizedVariant = 'long',
    format,
    sx,
    children,
  } = props;

  const internalDate = moment.isMoment(date) ? date : moment(date);

  return (
    <Tooltip title={internalDate.format('YYYY-MM-DD HH:mm')} placement="top">
      {(children as any) || (
        <Box component="time" sx={sx}>
          {humanized
            ? humanizedVariant === 'long'
              ? internalDate.fromNow()
              : getShortHumanizedTimestamp(internalDate)
            : internalDate.format(format)}
        </Box>
      )}
    </Tooltip>
  );
};
