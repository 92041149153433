export class Parser {
  static toCurrency(number: number, locale: string = 'en-US'): string {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(number);
  }

  static to2Decimal(num: number): number {
    return Number(num.toFixed(2));
  }

  static toMb(sizeInBytes: number): number {
    return sizeInBytes / 1_000_000;
  }

  static toGB(sizeInMb: number): number {
    return sizeInMb / 1_000;
  }

  static getCollectionIdFromUrl(url: string): string | null {
    const regex = /\/collections\/(.*)/g;
    const match = regex.exec(url);
    return match && match[1] ? match[1] : null;
  }
}
