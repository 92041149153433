import { gql } from '@apollo/client';
import { useClipboard } from '@dwarvesf/react-hooks';
import { Avatar, Box, IconButton, Skeleton, Typography } from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { toast } from 'components/common/Toast';
import { IconBoldMusic } from 'components/icons/components/bold/IconBoldMusic';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineCopy } from 'components/icons/components/outline/IconOutlineCopy';
import {
  Platform,
  TrendAudioFragmentTrendingSocialMediaPreviewFragment,
  TrendFragmentTrendingDetailViewFragment,
} from 'graphql/generated';
import { forwardRef, useEffect, useState } from 'react';
import { theme } from 'styles/theme';

export const TREND_AUDIO_FRAGMENT_TRENDING_SOCIAL_MEDIA_PREVIEW = gql`
  fragment TrendAudioFragmentTrendingSocialMediaPreview on TrendAudioModel {
    id
    coverUrl
    platform
    title
    urlMetadata {
      id
      url
    }
  }
`;

interface Props {
  trendAudio?: TrendAudioFragmentTrendingSocialMediaPreviewFragment;
  trendExample: TrendFragmentTrendingDetailViewFragment['trendExamples'][0];
  onVideoClick?: VoidFunction;
}

export const TrendingSocialMediaPreview = forwardRef(
  ({ trendAudio, trendExample, onVideoClick }: Props, ref: any) => {
    const videoRef = ref;
    const { onCopy } = useClipboard(
      trendAudio?.urlMetadata.url ?? trendExample.urlMetadata.url ?? '',
    );
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);
    const currentMedia =
      trendExample.urlMetadata.metadata?.medias?.[currentVisibleIndex];

    const [isLoading, setIsLoading] = useState(
      trendExample.urlMetadata.metadata?.medias?.length === 1,
    );

    const onCopyLink = () => {
      onCopy();
      toast({
        message: 'Link Copied',
        icon: <IconBoldTickCircle color={theme.colors?.primary.parchment} />,
        shouldShowCloseButton: false,
        position: 'bottom-center',
        sx: {
          bgcolor: theme.colors?.primary.black,
          padding: theme.spacing(1.5, 3, 1),
          borderRadius: 25,
          span: {
            color: theme.colors?.primary.parchment,
            ...theme.typography['headline-sm'],
          },
        },
      });
    };

    useEffect(() => {
      if (!videoRef || !videoRef.current) return;

      const video = videoRef.current;

      const handleTimeUpdate = () => {
        setIsVideoPlaying(
          !video.paused && video.currentTime > 0 && !video.ended,
        );
      };

      const handleVideoEnded = () => {
        setIsVideoPlaying(false);
      };

      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('ended', handleVideoEnded);

      video.addEventListener('loadedmetadata', () => {
        setIsLoading(false);
      });

      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('ended', handleVideoEnded);
      };
    }, [videoRef]);

    if (!currentMedia) return null;

    const Icon =
      trendAudio?.platform === Platform.Tiktok ||
      trendExample.urlMetadata.url.includes('tiktok')
        ? IconCustomTiktok
        : IconCustomInstagram;

    const handleVideoClick = () => {
      if (videoRef.current?.paused) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
      onVideoClick?.();
    };

    const height = {
      xs: window.innerHeight / (trendAudio && trendAudio?.coverUrl ? 2 : 1.5),
      sm: window.innerHeight / (trendAudio && trendAudio?.coverUrl ? 2 : 1.5),
      md: window.innerHeight / (trendAudio && trendAudio?.coverUrl ? 2 : 1.5),
      lg: window.innerHeight / 1.5,
      xl: window.innerHeight / 1.5,
    };

    return (
      <Box position="relative">
        {/* control when there are multiple media elements */}
        {(trendExample.urlMetadata.metadata?.medias || []).length > 1 && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              left: '50%',
              transform: 'translateX(-50%)',
              bottom:
                trendExample.urlMetadata.metadata?.medias?.[currentVisibleIndex]
                  .type === 'video'
                  ? theme.spacing(40)
                  : theme.spacing(25),
              display: 'flex',
              justifyContent: 'space-between',
              height: theme.spacing(10),
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setCurrentVisibleIndex((prev) =>
                  prev === 0
                    ? (trendExample.urlMetadata.metadata?.medias?.length || 1) -
                      1
                    : prev - 1,
                );
              }}
              disableRipple
              sx={{
                color: theme.colors?.primary.white,
              }}
            >
              <IconOutlineArrowLeft size={24} />
            </IconButton>
            <Box width="100%" display="flex" justifyContent="center" gap={2}>
              {(trendExample.urlMetadata.metadata?.medias || []).map(
                (media, index) => {
                  return (
                    <Box
                      key={index}
                      onClick={() => {
                        setCurrentVisibleIndex(index);
                      }}
                      sx={{
                        cursor: 'pointer',
                        width: 8,
                        height: 8,
                        borderRadius: 4,
                        backgroundColor:
                          currentVisibleIndex === index
                            ? theme.colors?.utility[400]
                            : theme.colors?.utility[600],
                        transform:
                          currentVisibleIndex === index ? 'scale(1.5)' : '',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                      }}
                    />
                  );
                },
              )}
            </Box>
            <IconButton
              onClick={() => {
                setCurrentVisibleIndex((prev) =>
                  prev ===
                  (trendExample.urlMetadata.metadata?.medias || []).length - 1
                    ? 0
                    : prev + 1,
                );
              }}
              disableRipple
              sx={{
                color: theme.colors?.primary.white,
                transform: 'rotate(180deg)',
              }}
            >
              <IconOutlineArrowLeft size={24} />
            </IconButton>
          </Box>
        )}

        {currentMedia.type === 'video' && (
          <>
            <Skeleton
              variant="rectangular"
              sx={{
                width: theme.spacing(82),
                height,
                borderRadius: theme.spacing(4),
                display: isLoading ? 'flex' : 'none',
              }}
            />

            <Box
              component="video"
              ref={videoRef}
              src={currentMedia.url}
              sx={{
                width: isLoading ? 0 : '100%',
                height: isLoading ? 0 : '100%',
                maxHeight: height,
                borderRadius: theme.spacing(4),
                visibility: isLoading ? 'hidden' : 'auto',
                objectFit: 'cover',
              }}
              onClick={handleVideoClick}
              onPause={() => {
                setIsVideoPlaying(false);
              }}
              onPlay={() => {
                setIsVideoPlaying(true);
              }}
              playsInline
            />

            {!isVideoPlaying && !isLoading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '50%',
                  transform: `translate(50%, -50%)`,
                }}
              >
                <IconButton
                  sx={{
                    opacity: 0.8,
                    boxShadow: `0px 26.667px 93.333px -20px rgba(24, 39, 75, 0.12), 0px 60px 293.333px -13.333px rgba(24, 39, 75, 0.14)`,
                  }}
                  onClick={handleVideoClick}
                >
                  <IconBoldPauseCircle
                    size={80}
                    color={theme.colors?.primary.white}
                  />
                </IconButton>
              </Box>
            )}
          </>
        )}
        {currentMedia.type === 'image' && (
          <Box
            component="img"
            src={currentMedia.url}
            draggable={false}
            sx={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              maxHeight: height,
              borderRadius: theme.spacing(4),
              transition: 'all 0.3s ease-in-out',
            }}
            alt="Image"
          />
        )}
        {/* eslint-disable-next-line */}
        {trendAudio && (
          <Box
            sx={{
              position: 'absolute',
              margin: theme.spacing(4, 1.5),
              display: 'flex',
              alignItems: 'center',
              gap: 3,
              bottom: 0,
              justifyContent: 'space-between',
              width: '-webkit-fill-available',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                padding: theme.spacing(1, 3),
                borderRadius: 25,
                background: `rgba(35, 6, 3, 0.20)`,
                backdropFilter: `blur(6px)`,
                maxWidth: '80%',
              }}
            >
              {(trendExample.urlMetadata?.metadata?.audio ||
                trendAudio.title) && (
                <>
                  <IconBoldMusic
                    size={16}
                    color={theme.colors?.primary.parchment}
                    style={{
                      flexShrink: 0,
                    }}
                  />
                  <Typography
                    variant="subhead-xl"
                    sx={{
                      color: theme.colors?.primary.parchment,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {(
                      trendExample.urlMetadata?.metadata?.audio ??
                      trendAudio.title
                    )?.replace('♬ ', '')}
                  </Typography>
                </>
              )}
            </Box>
            <Box
              sx={{
                position: 'relative',
                borderRadius: 2,
                border: `2px solid ${theme.colors?.primary.parchment}`,
                height: 45,
                width: 45,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: theme.colors?.utility['purple-4'],
                ':hover': {
                  '.copy-audio-link': {
                    opacity: 1,
                  },
                },
              }}
            >
              {trendAudio?.coverUrl ? (
                <>
                  <Avatar
                    src={trendAudio.coverUrl}
                    sx={{
                      height: '100%',
                      width: '100%',
                      borderRadius: 'inherit',
                      objectFit: 'cover',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 16,
                      height: 16,
                      borderRadius: '100%',
                      bgcolor: theme.colors?.utility['purple-4'],
                      position: 'absolute',
                      top: '100%',
                      right: 0,
                      transform: `translate(25%, -50%)`,
                    }}
                  >
                    <IconBoldMusic
                      size={10}
                      color={theme.colors?.primary.parchment}
                    />
                  </Box>
                </>
              ) : (
                <IconBoldMusic size={20} color="#E6DDED" />
              )}
              <IconButtonWithTooltip
                tooltip="Copy Audio Link"
                className="copy-audio-link"
                sx={{
                  borderRadius: 10,
                  background: `rgba(35, 6, 3, 0.25)`,
                  padding: 2,
                  position: 'absolute',
                  top: '50%',
                  right: '50%',
                  transform: 'translate(50%, -50%)',
                  opacity: 0,
                }}
                onClick={onCopyLink}
              >
                <IconOutlineCopy
                  size={16}
                  color={theme.colors?.primary.parchment}
                />
              </IconButtonWithTooltip>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            position: 'absolute',
            right: theme.spacing(3),
            top: theme.spacing(3),
          }}
        >
          <Icon size={32} color={theme.colors?.primary.parchment} />
        </Box>
      </Box>
    );
  },
);
