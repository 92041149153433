import { Button, Dialog, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';

type BillingNewPricingStarterPlanConfirmationProps = {
  isOpen: boolean;
};

export const BillingNewPricingStarterPlanConfirmation = (
  props: BillingNewPricingStarterPlanConfirmationProps,
) => {
  const { isOpen } = props;

  const navigate = useGuardNavigate();

  const handleClose = () => {
    navigate(0);
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: { p: 8, width: '361px' },
      }}
    >
      <Typography variant="headline-lg">
        You're on the Starter Free plan! 🎉
      </Typography>

      <Typography
        variant="subhead-md"
        color={theme.colors?.utility[700]}
        sx={{ marginTop: theme.spacing(2) }}
      >
        If you ever decide to switch, you can upgrade to a paid plan from the
        Billing Page in settings!
      </Typography>

      <Button
        variant="contained"
        sx={{
          marginTop: theme.spacing(6),
          bgcolor: theme.colors?.primary.black,
          color: theme.colors?.primary.white,
          borderRadius: theme.spacing(2),
          ':hover': {
            bgcolor: theme.colors?.primary.black,
          },
        }}
        onClick={handleClose}
      >
        Continue to Plot
      </Button>
    </Dialog>
  );
};
