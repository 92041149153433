import { theme } from 'styles/theme/theme';
import { HeaderVariant } from './types';

export type HeaderColors = {
  bgColor?: string;
  iconColor?: string;
  logoColor?: string;
  borderColor?: string;
};

export const getHeaderColors = (variant: HeaderVariant): HeaderColors => {
  switch (variant) {
    case 'light':
      return {
        bgColor: theme.colors?.primary.white,
        iconColor: theme.colors?.utility[800],
        logoColor: theme.colors?.primary.maroon,
      };
    case 'dark':
      return {
        bgColor: theme.colors?.primary.black,
        iconColor: theme.colors?.primary.white,
        logoColor: theme.colors?.primary.white,
      };
    case 'transparent':
      return {
        bgColor: 'transparent',
        iconColor: theme.colors?.primary.white,
        logoColor: theme.colors?.primary.white,
        borderColor: 'transparent',
      };
    default:
      break;
  }
  return {
    bgColor: theme.colors?.primary.white,
    iconColor: theme.colors?.utility[800],
    logoColor: theme.colors?.primary.maroon,
  };
};

export const getDefaultVariantBasedOnRoutePathname = (pathname: string) => {
  if (['/projects'].some((p) => pathname.startsWith(p))) {
    return 'transparent';
  }

  return 'light';
};
