import { gql } from '@apollo/client';
import { toast } from 'components/common/Toast';
import { useUserContext } from 'contexts/users/User.context';
import {
  CommentFilter,
  CommentFragmentCommentContainerViewFragment,
  CommentFragmentPostCommentListViewFragmentDoc,
  CreateCommentInput,
  GetCommentsForPostCommentListViewDocument,
  GetCommentsForPostCommentListViewQuery,
  UpdateCommentInput,
  useCreateCommentForPostMutation,
  useDeleteCommentForPostMutation,
  useReactToCommentForPostMutation,
  useReadAllCommentsForPostMutation,
  useUpdateCommentForPostMutation,
} from 'graphql/generated';
import { useCallback } from 'react';
import {
  evictObject,
  getCustomOperationContext,
  modifyObject,
} from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreateCommentForPost($data: CreateCommentInput!) {
    createComment(data: $data) {
      id
      ...CommentFragmentPostCommentListView
    }
  }
  ${CommentFragmentPostCommentListViewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation ReactToCommentForPost($data: ReactInput!) {
    react(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCommentForPost($data: UpdateCommentInput!) {
    updateComment(data: $data) {
      id
      ...CommentFragmentPostCommentListView
    }
  }
  ${CommentFragmentPostCommentListViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteCommentForPost($data: DeleteCommentInput!) {
    deleteComment(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation ReadAllCommentsForPost($data: ReadAllCommentsInput!) {
    readAllComments(data: $data) {
      message
      success
    }
  }
`;

export const usePostCommentHandlers = () => {
  const { user } = useUserContext();

  const [createPostComment] = useCreateCommentForPostMutation();
  const [reactToPostComment] = useReactToCommentForPostMutation();
  const [deletePostComment] = useDeleteCommentForPostMutation();
  const [updatePostComment] = useUpdateCommentForPostMutation();
  const [readAllPostComments] = useReadAllCommentsForPostMutation({
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });

  const onCreatePostComment = useCallback(
    (
      data: CreateCommentInput,
      filters: CommentFilter,
      callback?: (id: string) => void,
    ) => {
      createPostComment({
        variables: {
          data,
        },
        update: (cache, res) => {
          const createdPostComment = res.data?.createComment;

          if (createdPostComment) {
            const newPostCommentRef = cache.writeFragment({
              data: createdPostComment,
              fragment: CommentFragmentPostCommentListViewFragmentDoc,
              fragmentName: 'CommentFragmentPostCommentListView',
            });

            if (createdPostComment.parentThreadId) {
              const parentCommentReference = cache.identify({
                __typename: 'CommentModel',
                id: createdPostComment.parentThreadId,
              });

              // If it's a child comment, update the parent comment's childComments.
              cache.modify({
                id: parentCommentReference,
                fields: {
                  childComments: (cachedChildComments) => {
                    return [...cachedChildComments, newPostCommentRef];
                  },
                },
              });
            } else {
              cache.updateQuery(
                {
                  query: GetCommentsForPostCommentListViewDocument,
                  variables: {
                    filters,
                  },
                },
                (data: GetCommentsForPostCommentListViewQuery | null) => {
                  if (!data) return null;

                  return {
                    ...data,
                    comments: [...data.comments, createdPostComment],
                  };
                },
              );
            }

            callback?.(createdPostComment.id);
          }
        },
      });
    },
    [createPostComment],
  );

  const onReactToComment = useCallback(
    (commentId: string, emoji: string) => {
      reactToPostComment({
        variables: {
          data: {
            commentId,
            emoji,
          },
        },
        update: (cache, res) => {
          const createdPostComment = res.data?.react;

          if (createdPostComment) {
            modifyObject(cache, commentId, 'CommentModel', {
              userReactions: (cachedUserReactions) => {
                const reaction = cachedUserReactions.find(
                  (c) => c.emoji === emoji && c.userId === user?.id,
                );
                if (reaction) {
                  evictObject(cache, reaction.id, 'UserReactionModel');
                } else {
                  return [
                    ...cachedUserReactions,
                    {
                      __typename: 'UserReactionModel',
                      user,
                      userId: user?.id,
                    },
                  ];
                }
              },
            });
          }
        },
      });
    },
    [user, reactToPostComment],
  );

  const onDeletePostComment = useCallback(
    (
      postComment: Omit<
        CommentFragmentCommentContainerViewFragment,
        'childComments'
      >,
    ) => {
      const postCommentId = postComment.id;
      deletePostComment({
        variables: {
          data: {
            commentId: postComment.id,
          },
        },
        update: (cache, res) => {
          const deletedPostComment = res.data?.deleteComment;

          if (deletedPostComment?.success) {
            const deletedPostComment = cache.identify({
              __typename: 'CommentModel',
              id: postCommentId,
            });

            if (postComment.parentThreadId) {
              const parentCommentReference = cache.identify({
                __typename: 'CommentModel',
                id: postComment.parentThreadId,
              });

              cache.modify({
                id: parentCommentReference,
                fields: {
                  childComments: (cachedChildComments) => {
                    return cachedChildComments.filter(
                      (comment) => comment.__ref !== deletedPostComment,
                    );
                  },
                },
              });
            } else {
              evictObject(cache, postCommentId, 'CommentModel');
            }
          }
        },
      });
    },
    [deletePostComment],
  );

  const onUpdatePostComment = useCallback(
    (data: UpdateCommentInput) => {
      updatePostComment({
        variables: {
          data,
        },
        onCompleted: () => {
          toast({
            message: 'Post comment updated successfully!!',
            type: 'success',
          });
        },
        update: (cache, res) => {
          const updatedPostComment = res.data?.updateComment;
          if (updatedPostComment && updatedPostComment.postId) {
            modifyObject(cache, updatedPostComment.postId, 'PostModel', {
              text: () => updatedPostComment.text,
              childComments: () => updatedPostComment.childComments,
            });
          }
        },
      });
    },
    [updatePostComment],
  );

  const onReadAllPostComments = useCallback(
    (postId: string) => {
      readAllPostComments({
        variables: {
          data: {
            postId,
          },
        },
      });
    },
    [readAllPostComments],
  );

  return {
    onCreatePostComment,
    onReactToComment,
    onDeletePostComment,
    onUpdatePostComment,
    onReadAllPostComments,
  };
};
