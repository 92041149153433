import {
  PermissionSummaryView,
  PermissionSummaryViewProps,
} from 'features/permission';
import { CollectionFragmentCollectionPermissionSummaryFragment } from 'graphql/generated';
import { gql } from '@apollo/client';
import { useCheckIfCustomCollection } from 'features/collection';
import { COLLECTION_INVITE_MEMBER_FRAGMENT_COLLECTION_PERMISSION_MEMBER } from 'features/collectionPermission/components';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentCollectionPermissionSummary on CollectionModel {
    id
    generalPermission
    organizationName
    inviteMembers {
      ...CollectionInviteMemberFragmentCollectionPermissionMember
    }
  }
  ${COLLECTION_INVITE_MEMBER_FRAGMENT_COLLECTION_PERMISSION_MEMBER}
`;

type CollectionPermissionSummaryViewProps = {
  collection: CollectionFragmentCollectionPermissionSummaryFragment;
  componentsProps?: PermissionSummaryViewProps['componentsProps'];
};

export const CollectionPermissionSummaryView = (
  props: CollectionPermissionSummaryViewProps,
) => {
  const { collection, ...rest } = props;

  // Check if current collection is a custom collection (All Posts, My Posts or Saved)
  const isCustomCollection = useCheckIfCustomCollection(collection.id);

  if (isCustomCollection) {
    return null;
  }

  return (
    <PermissionSummaryView
      organizationName={collection.organizationName}
      generalPermission={collection.generalPermission}
      invitedMembers={collection.inviteMembers}
      {...rest}
    />
  );
};
