import { Box, Typography, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledHeaderTitle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  textAlign: 'left',
  flex: 1,
  overflow: 'hidden',
}));

export const StyledEmptyText = styled(Typography)(() => ({
  ...theme.typography['headline-xl'],
  color: theme.colors?.utility[600],
  fontSize: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
  fontWeight: 500,
}));
