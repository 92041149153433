import { gql } from '@apollo/client';
import { Box, Chip, Typography } from '@mui/material';
import { IconBoldMonitorMobile } from 'components/icons/components/bold/IconBoldMonitorMobile';
import { platformIconMap } from 'features/contentIdea/constants';
import {
  ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelectFragmentDoc,
  ContentIdeaFragmentContentIdeaPlatformsFragment,
  ContentIdeaFragmentContentIdeaPlatformsMenuItemFragmentDoc,
  ContentIdeaPermission,
  FieldType,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { ContentIdeaField } from '../field';
import { MultiSelectFieldProps } from '../field/MultiSelectField';
import {
  ContentIdeaPlatformsMenuItem,
  platformOptionToSupportedPlatformEnumMap,
} from './ContentIdeaPlatformsMenuItem';

export const CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PLATFORMS = gql`
  fragment ContentIdeaPublishMetadataFragmentContentIdeaPlatforms on ContentIdeaPublishMetadataModel {
    id
    platform
    socialAuthor {
      id
      platformHandle
    }
  }
`;

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_PLATFORMS = gql`
  fragment ContentIdeaFragmentContentIdeaPlatforms on ContentIdeaModel {
    id
    myPermissions
    platforms {
      id
      contentIdeaField {
        id
      }
      ...ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelect
    }
    contentIdeaPublishMetadata {
      id
      ...ContentIdeaPublishMetadataFragmentContentIdeaPlatforms
    }
    ...ContentIdeaFragmentContentIdeaPlatformsMenuItem
  }
  ${ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelectFragmentDoc}
  ${CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PLATFORMS}
  ${ContentIdeaFragmentContentIdeaPlatformsMenuItemFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaPublishMetadataForContentIdeaPlatforms(
    $data: UpdateContentIdeaPublishMetadataInput!
  ) {
    updateContentIdeaPublishMetadata(data: $data) {
      id
      ...ContentIdeaPublishMetadataFragmentContentIdeaPlatforms
    }
  }
  ${CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PLATFORMS}
`;

export type ContentIdeaPlatformsProps = {
  contentIdea: ContentIdeaFragmentContentIdeaPlatformsFragment;
  triggerVariant?: 'minimized' | 'full';
  renderTrigger?: MultiSelectFieldProps['renderTrigger'];
};

export const ContentIdeaPlatforms = (props: ContentIdeaPlatformsProps) => {
  const { contentIdea, triggerVariant = 'minimized', renderTrigger } = props;

  const platforms = contentIdea.platforms.value?.multiSelect || [];
  const contentIdeaPublishMetadata =
    contentIdea.contentIdeaPublishMetadata || [];

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  const renderMinimizedTrigger = ({ isOpen }) => {
    if (platforms.length === 0) {
      return (
        <IconBoldMonitorMobile
          size={16}
          color={
            isOpen ? theme.colors?.primary.black : theme.colors?.utility[500]
          }
        />
      );
    }

    const Icon = platformIconMap[platforms[0].value];

    return (
      <Box
        sx={{
          py: 0.5,
          px: 1,
          borderRadius: 1,
          bgcolor: '#23060312',
          color: theme.colors?.utility[700],
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {Icon ? (
          <Icon size={14} />
        ) : (
          <Typography
            variant="subhead-xs"
            sx={{
              maxWidth: 30,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {platforms[0].label}
          </Typography>
        )}
        {platforms.length > 1 && (
          <Typography variant="subhead-xs">+{platforms.length - 1}</Typography>
        )}
      </Box>
    );
  };

  const renderFullTrigger = ({ options, onDelete }) => {
    if (platforms.length === 0) {
      return (
        <Typography variant="subhead-lg" color={theme.colors?.utility[600]}>
          Empty
        </Typography>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          flexWrap: 'wrap',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {platforms.map((platform) => {
          const option = options.find((o) => o.value === platform.value);

          if (!option) {
            return null;
          }

          const Icon = platformIconMap[platform.value];
          const contentIdeaPublishMetadataForCurrentPlatform =
            contentIdeaPublishMetadata.find(
              (metadata) =>
                metadata.platform ===
                platformOptionToSupportedPlatformEnumMap[option.value],
            );

          return (
            <Chip
              sx={{
                bgcolor: option.bgcolor || theme.colors?.utility['400'],
                borderRadius: 1,
              }}
              label={
                <Typography variant="headline-xs">
                  {contentIdeaPublishMetadataForCurrentPlatform?.socialAuthor
                    ? contentIdeaPublishMetadataForCurrentPlatform.socialAuthor
                        .platformHandle
                    : option.label}
                </Typography>
              }
              onDelete={canEdit ? () => onDelete(platform.value) : undefined}
              variant="filled-borderless-color-dodge"
              icon={Icon && <Icon size={16} />}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <ContentIdeaField
      id={contentIdea.platforms.contentIdeaField.id}
      valueId={contentIdea.platforms.id}
      readOnly={!canEdit}
      value={{
        multiSelect: platforms.map((platform) => platform.value),
      }}
      type={FieldType.MultiSelect}
      renderTrigger={
        renderTrigger || triggerVariant === 'minimized'
          ? renderMinimizedTrigger
          : renderFullTrigger
      }
      renderOption={(props, { option, options, selected }) => {
        const platformOption = options.find((o) => o.value === option.value);
        const Icon = platformIconMap[option.value];

        return (
          <ContentIdeaPlatformsMenuItem
            {...props}
            {...option}
            contentIdea={contentIdea}
            bgcolor={platformOption?.bgcolor || ''}
            selected={selected}
            Icon={Icon}
          />
        );
      }}
    />
  );
};
