import { TableCell, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledHeaderCell = styled(TableCell)(() => ({
  borderColor: theme.colors?.utility[400],
  ...theme.typography['headline-xs'],
  color: theme.colors?.utility[700],
  whiteSpace: 'nowrap',
  paddingBottom: theme.spacing(2),
  paddingTop: 0,
  ':first-child': {
    paddingLeft: 0,
  },
}));

export const StyledBodyCell = styled(TableCell)(() => ({
  ':first-child': {
    paddingLeft: 0,
  },
  borderColor: theme.colors?.utility[275],
  ...theme.typography['subhead-lg'],
}));
