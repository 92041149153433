import { Box, styled } from '@mui/material';

export const ImageContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
}));

export const ImageWrapper = styled(Box)(() => ({
  width: 'fit-content',
  height: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
}));
