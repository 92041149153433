import {
  ComputableProgressInfo,
  ProgressCallback,
  UnknownProgressInfo,
  UploadClient,
  UploadcareFile,
} from '@uploadcare/upload-client';
import { useUserContext } from 'contexts/users/User.context';
import { AttachmentMetaDataInput } from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';

export const uploadCareClient = new UploadClient({
  publicKey: process.env.REACT_APP_PUBLIC_UPLOAD_CARE_KEY || '',
});

const MULTI_PART_THRESHOLD = 1024 * 1024 * 10;

export const convertUploadCareFileToMetaData = (
  uploadCareFile: UploadcareFile,
) => {
  const attachmenData: AttachmentMetaDataInput = {
    uuid: uploadCareFile.uuid,
    size: uploadCareFile.size,
    mimeType: uploadCareFile.mimeType,
    imageInfo: uploadCareFile.imageInfo
      ? {
          height: uploadCareFile.imageInfo.height,
          width: uploadCareFile.imageInfo.width,
          orientation: uploadCareFile.imageInfo.orientation,
        }
      : undefined,
    videoInfo: uploadCareFile.videoInfo
      ? {
          duration: uploadCareFile.videoInfo.duration,
          format: uploadCareFile.videoInfo.format,
          video: {
            height: uploadCareFile.videoInfo.video.height,
            width: uploadCareFile.videoInfo.video.width,
          },
        }
      : undefined,
  };
  return attachmenData;
};

export const useUploadCare = () => {
  const { user, orgBilling } = useUserContext();

  const analytics = useAnalytics();

  if (!user || !user.organization) {
    return null;
  }

  const orgPaywallContext = orgBilling;

  return async (
    file: File,
    fileName: string,
    onProgress?: ProgressCallback<ComputableProgressInfo | UnknownProgressInfo>,
  ) => {
    if (!user || !user || !user.organization) {
      throw new Error('User is not logged in');
    }

    // FIXME: update logic for refreshing token
    // const secureExpireEpoch = Date.parse(user.uploadToken.secureExpire);
    // if (Date.now() > secureExpireEpoch) {
    //   console.log(`UploadCare token expired, refreshing...`);
    //    const { data } = await queryMe();
    //    if (!data || !data.me || !data.me.uploadToken) {
    //      throw new Error('Could not refresh UploadCare token');
    //    }
    //   console.log(
    //     `Setting new Upload Care token for user ${user.user.id}. New expiration: ${data.me.uploadToken.secureExpire}`,
    //   );
    //   user.setUser({
    //     ...user.user,
    //     uploadToken: data.me.uploadToken,
    //   });
    // }

    if (
      orgPaywallContext &&
      orgPaywallContext.fileStorageLimitInMb &&
      orgPaywallContext.fileStorageUsedInMb >
        orgPaywallContext.fileStorageLimitInMb
    ) {
      analytics.track(EventName.PaywallHit, {
        userId: user.id,
        limitHitType: 'fileStorageUploaded',
        ...orgPaywallContext,
      });

      throw new Error('Upgrade to upload more files');
    }

    const result = await uploadCareClient.uploadFile(file, {
      multipartMinFileSize: MULTI_PART_THRESHOLD,
      secureSignature: user.uploadToken.secureSignature,
      secureExpire: user.uploadToken.secureExpire,
      metadata: {
        authorId: user.id,
        organizationId: user.organization.id,
      },
      fileName,
      onProgress,
    });

    return result;
  };
};

export type UseUploadCareUploadFileFn = ReturnType<typeof useUploadCare>;
