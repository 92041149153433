import {
  BillingSubscriptionStatus,
  OrganizationBillingFragmentFreeTrialFragment,
  useSetHasSeenFreeTrialEndedForBillingFreeTrialEndedMutation,
} from 'graphql/generated';
import { Button, Dialog, SxProps, Typography } from '@mui/material';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { theme } from 'styles/theme';
import { useEffect } from 'react';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useUserContext } from 'contexts/users/User.context';
import { IconLinearArrowRight3 } from 'components/icons/components/linear/IconLinearArrowRight3';
import { useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { gql } from '@apollo/client';
import { BillingUpgradeModal } from '../upgradeModal';
import { modifyObject } from '../../../../utils/apollo';

// eslint-disable-next-line
gql`
  mutation SetHasSeenFreeTrialEndedForBillingFreeTrialEnded {
    setHasSeenFreeTrialEnded {
      id
    }
  }
`;

type BillingFreeTrialEndedProps = {
  organizationBilling: OrganizationBillingFragmentFreeTrialFragment;
  sx?: SxProps;
};

export const BillingFreeTrialEnded = (props: BillingFreeTrialEndedProps) => {
  const { organizationBilling, sx } = props;
  const analytics = useAnalytics();
  const location = useLocation();
  const { user } = useUserContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: planOptionOpen,
    onOpen: planOptionOnOpen,
    onClose: planOptionOnClose,
  } = useDisclosure();

  useEffect(() => {
    if (
      !location.pathname.includes(PlotRoutes.billingCheckout()) &&
      organizationBilling.subscription?.status ===
        BillingSubscriptionStatus.Paused &&
      !organizationBilling.hasSeenFreeTrialEnded
    ) {
      onOpen();

      analytics.track(EventName.TrialWrappedViewed, {
        page: 'BillingFreeTrialEnded',
        userRole: user?.role,
        subscriptionTrialEnd: organizationBilling?.subscription?.trialEnd,
      });
    }
  }, [
    location.pathname,
    analytics,
    onOpen,
    organizationBilling.subscription,
    organizationBilling.hasSeenFreeTrialEnded,
  ]);

  const [setHasSeenFreeTrialEnded] =
    useSetHasSeenFreeTrialEndedForBillingFreeTrialEndedMutation();
  const handleClose = () => {
    setHasSeenFreeTrialEnded({
      update: (cache) => {
        modifyObject(
          cache,
          organizationBilling.id,
          'OrganizationBillingModel',
          {
            hasSeenFreeTrialEnded: () => true,
          },
        );
      },
    });
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            transition: 'background-color 1s ease, height 0.3s, width 0.3s',
            bgcolor: theme.colors?.primary.black,
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(6),
            p: theme.spacing(24, 29),
            position: 'relative',
            maxHeight: '480px',
            height: '480px',
            maxWidth: '700px',
            width: '700px',
            borderRadius: theme.spacing(6),
            overflow: 'hidden',
            ...sx,
          },
        }}
      >
        <Typography
          variant="headline-xl"
          fontSize={71}
          color={theme.colors?.primary.white}
          textAlign="center"
        >
          You Plot Trial has ended
        </Typography>

        <Typography
          variant="subhead-xl"
          color={theme.colors?.primary.white}
          textAlign="center"
        >
          I hope its not time to say goodbye! Upgrade to continue using Plot’s
          superpowers.
        </Typography>

        <Button
          variant="contained"
          sx={{
            bgcolor: theme.colors?.primary.white,
            color: theme.colors?.primary.black,
            borderRadius: theme.spacing(25),
            ':hover': {
              bgcolor: theme.colors?.primary.white,
            },
          }}
          endIcon={<IconLinearArrowRight3 />}
          onClick={() => {
            handleClose();
            planOptionOnOpen();
          }}
        >
          Upgrade
        </Button>

        <Typography
          sx={{
            fontSize: '47px',
            position: 'absolute',
            top: '-3%',
            left: '30%',
            // make sure cannot see through emoji
            color: theme.colors?.primary.white,
            transition: 'top 1s, left 1s',
          }}
        >
          🍇
        </Typography>
        <Typography
          sx={{
            fontSize: '73px',
            position: 'absolute',
            top: '43%',
            left: '-4%',
            // make sure cannot see through emoji
            color: theme.colors?.primary.white,
            transition: 'top 1s, left 1s',
          }}
        >
          🍋
        </Typography>
        <Typography
          sx={{
            fontSize: '73px',
            position: 'absolute',
            top: '7%',
            right: '-3%',
            // make sure cannot see through emoji
            color: theme.colors?.primary.white,
            transition: 'top 1s, right 1s',
          }}
        >
          🍊
        </Typography>
        <Typography
          sx={{
            fontSize: '65px',
            position: 'absolute',
            bottom: '1%',
            left: '16%',
            transform: 'scale(-1, 1)',
            // make sure cannot see through emoji
            color: theme.colors?.primary.white,
            transition: 'bottom 1s, left 1s',
          }}
        >
          🍉
        </Typography>
        <Typography
          sx={{
            fontSize: '63px',
            position: 'absolute',
            bottom: '17%',
            right: '6%',
            // make sure cannot see through emoji
            color: theme.colors?.primary.white,
            transition: 'bottom 1s, right 1s',
          }}
        >
          🥝
        </Typography>
      </Dialog>

      <BillingUpgradeModal
        isOpen={planOptionOpen}
        onClose={planOptionOnClose}
      />
    </>
  );
};
