import { gql, useApolloClient } from '@apollo/client';
import { usePrevious } from '@dwarvesf/react-hooks';
import { Box, TextFieldProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import {
  ContentIdeaFieldValueFragmentContentIdeaFieldDateFragmentDoc,
  ContentIdeaFragmentContentIdeaDueDateFragment,
  ContentIdeaPermission,
  FieldType,
  GetContentIdeasForContentCalendarPageCalendarSectionDocument,
  Stage,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { ContentIdeaField } from '../field';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_DUE_DATE = gql`
  fragment ContentIdeaFragmentContentIdeaDueDate on ContentIdeaModel {
    id
    myPermissions
    dueDate {
      id
      contentIdeaField {
        id
      }
      ...ContentIdeaFieldValueFragmentContentIdeaFieldDate
    }
  }
  ${ContentIdeaFieldValueFragmentContentIdeaFieldDateFragmentDoc}
`;

export type ContentIdeaDueDateProps = {
  contentIdea: ContentIdeaFragmentContentIdeaDueDateFragment;
  renderInput?: (
    props: TextFieldProps & {
      isOpen: boolean;
      openPicker: () => void;
      onClear: () => void;
    },
  ) => JSX.Element;
};

export const ContentIdeaDueDate = (props: ContentIdeaDueDateProps) => {
  const { contentIdea, renderInput } = props;

  const previousDate = usePrevious(contentIdea.dueDate.value?.date);

  const stopEvent = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // NOTE: THIS IS A VERY UGLY HACK
  // that aims to trigger a refetch in Content Calendar page > Calendar section when
  // a content idea's due date is updated
  const client = useApolloClient();
  useEffect(() => {
    if (
      contentIdea.dueDate.value?.date &&
      previousDate !== undefined &&
      previousDate !== contentIdea.dueDate.value?.date
    ) {
      setTimeout(() => {
        client.refetchQueries({
          include: [
            GetContentIdeasForContentCalendarPageCalendarSectionDocument,
          ],
        });
      }, 1000);
    }
  }, [contentIdea.dueDate.value?.date]); // eslint-disable-line

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  // Optimistically update content idea's stage to production when due date is set
  // We have a custom logic in the BE that when the due date is changed and current stage is brain dump,
  // it'll automatically be moved to production stage
  const onAfterChange = (value: string) => {
    if (value) {
      modifyObject(client.cache, contentIdea.id, 'ContentIdeaModel', {
        stage: (cachedStage) => {
          if (cachedStage === Stage.BrainDump) {
            return Stage.Production;
          }

          return cachedStage;
        },
      });
    }
  };

  return (
    <ContentIdeaField
      id={contentIdea.dueDate.contentIdeaField.id}
      valueId={contentIdea.dueDate.id}
      value={{
        date: contentIdea.dueDate.value?.date as string,
      }}
      type={FieldType.Date}
      readOnly={!canEdit}
      onAfterChange={(v) => onAfterChange(v as string)}
      renderInput={
        renderInput ||
        ((props) => {
          const { inputRef, inputProps, isOpen, openPicker } = props;
          const { value } = inputProps || {};

          return (
            <Tooltip title="Publish Date" placement="top">
              <Box
                component="button"
                ref={inputRef}
                display="flex"
                alignItems="center"
                onClick={(e) => {
                  stopEvent(e);
                  openPicker();
                }}
                disabled={!canEdit}
              >
                {value ? (
                  <Typography
                    variant="subhead-sm"
                    color={theme.colors?.utility[700]}
                  >
                    {moment(value).format('MMM D')}
                  </Typography>
                ) : (
                  <IconBoldCalendar
                    size={16}
                    color={
                      isOpen
                        ? theme.colors?.primary.black
                        : theme.colors?.utility[500]
                    }
                  />
                )}
              </Box>
            </Tooltip>
          );
        })
      }
    />
  );
};
