/**
 * This component is generated by ChatGPT.
 */

import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';

export type InfiniteScrollBoundaryProps = {
  disabled?: boolean;
  threshold?: number;
  side?: 'top' | 'bottom';
  onVisible: () => void;
};

export const InfiniteScrollBoundary = (props: InfiniteScrollBoundaryProps) => {
  const { disabled, threshold = 100, side = 'bottom', onVisible } = props;

  const boundaryRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onVisible();
          }
        });
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the element is visible
      },
    );

    if (boundaryRef.current) {
      observer.observe(boundaryRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      if (boundaryRef.current) {
        // eslint-disable-next-line
        observer.unobserve(boundaryRef.current);
      }
    };
  }, [disabled, onVisible]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '1px',
        margin: '-1px',
        position: 'relative',
        pointerEvents: 'none',
      }}
      data-disabled={disabled}
    >
      <Box
        ref={boundaryRef}
        style={{
          width: '100%',
          marginTop: side === 'bottom' ? -threshold * 2 : 0,
          height: threshold * 2,
          position: 'absolute',
        }}
      />
    </Box>
  );
};

export default InfiniteScrollBoundary;
