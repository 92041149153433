import { Extension } from '@tiptap/react';
import { EXTENSION_DISABLE_ENTER } from '../constants';

export const DisableEnter = Extension.create({
  name: EXTENSION_DISABLE_ENTER,

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        return true;
      },
    };
  },
});
