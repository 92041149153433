import { gql } from '@apollo/client';
import {
  BrandPermission,
  useGetBrandForUseSlBrandPermissionsQuery,
} from 'graphql/generated';

type Props = {
  brandId: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandForUseSLBrandPermissions($brandId: String!) {
    brand(id: $brandId) {
      id
      myPermissions
    }
  }
`;

export const useSLBrandPermissions = ({ brandId }: Props) => {
  const { data } = useGetBrandForUseSlBrandPermissionsQuery({
    variables: {
      brandId,
    },
    skip: !brandId,
  });
  return {
    hasFullAccess: (data?.brand?.myPermissions || []).includes(
      BrandPermission.Write,
    ),
  };
};
