import { useEffect } from 'react';
import { Command } from '../types';
import { COMMAND_TYPE } from '../constants';

/**
 * This hook is generated by ChatGPT.
 *
 * `useKeyboardCommands` is a React hook that allows you to add commands of type Command.
 * This should be used together with the Command context.
 */

const useKeyboardCommands = (
  commands: Command[],
  onTrigger: (commandType: COMMAND_TYPE) => void,
) => {
  useEffect(() => {
    // Set to keep track of currently pressed keys
    const downKeys = new Set<string>();

    // Event handler for keydown
    const handleDown = (event: KeyboardEvent) => {
      if (!event.key) {
        return;
      }

      downKeys.add(event.key.toLowerCase());

      // Prevent the shortcut from triggering when the user is focusing on input elements
      const activeElement = document?.activeElement as HTMLElement;
      if (
        (activeElement &&
          (activeElement.tagName === 'INPUT' ||
            activeElement.tagName === 'TEXTAREA' ||
            activeElement.isContentEditable)) || // exclude text editor
        document?.activeElement?.className.includes('ProseMirror')
      ) {
        return;
      }

      // Check if any of the defined commands match the pressed keys
      // Ignore comments without keys
      const matchedCommand = commands
        .filter((c) => c.keys.length > 0)
        .find((command) => {
          const { keys } = command;

          // We only match if the combo length is the same
          // This is to ensure that default browser shortcuts like CMD + R still works
          if (downKeys.size !== keys.length) {
            return false;
          }

          return keys.every((k) => {
            if (downKeys.has(k.toLowerCase())) {
              return true;
            }

            // FIXME: There's a case-insensitive issue here
            // Due to the way we're handling the down key in line 18
            if (k === 'Meta' || k === 'Control') {
              return downKeys.has('meta') || downKeys.has('control');
            }

            return false;
          });
        });

      if (matchedCommand) {
        // Prevent the default behavior of the matched command
        event.preventDefault();
        // Call onTrigger callback with the matched command id
        onTrigger(matchedCommand.type);
        // Clear downKeys on trigger
        downKeys.clear();
      }
    };

    // Event handler for keyup to clear the set of pressed keys
    const handleUp = () => {
      downKeys.clear();
    };

    // Add event listeners for keydown and keyup
    window.addEventListener('keydown', handleDown);
    window.addEventListener('keyup', handleUp);

    // Cleanup by removing event listeners when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleDown);
      window.removeEventListener('keyup', handleUp);
    };
  }, [commands, onTrigger]);
};

export default useKeyboardCommands;
