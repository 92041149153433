import { Box, Portal, Snackbar, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { PostFragmentPostPreviewFragment } from 'graphql/generated';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { PostPreview } from '../components';

export const usePostMovedSnackbar = () => {
  const [showToast, setShowToast] = useState(false);
  const [post, setPost] = useState<
    PostFragmentPostPreviewFragment | undefined
  >();
  const [toCollectionId, setToCollectionId] = useState<string | undefined>();

  const renderSnackbar = useCallback(() => {
    if (showToast) {
      return (
        <Portal>
          <Snackbar
            sx={{
              minWidth: theme.spacing(100),
              position: 'fixed !important',
              left: '50% !important',
              transform: 'translate(-50%, -50%)',
            }}
            ContentProps={{
              sx: {
                bgcolor: theme.colors?.utility['purple-1'],
                color: theme.colors?.utility['purple-4'],
                borderRadius: theme.spacing(6),
                p: theme.spacing(1, 6),
              },
            }}
            open={showToast}
            message={
              <Box display="flex" gap={4} alignItems="center">
                <Box
                  sx={{
                    width: theme.spacing(12),
                    height: theme.spacing(12),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  {post && toCollectionId && (
                    <PostPreview
                      sx={{
                        width: theme.spacing(12),
                        height: theme.spacing(12),
                        borderRadius: theme.spacing(1),
                      }}
                      post={post}
                    />
                  )}
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="headline-md"
                    color={theme.colors?.utility['purple-4']}
                  >
                    Post moved!
                  </Typography>
                  {toCollectionId && (
                    <Link to={PlotRoutes.collection(toCollectionId)}>
                      <Typography
                        variant="headline-sm"
                        color={theme.colors?.primary.black}
                      >
                        Go to collection
                      </Typography>
                    </Link>
                  )}
                </Box>
              </Box>
            }
          />
        </Portal>
      );
    }
  }, [showToast, toCollectionId, post]);

  const showSnackbar = useCallback(
    (post: PostFragmentPostPreviewFragment, collectionId: string) => {
      setPost(post);
      setToCollectionId(collectionId);

      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4_000);
    },
    [],
  );

  return {
    renderSnackbar,
    showSnackbar,
  };
};
