import { Box } from '@mui/material';
import { IconCustomTodo } from 'components/icons/components/custom/IconCustomTodo';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineCopy } from 'components/icons/components/outline/IconOutlineCopy';
import { IconOutlineUser } from 'components/icons/components/outline/IconOutlineUser';
import { TaskGroupBy } from 'graphql/generated';

export const taskGroupSelectOptions = [
  {
    label: 'Priority',
    Icon: ({ size, sx }: any) => (
      <Box
        component="span"
        sx={{
          width: size,
          height: size,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
      >
        !!!
      </Box>
    ),
    value: TaskGroupBy.Priority,
  },
  {
    label: 'Due Date',
    Icon: IconOutlineCalendar,
    value: TaskGroupBy.DueDate,
  },
  {
    label: 'Status',
    Icon: IconCustomTodo,
    value: TaskGroupBy.Status,
  },
  {
    label: 'Post',
    Icon: IconOutlineCopy,
    value: TaskGroupBy.Post,
  },
  {
    label: 'Assignee',
    Icon: IconOutlineUser,
    value: TaskGroupBy.Owner,
  },
];

export const FALLBACK_UNGROUP_ID = '-1';
