import { Box, TextField, Typography } from '@mui/material';
import { theme } from 'styles/theme';

type Props = {};

export const SocialMediaListeningOnboardingCreateTopicView = (props: Props) => {
  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Create a topic. 🔎
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        Our AI will work its magic to source and detect relevant video
        conversations based on your topic you define. With thousands of videos
        to sift through, our lil’ robot thrives on your guidance to cut through
        the noise.
      </Typography>

      <Box display="flex" flexDirection="column" gap={3} width="100%" mt={3}>
        <Typography variant="body-xl" color={theme.colors?.utility[500]}>
          Topic Title
        </Typography>
        <TextField
          autoFocus
          placeholder="ex: Plot Brand Mentions"
          sx={{
            '.MuiOutlinedInput-root': {
              borderRadius: theme.spacing(4),
              py: `${theme.spacing(6)} !important`,
              px: `${theme.spacing(6)} !important`,
              bgcolor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,

              input: {
                ...theme.typography['body-xl'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap={3} width="100%" mt={3}>
        <Typography variant="body-xl" color={theme.colors?.utility[500]}>
          What video conversations should we listen for?
        </Typography>
        <TextField
          autoFocus
          multiline
          minRows={3}
          maxRows={8}
          placeholder="Example: Find content showcasing mentions of our Plot brand by creators or instances where our brand logo is visible in videos. Content should focus on creators discussing topics such as social media management, project management, idea sharing, content calendars, asset management, ideation, and storage."
          sx={{
            '.MuiOutlinedInput-root': {
              borderRadius: theme.spacing(4),
              py: `${theme.spacing(6)} !important`,
              px: `${theme.spacing(6)} !important`,
              bgcolor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,

              input: {
                ...theme.typography['body-xl'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap={3} width="100%" mt={3}>
        <Typography variant="body-xl" color={theme.colors?.utility[500]}>
          How should we prioritize incoming posts for you?
        </Typography>
        <TextField
          autoFocus
          placeholder="Example: Show me creators with more than 2000 followers"
          sx={{
            '.MuiOutlinedInput-root': {
              borderRadius: theme.spacing(4),
              py: `${theme.spacing(6)} !important`,
              px: `${theme.spacing(6)} !important`,
              bgcolor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,

              input: {
                ...theme.typography['body-xl'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};
