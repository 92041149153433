import { Box } from '@mui/material';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningFilterByPlatformsButton,
  SocialMediaListeningFilterBySourcesButton,
  SocialMediaListeningFilterByUserButton,
} from 'features/socialMediaListening';
import { SocialMediaListeningFilterByTopicsButton } from 'features/socialMediaListening/components/filterByTopicsButton';
import { PlotFeature, SocialPostCommentsFilters } from 'graphql/generated';

type SocialMediaListeningAnalyticsOutboundInteractionFiltersProps = {
  filters: SocialPostCommentsFilters;
  setFilters: (filters: SocialPostCommentsFilters) => void;
  componentProps?: {
    buttonColors?: string;
    hideFilters?: {
      topics?: boolean;
      commentors?: boolean;
      platforms?: boolean;
      dateRange?: boolean;
      sources?: boolean;
    };
  };
};

export const SocialMediaListeningAnalyticsOutboundInteractionFilters = (
  props: SocialMediaListeningAnalyticsOutboundInteractionFiltersProps,
) => {
  const { filters, setFilters, componentProps = {} } = props;

  const { hideFilters } = componentProps;

  const { isFeatureEnabled } = useFeatureFlagContext();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
      }}
    >
      {isFeatureEnabled(PlotFeature.ManualOutboundUpload) &&
        !hideFilters?.sources && (
          <SocialMediaListeningFilterBySourcesButton
            selectedSources={filters.sources || []}
            onChange={(sources) => {
              setFilters({ ...filters, sources });
            }}
          />
        )}
      {!hideFilters?.topics && (
        <SocialMediaListeningFilterByTopicsButton
          selectedTopicIds={filters.topicIds || []}
          onChange={(topicIds) => {
            setFilters({ ...filters, topicIds });
          }}
        />
      )}
      {!hideFilters?.commentors && (
        <SocialMediaListeningFilterByUserButton
          selectedUsers={filters.responderIds || []}
          onChange={(userIds) => {
            setFilters({
              ...filters,
              responderIds: userIds.length > 0 ? userIds : undefined,
            });
          }}
          componentProps={{
            sx: {
              bgcolor: componentProps?.buttonColors,
              ':hover': {
                bgcolor: componentProps?.buttonColors,
              },
            },
          }}
        />
      )}

      {!hideFilters?.platforms && (
        <SocialMediaListeningFilterByPlatformsButton
          selectedPlatforms={filters.platforms || []}
          onChange={(platforms) => {
            setFilters({ ...filters, platforms });
          }}
          componentProps={{
            sx: {
              bgcolor: componentProps?.buttonColors,
              ':hover': {
                bgcolor: componentProps?.buttonColors,
              },
            },
          }}
        />
      )}

      {!hideFilters?.dateRange && (
        <SocialMediaListeningFilterByDateRangeButton
          selectedDateRange={
            filters.dateRange
              ? [filters.dateRange.startDate, filters.dateRange.endDate]
              : [null, null]
          }
          onChange={(dateRange) => {
            setFilters({
              ...filters,
              dateRange: { startDate: dateRange[0], endDate: dateRange[1] },
            });
          }}
          componentProps={{
            sx: {
              bgcolor: componentProps?.buttonColors,
              ':hover': {
                bgcolor: componentProps?.buttonColors,
              },
            },
          }}
        />
      )}
    </Box>
  );
};
