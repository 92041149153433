import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { OnboardingStates } from '../../types';

type Props = {
  latestOnboardingState: OnboardingStates;
  onboardingSequence: OnboardingStates[];
  currentState: OnboardingStates;
  onSelectState: (state: OnboardingStates) => void;
};

export const SocialMediaListeningOnboardingDotIndicator = ({
  latestOnboardingState,
  onboardingSequence,
  currentState,
  onSelectState,
}: Props) => {
  const IndicatorStateLabels = {
    [OnboardingStates.AboutBrandForm]: 'About Your Brand',
    [OnboardingStates.AskTopicName]: 'Topic Name',
    [OnboardingStates.CreateTopic]: 'Create Topic',
    [OnboardingStates.SocialHandleForm]: 'Social Handle',
    [OnboardingStates.PostPriorityInfo]: 'Post Priority Info',
    [OnboardingStates.CommunicationStrategy]: 'Communication Strategy',
    [OnboardingStates.Keywords]: 'Keywords',
    [OnboardingStates.Signals]: 'Signals',
    [OnboardingStates.PostHistoryPreference]: 'Post History Preference',
    [OnboardingStates.AskBrandName]: 'Brand Name',
    [OnboardingStates.AskBrandKnownNames]: 'Brand Aliases',
    [OnboardingStates.CountrySelection]: 'Country Selection',
    [OnboardingStates.AskTopicDescription]: 'Topic Description',
    [OnboardingStates.TikTokCreatorTracking]: 'TikTok Creators',
    [OnboardingStates.InstagramCreatorTracking]: 'Instagram Creators',
    [OnboardingStates.AddCompetitors]: 'Competitor list',
    [OnboardingStates.CompetitorsDetails]: 'Competitors Details',
    [OnboardingStates.SentimentSubjects]: 'Topic Sentiment',
  };

  const states = onboardingSequence;

  if (!states.includes(currentState) || !IndicatorStateLabels[currentState]) {
    return null;
  }

  const disabledStates = states.slice(
    states.indexOf(latestOnboardingState) + 1,
  );

  return (
    <Box
      position="fixed"
      right={theme.spacing(4)}
      top="50%"
      sx={{ transform: 'translateY(-50%)' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={5}
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {states.map((state) => {
          if (!IndicatorStateLabels[state]) {
            return null;
          }
          if (state === currentState) {
            return (
              <Box
                sx={{
                  backgroundColor: 'rgba(250, 243, 236, 0.20)',
                  borderRadius: theme.spacing(8),
                  p: theme.spacing(1, 3),
                }}
              >
                <Typography
                  variant="body-xl"
                  color={theme.colors?.primary.parchment}
                  fontWeight={500}
                >
                  {IndicatorStateLabels[state]}
                </Typography>
              </Box>
            );
          }
          return (
            <Box
              onClick={
                !disabledStates.includes(state)
                  ? () => onSelectState(state as OnboardingStates)
                  : undefined
              }
              key={state}
              sx={{
                ...(!disabledStates.includes(state)
                  ? { cursor: 'pointer' }
                  : { opacity: 0.3 }),
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor:
                  currentState === state
                    ? 'white'
                    : 'rgba(250, 243, 236, 0.60)',
                margin: '0 5px',
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
};
