import { Box, CircularProgress, Typography } from '@mui/material';
import { useNormalCollectionNestedTableData } from 'features/juiceboxTable/hooks/useNormalCollectionNestedTableData';
import { JuiceboxTableBaseTableView } from '../baseTable';

export type JuiceboxTableNormalCollectionTableViewProps = {
  collectionId: string;
  nestedLevel: number;
  style?: React.CSSProperties;
  hideHeader?: boolean;
};

export const JuiceboxTableNormalCollectionTableView = ({
  collectionId,
  nestedLevel,
  style,
  hideHeader = true,
}: JuiceboxTableNormalCollectionTableViewProps) => {
  const { collections, hasMoreCollections, posts, fetchNextPage, loading } =
    useNormalCollectionNestedTableData({
      collectionId,
    });

  if (!collections.length && !posts.length) {
    return (
      <Box
        py={8}
        sx={{
          textAlign: 'center',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body-xl">
            No collections or posts found.
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <JuiceboxTableBaseTableView
      hideHeader={hideHeader}
      nestedLevel={nestedLevel + 1}
      collections={collections}
      posts={posts}
      hasMore={hasMoreCollections}
      loadMore={fetchNextPage}
      style={{
        maxHeight: 'calc(100vh - 452px)',
        ...style,
      }}
    />
  );
};
