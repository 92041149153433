import { Box } from '@mui/material';
import { IconCustomMessageEdit } from 'components/icons/components/custom/IconCustomMessageEdit';
import { IconLinearTaskSquare } from 'components/icons/components/linear/IconLinearTaskSquare';
import { IconOutlineLayer } from 'components/icons/components/outline/IconOutlineLayer';
import { IconOutlineNote2 } from 'components/icons/components/outline/IconOutlineNote2';
import {
  AttachmentSearchHitFragmentItemThumbnailFragment,
  CollectionSearchHitFragmentItemThumbnailFragment,
  LinkSearchHitFragmentItemThumbnailFragment,
  NoteSearchHitFragmentItemThumbnailFragment,
  SearchableEntityType,
  TaskSearchHitFragmentItemThumbnailFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { SearchThumbnailAttachment } from './SearchThumbnailAttachment';
import { SearchThumbnailLink } from './SearchThumbnailLink';

type SearchThumbnailProps = {
  searchHit?:
    | NoteSearchHitFragmentItemThumbnailFragment
    | LinkSearchHitFragmentItemThumbnailFragment
    | AttachmentSearchHitFragmentItemThumbnailFragment
    | TaskSearchHitFragmentItemThumbnailFragment
    | CollectionSearchHitFragmentItemThumbnailFragment;
  type: SearchableEntityType;
  width?: string | number;
  height?: string | number;
};

export const SearchThumbnail = (props: SearchThumbnailProps) => {
  const { searchHit, type, width = 32, height = 32 } = props;

  if (type === SearchableEntityType.Tasks) {
    return (
      <Box
        display="flex"
        width={width}
        height={height}
        color={theme.colors?.primary.black}
        p={1}
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
      >
        <IconLinearTaskSquare style={{ width, height }} />
      </Box>
    );
  }

  if (type === SearchableEntityType.Notes) {
    return (
      <Box
        display="flex"
        width={width}
        height={height}
        color={theme.colors?.primary.black}
        p={1}
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
      >
        <IconOutlineNote2 style={{ width, height }} />
      </Box>
    );
  }

  if (type === SearchableEntityType.Attachments) {
    return (
      <Box
        display="flex"
        width={width}
        height={height}
        color={theme.colors?.primary.black}
        p={1}
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
      >
        <SearchThumbnailAttachment
          searchHitAttachment={
            searchHit as AttachmentSearchHitFragmentItemThumbnailFragment
          }
        />
      </Box>
    );
  }

  if (type === SearchableEntityType.Links) {
    return (
      <Box
        display="flex"
        width={width}
        height={height}
        color={theme.colors?.primary.black}
        p={1}
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
      >
        <SearchThumbnailLink
          searchHitLink={
            searchHit as LinkSearchHitFragmentItemThumbnailFragment
          }
        />
      </Box>
    );
  }

  if (type === SearchableEntityType.Collections) {
    return (
      <Box
        display="flex"
        width={width}
        height={height}
        color={theme.colors?.primary.black}
        p={1}
        justifyContent="center"
        alignItems="center"
        borderRadius={1}
      >
        <IconOutlineLayer style={{ width, height }} />
      </Box>
    );
  }

  return null;
};
