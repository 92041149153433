import { Box, BoxProps } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import './index.css';
import { UPLOAD_CARE_CDN_URL } from 'constants/uploadCare';

type ImageProps = BoxProps<'img'>;

export const Image = (props: ImageProps) => {
  const { src = '', sx, ...rest } = props;

  const [willRender, setWillRender] = useState(true);

  useEffect(() => {
    setWillRender(false);
    setTimeout(() => {
      setWillRender(true);
    });
  }, [src]);

  const render = useMemo(() => {
    if (src.startsWith(UPLOAD_CARE_CDN_URL)) {
      return (
        // @ts-ignore
        <lr-img
          src={src}
          {...rest}
          style={{
            ...sx,
          }}
        />
      );
    }

    return <Box component="img" src={src} sx={sx} {...rest} />;
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify({
      src,
      sx,
    }),
  ]);

  if (!willRender) {
    return null;
  }

  return render;
};
