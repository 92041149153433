import { gql } from '@apollo/client';
import {
  PostFragmentJuiceboxTablePostTableRowCellActivityFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowCellNameFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowCellPermissionFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowCellPreviewFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowCellSelectIconFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import {
  JuiceboxTablePostTableRowCellActivity,
  JuiceboxTablePostTableRowCellName,
  JuiceboxTablePostTableRowCellPermission,
  JuiceboxTablePostTableRowCellPreview,
  JuiceboxTablePostTableRowCellSelectIcon,
} from './cells';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentJuiceboxTablePostTableRow on PostModel {
    id

    ...PostFragmentJuiceboxTablePostTableRowCellName
    ...PostFragmentJuiceboxTablePostTableRowCellPreview
    ...PostFragmentJuiceboxTablePostTableRowCellPermission
    ...PostFragmentJuiceboxTablePostTableRowCellActivity
    ...PostFragmentJuiceboxTablePostTableRowCellSelectIcon
  }
  ${PostFragmentJuiceboxTablePostTableRowCellNameFragmentDoc}
  ${PostFragmentJuiceboxTablePostTableRowCellPreviewFragmentDoc}
  ${PostFragmentJuiceboxTablePostTableRowCellPermissionFragmentDoc}
  ${PostFragmentJuiceboxTablePostTableRowCellActivityFragmentDoc}
  ${PostFragmentJuiceboxTablePostTableRowCellSelectIconFragmentDoc}
`;

export type JuiceboxTablePostTableRowProps = {
  post: PostFragmentJuiceboxTablePostTableRowFragment;
  collectionId?: string;
};

export const JuiceboxTablePostTableRow = (
  props: JuiceboxTablePostTableRowProps,
) => {
  const { post, collectionId = '' } = props;

  return (
    <>
      <JuiceboxTablePostTableRowCellSelectIcon
        post={post}
        isSelected={false}
        sx={{
          display: 'flex',
          flex: 1,
          width: '5%',
          minWidth: theme.spacing(10),
          border: 'none',
        }}
      />
      <JuiceboxTablePostTableRowCellName
        post={post}
        collectionId={collectionId}
        sx={{
          display: 'flex',
          flex: 6,
          width: '35%',
          maxWidth: theme.spacing(120),
          minWidth: theme.spacing(50),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          border: 'none',
        }}
      />
      <JuiceboxTablePostTableRowCellPreview
        post={post}
        sx={{
          display: 'flex',
          flex: 6,
          width: '20%',
          minWidth: theme.spacing(50),
          border: 'none',
        }}
      />
      <JuiceboxTablePostTableRowCellPermission
        post={post}
        sx={{
          display: 'flex',
          flex: 4,
          width: '20%',
          minWidth: theme.spacing(50),
          border: 'none',
        }}
      />
      <JuiceboxTablePostTableRowCellActivity
        post={post}
        sx={{
          display: 'flex',
          flex: 4,
          width: '20%',
          minWidth: theme.spacing(50),
          border: 'none',
        }}
      />
    </>
  );
};
