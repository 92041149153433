import { gql } from '@apollo/client';
import {
  SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboardingFragmentDoc,
  useCreateSearchTermForUseBrandSearchTermsForSocialListeningOnboardingMutation,
  useGetBrandAiKeywordsForUseBrandSearchTermsForSocialListeningOnboardingLazyQuery,
  useGetTopicAiKeywordsForUseBrandSearchTermsForSocialListeningOnboardingLazyQuery,
} from 'graphql/generated';
import { getCustomOperationContext, modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboarding on SearchTermModel {
    id
    term
    isHashTag
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateSearchTermForUseBrandSearchTermsForSocialListeningOnboarding(
    $data: CreateSearchTermInput!
  ) {
    createSearchTerms(data: $data) {
      id
      ...SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboarding
    }
  }
  ${SearchTermFragmentForUseBrandSearchTermsForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetTopicAIKeywordsForUseBrandSearchTermsForSocialListeningOnboarding(
    $topicId: String!
  ) {
    getAIKeywordsForTopic(topicId: $topicId)
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandAIKeywordsForUseBrandSearchTermsForSocialListeningOnboarding(
    $brandId: String!
  ) {
    getAIKeywordsForBrand(brandId: $brandId)
  }
`;

export const useBrandSearchTermsForSocialListeningOnboarding = () => {
  const [createSearchTerms] =
    useCreateSearchTermForUseBrandSearchTermsForSocialListeningOnboardingMutation();
  const [getAIKeywordsForBrandQuery] =
    useGetBrandAiKeywordsForUseBrandSearchTermsForSocialListeningOnboardingLazyQuery();
  const [getAIKeywordsForTopicQuery] =
    useGetTopicAiKeywordsForUseBrandSearchTermsForSocialListeningOnboardingLazyQuery();

  const handleCreateSearchTerms = async (
    topicId: string,
    terms: string[],
    allowAutomaticUpdate = false,
  ) => {
    return createSearchTerms({
      variables: {
        data: {
          terms,
          topicId,
          allowToUpdateSearchTerm: allowAutomaticUpdate,
        },
      },
      update: (cache, { data }) => {
        modifyObject(cache, topicId, 'TopicModel', {
          searchTerms: () => [...(data?.createSearchTerms || [])],
          allowToUpdateSearchTerm: () => allowAutomaticUpdate,
        });
      },
    });
  };

  const getAIKeywordsForTopic = async (topicId: string) => {
    return getAIKeywordsForTopicQuery({
      variables: {
        topicId,
      },
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  };

  const getAIKeywordsForBrand = async (brandId: string) => {
    return getAIKeywordsForBrandQuery({
      variables: {
        brandId,
      },
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  };

  return {
    searchTermActions: {
      handleCreateSearchTerms,
      getAIKeywordsForTopic,
      getAIKeywordsForBrand,
    },
  };
};
