import { Box, Skeleton, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { theme } from 'styles/theme';

export const SLABrandInboundTopCreatorsSkeleton = () => {
  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="headline-sm"
          color={theme.colors?.utility[700]}
          pb={1}
          display="flex"
          alignItems="center"
          gap={2}
        >
          Top creators
          <Tooltip title="Top creators are ranked by the number of times they have mentioned the brand ">
            <Box display="flex" alignItems="center">
              <IconBoldInfoCircle size={16} />
            </Box>
          </Tooltip>
        </Typography>

        <Skeleton
          sx={{
            width: theme.spacing(36),
            height: theme.spacing(10),
          }}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
      >
        {new Array(12).fill(0).map((item, colIndex) => (
          <Box
            key={colIndex}
            display="flex"
            flex="1 1 25%"
            boxSizing="border-box"
            p={1}
          >
            <Box
              pr={4}
              py={2}
              pl={0}
              display="flex"
              gap={3}
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box display="flex" gap={2} alignItems="center">
                <Skeleton
                  variant="circular"
                  sx={{
                    width: theme.spacing(11),
                    height: theme.spacing(11),
                  }}
                />
                <Skeleton
                  sx={{
                    width: theme.spacing(40),
                    height: theme.spacing(12),
                  }}
                />
              </Box>
              <Skeleton
                sx={{
                  width: theme.spacing(10),
                  height: theme.spacing(12),
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
