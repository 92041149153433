import { ApolloCache, gql } from '@apollo/client';
import { GenerateId } from 'utils/generateId';
import { GET_COLLECTION_PERMISSION_FOR_DIALOG_VIEW } from 'features/collectionPermission';
import { GET_POST_PERMISSION_FOR_DAILOG_VIEW } from 'features/post-permission';
import {
  ApprovePermissionRequestInput,
  CreatePermissionRequestInput,
  GetCollectionPermissionForDialogViewQuery,
  GetPostPermissionForDialogViewQuery,
  PermissionLevel,
  PermissionRequestFragmentPermissionApproveRequestFragmentDoc,
  PermissionRequestFragmentUsePermissionRequestHandlerFragment,
  PermissionRequestStatus,
  RejectPermissionRequestInput,
  useApprovePermissionRequestForPermissionRequestHandlersMutation,
  useCreatePermissionRequestForPermissionRequestHandlersMutation,
  useMyPermissionRequestsLazyQuery,
  useMyPermissionRequestsQuery,
  useRejectPermissionRequestForPermissionRequestHandlersMutation,
} from 'graphql/generated';
import { evictObject, modifyObject } from 'utils/apollo';

export const PERMISSION_REQUEST_FRAGMENT_USE_PERMISSION_REQUEST_HANDLER = gql`
  fragment PermissionRequestFragmentUsePermissionRequestHandler on PermissionRequestModel {
    postId
    collectionId
    brandId
    user {
      organization {
        id
      }
    }
    ...PermissionRequestFragmentPermissionApproveRequest
  }
  ${PermissionRequestFragmentPermissionApproveRequestFragmentDoc}
`;

//eslint-disable-next-line
gql`
  mutation CreatePermissionRequestForPermissionRequestHandlers(
    $data: CreatePermissionRequestInput!
  ) {
    createPermissionRequest(data: $data) {
      postId
      collectionId
      brandId
      ...PermissionRequestFragmentUsePermissionRequestHandler
    }
  }
  ${PERMISSION_REQUEST_FRAGMENT_USE_PERMISSION_REQUEST_HANDLER}
`;

//eslint-diable-next-line
gql`
  mutation ApprovePermissionRequestForPermissionRequestHandlers(
    $data: ApprovePermissionRequestInput!
  ) {
    approvePermissionRequest(data: $data) {
      ...PermissionRequestFragmentUsePermissionRequestHandler
    }
  }
  ${PERMISSION_REQUEST_FRAGMENT_USE_PERMISSION_REQUEST_HANDLER}
`;

//eslint-diable-next-line
gql`
  mutation RejectPermissionRequestForPermissionRequestHandlers(
    $data: RejectPermissionRequestInput!
  ) {
    rejectPermissionRequest(data: $data) {
      ...PermissionRequestFragmentUsePermissionRequestHandler
    }
  }
  ${PERMISSION_REQUEST_FRAGMENT_USE_PERMISSION_REQUEST_HANDLER}
`;

//eslint-disable-next-line
gql`
  query MyPermissionRequests {
    myPermissionRequests {
      id
      postId
      collectionId
      brandId
      status
      user {
        id
        email
      }
    }
  }
`;

export const usePermissionRequestHandler = () => {
  const [approvePermissionRequest] =
    useApprovePermissionRequestForPermissionRequestHandlersMutation();
  const [rejectPermissionRequest] =
    useRejectPermissionRequestForPermissionRequestHandlersMutation();
  const { data: myPermissionRequests } = useMyPermissionRequestsQuery({
    variables: {},
  });
  const [getMyPemissionRequests] = useMyPermissionRequestsLazyQuery();
  const [createPermissionRequest] =
    useCreatePermissionRequestForPermissionRequestHandlersMutation();

  const onCreatePermissionRequest = async (
    input: CreatePermissionRequestInput,
  ) => {
    await createPermissionRequest({
      variables: {
        data: input,
      },
      update: (cache, { data }) => {
        if (!data) return;
        const request = data.createPermissionRequest;
        if (request.postId) {
          modifyObject(cache, request.postId, 'PostModel', {
            permissionRequests: (cachedRequests) => {
              return [...cachedRequests, request];
            },
          });
        }
        if (request.collectionId) {
          modifyObject(cache, request.collectionId, 'CollectionModel', {
            permissionRequests: (cachedRequests) => {
              return [...cachedRequests, request];
            },
          });
        }
      },
    });
  };

  const updateCollectionRequestCache = (
    cache: ApolloCache<any>,
    request: PermissionRequestFragmentUsePermissionRequestHandlerFragment,
    permissionLevel?: PermissionLevel,
  ) => {
    if (
      !request.collectionId ||
      request.status !== PermissionRequestStatus.Approved
    )
      return;

    const result = cache.readQuery({
      query: GET_COLLECTION_PERMISSION_FOR_DIALOG_VIEW,
      variables: {
        collectionId: request.collectionId,
      },
    }) as GetCollectionPermissionForDialogViewQuery;

    const inviteMembers = result.collection?.inviteMembers || [];

    const existingMemberIndex = inviteMembers.findIndex(
      (m) => m.user.id === request.user.id,
    );

    if (existingMemberIndex !== -1) {
      modifyObject(
        cache,
        inviteMembers[existingMemberIndex].id,
        'CollectionInviteMemberModel',
        {
          permissionLevel: () => permissionLevel,
        },
      );
    } else {
      modifyObject(cache, request.collectionId, 'CollectionModel', {
        inviteMembers: (cachedMembers) => {
          return [
            ...cachedMembers,
            {
              __typename: 'CollectionInviteMemberModel',
              id: GenerateId.create(),
              user: request.user,
              permissionLevel,
              lastSeen: null,
            },
          ];
        },
      });
    }
  };

  const updatePostRequestCache = (
    cache: ApolloCache<any>,
    request: PermissionRequestFragmentUsePermissionRequestHandlerFragment,
    permissionLevel?: PermissionLevel,
  ) => {
    if (!request.postId || request.status !== PermissionRequestStatus.Approved)
      return;

    const result = cache.readQuery({
      query: GET_POST_PERMISSION_FOR_DAILOG_VIEW,
      variables: {
        postId: request.postId,
      },
    }) as GetPostPermissionForDialogViewQuery;

    const inviteMembers = result.post?.inviteMembers || [];

    const existingMemberIndex = inviteMembers.findIndex(
      (m) => m.user.id === request.user.id,
    );

    if (existingMemberIndex !== -1) {
      modifyObject(
        cache,
        inviteMembers[existingMemberIndex].id,
        'PostInviteMemberModel',
        {
          permissionLevel: () => permissionLevel,
        },
      );
    } else {
      modifyObject(cache, request.postId, 'PostModel', {
        inviteMembers: (cachedMembers) => {
          return [
            ...cachedMembers,
            {
              __typename: 'PostInviteMemberModel',
              id: GenerateId.create(),
              user: request.user,
              permissionLevel,
              lastSeen: null,
            },
          ];
        },
      });
    }
  };

  const onApprovePermissionRequest = async (
    input: ApprovePermissionRequestInput,
  ) => {
    await approvePermissionRequest({
      variables: {
        data: input,
      },
      update: (cache, { data }) => {
        if (!data) return;
        const request = data.approvePermissionRequest;

        updatePostRequestCache(
          cache,
          request,
          input.input.permissionLevel || undefined,
        );

        updateCollectionRequestCache(
          cache,
          request,
          input.input.permissionLevel || undefined,
        );
        evictObject(cache, request.id, 'PermissionRequestModel');
      },
    });
  };

  const onRejectPermissionRequest = async (
    input: RejectPermissionRequestInput,
  ) => {
    await rejectPermissionRequest({
      variables: {
        data: input,
      },
      update: (cache, { data }) => {
        if (!data) return;
        const request = data.rejectPermissionRequest;

        evictObject(cache, request.id, 'PermissionRequestModel');
      },
    });
  };

  const onCheckIsRequestExist = async (input: {
    postId?: string;
    collectionId?: string;
    brandId?: string;
  }) => {
    const { data } = await getMyPemissionRequests();
    if (input.postId) {
      return data?.myPermissionRequests.some(
        (request) => request.postId === input.postId,
      );
    }
    if (input.collectionId) {
      return data?.myPermissionRequests.some(
        (request) => request.collectionId === input.collectionId,
      );
    }
    if (input.brandId) {
      return data?.myPermissionRequests.some(
        (request) => request.brandId === input.brandId,
      );
    }

    return false;
  };

  return {
    myPermissionRequests: myPermissionRequests?.myPermissionRequests || [],
    onApprovePermissionRequest,
    onRejectPermissionRequest,
    onCreatePermissionRequest,
    onCheckIsRequestExist,
  };
};
