import { Box, Typography } from '@mui/material';
import { getNoteColorFromColor } from 'features/note/utils';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import { PostNotePreviewViewProps } from './types';

export const PostNotePreviewView = (props: PostNotePreviewViewProps) => {
  const { post, sx, componentsProps, showAuthor = true } = props;

  const postColor = useMemo(() => getNoteColorFromColor(post.color), [post]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        px: 4,
        py: 16,
        flexDirection: 'column',
        position: 'relative',
        gap: theme.spacing(4),
        color: postColor ? postColor.textColor : theme.colors?.primary.black,
        backgroundColor: post.color,
        ...sx,
      }}
    >
      <Typography
        sx={{
          ...theme.typography['headline-sm'],
          wordWrap: 'break-word',
        }}
        {...componentsProps?.title}
      >
        {post.title}
      </Typography>
      {showAuthor && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1.5),
          }}
        >
          <Typography variant="subhead-sm" sx={{ opacity: 0.7 }}>
            Updated {moment(post?.updatedAt).fromNow()}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
