/**
 * NOTE: This component UI is nearly identical to BillingUpgradeModal.
 * But the functionality is different so I have to duplicate the UI into this one.
 */

import { Box, Dialog, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export type DownloadMobileAppModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DownloadMobileAppModal = (props: DownloadMobileAppModalProps) => {
  const { isOpen, onClose } = props;

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          m: 0,
          p: 0,
          borderRadius: 6,
          maxWidth: 800,
          bgcolor: '#FAF3EC',
        },
      }}
      onClose={onClose}
    >
      <Box
        sx={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            maxWidth: '50%',
          }}
        >
          <Box
            component="video"
            src="/images/download-mobile-app-video-01.mp4"
            sx={{
              display: 'block',
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
            autoPlay
            muted
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: theme.spacing(12),
            p: 10,
          }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: theme.spacing(8),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: theme.spacing(3),
              }}
            >
              <Typography variant="headline-lg" fontSize={30}>
                Plot on the App Store! 🎉
              </Typography>
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[1000]}
              >
                Because we know the real work happens on mobile, collaborate on
                the go.
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: theme.spacing(2),
              }}
            >
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[1000]}
              >
                Scan to download the app
              </Typography>
              <Box
                component="img"
                alt="Download mobile app QR"
                src="/images/download-mobile-app-qr.png"
                sx={{ width: 128, height: 128, ml: -1.5 }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
