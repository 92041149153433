import { gql } from '@apollo/client';
import { Box, Button, Input, Typography } from '@mui/material';
import { AvatarGroup } from 'components/common/AvatarGroup';
import {
  AccessRequestAdminList,
  USER_FRAGMENT_ACCESS_REQUEST_ADMIN_LIST,
} from 'features/accessRequest/components';
import { avatarBgColors } from 'features/accessRequest/constants';
import { useAccessRequestHandlers } from 'features/accessRequest/hooks';
import {
  InternalOrganizationRole,
  OrganizationFragmentAccessRequestCreateViewFragment,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

export const ORGANIZATION_FRAGMENT_ACCESS_REQUEST_CREATE_VIEW = gql`
  fragment OrganizationFragmentAccessRequestCreateView on OrganizationModel {
    id
    name
    users {
      role
      hasBeenApprovedByAdmin
      ...UserFragmentAccessRequestAdminList
    }
  }
  ${USER_FRAGMENT_ACCESS_REQUEST_ADMIN_LIST}
`;

interface AccessRequestCreateViewProps {
  organization: OrganizationFragmentAccessRequestCreateViewFragment;
  setIsRequestCreated: () => void;
  onCancel: () => void;
}

export const AccessRequestCreateView = ({
  organization,
  setIsRequestCreated,
  onCancel,
}: AccessRequestCreateViewProps) => {
  const { onCreateOrganizationAccessRequest } = useAccessRequestHandlers();

  const [message, setMessage] = useState('');

  const { admins, internalUsers, userBgColorList } = useMemo(() => {
    const admins = organization.users.filter(
      (user) =>
        user.hasSignedUp &&
        !user.isDisabled &&
        user.hasBeenApprovedByAdmin &&
        user.role === InternalOrganizationRole.Admin,
    );

    const internalUsers =
      organization.users.filter(
        (u) => u.hasSignedUp && !u.isDisabled && u.hasBeenApprovedByAdmin,
      ) || [];

    const userBgColorList: Record<string, string> = {};
    internalUsers.forEach((user) => {
      const colorRandomIndex = Math.floor(
        Math.random() * avatarBgColors.length,
      );
      userBgColorList[user.id] = avatarBgColors[colorRandomIndex] ?? '';
    });

    return {
      admins,
      internalUsers,
      userBgColorList,
    };
  }, [organization]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <Typography
        variant="headline-xl"
        fontSize={40}
        fontWeight={500}
        letterSpacing="-1.6px"
      >
        Join your teammates on Plot
      </Typography>
      <AvatarGroup
        users={internalUsers}
        avatarSize={48}
        max={6}
        hideAdditionalAvatarTooltip
        // Update the font size when the users length is greater than 100 because the text goes outside the additional avatar
        additionalAvatarSx={{
          fontSize: internalUsers.length > 100 ? 17 : 22,
        }}
        avatarConditionalSx={(user) => {
          return {
            bgcolor: userBgColorList[user.id],
            border: `2px solid rgba(250, 243, 236, 0.50) !important`,
            '& span': {
              fontWeight: 600,
              color: theme.colors?.primary.parchment,
            },
          };
        }}
      />
      <Typography variant="subhead-xl">
        Send a request to your admins to join <b>{organization.name}</b>{' '}
        Workspace:
      </Typography>
      <AccessRequestAdminList admins={admins} />
      <Input
        disableUnderline
        multiline
        rows={6}
        placeholder="Write a comment..."
        sx={{
          padding: theme.spacing(3, 4),
          bgcolor: 'rgba(35, 6, 3, 0.05)',
          ...theme.typography['subhead-xl'],
          borderRadius: 2,
        }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          variant="text"
          sx={{
            ...theme.typography['headline-sm'],
            color: theme.colors?.primary.black,
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          sx={{
            ...theme.typography['headline-sm'],
            bgcolor: theme.colors?.primary.black,
            color: theme.colors?.primary.parchment,
            borderRadius: 2,
            padding: theme.spacing(2, 4),
            ':hover': {
              bgcolor: theme.colors?.primary.black,
            },
          }}
          onClick={() => {
            onCreateOrganizationAccessRequest({
              message,
            });
            setTimeout(() => setIsRequestCreated(), 1000);
          }}
        >
          Request to join
        </Button>
      </Box>
    </Box>
  );
};
