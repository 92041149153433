import { Typography } from '@mui/material';
import { HighlightedTextRenderer } from 'features/search';
import {
  AttachmentSearchHitFragmentItemNameFragment,
  CollectionSearchHitFragmentItemNameFragment,
  LinkSearchHitFragmentItemNameFragment,
  NoteSearchHitFragmentItemNameFragment,
  PostModel,
  TaskSearchHitFragmentItemNameFragment,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { CollectionBreadcrumbs } from 'features/collection';

type SearchItemNameProps = {
  searchHit:
    | NoteSearchHitFragmentItemNameFragment
    | LinkSearchHitFragmentItemNameFragment
    | AttachmentSearchHitFragmentItemNameFragment
    | TaskSearchHitFragmentItemNameFragment
    | CollectionSearchHitFragmentItemNameFragment;
  match?: string;
};

export const SearchItemTitle = (props: SearchItemNameProps) => {
  const { searchHit, match } = props;

  const title: string = useMemo(() => {
    if (searchHit.item.__typename === 'PostModel') {
      const post = searchHit.item as PostModel;

      // Note
      if (post.title) {
        return post.title;
      }

      // Link
      if (post.urlMetadata && post.urlMetadata.length > 0) {
        const metadata = post.urlMetadata[0].metadata;
        return (
          metadata?.title || metadata?.description || post.urlMetadata[0].url
        );
      }

      // Attachment
      if (post.attachment) {
        return post.attachment.name;
      }

      return '';
    }

    if (searchHit.item.__typename === 'TaskModel') {
      return searchHit.item.name;
    }

    return '';
  }, [searchHit]);

  return searchHit.item.__typename === 'CollectionModel' ? (
    <CollectionBreadcrumbs
      collection={searchHit.item}
      delimiter="/"
      componentProps={{
        text: {
          lastLocationSx: {
            color: theme.colors?.primary.black,
          },
        },
      }}
    />
  ) : title === '' ? null : (
    <Typography
      display="block"
      color={theme.colors?.primary.black}
      variant="body-lg"
      maxHeight={20}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      <HighlightedTextRenderer text={title} match={match || ''} />
    </Typography>
  );
};
