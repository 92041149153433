import { Box, SxProps } from '@mui/material';
import { ResourceUploadItem } from 'hooks/useResourceUploadQueue';
import { memo, useEffect, useState } from 'react';
import { useLocalFileType } from './hooks/useLocalFileType';

export type FilePreviewProps = {
  resource: ResourceUploadItem;
};

// Render local file, support image/video/pdf
export const FilePreview = memo(({ resource }: FilePreviewProps) => {
  const [preview, setPreview] = useState<string>();
  const { type } = useLocalFileType(resource);

  useEffect(() => {
    if (!resource?.resource?.content) {
      setPreview(undefined);
      return;
    }

    let objectUrl = '';

    try {
      // create url from File
      objectUrl = URL.createObjectURL(resource.resource.content as File);
      setPreview(objectUrl);
    } catch (error) {
      console.error(error);
    }

    // free memory when ever this component is unmounted
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
    // eslint-disable-next-line
  }, [JSON.stringify(resource.resource.content)]);

  const previewStyle: SxProps = {
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    overflow: 'hidden',
    objectFit: 'cover',
    position: 'absolute',
    background: `linear-gradient(0deg, rgba(35, 6, 3, 0.40) 0%, rgba(35, 6, 3, 0.40) 100%), url(<path-to-image>), lightgray -0.012px 0px / 100.045% 122.936% no-repeat`,
  };

  if (type === 'pdf') {
    return (
      <Box
        component="object"
        data={preview}
        sx={{
          ...previewStyle,
          objectFit: 'contain',
        }}
      />
    );
  }

  if (type === 'video') {
    return (
      <Box
        component="video"
        src={preview}
        sx={{
          ...previewStyle,
          objectFit: 'contain',
        }}
      />
    );
  }

  return <Box component="img" src={preview} sx={previewStyle} />;
});
