import { Box, Button, IconButton, Typography } from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearTagRight } from 'components/icons/components/linear/IconLinearTagRight';
import { IconOutlineCrossXClose } from 'components/icons/components/outline/IconOutlineCrossXClose';
import { OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment } from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { EditCompetitorLabels } from '../../editCompetitorLabels';

type Props = {
  brandId: string;
  selectedIds: string[];
  onClose: () => void;
  onLabelToggle: (
    label: OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment,
    type: 'add' | 'remove',
  ) => void;
};

export const CommentInsightsBulkActions = ({
  brandId,
  selectedIds,
  onClose,
  onLabelToggle,
}: Props) => {
  const [selectedLabels, setSelectedLabels] = useState<
    OrganizationSocialPostCommentLabelFragmentEditCompetitorLabelsFragment[]
  >([]);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: theme.spacing(16),
        left: '50%',
        transform: 'translateX(-50%)',
        p: theme.spacing(4),
        borderRadius: theme.spacing(3),
        background: 'rgba(255, 255, 255, 0.80)',
        backdropFilter: 'blur(50px)',
        boxShadow:
          '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);',
      }}
    >
      <Box
        sx={{ display: 'flex', gap: theme.spacing(4), alignItems: 'center' }}
      >
        <Typography variant="body-md" fontWeight={600}>
          {selectedIds.length} ideas selected:
        </Typography>

        <EditCompetitorLabels
          brandId={brandId}
          selectedLabels={[...selectedLabels]}
          onLabelToggle={(label) => {
            if (selectedLabels.includes(label)) {
              onLabelToggle(label, 'remove');
              setSelectedLabels(
                selectedLabels.filter((l) => l.id !== label.id),
              );
            } else {
              onLabelToggle(label, 'add');
              setSelectedLabels([...selectedLabels, label]);
            }
          }}
          renderCustomButton={() => (
            <Button
              sx={{
                backgroundColor: theme.colors?.utility[275],
                color: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                display: 'flex',
                gap: 2,
                p: theme.spacing(1, 2),
                alignItems: 'center',
                '&:hover': { backgroundColor: theme.colors?.utility[275] },
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(35, 6, 3, 0.05);',
                  p: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                }}
              >
                <IconLinearTagRight size={16} />
              </Box>
              <Typography fontWeight={500} variant="body-xl">
                Label
              </Typography>
              <IconLinearArrowDown size={16} />
            </Button>
          )}
        />

        <IconButton onClick={() => onClose()}>
          <IconOutlineCrossXClose
            color={theme.colors?.utility[700]}
            size={24}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
