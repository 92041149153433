import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useUserContext } from 'contexts/users/User.context';
import {
  CommentContainerView,
  CommentInviteMentionUserDialog,
  useCommentInviteMemberHandlers,
  useCommentPermissionRequestHandlers,
  usePostCommentHandlers,
} from 'features/comments';
import { PostSectionHeaderView } from 'features/post';
import { getMentionedUserIds } from 'features/tiptap';
import {
  CommentFragmentCommentContainerViewFragmentDoc,
  CommentFragmentPostCommentViewFragment,
  CommentInputData,
  GeneralPermission,
  PostFragmentPostSectionHeaderViewFragmentDoc,
  PostPermission,
  PostType,
  useGetPostForInviteMemberHandlersQuery,
  useGetPostForPostCommentViewQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

export const COMMENT_FRAGMENT_POST_COMMENT_VIEW = gql`
  fragment CommentFragmentPostCommentView on CommentModel {
    id
    ...CommentFragmentCommentContainerView
  }
  ${CommentFragmentCommentContainerViewFragmentDoc}
`;

export const POST_FRAGMENT_POST_COMMENT_VIEW = gql`
  fragment PostFragmentPostCommentView on PostModel {
    id
    type
    myPermissions
    ...PostFragmentPostSectionHeaderView
  }
  ${PostFragmentPostSectionHeaderViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetPostForPostCommentView($id: String!) {
    post(id: $id) {
      id
      ...PostFragmentPostCommentView
    }
  }
  ${POST_FRAGMENT_POST_COMMENT_VIEW}
`;

export type PostCommentViewProps = {
  postId: string;
  comments: CommentFragmentPostCommentViewFragment[];
};

export const PostCommentView = (props: PostCommentViewProps) => {
  const { postId, comments } = props;
  const { user } = useUserContext();
  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>([]);
  const [commentHtml, setCommentHtml] = useState<string>('');
  const [parentThreadId, setParentThreadId] = useState<string>('');
  const location = useLocation();
  const { onInvitePostMembers } = useCommentInviteMemberHandlers();
  const { onCreatePermissionRequest } = useCommentPermissionRequestHandlers();

  const {
    isOpen: isOpenInviteMentionUserDialog,
    onClose: onCloseInviteMentionUserDialog,
    onOpen: onOpenInviteMentionUserDialog,
  } = useDisclosure();

  const { data } = useGetPostForPostCommentViewQuery({
    variables: {
      id: postId,
    },
  });
  const { data: postData } = useGetPostForInviteMemberHandlersQuery({
    variables: {
      postId,
    },
  });
  const post = data?.post;
  const myPermissions = post?.myPermissions || [];

  const { onReactToComment, onCreatePostComment } = usePostCommentHandlers();

  const onCreateComment = (data: CommentInputData) => {
    onCreatePostComment(
      {
        postId,
        data,
      },
      { postId },
    );
    onCloseInviteMentionUserDialog();
  };

  const onInviteMentioneUsersAndCreateComment = (data: CommentInputData) => {
    setCommentHtml(data.comment);
    setParentThreadId(data.parentThreadId || '');
    // The list of mentioned user ids that are not invited to the post
    const invitedMemberIds: string[] = [];

    // Get mentioned user ids from the comment html
    const mentionIds = getMentionedUserIds(data.comment).filter(
      (id) => id !== user?.id,
    );

    // This is the list of members that are already invited to the post
    const inviteMembers = postData?.post?.inviteMembers || [];

    // Removed the mentioned user ids that are already into the post
    const filteredMentionIds: string[] = mentionIds.filter(
      (mentionId) =>
        !inviteMembers.some((member) => member.user.id === mentionId),
    );

    const users = [
      ...(user?.organization.externalUsers || []),
      ...(user?.organization.users || []),
    ];

    for (const mentionId of filteredMentionIds) {
      const member = users.find((u) => u.id === mentionId);

      // If general permission is set to "Org" level, check the post organization id against the mentioned user's organization id
      // If the mentioned user is not in the same organization as the post, add it to the `invitedMemberIds`; if they are in the same organization, it means the user already has access to the post
      if (
        member &&
        postData?.post?.generalPermission.includes(
          GeneralPermission.OrganizationMembers,
        )
      ) {
        if (member.organization.id !== postData.post.organizationId) {
          invitedMemberIds.push(mentionId);
        }
      } else {
        // This case only happens when the post is private
        invitedMemberIds.push(mentionId);
      }
    }

    setMentionedUserIds(invitedMemberIds);

    if (invitedMemberIds.length > 0) {
      onOpenInviteMentionUserDialog();
    } else {
      onCreateComment(data);
    }
  };

  if (!post) {
    return null;
  }

  return (
    <>
      <Link
        to={
          post?.type === PostType.Note
            ? PlotRoutes.juiceboxNote({ id: post.id, title: post.title })
            : PlotRoutes.juice(post.id)
        }
        state={{
          backgroundLocation: location,
        }}
      >
        <PostSectionHeaderView
          post={post}
          sx={{
            px: theme.spacing(4),
          }}
        />
      </Link>
      <Box sx={{ wordBreak: 'break-word', py: 2 }}>
        {comments.map((comment) => {
          return (
            <CommentContainerView
              key={comment.id}
              comment={comment}
              canComment={post.myPermissions?.includes(PostPermission.Comment)}
              onReact={(commentId, emoji) => {
                onReactToComment(commentId, emoji);
              }}
              onReply={(data) => {
                onInviteMentioneUsersAndCreateComment({
                  ...data,
                  parentThreadId: comment.id,
                });
              }}
            />
          );
        })}
      </Box>
      {isOpenInviteMentionUserDialog && (
        <CommentInviteMentionUserDialog
          type={post.type === PostType.Note ? 'note' : 'post'}
          variant={
            myPermissions.includes(PostPermission.Update)
              ? 'invite'
              : 'request-access'
          }
          mentionedUserIds={mentionedUserIds}
          onCreateComment={() => {
            onCreateComment({ comment: commentHtml, parentThreadId });
          }}
          onInviteOrRequestAccessAndCreateComment={() => {
            onCreateComment({ comment: commentHtml, parentThreadId });
            if (myPermissions.includes(PostPermission.Update)) {
              onInvitePostMembers({
                postId,
                userIds: mentionedUserIds,
              });
            } else {
              onCreatePermissionRequest(
                mentionedUserIds.map((userId) => ({
                  userId,
                  postId,
                })),
              );
            }
          }}
        />
      )}
    </>
  );
};
