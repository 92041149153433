import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconOutlineInfoCircle } from 'components/icons/components/outline/IconOutlineInfoCircle';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import {
  ContentIdeaFragmentContentIdeaContextMenuFragment,
  ContentIdeaPermission,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { theme } from 'styles/theme';
import { useContentIdeaHandlers } from './useContentIdeaHandlers';

// eslint-disable-next-line
gql`
  fragment ContentIdeaFragmentContentIdeaContextMenu on ContentIdeaModel {
    id
    myPermissions
    name
  }
`;

type ContentIdeaContextMenuProps = Omit<
  ContextMenuProps,
  'options' | 'renderButton'
> &
  Partial<Pick<ContextMenuProps, 'renderButton'>> & {
    contentIdea: ContentIdeaFragmentContentIdeaContextMenuFragment;
    options?: {
      delete?: boolean | (() => void);
    };
    onAfterDelete?: () => void;
  };

export const ContentIdeaContextMenu = (props: ContentIdeaContextMenuProps) => {
  const {
    contentIdea,
    options = {
      delete: true,
    },
    onAfterDelete,
    ...rest
  } = props;

  const canDelete = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Delete,
  );

  const { onDeleteContentIdea } = useContentIdeaHandlers();
  const {
    dialog: deleteContentIdeaDialog,
    onOpen: openDeleteContentIdeaConfirmationDialog,
  } = useConfirmationDialog();

  const menuOptions = [
    ...(options?.delete === false
      ? []
      : [
          {
            title: 'Delete Content',
            icon: IconOutlineTrash,
            color: theme.colors?.utility['pink-3'],
            onClick: () =>
              openDeleteContentIdeaConfirmationDialog({
                title: (
                  <Typography
                    variant="headline-lg"
                    color={theme.colors?.primary.black}
                  >
                    Delete Content?
                  </Typography>
                ),
                subtitle: (
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 3,
                    }}
                  >
                    <Typography
                      variant="subhead-xl"
                      color={theme.colors?.primary.black}
                    >
                      Deleting this content will permanently remove the
                      collection <b>“{contentIdea.name}.”</b>
                    </Typography>
                    <Typography
                      variant="subhead-xl"
                      color={theme.colors?.primary.black}
                    >
                      Do you want to continue?
                    </Typography>
                  </Box>
                ),
                confirmText: 'Yes, continue to delete.',
                onConfirm: () => {
                  onDeleteContentIdea(contentIdea.id);
                  onAfterDelete?.();
                },
              }),
            disabled: !canDelete,
            closeOnClick: false,
          },
        ]),
  ];

  return (
    <>
      <ContextMenu
        onClick={(e) => {
          e.stopPropagation();
        }}
        renderButton={
          rest.renderButton === false
            ? false
            : rest.renderButton
            ? rest.renderButton
            : () => (
                <IconButton>
                  <IconLinearMore
                    style={{
                      color: theme.colors?.primary.black,
                      transform: `rotate(90deg)`,
                    }}
                  />
                </IconButton>
              )
        }
        options={menuOptions.map((option) => {
          const Icon = option.icon;
          return {
            // isDivider: option.isDivider,
            renderOption: () => (
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {Icon && <Icon size={16} color={option.color} />}
                </Box>
                <Typography variant="headline-xs" color={option.color}>
                  {option.title}
                </Typography>
              </Box>
            ),
            onClick: option.onClick,
            disabled: option.disabled,
            closeOnClick: option.closeOnClick,
          };
        })}
        MenuListProps={{
          sx: {
            width: 266,
            gap: `${theme.spacing(2)} !important`,
          },
        }}
        renderExtraBottom={
          !canDelete
            ? () => {
                return (
                  <Box
                    sx={{
                      mt: 3,
                      py: 4,
                      mx: 3,
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 3,
                      borderTop: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <IconOutlineInfoCircle
                      size={16}
                      style={{
                        color: theme.colors?.primary.maroon,
                        flexShrink: 0,
                      }}
                    />
                    <Typography variant="subhead-lg">
                      You do not have the permission to delete this content
                      idea.
                    </Typography>
                  </Box>
                );
              }
            : undefined
        }
        disableRestoreFocus
        PaperProps={{
          style: {
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(20px)`,
            border: 'none',
          },
        }}
        {...rest}
      />
      {deleteContentIdeaDialog}
    </>
  );
};
