import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Chip, Typography } from '@mui/material';
import { getCustomStatusStyles } from 'features/contentIdea/utils';
import {
  ContentIdeaFieldValueFragmentContentIdeaFieldSelectFragmentDoc,
  ContentIdeaFragmentContentIdeaReminderModalFragmentDoc,
  ContentIdeaFragmentContentIdeaStatusFragment,
  ContentIdeaPermission,
  FieldType,
  useGetContentIdeaForContentIdeaStatusLazyQuery,
} from 'graphql/generated';
import { ContentIdeaField, SelectFieldProps } from '../field';
import { ContentIdeaReminderModal } from '../reminderModal';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_STATUS = gql`
  fragment ContentIdeaFragmentContentIdeaStatus on ContentIdeaModel {
    id
    myPermissions
    status {
      id
      contentIdeaField {
        id
      }
      ...ContentIdeaFieldValueFragmentContentIdeaFieldSelect
    }
    ...ContentIdeaFragmentContentIdeaReminderModal
  }
  ${ContentIdeaFieldValueFragmentContentIdeaFieldSelectFragmentDoc}
  ${ContentIdeaFragmentContentIdeaReminderModalFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetContentIdeaForContentIdeaStatus($id: String!) {
    contentIdea(id: $id) {
      id
      stage
    }
  }
`;

export type ContentIdeaStatusProps = {
  contentIdea: ContentIdeaFragmentContentIdeaStatusFragment;
  renderTrigger?: SelectFieldProps['renderTrigger'];
};

export const ContentIdeaStatus = (props: ContentIdeaStatusProps) => {
  const { contentIdea, renderTrigger } = props;
  const status = contentIdea.status.value?.select;

  const {
    isOpen: isReminderModalOpen,
    onOpen: openReminderModal,
    onClose: closeReminderModal,
  } = useDisclosure();

  const [getContentIdea] = useGetContentIdeaForContentIdeaStatusLazyQuery();

  // If the status is changed to 'ReadyToPost' and the due date is not set, open the reminder modal
  const onAfterChange = (value: string) => {
    if (value === 'ReadyToPost' && !contentIdea.dueDate.value?.date) {
      openReminderModal();
    }

    // Refetching content idea to update the stage in the cache
    // We have a custom logic in the BE that when the status is changed,
    // it'll automatically be moved to different stages depending on the status
    getContentIdea({
      variables: { id: contentIdea.id },
      fetchPolicy: 'cache-and-network',
    });
  };

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  return (
    <>
      <ContentIdeaField
        id={contentIdea.status.contentIdeaField.id}
        valueId={contentIdea.status.id}
        value={{ select: status?.value }}
        type={FieldType.Select}
        showMoreOption={false}
        renderTrigger={
          renderTrigger ||
          (({ options }) => {
            const option = options.find((o) => o.value === status?.value);

            // FIXME: THIS IS A HACK FOR STATUS COLORS
            const customStyles = getCustomStatusStyles(
              option?.value || '',
              option?.bgcolor || '',
            );

            return (
              <Chip
                sx={{
                  borderRadius: 1,
                  ...(customStyles.color
                    ? {
                        '.MuiChip-label': {
                          filter: 'none !important',
                          color: customStyles.color,
                        },
                      }
                    : {}),
                  ...customStyles,
                }}
                label={
                  <Typography variant="headline-xs">
                    {option?.label || 'Select'}
                  </Typography>
                }
                variant="filled-borderless-color-dodge"
              />
            );
          })
        }
        onAfterChange={(v) => onAfterChange(v as string)}
        readOnly={!canEdit}
      />
      <ContentIdeaReminderModal
        contentIdea={contentIdea}
        open={isReminderModalOpen}
        onClose={closeReminderModal}
      />
    </>
  );
};
