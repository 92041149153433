import { Masonry } from '@mui/lab';
import { Box, Skeleton } from '@mui/material';

interface Props {
  bgcolor?: string;
}

export const MasonrySkeleton = (props: Props) => {
  const { bgcolor } = props;

  return (
    <Box sx={{ mr: -4 }}>
      <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
        {[600, 250, 300, 200, 450, 300, 400].map((height, index) => {
          return (
            <Box key={index} height={height}>
              <Skeleton
                key={index}
                sx={{
                  height: '100%',
                  borderRadius: 3,
                  transform: 'scale(1)',
                  bgcolor,
                }}
              />
            </Box>
          );
        })}
      </Masonry>
    </Box>
  );
};
