import { OrganizationBillingFragmentCollectionPaywallFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { Box, SxProps, Typography } from '@mui/material';
import { ProgressBar } from 'components/common/ProgressBar';
import { useMemo } from 'react';
import { Parser } from 'utils/parser';
import { IconCustomInfinity } from 'components/icons/components/custom/IconCustomInfinity';

type BillingCollectionLimitProgressProps = {
  organizationBilling: OrganizationBillingFragmentCollectionPaywallFragment;
  filledColor?: string;
  sx?: SxProps;
};

export const BillingCollectionLimitProgress = (
  props: BillingCollectionLimitProgressProps,
) => {
  const { organizationBilling, filledColor, sx } = props;

  const percentageUsed = useMemo(() => {
    if (organizationBilling.collectionUsageLimit) {
      return Parser.to2Decimal(
        (organizationBilling.collectionUsageCount /
          organizationBilling.collectionUsageLimit) *
          100,
      );
    }
    return 0;
  }, [organizationBilling]);

  const progressBarFilledColor = useMemo(() => {
    if (percentageUsed >= 0 && percentageUsed < 60) {
      return theme.colors?.utility['green-2'];
    }

    if (percentageUsed >= 60 && percentageUsed < 80) {
      return theme.colors?.utility['yellow-3'];
    }

    if (percentageUsed >= 80) {
      return theme.colors?.utility['pink-3'];
    }

    return theme.colors?.primary.maroon;
  }, [percentageUsed]);

  return !organizationBilling.collectionUsageLimit ? null : (
    <Box display="flex" flexDirection="column" gap={theme.spacing(2)} sx={sx}>
      <Box>
        <Typography color={theme.colors?.utility[1000]} variant="headline-xxs">
          Collection
        </Typography>
      </Box>

      {organizationBilling.collectionUsageLimit ? (
        <>
          <ProgressBar
            variant="number"
            completed={organizationBilling.collectionUsageCount}
            total={organizationBilling.collectionUsageLimit}
            bgColor={theme.colors?.utility[275]}
            filledColor={filledColor ?? progressBarFilledColor}
          />

          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            {organizationBilling.collectionUsageCount} of{' '}
            {organizationBilling.collectionUsageLimit} collection used
          </Typography>
        </>
      ) : (
        <Box
          display="flex"
          gap={theme.spacing(1)}
          justifyContent="flex-start"
          alignItems="center"
        >
          <IconCustomInfinity color={theme.colors?.utility[900]} />
          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            Unlimited Collection
          </Typography>
        </Box>
      )}
    </Box>
  );
};
