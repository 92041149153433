import { useRef } from 'react';
import { COMMAND_TYPE } from '../constants';
import { ActiveCommandState } from '../types';

export const useCommandListeners = () => {
  const commandListeners = useRef(
    Object.values(COMMAND_TYPE).reduce((acc, commandType) => {
      return {
        ...acc,
        [commandType]: [],
      };
    }, {} as Record<COMMAND_TYPE, Function[]>),
  );

  /**
   * Register listener callbacks to active commands based on type.
   * When an active command of certain type changes (e.g. data response is updated),
   * we'll call the registered callbacks.
   */
  const registerCommandListener = (
    commandType: COMMAND_TYPE,
    callback: (activeCommand: ActiveCommandState) => void,
  ) => {
    commandListeners.current[commandType].push(callback);
  };

  const unregisterCommandListener = (
    commandType: COMMAND_TYPE,
    callback: (activeCommand: ActiveCommandState) => void,
  ) => {
    commandListeners.current[commandType] = commandListeners.current[
      commandType
    ].filter((cb) => cb !== callback);
  };

  return {
    commandListeners,
    registerCommandListener,
    unregisterCommandListener,
  };
};
