import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

type Props = {
  title?: string;
  summaryData: {
    icon: React.ReactNode;
    label: React.ReactNode;
    value: string | number;
  }[];
  componentProps?: {
    container?: {
      sx?: SxProps;
    };
  };
};

export const SLAAnalyticsSummary = ({
  title = 'Summary',
  summaryData,
  componentProps,
}: Props) => {
  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        height: '100%',
        flex: 1,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        ...componentProps?.container?.sx,
      }}
    >
      <Typography
        variant="headline-sm"
        color={theme.colors?.utility[700]}
        pb={1}
      >
        {title}
      </Typography>

      {summaryData.map((item) => (
        <Typography
          variant="headline-sm"
          display="flex"
          width="100%"
          justifyContent="space-between"
        >
          <Box
            flex={2}
            component="span"
            display="flex"
            alignItems="center"
            gap={2}
          >
            {item.icon}
            {item.label}
          </Box>
          <Box flex={1} component="span" color={theme.colors?.utility[800]}>
            {item.value}
          </Box>
        </Typography>
      ))}

      {/* <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={2}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldUser size={14} />
          Followers
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          VALUE
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={2}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldVideoPlay size={14} />
          Total number of posts
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          VALUE
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={2}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldArrowUp size={14} />
          Number of posts/week
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          VALUE
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={2}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldVideoPlay size={14} />
          Total Engagement
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          VALUE
        </Box>
      </Typography>
      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={2}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldVideoPlay size={14} />
          Total view count
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          VALUE
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={2}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldHeart size={14} />
          Average likes
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          VALUE
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={2}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldVideoPlay size={14} />
          Average view count
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          VALUE
        </Box>
      </Typography> */}
    </Box>
  );
};
