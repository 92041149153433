import { Box, Typography } from '@mui/material';
import { IconLinearArrowLeft1 } from 'components/icons/components/linear/IconLinearArrowLeft1';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { AISearchButton } from 'features/search';
import React, { useState } from 'react';
import { theme } from 'styles/theme/theme';
import { useUserContext } from 'contexts/users/User.context';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  BillingUpgradeModal,
  BillingUpgradeModalProps,
} from 'features/billing';
import { DateUtils } from 'utils/date';
import { BillingSubscriptionStatus } from 'graphql/generated';
import { StyledInput, StyledSearchBar } from './styles';

export interface SearchBarProps {
  loading?: boolean;
  onChange: (text: string) => void;
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onActionKeyPress?: (text: string) => void;
  onNavigateBack?: () => void;
  placeholder: string;
  showingAIResults: boolean;
  componentsProps?: {
    billingUpgradeModal?: Pick<
      BillingUpgradeModalProps,
      'onRequestParentModalClose'
    >;
  };
}

export const SearchBar = React.memo(
  ({
    loading,
    onChange,
    placeholder,
    onActionKeyPress,
    onNavigateBack,
    onKeyDown,
    showingAIResults,
    componentsProps,
  }: SearchBarProps) => {
    const { orgBilling } = useUserContext();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const [searchValue, setSearchValue] = useState('');

    const handleAIButton = () => {
      if (!orgBilling?.aiFeaturesEnabled) {
        onOpen();
        return;
      }

      if (onActionKeyPress && searchValue) {
        onActionKeyPress(searchValue);
      }
    };

    return (
      <>
        <StyledSearchBar gap={3}>
          {onNavigateBack ? (
            <Box
              display="flex"
              onClick={onNavigateBack}
              sx={{ cursor: 'pointer' }}
            >
              <IconLinearArrowLeft1
                size={16}
                color={theme.colors?.primary.black}
              />
            </Box>
          ) : (
            <IconOutlineSearchNormal1
              size={16}
              color={theme.colors?.primary.black}
            />
          )}
          <StyledInput
            autoFocus
            disableUnderline
            placeholder={placeholder}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value);
              }
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onActionKeyPress && searchValue) {
                e.preventDefault();
                e.stopPropagation();

                handleAIButton();
              }
              if (onKeyDown) {
                onKeyDown(e);
              }
            }}
            endAdornment={
              <Box
                sx={{
                  display: 'flex',
                  gap: theme.spacing(2),
                  alignItems: 'center',
                }}
              >
                {orgBilling?.subscription?.status ===
                  BillingSubscriptionStatus.Trialing &&
                  orgBilling?.subscription?.trialEnd && (
                    <Typography
                      variant="subhead-sm"
                      color={theme.colors?.utility[800]}
                      width={70}
                    >
                      {`${DateUtils.fromNowInDays(
                        (orgBilling?.subscription.trialEnd ?? 0) * 1000,
                      )} left`}
                    </Typography>
                  )}

                <AISearchButton
                  loading={loading && showingAIResults}
                  showingAIResults={!loading && showingAIResults}
                  disabled={searchValue === ''}
                  onClick={handleAIButton}
                />
              </Box>
            }
          />
        </StyledSearchBar>

        <BillingUpgradeModal
          isOpen={isOpen}
          onClose={onClose}
          {...componentsProps?.billingUpgradeModal}
        />
      </>
    );
  },
);
