import { gql } from '@apollo/client';
import { useDebounce, useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Divider,
  MenuItem,
  Popover,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'components/common/Toast';
import { typography } from 'components/common/Typography/styles';
import { IconBoldCalendar1 } from 'components/icons/components/bold/IconBoldCalendar1';
import { IconBoldTimerPause } from 'components/icons/components/bold/IconBoldTimerPause';
import { IconCustomUser } from 'components/icons/components/custom/IconCustomUser';
import { IconLinearArrowCircleDown } from 'components/icons/components/linear/IconLinearArrowCircleDown';
import { IconLinearCloud } from 'components/icons/components/linear/IconLinearCloud';
import { IconLinearMainComponent } from 'components/icons/components/linear/IconLinearMainComponent';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import { IconLinearSubtitle } from 'components/icons/components/linear/IconLinearSubtitle';
import { IconLinearText } from 'components/icons/components/linear/IconLinearText';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconLinearVideoTick } from 'components/icons/components/linear/IconLinearVideoTick';
import { IconLinearVideoVertical } from 'components/icons/components/linear/IconLinearVideoVertical';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineCopy } from 'components/icons/components/outline/IconOutlineCopy';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { IconOutlineLink21 } from 'components/icons/components/outline/IconOutlineLink21';
import { IconOutlineStatus } from 'components/icons/components/outline/IconOutlineStatus';
import { IconOutlineTask } from 'components/icons/components/outline/IconOutlineTask';
import { IconOutlineTickCircle } from 'components/icons/components/outline/IconOutlineTickCircle';
import { IconOutlineTickSquare } from 'components/icons/components/outline/IconOutlineTickSquare';
import {
  ContentIdeaFieldFragmentContentIdeaFieldNameFragment,
  useDeleteContentIdeaFieldForContentIdeaFieldNameMutation,
  useDuplicateContentIdeaFieldForContentIdeaFieldNameMutation,
  useGetContentIdeaFieldsForContentIdeaFieldNameQuery,
  useUpdateContentIdeaFieldForContentIdeaFieldNameMutation,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject, getCustomOperationContext } from 'utils/apollo';
import { fieldTypeIconMap } from '../field/AddFieldButton';

// TODO: Make `yarn generate-icons` generate a mapping object from icon name to icon component
export const fieldNameIconMap = {
  IconBoldCalendar1,
  IconCustomUser,
  IconOutlineStatus,
  IconLinearSave,
  IconBoldTimerPause,
  IconLinearMainComponent,
  IconLinearSubtitle,
  IconLinearCloud,
  IconLinearVideoTick,
  IconLinearVideoVertical,
  IconLinearMonitorMobile,
  IconOutlineTask,
  IconOutlineTickSquare,
  IconOutlineTickCircle,
  IconOutlineCalendar,
  IconLinearUser,
  IconOutlineLink,
  IconOutlineLink21,
  IconOutlineLink2,
  IconLinearArrowCircleDown,
  IconLinearText,
};

export const CONTENT_IDEA_FIELD_FRAGMENT_CONTENT_IDEA_FIELD_NAME = gql`
  fragment ContentIdeaFieldFragmentContentIdeaFieldName on ContentIdeaFieldModel {
    id
    name
    icon
    type
    isPrimaryField
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaFieldForContentIdeaFieldName(
    $data: UpdateContentIdeaFieldInput!
  ) {
    updateContentIdeaField(data: $data) {
      id
      ...ContentIdeaFieldFragmentContentIdeaFieldName
    }
  }
  ${CONTENT_IDEA_FIELD_FRAGMENT_CONTENT_IDEA_FIELD_NAME}
`;

// eslint-disable-next-line
gql`
  mutation DuplicateContentIdeaFieldForContentIdeaFieldName(
    $data: DuplicateContentIdeaFieldInput!
  ) {
    duplicateContentIdeaField(data: $data) {
      id
      ...ContentIdeaFieldFragmentContentIdeaFieldName
    }
  }
  ${CONTENT_IDEA_FIELD_FRAGMENT_CONTENT_IDEA_FIELD_NAME}
`;

// eslint-disable-next-line
gql`
  mutation DeleteContentIdeaFieldForContentIdeaFieldName(
    $data: DeleteContentIdeaFieldInput!
  ) {
    deleteContentIdeaField(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetContentIdeaFieldsForContentIdeaFieldName(
    $filters: ContentIdeaFieldFilters
  ) {
    contentIdeaFields(filters: $filters) {
      id
      ...ContentIdeaFieldFragmentContentIdeaFieldName
    }
  }
  ${CONTENT_IDEA_FIELD_FRAGMENT_CONTENT_IDEA_FIELD_NAME}
`;

export type ContentIdeaFieldNameProps = {
  contentIdeaField: ContentIdeaFieldFragmentContentIdeaFieldNameFragment;
  contentIdeaId?: string;
  sx?: SxProps;
  isNew?: boolean;
  readOnly?: boolean;

  /**
   * Callback after the field has been duplicated/updated.
   * Should use cache manipulation, but let's keep it simple for now.
   * See ContentIdeaDetailView.tsx::L149
   */
  onAfterUpdate?: () => void;
  onAfterDuplicate?: () => void;
};

export const ContentIdeaFieldName = (props: ContentIdeaFieldNameProps) => {
  const {
    contentIdeaField,
    contentIdeaId,
    sx,
    isNew = false,
    readOnly = false,
    onAfterUpdate,
    onAfterDuplicate,
  } = props;

  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isPopoverForManagingFieldOpen,
    onOpen: openPopoverForManagingField,
    onClose: closePopoverForManagingField,
  } = useDisclosure();

  // Open popover when the field is new
  // 300ms delay to ensure the anchor element is rendered
  useEffect(() => {
    if (isNew) {
      setTimeout(() => {
        openPopoverForManagingField();
      }, 300);
    }
  }, [isNew]); // eslint-disable-line

  // Track the name of field when users edit it in the popover
  const [internalName, setInternalName] = useState(contentIdeaField.name);
  const [updateContentIdeaField] =
    useUpdateContentIdeaFieldForContentIdeaFieldNameMutation({
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  const onClosePopover = async (name: string) => {
    try {
      if (contentIdeaField.name !== name) {
        await updateContentIdeaField({
          variables: {
            data: {
              contentIdeaFieldId: contentIdeaField.id,
              contentIdeaId,
              data: {
                name,
              },
            },
          },
        });
      }

      onAfterUpdate?.();

      closePopoverForManagingField();
    } catch (error: any) {
      console.log(error);
      toast({
        type: 'error',
        message: error?.message || 'Failed to update field name',
      });
    }
  };

  useEffect(() => {
    setInternalName(contentIdeaField.name);
  }, [contentIdeaField.name]);

  const [duplicateContentIdeaField] =
    useDuplicateContentIdeaFieldForContentIdeaFieldNameMutation();
  const onDuplicate = async () => {
    await duplicateContentIdeaField({
      variables: {
        data: {
          contentIdeaFieldId: contentIdeaField.id,
          contentIdeaId,
        },
      },
    });

    onAfterDuplicate?.();
  };

  const [deleteContentIdeaField] =
    useDeleteContentIdeaFieldForContentIdeaFieldNameMutation();
  const onDelete = async () => {
    await deleteContentIdeaField({
      variables: {
        data: {
          contentIdeaFieldId: contentIdeaField.id,
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteContentIdeaField.success) {
          return;
        }

        evictObject(cache, contentIdeaField.id, 'ContentIdeaFieldModel');
      },
    });
  };

  const Icon = contentIdeaField.icon
    ? fieldNameIconMap[contentIdeaField.icon]
    : fieldNameIconMap[fieldTypeIconMap[contentIdeaField.type]];

  /**
   * Controllers for fetching & displaying similar fields
   * based on current field name
   */
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const {
    isOpen: isPopoverForSimilarFieldsOpen,
    onOpen: openPopoverForSimilarFields,
    onClose: closePopoverForSimilarFields,
  } = useDisclosure();

  const debouncedQuery = useDebounce(internalName, 500);
  const { data: contentIdeaFieldsData } =
    useGetContentIdeaFieldsForContentIdeaFieldNameQuery({
      variables: {
        filters: {
          query: debouncedQuery,
          isPrimaryField: false,
        },
      },
      skip: !debouncedQuery,
      fetchPolicy: 'cache-and-network',
    });
  const similarContentIdeaFields = (
    contentIdeaFieldsData?.contentIdeaFields || []
  ).filter((f) => f.id !== contentIdeaField.id);

  useEffect(() => {
    if (similarContentIdeaFields.length > 0) {
      openPopoverForSimilarFields();
    } else {
      closePopoverForSimilarFields();
    }
  }, [debouncedQuery, JSON.stringify(similarContentIdeaFields)]); // eslint-disable-line

  return (
    <>
      <Box
        ref={anchorElRef}
        component="button"
        sx={{
          display: 'flex',
          gap: 1.5,
          alignItems: 'center',
          color: theme.colors?.utility[700],
          textAlign: 'left',
          ...sx,
        }}
        onClick={openPopoverForManagingField}
        disabled={readOnly}
      >
        {Icon && <Icon size={16} />}
        <Typography variant="subhead-lg">{contentIdeaField.name}</Typography>
      </Box>
      <Popover
        open={isPopoverForManagingFieldOpen}
        onClose={() => {
          onClosePopover(internalName);
        }}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 6,
            minWidth: 240,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 24,
              height: 24,
              borderRadius: 1,
              bgcolor: '#2306030D',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {Icon && <Icon size={16} />}
          </Box>
          <TextField
            ref={textFieldRef}
            defaultValue={contentIdeaField.name}
            placeholder="Field name"
            autoFocus={isNew}
            sx={{
              flex: 1,
              input: {
                ...typography['subhead-lg'],
                px: 2,
                py: 1,
              },
              '.MuiInputBase-root': {
                borderRadius: 1,
                bgcolor: theme.colors?.utility[300],
                borderColor: theme.colors?.utility[500],
                overflow: 'hidden',
              },
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClosePopover(internalName);
              }
            }}
            onChange={(e) => setInternalName(e.target.value)}
          />
          {similarContentIdeaFields.length > 0 && (
            <Popover
              disableEnforceFocus
              disableAutoFocus
              disableRestoreFocus
              open={isPopoverForSimilarFieldsOpen}
              anchorEl={textFieldRef.current}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                sx: {
                  p: 2,
                  minWidth: 240,
                  maxHeight: 240,
                },
              }}
              onClose={closePopoverForSimilarFields}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {similarContentIdeaFields.map((field) => (
                  <MenuItem
                    key={field.id}
                    onClick={() => {
                      onClosePopover(field.name);
                    }}
                  >
                    <Typography variant="subhead-lg">{field.name}</Typography>
                  </MenuItem>
                ))}
              </Box>
            </Popover>
          )}
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {[
            ['Duplicate Property', IconOutlineCopy, onDuplicate],
            ['Delete Property', IconLinearTrash, onDelete],
          ].map(([label, Icon, onClick]: any) => {
            return (
              <MenuItem
                key={label}
                sx={{ gap: 3, borderRadius: 2, px: 1 }}
                onClick={() => {
                  onClick();
                  closePopoverForManagingField();
                }}
              >
                <Icon size={16} />
                <Typography variant="subhead-lg">{label}</Typography>
              </MenuItem>
            );
          })}
        </Box>
      </Popover>
    </>
  );
};
