import { Box, Button, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { SLAInfinitePostsGrid } from 'features/socialListeningAnalytics';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  PaginatedBrandInboundFiltersInputForSocialPosts,
  PaginatedBrandInboundFiltersInputSortField,
  SortOrder,
  useGetBrandInboundSocialPostsForSlaBrandInboundTopPostsViewQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { SLABrandInboundTopPostsViewSkeleton } from './SLABrandInboundTopPostsViewSkeleton';

type Props = {
  filters: BrandInboundFiltersInput;
};

export const SLABrandInboundTopPostsView = ({ filters }: Props) => {
  const [sortKey, setSortKey] = useState(
    PaginatedBrandInboundFiltersInputSortField.TotalEngagement,
  );

  const payload: PaginatedBrandInboundFiltersInputForSocialPosts = useMemo(
    () => ({
      ...filters,
      take: 10,
      sortBy: {
        field: sortKey,
        order: SortOrder.Desc,
      },
    }),
    [filters, sortKey],
  );

  const {
    data: topSocialPostsData,
    fetchMore,
    loading,
  } = useGetBrandInboundSocialPostsForSlaBrandInboundTopPostsViewQuery({
    variables: {
      data: payload,
    },
    fetchPolicy: 'cache-and-network',
    skip:
      filters.contentType === BrandContentType.Topic &&
      !filters.topicIds?.length,
  });
  const topPosts = topSocialPostsData?.getBrandInboundSocialPosts.data || [];
  const cursor =
    topSocialPostsData?.getBrandInboundSocialPosts.pageInfo.endCursor;

  if (
    loading ||
    (filters.contentType === BrandContentType.Topic &&
      !filters.topicIds?.length)
  ) {
    return <SLABrandInboundTopPostsViewSkeleton />;
  }

  return (
    <Box
      display="flex"
      p={8}
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 100,
          minHeight: 580,
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          mb={4}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Top posts
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <ContextMenu
              sx={{
                '& .context-menu-item': {
                  p: 0,
                  color: theme.colors?.primary.black,
                },
              }}
              options={[
                {
                  renderOption: () => (
                    <RadioMenuItem
                      label="Total view count"
                      value={
                        PaginatedBrandInboundFiltersInputSortField.TotalImpressions
                      }
                      checked={
                        sortKey ===
                        PaginatedBrandInboundFiltersInputSortField.TotalImpressions
                      }
                      sx={{
                        p: `${theme.spacing(2, 3)}`,
                      }}
                    />
                  ),
                  onClick: () => {
                    setSortKey(
                      PaginatedBrandInboundFiltersInputSortField.TotalImpressions,
                    );
                  },
                },
                {
                  renderOption: () => (
                    <RadioMenuItem
                      label="Total engagement"
                      value={
                        PaginatedBrandInboundFiltersInputSortField.TotalEngagement
                      }
                      checked={
                        sortKey ===
                        PaginatedBrandInboundFiltersInputSortField.TotalEngagement
                      }
                      sx={{
                        p: `${theme.spacing(2, 3)}`,
                      }}
                    />
                  ),
                  onClick: () => {
                    setSortKey(
                      PaginatedBrandInboundFiltersInputSortField.TotalEngagement,
                    );
                  },
                },
              ]}
              renderButton={() => (
                <Button
                  sx={{
                    backgroundColor: theme.colors?.utility[275],
                    color: theme.colors?.primary.black,
                    borderRadius: theme.spacing(2),
                    display: 'flex',
                    gap: 2,
                    p: theme.spacing(1, 2),
                    alignItems: 'center',
                    '&:hover': { backgroundColor: theme.colors?.utility[275] },
                  }}
                  disableRipple={false}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      backgroundColor: 'rgba(35, 6, 3, 0.05);',
                      p: theme.spacing(1),
                      borderRadius: theme.spacing(1),
                    }}
                  >
                    <IconLinearSort size={16} />
                  </Box>
                  <Typography fontWeight={500} variant="body-xl">
                    {sortKey ===
                      PaginatedBrandInboundFiltersInputSortField.TotalEngagement &&
                      'Total Engagement'}
                    {sortKey ===
                      PaginatedBrandInboundFiltersInputSortField.TotalImpressions &&
                      'Total View Count'}
                  </Typography>
                </Button>
              )}
            />
          </Box>
        </Box>

        {!topPosts.length && (
          <Box display="flex" justifyContent="center">
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[700]}
              textAlign="center"
              mt={8}
            >
              No posts found
            </Typography>
          </Box>
        )}

        {!!topPosts.length && (
          <SLAInfinitePostsGrid
            brandId={filters.brandId}
            loadMore={() => {
              fetchMore({
                variables: {
                  data: {
                    ...payload,
                    after: cursor,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    getBrandInboundSocialPosts: {
                      ...prev.getBrandInboundSocialPosts,
                      data: [
                        ...prev.getBrandInboundSocialPosts.data,
                        ...fetchMoreResult.getBrandInboundSocialPosts.data,
                      ].filter(
                        (e, index, self) =>
                          index === self.findIndex((p) => p.id === e.id),
                      ),
                      pageInfo: {
                        ...prev.getBrandInboundSocialPosts.pageInfo,
                        ...fetchMoreResult.getBrandInboundSocialPosts.pageInfo,
                      },
                    },
                  };
                },
              });
            }}
            hasMore={
              !!topSocialPostsData?.getBrandInboundSocialPosts.pageInfo
                .hasNextPage
            }
            socialPosts={topPosts}
          />
        )}
      </Box>
    </Box>
  );
};
