import { gql } from '@apollo/client';
import {
  CollectionFilterType,
  CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragmentDoc,
  CollectionFragmentUseCustomCollectionNestedTableDataFragmentDoc,
  PostFilterType,
  PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc,
  useGetContentIdeaCollectionsForCustomCollectionNestedTableDataQuery,
  useGetPostsSmartSearchForUseCustomCollectionNestedTableDataQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const COLLECTION_FRAGMENT_USE_CUSTOM_COLLECTION_NESTED_TABLE_DATA = gql`
  fragment CollectionFragmentUseCustomCollectionNestedTableData on CollectionModel {
    id
    name
    myPermissions
    ...CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenu
  }
  ${CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const POST_FRAGMENT_USE_CUSTOM_COLLECTION_NESTED_TABLE_DATA = gql`
  fragment PostFragmentUseCustomCollectionNestedTableData on PostModel {
    id
    ...PostFragmentJuiceboxTablePostTableRowWithContextMenu
  }
  ${PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForUseCustomCollectionNestedTableData(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $sortType: SortType
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      sortType: $sortType
    ) {
      data {
        item {
          id
          ...PostFragmentUseCustomCollectionNestedTableData
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${POST_FRAGMENT_USE_CUSTOM_COLLECTION_NESTED_TABLE_DATA}
`;

// eslint-disable-next-line
gql`
  query GetContentIdeaCollectionsForCustomCollectionNestedTableData(
    $after: String
    $take: Int
    $skip: Int
    $sortBy: SortByInputData
  ) {
    contentIdeaCollections(
      after: $after
      take: $take
      skip: $skip
      sortBy: $sortBy
    ) {
      data {
        ...CollectionFragmentUseCustomCollectionNestedTableData
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CollectionFragmentUseCustomCollectionNestedTableDataFragmentDoc}
`;

export type UseCustomCollectionNestedTableDataProps = {
  skipCollectionFetch?: boolean;
  collectionId?: string;
  postFilterType?: PostFilterType;
  collectionFilterType?: CollectionFilterType;
};

type CurrentState =
  | 'checkCollectionFetch'
  | 'fetchedCollections'
  | 'fetchingCollections'
  | 'fetchingPosts';

export const useCustomCollectionNestedTableData = ({
  skipCollectionFetch,
  collectionId,
  postFilterType,
  collectionFilterType,
}: UseCustomCollectionNestedTableDataProps) => {
  const [currentState, setCurrentState] = useState(
    'checkCollectionFetch' as CurrentState,
  );

  const {
    data: paginatedCollectionData,
    loading: isLoadingCollections,
    fetchMore: fetchMoreCollections,
  } = useGetContentIdeaCollectionsForCustomCollectionNestedTableDataQuery({
    variables: {
      take: 10,
    },
    skip: skipCollectionFetch,
    fetchPolicy: 'cache-and-network',
  });

  const paginatedCollections =
    paginatedCollectionData?.contentIdeaCollections.data || [];

  useEffect(() => {
    setCurrentState('checkCollectionFetch');
  }, [collectionFilterType]);

  useEffect(() => {
    if (currentState === 'fetchingPosts') {
      return;
    }

    if (currentState === 'fetchedCollections') {
      setCurrentState('fetchingPosts');
      return;
    }

    if (collectionFilterType === CollectionFilterType.ContentCalendar) {
      setCurrentState('fetchingCollections');
    } else {
      setCurrentState('fetchingPosts');
    }
  }, [collectionFilterType, currentState]);

  const hasMoreCollections =
    paginatedCollectionData?.contentIdeaCollections.pageInfo.hasNextPage ||
    false;

  const {
    data: postData,
    loading: isPostLoading,
    fetchMore: fetchMorePosts,
    refetch: refetchPosts,
  } = useGetPostsSmartSearchForUseCustomCollectionNestedTableDataQuery({
    variables: {
      filters: {
        ...(collectionId ? { collectionIds: [collectionId] } : {}),
        filterType: postFilterType || PostFilterType.OrganizationPosts,
      },
    },
    skip: currentState !== 'fetchingPosts',
    fetchPolicy: 'cache-and-network',
  });

  const posts = postData?.postsSmartSearch.data.map((x) => x.item) || [];

  const fetchNextPage = () => {
    if (!isLoadingCollections && currentState === 'fetchingCollections') {
      fetchMoreCollections({
        variables: {
          after:
            paginatedCollectionData?.contentIdeaCollections.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.contentIdeaCollections.pageInfo.hasNextPage) {
            setCurrentState('fetchingPosts');
          }

          return {
            ...fetchMoreResult,
            contentIdeaCollections: {
              ...fetchMoreResult.contentIdeaCollections,
              data: [
                ...(prev.contentIdeaCollections.data || []),
                ...(fetchMoreResult.contentIdeaCollections.data || []),
              ].filter(
                (e, index, self) =>
                  index === self.findIndex((t) => t.id === e.id),
              ),
            },
          };
        },
      });
    } else if (!isPostLoading && currentState === 'fetchingPosts') {
      fetchMorePosts({
        variables: {
          filters: {
            ...(collectionId ? { collectionIds: [collectionId] } : {}),
            filterType: PostFilterType.OrganizationPosts,
          },
          after: postData?.postsSmartSearch.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return {
            ...fetchMoreResult,
            postsSmartSearch: {
              ...fetchMoreResult.postsSmartSearch,
              data: [
                ...(prev.postsSmartSearch.data || []),
                ...(fetchMoreResult.postsSmartSearch.data || []),
                // Dedupe by cursor
              ].filter(
                (e, index, self) =>
                  index === self.findIndex((t) => t.item.id === e.item.id),
              ),
            },
          };
        },
      });
    }
  };

  return {
    collections: paginatedCollections,
    loading: isPostLoading || isLoadingCollections,
    posts,
    fetchNextPage,
    hasMore:
      hasMoreCollections ||
      postData?.postsSmartSearch.pageInfo.hasNextPage ||
      false,
    refetchPosts,
  };
};
