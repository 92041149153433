import { Box, Button, IconButton, OutlinedInput, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from 'styles/theme/theme';

export const Wrapper = styled(Box)(() => ({
  width: '100%',
  margin: '0 auto',
  height: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  '& .fullscreen': {
    width: '100%',
  },
}));
export const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
}));
export const ControlsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background:
    'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
}));
export const BoxWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledIconLinearRedo = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '& svg': {
    transform: 'rotate(180deg)',
    color: theme.colors?.utility[800],
  },
}));

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: `rgba(255,255,255,0.2)`,
  },
  barColorPrimary: {
    backgroundColor: theme.colors?.primary.maroon,
  },
}));

export const StyledCommentInput = styled(OutlinedInput)(() => ({
  fontSize: 13,
  background: theme.colors?.primary.white,
  borderRadius: 4,
  fontWeight: 500,
  fontFamily: 'TT Commons Pro',
  '& input': {
    padding: 8,
    '&::placeholder': {
      color: theme.colors?.utility[800],
    },
  },
  ':hover': { borderColor: 'transparent' },
  '& fieldset': { border: 'none', padding: 0 },
}));

export const LeaveCommentBtn = styled(Button)(() => ({
  border: 'none',
  backgroundColor: theme.colors?.utility[900],
  padding: 8,
  minWidth: 'fit-content',
  fontSize: 12,
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  '&:hover': {
    backgroundColor: theme.colors?.utility[900],
  },
}));
