import {
  CollectionMenuItemView,
  CollectionMenuItemViewProps,
} from 'features/collection';
import { usePostPermissionUpdatePostPermissionToCollectionPermission } from 'features/post-permission';
import { CollectionFragmentCollectionMenuItemViewFragment } from 'graphql/generated';

type CollectionMenuItemWithPostPermissionAlertViewProps =
  CollectionMenuItemViewProps & {
    postIds: string[];
  };

export const CollectionMenuItemWithPostPermissionAlertView = (
  props: CollectionMenuItemWithPostPermissionAlertViewProps,
) => {
  const { onOpenUpdatePostPermissionDialog, renderUpdatePostPermissionAlert } =
    usePostPermissionUpdatePostPermissionToCollectionPermission();

  const handleSelectCollection = async (
    selectedCollection: CollectionFragmentCollectionMenuItemViewFragment,
  ) => {
    props.onClick?.(selectedCollection);

    // only run permission update check when the collection is selected
    if (!props.selectedCollectionIds?.includes(selectedCollection.id)) {
      await onOpenUpdatePostPermissionDialog(
        props.postIds,
        selectedCollection.id,
      );
    }
  };

  return (
    <>
      <CollectionMenuItemView {...props} onClick={handleSelectCollection} />

      {renderUpdatePostPermissionAlert()}
    </>
  );
};
