import { EditorView } from '@tiptap/pm/view';
import { DropSide, MousePos } from 'features/tiptap/types';
import type { DOMNode } from 'prosemirror-view/src/dom';
import { blockAtCoords } from '../blockWrapper/utils';

/**
 * This method aims to return the node that is the target of the drop event.
 */
export const getDroppedOnNode = (view: EditorView, event: DragEvent) => {
  // Find current doc pos based on mouse pos
  let { docPos, node } = blockAtCoords(view, event);
  let nodeDOM = view.nodeDOM(docPos);
  const dropSide = determineDropSide(event, nodeDOM);

  // If dropSide is left or right, verify if the node is columnable.
  // If not, traverse up the parent nodes to find the nearest columnable node
  if (dropSide && ['left', 'right'].includes(dropSide)) {
    while (node && node.attrs.columnable !== 'true') {
      const resolvedPos = view.state.doc.resolve(docPos);

      if (resolvedPos.depth === 0) {
        break;
      }

      const parentPos = resolvedPos.before();
      const parent = view.state.doc.nodeAt(parentPos);

      if (parent) {
        docPos = parentPos;
        node = parent;
        nodeDOM = view.nodeDOM(docPos);
      } else {
        break;
      }
    }
  }

  const resolvedPos = view.state.doc.resolve(docPos);

  return {
    docPos,
    dropSide,
    node,
    nodeDOM,
    nodeFrom: resolvedPos.pos,
    nodeTo: resolvedPos.pos + (node?.nodeSize || 0),
  };
};

/**
 * This method determine which side (top, bottom, left, right) of the node
 * that the user is dragging over.
 *
 * @param mousePos
 * @param node
 */
export const determineDropSide = (
  mousePos: MousePos,
  node: DOMNode | null,
): DropSide => {
  if (!node) {
    return null;
  }

  try {
    const { left, right, top, height } = (
      node as HTMLElement
    ).getBoundingClientRect();

    const horizontalThreshold = 24;

    if (mousePos.clientX < left + horizontalThreshold) {
      return 'left';
    }

    if (mousePos.clientX > right - horizontalThreshold) {
      return 'right';
    }

    if (mousePos.clientY < top + height / 2) {
      return 'top';
    }

    if (mousePos.clientY > top + height / 2) {
      return 'bottom';
    }
  } catch {
    return null;
  }

  return null;
};
