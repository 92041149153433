import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { DatePicker } from 'components/common/DatePicker';
import { IconBoldTimerPause } from 'components/icons/components/bold/IconBoldTimerPause';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import {
  ContentIdeaFragmentContentIdeaSnoozeDateFragment,
  ContentIdeaPermission,
  useRemoveSnoozeForContentIdeaSnoozeDateMutation,
  useUpdateContentIdeaForContentIdeaSnoozeDateMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_SNOOZE_DATE = gql`
  fragment ContentIdeaFragmentContentIdeaSnoozeDate on ContentIdeaModel {
    id
    snoozeDate
    stage
    myPermissions
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaForContentIdeaSnoozeDate(
    $data: UpdateContentIdeaInput!
  ) {
    updateContentIdea(data: $data) {
      id
      ...ContentIdeaFragmentContentIdeaSnoozeDate
    }
  }
  ${CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_SNOOZE_DATE}
`;

// eslint-disable-next-line
gql`
  mutation RemoveSnoozeForContentIdeaSnoozeDate($contentIdeaId: String!) {
    removeContentIdeaFromSnooze(contentIdeaId: $contentIdeaId) {
      id
      ...ContentIdeaFragmentContentIdeaSnoozeDate
    }
  }
  ${CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_SNOOZE_DATE}
`;

export type ContentIdeaSnoozeDateProps = {
  contentIdea: ContentIdeaFragmentContentIdeaSnoozeDateFragment;
  onAfterSnooze?: (
    updatedIdea: ContentIdeaFragmentContentIdeaSnoozeDateFragment,
  ) => void;
};

export const ContentIdeaSnoozeDate = (props: ContentIdeaSnoozeDateProps) => {
  const { contentIdea, onAfterSnooze } = props;

  const openCalendarRef = useRef<Function>();

  const [updateContentIdea] =
    useUpdateContentIdeaForContentIdeaSnoozeDateMutation();
  const [removeIdeaFromSnooze] =
    useRemoveSnoozeForContentIdeaSnoozeDateMutation();

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  const onUpdateSnoozeDate = (date: Date) => {
    updateContentIdea({
      variables: {
        data: {
          contentIdeaId: contentIdea.id,
          data: {
            snoozeDate: date,
            timeZone: moment.tz.guess(),
          },
        },
      },
      update: (cache, { data }) => {
        if (!data?.updateContentIdea) return;
        modifyObject(cache, contentIdea.id, 'ContentIdeaModel', {
          stage: () => data.updateContentIdea.stage,
        });
        onAfterSnooze?.(data.updateContentIdea);
      },
    });
  };

  const onRemoveSnooze = () => {
    removeIdeaFromSnooze({
      variables: {
        contentIdeaId: contentIdea.id,
      },
      update: (cache, { data }) => {
        if (!data?.removeContentIdeaFromSnooze) return;
        modifyObject(cache, contentIdea.id, 'ContentIdeaModel', {
          stage: () => data.removeContentIdeaFromSnooze.stage,
          snoozeDate: () => data.removeContentIdeaFromSnooze.snoozeDate,
        });
        onAfterSnooze?.(data.removeContentIdeaFromSnooze);
      },
    });
  };

  // TODO: Get timezone from the snooze cron job
  const snoozeOptions = [
    {
      label: 'An hour from now',
      value: moment().add(1, 'hours'),
    },
    {
      label: 'Tomorrow',
      value: moment().add(1, 'days'),
    },
    {
      label: 'Next week',
      value: moment().add(1, 'weeks'),
    },
    {
      label: 'A month from now',
      value: moment().add(1, 'months'),
    },
  ];

  const formattedElapsedTime = useMemo(() => {
    let formattedTime: string = '';
    if (contentIdea.snoozeDate) {
      if (contentIdea.snoozeDate) {
        const elapsedTimeFromNow = moment(contentIdea.snoozeDate).fromNow(true);
        switch (elapsedTimeFromNow) {
          case 'a day':
            formattedTime = '1d';
            break;
          case 'a year':
            formattedTime = '1y';
            break;
          case 'a month':
            formattedTime = '1m';
            break;
          case 'an hour':
            formattedTime = '1h';
            break;
          default:
            const timeParts = elapsedTimeFromNow?.split(' ');
            formattedTime = timeParts.length
              ? timeParts[0] + (timeParts[1] ? timeParts[1][0] : '')
              : '';
        }
      }
    }

    return formattedTime;
  }, [contentIdea.snoozeDate]);

  return (
    <ContextMenu
      PaperProps={{
        style: {
          borderRadius: theme.spacing(2),
          backdropFilter: 'blur(20px)',
          boxShadow: 'none',
        },
      }}
      options={[
        ...snoozeOptions.map((options) => ({
          renderOption: () => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minWidth: 280,
                }}
              >
                <Typography variant="headline-xs">{options.label}</Typography>
                <Typography
                  variant="subhead-lg"
                  color={theme.colors?.utility[700]}
                >
                  {options.value.format('ddd, DD MMM, HH:mm A')}
                </Typography>
              </Box>
            );
          },
          onClick: () => onUpdateSnoozeDate(options.value.toDate()),
        })),
        {
          renderOption: () => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minWidth: 280,
                }}
              >
                <Typography variant="headline-xs">Custom</Typography>
                <DatePicker
                  renderInput={(props) => {
                    const { inputRef, openPicker } = props;
                    openCalendarRef.current = openPicker;
                    return (
                      <Box
                        component="button"
                        ref={inputRef}
                        display="flex"
                        alignItems="center"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          openPicker();
                        }}
                      >
                        <IconLinearArrowRight1
                          size={16}
                          color={theme.colors?.primary.black}
                        />
                      </Box>
                    );
                  }}
                  value={
                    contentIdea.snoozeDate
                      ? moment(contentIdea.snoozeDate)
                      : null
                  }
                  onChange={(date) => {
                    if (date) {
                      onUpdateSnoozeDate(date.toDate());
                    }
                  }}
                  includeTimeByDefault
                  closeOnSelect={false}
                />
              </Box>
            );
          },
          closeOnClick: false,
          onClick: () => {
            openCalendarRef.current?.();
          },
        },
        // Show remove snooze option if snooze date is set
        ...([
          contentIdea.snoozeDate
            ? {
                renderOption: () => (
                  <Typography variant="headline-xs">Remove snooze</Typography>
                ),
                onClick: () => {
                  onRemoveSnooze();
                },
              }
            : null,
        ].filter(Boolean) as any),
      ]}
      tooltip={{
        title: formattedElapsedTime
          ? `Snoozed for ${formattedElapsedTime}`
          : 'Snooze',
        placement: 'bottom',
      }}
      renderButton={(isOpen) => {
        return contentIdea.snoozeDate ? (
          <Box component="button" display="flex" alignItems="center">
            <IconBoldTimerPause
              size={16}
              color={theme.colors?.utility['pink-3']}
            />

            <Typography
              variant="headline-xxs"
              color={theme.colors?.utility['pink-3']}
            >
              {formattedElapsedTime}
            </Typography>
          </Box>
        ) : (
          <IconBoldTimerPause
            size={16}
            color={
              isOpen ? theme.colors?.primary.black : theme.colors?.utility[500]
            }
          />
        );
      }}
      disabled={!canEdit}
    />
  );
};
