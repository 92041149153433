import { useEffect, useState } from 'react';
import { useAnnotationManager } from '../../Annotation/contexts/Annotation.context';

export const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [isWithinBounds, setIsWithinBounds] = useState(false);
  const { canAnnotate } = useAnnotationManager();
  const commentInput = document.querySelector('.annotation-comment-input');
  const commentBubbles = document.querySelectorAll('.annotation__icon');

  useEffect(() => {
    const targetElement = document.querySelector(
      '.react-custom-annotation',
    ) as HTMLElement;
    const mouseMoveHandler = (event) => {
      const { clientX, clientY } = event;
      setMousePosition({ x: clientX, y: clientY });
    };

    const handleMouseEnter = () => {
      setIsWithinBounds(true);
    };

    const handleMouseLeave = () => {
      setIsWithinBounds(false);
    };

    const removeEventListener = () => {
      if (targetElement) {
        targetElement.removeEventListener('mouseenter', handleMouseEnter);
        targetElement.removeEventListener('mouseleave', handleMouseLeave);
        targetElement.removeEventListener('mousemove', mouseMoveHandler);
        targetElement.style.cursor = 'auto'; // Restore the default cursor pointer
      }

      if (commentInput) {
        commentInput.removeEventListener('mouseenter', () => {
          handleMouseLeave();
        });
        commentInput.removeEventListener('mouseleave', () => {
          handleMouseEnter();
        });
      }

      commentBubbles.forEach((commentBubble) => {
        commentBubble.removeEventListener('mouseenter', () => {
          targetElement.style.cursor = 'auto';
          handleMouseLeave();
        });
        commentBubble.removeEventListener('mouseleave', () => {
          targetElement.style.cursor = 'none';
          handleMouseEnter();
        });
      });
    };

    if (canAnnotate) {
      if (targetElement) {
        targetElement.addEventListener('mouseenter', handleMouseEnter);
        targetElement.addEventListener('mouseleave', handleMouseLeave);
        targetElement.addEventListener('mousemove', mouseMoveHandler);
        targetElement.style.cursor = 'none';
      }

      if (commentInput) {
        commentInput.addEventListener('mouseenter', () => {
          handleMouseLeave();
        });
        commentInput.addEventListener('mouseleave', () => {
          handleMouseEnter();
        });
      }

      commentBubbles.forEach((commentBubble) => {
        commentBubble.addEventListener('mouseenter', () => {
          targetElement.style.cursor = 'auto';
          handleMouseLeave();
        });
        commentBubble.addEventListener('mouseleave', () => {
          targetElement.style.cursor = 'none';
          handleMouseEnter();
        });
      });
    } else {
      removeEventListener();
    }

    return () => {
      removeEventListener();
    };
  }, [commentInput, canAnnotate, commentBubbles]);

  return { ...mousePosition, isWithinBounds };
};
