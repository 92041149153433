import { gql } from '@apollo/client';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  PostFilterType,
  PostFiltersForSmartSearch,
  PostFragmentCollectionPostDndPostCardFragmentDoc,
  PostFragmentPostCommandFragment,
  SortType,
  ViewEntityType,
  useGetPostsSmartSearchForCollectionPageCustomCollectionQuery,
  useViewEntitiesForPostMutation,
} from 'graphql/generated';
import { useUpdateQueryByCommand } from 'hooks/commands/useUpdateQueryByCommand';
import { useEffect } from 'react';

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForCollectionPageCustomCollection(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $sortType: SortType
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      sortType: $sortType
    ) {
      data {
        item {
          ...PostFragmentCollectionPostDndPostCard
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PostFragmentCollectionPostDndPostCardFragmentDoc}
`;

export type UsePostQueryForCustomCollectionProps = {
  filters: PostFiltersForSmartSearch;
  skip?: boolean;
  sortType?: SortType;
};

export const usePostQueryForCustomCollection = (
  props: UsePostQueryForCustomCollectionProps,
) => {
  const { filters, skip, sortType = SortType.DateCreated } = props;

  const {
    data,
    loading,
    fetchMore: _fetchMore,
    updateQuery,
  } = useGetPostsSmartSearchForCollectionPageCustomCollectionQuery({
    variables: {
      filters: {
        ...filters,
      },
      sortType,
    },
    skip,
    fetchPolicy: 'cache-and-network',
  });
  const isFirstLoading = loading && !data;

  const fetchMore = async () => {
    _fetchMore({
      variables: {
        filters: {
          ...filters,
        },
        after: data?.postsSmartSearch.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          postsSmartSearch: {
            ...fetchMoreResult.postsSmartSearch,
            data: [
              ...(prev.postsSmartSearch.data || []),
              ...(fetchMoreResult.postsSmartSearch.data || []),
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.item.id === e.item.id),
            ),
          },
        };
      },
    });
  };

  const addNewPostToQuery = (posts: PostFragmentPostCommandFragment[]) => {
    if (filters.filterType !== PostFilterType.MyFavoritePosts) {
      updateQuery((prev) => {
        return {
          ...prev,
          postsSmartSearch: {
            ...prev.postsSmartSearch,
            data: [
              ...posts.map((p) => ({
                __typename: 'PostSearchHit' as any,
                item: p,
              })),
              ...(prev.postsSmartSearch.data || []),
            ]
              // Dedupe by id
              .filter(
                (e, index, self) =>
                  index === self.findIndex((t) => t.item.id === e.item.id),
              ),
          },
        };
      });
    }
  };

  useUpdateQueryByCommand(COMMAND_TYPE.POST_PASTE_A_LINK, addNewPostToQuery);
  useUpdateQueryByCommand(COMMAND_TYPE.POST_UPLOAD_FILES, addNewPostToQuery);
  useUpdateQueryByCommand(COMMAND_TYPE.POST_WRITE_A_NOTE, addNewPostToQuery);

  // View all new posts after query is updated
  const [viewEntities] = useViewEntitiesForPostMutation();
  useEffect(() => {
    const newPosts = (data?.postsSmartSearch.data || []).filter(
      ({ item }) => item.isNew,
    );
    if (newPosts.length > 0) {
      viewEntities({
        variables: {
          data: {
            entityIds: newPosts.map(({ item }) => item.id),
            entityType: ViewEntityType.Post,
          },
        },
      });
    }
  }, [data]); // eslint-disable-line

  return {
    data,
    loading: isFirstLoading,
    fetchMore,
  };
};
