import { gql } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import {
  CollectionFragmentCollectionNameFragmentDoc,
  CollectionFragmentUpdatePostPermissionAlertViewFragment,
  useUpdateCollectionPermissionToParentCollectionPermissionMutation,
  useUpdatePostPermissionToCollectionPermissionMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation UpdatePostPermissionToCollectionPermission(
    $data: UpdatePostPermissionToCollectionPermissionInput!
  ) {
    updatePostPermissionToCollectionPermission(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCollectionPermissionToParentCollectionPermission(
    $data: UpdateCollectionPermissionToParentCollectionPermissionInput!
  ) {
    updateCollectionPermissionToParentCollectionPermission(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  fragment CollectionFragmentUpdatePostPermissionAlertView on CollectionModel {
    id
    ...CollectionFragmentCollectionName
  }
  ${CollectionFragmentCollectionNameFragmentDoc}
`;

type PermissionUpdatePostPermissionAlertViewProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  postIds?: string[];
  collectionIds?: string[];
  parentCollection: CollectionFragmentUpdatePostPermissionAlertViewFragment;
  onCallback?: VoidFunction;
};

export const UpdatePostPermissionAlertView = (
  props: PermissionUpdatePostPermissionAlertViewProps,
) => {
  const {
    isOpen,
    onClose,
    postIds = [],
    collectionIds = [],
    parentCollection,
    onCallback,
  } = props;

  const [updatePostPermissionToCollectionPermission] =
    useUpdatePostPermissionToCollectionPermissionMutation();
  const [updateCollectionPermissionToParentCollectionPermission] =
    useUpdateCollectionPermissionToParentCollectionPermissionMutation();

  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    onCallback?.();
    onClose();
  };

  const getItemCountText = (count: number, type: 'post' | 'collection') =>
    `${count > 1 ? `${count} ${type}s` : `${count === 0 ? '' : type}`}`;

  const handleUpdatePermission = async () => {
    setLoading(true);
    if (postIds.length > 0) {
      await updatePostPermissionToCollectionPermission({
        variables: {
          data: { collectionId: parentCollection.id, postIds },
        },
      });
    }
    if (collectionIds.length > 0) {
      await updateCollectionPermissionToParentCollectionPermission({
        variables: {
          data: {
            parentCollectionId: parentCollection.id,
            collectionIds,
          },
        },
      });
    }
    setLoading(false);

    handleOnClose();
  };

  const getPostAndCollectionCountText = () => {
    const postText = getItemCountText(postIds.length, 'post');
    const collectionText = getItemCountText(collectionIds.length, 'collection');
    return `${postText}${
      postText && collectionText ? ' and ' : ''
    }${collectionText}`;
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        // FIXME: This is a temporary fix for the dialog to be on top of
        // most of other things
        zIndex: 2000,
      }}
      PaperProps={{
        sx: {
          width: 350,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={theme.spacing(6)}
        padding={theme.spacing(6)}
      >
        <Box display="flex" flexDirection="column" gap={theme.spacing(4)}>
          <Typography variant="headline-lg">
            Want to update your {getPostAndCollectionCountText()} permissions?
          </Typography>

          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            Some people in #{parentCollection.name} won’t be able to see{' '}
            {postIds.length + collectionIds.length > 1 ? 'these' : 'this'}{' '}
            {getPostAndCollectionCountText()}. Update{' '}
            {getPostAndCollectionCountText()} permission so they can access.
          </Typography>
        </Box>

        <Box>
          <Button
            disabled={loading}
            fullWidth
            variant="contained"
            onClick={handleUpdatePermission}
            sx={{
              bgcolor: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              ':hover': {
                bgcolor: theme.colors?.primary.black,
                opacity: 0.7,
              },
            }}
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
          >
            Update permissions
          </Button>
          <Button
            fullWidth
            onClick={handleOnClose}
            sx={{
              marginTop: theme.spacing(2),
              textDecoration: 'underline',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Continue without
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
