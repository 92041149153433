import { NotificationType } from 'graphql/generated';

export const notificationTypeLabels = {
  // Tasks
  [NotificationType.TaskComment]: 'Comment on a Task',
  [NotificationType.TaskMention]: 'Mentioned in a Task',
  [NotificationType.TaskCommentReaction]: 'Reaction to a comment on a Task',

  // CJB
  [NotificationType.PostReaction]: 'Reaction to a Post',
  [NotificationType.PostMention]: 'Mentioned in a Post/Note',
  [NotificationType.PostComment]: 'Comment on a Post/Note',
  [NotificationType.PostCommentReaction]:
    'Reaction to a comment on a Post/Note',
  [NotificationType.CollectionMention]: 'Mentioned in a Collection',
  [NotificationType.CollectionComment]: 'Comment on a Collection',
  [NotificationType.CollectionCommentReaction]:
    'Reaction to a comment on a Collection',

  // Content Calendar
  [NotificationType.AssignedToContentIdea]: 'Assigned to a Content',
  [NotificationType.InvitedToContentIdea]: 'Invited to a Content',
};

// Track which notifications are manageable by the user
// 1. true: user can manage this notification (meaning this notification is supported)
// 2. false: user cannot manage this notification (meaning this notification is not supported)
// TODO: Use backend as source of truth, otherwise it's easy to get out of sync
// For now, see notificationSetting.service.ts
export const manageableNotificationSettings = {
  // Tasks
  tasks: [
    {
      type: NotificationType.TaskComment,
      inbox: true,
      email: true,
      mobilePush: false,
    },
    {
      type: NotificationType.TaskMention,
      inbox: true,
      email: false,
      mobilePush: false,
    },
    {
      type: NotificationType.TaskCommentReaction,
      inbox: true,
      email: false,
      mobilePush: true,
    },
  ],

  // CJB
  cjb: [
    {
      type: NotificationType.PostReaction,
      inbox: true,
      email: false,
      mobilePush: true,
    },
    {
      type: NotificationType.PostMention,
      inbox: true,
      email: true,
      mobilePush: true,
    },
    {
      type: NotificationType.PostComment,
      inbox: true,
      email: true,
      mobilePush: true,
    },
    {
      type: NotificationType.PostCommentReaction,
      inbox: true,
      email: false,
      mobilePush: true,
    },
    {
      type: NotificationType.CollectionMention,
      inbox: true,
      email: false,
      mobilePush: true,
    },
    {
      type: NotificationType.CollectionComment,
      inbox: true,
      email: true,
      mobilePush: true,
    },
    {
      type: NotificationType.CollectionCommentReaction,
      inbox: true,
      email: false,
      mobilePush: true,
    },
  ],

  // Content Calendar
  contentCalendar: [
    {
      type: NotificationType.AssignedToContentIdea,
      inbox: true,
      email: true,
      mobilePush: false,
    },
    {
      type: NotificationType.InvitedToContentIdea,
      inbox: true,
      email: true,
      mobilePush: false,
    },
  ],
};
