import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowRight4 } from 'components/icons/components/linear/IconLinearArrowRight4';
import {
  SortOrder,
  TrendCategory,
  TrendFragmentUseTrendNavigatorHandlerFragment,
  useTrendsForPaginationLazyQuery,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

const TREND_FRAGMENT_USE_TREND_NAVIGATOR_HANDLER = gql`
  fragment TrendFragmentUseTrendNavigatorHandler on TrendModel {
    id
    category
  }
`;

interface Props {
  trendId?: string;
  trendCategories?: TrendCategory[];
  customPersonaIds?: string[];
}

// eslint-disable-next-line
gql`
  query TrendsForPagination(
    $take: Int
    $filters: TrendFilters!
    $after: String
  ) {
    trends(take: $take, filters: $filters, after: $after) {
      data {
        ...TrendFragmentUseTrendNavigatorHandler
      }
    }
  }
  ${TREND_FRAGMENT_USE_TREND_NAVIGATOR_HANDLER}
`;

export const useTrendNavigatorHandler = ({
  trendCategories = [],
  trendId,
  customPersonaIds = [],
}: Props) => {
  const [prevTrend, setPrevTrend] = useState<
    TrendFragmentUseTrendNavigatorHandlerFragment | undefined
  >();
  const [nextTrend, setNextTrend] = useState<
    TrendFragmentUseTrendNavigatorHandlerFragment | undefined
  >();

  const [getTrendsForPaginationForNext] = useTrendsForPaginationLazyQuery();
  const [getTrendsForPaginationForPrev] = useTrendsForPaginationLazyQuery();

  useEffect(() => {
    setNextTrend(undefined);
    setPrevTrend(undefined);

    getTrend('next').then((trend) => {
      if (trend) {
        setNextTrend(trend);
      }
    });

    getTrend('prev').then((trend) => {
      if (trend) {
        setPrevTrend(trend);
      }
    });
  }, [trendId]); // eslint-disable-line

  const getTrend = async (direction: 'next' | 'prev') => {
    if (!trendId) {
      return;
    }

    const paginationVariables = {
      take: 1,
      after: trendId,
    };
    const defaultFilters = {
      categories: trendCategories,
      customPersonaIds,
    };

    const { data } =
      direction === 'next'
        ? await getTrendsForPaginationForNext({
            variables: {
              ...paginationVariables,
              filters: {
                ...defaultFilters,
                sortBy: {
                  field: 'trendingDate',
                  order: SortOrder.Desc,
                },
              },
            },
            fetchPolicy: 'network-only',
          })
        : await getTrendsForPaginationForPrev({
            variables: {
              ...paginationVariables,
              filters: {
                ...defaultFilters,
                sortBy: {
                  field: 'trendingDate',
                  order: SortOrder.Asc,
                },
              },
            },
            fetchPolicy: 'network-only',
          });

    return data?.trends.data[0];
  };

  const nextTrendId = nextTrend?.id;
  const prevTrendId = prevTrend?.id;

  const location = useLocation();

  const prevLinkRef = useRef<HTMLAnchorElement>(null);
  const nextLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // List of input element types to exclude
      const excludedInputTypes = ['input', 'textarea', 'select'];

      // Check if any input element is focused
      const isInputFocused =
        document.activeElement instanceof HTMLElement &&
        excludedInputTypes.includes(
          document.activeElement.tagName.toLowerCase(),
        );

      // Only navigate if no input element is focused
      if (!isInputFocused) {
        if (event.key === 'ArrowLeft' && prevLinkRef.current) {
          prevLinkRef.current.click();
        } else if (event.key === 'ArrowRight' && nextLinkRef.current) {
          nextLinkRef.current.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [prevTrendId, nextTrendId]);
  const renderLeftArrow = () => {
    if (!prevTrendId) return null;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Link
          to={{
            pathname: PlotRoutes.trendingJuice(prevTrendId || ''),
          }}
          replace
          state={location.state}
          ref={prevLinkRef}
        >
          <IconButton
            disableRipple
            size="small"
            sx={{
              backgroundColor: theme.colors?.utility[400],
              borderRadius: 1,
              border: `1px solid ${theme.colors?.utility[500]}`,
            }}
          >
            <IconLinearArrowRight4
              size={12}
              color={theme.colors?.primary.black}
              style={{ transform: 'rotate(180deg)' }}
            />
          </IconButton>
        </Link>
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[600]}
          textAlign="center"
        >
          Previous Trend
        </Typography>
      </Box>
    );
  };

  const renderRightArrow = () => {
    if (!nextTrendId) return null;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <Link
          to={{
            pathname: PlotRoutes.trendingJuice(nextTrendId || ''),
          }}
          replace
          state={location.state}
          ref={nextLinkRef}
        >
          <IconButton
            disableRipple
            size="small"
            sx={{
              backgroundColor: theme.colors?.utility[400],
              borderRadius: 1,
              border: `1px solid ${theme.colors?.utility[500]}`,
            }}
          >
            <IconLinearArrowRight4
              size={12}
              color={theme.colors?.primary.black}
            />
          </IconButton>
        </Link>
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[600]}
          textAlign="center"
        >
          Next Trend
        </Typography>
      </Box>
    );
  };

  return {
    nextTrendId,
    prevTrendId,
    renderLeftArrow,
    renderRightArrow,
  };
};
