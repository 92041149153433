import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import {
  Platform,
  SocialAuthorFragmentSocialAuthorListSectionFragmentDoc,
  useGetMeForSocialsPageQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { SocialAuthorListSection } from './sections/SocialAuthorListSection';

export const ORGANIZATION_FRAGMENT_SOCIALS_PAGE = gql`
  fragment OrganizationFragmentSocialsPage on OrganizationModel {
    id
    socialAuthors {
      id
      platform
      ...SocialAuthorFragmentSocialAuthorListSection
    }
  }
  ${SocialAuthorFragmentSocialAuthorListSectionFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetMeForSocialsPage {
    me {
      id
      organization {
        id
        ...OrganizationFragmentSocialsPage
      }
    }
  }
`;

export const SocialsPage = () => {
  const [tab, setTab] = useState<Platform>(Platform.Instagram);

  const { data, refetch } = useGetMeForSocialsPageQuery();
  const organization = data?.me?.organization;

  if (!organization) {
    return null;
  }

  const instagramSocialAuthors = organization.socialAuthors.filter(
    (s) => s.platform === Platform.Instagram,
  );
  const tiktokSocialAuthors = organization.socialAuthors.filter(
    (s) => s.platform === Platform.Tiktok,
  );

  return (
    <Box
      sx={{
        '& .MuiTabPanel-root': {
          paddingTop: 6,
        },
        '& .MuiTab-root': {
          padding: `0 !important`,
          span: {
            fontSize: 16,
          },
        },
        '& .MuiTabs-root': {
          pb: 6,
          borderBottom: 0,
        },
        '& > div > div:first-of-type': {
          borderBottom: `1px solid ${theme.colors?.utility[300]}`,
        },
      }}
    >
      <Tabs
        tab={tab}
        onChange={(val) => {
          setTab(val as Platform);
        }}
        tabs={[
          {
            key: Platform.Instagram,
            label: `Instagram (${instagramSocialAuthors.length})`,
            content: (
              <SocialAuthorListSection
                platform={Platform.Instagram}
                socialAuthors={instagramSocialAuthors}
                refetch={refetch}
                componentsProps={{
                  listItem: {
                    componentsProps: {
                      input: {
                        placeholder: 'https://www.instagram.com/username',
                      },
                      icon: {
                        color: theme.colors?.utility['orange-4'],
                      },
                    },
                  },
                }}
              />
            ),
          },
          {
            key: Platform.Tiktok,
            label: `TikTok (${tiktokSocialAuthors.length})`,
            content: (
              <SocialAuthorListSection
                platform={Platform.Tiktok}
                socialAuthors={tiktokSocialAuthors}
                refetch={refetch}
                componentsProps={{
                  listItem: {
                    componentsProps: {
                      input: {
                        placeholder: 'https://tiktok.com/@username',
                      },
                      icon: {
                        color: theme.colors?.utility['purple-4'],
                      },
                    },
                  },
                }}
              />
            ),
          },
        ]}
      />
    </Box>
  );
};
