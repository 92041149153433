import { Box, Button, Dialog, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { useUserContext } from 'contexts/users/User.context';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

interface CommentInviteMentionUserDialogProps {
  mentionedUserIds: string[];
  onCreateComment: () => void;
  onInviteOrRequestAccessAndCreateComment: () => void;
  type?: 'post' | 'note' | 'collection';
  variant?: 'invite' | 'request-access';
}

export const CommentInviteMentionUserDialog = ({
  mentionedUserIds,
  onCreateComment,
  onInviteOrRequestAccessAndCreateComment,
  type = 'post',
  variant = 'invite',
}: CommentInviteMentionUserDialogProps) => {
  const { user } = useUserContext();

  const users = [
    ...(user?.organization.users || []),
    ...(user?.organization.externalUsers || []),
  ];

  return (
    <Dialog
      open
      maxWidth="md"
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          padding: 8,
          borderRadius: 6,
          background: `rgba(250, 243, 236, 0.80)`,
          backdropFilter: `blur(20px)`,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          fontWeight: 400,
          maxWidth: 500,
        },
      }}
    >
      <Typography variant="headline-xl" letterSpacing="-0.96px">
        Invite mentioned user?
      </Typography>
      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Typography component="span" variant="subhead-xl" fontSize={20}>
          The person you mentioned
        </Typography>
        {mentionedUserIds.map((userId) => {
          const mentionedUser = users.find((u) => u.id === userId);
          if (!mentionedUser) return null;
          return (
            <Box component="span" sx={{ display: 'flex', gap: 1 }}>
              <Avatar user={mentionedUser} size={24} />
              <Typography variant="headline-md" fontSize={20} component="span">
                {getFullName(mentionedUser)}
              </Typography>
            </Box>
          );
        })}
        <Typography component="span" variant="subhead-xl" fontSize={20}>
          isn’t in this {type} yet.{' '}
          {variant === 'invite' ? 'Invite' : 'Request access for'} them to
          ensure they see your comment
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Button
          variant="text"
          fullWidth
          onClick={onInviteOrRequestAccessAndCreateComment}
          sx={{
            color: theme.colors?.primary.parchment,
            bgcolor: theme.colors?.primary.black,
            borderRadius: 2,
            padding: theme.spacing(2, 4),
            ':hover': {
              bgcolor: theme.colors?.primary.black,
            },
          }}
        >
          {variant === 'invite' ? 'Invite ' : 'Request access '}and post comment
        </Button>
        <Button
          fullWidth
          variant="text"
          sx={{ color: theme.colors?.primary.black }}
          onClick={onCreateComment}
        >
          Post without {variant === 'invite' ? 'inviting' : 'requesting access'}
        </Button>
      </Box>
    </Dialog>
  );
};
