import { Avatar, Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearExport3 } from 'components/icons/components/linear/IconLinearExport3';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconOutlineSetting2 } from 'components/icons/components/outline/IconOutlineSetting2';
import { useUserContext } from 'contexts/users/User.context';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

type Props = {
  generateUrl: (brandId: string) => string;
};

export const SLPageBrandSelector = ({ generateUrl }: Props) => {
  const { brandId = '' } = useParams();
  const navigate = useGuardNavigate();
  const { user } = useUserContext();

  const slBrands = useMemo(() => user?.socialListeningBrands || [], [user]);

  const currentBrand = useMemo(() => {
    const brand = slBrands.find((brand) => brand.id === brandId);
    if (!brand && slBrands.length) {
      return slBrands[0];
    }
    return brand;
  }, [brandId, slBrands]);

  if (!currentBrand) {
    return null;
  }

  return (
    <Box>
      <ContextMenu
        renderButton={() => (
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{
              cursor: 'pointer',
              backgroundColor: theme.colors?.utility[300],
              borderRadius: theme.spacing(6),
              padding: theme.spacing(2, 4),
            }}
          >
            <Avatar
              sx={{
                '& .MuiSvgIcon-root': {
                  mr: `0 !important`,
                },
                height: theme.spacing(6),
                width: theme.spacing(6),
              }}
              src={
                currentBrand.creators[0]?.profilePictureUrl ||
                currentBrand.creators[1]?.handle ||
                ''
              }
            />
            <Typography
              variant="body-xl"
              color={theme.colors?.primary.black}
              sx={{
                fontWeight: 600,
              }}
              onClick={() => {}}
            >
              {currentBrand.name}
            </Typography>
            <IconLinearArrowDown size={16} />
          </Box>
        )}
        options={(user?.socialListeningBrands || []).map((brand) => ({
          renderOption: () => (
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              sx={{
                flex: 1,
                cursor: 'pointer',
                padding: theme.spacing(1, 2),
              }}
            >
              <Avatar
                sx={{
                  height: 24,
                  width: 24,
                }}
                src={
                  brand.creators[0]?.profilePictureUrl ||
                  brand.creators[1]?.profilePictureUrl ||
                  ''
                }
              />
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[800]}
                fontWeight={600}
                flex={1}
                minWidth={theme.spacing(40)}
              >
                {brand.name}
              </Typography>

              <ContextMenu
                options={[
                  {
                    renderOption: () => (
                      <Typography
                        width="100%"
                        variant="body-lg"
                        fontWeight={600}
                        color={theme.colors?.utility[700]}
                        display="flex"
                        alignItems="center"
                        gap={2}
                      >
                        <IconOutlineSetting2 size={16} />
                        Settings
                      </Typography>
                    ),
                    onClick: () => {
                      navigate(
                        PlotRoutes.socialListeningSettings({
                          brandId: brand.id,
                        }),
                      );
                    },
                  },
                  {
                    renderOption: () => (
                      <Box onClick={(e) => e.stopPropagation()} width="100%">
                        <SocialListeningUserPermissionsView
                          brandId={brand.id}
                          renderCustomBtn={() => (
                            <Typography
                              width="100%"
                              variant="body-lg"
                              fontWeight={600}
                              color={theme.colors?.utility[700]}
                              display="flex"
                              alignItems="center"
                              gap={2}
                            >
                              <IconLinearExport3 size={16} />
                              Share
                            </Typography>
                          )}
                        />
                      </Box>
                    ),
                  },
                ]}
                renderButton={() => (
                  <IconLinearMore
                    color={theme.colors?.utility[600]}
                    size={20}
                  />
                )}
              />
            </Box>
          ),
          onClick: () => {
            navigate(generateUrl(brand.id));
          },
        }))}
      />
    </Box>
  );
};
