import { gql } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { IconOutlineArrowLeft2 } from 'components/icons/components/outline/IconOutlineArrowLeft2';
import {
  SearchType,
  SearchableEntityType,
  usePaginatedCollectionsForCollectionSearchResultsQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import {
  COLLECTION_SEARCH_RESULT_FRAGMENT_SECTION_VIEW,
  SearchListItemView,
} from 'features/search';

type Props = {
  searchStr: string;
  searchType: SearchType;
  onNavigateBack: () => void;
  elRefs: React.MutableRefObject<(HTMLElement | null)[]>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query PaginatedCollectionsForCollectionSearchResults(
    $data: UniversalSearchInput!
  ) {
    universalSearch(data: $data) {
      collectionSearchResult {
        ...CollectionSearchResultFragmentSectionView
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${COLLECTION_SEARCH_RESULT_FRAGMENT_SECTION_VIEW}
`;

const PAGE_SIZE = 10;

export const CollectionSearchResults = (props: Props) => {
  const { searchStr, onNavigateBack, searchType, elRefs } = props;
  const [hasMore, setHasMore] = useState(true);

  const variables = {
    entityTypes: [SearchableEntityType.Collections],
    limit: PAGE_SIZE,
    searchType,
    text: searchStr,
  };

  const { data: collectionData, fetchMore } =
    usePaginatedCollectionsForCollectionSearchResultsQuery({
      variables: {
        data: {
          ...variables,
        },
      },
    });

  const fetchMoreNotes = () => {
    if (
      !hasMore ||
      !collectionData?.universalSearch.collectionSearchResult.hits.length
    ) {
      return;
    }

    fetchMore({
      variables: {
        data: {
          ...variables,
          after:
            String(collectionData?.universalSearch.pageInfo.endCursor || 0) ||
            '0',
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          fetchMoreResult.universalSearch.collectionSearchResult.hits.length ===
          0
        ) {
          setHasMore(false);
        }
        return {
          ...fetchMoreResult,
          universalSearch: {
            ...fetchMoreResult.universalSearch,
            collectionSearchResult: {
              ...fetchMoreResult.universalSearch.collectionSearchResult,
              totalCount:
                fetchMoreResult.universalSearch.collectionSearchResult
                  .totalCount,
              hits: [
                ...(prev.universalSearch.collectionSearchResult?.hits || []),
                ...(fetchMoreResult.universalSearch.collectionSearchResult
                  ?.hits || []),
              ],
            },
          },
        };
      },
    });
  };

  useEffect(() => {
    setHasMore(true);
  }, [searchStr]);

  let itemIndex = 0;

  return (
    <Box>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        color={theme.colors?.utility[700]}
        mb={4}
        sx={{ cursor: 'pointer' }}
        onClick={onNavigateBack}
      >
        <IconOutlineArrowLeft2 size={12} />
        <Typography variant="body-xs" fontWeight={600}>
          Notes (
          {collectionData?.universalSearch.collectionSearchResult.totalCount})
        </Typography>
      </Box>
      <Box maxHeight={450} sx={{ overflowY: 'auto' }} mt={0}>
        <InfiniteScroll
          threshold={100}
          loadMore={() => fetchMoreNotes()}
          useWindow={false}
          hasMore={hasMore}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress
                size={24}
                sx={{ color: theme.colors?.primary.maroon }}
              />
            </Box>
          }
        >
          {(
            collectionData?.universalSearch.collectionSearchResult?.hits || []
          ).map((note) => (
            <Box key={note.item.id}>
              <SearchListItemView
                ref={(el) => {
                  elRefs.current[itemIndex] = el;
                  itemIndex++;
                }}
                searchHit={note}
                type={SearchableEntityType.Collections}
                searchString={searchStr}
              />
            </Box>
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
};
