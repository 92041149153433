import { gql } from '@apollo/client';
import { USER_FRAGMENT_AVATAR_GROUP } from 'components/common/AvatarGroup/types';
import { TASK_FRAGMENT_SEARCH_MODAL } from 'features/search/views/modal/components/TaskSearchList/types';
import { CollectionFragmentCollectionBreadcrumbsFragmentDoc } from 'graphql/generated';

const POST_METADATA_FRAGMENT_SEARCH_HANDLER = gql`
  fragment URLMetadataFragmentSearchHandler on URLMetadataModel {
    createdAt
    id
    metadata {
      audio
      author
      description
      hashtags
      medium
      site
      thumbnail {
        href
        mediaSize {
          height
          width
        }
        type
      }
      title
      type
    }
    updatedAt
    url
  }
`;

const POST_ATTACHMENT_FRAGMENT_SEARCH_HANDLER = gql`
  fragment PostAttachmentFragmentSearchHandler on AttachmentModel {
    createdAt
    id
    metaData {
      imageInfo {
        height
        orientation
        width
      }
      mimeType
      size
      videoInfo {
        duration
        format
        video {
          height
          width
        }
      }
    }
    name
    updatedAt
    url
  }
`;

export const POST_FRAGMENT_SEARCH_HANDLER = gql`
  fragment PostFragmentSearchHandler on PostModel {
    urlMetadata {
      ...URLMetadataFragmentSearchHandler
    }
    title
    id
    body
    attachment {
      ...PostAttachmentFragmentSearchHandler
    }
    type
  }
  ${POST_METADATA_FRAGMENT_SEARCH_HANDLER}
  ${POST_ATTACHMENT_FRAGMENT_SEARCH_HANDLER}
`;

export const COLLECTION_FRAGMENT_SEARCH_RECENT = gql`
  fragment CollectionFragmentSearchRecent on CollectionModel {
    id
    ...CollectionFragmentCollectionBreadcrumbs
    posts {
      id
      urlMetadata {
        id
        url
      }
    }
  }
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

// TODO: need to refactor this fragment
export const SEARCH_CLICKED_RESULT_FRAGMENT_SEARCH_RECENT = gql`
  fragment SearchClickedResultFragmentSearchRecent on SearchClickedResultModel {
    updatedAt
    task {
      ...TaskFragmentSearchModal
    }
    user {
      ...UserFragmentAvatarGroup
    }
    post {
      ...PostFragmentSearchHandler
    }
    collection {
      ...CollectionFragmentSearchRecent
    }
  }
  ${TASK_FRAGMENT_SEARCH_MODAL}
  ${USER_FRAGMENT_AVATAR_GROUP}
  ${POST_FRAGMENT_SEARCH_HANDLER}
  ${COLLECTION_FRAGMENT_SEARCH_RECENT}
`;
