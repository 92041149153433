import {
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const GenderDemographicsMap = {
  [SocialPostGenderDemographic.Male]: 'Male',
  [SocialPostGenderDemographic.Female]: 'Female',
  [SocialPostGenderDemographic.NotSpecified]: 'Not Specified',
};

export const GenerationDemographicsMap = {
  [SocialPostGenerationDemographic.GenZ]: 'Gen Z',
  [SocialPostGenerationDemographic.Millennials]: 'Millennials',
  [SocialPostGenerationDemographic.GenX]: 'Gen X',
  [SocialPostGenerationDemographic.Boomers]: 'Boomers',
};

export const GenderDemographicsBarColorMap = {
  [SocialPostGenderDemographic.Male]: theme.colors?.utility['blue-3'],
  [SocialPostGenderDemographic.Female]: theme.colors?.utility['pink-3'],
  [SocialPostGenderDemographic.NotSpecified]: theme.colors?.utility[400],
};

export const GenderDemographicsColorMap = {
  [SocialPostGenderDemographic.Male]: theme.colors?.utility['blue-3'],
  [SocialPostGenderDemographic.Female]: theme.colors?.utility['pink-3'],
  [SocialPostGenderDemographic.NotSpecified]: theme.colors?.utility[700],
};

export const GenderDemographicsChipBackgroundColorMap = {
  [SocialPostGenderDemographic.Male]: 'rgba(217, 227, 243, 0.50)',
  [SocialPostGenderDemographic.Female]: 'rgba(246, 225, 230, 0.50)',
  [SocialPostGenderDemographic.NotSpecified]: 'rgba(224, 224, 224, 0.50)',
};

export const GenerationDemographicsColorMap = {
  [SocialPostGenerationDemographic.GenZ]: theme.colors?.utility['teal-4'],
  [SocialPostGenerationDemographic.Millennials]:
    theme.colors?.utility['green-4'],
  [SocialPostGenerationDemographic.GenX]: theme.colors?.utility['blue-4'],
  [SocialPostGenerationDemographic.Boomers]: theme.colors?.utility['purple-4'],
};
export const GenerationDemographicsChipBackgroundColorMap = {
  [SocialPostGenerationDemographic.GenZ]: 'rgba(205, 226, 223, 0.50)',
  [SocialPostGenerationDemographic.Millennials]: 'rgba(224, 228, 202, 0.50)',
  [SocialPostGenerationDemographic.GenX]: 'rgba(217, 227, 243, 0.50)',
  [SocialPostGenerationDemographic.Boomers]: 'rgba(230, 221, 237, 0.50)',
};
