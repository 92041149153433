import { ComputableProgressInfo } from '@uploadcare/upload-client';
import { PostUploadFilesHandlerCallbacks } from 'contexts/commands/hooks/usePostCommands/components/PostUploadFilesHandler';
import { useUserContext } from 'contexts/users/User.context';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import {
  convertUploadCareFileToMetaData,
  useUploadCare,
} from 'hooks/useUploadCare';
import { useEffect, useState } from 'react';
import {
  ResourceData,
  ResourceUploadItem,
  ResourceUploadType,
  UploadStatus,
} from './types';

const cloneResources = (resources: ResourceUploadItem[]) => {
  return resources.map((item) => ({
    ...JSON.parse(JSON.stringify(item)),
    // Keep original because it might contain a File, which cannot be cloned by JSON.stringify
    resource: { ...item.resource },
  }));
};

export type UseResourceUploadQueueValues = {
  progressHandler?: PostUploadFilesHandlerCallbacks;
};

/**
 * This is the custom hook that is used to upload files to the server.
 *
 * It maintains 3 queues:
 * 1. uploadingResources: The convertUploadCareFileToMetaData that are currently being uploaded
 * 2. pendingUploadResources: The resources that are waiting to be uploaded
 * 3. uploadedResources: The resources that have been uploaded
 *
 * When an item is added to the pending queue using the addResourceToUploadQueue function, the useEffect automatically manages the upload process and
 * moves the item from the pending queue to the uploading queue.
 *
 * This hook provides the functions to add a resource to the queue, update the name of a resource, delete a resource and clear the session.
 *
 */
export const useResourceUploadQueue = ({
  progressHandler,
}: UseResourceUploadQueueValues = {}) => {
  const { user, orgBilling } = useUserContext();

  const uploadFile = useUploadCare();
  const [uploading, setUploading] = useState(false);

  const analytics = useAnalytics();

  const orgPaywallContext = orgBilling;

  const [uploadedResources, setUploadedResources] = useState<
    ResourceUploadItem[]
  >([]);
  const [uploadingResources, setUploadingResources] = useState<
    ResourceUploadItem[]
  >([]);
  const [pendingUploadResources, setPendingUploadResources] = useState<
    ResourceUploadItem[]
  >([]);

  const [allResources, setAllResources] = useState<ResourceUploadItem[]>([]);

  // This useEffect is used to manage the upload queue.
  // This useEffect will pick 1 item from the pending queue and move it to the uploading queue.
  useEffect(() => {
    if (uploading || uploadingResources.length !== 0) {
      return;
    }

    if (pendingUploadResources.length) {
      const pendingUploadResourcesQueue = [...pendingUploadResources];
      const itemsToUpload = pendingUploadResourcesQueue.splice(0, 1);
      setUploadingResources([...itemsToUpload]);
      setPendingUploadResources(pendingUploadResourcesQueue);
    }
  }, [uploading, pendingUploadResources, uploadingResources]);

  // When an item is added to the uploading queue, it is automatically uploaded to the server.
  // While the item is being uploaded, the upload progress is updated in the uploading queue.
  // Only 1 item is uploaded at a time to the uploadcare.
  useEffect(() => {
    if (!uploading && uploadingResources.length) {
      setUploading(true);
      uploadToUploadCare().finally(() => {
        setUploading(false);
      });
    }
  }, [uploadingResources]); // eslint-disable-line

  // This useEffect is used to manage the allResources queue.
  // This useEffect will update the allResources queue whenever any of the 3 queues are updated.
  // The allResources queue will contain all the resources in the order of their upload.
  useEffect(() => {
    setAllResources([
      ...uploadedResources,
      ...uploadingResources,
      ...pendingUploadResources,
    ]);
  }, [uploadedResources, uploadingResources, pendingUploadResources]);

  const addResourcesToUploadQueue = (resources: ResourceData[]) => {
    const mappedResources = resources.map(
      (resource, index) =>
        ({
          resource,
          uploading: false,
          uploadProgress: 0,
          uploadStatus: 'waiting',
          autoFocusFirstBatchItemOnAdd: index === 0,
        } as ResourceUploadItem),
    );

    setPendingUploadResources((prev) => {
      return [...prev, ...mappedResources];
    });
  };

  const updateResourceName = (index: number, name: string) => {
    setAllResources((currentList) => {
      const newList = [...currentList];
      newList[index].resource.name = name;
      return newList;
    });
  };

  const deleteResource = (index: number) => {
    const list = [...allResources];
    list.splice(index, 1);
    setAllResources(list);

    if (index < uploadedResources.length) {
      setUploadedResources((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });
    } else if (index < uploadedResources.length + uploadingResources.length) {
      setUploadingResources((prev) => {
        prev.splice(index - uploadedResources.length, 1);
        return [...prev];
      });
    } else {
      setPendingUploadResources((prev) => {
        prev.splice(
          index - uploadedResources.length - uploadingResources.length,
          1,
        );
        return [...prev];
      });
    }
  };

  const uploadToUploadCare = async () => {
    if (uploadedResources.length === 0) {
      progressHandler?.onUploadStart?.();
    }
    const itemToUpload = uploadingResources[0];
    switch (itemToUpload.resource.type) {
      case ResourceUploadType.Link: {
        const list = cloneResources(uploadingResources);
        const item = list[0];
        item.uploadProgress = 100;
        item.uploadStatus = UploadStatus.UPLOADED;
        item.uploading = false;
        item.uploadedInfo = {
          url: itemToUpload.resource.content as string,
        };
        setUploadingResources(list.splice(1));
        setUploadedResources([...uploadedResources, item]);
        break;
      }
      case ResourceUploadType.Attachment: {
        const {
          resource: { content, name },
        } = itemToUpload;
        try {
          if (
            orgPaywallContext &&
            orgPaywallContext.fileStorageLimitInMb &&
            orgPaywallContext.fileStorageUsedInMb >
              orgPaywallContext.fileStorageLimitInMb
          ) {
            analytics.track(EventName.PaywallHit, {
              data: {
                userId: user?.id,
                limitHitType: 'fileStorageUploaded',
                ...orgPaywallContext,
              },
            });

            clearAllResources();
            return;
          }

          if (!uploadFile) {
            throw new Error('Unknown error occurred. Please try again later.');
          }

          // set upload item status as uploading
          const item = uploadingResources[0];
          item.uploading = true;
          item.uploadStatus = UploadStatus.UPLOADING;
          setUploadingResources((prev) => {
            const list = [...prev];
            list[0] = item;
            return list;
          });

          const uploadResult = await uploadFile(
            content as File,
            name,
            (arg) => {
              if (arg.isComputable) {
                const progress = (arg as ComputableProgressInfo).value;
                // update uploading item progress
                setUploadingResources((prev) => {
                  const list = cloneResources(prev);
                  if (list.length === 0) return list;
                  const newProgress = Math.round(progress * 100);
                  list[0].uploadProgress = newProgress;
                  progressHandler?.onUploadProgress?.(list[0], newProgress);
                  return list;
                });
              }
            },
          );

          const metaData = convertUploadCareFileToMetaData(uploadResult);

          // mark item as uploaded
          setUploadingResources((uploadingResources) => {
            const list = cloneResources(uploadingResources);
            const item = list[0];
            if (!item) return list;
            item.uploadProgress = 100;
            item.uploadStatus = UploadStatus.UPLOADED;
            item.uploading = false;
            item.uploadedInfo = {
              url:
                `${uploadResult.cdnUrl}${uploadResult.name}` ||
                uploadResult.s3Url ||
                '',
              size: uploadResult.size || 0,
              metaData,
            };

            progressHandler?.onUploadDone?.(item);
            setUploadedResources([...uploadedResources, item]);
            return list.splice(1);
          });
        } catch (error) {
          // handle error if the item is not uploaded
          const list = [...uploadingResources];
          const item = list[0];
          if (!item) return list;
          item.uploadProgress = 0;
          item.uploadStatus = UploadStatus.ERROR;
          item.uploading = false;
          list.splice(1);
          setUploadingResources(list);
          setUploadedResources([...uploadedResources, item]);
        }
        break;
      }
      default:
        break;
    }
  };

  const clearAllResources = () => {
    setUploadedResources([]);
    setUploadingResources([]);
    setPendingUploadResources([]);
    setAllResources([]);
  };

  return {
    allResources,
    uploadedResources,
    addResourcesToUploadQueue,
    deleteResource,
    updateResourceName,
    clearAllResources,
  };
};
