import { PostThemeColor } from 'features/note/constants/noteColors';

export const TaskNodeAttributes = {
  id: 'task-id',
  status: 'data-status',
  startDate: 'data-startDate',
  endDate: 'data-endDate',
  ownerId: 'data-ownerId',
  priority: 'data-priority',
};

export const TaskNodeAttributesArray = Object.values(TaskNodeAttributes);

export interface TaskItemOptions {
  nested: boolean;
  externalAttrs: TaskItemExtensionExternalAttrs;
  HTMLAttributes: Record<string, any>;
}

export type TaskItemExtensionExternalAttrs = {
  themeColor?: PostThemeColor;
};
