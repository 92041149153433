import { gql } from '@apollo/client';
import {
  BrandFragmentForUseBrandSelectorFragmentDoc,
  OrganizationBillingFragmentBannerViewFragmentDoc,
  OrganizationBillingFragmentCollectionPaywallFragmentDoc,
  OrganizationBillingFragmentFileDeliveryFragmentDoc,
  OrganizationBillingFragmentFreeTrialFragmentDoc,
  OrganizationBillingFragmentGuestPaywallFragmentDoc,
  OrganizationBillingFragmentLimitProgressFragmentDoc,
  OrganizationBillingFragmentNewPricingFragmentDoc,
  OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc,
  OrganizationBillingFragmentTrendsPaywallFragmentDoc,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';

export const USER_PROFILE_FRAGMENT_USER_CONTEXT = gql`
  fragment UserProfileFragmentUserContext on UserProfileModel {
    id
    email
    firstName
    lastName
    avatarUrl
    title
    fields
    role
    externalRole
    createdAt
    role
    externalRole
    isDisabled
    hasBeenApprovedByAdmin
    isInvitedBy {
      id
      firstName
      lastName
      email
      avatarUrl
    }
    organization {
      id
      name
    }
    ...UserFragmentAvatarGroup
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export const ORGANIZATION_BILLING_FRAGMENT_USER_CONTEXT = gql`
  fragment OrganizationBillingFragmentUserContext on OrganizationBillingModel {
    id
    organizationId
    billingCustomerId
    aiFeaturesEnabled
    socialListeningEnabled
    requestedAdminToUpgradePlans
    customTrendsEnabled
    brandHoursParseLimit
    hasReachedBrandUsageLimit
    socialListeningHourUsageThresholdAlert {
      emailAlertThresholdPercentage
      dialogAlertThresholdPercentage
    }
    subscription {
      id
      upcomingInvoice {
        periodEnd
        amountDue
      }
    }
    ...OrganizationBillingFragmentCollectionPaywall
    ...OrganizationBillingFragmentFileDelivery
    ...OrganizationBillingFragmentLimitProgress
    ...OrganizationBillingFragmentFreeTrial
    ...OrganizationBillingFragmentGuestPaywall
    ...OrganizationBillingFragmentOrganizationMemberPaywall
    ...OrganizationBillingFragmentBannerView
    ...OrganizationBillingFragmentTrendsPaywall
    ...OrganizationBillingFragmentNewPricing
  }
  ${OrganizationBillingFragmentCollectionPaywallFragmentDoc}
  ${OrganizationBillingFragmentFileDeliveryFragmentDoc}
  ${OrganizationBillingFragmentLimitProgressFragmentDoc}
  ${OrganizationBillingFragmentFreeTrialFragmentDoc}
  ${OrganizationBillingFragmentGuestPaywallFragmentDoc}
  ${OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc}
  ${OrganizationBillingFragmentBannerViewFragmentDoc}
  ${OrganizationBillingFragmentTrendsPaywallFragmentDoc}
  ${OrganizationBillingFragmentNewPricingFragmentDoc}
`;

export const SOCIAL_AUTHOR_FRAGMENT_USER_CONTEXT = gql`
  fragment SocialAuthorFragmentUserContext on SocialAuthorModel {
    id
    name
    profileUrl
    avatarUrl
    platform
    platformHandle
  }
`;

// FIXME: Fix external user fragment when PR for internal/external role is merged
export const ORGANIZATION_FRAGMENT_USER_CONTEXT = gql`
  fragment OrganizationFragmentUserContext on OrganizationModel {
    id
    name
    domain
    myPermissions
    type
    users {
      ...UserProfileFragmentUserContext
    }
    externalUsers {
      ...UserProfileFragmentUserContext
    }
    myPermissions
    billing {
      ...OrganizationBillingFragmentUserContext
    }
    socialAuthors {
      id
      ...SocialAuthorFragmentUserContext
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_CONTEXT}
  ${ORGANIZATION_BILLING_FRAGMENT_USER_CONTEXT}
  ${SOCIAL_AUTHOR_FRAGMENT_USER_CONTEXT}
`;

export const USER_FRAGMENT_USER_CONTEXT = gql`
  fragment MeFragmentUserQueries on MeModel {
    id
    createdAt
    email
    firstName
    lastName
    phoneNumber
    avatarUrl
    fields
    role
    title
    cohorts
    hasBeenApprovedByAdmin
    onboardingState {
      haveSeenJuiceBoxIntro
      haveClickedJuiceBoxBtn
      haveOnboardedAiOrganize
      haveSeenTrialWrapped
      hasSeenContentCalendarIntroduction
      hasSeenContentCalendarOnboarding
    }
    socialListeningBrands {
      ...BrandFragmentForUseBrandSelector
    }
    onboardingAnswers {
      referralSource
    }
    organization {
      socialListeningBrands {
        id
        name
      }
      settings {
        restrictedAccessForUser
      }
      ...OrganizationFragmentUserContext
    }
    joinedOrganizations {
      id
      name
      internalRole
      externalRole
      billing {
        ...OrganizationBillingFragmentUserContext
      }
    }
    uploadToken {
      secureExpire
      secureSignature
    }
    registrationMethod
    hasVerifiedEmail
    emailVerificationToken
    taskCreatedCount
    postCreatedCount
    plan
    invitedByOrganization {
      id
      name
    }
    invitedByUser {
      id
      email
    }
    personalSettings {
      postGeneralPermissionDefault
      postGeneralPermissionDefaultPermissionLevel
      collectionGeneralPermissionDefault
      collectionGeneralPermissionDefaultPermissionLevel
    }
    logoutUrl
  }
  ${ORGANIZATION_FRAGMENT_USER_CONTEXT}
  ${ORGANIZATION_BILLING_FRAGMENT_USER_CONTEXT}
  ${BrandFragmentForUseBrandSelectorFragmentDoc}
`;

export const AUTH_FRAGMENT_USER_CONTEXT_FRAGMENT = gql`
  fragment AuthFragmentUserContext on AuthModel {
    accessToken
    refreshToken
    user {
      id
      role
    }
    isSignUp
  }
`;
