import { Iframely } from 'components/common/Iframely';

export const DefaultRenderer = (props: { src: string; id: string }) => {
  const { src, id } = props;

  return (
    <Iframely
      url={src}
      fullWidth
      urlMetadata={{
        id,
        url: src,
      }}
    />
  );
};
