import { gql } from '@apollo/client';
import { toast } from 'components/common/Toast';
import { useUserContext } from 'contexts/users/User.context';
import {
  CommentFilter,
  CommentFragmentCommentContainerViewFragment,
  CommentFragmentTaskCommentListViewFragmentDoc,
  CreateCommentInput,
  GetCommentsForTaskCommentListViewDocument,
  GetCommentsForTaskCommentListViewQuery,
  UpdateCommentInput,
  useCreateCommentForTaskMutation,
  useDeleteCommentForTaskMutation,
  useReactToCommentForTaskMutation,
  useReadAllCommentsForTaskMutation,
  useUpdateCommentForTaskMutation,
} from 'graphql/generated';
import { evictObject, modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreateCommentForTask($data: CreateCommentInput!) {
    createComment(data: $data) {
      id
      ...CommentFragmentTaskCommentListView
    }
  }
  ${CommentFragmentTaskCommentListViewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation ReactToCommentForTask($data: ReactInput!) {
    react(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCommentForTask($data: UpdateCommentInput!) {
    updateComment(data: $data) {
      id
      ...CommentFragmentTaskCommentListView
    }
  }
  ${CommentFragmentTaskCommentListViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteCommentForTask($data: DeleteCommentInput!) {
    deleteComment(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation ReadAllCommentsForTask($data: ReadAllCommentsInput!) {
    readAllComments(data: $data) {
      message
      success
    }
  }
`;

export const useTaskCommentHandlers = () => {
  const { user } = useUserContext();
  const [createTaskComment] = useCreateCommentForTaskMutation();
  const [reactToTaskComment] = useReactToCommentForTaskMutation();
  const [deleteTaskComment] = useDeleteCommentForTaskMutation();
  const [updateTaskComment] = useUpdateCommentForTaskMutation();
  const [readAllTaskComments] = useReadAllCommentsForTaskMutation();

  const onCreateTaskComment = (
    data: CreateCommentInput,
    filters: CommentFilter,
    callback?: (id: string) => void,
  ) => {
    createTaskComment({
      variables: {
        data,
      },
      update: (cache, res) => {
        const createdTaskComment = res.data?.createComment;

        if (createdTaskComment) {
          const newTaskCommentRef = cache.writeFragment({
            data: createdTaskComment,
            fragment: CommentFragmentTaskCommentListViewFragmentDoc,
            fragmentName: 'CommentFragmentTaskCommentListView',
          });

          if (createdTaskComment.parentThreadId) {
            const parentCommentReference = cache.identify({
              __typename: 'CommentModel',
              id: createdTaskComment.parentThreadId,
            });

            // If it's a child comment, update the parent comment's childComments.
            cache.modify({
              id: parentCommentReference,
              fields: {
                childComments: (cachedChildComments) => {
                  return [...cachedChildComments, newTaskCommentRef];
                },
              },
            });
          } else {
            cache.updateQuery(
              {
                query: GetCommentsForTaskCommentListViewDocument,
                variables: {
                  filters,
                },
              },
              (data: GetCommentsForTaskCommentListViewQuery | null) => {
                if (!data) return null;

                return {
                  ...data,
                  comments: [...data.comments, createdTaskComment],
                };
              },
            );
          }

          callback?.(createdTaskComment.id);
        }
      },
    });
  };

  const onReactToComment = (commentId: string, emoji: string) => {
    reactToTaskComment({
      variables: {
        data: {
          commentId,
          emoji,
        },
      },
      update: (cache, res) => {
        const createdTaskComment = res.data?.react;

        if (createdTaskComment) {
          modifyObject(cache, commentId, 'CommentModel', {
            userReactions: (cachedUserReactions) => {
              const reaction = cachedUserReactions.find(
                (c) => c.emoji === emoji && c.userId === user?.id,
              );
              if (reaction) {
                evictObject(cache, reaction.id, 'UserReactionModel');
              } else {
                return [
                  ...cachedUserReactions,
                  {
                    __typename: 'UserReactionModel',
                    user,
                    userId: user?.id,
                  },
                ];
              }
            },
          });
        }
      },
    });
  };
  const onDeleteTaskComment = (
    taskComment: Omit<
      CommentFragmentCommentContainerViewFragment,
      'childComments'
    >,
  ) => {
    const taskCommentId = taskComment.id;
    deleteTaskComment({
      variables: {
        data: {
          commentId: taskComment.id,
        },
      },
      update: (cache, res) => {
        const deletedTaskComment = res.data?.deleteComment;

        if (deletedTaskComment?.success) {
          const deletedTaskComment = cache.identify({
            __typename: 'CommentModel',
            id: taskCommentId,
          });

          if (taskComment.parentThreadId) {
            const parentCommentReference = cache.identify({
              __typename: 'CommentModel',
              id: taskComment.parentThreadId,
            });

            cache.modify({
              id: parentCommentReference,
              fields: {
                childComments: (cachedChildComments) => {
                  return cachedChildComments.filter(
                    (comment) => comment.__ref !== deletedTaskComment,
                  );
                },
              },
            });
          } else {
            evictObject(cache, taskCommentId, 'CommentModel');
          }
        }
      },
    });
  };

  const onUpdateTaskComment = (data: UpdateCommentInput) => {
    updateTaskComment({
      variables: {
        data,
      },
      onCompleted: () => {
        toast({
          message: 'Task comment updated successfully!!',
          type: 'success',
        });
      },
      update: (cache, res) => {
        const updatedTaskComment = res.data?.updateComment;
        if (updatedTaskComment && updatedTaskComment.taskId) {
          modifyObject(cache, updatedTaskComment.taskId, 'TaskModel', {
            text: () => updatedTaskComment.text,
            childComments: () => updatedTaskComment.childComments,
          });
        }
      },
    });
  };

  const onReadAllTaskComments = (taskId: string) => {
    readAllTaskComments({
      variables: {
        data: {
          taskId,
        },
      },
    });
  };
  return {
    onCreateTaskComment,
    onReactToComment,
    onDeleteTaskComment,
    onUpdateTaskComment,
    onReadAllTaskComments,
  };
};
