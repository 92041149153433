import { gql } from '@apollo/client';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import {
  SentimentSubjectStatus,
  useGetSentimentSubjectsByTopicForSettingsSentimentSubjectQuery,
  useUpdateSentimentSubjectForSettingsSentimentSubjectMutation,
  useUpsertSentimentSubjectsForSettingsSentimentSubjectMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject, modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSentimentSubjectsByTopicForSettingsSentimentSubject(
    $topicId: String!
  ) {
    sentimentSubjectsByTopic(topicId: $topicId) {
      id
      subject
      topicId
      status
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpsertSentimentSubjectsForSettingsSentimentSubject(
    $data: UpsertSentimentSubjectInput!
  ) {
    upsertSentimentSubjects(data: $data) {
      id
      subject
      topicId
      status
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateSentimentSubjectForSettingsSentimentSubject(
    $data: UpdateSentimentSubjectInput!
  ) {
    updateSentimentSubject(data: $data) {
      success
    }
  }
`;

type Props = {
  currentTopicId: string;
};

export const SettingsSentimentSubjectsView = ({ currentTopicId }: Props) => {
  const [upsertSentimentSubjects] =
    useUpsertSentimentSubjectsForSettingsSentimentSubjectMutation();
  const [updateSentimentSubject] =
    useUpdateSentimentSubjectForSettingsSentimentSubjectMutation();

  const {
    dialog: sentimentSubjectDeleteConfirmationDialog,
    onOpen: openSentimentSubjectDeleteConfirmationDialog,
  } = useConfirmationDialog();

  const [addingNewSubject, setAddingNewSubject] = useState(false);
  const [currentSubject, setCurrentSubject] = useState<string>('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedSubjects, setEditedSubjects] = useState<{
    [key: string]: string;
  }>({});

  const { data: sentimentSubjectsData, refetch: refetchSentimentSubjects } =
    useGetSentimentSubjectsByTopicForSettingsSentimentSubjectQuery({
      variables: {
        topicId: currentTopicId,
      },
    });
  const sentimentSubjectsFromAPI =
    sentimentSubjectsData?.sentimentSubjectsByTopic;

  const handleUpdateSentimentSubjectStatus = async (
    sentimentSubjectId: string,
    status: SentimentSubjectStatus,
  ) => {
    await updateSentimentSubject({
      variables: {
        data: {
          sentimentSubjectId,
          data: {
            status,
            subject:
              sentimentSubjectsFromAPI?.find((s) => s.id === sentimentSubjectId)
                ?.subject || '',
          },
        },
      },
      onCompleted: () => {
        refetchSentimentSubjects();
      },
    });
  };

  const handleSave = async (
    id: string,
    subject: string,
    status?: SentimentSubjectStatus,
  ) => {
    const existingSubject = sentimentSubjectsFromAPI?.find((s) => s.id === id);
    if (!existingSubject) return;

    await updateSentimentSubject({
      variables: {
        data: {
          sentimentSubjectId: id,
          data: {
            subject,
            ...(status ? { status } : {}),
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.updateSentimentSubject.success) {
          if (status === SentimentSubjectStatus.Deleted) {
            evictObject(cache, id, 'SentimentSubjectModel');
          } else {
            modifyObject(cache, id, 'SentimentSubjectModel', {
              subject: () => subject,
              ...(status ? { status: () => status } : {}),
            });
          }
        }
      },
      onCompleted: () => {
        setEditingIndex(null);
        setEditedSubjects((prev) => {
          const updated = { ...prev };
          delete updated[id];
          return updated;
        });
      },
    });
  };

  const addNewSubject = async () => {
    if (currentSubject.trim() === '') return;

    await upsertSentimentSubjects({
      variables: {
        data: {
          topicId: currentTopicId,
          subjects: [
            ...(sentimentSubjectsFromAPI?.map((s) => s.subject) || []),
            currentSubject,
          ],
        },
      },
      onCompleted: () => {
        setCurrentSubject('');
        setAddingNewSubject(false);
        refetchSentimentSubjects();
      },
    });
  };

  const deleteSubject = async (index: number) => {
    const updatedSubjects =
      sentimentSubjectsFromAPI
        ?.filter((_, i) => i !== index)
        .map((s) => s.subject) || [];

    await upsertSentimentSubjects({
      variables: {
        data: {
          topicId: currentTopicId,
          subjects: updatedSubjects,
        },
      },
      onCompleted: () => {
        refetchSentimentSubjects();
      },
    });
  };

  const onEscapeInInput = (index: number) => {
    setEditingIndex(null);
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="headline-md">Sentiment</Typography>
      <Typography
        variant="body-xl"
        color={theme.colors?.utility[800]}
        fontWeight={500}
      >
        What do you want to track the sentiment towards?
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: 'fit-content',
          cursor: 'pointer',
          background: 'rgba(35, 6, 3, 0.10)',
          borderRadius: theme.spacing(2),
          padding: theme.spacing(2, 3),
          color: theme.colors?.primary.black,
        }}
        onClick={() => {
          setAddingNewSubject(true);
          setEditingIndex(null);
        }}
      >
        <IconBoldAddCircle size={16} />
        <Typography variant="body-xl" fontWeight={600}>
          Add sentiment topic
        </Typography>
      </Box>

      {addingNewSubject && (
        <Box display="flex" flexDirection="column" gap={6} width="100%" mt={3}>
          <Box display="flex" flexDirection="column" gap={2} width="100%">
            <TextField
              autoFocus
              placeholder="Sentiment towards something in particular"
              value={currentSubject}
              onChange={(e) => setCurrentSubject(e.target.value)}
              onKeyDown={async (e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  await addNewSubject();
                } else if (e.key === 'Escape') {
                  setAddingNewSubject(false);
                  setCurrentSubject('');
                  (e.target as HTMLInputElement).blur();
                }
              }}
              sx={{
                '.MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(4),
                  border: `1px solid ${theme.colors?.utility[300]}`,
                  bgcolor: theme.colors?.utility[200],
                  py: `${theme.spacing(3)} !important`,
                  px: `${theme.spacing(4)} !important`,
                  '&:focus': {},

                  input: {
                    ...theme.typography['body-xl'],
                    p: '0 !important',
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              }}
            />

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ minHeight: theme.spacing(9) }}
            >
              <Typography
                variant="body-lg"
                display="flex"
                alignItems="center"
                height="100%"
              >
                Press{' '}
                <Box
                  component="span"
                  p={theme.spacing(0.5, 1)}
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.15)',
                    mx: 1,
                    color: theme.colors?.utility[700],
                    border: `1px solid ${theme.colors?.utility[500]}`,
                    borderRadius: theme.spacing(2),
                    fontWeight: 600,
                    fontSize: theme.spacing(3),
                  }}
                >
                  esc
                </Box>{' '}
                to cancel
              </Typography>

              {currentSubject.trim() !== '' && (
                <Box
                  component="button"
                  onClick={addNewSubject}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: 'rgba(35, 6, 3, 0.10)',
                    color: theme.colors?.primary.black,
                    borderRadius: theme.spacing(8),
                    px: theme.spacing(4),
                    py: theme.spacing(2),
                    fontWeight: 600,
                    border: 'none',
                    ...theme.typography['body-xl'],
                  }}
                >
                  Save sentiment
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {sentimentSubjectsFromAPI?.map((subject, index) => (
        <Box
          key={subject.id}
          display="flex"
          flexDirection="column"
          gap={2}
          width="100%"
        >
          <TextField
            placeholder="Sentiment towards something in particular"
            defaultValue={subject.subject}
            onChange={(e) => {
              setEditedSubjects((prev) => ({
                ...prev,
                [subject.id]: e.target.value,
              }));
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                await handleSave(
                  subject.id,
                  editedSubjects[subject.id] || subject.subject,
                );
              } else if (e.key === 'Escape') {
                onEscapeInInput(index);
                (e.target as HTMLInputElement).blur();
              }
            }}
            onFocus={() => setEditingIndex(index)}
            InputProps={{
              endAdornment: (
                <Box display="flex" gap={1} alignItems="center">
                  <Tooltip
                    title={
                      subject.status === SentimentSubjectStatus.Active
                        ? 'Pause sentiment'
                        : 'Resume sentiment'
                    }
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSave(
                          subject.id,
                          subject.subject,
                          subject.status === SentimentSubjectStatus.Active
                            ? SentimentSubjectStatus.Inactive
                            : SentimentSubjectStatus.Active,
                        );
                      }}
                      sx={{
                        p: 1,
                      }}
                    >
                      {subject.status === SentimentSubjectStatus.Active ? (
                        <IconBoldPauseCircle />
                      ) : (
                        <IconBoldPlayCircle />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete sentiment">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();

                        openSentimentSubjectDeleteConfirmationDialog({
                          title: (
                            <>
                              Are you sure you want to delete the created
                              sentiment?
                            </>
                          ),
                          subtitle: `"${subject.subject}" will be deleted.`,
                          confirmText: 'Yes, remove sentiment',
                          onConfirm: () => {
                            handleSave(
                              subject.id,
                              subject.subject,
                              SentimentSubjectStatus.Deleted,
                            );
                          },
                        });
                      }}
                      sx={{
                        color: theme.colors?.utility['pink-3'],
                        p: 1,
                      }}
                    >
                      <IconBoldTrash size={16} />
                    </IconButton>
                  </Tooltip>
                </Box>
              ),
            }}
            sx={{
              '.MuiOutlinedInput-root': {
                borderRadius: theme.spacing(4),
                border: `1px solid ${theme.colors?.utility[300]}`,
                py: `${theme.spacing(2)} !important`,
                px: `${theme.spacing(4)} !important`,
                bgcolor: theme.colors?.utility[200],
                color: theme.colors?.primary.black,
                '&:focus': {
                  border: `2px solid ${theme.colors?.utility[500]}`,
                },

                input: {
                  ...theme.typography['body-xl'],
                  p: '0 !important',
                },

                '.MuiOutlinedInput-notchedOutline': {
                  display: 'none !important',
                },
              },
            }}
          />

          {editingIndex === index && subject.subject.trim() !== '' && (
            <Box display="flex" justifyContent="flex-end">
              <Box
                component="button"
                onClick={() => {
                  handleSave(
                    subject.id,
                    editedSubjects[subject.id] || subject.subject,
                  );
                }}
                sx={{
                  display: 'flex',
                  width: 'fit-content',
                  cursor: 'pointer',
                  backgroundColor: 'rgba(35, 6, 3, 0.10)',
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(8),
                  px: theme.spacing(4),
                  py: theme.spacing(2),
                  fontWeight: 600,
                  border: 'none',
                  ...theme.typography['body-xl'],
                }}
              >
                Save sentiment
              </Box>
            </Box>
          )}
        </Box>
      ))}
      {sentimentSubjectDeleteConfirmationDialog}
    </Box>
  );
};
