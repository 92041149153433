import { Box, MenuItemProps, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { IconOutlineHashtag } from 'components/icons/components/outline/IconOutlineHashtag';
import { theme } from 'styles/theme';

export const UNORGANIZED_POSTS_MENU_ITEM_VALUE_KEY = '-1';

export type UnorganizedPostsMenuItemProps = MenuItemProps & {};

export const UnorganizedPostsMenuItem = (
  props: UnorganizedPostsMenuItemProps,
) => {
  const { ...rest } = props;

  return (
    <CheckboxMenuItem
      value={UNORGANIZED_POSTS_MENU_ITEM_VALUE_KEY}
      sx={{
        '&.Mui-selected': { backgroundColor: 'transparent' },
      }}
      {...rest}
      checked={rest.selected}
      label={
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Box
            sx={{
              width: 36,
              height: 36,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              p: 1.5,
              bgcolor: theme.colors?.utility['purple-4'],
              color: theme.colors?.primary.parchment,
            }}
          >
            <IconOutlineHashtag size={24} />
          </Box>
          <Box>
            <Typography variant="subhead-lg">Unorganized Posts</Typography>
          </Box>
        </Box>
      }
    />
  );
};
