import { Box, SxProps } from '@mui/material';
import { InfiniteScrollBoundary } from '../InfiniteScrollBoundary';

export type ScrollableContainerProps = {
  children: React.ReactNode;
  sx?: SxProps;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  onStartReached?: () => void;
  onEndReached?: () => void;
};

export const ScrollableContainer = (props: ScrollableContainerProps) => {
  const {
    children,
    sx,
    hasPreviousPage,
    hasNextPage,
    onStartReached,
    onEndReached,
  } = props;

  return (
    <Box
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        ...sx,
      }}
    >
      {onStartReached && (
        <InfiniteScrollBoundary
          disabled={!hasPreviousPage}
          onVisible={onStartReached}
          threshold={200}
          side="top"
        />
      )}
      {children}
      {onEndReached && (
        <InfiniteScrollBoundary
          disabled={!hasNextPage}
          onVisible={onEndReached}
          threshold={200}
        />
      )}
    </Box>
  );
};
