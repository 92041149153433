import { Box, Typography } from '@mui/material';
import { createDateRangesForGraph } from 'features/socialMediaListening/hooks/useGraphDataMakerNew';
import { MultiChartDataPoint } from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// Define line colors array with primary and highlight colors
const LINE_COLORS = [
  {
    primary: theme.colors?.utility['yellow-3'],
    highlight: theme.colors?.utility['yellow-1'],
  },
  {
    primary: theme.colors?.utility['green-3'],
    highlight: theme.colors?.utility['green-1'],
  },
  {
    primary: theme.colors?.utility['blue-3'],
    highlight: theme.colors?.utility['blue-1'],
  },
  {
    primary: theme.colors?.utility['purple-3'],
    highlight: theme.colors?.utility['purple-1'],
  },
  {
    primary: theme.colors?.utility['teal-3'],
    highlight: theme.colors?.utility['teal-1'],
  },
  {
    primary: theme.colors?.utility['pink-3'],
    highlight: theme.colors?.utility['pink-1'],
  },
  {
    primary: theme.colors?.utility['orange-3'],
    highlight: theme.colors?.utility['orange-1'],
  },
] as const;

// Export function to get color by index
export const getColorByIndex = (index: number, isHighlight = false): string => {
  const normalizedIndex =
    ((index % LINE_COLORS.length) + LINE_COLORS.length) % LINE_COLORS.length;
  const colors = LINE_COLORS[normalizedIndex];
  return isHighlight
    ? colors?.highlight ?? theme.colors?.utility['yellow-1']!
    : colors?.primary ?? theme.colors?.utility['yellow-3']!;
};

type LabelData = {
  id: string;
  value: number;
};

type Props = {
  multiGraphData: MultiChartDataPoint[];
  dateRange: [Date, Date];
  onClick: (id: string) => void;
};

// Custom tooltip component
const CustomTooltip = ({
  active,
  payload,
  coordinate,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: TooltipProps<number, string> & {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (id: string) => void;
}) => {
  if (!active || !payload?.length) return null;

  const handleItemClick = (id: string) => {
    onClick(id);
  };

  return (
    <Box
      onMouseEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onMouseEnter();
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onMouseLeave();
      }}
      sx={{
        backgroundColor: 'rgba(251, 247, 245, 1)',
        border: (theme) => `1px solid ${theme.colors?.utility[300]}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(8),
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        minWidth: '200px',
        position: 'relative',
        left: coordinate?.x && coordinate.x > 500 ? -220 : 10,
        pointerEvents: 'auto',
        overflowY: 'auto',
        maxHeight: theme.spacing(60),
      }}
    >
      <Typography
        variant="body-xl"
        color={theme.colors?.utility[600]}
        mb={2}
        display="block"
      >
        {payload[0].payload?.date}
      </Typography>

      {payload.map((entry) => (
        <Box
          key={entry.name}
          onClick={() => {
            const key = (entry.dataKey as string).replace('.value', '');
            if (entry.payload?.[key]?.id) {
              handleItemClick(entry.payload?.[key]?.id ?? '');
            }
          }}
          sx={{
            display: 'flex',
            py: theme.spacing(1),
            my: theme.spacing(1),
            alignItems: 'flex-start',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            color: entry.color,
            '&:hover': {
              backgroundColor: (theme) => theme.colors?.utility[200],
            },
          }}
        >
          <Box display="flex">
            <Typography
              variant="body-xl"
              sx={{
                width: theme.spacing(16),
                marginLeft: 'auto',
                fontWeight: 600,
              }}
            >
              {entry.value ? formatBigNumber(entry.value) : '0'}
            </Typography>

            <Typography
              sx={{
                marginRight: '8px',
                fontWeight: 500,
                color: (theme) => theme.colors?.utility[900],
              }}
              variant="body-xl"
            >
              {(entry.dataKey as string).replace('.value', '')}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const SLAMultiLineChartRenderer = (props: Props) => {
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const { multiGraphData, dateRange } = props;

  const allChartDataPoints = multiGraphData.flatMap(
    (dataset) => dataset.chartDataPoints,
  );
  const lowestDate = Math.min(
    ...allChartDataPoints.map((point) => moment(point.X).valueOf()),
  );

  const highestDate = Math.max(
    ...allChartDataPoints.map((point) => moment(point.X).valueOf()),
  );

  const graphDataRanges = useMemo(
    () =>
      createDateRangesForGraph(
        moment(lowestDate).toDate(),
        moment(highestDate).toDate(),
      ),
    [lowestDate, highestDate],
  );

  const combinedDataForGraph = useMemo(() => {
    if (!graphDataRanges.length) return [];

    const baseCombinedDataForGraph: {
      [key: string]: LabelData | string;
      date: string;
    }[] = graphDataRanges.map((range) => ({
      date: range.label,
    }));

    multiGraphData.forEach((dataset) => {
      // For each dataset, find matching data points for each date range
      baseCombinedDataForGraph.forEach((baseData, index) => {
        const rangeStart = moment(graphDataRanges[index].startDate);
        const rangeEnd = moment(graphDataRanges[index].endDate);

        // Find all points that fall within this date range
        const pointsInRange = dataset.chartDataPoints.filter((point) => {
          const pointDate = moment(point.X);
          return pointDate.isBetween(rangeStart, rangeEnd, 'day', '[]'); // Include start and end dates
        });

        // Calculate the sum of Y values for points in this range
        const sumY = pointsInRange.reduce((sum, point) => sum + point.Y, 0);

        // Store an object with id and value for each label
        baseData[dataset.label] = {
          id: dataset.id,
          value: pointsInRange.length ? sumY : 0,
        };
      });
    });

    return baseCombinedDataForGraph;
  }, [graphDataRanges, multiGraphData]);

  // Find min/max Y values across all processed datasets
  const { minY, maxY } = useMemo(() => {
    const allYValues = multiGraphData.flatMap((dataset) =>
      dataset.chartDataPoints.map((point) => point.Y),
    );
    return {
      minY: Math.min(...allYValues),
      maxY: Math.max(...allYValues),
    };
  }, [multiGraphData]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={combinedDataForGraph}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid vertical={false} stroke={theme.colors?.utility[300]} />
        <XAxis dataKey="date" />
        <YAxis
          domain={[minY, maxY]}
          tickFormatter={(value) => formatBigNumber(value)}
        />
        <Tooltip
          position={{ y: 50 }}
          allowEscapeViewBox={{ x: true, y: true }}
          coordinate={{ x: 0, y: 0 }}
          content={
            <CustomTooltip
              onMouseEnter={() => setIsTooltipHovered(true)}
              onMouseLeave={() => setIsTooltipHovered(false)}
              onClick={props.onClick}
            />
          }
          cursor={{ stroke: theme.colors?.utility[300], strokeWidth: 1 }}
          isAnimationActive
          wrapperStyle={{
            pointerEvents: isTooltipHovered ? 'auto' : 'none',
            zIndex: 100,
            visibility: isTooltipHovered ? 'visible' : undefined,
          }}
          contentStyle={{
            pointerEvents: 'auto',
          }}
        />
        {multiGraphData.map((dataset, index) => (
          <Line
            key={dataset.label}
            type="monotone"
            dataKey={`${dataset.label}.value`}
            stroke={getColorByIndex(index)}
            strokeWidth={2}
            dot={false}
            activeDot={{
              stroke: getColorByIndex(index, true),
              strokeWidth: 2,
              r: 6,
            }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
