import { Box, Button, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialMediaListeningZeroStateView = () => {
  return (
    <Box
      sx={{
        height: `calc(100vh - ${theme.spacing(16)})`,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 12,
      }}
    >
      <Typography variant="headline-xl" color="textPrimary">
        Interested in Social listening?
      </Typography>
      <Button
        variant="primary-alt"
        sx={{
          width: 'fit-content',
          borderRadius: 2,
          padding: theme.spacing(2, 4),
        }}
        onClick={() => {
          alert('Implement CTA');
        }}
      >
        Contact Sales
      </Button>
    </Box>
  );
};
