import { Slider, Typography } from '@mui/material';
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import AvatarEditor from 'react-avatar-editor';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineAddCircle } from 'components/icons/components/outline/IconOutlineAddCircle';
import { IconOutlineMinusCirlce } from 'components/icons/components/outline/IconOutlineMinusCirlce';
import { theme } from 'styles/theme/theme';
import {
  DeleteImageWrapper,
  EditorWrapper,
  ImageWrapper,
  SliderWrapper,
} from './styles';

const MIN_SCALE = 1;
const MAX_SCALE = 2;
const SCALE_STEP = 0.01;

interface Props {
  imageUrl?: string;
  /**
   * Same-domain image url or pre-processed image data url.
   * Note that it's most-likely pre-processed (e.g. downloaded to local & converted to data url)
   * because AvatarEditor can only work with un-tainted canvas.
   * Check line 91 -> 97.
   */
  editableImageUrl?: string;
  onRemove?: () => void;
  onUpdate: (imageFile: File, imageUrl: string) => void;
  type?: 'cover' | 'avatar';
}

export interface EditorRefProps {
  isEditing: boolean;
  onSubmit: () => Promise<void>;
}

export const ImageEditor = forwardRef(
  (props: Props, ref: ForwardedRef<EditorRefProps>) => {
    const {
      imageUrl,
      editableImageUrl,
      onRemove,
      onUpdate,
      type = 'cover',
    } = props;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const editorRef = useRef<AvatarEditor | null>();

    const [editorWidth, setEditorWidth] = useState(0);
    const [editorHeight, setEditorHeight] = useState(0);
    const [scale, setScale] = useState(MIN_SCALE);
    const [isEditing, setIsEditing] = useState(false);
    const [editedImage, setEditedImage] = useState('');

    React.useEffect(() => {
      if (editableImageUrl) {
        setIsEditing(true);
      }
    }, [editableImageUrl]);

    // Submit the edit. Basically get cropped image,
    // convert it into a File and output it out in the onUpdate method
    const onSubmit = useCallback(async () => {
      setIsEditing(false);

      const imageDataUrl = editorRef.current!.getImage().toDataURL();
      setEditedImage(imageDataUrl);
      const imageBlob = await fetch(imageDataUrl).then((res) => res.blob());
      const imageFile = new File([imageBlob], `image_${Date.now()}.png`, {
        type: 'image/png',
      });

      onUpdate(imageFile, imageDataUrl);
    }, [onUpdate]);

    // Expose submit method to parent because we'll need to trigger it from outside.
    // Check the design with the Save button outside the ImageEditor
    useImperativeHandle(ref, () => ({
      isEditing,
      onSubmit,
    }));

    useEffect(() => {
      // As the AvatarEditor needs a definite width number,
      // we need to get the container's width and set to the editor
      if (containerRef.current) {
        setEditorWidth(containerRef.current.clientWidth);
        setEditorHeight(
          type === 'cover' ? 140 : containerRef.current.clientWidth,
        );
      }
    }, [type]); // run once on mount

    const actionRender = useMemo(() => {
      if (!onRemove) return null;
      return (
        <DeleteImageWrapper component="button" onClick={onRemove}>
          <IconLinearTrash
            style={{
              height: 16,
              width: 16,
              color: theme.colors?.utility.red,
            }}
          />
        </DeleteImageWrapper>
      );
    }, [onRemove]);

    return (
      <EditorWrapper>
        <ImageWrapper ref={containerRef}>
          {isEditing ? (
            <AvatarEditor
              ref={(ref) => (editorRef.current = ref)}
              image={editableImageUrl || ''}
              width={editorWidth}
              height={editorHeight}
              scale={scale}
              border={0}
              rotate={0}
            />
          ) : (
            <img
              alt=""
              src={editedImage || imageUrl || editableImageUrl}
              style={{
                width: '100%',
                height: editorHeight,
                objectFit: 'cover',
              }}
            />
          )}
          {actionRender}
        </ImageWrapper>
        {isEditing && (
          <>
            <Typography variant="body-sm" color={theme.colors?.utility[800]}>
              Drag to Reposition
            </Typography>
            <SliderWrapper>
              <IconOutlineMinusCirlce
                onClick={() =>
                  setScale((o) => Math.max(MIN_SCALE, o - SCALE_STEP))
                }
                style={{
                  width: 20,
                  height: 20,
                  color: theme.colors?.utility[700],
                  cursor: 'pointer',
                }}
              />
              <Slider
                sx={{ flex: 1 }}
                min={MIN_SCALE}
                max={MAX_SCALE}
                step={SCALE_STEP}
                onChange={(_, value) => {
                  setScale(value as number);
                }}
                componentsProps={{
                  thumb: {
                    style: {
                      width: 20,
                      height: 20,
                      // border: `1px solid ${theme.colors?.utility['light-green']}`,
                      background: 'white',
                    },
                  },
                  track: {
                    style: {
                      background: theme.colors?.primary.black,
                      border: '1px solid transparent',
                    },
                  },
                  rail: {
                    style: {
                      background: theme.colors?.utility[400],
                    },
                  },
                }}
              />
              <IconOutlineAddCircle
                onClick={() =>
                  setScale((o) => Math.max(MIN_SCALE, o + SCALE_STEP))
                }
                style={{
                  color: theme.colors?.utility[700],
                  cursor: 'pointer',
                  width: 20,
                  height: 20,
                }}
              />
            </SliderWrapper>
          </>
        )}
      </EditorWrapper>
    );
  },
);
