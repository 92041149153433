import { useTaskPermissions } from 'features/task';
import { TaskPermission } from 'graphql/generated';

interface Props {
  children: React.ReactNode;
  permissions: TaskPermission[];
  taskId: string;
}

export const TaskPermissionGate = (props: Props) => {
  const { permissions, children, taskId } = props;

  const myPermissions = useTaskPermissions(taskId);

  if (permissions.every((p) => myPermissions.includes(p))) {
    // eslint-disable-next-line -- prevent TS error
    return <>{children}</>;
  }

  return null;
};
