import { Box, Grid as MuiGrid, SxProps } from '@mui/material';
import { PostPreview } from 'features/post';
import { CollectionFragmentCollectionMultiPostPreviewFragment } from 'graphql/generated';
import { PLACEHOLDER_BACKGROUND_COLORS } from '../constants';

export type GridProps = {
  collection: CollectionFragmentCollectionMultiPostPreviewFragment;
  sx?: SxProps;
};

export const Grid = (props: GridProps) => {
  const { collection, sx } = props;
  const { posts } = collection;

  const renderPost = (index: number) => {
    const post = posts[index];

    if (!post) {
      return (
        <Box
          sx={{ height: '100%', bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index] }}
        />
      );
    }

    return (
      <PostPreview
        key={post.id}
        post={post}
        sx={{
          height: '100%',
          bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index],
        }}
        variant="compact"
        componentsProps={{
          postNotePreviewCompact: {
            sx: {
              p: 6,
            },
            componentsProps: {
              icon: {
                size: 16,
              },
              title: {
                sx: {
                  fontSize: 14,
                  ' -webkit-line-clamp': 2,
                },
              },
            },
          },
        }}
      />
    );
  };

  return (
    <MuiGrid
      container
      spacing={0.25}
      borderRadius={2}
      overflow="hidden"
      sx={{
        overflow: 'hidden',
        borderRadius: 2,
        aspectRatio: '4 / 3',
        ...sx,
      }}
    >
      <MuiGrid item xs={6} height="100%">
        {renderPost(0)}
      </MuiGrid>
      <MuiGrid item xs={6} height="100%">
        <MuiGrid container spacing={0.25} height="100%">
          <MuiGrid item xs={12} height="50%" flexShrink={0}>
            {renderPost(1)}
          </MuiGrid>
          <MuiGrid item xs={12} height="50%" flexShrink={0}>
            {renderPost(2)}
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
