import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledEmptyStateWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(16),
  flexDirection: 'column',
  '.empty-state-title': {
    ...theme.typography['headline-xl'],
    fontWeight: 500,
    fontSize: 24,
    color: theme.colors?.utility[600],
  },
  '.empty-state-desc': {
    ...theme.typography['subhead-xl'],
    color: theme.colors?.utility[500],
  },
}));
