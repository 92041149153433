import { Sentiment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const SentimentLabels = {
  [Sentiment.Positive]: 'Positive',
  [Sentiment.Negative]: 'Negative',
  [Sentiment.Neutral]: 'Neutral',
  [Sentiment.Mixed]: 'Mixed',
  [Sentiment.None]: 'None',
};

export const SentimentColorsSx = {
  Default: {
    backgroundColor: theme.colors?.utility[400],
    color: theme.colors?.utility[800],
  },
  [Sentiment.Positive]: {
    backgroundColor: theme.colors?.utility['green-1'],
    color: theme.colors?.utility['green-3'],
  },
  [Sentiment.Negative]: {
    backgroundColor: theme.colors?.utility['pink-1'],
    color: theme.colors?.utility['pink-4'],
  },
};
