import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { DndDragItemTypes } from '../../constants';
import { DndCollectionDragPreview } from '../collectionDragPreview';
import { DndPostDragPreview } from '../postDragPreview';

export const CustomDragLayer = () => {
  const {
    item,
    itemType,
    isDragging,
    initialCursorOffset,
    initialFileOffset,
    currentFileOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialCursorOffset: monitor.getInitialClientOffset(),
    initialFileOffset: monitor.getInitialSourceClientOffset(),
    currentFileOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const previewRender = useMemo(() => {
    switch (itemType) {
      case DndDragItemTypes.Post: {
        if (item.selectedCollectionIds?.length === 0) {
          return <DndPostDragPreview postIds={item.selectedPostIds} />;
        }

        // TODO: Implement a preview for dragging both posts and collections
        return (
          <DndCollectionDragPreview
            collectionIds={item.selectedCollectionIds}
          />
        );
      }
      case DndDragItemTypes.Collection: {
        if (item.selectedPostIds?.length === 0) {
          return (
            <DndCollectionDragPreview
              collectionIds={item.selectedCollectionIds}
            />
          );
        }

        // TODO: Implement a preview for dragging both posts and collections
        return (
          <DndCollectionDragPreview
            collectionIds={item.selectedCollectionIds}
          />
        );
      }
      default: {
        return null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ itemType, item })]);

  if (!isDragging) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          ...getItemStyles(
            initialCursorOffset,
            initialFileOffset,
            currentFileOffset,
          ),
          pointerEvents: 'none',
        }}
      >
        {previewRender}
      </Box>
    </Box>
  );
};

function getItemStyles(
  initialCursorOffset: XYCoord | null,
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
) {
  if (!initialOffset || !currentOffset || !initialCursorOffset) {
    return {
      display: 'none',
    };
  }

  const x = initialCursorOffset.x + (currentOffset.x - initialOffset.x);
  const y = initialCursorOffset.y + (currentOffset.y - initialOffset.y);
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}
