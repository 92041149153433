import { theme } from 'styles/theme';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import { Parser } from 'utils/parser';
import { Image } from 'components/common/Image';
import { ResourceData } from 'hooks/useResourceUploadQueue';

type FilePreviewProps = {
  file: ResourceData;
  fileDeliveryLimitInMb?: number | null;
  onRemove: (file: ResourceData) => void;
  variant?: 'compact' | 'standalone';
  sx?: SxProps;
};

export const FilePreview = (props: FilePreviewProps) => {
  const {
    file,
    fileDeliveryLimitInMb,
    onRemove,
    variant = 'compact',
    sx,
  } = props;

  const isFileSizeGreaterThanLimit = useMemo(() => {
    return fileDeliveryLimitInMb
      ? Parser.toMb(file.size ?? 0) >= fileDeliveryLimitInMb
      : false;
  }, [file.size, fileDeliveryLimitInMb]);

  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!isFileSizeGreaterThanLimit && file.content instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(file.content);
    }
  }, [file, isFileSizeGreaterThanLimit]);

  const renderPreview = () => {
    const fileBlob = file.content as Blob;

    if (fileBlob.type.includes('image/')) {
      return (
        <Image
          src={preview}
          alt={file.name}
          sx={{
            width: '100%',
            objectFit: 'contain',
            borderRadius: theme.spacing(2),
          }}
        />
      );
    }

    if (fileBlob.type.includes('video/')) {
      return <video src={preview} width={138} />;
    }

    if (fileBlob.type.includes('audio/')) {
      return (
        <Box sx={{ width: '100%' }} component="audio" controls>
          <Box component="source" src={preview} />
        </Box>
      );
    }

    return <Typography>{file.name}</Typography>;
  };

  return (
    <Box
      sx={{
        borderRadius: theme.spacing(6),
        border: `${theme.spacing(3)} solid ${theme.colors?.primary.white}`,
        flex: 1,
        backgroundColor: theme.colors?.primary.white,
        aspectRatio: '3 / 4',
        position: 'relative',
        boxShadow: `0px 18px 88px -4px #18274B24`,
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        sx={{
          bgcolor: theme.colors?.primary.parchment,
          borderRadius: theme.spacing(2),
          display: 'flex',
          gap: theme.spacing(3),
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: variant === 'compact' ? theme.spacing(1) : theme.spacing(6),
          textAlign: 'center',
        }}
      >
        {isFileSizeGreaterThanLimit ? (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: theme.spacing(25),
                padding: theme.spacing(1, 3),
                bgcolor: theme.colors?.utility[500],
              }}
            >
              <Typography
                variant={variant === 'compact' ? 'headline-xxs' : 'headline-xs'}
                color={theme.colors?.primary.black}
              >
                Too large! 🙊
              </Typography>
            </Box>

            <Typography
              variant={variant === 'compact' ? 'subhead-md' : 'subhead-lg'}
              color={theme.colors?.utility[800]}
            >
              This file exceeds the
              <br />
              {fileDeliveryLimitInMb}MB limit
            </Typography>
          </>
        ) : (
          renderPreview()
        )}
      </Box>

      <IconButton
        sx={{ position: 'absolute', bottom: 0, right: 0 }}
        onClick={() => onRemove(file)}
      >
        <IconBoldTrash
          color={theme.colors?.utility[600]}
          size={variant === 'compact' ? 16 : 24}
        />
      </IconButton>
    </Box>
  );
};
