import { styled, Box } from '@mui/material';
import { theme } from 'styles/theme/theme';

const EditorWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const DeleteImageWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  marginTop: theme.spacing(3),
  marginRight: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  padding: 4,
  backgroundColor: theme.colors?.primary.white,
  zIndex: 10,
  cursor: 'pointer',
  borderRadius: '100%',
}));

const ImageWrapper = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  borderRadius: '6px',
  overflow: 'hidden',
  '& canvas': {
    display: 'block',
  },
  '& img': {
    display: 'block',
  },
}));

const SliderWrapper = styled(Box)(() => ({
  display: 'flex',
  gap: theme.spacing(4),
  width: '100%',
  alignItems: 'center',
}));

export { DeleteImageWrapper, ImageWrapper, EditorWrapper, SliderWrapper };
