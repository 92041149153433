/**
 * Generated by ChatGPT.
 */

// Function to capture a frame from a video and convert it to a base64 JPEG image
function captureFrame(videoUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    // Create a video element
    const video = document.createElement('video');

    // Set video source
    video.src = `${videoUrl}#t=0.001,1`;
    video.crossOrigin = 'anonymous';

    // Capture frame as soon as the video loads
    video.addEventListener('loadeddata', () => {
      try {
        // Create a canvas element
        // Calculate the scaling factor
        const maxDimension = 1024;
        const scale = Math.min(
          maxDimension / video.videoWidth,
          maxDimension / video.videoHeight,
        );
        const scaledWidth = video.videoWidth * scale;
        const scaledHeight = video.videoHeight * scale;

        const canvas = document.createElement('canvas');
        canvas.width = scaledWidth;
        canvas.height = scaledHeight;

        // Draw the video frame on the canvas
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert canvas to base64 JPEG image
        const imageData = canvas.toDataURL('image/jpeg');

        // Cleanup: remove video element
        video.remove();

        resolve(imageData);
      } catch (error) {
        reject(error);
      }
    });

    // Error handling
    video.addEventListener('error', (e) => {
      video.remove();

      reject(e);
    });
  });
}

export class VideoFrameCaptureQueue {
  private queue: Map<
    string,
    Array<{ resolve: (value: string) => void; reject: (reason?: any) => void }>
  > = new Map();
  private concurrentLimit: number;
  private activeCount: number = 0;

  constructor(concurrentLimit: number = 3) {
    this.concurrentLimit = concurrentLimit;
  }

  // Function to add a video URL to the queue
  enqueue(videoUrl: string): Promise<string> {
    if (!this.queue.has(videoUrl)) {
      this.queue.set(videoUrl, []);
    }

    return new Promise((resolve, reject) => {
      this.queue.get(videoUrl)?.push({ resolve, reject });
      if (this.activeCount < this.concurrentLimit) {
        this.startNext();
      }
    });
  }

  // Cancel all pending requests for a video URL
  cancel(videoUrl: string): void {
    this.queue.delete(videoUrl);
  }

  // Function to start processing the next video in the queue
  private startNext() {
    if (this.activeCount < this.concurrentLimit && this.queue.size > 0) {
      const [videoUrl, callbacks] = this.queue.entries().next().value;
      this.queue.delete(videoUrl);
      this.processVideo(videoUrl, callbacks);
    }
  }

  // Function to process a video URL
  private async processVideo(
    videoUrl: string,
    callbacks: Array<{
      resolve: (value: string) => void;
      reject: (reason?: any) => void;
    }>,
  ) {
    this.activeCount++;
    try {
      const imageData = await captureFrame(videoUrl);
      callbacks.forEach((callback) => callback.resolve(imageData));
    } catch (error) {
      callbacks.forEach((callback) => callback.reject(error));
    } finally {
      this.activeCount--;
      this.startNext();
    }
  }
}

export const videoFrameCaptureQueue = new VideoFrameCaptureQueue();
