import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, ClickAwayListener } from '@mui/material';
import { typography } from 'components/common/Typography/styles';

import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { CommentContentView } from '../content/CommentContentView';
import { CommentContainerProps } from './types';
import { CommentInput } from '../../components';
import { usePostCommentHandlers } from '../../hooks';
import { usePostAnnotationHandlers } from './usePostAnnotationHandlers';

export const CommentContainerView = forwardRef(
  (props: CommentContainerProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { comment, canComment = true, onReact, onReply } = props;
    const isMobileView = useMediaQueryMobile();

    const location = useLocation();
    const navigate = useNavigate();

    const { isOpen: showReplies, onToggle: toggleShowReplies } = useDisclosure({
      defaultIsOpen: true,
    });

    const {
      isOpen: isReplying,
      onToggle: onToggleReply,
      onClose: onCloseReply,
    } = useDisclosure();

    const richTextEditorRef = useRef<any>(null);
    const { onDeletePostAnnotation } = usePostAnnotationHandlers();
    const { onUpdatePostComment, onDeletePostComment } =
      usePostCommentHandlers();
    const [editCommentId, setEditCommentId] = useState('');

    // Upon clicking on the comment, we'll want to perform actions based on the postAnnotation
    // this comment is connected to. E.g. clicking on a comment contains a postAnnotation with video timestamp,
    // we'll want to seek to that timestamp.
    // The actual logic to handle the actions will be handled by the components that are responsible for rendering
    // the posts (image, video, etc.). This logic below will simply mark which postAnnotation to focus on.
    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.defaultPrevented || !comment.postAnnotation) {
        return;
      }

      navigate(location, {
        state: {
          ...((location.state as any) || {}),
          postAnnotationToPop: comment.postAnnotation,
        },
        replace: true,
      });
    };

    // Set annotation meta to url to location state so we can use it to highlight the annotations
    // Right now only Note listens to this data
    const onMouseEnter = () => {
      if (!comment.postAnnotation) {
        return;
      }

      navigate(location, {
        state: {
          ...((location.state as any) || {}),
          postAnnotationToHighlight: comment.postAnnotation,
        },
        replace: true,
      });
    };

    const onMouseLeave = () => {
      if (!comment.postAnnotation) {
        return;
      }

      navigate(location, {
        state: {
          ...((location.state as any) || {}),
          postAnnotationToHighlight: undefined,
        },
        replace: true,
      });
    };

    const [shouldResetHighlight, setShouldResetHighlight] = useState(false);

    const commentRef = useRef<HTMLDivElement>(null);
    const commentChildRefs = useRef(
      comment.childComments.map(() => React.createRef<HTMLDivElement>()),
    );

    const shouldHighlightComment = useCallback(
      (commentId: string, ref: React.RefObject<HTMLDivElement>) => {
        const state = (location.state as any) || {};

        if (state.commentToHighlightId && !shouldResetHighlight) {
          const shouldHighlight = state.commentToHighlightId === commentId;

          if (shouldHighlight && ref.current) {
            ref.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }

          return shouldHighlight;
        }
      },
      [shouldResetHighlight], // eslint-disable-line
    );

    // make sure to reset highlight when there is different location.state
    // this is when you want to highlight different comments in one post
    useEffect(() => {
      setShouldResetHighlight(false);
    }, [(location.state as any)?.commentToHighlightId]); // eslint-disable-line

    // reset highlight after 1.5s
    useEffect(() => {
      if (!shouldResetHighlight) {
        setTimeout(() => setShouldResetHighlight(true), 1500);
      }
    }, [shouldResetHighlight]);

    return (
      <ClickAwayListener onClickAway={onCloseReply}>
        <Box
          sx={{
            backgroundColor:
              editCommentId === comment.id ? '#F8E8CA' : 'transparent',
            borderRadius: 3,
            ':hover': {
              backgroundColor: '#F8E8CA',
            },
            padding: 4,
            position: 'relative',
            overflow: 'hidden',
            flexShrink: 0,
          }}
          ref={ref}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Box
            sx={{
              '& .comment-renderer': {
                gap: 3,
              },
              '& .comment-user': {
                mb: 2,
              },
            }}
          >
            <Box
              borderRadius={3}
              padding={theme.spacing(2)}
              ref={commentRef}
              bgcolor={
                // separate highlight from hover so we can control the transition
                shouldHighlightComment(comment.id, commentRef)
                  ? theme.colors?.utility['yellow-1']
                  : 'transparent'
              }
              sx={{
                transition: 'background-color 1s',
                width: '100%',
              }}
            >
              {!comment.isRead && (
                <Box position="absolute" left={4} top={25}>
                  <Box
                    sx={{
                      width: 6,
                      height: 6,
                      backgroundColor: theme.colors?.primary.maroon,
                      borderRadius: '100%',
                    }}
                  />
                </Box>
              )}
              <CommentContentView
                comment={comment}
                canEdit={canComment}
                setEditCommentId={setEditCommentId}
                inputStyle={{
                  background: theme.colors?.primary.white,
                  marginLeft: '4px',
                  borderRadius: 20,
                  padding: theme.spacing(1.5, 3),
                }}
                onReactToComment={(emoji) => {
                  onReact(comment.id, emoji);
                }}
                onUpdateComment={(data) => {
                  onUpdatePostComment({
                    commentId: comment.id,
                    data,
                  });
                }}
                onDeleteComment={() => {
                  onDeletePostComment(comment);
                  if (comment.postAnnotation?.id) {
                    onDeletePostAnnotation(comment.postAnnotation?.id);
                  }
                }}
              />
            </Box>
          </Box>
          {showReplies &&
            comment.childComments?.map((childComment, childCommentIndex) => {
              const isEditing = editCommentId === childComment.id;
              return (
                <Box
                  key={childComment.id}
                  sx={{
                    display: 'flex',
                    flexDirection: isEditing ? 'column' : 'row',
                    gap: 3,
                    ml: 6,
                    p: isMobileView ? 2 : theme.spacing(4, 0, 0, 3),
                    mb: 1,
                  }}
                >
                  <Box
                    borderRadius={3}
                    ref={commentChildRefs.current[childCommentIndex]}
                    bgcolor={
                      // separate highlight from hover so we can control the transition
                      shouldHighlightComment(
                        childComment.id,
                        commentChildRefs.current[childCommentIndex],
                      )
                        ? theme.colors?.utility['yellow-1']
                        : 'transparent'
                    }
                    padding={theme.spacing(0.5)}
                    sx={{
                      transition: 'background-color 1s',
                      width: '100%',
                    }}
                  >
                    <CommentContentView
                      comment={childComment}
                      setEditCommentId={setEditCommentId}
                      inputStyle={{
                        background: theme.colors?.primary.white,
                        marginLeft: '4px',
                        borderRadius: 20,
                        padding: theme.spacing(1.5, 3),
                      }}
                      onReactToComment={(emoji) => {
                        onReact(childComment.id, emoji);
                      }}
                      onUpdateComment={(data) => {
                        onUpdatePostComment({
                          commentId: childComment.id,
                          data,
                        });
                      }}
                      onDeleteComment={() => {
                        onDeletePostComment(childComment);
                      }}
                    />
                  </Box>
                </Box>
              );
            })}
          {!editCommentId && (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                ml: 6,
                pl: 5,
                mt: 2,
              }}
            >
              {!comment.parentThreadId && comment.childComments?.length > 0 && (
                <>
                  <Box
                    component="button"
                    sx={{
                      ...typography['body-md'],
                      fontWeight: 600,
                      color: theme.colors?.utility[800],
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleShowReplies();
                    }}
                  >
                    {showReplies ? 'Hide' : 'Show'}{' '}
                    {comment.childComments?.length}{' '}
                    {comment.childComments?.length > 1 ? 'replies' : 'reply'}
                  </Box>
                  {canComment && (
                    <Box
                      sx={{
                        ...typography['body-md'],
                        fontWeight: 600,
                        color: theme.colors?.utility[800],
                      }}
                    >
                      •
                    </Box>
                  )}
                </>
              )}

              {canComment && (
                <Button
                  size="small"
                  sx={{
                    padding: 0,
                    minWidth: 'fit-content',
                    color: theme.colors?.utility[800],
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onToggleReply();
                  }}
                >
                  Reply
                </Button>
              )}
            </Box>
          )}
          {canComment && isReplying && (
            <Box
              padding={theme.spacing(2)}
              sx={{
                '& .comment-user': {
                  mb: 2,
                },
              }}
            >
              <CommentInput
                commentInputRef={richTextEditorRef}
                autoFocus
                inputStyle={{
                  background: theme.colors?.primary.white,
                  borderRadius: 20,
                  boxShadow: `${theme.colors?.utility[275]} 2px 2px 5px`,
                  padding: theme.spacing(1, 3),
                }}
                onCreateComment={(comment) => {
                  onReply(comment);
                  onCloseReply();
                  richTextEditorRef?.current?.clear();
                }}
              />
            </Box>
          )}
        </Box>
      </ClickAwayListener>
    );
  },
);
