import { gql } from '@apollo/client';
import {
  CommentFilter,
  CommentFragmentCollectionCommentListViewFragmentDoc,
  CreateCommentInput,
  GetCollectionForCollectionPageDocument,
  GetCollectionForCollectionPageQuery,
  GetCommentsForCollectionCommentListViewDocument,
  GetCommentsForCollectionCommentListViewQuery,
  GetPostsSmartSearchForCollectionPageNormalCollectionDocument,
  GetPostsSmartSearchForCollectionPageNormalCollectionQuery,
  PostFilterType,
  useCreateCommentForCollectionMutation,
  useReadAllCommentsForCollectionMutation,
} from 'graphql/generated';
import { useCallback } from 'react';

// eslint-disable-next-line
gql`
  mutation CreateCommentForCollection($data: CreateCommentInput!) {
    createComment(data: $data) {
      id
      ...CommentFragmentCollectionCommentListView
    }
  }
  ${CommentFragmentCollectionCommentListViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation ReadAllCommentsForCollection($data: ReadAllCommentsInput!) {
    readAllComments(data: $data) {
      message
      success
    }
  }
`;

export const useCollectionCommentHandlers = () => {
  const [createCollectionComment] = useCreateCommentForCollectionMutation();
  const [readAllCollectionComments] = useReadAllCommentsForCollectionMutation();

  const onCreateCollectionComment = useCallback(
    (data: CreateCommentInput, filters: CommentFilter) => {
      createCollectionComment({
        variables: {
          data,
        },

        update: (cache, res) => {
          const createdCollectionComment = res.data?.createComment;

          if (createdCollectionComment) {
            const newCollectionCommentRef = cache.writeFragment({
              data: createdCollectionComment,
              fragment: CommentFragmentCollectionCommentListViewFragmentDoc,
              fragmentName: 'CommentFragmentCollectionCommentListView',
            });

            if (createdCollectionComment.parentThreadId) {
              const parentCommentReference = cache.identify({
                __typename: 'CommentModel',
                id: createdCollectionComment.parentThreadId,
              });

              // If it's a child comment, update the parent comment's childComments.
              cache.modify({
                id: parentCommentReference,
                fields: {
                  childComments: (cachedChildComments) => {
                    return [...cachedChildComments, newCollectionCommentRef];
                  },
                },
              });
            } else {
              cache.updateQuery(
                {
                  query: GetCommentsForCollectionCommentListViewDocument,
                  variables: {
                    filters,
                  },
                },
                (data: GetCommentsForCollectionCommentListViewQuery | null) => {
                  if (!data) return null;

                  return {
                    ...data,
                    comments: [...data.comments, createdCollectionComment],
                  };
                },
              );
            }
          }
        },
      });
    },
    [createCollectionComment],
  );

  const onReadAllCollectionComments = useCallback(
    (collectionId: string) => {
      readAllCollectionComments({
        variables: {
          data: {
            collectionId,
          },
        },
        update: (cache) => {
          cache.updateQuery(
            {
              query: GetCollectionForCollectionPageDocument,
              variables: {
                collectionId,
              },
            },
            (data: GetCollectionForCollectionPageQuery | null) => {
              if (!data) return null;

              const updatedCollection = {
                ...data,
                collection: {
                  ...data.collection,
                  unreadCommentCount: 0,
                  childCollections: data.collection.childCollections.map(
                    (childCollection) => ({
                      ...childCollection,
                      unreadCommentCount: 0,
                    }),
                  ),
                },
              };

              return updatedCollection;
            },
          );

          cache.updateQuery(
            {
              query:
                GetPostsSmartSearchForCollectionPageNormalCollectionDocument,
              variables: {
                filters: {
                  collectionId,
                  filterType: PostFilterType.OrganizationPosts,
                },
              },
            },
            (
              data: GetPostsSmartSearchForCollectionPageNormalCollectionQuery | null,
            ) => {
              if (!data) return null;

              const updatedPosts = {
                ...data,
                posts: {
                  ...data.postsSmartSearch,
                  data: data.postsSmartSearch.data.map((post) => ({
                    ...post,
                    item: {
                      ...post.item,
                      unreadCommentCount: 0,
                    },
                  })),
                },
              };

              return updatedPosts;
            },
          );
        },
      });
    },
    [readAllCollectionComments],
  );

  return {
    onCreateCollectionComment,
    onReadAllCollectionComments,
  };
};
