import { useDisclosure } from '@dwarvesf/react-hooks';
import { useRef, useState } from 'react';
import { SxProps } from '@mui/material';
import { ConfirmationDialog } from './components/ConfirmationDialog';

interface OnOpenProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

type Props = {
  sx?: SxProps;
};

export const useConfirmationDialog = (props?: Props) => {
  const { sx } = props || {};
  const { isOpen, onOpen: _onOpen, onClose } = useDisclosure();

  const confirmCallbackRef = useRef<() => void>();
  const cancelCallbackRef = useRef<() => void>();
  const [title, setTitle] = useState<React.ReactNode>();
  const [subtitle, setSubtitle] = useState<React.ReactNode>();
  const [confirmText, setConfirmText] = useState<string | undefined>();
  const [cancelText, setCancelText] = useState<string | undefined>();

  const onOpen = ({
    onConfirm,
    onCancel,
    title,
    subtitle,
    confirmText,
    cancelText,
  }: OnOpenProps) => {
    confirmCallbackRef.current = onConfirm;
    cancelCallbackRef.current = onCancel;
    setTitle(title);
    setSubtitle(subtitle);
    setConfirmText(confirmText);
    setCancelText(cancelText);

    _onOpen();
  };

  return {
    onOpen,
    onClose,
    dialog: (
      <ConfirmationDialog
        sx={sx}
        title={title}
        subtitle={subtitle}
        confirmText={confirmText}
        cancelText={cancelText}
        open={isOpen}
        onConfirm={() => {
          confirmCallbackRef.current?.();
          onClose();
        }}
        onCancel={() => {
          cancelCallbackRef.current?.();
          onClose();
        }}
      />
    ),
  };
};
