import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  ContentIdeaDueDate,
  ContentIdeaIcebox,
  ContentIdeaIceboxProps,
  ContentIdeaName,
  ContentIdeaOwners,
  ContentIdeaPillars,
  ContentIdeaPlatforms,
  ContentIdeaPreview,
  ContentIdeaStatus,
} from 'features/contentIdea/components';
import {
  ContentIdeaSnoozeDate,
  ContentIdeaSnoozeDateProps,
} from 'features/contentIdea/components/snoozeDate';
import {
  ContentIdeaFragmentContentIdeaDueDateFragmentDoc,
  ContentIdeaFragmentContentIdeaIceboxFragmentDoc,
  ContentIdeaFragmentContentIdeaListItemViewFragment,
  ContentIdeaFragmentContentIdeaNameFragmentDoc,
  ContentIdeaFragmentContentIdeaOwnersFragmentDoc,
  ContentIdeaFragmentContentIdeaPillarsFragmentDoc,
  ContentIdeaFragmentContentIdeaPlatformsFragmentDoc,
  ContentIdeaFragmentContentIdeaPreviewFragmentDoc,
  ContentIdeaFragmentContentIdeaSnoozeDateFragmentDoc,
  ContentIdeaFragmentContentIdeaStatusFragmentDoc,
  ContentIdeaPermission,
  Stage,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_LIST_ITEM_VIEW = gql`
  fragment ContentIdeaFragmentContentIdeaListItemView on ContentIdeaModel {
    id
    myPermissions
    ...ContentIdeaFragmentContentIdeaPreview
    ...ContentIdeaFragmentContentIdeaName
    ...ContentIdeaFragmentContentIdeaSnoozeDate
    ...ContentIdeaFragmentContentIdeaStatus
    ...ContentIdeaFragmentContentIdeaDueDate
    ...ContentIdeaFragmentContentIdeaOwners
    ...ContentIdeaFragmentContentIdeaPlatforms
    ...ContentIdeaFragmentContentIdeaPillars
    ...ContentIdeaFragmentContentIdeaIcebox
  }
  ${ContentIdeaFragmentContentIdeaPreviewFragmentDoc}
  ${ContentIdeaFragmentContentIdeaNameFragmentDoc}
  ${ContentIdeaFragmentContentIdeaSnoozeDateFragmentDoc}
  ${ContentIdeaFragmentContentIdeaStatusFragmentDoc}
  ${ContentIdeaFragmentContentIdeaDueDateFragmentDoc}
  ${ContentIdeaFragmentContentIdeaOwnersFragmentDoc}
  ${ContentIdeaFragmentContentIdeaPlatformsFragmentDoc}
  ${ContentIdeaFragmentContentIdeaPillarsFragmentDoc}
  ${ContentIdeaFragmentContentIdeaIceboxFragmentDoc}
`;

export type ContentIdeaListItemViewProps = {
  contentIdea: ContentIdeaFragmentContentIdeaListItemViewFragment;
  autoFocus?: boolean;
  isMoved?: boolean;
  componentProps?: {
    snooze?: Pick<ContentIdeaSnoozeDateProps, 'onAfterSnooze'>;
    icebox?: Pick<ContentIdeaIceboxProps, 'onAfterMove'>;
  };
};

export const ContentIdeaListItemView = (
  props: ContentIdeaListItemViewProps,
) => {
  const { contentIdea, autoFocus = false, isMoved, componentProps } = props;

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: theme.colors?.utility[250],
        borderRadius: 2,
        display: 'flex',
        gap: 3,
        opacity: isMoved ? 0.4 : 1,
      }}
    >
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ContentIdeaPreview
          contentIdea={contentIdea}
          readOnly={!canEdit}
          sx={{
            width: 75,
            height: 90,
            borderRadius: 1,
            overflow: 'hidden',
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        <ContentIdeaName contentIdea={contentIdea} autoFocus={autoFocus} />
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ContentIdeaStatus contentIdea={contentIdea} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mt: 'auto',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflow: 'hidden',
              height: 20,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              '& > button': {
                display: 'inline-flex',
                width: 'auto',
                flexShrink: 0,
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ContentIdeaDueDate contentIdea={contentIdea} />
            <ContentIdeaOwners contentIdea={contentIdea} />
            <ContentIdeaPlatforms contentIdea={contentIdea} />
            <ContentIdeaPillars contentIdea={contentIdea} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              '& > button': {
                width: 'auto',
                flexShrink: 0,
                display: 'inline-flex',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ContentIdeaSnoozeDate
              contentIdea={contentIdea}
              {...componentProps?.snooze}
            />
            {contentIdea.stage !== Stage.IceBox && (
              <ContentIdeaIcebox
                contentIdea={contentIdea}
                {...componentProps?.icebox}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
