import { Box } from '@mui/material';
import { NodeViewProps } from '@tiptap/react';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconCustomChangeToText } from 'components/icons/components/custom/IconCustomChangeToText';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearMessage } from 'components/icons/components/linear/IconLinearMessage';
import { UPLOAD_CARE_CDN_URL } from 'constants/uploadCare';
import { GenerateId } from 'utils/generateId';
import { theme } from 'styles/theme/theme';
import { ATTR_NOTE_COMMENT_ANCHOR_ID, ATTR_POST_ID } from '../../constants';

export type HoverMenuProps = {
  isUploading: boolean;
} & NodeViewProps;

export const HoverMenu = (props: HoverMenuProps) => {
  const { node, editor, extension, isUploading, getPos } = props;

  // We don't allow converting uploadcare assets to text,
  // or if the file is being uploaded
  const canConvertBackToText =
    !node.attrs.src?.startsWith(UPLOAD_CARE_CDN_URL) && !isUploading;

  const onAddComment = () => {
    const canInitANewCommentThread = !node.attrs[ATTR_NOTE_COMMENT_ANCHOR_ID];
    const noteCommentAnchorId =
      node.attrs[ATTR_NOTE_COMMENT_ANCHOR_ID] || GenerateId.create();

    if (canInitANewCommentThread) {
      const tr = editor.view.state.tr;
      tr.setNodeMarkup(
        getPos(),
        undefined,
        {
          ...node.attrs,
          [ATTR_NOTE_COMMENT_ANCHOR_ID]: noteCommentAnchorId,
        },
        node.marks,
      );

      editor.view.dispatch(tr);

      extension.options.onTriggerAddComment?.({
        noteCommentAnchorId,
        onCancelAddComment: () => {
          const tr = editor.view.state.tr;
          tr.setNodeMarkup(
            getPos(),
            undefined,
            {
              ...node.attrs,
              [ATTR_NOTE_COMMENT_ANCHOR_ID]: null,
            },
            node.marks,
          );

          editor.view.dispatch(tr);
        },
      });
    } else {
      extension.options.onTriggerAddComment?.({
        noteCommentAnchorId,
      });
    }
  };

  const onChangeBackToText = () => {
    // Change the node to a text node
    const tr = editor.view.state.tr;
    const pos = getPos();
    tr.replaceWith(
      pos,
      pos + node.nodeSize,
      editor.schema.text(node.attrs.src || '', [
        editor.schema.marks.link.create({
          href: node.attrs.src,
        }),
      ]),
    );
    editor.view.dispatch(tr);
  };

  const onOpenLink = () => {
    if (node.attrs[ATTR_POST_ID]) {
      window.open(`/posts/${node.attrs[ATTR_POST_ID]}`, '_blank');
    } else {
      window.open(node.attrs.src, '_blank');
    }
  };

  return (
    <Box
      className="menu-container"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: 3,
        display: 'flex',
        gap: 1,
        p: 1,
        backgroundColor: 'rgba(250, 243, 236, 0.40)',
        backdropFilter: 'blur(25px)',
        border: '1.5px solid rgba(15, 15, 15, 0.10)',
        m: 3,
        svg: {
          color: theme.colors?.primary.black,
        },
      }}
    >
      {extension.options.commentable && (
        <IconButtonWithTooltip
          size="small"
          tooltip="Comment"
          onClick={onAddComment}
        >
          <IconLinearMessage size={24} />
        </IconButtonWithTooltip>
      )}
      {extension.options.editable && canConvertBackToText && (
        <IconButtonWithTooltip
          size="small"
          tooltip="Change back to text"
          onClick={onChangeBackToText}
        >
          <IconCustomChangeToText size={24} />
        </IconButtonWithTooltip>
      )}
      {node.attrs.src && !isUploading && (
        <IconButtonWithTooltip
          size="small"
          tooltip="Open link"
          onClick={onOpenLink}
        >
          <IconLinearExport2 size={24} />
        </IconButtonWithTooltip>
      )}
    </Box>
  );
};
