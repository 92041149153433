import { Box, Button, SxProps, Typography } from '@mui/material';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import { theme } from 'styles/theme';

interface Props {
  title: string;
  onShowMore?: VoidFunction;
  componentsProps?: {
    button?: SxProps;
    icon?: {
      size?: number;
      color?: string;
    };
  };
}

export const JuiceboxSearchResultHeader = ({
  title,
  onShowMore,
  componentsProps = {},
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: theme.spacing(3),
      }}
    >
      <Typography
        variant="subhead-xs"
        fontWeight={600}
        color={theme.colors?.utility[700]}
      >
        {title}
      </Typography>
      {onShowMore && (
        <Button
          size="small"
          sx={{
            padding: 0,
            color: theme.colors?.primary.maroon,
            '& .MuiButton-endIcon ': {
              marginLeft: 0.5,
              marginTop: 0.5,
            },
            ...componentsProps.button,
          }}
          endIcon={
            <IconLinearArrowRight1
              size={12 || componentsProps.icon?.size}
              color={
                theme.colors?.primary.maroon || componentsProps.icon?.color
              }
            />
          }
          onClick={onShowMore}
        >
          Show more
        </Button>
      )}
    </Box>
  );
};
