import { Box } from '@mui/material';
import { SuggestionProps } from '@tiptap/suggestion';
import {
  SearchableEntityType,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import React from 'react';
import { theme } from 'styles/theme/theme';
import { getFullName } from 'utils/users';
import { UserSearchList } from 'features/search/views/modal/components/UserSearchList';
import { EmptySearchResult } from 'features/search/views/modal/components';

export type MentionListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

type MentionListProps = Pick<SuggestionProps, 'command'> & {
  items: UserFragmentAvatarGroupFragment[];
  loading: boolean;
  onMention?: (user: UserFragmentAvatarGroupFragment) => void;
  disableSubmit?: boolean;
};

export const MentionList = React.forwardRef<MentionListRef, MentionListProps>(
  (props, ref) => {
    const { items, loading, onMention, disableSubmit, command } = props;

    const focusRef = React.useRef<HTMLDivElement>(null);

    const handleOnSelectItem = (user: UserFragmentAvatarGroupFragment) => {
      if (!disableSubmit) {
        command({
          id: user.id,
          name: getFullName(user),
          type: SearchableEntityType.Users,
        });
      }
    };

    React.useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        return (
          event.key === 'ArrowUp' ||
          event.key === 'ArrowDown' ||
          event.key === 'Enter' ||
          event.key === 'Tab'
        );
      },
    }));

    React.useEffect(() => {
      if (focusRef) {
        focusRef.current?.focus();
      }
    }, []);

    return (
      <Box
        ref={focusRef}
        display="flex"
        flexDirection="column"
        bgcolor="white"
        borderRadius="20px"
        boxShadow="0px 3px 12px -3px rgba(0, 0, 0, 0.16)"
        overflow="hidden"
        maxHeight={theme.spacing(110)}
        flex={1}
        minWidth={330}
        height={308}
      >
        <Box
          sx={{
            padding: 2,
            '& .mention-user-container': {
              padding: theme.spacing(0, 2),
              borderRadius: 1,
            },
            '& .mention-user-cell': {
              borderBottom: 'none',
              padding: theme.spacing(3, 0),
            },
          }}
        >
          <UserSearchList
            data={items}
            onSelect={(user: UserFragmentAvatarGroupFragment) => {
              if (user) {
                handleOnSelectItem(user);
                onMention?.(user);
              }
            }}
            renderEmpty={() => <EmptySearchResult mt={3} />}
            loading={loading}
          />
        </Box>
      </Box>
    );
  },
);
