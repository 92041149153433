import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'components/common/Toast';
import { useUserContext } from 'contexts/users/User.context';
import { useCreatePermissionRequestForSocialListeningPermissionRequestMutation } from 'graphql/generated';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { SL_PERMISSION_REQUEST_KEY } from './consts';

type Props = {};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreatePermissionRequestForSocialListeningPermissionRequest(
    $data: CreatePermissionRequestInput!
  ) {
    createPermissionRequest(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line no-empty-pattern
export const SocialListeningPermissionRequestView = ({}: Props) => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { isOpen: open, onClose, onOpen } = useDisclosure();
  const { value, setValue } = useSessionStorage<{
    brandId: string;
    brandName: string;
  } | null>(SL_PERMISSION_REQUEST_KEY, null);

  const [brandName] = useState(value?.brandName);
  const [brandId] = useState(value?.brandId);

  useEffect(() => {
    if (
      value &&
      user &&
      !user.socialListeningBrands.find((x) => x.id === brandId)
    ) {
      onOpen();
      setValue(null);
    } else if (
      user &&
      brandId &&
      user.socialListeningBrands.find((x) => x.id === brandId)
    ) {
      navigate(PlotRoutes.socialListeningForBrand(brandId));
      setValue(null);
    }
  }, [user, brandId, value]); //eslint-disable-line

  const [note, setNote] = useState('');
  const [createPermissionRequest] =
    useCreatePermissionRequestForSocialListeningPermissionRequestMutation();

  const onRequestAccess = () => {
    createPermissionRequest({
      variables: { data: { input: { brandId, note } } },
    });
    toast({
      message: 'Request sent',
      type: 'success',
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          width: '450px',
          backgroundColor: theme.colors?.primary.parchment,
          p: 6,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        },
      }}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(12)}
        fontWeight={400}
      >
        Hmm looks like you need access
      </Typography>

      <Typography variant="headline-md" fontWeight={500}>
        Ask the owner to get access to {brandName ? `${brandName}'s` : ''}{' '}
        Social Listening
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        variant="outlined"
        placeholder="Write a comment..."
        sx={{
          mt: 2,
          borderRadius: 2,
          backgroundColor: '#2306031A',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />

      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={onRequestAccess}
          sx={{
            backgroundColor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
            border: 'none',
            '&:hover': {
              backgroundColor: theme.colors?.primary.black,
            },
          }}
        >
          Request access
        </Button>
      </DialogActions>
    </Dialog>
  );
};
