import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { AddUsersToOrgView } from 'features/organizationMembers/views/AddUsersToOrgView';
import { EmailValidationErrorForAddUser } from 'features/organizationMembers/views/AddUsersToOrgView/types';
import {
  InternalOrganizationRole,
  PermissionLevel,
  useInviteUsersToCurrentOrgByRolesForUseSocialListeningPermissionsManageMembersMutation,
  useUpdateBrandPermissionsForUseSocialListeningPermissionsManageMembersMutation,
} from 'graphql/generated';
import React, { useMemo } from 'react';
import { theme } from 'styles/theme';
import { emailValidation } from 'utils/validations';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateBrandPermissionsForUseSocialListeningPermissionsManageMembers(
    $data: UpdateBrandPermissionsInput!
  ) {
    updateBrandPermissions(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation InviteUsersToCurrentOrgByRolesForUseSocialListeningPermissionsManageMembers(
    $data: InviteUsersToCurrentOrgByRoleInput!
  ) {
    inviteUsersToCurrentOrgByRoles(data: $data) {
      id
    }
  }
`;

type Props = {
  brandId: string;
  renderButton?: () => React.ReactNode;
};

export const useSocialListeningPermissionsManageMembers = ({
  brandId,
  renderButton,
}: Props) => {
  const { user, refetchUserData } = useUserContext();
  const currentBrand = user?.socialListeningBrands.find(
    (b) => b.id === brandId,
  );

  const [updateBrandPermissions] =
    useUpdateBrandPermissionsForUseSocialListeningPermissionsManageMembersMutation();
  const [inviteMembers] =
    useInviteUsersToCurrentOrgByRolesForUseSocialListeningPermissionsManageMembersMutation();

  // replace these users with brand specific users for SL
  const internalUsers = useMemo(
    () =>
      user?.organization.users.filter((u) => u.hasBeenApprovedByAdmin) || [],
    [user],
  );
  const guestUsers = useMemo(
    () => user?.organization.externalUsers.filter((u) => !u.isDisabled) || [],
    [user],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const validateEmail = (
    email: string,
  ): { type: EmailValidationErrorForAddUser } => {
    if (email && email.trim()) {
      const isEmailValid = emailValidation.isValidSync(email);
      if (!isEmailValid) {
        return { type: 'invalidEmail' };
      }

      if (isEmailValid) {
        const existingUser = [...internalUsers, ...guestUsers]
          .filter((u) => !u.isDisabled)
          .find((x) => x.email === email);

        if (existingUser) {
          return { type: 'existsAlready' };
        }
        return { type: null };
      }
    }
    return { type: 'invalidEmail' };
  };

  const renderShareSocialListeningButton = renderButton
    ? () => (
        <Box
          sx={{
            display: 'flex',
            cursor: 'pointer',
          }}
          onClick={onOpen}
        >
          {renderButton()}
        </Box>
      )
    : () => (
        <Button
          variant="primary-alt"
          onClick={onOpen}
          sx={{
            backgroundColor: theme.colors?.primary.black,
            borderRadius: theme.spacing(8),
            '&:hover': { backgroundColor: theme.colors?.primary.black },
          }}
        >
          + Add members
        </Button>
      );

  const renderShareSocialListeningModal = () => {
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          sx: {
            p: 0,
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderRadius: theme.spacing(5),
          },
        }}
      >
        <AddUsersToOrgView
          existingInternalUsers={internalUsers.filter((u) => !u.isDisabled)}
          existingExternalUsers={guestUsers.filter((u) => !u.isDisabled)}
          hideShareModal={onClose}
          hideRoleSelection
          onInviteMembers={async (data) => {
            const internalRoles = data.filter((d) =>
              [
                InternalOrganizationRole.User,
                InternalOrganizationRole.Admin,
                InternalOrganizationRole.SocialListeningUser,
              ].includes(d.role as InternalOrganizationRole),
            );

            if (internalRoles.length > 0) {
              await inviteMembers({
                variables: {
                  data: {
                    data: internalRoles.map((d) => ({
                      email: d.email,
                      role: d.role as InternalOrganizationRole,
                    })),
                  },
                },
              });
            }

            const existingBrandMembers = currentBrand?.inviteMembers || [];
            const newBrandMembers = [
              ...existingBrandMembers
                .map((bim) => ({
                  email: bim.user.email,
                  permission: bim.permissionLevel,
                }))
                .filter((bim) => !data.some((d) => d.email === bim.email)),
              ...data.map((d) => ({
                email: d.email,
                permission: PermissionLevel.Full,
              })),
            ];

            await updateBrandPermissions({
              variables: {
                data: {
                  brandId,
                  members: newBrandMembers,
                },
              },
            });

            refetchUserData();
          }}
          validateEmail={validateEmail}
          guestOptions={{
            allowGuests: true,
            defaultSelectedRoleInUI:
              InternalOrganizationRole.SocialListeningUser,
          }}
        />
      </Dialog>
    );
  };

  return {
    renderShareSocialListeningButton,
    renderShareSocialListeningModal,
  };
};
