import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Divider, Typography } from '@mui/material';
import { BillingHourUsageProgress } from 'features/billing/components/hourUsage';
import {
  BillingPlanName,
  BillingSubscriptionStatus,
  OrganizationBillingFragmentCollectionPaywallFragmentDoc,
  OrganizationBillingFragmentFileStorageLimitProgressFragmentDoc,
  OrganizationBillingFragmentGuestPaywallFragmentDoc,
  OrganizationBillingFragmentLimitProgressFragment,
  OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { DateUtils } from 'utils/date';
import {
  BillingCollectionLimitProgress,
  BillingFileStorageLimitProgress,
  BillingGuestLimitProgress,
  BillingOrganizationMemberLimitProgress,
  BillingUpgradeModal,
} from '../../components';

// eslint-disable-next-line
gql`
  fragment OrganizationBillingFragmentLimitProgress on OrganizationBillingModel {
    id
    plan
    hasUsedFreeTrial
    subscription {
      status
      trialEnd
      currentPeriodEnd
    }
    ...OrganizationBillingFragmentFileStorageLimitProgress
    ...OrganizationBillingFragmentCollectionPaywall
    ...OrganizationBillingFragmentOrganizationMemberPaywall
    ...OrganizationBillingFragmentGuestPaywall
  }
  ${OrganizationBillingFragmentFileStorageLimitProgressFragmentDoc}
  ${OrganizationBillingFragmentCollectionPaywallFragmentDoc}
  ${OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc}
  ${OrganizationBillingFragmentGuestPaywallFragmentDoc}
`;

type BillingLimitProgressViewProps = {
  organizationBilling: OrganizationBillingFragmentLimitProgressFragment;
};

export const BillingLimitProgressView = (
  props: BillingLimitProgressViewProps,
) => {
  const { organizationBilling } = props;

  const {
    isOpen: upgradeOpen,
    onClose: upgradeOnClose,
    onOpen: upgradeOnOpen,
  } = useDisclosure();

  const renderPlanName = () => {
    switch (organizationBilling.plan) {
      case BillingPlanName.Free:
        return <Typography variant="headline-sm">Starter Plan</Typography>;
      case BillingPlanName.Basic:
      case BillingPlanName.Enterprise:
      case BillingPlanName.Standard: {
        return (
          <Box>
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(2),
                alignItems: 'center',
              }}
            >
              <Typography variant="headline-sm">
                {organizationBilling.plan === BillingPlanName.Basic
                  ? 'Basic'
                  : organizationBilling.plan === BillingPlanName.Standard
                  ? 'Business'
                  : 'Enterprise'}{' '}
                Plan
              </Typography>
              {organizationBilling.subscription?.status ===
                BillingSubscriptionStatus.Trialing && (
                <Typography
                  variant="headline-xxs"
                  color={theme.colors?.utility[700]}
                >
                  Free trial
                </Typography>
              )}
            </Box>

            <Box>
              <Typography
                variant="headline-xxs"
                color={theme.colors?.utility[700]}
              >
                {organizationBilling.subscription?.status ===
                  BillingSubscriptionStatus.Trialing &&
                organizationBilling.subscription?.trialEnd
                  ? `${DateUtils.fromNowInDays(
                      organizationBilling.subscription.trialEnd * 1000,
                    )} left`
                  : organizationBilling.subscription?.status ===
                      BillingSubscriptionStatus.Active &&
                    organizationBilling.subscription.currentPeriodEnd
                  ? `Due on ${moment(
                      organizationBilling.subscription.currentPeriodEnd * 1000,
                    ).format('Do MMM, YYYY')}`
                  : ''}
              </Typography>
            </Box>
          </Box>
        );
      }
      default:
        return null;
    }
  };

  const shouldShowCTAForUpgrade = useMemo(() => {
    return organizationBilling.plan === BillingPlanName.Free;
  }, [organizationBilling]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        borderRadius: theme.spacing(2),
        padding: theme.spacing(4),
        border: `2px solid ${theme.colors?.utility[275]}`,
        bgcolor: theme.colors?.primary.white,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>{renderPlanName()}</Box>

        {shouldShowCTAForUpgrade && (
          <>
            <Typography
              variant="headline-xxs"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={upgradeOnOpen}
            >
              {organizationBilling.hasUsedFreeTrial
                ? 'Upgrade'
                : `Start Free Trial`}
            </Typography>

            <BillingUpgradeModal
              isOpen={upgradeOpen}
              onClose={upgradeOnClose}
            />
          </>
        )}
      </Box>

      <Divider />

      <BillingHourUsageProgress />
      <BillingFileStorageLimitProgress
        organizationBilling={organizationBilling}
      />
      <BillingCollectionLimitProgress
        organizationBilling={organizationBilling}
      />
      <BillingOrganizationMemberLimitProgress
        organizationBilling={organizationBilling}
      />
      <BillingGuestLimitProgress organizationBilling={organizationBilling} />
    </Box>
  );
};
