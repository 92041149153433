import { Box, Button, Dialog, Typography } from '@mui/material';

import { gql } from '@apollo/client';
import { IconCustomClose } from 'components/icons/components/custom/IconCustomClose';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { useUpdateUserOnboardingStateForJuiceboxIntroPopupMutation } from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { StyledCloseButton, StyledVideoContainer } from './styles';

// eslint-disable-next-line
gql`
  mutation UpdateUserOnboardingStateForJuiceboxIntroPopup(
    $data: UpdateUserOnboardingStateInput!
  ) {
    updateUserOnboardingState(data: $data) {
      id
      onboardingState {
        haveSeenJuiceBoxIntro
        haveClickedJuiceBoxBtn
      }
    }
  }
`;

type JuiceboxIntroPopupProps = {
  onClosePopup: VoidFunction;
};

export const JuiceboxIntroPopup = ({
  onClosePopup,
}: JuiceboxIntroPopupProps) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const [updateUserAdditionalInfo] =
    useUpdateUserOnboardingStateForJuiceboxIntroPopupMutation();

  const onClose = () => {
    updateUserAdditionalInfo({
      variables: {
        data: {
          onboardingState: {
            haveSeenJuiceBoxIntro: true,
          },
        },
      },
      onCompleted(data) {
        if (data) {
          onClosePopup();
        }
      },
    });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        sx: {
          maxWidth: 664,
          maxHeight: 577,
          p: 0,
          borderRadius: theme.spacing(6),
          border: 'none',
        },
      }}
    >
      <StyledCloseButton onClick={onClose}>
        <IconCustomClose size={20} />
      </StyledCloseButton>
      <StyledVideoContainer
        sx={{
          minHeight: !isVideoLoaded ? 577 : 0,
          minWidth: !isVideoLoaded ? 664 : 0,
        }}
      >
        <Box
          className="intro-cover"
          component="video"
          autoPlay
          muted
          loop
          playsInline
          src="/auth/register_text_bg_video.mp4"
          onCanPlayThrough={() => setIsVideoLoaded(true)}
        />
        {isVideoLoaded ? (
          <>
            <Box className="transparent-overlay" />
            <Box className="title">
              <Button className="juicebox-button">
                <IconCustomSparkles size={16} />
                Creative Juicebox
              </Button>

              <Typography
                variant="headline-xl"
                letterSpacing="-0.96px"
                textAlign="center"
                fontSize={theme.spacing(6)}
                fontWeight={500}
              >
                Braindump ideas and assets on the go.
              </Typography>
              <Typography variant="headline-md" textAlign="center">
                Text, share links, or upload media files, and let us work our
                magic-turning your chaos into a organized workspace.
              </Typography>
            </Box>
          </>
        ) : (
          <Box className="video-overlay" />
        )}
      </StyledVideoContainer>
    </Dialog>
  );
};
