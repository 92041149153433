import { Box, SxProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldFlag } from 'components/icons/components/bold/IconBoldFlag';
import {
  taskPriorityBackgroundColor,
  taskPriorityText,
  taskPriorityTextColor,
} from 'features/task/constants';
import { TaskPriority } from 'graphql/generated';

type Props = {
  priority?: TaskPriority | null;
  sx?: SxProps;
  useShortLabel?: boolean;
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
  };
};

export const TaskPriorityLabel = (props: Props) => {
  const { priority, sx, useShortLabel = false, componentsProps } = props;

  if (!priority) {
    return (
      <Box display="flex" sx={sx}>
        <IconBoldFlag
          size={componentsProps?.icon?.size || 16}
          color={componentsProps?.icon?.color}
        />
      </Box>
    );
  }

  return (
    <Tooltip
      title={`${taskPriorityText[priority]} ${priority}`}
      disableHoverListener={!useShortLabel}
      placement="top"
    >
      <Box
        display="flex"
        width="fit-content"
        sx={{
          p: 1,
          borderRadius: 1,
          color: taskPriorityTextColor[priority],
          backgroundColor: taskPriorityBackgroundColor[priority],
          minWidth: componentsProps?.icon?.size || 16,
          minHeight: componentsProps?.icon?.size || 16,
          aspectRatio: useShortLabel ? '1/1' : undefined,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
      >
        <Typography variant="body-sm" lineHeight={1}>
          {useShortLabel
            ? taskPriorityText[priority]
            : `${taskPriorityText[priority]} ${priority}`}
        </Typography>
      </Box>
    </Tooltip>
  );
};
