import { useEffect } from 'react';

/**
 * This hook is a trimmed down & more generic version of useKeyboardCommands from Command context.
 * It accept an array of keys and a callback to be called when the keys are pressed.
 */
export const useKeyboardCommand = (keys: string[], onTrigger: () => void) => {
  useEffect(() => {
    // Set to keep track of currently pressed keys
    const downKeys = new Set<string>();

    // Event handler for keydown
    const handleDown = (event: KeyboardEvent) => {
      if (!event.key) {
        return;
      }

      downKeys.add(event.key.toLowerCase());

      // Prevent the shortcut from triggering when the user is focusing on input elements
      const activeElement = document?.activeElement as HTMLElement;
      if (
        (activeElement &&
          (activeElement.tagName === 'INPUT' ||
            activeElement.tagName === 'TEXTAREA' ||
            activeElement.isContentEditable)) || // exclude text editor
        document?.activeElement?.className.includes('ProseMirror')
      ) {
        return;
      }

      // We only match if the combo length is the same
      // This is to ensure that default browser shortcuts like CMD + R still works
      if (downKeys.size !== keys.length) {
        return;
      }

      const matched = keys.every((k) => {
        if (downKeys.has(k.toLowerCase())) {
          return true;
        }

        // FIXME: There's a case-insensitive issue here
        // Due to the way we're handling the down key in line 18
        if (k === 'Meta' || k === 'Control') {
          return downKeys.has('meta') || downKeys.has('control');
        }

        return false;
      });

      if (matched) {
        // Prevent the default behavior
        event.preventDefault();
        // Call onTrigger callback
        onTrigger();
        // Clear downKeys on trigger
        downKeys.clear();
      }
    };

    // Event handler for keyup to clear the set of pressed keys
    const handleUp = () => {
      downKeys.clear();
    };

    // Add event listeners for keydown and keyup
    window.addEventListener('keydown', handleDown);
    window.addEventListener('keyup', handleUp);

    // Cleanup by removing event listeners when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleDown);
      window.removeEventListener('keyup', handleUp);
    };
  }, [keys, onTrigger]);
};
