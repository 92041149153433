import { useDisclosure } from '@dwarvesf/react-hooks';
import { Typography } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect } from 'react';
import { IconOutlineArrowDownload } from 'components/icons/components/outline/IconOutlineArrowDownload';
import { theme } from 'styles/theme';
// eslint-disable-next-line -- cycle issue
import { ContinueInMobileAppCTA } from './ContinueInMobileAppCTA';
import { DownloadMobileAppModal } from './DownloadMobileAppModal';
import { StyledTab } from '../../styles';

const SESSION_STORAGE_HAS_SEEN_CONTINUE_IN_MOBILE_APP_CTA =
  'hasSeenContinueInMobileAppCTA';

// TODO: Move this somewhere more contextually accurate
export const MOBILE_APP_APP_STORE_URL =
  'https://apps.apple.com/us/app/plot-your-creative-hub/id6476969734';

export const DownloadMobileAppCTA = () => {
  const { user, isMobileAppWebView } = useUserContext();

  const isMobileView = useMediaQueryMobile();

  const {
    isOpen: isDownloadMobileAppModalOpen,
    onOpen: openDownloadMobileAppModal,
    onClose: closeDownloadMobileAppModal,
  } = useDisclosure();
  const {
    isOpen: isContinueInMobileAppModalOpen,
    onOpen: openContinueInMobileAppModal,
    onClose: closeContinueInMobileAppModal,
  } = useDisclosure();

  const onCTAButtonClick = () => {
    if (!user) {
      return;
    }

    // If is mobile view, open app store link
    // If on desktop, open download mobile app modal
    if (isMobileView) {
      window.open(MOBILE_APP_APP_STORE_URL, '_blank');
    } else {
      openDownloadMobileAppModal();
    }
  };

  // TODO: Move these to a separate component?
  // Need to think about it because it's a different functionality.
  // But in a sense, it's related to mobile app download :thinking:
  //
  // Automatically show continue in mobile app dialog once
  // and track it with session storage
  const {
    value: hasSeenContinueInMobileAppCTA,
    setValue: setHasSeenContinueInMobileAppCTA,
  } = useSessionStorage<string>(
    SESSION_STORAGE_HAS_SEEN_CONTINUE_IN_MOBILE_APP_CTA,
    '',
  );
  useEffect(() => {
    if (isMobileView) {
      if (!hasSeenContinueInMobileAppCTA) {
        openContinueInMobileAppModal();
      }
    }
  }, [isMobileView]); // eslint-disable-line

  return (
    <>
      <StyledTab onClick={onCTAButtonClick}>
        <IconOutlineArrowDownload
          size={16}
          color={theme.colors?.utility[900]}
        />

        <Typography variant="subhead-md" color={theme.colors?.utility[900]}>
          Download the Mobile App
        </Typography>
      </StyledTab>

      <DownloadMobileAppModal
        isOpen={isDownloadMobileAppModalOpen}
        onClose={closeDownloadMobileAppModal}
      />
      {/* if open web app in webview => do not show this */}
      {isMobileView && !isMobileAppWebView && (
        <ContinueInMobileAppCTA
          isOpen={isContinueInMobileAppModalOpen}
          onClose={() => {
            closeContinueInMobileAppModal();
            setHasSeenContinueInMobileAppCTA('true');
          }}
        />
      )}
    </>
  );
};
