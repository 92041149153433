import { Components, Theme } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';

export const selectOverrides: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    displayEmpty: true,
    IconComponent: IconLinearArrowDown,
  },
  styleOverrides: {
    select: {
      fontSize: 13,
      padding: '12px',
      lineHeight: 1.25,
      fontFamily: typography['headline-sm'].fontFamily,
    },
    icon: {
      width: 16,
      height: 16,
    },
  },
};

export const menuItemOverrides: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      fontSize: 13,
      padding: '8px 12px',
    },
  },
};
