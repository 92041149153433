import { Box } from '@mui/material';
import { NodeViewProps } from '@tiptap/react';
import { clamp, throttle } from 'lodash';
import { MouseEventHandler, useRef } from 'react';
import { theme } from 'styles/theme';

export type ResizersProps = {} & NodeViewProps;

export const Resizers = (props: ResizersProps) => {
  const { editor, node, updateAttributes, getPos } = props;

  const originalWidthRef = useRef(0);
  const mouseDownPosRef = useRef({ x: 0, y: 0 });
  const isDraggingFromTheLeftRef = useRef(false);

  const onMouseDown: MouseEventHandler = (event) => {
    mouseDownPosRef.current = { x: event.clientX, y: event.clientY };

    const nodeDOM = editor.view.nodeDOM(getPos()) as Element;
    originalWidthRef.current = nodeDOM?.clientWidth;

    // Disable pointer events on the document to prevent other elements from capturing the mouse event
    const docEl = document.querySelector(
      '.tiptap.ProseMirror',
    ) as HTMLDivElement;
    if (docEl) {
      docEl.style.pointerEvents = 'none';
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const onMouseUp = () => {
    const docEl = document.querySelector(
      '.tiptap.ProseMirror',
    ) as HTMLDivElement;
    if (docEl) {
      docEl.style.pointerEvents = 'auto';
    }

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  // Calculate new width based on user's drag
  // We'll use width in percentage
  const onMouseMove = throttle((event: MouseEvent) => {
    const { x } = mouseDownPosRef.current;

    // Percentage of width from current pivot point -> mouse
    let delta = 0;
    if (isDraggingFromTheLeftRef.current) {
      delta = ((x - event.clientX) / originalWidthRef.current) * 100;
    } else {
      delta = ((event.clientX - x) / originalWidthRef.current) * 100;
    }

    // The further away from the pivot point the user moves the mouse,
    // the smaller the embed container will become
    const newWidthPercentage = clamp(
      (node.attrs.width || 100) + delta,
      30,
      100,
    );

    updateAttributes({
      width: newWidthPercentage,
    });
  }, 100);

  return (
    <>
      <Box
        component="button"
        className="embed-resizer embed-resizer-left"
        sx={{
          position: 'absolute',
          top: 'calc(50% - 6px)',
          left: 2,
          cursor: 'ew-resize',
          width: 8,
          height: 40,
          borderRadius: 3,
          border: `2px solid ${theme.colors?.primary.white}`,
          bgcolor: theme.colors?.primary.black,
        }}
        onMouseDown={(e) => {
          isDraggingFromTheLeftRef.current = true;
          onMouseDown(e);
        }}
        draggable
      />
      <Box
        component="button"
        className="embed-resizer embed-resizer-right"
        sx={{
          position: 'absolute',
          top: 'calc(50% - 6px)',
          right: 2,
          cursor: 'ew-resize',
          width: 8,
          height: 40,
          borderRadius: 3,
          border: `2px solid ${theme.colors?.primary.white}`,
          bgcolor: theme.colors?.primary.black,
        }}
        onMouseDown={(e) => {
          isDraggingFromTheLeftRef.current = false;
          onMouseDown(e);
        }}
        draggable
      />
    </>
  );
};
