import { gql } from '@apollo/client';
import {
  TaskFragmentTaskDetailViewFragment,
  TaskStatus,
  useDeleteTaskForTaskDetailViewMutation,
  useUpdateTaskForTaskDetailViewMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { evictObject } from 'utils/apollo';
import { extractTextFromHtml } from 'utils/html';
import { HeadingSectionProps } from '../sections';

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskDetailView($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      id
      name
      description
      status
      startDate
      endDate
      taskMembers {
        id
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeleteTaskForTaskDetailView($data: DeleteTaskInput!) {
    deleteTask(data: $data) {
      success
      message
    }
  }
`;

interface Props {
  task?: TaskFragmentTaskDetailViewFragment;
  onAfterTaskDelete?: () => void;
}

export const useTaskHandlers = (props: Props) => {
  const { task, onAfterTaskDelete } = props;

  const [updateTask] = useUpdateTaskForTaskDetailViewMutation();
  const [deleteTask] = useDeleteTaskForTaskDetailViewMutation();

  const {
    dialog: taskDeleteConfirmationDialog,
    onOpen: openDeleteConfirmationDialogForTask,
  } = useConfirmationDialog();

  const onTaskUpdate: HeadingSectionProps['onTaskUpdate'] = (task) => {
    return updateTask({
      variables: {
        data: {
          taskId: task.id || '',
          data: {
            name: task.name,
            startDate: task.startDate,
            endDate: task.endDate,
            description: task.description,
            status: task.status,
            memberIds: task.taskMembers?.map((m) => m.id),
          },
        },
      },
      optimisticResponse: {
        updateTask: {
          id: task.id || '',
          __typename: 'TaskModel',
          name: task.name || '',
          startDate: task.startDate,
          endDate: task.endDate,
          description: task.description || '',
          status: task.status || TaskStatus.ToDo,
          taskMembers: (task.taskMembers || []).map((m) => ({
            id: m.id,
            __typename: 'UserProfileModel',
          })),
        },
      },
    });
  };

  const onTaskDelete = () => {
    openDeleteConfirmationDialogForTask({
      title: (
        <>
          Would you like to delete <b>"{extractTextFromHtml(task?.name)}"</b>?
        </>
      ),
      subtitle: 'Once you delete, this asset will no longer be accessible.',
      onConfirm: async () => {
        await deleteTask({
          variables: {
            data: {
              taskId: task?.id || '',
            },
          },
          update: (cache) => evictObject(cache, task?.id || '', 'TaskModel'),
        });

        onAfterTaskDelete?.();
      },
    });
  };

  return {
    onTaskUpdate,
    onTaskDelete,
    taskDeleteConfirmationDialog,
  };
};
