import { Labels } from 'hooks/useResourceUploadQueue';
import { EditorRefProps } from './ImageEditor/ImageEditor';

export enum CoverType {
  UNSPLASH = 'unsplash',
  UPLOAD = 'upload',
}

export interface Props {
  coverUrl?: string;
  originalCoverUrl?: string;
  onChange: (imageFile?: File, originalImageUrl?: string) => void;
  isSubmitting?: boolean;
}

export type AvatarUploadProps = {
  isOpen: boolean;
  onPrimaryBtnClick?: (uploadedImage: File, fileName: string) => void;
  closeModal: () => void;
  labels?: Labels;
};

export type CoverUploaderRefProps = EditorRefProps;
