import { Box, Typography } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { StyledSlider } from '../styles';

interface FilterByTopicRelevancyProps {
  onToggle: (value: number) => void;
  selectedTopicRelevancy: number;
  renderTitle?: () => React.ReactNode;
  type?: 'accordion' | 'normal';
}

export const FilterByTopicRelevancy = ({
  onToggle,
  selectedTopicRelevancy = 0.5,
  renderTitle,
  type = 'accordion',
}: FilterByTopicRelevancyProps) => {
  const renderDefaultTitle = useMemo(() => {
    return (
      <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
        Topic Relevancy
      </Typography>
    );
  }, []);

  const renderOptions = useMemo(() => {
    return (
      <Box
        sx={{ width: '100%', ...(type === 'accordion' && { pl: 4, pr: 2 }) }}
      >
        <StyledSlider
          defaultValue={0.5}
          valueLabelDisplay="auto"
          marks={[
            ...Array.from({ length: 6 }, (_, i) => ({
              value: 0.5 + i * 0.1,
              label: `${(0.5 + i * 0.1) * 100}%`,
            })),
            { value: 1, label: '100%' },
          ]}
          step={0.1}
          min={0.5}
          max={1}
          onChange={(_, value) => {
            if (typeof value === 'number') {
              onToggle(value);
            }
          }}
          value={selectedTopicRelevancy}
          valueLabelFormat={(value) => `${value * 100}%`}
        />
      </Box>
    );
  }, [type, selectedTopicRelevancy, onToggle]);

  if (type === 'normal') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {renderTitle?.() ?? renderDefaultTitle}
        <Box>{renderOptions}</Box>
      </Box>
    );
  }

  const Icon =
    socialListeningFilterIconMap[SocialListeningFiltersKey.TopicRelevancy];

  return (
    <CollapsibleSection
      sx={{
        padding: theme.spacing(2, 3), // To match the padding of MenuItem
        borderRadius: 3,
        ':hover': {
          background: 'rgba(0, 0, 0, 0.04)',
        },
      }}
      renderHeaderTitle={() =>
        renderTitle?.() ?? (
          <Box display="flex" alignItems="center" gap={2} width="100%">
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              <Icon size={16} />
            </Box>
            {renderDefaultTitle}
          </Box>
        )
      }
      divider={false}
    >
      {renderOptions}
    </CollapsibleSection>
  );
};
