import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { CarouselMedia, CarouselMediaTypeEnum } from 'graphql/generated';
import {
  StyledNavigation,
  StyledPagination,
  StyledSwiperContainer,
} from './styles';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useVideoPlayer } from '../Video/hooks/useVideoPlayer';

export const Carousel = forwardRef<
  HTMLElement,
  {
    medias: CarouselMedia[];
  }
>(({ medias }, ref) => {
  const carouselRef = useRef<HTMLElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const prevElementRef = useRef<HTMLDivElement>(null);
  const nextElementRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => carouselRef.current!, []);

  useEffect(() => {
    let swiper: Swiper;

    if (carouselRef.current) {
      swiper = new Swiper(carouselRef.current, {
        modules: [Navigation, Pagination],
        ...(paginationRef.current && {
          pagination: {
            el: paginationRef.current,
            clickable: true,
          },
        }),
        ...(prevElementRef.current &&
          nextElementRef.current && {
            navigation: {
              nextEl: nextElementRef.current,
              prevEl: prevElementRef.current,
            },
          }),
      });
    }

    return () => {
      if (swiper) {
        swiper.destroy();
      }
    };
  }, [carouselRef, paginationRef, prevElementRef, nextElementRef]);

  return (
    <Box position="relative">
      <StyledSwiperContainer
        ref={carouselRef}
        sx={{
          visibility: 'visible',
          width: '100%',
          height: '100%',
          borderRadius: 4,
        }}
        className="swiper"
      >
        <Box className="swiper-wrapper">
          {medias.map((media) =>
            media.type === CarouselMediaTypeEnum.Image ? (
              <Box
                key={media.id}
                component="img"
                src={media.url}
                className="swiper-slide"
              />
            ) : (
              <VideoMedia key={media.id} media={media} />
            ),
          )}
        </Box>
        <StyledNavigation ref={nextElementRef} className="swiper-button-next">
          <ChevronRight />
        </StyledNavigation>
        <StyledNavigation ref={prevElementRef} className="swiper-button-prev">
          <ChevronLeft />
        </StyledNavigation>
        <StyledPagination ref={paginationRef} className="swiper-pagination" />
      </StyledSwiperContainer>
    </Box>
  );
});

const VideoMedia = ({ media }: { media: CarouselMedia }) => {
  const { videoUI } = useVideoPlayer({
    videoUrl: media.url,
  });

  return <Box className="swiper-slide">{videoUI}</Box>;
};
