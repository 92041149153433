import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineDislike } from 'components/icons/components/outline/IconOutlineDislike';
import {
  SocialPostFragmentSocialListeningContentCalendarSaveButtonFragmentDoc,
  SocialPostFragmentSocialListeningPostSaveButtonFragmentDoc,
  SocialPostFragmentSocialMediaListeningPostContextMenuFragmentDoc,
  useGetSocialPostForSocialPostContextMenuQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { SocialListeningContentCalendarSaveButton } from '../socialListeningContentCalendarSaveButton';
import { SocialListeningPostSaveButton } from '../socialListeningPostSaveButton';

type SocialMediaListeningPostContextMenuProps = Omit<
  ContextMenuProps,
  'options' | 'renderButton'
> &
  Partial<Pick<ContextMenuProps, 'options' | 'renderButton'>> & {
    socialPostId: string;
    socialPostEngagementSuggestionId: string;
    onShowReportPostDialog: VoidFunction;
  };

// eslint-disable-next-line
gql`
  fragment SocialPostFragmentSocialMediaListeningPostContextMenu on SocialPostModel {
    ...SocialPostFragmentSocialListeningPostSaveButton
    ...SocialPostFragmentSocialListeningContentCalendarSaveButton
  }
  ${SocialPostFragmentSocialListeningPostSaveButtonFragmentDoc}
  ${SocialPostFragmentSocialListeningContentCalendarSaveButtonFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`query GetSocialPostForSocialPostContextMenu($socialPostId: String!) {
  socialPost(id: $socialPostId) {
    ...SocialPostFragmentSocialMediaListeningPostContextMenu
  }
  ${SocialPostFragmentSocialMediaListeningPostContextMenuFragmentDoc}
}`;

export const SocialMediaListeningPostContextMenu = (
  props: SocialMediaListeningPostContextMenuProps,
) => {
  const {
    socialPostId,
    socialPostEngagementSuggestionId,
    onShowReportPostDialog,
    ...rest
  } = props;
  const { data: socialPostData, loading: postLoading } =
    useGetSocialPostForSocialPostContextMenuQuery({
      variables: {
        socialPostId,
      },
      skip: !rest.open,
    });
  const post = socialPostData?.socialPost;

  const {
    isOpen: isBookmarkPopoverOpen,
    onClose: closeBookmarkPopover,
    onOpen: openBookmarkPopover,
  } = useDisclosure();

  const renderOption = (title, Icon) => {
    return (
      <Box display="flex" gap={2} alignItems="center">
        <Box
          sx={{
            padding: 1,
            borderRadius: 1,
            background: 'rgba(35, 6, 3, 0.05)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon size={16} color={theme.colors?.primary.black} />
        </Box>
        <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
          {title}
        </Typography>
      </Box>
    );
  };

  return (
    <ContextMenu
      onClick={(e) => {
        e.stopPropagation();
      }}
      renderButton={false}
      onClose={() => {
        closeBookmarkPopover();
      }}
      options={
        postLoading
          ? [
              {
                renderOption: () => (
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.primary.black}
                    display="flex"
                    alignItems="center"
                    gap={2}
                  >
                    <CircularProgress
                      size={20}
                      sx={{
                        color: theme.colors?.primary.black,
                      }}
                    />
                    Loading...
                  </Typography>
                ),
              },
            ]
          : post
          ? [
              {
                renderOption: () => (
                  <SocialListeningPostSaveButton
                    socialListeningPost={post}
                    socialPostEngagementSuggestionId={
                      socialPostEngagementSuggestionId
                    }
                    containerId="social-media-post-creative-juicebox-popover"
                    renderBtn={() =>
                      renderOption(
                        'Save to Creative Juicebox',
                        IconCustomCreativeJuiceBox,
                      )
                    }
                    onClickSavePost={() => {
                      if (post.savedPost) {
                        openBookmarkPopover();
                      }
                    }}
                  />
                ),
                onClick: () => {},
                closeOnClick: true,
              },
              {
                renderOption: () => (
                  <SocialListeningContentCalendarSaveButton
                    socialPost={post}
                    socialPostEngagementSuggestionId={
                      socialPostEngagementSuggestionId
                    }
                    renderBtn={() =>
                      renderOption(
                        'Add to Content Calendar',
                        IconOutlineCalendar,
                      )
                    }
                  />
                ),
                onClick: () => {},
                closeOnClick: true,
              },
              {
                renderOption: () => (
                  <Box
                    display="flex"
                    color={theme.colors?.utility['pink-3']}
                    gap={2}
                    alignItems="center"
                    onClick={onShowReportPostDialog}
                  >
                    <IconOutlineDislike size={16} />
                    <Typography fontWeight={600} variant="subhead-lg">
                      Report Post
                    </Typography>
                  </Box>
                ),
                onClick: () => {},
                closeOnClick: true,
              },
            ].map(({ renderOption, onClick, closeOnClick }) => {
              return {
                renderOption,
                onClick,
                closeOnClick,
              };
            })
          : []
      }
      MenuListProps={{
        sx: {
          width: 266,
          gap: `${theme.spacing(2)} !important`,
        },
      }}
      disableRestoreFocus
      sx={{
        ...(isBookmarkPopoverOpen ? { opacity: 0, zIndex: -1 } : {}),
        '& .MuiPaper-root': {
          ...(rest.anchorPosition?.top
            ? { top: `${rest.anchorPosition.top}px !important` }
            : {}),
          ...(rest.anchorPosition?.left
            ? { left: `${rest.anchorPosition.left}px !important` }
            : {}),
        },
      }}
      PaperProps={{
        style: {
          background: `rgba(255, 255, 255, 0.9)`,
          boxShadow: `0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)`,
          border: 'none',
          padding: theme.spacing(3, 0),
        },
      }}
      {...rest}
    />
  );
};
