import { gql } from '@apollo/client';
import { useGetMeForUseNotificationSettingsQuery } from 'graphql/generated';
import { useMemo } from 'react';
import {
  manageableNotificationSettings,
  notificationTypeLabels,
} from './constants';

export const NOTIFICATION_SETTING_FRAGMENT_USE_NOTIFICATION_SETTINGS = gql`
  fragment NotificationSettingFragmentUseNotificationSettings on NotificationSettingModel {
    id
    type
    email
    mobilePush
    inbox
  }
`;

// eslint-disable-next-line
gql`
  query GetMeForUseNotificationSettings {
    me {
      id
      notificationSettings {
        id
        ...NotificationSettingFragmentUseNotificationSettings
      }
    }
  }
  ${NOTIFICATION_SETTING_FRAGMENT_USE_NOTIFICATION_SETTINGS}
`;

export const useNotificationSettings = () => {
  const { data } = useGetMeForUseNotificationSettingsQuery();
  const user = data?.me;

  const {
    taskNotificationSettings,
    cjbNotificationSettings,
    contentCalendarNotificationSettings,
  } = useMemo(() => {
    if (!user?.notificationSettings) {
      return {
        taskNotificationSettings: [],
        cjbNotificationSettings: [],
        contentCalendarNotificationSettings: [],
      };
    }

    const taskNotificationSettings = manageableNotificationSettings.tasks
      .filter((s) => user.notificationSettings.some((i) => i.type === s.type))
      .map((s) => {
        const userSetting = user.notificationSettings.find(
          (i) => i.type === s.type,
        );

        return {
          ...userSetting!,
          label: notificationTypeLabels[s.type],
          manageableSetting: s,
        };
      })
      .filter((s) => Boolean(s.label));

    const cjbNotificationSettings = manageableNotificationSettings.cjb
      .filter((s) => user.notificationSettings.some((i) => i.type === s.type))
      .map((s) => {
        const userSetting = user.notificationSettings.find(
          (i) => i.type === s.type,
        );

        return {
          ...userSetting!,
          label: notificationTypeLabels[s.type],
          manageableSetting: s,
        };
      })
      .filter((s) => Boolean(s.label));

    const contentCalendarNotificationSettings =
      manageableNotificationSettings.contentCalendar
        .filter((s) => user.notificationSettings.some((i) => i.type === s.type))
        .map((s) => {
          const userSetting = user.notificationSettings.find(
            (i) => i.type === s.type,
          );

          return {
            ...userSetting!,
            label: notificationTypeLabels[s.type],
            manageableSetting: s,
          };
        })
        .filter((s) => Boolean(s.label));

    return {
      taskNotificationSettings,
      cjbNotificationSettings,
      contentCalendarNotificationSettings,
    };
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(user?.notificationSettings),
  ]);

  return {
    taskNotificationSettings,
    cjbNotificationSettings,
    contentCalendarNotificationSettings,
  };
};
