import { gql } from '@apollo/client';
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { IconBoldTrash } from 'components/icons/components/bold/IconBoldTrash';
import { CreatorAvatarWithNameView } from 'features/creator/views';
import {
  CreatorFragmentCreatorAvatarWithNameViewFragmentDoc,
  CreatorFragmentSocialListeningSettingsSocialHandleInputFragment,
  Platform,
  useGetSocialCreatorForSocialListeningSettingsSocialHandleInputLazyQuery,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { getCustomOperationContext } from 'utils/apollo';

export const CREATOR_FRAGMENT_SOCIAL_LISTENING_SETTINGS_SOCIAL_HANDLE_INPUT = gql`
  fragment CreatorFragmentSocialListeningSettingsSocialHandleInput on CreatorModel {
    id
    ...CreatorFragmentCreatorAvatarWithNameView
  }
  ${CreatorFragmentCreatorAvatarWithNameViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetSocialCreatorForSocialListeningSettingsSocialHandleInput(
    $filters: SocialCreatorFilters!
  ) {
    getSocialCreator(filters: $filters) {
      id
      ...CreatorFragmentSocialListeningSettingsSocialHandleInput
    }
  }
  ${CREATOR_FRAGMENT_SOCIAL_LISTENING_SETTINGS_SOCIAL_HANDLE_INPUT}
`;

export type SocialHandleInputProps = {
  creator?: CreatorFragmentSocialListeningSettingsSocialHandleInputFragment;
  platform: Platform;
  canRemove?: boolean;
  readOnly?: boolean;

  actions: {
    onRemove?: () => void;
    onConfirm: (
      creator: CreatorFragmentSocialListeningSettingsSocialHandleInputFragment,
    ) => void;
  };
};

export const SocialHandleInput = (props: SocialHandleInputProps) => {
  const { creator, platform, actions, canRemove, readOnly } = props;

  const [handle, setHandle] = useState(creator?.handle ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [getSocialCreator] =
    useGetSocialCreatorForSocialListeningSettingsSocialHandleInputLazyQuery();

  const {
    dialog: removeCreatorConfirmationDialog,
    onOpen: openRemoveCreatorConfirmationDialog,
  } = useConfirmationDialog();
  const {
    dialog: confirmCreatorConfirmationDialog,
    onOpen: openConfirmCreatorConfirmationDialog,
  } = useConfirmationDialog({
    sx: {
      maxWidth: 360,
    },
  });

  const onSubmit = async () => {
    setErrorMessage('');

    if (!handle || handle === creator?.handle) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await getSocialCreator({
        variables: {
          filters: {
            handle,
            platform,
          },
        },
        context: getCustomOperationContext({
          suppressTopLevelToast: true,
        }),
      });
      const creator = response.data?.getSocialCreator;

      if (!creator) {
        setErrorMessage(response.error?.message || 'Creator not found');
      } else {
        openConfirmCreatorConfirmationDialog({
          title: 'Confirm handle',
          subtitle: (
            <CreatorAvatarWithNameView
              creator={creator}
              sx={{
                width: '100%',
                bgcolor: '#2306031A',
                p: 4,
              }}
              componentsProps={{
                avatar: {
                  sx: {
                    width: 48,
                    height: 48,
                  },
                },
                nickName: {
                  sx: {
                    fontSize: 20,
                    color: theme.colors?.primary.black,
                  },
                },
                handle: {
                  sx: {
                    fontSize: 16,
                    color: theme.colors?.utility[800],
                  },
                },
              }}
            />
          ),
          confirmText: 'Save Changes',
          cancelText: 'Edit',
          onConfirm: async () => {
            actions.onConfirm(creator);
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemove = () => {
    if (!creator) {
      return;
    }

    actions.onRemove?.();

    setHandle('');
  };

  return (
    <>
      <Box display="flex" flexDirection="column" mt={2} gap={3}>
        <Typography variant="headline-sm">{platform} handle</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <TextField
            disabled={isLoading || readOnly}
            value={handle}
            onChange={(e) => setHandle(e.target.value)}
            InputProps={{
              startAdornment: '@',
              endAdornment: isLoading && <CircularProgress size={16} />,
            }}
            sx={{
              width: 280,
              '& .MuiOutlinedInput-root': {
                fontWeight: 600,
                color: theme.colors?.primary.black,
                pb: 1,
                paddingRight: `${theme.spacing(2)}`,
                borderRadius: theme.spacing(2),
                backgroundColor: theme.colors?.utility[250],
                border: `2px solid ${theme.colors?.utility[300]}`,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();

                onSubmit();
              }
            }}
            onBlur={onSubmit}
            placeholder={platform.toLowerCase()}
          />
          {!readOnly && canRemove && creator && (
            <IconButton
              size="small"
              onClick={() => {
                openRemoveCreatorConfirmationDialog({
                  onConfirm: onRemove,
                  title: `Are you sure you want to delete this ${platform} handle?`,
                  subtitle: `Just a heads up: if you delete @${creator.handle} we won't be able to parse any more content associated with this handle.`,
                  confirmText: 'Continue',
                  cancelText: 'Cancel',
                });
              }}
            >
              <IconBoldTrash
                size={16}
                color={theme.colors?.utility['pink-3']}
              />
            </IconButton>
          )}
        </Box>
        {errorMessage && (
          <Typography
            variant="subhead-lg"
            color={theme.colors?.utility['pink-3']}
          >
            {errorMessage}
          </Typography>
        )}
      </Box>
      {confirmCreatorConfirmationDialog}
      {removeCreatorConfirmationDialog}
    </>
  );
};
