import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PermissionMemberItem, PermissionSelector } from 'features/permission';
import { USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW } from 'features/user-profile';
import { CollectionInviteMemberFragmentCollectionPermissionMemberFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const COLLECTION_INVITE_MEMBER_FRAGMENT_COLLECTION_PERMISSION_MEMBER = gql`
  fragment CollectionInviteMemberFragmentCollectionPermissionMember on CollectionInviteMemberModel {
    id
    permissionLevel
    user {
      ...UserProfileFragmentUserProfilePermissionItemView
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW}
`;

type CollectionPermissionMemberProps = {
  collectionInviteMember: CollectionInviteMemberFragmentCollectionPermissionMemberFragment;
  collectionOrganizationId: string;
  onPermissionChange: (
    collectionInviteMember: CollectionInviteMemberFragmentCollectionPermissionMemberFragment,
    remove?: boolean,
  ) => void;
  readonly?: boolean;
};

export const CollectionPermissionMember = (
  props: CollectionPermissionMemberProps,
) => {
  const {
    collectionInviteMember,
    collectionOrganizationId,
    onPermissionChange,
    readonly,
  } = props;

  return (
    <PermissionMemberItem
      userProfile={collectionInviteMember.user}
      entityOrganizationId={collectionOrganizationId}
      type="custom"
      endAdornmentProps={{
        custom: {
          endAdornment: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.colors?.primary.black,
              }}
            >
              <PermissionSelector
                readonly={readonly}
                entityOrganizationId={collectionOrganizationId}
                userOrganizationId={collectionInviteMember.user.organization.id}
                isDisabledUser={!!collectionInviteMember.user.isDisabled}
                initialValue={collectionInviteMember.permissionLevel}
                onPermissionChange={(permission) => {
                  if (permission !== collectionInviteMember.permissionLevel) {
                    onPermissionChange({
                      ...collectionInviteMember,
                      permissionLevel: permission,
                    });
                  }
                }}
                selectPaperSx={{
                  backgroundColor: 'rgba(250, 243, 236, 1);',
                }}
                showRemove
                onRemove={() => {
                  onPermissionChange(collectionInviteMember, true);
                }}
              />
            </Box>
          ),
        },
      }}
    />
  );
};
