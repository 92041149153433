import { Box, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

export const PostCardViewSkeleton = () => {
  return (
    <Box height="100%">
      <Skeleton
        sx={{
          height: '100%',
          borderRadius: 3,
          transform: 'scale(1)',
          backgroundColor: theme.colors?.utility[50],
        }}
        animation={false}
      />
    </Box>
  );
};
