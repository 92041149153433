import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, SxProps, Typography, TypographyProps } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from 'components/common/DatePicker';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import moment from 'moment';
import React from 'react';
import { theme } from 'styles/theme/theme';

interface Props {
  value?: DateRange<moment.Moment>;
  readOnly?: boolean;
  onChange: (value: DateRange<moment.Moment>) => void;
  sx?: SxProps;
  showOnlyIcon?: boolean;
  dateTextVariant?: 'range' | 'due' | 'start';
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
    typography?: TypographyProps;
  };
}

export const DueDateSelectButton = (props: Props) => {
  const {
    value,
    readOnly,
    onChange,
    sx,
    showOnlyIcon,
    componentsProps,
    dateTextVariant = 'range',
  } = props;

  const pickerAnchorRef = React.useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isPickerOpen,
    onOpen: openPicker,
    onClose: closePicker,
  } = useDisclosure();

  const selected = value && value.filter(Boolean).length > 1;

  return (
    <Box>
      <Box
        component="button"
        type="button"
        ref={pickerAnchorRef}
        onClick={openPicker}
        display="flex"
        gap={1}
        alignItems="center"
        disabled={readOnly}
        borderRadius="12px"
        sx={sx}
      >
        {!selected && (
          <IconBoldCalendar
            size={componentsProps?.icon?.size || 24}
            color={componentsProps?.icon?.color || theme.colors?.utility[700]}
          />
        )}

        <Typography
          variant="subhead-xl"
          color={theme.colors?.utility[700]}
          {...componentsProps?.typography}
        >
          {selected
            ? `${
                dateTextVariant === 'range'
                  ? `${value?.[0]?.format('D MMM')} - ${value?.[1]?.format(
                      'D MMM',
                    )}`
                  : dateTextVariant === 'due'
                  ? value?.[1]?.format('D MMM')
                  : value?.[0]?.format('D MMM')
              } `
            : !showOnlyIcon && 'Select a Date'}
        </Typography>
      </Box>
      <DateRangePicker
        calendars={1}
        open={isPickerOpen}
        onClose={closePicker}
        value={value}
        onChange={onChange}
        sx={{ '& .MuiTypography-root': { display: 'none' } }}
        slots={{
          // eslint-disable-next-line
          textField: () => null,
        }}
        slotProps={{
          actionBar: {
            actions: [],
          },
          popper: {
            anchorEl: pickerAnchorRef.current,
            placement: 'bottom-start',
          },
        }}
      />
    </Box>
  );
};
