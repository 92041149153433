import type { Node } from '@tiptap/pm/model';
import { Selection } from '@tiptap/pm/state';
import { ATTR_BLOCK_ID, NODE_COLUMN } from '../extensions/constants'; //avoid circular dependency
import { MousePos } from '../types';

export const getMentionedUserIds = (html: string) => {
  const pattern = /data-id="([^"]+)"/g;

  const matches: string[] = [];
  let match: RegExpExecArray | null;

  // eslint-disable-next-line no-cond-assign
  while ((match = pattern.exec(html)) !== null) {
    matches.push(match[1]);
  }

  return matches;
};

/**
 * Traverse through the doc to find node based on attribute
 *
 * @param doc
 * @param attribute
 * @param value
 */
export const findNodeByAttribute = (
  doc: Node,
  attribute: string,
  value: any,
): { node: Node; parent: Node | null; from: number; to: number } | null => {
  let foundNode: {
    node: Node;
    parent: Node | null;
    from: number;
    to: number;
  } | null = null;

  // Traverse the document
  doc.descendants((node, pos, parent) => {
    if (node.attrs && node.attrs[attribute] === value) {
      foundNode = { node, parent, from: pos, to: pos + node.nodeSize };
      return false;
    }
  });

  return foundNode;
};

/**
 * Traverse through the doc to find node based on blockId attribute
 *
 * @param doc
 * @param blockId
 */
export const findNodeByBlockWrapperExtensionAttribute = (
  doc: Node,
  blockId: string,
): { node: Node; parent: Node | null; from: number; to: number } | null => {
  return findNodeByAttribute(doc, ATTR_BLOCK_ID, blockId);
};

/**
 * find node position based on data-block-id
 *
 * @param doc
 * @param nodeDataBlockWrapperExtension
 */
export const findNodePosition = (
  doc: Node,
  nodeDataBlockWrapperExtension: string,
) => {
  let position = -1;

  doc.descendants((node, pos) => {
    if (node.attrs[ATTR_BLOCK_ID] === nodeDataBlockWrapperExtension) {
      position = pos;
      return false; // Stop the traversal
    }
  });

  return position;
};

/**
 * Determine which side of the document the user is hovering over.
 * Here we relies on the document's bounding rect & the attributes set on the document
 * - innercontentmaxwidth: The maximum width of the inner content of the document
 * - bottompadding: The padding at the bottom of the document
 *
 * @param mouesPos
 * @returns
 */
export const determineWhichSideOfDocUserIsHoveringOver = (
  mousePos: MousePos,
  contentOffset?: {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
  },
) => {
  const docEl = document.querySelector('.tiptap.ProseMirror') as HTMLElement;

  if (docEl) {
    // TODO: Find a more "react" way to get these config
    const innerContentMaxWidth = parseInt(
      docEl.getAttribute('innercontentmaxwidth') || '0',
    );
    const bottomPadding = parseInt(docEl.getAttribute('bottompadding') || '0');

    const { top, bottom, left, right, width } = docEl.getBoundingClientRect();

    let adjustedLeft = left;
    let adjustedRight = right;
    if (width > innerContentMaxWidth) {
      adjustedLeft = left + (width - innerContentMaxWidth) / 2;
      adjustedRight = right - (width - innerContentMaxWidth) / 2;
    }

    if (mousePos.clientX <= adjustedLeft + (contentOffset?.left || 0)) {
      return 'left';
    }

    if (mousePos.clientX >= adjustedRight + (contentOffset?.right || 0)) {
      return 'right';
    }

    if (mousePos.clientY <= top + (contentOffset?.top || 0)) {
      return 'top';
    }

    if (
      mousePos.clientY >=
      bottom - bottomPadding + (contentOffset?.bottom || 0)
    ) {
      // HACK FOR DEVELOPMENT!!! 150 is the bottom padding in NoteView
      // I'll fix this soon. Probably not a good idea to add a padding to the bottom of the document like this
      return 'bottom';
    }
  }

  return null;
};

/**
 *  Check if the selection is inside a column block;
 *
 * @param selection
 * @returns
 */
export const checkIfSelectionIsInsideAColumnBlock = (selection: Selection) => {
  let columnable = true;
  let depth = selection.$from.depth;

  while (depth > 0) {
    const parent = selection.$from.node(depth);
    if (parent.type.name === NODE_COLUMN) {
      columnable = false;

      break;
    }

    depth -= 1;
  }

  return columnable;
};
