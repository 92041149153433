import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  BillingPaymentMethodFragmentCardDetailFragmentDoc,
  BillingSetupIntent,
  useCancelBillingSetupIntentMethodMutation,
  useCreateBillingSetupIntentMethodMutation,
  useUpdateCustomerDefaultPaymentMethodMutation,
} from 'graphql/generated';
import { LinearProgress } from 'components/common/LinearProgress';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { theme } from 'styles/theme/theme';
import { stripePromise } from 'features/billing';

// eslint-disable-next-line
gql`
  mutation UpdateCustomerDefaultPaymentMethod(
    $data: UpdateBillingCustomerDefaultPaymentMethodInput!
  ) {
    updateCustomerDefaultPaymentMethod(data: $data) {
      id
      defaultPaymentMethod {
        ...BillingPaymentMethodFragmentCardDetail
      }
    }
  }
  ${BillingPaymentMethodFragmentCardDetailFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateBillingSetupIntentMethod($data: CreateSetupIntentInput!) {
    createSetupIntent(data: $data) {
      id
      clientSecret
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation CancelBillingSetupIntentMethod($data: CancelSetupIntentInput!) {
    cancelSetupIntent(data: $data) {
      id
    }
  }
`;

interface UpdateCardDetailsProps {
  isOpen: boolean;
  onClose: VoidFunction;
  refetch?: () => Promise<void>;
}

const AddPaymentMethodForm = (
  props: Omit<UpdateCardDetailsProps, 'isOpen'>,
) => {
  const { onClose, refetch } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [updateCustomerDefaultPaymentMethod] =
    useUpdateCustomerDefaultPaymentMethodMutation();

  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState<string>();

  const addPaymentMethod = async () => {
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      setIsLoading(false);
      setErrorMsg(result.error.message);
    } else {
      try {
        await updateCustomerDefaultPaymentMethod({
          variables: {
            data: {
              paymentMethodId: result.setupIntent.payment_method as string,
            },
          },
        });
      } finally {
        // refetch here because the payment method has different id when creating a new default payment method id
        await refetch?.();
        setIsLoading(false);
        onClose();
      }
    }
  };

  return (
    <>
      <PaymentElement />

      <Box
        sx={{
          margin: '15px 0',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        {errorMsg && (
          <Typography variant="body-md" color={theme.colors?.utility.red}>
            {errorMsg}
          </Typography>
        )}

        <Button
          fullWidth
          disabled={isLoading}
          variant="primary"
          onClick={addPaymentMethod}
        >
          {isLoading ? <CircularProgress size="1rem" /> : 'Update'}
        </Button>

        <Button
          fullWidth
          disabled={isLoading}
          disableRipple
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export const UpdateCardDetails = (props: UpdateCardDetailsProps) => {
  const { isOpen, onClose, refetch } = props;

  const [setupIntent, setSetupIntent] = useState<BillingSetupIntent>();

  const [isLoading, setIsLoading] = useState(true);

  const [createBillingSetupIntent] =
    useCreateBillingSetupIntentMethodMutation();
  const [cancelBillingSetupIntent] =
    useCancelBillingSetupIntentMethodMutation();

  useEffect(() => {
    if (isOpen && !setupIntent) {
      createBillingSetupIntent({
        variables: {
          data: { paymentMethodTypes: ['card'] },
        },
      }).then((res) => {
        if (res.data?.createSetupIntent) {
          setSetupIntent(res.data.createSetupIntent);
        }

        setIsLoading(false);
      });
    }
  }, [createBillingSetupIntent, isOpen, setupIntent]);

  const handleOnClose = () => {
    if (setupIntent) {
      cancelBillingSetupIntent({
        variables: {
          data: { setupIntentId: setupIntent.id },
        },
      }).then();
    }

    setSetupIntent(undefined);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <DialogTitle>Update payment method</DialogTitle>

      <DialogContent>
        {isLoading ? (
          <LinearProgress />
        ) : (
          setupIntent && (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: setupIntent.clientSecret }}
            >
              <AddPaymentMethodForm onClose={onClose} refetch={refetch} />
            </Elements>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};
