import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Typography, styled } from '@mui/material';
import { StyledTabs } from './styles';
import { Props } from './types';

// I'm not sure about defining a fixed vertical padding for the tab panel like this yet,
// but we can circle back to this when we run into a scenario where we need to customize this
const StyledPanel = styled(TabPanel)`
  padding: 20px 0 0;
`;

export const Tabs = (props: Props) => {
  const {
    tab,
    tabs,
    tabsExtra,
    tabsExtraAlign = 'right',
    className,
    onChange,
  } = props;

  return (
    <TabContext value={tab}>
      <Box className={className}>
        <Box display="flex" width="100%" gap={4}>
          {tabsExtraAlign === 'left' && tabsExtra}
          <StyledTabs
            value={tab}
            onChange={(_, v) => {
              onChange(v);
            }}
            variant="scrollable"
            scrollButtons={false}
          >
            {tabs.map((tab) => {
              return (
                <Tab
                  label={
                    typeof tab.label === 'string' ? (
                      <Typography variant="body-lg" letterSpacing={0}>
                        {tab.label}
                      </Typography>
                    ) : (
                      tab.label
                    )
                  }
                  value={tab.key}
                  key={`${tab.key}-tab`}
                  id={`${tab.key}Tab`}
                  disableRipple
                />
              );
            })}
            {tabsExtraAlign === 'beside' && tabsExtra}
          </StyledTabs>
          {tabsExtraAlign === 'right' && tabsExtra}
        </Box>
        {tabs.map((tab) => {
          return (
            <StyledPanel value={tab.key} key={`${tab.key}-content`}>
              {tab.content}
            </StyledPanel>
          );
        })}
      </Box>
    </TabContext>
  );
};
