import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { PostPreview } from 'features/post/components';
import {
  CollectionFragmentCollectionCardCompactViewFragment,
  PostFragmentPostPreviewFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_COLLECTION_CARD_COMPACT_VIEW = gql`
  fragment PostFragmentCollectionCardCompactView on PostModel {
    id
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export const COLLECTION_FRAGMENT_COLLECTION_CARD_COMPACT_VIEW = gql`
  fragment CollectionFragmentCollectionCardCompactView on CollectionModel {
    id
    name
    posts(postCount: 1) {
      id
      ...PostFragmentCollectionCardCompactView
    }
  }
  ${POST_FRAGMENT_COLLECTION_CARD_COMPACT_VIEW}
`;

export type CollectionCardCompactViewProps = {
  collection: CollectionFragmentCollectionCardCompactViewFragment;
  sx?: SxProps;
};

export const CollectionCardCompactView = (
  props: CollectionCardCompactViewProps,
) => {
  const { collection, sx } = props;

  // Use first post for thumbnail
  const postForThumbnail = collection.posts[0];

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        bgcolor: theme.colors?.utility[300],
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 40,
          background:
            'linear-gradient(180deg, rgba(35, 6, 3, 0.00) 0%, #230603 100%)',
        },
        ...sx,
      }}
    >
      {postForThumbnail && <PostPreview post={postForThumbnail} />}
      <Typography
        variant="headline-xxs"
        sx={{
          color: theme.colors?.primary.parchment,
          position: 'absolute',
          bottom: 0,
          left: 0,
          m: 2,
          zIndex: 1,
        }}
      >
        {collection.name}
      </Typography>
    </Box>
  );
};
