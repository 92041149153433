import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconOutlineInfoCircle } from 'components/icons/components/outline/IconOutlineInfoCircle';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import {
  TaskFragmentTaskContextMenuFragment,
  TaskPermission,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { theme } from 'styles/theme';
import { useTaskHandlers } from './useTaskHandlers';

export const TASK_FRAGMENT_TASK_CONTEXT_MENU = gql`
  fragment TaskFragmentTaskContextMenu on TaskModel {
    id
    myPermissions
  }
`;

export type TaskContextMenuProps = Omit<
  ContextMenuProps,
  'options' | 'renderButton'
> &
  Partial<Pick<ContextMenuProps, 'options' | 'renderButton'>> & {
    task: TaskFragmentTaskContextMenuFragment;
    onAfterTaskDeleted?: (taskId: string) => void;
  };

export const TaskContextMenu = (props: TaskContextMenuProps) => {
  const { task, onAfterTaskDeleted, ...rest } = props;

  const hasPermission = task.myPermissions.includes(TaskPermission.Delete);

  const { onDeleteTasks } = useTaskHandlers();
  const { dialog: deleteTaskDialog, onOpen: openDeleteTaskConfirmationDialog } =
    useConfirmationDialog();

  const menuOptions = [
    {
      title: `Delete Task`,
      icon: IconOutlineTrash,
      color: theme.colors?.primary.maroon,
      onClick: () =>
        openDeleteTaskConfirmationDialog({
          subtitle: `Once it removed, this task will no longer be accessible.`,
          onConfirm: () => {
            onDeleteTasks(task.id);
            onAfterTaskDeleted?.(task.id);
          },
        }),
      disabled: !hasPermission,
      closeOnClick: false,
    },
  ];

  return (
    <>
      <ContextMenu
        renderButton={() => (
          <IconButton>
            <IconLinearMore
              style={{
                color: theme.colors?.primary.black,
                transform: `rotate(90deg)`,
              }}
            />
          </IconButton>
        )}
        options={menuOptions.map((option) => {
          const Icon = option.icon;
          return {
            renderOption: () => (
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon size={16} color={option.color} />
                </Box>
                <Typography variant="subhead-lg" color={option.color}>
                  {option.title}
                </Typography>
              </Box>
            ),
            onClick: option.onClick,
            disabled: option.disabled,
            closeOnClick: option.closeOnClick,
          };
        })}
        MenuListProps={{
          sx: {
            maxWidth: 272,
          },
        }}
        renderExtraBottom={
          !hasPermission
            ? () => {
                return (
                  <Box
                    sx={{
                      mt: 3,
                      py: 4,
                      mx: 3,
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 3,
                      borderTop: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <IconOutlineInfoCircle
                      size={16}
                      style={{
                        color: theme.colors?.primary.maroon,
                        flexShrink: 0,
                      }}
                    />
                    <Typography variant="subhead-lg">
                      You do not have permission to delete this task.
                    </Typography>
                  </Box>
                );
              }
            : undefined
        }
        disableRestoreFocus
        {...rest}
      />
      {deleteTaskDialog}
    </>
  );
};
