import { Box, Button, Dialog, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { IconLinearArrowLeft } from 'components/icons/components/linear/IconLinearArrowLeft';
import { MemberInviteConfirmation } from 'features/organizationMembers/views/AddUsersToOrgView/sections/memberInviteConfirmation';
import { MemberUserRoleSelection } from 'features/organizationMembers/views/AddUsersToOrgView/sections/memberUserRoleSelection';
import { SocialListeningUsersInvited } from 'features/organizationMembers/views/AddUsersToOrgView/sections/socialListeningUsersInvited';
import { InternalOrganizationRole } from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  email: string;
  defaultRole: InternalOrganizationRole;
  onConfirm: (role: InternalOrganizationRole) => Promise<void>;

  isOpen: boolean;
  onClose: () => void;
};

enum ApprovalState {
  RoleSelection,
  ConfirmMemberAdd,
  SLRoleUpdated,
}

export const ApproveMemberDialog = ({
  isOpen,
  onClose,
  onConfirm,
  email,
  defaultRole,
}: Props) => {
  const [role, setRole] = useState(defaultRole);
  const [currentView, setCurrentView] = useState(ApprovalState.RoleSelection);

  useEffect(() => {
    return () => {
      if (!isOpen) {
        setRole(defaultRole);
        setCurrentView(ApprovalState.RoleSelection);
      }
    };
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: theme.spacing(6),
          border: 'none',
          backgroundColor: 'rgba(250, 243, 236, 0.8)',
          backdropFilter: 'blur(20px)',
        },
      }}
    >
      {currentView === ApprovalState.RoleSelection && (
        <Box sx={{ width: theme.spacing(125) }}>
          <Box
            sx={{
              backdropFilter: 'blur(20px)',
              p: 6,
            }}
          >
            <Typography
              variant="body-lg"
              fontWeight={600}
              sx={{ cursor: 'pointer', width: 'fit-content' }}
              onClick={onClose}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <IconLinearArrowLeft size={16} />
              Back
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={2}
              sx={{
                mt: 4,
                backgroundColor: 'rgba(35, 6, 3, 0.10)',
                p: theme.spacing(1, 2),
                borderRadius: theme.spacing(4),
                width: 'fit-content',
              }}
            >
              <Avatar
                size={16}
                user={{
                  id: '',
                  email,
                  firstName: '',
                  lastName: '',
                }}
              />
              <Typography variant="body-lg" fontWeight={600}>
                {email}
              </Typography>
            </Box>
          </Box>

          <Box
            p={6}
            sx={{
              minHeight: theme.spacing(100),
              backgroundColor: 'rgba(250, 243, 236, 0.3)',
            }}
          >
            <MemberUserRoleSelection
              userRoleData={{ [email]: role }}
              onRoleChange={(email, role) => {
                setRole(role as InternalOrganizationRole);
              }}
            />
          </Box>

          <Button
            fullWidth
            variant="contained"
            onClick={async () => {
              if (role === InternalOrganizationRole.SocialListeningUser) {
                await onConfirm(role);
                setCurrentView(ApprovalState.SLRoleUpdated);
              } else {
                setCurrentView(ApprovalState.ConfirmMemberAdd);
              }
            }}
            sx={{
              border: 'none',
              p: 3,
              backgroundColor: theme.colors?.primary.black,
              fontWeight: 600,
              color: theme.colors?.primary.white,
              '&:hover': {
                backgroundColor: theme.colors?.primary.black,
                fontWeight: 600,
                color: theme.colors?.primary.white,
              },
            }}
          >
            Add member
          </Button>
        </Box>
      )}

      {currentView === ApprovalState.ConfirmMemberAdd && (
        <MemberInviteConfirmation
          emails={[email]}
          customTitle="Are you sure you want to add member?"
          onConfirm={async () => {
            await onConfirm(role);
            onClose();
          }}
          onCancel={() => {
            setCurrentView(ApprovalState.RoleSelection);
          }}
        />
      )}

      {currentView === ApprovalState.SLRoleUpdated && (
        <SocialListeningUsersInvited
          emails={[email]}
          customTitle="Member Added ✅"
          onDone={() => {
            onClose();
          }}
        />
      )}
    </Dialog>
  );
};
