import { Box, Popover } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { ImageAnnotationInput } from 'components/common/Media/components/Image/types';
import { PdfAnnotationInput } from 'components/common/Media/components/Pdf/types';
import { VideoAnnotationInput } from 'components/common/Media/components/Video/types';
import { useNewCoordinates } from 'components/common/Media/hooks/useNewCoordinates';
import { RichTextEditorRef } from 'components/common/form/RichTextEditor/RichTextEditor';
import { useUserContext } from 'contexts/users/User.context';
import { CommentInput } from 'features/comments';
import {
  CommentInputData,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import {
  Bubble,
  StyledCommentContainer,
  StyledCommentInputWrapper,
  StyledCommentWrapper,
  editorStyle,
  inputStyle,
} from './styles';

interface RenderSelectorProps {
  x: number | null;
  y: number | null;
  onCancel: () => void;
  onCreateAnnotation: (
    annotation:
      | Omit<PdfAnnotationInput, 'pageNumber'>
      | ImageAnnotationInput
      | Omit<VideoAnnotationInput, 'time'>,
  ) => void;
  customScale?: number;
}

export const RenderSelector = ({
  x,
  y,
  customScale,
  onCancel,
  onCreateAnnotation,
}: RenderSelectorProps) => {
  const { newX, newY } = useNewCoordinates({ x, y, customScale });

  const { user } = useUserContext();
  const commentInputRef = useRef<RichTextEditorRef | null>(null);

  const [showPopover, setShowPopover] = useState(false);
  const anchorElRef = useRef<HTMLDivElement | null>(null);

  const onSubmit = (comment: CommentInputData) => {
    if (!x || !y) return null;
    onCreateAnnotation({
      comment,
      x,
      y,
    });

    commentInputRef.current?.clear();
  };

  useEffect(() => {
    if (x && y) {
      setTimeout(() => {
        setShowPopover(true);
      });
    }

    return () => {
      setShowPopover(false);
    };
  }, [x, y]);

  if (!x || !y) return null;

  return (
    <>
      <Box
        ref={anchorElRef}
        sx={{
          position: 'absolute',
          left: `${newX}px`,
          top: `${newY}px`,
        }}
      >
        <StyledCommentContainer>
          <Bubble className="annotation__icon">
            <Avatar
              size={24}
              user={user as unknown as UserFragmentAvatarGroupFragment}
            />
          </Bubble>
        </StyledCommentContainer>
      </Box>
      <Popover
        open={showPopover}
        anchorEl={anchorElRef.current}
        onClose={() => {
          setShowPopover(false);
          onCancel();
        }}
        PaperProps={{
          sx: {
            bgcolor: 'transparent',
            boxShadow: 'none',
            border: 'none',
          },
        }}
      >
        <Box sx={{ pt: '32px' }}>
          <StyledCommentWrapper>
            <StyledCommentInputWrapper>
              <CommentInput
                commentInputRef={commentInputRef}
                autoFocus
                inputStyle={inputStyle}
                editorStyle={editorStyle}
                onCreateComment={onSubmit}
                placeholder="Add a comment"
              />
            </StyledCommentInputWrapper>
          </StyledCommentWrapper>
        </Box>
      </Popover>
    </>
  );
};
