import { Box, styled } from '@mui/material';
import { TaskStatus } from 'graphql/generated';
import {
  taskStatusEventBackgroundColor,
  taskStatusEventTextColor,
} from 'features/task';
import { theme } from 'styles/theme/theme';

export const EventRendererWrapper = styled(Box)<{ taskStatus: TaskStatus }>(
  ({ taskStatus }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    height: '100%',
    gap: theme.spacing(2),
    color: taskStatusEventTextColor[taskStatus],
    background: taskStatusEventBackgroundColor[taskStatus],
    textDecoration: taskStatus === TaskStatus.Done ? 'line-through' : undefined,
    borderRadius: 4,
    minHeight: 50,
    overflow: 'hidden',
  }),
);
