import { Box, Input, styled } from '@mui/material';
import { typography } from 'components/common/Typography/styles';

export const StyledSearchBar = styled(Box)(({ theme }) => ({
  position: 'sticky',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  padding: theme.spacing(4),
}));

export const StyledInput = styled(Input)(({ theme }) => ({
  ...typography['headline-md'],
  flex: 1,
  color: theme.colors?.primary.black,
}));
