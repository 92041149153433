import { useDisclosure } from '@dwarvesf/react-hooks';
import { Button, IconButton } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { IconOutlineMaximize4 } from 'components/icons/components/outline/IconOutlineMaximize4';
import {
  PlotFeatureType,
  useRoleBasedUserAccessContext,
} from 'contexts/RoleBasedUserAccess.context';
import { OrganizationBillingFragmentTrendsPaywallFragment } from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingTrendsCTAButtonProps = {
  organizationBilling: OrganizationBillingFragmentTrendsPaywallFragment;
};

export const BillingTrendsCTAButton = (props: BillingTrendsCTAButtonProps) => {
  const { organizationBilling } = props;
  const isMobileView = useMediaQueryMobile();

  const { checkCanAccess } = useRoleBasedUserAccessContext();
  const canAccessTrends = checkCanAccess(PlotFeatureType.Trends);

  const {
    isOpen: upgradeOpen,
    onOpen: upgradeOnOpen,
    onClose: upgradeOnClose,
  } = useDisclosure();

  if (!canAccessTrends) {
    return (
      <Link to={PlotRoutes.juiceboxTrendingRequestAccess()} replace>
        <Button
          variant="text"
          startIcon={<IconBoldLock size={20} />}
          sx={{
            borderRadius: theme.spacing(2),
            backgroundColor: `rgba(35, 6, 3, 0.10)`,
            p: 2,
            px: 3,
            display: 'flex',
            alignItems: 'center',
            height: theme.spacing(9),
            minWidth: 'fit-content',
            color: theme.colors?.primary.black,
            ':hover': {
              backgroundColor: `rgba(35, 6, 3, 0.10)`,
            },
          }}
        >
          Request Access
        </Button>
      </Link>
    );
  }

  return organizationBilling.trendsEnabled ? (
    <Link to={PlotRoutes.juiceboxTrending()}>
      {isMobileView ? (
        <IconButton
          sx={{
            px: 0,
          }}
          disableRipple
        >
          <IconOutlineMaximize4 size={16} color={theme.colors?.primary.black} />
        </IconButton>
      ) : (
        <Button
          variant="text"
          endIcon={
            <IconOutlineMaximize4
              size={16}
              color={theme.colors?.primary.black}
            />
          }
          sx={{
            borderRadius: theme.spacing(2),
            backgroundColor: `rgba(35, 6, 3, 0.10)`,
            p: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            height: theme.spacing(9),
            minWidth: 'fit-content',
            color: theme.colors?.primary.black,
            ':hover': {
              backgroundColor: `rgba(35, 6, 3, 0.10)`,
            },
          }}
        >
          Open Trending
        </Button>
      )}
    </Link>
  ) : (
    <>
      <Button
        variant="text"
        endIcon={
          <IconOutlineMaximize4 size={16} color={theme.colors?.primary.black} />
        }
        sx={{
          borderRadius: theme.spacing(2),
          backgroundColor: `rgba(35, 6, 3, 0.10)`,
          py: 2,
          px: 4,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          height: theme.spacing(9),
          minWidth: 'fit-content',
          color: theme.colors?.primary.black,
          ':hover': {
            backgroundColor: `rgba(35, 6, 3, 0.10)`,
          },
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          upgradeOnOpen();
        }}
      >
        {organizationBilling.hasUsedFreeTrial
          ? 'Upgrade'
          : 'Upgrade or Start Free Trial'}
      </Button>
      <BillingUpgradeModal isOpen={upgradeOpen} onClose={upgradeOnClose} />
    </>
  );
};
