import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { SL_PERMISSION_REQUEST_KEY } from './consts';

export const useRequestBrandPermission = () => {
  const { value, setValue } = useSessionStorage<{
    brandId: string;
    brandName: string;
  } | null>(SL_PERMISSION_REQUEST_KEY, null);

  const onRequestAccess = (brandId: string, brandName: string) => {
    setValue({
      brandId,
      brandName,
    });
  };

  const clearRequestBrandPermission = () => {
    setValue(null);
  };

  return {
    onRequestAccess,
    clearRequestBrandPermission,
  };
};
