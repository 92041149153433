import { Box, BoxProps } from '@mui/material';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomPinterest } from 'components/icons/components/custom/IconCustomPinterest';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomX } from 'components/icons/components/custom/IconCustomX';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { IconOutlineLink21 } from 'components/icons/components/outline/IconOutlineLink21';
import { IconOutlineNote2 } from 'components/icons/components/outline/IconOutlineNote2';
import { IconOutlineVideo } from 'components/icons/components/outline/IconOutlineVideo';
import { resolveFileIcon } from 'utils/fileIcons';

/**
 * Returns the icon rendered at the top right of the juice card.
 * Definitely not being able to handle 100% of the cases right now, but let's see.
 */
export const getPostIconTypeName = (props: {
  site?: string;
  medium?: string;
  mimeType?: string;
  url?: string;
}) => {
  const { site, medium, mimeType, url } = props;

  // Prioritize mimeType
  if (mimeType) {
    if (/^image/.test(mimeType)) {
      return {
        icon: IconOutlineImage,
        type: 'Image',
      };
    }

    if (/^video/.test(mimeType.trim())) {
      return {
        icon: IconOutlineVideo,
        type: 'Video',
      };
    }
  }

  // Then site
  switch (site?.toLowerCase()) {
    case 'twitter': {
      return {
        icon: IconCustomX,
        type: 'Twitter',
      };
    }
    case 'instagram': {
      return {
        icon: IconCustomInstagram,
        type: 'Instagram',
      };
    }
    case 'pinterest': {
      return {
        icon: IconCustomPinterest,
        type: 'Pinterest',
      };
    }
    case 'tiktok': {
      return {
        icon: IconCustomTiktok,
        type: 'tiktok',
      };
    }
    default: {
      break;
    }
  }

  // Then medium
  switch (medium) {
    case 'video': {
      return {
        icon: IconOutlineVideo,
        type: 'Video',
      };
    }
    case 'image': {
      return {
        icon: IconOutlineImage,
        type: 'Image',
      };
    }
    case 'link': {
      return {
        icon: IconOutlineLink21,
        type: 'Link',
      };
    }
    case 'file': {
      // Resolve file icon with custom files icons we have
      const fileIcon = resolveFileIcon('', mimeType);
      if (fileIcon) {
        return {
          icon: (props: BoxProps & { size: number }) => (
            <Box
              component="img"
              src={fileIcon}
              {...props}
              sx={{
                width: props.size,
                height: props.size,
              }}
            />
          ),
          type: 'File',
        };
      }

      return {
        icon: IconOutlineNote2,
        type: 'File',
      };
    }
    default: {
      break;
    }
  }

  // Then url
  if (url) {
    if (url.includes('twitter')) {
      return {
        icon: IconCustomX,
        type: 'Twitter',
      };
    }

    if (url.includes('instagram')) {
      return {
        icon: IconCustomInstagram,
        type: 'Instagram',
      };
    }

    if (url.includes('pinterest')) {
      return {
        icon: IconCustomPinterest,
        type: 'Pinterest',
      };
    }

    if (url.includes('tiktok')) {
      return {
        icon: IconCustomTiktok,
        type: 'tiktok',
      };
    }
  }

  // Nothing matched, return default document icon
  return {
    icon: IconOutlineNote2,
    type: 'Note',
  };
};
