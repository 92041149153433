import { Box, IconButton, Typography } from '@mui/material';
import { IconBoldVolumeHigh } from 'components/icons/components/bold/IconBoldVolumeHigh';
import { IconBoldVolumeSlash } from 'components/icons/components/bold/IconBoldVolumeSlash';
import { IconBoldWarning2 } from 'components/icons/components/bold/IconBoldWarning2';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';

export type HoverPlayableVideoProps = {
  thumbnailUrl?: string;
  videoUrl: string;
  onPlay?: () => void;
  renderActionsTopRight?: (isHovered: boolean) => React.ReactNode;
  renderActionsBottom?: (isHovered: boolean) => React.ReactNode;
  renderExtraActionsTopLeft?: (isHovered: boolean) => React.ReactNode;
};

export const HoverPlayableVideo = ({
  thumbnailUrl,
  videoUrl,
  onPlay,
  renderActionsTopRight,
  renderActionsBottom,
  renderExtraActionsTopLeft,
}: HoverPlayableVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  const handleMouseEnter = () => {
    if (videoRef.current) {
      videoRef.current.play();
      onPlay && onPlay(); // eslint-disable-line
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.load();
    }
    setIsHovered(false);
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  // Programmatically load the thumbnail image to check if it's valid
  // because we don't have an event to listen to when the video poster image fails to load
  const [isThumbnailValid, setIsThumbnailValid] = useState(true);
  useEffect(() => {
    const img = new Image();
    img.src = thumbnailUrl || '';
    img.onload = () => setIsThumbnailValid(true);
    img.onerror = () => setIsThumbnailValid(false);
  }, [thumbnailUrl]);

  const errorRender = useMemo(() => {
    // If there's an error loading the video or the video URL is empty
    // 1. Render thumbnail if it's valid, otherwise
    // 2. Render error message
    if (hasError || !videoUrl) {
      if (isThumbnailValid) {
        return (
          <Box
            component="img"
            src={thumbnailUrl}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 4,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        );
      }

      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          minHeight={100}
          sx={{
            backgroundColor: theme.colors?.utility[400],
            borderRadius: 4,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <Typography
            variant="body-md"
            color={theme.colors?.utility[700]}
            fontWeight={600}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <IconBoldWarning2 size={16} />
            Error loading video
          </Typography>
        </Box>
      );
    }

    return null;
  }, [hasError, isThumbnailValid, thumbnailUrl, videoUrl]);

  return (
    <Box
      position="relative"
      width="100%"
      height="0"
      sx={{ paddingTop: '177.77%', overflow: 'hidden' }} // 56.25% for 16:9 aspect ratio
      {...(errorRender
        ? {}
        : {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
          })}
    >
      {errorRender || (
        <>
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            sx={{ borderRadius: 4, cursor: 'pointer', objectFit: 'cover' }}
            component="video"
            ref={videoRef}
            src={videoUrl}
            poster={isThumbnailValid ? thumbnailUrl : undefined}
            muted={isMuted}
            onError={handleError}
          />

          <Box
            position="absolute"
            top={theme.spacing(2)}
            left={theme.spacing(2)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Box display="flex" gap={2} alignItems="center">
              {renderExtraActionsTopLeft &&
                renderExtraActionsTopLeft(isHovered)}
              {isHovered && (
                <IconButton
                  onClick={toggleMute}
                  disableRipple
                  sx={{
                    p: 1,
                    color: theme.colors?.primary.white,
                  }}
                >
                  {isMuted ? (
                    <IconBoldVolumeSlash size={16} />
                  ) : (
                    <IconBoldVolumeHigh size={16} />
                  )}
                </IconButton>
              )}
            </Box>
          </Box>

          <Box
            position="absolute"
            top={theme.spacing(2)}
            right={theme.spacing(2)}
            minHeight={24}
          >
            {renderActionsTopRight && renderActionsTopRight(isHovered)}
          </Box>
          {renderActionsBottom && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                px: 2,
                pb: 2,
                pt: 6,
                borderRadius: `0 0 16px 16px`,

                // Linear gradient from bottom to top, black -> transparent
                backgroundImage:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
              }}
            >
              {renderActionsBottom(isHovered)}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
