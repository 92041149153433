import { Box, Grid, Typography } from '@mui/material';
import { MOBILE_WIDTH_MD } from 'constants/mediaSizes';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { Outlet } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { IconLogoPlotLogo } from '../icons/components/logo/IconLogoPlotLogo';
import { StyledAuthFormContainer, StyledImageContainer } from './styles';

export const AuthRoute = () => {
  const isMobileView = useMediaQueryMobile(MOBILE_WIDTH_MD);
  return (
    <Grid
      container
      spacing={0}
      sx={{ position: 'relative', background: '#230603FF' }}
    >
      {!isMobileView && (
        <Grid item xs={12} md={6} lg={5}>
          <StyledImageContainer>
            <Box
              className="auth-cover"
              component="video"
              autoPlay
              muted
              loop
              playsInline
              src="/auth/bg_video.mov"
            />
            <Box className="transparent-overlay" />
            {/* <Box className="title">
              <Typography
                variant="headline-xl"
                letterSpacing="-1px"
                lineHeight={theme.spacing(14)}
              >
                Jam on content <br /> wherever you are.
              </Typography>
              <Typography variant="subhead-xl">
                Store, collaborate, and organize content in Plot.
              </Typography>
            </Box> */}
          </StyledImageContainer>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={6}
        lg={7}
        sx={{ maxHeight: '100vh', overflow: 'auto' }}
      >
        <StyledAuthFormContainer
          flexDirection="column"
          gap={6}
          sx={{
            minHeight: '100vh',
            justifyContent: isMobileView ? 'flex-start' : 'center',
          }}
        >
          <IconLogoPlotLogo
            className="logo"
            color={theme.colors?.primary.white}
            style={{
              ...(!isMobileView
                ? {
                    position: 'absolute',
                    top: theme.spacing(10),
                    right: isMobileView ? '50%' : theme.spacing(10),
                  }
                : {
                    marginTop: theme.spacing(4),
                  }),
            }}
          />
          <StyledAuthFormContainer>
            <Box sx={{ width: '100%', maxWidth: '450px' }}>
              <Outlet />
            </Box>
          </StyledAuthFormContainer>
        </StyledAuthFormContainer>
      </Grid>
    </Grid>
  );
};
