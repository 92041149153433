import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconCustomPin } from 'components/icons/components/custom/IconCustomPin';
import { usePinnedEntityMutations } from 'features/pinnedEntity';
import { isPostPinnedToCollection } from 'features/post/utils';
import { PostFragmentPostPinFragment } from 'graphql/generated';
import React from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment PostFragmentPostPin on PostModel {
    id
    pinnedToCollections {
      id
      parentCollectionId
    }
  }
`;

type PostPinProps = {
  post: PostFragmentPostPinFragment;
  collectionId: string;
  componentProps?: {
    iconContainer?: {
      sx?: SxProps;
    };
    icon?: {
      size?: number;
    };
  };
};

export const PostPin = (props: PostPinProps) => {
  const { post, collectionId, componentProps } = props;

  const isPostPinToCollection = isPostPinnedToCollection(post, collectionId);

  const { onUnpinManyPostsFromCollection } = usePinnedEntityMutations();

  const handleUnpin = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onUnpinManyPostsFromCollection(collectionId, [post.id]);
  };

  return !isPostPinToCollection ? null : (
    <Tooltip title="Unpin">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1),
          width: componentProps?.icon?.size ?? 12,
          height: componentProps?.icon?.size ?? 12,
          backgroundColor: theme.colors?.utility[300],
          ...componentProps?.iconContainer?.sx,
        }}
        onClick={handleUnpin}
      >
        <IconCustomPin size={componentProps?.icon?.size ?? 12} />
      </Box>
    </Tooltip>
  );
};
