import { useDisclosure } from '@dwarvesf/react-hooks';
import { Dialog } from '@mui/material';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  CommandHandler,
  CommandHandlerBaseContext,
} from 'contexts/commands/types';
import {
  CollectionSendToCollectionForm,
  CollectionSendToCollectionFormInitialValues,
} from 'features/collection/forms/sendToCollection';
import { useEffect } from 'react';
import { theme } from 'styles/theme';

export type SendToCollectionHandlerContext = {
  initialValues: CollectionSendToCollectionFormInitialValues;
} & CommandHandlerBaseContext<COMMAND_TYPE.SEND_TO_COLLECTION>;

export const SendToCollectionHandler: CommandHandler<
  COMMAND_TYPE.SEND_TO_COLLECTION
> = (props) => {
  const { commandId, context: { initialValues: { posts = [] } = {} } = {} } =
    props;

  const { getActiveCommandById, updateActiveCommand } = useCommandContext();
  const command = getActiveCommandById(commandId);

  const {
    isOpen: isSendToCollectionDialogOpen,
    onOpen: openSendToCollectionDialog,
    onClose: closeSendToCollectionDialog,
  } = useDisclosure();

  const onClose = async () => {
    closeSendToCollectionDialog();
    updateActiveCommand(commandId, { status: 'completed' });
  };

  useEffect(() => {
    if (command?.status === 'triggered') {
      openSendToCollectionDialog();
      updateActiveCommand(commandId, { status: 'processing' });
    }
  }, [command]); // eslint-disable-line -- triger flow on mount

  if (!isSendToCollectionDialogOpen) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          position: 'relative',
          width: '100%',
          maxWidth: 700,
          minWidth: 400,
          transition: 'all 0.2s',
          backgroundColor: theme.colors?.utility['purple-1'],
          borderRadius: 6,
        },
      }}
    >
      <CollectionSendToCollectionForm
        initialValues={{ posts }}
        sx={{ height: 480, padding: theme.spacing(0, 8) }}
      />
    </Dialog>
  );
};
