import { Typography } from '@mui/material';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';
import { Avatar } from 'components/common/AvatarGroup';
import { StyledCellContainer } from 'features/search/views/modal/styles';
import React from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { SelectionList } from '../SelectionList';
import { UserListSkeleton } from './UserListSkeleton';
import { StyledCell } from './styles';

export interface UserSearchListProps {
  data: UserFragmentAvatarGroupFragment[];
  onSelect?: (item: UserFragmentAvatarGroupFragment) => void;
  renderEmpty?: () => React.ReactElement;
  loading?: boolean;
}

export const UserSearchList = React.memo(
  ({ data, onSelect, renderEmpty, loading }: UserSearchListProps) => {
    if (loading) {
      return <UserListSkeleton />;
    }

    if (!Array.isArray(data) || data.length === 0) {
      return renderEmpty ? renderEmpty() : null;
    }

    return (
      <SelectionList
        data={data}
        onSelectItem={(item: any) => {
          onSelect?.(item);
        }}
        renderItem={({ item, idx, props }) => {
          return (
            <StyledCellContainer
              key={idx}
              {...props}
              className="mention-user-container"
            >
              <StyledCell gap={2} className="mention-user-cell">
                <Avatar user={item} size={20} />
                <Typography variant="headline-xs" fontSize={13}>
                  {getFullName(item)}
                </Typography>
                <Typography
                  variant="subhead-sm"
                  color={theme.colors?.utility[700]}
                >
                  {item.email}
                </Typography>
              </StyledCell>
            </StyledCellContainer>
          );
        }}
      />
    );
  },
);
