import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  NoteRichTextEditor,
  NoteRichTextEditorProps,
  NoteRichTextEditorRef,
  NoteTitle,
  NoteTitleProps,
  getNoteColorFromColor,
} from 'features/note';
import { POST_FRAGMENT_NOTE_COLOR } from 'features/note/components/color';
import { POST_FRAGMENT_NOTE_TITLE } from 'features/note/components/title';
import { NOTE_COLORS } from 'features/note/constants/noteColors';
import { PostFragmentNoteViewFragment } from 'graphql/generated';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_NOTE_VIEW = gql`
  fragment PostFragmentNoteView on PostModel {
    id
    ...PostFragmentNoteTitle
    ...PostFragmentNoteColor
    body
    myPermissions
  }
  ${POST_FRAGMENT_NOTE_TITLE}
  ${POST_FRAGMENT_NOTE_COLOR}
`;

interface NoteViewProps {
  note: PostFragmentNoteViewFragment;
  componentsProps?: {
    title?: Omit<NoteTitleProps, 'note'>;
    content?: Omit<NoteRichTextEditorProps, 'note'>;
  };
}

export interface NoteViewRef {
  title: HTMLTextAreaElement | null;
  content: NoteRichTextEditorRef | null;
  focusTitle: HTMLTextAreaElement['focus'];
  focusContent: NoteRichTextEditorRef['focus'];
}

export const NoteView = forwardRef<NoteViewRef, NoteViewProps>(
  ({ note, componentsProps = {} }, ref) => {
    const { id = '' } = useParams();

    const titleRef = useRef<HTMLTextAreaElement>(null);
    const contentRef = useRef<NoteRichTextEditorRef>(null);

    useImperativeHandle<NoteViewRef, NoteViewRef>(ref, () => ({
      focusTitle: (options) => {
        titleRef.current?.focus(options);
      },
      focusContent: (...props) => {
        contentRef.current?.focus(...props);
      },
      get title() {
        return titleRef.current;
      },
      get content() {
        return contentRef.current;
      },
    }));

    const themeColor = useMemo(() => {
      return getNoteColorFromColor(note.color) || NOTE_COLORS[0];
    }, [note]);

    return (
      <Box
        className="note-view"
        sx={{
          px: 6,
          mt: 'auto',
          mb: 'auto',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
          '.note-view-title': {
            width: '100%',
            maxWidth: 640,
          },
        }}
        gap={2}
      >
        <Box
          className="note-view-title"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <NoteTitle
            note={note}
            ref={titleRef}
            placeholder="Don't overthink it..."
            autoFocus={!!componentsProps.title?.autoFocus}
            onKeyDown={componentsProps.title?.onKeyDown}
            style={{
              main: {
                ...theme.typography['headline-xl'],
                backgroundColor: 'transparent',
                borderStyle: 'none',
                borderColor: 'transparent',
                overflow: 'auto',
                outline: 'none',
                color: themeColor.textColor,
                resize: 'none',
                padding: 0,
              },
              placeholder: {
                color: theme.colors?.utility[600],
              },
            }}
          />
        </Box>

        <NoteRichTextEditor
          key={id}
          className="note-view-body"
          ref={contentRef}
          defaultContent={note.body}
          themeColor={themeColor}
          placeholder="A place to draft your juicy ideas. Press '/' for commands..."
          {...componentsProps.content}
        />
      </Box>
    );
  },
);
