import { TaskPriority, TaskStatus } from 'graphql/generated';
import { theme } from 'styles/theme/theme';

export const taskStatusBackgroundColor = {
  [TaskStatus.ToDo]: theme.colors?.primary.white,
  [TaskStatus.InProgress]: theme.colors?.utility['yellow-2'],
  [TaskStatus.InReview]: theme.colors?.utility['eggplant'],
  [TaskStatus.Done]: theme.colors?.utility['green-2'],
  [TaskStatus.Archived]: theme.colors?.utility[500],
};

export const taskStatusEventBackgroundColor = {
  [TaskStatus.ToDo]: theme.colors?.utility[300],
  [TaskStatus.InProgress]: theme.colors?.utility['yellow-1'],
  [TaskStatus.InReview]: theme.colors?.utility['purple-1'],
  [TaskStatus.Done]: theme.colors?.utility['green-1'],
  [TaskStatus.Archived]: theme.colors?.utility[500],
};

export const taskStatusTextColor = {
  [TaskStatus.ToDo]: theme.colors?.utility[1000],
  [TaskStatus.InProgress]: theme.colors?.primary.white,
  [TaskStatus.InReview]: theme.colors?.primary.white,
  [TaskStatus.Done]: theme.colors?.primary.white,
  [TaskStatus.Archived]: theme.colors?.primary.white,
};
export const taskStatusEventTextColor = {
  [TaskStatus.ToDo]: theme.colors?.utility[800],
  [TaskStatus.InProgress]: theme.colors?.utility['yellow-4'],
  [TaskStatus.InReview]: theme.colors?.utility['purple-4'],
  [TaskStatus.Done]: theme.colors?.utility['green-4'],
  [TaskStatus.Archived]: theme.colors?.utility[900],
};
export const taskStatusParentEventTextColor = {
  [TaskStatus.ToDo]: theme.colors?.utility[600],
  [TaskStatus.InProgress]: theme.colors?.utility['yellow-3'],
  [TaskStatus.InReview]: theme.colors?.utility['purple-3'],
  [TaskStatus.Done]: theme.colors?.utility['green-3'],
  [TaskStatus.Archived]: theme.colors?.utility[900],
};

export const taskPriorityText = {
  [TaskPriority.High]: '!!!',
  [TaskPriority.Medium]: '!!',
  [TaskPriority.Low]: '!',
};

export const taskPriorityBackgroundColor = {
  [TaskPriority.High]: theme.colors?.utility['pink-1'],
  [TaskPriority.Medium]: theme.colors?.utility['yellow-1'],
  [TaskPriority.Low]: theme.colors?.utility[400],
};

export const taskPriorityTextColor = {
  [TaskPriority.High]: theme.colors?.utility['pink-4'],
  [TaskPriority.Medium]: theme.colors?.utility['yellow-3'],
  [TaskPriority.Low]: theme.colors?.utility[800],
};

export * from './groups';
