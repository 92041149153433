import {
  PostType,
  SearchClickedResultFragmentSearchRecentFragment,
  SearchableEntityType,
} from 'graphql/generated';

export const getSearchableEntityTypeForPostType = (type: PostType) => {
  switch (type) {
    case PostType.Note:
      return SearchableEntityType.Notes;
    case PostType.Link:
      return SearchableEntityType.Links;
    case PostType.Attachment:
      return SearchableEntityType.Attachments;
    default:
      return undefined;
  }
};
