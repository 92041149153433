import { Box, Typography } from '@mui/material';
import {
  SearchListItemView,
  getSearchableEntityTypeForPostType,
} from 'features/search';
import {
  AttachmentSearchHitFragmentListItemViewFragment,
  CollectionSearchHitFragmentListItemViewFragment,
  LinkSearchHitFragmentListItemViewFragment,
  NoteSearchHitFragmentListItemViewFragment,
  PostFragmentPostMasonryCardViewFragment,
  PostType,
  SearchClickedResultFragmentSearchRecentFragment,
  SearchHitType,
  SearchableEntityType,
  TaskSearchHitFragmentListItemViewFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme/theme';

export interface SearchRecentProps {
  recents: SearchClickedResultFragmentSearchRecentFragment[];
  elRefs: React.MutableRefObject<(HTMLElement | null)[]>;
  recentlyAddedPosts: PostFragmentPostMasonryCardViewFragment[];
}

export const SearchRecent = (props: SearchRecentProps) => {
  const { recents, elRefs, recentlyAddedPosts } = props;

  let itemIndex = 0;

  return (
    <Box display="flex" flexDirection="column" flex={1} overflow="hidden">
      <Typography
        mx={4}
        mb={2}
        variant="subhead-sm"
        color={theme.colors?.utility[700]}
      >
        Recently viewed
      </Typography>
      {recents.map((recent) => {
        if (recent.post && recent.post.type === PostType.Note) {
          const searchHit: NoteSearchHitFragmentListItemViewFragment = {
            item: {
              ...recent.post,
            },
            score: 0,
            type: SearchHitType.Regular,
          };
          return (
            <SearchListItemView
              searchHit={searchHit}
              type={SearchableEntityType.Notes}
              ref={(el) => {
                elRefs.current[itemIndex] = el;
                itemIndex++;
              }}
            />
          );
        }
        if (recent.post && recent.post.type === PostType.Link) {
          const searchHit: LinkSearchHitFragmentListItemViewFragment = {
            item: {
              ...recent.post,
            },
            score: 0,
            type: SearchHitType.Regular,
          };
          return (
            <SearchListItemView
              searchHit={searchHit}
              type={SearchableEntityType.Links}
              ref={(el) => {
                elRefs.current[itemIndex] = el;
                itemIndex++;
              }}
            />
          );
        }
        if (recent.post && recent.post.type === PostType.Attachment) {
          const searchHit: AttachmentSearchHitFragmentListItemViewFragment = {
            item: {
              ...recent.post,
            },
            score: 0,
            type: SearchHitType.Regular,
          };
          return (
            <SearchListItemView
              searchHit={searchHit}
              type={SearchableEntityType.Attachments}
              ref={(el) => {
                elRefs.current[itemIndex] = el;
                itemIndex++;
              }}
            />
          );
        }
        if (recent.task) {
          const searchHit: TaskSearchHitFragmentListItemViewFragment = {
            item: {
              ...recent.task,
            },
            score: 0,
            type: SearchHitType.Regular,
          };
          return (
            <SearchListItemView
              searchHit={searchHit}
              type={SearchableEntityType.Tasks}
              ref={(el) => {
                elRefs.current[itemIndex] = el;
                itemIndex++;
              }}
            />
          );
        }
        if (recent.collection) {
          const searchHit: CollectionSearchHitFragmentListItemViewFragment = {
            item: {
              ...recent.collection,
            },
            score: 0,
            type: SearchHitType.Regular,
          };
          return (
            <SearchListItemView
              searchHit={searchHit}
              type={SearchableEntityType.Collections}
              ref={(el) => {
                elRefs.current[itemIndex] = el;
                itemIndex++;
              }}
            />
          );
        }
      })}

      {recentlyAddedPosts.length > 0 && (
        <>
          <Typography
            mx={4}
            my={2}
            mb={2}
            variant="subhead-sm"
            color={theme.colors?.utility[700]}
          >
            Recently added
          </Typography>
          <Box display="flex" width="100%" sx={{ overflowX: 'auto' }}>
            {recentlyAddedPosts.map((post) => {
              const searchHit:
                | NoteSearchHitFragmentListItemViewFragment
                | LinkSearchHitFragmentListItemViewFragment
                | AttachmentSearchHitFragmentListItemViewFragment = {
                item: {
                  ...post,
                },
                score: 0,
                type: SearchHitType.Regular,
              };
              const type = getSearchableEntityTypeForPostType(post.type);
              return (
                type && (
                  <SearchListItemView
                    searchHit={searchHit}
                    type={type}
                    variant="preview"
                    ref={(el) => {
                      elRefs.current[itemIndex] = el;
                      itemIndex++;
                    }}
                  />
                )
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
