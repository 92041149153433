import { Box } from '@mui/material';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { CircularProgressWithTrack } from 'components/common/CircularProgress/CircularProgressWithTrack';
import { useUploadCare } from 'hooks/useUploadCare';
import React, { useEffect, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { getMimeTypeFromUrl } from 'utils/mime';
import { ATTR_BLOCK_ID, ATTR_POST_ID } from '../constants';
import { Caption, HoverMenu } from './components';
import { Resizers } from './components/Resizers';
import { mediaActions } from './constants';
import {
  DefaultRenderer,
  ImageRenderer,
  PostRenderer,
  VideoRenderer,
} from './renderers';

/*
  - This component is responsible for rendering and handling the interactions of an embed file node in the editor.
    File here means anything other than image and video.
  - It also displays a menu with actions like commenting, changing back to text, and opening the link.
*/
export const EmbedNodeView = (props: NodeViewProps) => {
  const { node, extension, updateAttributes } = props;

  const uploadFile = useUploadCare();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [placeholderSrc, setPlaceholderSrc] = useState('');

  useEffect(() => {
    if (node.attrs.file) {
      setIsUploading(true);

      // If file is an image, show a placeholder
      if (node.attrs.file.type?.startsWith('image')) {
        setPlaceholderSrc(URL.createObjectURL(node.attrs.file));
      }

      uploadFile?.(node.attrs.file, node.attrs.file.name, (progressInfo) => {
        if (progressInfo.isComputable) {
          setUploadProgress(progressInfo.value);
        }
      })
        .then((response) => {
          if (response.cdnUrl) {
            updateAttributes({
              src: `${response.cdnUrl}${response.name}`,
              file: null,
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  }, [node.attrs.file]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateMediaActionActiveStates = React.useCallback(() => {
    const activeStates: Record<string, boolean> = {};

    mediaActions.forEach(({ tooltip, isActive }) => {
      activeStates[tooltip] = !!isActive?.(node.attrs);
    });
  }, [node.attrs]);

  useEffect(() => {
    calculateMediaActionActiveStates();
  }, [calculateMediaActionActiveStates, node.attrs]);

  const renderEmbedContent = () => {
    if (!node.attrs.src && placeholderSrc) {
      return (
        <Box
          component="img"
          src={placeholderSrc}
          sx={{
            maxWidth: '100%',
          }}
        />
      );
    }

    const mimeType = getMimeTypeFromUrl(node.attrs.src || '');

    if (/^image/.test(mimeType)) {
      return <ImageRenderer src={node.attrs.src} />;
    }

    if (/^video/.test(mimeType.trim())) {
      return <VideoRenderer src={node.attrs.src} />;
    }

    if (node.attrs[ATTR_POST_ID]) {
      return <PostRenderer postId={node.attrs[ATTR_POST_ID]} />;
    }

    return (
      <DefaultRenderer
        key={node.attrs.src}
        src={node.attrs.src}
        id={node.attrs[ATTR_BLOCK_ID]}
      />
    );
  };

  return (
    <NodeViewWrapper
      {...node.attrs}
      as="div"
      className="embed-node-view"
      style={{
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '150ms',
        animationTimingFunction: 'ease-in-out',
        width: '100%',
      }}
      key={node.attrs.src}
    >
      <Box
        sx={{
          position: 'relative',
          transitionProperty: 'all',
          transitionDuration: '.15s',
          transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
          padding: theme.spacing(
            3,
            3,
            extension.options.captionable ? 6 : 3,
            3,
          ),
          '.menu-container, .embed-resizer': {
            opacity: 0,
            transition: 'opacity 0.3s',
          },
          '.caption-container': {
            opacity: node.attrs.caption ? 1 : 0,
            transition: 'opacity 0.3s',
          },
          '&:hover': {
            '.caption-container': {
              opacity:
                !extension.options.editable && !node.attrs.caption ? 0 : 1,
            },
            '.menu-container, .embed-resizer': {
              opacity: 1,
            },
          },
        }}
      >
        {renderEmbedContent()}
        {isUploading && (
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              minHeight: !placeholderSrc ? 150 : 'unset',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0, 0, 0, 0.5)',
            }}
          >
            <CircularProgressWithTrack size={64} value={uploadProgress * 100} />
          </Box>
        )}
        {extension.options.captionable && <Caption {...props} />}
        <HoverMenu {...props} isUploading={isUploading} />
        {extension.options.editable && <Resizers {...props} />}
      </Box>
    </NodeViewWrapper>
  );
};
