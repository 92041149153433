import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Collapse, SxProps, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { PostSectionHeaderView } from 'features/post/views/sectionHeader/PostSectionHeaderView';
import { TaskPriorityLabel } from 'features/task/components';
import {
  getTaskStatusIcon,
  getTaskStatusText,
} from 'features/task/components/status/utils';
import { FALLBACK_UNGROUP_ID } from 'features/task/constants';
import {
  PostFragmentPostSectionHeaderViewFragmentDoc,
  TaskFilters,
  TaskFragmentTaskListItemViewFragment,
  TaskGroupBy,
  TaskPriority,
  TaskStatus,
  UserFragmentAvatarGroupFragmentDoc,
  useGetPostForTaskListWithKeyboardShortcutsGroupListItemViewQuery,
  useGetUserProfileForTaskListWithKeyboardShortcutsGroupListItemViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import {
  TaskListWithKeyboardShortcutsListView,
  TaskListWithKeyboardShortcutsListViewProps,
} from '../list';

export type TaskListWithKeyboardShortcutsGroupListItemViewProps = {
  groupId: string;
  groupBy: TaskGroupBy;
  taskFilters?: TaskFilters;
  sx?: SxProps;
  componentsProps?: {
    taskList?: Pick<
      TaskListWithKeyboardShortcutsListViewProps,
      'componentsProps'
    >;
  };
  onTaskListChange?: (tasks: TaskFragmentTaskListItemViewFragment[]) => void;
} & Pick<TaskListWithKeyboardShortcutsListViewProps, 'onFocusedTaskChange'>;

export const TaskListWithKeyboardShortcutsGroupListItemView = (
  props: TaskListWithKeyboardShortcutsGroupListItemViewProps,
) => {
  const {
    groupId,
    groupBy,
    sx,
    taskFilters,
    componentsProps = {},
    onTaskListChange,
    onFocusedTaskChange,
  } = props;

  const { isOpen: isExpanded, onToggle: toggleExpanded } = useDisclosure({
    defaultIsOpen: true,
  });

  const taskListFilters: TaskFilters = useMemo(() => {
    let filters: TaskFilters = {
      ...taskFilters,
    };

    switch (groupBy) {
      case TaskGroupBy.Priority: {
        filters = {
          ...filters,
          priorities: [groupId as TaskPriority],
        };

        break;
      }
      case TaskGroupBy.DueDate: {
        filters = {
          ...filters,
          endDate: {
            equals: groupId,
          },
        };

        break;
      }
      case TaskGroupBy.Status: {
        filters = {
          ...filters,
          statuses: [groupId as TaskStatus],
        };

        break;
      }
      case TaskGroupBy.Post: {
        filters = {
          ...filters,
          postIds: [groupId],
        };

        break;
      }
      case TaskGroupBy.Owner: {
        filters = {
          ...filters,
          memberIds: [groupId],
        };

        break;
      }
      default: {
        break;
      }
    }

    return filters;
  }, [groupId, groupBy, taskFilters]);

  const groupHeaderRender = useMemo(() => {
    switch (groupBy) {
      case TaskGroupBy.Priority: {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {groupId === FALLBACK_UNGROUP_ID ? (
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[700]}
              >
                No Priority
              </Typography>
            ) : (
              <>
                <TaskPriorityLabel
                  priority={groupId as TaskPriority}
                  useShortLabel
                  sx={{
                    p: 0,
                    span: {
                      width: 24,
                      height: 24,
                      lineHeight: `24px`,
                      fontSize: `16px !important`,
                    },
                  }}
                />
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.utility[700]}
                >
                  {groupId}
                </Typography>
              </>
            )}
          </Box>
        );
      }
      case TaskGroupBy.DueDate: {
        return (
          <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
            {groupId === FALLBACK_UNGROUP_ID
              ? 'No Due Date'
              : moment(groupId).format('ddd • MMM DD, YYYY')}
          </Typography>
        );
      }
      case TaskGroupBy.Status: {
        const icon = getTaskStatusIcon(groupId as TaskStatus);

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <icon.Icon size={16} color={icon.color} />
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[700]}
            >
              {getTaskStatusText(groupId as TaskStatus)}
            </Typography>
          </Box>
        );
      }
      case TaskGroupBy.Post: {
        return <PostGroupHeader postId={groupId} />;
      }
      case TaskGroupBy.Owner: {
        return <AssigneeGroupHeader assigneeId={groupId} />;
      }
      default: {
        return groupId;
      }
    }
  }, [groupId, groupBy]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        component="button"
        type="button"
        onClick={toggleExpanded}
        sx={{
          display: 'flex',
          gap: 1.5,
          alignItems: 'flex-start',
          px: 6,
        }}
      >
        <Box sx={{ pt: 0.75 }}>
          {isExpanded ? (
            <IconLinearArrowDown size={16} />
          ) : (
            <IconLinearArrowRight size={16} />
          )}
        </Box>
        {groupHeaderRender}
      </Box>
      <Collapse in={isExpanded} sx={{ ml: 5.5 }}>
        <TaskListWithKeyboardShortcutsListView
          filters={taskListFilters}
          renderEmptyState={() => <></>}
          shortcuts={{
            t: false,
          }}
          onTaskListChange={onTaskListChange}
          onFocusedTaskChange={onFocusedTaskChange}
          {...componentsProps.taskList}
        />
      </Collapse>
    </Box>
  );
};

/**
 * Custom header components for the task groups.
 * NOTE: We will want to move them to somewhere else. But for now let's keep them here.
 * They are basically just a wrapper with a query to get the needed data to render the header.
 */

// eslint-disable-next-line
gql`
  query GetPostForTaskListWithKeyboardShortcutsGroupListItemView($id: String!) {
    post(id: $id) {
      id
      ...PostFragmentPostSectionHeaderView
    }
  }
  ${PostFragmentPostSectionHeaderViewFragmentDoc}
`;

const PostGroupHeader = (props: { postId: string }) => {
  const { postId } = props;

  const { data } =
    useGetPostForTaskListWithKeyboardShortcutsGroupListItemViewQuery({
      variables: {
        id: postId,
      },
      skip: postId === FALLBACK_UNGROUP_ID,
    });
  const post = data?.post;

  if (postId === FALLBACK_UNGROUP_ID) {
    return (
      <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
        No Post
      </Typography>
    );
  }

  if (!post) {
    return <>{postId}</>;
  }

  return <PostSectionHeaderView post={post} previewAlignment="left" />;
};

// eslint-disable-next-line
gql`
  query GetUserProfileForTaskListWithKeyboardShortcutsGroupListItemView(
    $id: String!
  ) {
    userProfile(id: $id) {
      id
      ...UserFragmentAvatarGroup
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

const AssigneeGroupHeader = (props: { assigneeId: string }) => {
  const { assigneeId } = props;

  const { data } =
    useGetUserProfileForTaskListWithKeyboardShortcutsGroupListItemViewQuery({
      variables: {
        id: assigneeId,
      },
      skip: assigneeId === FALLBACK_UNGROUP_ID,
    });
  const assignee = data?.userProfile;

  if (assigneeId === FALLBACK_UNGROUP_ID) {
    return (
      <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
        No Assignee
      </Typography>
    );
  }

  if (!assignee) {
    return <>{assigneeId}</>;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <Avatar user={assignee} size={24} />
      <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
        {getFullName(assignee)}
      </Typography>
    </Box>
  );
};
