/**
 * This extension adds a plugin to handle parsing files from clipboard,
 * uploading them to Uploadcare, and create Embed nodes with the uploaded urls.
 */

import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { EXTENSION_FILE_PASTE, NODE_EMBED } from '../constants';

export type FilePasteOptions = {};

export const FilePaste = Extension.create<FilePasteOptions>({
  name: EXTENSION_FILE_PASTE,

  defaultOptions: {},

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste(view, event) {
            const items = Array.from(event.clipboardData?.items || []);

            if (items.length === 0) {
              return false;
            }

            event.preventDefault();

            items.forEach(async (item) => {
              const file = item.getAsFile();

              if (file) {
                const node = view.state.schema.nodes[NODE_EMBED].create({
                  file,
                });

                const transaction = view.state.tr.replaceSelectionWith(node);

                view.dispatch(transaction);
              }
            });

            return false;
          },
        },
      }),
    ];
  },
});
