import { gql } from '@apollo/client';
import { TextareaAutosizeProps, Typography } from '@mui/material';
import { TextareaAutosize } from 'components/common/form/TextareaAutosize';
import {
  CollectionFragmentCollectionNameFragment,
  useUpdateCollectionForCollectionNameMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef } from 'react';

export const COLLECTION_FRAGMENT_COLLECTION_NAME = gql`
  fragment CollectionFragmentCollectionName on CollectionModel {
    id
    name
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCollectionForCollectionName($data: UpdateCollectionInput!) {
    updateCollection(data: $data) {
      ...CollectionFragmentCollectionName
    }
  }
  ${COLLECTION_FRAGMENT_COLLECTION_NAME}
`;

export type CollectionNameProps = TextareaAutosizeProps & {
  collection: CollectionFragmentCollectionNameFragment;
  readOnly?: boolean;
  saveOnBlur?: boolean;
};

export const CollectionName = forwardRef(
  (props: CollectionNameProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const {
      collection,
      readOnly,
      saveOnBlur,
      style,
      autoFocus,
      onBlur,
      ...rest
    } = props;

    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const [updateCollection] = useUpdateCollectionForCollectionNameMutation();

    useEffect(() => {
      if (autoFocus && textareaRef.current) {
        textareaRef.current.focus();
        const length = textareaRef.current.value.length;
        textareaRef.current.setSelectionRange(length, length);
      }
    }, [autoFocus, textareaRef]);

    const onChange = useMemo(
      () =>
        debounce((e) => {
          updateCollection({
            variables: {
              data: {
                collectionId: collection.id,
                data: {
                  name: e.target.value,
                },
              },
            },
          });
        }, 300),
      [collection, updateCollection],
    );

    const handleOnBlur = (e) => {
      if (saveOnBlur) {
        updateCollection({
          variables: {
            data: {
              collectionId: collection.id,
              data: {
                name: e.target.value,
              },
            },
          },
        });
      }
      onBlur?.(e);
    };

    return readOnly ? (
      <Typography sx={style}>{collection.name}</Typography>
    ) : (
      <TextareaAutosize
        ref={(r) => {
          textareaRef.current = r;
          if (ref) {
            if (typeof ref === 'function') {
              ref(r);
            } else {
              ref.current = r;
            }
          }
        }}
        autoFocus={autoFocus}
        defaultValue={collection.name}
        style={{
          overflow: 'hidden',
          ...style,
        }}
        onChange={!saveOnBlur ? onChange : () => {}}
        variant="inline"
        onBlur={handleOnBlur}
        {...rest}
      />
    );
  },
);
