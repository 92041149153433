import { Box } from '@mui/material';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { GENERAL_ERROR_MESSAGE } from 'constants/error';

export const MediaErrorBoundary = (
  props: React.PropsWithChildren<{
    hiddenOnError?: boolean;
    getErrorMessage?: (
      error: Error,
      errorInfo: React.ErrorInfo,
    ) => React.ReactNode;
  }>,
) => {
  const { children, hiddenOnError, getErrorMessage: _getErrorMessage } = props;

  // Handle these errors manually
  const getErrorMessage =
    _getErrorMessage ||
    ((error: Error) => {
      // pdf-js legacy browser error
      if (error.message.includes('react-pdf')) {
        return (
          <Box textAlign="center">
            Current browser might be too old to support our PDF rendering.
            <br />
            Please try again with a newer browser!
          </Box>
        );
      }

      return GENERAL_ERROR_MESSAGE;
    });

  return (
    <ErrorBoundary
      getErrorMessage={getErrorMessage}
      disableReload
      hiddenOnError={hiddenOnError}
      sx={{ width: '100%', height: '100%' }}
    >
      {children}
    </ErrorBoundary>
  );
};
