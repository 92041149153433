import React from 'react';
import {
  SearchListItemView,
  SearchListItemViewSearchHit,
} from 'features/search';
import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { PostModel, SearchableEntityType } from 'graphql/generated';

type Props = {
  searchString: string;
  searchHits: SearchListItemViewSearchHit[];
  elRefs: React.MutableRefObject<(HTMLElement | null)[]>;
};

export const TopSearchResults = (props: Props) => {
  const { searchHits, elRefs, searchString } = props;
  let elIndex = 0;

  const getSearchHitEntityType = (searchHit: SearchListItemViewSearchHit) => {
    if (searchHit.item.__typename === 'PostModel') {
      const post = searchHit.item as PostModel;

      if (post.title) {
        return SearchableEntityType.Notes;
      }

      if (post.urlMetadata) {
        return SearchableEntityType.Links;
      }

      if (post.attachment) {
        return SearchableEntityType.Attachments;
      }
    }

    if (searchHit.item.__typename === 'TaskModel') {
      return SearchableEntityType.Tasks;
    }

    if (searchHit.item.__typename === 'CollectionModel') {
      return SearchableEntityType.Collections;
    }

    return SearchableEntityType.Posts;
  };

  return props.searchHits.length === 0 ? null : (
    <>
      <Box
        px={3}
        my={2}
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
      >
        <Typography
          color={theme.colors?.utility[700]}
          fontWeight={600}
          variant="body-xs"
        >
          Top Results
        </Typography>
      </Box>

      {searchHits.map((searchHit) => (
        <SearchListItemView
          key={searchHit.item.id}
          ref={(el) => {
            elRefs.current[elIndex] = el;
            elIndex++;
          }}
          searchHit={searchHit}
          variant="list"
          type={getSearchHitEntityType(searchHit)}
          searchString={searchString}
        />
      ))}
    </>
  );
};
