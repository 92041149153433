import { OrganizationBillingFragmentOrganizationMemberPaywallFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { Box, SxProps, Typography } from '@mui/material';
import { ProgressBar } from 'components/common/ProgressBar';
import { useMemo } from 'react';
import { Parser } from 'utils/parser';
import { IconCustomInfinity } from 'components/icons/components/custom/IconCustomInfinity';

type BillingOrganizationMemberLimitProgressProps = {
  organizationBilling: OrganizationBillingFragmentOrganizationMemberPaywallFragment;
  filledColor?: string;
  sx?: SxProps;
};

export const BillingOrganizationMemberLimitProgress = (
  props: BillingOrganizationMemberLimitProgressProps,
) => {
  const { organizationBilling, filledColor, sx } = props;

  const shouldDisplay = useMemo(() => {
    return (
      organizationBilling.organizationMemberLimit !== undefined &&
      organizationBilling.organizationMemberLimit !== null &&
      organizationBilling.organizationMemberLimit > 1
    );
  }, [organizationBilling.organizationMemberLimit]);

  const percentageUsed = useMemo(() => {
    return organizationBilling.organizationMemberLimit
      ? Parser.to2Decimal(
          (organizationBilling.organizationMemberUsage /
            organizationBilling.organizationMemberLimit) *
            100,
        )
      : 0;
  }, [organizationBilling]);

  const progressBarFilledColor = useMemo(() => {
    if (percentageUsed >= 0 && percentageUsed < 60) {
      return theme.colors?.primary.black;
    }

    if (percentageUsed >= 60 && percentageUsed < 80) {
      return theme.colors?.utility['yellow-3'];
    }

    if (percentageUsed >= 80) {
      return theme.colors?.utility['pink-3'];
    }

    return theme.colors?.primary.maroon;
  }, [percentageUsed]);

  return !shouldDisplay ? null : (
    <Box display="flex" flexDirection="column" gap={theme.spacing(2)} sx={sx}>
      <Box>
        <Typography color={theme.colors?.utility[1000]} variant="headline-xxs">
          Members
        </Typography>
      </Box>

      {organizationBilling.organizationMemberLimit ? (
        <>
          <ProgressBar
            variant="number"
            completed={organizationBilling.organizationMemberUsage}
            total={organizationBilling.organizationMemberLimit}
            bgColor={theme.colors?.utility[275]}
            filledColor={filledColor ?? progressBarFilledColor}
          />

          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            {organizationBilling.organizationMemberUsage} out of{' '}
            {organizationBilling.organizationMemberLimit}
          </Typography>
        </>
      ) : (
        <Box
          display="flex"
          gap={theme.spacing(1)}
          justifyContent="flex-start"
          alignItems="center"
        >
          <IconCustomInfinity color={theme.colors?.utility[900]} />
          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            Unlimited Members
          </Typography>
        </Box>
      )}
    </Box>
  );
};
