import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  IconButton,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { CheckboxMenuItem, RadioMenuItem } from 'components/common/form/Select';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import {
  DetailedSentiment,
  FollowersScale,
  SocialPostNotificationConfigFragmentNotificationsSectionFragment,
  TopicFragmentNotificationsSectionFragment,
  TopicFragmentNotificationsSectionFragmentDoc,
  useCreateSocialPostNotificationConfigForNotificationsSectionMutation,
  useDeleteSocialPostNotificationConfigForNotificationsSectionMutation,
  useGetBrandForNotificationSectionQuery,
  useGetNotificationConfigsForNotificationsSectionQuery,
  useUpdateSocialPostNotificationConfigForNotificationsSectionMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import { getCustomOperationContext } from 'utils/apollo';
import { CustomTopicNotificationDialog } from './CustomTopicNotificationDialog';
import {
  socialPostNotificationConfigFollowersScaleOptions,
  socialPostNotificationConfigRelevancyOptions,
  socialPostNotificationConfigSentimentOptions,
} from './constants';
import { getSocialPostNotificationConfigFollowersScaleSelectLabel } from './utils';

export const TOPIC_FRAGMENT_NOTIFICATIONS_SECTION = gql`
  fragment TopicFragmentNotificationsSection on TopicModel {
    id
    name
  }
`;

export const SOCIAL_POST_NOTIFICATION_CONFIG_FRAGMENT_NOTIFICATIONS_SECTION = gql`
  fragment SocialPostNotificationConfigFragmentNotificationsSection on SocialPostNotificationConfigModel {
    id
    creatorFollowing
    brandId
    sentiments
    minimumScore
    enabled
    creatorFollowingEnabled
    sentimentsEnabled
    minimumScoreEnabled
    topic {
      id
      ...TopicFragmentNotificationsSection
    }
  }
  ${TOPIC_FRAGMENT_NOTIFICATIONS_SECTION}
`;

// eslint-disable-next-line
gql`
  query GetNotificationConfigsForNotificationsSection {
    getNotificationConfigs {
      id
      ...SocialPostNotificationConfigFragmentNotificationsSection
    }
  }
  ${SOCIAL_POST_NOTIFICATION_CONFIG_FRAGMENT_NOTIFICATIONS_SECTION}
`;

// eslint-disable-next-line
gql`
  mutation CreateSocialPostNotificationConfigForNotificationsSection(
    $data: CreateSocialPostNotificationConfigInput!
  ) {
    createSocialPostNotificationConfig(data: $data) {
      id
      ...SocialPostNotificationConfigFragmentNotificationsSection
    }
  }
  ${SOCIAL_POST_NOTIFICATION_CONFIG_FRAGMENT_NOTIFICATIONS_SECTION}
`;

// eslint-disable-next-line
gql`
  mutation UpdateSocialPostNotificationConfigForNotificationsSection(
    $data: UpdateSocialPostNotificationConfigInput!
  ) {
    updateSocialPostNotificationConfig(data: $data) {
      id
      ...SocialPostNotificationConfigFragmentNotificationsSection
    }
  }
  ${SOCIAL_POST_NOTIFICATION_CONFIG_FRAGMENT_NOTIFICATIONS_SECTION}
`;

// eslint-disable-next-line
gql`
  mutation DeleteSocialPostNotificationConfigForNotificationsSection(
    $id: String!
  ) {
    deleteSocialPostNotificationConfig(id: $id) {
      success
      message
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandForNotificationSection($brandId: String!) {
    brand(id: $brandId) {
      id
      topics {
        id
        ...TopicFragmentNotificationsSection
      }
    }
  }
  ${TopicFragmentNotificationsSectionFragmentDoc}
`;

export type NotificationsSectionProps = {
  currentBrandId: string;
  topics?: TopicFragmentNotificationsSectionFragment[];
  componentProps?: {
    hideLabels?: boolean;
  };
};

export const NotificationsSection = (props: NotificationsSectionProps) => {
  const { currentBrandId, topics: _topics = [], componentProps = {} } = props;

  const { data: brandData } = useGetBrandForNotificationSectionQuery({
    variables: {
      brandId: currentBrandId,
    },
    skip: !!_topics.length,
  });
  const topics = _topics.length ? _topics : brandData?.brand?.topics || [];

  const location = useLocation();
  const { highlightNotificationsSection = false } = (location.state || {}) as {
    highlightNotificationsSection?: boolean;
  };

  const [createSocialPostNotificationConfig] =
    useCreateSocialPostNotificationConfigForNotificationsSectionMutation({
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  const [updateSocialPostNotificationConfig] =
    useUpdateSocialPostNotificationConfigForNotificationsSectionMutation();
  const [deleteSocialPostNotificationConfig] =
    useDeleteSocialPostNotificationConfigForNotificationsSectionMutation();

  const {
    data: notificationConfigsData,
    loading,
    refetch: refetchNotificationConfigsData,
  } = useGetNotificationConfigsForNotificationsSectionQuery();
  const notificationConfigs =
    notificationConfigsData?.getNotificationConfigs || [];
  const brandSpecificNotificationConfig = notificationConfigs.filter(
    (c) => c.brandId === currentBrandId,
  );

  // Global notification config will be the one that does not have a topic
  const globalNotificationConfig = brandSpecificNotificationConfig.find(
    (c) => !c.topic,
  );
  const topicNotificationConfigs = brandSpecificNotificationConfig.filter(
    (c) => c.topic,
  );

  // On load, if we cannot find any global notification config for current user,
  // create a default one
  useEffect(() => {
    if (!loading && !globalNotificationConfig) {
      createSocialPostNotificationConfig({
        variables: {
          data: {
            data: {
              brandId: currentBrandId,
              enabled: false,
              minimumScore: 0.8,
            },
          },
        },
        update: () => refetchNotificationConfigsData(),
      });
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify({
      globalNotificationConfig,
      loading,
    }),
  ]);

  const [
    selectedSocialPostNotificationConfig,
    setSelectedSocialPostNotificationConfig,
  ] = useState<
    SocialPostNotificationConfigFragmentNotificationsSectionFragment | undefined
  >();
  const {
    isOpen: isCustomTopicNotificationDialogOpen,
    onOpen: openCustomTopicNotificationDialog,
    onClose: closeCustomTopicNotificationDialog,
  } = useDisclosure();

  const {
    dialog: deleteTopicNotificationConfigDialog,
    onOpen: openDeleteTopicNotificationConfigDialog,
  } = useConfirmationDialog();

  const commonSelectProps: Partial<SelectProps> = {
    sx: {
      width: '100%',
      bgcolor: theme.colors?.utility[300],
      height: '32px',
      borderRadius: 1,
      '.MuiSelect-select': {
        minHeight: '32px !important',
        lineHeight: '32px !important',
        py: '4px !important',
        pr: '32px !important',
        pl: '12px !important',
      },
      fieldset: {
        border: `1px solid ${theme.colors?.utility[400]}`,
      },
    },
    MenuProps: {
      PaperProps: {
        sx: {
          p: 3,
        },
      },
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
      },
      transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
      },
    },
  };

  if (!globalNotificationConfig) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
          position: 'relative',

          '&::before': {
            content: '""',
            display: 'block',
            height: 'calc(100% + 24px)',
            width: 'calc(100%)',
            backgroundColor: theme.colors?.utility['yellow-1'],
            position: 'absolute',
            top: -12,
            left: -16,
            borderRadius: 3,
            opacity: highlightNotificationsSection ? 0.5 : 0,
          },

          '> *': {
            position: 'relative',
          },
        }}
      >
        {!componentProps.hideLabels && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mb: 3,
              gap: 1,
            }}
          >
            <Typography variant="headline-sm">Notifications</Typography>
            <Typography variant="subhead-xl" color={theme.colors?.utility[800]}>
              Customize your notification settings by selecting the specific
              conditions that trigger alerts.
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 2,
            mb: 6,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CheckboxMenuItem
              value=""
              label={<Typography variant="subhead-lg">Creator has</Typography>}
              checked={globalNotificationConfig.creatorFollowingEnabled}
              onClick={() => {
                updateSocialPostNotificationConfig({
                  variables: {
                    data: {
                      id: globalNotificationConfig.id,
                      data: {
                        creatorFollowingEnabled:
                          !globalNotificationConfig.creatorFollowingEnabled,
                      },
                    },
                  },
                  optimisticResponse: {
                    updateSocialPostNotificationConfig: {
                      ...globalNotificationConfig,
                      creatorFollowingEnabled:
                        !globalNotificationConfig.creatorFollowingEnabled,
                    },
                  },
                });
              }}
            />
            <Select
              value={globalNotificationConfig.creatorFollowing || []}
              multiple
              {...commonSelectProps}
              onChange={(e) => {
                const data = {
                  followersScale: e.target.value as FollowersScale[],
                  // Also enable the creator following if users are adding the followers scale
                  ...(globalNotificationConfig.creatorFollowing.length ===
                    0 && { creatorFollowingEnabled: true }),
                  ...((e.target.value as FollowersScale[]).length === 0 && {
                    creatorFollowingEnabled: false,
                  }),
                };

                updateSocialPostNotificationConfig({
                  variables: {
                    data: {
                      id: globalNotificationConfig.id,
                      data,
                    },
                  },
                  optimisticResponse: {
                    updateSocialPostNotificationConfig: {
                      ...globalNotificationConfig,
                      ...data,
                    },
                  },
                });
              }}
              renderValue={() => {
                if (
                  !globalNotificationConfig.creatorFollowing ||
                  !globalNotificationConfig.creatorFollowing.length
                ) {
                  return (
                    <Typography variant="subhead-lg">
                      <i>Make a selection</i>
                    </Typography>
                  );
                }

                return (
                  <Typography variant="subhead-lg">
                    {getSocialPostNotificationConfigFollowersScaleSelectLabel(
                      globalNotificationConfig.creatorFollowing,
                    )}
                  </Typography>
                );
              }}
            >
              {socialPostNotificationConfigFollowersScaleOptions.map(
                (option) => {
                  return (
                    <CheckboxMenuItem
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      checked={globalNotificationConfig.creatorFollowing.includes(
                        option.value,
                      )}
                    />
                  );
                },
              )}
            </Select>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CheckboxMenuItem
              value=""
              label={
                <Typography variant="subhead-lg">
                  The topic relevancy is more than
                </Typography>
              }
              checked={globalNotificationConfig.minimumScoreEnabled}
              onClick={() => {
                updateSocialPostNotificationConfig({
                  variables: {
                    data: {
                      id: globalNotificationConfig.id,
                      data: {
                        minimumScoreEnabled:
                          !globalNotificationConfig.minimumScoreEnabled,
                      },
                    },
                  },
                  optimisticResponse: {
                    updateSocialPostNotificationConfig: {
                      ...globalNotificationConfig,
                      minimumScoreEnabled:
                        !globalNotificationConfig.minimumScoreEnabled,
                    },
                  },
                });
              }}
            />
            <Select
              value={globalNotificationConfig.minimumScore || []}
              {...commonSelectProps}
              onChange={(e) => {
                const data = {
                  minimumScore: e.target.value as number,
                  // Also enable the minimum score if users are picking a minimum score
                  // 0.5 is the default value from the backend
                  ...(globalNotificationConfig.minimumScore === 0.5 && {
                    minimumScoreEnabled: true,
                  }),
                };

                updateSocialPostNotificationConfig({
                  variables: {
                    data: {
                      id: globalNotificationConfig.id,
                      data,
                    },
                  },
                  optimisticResponse: {
                    updateSocialPostNotificationConfig: {
                      ...globalNotificationConfig,
                      ...data,
                    },
                  },
                });
              }}
              renderValue={() => {
                const option =
                  socialPostNotificationConfigRelevancyOptions.find(
                    (option) =>
                      option.value === globalNotificationConfig.minimumScore,
                  );

                if (!option) {
                  return (
                    <Typography variant="subhead-lg">
                      <i>Make a selection</i>
                    </Typography>
                  );
                }

                return (
                  <Typography variant="subhead-lg">{option.label}</Typography>
                );
              }}
            >
              {socialPostNotificationConfigRelevancyOptions.map((option) => {
                return (
                  <RadioMenuItem
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    checked={
                      globalNotificationConfig.minimumScore === option.value
                    }
                  />
                );
              })}
            </Select>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CheckboxMenuItem
              value=""
              label={
                <Typography variant="subhead-lg">The sentiment is</Typography>
              }
              checked={globalNotificationConfig.sentimentsEnabled}
              onClick={() => {
                updateSocialPostNotificationConfig({
                  variables: {
                    data: {
                      id: globalNotificationConfig.id,
                      data: {
                        sentimentsEnabled:
                          !globalNotificationConfig.sentimentsEnabled,
                      },
                    },
                  },
                  optimisticResponse: {
                    updateSocialPostNotificationConfig: {
                      ...globalNotificationConfig,
                      sentimentsEnabled:
                        !globalNotificationConfig.sentimentsEnabled,
                    },
                  },
                });
              }}
            />
            <Select
              value={globalNotificationConfig.sentiments || []}
              multiple
              {...commonSelectProps}
              onChange={(e) => {
                const data = {
                  sentiments: e.target.value as DetailedSentiment[],
                  // Also enable the sentiments if users are picking a sentiment
                  ...(globalNotificationConfig.sentiments.length === 0 && {
                    sentimentsEnabled: true,
                  }),
                  ...((e.target.value as DetailedSentiment[]).length === 0 && {
                    sentimentsEnabled: false,
                  }),
                };

                updateSocialPostNotificationConfig({
                  variables: {
                    data: {
                      id: globalNotificationConfig.id,
                      data,
                    },
                  },
                  optimisticResponse: {
                    updateSocialPostNotificationConfig: {
                      ...globalNotificationConfig,
                      ...data,
                    },
                  },
                });
              }}
              renderValue={() => {
                if (
                  !globalNotificationConfig.sentiments ||
                  !globalNotificationConfig.sentiments.length
                ) {
                  return (
                    <Typography variant="subhead-lg">
                      <i>Make a selection</i>
                    </Typography>
                  );
                }

                return (
                  <Typography variant="subhead-lg">
                    {globalNotificationConfig.sentiments
                      .map((sentiment) =>
                        socialPostNotificationConfigSentimentOptions.find(
                          (option) => option.value === sentiment,
                        ),
                      )
                      .map((option) => option?.label)
                      .join(', ')}
                  </Typography>
                );
              }}
            >
              {socialPostNotificationConfigSentimentOptions.map((option) => {
                return (
                  <CheckboxMenuItem
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    checked={globalNotificationConfig.sentiments.includes(
                      option.value,
                    )}
                  />
                );
              })}
            </Select>
          </Box>
          {topicNotificationConfigs.map((topicNotificationConfig) => {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CheckboxMenuItem
                  value=""
                  label={
                    <Typography variant="subhead-lg">
                      Topic: <b>{topicNotificationConfig.topic?.name}</b>
                    </Typography>
                  }
                  checked={topicNotificationConfig.enabled || false}
                  onClick={() => {
                    updateSocialPostNotificationConfig({
                      variables: {
                        data: {
                          id: topicNotificationConfig.id,
                          data: {
                            enabled: !topicNotificationConfig.enabled,
                          },
                        },
                      },
                      optimisticResponse: {
                        updateSocialPostNotificationConfig: {
                          ...topicNotificationConfig,
                          enabled: !topicNotificationConfig.enabled,
                        },
                      },
                    });
                  }}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    setSelectedSocialPostNotificationConfig(
                      topicNotificationConfig,
                    );
                    openCustomTopicNotificationDialog();
                  }}
                >
                  <IconOutlineEdit2 size={16} />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    openDeleteTopicNotificationConfigDialog({
                      onConfirm: () => {
                        deleteSocialPostNotificationConfig({
                          variables: {
                            id: topicNotificationConfig.id,
                          },
                          update: () => {
                            refetchNotificationConfigsData();
                          },
                        });
                      },
                    });
                  }}
                >
                  <IconLinearTrash
                    size={16}
                    color={theme.colors?.utility['pink-3']}
                  />
                </IconButton>
              </Box>
            );
          })}
        </Box>
        <Box>
          <Button
            variant="tertiary"
            startIcon={<IconLinearAdd size={16} />}
            size="small"
            sx={{
              borderRadius: 50,
            }}
            onClick={openCustomTopicNotificationDialog}
          >
            Customize topic notifications
          </Button>
        </Box>
      </Box>

      {isCustomTopicNotificationDialogOpen && (
        <CustomTopicNotificationDialog
          socialPostNotificationConfig={selectedSocialPostNotificationConfig}
          topics={topics}
          brandId={currentBrandId}
          onClose={() => {
            closeCustomTopicNotificationDialog();
            refetchNotificationConfigsData();
            setSelectedSocialPostNotificationConfig(undefined);
          }}
        />
      )}

      {deleteTopicNotificationConfigDialog}
    </>
  );
};
