import { Box, Button, Dialog, Typography } from '@mui/material';
import { Image } from 'components/common/Image';
import { theme } from 'styles/theme';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';

type BillingUpgradeSuccessModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onRefresh?: VoidFunction;
};

export const BillingUpgradeSuccessModal = (
  props: BillingUpgradeSuccessModalProps,
) => {
  const { isOpen, onClose, onRefresh } = props;

  const isMobileView = useMediaQueryMobile();

  const handleRefresh = () => {
    if (onRefresh) {
      onRefresh();
    } else {
      window.location.reload();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: isMobileView ? 0 : 10,
          m: isMobileView ? 4 : 8,
          bgcolor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          maxWidth: 900,
          width: 900,
          height: 900,
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(8),
            justifyContent: isMobileView ? 'flex-end' : 'center',
            alignItems: 'center',
            padding: isMobileView
              ? theme.spacing(8)
              : theme.spacing(42, 0, 15, 0),
            textAlign: 'center',
            width: 800,
            height: 500,
            position: 'relative',
            bgcolor: theme.colors?.utility['orange-2'],
            borderRadius: 6,
            boxShadow:
              '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
          }}
        >
          <Typography
            sx={{
              fontSize: theme.spacing(18),
              position: 'absolute',
              top: isMobileView ? '-166px' : '-53px',
              left: '19%',
              zIndex: 1,
            }}
          >
            🍍
          </Typography>
          <Typography
            sx={{
              fontSize: theme.spacing(18),
              position: 'absolute',
              bottom: isMobileView ? '230px' : '-49px',
              left: isMobileView ? '16%' : '12%',
              zIndex: 1,
            }}
          >
            🥭
          </Typography>
          <Typography
            sx={{
              fontSize: theme.spacing(18),
              position: 'absolute',
              bottom: isMobileView ? '384px' : '60px',
              right: isMobileView ? '3%' : '-4%',
              zIndex: 1,
            }}
          >
            🍇
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              top: '-120px',
              left: isMobileView ? undefined : '36%',
            }}
          >
            <Image
              src="https://ucarecdn.com/796c483b-dcf7-46b0-95d7-aebd083f7a58/upgradesuccessmain.png"
              width={isMobileView ? '330px' : '230px'}
              borderRadius={theme.spacing(6)}
              sx={{
                objectFit: 'cover',
              }}
            />
          </Box>

          <Typography variant="headline-xl" color={theme.colors?.primary.white}>
            Upgraded!
          </Typography>

          <Typography variant="headline-md" color={theme.colors?.primary.white}>
            Your workspace just got supercharged. You go Picasso!
          </Typography>

          <Button
            fullWidth={isMobileView}
            sx={{
              bgcolor: theme.colors?.primary.black,
              color: theme.colors?.primary.white,
              ':hover': {
                bgcolor: theme.colors?.primary.black,
              },
            }}
            onClick={handleRefresh}
          >
            Back to Plot
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
