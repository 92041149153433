import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { PermissionMemberItem, PermissionSelector } from 'features/permission';
import {
  BrandInviteMemberFragmentSocialListeningPermissionMemberFragment,
  PermissionLevel,
  UserProfileFragmentSocialListeningPermissionMemberFragment,
  UserProfileFragmentSocialListeningPermissionMemberFragmentDoc,
} from 'graphql/generated';
import React from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandInviteMemberFragmentSocialListeningPermissionMember on BrandInviteMemberModel {
    id
    permissionLevel
    user {
      id
      ...UserProfileFragmentSocialListeningPermissionMember
    }
  }
  ${UserProfileFragmentSocialListeningPermissionMemberFragmentDoc}
`;

type Props = {
  brandInviteMember: BrandInviteMemberFragmentSocialListeningPermissionMemberFragment;
  slOrgId: string;
  readonly?: boolean;
  onPermissionChange: (
    brandInviteMember: {
      user: UserProfileFragmentSocialListeningPermissionMemberFragment;
      permissionLevel: PermissionLevel;
    },
    remove?: boolean,
  ) => void;
};

export const SocialListeningPermissionMember = ({
  brandInviteMember,
  slOrgId,
  readonly,
  onPermissionChange,
}: Props) => {
  const { user } = useUserContext();
  const meUserProfile = user?.organization.users.find((u) => u.id === user.id);

  if (!meUserProfile) {
    return null;
  }

  return (
    <PermissionMemberItem
      userProfile={
        brandInviteMember.user.id === meUserProfile.id
          ? meUserProfile
          : brandInviteMember.user
      }
      entityOrganizationId={slOrgId}
      type="custom"
      endAdornmentProps={{
        custom: {
          endAdornment: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.colors?.primary.black,
              }}
            >
              <PermissionSelector
                readonly={readonly}
                entityOrganizationId={slOrgId}
                visiblePermissions={[
                  PermissionLevel.Full,
                  PermissionLevel.View,
                ]}
                userOrganizationId={brandInviteMember.user.organization.id}
                isDisabledUser={!!brandInviteMember.user.isDisabled}
                initialValue={brandInviteMember.permissionLevel}
                onPermissionChange={(permission) => {
                  if (permission !== brandInviteMember.permissionLevel) {
                    onPermissionChange({
                      ...brandInviteMember,
                      permissionLevel: permission,
                    });
                  }
                }}
                selectPaperSx={{
                  backgroundColor: 'rgba(250, 243, 236, 1);',
                }}
                showRemove
                onRemove={() => {
                  onPermissionChange(brandInviteMember, true);
                }}
              />
            </Box>
          ),
        },
      }}
    />
  );
};
