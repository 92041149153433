import { Box, Button, Typography } from '@mui/material';
import { theme } from 'styles/theme';

type SocialMediaListeningOnboardingIntroProps = {
  onContinue: () => void;
};

export const SocialMediaListeningOnboardingCompetitorsIntro = ({
  onContinue,
}: SocialMediaListeningOnboardingIntroProps) => {
  return (
    <Box
      p={14}
      display="flex"
      gap={2.5}
      sx={{
        background: 'rgba(35, 6, 3, 0.30)',
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.20) inset',
        backdropFilter: 'blur(24px)',
        borderRadius: 11,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        maxWidth: theme.spacing(100),
        position: 'relative',
        zIndex: 2,
        border: `${theme.spacing(3)} solid rgba(255, 255, 255, 0.05)`,
      }}
    >
      <Box position="absolute" sx={{ inset: 0 }}>
        <Typography
          fontSize={theme.spacing(20)}
          position="absolute"
          top={theme.spacing(-22)}
          right={theme.spacing(20)}
          // rotate by 90 degrees and flip on x axis
          sx={{ transform: 'rotate(0deg) scaleX(-1)' }}
        >
          📣
        </Typography>
        <Typography
          fontSize={theme.spacing(20)}
          position="absolute"
          bottom={theme.spacing(22)}
          right={theme.spacing(-20)}
          // rotate by 90 degrees and flip on x axis
          sx={{ transform: 'rotate(20deg) scaleX(1)' }}
        >
          🎥
        </Typography>

        <Typography
          fontSize={theme.spacing(20)}
          position="absolute"
          bottom={theme.spacing(-10)}
          right={theme.spacing(-20)}
          // rotate by 90 degrees and flip on x axis
          sx={{ transform: 'rotate(-12deg) scaleX(1)' }}
        >
          🕵️
        </Typography>

        <Typography
          fontSize={theme.spacing(20)}
          position="absolute"
          bottom={theme.spacing(-10)}
          left={theme.spacing(-20)}
          // rotate by 90 degrees and flip on x axis
          sx={{ transform: 'rotate(-20deg) scaleX(-1)' }}
        >
          🤳
        </Typography>

        <Typography
          fontSize={theme.spacing(20)}
          position="absolute"
          top={theme.spacing(10)}
          left={theme.spacing(-20)}
          // rotate by 90 degrees and flip on x axis
          sx={{ transform: 'rotate(-10deg) scaleX(-1)' }}
        >
          📉
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.20)',
          borderRadius: 20,
          p: theme.spacing(1.5, 3),
        }}
      >
        <Typography variant="body-sm" color={theme.colors?.primary.parchment}>
          Competitor Analytics
        </Typography>
      </Box>

      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        color={theme.colors?.primary.parchment}
        fontWeight={600}
        textAlign="center"
      >
        Lurk and gather insights into your competitors’ strategies 👀
      </Typography>

      <Button
        fullWidth
        sx={{
          bgcolor: theme.colors?.primary.black,
          color: theme.colors?.primary.white,
          borderRadius: theme.spacing(2),
          ':hover': {
            bgcolor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
          },
          p: theme.spacing(6, 3),
          mr: 2,
          mt: 5,
          flexShrink: 0,
          height: 36,
        }}
        onClick={onContinue}
      >
        <Typography
          variant="body-xl"
          fontWeight={600}
          color={theme.colors?.primary.parchment}
        >
          Set it up
        </Typography>
      </Button>
    </Box>
  );
};
