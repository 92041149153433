import { gql } from '@apollo/client';
import { SxProps, TableCell } from '@mui/material';
import { PostUnreadCommentBadge } from 'features/post';
import {
  PostFragmentJuiceboxTablePostTableRowCellActivityFragment,
  PostFragmentPostUnreadCommentBadgeFragmentDoc,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentJuiceboxTablePostTableRowCellActivity on PostModel {
    id
    ...PostFragmentPostUnreadCommentBadge
  }
  ${PostFragmentPostUnreadCommentBadgeFragmentDoc}
`;

export type JuiceboxTablePostTableRowCellActivityProps = {
  post: PostFragmentJuiceboxTablePostTableRowCellActivityFragment;
  sx?: SxProps;
};

export const JuiceboxTablePostTableRowCellActivity = (
  props: JuiceboxTablePostTableRowCellActivityProps,
) => {
  const { post, sx = {} } = props;

  return (
    <TableCell sx={{ ...sx }}>
      <PostUnreadCommentBadge post={post} />
    </TableCell>
  );
};
