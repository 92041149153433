import { Tabs, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${theme.colors?.utility[300]};
  flex: 1;
  
  .MuiTabs-scroller {
    overflow: auto;
    ::-webkit-scrollbar: {
      display: none;
    },
    scrollbar-width: 0;
    -ms-overflow-style: none;
  }

  .MuiTabs-indicator {
    background-color: ${theme.colors?.primary.black};
  }

  .MuiTabs-flexContainer {
    gap: 24px;
  }

  .MuiTab-root {
    padding: 0 0 20px 0;
    text-transform: unset;
    min-width: unset;
    overflow: visible;

    &.Mui-selected {
      color: ${theme.colors?.primary.black};
      font-weight: 600;
    }
  }
`;
