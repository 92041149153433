import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Avatar, Box, Typography } from '@mui/material';
import { CustomStackedBarChart } from 'components/common/CustomStackedBarChart';
import { BrandBreakdownDialogView } from 'features/brand';
import { useGetBrandsParsedHoursQuery } from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandsParsedHours($totalHoursParsedData: BrandHoursParsedInput) {
    me {
      id
      organization {
        id
        billing {
          id
          brandHoursParseLimit
        }
        socialListeningBrands {
          id
          name
          creators {
            id
            profilePictureUrl
          }
          totalHoursParsedCount(data: $totalHoursParsedData)
        }
      }
    }
  }
`;

export const BrandParsedHours = () => {
  const brandBreakdownDialog = useDisclosure();

  const { data: parsedHoursData } = useGetBrandsParsedHoursQuery({
    variables: {
      totalHoursParsedData: {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate(),
      },
    },
  });

  const totalHoursParsed = useMemo(() => {
    if (!parsedHoursData) {
      return 0;
    }

    return parsedHoursData.me.organization.socialListeningBrands.reduce(
      (totalHoursParsed, brand) => {
        return totalHoursParsed + brand.totalHoursParsedCount;
      },
      0,
    );
  }, [parsedHoursData]);

  const totalHoursLimit =
    parsedHoursData?.me.organization.billing.brandHoursParseLimit || 0;

  const data = useMemo(() => {
    if (!parsedHoursData) {
      return [];
    }

    const brandData = parsedHoursData.me.organization.socialListeningBrands.map(
      (brand) => ({
        item: brand,
        label: brand.name,
        value: brand.totalHoursParsedCount,
      }),
    );

    return [
      ...brandData,
      {
        label: 'Remaining',
        value: Math.max(totalHoursLimit - totalHoursParsed, 0),
        color: 'transparent',
      },
    ];
  }, [parsedHoursData, totalHoursLimit, totalHoursParsed]);

  return (
    <Box>
      <Typography
        variant="headline-xs"
        color={theme.colors?.utility[700]}
        marginBottom={1}
        component="p"
      >
        Total hours parsed: {totalHoursParsed}/{totalHoursLimit}
      </Typography>
      <Box sx={{ width: 240 }} onClick={brandBreakdownDialog.onOpen}>
        <CustomStackedBarChart
          data={data}
          renderTooltipContent={(i) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2.5,
                }}
              >
                <Avatar
                  src={i.item?.creators[0]?.profilePictureUrl ?? ''}
                  sx={{ width: 24, height: 24 }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Typography variant="headline-xxs">
                    {i.item?.name ?? i.label}
                  </Typography>
                  <Typography
                    variant="subhead-sm"
                    color={theme.colors?.utility[600]}
                  >
                    {i.value} hour
                    {i.value > 1 ? 's' : ''} parsed
                  </Typography>
                </Box>
              </Box>
            );
          }}
        />
      </Box>
      {brandBreakdownDialog.isOpen && (
        <BrandBreakdownDialogView
          open={brandBreakdownDialog.isOpen}
          onClose={brandBreakdownDialog.onClose}
        />
      )}
    </Box>
  );
};
