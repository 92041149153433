import { Typography } from '@mui/material';
import { DatePicker } from 'components/common/DatePicker';
import { DatePickerProps } from 'components/common/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import { theme } from 'styles/theme';

export type DateFieldProps = {
  value: Moment | null;
  onChange: (value: Date | null) => void;
  renderInput?: DatePickerProps['renderInput'];
  readOnly?: boolean;
};

export const DateField = (props: DateFieldProps) => {
  const { value, renderInput, onChange, readOnly } = props;

  return (
    <DatePicker
      disabled={readOnly}
      value={value}
      onChange={(date) => {
        if (date) {
          onChange(date.toDate());
        } else {
          onChange(null);
        }
      }}
      renderInput={
        renderInput ||
        // Mar 13, 2024
        ((props) => {
          const { inputRef, inputProps, openPicker } = props;
          const { value } = inputProps || {};

          return (
            <Typography
              ref={inputRef}
              component="button"
              variant="subhead-lg"
              color={!value ? theme.colors?.utility[600] : undefined}
              onClick={openPicker}
              disabled={readOnly}
              sx={{
                display: 'block',
                width: '100%',
                textAlign: 'left',
              }}
            >
              {value ? moment(value).format('MMM D, YYYY • hh:mmA') : 'Empty'}
            </Typography>
          );
        })
      }
      includeTimeByDefault
    />
  );
};
