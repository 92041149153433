import { mergeAttributes, Node } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { CommentExtensionOptions } from '../comment';
import {
  ATTR_NOTE_COMMENT_ANCHOR_ID,
  ATTR_POST_ID,
  NODE_EMBED,
} from '../constants';
import { EmbedNodeView } from './EmbedNodeView';

export type EmbedExtensionOptions = {
  commentable?: boolean;
  editable?: boolean;
  captionable?: boolean;
} & Pick<CommentExtensionOptions, 'onTriggerAddComment'>;

export const Embed = Node.create<EmbedExtensionOptions>({
  name: NODE_EMBED,

  group: 'block',

  selectable: true,

  atom: true,

  addOptions() {
    return {
      commentable: false,
      editable: true,
      onTriggerAddComment: undefined,
      captionable: false,
    };
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      width: {
        default: 100, // will be handled as percentage,
        parseHTML: (element) => {
          const width = parseFloat(
            element.getAttribute('width')?.replace('%', '') || '100',
          );

          return Number.isNaN(width) ? 100 : width;
        },
      },
      height: {
        default: 'auto',
      },
      file: {
        default: null,
        rendered: false,
      },
      [ATTR_POST_ID]: {
        default: null,
      },
      caption: {
        default: null,
      },
      [ATTR_NOTE_COMMENT_ANCHOR_ID]: {
        default: null,
        renderHTML: (attrs) => {
          if (attrs[ATTR_NOTE_COMMENT_ANCHOR_ID]) {
            return {
              [ATTR_NOTE_COMMENT_ANCHOR_ID]: attrs[ATTR_NOTE_COMMENT_ANCHOR_ID],
            };
          }

          return {};
        },
        parseHTML: (element) => {
          return element.getAttribute(ATTR_NOTE_COMMENT_ANCHOR_ID);
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
      {
        tag: 'image',
      },
      {
        tag: 'video',
      },
      {
        tag: 'div[post-id]',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const attrs = mergeAttributes(node.attrs, HTMLAttributes, {
      'data-type': this.name,
    });

    return ['div', attrs];
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedNodeView);
  },

  addProseMirrorPlugins() {
    return [
      // Remove itself if the node's src is empty & it's not in uploading state
      new Plugin({
        appendTransaction(_transactions, oldState, newState) {
          // no changes
          if (newState.doc === oldState.doc) {
            return;
          }

          const tr = newState.tr;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (node.type.name === NODE_EMBED) {
              if (
                !node.attrs.src &&
                !node.attrs.file &&
                !node.attrs[ATTR_POST_ID]
              ) {
                // Delete itself
                tr.delete(pos, pos + node.nodeSize);

                modified = true;
              }
            }
          });

          if (modified) {
            return tr;
          }
        },
      }),
    ];
  },
});
