import { gql } from '@apollo/client';
import {
  PostFilters,
  PostFragmentCollectionMultiPostPreviewFragmentDoc,
  useGetPostsForCustomCollectionsUsePreviewablePostQueryQuery,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';

// eslint-disable-next-line
gql`
  query GetPostsForCustomCollectionsUsePreviewablePostQuery(
    $filters: PostFilters!
    $take: Int
    $after: String
  ) {
    posts(filters: $filters, take: $take, after: $after) {
      data {
        id
        ...PostFragmentCollectionMultiPostPreview
      }
      meta {
        totalCount
        newPostCount
      }
    }
  }
  ${PostFragmentCollectionMultiPostPreviewFragmentDoc}
`;

export type UsePreviewablePostQueryProps = {
  filters: PostFilters;
  take?: number;
};

export const usePreviewablePostQuery = (
  props: UsePreviewablePostQueryProps,
) => {
  const { filters, take = 3 } = props;

  const [incrementalTake, setIncrementalTake] = useState(take * 2);

  const { data, fetchMore } =
    useGetPostsForCustomCollectionsUsePreviewablePostQueryQuery({
      variables: {
        filters,
        take,
      },
      fetchPolicy: 'cache-and-network',
    });

  const previewablePosts = useMemo(() => {
    if (!data) return [];

    return (
      data.posts.data
        .filter((post) => {
          // Check urlMetadata for thumbnail
          if (post.urlMetadata.length === 0) {
            return false;
          }

          return post.urlMetadata.some(
            (metadata) => (metadata.metadata?.thumbnail || []).length > 0,
          );
        })
        // Dedupe by id
        .filter(
          (post, index, self) =>
            self.findIndex((p) => p.id === post.id) === index,
        )
    );
  }, [data]);

  // Fetch more posts if there are less than 3 previewable posts
  // Increase the take each time until there are at least N previewable posts
  useEffect(() => {
    if (!data) {
      return;
    }

    if (previewablePosts.length >= take) {
      return;
    }

    fetchMore({
      variables: {
        filters: {
          ...filters,
        },
        take: incrementalTake,
        after: previewablePosts[previewablePosts.length - 1]?.id,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          ...prev,
          posts: {
            ...prev.posts,
            data: [...prev.posts.data, ...fetchMoreResult.posts.data],
          },
        };
      },
    });

    setIncrementalTake(incrementalTake + take);
  }, [JSON.stringify({ previewablePosts })]); // eslint-disable-line

  return { data, previewablePosts };
};
