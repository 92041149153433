import { gql } from '@apollo/client';
import {
  TASK_FRAGMENT_TASK_LIST_ITEM_VIEW,
  TaskListItemViewProps,
} from 'features/task/views/listItem';
import {
  DeleteTaskInput,
  NewTaskInput,
  TaskFragmentTaskSubtaskSectionFragment,
  UpdateTaskInput,
} from 'graphql/generated';

export const TASK_FRAGMENT_TASK_SUBTASK_SECTION = gql`
  fragment TaskFragmentTaskSubtaskSection on TaskModel {
    ...TaskFragmentTaskListItemView
    myPermissions
  }
  ${TASK_FRAGMENT_TASK_LIST_ITEM_VIEW}
`;

export interface TaskSubtaskSectionProps {
  task: TaskFragmentTaskSubtaskSectionFragment;
  subtasks: TaskFragmentTaskSubtaskSectionFragment[];
  onSubtaskCreate?: (input: NewTaskInput) => Promise<void>;
  onSubtaskUpdate?: (data: UpdateTaskInput) => void;
  onSubtaskDelete?: (data: DeleteTaskInput) => void;
  componentsProps?: {
    listItem?: Omit<TaskListItemViewProps, 'task'>;
  };
}
