import { theme } from 'styles/theme';

export const postReactionOptions = [
  {
    emoji: '👍',
    tooltip: 'This one!',
    hoverBgColor: theme.colors?.utility['green-1'],
  },
  {
    emoji: '👎',
    tooltip: "Don't love",
    hoverBgColor: theme.colors?.utility['pink-1'],
  },
  {
    emoji: '🤔',
    tooltip: 'Hmmm',
    hoverBgColor: theme.colors?.utility['yellow-1'],
  },
  {
    emoji: '⚰️',
    tooltip: 'Archive',
    hoverBgColor: theme.colors?.utility[400],
  },
];
