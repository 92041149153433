import { Box } from '@mui/material';
import { TrendingAudioPreview } from 'features/trending/components';
import { useTrendExampleNavigatorHandler } from 'features/trending/hooks';
import { TrendFragmentTrendingDetailViewFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import {
  TrendingDetailActionButtonsView,
  TrendingDetailActionButtonsViewProps,
} from './ActionButtons';
import { TrendingDetailMainView } from './DetailMain';
import { TrendingDetailMoreExamplesView } from './DetailMoreExamples';

type Props = {
  trend: TrendFragmentTrendingDetailViewFragment;
  showTrendExamples: boolean;
  setShowTrendExamples: (show: boolean) => void;
  hasMoreExamples: boolean;
  currentTrendExampleId: string;
  setCurrentTrendExampleId: (val: string) => void;
  trendExamples: TrendFragmentTrendingDetailViewFragment['trendExamples'];
  componentProps: {
    actionButtons: Pick<
      TrendingDetailActionButtonsViewProps,
      'hideInfo' | 'onShowInfo'
    >;
  };
};

export const TREND_EXAMPLE_PREFIX = 'trend-example-';

export const TrendingDetailContentView = ({
  trend,
  setShowTrendExamples,
  showTrendExamples,
  componentProps,
  hasMoreExamples,
  trendExamples,
  currentTrendExampleId,
  setCurrentTrendExampleId,
}: Props) => {
  const [direction, setDirection] = useState<'up' | 'down'>('down');

  const { onWheel } = useTrendExampleNavigatorHandler();

  // handle keyboard up and down arrow keys navigation
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // List of input element types to exclude
      const excludedInputTypes = ['input', 'textarea', 'select'];
      // Check if any input element is focused
      const isInputFocused =
        document.activeElement instanceof HTMLElement &&
        excludedInputTypes.includes(
          document.activeElement.tagName.toLowerCase(),
        );

      if (!isInputFocused) {
        const divsWithId = document.querySelectorAll(
          `div[id^="${TREND_EXAMPLE_PREFIX}"]`,
        ) as NodeListOf<HTMLDivElement>;

        // Find the div with the most visible area
        const mostVisibleDiv = Array.from(
          divsWithId,
        ).reduce<HTMLDivElement | null>((prevDiv, currDiv) => {
          const prevRect: any = prevDiv
            ? prevDiv.getBoundingClientRect()
            : { height: 0 };
          const currRect: DOMRect = currDiv.getBoundingClientRect();

          // Calculate the visible area of each div (intersection with viewport)
          const prevVisibleArea =
            Math.min(prevRect.bottom, window.innerHeight) -
              Math.max(prevRect.top, 0) ?? 0;
          const currVisibleArea =
            Math.min(currRect.bottom, window.innerHeight) -
              Math.max(currRect.top, 0) ?? 0;

          return currVisibleArea > prevVisibleArea ? currDiv : prevDiv;
        }, divsWithId[0]); // Initial value for prevDiv

        if (event.key === 'ArrowUp') {
          // Scroll to the previous sibling of the most visible div
          if (mostVisibleDiv && mostVisibleDiv.previousElementSibling) {
            const trendExampleId =
              mostVisibleDiv.previousElementSibling.id.split(
                TREND_EXAMPLE_PREFIX,
              )[1];
            if (currentTrendExampleId !== trendExampleId) {
              mostVisibleDiv.previousElementSibling.scrollIntoView({
                behavior: 'smooth',
              });
              setDirection('up');
              setCurrentTrendExampleId(trendExampleId);
            }
          } else {
            setShowTrendExamples(false);
            setCurrentTrendExampleId('');
          }
        } else if (event.key === 'ArrowDown') {
          // Scroll to the next sibling of the most visible div
          if (mostVisibleDiv && mostVisibleDiv.nextElementSibling) {
            const trendExampleId =
              mostVisibleDiv.nextElementSibling.id.split(
                TREND_EXAMPLE_PREFIX,
              )[1];
            if (currentTrendExampleId !== trendExampleId) {
              mostVisibleDiv.nextElementSibling.scrollIntoView({
                behavior: 'smooth',
              });
              setDirection('down');
              setCurrentTrendExampleId(trendExampleId);
            }
          } else if (!divsWithId.length && hasMoreExamples) {
            setShowTrendExamples(true);
            setCurrentTrendExampleId(trendExamples[0].id);
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [trend, hasMoreExamples]); // eslint-disable-line

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '::-webkit-scrollbar': {
          width: 0,
        },
      }}
      onWheel={onWheel}
    >
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              minHeight: 'calc(100% - 40px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!showTrendExamples && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                }}
              >
                {trend.trendExamples.length && trend.trendExamples[0] ? (
                  <TrendingDetailMainView
                    trend={trend}
                    componentProps={{
                      actionButtons: componentProps.actionButtons,
                    }}
                  />
                ) : (
                  trend.trendAudio && (
                    <>
                      <TrendingAudioPreview trendAudio={trend.trendAudio} />
                      <Box
                        sx={{
                          display: 'flex',
                          marginBottom: 19,
                        }}
                      >
                        <TrendingDetailActionButtonsView
                          {...componentProps.actionButtons}
                          trend={trend}
                          urlMetadataId={trend.trendAudio.urlMetadata.id}
                          totalCounts={{
                            contentCalendar:
                              trend.trendAudioSavedCount
                                ?.savedContentCalendarTotal || 0,
                            trends:
                              trend.trendAudioSavedCount?.totalSavedCount || 0,
                          }}
                        />
                      </Box>
                    </>
                  )
                )}
              </Box>
            )}
            {showTrendExamples && hasMoreExamples && (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'auto',
                  height: '100%',
                  '::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <TrendingDetailMoreExamplesView
                  trend={trend}
                  componentProps={{
                    actionButtons: componentProps.actionButtons,
                  }}
                  currentTrendExampleId={currentTrendExampleId}
                  trendExamples={trendExamples}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
