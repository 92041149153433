import { gql } from '@apollo/client';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { CommandKeys } from 'components/common/CommandKey';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { TopicSignal } from 'features/topic';
import {
  GetTopicSignalDefinitionsForSocialMediaListeningOnboardingSignalsDocument,
  GetTopicSignalDefinitionsForSocialMediaListeningOnboardingSignalsQuery,
  SignalDefinitionFragmentTopicSignalFragmentDoc,
  useCreateSignalDefinitionForSocialMediaListeningOnboardingSignalsMutation,
  useGetTopicSignalDefinitionsForSocialMediaListeningOnboardingSignalsQuery,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

// eslint-disable-next-line
gql`
  query GetTopicSignalDefinitionsForSocialMediaListeningOnboardingSignals(
    $topicId: String!
  ) {
    topicSignalDefinitions(topicId: $topicId) {
      ...SignalDefinitionFragmentTopicSignal
    }
  }
  ${SignalDefinitionFragmentTopicSignalFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateSignalDefinitionForSocialMediaListeningOnboardingSignals(
    $data: CreateSignalDefinitionInput!
  ) {
    createSignalDefinition(data: $data) {
      ...SignalDefinitionFragmentTopicSignal
    }
  }
  ${SignalDefinitionFragmentTopicSignalFragmentDoc}
`;

type SocialMediaListeningOnboardingSignalsProps = {
  topicId: string;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingSignals = ({
  topicId,
  onNext,
}: SocialMediaListeningOnboardingSignalsProps) => {
  const [createdSignalDefinitionId, setCreatedSignalDefinitionId] =
    useState<string>('');
  const [createSignalDefinition, { loading: isCreatingSignalDefinition }] =
    useCreateSignalDefinitionForSocialMediaListeningOnboardingSignalsMutation();
  const { data } =
    useGetTopicSignalDefinitionsForSocialMediaListeningOnboardingSignalsQuery({
      variables: { topicId },
    });
  const signalDefinitions = data?.topicSignalDefinitions || [];

  const onCreateCategory = () => {
    createSignalDefinition({
      variables: {
        data: {
          data: {
            topicId,
          },
        },
      },
      update: (cache, { data }) => {
        const newSignalDefinition = data?.createSignalDefinition;
        if (newSignalDefinition) {
          setCreatedSignalDefinitionId(newSignalDefinition.id);
          cache.updateQuery(
            {
              query:
                GetTopicSignalDefinitionsForSocialMediaListeningOnboardingSignalsDocument,
              variables: {
                topicId,
              },
            },
            (
              data: GetTopicSignalDefinitionsForSocialMediaListeningOnboardingSignalsQuery | null,
            ) => {
              if (!data) return null;

              return {
                ...data,
                topicSignalDefinitions: [
                  newSignalDefinition,
                  ...data.topicSignalDefinitions,
                ],
              };
            },
          );
        }
      },
    });
  };

  return (
    <Box
      width="60vw"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      maxHeight="80vh"
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Signals 🎯
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        Signals are like labels we create to organize similar types of
        information and track quantitative data. Think of it like sorting drink
        recipes into specific groups - for example, under 'ingredients' we might
        label content that uses pickles, jalapeños, protein powder, or creamer
        to help find patterns, trends, and insights.
      </Typography>
      <IconButton
        sx={{
          padding: theme.spacing(2, 3),
          borderRadius: 2,
          background: 'rgba(255, 255, 255, 0.1)',
          color: theme.colors?.primary.parchment,
          gap: 1,
          width: 'fit-content',
          mt: 4,
          ':disabled': {
            background: 'rgba(255, 255, 255, 0.1)',
            color: theme.colors?.utility[500],
          },
        }}
        disabled={
          isCreatingSignalDefinition ||
          signalDefinitions.some((s) => s.title === '')
        }
        onClick={onCreateCategory}
      >
        {isCreatingSignalDefinition && <CircularProgress size={16} />}
        <IconBoldAddCircle size={16} />
        <Typography variant="headline-sm">Add signal</Typography>
      </IconButton>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
          mt: 2,
          maxHeight: '100%',
          overflowY: 'auto',
          '::-webkit-scrollbar': {
            width: 0,
          },
        }}
      >
        {signalDefinitions.map((signalDefinition) => (
          <TopicSignal
            key={signalDefinition.id}
            showHelpText
            signalDefinition={signalDefinition}
            autoFocus={createdSignalDefinitionId === signalDefinition.id}
          />
        ))}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
        }}
      />
      <RenderNextButton
        sx={{
          bottom: theme.spacing(10),
        }}
        onNextClick={() => {
          onNext();
        }}
        disabled={
          isCreatingSignalDefinition ||
          signalDefinitions.some((s) => s.title === '')
        }
        // eslint-disable-next-line
        actionBtnEndIcon={<></>}
        helperText={
          <Box
            sx={{
              ...theme.typography['subhead-lg'],
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
            }}
          >
            press <b>Cmd</b>
            <CommandKeys
              keys={['⌘']}
              componentsProps={{
                key: {
                  sx: {
                    background: 'rgba(250, 243, 236, 0.15)',
                    color: theme.colors?.primary.parchment,
                    fontSize: 14,
                    fontFamily: 'system-ui',
                    fontWeight: 600,
                  },
                },
              }}
            />
            + <b>Enter</b>
            <CommandKeys
              keys={['⏎']}
              componentsProps={{
                key: {
                  sx: {
                    background: 'rgba(250, 243, 236, 0.15)',
                    color: theme.colors?.primary.parchment,
                    fontSize: 14,
                    fontFamily: 'system-ui',
                    fontWeight: 600,
                  },
                },
              }}
            />
          </Box>
        }
      />
    </Box>
  );
};
