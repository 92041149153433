import { Box, Skeleton } from '@mui/material';

export const TaskListItemViewSkeleton = () => {
  return (
    <Box display="flex" gap={2}>
      <Skeleton
        width={24}
        height={24}
        sx={{ borderRadius: 50, transform: 'scale(1)', flexShrink: 0 }}
      />
      <Skeleton
        variant="rectangular"
        width="100%"
        height={24}
        sx={{
          borderRadius: 2,
          maxWidth: 300,
        }}
      />
    </Box>
  );
};
