import { keyframes } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { theme } from 'styles/theme';
import { CommandKey } from 'components/common/CommandKey';
import { useUserContext } from 'contexts/users/User.context';

const loadingAnimation = keyframes`
  0% {
    background-color: ${theme.colors?.utility[700]};
  }
  50% {
    background-color: ${theme.colors?.primary.black};
  }
  100% {
    background-color: ${theme.colors?.utility[700]};
  }
`;

type AISearchButtonProps = {
  loading?: boolean;
  showingAIResults: boolean;
  disabled?: boolean;
  onClick?: VoidFunction;
};

export const AISearchButton = (props: AISearchButtonProps) => {
  const { showingAIResults, loading, disabled, onClick } = props;

  const { orgBilling } = useUserContext();

  return (
    <Box
      onClick={onClick}
      sx={{
        opacity: disabled ? 0.6 : 1,
        cursor: disabled || showingAIResults ? 'default' : 'pointer',
        borderRadius: theme.spacing(2),
        background: showingAIResults
          ? theme.colors?.secondary['pale-maroon']
          : 'linear-gradient(#faf3ec 0 0) padding-box, linear-gradient(146.79deg, rgba(141, 112, 163, 0.7) 1.07%, rgba(115, 148, 197, 0.7) 24.73%, rgba(111, 164, 157, 0.7) 49.37%, rgba(138, 161, 101, 0.7) 70.57%, rgba(211, 133, 67, 0.7) 99.65%) border-box',
        p: 2,
        animation: loading
          ? `${loadingAnimation} 1.5s infinite alternate`
          : 'none',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        height: theme.spacing(9),
        border: '2px solid transparent',
      }}
    >
      <Box
        color={
          showingAIResults
            ? theme.colors?.primary.maroon
            : theme.colors?.primary.black
        }
        display="flex"
        mr={1}
      >
        <IconCustomSparkles size={16} />
      </Box>

      <Typography
        variant="headline-xs"
        color={
          showingAIResults
            ? theme.colors?.primary.maroon
            : theme.colors?.primary.black
        }
        sx={{ whiteSpace: 'nowrap' }}
      >
        {showingAIResults
          ? 'Displaying AI results'
          : `${orgBilling?.aiFeaturesEnabled ? '' : 'Try '}AI Search`}
      </Typography>

      {!showingAIResults && (
        <CommandKey sx={{ padding: '3px 3px 3px 1px' }}>⏎</CommandKey>
      )}
    </Box>
  );
};
