import { Box, Card, Skeleton, Typography } from '@mui/material';

export const SLABrandInboundGraphViewSkeleton = () => {
  return (
    <Card
      sx={{
        borderRadius: 5,
        my: 2,
        p: 6,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={1}
          mb={3}
        >
          <Typography
            variant="headline-sm"
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Graph
          </Typography>
          {/* Value skeleton */}
          <Skeleton variant="text" width={120} height={40} />
          <Skeleton variant="text" width={200} height={60} />
        </Box>
        <Box>
          {/* Filter button skeleton */}
          <Skeleton variant="rounded" width={180} height={40} />
        </Box>
      </Box>

      {/* Main graph skeleton */}
      <Skeleton variant="rectangular" width="100%" height={400} />

      {/* Multi-chart chips skeleton */}
      {/* <Box
        display="flex"
        gap={2}
        alignItems="center"
        flexWrap="wrap"
        mt={4}
        mb={6}
      >
        {[1, 2, 3].map((index) => (
          <Skeleton
            key={index}
            variant="rounded"
            width={120}
            height={32}
            sx={{ borderRadius: 4 }}
          />
        ))}
        <Skeleton variant="circular" width={20} height={20} />
      </Box>

      <Skeleton variant="rectangular" width="100%" height={400} /> */}
    </Card>
  );
};
