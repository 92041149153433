import { gql } from '@apollo/client';
import { PostContextMenu } from 'features/post';
import { usePostPermissionDialogView } from 'features/post-permission';
import {
  PostFragmentJuiceboxTablePostTableRowFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowWithContextMenuFragment,
  PostFragmentPostContextMenuFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { JuiceboxTablePostTableRow } from './JuiceboxTablePostTableRow';
import { StyledRightClickContextMenuWrapper } from './styles';

export const POST_FRAGMENT_JUICEBOX_TABLE_POST_TABLE_ROW_WITH_CONTEXT_MENU = gql`
  fragment PostFragmentJuiceboxTablePostTableRowWithContextMenu on PostModel {
    id
    ...PostFragmentJuiceboxTablePostTableRow
    ...PostFragmentPostContextMenu
  }
  ${PostFragmentJuiceboxTablePostTableRowFragmentDoc}
  ${PostFragmentPostContextMenuFragmentDoc}
`;

export type JuiceboxTablePostTableRowWithContextMenuProps = {
  post: PostFragmentJuiceboxTablePostTableRowWithContextMenuFragment;
  isSelectModeActive?: boolean;
  onClick?: () => void;
  nestedLevel?: number;
  collectionId?: string;
};

export const JuiceboxTablePostTableRowWithContextMenu = ({
  post,
  isSelectModeActive,
  onClick,
  collectionId = '',
  nestedLevel = 0,
}: JuiceboxTablePostTableRowWithContextMenuProps) => {
  const {
    openDialog: showPostPermissionDialog,
    renderContent: renderPostPermissionDialogView,
  } = usePostPermissionDialogView({
    postId: post.id || '',
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <StyledRightClickContextMenuWrapper
        sx={{
          height: '100%',
          pl: theme.spacing(nestedLevel * 6),
          pointerEvents: isSelectModeActive ? 'none' : 'auto',
          bgcolor: 'transparent',
          boxShadow: 'none',
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid rgba(230, 221, 237, 0.7)',
          '& td:first-child': {
            borderTopLeftRadius: theme.spacing(3),
            borderBottomLeftRadius: theme.spacing(3),
          },
          '& td:last-child': {
            borderTopRightRadius: theme.spacing(3),
            borderBottomRightRadius: theme.spacing(3),
          },
          '&:hover': {
            bgcolor: 'rgba(230, 221, 237, 0.7)',
            cursor: 'pointer',
          },
        }}
        onClick={onClick}
        renderMenu={(props) => (
          <PostContextMenu post={post} renderButton={false} {...props} />
        )}
      >
        <JuiceboxTablePostTableRow
          key={`${post.id}`}
          post={post}
          collectionId={collectionId}
        />
      </StyledRightClickContextMenuWrapper>
      {renderPostPermissionDialogView()}
    </>
  );
};
