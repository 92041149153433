/**
 * This is a wrapper for ApolloProvider, not an actual context
 */

import { ApolloProvider as OGApolloProvider } from '@apollo/client';
import { useApolloClient } from 'hooks/useApolloClient';

const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { client } = useApolloClient();

  return <OGApolloProvider client={client}>{children}</OGApolloProvider>;
};

export { ApolloProvider };
