import { gql } from '@apollo/client';
import { useGetCollectionForCollectionPermissionsQuery } from 'graphql/generated';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetCollectionForCollectionPermissions($collectionId: String!) {
    collection(id: $collectionId) {
      id
      myPermissions
    }
  }
`;

type Props = {
  collectionId?: string;
};

export const useCollectionPermissions = (props: Props) => {
  const { collectionId = '' } = props;

  const { data } = useGetCollectionForCollectionPermissionsQuery({
    variables: {
      collectionId,
    },
    skip: !collectionId,
    // Suppressing the top level toast message because this hook is mainly used for
    // internal logic & user should not be notified about the permission check
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });

  return {
    permissions: data?.collection?.myPermissions || [],
  };
};
