import { gql } from '@apollo/client';
import { SxProps, TableCell } from '@mui/material';
import { JuiceboxTableBaseTableViewContext } from 'features/juiceboxTable/views/baseTable/types';
import { PostFragmentJuiceboxTablePostTableRowCellSelectIconFragment } from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentJuiceboxTablePostTableRowCellSelectIcon on PostModel {
    id
  }
`;

export type JuiceboxTablePostTableRowCellSelectIconProps = {
  post: PostFragmentJuiceboxTablePostTableRowCellSelectIconFragment;
  isSelected: boolean;
  context?: JuiceboxTableBaseTableViewContext;
  sx?: SxProps;
};

export const JuiceboxTablePostTableRowCellSelectIcon = (
  props: JuiceboxTablePostTableRowCellSelectIconProps,
) => {
  const { post, isSelected, context, sx = {} } = props;

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <TableCell sx={{ textAlign: 'right', ...sx }}>
      <></>
      {/*{collection.id === context?.context.collection?.id &&*/}
      {/*  (isSelected ? (*/}
      {/*    <IconButton disableRipple>*/}
      {/*      <IconBoldTickCircle size={24} color={theme.colors?.primary.black} />*/}
      {/*    </IconButton>*/}
      {/*  ) : (*/}
      {/*    <Box*/}
      {/*      onClick={handleSelect}*/}
      {/*      sx={{*/}
      {/*        m: 2,*/}
      {/*        ml: 2.5,*/}
      {/*        cursor: 'pointer',*/}
      {/*        width: theme.spacing(5),*/}
      {/*        height: theme.spacing(5),*/}
      {/*        border: `1px solid ${theme.colors?.utility[800]}`,*/}
      {/*        borderRadius: theme.spacing(5),*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ))}*/}
    </TableCell>
  );
};
