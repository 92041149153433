import { Box, CircularProgress, Typography } from '@mui/material';
import { WordLimitedInputArea } from 'features/socialMediaListening/components';
import React, { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingTopicDescriptionProps = {
  loading?: boolean;
  value: string;
  setValue: (value: string) => void;
  onNext: () => void;
  type?: 'creator' | 'topic';
  componentProps?: {
    title?: string;
    subtitle?: string;
    inputPlaceholder?: string;
  };
};

export const SocialMediaListeningOnboardingTopicDescription = ({
  loading,
  value,
  setValue,
  onNext,
  type = 'topic',
  componentProps,
}: SocialMediaListeningOnboardingTopicDescriptionProps) => {
  const [hasError, setHasError] = useState(false);
  const validateAndProceed = () => {
    if (!value.trim().length) {
      setHasError(true);
    } else {
      setHasError(false);
      onNext();
    }
  };

  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      <Typography
        variant="body-lg"
        sx={{
          fontWeight: 600,
          width: 'fit-content',
          padding: theme.spacing(1, 3),
          bgcolor: 'rgba(250, 243, 236, 0.20)',
          borderRadius: theme.spacing(4),
        }}
      >
        {type === 'topic' && 'Topic Insights'}
        {type === 'creator' && 'Creator Focus'}
      </Typography>
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        {componentProps?.title || 'Tell us about the topic. 🙇‍♀️'}
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        {componentProps?.subtitle ||
          'Tell us about the type of content you want to engage with.'}
      </Typography>
      {loading && (
        <Typography
          variant="body-xl"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <CircularProgress
            size={theme.spacing(3)}
            sx={{
              color: theme.colors?.primary.parchment,
            }}
          />
          Generating topic insights...
        </Typography>
      )}
      <WordLimitedInputArea
        maxWords={100}
        componentProps={{
          wordUsage: {
            sx: {
              color: theme.colors?.utility[500],
            },
          },
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            validateAndProceed();
          }
        }}
        helperText={
          <Typography variant="body-xl">
            Shift{' '}
            <Box
              component="span"
              p={theme.spacing(0.5, 1)}
              sx={{
                backgroundColor: 'rgba(250, 243, 236, 0.15)',
                mx: 1,
                border: `2px solid ${theme.colors?.utility[500]}`,
                borderRadius: theme.spacing(2),
                fontWeight: 600,
                fontSize: theme.spacing(3),
              }}
            >
              ⇧
            </Box>{' '}
            + Enter{' '}
            <Box
              component="span"
              p={theme.spacing(0.5, 1)}
              sx={{
                backgroundColor: 'rgba(250, 243, 236, 0.15)',
                mx: 1,
                border: `2px solid ${theme.colors?.utility[500]}`,
                borderRadius: theme.spacing(2),
                fontWeight: 600,
                fontSize: theme.spacing(3),
              }}
            >
              ⏎
            </Box>{' '}
            to make a line break
          </Typography>
        }
        autoFocus
        placeholder={
          componentProps?.inputPlaceholder ||
          'Example: Find content showcasing mentions of our Plot brand by creators or instances where our brand logo is visible in videos. Content should focus on creators discussing topics such as social media management, project management, idea sharing, content calendars, asset management, ideation, and storage.'
        }
        multiline
        disabled={loading}
        minRows={10}
        maxRows={16}
        sx={{
          mt: 4,
          '.MuiOutlinedInput-root': {
            ...(hasError
              ? { border: `2px solid ${theme.colors?.utility['pink-3']}` }
              : {}),
            borderRadius: theme.spacing(4),
            py: `${theme.spacing(8)} !important`,
            px: `${theme.spacing(8)} !important`,
            bgcolor: 'rgba(255, 255, 255, 0.10)',
            color: theme.colors?.primary.parchment,

            input: {
              p: '0 !important',
              ...theme.typography['body-xl'],
            },

            '& .Mui-disabled': {
              backgroundColor: 'transparent',
              color: theme.colors?.primary.parchment,
            },

            '.MuiOutlinedInput-notchedOutline': {
              display: 'none !important',
            },
          },
        }}
      />
      <RenderNextButton onNextClick={validateAndProceed} />
    </Box>
  );
};
