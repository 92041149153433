import { Chip, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { useUserContext } from 'contexts/users/User.context';
import { fieldNameIconMap, getCustomStatusStyles } from 'features/contentIdea';
import { ContentIdeaFieldFragmentContentCalendarFilterFragment } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { StyledIconContainer, StyledMenuItemContainer } from '../../styles';
import { platformIconMap } from '../../types';

type ContentCalendarFilterProps = {
  filter: ContentIdeaFieldFragmentContentCalendarFilterFragment;
  selectedOptions: string[];
  onSelectFilter: (fieldName: string, selected: string) => void;
};

export const ContentCalendarFilter = (props: ContentCalendarFilterProps) => {
  const { filter, selectedOptions, onSelectFilter } = props;

  const { user } = useUserContext();

  const FilterIcon = filter.icon ? fieldNameIconMap[filter.icon] : null;

  const dropDownOptions = useMemo(() => {
    return filter.options.map((option) => {
      const extraInfo = platformIconMap.find((p) => p.name === option.value);

      // FIXME: THIS IS A HACK FOR STATUS COLORS
      const customStyles = getCustomStatusStyles(
        option.value,
        option.bgcolor || '',
      );

      return {
        label: (
          <Chip
            variant="filled-borderless-color-dodge"
            sx={{
              borderRadius: 1,
              // Override color dodge CSS if text color is available
              ...(extraInfo?.color || customStyles.color
                ? {
                    '.MuiChip-label': {
                      filter: 'none !important',
                      color: customStyles.color,
                    },
                  }
                : {}),
              ...customStyles,
            }}
            icon={extraInfo?.icon}
            label={
              <Typography variant="headline-xs" color={extraInfo?.color}>
                {option.label}
              </Typography>
            }
          />
        ),
        isChecked: selectedOptions.some((s) => s === option.value),
        onClick: () => onSelectFilter(filter.name, option.value),
      };
    });
  }, [filter.id, filter.name, filter.options, selectedOptions, user]); // eslint-disable-line

  return (
    <DropDownMoreMenuItem
      disableRipple
      label={
        <StyledMenuItemContainer
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <StyledIconContainer>
            <FilterIcon size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">
            {filter.name}{' '}
            {selectedOptions.length > 0 && (
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[600]}
              >
                ({selectedOptions.length})
              </Typography>
            )}
          </Typography>
        </StyledMenuItemContainer>
      }
      dropDownOptions={dropDownOptions}
    />
  );
};
