import { Box, SxProps, Typography } from '@mui/material';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import moment from 'moment';
import { theme } from 'styles/theme';

type Props = {
  startDate?: Date;
  endDate?: Date;
  sx?: SxProps;
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
  };
};

export const TaskDueDateRangeButton = (props: Props) => {
  const { startDate, endDate, sx, componentsProps = {} } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: theme.spacing(2, 2.5),
        gap: 2,
        borderRadius: 3,
        backgroundColor: theme.colors?.utility[250],
        ...sx,
      }}
    >
      <IconBoldCalendar
        size={componentsProps?.icon?.size || 16}
        color={componentsProps?.icon?.color}
      />
      <Typography variant="body-xl" color={theme.colors?.utility[900]}>
        {startDate || endDate
          ? `${startDate ? moment(startDate).format('MMM D') : 'N/A'} -
          ${endDate ? moment(endDate).format('MMM D') : 'N/A'}`
          : 'Due date'}
      </Typography>
    </Box>
  );
};
