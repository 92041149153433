import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  onInputChange: (searchStr: string) => void;
};

export const SocialMediaListeningSearchInput = ({ onInputChange }: Props) => {
  const [searchStr, setSearchStr] = useState('');
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      onInputChange(searchStr);
    }, 300);
    return () => clearTimeout(timer);
  }, [onInputChange, searchStr]);

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  if (showInput) {
    return (
      <Box>
        <TextField
          inputRef={inputRef} // Add this line
          onBlur={() => {
            if (searchStr === '') {
              setShowInput(false);
            }
          }}
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
          sx={{
            minWidth: theme.spacing(75),
            '& .MuiOutlinedInput-root': {
              pb: 1,
              paddingRight: `${theme.spacing(2)}`,
              borderRadius: theme.spacing(10),
              backgroundColor: theme.colors?.utility[275],
              border: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box display="flex" pt={4} p={1}>
                  <IconOutlineSearchNormal1
                    size={16}
                    color={theme.colors?.utility[700]}
                  />
                </Box>
              </InputAdornment>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            size: searchStr.length || 1,
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <IconButton
        sx={{ p: 4, backgroundColor: theme.colors?.utility[275] }}
        onClick={() => {
          setShowInput(true);
        }}
      >
        <IconOutlineSearchNormal1 size={16} />
      </IconButton>
    </Box>
  );
};
