import { Box, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

export const ContentIdeaListItemViewSkeleton = () => {
  return (
    <Box
      sx={{
        width: '100%',
        p: 2,
        bgcolor: theme.colors?.utility[250],
        borderRadius: 2,
        display: 'flex',
        gap: 3,
      }}
    >
      <Skeleton
        variant="rectangular"
        sx={{
          width: 75,
          height: 90,
          borderRadius: 1,
        }}
      />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height={24}
          sx={{
            borderRadius: 1,
          }}
        />
        <Skeleton
          variant="rectangular"
          width={100}
          height={24}
          sx={{
            borderRadius: 1,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mt: 'auto',
          }}
        >
          <Skeleton
            variant="rectangular"
            width={16}
            height={16}
            sx={{
              borderRadius: 1,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={16}
            height={16}
            sx={{
              borderRadius: 1,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={16}
            height={16}
            sx={{
              borderRadius: 1,
            }}
          />
          <Skeleton
            variant="rectangular"
            width={16}
            height={16}
            sx={{
              borderRadius: 1,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
