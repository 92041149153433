import { gql } from '@apollo/client';
import { SxProps } from '@mui/material';
import { PostFragmentPostNotePreviewCompactViewFragment } from 'graphql/generated';

export const POST_FRAGMENT_POST_NOTE_PREVIEW_COMPACT_VIEW = gql`
  fragment PostFragmentPostNotePreviewCompactView on PostModel {
    id
    title
    color
  }
`;

export interface PostNotePreviewCompactViewProps {
  post: PostFragmentPostNotePreviewCompactViewFragment;
  sx?: SxProps;
  componentsProps?: {
    title?: { sx?: SxProps };
    icon?: { size?: number };
  };
}
