import { createContext } from '@dwarvesf/react-utils';
import { ZoomOption } from 'components/common/Annotation/types';
import { useZoom } from '../hooks/useZoom';

export type ZoomManagerProviderProps = {
  children: React.ReactNode;
  defaultZoomOption?: ZoomOption;
};

interface ContextValues extends ReturnType<typeof useZoom> {}

const [Provider, useZoomManager] = createContext<ContextValues>({
  name: 'zoom-manager',
});

const ZoomManagerProvider = (props: ZoomManagerProviderProps) => {
  const { children, defaultZoomOption } = props;

  const zoomProps = useZoom({ defaultZoomOption });

  return (
    <Provider
      value={{
        ...zoomProps,
      }}
    >
      {children}
    </Provider>
  );
};

export { ZoomManagerProvider, useZoomManager };
