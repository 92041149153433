import { Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowRight3 } from 'components/icons/components/linear/IconLinearArrowRight3';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type Props = {
  brandId: string;
  onPrev: () => void;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingAddAnotherTopic = ({
  brandId,
  onNext,
  onPrev,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<
    'topic' | 'creator' | null
  >();
  const navigate = useNavigate();

  return (
    <Box
      width="50vw"
      maxHeight="65vh"
      display="flex"
      gap={6}
      flexDirection="column"
      alignItems="flex-start"
      color={theme.colors?.primary.parchment}
    >
      <IconButton
        sx={{
          color: theme.colors?.primary.parchment,
          transform: 'rotate(180deg)',
        }}
        disableRipple
        onClick={onPrev}
      >
        <IconLinearArrowRight3 size={20} />
      </IconButton>
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Add another topic
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        gap={8}
        mt={4}
        width="100%"
        sx={{
          '& .selected': {
            border: `2px solid ${theme.colors?.primary.parchment}`,
          },
        }}
      >
        <Box
          onClick={() => {
            setSelectedOption('topic');
          }}
          className={selectedOption === 'topic' ? 'selected' : ''}
          display="flex"
          gap={3}
          sx={{
            width: '100%',
            cursor: 'pointer',
            border: `2px solid transparent`,
            minWidth: 200,
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            borderRadius: 3,
            p: theme.spacing(3, 6),
            alignItems: 'center',
          }}
        >
          <Typography
            variant="headline-md"
            sx={{
              border: `2px solid ${theme.colors?.primary.parchment}`,
              p: theme.spacing(1, 2),
              borderRadius: theme.spacing(2),
            }}
          >
            A
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="headline-lg" fontWeight={600}>
              Topic Insights
            </Typography>
            <Typography variant="headline-sm" fontWeight={600}>
              Track conversations across customized topics
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => {
            setSelectedOption('creator');
          }}
          className={selectedOption === 'creator' ? 'selected' : ''}
          display="flex"
          gap={3}
          sx={{
            width: '100%',
            cursor: 'pointer',
            border: `2px solid transparent`,
            minWidth: 200,
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
            borderRadius: 3,
            p: theme.spacing(3, 6),
            alignItems: 'center',
          }}
        >
          <Typography
            variant="headline-md"
            sx={{
              border: `2px solid ${theme.colors?.primary.parchment}`,
              p: theme.spacing(1, 2),
              borderRadius: theme.spacing(2),
            }}
          >
            B
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="headline-lg" fontWeight={600}>
              Creator Focus
            </Typography>
            <Typography variant="headline-sm" fontWeight={600}>
              Track specific creators discussing your key topics
            </Typography>
          </Box>
        </Box>
      </Box>
      <RenderNextButton
        onNextClick={() => {
          if (!selectedOption) return;
          if (selectedOption === 'topic') {
            const url = PlotRoutes.socialListeningOnboarding('topic', brandId, {
              new: true,
            });
            navigate(url, {
              replace: true,
            });
          } else if (selectedOption === 'creator') {
            const url = PlotRoutes.socialListeningOnboarding(
              'creator',
              brandId,
              { new: true },
            );
            navigate(url, {
              replace: true,
            });
          }
        }}
        actionLabel="Next"
        // eslint-disable-next-line react/jsx-no-useless-fragment
        actionBtnEndIcon={<></>}
        disabled={!selectedOption}
        helperText={
          <Typography
            variant="body-xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            or{' '}
            <Box
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={onNext}
            >
              Set up later
            </Box>
          </Typography>
        }
      />
    </Box>
  );
};
