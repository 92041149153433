import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PostCommentListView } from 'features/comments';
import { PostFragmentPostDetailSidebarViewCommentsTabContentFragment } from 'graphql/generated';

export const POST_FRAGMENT_POST_DETAIL_SIDEBAR_VIEW_COMMENTS_TAB_CONTENT = gql`
  fragment PostFragmentPostDetailSidebarViewCommentsTabContent on PostModel {
    id
    annotations {
      id
    }
  }
`;

type Props = {
  post?: PostFragmentPostDetailSidebarViewCommentsTabContentFragment;
};

export const CommentsTabContent = (props: Props) => {
  const { post } = props;

  if (!post) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <PostCommentListView
        postId={post.id}
        hasAnnotations={post.annotations.length > 0}
        sx={{ flex: 1 }}
        componentsProps={{
          searchBox: {
            sx: {
              position: 'sticky',
              top: 0,
            },
          },
          commentListContainer: {
            sx: {
              px: 2,
            },
          },
          commentInputContainer: {
            sx: {
              px: 6,
              py: 4,
              position: 'sticky',
              bottom: 0,
            },
          },
        }}
      />
    </Box>
  );
};
