import {
  CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragment,
  PostFragmentJuiceboxTablePostTableRowWithContextMenuFragment,
} from 'graphql/generated';
import React, { CSSProperties, ReactNode } from 'react';
import { Updater } from 'use-immer';

export type ColumnData = {
  dataKey: string;
  label: ReactNode;
  style: CSSProperties;
};

export const columns: ColumnData[] = [
  {
    label: (
      <></>
      // <Box
      //   sx={{
      //     m: 2,
      //     ml: 2.5,
      //     cursor: 'pointer',
      //     width: theme.spacing(5),
      //     height: theme.spacing(5),
      //     border: `1px solid ${theme.colors?.utility[800]}`,
      //     borderRadius: theme.spacing(5),
      //   }}
      // />
    ),
    dataKey: 'icon',
    style: {
      flex: 1,
      width: '5%',
    },
  },
  {
    label: 'Name',
    dataKey: 'name',
    style: {
      flex: 6,
      width: '35%',
    },
  },
  {
    label: 'Preview',
    dataKey: 'preview',
    style: {
      flex: 6,
      width: '20%',
    },
  },
  {
    label: 'Permission',
    dataKey: 'permission',
    style: {
      flex: 4,
      width: '20%',
    },
  },
  {
    label: 'Activity',
    dataKey: 'activity',
    style: {
      flex: 4,
      width: '20%',
    },
  },
];

export type JuiceboxTableBaseTableViewContextData = {
  currentCollectionId?: string;
  collection?: CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragment;
  post?: PostFragmentJuiceboxTablePostTableRowWithContextMenuFragment;
  isRenaming?: boolean;
  searchParams?: Record<string, string>;
};

export type JuiceboxTableBaseTableViewContext = {
  context: JuiceboxTableBaseTableViewContextData;
  setContext: Updater<JuiceboxTableBaseTableViewContextData>;
  handleContextMenu: (e: React.MouseEvent<HTMLDivElement>) => void;
};
