import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  SxProps,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineHashtag } from 'components/icons/components/outline/IconOutlineHashtag';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  CUSTOM_COLLECTION,
  CollectionBreadcrumbs,
  customCollectionLabels,
} from 'features/collection';
import { useAutocompleteInfiniteCollections } from 'features/collection/hooks/useAutocompleteInfiniteCollections';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionPermission,
  PlotFeature,
  PostFragmentPostCollectionListFragment,
  SearchHitType,
  useDeleteTrendFavoriteForPostCollectionListMutation,
  useGetPostForPostCollectionListQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import { Tooltip } from 'components/common/Tooltip';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { PostBookmarkPopover } from '../bookmarkButton/PostBookmarkPopover';
import { usePostHandlers } from './usePostHandlers';

export const POST_FRAGMENT_POST_COLLECTION_LIST = gql`
  fragment PostFragmentPostCollectionList on PostModel {
    id
    myPermissions
    isFavorite
    isFavoriteTrend
    trend {
      id
      title
    }
    collections {
      id
      parentCollectionId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation DeleteTrendFavoriteForPostCollectionList(
    $data: DeleteTrendFavoriteInput!
  ) {
    deleteTrendFavorite(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetPostForPostCollectionList($id: String!) {
    post(id: $id) {
      id
      collections {
        id
        name
        myPermissions
        breadcrumbsFromRoot {
          id
          contentIdeaId
        }
        ...CollectionFragmentCollectionBreadcrumbs
      }
    }
  }
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

export type PostCollectionListProps = {
  isOpen?: boolean;
  post: PostFragmentPostCollectionListFragment;
  renderCustomBtn?: () => React.ReactNode;
  sx?: SxProps;
  shouldShowCollectionsPill?: boolean;
  tooltip?: string;
};

export const PostCollectionList = (props: PostCollectionListProps) => {
  const {
    post,
    isOpen,
    renderCustomBtn,
    sx,
    shouldShowCollectionsPill,
    tooltip,
  } = props;

  const navigate = useNavigate();
  const { isFeatureEnabled } = useFeatureFlagContext();

  const { orgBilling } = useUserContext();

  const isAllowedToOrganize = useMemo(() => {
    return (
      orgBilling?.collectionUsageLimit == null ||
      orgBilling?.collectionUsageLimit > 0
    );
  }, [orgBilling]);

  const postBookmarkAnchorElRef = useRef<HTMLButtonElement | null>(null);
  const [collectionPillAnchorEl, setCollectionPillAnchorEl] =
    useState<HTMLElement | null>(null);
  const {
    isOpen: isCollectionListPopoverOpen,
    onOpen: openCollectionListPopover,
    onClose: closeCollectionListPopover,
  } = useDisclosure({
    defaultIsOpen: !!isOpen,
  });
  const { collectionSearchHits = [], loadCollectionsData } =
    useAutocompleteInfiniteCollections({
      postIds: [post.id],
      searchStr: '',
    });

  const [deletePostFavoriteTrend] =
    useDeleteTrendFavoriteForPostCollectionListMutation();

  // Get list of collections that the post is already saved to
  const { data: postData, refetch: refetchPost } =
    useGetPostForPostCollectionListQuery({
      variables: {
        id: post.id,
      },
      fetchPolicy: 'cache-and-network',
    });
  const collectionsPostHasBeenSavedTo = postData?.post?.collections || [];
  const collectionIdsPostHasBeenSavedTo = collectionsPostHasBeenSavedTo.map(
    (c) => c.id,
  );
  const postCollections = postData?.post?.collections;

  const collections = useMemo(() => {
    return collectionSearchHits
      .map((x) => ({
        ...x.item,
        searchHitType: x.type,
      }))
      .filter((c) => c.myPermissions.includes(CollectionPermission.Update));
  }, [collectionSearchHits]);

  const analytics = useAnalytics();

  const { onTogglePostCollection } = usePostHandlers();
  const onSelectCollection = async (collectionId: string) => {
    // === Analytics ===
    const collection = collections.find((c) => c.id === collectionId);
    if (collection?.searchHitType === SearchHitType.Smart) {
      analytics.track(EventName.SuggestedCollectionSelected, {
        collectionId: collection.id,
      });
    }
    // === End of Analytics ===

    onTogglePostCollection(
      post.id,
      collectionId,
      !collectionIdsPostHasBeenSavedTo.includes(collectionId),
    ).then(() => {
      refetchPost();
    });
  };

  const onClose = () => {
    closeCollectionListPopover();
    refetchPost();
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', ...sx }}>
        {shouldShowCollectionsPill && (
          <>
            {post.isFavoriteTrend && (
              <Chip
                label={
                  <Typography variant="subhead-lg">
                    {customCollectionLabels[CUSTOM_COLLECTION.SAVED_TRENDS]}
                  </Typography>
                }
                variant="filled-borderless"
                sx={{
                  color: theme.colors?.primary.black,
                  alignItems: 'center',
                }}
                onDelete={() => {
                  deletePostFavoriteTrend({
                    variables: {
                      data: {
                        postId: post.id,
                      },
                    },
                    update: (cache) => {
                      modifyObject(cache, post.id, 'PostModel', {
                        isFavoriteTrend: () => {
                          return false;
                        },
                      });
                    },
                    onCompleted: () => {
                      refetchPost();
                    },
                  });
                }}
                onClick={() => {
                  navigate(
                    PlotRoutes.collection(CUSTOM_COLLECTION.SAVED_TRENDS),
                  );
                }}
              />
            )}

            {postCollections?.map((collection) => {
              const contentIdeaId = collection.breadcrumbsFromRoot.find(
                (item) => item.contentIdeaId !== null,
              )?.contentIdeaId;

              const label = (
                <CollectionBreadcrumbs
                  collection={collection}
                  delimiter="/"
                  ellipse
                  onCollectionClick={() => {}}
                  wrapBreadcrumbs={false}
                />
              );

              const canIRemoveThisCollection =
                collection.myPermissions.includes(CollectionPermission.Update);

              return (
                <>
                  <Chip
                    key={collection.id}
                    label={label}
                    variant="filled-borderless"
                    {...(contentIdeaId
                      ? {
                          icon: (
                            <IconBoldCalendar
                              size={16}
                              color={theme.colors?.primary.black}
                              style={{ flexShrink: 0 }}
                            />
                          ),
                        }
                      : {})}
                    sx={{ color: theme.colors?.primary.black }}
                    onDelete={
                      canIRemoveThisCollection
                        ? () => {
                            onSelectCollection(collection.id);
                          }
                        : undefined
                    }
                    onClick={(e) => {
                      if (
                        contentIdeaId &&
                        isFeatureEnabled(PlotFeature.ContentCalendar)
                      ) {
                        setCollectionPillAnchorEl(e.currentTarget);
                      } else {
                        navigate(PlotRoutes.collection(collection.id));
                      }
                    }}
                  />
                  <Popover
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: -8,
                      horizontal: 'center',
                    }}
                    PaperProps={{
                      sx: {
                        padding: 2,
                        borderRadius: 3,
                        background: `rgba(255, 255, 255, 0.90)`,
                        boxShadow: `0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)`,
                        border: 'none',
                        minWidth: 266,
                      },
                    }}
                    open={Boolean(collectionPillAnchorEl)}
                    anchorEl={collectionPillAnchorEl}
                    onClose={() => {
                      setCollectionPillAnchorEl(null);
                    }}
                  >
                    {[
                      {
                        icon: IconOutlineCalendar,
                        label: 'Go to Calendar',
                        onClick: () => {
                          navigate(PlotRoutes.contentCalendar(), {
                            state: {
                              view: 'split',
                              currentlyFocusedContentIdeaId: contentIdeaId,
                            },
                          });
                        },
                      },
                      {
                        icon: IconOutlineHashtag,
                        label: 'Go to Collection',
                        onClick: () => {
                          navigate(PlotRoutes.collection(collection.id));
                        },
                      },
                    ].map(({ icon, label, onClick }) => {
                      const Icon = icon;
                      return (
                        <IconButton
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            gap: 2,
                            padding: theme.spacing(2, 4),
                            borderRadius: 3,
                            width: '100%',
                            ':hover': {
                              bgcolor: `rgba(35, 6, 3, 0.1)`,
                              backdropFilter: `blur(25px)`,
                            },
                          }}
                          onClick={onClick}
                        >
                          <Box
                            sx={{
                              borderRadius: 1,
                              background: `rgba(35, 6, 3, 0.05)`,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 24,
                              height: 24,
                            }}
                          >
                            <Icon
                              size={16}
                              color={theme.colors?.primary.black}
                            />
                          </Box>
                          <Typography
                            variant="subhead-lg"
                            color={theme.colors?.primary.black}
                          >
                            {label}
                          </Typography>
                        </IconButton>
                      );
                    })}
                  </Popover>
                </>
              );
            })}
          </>
        )}

        <Tooltip
          title={
            isAllowedToOrganize ? (
              tooltip ?? 'Organize'
            ) : !orgBilling ? (
              ''
            ) : (
              <BillingCollectionLimitIndicator
                organizationBilling={orgBilling}
                variant="text"
              />
            )
          }
        >
          <Box>
            {renderCustomBtn ? (
              <Box
                ref={postBookmarkAnchorElRef}
                sx={{ cursor: 'pointer' }}
                onClick={
                  !isAllowedToOrganize
                    ? () => {}
                    : () => {
                        openCollectionListPopover();
                        loadCollectionsData();
                      }
                }
              >
                {renderCustomBtn()}
              </Box>
            ) : (
              <Button
                ref={postBookmarkAnchorElRef}
                disabled={!isAllowedToOrganize}
                variant="pill"
                onClick={() => {
                  openCollectionListPopover();
                  loadCollectionsData();
                }}
                sx={{ py: 1.5, px: 3, height: 32, minWidth: 'unset' }}
              >
                <IconLinearAdd size={12} style={{ opacity: 0.75 }} />
                <IconBoldSave size={16} />
              </Button>
            )}
          </Box>
        </Tooltip>
      </Box>

      {isAllowedToOrganize && (
        <PostBookmarkPopover
          post={post}
          open={isCollectionListPopoverOpen}
          anchorEl={postBookmarkAnchorElRef.current}
          onClose={onClose}
        />
      )}
    </>
  );
};
