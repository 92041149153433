import React, { useEffect, useState } from 'react';
import { SuggestionProps } from '@tiptap/suggestion';
import { Box, ListItemButton, Typography } from '@mui/material';
import { theme } from 'styles/theme/theme';
import data, { Emoji, EmojiMartData } from '@emoji-mart/data';
import { FrequentlyUsed, init, SearchIndex } from 'emoji-mart';
import { Editor, Range } from '@tiptap/react';

init({ data });

type EmojiListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

type EmojiListProps = Pick<SuggestionProps, 'command'> & {
  query: string;
  editor: Editor;
  range: Range;
};

export const EmojiList = React.forwardRef<EmojiListRef, EmojiListProps>(
  (props, ref) => {
    const { query = '', editor, range } = props;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [emojis, setEmojis] = useState<Emoji[]>([]);

    useEffect(() => {
      if (!query) {
        const emojis = FrequentlyUsed.DEFAULTS.map(
          (emojiId) => (data as EmojiMartData).emojis[emojiId],
        );
        setEmojis(emojis);
        setSelectedIndex(0);
        return;
      }
      async function search(value) {
        let emojis = await SearchIndex.search(`:${value}`);
        if (emojis.length === 0) {
          // for search emoji by meaning
          emojis = await SearchIndex.search(value);
        }
        setEmojis(emojis);
        setSelectedIndex(0);
      }
      search(query);
    }, [query]);

    const handleEmojiSelect = (emoji: Emoji) => {
      if (!editor || !range) return;
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .insertContent(emoji.skins[0]?.native)
        .run();
    };

    const minIndex = 0;
    const maxIndex = emojis.length - 1;
    const calculateNewIndex = (key: string) => {
      switch (key) {
        case 'ArrowUp':
          // 12 is number emoji item in a row in emoji bubble menu
          return selectedIndex - 12 < minIndex
            ? selectedIndex - 12 + Math.ceil(maxIndex / 12) * 12 < maxIndex
              ? selectedIndex - 12 + Math.ceil(maxIndex / 12) * 12
              : maxIndex
            : selectedIndex - 12;
        case 'ArrowDown':
          return selectedIndex + 12 > maxIndex
            ? selectedIndex + 12 - Math.ceil(maxIndex / 12) * 12 > minIndex
              ? selectedIndex + 12 - Math.ceil(maxIndex / 12) * 12
              : maxIndex
            : selectedIndex + 12;
        case 'ArrowLeft':
          return selectedIndex - 1 < minIndex ? maxIndex : selectedIndex - 1;
        case 'ArrowRight':
          return selectedIndex + 1 > maxIndex ? minIndex : selectedIndex + 1;
        default:
          return selectedIndex;
      }
    };

    React.useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        event.stopPropagation();
        switch (event.key) {
          case 'ArrowUp':
          case 'ArrowDown':
          case 'ArrowLeft':
          case 'ArrowRight':
            setSelectedIndex(calculateNewIndex(event.key));
            return true;
          case 'Enter':
          case 'Tab':
            handleEmojiSelect(emojis[selectedIndex]);
            return true;
          default:
            return false;
        }
      },
    }));

    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        padding={2}
        bgcolor="white"
        borderRadius="4px"
        boxShadow="0px 3px 12px -3px rgba(0, 0, 0, 0.16)"
        overflow="hidden"
        maxHeight={theme.spacing(110)}
        width="fit-content"
        flex={1}
      >
        {!emojis.length && (
          <Typography
            variant="subhead-lg"
            color={theme.colors?.utility[1000]}
            gridColumn="span 12"
          >
            No results
          </Typography>
        )}
        {emojis.map((each, index) => (
          <ListItemButton
            key={each.id}
            selected={index === selectedIndex}
            onClick={() => handleEmojiSelect(each)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 28,
              height: 28,
            }}
          >
            {each.skins[0]?.native}
          </ListItemButton>
        ))}
      </Box>
    );
  },
);
