import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearAddCircle } from 'components/icons/components/linear/IconLinearAddCircle';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { PostPreview } from 'features/post/components';
import {
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostSuggestionCardViewFragment,
  PostType,
} from 'graphql/generated';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_POST_SUGGESTION_CARD_VIEW = gql`
  fragment PostFragmentPostSuggestionCardView on PostModel {
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

interface Props {
  post: PostFragmentPostSuggestionCardViewFragment;
  onAddPostToCollection: () => void;
  onDeletePostFromCollection: () => void;
}

export const PostSuggestionCardView = ({
  post,
  onAddPostToCollection,
  onDeletePostFromCollection,
}: Props) => {
  const onDelete = () => {
    onDeletePostFromCollection();
  };

  return (
    <Box position="relative" overflow="hidden" borderRadius={3} flexShrink={0}>
      <Link
        target="_blank"
        to={{
          pathname:
            post.type === PostType.Note
              ? PlotRoutes.juiceboxNote({ id: post.id, title: post.title })
              : PlotRoutes.juice(post.id),
        }}
      >
        <PostPreview
          post={post}
          sx={{
            minHeight: 150,
            borderRadius: 3,
            backgroundColor: theme.colors?.primary.white,
          }}
          componentsProps={{
            postNotePreviewCompact: {
              componentsProps: {
                title: {
                  sx: {
                    '-webkit-line-clamp': 5,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    textAlign: 'left',
                  },
                },
              },
            },
          }}
        />
      </Link>
      <Box
        sx={{
          position: 'absolute',
          bottom: 12,
          left: '50%',
          transform: 'translateX(-50%)',
          '&:before': {
            content: '""',
            display: 'block',
            height: 155,
            width: 155,
            position: 'absolute',
            bottom: 0,
            left: 0,
            ml: -12,
            mb: -12,
          },
        }}
      >
        <Box
          display="flex"
          gap={6}
          alignItems="center"
          sx={{
            backgroundColor: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(${theme.spacing(7)})`,
            p: theme.spacing(2, 4),
            borderRadius: theme.spacing(25),
          }}
        >
          <IconLinearAddCircle
            size={20}
            color={theme.colors?.primary.black}
            style={{ cursor: 'pointer', position: 'relative' }}
            onClick={onAddPostToCollection}
          />
          <Box
            width="1px"
            height={theme.spacing(4)}
            sx={{ borderRight: `1px solid ${theme.colors?.primary.black}` }}
          />
          <IconLinearTrash
            size={20}
            color={theme.colors?.primary.black}
            style={{ cursor: 'pointer', position: 'relative' }}
            onClick={onDelete}
          />
        </Box>
      </Box>
    </Box>
  );
};
