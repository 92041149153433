import { gql } from '@apollo/client';
import {
  ContentIdeaFilters,
  ContentIdeaMetaStats,
  Stage,
  useGetContentIdeaMetaStatsForContentCalendarQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetContentIdeaMetaStatsForContentCalendar(
    $filters: ContentIdeaFilters
  ) {
    contentIdeaMetaStats(filters: $filters) {
      braindumpTotalCount
      productionTotalCount
      publishTotalCount
      iceboxTotalCount
    }
  }
`;

export const useContentIdeaMetaStats = (filters: ContentIdeaFilters) => {
  const { data, updateQuery, refetch } =
    useGetContentIdeaMetaStatsForContentCalendarQuery({
      variables: {
        filters,
      },
      fetchPolicy: 'cache-and-network',
    });

  const getUpdatedMetaStats = (
    stats: ContentIdeaMetaStats,
    stage: Stage,
    type: 'increment' | 'decrement',
  ) => {
    const contentIdeaMeta = { ...stats };
    switch (stage) {
      case Stage.BrainDump: {
        contentIdeaMeta.braindumpTotalCount += type === 'increment' ? 1 : -1;
        break;
      }
      case Stage.Production: {
        contentIdeaMeta.productionTotalCount += type === 'increment' ? 1 : -1;
        break;
      }
      case Stage.Publish: {
        contentIdeaMeta.publishTotalCount += type === 'increment' ? 1 : -1;
        break;
      }
      case Stage.IceBox: {
        contentIdeaMeta.iceboxTotalCount += type === 'increment' ? 1 : -1;
        break;
      }
      default: {
        break;
      }
    }
    return contentIdeaMeta;
  };

  const onUpdateContentIdeaMetaStats = (stage: Stage, prevStage?: Stage) => {
    updateQuery((prevData) => {
      let contentIdeaMeta = { ...prevData.contentIdeaMetaStats };
      contentIdeaMeta = getUpdatedMetaStats(
        contentIdeaMeta,
        stage,
        'increment',
      );

      if (prevStage) {
        contentIdeaMeta = getUpdatedMetaStats(
          contentIdeaMeta,
          prevStage,
          'decrement',
        );
      }

      return {
        ...prevData,
        contentIdeaMetaStats: contentIdeaMeta,
      };
    });
  };

  return {
    contentIdeaMetaStats: data?.contentIdeaMetaStats || {
      braindumpTotalCount: 0,
      productionTotalCount: 0,
      publishTotalCount: 0,
      iceboxTotalCount: 0,
    },
    onUpdateContentIdeaMetaStats,
    refetchContentIdeaMetaStats: refetch,
  };
};
