import {
  DateRangePickerProps,
  LocalizationProvider,
  DateRangePicker as MuiDateRangePicker,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { IconLinearArrowLeft1 } from 'components/icons/components/linear/IconLinearArrowLeft1';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import { theme } from 'styles/theme/theme';
import { useStyles } from './styles';
import { typography } from '../Typography/styles';

export const CUSTOM_RANGE_LABEL = 'Custom Range';

export const DateRangePicker = (props: DateRangePickerProps<moment.Moment>) => {
  const styles = useStyles();

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{ clearButtonLabel: 'Clear Selection' }}
    >
      <MuiDateRangePicker
        dayOfWeekFormatter={(day) => day.toUpperCase()}
        {...props}
        slots={{
          leftArrowIcon: IconLinearArrowLeft1,
          rightArrowIcon: IconLinearArrowRight1,
          ...props.slots,
        }}
        slotProps={{
          ...props.slotProps,
          actionBar: props.slotProps?.actionBar || {
            actions: ['clear'],
            style: {
              borderTop: `1px solid ${theme.colors?.utility[300]}`,
              padding: theme.spacing(2, 3),
              textDecorationLine: 'underline',
              ...typography['subhead-lg'],
              color: theme.colors?.utility[800],
            },
          },
          desktopPaper: {
            ...props.slotProps?.desktopPaper,
            classes: {
              root: [styles.default, styles.dateRange].join(' '),
              // @ts-ignore
              ...props.slotProps?.desktopPaper?.classes,
            },
            sx: {
              '& .MuiDateRangeCalendar-monthContainer': {
                borderRightColor: `${theme.colors?.utility[300]} !important`,
                borderRightWidth: '1px',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
