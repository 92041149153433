import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { theme } from 'styles/theme';

export const SLABrandInboundTopHashtagsViewSkeleton = () => {
  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="headline-sm"
          color={theme.colors?.utility[700]}
          pb={1}
          display="flex"
          alignItems="center"
          gap={2}
        >
          Top hashtags
          <Tooltip title="The ranking of hashtags is based on the engagement of the posts that the hashtag was used on ">
            <Box display="flex" alignItems="center">
              <IconBoldInfoCircle size={16} />
            </Box>
          </Tooltip>
        </Typography>

        <Skeleton
          sx={{ width: theme.spacing(30), height: theme.spacing(12) }}
        />
      </Box>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <Skeleton sx={{ height: theme.spacing(8) }} />
          <Skeleton sx={{ height: theme.spacing(8) }} />
          <Skeleton sx={{ height: theme.spacing(8) }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton sx={{ height: theme.spacing(8) }} />
          <Skeleton sx={{ height: theme.spacing(8) }} />
          <Skeleton sx={{ height: theme.spacing(8) }} />
        </Grid>
      </Grid>
    </Box>
  );
};
