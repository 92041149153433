import { gql } from '@apollo/client';
import { ArrowDropDown } from '@mui/icons-material';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { ContextMenu } from 'components/common/ContextMenu';
import { useAccessRequestHandlers } from 'features/accessRequest';
import {
  BrandInviteMemberFragmentSocialListeningPermissionMemberFragmentDoc,
  BrandInviteMemberModelFragmentApproveBrandInviteMemberFragment,
  PermissionLevel,
} from 'graphql/generated';
import React, { useState } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

type Props = {
  brandInviteMember: BrandInviteMemberModelFragmentApproveBrandInviteMemberFragment;
  onApprove: (permissionLevel: PermissionLevel) => void;
  onReject: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandInviteMemberModelFragmentApproveBrandInviteMember on BrandInviteMemberModel {
    ...BrandInviteMemberFragmentSocialListeningPermissionMember
  }
  ${BrandInviteMemberFragmentSocialListeningPermissionMemberFragmentDoc}
`;

export const ApproveBrandInviteMember = ({
  brandInviteMember,
  onApprove,
  onReject,
}: Props) => {
  const [currentPermission, setCurrentPermission] = useState(
    brandInviteMember.permissionLevel,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          gap: 2,
          py: 3,
        }}
      >
        <Avatar user={brandInviteMember.user} size={36} />
        <Box display="flex" flexDirection="column" flexGrow={1} gap={0.5}>
          <Box display="flex" gap={1} alignItems="center">
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
            >
              {getFullName(brandInviteMember.user)}
            </Typography>
          </Box>
          <Typography
            fontWeight={500}
            variant="body-lg"
            color={theme.colors?.utility['900']}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              paddingRight: '2px',
            }}
          >
            {brandInviteMember.user.email}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Button
          variant="text"
          onClick={() => {
            onReject();
          }}
        >
          Deny
        </Button>
      </Box>
      <Button
        variant="primary"
        sx={{
          height: theme.spacing(8),
          bgcolor: theme.colors?.primary.black,
          color: theme.colors?.primary.white,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          border: 'none',
          width: theme.spacing(41),
          overflow: 'hidden',
          '&:hover': {
            bgcolor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
          },
        }}
        onClick={() => {
          onApprove(currentPermission);
        }}
      >
        {currentPermission === PermissionLevel.Full
          ? 'Full access'
          : 'View only'}
      </Button>
      <ContextMenu
        renderButton={() => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => {}}
              disableRipple
              sx={{
                p: 3,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                height: theme.spacing(8),
                maxWidth: 2,
                bgcolor: theme.colors?.utility[800],
                color: theme.colors?.primary.white,
                border: 'none',
                '&:hover': {
                  bgcolor: theme.colors?.utility[800],
                  color: theme.colors?.primary.white,
                },
              }}
            >
              <ArrowDropDown />
            </IconButton>
          </Box>
        )}
        options={[
          {
            onClick: () => {
              setCurrentPermission(PermissionLevel.Full);
            },
            renderOption() {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body-lg"
                    color={theme.colors?.utility['800']}
                  >
                    Full access
                  </Typography>
                </Box>
              );
            },
          },
          {
            onClick: () => {
              setCurrentPermission(PermissionLevel.View);
            },
            renderOption() {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body-lg"
                    color={theme.colors?.utility['800']}
                  >
                    View only
                  </Typography>
                </Box>
              );
            },
          },
        ]}
      />
    </Box>
  );
};
