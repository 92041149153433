import { Box, styled } from '@mui/material';

export const StyledVideoContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  background: theme.colors?.utility.turnip,
  borderRadius: theme.spacing(6),
  border: 'none',
  height: '100%',
  width: '100%',

  '& .transparent-overlay': {
    position: 'absolute',
    inset: 0,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
    borderRadius: theme.spacing(6),
  },
  '& .video-overlay': {
    position: 'absolute',
    inset: 0,
    backgroundColor: theme.colors?.utility[500],
    borderRadius: theme.spacing(6),
  },

  '& .title': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    position: 'absolute',
    paddingRight: theme.spacing(10),
    bottom: theme.spacing(12),
    left: theme.spacing(10),
    color: theme.colors?.primary.white,
    alignItems: 'center',
  },

  '& .intro-cover': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(6),
  },

  '& .juicebox-button': {
    color: theme.colors?.primary.white,
    backgroundColor: theme.colors?.utility['pink-3'],
    borderRadius: theme.spacing(6),
    width: 'fit-content',
    '&:hover': {
      backgroundColor: theme.colors?.utility['pink-3'],
    },
    mb: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

export const StyledCloseButton = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  top: 24,
  right: 24,
  zIndex: 1,
}));
