import { UrlMetadataFragmentIframelyFragment } from 'graphql/generated';
import { CustomRenderingWidget } from './components/CustomRenderingWidget';
import {
  IframelyEmbedWidget,
  IframelyEmbedWidgetProps,
} from './components/IframelyEmbedWidget';
import { useCheckRenderingWidget } from './hooks/useCheckRenderingWidget';

interface Props {
  url: string;
  urlMetadata?: UrlMetadataFragmentIframelyFragment;
  onContentSizeUpdated?: (height: number, width: number) => void;

  /**
   * This field decides if the iframe should take up 100% of the container's width.
   * For details should refer to IframelyEmbedWidget
   *
   * Basically:
   * - If we are rendering this inside a JuiceCard (Masonry),
   *   we want it to take up 100% of the container & decide the final's container's height
   * - If we are rendering this inside the JuiceModal, we want the iframe to be more
   *   flexible because taking full width doesn't work in all cases. E.g. for a video/image
   *   we'll want to calculate the sizes in a way that looks good & whole in the modal container
   *
   * TODO: This is still not good enough. Need to think of a better solution.
   */
  fullWidth?: boolean;

  doNotUseCustomRendering?: boolean;

  componentsProps?: {
    iframelyEmbedWidget?: Pick<
      IframelyEmbedWidgetProps,
      'componentsProps' | 'sx' | 'shouldShowSkeleton' | 'onLoadingContent'
    >;
  };

  /**
   * Callback to inform parent component about the error
   * or the fact that Iframely cannot render a preview UI
   */
  onErrorOrNoPreview?: (error?: any) => void;
}

/* TODO: There's an issue where the Iframely component sometimes only renders the first time a post is viewed.
 Subsequent attempts to view the same post might not display the preview.
 A temporary solution is to pass a unique key prop to the Iframely component for proper React rendering behavior. */

export const Iframely = (props: Props) => {
  const {
    url,
    fullWidth = true,
    urlMetadata,
    doNotUseCustomRendering = false,
    componentsProps = {},
    onErrorOrNoPreview,
    onContentSizeUpdated,
  } = props;

  const mimeType = urlMetadata?.metadata?.type || '';
  const { isUsingCustomRenderingWidget, customRenderingElement } =
    useCheckRenderingWidget({ mimeType, url });

  if (!doNotUseCustomRendering && isUsingCustomRenderingWidget) {
    return <CustomRenderingWidget element={customRenderingElement} url={url} />;
  }

  return (
    <IframelyEmbedWidget
      url={url}
      onContentSizeUpdated={onContentSizeUpdated}
      urlMetadata={urlMetadata}
      fullWidth={fullWidth}
      componentsProps={componentsProps.iframelyEmbedWidget?.componentsProps}
      onErrorOrNoPreview={onErrorOrNoPreview}
      sx={componentsProps.iframelyEmbedWidget?.sx}
      shouldShowSkeleton={
        componentsProps.iframelyEmbedWidget?.shouldShowSkeleton
      }
      onLoadingContent={componentsProps.iframelyEmbedWidget?.onLoadingContent}
    />
  );
};
