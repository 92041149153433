import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Checkbox, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select/RadioMenuItem';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { SLATopHashtags } from 'features/socialListeningAnalytics/components/slaTopHashtags/SLATopHashtags';
import {
  BrandContentType,
  BrandFragmentSlaBrandInboundTopHashtagsViewFragment,
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForHashtagsUsedSortField,
  SortOrder,
  useGetHashtagsForBrandForSlaBrandInboundTopHashtagsViewQuery,
  useHideHashtagUsedForSlaBrandForSlaBrandInboundTopHashtagsViewMutation,
  useUnhideHashtagUsedForSlaBrandForSlaBrandInboundTopHashtagsViewMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { BrandTopHashtagPostsView } from '../slaBrandTopHashtagPosts/SLABrandTopHashtagPostsView';
import { SLABrandInboundTopHashtagsViewSkeleton } from './SLABrandInboundTopHashtagsViewSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetHashtagsForBrandForSLABrandInboundTopHashtagsView(
    $data: BrandInboundFiltersInputForHashtagsUsed!
  ) {
    hashtagsForBrand(data: $data) {
      name
      isHidden
      averageEngagementRate
      numberOfTimesUsed
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment HashtagUsedForBrandFragmentSLABrandInboundTopHashtagsView on HashtagUsedForBrandModel {
    isHidden
    name
    numberOfTimesUsed
    averageEngagementRate
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandFragmentSlaBrandInboundTopHashtagsView on BrandModel {
    id
    name
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation HideHashtagUsedForSlaBrandForSLABrandInboundTopHashtagsView(
    $data: HideHashtagUsedForBrandInput!
  ) {
    hideHashtagUsedForBrand(data: $data) {
      success
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UnhideHashtagUsedForSlaBrandForSLABrandInboundTopHashtagsView(
    $data: HideHashtagUsedForBrandInput!
  ) {
    unhideHashtagUsedForBrand(data: $data) {
      success
    }
  }
`;

type Props = {
  brand: BrandFragmentSlaBrandInboundTopHashtagsViewFragment;
  filters: BrandInboundFiltersInput;
};

export const SLABrandInboundTopHashtagsView = ({ brand, filters }: Props) => {
  const [includeHidden, setIncludeHidden] = useState(false);
  const [hiddenHashtags, setHiddenHashtags] = useState([] as string[]);
  const [sortKey, setSortKey] = useState(
    BrandInboundFiltersInputForHashtagsUsedSortField.AverageEngagementRate,
  );

  const [selectedHashtag, setSelectedHashtag] = useState('');
  const {
    isOpen: isTopHashtagsPostModalOpen,
    onOpen: onOpenTopHashtagsPostModal,
    onClose: onCloseTopHashtagsPostModal,
  } = useDisclosure();

  const { data, loading: isLoadingData } =
    useGetHashtagsForBrandForSlaBrandInboundTopHashtagsViewQuery({
      variables: {
        data: {
          ...filters,
          showHidden: includeHidden,
          sortBy: {
            field: sortKey,
            order: SortOrder.Desc,
          },
        },
      },
      skip:
        filters.contentType === BrandContentType.Topic &&
        !filters.topicIds?.length,
    });

  const hashtagsForBrand = data?.hashtagsForBrand || [];

  useEffect(() => {
    setHiddenHashtags(
      hashtagsForBrand.filter((d) => d.isHidden).map((d) => d.name),
    );
  }, [JSON.stringify(hashtagsForBrand)]); // eslint-disable-line

  const [hideHashtagUsed] =
    useHideHashtagUsedForSlaBrandForSlaBrandInboundTopHashtagsViewMutation();
  const [unhideHashtagUsed] =
    useUnhideHashtagUsedForSlaBrandForSlaBrandInboundTopHashtagsViewMutation();

  if (
    isLoadingData ||
    (filters.contentType === BrandContentType.Topic &&
      !filters.topicIds?.length)
  ) {
    return <SLABrandInboundTopHashtagsViewSkeleton />;
  }

  return (
    <>
      <SLATopHashtags
        componentsProps={{
          sx: {
            height: '100%',
          },
        }}
        hashtagValues={hashtagsForBrand.map((h) => [
          h.name,
          sortKey ===
          BrandInboundFiltersInputForHashtagsUsedSortField.AverageEngagementRate
            ? `${
                String(
                  formatBigNumber(
                    parseFloat(
                      (typeof h.averageEngagementRate === 'number'
                        ? h.averageEngagementRate * 100
                        : 0
                      ).toFixed(2),
                    ),
                  ),
                ) || '0'
              }%`
            : String(formatBigNumber(h?.numberOfTimesUsed || 0)),
        ])}
        disabledHashtagValues={hiddenHashtags}
        title="Top Hashtags"
        renderFilter={() => (
          <ContextMenu
            sx={{
              '& .context-menu-item': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={[
              {
                renderCustomComponent: () => (
                  <Typography fontWeight={600} px={3} variant="body-lg">
                    Sort by
                  </Typography>
                ),
              },
              {
                renderOption: () => (
                  <RadioMenuItem
                    label="Post engagement rate"
                    value={
                      BrandInboundFiltersInputForHashtagsUsedSortField.AverageEngagementRate
                    }
                    checked={
                      sortKey ===
                      BrandInboundFiltersInputForHashtagsUsedSortField.AverageEngagementRate
                    }
                    sx={{
                      p: `${theme.spacing(2, 3)}`,
                    }}
                  />
                ),
                onClick: () => {
                  setSortKey(
                    BrandInboundFiltersInputForHashtagsUsedSortField.AverageEngagementRate,
                  );
                },
              },
              {
                renderOption: () => (
                  <RadioMenuItem
                    label="Number of times used"
                    value={
                      BrandInboundFiltersInputForHashtagsUsedSortField.NumberOfTimesUsed
                    }
                    checked={
                      sortKey ===
                      BrandInboundFiltersInputForHashtagsUsedSortField.NumberOfTimesUsed
                    }
                    sx={{
                      p: `${theme.spacing(2, 3)}`,
                    }}
                  />
                ),
                onClick: () => {
                  setSortKey(
                    BrandInboundFiltersInputForHashtagsUsedSortField.NumberOfTimesUsed,
                  );
                },
              },
              {
                isDivider: true,
              },
              {
                renderCustomComponent: () => (
                  <Typography
                    fontWeight={600}
                    px={3}
                    pt={3}
                    variant="body-lg"
                    display="block"
                  >
                    Show
                  </Typography>
                ),
              },
              {
                renderOption: () => (
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      setIncludeHidden(!includeHidden);
                      // setHashTags([]);
                      // setDisabledHashtags([]);
                    }}
                  >
                    <Checkbox
                      sx={{
                        '&.Mui-checked': { color: theme.colors?.utility[800] },
                      }}
                      checked={includeHidden}
                      disableRipple
                    />
                    <Typography variant="body-lg">Hidden hashtatgs</Typography>
                  </Box>
                ),
              },
            ]}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': { backgroundColor: theme.colors?.utility[275] },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  {sortKey ===
                    BrandInboundFiltersInputForHashtagsUsedSortField.AverageEngagementRate &&
                    'Post engagement rate'}
                  {sortKey ===
                    BrandInboundFiltersInputForHashtagsUsedSortField.NumberOfTimesUsed &&
                    'Number of times used'}
                </Typography>
              </Button>
            )}
          />
        )}
        onHashtagClick={(name) => {
          setSelectedHashtag(name);
          onOpenTopHashtagsPostModal();
        }}
        onHideHashtag={(name) =>
          hideHashtagUsed({
            variables: {
              data: {
                brandId: filters.brandId,
                hashtagName: name,
              },
            },
            onCompleted: () => {
              setHiddenHashtags([...hiddenHashtags, name]);
            },
          })
        }
        onUnhideHashtag={(name) =>
          unhideHashtagUsed({
            variables: {
              data: {
                brandId: filters.brandId,
                hashtagName: name,
              },
            },
            onCompleted: () => {
              setHiddenHashtags(hiddenHashtags.filter((h) => h !== name));
            },
          })
        }
      />
      {selectedHashtag && (
        <BrandTopHashtagPostsView
          brand={brand}
          hashtag={selectedHashtag}
          filters={filters}
          onClose={onCloseTopHashtagsPostModal}
          isOpen={isTopHashtagsPostModalOpen}
        />
      )}
    </>
  );
};
