import { Box, Dialog, IconButton, SxProps } from '@mui/material';
import { useVideoPlayer } from 'components/common/Media/components/Video/hooks/useVideoPlayer';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { theme } from 'styles/theme';

interface Props {
  onClose: VoidFunction;
  sx?: SxProps;
}

export const ContentCalendarOnboardingVideoModal = ({ onClose, sx }: Props) => {
  const { videoUI } = useVideoPlayer({
    videoUrl: '/videos/ContentCalendarDemo.mp4',
    videoSx: {
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(6),
    },
    videoControlsSx: {
      borderRadius: 25,
      background: `rgba(35, 6, 3, 0.20)`,
      backdropFilter: `blur(20px)`,
      bottom: theme.spacing(4.5),
      height: theme.spacing(11),
      padding: theme.spacing(0, 4.5),
      '& svg': {
        height: 20,
        width: 20,
      },
      '& .MuiSelect-select span': {
        ...theme.typography['headline-xxs'],
      },
    },
  });
  return (
    <Dialog
      open
      fullScreen
      PaperProps={{
        sx: {
          background: `rgba(35, 6, 3, 0.20)`,
          backdropFilter: `blur(38px)`,
          border: 'none',
          padding: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          '& .MuiLinearProgress-root': {
            width: `calc(100% - 32px)`,
          },
          maxHeight: '100vh',
          maxWidth: '100vw',
          p: 0,
          m: 0,
          ...sx,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          maxHeight: 660,
          // useVideoPlayer hook is return UI wrapped in divs with no ability to customize
          // so we have to do it this way
          '> div': {
            height: '100%',
            '> div': {
              height: '100%',
            },
          },
        }}
      >
        {videoUI}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          margin: 5,
        }}
      >
        <IconButton onClick={onClose}>
          <IconBoldCloseCircle size={36} color="#FAF3ECBF" />
        </IconButton>
      </Box>
    </Dialog>
  );
};
