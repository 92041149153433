import { Box, Divider, Skeleton } from '@mui/material';
import { MasonrySkeleton } from 'components/common/Skeleton';
import { theme } from 'styles/theme/theme';

export const TaskDetailViewSkeleton = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="white"
      width="100%"
      padding={8}
      gap={8}
    >
      <Box display="flex" flexDirection="column" bgcolor="white" gap={6}>
        <Box display="flex" justifyContent="space-between">
          <Skeleton variant="rounded" width={40} height={14} />
          <Skeleton variant="rounded" width={24} height={24} />
        </Box>

        <Skeleton variant="rounded" width="60%" height={32} />

        <Box display="flex" gap={2}>
          <Skeleton variant="rounded" width={120} height={36} />
          <Skeleton variant="rounded" width={120} height={36} />
          <Skeleton variant="rounded" width={120} height={36} />
          <Skeleton variant="rounded" width={120} height={36} />
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          <Skeleton variant="rounded" width="100%" height={20} />
          <Skeleton variant="rounded" width="30%" height={20} />
        </Box>
        <Divider
          sx={{
            borderBottom: `3px solid ${theme.colors?.utility[300]}`,
            mt: 4,
          }}
        />
        <Box display="flex" flexDirection="column" bgcolor="white" gap={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Skeleton variant="rounded" width={12} height={12} />
              <Skeleton variant="text" width={150} height={30} />
            </Box>
            <Skeleton variant="rounded" width={103} height={30} />
          </Box>
          {[...Array(2)].map((_, index) => (
            <Box key={index} display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="flex-start" gap={2}>
                <Skeleton
                  variant="circular"
                  height={16}
                  width={16}
                  sx={{ mt: 1 }}
                />
                <Box display="grid">
                  <Skeleton variant="text" width={200} height={22} />
                  <Skeleton variant="text" width={100} height={16} />
                </Box>
              </Box>
              <Box display="flex" gap={3}>
                <Skeleton variant="rounded" height={24} width={24} />
                <Skeleton variant="circular" height={24} width={24} />
              </Box>
            </Box>
          ))}
        </Box>
        <Divider
          sx={{
            borderBottom: `3px solid ${theme.colors?.utility[300]}`,
            mt: 4,
          }}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={2}>
            <Skeleton variant="rounded" width={12} height={12} />
            <Skeleton variant="text" width={150} height={30} />
          </Box>
          <Skeleton variant="rounded" width={103} height={30} />
        </Box>
        <MasonrySkeleton />
      </Box>
    </Box>
  );
};
