import {
  FileFilterType,
  LinkFilterType,
  PostFiltersForSmartSearch,
  SortType,
  UserFilterType,
  UserProfileModel,
} from 'graphql/generated';
import moment from 'moment';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateFilterType } from '../filters';

export enum JuiceboxFilterType {
  // Type filters (aka. what to include in the response)
  // TODO: This should be in a separate enum. Those are the values of a filter, not the filter type itself
  Notes = 'Notes',
  Links = 'Links',
  Uploads = 'Uploads',
  Collections = 'Collections',

  // People filters
  Users = 'Users',

  // Reaction filters
  Reactions = 'Reactions',

  // Collection filters (filter by collection id)
  // Not to be confused with filtering by type Collections
  PostCollections = 'PostCollections',

  Date = 'Date',
}

export interface JuiceboxDateType {
  startDate: Date;
  endDate: Date;
}

export interface JuiceboxFilter {
  label?: ReactNode;
  value: string; // where we put the identifier (user.id, collection.id, etc.)
  item?: UserProfileModel | JuiceboxDateType; // add additional model data if needed
  itemType?: UserFilterType; // same here
  filterType: JuiceboxFilterType;
}

export type UseJuiceboxFilterProps = {
  filters: PostFiltersForSmartSearch;
  sortType?: SortType;
  setFilters: (filters: PostFiltersForSmartSearch) => void;

  /**
   * NOTE: This is not used anywhere in the main logic of this hook.
   * Its only responsibility is to be set to location state together with selectedFilters
   * so it could be loaded as cache.
   */
  query?: string;
};

export const useJuiceboxFilter = ({
  query,
  filters,
  sortType,
  setFilters,
}: UseJuiceboxFilterProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedFilters, setSelectedFilters] = useState<JuiceboxFilter[]>(
    (
      location.state as {
        selectedFilters?: JuiceboxFilter[];
      }
    )?.selectedFilters ?? [],
  );

  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const { canSkipCollectionQuery, canSkipPostQuery } = useMemo(() => {
    const filterTypes = selectedFilters.map((f) => f.filterType);

    // Will not skip collection query if:
    // 1. No filters are selected, OR
    // 2. We are filtering by Collections or PostCollections
    const canSkipCollectionQuery =
      filterTypes.length === 0
        ? false
        : !(
            filterTypes.includes(JuiceboxFilterType.Collections) ||
            filterTypes.includes(JuiceboxFilterType.PostCollections)
          );

    // Will not skip post query if:
    // 1. No filters are selected, OR
    // 2. We are NOT filtering by Collections type only
    const canSkipPostQuery =
      filterTypes.length === 0
        ? false
        : filterTypes.includes(JuiceboxFilterType.Collections) &&
          filterTypes.filter((t) =>
            [
              JuiceboxFilterType.Collections,
              JuiceboxFilterType.Notes,
              JuiceboxFilterType.Links,
              JuiceboxFilterType.Uploads,
            ].includes(t),
          ).length === 1;

    return {
      canSkipCollectionQuery,
      canSkipPostQuery,
    };
  }, [selectedFilters]);

  useEffect(() => {
    let dateRange;
    const selectedDateFilter = selectedFilters.find(
      (f) => f.filterType === JuiceboxFilterType.Date,
    );
    switch (selectedDateFilter?.value) {
      case DateFilterType.Today:
        dateRange = {
          startDate: moment().startOf('day').toDate(),
          endDate: moment().toDate(),
        };
        break;
      case DateFilterType.Last7Days:
        dateRange = {
          startDate: moment().subtract(7, 'days').toDate(),
          endDate: moment().toDate(),
        };
        break;
      case DateFilterType.Last30Days:
        dateRange = {
          startDate: moment().subtract(30, 'days').toDate(),
          endDate: moment().toDate(),
        };
        break;
      case DateFilterType.ThisYear:
        dateRange = {
          startDate: moment().startOf('year').toDate(),
          endDate: moment().toDate(),
        };
        break;
      case DateFilterType.LastYear:
        dateRange = {
          startDate: moment().subtract(1, 'year').startOf('year').toDate(),
          endDate: moment().subtract(1, 'year').endOf('year').toDate(),
        };
        break;
      case DateFilterType.CustomRange:
        if (selectedDateFilter.item) {
          const date = selectedDateFilter.item as JuiceboxDateType;
          dateRange = {
            startDate: date.startDate,
            endDate: date.endDate,
          };
          return;
        }
        dateRange = {
          startDate: null,
          endDate: null,
        };
        break;
      default:
        dateRange = { startDate: null, endDate: null };
    }

    const updatedFilters = {
      ...filters,
      noteFilterInput: {
        selectAll: selectedFilters.some(
          (f) => f.value === JuiceboxFilterType.Notes,
        ),
      },
      fileFilterInput: {
        selectAll: selectedFilters.some(
          (f) => f.value === JuiceboxFilterType.Uploads,
        ),
        types: selectedFilters
          .filter(
            (sf) =>
              sf.filterType === JuiceboxFilterType.Uploads &&
              sf.value !== JuiceboxFilterType.Uploads,
          )
          .map((f) => f.value) as FileFilterType[],
      },
      linkFilterInput: {
        selectAll: selectedFilters.some(
          (f) => f.value === JuiceboxFilterType.Links,
        ),
        types: selectedFilters
          .filter(
            (sf) =>
              sf.filterType === JuiceboxFilterType.Links &&
              sf.value !== JuiceboxFilterType.Links,
          )
          .map((f) => f.value) as LinkFilterType[],
      },
      userFilterInput: {
        data: selectedFilters
          .filter((f) => f.filterType === JuiceboxFilterType.Users)
          .map((f) => ({
            ...(f.itemType ? { type: f.itemType } : {}),
            userId: f.value,
          })),
      },
      collectionIds: selectedFilters
        .filter((f) => f.filterType === JuiceboxFilterType.PostCollections)
        .map((f) => f.value),
      reactionFilterInput: {
        emojis: selectedFilters
          .filter((f) => f.filterType === JuiceboxFilterType.Reactions)
          .map((f) => f.value),
      },
      dateFilterInput:
        dateRange.startDate && dateRange.endDate ? dateRange : null,
    };

    setFilters({
      ...updatedFilters,
    });

    setIsFilterApplied(selectedFilters.length > 0);

    navigate(location, {
      replace: true,
      state: {
        ...((location?.state || {}) as any),
        selectedFilters,
        filters: selectedFilters.length > 0 ? updatedFilters : {},
        sortType,
        query,
      },
    });
  }, [JSON.stringify({ selectedFilters, sortType, query })]); // eslint-disable-line

  return {
    isFilterApplied,
    canSkipCollectionQuery,
    canSkipPostQuery,
    selectedFilters,
    setSelectedFilters,
  };
};
