import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import { CollectionBreadcrumbs } from 'features/collection';
import { CardStackAlt } from 'features/collection/components/multiPostPreview/variants';
import {
  CommentContainerView,
  usePostCommentHandlers,
} from 'features/comments';
import {
  CollectionPermission,
  CommentFragmentCollectionCommentViewFragment,
  CommentInputData,
  useGetCollectionForCollectionCommentViewQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { COLLECTION_FRAGMENT_COLLECTION_COMMENT_VIEW } from './types';

// eslint-disable-next-line
gql`
  query GetCollectionForCollectionCommentView($id: String!) {
    collection(id: $id) {
      id
      ...CollectionFragmentCollectionCommentView
    }
  }
  ${COLLECTION_FRAGMENT_COLLECTION_COMMENT_VIEW}
`;

export type CollectionCommentViewProps = {
  comments: CommentFragmentCollectionCommentViewFragment[];
  collectionId: string;
  shouldExpand?: boolean;
  onReply: (comment: CommentInputData) => void;
};

export const CollectionCommentView = (props: CollectionCommentViewProps) => {
  const { comments, collectionId, shouldExpand, onReply } = props;

  const { data } = useGetCollectionForCollectionCommentViewQuery({
    variables: {
      id: collectionId,
    },
  });
  const collection = data?.collection;
  const isNestedCollection = collection?.parentCollectionId !== null;

  const { onReactToComment } = usePostCommentHandlers();

  if (!collection || !comments.length) {
    return null;
  }

  return (
    <CollapsibleSection
      defaultIsOpen={shouldExpand}
      iconPosition="left"
      sx={{ mt: theme.spacing(3) }}
      renderHeaderTitle={() => (
        <Box
          pr={theme.spacing(2)}
          pl={theme.spacing(1)}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={theme.spacing(2)}
          overflow="hidden"
        >
          <Box>
            <CollectionBreadcrumbs
              collection={collection}
              ellipseAtLength={4}
              delimiter="/"
              wrapBreadcrumbs
              componentProps={{
                text: {
                  sx: {
                    ...theme.typography['headline-md'],
                  },
                  lastLocationSx: {
                    color: theme.colors?.primary.black,
                  },
                },
              }}
            />
          </Box>

          {isNestedCollection && (
            <Box
              sx={{
                mt: '10px',
                width: 35,
                height: 41,
              }}
            >
              <CardStackAlt
                collection={collection}
                componentsProps={{
                  card: {
                    sx: {
                      width: '100%',
                      border: `1px solid ${theme.colors?.primary.white}`,
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>
      )}
    >
      <Box sx={{ wordBreak: 'break-word', py: 2 }}>
        {comments.map((comment) => {
          return (
            <CommentContainerView
              key={comment.id}
              canComment={collection.myPermissions.includes(
                CollectionPermission.Comment,
              )}
              comment={comment}
              onReact={(commentId, emoji) => {
                onReactToComment(commentId, emoji);
              }}
              onReply={(data) => {
                onReply({
                  ...data,
                  parentThreadId: comment.id,
                });
              }}
            />
          );
        })}
      </Box>
    </CollapsibleSection>
  );
};
