import { Box, Button, Card, Skeleton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { Tooltip as CustomTooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { useGraphDataMakerNew } from 'features/socialMediaListening/hooks/useGraphDataMakerNew';
import {
  CompetitorFlatCreatorGraphDataAggregationType,
  useGetCompetitorFlatCreatorGraphDataForBrandCompetitorGraphQuery,
} from 'graphql/generated';
import { useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { BrandCompetitorGraphProps } from './types';

const sortKeyOptions = [
  {
    label: 'Total post count',
    value: CompetitorFlatCreatorGraphDataAggregationType.TotalPostCount,
  },
  {
    label: 'Total engagement',
    value: CompetitorFlatCreatorGraphDataAggregationType.TotalEngagement,
  },
  {
    label: 'Total view count',
    value: CompetitorFlatCreatorGraphDataAggregationType.TotalImpressions,
  },
];

export const BrandCompetitorGraph = ({
  filters,
}: BrandCompetitorGraphProps) => {
  const { dateRange } = filters;
  const [sortKey, setSortKey] = useState(
    CompetitorFlatCreatorGraphDataAggregationType.TotalPostCount,
  );
  const { data, loading } =
    useGetCompetitorFlatCreatorGraphDataForBrandCompetitorGraphQuery({
      variables: {
        data: { ...filters, aggregationType: sortKey },
      },
      skip: !filters.creatorId,
      fetchPolicy: 'cache-and-network',
    });
  const graphData = data?.getCompetitorFlatCreatorGraphData;

  const { chartData } = useGraphDataMakerNew({
    dailyChartData: graphData?.chartDataPoints || [],
    dateRange: [dateRange.startDate, dateRange.endDate],
  });

  const isEmpty = chartData.every((p) => !p.Y);

  return (
    <Box>
      <Card
        sx={{
          borderRadius: 5,
          my: 2,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={1}
          mb={3}
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[700]}
              pb={1}
              display="flex"
              alignItems="center"
              gap={2}
            >
              Graph
              <CustomTooltip
                title={`The total count of ${
                  sortKey ===
                  CompetitorFlatCreatorGraphDataAggregationType.TotalPostCount
                    ? 'posts'
                    : sortKey ===
                      CompetitorFlatCreatorGraphDataAggregationType.TotalEngagement
                    ? 'engagement'
                    : 'impressions'
                } for the selected date range.`}
              >
                <Box display="flex" alignItems="center">
                  <IconBoldInfoCircle size={16} />
                </Box>
              </CustomTooltip>
            </Typography>

            <Box>
              <ContextMenu
                sx={{
                  '& .context-menu-item': {
                    p: 0,
                    color: theme.colors?.primary.black,
                  },
                }}
                options={[
                  ...sortKeyOptions.map((option) => ({
                    renderOption: () => (
                      <RadioMenuItem
                        label={option.label}
                        value={option.value}
                        checked={sortKey === option.value}
                        sx={{
                          p: `${theme.spacing(2, 3)}`,
                        }}
                      />
                    ),
                    onClick: () => {
                      setSortKey(option.value);
                    },
                  })),
                ]}
                renderButton={() => (
                  <Button
                    sx={{
                      backgroundColor: theme.colors?.utility[275],
                      color: theme.colors?.primary.black,
                      borderRadius: theme.spacing(2),
                      display: 'flex',
                      gap: 2,
                      height: 'fit-content',
                      p: theme.spacing(1, 2),
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: theme.colors?.utility[275],
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'rgba(35, 6, 3, 0.05);',
                        p: theme.spacing(1),
                        borderRadius: theme.spacing(1),
                      }}
                    >
                      <IconLinearSort size={16} />
                    </Box>
                    <Typography fontWeight={500} variant="body-xl">
                      {
                        sortKeyOptions.find(
                          (option) => option.value === sortKey,
                        )?.label
                      }
                    </Typography>
                  </Button>
                )}
              />
            </Box>
          </Box>
          <Typography variant="headline-lg" fontSize={theme.spacing(8)} mt={2}>
            {formatBigNumber(graphData?.totalCount || 0)}{' '}
            <Box component="span" color={theme.colors?.utility[600]}>
              {sortKey ===
              CompetitorFlatCreatorGraphDataAggregationType.TotalPostCount
                ? 'posts'
                : sortKey ===
                  CompetitorFlatCreatorGraphDataAggregationType.TotalEngagement
                ? 'total engagement'
                : 'view count'}
            </Box>
          </Typography>
        </Box>
        {!isEmpty && (
          <ResponsiveContainer width="100%" height={400}>
            {loading ? (
              <Skeleton />
            ) : (
              <AreaChart
                data={chartData || []}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={theme.colors?.utility[300]}
                />
                <XAxis dataKey="X" />
                <Tooltip
                  formatter={(value) => [
                    formatBigNumber(Number(value)),
                    sortKey ===
                    CompetitorFlatCreatorGraphDataAggregationType.TotalPostCount
                      ? 'Posts'
                      : sortKey ===
                        CompetitorFlatCreatorGraphDataAggregationType.TotalEngagement
                      ? 'Engagement'
                      : 'Views',
                  ]}
                />
                <Area
                  type="monotone"
                  dataKey="Y"
                  stroke={theme.colors?.utility[600]}
                  strokeWidth={3}
                  fill="rgba(61, 43, 43, 0.5)"
                />
              </AreaChart>
            )}
          </ResponsiveContainer>
        )}
        {isEmpty && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 284,
              borderRadius: 3,
              bgcolor: theme.colors?.utility[275],
            }}
          >
            <Typography
              variant="headline-sm"
              textAlign="center"
              color={theme.colors?.utility[700]}
            >
              No data available for this date range
            </Typography>
          </Box>
        )}
      </Card>
    </Box>
  );
};
