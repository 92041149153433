import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomFacebook2 } from 'components/icons/components/custom/IconCustomFacebook2';
import { IconCustomLinkedin2 } from 'components/icons/components/custom/IconCustomLinkedin2';
import { IconCustomPinterest } from 'components/icons/components/custom/IconCustomPinterest';
import { IconCustomReddit } from 'components/icons/components/custom/IconCustomReddit';
import { IconCustomSnapchat } from 'components/icons/components/custom/IconCustomSnapchat';
import { IconCustomThread } from 'components/icons/components/custom/IconCustomThread';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomX } from 'components/icons/components/custom/IconCustomX';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { IconLinearYoutube } from 'components/icons/components/linear/IconLinearYoutube';
import {
  CollectionFragmentContentCalendarFilterByCollectionsFragment,
  FieldType,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export type SelectedSort = {
  name: string;
  value:
    | null
    | 'Publishing Date'
    | 'Status'
    | 'Platforms'
    | 'Pillars'
    | 'Assignee';
};

export const platformIconMap = [
  {
    name: 'Tiktok',
    color: theme.colors?.utility['purple-4'],
    icon: (
      <IconCustomTiktok size={16} color={theme.colors?.utility['purple-4']} />
    ),
  },
  {
    name: 'Facebook',
    color: theme.colors?.utility['blueberry'],
    icon: (
      <IconCustomFacebook2
        size={16}
        color={theme.colors?.utility['blueberry']}
      />
    ),
  },
  {
    name: 'Instagram',
    color: theme.colors?.utility['orange-4'],
    icon: (
      <IconBoldInstagram size={16} color={theme.colors?.utility['orange-4']} />
    ),
  },
  {
    name: 'Twitter',
    color: theme.colors?.utility['green-4'],
    icon: <IconCustomX size={16} color={theme.colors?.utility['green-4']} />,
  },
  {
    name: 'LinkedIn',
    color: theme.colors?.utility['teal-4'],
    icon: (
      <IconCustomLinkedin2 size={16} color={theme.colors?.utility['teal-4']} />
    ),
  },
  {
    name: 'Threads',
    color: theme.colors?.utility['purple-4'],
    icon: (
      <IconCustomThread size={16} color={theme.colors?.utility['purple-4']} />
    ),
  },
  {
    name: 'Reddit',
    color: theme.colors?.utility['yellow-4'],
    icon: (
      <IconCustomReddit size={16} color={theme.colors?.utility['yellow-4']} />
    ),
  },
  {
    name: 'Youtube',
    color: theme.colors?.utility['pink-4'],
    icon: (
      <IconLinearYoutube size={16} color={theme.colors?.utility['pink-4']} />
    ),
  },
  {
    name: 'YoutubeShorts',
    color: theme.colors?.utility['teal-4'],
    icon: (
      <IconCustomYoutubeShorts
        size={16}
        color={theme.colors?.utility['teal-4']}
      />
    ),
  },
  {
    name: 'Pinterest',
    color: theme.colors?.utility['blueberry'],
    icon: (
      <IconCustomPinterest
        size={16}
        color={theme.colors?.utility['blueberry']}
      />
    ),
  },
  {
    name: 'Snapchat',
    color: theme.colors?.utility['orange-4'],
    icon: (
      <IconCustomSnapchat size={16} color={theme.colors?.utility['orange-4']} />
    ),
  },
];

export type ContentCalendarFilters = Record<
  string,
  {
    name: string;
    type: FieldType;
    label?: string;
    selected?: string[];
    collections?: CollectionFragmentContentCalendarFilterByCollectionsFragment[]; // for collections filter only
    startDate?: string; // for date filter only
    endDate?: string; // for date filter only
  }
>;
