import { gql } from '@apollo/client';
import { Box, Tooltip, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import {
  CollectionListItemSkeleton,
  CollectionListItemView,
  CollectionPeekingCardView,
} from 'features/collection/views';
import {
  CollectionFilters,
  CollectionFragmentCollectionListItemViewFragmentDoc,
  CollectionFragmentCollectionPeekingCardViewFragmentDoc,
  CollectionFragmentSimilarCollectionListFragment,
  SearchHitType,
  useGetCollectionsSimilarSearchForSimilarCollectionListQuery,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect } from 'react';
import { theme } from 'styles/theme';

export const COLLECTION_FRAGMENT_SIMILAR_COLLECTION_LIST = gql`
  fragment CollectionFragmentSimilarCollectionList on CollectionModel {
    id
    ...CollectionFragmentCollectionListItemView
    ...CollectionFragmentCollectionPeekingCardView
  }
  ${CollectionFragmentCollectionListItemViewFragmentDoc}
  ${CollectionFragmentCollectionPeekingCardViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionsSimilarSearchForSimilarCollectionList(
    $filters: CollectionFilters!
  ) {
    collectionsSimilarSearch(filters: $filters) {
      hits {
        type
        item {
          id
          ...CollectionFragmentSimilarCollectionList
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT_SIMILAR_COLLECTION_LIST}
`;

export type SimilarCollectionListProps = {
  filters: CollectionFilters;
  onSelect: (collectionId: string) => void;
  onLoad?: (
    collections: CollectionFragmentSimilarCollectionListFragment[],
  ) => void;
};

export const SimilarCollectionList = (props: SimilarCollectionListProps) => {
  const { filters, onSelect, onLoad } = props;

  const { data, loading: isLoadingSimilarCollections } =
    useGetCollectionsSimilarSearchForSimilarCollectionListQuery({
      variables: {
        filters,
      },
      fetchPolicy: 'network-only',
      skip: !filters.query,
    });
  const collectionHitResults = data?.collectionsSimilarSearch.hits || [];
  const collections = collectionHitResults.map((h) => h.item);

  useEffect(() => {
    onLoad?.(collections);
  }, [collections]); // eslint-disable-line

  const isLoading = isLoadingSimilarCollections || !filters.query;
  const isEmpty = collectionHitResults.length === 0 && !isLoading;

  const isMobileView = useMediaQueryMobile();

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="headline-xxs"
        mb={1.5}
        color={theme.colors?.utility[600]}
      >
        Existing Collections
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mx: -2,
          maxHeight: 212,
          overflow: 'auto',
        }}
      >
        {isLoading ? (
          new Array(4).fill(null).map((_, index) => (
            <Box key={index} my={2} px={2}>
              <CollectionListItemSkeleton key={index} />
            </Box>
          ))
        ) : isEmpty ? (
          <Typography variant="body-md" my={2} px={2}>
            No existing collections found.
          </Typography>
        ) : (
          collectionHitResults.map((collectionResult) => {
            const collection = collectionResult.item;
            const isExistingCollectionBasedOnName =
              collection.name.trim() === filters.query?.trim();

            return (
              <Tooltip
                key={collection.id}
                title={<CollectionPeekingCardView collection={collection} />}
                disableTouchListener
                placement={isMobileView ? 'bottom' : 'right'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      p: 0,
                      bgcolor: 'transparent',
                    },
                  },
                }}
              >
                <Box
                  onClick={() => onSelect(collection.id)}
                  sx={{
                    borderRadius: 3,
                    p: '8px 10px !important',
                    transition: 'all 0.2s',
                    ':hover': {
                      backgroundColor: '#2306031A !important',
                    },
                  }}
                >
                  <CollectionListItemView
                    collection={collection}
                    isSmartSearchResult={
                      collectionResult.type === SearchHitType.Smart
                    }
                    renderName={(collection) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1}
                          flexShrink={0}
                        >
                          <Typography variant="subhead-lg">
                            {collection.name}
                          </Typography>
                          {isExistingCollectionBasedOnName && (
                            <Box
                              sx={{
                                ...typography['body-xs'],
                                fontWeight: 600,
                                px: 2,
                                py: 0.25,
                                bgcolor: theme.colors?.utility['pink-1'],
                                color: theme.colors?.utility['pink-4'],
                                borderRadius: 50,
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Already Exists
                            </Box>
                          )}
                        </Box>
                      );
                    }}
                  />
                </Box>
              </Tooltip>
            );
          })
        )}
      </Box>
    </Box>
  );
};
