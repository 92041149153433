import { gql } from '@apollo/client';
import { useGetTaskForTaskPermissionsQuery } from 'graphql/generated';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line
gql`
  query GetTaskForTaskPermissions($id: String!) {
    task(id: $id) {
      id
      myPermissions
    }
  }
`;

export const useTaskPermissions = (id = '') => {
  const data = useGetTaskForTaskPermissionsQuery({
    variables: {
      id,
    },
    skip: !id,
    // Suppressing the top level toast message because this hook is mainly used for
    // internal logic & user should not be notified about the permission check
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });

  // This handling is needed for the cases where the user is having access to a project request but not have the access to the project itself.
  // Without this handling, the user will see the message forbidden resource on the request page which actually he is having access to
  if (data.error) {
    return [];
  }

  return data.data?.task.myPermissions || [];
};
