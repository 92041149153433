import { gql } from '@apollo/client';
import {
  PostFragmentPostPreviewFragmentDoc,
  useDeleteCollectionForCollectionContextMenuMutation,
  useUpdateCollectionPreviewForCollectionContextMenuMutation,
} from 'graphql/generated';
import { evictObject, modifyObject } from 'utils/apollo';
import { useUserContext } from '../../../../contexts/users/User.context';

// eslint-disable-next-line
gql`
  mutation DeleteCollectionForCollectionContextMenu($collectionId: String!) {
    deleteCollection(id: $collectionId) {
      message
      success
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateCollectionPreviewForCollectionContextMenu(
    $data: UpdateCollectionPreviewInput!
  ) {
    updateCollectionPreview(data: $data) {
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export const useCollectionHandlers = () => {
  const { orgBilling } = useUserContext();

  const [deleteCollection] =
    useDeleteCollectionForCollectionContextMenuMutation();
  const [updateCollectionPreview] =
    useUpdateCollectionPreviewForCollectionContextMenuMutation();

  const onDeleteCollection = async (
    collectionId: string,
    parentCollectionId?: string | null,
  ) => {
    return deleteCollection({
      variables: {
        collectionId,
      },
      update: (cache) => {
        if (parentCollectionId) {
          const deletedCollection = cache.identify({
            __typename: 'CollectionModel',
            id: collectionId,
          });

          modifyObject(cache, parentCollectionId, 'CollectionModel', {
            childCollections: (cachedCollections = []) => {
              return cachedCollections.filter(
                (collection) => collection.__ref !== deletedCollection,
              );
            },
          });
        } else {
          evictObject(cache, collectionId, 'CollectionModel');

          if (orgBilling) {
            modifyObject(cache, orgBilling?.id, 'OrganizationBillingModel', {
              collectionUsageCount: (existingFieldValues) =>
                existingFieldValues - 1,
            });
          }
        }
      },
    });
  };

  const onCollectionPreviewUpdate = (collectionId: string, postId?: string) => {
    updateCollectionPreview({
      variables: {
        data: {
          collectionId,
          postId,
        },
      },
      update: (cache, response) => {
        modifyObject(cache, collectionId, 'CollectionModel', {
          previewPost: () => {
            return response.data?.updateCollectionPreview;
          },
        });
        modifyObject(cache, collectionId, 'CollectionModel', {
          hasPreviewPost: () => {
            return !!postId;
          },
        });
      },
    });
  };

  return {
    onDeleteCollection,
    onCollectionPreviewUpdate,
  };
};
