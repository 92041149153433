import { gql } from '@apollo/client';
import { Box, SxProps, TableCell, Typography } from '@mui/material';
import { IconCustomX } from 'components/icons/components/custom/IconCustomX';
import { PostPreview } from 'features/post';
import {
  PostFragmentJuiceboxTablePostTableRowCellPreviewFragment,
  PostFragmentPostPreviewFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentJuiceboxTablePostTableRowCellPreview on PostModel {
    id
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export type JuiceboxTablePostTableRowCellPreviewProps = {
  post: PostFragmentJuiceboxTablePostTableRowCellPreviewFragment;
  sx?: SxProps;
};

export const JuiceboxTablePostTableRowCellPreview = (
  props: JuiceboxTablePostTableRowCellPreviewProps,
) => {
  const { post, sx = {} } = props;

  // Check if the post is a Twitter URL for a different preview
  const isTwitterUrl =
    post.urlMetadata?.[0]?.url?.includes('twitter.com') ||
    post.urlMetadata?.[0]?.url?.includes('t.co');

  if (isTwitterUrl) {
    return (
      <TableCell sx={{ ...sx }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.colors?.primary.black,
              borderRadius: theme.spacing(1),
              display: 'flex',
              height: theme.spacing(10),
              width: theme.spacing(8),
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.colors?.primary.white,
            }}
          >
            <IconCustomX size={24} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: theme.spacing(1),
            }}
          >
            <Box
              display="flex"
              gap={1}
              sx={{
                backgroundColor: 'rgba(35, 6, 3, 0.07)',
                borderRadius: 100,
                p: theme.spacing(1, 2),
              }}
            >
              <IconCustomX size={16} />
              <Typography
                color={theme.colors?.utility[800]}
                variant="body-sm"
                fontWeight={600}
              >
                x.com
              </Typography>
            </Box>
          </Box>
        </Box>
      </TableCell>
    );
  }

  return (
    <TableCell sx={{ ...sx }}>
      <PostPreview
        post={post}
        variant="compact"
        sx={{
          borderRadius: theme.spacing(1),
          width: '100px',
          height: '60px',
        }}
      />
    </TableCell>
  );
};
