import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isCuid } from 'utils/helpers';

/**
 * This hooks simply returns the collectionId from the url params,
 * also taking into account the background location state.
 */
export const useCollectionIdFromParams = () => {
  const location = useLocation();
  const locationState = useMemo(
    () =>
      (location.state as {
        backgroundLocation?: Location;
        collectionId?: string;
      }) || {},
    [location.state],
  );

  const collectionId = useMemo(() => {
    const windowPath = window.location?.pathname || '';
    const reactRouterPath = location?.pathname;

    const backgroundPath = locationState?.backgroundLocation?.pathname || '';

    // get collectionId from:
    // 1. Current location's pathname, OR
    // 2. Location state, OR
    // 3. Background location's pathname
    const id =
      (windowPath?.includes('collections') && windowPath.split('/').pop()) ||
      (reactRouterPath?.includes('collections') &&
        reactRouterPath.split('/').pop()) ||
      (backgroundPath?.includes('collections') &&
        backgroundPath.split('/').pop()) ||
      locationState?.collectionId ||
      undefined;

    return id && isCuid(id) ? id : undefined;
  }, [location, locationState]);

  return { collectionId };
};
