import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { CreateContentIdeaFieldInputData, FieldType } from 'graphql/generated';
import { useRef } from 'react';
import { theme } from 'styles/theme';
import { fieldNameIconMap } from '../fieldName';

export const fieldTypeIconMap = {
  [FieldType.Text]: 'IconLinearText',
  [FieldType.Select]: 'IconLinearArrowCircleDown',
  [FieldType.MultiSelect]: 'IconOutlineTask',
  [FieldType.Tasks]: 'IconOutlineTickCircle',
  [FieldType.Date]: 'IconOutlineCalendar',
  [FieldType.Users]: 'IconLinearUser',
  [FieldType.Collection]: 'IconOutlineLink2',
  [FieldType.Checkbox]: 'IconOutlineTickSquare',
};

const options = [
  {
    label: 'Text',
    value: FieldType.Text,
    icon: fieldTypeIconMap[FieldType.Text],
  },
  {
    label: 'Select',
    value: FieldType.Select,
    icon: fieldTypeIconMap[FieldType.Select],
  },
  {
    label: 'Multi-Select',
    value: FieldType.MultiSelect,
    icon: fieldTypeIconMap[FieldType.MultiSelect],
  },
  {
    label: 'Tasks',
    value: FieldType.Tasks,
    icon: fieldTypeIconMap[FieldType.Tasks],
  },
  {
    label: 'Date',
    value: FieldType.Date,
    icon: fieldTypeIconMap[FieldType.Date],
  },
  {
    label: 'Person',
    value: FieldType.Users,
    icon: fieldTypeIconMap[FieldType.Users],
  },
  {
    label: 'Media & Links',
    value: FieldType.Collection,
    icon: fieldTypeIconMap[FieldType.Collection],
  },
  {
    label: 'Checkbox',
    value: FieldType.Checkbox,
    icon: fieldTypeIconMap[FieldType.Checkbox],
  },
];

export type AddFieldButtonProps = {
  onAddField: (data: CreateContentIdeaFieldInputData) => void;
};

export const AddFieldButton = (props: AddFieldButtonProps) => {
  const { onAddField } = props;

  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure();

  return (
    <>
      <Box
        ref={anchorElRef}
        component="button"
        type="button"
        sx={{
          display: 'flex',
          gap: 1.5,
          color: theme.colors?.utility[700],
        }}
        onClick={openMenu}
      >
        <IconLinearAdd size={16} />
        <Typography variant="subhead-lg">Add Field</Typography>
      </Box>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={closeMenu}
        PaperProps={{
          sx: {
            p: 3,
          },
        }}
      >
        {options.map((option) => {
          const Icon = fieldNameIconMap[option.icon];

          return (
            <MenuItem
              key={option.value}
              sx={{
                gap: 2,
                borderRadius: 3,
                minWidth: 200,
              }}
              onClick={() => {
                onAddField({
                  name: option.label,
                  fieldType: option.value,
                  icon: option.icon,
                });

                closeMenu();
              }}
            >
              {Icon && (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: 1,
                    bgcolor: '#2306030D',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon size={16} />
                </Box>
              )}
              <Typography variant="subhead-lg">{option.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
