import { Box, Chip, MenuItem, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconOutlineAddCircle } from 'components/icons/components/outline/IconOutlineAddCircle';
import { useUserContext } from 'contexts/users/User.context';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

export type UsersFieldProps = {
  value: string[];
  onChange: (value: string[]) => void;
  renderTrigger?: (props: {
    isOpen: boolean;
    options: { label: string; value: string }[];
    users?: UserFragmentAvatarGroupFragment[];
    onDelete: (value: string) => void;
    onClear: () => void;
  }) => JSX.Element;
  readOnly?: boolean;
};

export const UsersField = (props: UsersFieldProps) => {
  const { value, onChange, renderTrigger, readOnly } = props;

  const { user } = useUserContext();

  const users = useMemo(() => {
    if (!user) {
      return [];
    }

    return [...user.organization.users, ...user.organization.externalUsers];
  }, [user]);
  const options = useMemo(() => {
    return users.map((u) => {
      return {
        label: getFullName(u),
        value: u.id,
        email: u.email, // for filtering
      };
    });
  }, [users]);
  // Selected users should be at the top
  const sortedOptions = options.sort((a, b) => {
    if (value.includes(a.value) && !value.includes(b.value)) {
      return -1;
    }

    if (!value.includes(a.value) && value.includes(b.value)) {
      return 1;
    }

    return 0;
  });

  const onDelete = (valueToDelete: string) => {
    const newValue = value.filter((v) => v !== valueToDelete);
    const newOptions = options.filter((o) =>
      newValue.some((v) => v === o.value),
    );
    onChange(newOptions.map((o) => o.value));
  };

  const onClear = () => {
    onChange([]);
  };

  return (
    <AutocompletePopup
      readOnly={readOnly}
      defaultValue={sortedOptions.filter((o) =>
        value.some((v) => v === o.value),
      )}
      multiple
      options={sortedOptions}
      onChange={(_, value) => {
        if (Array.isArray(value)) {
          onChange(value.map((v) => v.value));
        }
      }}
      placeholder="Search"
      renderTrigger={
        renderTrigger
          ? (open) =>
              renderTrigger({
                isOpen: open || false,
                options: sortedOptions,
                users,
                onDelete,
                onClear,
              })
          : () => {
              if (value.length === 0) {
                return (
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[600]}
                  >
                    Empty
                  </Typography>
                );
              }

              return (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  {value.map((v) => {
                    const user = users.find((u) => u.id === v);

                    if (!user) {
                      return null;
                    }

                    return (
                      <Chip
                        key={v}
                        icon={
                          <Box>
                            <Avatar user={user} size={20} />
                          </Box>
                        }
                        label={user.firstName}
                        variant="filled-borderless"
                        sx={{
                          color: theme.colors?.primary.black,
                          fontWeight: 600,
                        }}
                        onDelete={() => {
                          onChange(value.filter((val) => val !== v));
                        }}
                      />
                    );
                  })}
                </Box>
              );
            }
      }
      triggerWrapperSx={{
        width: '100%',
      }}
      renderOption={(props, option) => {
        const user = users.find((u) => u.id === option.value);
        const selected = value.some((v) => v === option.value);

        if (!user) {
          return null;
        }

        return (
          <MenuItem
            {...props}
            key={option.value}
            value={option.value}
            sx={{
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: 4,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ maxWidth: '100%' }}>
                <AvatarWithName user={user} avatarSize={20} />
              </Box>
              {selected ? (
                <IconBoldTickCircle
                  size={16}
                  style={{
                    flexShrink: 0,
                  }}
                />
              ) : (
                <IconOutlineAddCircle
                  size={16}
                  style={{
                    flexShrink: 0,
                  }}
                />
              )}
            </Box>
          </MenuItem>
        );
      }}
      popoverProps={{
        PaperProps: {
          sx: {
            width: 300,
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(20px)`,
          },
        },
      }}
    />
  );
};
