import { COMMAND_KEYS, COMMAND_TYPE } from '../../constants';
import { Command } from '../../types';
import { CreateCollectionHandler } from './components/CreateCollectionHandler';
import { SendToCollectionHandler } from './components/SendToCollectionHandler';

export const useCollectionCommands = (): Command[] => {
  return [
    {
      type: COMMAND_TYPE.CREATE_COLLECTION,
      keys: COMMAND_KEYS[COMMAND_TYPE.CREATE_COLLECTION],
      Handler: CreateCollectionHandler as any,
    },
    {
      type: COMMAND_TYPE.SEND_TO_COLLECTION,
      keys: COMMAND_KEYS[COMMAND_TYPE.SEND_TO_COLLECTION],
      Handler: SendToCollectionHandler as any,
    },
  ];
};
