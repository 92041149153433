import { Box, Typography, TextField } from '@mui/material';
import { useTopicDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks';
import { TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment } from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type Props = {
  currentTopicId: string;
  updateCurrentTopicId: (topicId: string) => void;
  onNext: () => void;
  brandId: string;
  topics: TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment[];

  type?: 'creator' | 'topic';

  componentProps?: {
    title?: string;
    inputPlaceholder?: string;
  };
};

export const SocialMediaListeningOnboardingTopicName = ({
  currentTopicId,
  updateCurrentTopicId,
  brandId,
  topics,
  onNext,
  componentProps,
  type = 'topic',
}: Props) => {
  const [topicName, setTopicName] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const validateAndProceed = () => {
    if (!topicName.trim().length) {
      setHasError(true);
    } else {
      setHasError(false);
      onSubmit();
    }
  };

  const {
    topicActions: {
      handleAddEngagementTopic,
      handleAddCreatorTrackingTopic,
      saving: savingTopicData,
    },
  } = useTopicDataForSocialListeningOnboarding({ topics });

  useEffect(() => {
    topics.forEach((topic) => {
      if (topic.id === currentTopicId && currentTopicId) {
        setTopicName(topic.name);
      }
    });
  }, [topics]);

  const onSubmit = async () => {
    try {
      if (type === 'creator') {
        const topicId = await handleAddCreatorTrackingTopic(
          { topicName },
          brandId,
          currentTopicId,
        );
        updateCurrentTopicId(topicId || '');
        onNext();
        return;
      }
      // Add engagement topic as a default
      const topicId = await handleAddEngagementTopic(
        { topicName },
        brandId,
        currentTopicId,
      );
      updateCurrentTopicId(topicId || '');
      onNext();
    } catch (error) {
      setHasError(true);
      setErrorMessage((error as Error).message);
    }
  };

  return (
    <Box
      width="60vw"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography
        variant="body-lg"
        sx={{
          fontWeight: 600,
          width: 'fit-content',
          padding: theme.spacing(1, 3),
          bgcolor: 'rgba(250, 243, 236, 0.20)',
          borderRadius: theme.spacing(4),
        }}
      >
        {type === 'topic' && 'Topic Insights'}
        {type === 'creator' && 'Creator Focus'}
      </Typography>
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        {componentProps?.title || 'What’s the topic?'}
      </Typography>

      <Box display="flex" flexDirection="column" gap={3} width="100%" mt={3}>
        <TextField
          autoFocus
          placeholder={componentProps?.inputPlaceholder || 'Topic name'}
          value={topicName}
          onChange={(e) => setTopicName(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              validateAndProceed();
            }
          }}
          sx={{
            '.MuiOutlinedInput-root': {
              ...(hasError
                ? { border: `2px solid ${theme.colors?.utility['pink-1']}` }
                : {}),
              borderRadius: theme.spacing(4),
              py: `${theme.spacing(6)} !important`,
              px: `${theme.spacing(6)} !important`,
              bgcolor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,

              input: {
                ...theme.typography['headline-lg'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />

        {errorMessage && (
          <Typography variant="body-xl" color={theme.colors?.utility['pink-1']}>
            {errorMessage}
          </Typography>
        )}
      </Box>

      <RenderNextButton
        onNextClick={validateAndProceed}
        loading={savingTopicData}
      />
    </Box>
  );
};
