import { gql } from '@apollo/client';
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Menu,
  Typography,
} from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import {
  CompetitorFragmentBrandCompetitorCreatorSelectorFragment,
  Platform,
} from 'graphql/generated';
import { useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CompetitorFragmentBrandCompetitorCreatorSelector on CompetitorModel {
    id
    trackingBrand {
      id
      creators {
        id
        platform
        handle
      }
    }
  }
`;

interface Props {
  competitor: CompetitorFragmentBrandCompetitorCreatorSelectorFragment;
  selectedCreatorId: string;
  onChange: (creatorId: string, platform: Platform) => void;
}

const PlatformIconMap = {
  [Platform.Instagram]: IconBoldInstagram,
  [Platform.Tiktok]: IconCustomTiktok,
};

export const BrandCompetitorCreatorSelector = ({
  competitor,
  selectedCreatorId,
  onChange,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [collasedPlatforms, setCollasedPlatforms] = useState<Platform[]>([]);

  const { instagramCreators, tiktokCreators } = useMemo(() => {
    return {
      instagramCreators: competitor.trackingBrand?.creators.filter(
        (creator) =>
          creator.platform === Platform.Instagram ||
          creator.platform === Platform.Reel,
      ),
      tiktokCreators: competitor.trackingBrand?.creators.filter(
        (creator) => creator.platform === Platform.Tiktok,
      ),
    };
  }, [competitor.trackingBrand?.creators]);

  const selectedCreator = useMemo(() => {
    return competitor.trackingBrand?.creators.find(
      (creator) => creator.id === selectedCreatorId,
    );
  }, [competitor.trackingBrand?.creators, selectedCreatorId]);

  const renderPlatformOption = useCallback(
    (creators, platform) => {
      const Icon = PlatformIconMap[platform];
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setCollasedPlatforms((prev) =>
                prev.includes(platform)
                  ? prev.filter((p) => p !== platform)
                  : [...prev, platform],
              );
            }}
          >
            <IconLinearArrowDown size={16} color={theme.colors?.utility[700]} />
            <Icon size={16} color={theme.colors?.utility[700]} />
            <Typography variant="headline-xs">{platform}</Typography>
          </Box>
          <Collapse in={!collasedPlatforms.includes(platform)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                pl: 1,
                pt: 4,
              }}
            >
              {creators.map((creator) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    onChange(creator.id, platform);
                  }}
                >
                  <Typography variant="subhead-lg">
                    @{creator.handle}
                  </Typography>
                  {creator.id === selectedCreatorId && (
                    <IconCustomTick size={14} />
                  )}
                </Box>
              ))}
            </Box>
          </Collapse>
        </Box>
      );
    },
    [collasedPlatforms, selectedCreatorId, onChange],
  );

  if (!selectedCreator) {
    return null;
  }

  const Icon = PlatformIconMap[selectedCreator.platform];

  return (
    <>
      <IconButton
        sx={{
          borderRadius: 2,
          bgcolor: theme.colors?.utility[275],
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{
            height: 24,
            width: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            background: 'rgba(35, 6, 3, 0.05)',
          }}
        >
          <Icon size={16} />
        </Box>
        <Typography variant="subhead-xl">@{selectedCreator.handle}</Typography>
        <IconLinearArrowDown size={16} color={theme.colors?.utility[700]} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            border: 'none',
            minWidth: 220,
            padding: 6,
            '& ul > :first-child': {
              display: 'none',
            },
          },
          style: {
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
          },
        }}
        MenuListProps={{
          sx: {
            ':hover': {
              background: 'transparent',
            },
          },
        }}
      >
        <DropDownMoreMenuItem
          disableRipple
          defaultOpen
          label=""
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  gap: 2,
                },
              },
            },
          }}
          dropDownOptions={[
            {
              label: Platform.Instagram,
              value: Platform.Instagram,
              renderOption: () =>
                renderPlatformOption(instagramCreators, Platform.Instagram),
            },
            {
              label: '',
              renderOption: () => (
                <Divider
                  sx={{ my: 3, borderColor: theme.colors?.utility[500] }}
                />
              ),
            },
            {
              label: Platform.Tiktok,
              value: Platform.Tiktok,
              renderOption: () =>
                renderPlatformOption(tiktokCreators, Platform.Tiktok),
            },
          ]}
        />
      </Menu>
    </>
  );
};
