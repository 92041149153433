import { Box, Checkbox, Input, SxProps, Typography } from '@mui/material';
import {
  PermissionLevel,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { PermissionSelector } from '../components';
import { ManageUsersInput } from '../components/manageUsersInput/ManageUsersInput';

type Props = {
  entityOrganizationId: string;
  onSearchStrUpdate: (searchStr: string) => void;
  onMembersUpdate: (
    members: UserProfileFragmentUserProfilePermissionItemViewFragment[],
  ) => void;
  onPermissionUpdate: (permission: PermissionLevel) => void;
  onNoteUpdate?: (note: string) => void;
  onShouldCreateCommentUpdate?: (shouldCreateComment: boolean) => void;
};

interface RenderInputProps {
  sx?: SxProps;
  inputRef?: React.RefObject<HTMLInputElement>;
}
export interface PermissionSelectViewProps {
  componentProps?: {
    noteContainerSx?: SxProps;
  };
}

export const usePermissionAddMemberInput = (props: Props) => {
  const {
    entityOrganizationId,
    onSearchStrUpdate,
    onMembersUpdate,
    onPermissionUpdate,
    onShouldCreateCommentUpdate,
    onNoteUpdate,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<
    UserProfileFragmentUserProfilePermissionItemViewFragment[]
  >([]);

  const userOrganizationId = useMemo(() => {
    // find the guest organizationId. if no guest, then just use the entity organization Id
    // this is used for the paywall in Permission Level Select
    for (const user of selectedUsers) {
      if (user.organization.id !== entityOrganizationId) {
        return user.organization.id;
      }
    }

    return entityOrganizationId;
  }, [selectedUsers, entityOrganizationId]);

  const [permissionLevel, setPermissionLevel] = useState(PermissionLevel.View);
  const [note, setNote] = useState('');
  // If it is true then create a comment in post, note or collection with the `note` text
  const [shouldCreateComment, setShouldCreateComment] = useState(true);

  useEffect(() => {
    onMembersUpdate(selectedUsers);
  }, [onMembersUpdate, selectedUsers]);

  useEffect(() => {
    onPermissionUpdate(permissionLevel);
  }, [onPermissionUpdate, permissionLevel]);

  useEffect(() => {
    onNoteUpdate?.(note);
  }, [onNoteUpdate, note]);

  useEffect(() => {
    onShouldCreateCommentUpdate?.(shouldCreateComment);
  }, [onShouldCreateCommentUpdate, shouldCreateComment]);

  const toggleUserSelect = (
    user: UserProfileFragmentUserProfilePermissionItemViewFragment,
  ) => {
    if (selectedUsers.includes(user)) {
      setSelectedUsers(selectedUsers.filter((x) => x.email !== user.email));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
    setInputValue('');
    onSearchStrUpdate('');
  };

  const reset = () => {
    setSelectedUsers([]);
    setInputValue('');
  };

  const renderInput = (props: RenderInputProps) => {
    const { sx, inputRef } = props;
    return (
      <Box sx={sx}>
        <ManageUsersInput
          searchStr={inputValue}
          inputRef={inputRef}
          onSearchStrUpdate={(text) => {
            setInputValue(text);
            onSearchStrUpdate(text);
          }}
          onRemoveUser={(index) => {
            toggleUserSelect(selectedUsers[index]);
          }}
          users={selectedUsers}
          showInviteButton={false}
          componentProps={{
            tag: { sx: { bgcolor: 'rgba(35, 6, 3, 0.1)' } },
            input: {
              sx: {
                '& .MuiChip-label': {
                  pl: 1,
                },
                '.MuiOutlinedInput-root': {
                  p: `0 !important`,

                  input: {
                    ...theme.typography['headline-xs'],
                    p: '0 !important',
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              },
            },
          }}
          sx={{
            padding: 0,
          }}
        />
      </Box>
    );
  };

  const renderPermissionSelectView = ({
    componentProps = {},
  }: PermissionSelectViewProps = {}) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          gap: 6,
          padding: theme.spacing(6, 4),
        }}
      >
        {selectedUsers.length > 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: 2.5,
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <Typography
                variant="headline-xs"
                color={theme.colors?.utility[600]}
              >
                Permission level
              </Typography>
              <PermissionSelector
                entityOrganizationId={entityOrganizationId}
                userOrganizationId={userOrganizationId}
                initialValue={PermissionLevel.View}
                onPermissionChange={(permission) => {
                  setPermissionLevel(permission);
                }}
                selectPaperSx={{
                  backgroundColor: 'rgba(250, 243, 236, 1)',
                }}
                sx={{
                  '& .MuiSelect-select': {
                    padding: '0px !important',

                    '& span': {
                      ...theme.typography['subhead-xl'],
                      color: theme.colors?.primary.black,
                    },
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                padding: 3,
                borderRadius: 2,
                bgcolor: 'rgba(35, 6, 3, 0.10)',
                height: 230,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 2,
                ...componentProps.noteContainerSx,
              }}
            >
              <Input
                placeholder="Write a note..."
                multiline
                fullWidth
                disableUnderline
                onChange={(e) => setNote(e.target.value)}
                sx={{
                  ...theme.typography['subhead-xl'],
                  padding: 0,
                  color: theme.colors?.utility[1100],
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  width: 'fit-content',
                  cursor: 'pointer',
                }}
                onClick={() => setShouldCreateComment(!shouldCreateComment)}
              >
                <Checkbox
                  size="small"
                  checked={shouldCreateComment}
                  sx={{
                    padding: 0,
                  }}
                />
                <Typography
                  sx={{
                    ...theme.typography['subhead-xl'],
                    color: theme.colors?.primary.black,
                  }}
                >
                  Post as a comment
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  };

  return {
    note,
    shouldCreateComment,
    renderInput,
    toggleUserSelect,
    resetInput: reset,
    renderPermissionSelectView,
  };
};
