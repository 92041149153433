import { gql } from '@apollo/client';

export const NOTE_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX = gql`
  fragment NoteSearchHitFragmentItemSuffix on PostSearchHit {
    item {
      id
      urlMetadata {
        id
        url
      }
    }
  }
`;

export const LINK_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX = gql`
  fragment LinkSearchHitFragmentItemSuffix on PostSearchHit {
    item {
      id
    }
  }
`;

export const ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX = gql`
  fragment AttachmentSearchHitFragmentItemSuffix on PostSearchHit {
    item {
      id
    }
  }
`;

export const TASK_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX = gql`
  fragment TaskSearchHitFragmentItemSuffix on TaskSearchHitV2 {
    item {
      id
    }
  }
`;

export const COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX = gql`
  fragment CollectionSearchHitFragmentItemSuffix on CollectionSearchHit {
    item {
      id
      posts {
        id
        urlMetadata {
          id
          url
        }
      }
    }
  }
`;
