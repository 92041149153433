import { gql } from '@apollo/client';
import { Box, Typography, styled } from '@mui/material';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import {
  TaskFragmentTaskDescriptionFragment,
  TaskPermission,
  useUpdateTaskForTaskDescriptionMutation,
} from 'graphql/generated';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';

export const TASK_FRAGMENT_TASK_DESCRIPTION = gql`
  fragment TaskFragmentTaskDescription on TaskModel {
    id
    description
    myPermissions
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskDescription($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      id
      description
    }
  }
`;

const MAX_HEIGHT = 63;

const FadingContainer = styled('div')<{ backgroundColor?: string }>(
  ({ backgroundColor }) => ({
    position: 'absolute',
    left: 0,
    bottom: -5,
    width: '100%',
    height: 16,
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%,  ${
      backgroundColor || theme.colors?.primary.white
    } 63.67%)`,
  }),
);

export enum ContentMode {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
  None = 'none',
}

export type TaskDescriptionProps = {
  task: TaskFragmentTaskDescriptionFragment;
  defaultContentMode?: ContentMode;
  style?: CSSProperties;
};

export const TaskDescription = (props: TaskDescriptionProps) => {
  const { style, defaultContentMode, task } = props;
  const { description, myPermissions } = task;
  const [contentMode, setContentMode] = useState<ContentMode>(
    defaultContentMode || ContentMode.None,
  );
  const ref = useRef<HTMLDivElement>(null);
  const [updateTask] = useUpdateTaskForTaskDescriptionMutation();

  const onTaskUpdate = (description: string) => {
    return updateTask({
      variables: {
        data: {
          taskId: task.id,
          data: {
            description,
          },
        },
      },
      optimisticResponse: {
        updateTask: {
          id: task.id || '',
          __typename: 'TaskModel',
          description: task.description || '',
        },
      },
    });
  };

  useEffect(() => {
    if (!ref.current || defaultContentMode === ContentMode.None) return;

    setTimeout(() => {
      if (ref.current && ref.current.clientHeight > MAX_HEIGHT) {
        setContentMode(ContentMode.Collapsed);
      } else {
        setContentMode(ContentMode.None);
      }
    }, 50);
  }, []); // eslint-disable-line

  const canEdit = myPermissions?.includes(TaskPermission.Update);

  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          maxHeight:
            contentMode === ContentMode.Collapsed ? MAX_HEIGHT : undefined,
        }}
        ref={ref}
      >
        <RichTextEditor
          defaultContent={description}
          editable={canEdit}
          style={style}
          onFocus={() => canEdit && setContentMode(ContentMode.None)}
          borderless
          hideToolBar
          placeholder="Write a description..."
          onBlur={(e) => {
            if (!canEdit) return;
            onTaskUpdate(e.target.innerHTML);
          }}
        />
        {contentMode === ContentMode.Collapsed && <FadingContainer />}
      </Box>
      {contentMode !== ContentMode.None && (
        <Typography
          component="button"
          sx={{
            ...theme.typography['subhead-xl'],
            color: theme.colors?.utility[700],
            textAlign: 'left',
          }}
          onClick={(e) => {
            e.preventDefault();
            setContentMode(
              contentMode === ContentMode.Collapsed
                ? ContentMode.Expanded
                : ContentMode.Collapsed,
            );
          }}
        >
          {contentMode === ContentMode.Collapsed ? 'See more' : 'See less'}
        </Typography>
      )}
    </div>
  );
};
