import { useLostConnectionListener } from 'features/collaboration';
import { toast, closeToast } from 'components/common/Toast';
import { useRef } from 'react';

export const CollaborationConnectionToast = () => {
  const toastId = useRef<string>();

  useLostConnectionListener((event) => {
    if (event === 'lost') {
      toastId.current = toast({
        type: 'warning',
        title: 'Lost Connection',
        message: 'Still trying to reconnect…',
        persist: true,
      });
    } else if (event === 'restored') {
      toast({
        type: 'success',
        title: 'Reconnected',
        message: '',
      });
      if (toastId.current) {
        closeToast(toastId.current);
      }
    } else if (event === 'failed') {
      toast({
        type: 'error',
        title: 'Failed to connect',
        message: 'Could not reconnect, please refresh',
        persist: true,
      });
      if (toastId.current) {
        closeToast(toastId.current);
      }
    }
  });

  return null;
};
