import { gql } from '@apollo/client';
import { Box, Divider, SxProps, TextField, Typography } from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { useUserContext } from 'contexts/users/User.context';
import { OrganizationAvatarWithName } from 'features/organization/components/avatarWithName';
import {
  UserFragmentAvatarGroupFragmentDoc,
  useGetMeForContentCalendarFilterByOwnersInnerContentQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetMeForContentCalendarFilterByOwnersInnerContent {
    me {
      id
      organization {
        id
        name
        users {
          id
          ...UserFragmentAvatarGroup
        }
        externalUsers {
          id
          ...UserFragmentAvatarGroup
          organization {
            id
            name
          }
        }
      }
      joinedOrganizations {
        id
        name
        users {
          id
          ...UserFragmentAvatarGroup
        }
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type ContentCalendarFilterByOwnersInnerContentProps = {
  selectedUserIds: string[];
  selectedOrganizationIds: string[];
  sx?: SxProps;
  onSelectUser: (selectedUserId: string) => void;
  onSelectOrganization: (selectedOrganizationId: string) => void;
};

export const ContentCalendarFilterByOwnersInnerContent = (
  props: ContentCalendarFilterByOwnersInnerContentProps,
) => {
  const {
    selectedUserIds,
    selectedOrganizationIds,
    sx,
    onSelectUser,
    onSelectOrganization,
  } = props;

  const { user } = useUserContext();
  const { data: meData } =
    useGetMeForContentCalendarFilterByOwnersInnerContentQuery();
  const me = meData?.me;

  // Other organizations that current user is part of
  const joinedOrganizations = me
    ? me.joinedOrganizations.filter(
        (jo) => jo.id !== me.organization.id && jo.users.length > 0,
      )
    : [];
  // External organizations that have guests in current user's organization
  const guestOrganizations = me
    ? me.organization.externalUsers.reduce((acc, eu) => {
        const guestOrganization = acc.find((o) => o.id === eu.organization.id);

        if (eu.organization.id !== me.organization.id && !guestOrganization) {
          acc.push({
            ...eu.organization,
            users: [eu],
          });
        } else {
          guestOrganization?.users.push(eu);
        }

        return acc;
      }, [] as typeof me.joinedOrganizations)
    : [];

  const otherOrganizations = [
    ...joinedOrganizations,
    ...guestOrganizations,
    // Dedupe by id
  ].filter((o, index, self) => self.findIndex((s) => s.id === o.id) === index);

  // Get list of organizations that current user can see
  // 1. Current user's organization
  // 3. External organizations that have guests in current user's organization
  const organizations = me ? [me.organization, ...otherOrganizations] : [];

  // Get list of users that current user can see
  // 1. Internal users of current user's organization
  // 2. Guests of current user's organization
  // 3. Internal users of external organizations that current user is part of
  const users = me
    ? [
        ...me.organization.users,
        ...me.organization.externalUsers,
        ...joinedOrganizations.flatMap((jo) => jo.users),
      ]
        // Dedupe by id
        .filter(
          (u, index, self) => self.findIndex((s) => s.id === u.id) === index,
        )
    : [];

  const [query, setQuery] = useState('');
  const debouncedSetQuery = useMemo(() => debounce(setQuery, 300), []);

  const filteredUsers = users.filter((u) => {
    const fullName = `${u.firstName} ${u.lastName}`;
    return fullName.toLowerCase().includes(query.toLowerCase());
  });
  const filteredOrganizations = organizations.filter((o) => {
    return o.name.toLowerCase().includes(query.toLowerCase());
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 3,
        maxHeight: 320,
        overflow: 'auto',
        ...sx,
      }}
    >
      <TextField
        autoFocus
        placeholder="Search"
        onKeyDown={(e) => e.stopPropagation()}
        onChange={(e) => {
          debouncedSetQuery(e.currentTarget.value);
        }}
        sx={{
          minWidth: `220px`,
          mb: 2,
          px: 1,

          '& .MuiOutlinedInput-notchedOutline': {
            border: `0 !important`,
            outline: `0 !important`,
          },

          '& .MuiInputBase-root': {
            borderRadius: 8,
            padding: `12px 20px !important`,
            boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)',

            input: {
              padding: `0 !important`,
              fontSize: `13px`,
            },
          },
        }}
      />
      {user && (
        <>
          <CheckboxMenuItem
            value={user.id}
            label={<AvatarWithName user={user as any} avatarSize={20} />}
            onClick={() => onSelectUser(user.id)}
            checked={selectedUserIds.some((uId) => uId === user.id)}
          />
          <CheckboxMenuItem
            value={user.organization.id}
            label={
              <OrganizationAvatarWithName
                // @ts-ignore
                organization={user.organization}
                avatarSize={20}
                avatarUrl="/logo192.png"
              />
            }
            onClick={() => onSelectOrganization(user.organization.id)}
            checked={selectedOrganizationIds.some(
              (oId) => oId === user.organization.id,
            )}
          />
        </>
      )}
      <Divider
        sx={{
          my: 1,
          mx: 3,
        }}
      />
      <Box>
        <DropDownMoreMenuItem
          defaultOpen
          label={
            <Typography
              variant="headline-xxs"
              color={theme.colors?.utility[600]}
            >
              Organizations
            </Typography>
          }
          dropDownOptions={filteredOrganizations
            .filter((o) => o.id !== user?.organization.id)
            .map((o) => ({
              label: (
                <OrganizationAvatarWithName
                  // @ts-ignore
                  organization={o}
                  avatarSize={20}
                  avatarUrl="/logo192.png"
                />
              ),
              isChecked: selectedOrganizationIds.some((oId) => oId === o.id),
              onClick: () => onSelectOrganization(o.id),
            }))}
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  py: 0,
                },
              },
            },
          }}
          sx={{
            bgcolor: 'transparent !important',
          }}
        />
      </Box>
      <Divider
        sx={{
          my: 1,
          mx: 3,
        }}
      />
      <Box>
        <DropDownMoreMenuItem
          defaultOpen
          label={
            <Typography
              variant="headline-xxs"
              color={theme.colors?.utility[600]}
            >
              Members
            </Typography>
          }
          dropDownOptions={filteredUsers
            .filter((u) => u.id !== user?.id)
            .map((u) => ({
              label: <AvatarWithName user={u as any} avatarSize={20} />,
              isChecked: selectedUserIds.some((uId) => uId === u.id),
              onClick: () => onSelectUser(u.id),
            }))}
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  py: 0,
                },
              },
            },
          }}
          sx={{
            bgcolor: 'transparent !important',
          }}
        />
      </Box>
    </Box>
  );
};
