import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconOutlineLayer } from 'components/icons/components/outline/IconOutlineLayer';
import { PostPreview } from 'features/post';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentJuiceboxCollectionSearchItemFragment,
  PostFragmentPostPreviewFragment,
  useCreateSearchHitForJuiceboxFilterSuggestionViewMutation,
} from 'graphql/generated';
import { ForwardedRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { CollectionBreadcrumbs } from '../../../collection';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentJuiceboxCollectionSearchItem on CollectionModel {
    id
    name
    ...CollectionFragmentCollectionBreadcrumbs
  }
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

interface Props {
  collection: CollectionFragmentJuiceboxCollectionSearchItemFragment;
  posts: PostFragmentPostPreviewFragment[];
  query?: string;
}

export const JuiceboxCollectionSearchItem = forwardRef(
  ({ collection, posts, query }: Props, ref: ForwardedRef<HTMLElement>) => {
    const [createCollectionSearchHit] =
      useCreateSearchHitForJuiceboxFilterSuggestionViewMutation();

    return (
      <Link to={PlotRoutes.collection(collection.id)}>
        <Box
          ref={ref}
          tabIndex={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: 3,
            padding: theme.spacing(2, 3),
            ':hover': {
              backgroundColor: theme.colors?.utility[400],
            },
            '&:focus': {
              backgroundColor: theme.colors?.utility[400],
              outline: 'none',
            },
          }}
          onClick={() => {
            createCollectionSearchHit({
              variables: {
                data: {
                  collection: {
                    id: collection.id,
                  },
                  query,
                },
              },
            });
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 24,
                height: 24,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: 1,
              }}
            >
              <IconOutlineLayer size={14} color={theme.colors?.primary.black} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <CollectionBreadcrumbs
                collection={collection}
                delimiter="/"
                componentProps={{
                  text: {
                    lastLocationSx: {
                      color: theme.colors?.primary.black,
                    },
                  },
                }}
              />
              <Typography
                variant="subhead-sm"
                color={theme.colors?.utility[800]}
              >
                Collection
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            {posts.map((post) => (
              <PostPreview
                post={post}
                sx={{
                  width: Math.floor(Math.random() * (80 - 24 + 1)) + 24,
                  height: 36,
                  borderRadius: 1,
                  flexShrink: 0,
                }}
              />
            ))}
          </Box>
        </Box>
      </Link>
    );
  },
);
