import { gql } from '@apollo/client';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Image } from 'components/common/Image';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { useUpdateUserOnboardingStateForContentCalendarIntroModalMutation } from 'graphql/generated';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation UpdateUserOnboardingStateForContentCalendarIntroModal(
    $data: UpdateUserOnboardingStateInput!
  ) {
    updateUserOnboardingState(data: $data) {
      id
      onboardingState {
        hasSeenContentCalendarIntroduction
      }
    }
  }
`;

interface ContentCalendarIntroModalProps {
  onClose: VoidFunction;
}

export const ContentCalendarIntroModal = ({
  onClose,
}: ContentCalendarIntroModalProps) => {
  const [updateUserOnboardingState] =
    useUpdateUserOnboardingStateForContentCalendarIntroModalMutation();

  const onCloseModal = () => {
    updateUserOnboardingState({
      variables: {
        data: {
          onboardingState: {
            hasSeenContentCalendarIntroduction: true,
          },
        },
      },
      onCompleted(data) {
        if (data) {
          onClose();
        }
      },
    });
  };
  return (
    <Dialog
      open
      onClose={onCloseModal}
      maxWidth="lg"
      PaperProps={{
        sx: {
          maxWidth: 694,
          borderRadius: 6,
          background: theme.colors?.utility['teal-1'],
          boxShadow: `0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)`,
          border: 'none',
          position: 'relative',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <IconButton onClick={onCloseModal}>
          <IconBoldCloseCircle />
        </IconButton>
      </Box>
      <Image
        src="/images/content-calendar-intro.png"
        sx={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
        draggable={false}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: theme.spacing(9, 4, 12, 4),
        }}
      >
        <Box
          sx={{
            maxWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(0.5, 2),
              ...theme.typography['headline-xs'],
              color: theme.colors?.utility[800],
              borderRadius: 25,
              background: `rgba(35, 6, 3, 0.07)`,
              width: 'fit-content',
            }}
          >
            Limited-time beta
          </Box>
          <Typography
            variant="headline-xl"
            fontSize={36}
            fontWeight={500}
            letterSpacing="-0.72px"
            color={theme.colors?.utility[900]}
            textAlign="center"
          >
            The new standard for Content Calendars
          </Typography>
          <Typography
            variant="subhead-xl"
            color={theme.colors?.utility[700]}
            textAlign="center"
          >
            Finally, a place to turn your ideas into execution
          </Typography>
          <Link to={PlotRoutes.contentCalendar()}>
            <Button
              sx={{
                mt: 3,
                color: theme.colors?.primary.parchment,
                bgcolor: theme.colors?.primary.black,
                borderRadius: 2,
                padding: theme.spacing(3, 6),
                ':hover': {
                  bgcolor: theme.colors?.primary.black,
                },
              }}
              onClick={onCloseModal}
            >
              Take me there!
            </Button>
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
};
