import { gql } from '@apollo/client';
import { Box, IconButton } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Breadcrumb } from 'components/common/Breadcrumb';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { TaskFragmentTaskDetailViewLocationNavigatorFragment } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import { extractTextFromHtml } from 'utils/html';
import { useNestedTaskNavigationContext } from '../../contexts';

export const TASK_FRAGMENT_TASK_DETAIL_VIEW_LOCATION_NAVIGATOR = gql`
  fragment TaskFragmentTaskDetailViewLocationNavigator on TaskModel {
    id
    name
  }
`;

export type TaskLocationNavigatorSectionProps = {
  task: TaskFragmentTaskDetailViewLocationNavigatorFragment;
};

export const TaskLocationNavigatorSection = (
  props: TaskLocationNavigatorSectionProps,
) => {
  const { task } = props;

  const { locationStack, navigateToIndex, goBackInStack } =
    useNestedTaskNavigationContext();

  const breadcrumbItems = useMemo(() => {
    return [
      ...(locationStack?.map((item) => ({
        label: extractTextFromHtml(item.task.name),
        href: PlotRoutes.task(item.task.id),
      })) ?? []),
      {
        label: extractTextFromHtml(task.name),
      },
    ];
  }, [locationStack, task]);

  return (
    <Box display="flex" alignItems="center" gap={2} overflow="hidden">
      <IconButton size="small" onClick={goBackInStack} sx={{ flexShrink: 0 }}>
        <IconOutlineArrowLeft size={24} color={theme.colors?.primary.black} />
      </IconButton>
      <Breadcrumb
        sx={{
          flex: 1,
          overflow: 'hidden',
          ol: {
            flexWrap: 'nowrap',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
          '& li': {
            maxWidth: 80,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: theme.colors?.utility[700],
            '& a': {
              ...theme.typography['headline-xs'],
              color: theme.colors?.utility[700],
              textDecoration: 'none',
            },
          },
          '& li:last-of-type': {
            flex: 1,
            maxWidth: 'unset',
          },
          mb: 0,
        }}
        items={breadcrumbItems}
        onNavigate={(_, index) => navigateToIndex(index)}
      />
    </Box>
  );
};
