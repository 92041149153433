import { gql } from '@apollo/client';
import { Box, Skeleton, Typography } from '@mui/material';
import { SentimentColorsSx } from 'features/socialMediaListening/views/postDetail/consts';
import {
  Sentiment,
  SocialPostFragmentSocialPostClaimsCardFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentSocialPostClaimsCard on SocialPostModel {
    socialPostEngagementSuggestions(brandId: $brandId) {
      id
      oneLineSummary
    }
    id
    thumbnailUrl
    description
    contextualInformation {
      oneLineSummary
    }
  }
`;

type Props = {
  sentiment: Sentiment;
  claims: string[];
  customLabel?: string;
  socialPost: SocialPostFragmentSocialPostClaimsCardFragment;
};

export const SocialPostClaimsCard = ({
  sentiment,
  socialPost,
  customLabel,
  claims,
}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: theme.colors?.utility[250],
        borderRadius: 3,
        p: theme.spacing(3, 4),
        display: 'flex',
        alignItems: 'flex-start',
        gap: 2,
      }}
    >
      {socialPost.thumbnailUrl ? (
        <Box
          component="img"
          width={theme.spacing(15)}
          minWidth={theme.spacing(15)}
          height={theme.spacing(21)}
          minHeight={theme.spacing(21)}
          sx={{
            objectFit: 'cover',
            borderRadius: theme.spacing(3),
            backgroundColor: theme.colors?.utility[400],
          }}
          src={socialPost.thumbnailUrl}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          width={theme.spacing(15)}
          height={theme.spacing(21)}
          animation={false}
          sx={{
            minWidth: theme.spacing(15),
            borderRadius: theme.spacing(3),
          }}
        />
      )}
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography
          variant="body-lg"
          color={theme.colors?.utility[1000]}
          fontWeight={500}
        >
          {customLabel ||
            socialPost.socialPostEngagementSuggestions?.[0]?.oneLineSummary ||
            socialPost.contextualInformation?.oneLineSummary ||
            socialPost.description}
        </Typography>
        <Box display="flex" gap={2} alignItems="center" flexWrap="wrap">
          {claims.map((claim) => (
            <Typography
              variant="body-lg"
              sx={{
                p: theme.spacing(1, 2),
                borderRadius: theme.spacing(1),
                ...SentimentColorsSx.Default,
                ...(sentiment === Sentiment.Positive &&
                  SentimentColorsSx.POSITIVE),
                ...(sentiment === Sentiment.Negative &&
                  SentimentColorsSx.NEGATIVE),
              }}
            >
              {claim}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
