import { Box, Button, Radio, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { SLAInfinitePostsGrid } from 'features/socialListeningAnalytics';
import {
  GetSocialPostsCreatedForCompetitorsInput,
  PaginatedCompetitorFlatAnalyticFiltersInputSortField,
  SortOrder,
  useGetSocialPostsCreatedForCompetitorsForCompetitorTopPostsQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { CompetitorTopPostsSkeleton } from './CompetitorTopPostsSkeleton';
import { SocialMediaListeningCompetitorDetailTopPostsProps } from './types';

export const CompetitorTopPosts = ({
  brandId,
  filters,
}: SocialMediaListeningCompetitorDetailTopPostsProps) => {
  const [sortKey, setSortKey] = useState(
    PaginatedCompetitorFlatAnalyticFiltersInputSortField.TotalEngagement,
  );

  const payload: GetSocialPostsCreatedForCompetitorsInput = useMemo(
    () => ({
      ...filters,
      take: 10,
      sortBy: {
        field: sortKey,
        order: SortOrder.Desc,
      },
    }),
    [filters, sortKey],
  );

  const {
    data: topSocialPostsData,
    fetchMore,
    loading,
  } = useGetSocialPostsCreatedForCompetitorsForCompetitorTopPostsQuery({
    variables: {
      data: {
        ...payload,
      },
    },
    skip: !filters.creatorId,
    fetchPolicy: 'cache-and-network',
  });
  const topPosts =
    topSocialPostsData?.getSocialPostsCreatedForCompetitors.data || [];
  const cursor =
    topSocialPostsData?.getSocialPostsCreatedForCompetitors.pageInfo.endCursor;

  const sortOptions = [
    {
      label: 'Total view count',
      value:
        PaginatedCompetitorFlatAnalyticFiltersInputSortField.TotalImpressions,
    },
    {
      label: 'Total engagement',
      value:
        PaginatedCompetitorFlatAnalyticFiltersInputSortField.TotalEngagement,
    },
  ];

  if (loading && !topPosts.length) {
    return <CompetitorTopPostsSkeleton />;
  }

  return (
    <Box
      display="flex"
      p={8}
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 100,
          minHeight: 550,
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          mb={4}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Top posts
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <ContextMenu
              sx={{
                '& .MuiMenuItem-root': {
                  p: 0,
                  color: theme.colors?.primary.black,
                },
              }}
              options={[
                ...sortOptions.map((option) => ({
                  renderOption: () => (
                    <Box display="flex" alignItems="center">
                      <Radio checked={sortKey === option.value} />
                      <Typography variant="body-lg">{option.label}</Typography>
                    </Box>
                  ),
                  onClick: () => {
                    setSortKey(option.value);
                  },
                })),
              ]}
              renderButton={() => (
                <Button
                  sx={{
                    backgroundColor: theme.colors?.utility[275],
                    color: theme.colors?.primary.black,
                    borderRadius: theme.spacing(2),
                    display: 'flex',
                    gap: 2,
                    p: theme.spacing(1, 2),
                    alignItems: 'center',
                    '&:hover': { backgroundColor: theme.colors?.utility[275] },
                  }}
                  disableRipple={false}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      backgroundColor: 'rgba(35, 6, 3, 0.05);',
                      p: theme.spacing(1),
                      borderRadius: theme.spacing(1),
                    }}
                  >
                    <IconLinearSort size={16} />
                  </Box>
                  <Typography fontWeight={500} variant="body-xl">
                    {
                      sortOptions.find((option) => option.value === sortKey)
                        ?.label
                    }
                  </Typography>
                </Button>
              )}
            />
          </Box>
        </Box>

        {!topPosts.length && (
          <Box display="flex" justifyContent="center">
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[700]}
              textAlign="center"
              mt={8}
            >
              No posts found
            </Typography>
          </Box>
        )}

        {!!topPosts.length && (
          <SLAInfinitePostsGrid
            brandId={brandId}
            loadMore={() => {
              fetchMore({
                variables: {
                  data: {
                    ...payload,
                    after: cursor,
                  },
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return {
                    getSocialPostsCreatedForCompetitors: {
                      ...fetchMoreResult.getSocialPostsCreatedForCompetitors,
                      data: [
                        ...prev.getSocialPostsCreatedForCompetitors.data,
                        ...fetchMoreResult.getSocialPostsCreatedForCompetitors
                          .data,
                      ],
                    },
                  };
                },
              });
            }}
            hasMore={
              !!topSocialPostsData?.getSocialPostsCreatedForCompetitors.pageInfo
                .hasNextPage
            }
            socialPosts={topPosts}
          />
        )}
      </Box>
    </Box>
  );
};
