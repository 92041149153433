import {
  Box,
  CircularProgress,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { IconBoldEdit } from 'components/icons/components/bold/IconBoldEdit';
import { CreatorAvatarWithNameView } from 'features/creator/views';
import { useCreatorDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks';
import {
  CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment,
  Platform,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

export type SocialMediaListeningOnboardingSocialHandleInputProps = {
  platform: Platform;
  creator?: CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment;

  addCreator: (
    creator: CreatorFragmentSocialMediaListeningOnboardingSocialHandleFormFragment,
  ) => Promise<void>;
  removeCreator: (creatorId: string) => Promise<void>;

  componentProps?: {
    edit?: {
      sx?: SxProps;
    };
  };
} & TextFieldProps;

export const SocialMediaListeningOnboardingSocialHandleInput = (
  props: SocialMediaListeningOnboardingSocialHandleInputProps,
) => {
  const {
    creator,
    platform,
    addCreator,
    removeCreator,
    componentProps,
    ...textFieldProps
  } = props;

  const [internalCreator, setInternalCreator] = useState(creator);
  const [isRenderingPreview, setIsRenderingPreview] = useState(
    Boolean(creator),
  );

  const { queries } = useCreatorDataForSocialListeningOnboarding();
  const { getCreatorByHandle } = queries;

  const [handle, setHandle] = useState(
    creator ? `@${creator?.handle || ''}` : '',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (creator) {
      setInternalCreator(creator);
    }
  }, [creator]);

  const onSearchHandle = async (handle: string) => {
    // Remove creator if handle is empty or the same as the previous one
    if (!handle.replace('@', '')) {
      if (creator) {
        await addCreator(creator);
      }

      return;
    }

    if (handle.replace('@', '') === creator?.handle) {
      setIsRenderingPreview(true);

      return;
    }

    try {
      setIsLoading(true);

      // We don't need the '@' symbols at the start. It's only for show
      const response = await getCreatorByHandle(
        handle.replace('@', ''),
        platform,
      );
      const creator = response.data?.getSocialCreator;

      if (!creator) {
        setErrorMessage(response.error?.message || 'Creator not found');
      } else {
        setInternalCreator(creator);
        setIsRenderingPreview(true);

        await addCreator(creator);
      }
    } catch (error: any) {
      setErrorMessage(error?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const renderInput = () => {
    return (
      <>
        <TextField
          autoFocus
          placeholder={`@${platform.toLowerCase()}`}
          value={handle}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <CircularProgress
                    sx={{
                      color: theme.colors?.primary.parchment,
                    }}
                    size={20}
                  />
                )}
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            // add a preceding @ if not exists
            if (e.target.value[0] !== '@') {
              setHandle(`@${e.target.value}`);
            } else {
              setHandle(e.target.value);
            }
          }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();

              onSearchHandle(handle);
            }
          }}
          onBlur={() => {
            onSearchHandle(handle);
          }}
          sx={{
            '.MuiOutlinedInput-root': {
              borderRadius: theme.spacing(4),
              py: `${theme.spacing(6)} !important`,
              px: `${theme.spacing(6)} !important`,
              bgcolor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,

              '& .Mui-disabled': {
                backgroundColor: 'transparent',
                color: theme.colors?.primary.parchment,
              },

              input: {
                ...theme.typography['headline-lg'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
          {...textFieldProps}
        />
        {errorMessage && (
          <Typography variant="subhead-lg">{errorMessage}</Typography>
        )}
      </>
    );
  };

  const renderPreview = () => {
    if (!internalCreator) {
      return null;
    }

    return (
      <>
        <CreatorAvatarWithNameView creator={internalCreator} />
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          justifyContent="flex-start"
          width={theme.spacing(100)}
          sx={{
            cursor: 'pointer',
            ...componentProps?.edit?.sx,
          }}
          onClick={async () => {
            await removeCreator(internalCreator.id);
            setIsRenderingPreview(false);
          }}
        >
          <IconBoldEdit size={20} />
          <Typography
            variant="body-xl"
            sx={{
              textDecoration: 'underline',
            }}
          >
            Edit
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Typography variant="headline-lg" fontSize={20}>
        {platform}
      </Typography>
      {isRenderingPreview ? renderPreview() : renderInput()}
    </Box>
  );
};
