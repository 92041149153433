/**
 * I'm not sure I should put this here, but I'll put it here for now.
 */

import { gql } from '@apollo/client';
import { Box, Button, Divider, MenuItem, Typography } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import {
  useCheckForUnsavedChanges,
  useContentCalendarView,
} from 'features/contentCalendarView';
import { ContentCalendarViewListItemView } from 'features/contentCalendarView/views/listItem';
import {
  ContentCalendarViewFragmentContentCalendarViewListItemViewFragmentDoc,
  useGetContentCalendarViewsForManageViewsSectionQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { ApplyChangesButton } from './ApplyChangesButton';
import { CreateViewForm } from './CreateViewForm';

// eslint-disable-next-line
gql`
  query GetContentCalendarViewsForManageViewsSection {
    contentCalendarViews {
      id
      ...ContentCalendarViewFragmentContentCalendarViewListItemView
    }
  }
  ${ContentCalendarViewFragmentContentCalendarViewListItemViewFragmentDoc}
`;

export type ManageViewsSectionProps = {
  selectedFilters: Record<string, object>;

  /**
   * This is a work-around to allow parent component to know if we are currently
   * saving configuration. This is needed for ContentCalendarSortAndFilterButton::L266
   * where we should bypass callback prompt (blocker) if we are currently saving configuration.
   */
  onToggleSavingConfiguration: (isSavingConfiguration: boolean) => void;
};

export const ManageViewsSection = (props: ManageViewsSectionProps) => {
  const { selectedFilters, onToggleSavingConfiguration } = props;

  const location = useLocation();
  const navigate = useNavigate();

  // We'll be using `view` search param to determine if we are using a view.
  const [params] = useSearchParams();
  const contentCalendarViewId = params.get('view') || '';

  // Fetch all views for the current user
  const { data: contentCalendarViewsData, refetch } =
    useGetContentCalendarViewsForManageViewsSectionQuery();
  const contentCalendarViews =
    contentCalendarViewsData?.contentCalendarViews || [];

  const { contentCalendarView } = useContentCalendarView(contentCalendarViewId);
  const initialFilters = useMemo(() => {
    if (contentCalendarView?.filters) {
      return JSON.parse(contentCalendarView.filters);
    }

    return selectedFilters;
  }, [contentCalendarView?.filters]); // eslint-disable-line react-hooks/exhaustive-deps
  const { hasUnsavedChanges: hasUnsavedChangesComparingToInitialFilters } =
    useCheckForUnsavedChanges(initialFilters, selectedFilters);
  const { hasUnsavedChanges: hasUnsavedChangesComparingToEmptyFilters } =
    useCheckForUnsavedChanges({}, selectedFilters);

  // NOTE: This is a temporary workaround until I can think of something better
  // We have to show the Save configuration button if the filters contain any value & we are not using a view
  const hasUnsavedChanges =
    hasUnsavedChangesComparingToInitialFilters ||
    (!contentCalendarView && hasUnsavedChangesComparingToEmptyFilters);

  // State to handle saving configuration as we'll render different UI
  const [isSavingConfiguration, _setIsSavingConfiguration] = useState(false);
  const setIsSavingConfiguration = (value: boolean) => {
    _setIsSavingConfiguration(value);
    onToggleSavingConfiguration(value);
  };

  const unsavedChangesHandlerRender = useMemo(() => {
    if (!hasUnsavedChanges) {
      return null;
    }

    if (isSavingConfiguration) {
      return (
        <CreateViewForm
          selectedFilters={selectedFilters}
          onAfterSubmit={() => {
            refetch();
            setIsSavingConfiguration(false);
          }}
          onCancel={() => {
            setIsSavingConfiguration(false);
          }}
        />
      );
    }

    // If there are unsaved changes and we are not currently using a view,
    // allow user to save current configuration as a new view.
    if (hasUnsavedChanges && !contentCalendarViewId) {
      return (
        <Button
          type="button"
          variant="tertiary"
          sx={{
            display: 'flex',
            mx: 'auto',
            mt: 2,
            mb: 4,
            borderRadius: 2,
          }}
          size="small"
          startIcon={<IconLinearAdd size={16} />}
          onClick={() => setIsSavingConfiguration(true)}
        >
          Save this configuration
        </Button>
      );
    }

    // If there are unsaved changes and we are currently using a view,
    // allow user to update the current view or perform extra actions.
    if (hasUnsavedChanges && contentCalendarViewId) {
      return (
        <ApplyChangesButton
          selectedFilters={selectedFilters}
          onSaveAsNewView={() => {
            setIsSavingConfiguration(true);
          }}
        />
      );
    }

    return null;
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    hasUnsavedChanges,
    contentCalendarViewId,
    isSavingConfiguration,
    selectedFilters,
  ]);

  return (
    <>
      {unsavedChangesHandlerRender}
      {contentCalendarViews.length > 0 ? (
        <>
          <Divider
            sx={{
              width: 'calc(100% - 24px)',
              mx: 'auto',
              my: '8px !important',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: 2,
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
              mb={2}
              px={3}
            >
              Views
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {contentCalendarViews.map((contentCalendarView) => {
                return (
                  <MenuItem
                    key={contentCalendarView.id}
                    onClick={() => {
                      // Deselect if clicking on current view
                      if (contentCalendarViewId) {
                        params.delete('view');
                      } else {
                        params.set('view', contentCalendarView.id);
                      }

                      navigate(
                        {
                          pathname: location.pathname,
                          search: params.toString(),
                        },
                        {
                          state: location.state,
                          replace: true,
                        },
                      );
                    }}
                    sx={{
                      width: '100%',
                      display: 'block',
                      borderRadius: 3,
                      py: 1.5,
                      '&.Mui-focusVisible': {
                        bgcolor: 'transparent',
                      },
                    }}
                    disableRipple
                    disableTouchRipple
                    focusRipple={false}
                  >
                    <ContentCalendarViewListItemView
                      contentCalendarView={contentCalendarView}
                    />
                  </MenuItem>
                );
              })}
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};
