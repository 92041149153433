import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, ClickAwayListener, Collapse, Typography } from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearArrowRight1 } from 'components/icons/components/linear/IconLinearArrowRight1';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import {
  TaskListItemView,
  TaskListItemViewProps,
  TaskPermissionGate,
} from 'features/task';
import {
  NewTaskInput,
  TaskFragmentTaskSubtaskSectionFragment,
  TaskPermission,
  TaskStatus,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { useNestedTaskNavigationContext } from '../../contexts';
import {
  StyledCreateSubtaskButton,
  StyledEmptyStateWrapper,
  StyledHeaderWrapper,
  StyledSubtaskContainer,
} from './styles';
import { TaskSubtaskSectionProps } from './types';

export const TaskSubtaskSection = (props: TaskSubtaskSectionProps) => {
  const {
    task,
    subtasks,
    onSubtaskCreate,
    onSubtaskUpdate,
    componentsProps = {},
  } = props;

  const { user } = useUserContext();

  const {
    isOpen: isSubtasksOpen,
    onOpen: openSubtasks,
    onToggle: toggleSubtasks,
  } = useDisclosure({
    defaultIsOpen: true,
  });

  const [newSubtask, setNewSubtask] = useState<
    (TaskFragmentTaskSubtaskSectionFragment & { isNew: boolean }) | null
  >(null);

  const getNewTaskData = () => {
    return {
      __typename: 'TaskModel',
      id: GenerateId.create(),
      name: '',
      status: TaskStatus.ToDo,
      endDate: null,
      startDate: null,
      priority: null,
      taskMembers: !user ? [] : [user],
      project: null,
      parentTaskId: task.id || null,
      sortOrder: null,
      isNew: true,
      myPermissions: [
        TaskPermission.Read,
        TaskPermission.Update,
        TaskPermission.Delete,
      ],
    } as TaskFragmentTaskSubtaskSectionFragment & { isNew: boolean };
  };

  const Icon = isSubtasksOpen ? IconLinearArrowDown : IconLinearArrowRight1;

  const { pushToStack } = useNestedTaskNavigationContext();

  const listItemProps: Partial<TaskListItemViewProps> = {
    componentsProps: {
      name: {
        style: {
          wordBreak: 'break-word',
        },
      },
      dueDate: {
        sx: {
          '& span': {
            ...theme.typography['body-xl'],
          },
        },
        componentsProps: {
          icon: {
            size: 24,
          },
        },
      },
      priority: {
        sx: {
          '& span': {
            ...theme.typography['body-xl'],
          },
        },
        componentsProps: {
          icon: {
            size: 24,
          },
        },
      },
      assignee: {
        componentsProps: {
          icon: {
            size: 24,
          },
        },
      },
      link: (subtask) => {
        return {
          onClick: (e) => {
            e.preventDefault();
            pushToStack(task, subtask);
          },
          icon: {
            size: 24,
          },
        };
      },
      content: {
        sx: {
          justifyContent: 'space-between',
          flexDirection: 'row',
        },
      },
      actionButtonContainer: {
        sx: {
          gap: 4,
        },
      },
    },
    ...componentsProps?.listItem,
  };

  return (
    <Box>
      <StyledHeaderWrapper
        onClick={(e) => {
          e.preventDefault();
          toggleSubtasks();
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Icon size={16} color={theme.colors?.utility[700]} />
          <Typography variant="headline-lg" color={theme.colors?.utility[700]}>
            Subtasks
          </Typography>
        </Box>
        <TaskPermissionGate
          taskId={task.id}
          permissions={[TaskPermission.Update]}
        >
          <StyledCreateSubtaskButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openSubtasks();
              if (!newSubtask?.isNew) {
                setNewSubtask(getNewTaskData());
              }
            }}
            size="small"
          >
            Add a Subtask
          </StyledCreateSubtaskButton>
        </TaskPermissionGate>
      </StyledHeaderWrapper>
      <ClickAwayListener onClickAway={() => setNewSubtask(null)}>
        <Collapse in={isSubtasksOpen}>
          {subtasks.length === 0 && !newSubtask ? (
            <StyledEmptyStateWrapper>
              <Typography className="empty-state-title">
                No Subtasks yet
              </Typography>
              <Typography className="empty-state-desc">
                Click a Subtask to get things rolling.
              </Typography>
            </StyledEmptyStateWrapper>
          ) : (
            <StyledSubtaskContainer>
              {newSubtask && (
                <TaskListItemView
                  key={newSubtask.id}
                  task={newSubtask}
                  isFocused
                  canNavigate={!newSubtask.isNew}
                  {...listItemProps}
                  onChange={(input) => {
                    const newInput: NewTaskInput = {
                      name: input.name,
                      parentTaskId: task.id,
                      endDate: input.endDate,
                      priority: input.priority,
                      memberIds: input.taskMembers.map((member) => member.id),
                      id: input.id,
                      status: input.status,
                    };
                    if (newSubtask.isNew) {
                      onSubtaskCreate?.(newInput);
                      setNewSubtask({ ...input, isNew: false });
                    } else {
                      onSubtaskUpdate?.({
                        data: newInput,
                        taskId: newSubtask.id,
                      });
                      setNewSubtask({ ...newSubtask, ...input });
                    }
                  }}
                />
              )}
              {[...subtasks.filter((t) => t.id !== newSubtask?.id)].map(
                (subtask) => {
                  return (
                    <TaskListItemView
                      key={subtask.id}
                      task={subtask}
                      {...listItemProps}
                    />
                  );
                },
              )}
            </StyledSubtaskContainer>
          )}
        </Collapse>
      </ClickAwayListener>
    </Box>
  );
};
