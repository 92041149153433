import { Box, Grid, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

export const CollectionCardViewSkeleton = () => {
  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 3,
        bgcolor: theme.colors?.primary.white,
      }}
    >
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton variant="text" height={14} width="32%" animation={false} />
          <Skeleton
            variant="rounded"
            height={22}
            width="25%"
            animation={false}
            sx={{
              borderRadius: 25,
            }}
          />
        </Box>
        <Box display="flex" gap={1} mb={2}>
          <Skeleton variant="rounded" height={8} width={8} animation={false} />
          <Skeleton variant="text" height={10} width="18%" animation={false} />
        </Box>
      </Box>
      <Grid
        container
        spacing={0.25}
        borderRadius={2}
        overflow="hidden"
        sx={{
          overflow: 'hidden',
          borderRadius: 2,
          aspectRatio: '4 / 3',
        }}
      >
        <Grid item xs={12} height="100%">
          <Skeleton variant="rectangular" height="100%" animation={false} />
        </Grid>
      </Grid>
    </Box>
  );
};
