import { gql } from '@apollo/client';
import { Box, Skeleton, Typography } from '@mui/material';
import {
  BillingPaymentMethodFragmentCardDetailFragment,
  BillingPaymentMethodFragmentCardDetailFragmentDoc,
  useGetDefaultPaymentMethodForCardDetailsQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { AdminUserPermissionGate } from 'components/common/PermissionGate';
import { useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { UpdateCardDetails } from './UpdateCardDetails';

// eslint-disable-next-line
gql`
  fragment BillingPaymentMethodFragmentCardDetail on BillingPaymentMethod {
    id
    card {
      brand
      expMonth
      expYear
      last4
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetDefaultPaymentMethodForCardDetails {
    getDefaultPaymentMethod {
      ...BillingPaymentMethodFragmentCardDetail
    }
  }
  ${BillingPaymentMethodFragmentCardDetailFragmentDoc}
`;

export const CardDetails = () => {
  const { data, loading, refetch } =
    useGetDefaultPaymentMethodForCardDetailsQuery();

  const [params] = useSearchParams();
  const action = params.get('action');

  const {
    isOpen: updateCardOpen,
    onOpen: updateCardOnOpen,
    onClose: updateCardOnClose,
  } = useDisclosure();

  const [paymentMethod, setPaymentMethod] =
    useState<BillingPaymentMethodFragmentCardDetailFragment>();

  useEffect(() => {
    if (!loading && data) {
      setPaymentMethod(data.getDefaultPaymentMethod ?? undefined);
    }
  }, [loading, data]);

  useEffect(() => {
    if (action === 'edit-card') {
      updateCardOnOpen();
    }
  }, [action]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        border: `1px solid ${theme.colors?.utility[400]}`,
        p: 4,
        borderRadius: theme.spacing(2),
      }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={theme.spacing(2)}
        >
          <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
            Card Added
          </Typography>

          {paymentMethod?.card && (
            <AdminUserPermissionGate>
              <Typography
                variant="headline-xs"
                color={theme.colors?.primary.black}
                onClick={updateCardOnOpen}
              >
                Edit
              </Typography>
            </AdminUserPermissionGate>
          )}
        </Box>

        {loading ? (
          <Skeleton />
        ) : paymentMethod?.card ? (
          <>
            <Typography variant="headline-md">
              {paymentMethod.card.brand.toUpperCase()} •••• •••• ••••{' '}
              {paymentMethod.card.last4}
            </Typography>
            <Typography variant="headline-xs">
              Expires {paymentMethod.card.expMonth}/{paymentMethod.card.expYear}
            </Typography>
          </>
        ) : (
          <AdminUserPermissionGate>
            <Typography
              variant="headline-md"
              color={theme.colors?.primary.black}
              onClick={updateCardOnOpen}
              sx={{
                cursor: 'pointer',
              }}
            >
              Add a Card
            </Typography>
          </AdminUserPermissionGate>
        )}
      </Box>

      <UpdateCardDetails
        isOpen={updateCardOpen}
        onClose={updateCardOnClose}
        refetch={async () => {
          await refetch();
        }}
      />
    </Box>
  );
};
