import { gql } from '@apollo/client';
import { Box, SxProps, TableCell } from '@mui/material';
import { PostPermissionSummary } from 'features/post-permission';
import {
  PostFragmentJuiceboxTablePostTableRowCellPermissionFragment,
  PostFragmentPostPermissionSummaryFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentJuiceboxTablePostTableRowCellPermission on PostModel {
    id
    ...PostFragmentPostPermissionSummary
  }
  ${PostFragmentPostPermissionSummaryFragmentDoc}
`;

export type JuiceboxTablePostTableRowCellPermissionProps = {
  post: PostFragmentJuiceboxTablePostTableRowCellPermissionFragment;
  sx?: SxProps;
};

export const JuiceboxTablePostTableRowCellPermission = (
  props: JuiceboxTablePostTableRowCellPermissionProps,
) => {
  const { post, sx = {} } = props;

  return (
    <TableCell sx={{ ...sx }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(35, 6, 3, 0.10)',
          color: theme.colors?.primary.black,
          width: 'fit-content',
          borderRadius: theme.spacing(6),
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <PostPermissionSummary
          post={post}
          componentProps={{
            sx: {
              width: '100%',
              ...theme.typography['subhead-lg'],
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
            generalPermission: {
              sx: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            },
          }}
        />
      </Box>
    </TableCell>
  );
};
