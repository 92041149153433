import { Box, Snackbar, Typography } from '@mui/material';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionMultiPostPreviewFragmentDoc,
  useCollectionPermissionToastQuery,
} from 'graphql/generated';
import { CollectionMultiPostPreview } from '../../../collection/components/multiPostPreview';

gql`
  query CollectionPermissionToast($id: String!) {
    collection(id: $id) {
        ...CollectionFragmentCollectionMultiPostPreview
    }
    ${CollectionFragmentCollectionMultiPostPreviewFragmentDoc}
  }
`;

type CollectionPermissionToastProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  collectionId: string;
  message: string;
  autoHideDuration?: number;
};
export const CollectionPermissionToast = (
  props: CollectionPermissionToastProps,
) => {
  const {
    collectionId,
    isOpen,
    onClose,
    message,
    autoHideDuration = 3000,
  } = props;

  const { data: collectionData } = useCollectionPermissionToastQuery({
    variables: {
      id: collectionId,
    },
  });
  const collection = collectionData?.collection;

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  const content = useMemo(() => {
    return (
      <Box
        display="flex"
        gap={theme.spacing(3)}
        alignItems="center"
        justifyContent="center"
      >
        {collection && (
          <Box
            height={theme.spacing(16)}
            width={theme.spacing(16)}
            marginLeft={theme.spacing(4)}
          >
            <CollectionMultiPostPreview
              collection={collection}
              variant="card-stack-square"
              sx={{ marginTop: theme.spacing(2) }}
            />
          </Box>
        )}
        <Typography
          variant="headline-md"
          color={theme.colors?.utility['orange-4']}
        >
          {message}
        </Typography>
      </Box>
    );
  }, []);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={content}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{
        '& .MuiSnackbarContent-root': {
          bgcolor: theme.colors?.utility['orange-1'],
          padding: theme.spacing(4),
          borderRadius: theme.spacing(6),
        },
      }}
    />
  );
};
