import { FollowersScale } from 'graphql/generated';
import { socialPostNotificationConfigFollowersScaleOptions } from './constants';

export const getSocialPostNotificationConfigFollowersScaleSelectLabel = (
  values: FollowersScale[],
) => {
  const selectedIndexes = socialPostNotificationConfigFollowersScaleOptions
    .map((option, index) => {
      if (values.includes(option.value)) {
        return index;
      }

      return null;
    })
    .filter((index) => index !== null) as number[];

  // If we have a consecutive range of indexes, we can merge them into a single string
  // E.g.: 1 - 10k, 10k - 100k -> 1 - 100k
  const isConsecutive = (arr: number[]) => {
    if (arr.length === 1) {
      return false;
    }

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] !== arr[i - 1] + 1) {
        return false;
      }
    }

    return true;
  };

  if (isConsecutive(selectedIndexes)) {
    const startFragments =
      socialPostNotificationConfigFollowersScaleOptions[
        selectedIndexes[0]
      ].label.split('-');
    const endFragments =
      socialPostNotificationConfigFollowersScaleOptions[
        selectedIndexes[selectedIndexes.length - 1]
      ].label.split('-');

    return `${startFragments[0]} - ${endFragments[1] || endFragments[0]}`;
  }

  return selectedIndexes
    .map(
      (index) => socialPostNotificationConfigFollowersScaleOptions[index].label,
    )
    .join(', ');
};
