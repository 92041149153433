import { Box, Card, Typography } from '@mui/material';
// import {
//   BrandContentType,
//   BrandContentTypeSelector,
//   BrandSummary,
// } from 'features/socialMediaListening/components';
import { useGraphDataMaker } from 'features/socialMediaListening/hooks';
import {
  MetricsTimeRange,
  Sentiment,
  useGetBrandDataForSocialListeningAnalyticsQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SentimentThemeCardWithMetricsTimeRange } from './sections/sentimentThemeCard/SentimentThemeCardWithMetricsTimeRange';
import { SentimentTrendTableData } from './sections/sentimentThemeCard/sentimentTrendTableData';
import { TimeDurationSelector } from './sections/timeDurationSelector/TimeDurationSelector';
import { TopicsFilter } from './sections/topicsFilter/TopicsFilter';

type Props = {
  brandId: string;
};

export const SocialMediaListeningAnalyticsInboundView = ({
  brandId,
}: Props) => {
  const [timeRange, setTimeRange] = useState(MetricsTimeRange.OneWeek);
  const [filteredTopicIds, setFilteredTopicIds] = useState<string[]>([]);

  const { data: brandsData } = useGetBrandDataForSocialListeningAnalyticsQuery({
    variables: {
      brandId,
      brandMentionedMetricsData: {
        timeRange,
        topicIds: filteredTopicIds,
      },
      sentimentMetricsData: {
        timeRange,
        topicIds: filteredTopicIds,
      },
      brandTrendsBySentiment: {
        timeRange,
        topicIds: filteredTopicIds,
      },
    },
  });
  const brand = brandsData?.brand;
  const allTopics = brand?.topics || [];

  const { chartData: brandMentionedChartData } = useGraphDataMaker({
    dailyChartData: brand?.brandMentionsMetrics?.chartDataPoints || [],
    timeRange,
  });
  const totalMentions = useMemo(() => {
    return (brandMentionedChartData || []).reduce(
      (acc, curr) => acc + curr.Y,
      0,
    );
  }, [brandMentionedChartData]);

  return (
    <Box>
      <Box display="flex" gap={2} justifyContent="space-between" mb={6}>
        <TopicsFilter
          topics={allTopics}
          onTopicsChange={(topicIds) => {
            setFilteredTopicIds(topicIds);
          }}
        />
        <TimeDurationSelector
          options={[
            MetricsTimeRange.OneWeek,
            MetricsTimeRange.OneMonth,
            MetricsTimeRange.ThreeMonths,
          ]}
          currentValue={MetricsTimeRange.OneWeek}
          onChange={(value) => {
            setTimeRange(value as MetricsTimeRange);
          }}
        />
      </Box>

      <Card
        sx={{
          borderRadius: 5,
          my: 2,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={1}
          mb={3}
        >
          <Typography variant="headline-md" fontSize={theme.spacing(5)}>
            Total number of brand mentions
          </Typography>
          <Typography
            variant="headline-md"
            fontSize={theme.spacing(5)}
            color={theme.colors?.utility[700]}
          >
            {/* definition */}
          </Typography>
          <Typography variant="headline-lg" fontSize={theme.spacing(8)}>
            {Number(totalMentions.toFixed(2))}
          </Typography>
        </Box>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={brandMentionedChartData || []}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              vertical={false}
              stroke={theme.colors?.utility[300]}
            />
            <XAxis dataKey="X" />
            <YAxis
              domain={[
                Math.min(
                  ...((brandMentionedChartData || []).map((x) =>
                    Math.floor(x.Y * 0.9),
                  ) || [0]),
                ),
                Math.max(
                  ...((brandMentionedChartData || []).map((x) =>
                    Math.ceil(x.Y * 1.1),
                  ) || [0]),
                  brand?.brandMentionsMetrics?.allTimeHigh.Y || 0,
                ),
              ]}
              tickFormatter={(value) => formatBigNumber(value)}
            />{' '}
            {/** Range on y axis - For showing All time high */}
            <Tooltip
              formatter={(value, name) => [
                parseFloat(Number(value).toFixed(2)),
                'Mentions',
              ]}
            />
            <Area
              type="monotone"
              dataKey="Y"
              stroke={theme.colors?.utility[600]}
              strokeWidth={3}
              fill="rgba(61, 43, 43, 0.5)"
            />
            {/* {brand?.brandMentionsMetrics?.allTimeHigh.Y && (
              <ReferenceLine
                y={brand.brandMentionsMetrics.allTimeHigh.Y}
                stroke="purple"
                strokeDasharray="3 3"
              >
                <Label
                  value="All Time High"
                  position="insideTopLeft"
                  offset={10}
                  style={{ fill: 'purple', fontSize: 14 }}
                />
              </ReferenceLine>
            )} */}
          </AreaChart>
        </ResponsiveContainer>
      </Card>

      <Box display="flex" gap={2} mb={4}>
        {brand?.sentimentMetrics?.positive && (
          <SentimentThemeCardWithMetricsTimeRange
            sentiment={Sentiment.Positive}
            chartData={{
              title: 'Positive',
              subTitle: '',
              averageCount: brand.sentimentMetrics.positive.totalCount || 0,
              averageCountHelperText: '',
              chartDataPoints: brand.sentimentMetrics.positive.chartDataPoints,
            }}
            timeRange={timeRange}
            componentProps={{
              mainColor: theme.colors?.utility['green-4'],
              highlightColor: 'rgba(224, 228, 202, 0.50)',
            }}
            renderTableData={() => (
              <SentimentTrendTableData
                brandId={brandId}
                selectedTopicIds={filteredTopicIds}
                sentiment={Sentiment.Positive}
                sentimentTrendsData={brand.brandTrendsBySentiment?.positive}
                timeRange={timeRange}
                componentProps={{
                  mainColor: theme.colors?.utility['green-4'],
                  highlightColor: 'rgba(224, 228, 202, 0.50)',
                }}
              />
            )}
          />
        )}

        {brand?.sentimentMetrics?.neutral && (
          <SentimentThemeCardWithMetricsTimeRange
            sentiment={Sentiment.Neutral}
            timeRange={timeRange}
            chartData={{
              title: 'Neutral',
              subTitle: '',
              averageCount: brand.sentimentMetrics.neutral.totalCount || 0,
              averageCountHelperText: '',
              chartDataPoints: brand.sentimentMetrics.neutral.chartDataPoints,
            }}
            componentProps={{
              mainColor: theme.colors?.utility['blue-4'],
              highlightColor: 'rgba(217, 227, 243, 0.50)',
            }}
            renderTableData={() => (
              <SentimentTrendTableData
                brandId={brandId}
                selectedTopicIds={filteredTopicIds}
                sentiment={Sentiment.Neutral}
                sentimentTrendsData={brand.brandTrendsBySentiment?.neutral}
                timeRange={timeRange}
                componentProps={{
                  mainColor: theme.colors?.utility['blue-4'],
                  highlightColor: 'rgba(217, 227, 243, 0.50)',
                }}
              />
            )}
          />
        )}

        {brand?.sentimentMetrics?.negative && (
          <SentimentThemeCardWithMetricsTimeRange
            sentiment={Sentiment.Negative}
            timeRange={timeRange}
            chartData={{
              title: 'Negative',
              subTitle: '',
              averageCount: brand.sentimentMetrics.negative.totalCount || 0,
              averageCountHelperText: '',
              chartDataPoints: brand.sentimentMetrics.negative.chartDataPoints,
            }}
            componentProps={{
              mainColor: theme.colors?.utility['pink-4'],
              highlightColor: 'rgba(246, 225, 230, 0.50)',
            }}
            renderTableData={() => (
              <SentimentTrendTableData
                brandId={brandId}
                selectedTopicIds={filteredTopicIds}
                sentiment={Sentiment.Negative}
                sentimentTrendsData={brand.brandTrendsBySentiment?.negative}
                timeRange={timeRange}
                componentProps={{
                  mainColor: theme.colors?.utility['pink-4'],
                  highlightColor: 'rgba(246, 225, 230, 0.50)',
                }}
              />
            )}
          />
        )}
      </Box>

      {/* <BrandQuestionsInboundCommentInsights
        brandId={brand?.id || ''}
        platforms={[Platform.Tiktok, Platform.Instagram]}
      /> */}
    </Box>
  );
};
