import { gql } from '@apollo/client';
import { Box, IconButton } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { TaskListItemView, TaskListItemViewProps } from 'features/task';
import {
  TaskFragmentTaskListItemViewFragmentDoc,
  useGetTaskByIdsForTasksFieldQuery,
} from 'graphql/generated';
import { Transition, TransitionGroup } from 'react-transition-group';
import { theme } from 'styles/theme';
import {
  TASK_FRAGMENT_USE_TASKS_FIELD_SHORTCUTS,
  useTasksFieldShortcuts,
} from './hooks/useTasksFieldShortcuts';

export const TASK_FRAGMENT_TASKS_FIELD = gql`
  fragment TaskFragmentTasksField on TaskModel {
    ...TaskFragmentTaskListItemView
    ...TaskFragmentUseTasksFieldShortcuts
  }
  ${TaskFragmentTaskListItemViewFragmentDoc}
  ${TASK_FRAGMENT_USE_TASKS_FIELD_SHORTCUTS}
`;

// eslint-disable-next-line
gql`
  query GetTaskByIdsForTasksField($ids: [String!]!) {
    tasksByIds(ids: $ids) {
      ...TaskFragmentTasksField
      subtasks {
        ...TaskFragmentTasksField
      }
    }
  }
  ${TASK_FRAGMENT_TASKS_FIELD}
`;

interface TasksFieldProps {
  value: string[];
  onChange: (value: string[]) => void;
  readOnly?: boolean;
}

export const TasksField = ({ onChange, value, readOnly }: TasksFieldProps) => {
  const { data } = useGetTaskByIdsForTasksFieldQuery({
    variables: {
      ids: value,
    },
    skip: !value.length,
  });

  const existingTasks = data?.tasksByIds || [];

  const listItemProps: Partial<TaskListItemViewProps> = {
    componentsProps: {
      name: {
        style: {
          wordBreak: 'break-word',
          ...theme.typography['subhead-xl'],
        },
      },
      dueDate: {
        sx: {
          '& span': {
            ...theme.typography['subhead-md'],
            whiteSpace: 'nowrap',
          },
        },
        componentsProps: {
          icon: {
            size: 16,
          },
        },
      },
      priority: {
        sx: {
          '& span': {
            ...theme.typography['body-xl'],
          },
        },
        componentsProps: {
          icon: {
            size: 16,
          },
        },
      },
      assignee: {
        componentsProps: {
          icon: {
            size: 16,
          },
        },
      },
      link: () => {
        return {
          icon: {
            size: 16,
          },
        };
      },
      content: {
        sx: {
          justifyContent: 'space-between',
          flexDirection: 'row',
        },
      },
      actionButtonContainer: {
        sx: {
          gap: 4,
        },
      },
    },
  };

  const {
    createNewTask,
    finalTasks,
    pendingTasks,
    onPendingTaskChange,
    focusedTaskId,
    setFocusedTaskId,
    setPendingTasks,
    onTaskNameKeyDown,
  } = useTasksFieldShortcuts({
    existingTasks,
    onAfterTaskCreated: (taskId) => {
      onChange([...value, taskId]);
    },
  });

  const transitionStyles = {
    exiting: {
      opacity: 0,
      transform: 'translateX(50%)',
      transition: 'all 0.2s',
    },
    exited: { opacity: 0 },
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'flex-start',
      }}
    >
      {finalTasks.length > 0 && (
        <Box width="100%">
          <TransitionGroup
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            {finalTasks.map((task) => {
              const isPending = pendingTasks.find((t) => t.id === task.id);
              const isSubtask = Boolean(task.parentTaskId);

              return (
                <Transition timeout={200} key={task.id}>
                  {(state) => {
                    return (
                      <Box
                        position="relative"
                        style={{
                          ...transitionStyles[state],
                        }}
                      >
                        <TaskListItemView
                          task={task}
                          onChange={isPending ? onPendingTaskChange : undefined}
                          readOnly={readOnly}
                          // @ts-ignore
                          canNavigate={isPending && task.loading}
                          isFocused={task.id === focusedTaskId}
                          onFocus={() => setFocusedTaskId(task.id)}
                          onBlur={() => {
                            setFocusedTaskId(undefined);
                            setPendingTasks(
                              pendingTasks.filter((t) => t.name !== ''),
                            );
                          }}
                          showIcon
                          {...listItemProps.componentsProps}
                          sx={{
                            pl: isSubtask ? 8 : undefined,
                          }}
                          componentsProps={{
                            ...listItemProps?.componentsProps,
                            name: {
                              editorProps: {
                                handleKeyDown: onTaskNameKeyDown,
                              },
                              ...listItemProps?.componentsProps?.name,
                            },
                            contextMenu: {
                              onAfterTaskDeleted: (taskId) => {
                                if (pendingTasks.find((t) => t.id === taskId)) {
                                  setPendingTasks([
                                    ...pendingTasks.filter(
                                      (t) => t.id !== taskId,
                                    ),
                                  ]);
                                }
                              },
                            },
                          }}
                        />
                      </Box>
                    );
                  }}
                </Transition>
              );
            })}
          </TransitionGroup>
        </Box>
      )}

      <IconButton
        sx={{
          padding: 0,
          ...theme.typography['subhead-lg'],
          color: theme.colors?.utility[600],
        }}
        disableRipple
        onClick={(e) => {
          e.preventDefault();
          createNewTask();
        }}
        disabled={readOnly}
      >
        <IconLinearAdd size={16} /> Add a Task
      </IconButton>
    </Box>
  );
};
