import { gql } from '@apollo/client';
import { USER_FRAGMENT_AVATAR_GROUP } from 'components/common/AvatarGroup/types';
import {
  CollectionFragmentCollectionViewersFragment,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import { AvatarGroup } from 'components/common/AvatarGroup';
import { useMemo } from 'react';
import { Tooltip } from 'components/common/Tooltip';
import { Box, SxProps, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import moment from 'moment';
import { useUserContext } from 'contexts/users/User.context';
import { UserInfo } from 'features/collaboration';

export const COLLECTION_FRAGMENT_COLLECTION_VIEWERS = gql`
  fragment CollectionFragmentCollectionViewers on CollectionModel {
    id
    viewers {
      lastSeen
      user {
        ...UserFragmentAvatarGroup
      }
    }
  }
  ${USER_FRAGMENT_AVATAR_GROUP}
`;

type CollectionViewersProps = {
  collection: CollectionFragmentCollectionViewersFragment;
  onlineUsers?: UserInfo[];
};

export const CollectionViewers = (props: CollectionViewersProps) => {
  const { collection, onlineUsers } = props;
  const { user } = useUserContext();

  const onlineUserIds = useMemo(
    () => (onlineUsers || []).map((onlineUser) => onlineUser.id),
    [onlineUsers],
  );

  const viewers = useMemo(() => {
    return (
      [...collection.viewers]
        .filter((viewer) => viewer.user.id !== user?.id)
        .map((viewer) => {
          return {
            isViewing: onlineUserIds.includes(viewer.user.id),
            lastSeen: viewer.lastSeen,
            ...viewer.user,
          };
        })
        // put online user first
        .sort((a, b) => {
          if (a.isViewing === b.isViewing) return 0;
          return a.isViewing ? 1 : -1;
        })
    );
  }, [collection.viewers, onlineUserIds, user?.id]);

  const renderViewerAvatar = (
    user: UserFragmentAvatarGroupFragment,
  ): SxProps => {
    const viewer = viewers.find((m) => m.id === user.id);

    if (!viewer?.isViewing) {
      return {
        filter: 'grayscale(100%)',
        opacity: 0.6,
      };
    }

    return {};
  };

  const renderViewerStatus = (user: UserFragmentAvatarGroupFragment) => {
    const member = viewers.find((m) => m.id === user.id);

    if (member?.isViewing) {
      return 'Currently viewing';
    }

    if (member?.lastSeen) {
      return `Last viewed ${moment(member?.lastSeen).fromNow()}`;
    }

    return 'Has not seen post';
  };

  return (
    <AvatarGroup
      users={viewers}
      avatarSize={24}
      avatarConditionalSx={(user) => renderViewerAvatar(user)}
      variant="non-overlap"
      customAvatarTooltip={(user, children) => (
        <Tooltip
          title={
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={theme.spacing(1)}
              padding={theme.spacing(1, 1)}
            >
              <Typography variant="headline-xxs">{`${user.firstName} ${user.lastName}`}</Typography>
              <Typography variant="body-md">
                {renderViewerStatus(user)}
              </Typography>
            </Box>
          }
        >
          {children}
        </Tooltip>
      )}
    />
  );
};
