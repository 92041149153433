import { gql } from '@apollo/client';
import {
  PlotFeature,
  useAppLevelFeatureFlagsQuery,
  useFeatureFlagsQuery,
} from 'graphql/generated';
import { createContext, useContext, useMemo } from 'react';
import { useUserContext } from './users/User.context';

// eslint-disable-next-line
gql`
  query FeatureFlags {
    featureFlags {
      id
      name
      enabled
      organizationId
      updatedAt
      createdAt
    }
  }
`;

// eslint-disable-next-line
gql`
  query AppLevelFeatureFlags {
    appLevelFeatureFlags {
      id
      name
      enabled
      organizationId
      updatedAt
      createdAt
    }
  }
`;

export const FEATURE_FLAG_FRAGMENT_FEATURE_FLAGS_CONTEXT = gql`
  fragment FeatureFlagFragmentFeatureFlagsContext on FeatureFlagModel {
    id
    name
    enabled
    organizationId
    updatedAt
    createdAt
  }
`;

export type FeatureFlagContextType = {
  loading: boolean;
  isFeatureEnabled: (featureName: PlotFeature) => boolean;
};

export const FeatureFlagContext = createContext<FeatureFlagContextType | null>(
  null,
);

type FeatureFlagProps = {
  children: React.ReactNode;
};

export const FeatureFlagContextProvider = ({ children }: FeatureFlagProps) => {
  const { user } = useUserContext();
  const { data: featuresList, loading: isLoadingFeatureList } =
    useFeatureFlagsQuery({
      variables: {},
      skip: !user,
    });

  const { data: appLevelFeaturesList, loading: isLoadingAppLevelFeatureList } =
    useAppLevelFeatureFlagsQuery({
      variables: {},
      skip: !user,
    });

  const providerData = useMemo(() => {
    return {
      loading: isLoadingFeatureList || isLoadingAppLevelFeatureList,
      isFeatureEnabled: (featureName: PlotFeature) => {
        const isEnabledAtOrgLevel =
          featuresList?.featureFlags.find(
            (feature) => feature.name === featureName,
          )?.enabled || false;

        const isEnabledAtAppLevel =
          appLevelFeaturesList?.appLevelFeatureFlags.find(
            (feature) => feature.name === featureName,
          )?.enabled || false;

        return isEnabledAtOrgLevel || isEnabledAtAppLevel;
      },
    };
  }, [
    featuresList,
    appLevelFeaturesList,
    isLoadingAppLevelFeatureList,
    isLoadingFeatureList,
  ]);

  return (
    <FeatureFlagContext.Provider value={providerData}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlagContext = () => {
  return useContext(FeatureFlagContext) || ({} as FeatureFlagContextType);
};
