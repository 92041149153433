import { InternalOrganizationRole, PlotFeature } from 'graphql/generated';
import { createContext, useContext, useMemo } from 'react';
import { useFeatureFlagContext } from './FeatureFlag.context';
import { useUserContext } from './users/User.context';

export enum PlotFeatureType {
  CreativeJuicebox = 'CreativeJuiceBox',
  ContentCalendar = 'ContentCalendar',
  Trends = 'Trends',
  SocialListening = 'SocialListening',
  Tasks = 'Tasks',
}

export type RoleBasedUserAccessContextType = {
  checkCanAccess: (feature: PlotFeatureType) => boolean;
};

export const RoleBasedUserAccessContext =
  createContext<RoleBasedUserAccessContextType | null>(null);

type RoleBasedUserAccessProps = {
  children: React.ReactNode;
};

export const RoleBasedUserAccessContextProvider = ({
  children,
}: RoleBasedUserAccessProps) => {
  const { isFeatureEnabled, loading } = useFeatureFlagContext();
  const { user } = useUserContext();

  const providerData = useMemo(() => {
    return {
      checkCanAccess: (featureName: PlotFeatureType) => {
        // If ff or user is loading, return true to prevent messing with default redirects
        // FIXME: This is a temporary fix to prevent the UI from flickering (e.g. access page is quickly shown
        // before the user is redirected to the correct page).
        // We should only allow page load & access checking once we have the user and feature flag data,
        // not return true or false here.
        if (loading || !user) {
          return true;
        }

        switch (featureName) {
          case PlotFeatureType.ContentCalendar:
            return (
              isFeatureEnabled(PlotFeature.ContentCalendar) &&
              user?.role !== InternalOrganizationRole.SocialListeningUser
            );
          case PlotFeatureType.Tasks:
          case PlotFeatureType.CreativeJuicebox:
          case PlotFeatureType.Trends:
            return user?.role !== InternalOrganizationRole.SocialListeningUser;
          case PlotFeatureType.SocialListening:
            return true;
          default:
            return false;
        }
      },
    };
  }, [isFeatureEnabled, loading, user?.role]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RoleBasedUserAccessContext.Provider value={providerData}>
      {children}
    </RoleBasedUserAccessContext.Provider>
  );
};

export const useRoleBasedUserAccessContext = () => {
  return (
    useContext(RoleBasedUserAccessContext) ||
    ({} as RoleBasedUserAccessContextType)
  );
};
