import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Avatar,
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { IconBoldArrowLeft } from 'components/icons/components/bold/IconBoldArrowLeft';
import { IconBoldArrowRight } from 'components/icons/components/bold/IconBoldArrowRight';
import { BrandMoreContextMenu } from 'features/brand/components';
import { BrandPauseContextMenu } from 'features/brand/components/pauseContextMenu';
import {
  TopicLabel,
  TopicMoreContextMenu,
  TopicPauseContextMenu,
} from 'features/topic';
import {
  AggregatedBrandDurationUsageFragmentBrandTableRowFragment,
  BrandFragmentBrandMoreContextMenuFragmentDoc,
  BrandFragmentBrandPauseContextMenuFragmentDoc,
  TopicFragmentTopicLabelFragmentDoc,
  TopicFragmentTopicMoreContextMenuFragmentDoc,
  TopicFragmentTopicPauseContextMenuFragmentDoc,
} from 'graphql/generated';
import { useCallback } from 'react';
import { theme } from 'styles/theme';
// eslint-disable-next-line
import { brandBreakdownTableHeaders } from './BrandBreakdownDialogView';

// eslint-disable-next-line
gql`
  fragment AggregatedBrandDurationUsageFragmentBrandTableRow on AggregatedBrandDurationUsageResult {
    durationUsed
    totalParsedPostsCount
    totalPositivelyParsedPostsCount
    brand {
      id
      name
      ...BrandFragmentBrandPauseContextMenu
      ...BrandFragmentBrandMoreContextMenu
      creators {
        id
        profilePictureUrl
      }
    }
    topics {
      topic {
        id
        name
        ...TopicFragmentTopicPauseContextMenu
        ...TopicFragmentTopicMoreContextMenu
        ...TopicFragmentTopicLabel
      }
      durationUsed
      totalParsedPostsCount
      totalPositivelyParsedPostsCount
    }
  }
  ${TopicFragmentTopicPauseContextMenuFragmentDoc}
  ${TopicFragmentTopicMoreContextMenuFragmentDoc}
  ${TopicFragmentTopicLabelFragmentDoc}
  ${BrandFragmentBrandMoreContextMenuFragmentDoc}
  ${BrandFragmentBrandPauseContextMenuFragmentDoc}
`;

interface BrandTableRowProps {
  brand: AggregatedBrandDurationUsageFragmentBrandTableRowFragment;
  showTopicDirectly?: boolean;
}

const tableRowSx = {
  borderRadius: 3,
  '& td': {
    borderBottom: `1px solid ${theme.colors?.utility[250]}`,
    ...theme.typography['subhead-xl'],
    color: theme.colors?.utility[800],
  },
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    '.actions': {
      opacity: 1,
    },
  },
  '& td:first-child': {
    pl: 2.5,
    borderRadius: theme.spacing(3, 0, 0, 3),
  },
  '& td:last-child': {
    borderRadius: theme.spacing(0, 3, 3, 0),
  },
};

export const BrandTableRow = ({
  brand: aggregatedBrand,
  showTopicDirectly,
}: BrandTableRowProps) => {
  const {
    brand,
    durationUsed,
    topics,
    totalParsedPostsCount,
    totalPositivelyParsedPostsCount,
  } = aggregatedBrand;

  const topicsCollapsed = useDisclosure();

  const totalDurationUsedInHours = Math.ceil(durationUsed / 3600);

  const renderTopicRow = useCallback(
    (
      topic: AggregatedBrandDurationUsageFragmentBrandTableRowFragment['topics'][0],
    ) => {
      const parsedInHours = Math.ceil(topic.durationUsed / 3600);
      return (
        <TableRow sx={tableRowSx}>
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              {showTopicDirectly ? null : (
                <>
                  <IconBoldArrowLeft
                    size={14}
                    color={theme.colors?.utility[600]}
                    style={{
                      opacity: 0,
                    }}
                  />

                  <Avatar
                    src=""
                    sx={{
                      width: 20,
                      height: 20,
                      opacity: 0,
                    }}
                  />
                </>
              )}
              <Typography
                variant="subhead-xl"
                color={theme.colors?.primary.black}
              >
                {topic.topic.name}{' '}
              </Typography>
              <TopicLabel topic={topic.topic} />
            </Box>
          </TableCell>
          <TableCell>{parsedInHours}</TableCell>
          <TableCell>{topic.totalParsedPostsCount}</TableCell>
          <TableCell>{topic.totalPositivelyParsedPostsCount}</TableCell>
          <TableCell>
            <Box
              className="actions"
              sx={{
                display: 'flex',
                alignItems: 'center',
                opacity: 0,
                justifyContent: 'center',
              }}
            >
              <TopicPauseContextMenu topic={topic.topic} disablePortal />
              <TopicMoreContextMenu topic={topic.topic} disablePortal />
            </Box>
          </TableCell>
        </TableRow>
      );
    },
    [showTopicDirectly],
  );

  // when there is only one brand, show the topics directly
  if (showTopicDirectly) {
    return (
      <>
        {topics.map((topic) => {
          return renderTopicRow(topic);
        })}
      </>
    );
  }

  return (
    <>
      <TableRow sx={tableRowSx}>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
            onClick={topicsCollapsed.onToggle}
          >
            <IconBoldArrowRight
              size={14}
              color={theme.colors?.utility[600]}
              style={{
                opacity: topics.length ? 1 : 0,
                transform: topicsCollapsed.isOpen
                  ? 'rotate(90deg)'
                  : 'rotate(0deg)',
              }}
            />

            <Avatar
              src={
                brand.creators?.[0]?.profilePictureUrl ||
                brand.creators?.[1]?.profilePictureUrl ||
                ''
              }
              sx={{
                width: 20,
                height: 20,
              }}
            />
            <Typography
              variant="headline-sm"
              color={theme.colors?.primary.black}
            >
              {brand.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{totalDurationUsedInHours}</TableCell>
        <TableCell>{totalParsedPostsCount}</TableCell>
        <TableCell>{totalPositivelyParsedPostsCount}</TableCell>
        <TableCell>
          <Box
            className="actions"
            sx={{
              display: 'flex',
              alignItems: 'center',
              opacity: 0,
              justifyContent: 'center',
            }}
          >
            <BrandPauseContextMenu brand={brand} disablePortal />
            <BrandMoreContextMenu brand={brand} disablePortal />
          </Box>
        </TableCell>
      </TableRow>
      {topics.map((topic) => {
        return (
          <TableRow>
            <TableCell style={{ padding: 0, border: 'none' }} colSpan={6}>
              <Collapse
                in={topicsCollapsed.isOpen}
                timeout="auto"
                unmountOnExit
              >
                <Table>
                  <TableHead sx={{ opacity: 0 }}>
                    <TableRow sx={{ border: 'none' }}>
                      {brandBreakdownTableHeaders.map((header, index) => (
                        <TableCell
                          key={header}
                          sx={{
                            padding: 0,
                            border: 'none',
                            width: index === 0 ? '30%' : '18%',
                            height: 0,
                          }}
                        />
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTopicRow(topic)}</TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
