import { PostFilterType } from 'graphql/generated';

export enum CUSTOM_COLLECTION {
  ALL_POSTS = 'all-posts',
  MY_POSTS = 'my-posts',
  SAVED = 'saved',
  SAVED_TRENDS = 'saved-trends',
  CONTENT_CALENDAR = 'content-calendar',
}

export const customCollectionLabels = {
  [CUSTOM_COLLECTION.ALL_POSTS]: 'All Posts',
  [CUSTOM_COLLECTION.MY_POSTS]: 'My Posts',
  [CUSTOM_COLLECTION.SAVED]: 'Saved',
  [CUSTOM_COLLECTION.SAVED_TRENDS]: '⚡ My Saved Trends',
  [CUSTOM_COLLECTION.CONTENT_CALENDAR]: 'Content Calendar',
};

export const customCollectionPostFilterTypes: Record<
  CUSTOM_COLLECTION,
  PostFilterType
> = {
  [CUSTOM_COLLECTION.ALL_POSTS]: PostFilterType.OrganizationPosts,
  [CUSTOM_COLLECTION.MY_POSTS]: PostFilterType.MyPosts,
  [CUSTOM_COLLECTION.SAVED]: PostFilterType.MyFavoritePosts,
  [CUSTOM_COLLECTION.SAVED_TRENDS]: PostFilterType.MySavedTrends,
  [CUSTOM_COLLECTION.CONTENT_CALENDAR]: PostFilterType.ContentCalendar,
};
