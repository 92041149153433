import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StyledSwiperContainer = styled(Box)`
  --swiper-navigation-size: 50px;
  --swiper-navigation-sides-offset: 8px;
`;

export const StyledPagination = styled('div')`
  .swiper-pagination-bullet {
    background: #faf3ec;
    opacity: 50%;
  }

  .swiper-pagination-bullet-active {
    opacity: 100%;
    transform: scale(1.3);
  }
`;

export const StyledNavigation = styled('div')`
  &.swiper-button-next,
  &.swiper-button-prev {
    color: #faf3ec;
    font-size: 18px;

    &::after {
      content: '';
    }

    &.swiper-button-disabled {
      display: none;
    }
  }
`;
