import { useRef, useState } from 'react';
import {
  BASE_MAX_ZOOM_SCALE,
  BASE_MIN_ZOOM_SCALE,
  ZoomOption,
} from '../../Annotation/types';

export type UseZoomProps = {
  defaultZoomOption?: ZoomOption;
};

export const useZoom = (props: UseZoomProps) => {
  const { defaultZoomOption } = props;

  const viewSizes = useRef<{
    viewportWidth: number;
    maxOriginalBoundingBoxWidth: number;
  }>({
    viewportWidth: 0,
    maxOriginalBoundingBoxWidth: 0,
  });

  const getActualZoomScaleBounds = () => {
    // Get size diff between original bounding box & viewport width
    const scaleRatio =
      viewSizes.current.maxOriginalBoundingBoxWidth /
      viewSizes.current.viewportWidth;

    // BASE_MIN_ZOOM_SCALE & BASE_MAX_ZOOM_SCALE are for the viewport,
    // but scaled sizes are calculated based on original bounding box size, so some calculation is required
    // E.g:
    //
    // If viewport is 100, original bounding box is 400, meaning original is 4 times bigger than viewport
    // -> base min scale (0.25) to viewport is (0.25 / 4) to original
    //    base max scale (2) to viewport is (2 / 4) to original
    //
    // On the other hand, if viewport is 100, original is 50, meaning original is only 0.5 times of viewport
    // -> base min scale (0.25) to viewport is (0.25 / 0.5 = 0.25 * 2) to original
    //    base max scale (2) to viewport is (2 / 0.5 = 2 * 2) to original
    return {
      minZoomScale: BASE_MIN_ZOOM_SCALE / scaleRatio,
      maxZoomScale: BASE_MAX_ZOOM_SCALE / scaleRatio,
    };
  };

  const [scale, _setScale] = useState<number | undefined>(() => {
    // If defaultOption is set to ACTUAL_SIZE, set the scale to 1
    if (defaultZoomOption === ZoomOption.ACTUAL_SIZE) {
      return 1;
    }

    // Otherwise, it should be undefined
    // It should be set after the viewport width & maxOriginalBoundingBoxWidth are set
    return undefined;
  });
  const [selectedZoomOption, setSelectedZoomOption] = useState<
    ZoomOption | undefined
  >(defaultZoomOption);

  const setScale = (scale: number) => {
    const bounds = getActualZoomScaleBounds();
    _setScale(
      Math.min(Math.max(bounds.minZoomScale, scale), bounds.maxZoomScale),
    );
  };

  function onZoomIn(s?: number) {
    if (!scale) {
      return;
    }

    const newScale = s || scale + 0.1;

    setSelectedZoomOption(undefined);

    const bounds = getActualZoomScaleBounds();

    if (newScale > bounds.maxZoomScale) {
      setScale(bounds.maxZoomScale);
      return;
    }

    setScale(newScale);
  }

  function onZoomOut(s?: number) {
    if (!scale) {
      return;
    }

    const newScale = s || scale - 0.1;

    setSelectedZoomOption(undefined);

    const bounds = getActualZoomScaleBounds();

    if (newScale < bounds.minZoomScale) {
      setScale(bounds.minZoomScale);
      return;
    }
    setScale(newScale);
  }

  function onResetZoom() {
    setSelectedZoomOption(defaultZoomOption);
  }

  return {
    scale,
    setScale,
    selectedZoomFilter: selectedZoomOption,
    onZoomIn,
    onZoomOut,
    onResetZoom,
    onZoomOptionChanged: setSelectedZoomOption,
    viewSizes,
  };
};
