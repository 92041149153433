import { gql } from '@apollo/client';
import { SxProps } from '@mui/material';
import { PostIconProps, PostPreviewProps } from 'features/post/components';
import {
  PostFragmentPostCardViewFragment,
  PostFragmentPostIconFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostTitleFragmentDoc,
} from 'graphql/generated';

export const POST_FRAGMENT_POST_CARD_VIEW = gql`
  fragment PostFragmentPostCardView on PostModel {
    id
    ...PostFragmentPostPreview
    ...PostFragmentPostTitle
    ...PostFragmentPostIcon
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentPostTitleFragmentDoc}
  ${PostFragmentPostIconFragmentDoc}
`;

export type PostCardViewProps = {
  post: PostFragmentPostCardViewFragment;
  sx?: SxProps;
  componentsProps?: {
    preview?: Pick<PostPreviewProps, 'sx' | 'disableMouseEvents'>;
    icon?: Pick<PostIconProps, 'style' | 'size'>;
  };
  onClick?: () => void;
};
