import { Box, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';
import { ForwardedRef, forwardRef } from 'react';
import { theme } from 'styles/theme';

interface Props {
  label: string;
  item?: UserFragmentAvatarGroupFragment;
  value: string;
  hoverBgColor?: string;
  onSelectItem: VoidFunction;
}

export const JuiceboxFilterSearchItem = forwardRef(
  (
    { label, item, value, hoverBgColor, onSelectItem }: Props,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    return (
      <Box
        tabIndex={0}
        ref={ref}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          borderRadius: 3,
          padding: theme.spacing(2, 3),
          ':hover': {
            backgroundColor: hoverBgColor || theme.colors?.utility[400],
          },
          '&:focus': {
            backgroundColor: hoverBgColor || theme.colors?.utility[400],
            outline: 'none',
          },
        }}
        onClick={onSelectItem}
      >
        <Typography variant="headline-md" color={theme.colors?.utility[600]}>
          {label}
        </Typography>
        {item && <Avatar user={item} size={20} />}

        <Typography variant="headline-md" color={theme.colors?.utility[1100]}>
          {value}
        </Typography>
      </Box>
    );
  },
);
