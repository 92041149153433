import { useDisclosure } from '@dwarvesf/react-hooks';
import { Dialog } from '@mui/material';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CommandHandler } from 'contexts/commands/types';
import { JuiceboxWindowDialogView } from 'features/juiceboxWindow';
import { useEffect } from 'react';

export type PostSelectFromCJBHandlerContext = {
  submitButtonLabel?: string;
  hardcodedContentIdeaCollectionId?: string;
};

export const PostSelectFromCJBHandler: CommandHandler<
  COMMAND_TYPE.POST_SELECT_FROM_CJB
> = (props) => {
  const {
    commandId,
    context: { submitButtonLabel, hardcodedContentIdeaCollectionId } = {},
  } = props;
  const { updateActiveCommand } = useCommandContext();

  const {
    isOpen: isDialogOpen,
    onOpen: openDialog,
    onClose: closeDialog,
  } = useDisclosure();

  useEffect(() => {
    openDialog();
  }, []); // eslint-disable-line -- triger flow on mount

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          width: '100%',
          height: '100%',
          maxWidth: 'calc(100% - 48px)',
          maxHeight: 'calc(100% - 48px)',
          m: 6,
          borderRadius: 6,
          bgcolor: 'transparent',
        },
      }}
    >
      <JuiceboxWindowDialogView
        hardcodedContentIdeaCollectionId={hardcodedContentIdeaCollectionId}
        sx={{ width: '100%', height: '100%' }}
        onClose={closeDialog}
        onSubmit={(posts) => {
          updateActiveCommand(commandId, { status: 'completed', data: posts });
          closeDialog();
        }}
        componentsProps={{
          submitButton: {
            label: submitButtonLabel,
          },
        }}
      />
    </Dialog>
  );
};
