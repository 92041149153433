/**
 * Reusable component for a context menu option.
 * Purely UI because we are handling click events in the ContextMenu component.
 */

import { Box, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

export type ContextMenuOptionProps = {
  label: ReactNode;
  icon?: ReactNode;
  sx?: SxProps;
  componentsProps?: {
    iconContainer?: {
      sx?: SxProps;
    };
  };
};

export const ContextMenuOption = (props: ContextMenuOptionProps) => {
  const { label, icon, sx, componentsProps = {} } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        ...sx,
      }}
    >
      {icon && (
        <Box
          sx={{
            p: 1,
            borderRadius: 1,
            bgcolor: 'rgba(35, 6, 3, 0.05)',
            lineHeight: 0,
            ...componentsProps.iconContainer?.sx,
          }}
        >
          {icon}
        </Box>
      )}
      {typeof label === 'string' ? (
        <Typography variant="subhead-lg">{label}</Typography>
      ) : (
        label
      )}
    </Box>
  );
};
