import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT_ATTACHMENT_NAME } from 'features/attachment';

export const POST_FRAGMENT_ATTACHMENT = gql`
  fragment PostFragmentAttachment on PostModel {
    attachment {
      ...AttachmentFragmentAttachmentName
      url
      metaData {
        mimeType
      }
    }
  }
  ${ATTACHMENT_FRAGMENT_ATTACHMENT_NAME}
`;
