import { Input, Popover, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';
import { typography } from 'components/common/Typography/styles';

export const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    border-radius: 8px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
    min-width: 250px;
  }
`;

export const StyleInput = styled(Input)(() => ({
  ...typography['subhead-md'],
  fontColor: theme.colors?.utility[1000],
  padding: theme.spacing(0, 4),
}));
