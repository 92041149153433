import { Box, SxProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { TaskStatus } from 'graphql/generated';
import { theme } from 'styles/theme/theme';
import { getTaskStatusIcon, getTaskStatusText } from 'features/task';
import { StatusItem } from './StatusItem';

interface Props {
  value?: TaskStatus;
  readOnly?: boolean;
  showOnlyIcon?: boolean;
  iconFillColor?: string;
  iconSize?: number;
  onChange?: (value: TaskStatus) => void;
  sx?: SxProps;
}

export const StatusSelectButton = (props: Props) => {
  const {
    value,
    readOnly: _readOnly,
    showOnlyIcon = false,
    iconFillColor,
    iconSize,
    onChange,
    sx,
  } = props;

  const options = [
    TaskStatus.ToDo,
    TaskStatus.InProgress,
    TaskStatus.InReview,
    TaskStatus.Done,
    TaskStatus.Archived,
  ].map((status) => ({
    label: getTaskStatusText(status),
    value: status,
  }));

  const statusIcons = getTaskStatusIcon(value as TaskStatus);
  const readOnly = _readOnly || !onChange;

  return (
    <AutocompletePopup
      readOnly={readOnly}
      value={options.find((option) => option.value === value)}
      options={options}
      renderTrigger={() =>
        showOnlyIcon ? (
          <Tooltip title={getTaskStatusText(value)} placement="top">
            <Box
              display="flex"
              alignItems="center"
              sx={sx}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <statusIcons.Icon
                color={iconFillColor ?? statusIcons.color}
                size={iconSize ?? statusIcons.size}
              />
            </Box>
          </Tooltip>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            py={2}
            px={3}
            borderRadius="12px"
            whiteSpace="nowrap"
            minHeight={theme.spacing(10)}
            bgcolor={theme.colors?.utility[250]}
            onClick={(e) => {
              e.preventDefault();
            }}
            sx={sx}
          >
            <statusIcons.Icon
              color={iconFillColor ?? statusIcons.color}
              size={iconSize ?? statusIcons.size}
              style={{ marginRight: theme.spacing(2) }}
            />
            <Typography variant="subhead-xl" color={theme.colors?.utility[900]}>
              {getTaskStatusText(value)}
            </Typography>
          </Box>
        )
      }
      onChange={(_, value) =>
        !Array.isArray(value) && onChange?.(value?.value as TaskStatus)
      }
      renderOption={(props, option, state) => {
        return <StatusItem {...props} {...option} selected={state.selected} />;
      }}
      placeholder="Change status"
      popoverProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        sx: {
          '.MuiAutocomplete-listbox': {
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          },
        },
      }}
    />
  );
};
