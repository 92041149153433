import { Avatar } from 'components/common/AvatarGroup';
import { useNewCoordinates } from 'components/common/Media/hooks/useNewCoordinates';
import {
  PostAnnotationFragmentAnnotationFragment,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnnotationPopover } from './AnnotationPopover';
import { StyledAnnotationAvatar } from './styles';

export const RenderAnnotation = ({
  annotation,
  popoverOpenByDefault,
  popoverOpenPopDelay = 0,
  customScale,
}: {
  annotation: PostAnnotationFragmentAnnotationFragment;
  popoverOpenByDefault?: boolean;
  // Delay in ms to open popover when handling postAnnotationToPop
  // FIXME: Find a better way. Right now we need this because it takes time for the page
  // to be scrolled to in CustomDocument (we are using virtualized list)
  popoverOpenPopDelay?: number;
  customScale?: number;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { x, y } = annotation;
  const { newX, newY } = useNewCoordinates({ x, y, customScale });

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  const onOpenPopover = () => {
    setAnchorEl(containerRef.current);
  };

  // When annotation props of this component changes,
  // Open popover if popoverOpenByDefault is true
  useEffect(() => {
    if (popoverOpenByDefault) {
      setTimeout(() => {
        onOpenPopover();
      });
    }
  }, [annotation.id, popoverOpenByDefault]);

  const location = useLocation();
  const navigate = useNavigate();

  // Get postAnnotationToPop from location state. See CommentRenderer::L61
  const locationState = location.state as {
    postAnnotationToPop?: PostAnnotationFragmentAnnotationFragment;
  } | null;

  // If postAnnotationToPop is the same as this annotation id, open popover
  useEffect(() => {
    setTimeout(() => {
      if (locationState?.postAnnotationToPop?.id === annotation.id) {
        // @ts-ignore
        containerRef.current?.scrollIntoView({ behavior: 'instant' });
        onOpenPopover();

        // Clear postAnnotationToPop from location state
        navigate(location.pathname, {
          state: {
            ...((location.state as any) || {}),
            postAnnotationToPop: undefined,
          },
          replace: true,
        });
      }
    }, popoverOpenPopDelay);
  }, [annotation.id, locationState?.postAnnotationToPop]); // eslint-disable-line

  const annotationAvatarStyle =
    x && y
      ? {
          left: `${newX}px`,
          top: `${newY}px`,
        }
      : {};

  return (
    <>
      <StyledAnnotationAvatar
        ref={containerRef}
        onClick={onOpenPopover}
        style={{
          position: 'absolute',
          ...annotationAvatarStyle,
          opacity: annotation.isResolved ? 0.7 : 1,
        }}
        className="annotation__icon"
      >
        <Avatar
          user={annotation.createdBy as UserFragmentAvatarGroupFragment}
          key={annotation.createdBy.email}
          size={24}
        />
      </StyledAnnotationAvatar>
      <AnnotationPopover
        anchorEl={anchorEl}
        annotation={annotation}
        onClose={onClosePopover}
      />
    </>
  );
};
