import { Popover } from '@mui/material';
import { CommentInput } from 'features/comments';
import { CommentInputData } from 'graphql/generated';
import { theme } from 'styles/theme';
import { ATTR_NOTE_COMMENT_ANCHOR_ID } from '../../constants';

export type CommentInputPopoverProps = {
  noteCommentAnchorId: string;
  onClose: () => void;
  onCreateComment: (comment: CommentInputData) => void;
};

export const CommentInputPopover = (props: CommentInputPopoverProps) => {
  const { noteCommentAnchorId, onClose, onCreateComment } = props;

  const anchorEl = document.querySelector(
    // NOTE: See Comment extension. We are using "btn-" as a prefix for the noteCommentAnchorIds
    // passed by the comment count decorators. So when we are looking for the anchor element,
    // we have to differentiate between finding the node in the editor body, or the button decorators.
    noteCommentAnchorId.includes('btn-')
      ? `[${ATTR_NOTE_COMMENT_ANCHOR_ID}*="${noteCommentAnchorId}"]`
      : `[${ATTR_NOTE_COMMENT_ANCHOR_ID}*="${noteCommentAnchorId}"]:not([${ATTR_NOTE_COMMENT_ANCHOR_ID}^="btn-"])`,
  );

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      PaperProps={{
        sx: {
          position: 'relative',
          p: 4,
          maxWidth: 340,
          '.comment-input-wrapper': {
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1.5,
          },
          '.comment-user': {
            pt: 2,
          },
        },
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <CommentInput
        autoFocus
        inputStyle={{
          borderRadius: theme.spacing(5),
          backgroundColor: theme.colors?.utility[275],
          padding: theme.spacing(1, 2.5),
        }}
        editorStyle={{
          ...theme.typography['subhead-xl'],
          color: theme.colors?.utility[900],
          padding: theme.spacing(1, 1, 1, 0),
        }}
        onCreateComment={onCreateComment}
        placeholder="Add a comment"
      />
    </Popover>
  );
};
