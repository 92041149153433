import { Box, Typography } from '@mui/material';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import { getNoteColorFromColor } from 'features/note/utils';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import { PostNotePreviewCompactViewProps } from './types';

export const PostNotePreviewCompactView = (
  props: PostNotePreviewCompactViewProps,
) => {
  const { post, sx, componentsProps } = props;

  const postColor = useMemo(() => getNoteColorFromColor(post.color), [post]);

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        p: 2,
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'center',
        gap: 1.5,
        color: postColor ? postColor.textColor : theme.colors?.primary.black,
        backgroundColor: post.color,
        ...sx,
      }}
    >
      <IconLinearNote2
        size={12}
        style={{ flexShrink: 0 }}
        {...componentsProps?.icon}
      />
      <Typography
        {...componentsProps?.title}
        sx={{
          ...theme.typography['headline-xl'],
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 1.4,
          wordWrap: 'break-word',
          ' -webkit-line-clamp': 3,
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden',
          ...componentsProps?.title?.sx,
        }}
      >
        {post.title}
      </Typography>
    </Box>
  );
};
