import { gql } from '@apollo/client';
import {
  POST_FRAGMENT_ATTACHMENT,
  POST_FRAGMENT_URL_METADATA,
} from 'features/post/components';

export const NOTE_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL = gql`
  fragment NoteSearchHitFragmentItemThumbnail on PostSearchHit {
    item {
      id
    }
  }
`;

export const LINK_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL = gql`
  fragment LinkSearchHitFragmentItemThumbnail on PostSearchHit {
    item {
      id
      ...PostFragmentUrlMetadata
    }
  }
  ${POST_FRAGMENT_URL_METADATA}
`;

export const ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL = gql`
  fragment AttachmentSearchHitFragmentItemThumbnail on PostSearchHit {
    item {
      id
      ...PostFragmentAttachment
    }
  }
  ${POST_FRAGMENT_ATTACHMENT}
`;

export const TASK_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL = gql`
  fragment TaskSearchHitFragmentItemThumbnail on TaskSearchHitV2 {
    item {
      id
    }
  }
`;

export const COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL = gql`
  fragment CollectionSearchHitFragmentItemThumbnail on CollectionSearchHit {
    item {
      id
    }
  }
`;
