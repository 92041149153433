import { Fragment } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';
import { GenerateId } from 'utils/generateId';
import { useUploadCare } from 'hooks/useUploadCare';
import { ATTR_BLOCK_ID, NODE_EMBED } from '../extensions';
import { buildColumn, buildColumnBlock } from '../extensions/column/utils';
import { checkIfSelectionIsInsideAColumnBlock } from '../utils';

export const useFileUploadHandlers = () => {
  const uploadFile = useUploadCare();

  const handleMediaUpload = (view: EditorView, files: File[]) => {
    const { schema, selection, doc, tr } = view.state;

    // for case user is selecting a text, then paste a link (cmd+V) => automatically create an anchor link
    const selectionValue = doc.textBetween(selection.from, selection.to, ' ');
    if (selectionValue) {
      return;
    }

    const filesWithIds = files.map((file) => ({
      id: GenerateId.create(),
      file,
    }));

    // create new components with local image url and loading state
    if (filesWithIds.length === 1) {
      const embedNode = schema.nodes[NODE_EMBED].create({
        file: filesWithIds[0].file,
      });

      tr.replaceSelectionWith(embedNode);
      view.dispatch(tr);
    } else if (filesWithIds.length > 1) {
      // First, check if the current selection sits inside a column block,
      // because we can't insert a column-list block inside a column block
      const columnable = checkIfSelectionIsInsideAColumnBlock(selection);

      if (columnable) {
        const MAX_COLUMN = 2;
        const columns: Fragment[][] = new Array(MAX_COLUMN)
          .fill(null)
          .map(() => []);

        filesWithIds.forEach((fileWithId, index) => {
          // Max 2 columns so we'll cycle through the columns
          columns[index % MAX_COLUMN].push(
            schema.nodes[NODE_EMBED].create({
              [ATTR_BLOCK_ID]: fileWithId.id,
              file: fileWithId.file,
            }).toJSON(),
          );
        });

        const columnListNode = schema.nodeFromJSON(
          buildColumnBlock({
            content: columns.map((c) => buildColumn({ content: c })),
          }),
        );

        tr.replaceSelectionWith(columnListNode);
      } else {
        // Insert the files as separate nodes
        const postNodes = filesWithIds.map((fileWithId) =>
          schema.nodes[NODE_EMBED].create({
            [ATTR_BLOCK_ID]: fileWithId.id,
            file: fileWithId.file,
          }),
        );

        tr.insert(tr.selection.from, postNodes);
      }

      view.dispatch(tr);
    }

    // upload image and replace url
    filesWithIds.forEach(({ id, file }) => {
      uploadFile?.(file, file.name).then((uploadResult) => {
        view.state.doc.descendants((node, pos) => {
          if (node.attrs[ATTR_BLOCK_ID] === id) {
            const transaction = view.state.tr.setNodeMarkup(pos, undefined, {
              ...node.attrs,
              src: uploadResult?.cdnUrl,
              loading: null,
            });

            view.dispatch(transaction);
            return false;
          }
        });
      });
    });
  };

  return { handleMediaUpload };
};
