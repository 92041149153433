export const stringToColor = (str: string, s: number = 40, l: number = 50) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
};

export const stringAvatar = (fullName: string = 'Plot') => {
  const name = fullName?.split(' ');
  return `${name[0][0]}${name[1] ? name[1][0] : ''}`;
};
