import { gql } from '@apollo/client';
import {
  SearchResultFragmentRichTextFragmentDoc,
  SearchableEntityType,
  useGetSearchResultForRichTextLazyQuery,
} from 'graphql/generated';
import { useCallback, useRef } from 'react';

// eslint-disable-next-line
gql`
  query GetSearchResultForRichText($data: SearchInput!) {
    searchEntities(data: $data) {
      ...SearchResultFragmentRichText
    }
  }
  ${SearchResultFragmentRichTextFragmentDoc}
`;

export const useSearchHandlers = (entityTypes: SearchableEntityType[]) => {
  const clearTimeoutRef = useRef<any>();
  const [getSearchResult] = useGetSearchResultForRichTextLazyQuery();

  const searchHandler = useCallback(
    async (query: string) => {
      const result = await getSearchResult({
        variables: {
          data: {
            text: query,
            limit: 20,
            entityTypes,
          },
        },
      });
      return result;
    },
    [entityTypes, getSearchResult],
  );

  const onSearch = async (query: string) => {
    // Clear the timeout reference if it exists
    clearTimeoutRef.current?.();

    // Create a new promise to perform a search with a timeout of 500ms
    const result = (await new Promise((resolve) => {
      const timeout = setTimeout(async () => {
        // Perform the search and resolve the promise with the result
        const res = await searchHandler(query);
        resolve(res);
      }, 300);
      // Store a reference to the clearTimeout function to be able to cancel the timeout
      clearTimeoutRef.current = () => {
        // Clear the timeout and resolve the promise with undefined
        clearTimeout(timeout);
        resolve(undefined);
      };
    })) as any;

    return result;
  };

  return {
    onSearch,
  };
};
