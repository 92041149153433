import { Box, Typography } from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { theme } from 'styles/theme';
import { SortOption } from '../types';
import { SortByOptions } from './SortByOptions';

type Props = {
  options: SortOption[];
  currentSortBy: string;
  onOptionClick: (key: string) => void;
};

export const SortBy = ({ options, onOptionClick, currentSortBy }: Props) => {
  if (!options.length) {
    return null;
  }

  const renderLabel = options.find(
    (option) => option.key === currentSortBy,
  )?.label;

  return (
    <MoreMenuItem
      disableRipple
      label={
        <Box
          sx={{
            m: 0,
            borderRadius: theme.spacing(3),
            width: 270,
            justifyContent: 'space-between',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            sx={{ width: '100%' }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              <IconLinearSort size={16} />
            </Box>
            <Box display="flex" flex={1}>
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                fontWeight={500}
              >
                Sort By
              </Typography>
            </Box>

            <Typography
              variant="body-lg"
              color={theme.colors?.utility[600]}
              fontWeight={500}
            >
              {renderLabel || 'No Selection'}
            </Typography>
          </Box>
        </Box>
      }
    >
      <SortByOptions options={options} onOptionClick={onOptionClick} />
    </MoreMenuItem>
  );
};
