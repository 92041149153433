import { Box, styled } from '@mui/material';
import { PostThemeColor } from 'features/note/constants/noteColors';
import { theme } from 'styles/theme';

export const NoteRichTextContainer = styled(Box)<{
  themeColor?: PostThemeColor;
}>(({ themeColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  maxWidth: '100vw',

  '.ProseMirror': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '> *': {
      width: '100%',
    },
  },

  a: {
    color: themeColor?.linkColor,
  },

  // Update selection bg color
  p: {
    '::selection': {
      background: themeColor?.textSelectionBackgroundColor,
    },
  },

  span: {
    '::selection': {
      background: themeColor?.textSelectionBackgroundColor,
    },
  },

  code: {
    backgroundColor: themeColor?.focusBgColor,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
  },

  '.node-codeBlock': {
    backgroundColor: themeColor?.focusBgColor,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 2),

    code: {
      backgroundColor: 'transparent',
      padding: 0,
    },
  },

  '.mention-node': {
    color: `${themeColor?.textMentionColor} !important`,
  },

  'span[data-withcomment="true"]': {
    backgroundColor: themeColor?.textCommentedBackgroundColorL3,
    borderBottom: `1px solid ${themeColor?.textMentionColor}`,
    transition: 'background-color 0.2s',

    '&.comment-thread-count-1': {
      backgroundColor: themeColor?.textCommentedBackgroundColor,
    },

    '&.comment-thread-count-2': {
      backgroundColor: themeColor?.textCommentedBackgroundColorL2,
    },
  },

  '.block[data-block-id]': {
    '&::after': {
      backgroundColor: themeColor?.focusBgColor,
    },

    '&.node-embed': {
      '.embed-node-view': {
        '&:hover': {
          '&::after': {
            backgroundColor: themeColor?.focusBgColor,
          },
        },

        '&[data-notecommentanchorid]': {
          backgroundColor: themeColor?.textCommentedBackgroundColor,
        },
      },
    },

    '&.node-horizontalRule': {
      '&::before': {
        backgroundColor: themeColor?.dividerColor,
      },
    },
  },

  '.is-commandMenu-empty': {
    '&::after': {
      backgroundColor: themeColor?.focusBgColor,
      color: themeColor?.textPlaceholderColor,
    },
  },
}));
