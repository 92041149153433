import { PostModel } from 'graphql/generated';
import { getMimeTypeFromUrl } from 'utils/mime';

export const isPostDownloadable = async (post: {
  urlMetadata?: Pick<PostModel['urlMetadata'][0], 'metadata' | 'url'>[];
}) => {
  const fileTypes = ['file', 'video', 'image', 'socialMediaPosts'];
  const urlMetadata = post.urlMetadata?.[0];
  let medium = urlMetadata?.metadata?.medium || '';
  const url = urlMetadata?.url;

  if (urlMetadata?.metadata?.medias?.length) {
    medium = 'socialMediaPosts';
  } else if (!medium && url) {
    let mimeType = getMimeTypeFromUrl(url);

    if (!mimeType) {
      // check if url is downloadable
      const res = await fetch(url);
      if (res.ok) {
        mimeType = res.headers.get('Content-Type') || '';
      }
    }

    if (mimeType && mimeType.startsWith('image/')) {
      medium = 'image';
    } else if (mimeType && mimeType.startsWith('video/')) {
      medium = 'video';
    } else if (mimeType && mimeType.startsWith('application/')) {
      medium = 'file';
    }
  }

  return fileTypes.includes(medium);
};

export const isPostPinnedToCollection = (
  post: {
    pinnedToCollections: Pick<
      PostModel['pinnedToCollections'][0],
      'parentCollectionId'
    >[];
  },
  collectionId: string,
) => {
  return post.pinnedToCollections.some(
    (p) => p.parentCollectionId === collectionId,
  );
};
