import { useDisclosure } from '@dwarvesf/react-hooks';
import { useCheckIfCustomCollection } from 'features/collection';
import { CollectionInviteType } from 'graphql/generated';
import { CollectionPermissionDialogView } from '../views';

type Props = {
  collectionId: string;
};

export const useCollectionPermissionDialogView = ({ collectionId }: Props) => {
  const {
    isOpen: isOpenPermissionDialog,
    onOpen: onOpenPermissionDialog,
    onClose: onClosePermissionDialog,
  } = useDisclosure();

  const isCustomCollection = useCheckIfCustomCollection(collectionId);

  const renderContent = () => {
    if (!collectionId || isCustomCollection) return null;

    return !isOpenPermissionDialog ? null : (
      <CollectionPermissionDialogView
        collectionId={collectionId}
        open={isOpenPermissionDialog}
        onClose={onClosePermissionDialog}
        collectionInviteType={CollectionInviteType.CollectionInvite}
        renderCustomButton={() => <></>}
      />
    );
  };

  return {
    renderContent,
    openDialog: onOpenPermissionDialog,
  };
};
