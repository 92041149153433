import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
} from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { theme } from 'styles/theme';
import { InternalMembers } from 'features/organizationMembers/views/MembersView/InternalMembers';
import { useImmer } from 'use-immer';
import { useMembersHandlers } from 'pages/org/settings/members/hooks/useMembersHandlers';
import { UserProfileFragmentUserContextFragment } from 'graphql/generated';
import { useState } from 'react';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useUserContext } from 'contexts/users/User.context';
import { IconBoldWarning2 } from 'components/icons/components/bold/IconBoldWarning2';

type ManageMembersProps = {
  activeOrgUsers: UserProfileFragmentUserContextFragment[];
  selectionLimit?: number;
  isOpen: boolean;
  onClose: VoidFunction;
  isNotClosable?: boolean;
  onGoBack?: VoidFunction;
  subDescription?: string;
  onCustomSubmit?: (selected: string[]) => void;
};

export const ManageMembers = (props: ManageMembersProps) => {
  const {
    activeOrgUsers,
    selectionLimit,
    isOpen,
    onClose,
    isNotClosable,
    onGoBack,
    subDescription,
    onCustomSubmit,
  } = props;

  const { enableOnlyTheseUsersInOrg, userEnableConfirmationDialog } =
    useMembersHandlers();

  const { user } = useUserContext();

  const [selectedMembers, setSelectedMembers] = useImmer<string[]>(
    user ? [user.id] : [],
  );

  const [loading, setLoading] = useState(false);
  const handleUpdateMembers = async () => {
    setLoading(true);

    enableOnlyTheseUsersInOrg(selectedMembers);

    setLoading(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      PaperProps={{ sx: { bgcolor: 'rgba(250, 243, 236, 0.9)' } }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `2px solid rgba(35, 6, 3, 0.1)`,
            padding: theme.spacing(6, 8),
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {onGoBack && (
              <IconButton onClick={onGoBack}>
                <IconOutlineArrowLeft
                  color={theme.colors?.primary.black}
                  size={24}
                />
              </IconButton>
            )}

            <Typography variant="headline-lg">
              Manage Members · {selectedMembers.length} member
              {selectedMembers.length > 1 ? 's' : ''} selected
            </Typography>
          </Box>

          {!isNotClosable && (
            <IconButton size="small" onClick={onClose}>
              <IconBoldCloseCircle />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          padding: theme.spacing(6, 8),
          height: '55vh',
          overflow: 'auto',
        }}
      >
        <InternalMembers
          hideTableHeaders
          hideMoreOptionMenu
          showCheckBox
          selectionLimit={selectionLimit}
          users={activeOrgUsers}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: subDescription ? 'space-between' : 'flex-end',
          padding: theme.spacing(6, 8),
          borderTop: `2px solid rgba(35, 6, 3, 0.1)`,
        }}
      >
        {subDescription && (
          <Box
            sx={{
              bgcolor: '#2306030D',
              display: 'flex',
              alignItems: 'center',
              borderRadius: theme.spacing(2),
              gap: theme.spacing(2),
              padding: theme.spacing(2, 3),
            }}
          >
            <IconBoldWarning2 color={theme.colors?.primary.black} />
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[800]}
            >
              {subDescription}
            </Typography>
          </Box>
        )}
        <Button
          variant="contained"
          onClick={
            onCustomSubmit
              ? () => onCustomSubmit(selectedMembers)
              : handleUpdateMembers
          }
          disabled={loading || selectedMembers.length === 1}
          endIcon={loading && <CircularProgress size={14} />}
          sx={{
            bgcolor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
            borderRadius: theme.spacing(2),
            ':hover': {
              bgcolor: theme.colors?.primary.black,
              color: theme.colors?.primary.white,
            },
          }}
        >
          Update Members
        </Button>

        {userEnableConfirmationDialog}
      </Box>
    </Dialog>
  );
};
