import { useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box } from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import { PostBookmarkPopover } from 'features/post/components/bookmarkButton/PostBookmarkPopover';
import { useCreatePostHandler } from 'features/trending/hooks/useCreatePostHandler';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { TrendSaveButtonProps } from './types';

export const TrendingSaveButton = (props: TrendSaveButtonProps) => {
  const {
    isSavedToTrendsCollection,
    trendId,
    renderBtn,
    urlMetadataId,
    postId,
    onPostCreated,
    componentsProps = {},
  } = props;

  const client = useApolloClient();
  const [currentPostId, setCurrentPostId] = useState('');
  const { onCreatePostForTrend, isLoadingCreatePostForTrend } =
    useCreatePostHandler();

  useEffect(() => {
    setCurrentPostId(postId ?? '');
  }, [postId]);

  const anchorElRef = useRef<HTMLElement>(null);
  const {
    isOpen: isBookmarkPopoverOpen,
    onClose: closeBookmarkPopover,
    onOpen: openBookmarkPopover,
  } = useDisclosure();

  const handleCreatePost = async () => {
    if (!currentPostId) {
      const data = await onCreatePostForTrend(trendId, urlMetadataId);
      return data.data?.createPostForTrend.id || '';
    }
    return currentPostId;
  };

  const reloadTrendData = () => {
    client.refetchQueries({
      include: ['TrendQueryForTrendingDetailView'],
    });
  };

  const handleClick = () => {
    handleCreatePost().then((id) => {
      setCurrentPostId(id);
      onPostCreated?.(id);
      reloadTrendData();

      setTimeout(() => {
        openBookmarkPopover();
      }, 0);
    });
  };

  const handleClose = () => {
    closeBookmarkPopover();
  };

  return (
    <Box>
      {renderBtn ? (
        <Box ref={anchorElRef} onClick={handleClick}>
          {renderBtn()}
        </Box>
      ) : (
        <Box display="flex">
          <IconButtonWithTooltip
            tooltip={currentPostId ? 'Saved' : 'Save this post'}
            size="small"
            ref={anchorElRef as any}
            sx={{
              width: 'fit-content',
              padding: 2.5,
              bgcolor: theme.colors?.utility['purple-1'],
            }}
            onClick={() => {
              if (currentPostId) {
                openBookmarkPopover();
              } else {
                handleClick();
              }
            }}
            disabled={isLoadingCreatePostForTrend}
          >
            {!currentPostId ? (
              <IconLinearSave size={24} color={theme.colors?.primary.black} />
            ) : (
              <IconBoldSave size={24} color={theme.colors?.primary.black} />
            )}
          </IconButtonWithTooltip>
        </Box>
      )}
      {isBookmarkPopoverOpen && (
        <PostBookmarkPopover
          post={{
            id: currentPostId,
            isFavorite: !!isSavedToTrendsCollection,
            isFavoriteTrend: !!isSavedToTrendsCollection,
            trend: { id: trendId, title: '' },
          }}
          disableHeaderSaveButton
          open={isBookmarkPopoverOpen}
          anchorEl={anchorElRef.current}
          onClose={handleClose}
          {...componentsProps.popover}
        />
      )}
    </Box>
  );
};
