import { TableCell, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledTableHeadCell = styled(TableCell)(() => ({
  borderWidth: 0,
  fontSize: 13,
  fontWeight: 600,
  paddingLeft: 0,
  paddingTop: 6,
  paddingBottom: 6,
  color: theme.colors?.utility[700],
  fontFamily: theme.typography['headline-xxs'].fontFamily,
}));

export const StyledTableCell = styled(TableCell)(() => ({
  borderColor: theme.colors?.utility[300],
  fontSize: 13,
  fontWeight: 600,
  paddingLeft: 0,
  paddingTop: 6,
  paddingBottom: 6,
  color: theme.colors?.utility[800],
  fontFamily: theme.typography['body-lg'].fontFamily,
  textTransform: 'capitalize',
}));
