import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import {
  CompetitorDetailType,
  DefaultCompetitorDetail,
} from 'features/socialMediaListening/views/onboarding/SocialMediaListeningCompetitorsOnboardingView';
import React, { useState } from 'react';
import { theme } from 'styles/theme';

type Props = {
  activeUntilIndex: number;
  currentIndex: number;
  competitors: CompetitorDetailType[];
  updateCompetitors: (competitors: CompetitorDetailType[]) => void;
  removeCompetitor: (index: number) => void;
  onClick: (index: number) => void;
};

export const ManageCompetitors = ({
  activeUntilIndex,
  currentIndex,
  competitors,
  updateCompetitors,
  removeCompetitor,
  onClick,
}: Props) => {
  const newCompetitorDisclosure = useDisclosure();
  const [newCompetitorName, setNewCompetitorName] = useState('');

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          left: theme.spacing(4),
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          sx={{ maxHeight: '80vh', overflowY: 'auto' }}
        >
          {competitors.map((competitor, index) => (
            <Typography
              variant="body-xl"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                cursor: 'pointer',
                fontWeight: currentIndex === index ? 600 : 500,
                fontSize:
                  currentIndex === index
                    ? theme.spacing(5)
                    : theme.spacing(3.5),
                color:
                  currentIndex === index
                    ? theme.colors?.utility['yellow-1']
                    : theme.colors?.utility[600],
                opacity: activeUntilIndex >= index ? 1 : 0.6,
                pointerEvents: activeUntilIndex >= index ? 'auto' : 'none',
                '&:hover .delete-icon': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
              onClick={() => {
                if (index <= activeUntilIndex) {
                  onClick(index);
                }
              }}
            >
              {competitor.name}
              {competitors.length > 1 && (
                <Box
                  className="delete-icon"
                  sx={{
                    display: 'none',
                    '&:hover': {
                      color: theme.colors?.utility[400],
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeCompetitor(index);
                  }}
                >
                  <IconBoldCloseCircle size={16} />
                </Box>
              )}
            </Typography>
          ))}
          <Typography
            variant="body-lg"
            onClick={() => {
              newCompetitorDisclosure.onOpen();
            }}
            sx={{
              mt: 2,
              cursor: 'pointer',
              fontWeight: 600,
              width: 'fit-content',
              padding: theme.spacing(1, 3),
              bgcolor: 'rgba(250, 243, 236, 0.20)',
              borderRadius: theme.spacing(4),
            }}
          >
            + Add Competitor
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={newCompetitorDisclosure.isOpen}
        PaperProps={{
          sx: {
            m: 0,
            p: 8,
            borderRadius: 6,
            width: 360,
            bgcolor: theme.colors?.primary.white,
          },
        }}
        onClose={newCompetitorDisclosure.onClose}
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="headline-lg">Add a competitor</Typography>
          <TextField
            placeholder="Competitor"
            sx={{
              '.MuiOutlinedInput-root': {
                bgcolor: 'rgba(250, 243, 236, 0.30)',
                border: `1px solid ${theme.colors?.utility[400]}`,
              },
            }}
            value={newCompetitorName}
            onChange={(e) => {
              setNewCompetitorName(e.target.value);
            }}
          />
          <Button
            variant="primary"
            disabled={!newCompetitorName}
            sx={{
              backgroundColor: theme.colors?.primary.black,
              color: theme.colors?.primary.parchment,
              '&:hover': {
                backgroundColor: theme.colors?.primary.black,
                color: theme.colors?.primary.parchment,
              },
            }}
            onClick={() => {
              updateCompetitors([
                ...competitors,
                {
                  ...DefaultCompetitorDetail,
                  name: newCompetitorName,
                },
              ]);
              setNewCompetitorName('');
              newCompetitorDisclosure.onClose();
            }}
          >
            Add
          </Button>
          <Button
            variant="text"
            sx={{
              color: theme.colors?.primary.black,
            }}
            onClick={newCompetitorDisclosure.onClose}
          >
            Cancel
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
