import { InternalOrganizationRole } from 'graphql/generated';
import { useUserContext } from 'contexts/users/User.context';

interface Props {
  children: React.ReactNode;
}

export const AdminUserPermissionGate = (props: Props) => {
  const { user } = useUserContext();
  const { children } = props;

  if (user?.role === InternalOrganizationRole.Admin) {
    // eslint-disable-next-line -- prevent TS error
    return <>{children}</>;
  }

  return null;
};
