import { Dispatch, RefObject, SetStateAction, useState } from 'react';
import { useFullScreenHandle } from 'react-full-screen';

export const useVideoControls = (
  videoRef: RefObject<HTMLVideoElement>,
  setMaxBoundingBoxWidth?: Dispatch<SetStateAction<number>>,
) => {
  // total duration of video in seconds
  const [duration, setDuration] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = useFullScreenHandle();

  // add 5 seconds from current time
  const onSeekForward = () => {
    if (!videoRef || !videoRef.current) return;
    videoRef.current.currentTime += 5;
  };

  // remove 5 seconds from current time
  const onSeekBackward = () => {
    if (!videoRef || !videoRef.current) return;
    videoRef.current.currentTime -= 5;
  };

  /**
   * Seek to a specific time in the video
   * @param time - time in seconds
   */
  const onSeekTime = (time: number) => {
    if (!videoRef || !videoRef.current) return;
    videoRef.current.currentTime = time;
  };

  // seek to a specific time
  const onSeekbarClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!videoRef || !videoRef.current || !duration) return;

    const progressBar = event.currentTarget;
    const clickPosition =
      event.clientX - progressBar.getBoundingClientRect().left;
    const percentClicked = clickPosition / progressBar.offsetWidth;
    const newTime = percentClicked * duration;
    videoRef.current.currentTime = newTime;
  };

  // toggle fullscreen
  const onFullScreen = () => {
    if (!isFullScreen) {
      handleFullScreen.enter();
    } else {
      handleFullScreen.exit();
    }
    // eslint-disable-next-line
    setMaxBoundingBoxWidth &&
      setMaxBoundingBoxWidth(
        videoRef?.current?.getBoundingClientRect()?.width!,
      );
    setIsFullScreen(!isFullScreen);
  };

  return {
    onSeekTime,
    onSeekForward,
    onSeekBackward,
    onSeekbarClicked,
    onFullScreen,
    handleFullScreen,
    setDuration,
    isFullScreen,
    setIsFullScreen,
    duration,
  };
};
