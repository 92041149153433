import { Box, IconButton, Popover } from '@mui/material';
import { RichTextEditor } from 'components/common/form/RichTextEditor';
import { IconBoldMessages3 } from 'components/icons/components/bold/IconBoldMessages3';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { getUseEditorExtensionsPropsByVariant } from 'features/tiptap';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { hasContent } from 'utils/html';
import { StyledIconButton, tooltipStyle } from './styles';

export type Props = {
  comment: string;
  setComment: (comment: string) => void;
  onCloseCommentPopover: () => void;
  disabled?: boolean;
};

export const CommentButton = (props: Props) => {
  const { comment, setComment, onCloseCommentPopover, disabled } = props;

  const [commentAnchorEl, setCommentAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const onClose = () => {
    setCommentAnchorEl(null);
    onCloseCommentPopover();
  };

  const isSaveButtonDisabled = !hasContent(comment);

  return (
    <>
      <StyledIconButton
        tooltip="Comment"
        onClick={(e) => {
          setCommentAnchorEl(e.currentTarget);
        }}
        tooltipPopperProps={{
          sx: tooltipStyle,
        }}
        placement="top"
        disabled={disabled}
      >
        <IconBoldMessages3 />
      </StyledIconButton>
      <Popover
        open={Boolean(commentAnchorEl)}
        anchorEl={commentAnchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: -16,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disablePortal
        PaperProps={{
          sx: {
            border: 'none',
            boxShadow: 'none',
            pl: theme.spacing(6),
            pr: theme.spacing(4),
            py: theme.spacing(4),
            width: 300,
            borderRadius: 6,
            background: '#FAF3ECCC',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflow: 'hidden',
            '& img': {
              width: 150,
              height: 120,
              objectFit: 'cover !important',
            },
            '& p': {
              margin: theme.spacing(2, 0),
            },
            '& div[name="message"]': {
              padding: 0,
              margin: 0,
            },
          }}
        >
          <RichTextEditor
            defaultContent={comment}
            placeholder="Type your thoughts..."
            hideToolBar
            autoFocus
            borderless
            style={{
              ...theme.typography['subhead-lg'],
              color: theme.colors?.primary.black,
            }}
            containerProps={{
              sx: {
                maxHeight: 130,
                overflowY: 'auto',
              },
            }}
            onChange={({ html }) => setComment(html)}
            editorProps={{
              handleKeyDown(view, event) {
                // NOTE: DO NOT SIMPLY MODIFY THIS FUNCTION
                // This logic is copied from CommentInput.tsx
                // See CommentInput.tsx for more information

                // Submit if user presses enter and shift is not pressed, AND
                // some certain extensions are not active

                // TODO: FIND A BETTER WAY
                const isExtensionActive = Object.keys(view.state).some(
                  (key) => {
                    if (['mention', 'emoji'].some((ext) => key.includes(ext))) {
                      if (view.state[key]?.active) {
                        return true;
                      }
                    }

                    return false;
                  },
                );

                if (
                  event.key === 'Enter' &&
                  !event.shiftKey &&
                  !isExtensionActive
                ) {
                  onClose();

                  return true;
                }

                return false;
              },
            }}
            useEditorExtensionsProps={getUseEditorExtensionsPropsByVariant(
              'inline',
            )}
          />
        </Box>
        <IconButton
          size="small"
          onClick={onClose}
          disabled={isSaveButtonDisabled}
          sx={{ color: theme.colors?.primary.black }}
        >
          <IconBoldTickCircle size={20} />
        </IconButton>
      </Popover>
    </>
  );
};
