import { gql } from '@apollo/client';
import { useGetCollectionViewersQuery } from 'graphql/generated';
import { UserInfo } from 'features/collaboration';
import { useEffect, useMemo } from 'react';
import {
  COLLECTION_FRAGMENT_COLLECTION_VIEWERS,
  CollectionViewers,
} from './CollectionViewers';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
    query GetCollectionViewers($id: String!) {
        collection(id: $id) {
            ...CollectionFragmentCollectionViewers
        }
        ${COLLECTION_FRAGMENT_COLLECTION_VIEWERS}
    }
`;

type CollectionCollaborativeViewersProps = {
  collectionId: string;
  onlineUsers: UserInfo[];
};

export const CollectionCollaborativeViewers = (
  props: CollectionCollaborativeViewersProps,
) => {
  const { collectionId, onlineUsers } = props;

  const { data, loading, refetch } = useGetCollectionViewersQuery({
    variables: {
      id: collectionId,
    },
  });
  const isFirstLoading = loading && !data;

  useEffect(() => {
    // waiting about 3 seconds to make sure our webhooks was called and DB was updated
    setTimeout(() => {
      refetch();
    }, 3000);
  }, [onlineUsers, refetch]);

  const collection = useMemo(() => {
    return (
      data?.collection || {
        id: collectionId,
        viewers: [],
      }
    );
  }, [collectionId, data]);

  return isFirstLoading ? null : (
    <CollectionViewers collection={collection} onlineUsers={onlineUsers} />
  );
};
