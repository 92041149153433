import React from 'react';
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
  Location,
} from 'react-router-dom';
import { NavigateFunction } from 'react-router/lib/hooks';

export function useGuardNavigate(): NavigateFunction {
  const navigate = useNavigate();
  const location = useLocation();
  const { backgroundLocation, secondaryLocation, redirect } =
    (location.state as {
      backgroundLocation?: Location;
      secondaryLocation?: Location;
      redirect?: boolean;
    }) || {};

  return React.useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      if (typeof to === 'number') {
        // If we are going back 1 page, but the backgroundLocation is the root
        // and redirect === true, that means current page (layer 2) is actually opened by a redirect page (layer 1)
        // If we simply go back (-1) -> redirect page -> same page -> redirect page -> same page -> .... it'll be an infinite loop
        // We need to directly go to the homepage
        const isCurrentPageComingFromRedirect = Boolean(redirect);
        const shouldGoBackToHomepage =
          isCurrentPageComingFromRedirect &&
          to === -1 &&
          backgroundLocation?.pathname === '/';

        // NOTE: We'll skip this check if there's a secondaryLocation. Right now, when we navigating users around, we are
        // spreading the state to the next location, which also includes the redirect flag. It's not right because we don't
        // want that flag to be passed around.
        // TODO: Will need to find a better way to handle this.
        const isCurrentPageOn3rdLayer = Boolean(secondaryLocation);

        if (shouldGoBackToHomepage && !isCurrentPageOn3rdLayer) {
          navigate('/');

          return;
        }

        // When the history stack is empty, the location.key will have the value "default"
        if (location.key !== 'default') {
          navigate(to);
        } else {
          navigate('/');
        }
      } else {
        navigate(to, options);
      }
    },
    [location.key, backgroundLocation, secondaryLocation, redirect, navigate],
  );
}
