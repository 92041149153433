import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearDocumentUpload } from 'components/icons/components/linear/IconLinearDocumentUpload';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { usePostHandlers } from 'contexts/commands/hooks/usePostCommands/hooks/usePostHandlers';
import { getRandomNoteColor } from 'features/note';
import {
  PostFragmentPostCommandFragment,
  PostFragmentPostCommandFragmentDoc,
  useUpdateTaskForTaskDetailViewMediaSectionAddPostsButtonMutation,
} from 'graphql/generated';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskDetailViewMediaSectionAddPostsButton($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      id
      posts {
        id
        ...PostFragmentPostCommand
      }
    }
    ${PostFragmentPostCommandFragmentDoc}
  }
`;

type AddPostsButtonProps = {
  taskId: string;
  onPostsAdded?: (posts: PostFragmentPostCommandFragment[]) => void;
};

export const AddPostsButton = (props: AddPostsButtonProps) => {
  const { taskId, onPostsAdded } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const { secondaryLocation, backgroundLocation } =
    (location.state as any) || {};

  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure();
  const anchorElRef = useRef<HTMLButtonElement>(null);

  const { triggerCommand } = useCommandContext();

  const [updateTask] =
    useUpdateTaskForTaskDetailViewMediaSectionAddPostsButtonMutation();

  // Reuse method from post commands
  const { onCreateNotePost } = usePostHandlers();

  return (
    <>
      <IconButton
        ref={anchorElRef}
        sx={{
          width: 40,
          height: 40,
          bgcolor: theme.colors?.primary.black,
          color: theme.colors?.primary.parchment,
          ':hover': {
            bgcolor: 'rgba(35, 6, 3, 0.8)',
          },
        }}
        onClick={openMenu}
      >
        <IconLinearAdd />
      </IconButton>
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 3,
            bgcolor: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
          },
        }}
      >
        {[
          [
            IconOutlineLink,
            'Paste a link',
            () => {
              triggerCommand(COMMAND_TYPE.POST_PASTE_A_LINK, {
                taskId,
                onCompleted: onPostsAdded,
              });
            },
          ],
          [
            IconLinearDocumentUpload,
            'Upload media',
            () => {
              triggerCommand(COMMAND_TYPE.POST_UPLOAD_FILES, {
                taskId,
                onCompleted: onPostsAdded,
              });
            },
          ],
          [
            IconCustomCreativeJuiceBox,
            'Attach from Creative Juicebox',
            () => {
              triggerCommand(COMMAND_TYPE.POST_SELECT_FROM_CJB, {
                onCompleted: (posts) => {
                  onPostsAdded?.(posts);

                  // Attach posts to task manually. The command doesn't handle that for us
                  updateTask({
                    variables: {
                      data: {
                        taskId,
                        data: {
                          postIds: posts.map((post) => post.id),
                        },
                      },
                    },
                  });
                },
              });
            },
          ],
          [
            IconLinearNote2,
            'Create a Note',
            () => {
              onCreateNotePost({
                taskId,
                title: 'Untitled',
                color: getRandomNoteColor().color,
              }).then((post) => {
                if (post) {
                  onPostsAdded?.([post]);

                  navigate(
                    `${PlotRoutes.juicePreview(post.id)}${location.search}`,
                    {
                      state: {
                        ...((location.state as any) || {}),
                        isCreatingNewNote: true,
                        taskId,
                        backgroundLocation: backgroundLocation || location,
                        secondaryLocation:
                          secondaryLocation ||
                          (backgroundLocation ? location : undefined),
                      },
                    },
                  );
                }
              });
            },
          ],
        ].map(([Icon, label, onClick], index) => {
          return (
            <MenuItem
              key={index}
              sx={{
                borderRadius: 3,
                p: 3,
              }}
              onClick={() => {
                // @ts-ignore
                onClick();
                closeMenu();
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon size={16} />
                <Typography variant="headline-xs" fontSize={13}>
                  {label as string}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
