import { gql } from '@apollo/client';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import {
  GeneralPermissionSelector,
  PermissionMemberItem,
  PermissionSelectViewProps,
  useCopyLink,
  usePermissionAddMemberInput,
  usePermissionUserSearch,
} from 'features/permission';
import {
  PostPermissionSuggestedMember,
  SUGGESTED_INVITE_MEMBER_FRAGMENT_POST_PERMISSION_SUGGESTED_MEMBER,
} from 'features/post-permission/components';
import {
  GeneralPermission,
  PermissionLevel,
  PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment,
  SuggestedInviteMemberFragmentPostPermissionSuggestedMemberFragment,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
} from 'graphql/generated';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { useImmer } from 'use-immer';
import { getFullName } from 'utils/users';

export const POST_SUGGESTED_AUTH_FRAGMENT_POST_PERMISSION_SUGGESTED_AUTH_DIALOG_BODY = gql`
  fragment PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBody on PostSuggestedAuthModel {
    generalPermission
    permissionLevel
    suggestedInviteMembers {
      ...SuggestedInviteMemberFragmentPostPermissionSuggestedMember
    }
  }
  ${SUGGESTED_INVITE_MEMBER_FRAGMENT_POST_PERMISSION_SUGGESTED_MEMBER}
`;

export type PostPermissionSuggestedAuthDialogBodyProps = {
  postSuggestedAuth: PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment;
  updateSuggestedAuthDataChanged: (
    data: PostSuggestedAuthFragmentPostPermissionSuggestedAuthDialogBodyFragment,
    note: string,
    shouldCreateComment: boolean,
  ) => void;
  setHasPendingChanges?: (state: boolean) => void;
  updateBtnRef?: React.RefObject<HTMLButtonElement>;
  sx?: SxProps;
  shouldRenderCopyLink?: boolean;
  componentsProps?: {
    header?: {
      sx?: SxProps;
    };
    body?: {
      sx?: SxProps;
    };
    permissionSelectView?: Pick<PermissionSelectViewProps, 'componentProps'>;
  };
};

export const PostPermissionSuggestedDialogBody = (
  props: PostPermissionSuggestedAuthDialogBodyProps,
) => {
  const {
    postSuggestedAuth,
    updateSuggestedAuthDataChanged,
    setHasPendingChanges,
    updateBtnRef,
    sx,
    shouldRenderCopyLink = true,
    componentsProps = {},
  } = props;

  const { user, isWorkOrganization } = useUserContext();

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [inviteMembers, setInviteMembers] = useImmer<
    SuggestedInviteMemberFragmentPostPermissionSuggestedMemberFragment[]
  >(postSuggestedAuth.suggestedInviteMembers);

  const [inputValue, setInputValue] = useState('');
  const [newSelectedUsers, setNewSelectedUsers] = useState<
    UserProfileFragmentUserProfilePermissionItemViewFragment[]
  >([]);
  const [newSelectedUsersPermission, setNewSelectedUsersPermission] =
    useState<PermissionLevel>(PermissionLevel.View);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { suggestedUsers } = usePermissionUserSearch({
    searchStr: inputValue,
    existingMembers: inviteMembers.map((m) => m.user),
  });

  const filterExistingMembers = useMemo(() => {
    return inviteMembers.filter(
      (m) =>
        m.user.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        getFullName(m.user).toLowerCase().includes(inputValue.toLowerCase()),
    );
  }, [inviteMembers, inputValue, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { renderCopyButton, renderCopyLinkToast } = useCopyLink();

  const {
    note,
    shouldCreateComment,
    renderInput,
    toggleUserSelect,
    resetInput,
    renderPermissionSelectView,
  } = usePermissionAddMemberInput({
    entityOrganizationId: user?.organization.id ?? '',
    onSearchStrUpdate: setInputValue,
    onMembersUpdate: setNewSelectedUsers,
    onPermissionUpdate: setNewSelectedUsersPermission,
  });

  const [overallPermission, setOverallPermission] = useImmer<{
    generalPermission: GeneralPermission;
    permission: PermissionLevel;
  }>({
    generalPermission: postSuggestedAuth.generalPermission,
    permission: postSuggestedAuth.permissionLevel,
  });

  const handleInviteMemberPermissionChange = (
    inviteMember: SuggestedInviteMemberFragmentPostPermissionSuggestedMemberFragment,
    remove?: boolean,
  ) => {
    const memberIndex = inviteMembers.findIndex(
      (c) => c.user.id === inviteMember.user.id,
    );

    if (memberIndex === -1) {
      return;
    }

    if (remove) {
      setInviteMembers((draft) => {
        draft.splice(memberIndex, 1);
      });
    } else {
      setInviteMembers((draft) => {
        draft[memberIndex].permissionLevel = inviteMember.permissionLevel;
      });
    }
  };

  const hasExistingMemberDataChanged = useMemo(() => {
    return (
      JSON.stringify(postSuggestedAuth.suggestedInviteMembers) !==
      JSON.stringify(inviteMembers)
    );
  }, [postSuggestedAuth.suggestedInviteMembers, inviteMembers]);

  const hasOverallPermissionDataChanged = useMemo(() => {
    return (
      postSuggestedAuth.generalPermission !==
        overallPermission.generalPermission ||
      postSuggestedAuth.permissionLevel !== overallPermission.permission
    );
  }, [
    postSuggestedAuth.generalPermission,
    postSuggestedAuth.permissionLevel,
    overallPermission,
  ]);

  const isThereNewInvite = useMemo(() => {
    return newSelectedUsers.length > 0;
  }, [newSelectedUsers]);

  const updatePermissions = () => {
    const newMembers = newSelectedUsers.map((u) => ({
      user: u,
      permissionLevel: newSelectedUsersPermission,
    }));

    setInviteMembers((draft) => {
      draft.push(...newMembers);
    });

    updateSuggestedAuthDataChanged(
      {
        generalPermission: overallPermission.generalPermission,
        permissionLevel: overallPermission.permission,
        suggestedInviteMembers: [...inviteMembers, ...newMembers],
      },
      note,
      shouldCreateComment,
    );

    resetInput();
  };

  const isUpdateDisabled =
    !hasExistingMemberDataChanged &&
    !hasOverallPermissionDataChanged &&
    !isThereNewInvite;

  useEffect(() => {
    setHasPendingChanges?.(!isUpdateDisabled);
  }, [isUpdateDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.onfocus = () => {
        setIsInputFocused(true);
      };
      searchInputRef.current.onblur = () => {
        setTimeout(() => {
          setIsInputFocused(false);
        }, 100);
      };
    }
  }, [searchInputRef]);

  const renderMembers = useMemo(() => {
    if (inputValue) {
      return (
        <>
          {filterExistingMembers.length > 0 && user && (
            <Box sx={{ p: theme.spacing(4, 6) }}>
              <Box mb={2}>
                <Typography
                  fontWeight={600}
                  variant="body-lg"
                  color={theme.colors?.utility['800']}
                >
                  In these posts
                </Typography>
              </Box>

              {filterExistingMembers.map((inviteMember) => (
                <PostPermissionSuggestedMember
                  key={inviteMember.user.id}
                  entityOrganizationId={user.organization.id}
                  suggestedInviteMember={inviteMember}
                  onPermissionChange={handleInviteMemberPermissionChange}
                />
              ))}
            </Box>
          )}

          <Box
            sx={{
              p: theme.spacing(4, 6),
              paddingTop: filterExistingMembers.length === 0 ? 4 : 0,
            }}
          >
            {suggestedUsers.length === 0 && inputValue !== '' && (
              <Typography
                fontWeight={600}
                variant="body-lg"
                color={theme.colors?.utility['800']}
              >
                Keep typing a valid email...
              </Typography>
            )}
            {suggestedUsers.length > 0 && (
              <>
                <Box mb={4}>
                  <Typography
                    fontWeight={600}
                    variant="body-lg"
                    color={theme.colors?.utility['800']}
                  >
                    Not in these posts
                  </Typography>
                </Box>
                {suggestedUsers.slice(0, 5).map((suggestedUser) =>
                  suggestedUser ? (
                    <PermissionMemberItem
                      key={suggestedUser.id}
                      entityOrganizationId={user?.organization.id || ''}
                      totalMembers={
                        newSelectedUsers.filter(
                          (u) =>
                            !user?.organization.users
                              .filter(
                                (user) => user.shouldBeCountedTowardPaywall,
                              )
                              .some(
                                (internalUser) => internalUser.id === u.id,
                              ) && u.organization.id === user?.organization.id,
                        ).length
                      }
                      totalGuests={
                        newSelectedUsers.filter((u) => {
                          if (isWorkOrganization) {
                            return (
                              !user?.organization.externalUsers
                                .filter(
                                  (user) => user.shouldBeCountedTowardPaywall,
                                )
                                .some((guest) => guest.id === u.id) &&
                              u.organization.id !== user?.organization.id
                            );
                          }
                          // everyone is a guests for non-work organization
                          return true;
                        }).length
                      }
                      userProfile={suggestedUser}
                      type="suggested"
                      endAdornmentProps={{
                        suggested: {
                          isSelected: !!newSelectedUsers.find(
                            (u) => u.email === suggestedUser.email,
                          ),
                          toggleSelect: toggleUserSelect,
                        },
                      }}
                    />
                  ) : null,
                )}
              </>
            )}
          </Box>
        </>
      );
    }
    if (inviteMembers.length === 0 && isInputFocused) {
      return (
        <Box sx={{ p: theme.spacing(4, 6) }}>
          <Box mb={4}>
            <Typography
              fontWeight={600}
              variant="body-lg"
              color={theme.colors?.utility['800']}
            >
              Suggested
            </Typography>
          </Box>
          {suggestedUsers.slice(0, 6).map((suggestedUser) =>
            suggestedUser ? (
              <PermissionMemberItem
                key={suggestedUser.id}
                entityOrganizationId={user?.organization.id || ''}
                totalMembers={
                  newSelectedUsers.filter(
                    (u) =>
                      !user?.organization.users
                        .filter((user) => user.shouldBeCountedTowardPaywall)
                        .some((internalUser) => internalUser.id === u.id) &&
                      u.organization.id === user?.organization.id,
                  ).length
                }
                totalGuests={
                  newSelectedUsers.filter((u) => {
                    if (isWorkOrganization) {
                      return (
                        !user?.organization.externalUsers
                          .filter((user) => user.shouldBeCountedTowardPaywall)
                          .some((guest) => guest.id === u.id) &&
                        u.organization.id !== user?.organization.id
                      );
                    }
                    // everyone is a guests for non-work organization
                    return true;
                  }).length
                }
                userProfile={suggestedUser}
                type="suggested"
                endAdornmentProps={{
                  suggested: {
                    isSelected: !!newSelectedUsers.find(
                      (u) => u.email === suggestedUser.email,
                    ),
                    toggleSelect: toggleUserSelect,
                  },
                }}
              />
            ) : null,
          )}
        </Box>
      );
    }
    return !user ? null : (
      <Box sx={{ p: theme.spacing(4, 6) }}>
        <PermissionMemberItem
          entityOrganizationId={user.organization.id}
          userProfile={{
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            hasSignedUp: true,
            organization: {
              id: user.organization.id,
            },
          }}
          type="owner"
        />

        {filterExistingMembers.map((inviteMember) => (
          <PostPermissionSuggestedMember
            entityOrganizationId={user.organization.id}
            suggestedInviteMember={inviteMember}
            onPermissionChange={handleInviteMemberPermissionChange}
          />
        ))}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isInputFocused,
    inviteMembers,
    user,
    inputValue,
    inviteMembers.length,
    user,
    filterExistingMembers,
    suggestedUsers,
    newSelectedUsers,
    toggleUserSelect,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          borderRadius: theme.spacing(6, 6, 0, 0),
          display: 'flex',
          flexDirection: 'column',
          backdropFilter: 'blur(20px)',
        }}
      >
        {/* @ts-ignore */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: `rgba(250, 243, 236, 0.85)`,
            gap: 2,
            padding: theme.spacing(4, 6),
            ...(newSelectedUsers.length
              ? { paddingLeft: 4, alignItems: 'flex-start' }
              : {}),
            maxHeight: 110,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 0,
            msOverflowStyle: 'none',
            ...componentsProps.header?.sx,
          }}
        >
          {renderInput({
            sx: { width: '100%' },
            inputRef: searchInputRef,
          })}
          {shouldRenderCopyLink && renderCopyButton()}
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            backgroundColor: 'rgba(250, 243, 236, 0.8)',
            height: 408,
            ...componentsProps.body?.sx,
          }}
        >
          {!inputValue && newSelectedUsers.length
            ? renderPermissionSelectView({
                ...componentsProps.permissionSelectView,
              })
            : renderMembers}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          backdropFilter: 'blur(20px)',
        }}
      >
        <GeneralPermissionSelector
          entityType="post"
          organizationId={user!.organization.id}
          organizationName={user!.organization.name}
          initialGeneralPermission={postSuggestedAuth.generalPermission}
          initialPermissionLevel={postSuggestedAuth.permissionLevel}
          onGeneralPermissionChange={(generalPermission, permissionLevel) => {
            setOverallPermission((draft) => {
              draft.generalPermission = generalPermission;
              draft.permission = permissionLevel ?? PermissionLevel.View;
            });
          }}
          componentProps={{
            sx: {
              bgcolor: 'rgba(250, 243, 236, 0.85)',
              backdropFilter: 'blur(20px)',
              p: theme.spacing(4, 6),
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 0,
            },
          }}
        />

        <Button
          ref={updateBtnRef}
          disabled={isUpdateDisabled}
          variant="primary-alt"
          fullWidth
          onClick={updatePermissions}
          sx={{
            height: 42,
            borderRadius: theme.spacing(0, 0, 6, 6),
          }}
        >
          Update
        </Button>
      </Box>
      {renderCopyLinkToast()}
    </Box>
  );
};
