import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import {
  BillingPlanName,
  OrganizationBillingFragmentFileDeliveryFragment,
  OrganizationType,
} from 'graphql/generated';
import { ResourceData } from 'hooks/useResourceUploadQueue';
import { useEffect, useMemo } from 'react';
import { theme } from 'styles/theme';
import { Updater } from 'use-immer';
import { Parser } from 'utils/parser';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { makeStyles } from '@mui/styles';
import { useBillingPlanData } from '../../hooks';
import { BillingUpgradeModal } from '../upgradeModal';
import { FilePreview } from './FilePreview';

const useStyles = makeStyles(() => ({
  paper: { minWidth: '490px' },
}));

// eslint-disable-next-line
gql`
  fragment OrganizationBillingFragmentFileDelivery on OrganizationBillingModel {
    id
    fileDeliveryLimitInMb
    hasUsedFreeTrial
  }
`;

type BillingFileDeliveryPaywallProps = {
  organizationBilling: OrganizationBillingFragmentFileDeliveryFragment;
  files: ResourceData[];
  onUpdateFiles: Updater<ResourceData[]>;
  onReset: VoidFunction;
  onCancel: VoidFunction;
  onContinue: VoidFunction;
};

export const BillingFileDeliveryPaywall = (
  props: BillingFileDeliveryPaywallProps,
) => {
  const {
    organizationBilling,
    files,
    onUpdateFiles,
    onReset,
    onCancel,
    onContinue,
  } = props;

  const { user, isEnterpriseOrganization } = useUserContext();
  const { numberOfTrialDays } = useBillingPlanData(
    isEnterpriseOrganization
      ? BillingPlanName.Enterprise
      : BillingPlanName.Standard,
  );

  const isLimitReached = useMemo(() => {
    if (organizationBilling.fileDeliveryLimitInMb) {
      return files.some(
        (file) =>
          Parser.toMb(file.size ?? 0) >=
          organizationBilling.fileDeliveryLimitInMb!,
      );
    }

    return false;
  }, [files, organizationBilling.fileDeliveryLimitInMb]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: upgradeOpen,
    onOpen: upgradeOnOpen,
    onClose: upgradeOnClose,
  } = useDisclosure();

  // whether you open the paywall modal
  useEffect(() => {
    if (files.length > 0) {
      if (!isLimitReached) {
        onClose();
        onContinue();
      } else {
        onOpen();
      }
    }
  }, [isLimitReached, files]); // eslint-disable-line

  const onRemoveFile = (file: ResourceData) => {
    if (files.length === 1) {
      onCancel();
      onClose();
    } else if (files.length > 1) {
      onUpdateFiles((draft) => {
        return draft.filter((f) => f.name !== file.name);
      });
    }
  };

  const renderPostList = () => {
    if (files.length === 1) {
      return (
        <FilePreview
          file={files[0]}
          fileDeliveryLimitInMb={organizationBilling.fileDeliveryLimitInMb}
          onRemove={onRemoveFile}
          variant="standalone"
          sx={{
            maxWidth: 185,
          }}
        />
      );
    }

    return (
      <Grid container spacing={4}>
        {files.map((file, index) => (
          <Grid key={index} item xs={files.length > 2 ? 4 : 6}>
            <FilePreview
              file={file}
              fileDeliveryLimitInMb={organizationBilling.fileDeliveryLimitInMb}
              onRemove={onRemoveFile}
              variant="compact"
              sx={{
                border: `${theme.spacing(1.5)} solid ${
                  theme.colors?.primary.white
                }`,
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleRemoveLargeFile = () => {
    if (files.length === 1) {
      onClose();
      onCancel();
    } else {
      onUpdateFiles((draft) => {
        return draft.filter((f) => {
          if (f.size) {
            return (
              Parser.toMb(f.size ?? 0) <
              organizationBilling.fileDeliveryLimitInMb!
            );
          }
          return true;
        });
      });
    }
  };

  const classes = useStyles();
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          onReset();
          onClose();
        }}
        classes={{ paper: classes.paper }}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            display: 'flex',
            position: 'relative',
            width: '100%',
            maxWidth: 490,
            minWidth: 400,
            transition: 'all 0.2s',
            backgroundColor: theme.colors?.utility['purple-1'],
            borderRadius: theme.spacing(8),
            minHeight: 510,
            justifyContent: 'center',
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(6),
            position: 'relative',
            padding: theme.spacing(3, 3, 6, 3),
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflow: files.length > 1 ? 'auto' : 'overflow',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 450,
              bgcolor: 'rgba(35, 6, 3, 0.07)',
              justifyContent: 'center',
              borderRadius: theme.spacing(8),
              pt: files.length > 1 ? theme.spacing(4) : theme.spacing(10),
              pb: files.length > 1 ? theme.spacing(4) : theme.spacing(16),
              gap: files.length > 1 ? theme.spacing(8) : theme.spacing(20),
              px: files.length > 1 ? theme.spacing(3) : theme.spacing(5),
              maxHeight: 480,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                bgcolor: 'rgba(35, 6, 3, 0.3)',
                borderRadius: 100,
                px: theme.spacing(2),
                py: theme.spacing(1),
                gap: theme.spacing(1.5),
              }}
            >
              <IconBoldBuildings
                size={12}
                color={theme.colors?.primary.white}
              />
              <Typography
                variant="headline-xxs"
                color={theme.colors?.primary.parchment}
              >
                {user?.organization.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: files.length > 1 ? 'auto' : 'overflow',
                boxShadow: `0px 18px 88px -4px #18274B24`,
                alignSelf: 'center',
              }}
            >
              {renderPostList()}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: theme.spacing(6),
              alignSelf: 'center',
            }}
          >
            <Typography
              variant="headline-lg"
              fontWeight={600}
              color={theme.colors?.utility['purple-4']}
              textAlign="center"
            >
              This content must be good.
              <Typography fontWeight={400}>
                Try our Teams plan to upload files over{' '}
                {organizationBilling.fileDeliveryLimitInMb}MB.
              </Typography>
            </Typography>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: theme.spacing(3),
              }}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={upgradeOnOpen}
                sx={{
                  bgcolor: theme.colors?.primary.black,
                  color: theme.colors?.primary.white,
                  height: theme.spacing(11),
                  borderRadius: theme.spacing(2),
                  '&:hover': {
                    bgcolor: theme.colors?.primary.black,
                    opacity: 0.8,
                  },
                }}
              >
                {organizationBilling
                  ? 'Upgrade'
                  : `${numberOfTrialDays}-day Free Trial`}
              </Button>

              <Typography variant="subhead-xl" onClick={handleRemoveLargeFile}>
                or{' '}
                <Typography
                  component="span"
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {files.length > 1
                    ? 'Remove large file'
                    : 'Upload another file'}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>

      <BillingUpgradeModal
        isOpen={upgradeOpen}
        onClose={upgradeOnClose}
        onRequestParentModalClose={onClose}
      />
    </>
  );
};
