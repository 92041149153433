type HighlightedTextRendererProps = {
  text: string;
  match: string;
};

export const HighlightedTextRenderer = ({
  text,
  match,
}: HighlightedTextRendererProps) => {
  const renderHighlightedText = () => {
    if (!text || !match) {
      return { __html: text };
    }

    const regex = new RegExp(match, 'gi');
    const highlightedText = text.replace(regex, (match) => `<b>${match}</b>`);

    return { __html: highlightedText };
  };

  return <span dangerouslySetInnerHTML={renderHighlightedText()} />;
};
