import { Box, Divider, IconButton } from '@mui/material';
import { IconOutlineEye } from 'components/icons/components/outline/IconOutlineEye';
import {
  TrendInfo,
  TrendLinkedPosts,
  TrendingFilterPill,
} from 'features/trending';
import { TrendFragmentTrendingDetailViewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

type Props = {
  trend: TrendFragmentTrendingDetailViewFragment;
  onHideInfo: VoidFunction;
};

export const TrendingSidebarView = ({ trend, onHideInfo }: Props) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          sx={{
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...theme.typography['subhead-lg'],
            color: theme.colors?.utility[800],
            gap: 1,
          }}
          onClick={onHideInfo}
          disableRipple
        >
          <IconOutlineEye size={16} color={theme.colors?.utility[800]} />
          Hide Info
        </IconButton>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          color: theme.colors?.primary.black,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <TrendingFilterPill
            filter={trend.category}
            sx={{ gap: 3, width: 'fit-content' }}
          />
          <TrendInfo trendInfo={trend} />

          <Divider sx={{ borderColor: theme.colors?.utility[400] }} />

          <TrendLinkedPosts linkedPosts={trend.linkedPosts} />
        </Box>
      </Box>
    </>
  );
};
