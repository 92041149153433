import { gql } from '@apollo/client';
import { toast } from 'components/common/Toast';
import { useUserContext } from 'contexts/users/User.context';
import {
  InternalOrganizationRole,
  useInviteUsersToCurrentOrgByRolesForUseOrgMemberManagementMutation,
  useInviteUsersToCurrentOrgForUseOrgMemberManagementMutation,
} from 'graphql/generated';
import { useMemo } from 'react';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment UserFragmentUseOrgMemberManagement on UserProfileModel {
    id
    firstName
    lastName
    email
    avatarUrl
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation InviteUsersToCurrentOrgForUseOrgMemberManagement(
    $data: InviteUsersToCurrentOrgInput!
  ) {
    inviteUsersToCurrentOrg(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation InviteUsersToCurrentOrgByRolesForUseOrgMemberManagement(
    $data: InviteUsersToCurrentOrgByRoleInput!
  ) {
    inviteUsersToCurrentOrgByRoles(data: $data) {
      id
    }
  }
`;

export const useOrgMemberManagement = () => {
  const { user, orgBilling } = useUserContext();

  const isSocialListeningEnabled = useMemo(() => {
    return orgBilling?.socialListeningEnabled;
  }, [orgBilling]);

  const [inviteUsersToCurrentOrg] =
    useInviteUsersToCurrentOrgForUseOrgMemberManagementMutation();
  const [inviteUsersToOrgByRoles] =
    useInviteUsersToCurrentOrgByRolesForUseOrgMemberManagementMutation();

  const inviteOrgUsers = async (
    emails: string[],
    role: InternalOrganizationRole,
  ) => {
    inviteUsersToCurrentOrg({
      variables: {
        data: {
          emails,
          role,
        },
      },
      refetchQueries: ['GetMe'],
      update: (cache) => {
        // only update the organization member cache when admins invite
        if (user?.role === InternalOrganizationRole.Admin && orgBilling) {
          modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
            organizationMemberUsage: (existingFieldValues) =>
              existingFieldValues + emails.length,
          });
        }
      },
    }).then(() => {
      if (
        user?.role !== InternalOrganizationRole.Admin &&
        user?.organization.settings.restrictedAccessForUser
      ) {
        toast({
          message: 'Invitation sent to admin for approval',
          type: 'success',
        });
      }
    });
  };

  const inviteMembersForNonSLOrg = async (
    emails: string[],
    role: InternalOrganizationRole,
  ) => {
    if (isSocialListeningEnabled) {
      throw new Error(
        'Not accessible since social listening for orgs with social listening enabled',
      );
    }
    return inviteOrgUsers(emails, role);
  };

  const inviteMembersForSLOrg = async (
    data: { email: string; role: InternalOrganizationRole }[],
  ) => {
    if (!isSocialListeningEnabled) {
      throw new Error(
        'Not accessible for organizations without social listening enabled',
      );
    }

    await inviteUsersToOrgByRoles({
      variables: {
        data: {
          data,
        },
      },
      refetchQueries: ['GetMe'],
      update: (cache) => {
        // only update the organization member cache when admins invite
        if (user?.role === InternalOrganizationRole.Admin && orgBilling) {
          modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
            organizationMemberUsage: (existingFieldValues) =>
              existingFieldValues + data.length,
          });
        }
      },
    }).then(() => {
      if (
        user?.role !== InternalOrganizationRole.Admin &&
        user?.organization.settings.restrictedAccessForUser
      ) {
        toast({
          message: 'Invitation sent to admin for approval',
          type: 'success',
        });
      }
    });
  };

  return {
    inviteMembersForNonSLOrg,
    inviteMembersForSLOrg,
  };
};
