import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { IconBoldMainComponent } from 'components/icons/components/bold/IconBoldMainComponent';
import {
  ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelectFragmentDoc,
  ContentIdeaFragmentContentIdeaPillarsFragment,
  ContentIdeaPermission,
  FieldType,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { ContentIdeaField } from '../field';
import { MultiSelectFieldProps } from '../field/MultiSelectField';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_PILLARS = gql`
  fragment ContentIdeaFragmentContentIdeaPillars on ContentIdeaModel {
    id
    myPermissions
    pillars {
      id
      contentIdeaField {
        id
      }
      ...ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelect
    }
  }
  ${ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelectFragmentDoc}
`;

export type ContentIdeaPillarsProps = {
  contentIdea: ContentIdeaFragmentContentIdeaPillarsFragment;
  renderTrigger?: MultiSelectFieldProps['renderTrigger'];
};

export const ContentIdeaPillars = (props: ContentIdeaPillarsProps) => {
  const { contentIdea, renderTrigger } = props;
  const pillars = contentIdea.pillars?.value?.multiSelect || [];

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  return (
    <ContentIdeaField
      id={contentIdea.pillars.contentIdeaField.id}
      valueId={contentIdea.pillars.id}
      readOnly={!canEdit}
      value={{
        multiSelect: pillars.map((pillar) => pillar.value),
      }}
      type={FieldType.MultiSelect}
      renderTrigger={
        renderTrigger ||
        (({ isOpen, options }) => {
          if (pillars.length === 0) {
            return (
              <IconBoldMainComponent
                size={16}
                color={
                  isOpen
                    ? theme.colors?.primary.black
                    : theme.colors?.utility[500]
                }
              />
            );
          }

          const firstPillar = options.find((o) => o.value === pillars[0].value);

          return (
            <Box
              sx={{
                py: 1,
                px: 2,
                borderRadius: 1,
                bgcolor: '#23060312',
                color: theme.colors?.utility[700],
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* @ts-ignore -- firstPillar should not be undefined */}
              <Typography
                variant="subhead-xs"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {firstPillar?.label}
              </Typography>
              {pillars.length > 1 && (
                <Typography variant="subhead-xs">
                  +{pillars.length - 1}
                </Typography>
              )}
            </Box>
          );
        })
      }
    />
  );
};
