import {
  SearchableEntityType,
  UniversalSearchFragmentSearchHandlerFragment,
} from 'graphql/generated';
import React, { useEffect, useState } from 'react';
import {
  SearchListItemViewSearchHit,
  SearchSectionView,
  SearchSectionViewSearchResult,
} from 'features/search';
import { TopSearchResults } from './TopSearchResults';

type Props = {
  searchString: string;
  searchData: UniversalSearchFragmentSearchHandlerFragment;
  elRefs: React.MutableRefObject<(HTMLElement | null)[]>;
  onShowMore: (entityType: SearchableEntityType) => void;
};

export const SearchResults = (props: Props) => {
  const { searchData, elRefs, searchString, onShowMore } = props;

  const [topResults, setTopResults] = useState<SearchListItemViewSearchHit[]>(
    [],
  );

  useEffect(() => {
    const allSearchHits: SearchListItemViewSearchHit[] = [
      ...(searchData.attachmentSearchResult.hits || []),
      ...(searchData.noteSearchResult.hits || []),
      ...(searchData.linkSearchResult.hits || []),
      ...(searchData.taskSearchResult.hits || []),
      ...(searchData.collectionSearchResult.hits || []),
    ];
    allSearchHits.sort((a, b) => b.score - a.score);
    setTopResults(allSearchHits.slice(0, 3));
  }, [searchData]);

  const filterSearchHits = (
    searchResult: SearchSectionViewSearchResult,
  ): SearchSectionViewSearchResult => {
    const itemIdsToBeFiltered = topResults.map((top) => top.item.id);

    try {
      // @ts-ignore - Typescript cannot find filter() for union object
      const filteredSearchHit = searchResult.hits.filter(
        (hit) => !itemIdsToBeFiltered.includes(hit.item.id),
      );

      return {
        __typename: searchResult.__typename,
        totalCount: searchResult.totalCount,
        // @ts-ignore -- Not sure why this fails?
        hits: filteredSearchHit,
      };
    } catch (e) {
      console.error(e);
      return searchResult;
    }
  };

  useEffect(() => {
    elRefs.current = [];
  }, [searchData]); // eslint-disable-line react-hooks/exhaustive-deps

  const noteOffset = topResults.length;
  const linkOffset =
    noteOffset + (searchData.noteSearchResult.hits.length || 0);
  const attachmentOffset =
    linkOffset + (searchData.linkSearchResult.hits.length || 0);
  const taskOffset =
    attachmentOffset + (searchData.attachmentSearchResult.hits.length || 0);
  const collectionOffset =
    taskOffset + (searchData.taskSearchResult.hits.length || 0);

  return (
    <>
      <TopSearchResults
        searchString={searchString}
        searchHits={topResults}
        elRefs={elRefs}
      />

      {searchData.noteSearchResult.hits &&
        searchData.noteSearchResult.hits.length > 0 && (
          <SearchSectionView
            elRefs={elRefs}
            type={SearchableEntityType.Notes}
            searchResult={filterSearchHits(searchData.noteSearchResult)}
            searchString={searchString}
            initialIndex={noteOffset}
            onHeaderClick={() => onShowMore(SearchableEntityType.Notes)}
          />
        )}

      {searchData.linkSearchResult.hits &&
        searchData.linkSearchResult.hits.length > 0 && (
          <SearchSectionView
            elRefs={elRefs}
            type={SearchableEntityType.Links}
            searchResult={filterSearchHits(searchData.linkSearchResult)}
            searchString={searchString}
            initialIndex={linkOffset}
            onHeaderClick={() => onShowMore(SearchableEntityType.Links)}
          />
        )}

      {searchData.attachmentSearchResult.hits &&
        searchData.attachmentSearchResult.hits.length > 0 && (
          <SearchSectionView
            elRefs={elRefs}
            type={SearchableEntityType.Attachments}
            searchResult={filterSearchHits(searchData.attachmentSearchResult)}
            searchString={searchString}
            initialIndex={attachmentOffset}
            onHeaderClick={() => onShowMore(SearchableEntityType.Attachments)}
          />
        )}

      {searchData.taskSearchResult.hits &&
        searchData.taskSearchResult.hits.length > 0 && (
          <SearchSectionView
            elRefs={elRefs}
            type={SearchableEntityType.Tasks}
            searchResult={filterSearchHits(searchData.taskSearchResult)}
            searchString={searchString}
            initialIndex={taskOffset}
            onHeaderClick={() => onShowMore(SearchableEntityType.Tasks)}
          />
        )}

      {searchData.collectionSearchResult.hits &&
        searchData.collectionSearchResult.hits.length > 0 && (
          <SearchSectionView
            elRefs={elRefs}
            type={SearchableEntityType.Collections}
            searchResult={filterSearchHits(searchData.collectionSearchResult)}
            searchString={searchString}
            initialIndex={collectionOffset}
            onHeaderClick={() => onShowMore(SearchableEntityType.Collections)}
          />
        )}
    </>
  );
};
