import { useMemo } from 'react';

export const useCheckForUnsavedChanges = (
  initialFilters: Record<string, object>,
  selectedFilters: Record<string, object>,
) => {
  const hasUnsavedChanges = useMemo(() => {
    // If there are no selected filters, there are no unsaved changes
    // The structure of this object can be found in `ContentCalendarSortAndFilterButton` line 315
    if (
      Object.keys(selectedFilters?.selectedFilters).some(
        (key) =>
          // @ts-ignore
          selectedFilters?.selectedFilters[key]?.selected?.length > 0 ||
          // @ts-ignore
          selectedFilters?.selectedFilters[key]?.label,
      ) ||
      selectedFilters?.selectedSort?.['id']
    ) {
      return JSON.stringify(selectedFilters) !== JSON.stringify(initialFilters);
    }

    return false;
  }, [JSON.stringify({ selectedFilters, initialFilters })]); // eslint-disable-line react-hooks/exhaustive-deps

  return { hasUnsavedChanges };
};
