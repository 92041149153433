import { Box, Skeleton } from '@mui/material';

export const CollectionListItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        gap: 2,
      }}
    >
      <Skeleton width={36} height={36} sx={{ transform: 'scale(1)' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Skeleton width={120} height={14} sx={{ transform: 'scale(1)' }} />
        <Skeleton width={80} height={12} sx={{ transform: 'scale(1)' }} />
      </Box>
    </Box>
  );
};
