import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { isCuid as isCuid2 } from '@paralleldrive/cuid2';

export const validateUrl = (url: string) => {
  // We accept link with or without protocol
  const isValidLink = Boolean(
    url.trim() &&
      url.match(/(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?/gi),
  );

  // If without protocol, we add https
  if (isValidLink) {
    if (!url.startsWith('http')) {
      return `https://${url}`;
    }

    return url;
  }

  return '';
};

interface PortalProps {
  containerId: string;
  children: React.ReactNode;
}

export const CreatePortalById: React.FC<PortalProps> = ({
  containerId,
  children,
  // TODO: remove any once we find the proper solution
}): any => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement>();

  // Sometimes can not get container div because it render after the portal
  // So the portal can not find the element
  // => we have useLayoutEffect to get the container => to make sure the container already rendered
  useLayoutEffect(() => {
    const container = document.getElementById(containerId);

    if (container) {
      setPortalContainer(container);
    }
  }, [containerId]);

  if (!portalContainer) {
    // if no portal container found, return children directly
    return children || null;
  }

  return ReactDOM.createPortal(children, portalContainer);
};

export const isCuid = (str: string): boolean => {
  // Cuid pattern: a block of 25 alphanumeric characters, hyphens, and a block of 4 alphanumeric characters
  const cuidPattern = /^[a-z0-9]{25}(-[a-z0-9]{4})?$/;

  // UUIDV4 -- Check this too as it's an old pattern that's still used for old data
  const uuidv4Pattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

  return cuidPattern.test(str) || uuidv4Pattern.test(str) || isCuid2(str);
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
