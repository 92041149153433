import { Box, BoxProps } from '@mui/material';
import { IconCustomNounMoveButton } from 'components/icons/components/custom/IconCustomNounMoveButton';
import { DndDragItemTypes } from 'features/dnd/constants';
import { useDrag } from 'react-dnd';
import { theme } from 'styles/theme';

export type DndSortableDragItemProps<T> = BoxProps & {
  type: DndDragItemTypes;
  item: T & { id: string };
};

export const DndSortableDragItem = <T,>(props: DndSortableDragItemProps<T>) => {
  const { type, item, children, sx, ...rest } = props;

  const [collected, drag, preview] = useDrag(() => ({
    type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={preview}
      data-item-id={item?.id}
      sx={{
        position: 'relative',
        '.drag-handle': {
          opacity: 0,
        },
        '&:hover': {
          '.drag-handle': {
            opacity: 1,
          },
        },
        ...sx,
      }}
      {...rest}
    >
      <Box
        ref={drag}
        className="drag-handle"
        sx={{
          display: 'inline-flex',
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translate(-24px, -50%)',
          color: theme.colors?.utility[500],
          cursor: collected.isDragging ? 'move' : 'pointer',
        }}
      >
        <IconCustomNounMoveButton size={24} />
      </Box>
      {children}
    </Box>
  );
};
