import { Box, Button, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { theme } from 'styles/theme';

type Props = {
  customTitle?: string;
  emails: string[];
  onConfirm: () => void;
  onCancel: () => void;
};

export const MemberInviteConfirmation = ({
  customTitle,
  emails,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <Box
      sx={{
        p: theme.spacing(6),
        backgroundColor: theme.colors?.primary.white,
        width: theme.spacing(85),
        minHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        boxShadow:
          '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);',
      }}
    >
      <Typography variant="headline-lg">
        {!customTitle
          ? 'Are you sure you want to add these members?'
          : customTitle}
      </Typography>

      <Typography variant="body-xl">
        They will be all additional paid users. Admins will be sent an invoice
        once you click "Continue" to add the member.
      </Typography>

      <Box display="flex" flexDirection="column" gap={2} flexWrap="wrap" mt={2}>
        {emails.map((email) => (
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            sx={{
              backgroundColor: 'rgba(35, 6, 3, 0.10)',
              borderRadius: theme.spacing(25),
              p: theme.spacing(1, 2),
              width: 'fit-content',
            }}
          >
            <Avatar
              user={{
                id: '',
                email,
                firstName: '',
                lastName: '',
              }}
              size={16}
            />
            <Typography variant="body-xl" fontWeight={600}>
              {email}
            </Typography>
          </Box>
        ))}
      </Box>

      <Button
        variant="contained"
        onClick={onConfirm}
        sx={{
          border: 'none',
          mt: 6,
          p: 3,
          borderRadius: theme.spacing(2),
          backgroundColor: theme.colors?.primary.black,
          fontWeight: 600,
          color: theme.colors?.primary.white,
          '&:hover': {
            backgroundColor: theme.colors?.primary.black,
            fontWeight: 600,
            color: theme.colors?.primary.white,
          },
        }}
      >
        Yes, continue.
      </Button>

      <Button
        variant="text"
        onClick={onCancel}
        sx={{
          border: 'none',
          p: 3,
        }}
      >
        Cancel
      </Button>
    </Box>
  );
};
