import { gql } from '@apollo/client';
import { POST_FRAGMENT_NOTE_COLOR } from 'features/note/components/color';
import { POST_FRAGMENT_NOTE_TITLE } from 'features/note/components/title';

export const POST_FRAGMENT_COLLABORATIVE_NOTE_VIEW = gql`
  fragment PostFragmentCollaborativeNoteView on PostModel {
    id
    roomId
    ...PostFragmentNoteTitle
    ...PostFragmentNoteColor
    body
    myPermissions
  }
  ${POST_FRAGMENT_NOTE_TITLE}
  ${POST_FRAGMENT_NOTE_COLOR}
`;
