import { gql } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@mui/material';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { PlotRoutes } from 'Routes';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import { IconBoldExport } from 'components/icons/components/bold/IconBoldExport';
import { IconBoldFlag } from 'components/icons/components/bold/IconBoldFlag';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import { NOTE_COLORS } from 'features/note/constants/noteColors';
import {
  TaskAssignee,
  TaskDueDateRange,
  TaskPriority,
  TaskStatus,
} from 'features/task';
import {
  TaskFragmentTaskAssigneeFragmentDoc,
  TaskFragmentTaskDueDateRangeFragmentDoc,
  TaskFragmentTaskItemExtensionFragment,
  TaskFragmentTaskPriorityComponentFragmentDoc,
  TaskFragmentTaskStatusFragmentDoc,
  TaskPriority as TaskPriorityType,
  TaskStatus as TaskStatusType,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { extractTextFromHtml } from 'utils/html';
import {
  TaskItemExtensionExternalAttrs,
  TaskNodeAttributes,
} from '../../types';
import { StyledActionButton } from './styles';
import { useTaskQueryWithPolling } from './useTaskQueryWithPolling';

export const TASK_FRAGMENT_TASK_ITEM_EXTENSION = gql`
  fragment TaskFragmentTaskItemExtension on TaskModel {
    id
    name
    ...TaskFragmentTaskStatus
    ...TaskFragmentTaskAssignee
    ...TaskFragmentTaskDueDateRange
    ...TaskFragmentTaskPriorityComponent
  }
  ${TaskFragmentTaskStatusFragmentDoc}
  ${TaskFragmentTaskAssigneeFragmentDoc}
  ${TaskFragmentTaskDueDateRangeFragmentDoc}
  ${TaskFragmentTaskPriorityComponentFragmentDoc}
`;

export const TaskNodeView = ({
  node,
  extension,
  editor,
  getPos,
  updateAttributes,
}: NodeViewProps) => {
  const location = useLocation();
  const locationState =
    (location.state as {
      backgroundLocation?: Location;
      secondaryLocation?: Location;
    }) || {};

  const { user, isMobileAppWebView } = useUserContext();
  const users = [
    ...(user?.organization.users || []),
    ...(user?.organization.externalUsers || []),
  ];

  const mapTaskDataFromNodeAttrs = (nodeAttrs: Record<string, any>) => {
    const id = nodeAttrs[TaskNodeAttributes.id];
    const status = nodeAttrs[TaskNodeAttributes.status] ?? TaskStatusType.ToDo;
    const startDate = nodeAttrs[TaskNodeAttributes.startDate];
    const endDate = nodeAttrs[TaskNodeAttributes.endDate];
    const priority = nodeAttrs[TaskNodeAttributes.priority] ?? null;

    const memberIds = nodeAttrs[TaskNodeAttributes.ownerId]?.split(',') || [];
    const taskMembers = users.filter((i) => memberIds.includes(i.id)) || [];

    const name = node.textContent || '';

    return {
      id,
      status,
      startDate,
      endDate,
      priority,
      taskMembers,
      name,
    };
  };

  // If node.attrs.id is not available, this is a new task item node
  const isNewTask = useRef(!node.attrs[TaskNodeAttributes.id]);

  // Internal task item object used for managing the state of the task node
  // based on node.attrs and node.textContent
  const [internalTask, setInternalTask] =
    useState<TaskFragmentTaskItemExtensionFragment>(
      mapTaskDataFromNodeAttrs(node.attrs),
    );

  // On the other hand, also fetch this task from the server
  const { task: remoteTask, loading: isRemoteTaskLoading } =
    useTaskQueryWithPolling({
      taskId: internalTask.id,
      skip: !internalTask.id || !internalTask.name,
      delayBeforeFirstFetch: isNewTask.current ? 3000 : 0,
    });

  const { themeColor = NOTE_COLORS[0] } = (node.attrs.externalAttrs ||
    extension.options.externalAttrs ||
    {}) as TaskItemExtensionExternalAttrs;

  // If task item node have no id or have duplicated id => gen an new id then update id attribute
  useEffect(() => {
    const taskId = node.attrs[TaskNodeAttributes.id];
    const allTaskNodes: NodeListOf<Element> =
      document.querySelectorAll('.task-item-node');
    const taskIds: string[] = Array.from(allTaskNodes).map(
      (item) => item?.attributes?.[TaskNodeAttributes.id]?.value,
    );
    const isDuplicateId = taskIds?.filter((id) => id === taskId)?.length > 1;

    if (!taskId || isDuplicateId) {
      const newTaskId = GenerateId.create();
      setInternalTask((pre) => ({
        ...pre,
        id: newTaskId,
      }));
      updateAttributes({
        [TaskNodeAttributes.id]: newTaskId,
      });
    }
  }, [node.attrs[TaskNodeAttributes.id], updateAttributes]); // eslint-disable-line

  // Update internal task item object when task item node attrs change
  useEffect(() => {
    setInternalTask(mapTaskDataFromNodeAttrs(node.attrs));
  }, [node.attrs]); // eslint-disable-line

  // Update internalTask.name when node.textContent change (when users make edit to the node)
  const nameText = node.textContent || '';
  useEffect(() => {
    setInternalTask((pre) => ({
      ...pre,
      name: nameText,
    }));
  }, [nameText]);

  // Update node attrs and content when remoteTask changes
  // and there are differences between internalTask and remoteTask
  // Some scenarios:
  // 1. Users update the task in the task list sidebar
  // 2. Users update the task in task detail modal (elsewhere when we are not rendering the note at all),
  //    then open up the note. The task nodes in the note should be updated.
  // 3. ...
  useEffect(() => {
    // Use an interval to run syncing logic,
    // with inner try-catch to prevent `updateAttributes` from throwing error (e.g. when editor is not ready yet)
    const interval = setInterval(() => {
      try {
        if (remoteTask && remoteTask.id === internalTask.id) {
          if (internalTask.status !== remoteTask.status) {
            updateAttributes({
              [TaskNodeAttributes.status]: remoteTask.status,
            });
          }

          if (internalTask.startDate !== remoteTask.startDate) {
            updateAttributes({
              [TaskNodeAttributes.startDate]: remoteTask.startDate,
            });
          }

          if (internalTask.endDate !== remoteTask.endDate) {
            updateAttributes({
              [TaskNodeAttributes.endDate]: remoteTask.endDate,
            });
          }

          if (internalTask.priority !== remoteTask.priority) {
            updateAttributes({
              [TaskNodeAttributes.priority]: remoteTask.priority,
            });
          }

          if (
            internalTask.taskMembers.length !== remoteTask.taskMembers.length ||
            internalTask.taskMembers.some(
              (member) =>
                !remoteTask.taskMembers.some(
                  (remoteMember) => remoteMember.id === member.id,
                ),
            )
          ) {
            updateAttributes({
              [TaskNodeAttributes.ownerId]: remoteTask.taskMembers
                .map((member) => member.id)
                .filter(Boolean)
                .join(','),
            });
          }

          if (internalTask.priority !== remoteTask.priority) {
            updateAttributes({
              [TaskNodeAttributes.priority]: remoteTask.priority,
            });
          }

          if (
            // FIXME: This condition is VERY FRAGILE. We have very little control over this right now.
            // See `TaskName` for more details.
            internalTask.name !== extractTextFromHtml(remoteTask.name)
          ) {
            const taskElement = editor.view.nodeDOM(getPos()) as HTMLElement;
            if (taskElement) {
              const taskElementContent = taskElement.querySelector('p');

              if (taskElementContent) {
                taskElementContent.innerHTML = remoteTask.name;
              }
            }
          }
        }

        clearInterval(interval);
      } catch (error) {
        console.log(error);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, [remoteTask]); // eslint-disable-line -- only update when remoteTask changes

  const selected =
    editor.state.selection.from >= getPos() &&
    editor.state.selection.to <= getPos() + node.content.size;

  return (
    <NodeViewWrapper task-id={internalTask.id}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 2,
          px: 1.5,
          width: '100%',
          position: 'relative',
          button: {
            color: 'inherit',
          },
        }}
      >
        <Box pt={2.25}>
          <TaskStatus
            task={internalTask}
            iconFillColor={themeColor.textPlaceholderColor}
            onChange={(value) => {
              setInternalTask((pre) => ({
                ...pre,
                status: value,
              }));
              updateAttributes({
                [TaskNodeAttributes.status]: value,
              });
            }}
            componentsProps={{
              popover: {
                disableRestoreFocus: true,
              },
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <NodeViewContent
            key="NodeViewContent-key"
            className="node-task-view-content"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            alignSelf: 'flex-start',
            justifyContent: 'flex-end',
            mt: 2.25,
            flexShrink: 0,
            button: {
              display: 'flex',
            },
            // Only show these buttons under the right conditions
            '> *:first-child':
              selected || (internalTask.startDate && internalTask.endDate)
                ? {}
                : { opacity: 0, pointerEvents: 'none' },
            '> *:nth-child(2)':
              selected || internalTask.priority
                ? {}
                : { opacity: 0, pointerEvents: 'none' },
            '> *:nth-child(3)':
              selected || internalTask.taskMembers[0]
                ? {}
                : { opacity: 0, pointerEvents: 'none' },
          }}
        >
          <TaskDueDateRange
            task={internalTask}
            onChange={(startDate, endDate) => {
              updateAttributes({
                [TaskNodeAttributes.startDate]: startDate
                  ? moment(startDate).toISOString()
                  : '',
                [TaskNodeAttributes.endDate]: endDate
                  ? moment(endDate).toISOString()
                  : startDate
                  ? moment(startDate).toISOString()
                  : '',
              });
              setInternalTask((pre) => ({
                ...pre,
                startDate: startDate ? moment(startDate) : null,
                endDate: endDate
                  ? moment(endDate)
                  : startDate
                  ? moment(startDate)
                  : null,
              }));
            }}
            renderTrigger={() =>
              internalTask.startDate && internalTask?.endDate ? (
                <Typography variant="subhead-sm" color={themeColor.textColor}>
                  {moment(internalTask?.endDate).format('MMM DD')}
                </Typography>
              ) : (
                <IconBoldCalendar
                  size={16}
                  color={themeColor.textPlaceholderColor}
                />
              )
            }
          />
          <TaskPriority
            task={internalTask}
            onChange={(value) => {
              updateAttributes({
                [TaskNodeAttributes.priority]: value,
              });
              setInternalTask((pre) => ({
                ...pre,
                priority: value as TaskPriorityType,
              }));
            }}
            componentsProps={{
              popover: {
                disableRestoreFocus: true,
              },
            }}
            renderTrigger={
              internalTask.priority
                ? undefined
                : () => (
                    <IconBoldFlag
                      size={16}
                      color={themeColor.textPlaceholderColor}
                    />
                  )
            }
          />
          <TaskAssignee
            task={internalTask}
            onChange={(_, users) => {
              updateAttributes({
                [TaskNodeAttributes.ownerId]: users
                  .map((u) => u.id)
                  .filter(Boolean)
                  .join(','),
              });
              setInternalTask((pre) => ({
                ...pre,
                taskMembers: users,
              }));
            }}
            renderTrigger={
              internalTask.taskMembers[0]
                ? undefined
                : () => (
                    <IconBoldUser
                      size={16}
                      color={themeColor.textPlaceholderColor}
                    />
                  )
            }
            componentsProps={{
              icon: {
                size: 16,
              },
              popover: {
                disableRestoreFocus: true,
              },
            }}
            readOnly={!(internalTask.taskMembers[0] || selected)}
          />
          {!isMobileAppWebView &&
            (remoteTask ? (
              <StyledActionButton
                placement="top"
                tooltip="View task details"
                disableRipple
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: 0,
                }}
                className="task-action"
              >
                <Link
                  to={PlotRoutes.task(remoteTask.id)}
                  state={{
                    /**
                     * This should be displayed in a note, which means users can navigate to the task from:
                     * 1. 2nd layer (note modal)
                     *    If users are navigating from 2nd layer, keep the 1st layer &
                     *    set current location (note modal) as 2nd layer (secondaryLocation).
                     *    We then open the task modal in 3rd layer.
                     * 2. 3rd layer (post preview modal)
                     *    If users are navigating from 3rd layer, keep the 1st & 2nd layer.
                     *    Simply move to the new task in 3rd layer.
                     */
                    // @ts-ignore
                    backgroundLocation: locationState.backgroundLocation,
                    secondaryLocation:
                      locationState.secondaryLocation || location,
                  }}
                  style={{ padding: 0, margin: 0 }}
                >
                  <IconBoldExport
                    size={16}
                    color={themeColor.textPlaceholderColor}
                    style={{ cursor: 'pointer' }}
                  />
                </Link>
              </StyledActionButton>
            ) : isRemoteTaskLoading ? (
              <CircularProgress
                size={16}
                sx={{ color: themeColor.textPlaceholderColor }}
              />
            ) : null)}
        </Box>
      </Box>
    </NodeViewWrapper>
  );
};
