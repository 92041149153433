import { Box, Skeleton, TableRow } from '@mui/material';
import { StyledBodyCell } from './styles';

export const ContentIdeaTableRowSkeleton = () => {
  return (
    <TableRow>
      <StyledBodyCell>
        <Skeleton
          animation={false}
          variant="rectangular"
          sx={{
            width: 56,
            height: 72,
            borderRadius: 2,
          }}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton animation={false} variant="text" width={150} />
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton
          animation={false}
          variant="rectangular"
          height={30}
          width={100}
          sx={{
            borderRadius: 1,
          }}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton animation={false} variant="text" width={80} />
      </StyledBodyCell>
      <StyledBodyCell>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Skeleton
            animation={false}
            variant="rounded"
            height={18}
            width={18}
            sx={{
              borderRadius: 25,
            }}
          />
          <Skeleton animation={false} variant="text" width={80} />
        </Box>
      </StyledBodyCell>
      <StyledBodyCell>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap',
          }}
        >
          {[...Array(2)].map((_, index) => (
            <Skeleton
              animation={false}
              key={index}
              variant="rectangular"
              height={30}
              width={Math.floor(Math.random() * (120 - 60 + 1)) + 80}
              sx={{
                borderRadius: 1,
              }}
            />
          ))}
        </Box>
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton
          animation={false}
          variant="rectangular"
          height={30}
          width={Math.floor(Math.random() * (120 - 60 + 1)) + 80}
          sx={{
            borderRadius: 1,
          }}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton
          animation={false}
          variant="rectangular"
          height={25}
          width={Math.floor(Math.random() * (150 - 90 + 1)) + 90}
          sx={{
            borderRadius: 25,
          }}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
            flexWrap: 'wrap',
          }}
        >
          {[...Array(2)].map((_, index) => (
            <Skeleton
              animation={false}
              key={index}
              variant="rectangular"
              height={72}
              width={Math.floor(Math.random() * (120 - 30 + 1)) + 30}
              sx={{
                borderRadius: 2,
              }}
            />
          ))}
        </Box>
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton
          animation={false}
          variant="rectangular"
          height={72}
          width="100%"
          sx={{
            borderRadius: 2,
          }}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton
          animation={false}
          variant="rectangular"
          height={72}
          width={60}
          sx={{
            borderRadius: 2,
          }}
        />
      </StyledBodyCell>
      <StyledBodyCell>
        <Skeleton animation={false} variant="text" width={90} />
      </StyledBodyCell>
    </TableRow>
  );
};
