/**
 * This is a top-level error boundary component.
 * Right now there's mostly nothing inside this except for a default fallback.
 * I will follow up with this in PLO-1012.
 */

import { Box, Button, SxProps } from '@mui/material';
import * as Sentry from '@sentry/react';
import { GENERAL_ERROR_MESSAGE } from 'constants/error';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  disableReload?: boolean;
  sx?: SxProps;
  hiddenOnError?: boolean;
  getErrorMessage?: (
    error: Error,
    errorInfo: React.ErrorInfo,
  ) => React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
    };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    this.setState({
      error,
      errorInfo,
    });
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.hiddenOnError) {
        return null;
      }

      // Capture top level errors and metadata arround it
      if (this.state.error && this.state.errorInfo) {
        Sentry.withScope((scope) => {
          scope.setExtra('Error', this.state.error);
          scope.setExtra('Error Info', this.state.errorInfo);
          // Capturing the error as a message
          // since an exception isn't actually thrown here
          Sentry.captureMessage(
            `Top Level Error: ${JSON.stringify(this.state.error)}`,
            'error',
          );
        });
      }

      return (
        <Box
          width="100%"
          height="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={4}
          sx={this.props.sx}
        >
          <Box>
            {this.props.getErrorMessage &&
            this.state.error &&
            this.state.errorInfo
              ? this.props.getErrorMessage(
                  this.state.error,
                  this.state.errorInfo,
                )
              : GENERAL_ERROR_MESSAGE}
          </Box>
          {!this.props.disableReload && (
            <Button
              variant="primary"
              size="small"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          )}
        </Box>
      );
    }

    return this.props.children;
  }
}
