import { Box, TextField, Typography } from '@mui/material';
import { useBrandDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks/onboarding';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingBrandNameProps = {
  brandId?: string;
  onEnter: () => void;
};

export const SocialMediaListeningOnboardingBrandName = ({
  brandId,
  onEnter,
}: SocialMediaListeningOnboardingBrandNameProps) => {
  const navigate = useNavigate();

  const { brand, onboardingActions } = useBrandDataForSocialListeningOnboarding(
    { brandId },
  );
  const [brandName, setBrandName] = useState(brand?.name || '');

  const [hasError, setHasError] = useState(false);
  const validateAndProceed = () => {
    if (!brandName.trim().length) {
      setHasError(true);
    } else {
      setHasError(false);
      onSubmit();
    }
  };

  useEffect(() => {
    setBrandName(brand?.name || '');
  }, [brand?.name]);

  const onSubmit = async () => {
    const _brandId = await onboardingActions.handleBrandNameUpdate(brandName);
    if (brandId) {
      onEnter();
    } else {
      navigate(PlotRoutes.socialListeningBrandOnboarding(_brandId), {
        replace: true,
      });
      onEnter();
    }
  };

  return (
    <Box
      position="relative"
      width="60vw"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
      sx={{ overflowY: 'auto', pb: 4 }}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        What’s your Brand Name?
      </Typography>

      <Box display="flex" flexDirection="column" gap={3} width="100%" mt={3}>
        <TextField
          autoFocus
          placeholder="Brand Name"
          value={brandName}
          onChange={(e) => setBrandName(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              validateAndProceed();
            }
          }}
          sx={{
            '.MuiOutlinedInput-root': {
              ...(hasError
                ? { border: `2px solid ${theme.colors?.utility['pink-1']}` }
                : {}),
              borderRadius: theme.spacing(4),
              py: `${theme.spacing(6)} !important`,
              px: `${theme.spacing(6)} !important`,
              bgcolor: 'rgba(255, 255, 255, 0.10)',
              color: theme.colors?.primary.parchment,

              input: {
                ...theme.typography['headline-lg'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
        />
      </Box>

      <RenderNextButton
        loading={onboardingActions.savingBrandData}
        onNextClick={validateAndProceed}
      />
    </Box>
  );
};
