import { gql } from '@apollo/client';
import { Typography } from '@mui/material';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import {
  CreatorFragmentCreatorHandleChipFragment,
  Platform,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CreatorFragmentCreatorHandleChip on CreatorModel {
    id
    handle
    platform
  }
`;

type CreatorHandleChipProps = {
  creator: CreatorFragmentCreatorHandleChipFragment;
};

export const CreatorHandleChip = ({ creator }: CreatorHandleChipProps) => {
  return (
    <Typography
      variant="body-lg"
      color={theme.colors?.utility[800]}
      display="flex"
      alignItems="center"
      gap={2}
      sx={{
        mt: 1,
        width: 'fit-content',
        p: theme.spacing(1, 2),
        backgroundColor: 'rgba(35, 6, 3, 0.05)',
        borderRadius: theme.spacing(2),
      }}
    >
      {creator.platform === Platform.Instagram && (
        <IconBoldInstagram size={16} />
      )}
      {creator.platform === Platform.Tiktok && <IconCustomTiktok size={16} />}
      {creator.handle}
    </Typography>
  );
};
