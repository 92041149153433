import { gql } from '@apollo/client';
import { POST_FRAGMENT_POST_PERMISSION_SUMMARY } from 'features/post-permission';
import {
  CollectionFragmentCollectionCardViewFragmentDoc,
  CollectionFragmentCollectionPermissionSummaryFragmentDoc,
  PostFilterType,
  PostFragmentCollectionPostDndPostCardFragmentDoc,
  useCollectionsSmartSearchForCollectionPermissionCollectionTreeQuery,
  useGetPostsSmartSearchForCollectionPermissionCollectionTreeQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const COLLECTION_FRAGMENT_COLLECTION_PERMISSION_COLLECTION_TREE = gql`
  fragment CollectionFragmentCollectionPermissionCollectionTree on CollectionModel {
    id
    name
    myPermissions
    ...CollectionFragmentCollectionPermissionSummary
  }
  ${CollectionFragmentCollectionPermissionSummaryFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query CollectionsSmartSearchForCollectionPermissionCollectionTree(
    $filters: CollectionFilters!
    $after: String
    $take: Int
  ) {
    collectionsSmartSearch(filters: $filters, after: $after, take: $take) {
      meta {
        totalCount
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
      data {
        type
        item {
          ...CollectionFragmentCollectionCardView
          ...CollectionFragmentCollectionPermissionCollectionTree
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT_COLLECTION_PERMISSION_COLLECTION_TREE}
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const POST_FRAGMENT_COLLECTION_PERMISSION_COLLECTION_TREE = gql`
  fragment PostFragmentCollectionPermissionCollectionTree on PostModel {
    id
    attachment {
      name
    }
    ...PostFragmentCollectionPostDndPostCard
    ...PostFragmentPostPermissionSummary
  }
  ${PostFragmentCollectionPostDndPostCardFragmentDoc}
  ${POST_FRAGMENT_POST_PERMISSION_SUMMARY}
`;

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForCollectionPermissionCollectionTree(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $sortType: SortType
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      sortType: $sortType
    ) {
      data {
        item {
          id
          ...PostFragmentCollectionPermissionCollectionTree
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${POST_FRAGMENT_COLLECTION_PERMISSION_COLLECTION_TREE}
`;

type Props = {
  collectionId?: string;
};

export const useCollectionPermissionCollectionTreeHook = ({
  collectionId,
}: Props) => {
  const {
    data: paginatedCollectionData,
    loading: isLoadingCollections,
    fetchMore: fetchMoreCollections,
  } = useCollectionsSmartSearchForCollectionPermissionCollectionTreeQuery({
    variables: {
      filters: {
        ...(collectionId ? { parentCollectionIds: [collectionId] } : {}),
      },
      take: 10,
    },

    fetchPolicy: 'network-only',
  });
  const [fetchedCollections, setFetchedCollections] = useState(false);
  const collections =
    paginatedCollectionData?.collectionsSmartSearch.data.map((x) => x.item) ||
    [];

  useEffect(() => {
    if (
      paginatedCollectionData?.collectionsSmartSearch.meta.totalCount ===
      collections.length
    ) {
      setFetchedCollections(true);
    }
  }, [paginatedCollectionData]);

  const {
    data: postData,
    loading: isPostLoading,
    fetchMore: fetchMorePosts,
  } = useGetPostsSmartSearchForCollectionPermissionCollectionTreeQuery({
    variables: {
      filters: {
        ...(collectionId ? { collectionIds: [collectionId] } : {}),
        filterType: PostFilterType.OrganizationPosts,
      },
    },
    skip: !fetchedCollections,
    fetchPolicy: 'cache-and-network',
  });

  const posts = postData?.postsSmartSearch.data.map((x) => x.item) || [];

  const fetchNextPage = () => {
    if (
      !fetchedCollections &&
      !isLoadingCollections &&
      paginatedCollectionData?.collectionsSmartSearch?.pageInfo.hasNextPage
    ) {
      fetchMoreCollections({
        variables: {
          filters: {
            ...(collectionId ? { parentCollectionIds: [collectionId] } : {}),
          },
          after:
            paginatedCollectionData?.collectionsSmartSearch?.pageInfo.endCursor,
          take: 10,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult.collectionsSmartSearch.pageInfo.hasNextPage) {
            setFetchedCollections(true);
          }

          return {
            ...fetchMoreResult,
            collectionsSmartSearch: {
              ...fetchMoreResult.collectionsSmartSearch,
              data: [
                ...(prev.collectionsSmartSearch.data || []),
                ...(fetchMoreResult.collectionsSmartSearch.data || []),
                // Dedupe by cursor
              ].filter(
                (e, index, self) =>
                  index === self.findIndex((t) => t.item.id === e.item.id),
              ),
            },
          };
        },
      });
    } else if (fetchedCollections && !isPostLoading) {
      fetchMorePosts({
        variables: {
          filters: {
            ...(collectionId ? { collectionIds: [collectionId] } : {}),
            filterType: PostFilterType.OrganizationPosts,
          },
          after: postData?.postsSmartSearch.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return {
            ...fetchMoreResult,
            postsSmartSearch: {
              ...fetchMoreResult.postsSmartSearch,
              data: [
                ...(prev.postsSmartSearch.data || []),
                ...(fetchMoreResult.postsSmartSearch.data || []),
                // Dedupe by cursor
              ].filter(
                (e, index, self) =>
                  index === self.findIndex((t) => t.item.id === e.item.id),
              ),
            },
          };
        },
      });
    }
  };

  useEffect(() => {
    setFetchedCollections(false);
  }, []);

  return {
    loading: isLoadingCollections || isPostLoading,
    collections,
    posts,
    fetchNextPage,
    hasMoreCollections:
      paginatedCollectionData?.collectionsSmartSearch?.pageInfo.hasNextPage,
  };
};
