import {
  Autocomplete,
  Box,
  Button,
  Chip,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { typography } from 'components/common/Typography/styles';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

import { gql } from '@apollo/client';
import {
  UserFragmentAvatarGroupFragmentDoc,
  UserProfileFragmentManageUsersInputFragment,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment UserProfileFragmentManageUsersInput on UserProfileModel {
    id
    ...UserFragmentAvatarGroup
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

type ManageUsersInputProps = {
  users: UserProfileFragmentManageUsersInputFragment[];

  onEnter?: VoidFunction;
  searchStr: string;
  onSearchStrUpdate: (value: string) => void;
  onRemoveUser: (index: number) => void;
  onInvite?: VoidFunction;
  showInviteButton?: boolean;
  sx?: SxProps;
  inputRef?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  disabled?: boolean;
  componentProps?: {
    tag?: {
      sx?: SxProps;
    };
    input?: {
      sx?: SxProps;
    };
  };
};

export const ManageUsersInput = (props: ManageUsersInputProps) => {
  const {
    users,
    searchStr,
    onSearchStrUpdate,
    onInvite,
    onRemoveUser,
    onEnter,
    showInviteButton,
    componentProps,
    sx,
    inputRef,
    disabled,
    placeholder,
  } = props;

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      id="size-small-standard-multi"
      options={users}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) => option.id}
      value={users}
      inputValue={searchStr}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'reset' && newInputValue === '') {
        } else {
          onSearchStrUpdate(newInputValue);
        }
      }}
      sx={{
        p: theme.spacing(4, 4, 0, 4),
        '& .Mui-disabled': {
          backgroundColor: 'transparent !important',
        },
        ...sx,
      }}
      PaperComponent={() => null}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            sx={componentProps?.tag?.sx}
            onDelete={() => {
              onRemoveUser(index);
            }}
            label={
              <Box display="flex" gap={2} alignItems="center">
                <Avatar user={option} size={20} />
                <Typography
                  variant="body-xl"
                  fontWeight={600}
                  color={theme.colors?.primary.black}
                >
                  {getFullName(option)}
                </Typography>
              </Box>
            }
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          inputRef={inputRef}
          {...params}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEnter?.();
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: showInviteButton && (
              <Button
                disabled={!users.length}
                onClick={() => {
                  onInvite?.();
                  onSearchStrUpdate('');
                }}
                sx={{
                  position: 'absolute',
                  right: 0,
                  borderRadius: theme.spacing(2),
                }}
                variant="primary-alt"
              >
                Invite
              </Button>
            ),
          }}
          variant="outlined"
          placeholder={placeholder ?? 'Add people...'}
          sx={{
            '.MuiOutlinedInput-root': {
              borderRadius: 100,
              py: '6px !important',
              pl: '12px !important',
              pr: '100px !important',

              input: {
                ...typography['subhead-lg'],
                p: '0 !important',
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
            ...componentProps?.input?.sx,
          }}
        />
      )}
    />
  );
};
