/**
 * This isn't exactly a form, but whatever...
 */

import { gql } from '@apollo/client';
import {
  Autocomplete,
  Box,
  BoxProps,
  Grid,
  SxProps,
  TextField,
  Typography,
  createFilterOptions,
  styled,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { POST_FRAGMENT_POST_COMMAND } from 'contexts/commands/hooks/usePostCommands/types';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import { useAutocompleteInfiniteCollections } from 'features/collection/hooks/useAutocompleteInfiniteCollections';
import {
  COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
  CollectionListItemSkeleton,
  CollectionMenuItemAddNewView,
  CollectionMenuItemWithPostPermissionAlertView,
} from 'features/collection/views';
import {
  CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
  ContentCalendarCollectionMenuItem,
} from 'features/contentCalendar';
import { PostPreview } from 'features/post';
import {
  CollectionPermission,
  PlotFeature,
  PostFragmentCollectionSendToCollectionFormFragment,
  SearchHitType,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { usePostHandlers } from './usePostHandlers';

const StyledPostContainer = styled(Box)<BoxProps>(() => ({
  borderRadius: theme.spacing(6),
  border: `${theme.spacing(2)} solid ${theme.colors?.primary.white}`,
  flex: 1,
  backgroundColor: theme.colors?.primary.white,
  aspectRatio: '3 / 4',
}));

export const POST_FRAGMENT_COLLECTION_SEND_TO_COLLECTION_FORM = gql`
  fragment PostFragmentCollectionSendToCollectionForm on PostModel {
    id
    ...PostFragmentPostCommand
  }
  ${POST_FRAGMENT_POST_COMMAND}
`;

const filter = createFilterOptions<{ value: string; label: string }>();

export type CollectionSendToCollectionFormInitialValues = {
  posts: PostFragmentCollectionSendToCollectionFormFragment[];
};

export type CollectionSendToCollectionFormProps = {
  initialValues?: CollectionSendToCollectionFormInitialValues;
  sx?: SxProps;
};

export const CollectionSendToCollectionForm = (
  props: CollectionSendToCollectionFormProps,
) => {
  const { orgBilling } = useUserContext();

  const { initialValues, sx } = props;
  const { posts = [] } = initialValues || {};

  const analytics = useAnalytics();
  const { triggerCommand } = useCommandContext();

  const [
    collectionIdsPostsHaveBeenSavedTo,
    setCollectionIdsPostsHaveBeenSavedTo,
  ] = useState(
    // All posts need to belong to the same collection for it to count
    posts
      .map((p) => (p.collections || []).map((c) => c.id))
      .flat()
      .filter((cId) => {
        return posts.every((p) =>
          (p.collections || []).some((c) => c.id === cId),
        );
      }),
  );

  const [searchStr, setSearchStr] = useState('');
  const {
    autoCompleteProps,
    collectionSearchHits = [],
    loadCollectionsData,
    refetchCollections,
    fetchingCollections: loading,
  } = useAutocompleteInfiniteCollections({
    postIds: posts.map((p) => p.id),
    searchStr,
  });

  const { isFeatureEnabled } = useFeatureFlagContext();

  useEffect(() => {
    loadCollectionsData();
  }, []); // eslint-disable-line

  const collections = useMemo(() => {
    const collections =
      collectionSearchHits.map((h) => ({
        ...h.item,
        searchHitType: h.type,
      })) || [];
    return collections.filter((c) =>
      c.myPermissions.includes(CollectionPermission.Update),
    );
  }, [collectionSearchHits]);

  const options = useMemo(() => {
    return collections
      .map((collection) => ({
        value: collection.id,
        label: collection.name,
      }))
      .sort((a, b) => {
        // Push selected collections to the top
        const aIsSelected = collectionIdsPostsHaveBeenSavedTo.includes(a.value)
          ? 1
          : 0;
        const bIsSelected = collectionIdsPostsHaveBeenSavedTo.includes(b.value)
          ? 1
          : 0;

        return bIsSelected - aIsSelected;
      });
  }, [collections]); // eslint-disable-line -- we don't add value here because we don't want to re-sort the options when the value changes

  const { onSavePostsToCollections, onRemovePostsFromCollections } =
    usePostHandlers();

  const onSelectCollection = async (collectionId: string) => {
    // === Analytics ===
    const collection = collections.find((c) => c.id === collectionId);
    if (collection?.searchHitType === SearchHitType.Smart) {
      analytics.track(EventName.SuggestedCollectionSelected, {
        collectionId: collection.id,
      });
    }
    // === End of Analytics ===

    if (!collectionIdsPostsHaveBeenSavedTo.includes(collectionId)) {
      onSavePostsToCollections(
        posts.map((p) => p.id),
        [collectionId],
      );
      setCollectionIdsPostsHaveBeenSavedTo((prev) => [...prev, collectionId]);
    } else {
      onRemovePostsFromCollections(
        posts.map((p) => p.id),
        [collectionId],
      );
      setCollectionIdsPostsHaveBeenSavedTo((prev) =>
        prev.filter((id) => id !== collectionId),
      );
    }
  };

  const renderPostList = () => {
    if (posts.length === 1) {
      return (
        <StyledPostContainer
          sx={{
            width: '100%',
          }}
        >
          <PostPreview
            post={posts[0]}
            sx={{ borderRadius: theme.spacing(4), flex: 1 }}
          />
        </StyledPostContainer>
      );
    }

    return (
      <Grid
        container
        spacing={4}
        sx={{
          width: '100%',
          overflow: 'auto',
          pt: theme.spacing(8),
          pb: theme.spacing(8),
        }}
      >
        {posts.map((post, idx) => {
          return (
            <Grid key={idx} item xs={6}>
              <StyledPostContainer sx={{ borderRadius: 4 }}>
                {posts && (
                  <PostPreview
                    post={post}
                    sx={{
                      borderRadius: theme.spacing(3),
                    }}
                  />
                )}
              </StyledPostContainer>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '50%',
          maxHeight: '100%',
          overflow: posts.length > 1 ? 'auto' : 'overflow',
          display: 'flex',
          alignSelf: 'center',
          pr: posts.length > 1 ? 2 : 6,
        }}
      >
        {renderPostList()}
      </Box>

      <Box
        width="50%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        gap={theme.spacing(3)}
        padding={theme.spacing(8, 0, 2, 0)}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            pt: 8,
          }}
        >
          <Typography
            variant="headline-xl"
            fontSize={24}
            color={theme.colors?.utility['purple-4']}
            mb={6}
          >
            Pick a collection
          </Typography>
          <Autocomplete
            ListboxProps={autoCompleteProps.ListboxProps}
            ListboxComponent={autoCompleteProps.ListBoxComponent}
            inputValue={searchStr}
            onInputChange={(_, value, reason) => {
              if (reason === 'input') {
                setSearchStr(value);
              }
            }}
            open
            multiple
            value={[]}
            options={options}
            loading={loading}
            loadingText={
              <Box display="flex" flexDirection="column" gap={4}>
                <CollectionListItemSkeleton />
                <CollectionListItemSkeleton />
              </Box>
            }
            disablePortal
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                placeholder="Collection"
                sx={{
                  '.MuiOutlinedInput-root': {
                    mb: 4,
                    height: '32px',
                    borderRadius: 100,
                    py: '6px !important',
                    px: '12px !important',
                    bgcolor: theme.colors?.primary.white,

                    input: {
                      ...typography['subhead-lg'],
                      p: '0 !important',
                    },

                    '.MuiOutlinedInput-notchedOutline': {
                      display: 'none !important',
                    },
                  },
                }}
              />
            )}
            renderTags={() => null}
            renderOption={(props, option) => {
              const collection = collections.find((c) => c.id === option.value);

              if (option.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY) {
                return (
                  <CollectionMenuItemAddNewView
                    {...props}
                    key={option.value}
                    label={option.label}
                  />
                );
              }

              const commonMenuItemProps = {
                componentProps: {
                  listItem: {
                    sx: {
                      maxWidth: 240,
                    },
                  },
                  menu: {
                    sx: {
                      '& .MuiPaper-root': {
                        boxShadow:
                          'rgba(24, 39, 75, 0.12) 0px 12px 42px -4px, rgba(24, 39, 75, 0.12) 0px 8px 18px -6px !important',
                        bgcolor: 'rgba(255, 255, 255, 0.9)',
                        backdropFilter: 'blur(24px)',
                        width: 310,
                      },
                    },
                  },
                },
              };

              if (
                option.value === CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY
              ) {
                return (
                  <ContentCalendarCollectionMenuItem
                    key={option.value}
                    onClick={(selectedCollection) => {
                      if (selectedCollection) {
                        onSelectCollection(selectedCollection.id);
                      }
                    }}
                    selectedCollectionIds={collectionIdsPostsHaveBeenSavedTo}
                    {...commonMenuItemProps}
                  />
                );
              }

              if (collection) {
                return (
                  <CollectionMenuItemWithPostPermissionAlertView
                    key={collection.id}
                    postIds={posts.map((p) => p.id)}
                    collection={collection}
                    onClick={(selectedCollection) =>
                      onSelectCollection(selectedCollection.id)
                    }
                    selectIconPosition="end"
                    selectedCollectionIds={collectionIdsPostsHaveBeenSavedTo}
                    shouldShowBreadcrumbsForRoot
                    hideSelectIcon={false}
                    {...commonMenuItemProps}
                    componentProps={{
                      ...commonMenuItemProps.componentProps,
                      listItem: {
                        isSmartSearchResult:
                          collection.searchHitType === SearchHitType.Smart,
                      },
                    }}
                  />
                );
              }

              return null;
            }}
            onChange={(event, value, reason, details) => {
              const { option } = details || {};

              // Only handle when user selects the add new option
              // For other options, we handle them in renderOption & only accept click event
              // Reason: It's confusing to let default keyboard events work here, because users can randomly Enter
              // and toggle collection without knowing it
              const canCreate = orgBilling?.collectionUsageLimit
                ? orgBilling.collectionUsageCount <
                  orgBilling.collectionUsageLimit
                : true;

              if (
                canCreate &&
                option?.value === COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY
              ) {
                triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
                  initialValues: {
                    name: option.label === 'New collection' ? '' : option.label,
                    posts: [],
                  },
                  onCompleted: (collection) => {
                    onSelectCollection(collection.id).then(() =>
                      refetchCollections(),
                    );
                  },
                  onSelectExistingCollection: (collectionId) => {
                    onSelectCollection(collectionId);
                  },
                });
              }
            }}
            popupIcon={null}
            clearIcon={null}
            // @ts-ignore
            PopperComponent={Box}
            PaperComponent={Box}
            componentsProps={{
              popper: {
                sx: {
                  flex: 1,
                  position: 'relative',
                  width: '100% !important',
                  overflow: 'auto',
                  height: '100%',
                  '.MuiAutocomplete-listbox': {
                    minHeight: '100%',
                    maxHeight: 'unset',
                    pb: 8,
                  },
                },
              },
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const isOptionExist = options.some(
                (option) =>
                  option.label.trim().toLowerCase() ===
                  params.inputValue.trim().toLowerCase(),
              );

              if (
                !params.inputValue &&
                isFeatureEnabled(PlotFeature.ContentCalendar)
              ) {
                filtered.unshift({
                  label: 'Content Calendar',
                  value: CONTENT_CALENDAR_COLLECTION_MENU_ITEM_VALUE_KEY,
                });
              }
              if (!isOptionExist) {
                filtered.unshift({
                  label: params.inputValue || 'New collection',
                  value: COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY,
                });
              }

              return filtered;
            }}
          />
        </Box>

        {orgBilling && (
          <BillingCollectionLimitIndicator
            variant="compact"
            organizationBilling={orgBilling}
            sx={{ borderRadius: theme.spacing(2) }}
          />
        )}
      </Box>
    </Box>
  );
};
