/**
 * This hook checks what type of widget we should use to render preview UI for a url.
 * Right now we have 2 rendering widget:
 * 1. IframelyEmbedWidget, which renders through Iframely's iframe
 * 2. CustomRenderingWidget, which renders by using native elements like img, video, etc. in certain scenarios.
 *
 * The reason why we need a custom widget is because sometimes Iframely handles things very poorly.
 * SVG gets cut-off, MP4 with mime application/octet-stream could not load, etc.
 */

import { useMemo, useState } from 'react';
import { getValidatedMimeType } from 'utils/mime';
import { CustomRenderingElement } from '../components/CustomRenderingWidget';
import { useIframelyAPI } from './useIframelyAPI';

export enum IframelyRenderingWidget {
  CUSTOM = 'custom',
  EMBED_IFRAME = 'embed-iframe',
}

interface Props {
  mimeType?: string;
  url?: string;
  hasPreviewableSocialMedia?: boolean;
}

export const useCheckRenderingWidget = (props: Props) => {
  const {
    mimeType: _mimeType = '',
    url = '',
    hasPreviewableSocialMedia = false,
  } = props;

  const [mimeType, setMimeType] = useState(_mimeType);
  const [customRenderingElement, setCustomRenderingElement] =
    useState<CustomRenderingElement>();

  const { getUrlMetadata } = useIframelyAPI();

  useMemo(() => {
    if (!mimeType && !!url && !hasPreviewableSocialMedia) {
      getUrlMetadata(url).then(({ links }) => {
        // Capture file type
        // Original ref: urlMetadata.service.ts::L282
        if (links?.file) {
          // We'll want to avoid getting the "reader" object
          // as in some scenarios like PDF, it's actually the google docs wrapper, which has mimeType of text/html
          const mimeType =
            links.file.filter((f) => !f.rel.includes('reader'))[0]?.type || '';

          setMimeType(mimeType);
        }
      });
    }
  }, [mimeType, url]); // eslint-disable-line

  useMemo(() => {
    if (hasPreviewableSocialMedia) {
      setCustomRenderingElement('socialMediaPreview');
    } else if (mimeType) {
      const validatedMimeType = getValidatedMimeType(mimeType, url);

      if (/^image/.test(validatedMimeType)) {
        setCustomRenderingElement('img');
      }
      if (/^video/.test(validatedMimeType.trim())) {
        setCustomRenderingElement('video');
      }

      if (/^audio/.test(validatedMimeType)) {
        setCustomRenderingElement('audio');
      }

      if (/^application\/pdf/.test(validatedMimeType)) {
        setCustomRenderingElement('pdf');
      }
    }
  }, [mimeType]); // eslint-disable-line

  return {
    isUsingCustomRenderingWidget: Boolean(customRenderingElement),
    customRenderingElement,
  };
};
