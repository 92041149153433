import { useResolveResourcePreview } from 'hooks/useResourceUploadQueue/useResolveResourcePreview';
import { AttachmentSearchHitFragmentItemThumbnailFragment } from 'graphql/generated';
import React, { useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { theme } from 'styles/theme';
import { IconOutlineDocument1 } from 'components/icons/components/outline/IconOutlineDocument1';
import { Image } from 'components/common/Image';
import { ResourceUploadType } from 'hooks/useResourceUploadQueue';

type SearchThumbnailAttachmentProps = {
  searchHitAttachment: AttachmentSearchHitFragmentItemThumbnailFragment;
};

export const SearchThumbnailAttachment = (
  props: SearchThumbnailAttachmentProps,
) => {
  const { searchHitAttachment } = props;

  const { url, title, mimeType } = useMemo(() => {
    const attachment = searchHitAttachment.item.attachment;

    return {
      url: attachment?.url || '',
      title: attachment?.name || '',
      mimeType: attachment?.metaData.mimeType || '',
      fileExtension: attachment?.name?.split('.')?.pop()?.toUpperCase() || '',
    };
  }, [searchHitAttachment]);

  const { isLoading: isLoadingPreview, previewUrl } = useResolveResourcePreview(
    {
      iconSize: 16,
      type: ResourceUploadType.Attachment,
      content: url,
      name: title,
      mimeType,
    },
  );

  return isLoadingPreview ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
      <CircularProgress
        size={24}
        sx={{ color: theme.colors?.primary.maroon }}
      />
    </Box>
  ) : previewUrl ? (
    <Image
      sx={{
        borderRadius: theme.spacing(2),
        objectFit: 'cover',
        height: '100%',
        width: '100%',
      }}
      src={previewUrl}
    />
  ) : (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: theme.colors?.utility[300],
        borderRadius: theme.spacing(2),
      }}
    >
      <IconOutlineDocument1 size={16} />
    </Box>
  );
};
