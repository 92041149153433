import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { TopicFragmentTopicContentFilterSettingsDialogViewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { TopicContentFilterSettingsView } from '../contentFilterSettings/TopicContentFilterSettingsView';

export const TOPIC_FRAGMENT_TOPIC_CONTENT_FILTER_SETTINGS_DIALOG_VIEW = gql`
  fragment TopicFragmentTopicContentFilterSettingsDialogView on TopicModel {
    id
    name
    brand {
      id
      creators {
        id
        profilePictureUrl
      }
    }
  }
`;

export type TopicContentFilterSettingsDialogViewProps = {
  topic: TopicFragmentTopicContentFilterSettingsDialogViewFragment;
  open: boolean;
  onClose: () => void;
};

export const TopicContentFilterSettingsDialogView = (
  props: TopicContentFilterSettingsDialogViewProps,
) => {
  const { topic, open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 8,
          borderRadius: 8,
          bgcolor: '#FAF3ECCC',
          backdropFilter: 'blur(40px)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          sx={{
            padding: 0,
          }}
          disableRipple
          onClick={onClose}
        >
          <IconBoldCloseCircle color={theme.colors?.utility[600]} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="headline-xl" fontSize={28} mb={2}>
            Content Filter Settings
          </Typography>
          <Typography
            variant="subhead-lg"
            color={theme.colors?.utility[800]}
            mb={4}
          >
            Define minimum requirements to ensure higher quality matches and
            filter out noise.
          </Typography>
          <Box>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                borderRadius: 1,
                px: 3,
                py: 1,
                gap: 1,
                bgcolor: '#2306030D',
              }}
            >
              <Avatar
                sx={{
                  height: 16,
                  width: 16,
                }}
                src={topic.brand.creators[0]?.profilePictureUrl || ''}
              />
              <Typography
                variant="headline-xs"
                color={theme.colors?.utility[900]}
              >
                / {topic.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ color: '#2306031A', my: 6 }} />
        <TopicContentFilterSettingsView topicId={topic.id} />
      </Box>
    </Dialog>
  );
};
