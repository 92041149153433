import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragment } from 'graphql/generated';
import _ from 'lodash';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SignalDefinitionFragmentSLAAnalyticsFilterByCategory on SignalDefinitionModel {
    id
    title
    options {
      id
      label
      value
    }
  }
`;

interface SLAAnalyticsFilterByCategoryProps {
  signalDefinitions: SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragment[];
  renderTitle?: () => React.ReactNode;
  selectedCategoryIds: string[];
  selectedCategoryOptionIds: string[];
  onChange: (
    selectedCategoryIds: string[],
    selectedCategoryOptionIds: string[],
  ) => void;
  variant?: 'normal' | 'accordion';
}

export const SLAAnalyticsFilterByCategory = ({
  signalDefinitions,
  renderTitle,
  selectedCategoryIds,
  selectedCategoryOptionIds,
  onChange,
  variant = 'accordion',
}: SLAAnalyticsFilterByCategoryProps) => {
  const categories = signalDefinitions.map((signalDefinition) => ({
    id: signalDefinition.id,
    name: signalDefinition.title,
    subCategories: signalDefinition.options.map((option) => ({
      id: option.id,
      name: option.label,
    })),
  }));

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconCustomSparkles
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-lg">Signals</Typography>
      </Box>
    );
  }, [renderTitle]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      sx={{
        ...(variant === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(variant === 'normal' && {
            display: 'none',
          }),
        },
      }}
      defaultOpen={variant === 'normal'}
      label={renderLabel}
      dropDownOptions={
        categories.length
          ? categories.map((category) => {
              const allSubcategoryIds = category.subCategories.map(
                (sub) => sub.id,
              );
              const allSubcategoriesSelected = category.subCategories.every(
                (sub) => selectedCategoryOptionIds.includes(sub.id),
              );

              return {
                label: category.name,
                value: category.id,
                renderOption: () =>
                  category.subCategories.length ? (
                    <Box>
                      <DropDownMoreMenuItem
                        renderHeaderAsOption
                        label={
                          <Box sx={{ maxWidth: 120, overflow: 'hidden' }}>
                            <Typography
                              variant="subhead-lg"
                              sx={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                              }}
                            >
                              {category.name}
                            </Typography>
                          </Box>
                        }
                        onClick={() => {
                          if (allSubcategoriesSelected) {
                            // Unselect all children only
                            onChange(
                              [], // Never include parent IDs
                              selectedCategoryOptionIds.filter(
                                (id) => !allSubcategoryIds.includes(id),
                              ),
                            );
                          } else {
                            // Select all children only
                            onChange(
                              [], // Never include parent IDs
                              _.uniq([
                                ...selectedCategoryOptionIds,
                                ...allSubcategoryIds,
                              ]),
                            );
                          }
                        }}
                        dropDownOptions={category.subCategories.map((sub) => ({
                          value: sub.id,
                          label: sub.name,
                          isChecked: selectedCategoryOptionIds.includes(sub.id),
                          renderOption: () => (
                            <CheckboxMenuItem
                              disableRipple
                              value={sub.id}
                              label={sub.name}
                              onClick={() => {
                                const isSubSelected =
                                  selectedCategoryOptionIds.includes(sub.id);

                                let newOptionIds = _.uniq([
                                  ...selectedCategoryOptionIds,
                                ]);

                                if (isSubSelected) {
                                  // Unselect subcategory only
                                  newOptionIds = newOptionIds.filter(
                                    (id) => id !== sub.id,
                                  );
                                } else {
                                  // Select subcategory only
                                  newOptionIds = [...newOptionIds, sub.id];
                                }

                                // Never include parent IDs
                                onChange([], newOptionIds);
                              }}
                              checked={selectedCategoryOptionIds.includes(
                                sub.id,
                              )}
                              sx={{
                                ml: 6,
                              }}
                            />
                          ),
                        }))}
                      />
                    </Box>
                  ) : (
                    <CheckboxMenuItem
                      disableRipple
                      value={category.id}
                      label={category.name}
                      onClick={() => {
                        const isSelected = selectedCategoryIds.includes(
                          category.id,
                        );
                        if (isSelected) {
                          onChange(
                            selectedCategoryIds.filter(
                              (id) => id !== category.id,
                            ),
                            [],
                          );
                        } else {
                          onChange([...selectedCategoryIds, category.id], []);
                        }
                      }}
                      checked={selectedCategoryIds.includes(category.id)}
                    />
                  ),
              };
            })
          : [
              {
                label: 'No categories',
                value: '',
                renderOption: () => (
                  <Typography
                    p={2}
                    textAlign="center"
                    variant="subhead-lg"
                    color={theme.colors?.utility[700]}
                  >
                    No categories available
                  </Typography>
                ),
              },
            ]
      }
    />
  );
};
