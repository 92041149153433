import { gql } from '@apollo/client';
import { useClipboard, useDisclosure } from '@dwarvesf/react-hooks';
import { IconButton, Snackbar, SxProps } from '@mui/material';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import {
  ShareEntityType,
  useGenerateShareLinkForUseCopyLinkMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation GenerateShareLinkForUseCopyLink($data: GenerateShareLinkInput!) {
    generateShareLink(data: $data) {
      id
      entityId
      entityType
      validationToken
    }
  }
`;

export type UseCopyLinkProps = {
  entityId?: string;
  entityType?: ShareEntityType;
};

export const useCopyLink = (props: UseCopyLinkProps = {}) => {
  const { entityId, entityType } = props;

  const [url, setUrl] = useState(window.location.href);

  const { onCopy: onCopyLink } = useClipboard(url);
  const {
    isOpen: isCopied,
    onClose: onCloseCopyToast,
    onOpen: onShowCopyToast,
  } = useDisclosure();

  const [generateShareLink] = useGenerateShareLinkForUseCopyLinkMutation({
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });

  useEffect(() => {
    if (!entityId || !entityType) {
      return;
    }

    generateShareLink({
      variables: {
        data: {
          entityId,
          entityType,
        },
      },
    })
      .then((res) => {
        const shareLink = res.data?.generateShareLink;

        if (!shareLink) {
          return;
        }

        setUrl(
          `${window.location.origin}?inviteTo=${entityType}:${entityId}&validationToken=${shareLink.validationToken}`,
        );
      })
      .catch(() => {
        // Do nothing
      });
  }, [entityId, entityType]); // eslint-disable-line

  const renderCopyButton = (sx?: SxProps) => {
    return (
      <IconButton
        sx={{
          borderRadius: 100,
          background: `rgba(35, 6, 3, 0.10)`,
          ...sx,
        }}
        onClick={() => {
          onCopyLink();
          onShowCopyToast();
        }}
      >
        <IconOutlineLink2 size={16} color={theme.colors?.primary.black} />
      </IconButton>
    );
  };

  const renderCopyLinkToast = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          width: 'fit-content',
          '& .MuiPaper-root': {
            minWidth: 'fit-content',
          },
        }}
        ContentProps={{
          sx: {
            bgcolor: theme.colors?.utility['orange-1'],
            borderRadius: theme.spacing(3),
            border: 'none',
            ...theme.typography['headline-sm'],
            padding: theme.spacing(0, 8),
            color: theme.colors?.utility['orange-4'],
          },
        }}
        open={isCopied}
        autoHideDuration={3000}
        onClose={onCloseCopyToast}
        message="Link copied"
      />
    );
  };

  return { renderCopyButton, renderCopyLinkToast };
};
