import { useUserContext } from 'contexts/users/User.context';
import { useMemo } from 'react';
import {
  GeneralPermission,
  PermissionLevel,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
} from 'graphql/generated';
import { Box, SxProps, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { IconLinearLock1 } from 'components/icons/components/linear/IconLinearLock1';
import { AvatarGroup } from 'components/common/AvatarGroup';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';

export type PermissionSummaryViewProps = {
  organizationName: string;
  generalPermission: GeneralPermission;
  invitedMembers: {
    user: UserProfileFragmentUserProfilePermissionItemViewFragment;
    permissionLevel: PermissionLevel;
  }[];
  sx?: SxProps;
  componentsProps?: {
    organizationBadge?: {
      sx?: SxProps;
    };
    avatar?: {
      size?: number;
    };
  };
};

export const PermissionSummaryView = (props: PermissionSummaryViewProps) => {
  const {
    organizationName,
    invitedMembers,
    generalPermission,
    sx,
    componentsProps = {},
  } = props;

  const { user } = useUserContext();

  const members = useMemo(
    () =>
      invitedMembers
        .filter((x) => x.user.organization.id !== user?.organization.id)
        .map((x) => x.user),
    [invitedMembers], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (generalPermission === GeneralPermission.InviteOnly) {
    if (!invitedMembers.length) {
      return (
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          sx={{
            borderRadius: theme.spacing(4),
            color: theme.colors?.primary.black,
            fontWeight: 500,
            p: theme.spacing(1, 2),
            backgroundColor: 'rgba(35, 6, 3, 0.10)',
            overflow: 'hidden',
            width: 'fit-content',
            ...componentsProps.organizationBadge?.sx,
          }}
        >
          <IconLinearLock1
            size={componentsProps.avatar?.size || 16}
            style={{ flexShrink: 0 }}
          />
          <Typography
            variant="body-lg"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            Private
          </Typography>
        </Box>
      );
    }
    return (
      <AvatarGroup avatarSize={16} users={invitedMembers.map((x) => x.user)} />
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{
          borderRadius: theme.spacing(4),
          color: theme.colors?.primary.black,
          fontWeight: 500,
          p: theme.spacing(1, 2),
          backgroundColor: 'rgba(35, 6, 3, 0.10)',
          overflow: 'hidden',
          ...componentsProps.organizationBadge?.sx,
        }}
      >
        <IconBoldBuildings
          size={componentsProps.avatar?.size || 16}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="body-lg"
          sx={{
            flex: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {organizationName}
        </Typography>
      </Box>
      {members.length > 0 && (
        <>
          <Box
            color={theme.colors?.utility[800]}
            display="flex"
            alignItems="center"
          >
            <IconLinearAdd size={componentsProps.avatar?.size || 16} />
          </Box>
          <AvatarGroup
            avatarSize={componentsProps.avatar?.size || 16}
            users={members}
          />
        </>
      )}
    </Box>
  );
};
