import { theme } from 'styles/theme';

export const postReactionOptions = [
  {
    emoji: '👍',
    tooltip: 'This one!',
    hoverBgColor: theme.colors?.utility['green-1'],
    selectedBgColor: theme.colors?.utility['green-4'],
    selectedTextColor: theme.colors?.primary.parchment,
  },
  {
    emoji: '👎',
    tooltip: "Don't love",
    hoverBgColor: theme.colors?.utility['pink-3'],
    selectedBgColor: theme.colors?.utility['pink-4'],
    selectedTextColor: theme.colors?.primary.parchment,
  },
  {
    emoji: '🤔',
    tooltip: 'Hmmm',
    hoverBgColor: theme.colors?.utility['yellow-1'],
    selectedBgColor: theme.colors?.utility['yellow-4'],
    selectedTextColor: theme.colors?.primary.black,
  },
  {
    emoji: '⚰️',
    tooltip: 'Archive',
    hoverBgColor: theme.colors?.utility[400],
    selectedBgColor: theme.colors?.utility[700],
    selectedTextColor: theme.colors?.primary.parchment,
  },
];
