import React, { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { Box, styled, SxProps, Typography } from '@mui/material';

const StyledOption = styled(Typography)({
  padding: '3px 10px',
  textAlign: 'center',
  width: '50%',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Indicator = styled(Box)({
  position: 'absolute',
  width: '50%',
  height: '100%',
  borderRadius: theme.spacing(3.5),
  transition: 'all 300ms ease-in-out',
});

interface TextSwitchProps {
  sx?: SxProps;
  textOptionLeft: React.ReactNode;
  textOptionRight: React.ReactNode;
  isLeftOptionSelected?: boolean;
  onClick?: (isLeftOptionSelected: boolean) => void;
  selectedOptionTextColor?: string;
  componentProps?: {
    text?: {
      sx?: SxProps;
    };
    indicator?: {
      sx?: SxProps;
    };
  };
}

export const TextSwitch = (props: TextSwitchProps) => {
  const {
    sx,
    textOptionLeft,
    textOptionRight,
    componentProps,
    isLeftOptionSelected,
    selectedOptionTextColor,
    onClick,
  } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const indicatorRef = useRef<HTMLDivElement | null>(null);
  const textOptionLeftRef = useRef<HTMLSpanElement | null>(null);
  const textOptionRightRef = useRef<HTMLSpanElement | null>(null);

  const [selectedOption, setSelectedOption] = useState(isLeftOptionSelected);

  useEffect(() => {
    setSelectedOption(isLeftOptionSelected);
  }, [isLeftOptionSelected]);

  useEffect(() => {
    if (
      containerRef.current &&
      indicatorRef.current &&
      textOptionLeftRef.current &&
      textOptionRightRef.current
    ) {
      if (selectedOption) {
        indicatorRef.current.style.width = `${textOptionLeftRef.current.clientWidth}px`;

        const containerStyles = window.getComputedStyle(containerRef.current);
        indicatorRef.current.style.left = containerStyles.paddingLeft;

        if (selectedOptionTextColor) {
          textOptionLeftRef.current!.style.color = selectedOptionTextColor;
        }
        textOptionRightRef.current!.style.color = 'inherit';
      } else {
        indicatorRef.current.style.width = `${textOptionRightRef.current.clientWidth}px`;
        indicatorRef.current.style.left = `${
          textOptionLeftRef.current.clientWidth + 1
        }px`;

        textOptionLeftRef.current!.style.color = 'inherit';

        if (selectedOptionTextColor) {
          textOptionRightRef.current!.style.color = selectedOptionTextColor;
        }
      }
    }
  }, [containerRef, indicatorRef, selectedOption, selectedOptionTextColor]);

  const selectOption = () => {
    if (onClick) {
      onClick(!selectedOption);
    }

    setSelectedOption(!selectedOption);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: theme.spacing(3.5),
        ...sx,
      }}
      onClick={selectOption}
    >
      <StyledOption ref={textOptionLeftRef} sx={componentProps?.text?.sx}>
        {textOptionLeft}
      </StyledOption>
      <StyledOption ref={textOptionRightRef} sx={componentProps?.text?.sx}>
        {textOptionRight}
      </StyledOption>
      <Indicator
        ref={indicatorRef}
        sx={{
          borderRadius: theme.spacing(3.5),
          ...componentProps?.indicator?.sx,
        }}
      />
    </Box>
  );
};
