import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { SocialPostCommentFragmentSocialPostCommentCreatedTimeFragment } from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';

// eslint-disable-next-line
gql`
  fragment SocialPostCommentFragmentSocialPostCommentCreatedTime on SocialPostCommentModel {
    id
    platformCreateTime
  }
`;

type SocialPostCommentCreatedTimeProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentCreatedTimeFragment;
  componentProps?: {
    sx?: SxProps;
    textSx?: SxProps;
  };
};

export const SocialPostCommentCreatedTime = (
  props: SocialPostCommentCreatedTimeProps,
) => {
  const { socialPostComment, componentProps } = props;

  const getHumanFormat = useMemo(() => {
    return moment(socialPostComment.platformCreateTime).format('MMMM D, YYYY');
  }, [socialPostComment.platformCreateTime]);

  return (
    <Box sx={componentProps?.sx}>
      {/**
       * This conditional render here is to support manually parsed social post flow,
       * where we render SocialPostCommentCardView as placeholder when there's no actual social post comment yet.
       * */}
      {socialPostComment.platformCreateTime ? (
        <Typography variant="subhead-sm" sx={componentProps?.textSx}>
          {getHumanFormat}
        </Typography>
      ) : (
        <>&nbsp;</>
      )}
    </Box>
  );
};
