import {
  CollectionFragmentContentCalendarFilterByCollectionsFragment,
  ContentIdeaFieldValueFilter,
  FieldType,
} from 'graphql/generated';
import { DraftFunction } from 'use-immer';
import { ContentCalendarFilters } from './types';

/**
 * This functions converts the internal filters object of `ContentCalendarSortAndFilterButton`
 * to the format that matches `ContentIdeaFilters` in the backend.
 *
 * @param filters
 * @returns
 */
export const convertToContentCalendarFilters = (
  filters: ContentCalendarFilters,
) => {
  const fields: ContentIdeaFieldValueFilter[] = [];
  let collectionIds: string[] = [];
  let organizationIds: string[] = [];
  let socialAuthorIds: string[] = [];

  Object.keys(filters).forEach((f) => {
    if (f === 'Collections') {
      if (filters[f].collections) {
        collectionIds = filters[f].collections!.map((c) => c.id) as string[];
      }
    } else if (f === 'Organizations') {
      if (Array.isArray(filters[f].selected)) {
        organizationIds = filters[f].selected as string[];
      }
    } else if (f === 'Social Authors') {
      if (filters[f].selected) {
        socialAuthorIds = filters[f].selected as string[];
      }
    } else if (filters[f].type === FieldType.Date) {
      if (filters[f].startDate && filters[f].endDate) {
        fields.push({
          name: f,
          value: {
            date: {
              startDate: filters[f].startDate,
              endDate: filters[f].endDate,
            },
          },
        });
      }
    } else if (filters[f].selected && filters[f].selected!.length > 0) {
      fields.push({
        name: f,
        value: {
          multiSelect: filters[f].selected as string[],
        },
      });
    }
  });

  return {
    fields,
    collectionIds,
    organizationIds,
    socialAuthorIds,
  };
};

/**
 * This method updates the filters using the setSelectedFilters created by useImmer.
 * This is not to be used separately outside of this component.
 */
export const customSetSelectedFilters = (props: {
  setSelectedFilters: (
    args: {} | DraftFunction<ContentCalendarFilters>,
  ) => void;
  fieldType: FieldType;
  fieldName: string;
  value?: string;
  startDate?: string;
  endDate?: string;
  collection?: CollectionFragmentContentCalendarFilterByCollectionsFragment;
}) => {
  const {
    setSelectedFilters,
    fieldType,
    fieldName,
    value,
    startDate,
    endDate,
    collection,
  } = props;

  switch (fieldType) {
    case FieldType.Select:
    case FieldType.MultiSelect:
    case FieldType.Users: {
      setSelectedFilters((draft) => {
        if (!value) {
          return;
        }

        if (!draft[fieldName]) {
          draft[fieldName] = {
            name: fieldName,
            selected: [value],
            type: fieldType,
          };
        } else if (draft[fieldName].selected!.some((v) => v === value)) {
          draft[fieldName].selected = draft[fieldName].selected!.filter(
            (v) => v !== value,
          );

          if (draft[fieldName].selected!.length === 0) {
            delete draft[fieldName];
          }
        } else {
          draft[fieldName].selected!.push(value);
        }
      });

      break;
    }
    case FieldType.Collection: {
      setSelectedFilters((draft) => {
        if (!collection) {
          return;
        }

        if (!draft[fieldName]) {
          draft[fieldName] = {
            name: fieldName,
            collections: [collection],
            type: fieldType,
          };
        } else if (
          draft[fieldName].collections!.some((c) => c.id === collection.id)
        ) {
          draft[fieldName].collections = draft[fieldName].collections!.filter(
            (c) => c.id !== collection.id,
          );

          if (draft[fieldName].collections!.length === 0) {
            delete draft[fieldName];
          }
        } else {
          draft[fieldName].collections!.push(collection);
        }
      });

      break;
    }
    case FieldType.Date: {
      setSelectedFilters((draft) => {
        if (!draft[fieldName]) {
          draft[fieldName] = {
            name: fieldName,
            label: value,
            type: FieldType.Date,
            startDate,
            endDate,
          };
        } else if (draft[fieldName].label && draft[fieldName].label === value) {
          delete draft[fieldName];
        } else {
          draft[fieldName].label = value;
          draft[fieldName].startDate = startDate;
          draft[fieldName].endDate = endDate;
        }
      });

      break;
    }
    default: {
      break;
    }
  }
};
