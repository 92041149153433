import { gql } from '@apollo/client';
import { Box, Dialog, Typography } from '@mui/material';
import {
  CollectionBreadcrumbs,
  CollectionMoveToAnotherCollectionLayout,
  CollectionMoveToAnotherCollectionLayoutProps,
  useCheckIfCustomCollection,
} from 'features/collection';
import { PostPreview } from 'features/post/components';
import { getPostIconTypeName } from 'features/post/components/icon/utils';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentUseMoveToAnotherCollectionModalFragment,
  CollectionFragmentUseMoveToAnotherCollectionModalFragmentDoc,
  PostFragmentPostMoveToAnotherCollectionFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  useGetCollectionForPostMoveToAnotherCollectionQuery,
  useGetPostForPostMoveToAnotherCollectionQuery,
} from 'graphql/generated';
import { SyntheticEvent } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment PostFragmentPostMoveToAnotherCollection on PostModel {
    description
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment CollectionFragmentUseMoveToAnotherCollectionModal on CollectionModel {
    id
    name
    ...CollectionFragmentCollectionBreadcrumbs
  }
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

type PostMoveToAnotherCollectionProps = {
  postIds?: string[];
  collectionIds?: string[];
  currentCollectionId: string;
  isOpen: boolean;
  onClose: VoidFunction;
  onMove: (
    toCollection?: CollectionFragmentUseMoveToAnotherCollectionModalFragment,
  ) => void;
  componentsProps?: {
    layout?: Pick<CollectionMoveToAnotherCollectionLayoutProps, 'context'>;
  };
};

// eslint-disable-next-line
gql`
  query GetCollectionForPostMoveToAnotherCollection($collectionId: String!) {
    collection(id: $collectionId) {
      id
      ...CollectionFragmentUseMoveToAnotherCollectionModal
    }
  }
  ${CollectionFragmentUseMoveToAnotherCollectionModalFragmentDoc}
`;
// eslint-disable-next-line
gql`
  query GetPostForPostMoveToAnotherCollection($postId: String!) {
    post(id: $postId) {
      id
      ...PostFragmentPostMoveToAnotherCollection
    }
  }
  ${PostFragmentPostMoveToAnotherCollectionFragmentDoc}
`;

export const PostMoveToAnotherCollection = (
  props: PostMoveToAnotherCollectionProps,
) => {
  const {
    isOpen,
    onClose,
    onMove,
    postIds = [],
    collectionIds = [],
    currentCollectionId,
    componentsProps = {},
  } = props;

  const isCustomCollection = useCheckIfCustomCollection(currentCollectionId);

  const { data: collectionData } =
    useGetCollectionForPostMoveToAnotherCollectionQuery({
      variables: {
        collectionId: currentCollectionId,
      },
      skip: !currentCollectionId || isCustomCollection,
    });
  const currentCollection = collectionData?.collection;

  const { data: postData } = useGetPostForPostMoveToAnotherCollectionQuery({
    variables: {
      postId: postIds[0],
    },
    skip: postIds.length > 1,
  });
  const post = postData?.post;

  const handleClose = (e: SyntheticEvent) => {
    // Stop event propagation in case this component is rendered
    // from within another component that handles click (e.g. link)
    e?.stopPropagation();

    onClose();
  };

  const { icon, type } = getPostIconTypeName({
    url: post?.urlMetadata?.[0]?.url || undefined,
    site: post?.urlMetadata?.[0]?.metadata?.site || undefined,
    medium: post?.urlMetadata?.[0]?.metadata?.medium || undefined,
    mimeType: post?.urlMetadata?.[0]?.metadata?.type || undefined,
  });

  const getMoveTitle = () => {
    const collectionLength = collectionIds.length;
    const postLength = postIds.length;
    if (collectionLength && postLength) {
      return ` ${collectionLength} collection${
        collectionLength > 1 ? 's' : ''
      } and ${postLength} post${postLength > 1 ? 's' : ''}`;
    }
    if (collectionLength === 1) {
      return ` ${collectionData?.collection.name}`;
    }
    if (collectionLength) {
      return ` ${collectionLength} collections`;
    }
    if (postLength === 1) {
      return ':';
    }
    if (postLength) {
      return ` ${postLength} posts`;
    }
  };

  if (!postIds.length && !collectionIds.length) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: theme.spacing(6),
          border: 'none',
        },
      }}
      PaperProps={{
        // Stop event propagation in case this component is rendered
        // from within another component that handles click (e.g. link)
        onClick: (e) => e.stopPropagation(),
      }}
      disableRestoreFocus
    >
      <CollectionMoveToAnotherCollectionLayout
        onSelectDestinationCollection={(toCollection) => {
          onMove(toCollection);
        }}
        type={collectionIds.length ? 'collection' : 'post'}
        postIdsToMove={postIds}
        {...componentsProps.layout}
      >
        <Typography variant="headline-md" fontSize={theme.spacing(7)}>
          Move{getMoveTitle()}
        </Typography>
        {post && postIds.length === 1 && (
          <Box display="flex" gap={3}>
            <PostPreview
              sx={{
                width: 52,
                height: 72,
                borderRadius: theme.spacing(2),
              }}
              post={post}
            />
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                gap={1}
                sx={{
                  backgroundColor: 'rgba(35, 6, 3, 0.07)',
                  borderRadius: 100,
                  p: theme.spacing(1, 2),
                }}
              >
                {icon({ size: 16, color: theme.colors?.utility[800] })}
                <Typography
                  color={theme.colors?.utility[800]}
                  variant="body-sm"
                  fontWeight={600}
                >
                  {post.urlMetadata?.[0]?.metadata?.site ||
                    post.title ||
                    type ||
                    post.urlMetadata?.[0]?.url}
                </Typography>
              </Box>
              {post.description && (
                <Typography
                  color={theme.colors?.utility[800]}
                  variant="body-sm"
                  fontWeight={600}
                >
                  {post.description}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {currentCollection && (
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              alignItems="center"
              mt={1}
              mb={2}
            >
              <Box
                sx={{ display: 'flex', gap: 2, minWidth: theme.spacing(26.5) }}
              >
                <Typography
                  variant="body-lg"
                  fontWeight={600}
                  color={theme.colors?.utility[800]}
                >
                  Current Location
                </Typography>
              </Box>

              <Box display="flex" gap={2} flex={1}>
                <CollectionBreadcrumbs
                  collection={currentCollection}
                  ellipseAtLength={2}
                  onCollectionClick={() => {}}
                  sx={{
                    ...theme.typography['body-md'],
                    color: theme.colors?.utility[800],
                    backgroundColor: 'rgba(35, 6, 3, 0.10)',
                    p: theme.spacing(1, 4),
                    borderRadius: 4,
                    width: 'fit-content',
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </CollectionMoveToAnotherCollectionLayout>
    </Dialog>
  );
};
