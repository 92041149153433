import { useEffect, useState, useRef } from 'react';

export type UseKeyboardNavigationProps = {
  enabled: boolean;
  onEnterClick: () => void;
};

export const useKeyboardNavigation = (props: UseKeyboardNavigationProps) => {
  const { enabled = false, onEnterClick } = props;

  const itemRefs = useRef<(HTMLElement | null)[]>([]);
  const [focusedElIndex, setFocusedElIndex] = useState(-1);

  useEffect(() => {
    itemRefs.current[focusedElIndex]?.focus();
  }, [focusedElIndex, itemRefs]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
        if (focusedElIndex > 0) {
          setFocusedElIndex((prev) => prev - 1);
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();
        if (itemRefs.current[focusedElIndex + 1]) {
          setFocusedElIndex((prev) => prev + 1);
        }
      } else if (e.key === 'Enter') {
        itemRefs.current[focusedElIndex]?.click();
        onEnterClick();
      } else {
        setFocusedElIndex(-1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [focusedElIndex, itemRefs, enabled]); // eslint-disable-line

  return { focusedElIndex, setFocusedElIndex, itemRefs };
};
