import { styled } from '@mui/material';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';

export const StyledRightClickContextMenuWrapper = styled(
  RightClickContextMenuWrapper,
)(() => ({
  width: '100%',
  height: '100%',
  boxShadow:
    '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
  borderRadius: 12,
  overflow: 'hidden',
  backgroundColor: 'white',
  position: 'relative',
}));
