import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { usePostPermissionUpdatePostPermissionToCollectionPermission } from 'features/post-permission';
import {
  CollectionFragmentUpdatePostPermissionAlertViewFragment,
  useMovePostsToCollectionForUseMoveToAnotherCollectionModalMutation,
} from 'graphql/generated';
import { useCallback } from 'react';
import { evictObject } from 'utils/apollo';
import { PostMoveToAnotherCollection } from '../views/moveToAnotherCollection';

type Props = {
  postIds: string[];
  currentCollectionId: string;
  onMoved?: (destinationCollectionId: string) => void;
};

// eslint-disable-next-line
gql`
  mutation MovePostsToCollectionForUseMoveToAnotherCollectionModal(
    $data: MovePostsToCollectionInput!
  ) {
    movePostsToCollection(data: $data) {
      success
      message
    }
  }
`;
export const useMoveToAnotherCollectionModalForPost = ({
  postIds,
  currentCollectionId,
  onMoved,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [movePostToCollection] =
    useMovePostsToCollectionForUseMoveToAnotherCollectionModalMutation();

  const { onOpenUpdatePostPermissionDialog, renderUpdatePostPermissionAlert } =
    usePostPermissionUpdatePostPermissionToCollectionPermission();

  const { cache } = useApolloClient();

  const onMovePost = useCallback(
    async (
      postIds: string[],
      toCollection: CollectionFragmentUpdatePostPermissionAlertViewFragment,
    ) => {
      await movePostToCollection({
        variables: {
          data: {
            postIds,
            toCollectionId: toCollection.id,
            fromCollectionId: currentCollectionId,
          },
        },
      });

      await onOpenUpdatePostPermissionDialog(postIds, toCollection.id, () => {
        postIds.forEach((postId) => {
          evictObject(cache, postId, 'PostModel');
        });
        onMoved?.(toCollection.id);
      });
      onClose();
    },
    [onClose], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const render = useCallback(
    () =>
      currentCollectionId && (
        <>
          <PostMoveToAnotherCollection
            postIds={postIds}
            isOpen={isOpen}
            onClose={onClose}
            onMove={(toCollection) =>
              toCollection && onMovePost(postIds, toCollection)
            }
            currentCollectionId={currentCollectionId}
          />

          {renderUpdatePostPermissionAlert()}
        </>
      ),
    [
      currentCollectionId,
      postIds,
      isOpen,
      onClose,
      onMovePost,
      renderUpdatePostPermissionAlert,
    ],
  );

  return {
    renderMoveToAnotherPostModal: render,
    showMovePost: onOpen,
    hideMovePost: onClose,
  };
};
