import { Box, Skeleton } from '@mui/material';

export const ContentIdeaDetailViewSkeleton = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          mb: 8,
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            width: 100,
            height: 120,
            borderRadius: 3,
            overflow: 'hidden',
            flexShrink: 0,
          }}
        />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              borderRadius: 1,
              height: 48,
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              width: 128,
              borderRadius: 1,
              height: 48,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 4,
          flexWrap: 'wrap',
        }}
      >
        {new Array(3).fill(null).map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            sx={{
              height: 36,
              width: 96,
              borderRadius: 24,
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
        }}
      >
        {new Array(9).fill(null).map((_, i) => (
          <Box
            key={i}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 6,
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{ width: 128, height: 24, borderRadius: 1 }}
            />
            <Skeleton
              variant="rectangular"
              sx={{ flex: 1, height: 24, borderRadius: 1 }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
