import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, IconButton, SxProps, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { PostPreview } from 'features/post';
import {
  OrganizationBillingFragmentAiOrganizePaywallFragment,
  PostFragmentBillingAiOrganizePaywallFragment,
  useGetSuggestedPostsForBillingAiOrganizePaywallQuery,
} from 'graphql/generated';
import { useCallback, useState } from 'react';
import { theme } from 'styles/theme';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingAiOrganizePaywallProps = {
  organizationBilling: OrganizationBillingFragmentAiOrganizePaywallFragment;
  variant: 'banner' | 'text';
  sx?: SxProps;
};

export const BillingAiOrganizePaywall = (
  props: BillingAiOrganizePaywallProps,
) => {
  const { organizationBilling, variant, sx } = props;

  const [hideBanner, setHideBanner] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: suggestedPostData } =
    useGetSuggestedPostsForBillingAiOrganizePaywallQuery();
  const posts = suggestedPostData?.suggestedPostsForUser || [];

  const renderVariant = useCallback(() => {
    if (variant === 'banner' && !hideBanner) {
      const firstPreview: PostFragmentBillingAiOrganizePaywallFragment =
        posts[0];

      return (
        <Box sx={sx}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              background:
                'linear-gradient(146.79deg, rgba(141, 112, 163, 0.2) 1.07%, rgba(115, 148, 197, 0.2) 24.73%, rgba(111, 164, 157, 0.2) 49.37%, rgba(138, 161, 101, 0.2) 70.57%, rgba(211, 133, 67, 0.2) 99.65%) padding-box,linear-gradient(146.79deg, rgba(141, 112, 163, 0.2) 1.07%, rgba(115, 148, 197, 0.2) 24.73%, rgba(111, 164, 157, 0.2) 49.37%, rgba(138, 161, 101, 0.2) 70.57%, rgba(211, 133, 67, 0.2) 99.65%) border-box',
              padding: theme.spacing(3, 17),
              border: '2px solid transparent',
              borderRadius: theme.spacing(5),
            }}
          >
            <Box>
              <PostPreview
                post={firstPreview}
                sx={{
                  width: '71px',
                  height: '71px',
                  transform: 'rotate(-15deg)',
                  borderRadius: theme.spacing(3),
                  marginTop: theme.spacing(-8),
                }}
              />
              <IconCustomSparkles color="#936E541A" size={48} />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: theme.spacing(3),
              }}
            >
              <Typography
                variant="headline-lg"
                color={theme.colors?.utility['orange-4']}
                fontSize={20}
              >
                Dump files and let AI magically organize your work.
              </Typography>

              <Button
                variant="contained"
                sx={{
                  bgcolor: theme.colors?.primary.black,
                  color: theme.colors?.primary.white,
                  ...theme.typography['headline-sm'],
                  ':hover': {
                    bgcolor: theme.colors?.primary.black,
                  },
                }}
                onClick={onOpen}
              >
                {organizationBilling.hasUsedFreeTrial
                  ? 'Upgrade'
                  : 'Start Free Trial'}
              </Button>
            </Box>

            <Box>
              <IconCustomSparkles color="#936E541A" size={48} />
              <PostPreview
                post={firstPreview}
                sx={{
                  width: '71px',
                  height: '71px',
                  transform: 'rotate(15deg)',
                  borderRadius: theme.spacing(3),
                  marginBottom: theme.spacing(-8),
                }}
              />
            </Box>

            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
              }}
              onClick={() => setHideBanner(true)}
            >
              <IconBoldCloseCircle />
            </IconButton>
          </Box>
        </Box>
      );
    }

    return null;
  }, [posts, variant, hideBanner, organizationBilling.hasUsedFreeTrial]); // eslint-disable-line

  if (organizationBilling.aiFeaturesEnabled || posts.length === 0) {
    return null;
  }

  return (
    <>
      {renderVariant()}

      <BillingUpgradeModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
