import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { SocialPostCommentFragmentSocialPostCommentLikesFragment } from 'graphql/generated';

gql`
  fragment SocialPostCommentFragmentSocialPostCommentLikes on SocialPostCommentModel {
    id
    diggCount
  }
`;

type SocialPostCommentLikesProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentLikesFragment;
  componentProps?: {
    sx?: SxProps;
    textSx?: SxProps;
  };
};

export const SocialPostCommentLikes = (props: SocialPostCommentLikesProps) => {
  const { socialPostComment, componentProps } = props;

  return (
    <Box sx={componentProps?.sx}>
      <Typography variant="headline-xxs" sx={componentProps?.textSx}>
        {socialPostComment.diggCount} likes
      </Typography>
    </Box>
  );
};
