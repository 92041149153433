import { Box, BoxProps } from '@mui/material';
import { DndDragItemTypes } from 'features/dnd/constants';
import { ReactNode } from 'react';
import { DropTargetHookSpec, DropTargetMonitor, useDrop } from 'react-dnd';

export type DndDropItemProps<T extends { id: string }> = Omit<
  BoxProps,
  'onDrop'
> & {
  item?: T & { id: string };
  accept: DndDragItemTypes[];
  children?: ReactNode;
  canDrop?: DropTargetHookSpec<T & { id: string }, unknown, unknown>['canDrop'];
  onDrop?: (
    item: T & { id: string },
    monitor: DropTargetMonitor<T & { id: string }, unknown>,
  ) => void;
};

export const DndDropItem = <T extends { id: string }>(
  props: DndDropItemProps<T>,
) => {
  const { item, accept, children, sx, onDrop, canDrop, ...rest } = props;

  const [collected, drop] = useDrop<
    T,
    unknown,
    {
      isOver: boolean;
      canDrop: boolean;
    }
  >(() => ({
    accept,
    canDrop: (_, monitor) => {
      // Prevent an item from dropping on itself
      // This is usually the case when an item can be both drag and drop sources (e.g. collection)
      // @ts-ignore
      if (item && item.id === monitor.getItem().id) {
        return false;
      }

      return canDrop?.(_, monitor) ?? true;
    },
    drop: (item, monitor) => {
      // Do nothing if some other drop item has already handled the drop
      if (monitor.didDrop() || !monitor.isOver({ shallow: true })) {
        return;
      }

      onDrop?.(item, monitor);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <Box
      ref={drop}
      sx={{
        position: 'relative',
        cursor: collected.isOver
          ? collected.canDrop
            ? 'move'
            : 'not-allowed'
          : 'auto',
        '&:after': {
          content: '""',
          display: 'block',
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          transition: 'opacity 0.2s',
          opacity: collected.isOver ? 0.5 : 0,
          bgcolor: collected.canDrop ? '#F8E8CA' : 'transparent',
        },
        // By default, once canDrop is true, set pointerEvents to auto to ensure this component can receive events
        // This is useful when the drop target is inside a container that has `pointerEvents: none`
        ...(collected.canDrop ? { pointerEvents: 'auto' } : {}),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
