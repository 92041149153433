import { gql } from '@apollo/client';
import { Avatar, Box, Typography } from '@mui/material';
import { SocialPostCommentFragmentSocialPostCommunityCommentsFragment } from 'graphql/generated';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostCommentFragmentSocialPostCommunityComments on SocialPostCommentModel {
    id
    childCommentCount
    parentSocialPostCommentId
    diggCount
    commentCreator {
      id
      nickName
      profilePictureUrl
    }
    platformCreateTime
    text
  }
`;

type Props = {
  comments: SocialPostCommentFragmentSocialPostCommunityCommentsFragment[];
};

export const SocialPostCommunityComments = ({ comments }: Props) => {
  const renderComments = useCallback(
    (
      comments: SocialPostCommentFragmentSocialPostCommunityCommentsFragment[],
      nestedLevel = 0,
      parentCommentId?: string,
    ) => {
      const commentsAtCurrentLevel = comments.filter((c) =>
        parentCommentId
          ? c.parentSocialPostCommentId === parentCommentId
          : !c.parentSocialPostCommentId,
      );
      return commentsAtCurrentLevel.map((comment) => (
        <Box key={comment.id} mt={3}>
          <Box
            display="flex"
            gap={3}
            sx={{ marginLeft: theme.spacing(7 * nestedLevel) }}
          >
            <Avatar
              src={comment.commentCreator?.profilePictureUrl || ''}
              sx={{ width: theme.spacing(6), height: theme.spacing(6) }}
            />
            <Box display="flex" flexDirection="column" gap={2} mb={2}>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography variant="headline-sm">
                  {comment.commentCreator?.nickName}
                </Typography>
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[700]}
                >
                  {moment(comment.platformCreateTime).fromNow()}
                </Typography>
              </Box>
              <Typography variant="body-xl" color={theme.colors?.utility[900]}>
                {comment.text}
              </Typography>
              {(comment.diggCount > 0 || comment.childCommentCount > 0) && (
                <Typography
                  variant="body-md"
                  fontWeight={600}
                  color={theme.colors?.utility[800]}
                >
                  {comment.diggCount > 0 &&
                    `${comment.diggCount} ${
                      comment.diggCount === 1 ? 'like' : 'likes'
                    }`}
                  &nbsp;&nbsp;
                  {comment.childCommentCount > 0 &&
                    `${comment.childCommentCount} ${
                      comment.childCommentCount === 1 ? 'reply' : 'replies'
                    }`}
                </Typography>
              )}
            </Box>
          </Box>
          {renderComments(comments, nestedLevel + 1, comment.id)}
        </Box>
      ));
    },
    [comments],
  );

  const parentComments = useMemo(
    () => renderComments(comments, 0),
    [renderComments],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <Box display="flex" flexDirection="column">
      {parentComments}
    </Box>
  );
};
