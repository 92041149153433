import { Box, Typography } from '@mui/material';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { InstagramCreatorsDialogButton } from './sections/instagramCreatorsDialogButton';
import { TiktokCreatorsDialogButton } from './sections/tiktokCreatorsDialogButton';

type Props = {
  tiktokHandle?: string;
  instagramHandle?: string;
  topicId: string;
};

export const SocialMediaListeningManageCreatorsView = ({
  topicId,
  instagramHandle,
  tiktokHandle,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {tiktokHandle && (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography
            variant="body-md"
            display="flex"
            gap={1}
            alignItems="center"
            fontWeight={600}
          >
            <IconCustomTiktok size={12} /> TikTok
          </Typography>
          <TiktokCreatorsDialogButton
            topicId={topicId}
            tiktokHandle={tiktokHandle}
          />
        </Box>
      )}
      {instagramHandle && (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography
            variant="body-md"
            display="flex"
            gap={1}
            alignItems="center"
            fontWeight={600}
          >
            <IconCustomInstagram size={12} /> Instagram
          </Typography>

          <InstagramCreatorsDialogButton
            instagramHandle={instagramHandle}
            topicId={topicId}
          />
        </Box>
      )}
    </Box>
  );
};
