import { Box, styled } from '@mui/material';

export const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: theme.colors?.primary.black,
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  position: 'fixed',
  top: 0,
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: theme.colors?.primary.white,
  padding: theme.spacing(4, 6),
  borderBottom: `1px solid ${theme.colors?.utility[300]}`,
  transition: 'all 0.2s linear',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 2),
  },
}));
