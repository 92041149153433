import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconCustomX } from 'components/icons/components/custom/IconCustomX';
import { PostPreview } from 'features/post';
import {
  PostFragmentJuiceboxLinkSearchItemFragment,
  PostFragmentPostPreviewFragmentDoc,
  useCreateSearchHitForJuiceboxFilterSuggestionViewMutation,
} from 'graphql/generated';
import { ForwardedRef, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment PostFragmentJuiceboxLinkSearchItem on PostModel {
    id
    urlMetadata {
      id
      url
      metadata {
        title
        site
      }
    }
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

interface Props {
  link: PostFragmentJuiceboxLinkSearchItemFragment;
  query?: string;
}

export const JuiceboxLinkSearchItem = forwardRef(
  ({ link, query }: Props, ref: ForwardedRef<HTMLElement>) => {
    const urlMetadata = link.urlMetadata?.[0];
    const [createLinkSearchHit] =
      useCreateSearchHitForJuiceboxFilterSuggestionViewMutation();
    const location = useLocation();
    return (
      <Link
        to={PlotRoutes.juice(link.id)}
        state={{
          backgroundLocation: location,
        }}
      >
        <Box
          ref={ref}
          tabIndex={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            borderRadius: 3,
            padding: theme.spacing(2, 3),
            ':hover': {
              backgroundColor: theme.colors?.utility[400],
            },
            '&:focus': {
              backgroundColor: theme.colors?.utility[400],
              outline: 'none',
            },
          }}
          onClick={() => {
            createLinkSearchHit({
              variables: {
                data: {
                  post: {
                    id: link.id,
                  },
                  query,
                },
              },
            });
          }}
        >
          {urlMetadata?.url?.includes('twitter') ||
          urlMetadata?.metadata?.site?.toLowerCase() === 'twitter' ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 24,
                width: 24,
                height: 24,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: 1,
              }}
            >
              <IconCustomX
                size={16}
                style={{ color: theme.colors?.primary.black }}
              />
            </Box>
          ) : (
            <PostPreview
              post={link}
              sx={{
                height: 36,
                width: 24,
                borderRadius: 1,
                flexShrink: 0,
              }}
            />
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              variant="subhead-lg"
              color={theme.colors?.primary.black}
              sx={{
                ' -webkit-line-clamp': 1,
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                textAlign: 'left',
              }}
            >
              {urlMetadata?.metadata?.['title'] || urlMetadata?.url}
            </Typography>
            <Typography variant="subhead-sm" color={theme.colors?.utility[800]}>
              {urlMetadata.metadata?.site ||
                (urlMetadata?.url &&
                  new URL(urlMetadata.url)?.hostname.replace('www.', '')) ||
                'Link'}
            </Typography>
          </Box>
        </Box>
      </Link>
    );
  },
);
