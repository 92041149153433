import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { AvatarGroup } from 'components/common/AvatarGroup';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import {
  CollectionInviteType,
  ContentIdeaFragmentContentIdeaInviteAssigneeUserModalFragmentDoc,
  ContentIdeaFragmentContentIdeaOwnersFragment,
  ContentIdeaPermission,
  FieldType,
  GeneralPermission,
  NestedEntitiesMode,
  PermissionLevel,
  useInviteMembersForContentIdeaOwnersMutation,
  useUpdateContentIdeaFieldValueForContentIdeaOwnersMutation,
  useUpdateNestedCollectionPostPermissionsForContentIdeaOwnersMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { UsersFieldProps } from '../field';
import { ContentIdeaField } from '../field/ContentIdeaField';
import { ContentIdeaInviteAssigneeUserModal } from '../inviteAssigneeUserModal';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_OWNERS = gql`
  fragment ContentIdeaFragmentContentIdeaOwners on ContentIdeaModel {
    id
    myPermissions
    collection {
      id
      creatorId
      generalPermission
      inviteMembers {
        id
        user {
          id
        }
      }
    }
    owners {
      id
      value {
        id
        users {
          id
          ...UserFragmentAvatarGroup
        }
      }
      contentIdeaField {
        id
      }
    }
    ...ContentIdeaFragmentContentIdeaInviteAssigneeUserModal
  }
  ${ContentIdeaFragmentContentIdeaInviteAssigneeUserModalFragmentDoc}
`;

//eslint-disable-next-line
gql`
  mutation InviteMembersForContentIdeaOwners(
    $data: InviteCollectionMembersInput!
  ) {
    inviteCollectionMembers(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateNestedCollectionPostPermissionsForContentIdeaOwners(
    $data: UpdateNestedCollectionPostPermissionsInput!
  ) {
    updateNestedCollectionPostPermissions(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaFieldValueForContentIdeaOwners(
    $data: UpdateContentIdeaFieldValueInput!
  ) {
    updateContentIdeaFieldValue(data: $data) {
      id
      value {
        id
        users {
          id
        }
      }
    }
  }
`;

export type ContentIdeaOwnersProps = {
  contentIdea: ContentIdeaFragmentContentIdeaOwnersFragment;
  renderTrigger?: UsersFieldProps['renderTrigger'];
};

export const ContentIdeaOwners = (props: ContentIdeaOwnersProps) => {
  const { contentIdea, renderTrigger } = props;
  const owners = contentIdea.owners?.value?.users || [];
  const { user } = useUserContext();

  const inviteModal = useDisclosure();

  const [assignedUserIds, setAssignedUserIds] = useState<string[]>([]);
  const [invitedUserIds, setInvitedUserIds] = useState<string[]>([]);

  const [updateContentIdeaFieldValue] =
    useUpdateContentIdeaFieldValueForContentIdeaOwnersMutation();
  const [inviteMembersToCollection] =
    useInviteMembersForContentIdeaOwnersMutation();
  const [updateNestedCollectionPostPermissions] =
    useUpdateNestedCollectionPostPermissionsForContentIdeaOwnersMutation();

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  const onUpdateFieldValue = async (
    selectedAssigneeIds: string[],
    canShowModal = false,
  ) => {
    setAssignedUserIds(selectedAssigneeIds);

    // This is the list of members that are already invited to the collection
    const inviteMembers = contentIdea.collection.inviteMembers;

    // Removed the assignee user IDs and creator that are already in the collection
    const filteredAssigneeIds: string[] = selectedAssigneeIds.filter(
      (assigneeId) =>
        !inviteMembers.some((member) => member.user.id === assigneeId) &&
        contentIdea.collection.creatorId !== assigneeId,
    );

    setInvitedUserIds(filteredAssigneeIds);

    // If there are any users that are not invited to the collection and it's a private collection, then open the invite modal.
    if (
      filteredAssigneeIds.length &&
      contentIdea.collection.generalPermission ===
        GeneralPermission.InviteOnly &&
      canShowModal
    ) {
      inviteModal.onOpen();
    } else {
      const users = [
        ...(user?.organization?.users || []),
        ...(user?.organization.externalUsers || []),
      ];
      // Otherwise, invite the users to the collection implicitly.
      if (filteredAssigneeIds.length) {
        await inviteMembersToCollection({
          variables: {
            data: {
              emailInvites: [],
              memberInvites: filteredAssigneeIds.map((userId) => ({
                userId,
                collectionId: contentIdea.collection.id,
                permission: PermissionLevel.Full,
              })),
              inviteType: CollectionInviteType.ContentIdeaAssigned,
            },
          },
          update: (cache, { data }) => {
            if (!data?.inviteCollectionMembers.success) return;

            modifyObject(cache, contentIdea.collection.id, 'CollectionModel', {
              inviteMembers: (members) => {
                return [
                  ...members,
                  ...filteredAssigneeIds.map((userId) => ({
                    __typename: 'CollectionInviteMemberModel',
                    id: GenerateId.create(),
                    user: users.find((user) => user.id === userId),
                    permissionLevel: PermissionLevel.Full,
                  })),
                ];
              },
            });
          },
        });

        const filteredAssigneeEmails: string[] = [];

        // eslint-disable-next-line
        filteredAssigneeIds.map((userId) => {
          const user = users.find((user) => user.id === userId);
          if (user) {
            filteredAssigneeEmails.push(user.email);
          }
        });

        await updateNestedCollectionPostPermissions({
          variables: {
            data: {
              collectionId: contentIdea.collection.id,
              permissions: {
                inviteMembers: {
                  emailInvites: filteredAssigneeEmails.map((email) => ({
                    email,
                    permission: PermissionLevel.Full,
                  })),
                },
                generalPermission: {},
                removeMemberIds: [],
              },
              nestedEntities: [
                {
                  excludedChildCollectionIds: [],
                  excludedPostIds: [],
                  mode: NestedEntitiesMode.Select,
                  collectionId: contentIdea.collection.id,
                  nestedEntities: [],
                },
              ],
            },
          },
        });
      }
      // If all the users are already invited to the collection, update the content idea field value
      updateContentIdeaFieldValue({
        variables: {
          data: {
            contentIdeaFieldValueId: contentIdea.owners.id,
            data: {
              value: {
                userIds: selectedAssigneeIds,
              },
            },
          },
        },
        optimisticResponse: {
          updateContentIdeaFieldValue: {
            id: contentIdea.owners.id,
            value: {
              users: selectedAssigneeIds.map((t) => ({ id: t })),
            },
            __typename: 'ContentIdeaFieldValueModel',
          },
        },
      });
    }
  };

  return (
    <>
      <ContentIdeaField
        id={contentIdea.owners.contentIdeaField.id}
        valueId={contentIdea.owners.id}
        value={{ userIds: (owners || []).map((o) => o.id) }}
        type={FieldType.Users}
        readOnly={!canEdit}
        renderTrigger={
          renderTrigger ||
          (({ isOpen }) => {
            if (owners.length === 0) {
              return (
                <IconBoldUser
                  size={16}
                  color={
                    isOpen
                      ? theme.colors?.primary.black
                      : theme.colors?.utility[500]
                  }
                />
              );
            }

            return <AvatarGroup users={owners || []} avatarSize={16} max={2} />;
          })
        }
        onUpdateFieldValue={(data) => {
          const selectedAssigneeIds = data.value.userIds || [];
          onUpdateFieldValue(selectedAssigneeIds, true);
        }}
      />
      {inviteModal.isOpen && (
        <ContentIdeaInviteAssigneeUserModal
          onClose={inviteModal.onClose}
          contentIdea={contentIdea}
          invitedUserIds={invitedUserIds}
          onContinue={() => {
            onUpdateFieldValue(assignedUserIds);
            inviteModal.onClose();
          }}
        />
      )}
    </>
  );
};
