import { gql, useApolloClient } from '@apollo/client';
import {
  useAddPostToCollectionForPostBookmarkPopoverMutation,
  useCreatePostFavoriteForPostBookmarkButtonMutation,
  useDeletePostFavoriteForPostBookmarkButtonMutation,
  useRemovePostFromCollectionForPostBookmarkPopoverMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreatePostFavoriteForPostBookmarkButton(
    $data: CreatePostFavoriteInputV2!
  ) {
    createPostFavoriteV2(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeletePostFavoriteForPostBookmarkButton(
    $data: DeletePostFavoriteInputV2!
  ) {
    deletePostFavoriteV2(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation AddPostToCollectionForPostBookmarkPopover(
    $data: AddPostToCollectionInput!
  ) {
    addPostToCollection(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation RemovePostFromCollectionForPostBookmarkPopover(
    $data: RemovePostFromCollectionInput!
  ) {
    removePostFromCollection(data: $data) {
      success
      message
    }
  }
`;

export const usePostHandlers = () => {
  const client = useApolloClient();

  const [createPostFavorite] =
    useCreatePostFavoriteForPostBookmarkButtonMutation();
  const [deletePostFavorite] =
    useDeletePostFavoriteForPostBookmarkButtonMutation();
  const [addPostToCollection] =
    useAddPostToCollectionForPostBookmarkPopoverMutation();
  const [removePostFromCollection] =
    useRemovePostFromCollectionForPostBookmarkPopoverMutation();

  const onTogglePostFavorite = (postId: string, isFavorite: boolean) => {
    if (isFavorite) {
      createPostFavorite({
        variables: {
          data: {
            postId,
          },
        },
      });
    } else {
      deletePostFavorite({
        variables: {
          data: {
            postId,
          },
        },
      });
    }

    modifyObject(client.cache, postId, 'PostModel', {
      isFavorite: () => isFavorite,
    });
  };

  const onTogglePostCollection = async (
    postId: string,
    collectionId: string,
    willAdd: boolean,
  ) => {
    if (willAdd) {
      await addPostToCollection({
        variables: {
          data: {
            postId,
            collectionId,
          },
        },
      });
    } else {
      await removePostFromCollection({
        variables: {
          data: {
            postId,
            collectionId,
          },
        },
      });
    }
  };

  return {
    onTogglePostFavorite,
    onTogglePostCollection,
  };
};
