import { Link } from 'react-router-dom';
import { StyledBreadCrumb } from './styles';
import { Props } from './types';

export const Breadcrumb = (props: Props) => {
  const { items, onNavigate, ...rest } = props;

  return (
    <StyledBreadCrumb aria-label="breadcrumb" {...rest}>
      {items.map((item, index) => {
        if (item.component) {
          return item.component;
        }

        return (
          <Link
            key={index}
            to={item?.href || ''}
            state={item?.state}
            {...(onNavigate
              ? {
                  onClick: (e) => {
                    e.preventDefault();
                    onNavigate(item, index);
                  },
                }
              : {})}
          >
            {item.label}
          </Link>
        );
      })}
    </StyledBreadCrumb>
  );
};
