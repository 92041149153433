import { createClient } from '@liveblocks/client';
import { createRoomContext } from '@liveblocks/react';
import { Cookies } from 'react-cookie';

const client = createClient({
  authEndpoint: async (roomId) => {
    const token = new Cookies().get('token');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    const body = JSON.stringify({
      roomId,
    });

    const response = await fetch(
      `${process.env.REACT_APP_PLOT_BACKEND_ENDPOINT}/liveblocks/auth`,
      {
        method: 'POST',
        headers,
        body,
      },
    );

    return response.json();
  },
  throttle: 16, //16ms. This is equivalent to a 60fps animation. This allows us to have smoother rendering
});

export type Presence = {
  cursor: { x: number; y: number } | null;
};

export type Storage = {};

export type UserInfo = {
  id: string;
  firstName: string;
  lastName: string;
  color: string;
};
export type UserMeta = {
  id: string;
  info: UserInfo;
};

export type RoomEvent = {};

export const {
  suspense: {
    RoomProvider,
    useRoom,
    useMyPresence,
    useUpdateMyPresence,
    useSelf,
    useOthers,
    useOthersMapped,
    useOthersConnectionIds,
    useOther,
    useBroadcastEvent,
    useEventListener,
    useErrorListener,
    useStorage,
    useObject,
    useMap,
    useList,
    useBatch,
    useHistory,
    useUndo,
    useRedo,
    useCanUndo,
    useCanRedo,
    useMutation,
    useLostConnectionListener,
  },
} = createRoomContext<Presence, Storage, UserMeta, RoomEvent>(client);
