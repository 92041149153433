/**
 * This component is used to wrap a component that needs to have a right click context menu.
 *
 * NOTE: It ASSUMES that we are rendering the menu with MuiMenu.
 */

import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, BoxProps, MenuProps } from '@mui/material';
import { forwardRef, useState } from 'react';

export type RightClickContextMenuWrapperProps = BoxProps & {
  disabled?: boolean;
  renderMenu?: (
    props: Partial<
      Pick<
        MenuProps,
        | 'onClose'
        | 'anchorPosition'
        | 'open'
        | 'anchorEl'
        | 'anchorReference'
        | 'anchorOrigin'
        | 'transformOrigin'
        | 'onMouseDown'
        | 'onMouseMove'
      >
    >,
  ) => React.ReactNode;
};

export const RightClickContextMenuWrapper = forwardRef(
  (props: RightClickContextMenuWrapperProps, ref) => {
    const { children, disabled, renderMenu, ...rest } = props;

    const [anchorPosition, setAnchorPosition] = useState<{
      top: number;
      left: number;
    }>();

    const {
      isOpen: isMenuOpen,
      onOpen: openMenu,
      onClose: closeMenu,
    } = useDisclosure();

    return (
      <Box
        ref={ref}
        {...rest}
        onContextMenu={
          !disabled && Boolean(renderMenu)
            ? (e) => {
                // Ignore this event if ref cannot find e.target.
                // This mostly happens when we are rendering portals (with MUI popover & stuff) from children of this component.
                if (!e.currentTarget?.contains(e.target as Node)) {
                  return;
                }

                e.preventDefault();
                e.stopPropagation();

                closeMenu();
                setTimeout(() => {
                  openMenu();
                  setAnchorPosition({ top: e.clientY, left: e.clientX });
                });

                return false;
              }
            : undefined
        }
        // Prevent right click mouse down events from propagating
        onMouseDownCapture={
          !disabled
            ? (e) => {
                if (e.button === 2) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }
            : undefined
        }
      >
        {children}
        {isMenuOpen &&
          renderMenu &&
          renderMenu({
            open: true,
            anchorEl: undefined,
            anchorPosition,
            anchorReference: 'anchorPosition',
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            onClose: closeMenu,
            // Prevent mouse events from bubbling up when the context menu is open
            // as it might mess with other components. This is definitely not ideal,
            // but it should work for now.
            // NOTE: See `CollectionPostDndPostCard` for an example of this.
            onMouseDown: (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
            onMouseMove: (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
          })}
      </Box>
    );
  },
);
