import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Location, Route, Routes, useLocation } from 'react-router-dom';

// Lazyload all the pages
const ContentIdeaPage = lazy(() =>
  import('pages/contentIdeas/detail').then((module) => ({
    default: module.ContentIdeaPage,
  })),
);
const JuiceModal = lazy(() =>
  import('pages/juicebox/detail').then((module) => ({
    default: module.JuiceModal,
  })),
);
const PostPreviewPage = lazy(() =>
  import('pages/juicebox/detail/preview').then((module) => ({
    default: module.PostPreviewPage,
  })),
);
const JuiceboxNoteModal = lazy(() =>
  import('pages/juicebox/note/detail').then((module) => ({
    default: module.JuiceboxNoteModal,
  })),
);
const SocialMediaListeningAnalyticsOutboundLeaderboardDetailPage = lazy(() =>
  import(
    'pages/socialMediaListening/analytics/outbound/leaderboard/detail/SocialMediaListeningAnalyticsOutboundLeaderboardDetailPage'
  ).then((module) => ({
    default: module.SocialMediaListeningAnalyticsOutboundLeaderboardDetailPage,
  })),
);
const TaskDetailPage = lazy(() =>
  import('pages/tasks/detail/TaskDetailPage').then((module) => ({
    default: module.TaskDetailPage,
  })),
);
const SocialMediaListeningAnalyticsOutboundInteractionPage = lazy(() =>
  import('pages/socialMediaListening/analytics/outbound/interaction').then(
    (module) => ({
      default: module.SocialMediaListeningAnalyticsOutboundInteractionPage,
    }),
  ),
);
const AttachmentDetailPage = lazy(() =>
  import('pages/attachments/detail').then((module) => ({
    default: module.AttachmentDetailPage,
  })),
);

/**
 * These routes only render when we have backgroundLocation.
 * If we have secondaryLocation, we use it for the location object.
 */
export const Layer2RouteView = () => {
  const location = useLocation();

  const { secondaryLocation, backgroundLocation } =
    (location.state as {
      backgroundLocation?: Location;
      secondaryLocation?: Location;
    }) || {};

  if (!backgroundLocation) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Suspense>
        <Routes location={secondaryLocation || location}>
          <Route path="/tasks/:taskId" element={<TaskDetailPage />} />

          {/* Creative Juicebox */}
          <Route path="/juicebox">
            <Route path="note/:id" element={<JuiceboxNoteModal />} />
            <Route path=":id" element={<JuiceModal />} />
            <Route path=":id/preview" element={<PostPreviewPage />} />
          </Route>

          {/* Content Calendar */}
          <Route path="/content-ideas/:id" element={<ContentIdeaPage />} />

          <Route
            path="/social-listening/brands/:brandId/analytics/outbound/leaderboard/:id"
            element={
              <SocialMediaListeningAnalyticsOutboundLeaderboardDetailPage />
            }
          />

          <Route
            path="/social-listening/brands/:brandId/analytics/outbound/interactions"
            element={<SocialMediaListeningAnalyticsOutboundInteractionPage />}
          />

          {/* Attachment */}
          <Route path="/attachments/:id" element={<AttachmentDetailPage />} />
        </Routes>
      </Suspense>
    </Box>
  );
};
