import { gql } from '@apollo/client';
import {
  LinkedPostFragmentTrendLinkPostsFragment,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const LINKED_POST_FRAGMENT_TREND_LINK_POSTS = gql`
  fragment LinkedPostFragmentTrendLinkPosts on PostModel {
    id
    type
    createdAt
    isFavorite
    isFavoriteTrend
    myPermissions
    trend {
      id
      title
    }
    urlMetadata {
      createdAt
      id
      metadata {
        audio
        author
        description
        hashtags
        medium
        site
        thumbnail {
          href
          mediaSize {
            height
            width
          }
          status
          token
          type
        }
        title
        type
      }
      updatedAt
      url
    }
    creator {
      ...UserFragmentAvatarGroup
    }
    collections {
      id
      name
      contentIdeaId
      parentCollection {
        contentIdeaId
        id
        name
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type LinkedPostsProps = {
  minimalView?: boolean;
  linkedPosts: LinkedPostFragmentTrendLinkPostsFragment[];
};
