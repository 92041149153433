import {
  Box,
  Checkbox,
  MenuItem,
  MenuItemProps,
  Typography,
} from '@mui/material';
import { TooltipForTruncatedText } from 'components/common/TooltipForTruncatedText';
import { IconBoldTickSquare } from 'components/icons/components/bold/IconBoldTickSquare';
import { ReactNode, forwardRef } from 'react';
import { theme } from 'styles/theme/theme';

type CheckboxMenuItemProps = MenuItemProps & {
  value: string | ReadonlyArray<string> | number | undefined;
  label: ReactNode;
  description?: string;
  checked?: boolean;
  checkboxPosition?: 'start' | 'end';
  renderCheckbox?: (checked?: boolean) => ReactNode;
  // if true, click works only on the checkbox, not on the whole item
  isCheckboxExclusiveClick?: boolean;
};

export const CheckboxMenuItem = forwardRef<
  HTMLLIElement,
  CheckboxMenuItemProps
>((props: CheckboxMenuItemProps, ref) => {
  const {
    value,
    checked,
    label,
    description,
    checkboxPosition = 'start',
    renderCheckbox,
    onClick,
    isCheckboxExclusiveClick = true,
    ...rest
  } = props;

  const renderCheckboxButton = (checked?: boolean) => {
    return renderCheckbox ? (
      renderCheckbox(checked)
    ) : (
      <Checkbox
        size="small"
        checked={checked}
        {...(isCheckboxExclusiveClick
          ? { onClick: (e: any) => onClick?.(e) }
          : {})}
        sx={{
          p: 0,
        }}
        checkedIcon={
          <IconBoldTickSquare size={20} color={theme.colors?.primary.black} />
        }
      />
    );
  };

  return (
    <MenuItem
      ref={ref}
      value={value}
      {...(!isCheckboxExclusiveClick ? { onClick } : {})}
      {...rest}
      sx={{
        width: '100%',
        borderRadius: theme.spacing(3),
        padding: theme.spacing(2, 3),
        overflow: 'hidden',
        ...rest.sx,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={
          checkboxPosition === 'start' ? 'flex-start' : 'space-between'
        }
        width="100%"
        gap={2}
      >
        {checkboxPosition === 'start' && renderCheckboxButton(checked)}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          {typeof label === 'string' ? (
            <TooltipForTruncatedText title={label}>
              <Typography
                variant="headline-xs"
                color={theme.colors?.utility[900]}
              >
                {label}
              </Typography>
            </TooltipForTruncatedText>
          ) : (
            label
          )}

          {description && (
            <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
              {description}
            </Typography>
          )}
        </Box>
        {checkboxPosition === 'end' && renderCheckboxButton(checked)}
      </Box>
    </MenuItem>
  );
});
