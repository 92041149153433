import { Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

type Props = {
  brandId: string;
};

export const SLATopicZeroState = ({ brandId }: Props) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{ width: '100%', height: '50vh' }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={4}
    >
      <Typography
        variant="headline-md"
        fontSize={theme.spacing(5)}
        color={theme.colors?.utility[700]}
        textAlign="center"
      >
        No topics available for
        <br />
        this brand
      </Typography>
      <ContextMenu
        renderButton={() => (
          <Typography
            variant="headline-md"
            display="flex"
            alignItems="center"
            gap={2}
            color={theme.colors?.primary[500]}
            sx={{
              cursor: 'pointer',
              backgroundColor: theme.colors?.utility[250],
              borderRadius: theme.spacing(1),
              padding: theme.spacing(1, 2),
            }}
          >
            <IconLinearAdd />
            Create a new topic
          </Typography>
        )}
        options={[
          {
            renderOption: () => (
              <Box display="flex" gap={1} flexDirection="column">
                <Typography variant="body-lg">Topic Insights</Typography>
                <Typography
                  variant="body-sm"
                  color={theme.colors?.utility[700]}
                >
                  Track conversations across customized <br /> topics
                </Typography>
              </Box>
            ),
            onClick: () =>
              navigate(PlotRoutes.socialListeningOnboarding('topic', brandId)),
          },
          {
            renderOption: () => (
              <Box display="flex" gap={1} flexDirection="column">
                <Typography variant="body-lg">Creator Tracking </Typography>
                <Typography
                  variant="body-sm"
                  color={theme.colors?.utility[700]}
                >
                  Track specific creators discussing
                  <br /> your key topics
                </Typography>
              </Box>
            ),
            onClick: () =>
              navigate(
                PlotRoutes.socialListeningOnboarding('creator', brandId),
              ),
          },
        ]}
      />
    </Box>
  );
};
