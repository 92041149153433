import { Extension, textInputRule } from '@tiptap/react';
import { EXTENSION_INPUT_TRANSFORMATION } from '../constants';

/**
 * This extension handles input transformation. Such as:
 * - "->" to "→"
 * - "<-" to "←"
 * ...
 */
export const InputTransformation = Extension.create({
  name: EXTENSION_INPUT_TRANSFORMATION,

  addInputRules() {
    return [
      // Replace "->" with "→"
      textInputRule({
        find: /->/g,
        replace: '→',
      }),
      // Replace "<-" with "←"
      textInputRule({
        find: /<-/g,
        replace: '←',
      }),
      // Replace "=>" with "⇒"
      textInputRule({
        find: /=>/g,
        replace: '⇒',
      }),
      // Replace "<=" with "⇐"
      textInputRule({
        find: /<=/g,
        replace: '⇐',
      }),
      // Replace "(tm)" with "™"
      textInputRule({
        find: /\(tm\)/g,
        replace: '™',
      }),
      // Replace "(r)" with "®"
      textInputRule({
        find: /\(r\)/g,
        replace: '®',
      }),
      // Replace "(c)" with "©"
      textInputRule({
        find: /\(c\)/g,
        replace: '©',
      }),
    ];
  },
});
