import { useUserContext } from 'contexts/users/User.context';
import { SocialMediaListeningIntroPage } from 'pages/socialMediaListening/intro';
import { Outlet } from 'react-router-dom';

export const SocialMediaListeningAuthLayout = () => {
  const { orgBilling, user } = useUserContext();

  if (
    !orgBilling?.socialListeningEnabled &&
    user?.socialListeningBrands.length === 0
  ) {
    return <SocialMediaListeningIntroPage />;
  }

  return <Outlet />;
};
