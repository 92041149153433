import { Box } from '@mui/material';
import { lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// Lazyload all the pages
const PostPreviewPage = lazy(() =>
  import('pages/juicebox/detail/preview').then((module) => ({
    default: module.PostPreviewPage,
  })),
);
const TaskDetailPage = lazy(() =>
  import('pages/tasks/detail/TaskDetailPage').then((module) => ({
    default: module.TaskDetailPage,
  })),
);
const AttachmentDetailPage = lazy(() =>
  import('pages/attachments/detail').then((module) => ({
    default: module.AttachmentDetailPage,
  })),
);

/**
 * These routes are only rendered when we have backgroundLocation and secondaryLocation.
 */
export const Layer3RouteView = () => {
  const location = useLocation();
  const { backgroundLocation, secondaryLocation } =
    (location.state as {
      backgroundLocation?: Location;
      secondaryLocation?: Location;
    }) || {};

  if (!backgroundLocation || !secondaryLocation) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 2,
      }}
    >
      <Suspense>
        <Routes location={location}>
          <Route path="/tasks/:taskId" element={<TaskDetailPage />} />
          <Route path="/juicebox/:id/preview" element={<PostPreviewPage />} />
          <Route path="/attachments/:id" element={<AttachmentDetailPage />} />
        </Routes>
      </Suspense>
    </Box>
  );
};
