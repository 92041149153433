import { Box, styled } from '@mui/material';

export const StyledAuthNavContainer = styled(Box)(({ theme }) => ({
  '& .nav-link': {
    textDecoration: 'none',
    fontWeight: 600,
    color: `rgba(250, 243, 236, 0.50)`,
  },
  '& .nav-link.active': {
    color: theme.colors?.primary.parchment,
    paddingBottom: theme.spacing(2),
    borderBottom: `2px solid ${theme.colors?.primary.parchment}`,
  },
}));
