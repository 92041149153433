import { COMMAND_KEYS, COMMAND_TYPE } from '../../constants';
import { Command } from '../../types';
import { PostAfterCreationHandler } from './components/PostAfterCreationHandler';
import { PostDownloadHandler } from './components/PostDownloadHandler';
import { PostPasteALinkHandler } from './components/PostPasteALinkHandler';
import { PostSelectFromCJBHandler } from './components/PostSelectFromCJBHandler';
import { PostUploadFilesHandler } from './components/PostUploadFilesHandler';
import { PostWriteANoteHandler } from './components/PostWriteANoteHandler';

export const usePostCommands = (): Command[] => {
  return [
    {
      type: COMMAND_TYPE.POST_WRITE_A_NOTE,
      keys: COMMAND_KEYS[COMMAND_TYPE.POST_WRITE_A_NOTE],
      Handler: PostWriteANoteHandler as any,
    },
    {
      type: COMMAND_TYPE.POST_PASTE_A_LINK,
      keys: COMMAND_KEYS[COMMAND_TYPE.POST_PASTE_A_LINK],
      Handler: PostPasteALinkHandler as any,
    },
    {
      type: COMMAND_TYPE.POST_UPLOAD_FILES,
      keys: COMMAND_KEYS[COMMAND_TYPE.POST_UPLOAD_FILES],
      Handler: PostUploadFilesHandler as any,
    },
    {
      type: COMMAND_TYPE.POST_AFTER_CREATION,
      keys: COMMAND_KEYS[COMMAND_TYPE.POST_AFTER_CREATION],
      Handler: PostAfterCreationHandler as any,
    },
    {
      type: COMMAND_TYPE.POST_DOWNLOAD,
      keys: COMMAND_KEYS[COMMAND_TYPE.POST_DOWNLOAD],
      Handler: PostDownloadHandler as any,
    },
    {
      type: COMMAND_TYPE.POST_SELECT_FROM_CJB,
      keys: COMMAND_KEYS[COMMAND_TYPE.POST_SELECT_FROM_CJB],
      Handler: PostSelectFromCJBHandler as any,
    },
  ];
};
