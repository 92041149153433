import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconBoldFlash } from 'components/icons/components/bold/IconBoldFlash';
import { IconBoldHashtag } from 'components/icons/components/bold/IconBoldHashtag';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import {
  InternalOrganizationRole,
  useDowngradeToFreePlanForNewPricingMutation,
} from 'graphql/generated';
import { gql } from '@apollo/client';
import { useMemo, useState } from 'react';
import { BillingNewPricingStarterPlanConfirmation } from './BillingNewPricingStarterPlanConfirmation';
import { BillingNewPricingNotifyAdmin } from './BillingNewPricingNotifyAdmin';

// eslint-disable-next-line
gql`
  mutation DowngradeToFreePlanForNewPricing {
    downgradeToFreePlan {
      id
    }
  }
`;

type BillingNewPricingStarterDetailsProps = {
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  onOpenPlanOptions: VoidFunction;
};

export const BillingNewPricingStarterDetails = (
  props: BillingNewPricingStarterDetailsProps,
) => {
  const { isOpen, onOpen, onClose, onOpenPlanOptions } = props;

  const { user } = useUserContext();

  const isAdmin = useMemo(() => {
    return user?.role === InternalOrganizationRole.Admin;
  }, [user?.role]);

  const { isOpen: starterPlanSuccessOpen, onOpen: starterPlanSuccessOnOpen } =
    useDisclosure();

  const {
    isOpen: notifyAdminOpen,
    onClose: notifyAdminOnClose,
    onOpen: notifyAdminOnOpen,
  } = useDisclosure();

  const [downgradeToFreePlan] = useDowngradeToFreePlanForNewPricingMutation();
  const [downgradeLoading, setDowngradeLoading] = useState(false);

  const handleDowngradeToFreePlan = async () => {
    setDowngradeLoading(true);
    await downgradeToFreePlan();
    setDowngradeLoading(false);

    onClose();
    starterPlanSuccessOnOpen();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            width: '375px',
            padding: theme.spacing(8),
            bgcolor: theme.colors?.primary.white,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(6),
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          }}
        >
          <Typography variant="headline-xl" fontSize={24} fontWeight={500}>
            Are you sure you want to continue with the Starter Free?
          </Typography>
          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            Starter Free is intended for personal use. Your organization will be
            updated with the following changes if you choose to continue:
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(4),
          }}
        >
          <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
            <IconBoldHashtag size={16} style={{ marginTop: '3px' }} />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
              }}
            >
              <Typography
                variant="headline-sm"
                color={theme.colors?.primary.black}
              >
                No Collections
              </Typography>

              <Typography
                variant="subhead-md"
                sx={{ color: theme.colors?.utility[800] }}
              >
                Your existing collections will now be view-only.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
            <IconBoldBuildings size={16} style={{ marginTop: '3px' }} />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
              }}
            >
              <Typography
                variant="headline-sm"
                color={theme.colors?.primary.black}
              >
                No Organization Members
              </Typography>

              <Typography
                variant="subhead-md"
                sx={{ color: theme.colors?.utility[800] }}
              >
                Your current organization members will be disabled.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
            <IconBoldFlash size={16} style={{ marginTop: '3px' }} />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
              }}
            >
              <Typography
                variant="headline-sm"
                color={theme.colors?.primary.black}
              >
                No Trends
              </Typography>

              <Typography
                variant="subhead-md"
                sx={{ color: theme.colors?.utility[800] }}
              >
                Trends will be available in the upgraded plan.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          {isAdmin ? (
            <Button
              variant="contained"
              disabled={downgradeLoading}
              fullWidth
              sx={{
                ...theme.typography['headline-sm'],
                bgcolor: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                color: theme.colors?.primary.white,
                ':hover': { bgcolor: theme.colors?.primary.black },
              }}
              onClick={handleDowngradeToFreePlan}
            >
              {downgradeLoading ? (
                <CircularProgress size={16} />
              ) : (
                'Yes, continue with Starter Free.'
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              sx={{
                ...theme.typography['headline-sm'],
                bgcolor: theme.colors?.primary.black,
                borderRadius: theme.spacing(2),
                color: theme.colors?.primary.white,
                ':hover': { bgcolor: theme.colors?.primary.black },
              }}
              onClick={notifyAdminOnOpen}
            >
              Notify Admin
            </Button>
          )}

          <Button
            disabled={downgradeLoading}
            fullWidth
            sx={{
              textDecoration: 'underline',
              ':hover': { textDecoration: 'underline' },
            }}
            onClick={onOpenPlanOptions}
          >
            Explore Plans
          </Button>
        </Box>
      </Dialog>

      {isAdmin ? (
        <BillingNewPricingStarterPlanConfirmation
          isOpen={starterPlanSuccessOpen}
        />
      ) : (
        <BillingNewPricingNotifyAdmin
          isOpen={notifyAdminOpen}
          onClose={notifyAdminOnClose}
        />
      )}
    </>
  );
};
