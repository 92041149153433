import { Box, Button, CircularProgress } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { useUpdateUserProfileForProfileTabMutation } from 'graphql/generated';
import { useUploadCare } from 'hooks/useUploadCare';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { ImageEditor } from '../CoverUploader/ImageEditor';
import { useLoadImageLocally } from '../CoverUploader/hooks/useLoadImageLocally';
import { CoverType, CoverUploaderRefProps } from '../CoverUploader/types';
import { toast } from '../Toast';
import { StyledDialog, StyledTitle } from './styles';
import { AvatarUploadProps } from './types';

export const AvatarUpload = ({
  isOpen,
  onClose,
  uploadedImage,
  setUploadedImage,
}: AvatarUploadProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [willWaitForCanvasSubmit, setWillWaitForCanvasSubmit] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const uploaderRef = useRef<CoverUploaderRefProps | null>(null);

  const { user } = useUserContext();
  const uploadFile = useUploadCare();
  const [updateUserProfile] = useUpdateUserProfileForProfileTabMutation();
  useLoadImageLocally({
    data: {
      type: CoverType.UPLOAD,
      data: uploadedImage,
    },
    onLoad: setImageUrl,
  });

  const onSave = async () => {
    try {
      if (!uploadFile || !user || !uploadedImage) {
        throw new Error('Unknown error occurred. Please try again later.');
      }
      // If CoverUploader is still in editing mode
      if (uploaderRef.current && uploaderRef.current.isEditing) {
        setIsSubmitting(true);
        // Manually trigger submission. This should set value to fileToUpload on success
        await uploaderRef.current.onSubmit();
        setWillWaitForCanvasSubmit(true);
      }
    } catch (err) {
      toast({
        message: 'Unknown error occurred. Please try again later.',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (!uploadFile || !user || !willWaitForCanvasSubmit) return;
    const upload = async () => {
      try {
        setIsSubmitting(true);
        setWillWaitForCanvasSubmit(false);
        const uploadedFile = await uploadFile(
          uploadedImage,
          uploadedImage.name,
        );
        await updateUserProfile({
          variables: {
            data: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              avatarUrl: uploadedFile.cdnUrl,
            },
          },
        });
        toast({
          message: 'Avatar updated successfully!',
          type: 'success',
        });
      } catch (err) {
        toast({
          message: 'Unknown error occurred. Please try again later.',
          type: 'error',
        });
      } finally {
        setIsSubmitting(false);
        onClose();
      }
    };
    upload();
  }, [
    onClose,
    updateUserProfile,
    uploadFile,
    uploadedImage,
    user,
    willWaitForCanvasSubmit,
  ]);

  return (
    <StyledDialog onClose={onClose} open={isOpen} maxWidth="sm" fullWidth>
      <Box display="flex" padding={8}>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          sx={{ width: '100%' }}
        >
          <StyledTitle variant="headline-md">Adjust Avatar</StyledTitle>
          <Box>
            <ImageEditor
              ref={uploaderRef}
              type="avatar"
              imageUrl={imageUrl}
              editableImageUrl={isSubmitting ? undefined : imageUrl}
              onUpdate={(imageFile, imageDataUrl) => {
                setUploadedImage(imageFile);
                setImageUrl(imageDataUrl);
              }}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={1} mt={5}>
            <Button
              variant="secondary"
              onClick={onClose}
              sx={{
                border: 'none',
                color: theme.colors?.utility[700],
                fontFamily: 'TT Commons Pro',
              }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSave}
              disabled={isSubmitting}
              sx={{
                fontFamily: 'TT Commons Pro',
              }}
              size="small"
            >
              {isSubmitting ? (
                <CircularProgress
                  size={16}
                  sx={{ color: theme.colors?.primary.maroon }}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledDialog>
  );
};
