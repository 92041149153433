import { gql } from '@apollo/client';
import { Box, IconButton, Menu, Typography } from '@mui/material';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragment,
  SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragmentDoc,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
  useGetTopicForSlaAnalyticsFiltersQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { SLAAnalyticsFilterByCategory } from './SLAAnalyticsFilterByCategory';
import { SLAAnalyticsFilterByDemographics } from './SLAAnalyticsFilterByDemographics';
import { SLAAnalyticsFilterByPeriod } from './SLAAnalyticsFilterByPeriod';
import { SLAAnalyticsFilterByPlatform } from './SLAAnalyticsFilterByPlatform';
import { SLAAnalyticsFilterBySource } from './SLAAnalyticsFilterBySource';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetTopicForSLAAnalyticsFilters($topicId: String!) {
    topic(id: $topicId) {
      id
      topicSignalDefinitions {
        id
        signalDefinition {
          ...SignalDefinitionFragmentSLAAnalyticsFilterByCategory
        }
      }
    }
  }
  ${SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragmentDoc}
`;

interface Props {
  brandId: string;
  topicId?: string;
  onChange: (
    filters: Omit<
      BrandInboundFiltersInput & {
        gender?: SocialPostGenderDemographic[];
        generation?: SocialPostGenerationDemographic[];
      },
      'contentType'
    >,
  ) => void;
  hideOptions?: {
    category?: boolean;
  };
  selectedFilters?: Omit<
    BrandInboundFiltersInput & {
      gender?: SocialPostGenderDemographic[];
      generation?: SocialPostGenerationDemographic[];
    },
    'contentType'
  >;
  contentType: BrandContentType;
  onSetCurrentTopicCategories?: (
    categories: SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragment[],
  ) => void;
}

export const SLAAnalyticsFilters = ({
  brandId,
  topicId,
  contentType,
  onChange,
  hideOptions = {},
  selectedFilters,
  onSetCurrentTopicCategories,
}: Props) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const filterOpen = Boolean(filterAnchorEl);

  const [selectedDateRange, setSelectedDateRange] = useState<{
    label: string;
    range: Date[];
  }>(
    // If on mount, selectedFilters.dateRange is provided, set as custom range
    // TODO: This is a temporary solution, need to refactor this.
    // We might need to save the date range by label, instead of date objects because
    // date objects could change very easily as we don't remove the minute/second data.
    selectedFilters?.dateRange
      ? {
          label: CUSTOM_RANGE_LABEL,
          range: [
            selectedFilters.dateRange.startDate,
            selectedFilters.dateRange.endDate,
          ],
        }
      : {
          label: 'Last 7 days',
          range: [
            moment().subtract(7, 'day').startOf('day').toDate(),
            moment().toDate(),
          ],
        },
  );

  const { data: topicData } = useGetTopicForSlaAnalyticsFiltersQuery({
    variables: { topicId: topicId! },
    skip: !topicId,
  });

  const signalDefinitions = useMemo(
    () =>
      (topicData?.topic?.topicSignalDefinitions || []).map(
        (t) => t.signalDefinition,
      ),
    [topicData?.topic?.topicSignalDefinitions],
  );

  useEffect(() => {
    onSetCurrentTopicCategories?.(signalDefinitions);
  }, [signalDefinitions]); // eslint-disable-line

  const {
    selectedCreatorIds,
    selectedPlatforms,
    selectedPostTypes,
    selectedCategoryIds,
    selectedCategoryOptionIds,
    selectedGender,
    selectedGeneration,
    selectedSources,
  } = useMemo(() => {
    const selectedSources = selectedFilters?.sources || [];
    const selectedCreatorIds = selectedFilters?.creatorIds || [];
    const selectedPlatforms = selectedFilters?.platforms || [];
    const selectedPostTypes = selectedFilters?.postTypes || [];
    const selectedCategoryIds = selectedFilters?.signalDefinitionIds || [];
    const selectedCategoryOptionIds =
      selectedFilters?.signalCategoryOptionIds || [];
    const selectedGender = selectedFilters?.gender || [];
    const selectedGeneration = selectedFilters?.generation || [];
    return {
      selectedCreatorIds,
      selectedPlatforms,
      selectedPostTypes,
      selectedCategoryIds,
      selectedCategoryOptionIds,
      selectedGender,
      selectedGeneration,
      selectedSources,
    };
  }, [JSON.stringify({ selectedFilters })]); // eslint-disable-line

  const onUpdateFilters = useCallback(
    (
      filters: Omit<
        BrandInboundFiltersInput & {
          gender?: SocialPostGenderDemographic[];
          generation?: SocialPostGenerationDemographic[];
        },
        'contentType' | 'brandId' | 'dateRange'
      >,
    ) => {
      onChange({
        ...selectedFilters,
        ...filters,
        brandId,
        dateRange: {
          startDate: selectedDateRange.range[0]!,
          endDate: selectedDateRange.range[1]!,
        },
      });
    },
    [selectedDateRange, selectedFilters, brandId], // eslint-disable-line
  );

  // Update filters when selected date range changes
  useEffect(() => {
    onUpdateFilters({});
  }, [selectedDateRange]); // eslint-disable-line

  useEffect(() => {
    onUpdateFilters({
      ...(selectedFilters?.topicIds?.[0] !== topicId && {
        signalCategoryOptionIds: [],
        signalDefinitionIds: [],
      }),
    });
  }, [topicId]); // eslint-disable-line

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <SLAAnalyticsFilterByPlatform
        selectedPlatforms={selectedPlatforms}
        selectedTypes={selectedPostTypes}
        selectedCreatorIds={selectedCreatorIds}
        onChange={({
          selectedCreatorIds,
          selectedPlatforms,
          selectedTypes,
        }) => {
          onUpdateFilters({
            creatorIds: selectedCreatorIds,
            platforms: selectedPlatforms,
            postTypes: selectedTypes,
          });
        }}
        brandId={brandId}
      />
      <SLAAnalyticsFilterByPeriod
        selectedPeriod={{
          label: selectedDateRange.label,
          startDate: selectedDateRange.range[0]!,
          endDate: selectedDateRange.range[1]!,
        }}
        onToggle={setSelectedDateRange}
      />
      <IconButton
        sx={{
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(2),
          p: theme.spacing(2),
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          setFilterAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(35, 6, 3, 0.05)',
              borderRadius: theme.spacing(1),
              padding: theme.spacing(1),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconBoldSetting5
              size={16}
              style={{
                color: theme.colors?.primary.black,
              }}
            />
          </Box>

          <Typography variant="subhead-xl">Add a filter</Typography>
        </Box>
      </IconButton>
      <Menu
        anchorEl={filterAnchorEl}
        open={filterOpen}
        onClose={() => setFilterAnchorEl(null)}
        PaperProps={{
          sx: {
            minWidth: 244,
            maxWidth: 244,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
            border: 'none',
            maxHeight: 500,
            '&::-webkit-scrollbar': {
              width: 0,
            },
          },
        }}
      >
        {!hideOptions?.category && topicId && (
          <SLAAnalyticsFilterByCategory
            selectedCategoryIds={selectedCategoryIds}
            selectedCategoryOptionIds={selectedCategoryOptionIds}
            onChange={(categoryIds, categoryOptionIds) => {
              onUpdateFilters({
                signalCategoryOptionIds: categoryOptionIds,
                signalDefinitionIds: categoryIds,
              });
            }}
            signalDefinitions={signalDefinitions}
          />
        )}

        {contentType !== BrandContentType.Owned && (
          <SLAAnalyticsFilterByDemographics
            selectedGender={selectedGender}
            selectedGeneration={selectedGeneration}
            onChange={({ selectedGender, selectedGeneration }) => {
              onUpdateFilters({
                gender: selectedGender,
                generation: selectedGeneration,
              });
            }}
          />
        )}
        <SLAAnalyticsFilterBySource
          selectedSources={selectedSources}
          onChange={(selectedSources) => {
            onUpdateFilters({ sources: selectedSources });
          }}
        />
      </Menu>
    </Box>
  );
};
