import { Box } from '@mui/material';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router-dom';

export const GlobalDropzoneLayer = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();

  // Only support drop multiple files to create CJB posts in home page, CJB page and collection detail page
  const isInSupportedPages =
    ['', '/', '/juicebox'].includes(location.pathname) ||
    ['/collections'].some((i) => location.pathname.startsWith(i));

  // disable global drop in note detail page
  const isDisableDropBehavior = location?.pathname?.includes('/juicebox/note/');

  const { triggerCommand } = useCommandContext();

  const { acceptedFiles, isDragActive, getRootProps, getInputProps } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      multiple: true,
      disabled: isDisableDropBehavior || !isInSupportedPages,
    });

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (files.length > 0 && isInSupportedPages) {
      triggerCommand(COMMAND_TYPE.POST_UPLOAD_FILES, {
        skipFileBrowser: true,
        initialFiles: files.map((i) => ({ file: i })),
      });
      setFiles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, isInSupportedPages]);

  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
      {/* Blur view when drag active */}
      {isDragActive && (
        <Box
          sx={{
            position: 'fixed',
            inset: 0,
            bgcolor: 'rgba(0, 0, 0, 0.25)',
            zIndex: 99999,
            backdropFilter: 'blur(2px)',
          }}
        />
      )}
    </div>
  );
};
