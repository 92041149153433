import { gql } from '@apollo/client';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { ContextMenu } from 'components/common/ContextMenu';
import { Option } from 'components/common/ContextMenu/types';
import { IconLinearMore2 } from 'components/icons/components/linear/IconLinearMore2';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { useUserContext } from 'contexts/users/User.context';
import {
  StyledContextMenuOptionWrapper,
  StyledTableBodyCell,
  StyledTableHeadCell,
} from 'features/organizationMembers/views/MembersView/styles';
import { useSocialListeningPermissionsManageMembers } from 'features/socialListeningPermissions';
import { useSLBrandPermissions } from 'features/socialMediaListening';
import {
  GeneralPermission,
  InternalOrganizationRole,
  PermissionLevel,
  useGetBrandForSocialListeningUsersViewQuery,
  UserFragmentAvatarGroupFragmentDoc,
  useUpdateBrandPermissionsForSocialListeningUserPermissionsDialogBodyViewMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandForSocialListeningUsersView($brandId: String!) {
    brand(id: $brandId) {
      id
      inviteMembers {
        id
        userId
        permissionLevel
        user {
          isInvitedBy {
            id
            lastName
            firstName
            email
          }
          ...UserFragmentAvatarGroup
        }
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateBrandPermissionsForSocialListeningUsersView(
    $data: UpdateBrandPermissionsInput!
  ) {
    updateBrandPermissions(data: $data) {
      success
      message
    }
  }
`;

type Props = {
  brandId: string;
};

const columns = ['Name', 'User Access', 'Invited By', ''];

export const SocialListeningUsersView = ({ brandId }: Props) => {
  const { user, refetchUserData } = useUserContext();
  const { dialog: removeUserDialog, onOpen: showRemoveUserDialog } =
    useConfirmationDialog();
  const { renderShareSocialListeningButton, renderShareSocialListeningModal } =
    useSocialListeningPermissionsManageMembers({
      brandId,
    });

  const { hasFullAccess: userHasFullAccess } = useSLBrandPermissions({
    brandId,
  });

  const currentBrand = user?.socialListeningBrands.find(
    (brand) => brand.id === brandId,
  );

  const hasUpdatePermission =
    user?.role === InternalOrganizationRole.Admin ||
    (currentBrand?.permissionLevel === PermissionLevel.Full &&
      currentBrand.generalPermission ===
        GeneralPermission.OrganizationMembers &&
      user?.organization.id === currentBrand?.organizationId) ||
    currentBrand?.inviteMembers?.some(
      (inviteMember) =>
        inviteMember.user.id === user?.id &&
        inviteMember.permissionLevel === PermissionLevel.Full,
    );

  const [updateBrandPermissions] =
    useUpdateBrandPermissionsForSocialListeningUserPermissionsDialogBodyViewMutation();

  const { data: brandData } = useGetBrandForSocialListeningUsersViewQuery({
    variables: {
      brandId,
    },
  });

  const inviteMembers = brandData?.brand.inviteMembers || [];
  const internalMembers = user?.organization.users || [];
  const usersToRender = [
    ...internalMembers,
    ...inviteMembers.map((inviteMember) => inviteMember.user),
  ].filter(
    // Deduplicate by id
    (user, index, self) => index === self.findIndex((t) => t.id === user.id),
  );

  const getUser = (email: string) => {
    const _user = user?.organization.users.find((u) => u.email === email);
    if (_user) {
      return {
        user: _user,
        role:
          _user.role === InternalOrganizationRole.Admin
            ? 'Admin'
            : _user.role === InternalOrganizationRole.User
            ? 'All Access Member'
            : 'Social Listening User',
      };
    }

    const _externalUser = user?.organization.externalUsers.find(
      (u) => u.email === email,
    );
    if (_externalUser) {
      return { user: _externalUser, role: 'Guest' };
    }

    return null;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Box
        mt={2}
        mb={4}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="headline-md"
          fontWeight={600}
          color={theme.colors?.utility[1000]}
        >
          Users
        </Typography>
        {userHasFullAccess && renderShareSocialListeningButton()}
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableHeadCell key={`${column}-${index}`}>
                  {column}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usersToRender.map((user) => {
              const options: Option[] = [];

              // Only allow removing guests here
              if (
                hasUpdatePermission &&
                getUser(user.email)?.role === 'Guest'
              ) {
                options.push({
                  onClick: () => {
                    showRemoveUserDialog({
                      subtitle: `Are you sure you want to remove ${getFullName(
                        user,
                      )}?`,
                      onConfirm: async () => {
                        const membersUpdated = (
                          currentBrand?.inviteMembers || []
                        ).filter((member) => member.user.id !== user.id);

                        await updateBrandPermissions({
                          variables: {
                            data: {
                              brandId,
                              members: membersUpdated.map((m) => ({
                                email: m.user.email,
                                permission: m.permissionLevel,
                              })),
                            },
                          },
                        });
                        refetchUserData();
                      },
                    });
                  },
                  renderOption: () => (
                    <StyledContextMenuOptionWrapper
                      sx={{
                        color: theme.colors?.utility['pink-3'],
                        p: theme.spacing(3, 0),
                      }}
                    >
                      <IconLinearTrash size={18} />
                      <Typography
                        variant="body-md"
                        fontWeight={500}
                        color={theme.colors?.utility['pink-3']}
                        pt={0.5}
                      >
                        Remove Access
                      </Typography>
                    </StyledContextMenuOptionWrapper>
                  ),
                });
              }

              return (
                <TableRow key={user.id}>
                  <StyledTableBodyCell>
                    <AvatarWithName user={user} />
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {getUser(user.email) && (
                        <Typography
                          variant="body-sm"
                          fontWeight={500}
                          color={theme.colors?.primary.white}
                          sx={{
                            backgroundColor: theme.colors?.utility[800],
                            borderRadius: theme.spacing(8),
                            p: theme.spacing(1, 3),
                            fontWeight: 600,
                          }}
                        >
                          {getUser(user.email)?.role}
                        </Typography>
                      )}
                    </Box>
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <Typography
                      variant="body-sm"
                      fontWeight={500}
                      color={theme.colors?.utility[800]}
                    >
                      {user.isInvitedBy ? getFullName(user.isInvitedBy) : '-'}
                    </Typography>
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {!!options.length && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <ContextMenu
                          sx={{
                            '& .MuiPaper-root': {
                              borderRadius: theme.spacing(4),
                              border: 'none',
                              minWidth: 250,
                            },
                          }}
                          className="rererere"
                          renderButton={() => (
                            <IconLinearMore2
                              color={theme.colors?.utility[800]}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                          options={options}
                        />
                      </Box>
                    )}
                  </StyledTableBodyCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {removeUserDialog}
      {renderShareSocialListeningModal()}
    </>
  );
};
