import { Node } from '@tiptap/react';
import { MasonryExtensionName } from './MasonryExtension';

export const MasonryWrapperExtension = Node.create({
  name: 'masonryWrapper',
  group: 'block',
  content: MasonryExtensionName,
  selectable: false,

  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'masonry-wrapper', node: this.name, priority: 51 }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['masonry-wrapper', HTMLAttributes, 0];
  },
});
