import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  Typography,
  checkboxClasses,
  formControlLabelClasses,
  radioClasses,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import {
  CommentFilter as CommentFilterType,
  SortOrder,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';

interface Props {
  filters: CommentFilterType;
  onSetFilters: (filters: CommentFilterType) => void;
}

enum PostCommentSortBy {
  CREATED_AT = 'createdAt',
  UNREAD = 'unread',
}

export const CommentFilter = ({ filters, onSetFilters }: Props) => {
  const isMobileView = useMediaQueryMobile();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedSortValue, setSelectedSortValue] = useState<
    PostCommentSortBy | string
  >(PostCommentSortBy.CREATED_AT);

  const onClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedSortValue) {
      onSetFilters({
        ...filters,
        sortBy: {
          field: PostCommentSortBy.CREATED_AT,
          order:
            selectedSortValue === PostCommentSortBy.CREATED_AT
              ? SortOrder.Asc
              : SortOrder.Desc,
        },
      });
    } else {
      onSetFilters({
        ...filters,
        sortBy: undefined,
      });
    }
  }, [selectedSortValue]);

  return (
    <>
      <Box
        component="button"
        type="button"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        <IconLinearSort size={16} color={theme.colors?.utility.blueberry} />
      </Box>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: isMobileView ? '100%' : 'auto',
            mt: 4,
            p: 4,
            border: 'none',
            borderRadius: 2,
            boxShadow: '0px 3px 12px -2px rgba(0, 0, 0, 0.10)',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          },
        }}
      >
        <Typography variant="subhead-sm" color={theme.colors?.utility[900]}>
          Sort by
        </Typography>
        <RadioGroup defaultValue="unread" name="sortBy">
          {[
            {
              value: PostCommentSortBy.CREATED_AT,
              label: 'Date',
            },
            {
              value: PostCommentSortBy.UNREAD,
              label: 'Unread',
            },
          ].map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              label={label}
              checked={selectedSortValue === value}
              onClick={() => {
                setSelectedSortValue((prevValue) =>
                  prevValue === value ? '' : value,
                );
              }}
              control={<Radio />}
              sx={{
                mx: 0,
                py: 2,
                [`& .${formControlLabelClasses.label}`]: {
                  ...typography['headline-xs'],
                },
                [`& .${radioClasses.root}`]: {
                  p: 0,
                  pr: 3,
                  '& svg': {
                    width: '20px',
                    height: '20px',
                  },
                },
              }}
            />
          ))}
        </RadioGroup>
        <Divider />
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[900]}
          sx={{ mt: 3 }}
        >
          Show
        </Typography>
        {[
          {
            value: 'showMyThreads',
            label: 'Only your threads',
            isChecked: filters.showMyThreads || false,
          },
          {
            value: 'nestedCollectionsOnly',
            label: 'Comments on nested collections',
            isChecked: filters.nestedCollectionsOnly || false,
          },
          {
            value: 'postCommentsOnly',
            label: 'Comments on posts',
            isChecked: filters.postCommentsOnly || false,
          },
        ].map(({ value, label, isChecked }) => (
          <FormControlLabel
            key={value}
            value={value}
            label={label}
            onChange={() => {
              onSetFilters({
                ...filters,
                [`${value}`]: !filters[`${value}`],
              });
            }}
            control={<Checkbox checked={isChecked} />}
            sx={{
              mx: 0,
              py: 2,
              [`& .${formControlLabelClasses.label}`]: {
                ...typography['headline-xs'],
              },
              [`& .${checkboxClasses.root}`]: {
                p: 0,
                pr: 3,
                '& svg': {
                  width: '20px',
                  height: '20px',
                },
                [`&.${checkboxClasses.checked}`]: {
                  color: theme.colors?.primary.maroon,
                },
              },
            }}
          />
        ))}
      </Popover>
    </>
  );
};
