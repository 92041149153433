import {
  Metadata,
  ThumbnailMetadata,
  UrlMetadataFragmentIframelyFragment,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { IFramelyResponse, useIframelyAPI } from './useIframelyAPI';

/**
 * This hook is to call Iframely API directly and construct a temporary urlMetadata object that we can use.
 * It should be called whenever we have a url but no good urlMetadata (e.g. when we just created a new post and BE jobs
 * are still processing urlMetadata).
 *
 * Note that we get little data from here. It's mostly for thumbnail/mimeType detection.
 */
export const useFallbackMetadata = (props: {
  url: string;
  urlMetadata?: UrlMetadataFragmentIframelyFragment;
}) => {
  const { url, urlMetadata } = props;

  const [fallbackUrlMetadata, setFallbackUrlMetadata] = useState<
    UrlMetadataFragmentIframelyFragment | undefined
  >(urlMetadata);
  const { getUrlMetadata } = useIframelyAPI();

  useEffect(() => {
    if (
      // Has url, AND
      !!url &&
      // No urlMetadata, OR
      (!urlMetadata ||
        // No metadata object, OR
        !urlMetadata.metadata ||
        // Nothing inside the metadata object
        (!urlMetadata.metadata.site &&
          !urlMetadata.metadata.medium &&
          !urlMetadata.metadata.type &&
          (!urlMetadata.metadata.thumbnail ||
            urlMetadata.metadata.thumbnail.length === 0)))
    ) {
      getUrlMetadata(url)
        .then((response: IFramelyResponse) => {
          const metadata: Metadata = {
            site: response.meta?.site,
            title: response.meta?.title,
            author: response.meta?.author,
            description: response.meta?.description,
            medium: response.meta?.medium,
          };

          // Add the thumbnail metadata
          if (response.links?.thumbnail || response.links?.image) {
            const thumbnailLinks =
              response.links?.thumbnail || response.links?.image || [];

            const thumbnails: ThumbnailMetadata[] = thumbnailLinks.map(
              (thumbnail) => {
                return {
                  href: thumbnail.href,
                  type: thumbnail.type,
                  mediaSize: {
                    height: thumbnail.media.height,
                    width: thumbnail.media.width,
                  },
                };
              },
            );

            metadata.thumbnail = thumbnails;
          }

          // Capture file type
          if (response.links?.file) {
            // We'll want to avoid getting the "reader" object
            // as in some scenarios like PDF, it's actually the google docs wrapper, which has mimeType of text/html
            metadata.type =
              response.links.file.filter((f) => !f.rel.includes('reader'))[0]
                ?.type || '';
          }

          setFallbackUrlMetadata({
            id: '',
            url,
            __typename: 'URLMetadataModel',
            ...urlMetadata,
            metadata,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [urlMetadata]); // eslint-disable-line

  return {
    fallbackUrlMetadata,
    shouldUseFallbackUrlMetadata: Boolean(fallbackUrlMetadata),
  };
};
