import { gql } from '@apollo/client';
import {
  CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc,
  Platform,
  useCreateCreatorForUseSocialListeningOnboardingBrandMutation,
  useGetSocialCreatorForUseCreatorDataForSocialListeningOnboardingLazyQuery,
  useRemoveCreatorForUseSocialListeningOnboardingBrandMutation,
} from 'graphql/generated';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CreatorFragmentForUseCreatorDataForSocialListeningOnboarding on CreatorModel {
    id
    nickName
    profilePictureUrl
    handle
    platform
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSocialCreatorForUseCreatorDataForSocialListeningOnboarding(
    $filters: SocialCreatorFilters!
  ) {
    getSocialCreator(filters: $filters) {
      ...CreatorFragmentForUseCreatorDataForSocialListeningOnboarding
    }
  }
  ${CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateCreatorForUseSocialListeningOnboardingBrand(
    $data: CreateCreatorInput!
  ) {
    createCreator(data: $data) {
      ...CreatorFragmentForUseCreatorDataForSocialListeningOnboarding
    }
  }
  ${CreatorFragmentForUseCreatorDataForSocialListeningOnboardingFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation RemoveCreatorForUseSocialListeningOnboardingBrand(
    $data: RemoveCreatorInput!
  ) {
    removeCreator(data: $data) {
      success
      message
    }
  }
`;

export const useCreatorDataForSocialListeningOnboarding = () => {
  const [getSocialCreator] =
    useGetSocialCreatorForUseCreatorDataForSocialListeningOnboardingLazyQuery();

  const [createCreator] =
    useCreateCreatorForUseSocialListeningOnboardingBrandMutation();
  const [removeCreator] =
    useRemoveCreatorForUseSocialListeningOnboardingBrandMutation();

  const getCreatorByHandle = async (handle: string, platform: Platform) => {
    return getSocialCreator({
      variables: {
        filters: {
          handle,
          platform,
        },
      },
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  };

  const handleCreateCreator = async (
    handle: string,
    brandId: string,
    platform: Platform,
  ) => {
    return createCreator({
      variables: {
        data: {
          data: {
            handle,
            brandId,
            platform,
          },
        },
      },
      refetchQueries: ['GetBrandsForUseSocialListeningOnboarding'],
    });
  };

  const handleRemoveCreator = async (creatorId: string, brandId: string) => {
    return removeCreator({
      variables: {
        data: {
          creatorId,
          brandId,
        },
      },
      refetchQueries: ['GetBrandsForUseSocialListeningOnboarding'],
    });
  };

  return {
    queries: {
      getCreatorByHandle,
    },
    onboardingActions: {
      handleCreateCreator,
      handleRemoveCreator,
    },
  };
};
