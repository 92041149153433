import { Box, IconButton, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { ReactNode, useMemo } from 'react';
import { theme } from 'styles/theme';

export enum JuiceboxParentFilterType {
  Type = 'Type',
  People = 'People',
  Collection = 'Collection',
  Reaction = 'Reaction',
  Date = 'Date',
}

interface Props {
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { size?: number }
  >;
  renderCustomIcon?: ReactNode;
  text: ReactNode;
  onRemoveFilter: () => void;
  filterType: JuiceboxParentFilterType;
}

export const JuiceboxFilterPill = ({
  Icon,
  onRemoveFilter,
  text,
  filterType,
  renderCustomIcon,
}: Props) => {
  const { cancelIconColor, pillBgColor, pillIconTextColor } = useMemo(() => {
    switch (filterType) {
      case JuiceboxParentFilterType.Type:
        return {
          pillBgColor: theme.colors?.utility['teal-1'],
          pillIconTextColor: theme.colors?.utility['teal-4'],
          cancelIconColor: theme.colors?.utility['teal-2'],
        };
      case JuiceboxParentFilterType.People:
        return {
          pillBgColor: theme.colors?.utility['blue-1'],
          pillIconTextColor: theme.colors?.utility.blueberry,
          cancelIconColor: theme.colors?.utility['blue-4'],
        };
      case JuiceboxParentFilterType.Collection:
        return {
          pillBgColor: theme.colors?.utility['purple-1'],
          pillIconTextColor: theme.colors?.utility['purple-4'],
          cancelIconColor: theme.colors?.utility['purple-2'],
        };
      case JuiceboxParentFilterType.Reaction:
        return {
          pillBgColor: theme.colors?.utility['green-1'],
          pillIconTextColor: theme.colors?.utility['green-4'],
          cancelIconColor: theme.colors?.utility['pale-olive'],
        };
      default:
        return {
          pillBgColor: theme.colors?.utility['orange-1'],
          pillIconTextColor: theme.colors?.utility['orange-4'],
          cancelIconColor: theme.colors?.utility['orange-2'],
        };
    }
  }, [filterType]);

  return (
    <Box
      sx={{
        padding: theme.spacing(1, 2),
        borderRadius: 2,
        backgroundColor: pillBgColor,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {renderCustomIcon && renderCustomIcon}
      {Icon && (
        <Icon
          size={24}
          color={pillIconTextColor}
          style={{
            flexShrink: 0,
          }}
        />
      )}
      {typeof text === 'string' ? (
        <Typography variant="headline-lg" color={pillIconTextColor}>
          {text}
        </Typography>
      ) : (
        <>{text}</>
      )}
      <IconButton
        sx={{ padding: 0, cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          onRemoveFilter();
        }}
      >
        <IconBoldCloseCircle color={cancelIconColor} />
      </IconButton>
    </Box>
  );
};
