import { gql } from '@apollo/client';
import {
  ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_NAME,
  ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX,
  ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL,
  ATTACHMENT_SEARCH_HIT_FRAGMENT_SEARCH_TYPE,
  COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_NAME,
  COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX,
  COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL,
  COLLECTION_SEARCH_HIT_FRAGMENT_SEARCH_TYPE,
  LINK_SEARCH_HIT_FRAGMENT_ITEM_NAME,
  LINK_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX,
  LINK_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL,
  LINK_SEARCH_HIT_FRAGMENT_SEARCH_TYPE,
  NOTE_SEARCH_HIT_FRAGMENT_ITEM_NAME,
  NOTE_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX,
  NOTE_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL,
  NOTE_SEARCH_HIT_FRAGMENT_SEARCH_TYPE,
  TASK_SEARCH_HIT_FRAGMENT_ITEM_NAME,
  TASK_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX,
  TASK_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL,
  TASK_SEARCH_HIT_FRAGMENT_SEARCH_TYPE,
} from 'features/search';
import {
  AttachmentSearchHitFragmentListItemViewFragment,
  CollectionSearchHitFragmentListItemViewFragment,
  LinkSearchHitFragmentListItemViewFragment,
  NoteSearchHitFragmentListItemViewFragment,
  TaskSearchHitFragmentListItemViewFragment,
} from 'graphql/generated';

export type SearchListItemViewSearchHit =
  | NoteSearchHitFragmentListItemViewFragment
  | LinkSearchHitFragmentListItemViewFragment
  | AttachmentSearchHitFragmentListItemViewFragment
  | TaskSearchHitFragmentListItemViewFragment
  | CollectionSearchHitFragmentListItemViewFragment;

export const NOTE_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW = gql`
  fragment NoteSearchHitFragmentListItemView on PostSearchHit {
    score
    ...NoteSearchHitFragmentSearchType
    ...NoteSearchHitFragmentItemName
    ...NoteSearchHitFragmentItemThumbnail
    ...NoteSearchHitFragmentItemSuffix
  }
  ${NOTE_SEARCH_HIT_FRAGMENT_SEARCH_TYPE}
  ${NOTE_SEARCH_HIT_FRAGMENT_ITEM_NAME}
  ${NOTE_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL}
  ${NOTE_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX}
`;

export const LINK_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW = gql`
  fragment LinkSearchHitFragmentListItemView on PostSearchHit {
    score
    ...LinkSearchHitFragmentSearchType
    ...LinkSearchHitFragmentItemName
    ...LinkSearchHitFragmentItemThumbnail
    ...LinkSearchHitFragmentItemSuffix
  }
  ${LINK_SEARCH_HIT_FRAGMENT_SEARCH_TYPE}
  ${LINK_SEARCH_HIT_FRAGMENT_ITEM_NAME}
  ${LINK_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL}
  ${LINK_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX}
`;

export const ATTACHMENT_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW = gql`
  fragment AttachmentSearchHitFragmentListItemView on PostSearchHit {
    score
    ...AttachmentSearchHitFragmentSearchType
    ...AttachmentSearchHitFragmentItemName
    ...AttachmentSearchHitFragmentItemThumbnail
    ...AttachmentSearchHitFragmentItemSuffix
  }
  ${ATTACHMENT_SEARCH_HIT_FRAGMENT_SEARCH_TYPE}
  ${ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_NAME}
  ${ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL}
  ${ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX}
`;

export const TASK_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW = gql`
  fragment TaskSearchHitFragmentListItemView on TaskSearchHitV2 {
    score
    ...TaskSearchHitFragmentSearchType
    ...TaskSearchHitFragmentItemName
    ...TaskSearchHitFragmentItemThumbnail
    ...TaskSearchHitFragmentItemSuffix
  }
  ${TASK_SEARCH_HIT_FRAGMENT_SEARCH_TYPE}
  ${TASK_SEARCH_HIT_FRAGMENT_ITEM_NAME}
  ${TASK_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL}
  ${TASK_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX}
`;

export const COLLECTION_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW = gql`
  fragment CollectionSearchHitFragmentListItemView on CollectionSearchHit {
    score
    ...CollectionSearchHitFragmentSearchType
    ...CollectionSearchHitFragmentItemName
    ...CollectionSearchHitFragmentItemThumbnail
    ...CollectionSearchHitFragmentItemSuffix
  }
  ${COLLECTION_SEARCH_HIT_FRAGMENT_SEARCH_TYPE}
  ${COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_NAME}
  ${COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_THUMBNAIL}
  ${COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_SUFFIX}
`;
