import { Image } from 'components/common/Image';
import { useState } from 'react';

export const CustomImagePreview = (props: { url: string }) => {
  const { url } = props;

  const [hasError, setHasError] = useState(false);

  if (hasError) {
    throw new Error('Image failed to load');
  }

  return (
    <Image
      src={url}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        minHeight: 'inherit',
        borderRadius: 'inherit',
      }}
      onError={() => setHasError(true)}
    />
  );
};
