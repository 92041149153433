import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLayoutEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';

type CustomOverflowTitleProps = {
  currentUrl: string;
  title: string;
  onShowMore: () => void;
};

export const CustomOverflowTitle = ({
  currentUrl,
  title,
  onShowMore,
}: CustomOverflowTitleProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef<HTMLElement | null>(null);
  const textRef = useRef<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const checkOverflow = () => {
      setTimeout(() => {
        if (textRef.current && containerRef.current) {
          const textHeight = textRef.current.getBoundingClientRect().height;
          const containerHeight =
            containerRef.current.getBoundingClientRect().height;

          setIsOverflowing(textHeight > containerHeight);
        }
      }, 200);
    };

    // Call checkOverflow right away and whenever the title changes
    checkOverflow();

    // Add event listener for window resize
    window.addEventListener('resize', checkOverflow);

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', checkOverflow);

    // eslint-disable-next-line
  }, [title, currentUrl, textRef.current, containerRef.current]);

  return (
    <Box
      key={currentUrl}
      position="relative"
      sx={{ width: '100%', maxHeight: theme.spacing(10), overflow: 'hidden' }}
      ref={containerRef}
    >
      <Typography
        display="block"
        variant="body-lg"
        fontWeight={600}
        ref={textRef}
        sx={{}}
      >
        {title}
      </Typography>
      {isOverflowing && (
        <Box onClick={onShowMore} sx={{ cursor: 'pointer' }}>
          <Typography
            variant="body-lg"
            fontWeight={600}
            sx={{
              position: 'absolute',
              pr: 2,
              p: 1,
              pt: 0,
              bottom: 0,
              right: 0,
              color: theme.colors?.utility[800],
              backgroundColor: theme.colors?.primary.parchment,
            }}
          >
            ...Show more
          </Typography>
        </Box>
      )}
    </Box>
  );
};
