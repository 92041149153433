/**
 * Get origin from url
 */
export const getOriginFromUrl = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    // Invalid URL
    return '';
  }
};
