import { Extension, ReactRenderer } from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import tippy, { GetReferenceClientRect } from 'tippy.js';
import { PluginKey } from 'prosemirror-state';
import { EmojiList } from './EmojiList';
import { EXTENSION_EMOJI } from '../constants';

export const Emoji = Extension.create({
  name: EXTENSION_EMOJI,

  // show emoji menu when typing colon (:)
  addOptions() {
    return {
      suggestion: {
        char: ':',
        command: ({ editor, range, props }) => {
          props.command({ editor, range });
        },
      },
    };
  },

  // show emoji menu with shortcut keys Cmd + E or Ctrl + E
  // Mod as a shorthand for Cmd on Mac and Ctrl on other platforms
  addKeyboardShortcuts() {
    return {
      'Mod-e': () => this.editor.commands.insertContent(':'),
    };
  },

  // render emoji menu
  addProseMirrorPlugins() {
    return [
      Suggestion({
        pluginKey: new PluginKey('emoji'),
        editor: this.editor,
        ...this.options.suggestion,
        render: () => {
          let component: ReactRenderer<any>;
          let popup: any;
          return {
            onStart: (props) => {
              component = new ReactRenderer(EmojiList, {
                props,
                editor: props.editor,
              });

              if (!props.clientRect) {
                return;
              }

              popup = tippy('body', {
                getReferenceClientRect:
                  props.clientRect as GetReferenceClientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
              });
            },

            onUpdate(props) {
              component.updateProps(props);
              popup[0].setProps({
                getReferenceClientRect: props.clientRect,
              });
            },

            onKeyDown(props) {
              if (!component.ref) {
                return false;
              }

              if (props.event.key === 'Escape') {
                popup[0].hide();
                return true;
              }

              return component.ref.onKeyDown(props);
            },

            onExit() {
              popup[0].destroy();
              component.destroy();
            },
          };
        },
      }),
    ];
  },
});
