import { gql } from '@apollo/client';
import { useDebounce } from '@dwarvesf/react-hooks';
import { Box, Button, Chip, Input, SxProps, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { CollectionMultiPostPreview } from 'features/collection/components';
import {
  CollectionFragmentCollectionNameFragment,
  CollectionFragmentSimilarCollectionListFragment,
  PostFragmentCollectionCreateFormFragment,
  PostFragmentCollectionMultiPostPreviewFragmentDoc,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import { SimilarCollectionList } from './SimilarCollectionList';

export const POST_FRAGMENT_COLLECTION_CREATE_FORM = gql`
  fragment PostFragmentCollectionCreateForm on PostModel {
    id
    ...PostFragmentCollectionMultiPostPreview
  }
  ${PostFragmentCollectionMultiPostPreviewFragmentDoc}
`;

export type CollectionCreateFormValues = {
  name: string;
  parentCollectionId?: string;
  postIds?: string[];
};

export type CollectionCreateFormInitialValues = {
  name: string;
  parentCollection?: CollectionFragmentCollectionNameFragment;
  posts: PostFragmentCollectionCreateFormFragment[];
};

export type CollectionCreateFormProps = {
  initialValues?: CollectionCreateFormInitialValues;
  sx?: SxProps;
  onSubmit: (values: CollectionCreateFormValues) => Promise<void>;
  onSelectExistingCollection?: (collectionId: string) => void;
};

export const CollectionCreateForm = (props: CollectionCreateFormProps) => {
  const { orgBilling } = useUserContext();

  const { initialValues, sx, onSubmit, onSelectExistingCollection } = props;

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      name: initialValues?.name || '',
      parentCollectionId: initialValues?.parentCollection?.id || '',
      postIds: initialValues?.posts.map((post) => post.id) || [],
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const name = watch('name');
  const debouncedName = useDebounce(name, 500);

  const internalOnSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true);
    await onSubmit(values);
    setIsSubmitting(false);
  });

  const [similarCollections, setSimilarCollections] = useState<
    CollectionFragmentSimilarCollectionListFragment[]
  >([]);
  const showSimilarCollections = Boolean(name);

  // Prevent submit if there are already a collection with the same name
  const canSubmit = !similarCollections.some(
    (collection) => collection.name.trim() === name.trim(),
  );

  const isLimitReached = useMemo(() => {
    return orgBilling?.collectionUsageLimit
      ? orgBilling.collectionUsageCount >= orgBilling.collectionUsageLimit
      : false;
  }, [orgBilling, initialValues]);

  return (
    <Box
      component="form"
      onSubmit={internalOnSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          transition: '2s all',
        }}
      >
        <Box
          width={showSimilarCollections ? 40 : '100%'}
          height={showSimilarCollections ? 40 : undefined}
          sx={{ mb: showSimilarCollections ? 0 : 8 }}
        >
          <CollectionMultiPostPreview
            variant="grid"
            collection={{
              id: '',
              __typename: 'CollectionModel',
              posts: initialValues?.posts || [],
            }}
            sx={
              showSimilarCollections
                ? {
                    aspectRatio: '1/1',
                    borderRadius: 0,
                  }
                : undefined
            }
          />
        </Box>
        <Box
          width={showSimilarCollections ? 'calc(100% - 40px)' : '100%'}
          sx={{ pl: showSimilarCollections ? 3 : 0 }}
        >
          <Input
            multiline
            disableUnderline
            maxRows={3}
            placeholder="Collection name"
            autoFocus
            autoComplete="off"
            sx={{
              ...typography['headline-lg'],
              border: 'none !important',
              outline: 'none !important',
              padding: 0,
              pl: !showSimilarCollections ? 8 : 0,
              fontSize: 28,
              width: '100%',
              lineHeight: 'normal',
            }}
            {...register('name', {
              required: 'This field is required',
            })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                internalOnSubmit();
              }
            }}
          />
          {errors.name && (
            <Typography
              component="div"
              variant="subhead-sm"
              color={theme.colors?.utility['pink-4']}
              textAlign="center"
            >
              {errors.name.message}
            </Typography>
          )}
        </Box>
      </Box>

      {initialValues?.parentCollection && (
        <Box
          sx={{
            width: showSimilarCollections ? '100%' : 'auto',
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Typography variant="headline-xs" color={theme.colors?.utility[600]}>
            Current location:
          </Typography>
          <Chip
            label={initialValues?.parentCollection.name}
            size="small"
            sx={{
              ...theme.typography['headline-xs'],
              color: theme.colors?.utility[800],
              backgroundColor: theme.colors?.utility[300],
              border: 'none',
            }}
          />
        </Box>
      )}

      {showSimilarCollections && onSelectExistingCollection && (
        <SimilarCollectionList
          filters={{
            query: debouncedName,
            parentCollectionId: initialValues?.parentCollection?.id,
          }}
          onSelect={onSelectExistingCollection}
          onLoad={setSimilarCollections}
        />
      )}
      <Button
        variant="primary-alt"
        type="submit"
        disabled={isLimitReached || isSubmitting || !canSubmit}
      >
        Create collection
      </Button>
    </Box>
  );
};
