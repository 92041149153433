import { Box, styled } from '@mui/material';
import { useMousePosition } from 'components/common/Media/hooks/useMousePosition';
import { useAnnotationManager } from 'components/common/Annotation/contexts/Annotation.context';
import { theme } from 'styles/theme/theme';

const StyledCursor = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: 20,
  height: 20,
  border: `2px solid ${theme.colors?.primary.white}`,
  borderRadius: '50% 50% 50% 0',
  transform: `translate(-50%, -50%)`,
  zIndex: 999,
  pointerEvents: 'none',
  background: theme.colors?.primary.black,
}));

export const Cursor = () => {
  const { x, y, isWithinBounds } = useMousePosition();
  const { canAnnotate } = useAnnotationManager();

  if (!isWithinBounds || !canAnnotate) return null;

  return <StyledCursor style={{ left: `${x}px`, top: `${y}px` }} />;
};
