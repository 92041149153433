import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useViewManager } from 'components/common/Media/contexts/ViewManager.context';

/**
 * Wrapper for Annotation. Reason for this is because
 * we need full access to container elements of Annotation
 * so we can get scroll offset to calculate annotation coordinates
 *
 * @returns JSX
 */
export const ViewContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setViewportWidth, setViewportHeight, setMaxBoundingBoxWidth } =
    useViewManager();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setViewport = () => {
      if (containerRef.current) {
        setViewportWidth(containerRef.current.clientWidth);
        setViewportHeight(containerRef.current.clientHeight);
      }
    };

    setViewport();

    const handleResize = () => {
      // Reset viewport and maxBoundingBoxWidth
      setViewport();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // eslint-disable-line

  return (
    <Box
      ref={containerRef}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
      id="view-container"
    >
      <Box
        sx={{
          minWidth: '100%',
          minHeight: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
