import { AlertColor, SxProps } from '@mui/material';
import { ToastPosition } from 'react-hot-toast';
import { ButtonProps } from '@mui/material/Button/Button';

export enum CustomToastNotificationClass {
  NEW_VERSION = 'NewVersion',
  GRAPHQL = 'GraphQL',
  FORBIDDEN = 'Forbidden',
}

export interface CustomToastProps {
  type?: AlertColor;
  icon?: JSX.Element;
  title?: string;
  message: string;
  actionButtons?: ButtonProps[];
  captureMessage?: boolean;
  persist?: boolean;
  position?: ToastPosition;
  sx?: SxProps;
  shouldShowCloseButton?: boolean;
  duplicates?: {
    limit: number;
    notificationClass: CustomToastNotificationClass;
  };
}
