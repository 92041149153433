import { gql } from '@apollo/client';
import { Box, Collapse, IconButton, Menu, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import {
  Platform,
  SocialPostType,
  useGetBrandForSlaAnalyticsFilterByPlatformQuery,
} from 'graphql/generated';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetBrandForSLAAnalyticsFilterByPlatform($brandId: String!) {
    brand(id: $brandId) {
      id
      creators {
        id
        handle
        platform
      }
    }
  }
`;

interface SLAAnalyticsFilterByPlatformProps {
  selectedPlatforms?: Platform[];
  selectedTypes?: SocialPostType[];
  selectedCreatorIds?: string[];
  renderTitle?: () => React.ReactNode;
  brandId: string;
  onChange: (values: {
    selectedPlatforms: Platform[];
    selectedTypes: SocialPostType[];
    selectedCreatorIds: string[];
  }) => void;
}

export const SLAAnalyticsFilterByPlatform = ({
  selectedPlatforms = [],
  selectedTypes = [],
  selectedCreatorIds = [],
  renderTitle,
  onChange,
  brandId,
}: SLAAnalyticsFilterByPlatformProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: brandData } = useGetBrandForSlaAnalyticsFilterByPlatformQuery({
    variables: {
      brandId,
    },
    skip: !brandId,
  });

  const [collapsedSections, setCollapsedSections] = useState<Platform[]>(
    Object.keys(Platform) as Platform[],
  );

  const { instagramCreators, tiktokCreators } = useMemo(() => {
    return {
      tiktokCreators:
        brandData?.brand?.creators.filter(
          (o) => o.platform === Platform.Tiktok,
        ) ?? [],
      instagramCreators:
        brandData?.brand?.creators.filter(
          (o) => o.platform === Platform.Instagram,
        ) ?? [],
    };
  }, [brandData]);

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }
    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconLinearMonitorMobile size={16} />
        </Box>

        <Typography variant="subhead-xl">
          {selectedPlatforms.length
            ? selectedPlatforms.join(' & ')
            : 'Platforms'}
        </Typography>
        <IconLinearArrowDown size={16} color={theme.colors?.utility[700]} />
      </Box>
    );
  }, [renderTitle, selectedPlatforms]);

  const onToggleCollapse = (platform: Platform) => {
    if (collapsedSections.includes(platform)) {
      setCollapsedSections((prev) => prev.filter((o) => o !== platform));
    } else {
      setCollapsedSections((prev) => [...prev, platform]);
    }
  };

  return (
    <>
      <IconButton
        sx={{
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(2),
          p: theme.spacing(2),
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        {renderLabel}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 244,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
            border: 'none',
            '& ul > :first-child': {
              display: 'none',
            },
          },
        }}
      >
        <DropDownMoreMenuItem
          disableRipple
          defaultOpen
          label=""
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  gap: 2,
                },
              },
            },
          }}
          dropDownOptions={[
            {
              label: Platform.Instagram,
              value: Platform.Instagram,
              renderOption: () => {
                return (
                  <Box>
                    <CheckboxMenuItem
                      checkboxPosition="end"
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                          }}
                          onClick={() => {
                            onToggleCollapse(Platform.Instagram);
                          }}
                        >
                          <IconLinearArrowDown
                            size={16}
                            color={theme.colors?.utility[700]}
                          />
                          <IconBoldInstagram
                            size={16}
                            color={theme.colors?.utility[700]}
                          />
                          <Typography variant="headline-xs">
                            Instagram
                          </Typography>
                        </Box>
                      }
                      value={Platform.Instagram}
                      checked={selectedPlatforms.includes(Platform.Instagram)}
                      onClick={() => {
                        if (selectedPlatforms.includes(Platform.Instagram)) {
                          onChange({
                            selectedPlatforms: selectedPlatforms.filter(
                              (o) => o !== Platform.Instagram,
                            ),
                            selectedTypes: [],
                            selectedCreatorIds: selectedCreatorIds.filter(
                              (o) =>
                                !instagramCreators.map((c) => c.id).includes(o),
                            ),
                          });
                        } else {
                          onChange({
                            selectedPlatforms: [
                              ...selectedPlatforms,
                              Platform.Instagram,
                            ],
                            selectedTypes: Object.values(SocialPostType),
                            selectedCreatorIds: _.uniq([
                              ...selectedCreatorIds,
                              ...instagramCreators.map((c) => c.id),
                            ]),
                          });
                        }
                      }}
                    />
                    <Collapse
                      in={collapsedSections.includes(Platform.Instagram)}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          pl: 6,
                        }}
                      >
                        {instagramCreators.map((creator) => {
                          return (
                            <CheckboxMenuItem
                              checkboxPosition="end"
                              label={`@${creator.handle}`}
                              value={creator.id}
                              checked={selectedCreatorIds.includes(creator.id)}
                              onClick={() => {
                                onChange({
                                  selectedPlatforms,
                                  selectedTypes,
                                  selectedCreatorIds:
                                    selectedCreatorIds.includes(creator.id)
                                      ? selectedCreatorIds.filter(
                                          (o) => o !== creator.id,
                                        )
                                      : [...selectedCreatorIds, creator.id],
                                });
                              }}
                            />
                          );
                        })}
                        <Box
                          sx={{
                            py: 2,
                            pl: 3,
                            ...(!instagramCreators.length ? { pt: 0 } : {}),
                          }}
                        >
                          <Typography
                            variant="subhead-sm"
                            color={theme.colors?.utility[700]}
                          >
                            Post Type
                          </Typography>
                        </Box>
                        {(
                          [
                            [SocialPostType.Video, 'Reels'],
                            [SocialPostType.Photo, 'Photos'],
                            [SocialPostType.Carousel, 'Stories'],
                          ] as [SocialPostType, string][]
                        ).map(([type, label]) => {
                          return (
                            <CheckboxMenuItem
                              key={type}
                              checkboxPosition="end"
                              label={label}
                              value={type}
                              checked={selectedTypes.includes(type)}
                              onClick={() => {
                                onChange({
                                  selectedPlatforms: Array.from(
                                    new Set([
                                      ...selectedPlatforms,
                                      Platform.Instagram,
                                    ]),
                                  ),
                                  selectedTypes: selectedTypes.includes(type)
                                    ? selectedTypes.filter((o) => o !== type)
                                    : [...selectedTypes, type],
                                  selectedCreatorIds,
                                });
                              }}
                            />
                          );
                        })}
                      </Box>
                    </Collapse>
                  </Box>
                );
              },
            },
            {
              label: Platform.Tiktok,
              value: Platform.Tiktok,
              renderOption: () => {
                return (
                  <Box>
                    <CheckboxMenuItem
                      isCheckboxExclusiveClick
                      checkboxPosition="end"
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            width: '100%',
                          }}
                          onClick={() => {
                            onToggleCollapse(Platform.Tiktok);
                          }}
                        >
                          {!!tiktokCreators.length && (
                            <IconLinearArrowDown
                              size={16}
                              color={theme.colors?.utility[700]}
                            />
                          )}
                          <IconCustomTiktok
                            size={16}
                            color={theme.colors?.utility[700]}
                          />
                          <Typography variant="headline-xs">Tiktok</Typography>
                        </Box>
                      }
                      value={Platform.Tiktok}
                      checked={selectedPlatforms.includes(Platform.Tiktok)}
                      onClick={() => {
                        if (selectedPlatforms.includes(Platform.Tiktok)) {
                          onChange({
                            selectedPlatforms: selectedPlatforms.filter(
                              (o) => o !== Platform.Tiktok,
                            ),
                            selectedTypes,
                            selectedCreatorIds: selectedCreatorIds.filter(
                              (o) =>
                                !tiktokCreators.map((c) => c.id).includes(o),
                            ),
                          });
                        } else {
                          onChange({
                            selectedPlatforms: [
                              ...selectedPlatforms,
                              Platform.Tiktok,
                            ],
                            selectedTypes,
                            selectedCreatorIds: _.uniq([
                              ...selectedCreatorIds,
                              ...tiktokCreators.map((c) => c.id),
                            ]),
                          });
                        }
                      }}
                    />
                    <Collapse in={collapsedSections.includes(Platform.Tiktok)}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          pl: 6,
                        }}
                      >
                        {tiktokCreators.map((creator) => {
                          return (
                            <CheckboxMenuItem
                              checkboxPosition="end"
                              label={`@${creator.handle}`}
                              value={creator.id}
                              checked={selectedCreatorIds.includes(creator.id)}
                              onClick={() => {
                                onChange({
                                  selectedPlatforms,
                                  selectedTypes,
                                  selectedCreatorIds:
                                    selectedCreatorIds.includes(creator.id)
                                      ? selectedCreatorIds.filter(
                                          (o) => o !== creator.id,
                                        )
                                      : [...selectedCreatorIds, creator.id],
                                });
                              }}
                            />
                          );
                        })}
                      </Box>
                    </Collapse>
                  </Box>
                );
              },
            },
          ]}
        />
      </Menu>
    </>
  );
};
