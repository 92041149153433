import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { toast } from 'components/common/Toast';
import { CollectionThumbnail } from 'features/collection';
import { useJuiceboxSelectContext } from 'features/juicebox';
import { UpdatePostPermissionAlertView } from 'features/post-permission';
import { PostMoveToAnotherCollection } from 'features/post/views/moveToAnotherCollection';
import {
  CollectionFragmentUpdatePostPermissionAlertViewFragment,
  useIsCollectionsPermissionLessThanParentCollectionPermissionForCollectionPostLazyQuery,
  useIsPostsPermissionLessThanParentCollectionPermissionForCollectionPostLazyQuery,
  useMoveCollectionsToCollectionForUseCollectionPostMoveToAnotherCollectionModalMutation,
  useMovePostsToCollectionForUseCollectionPostMoveToAnotherCollectionModalMutation,
} from 'graphql/generated';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';

type Props = {
  collectionId: string;
  onCloseParent?: () => void;
};

// eslint-disable-next-line
gql`
  mutation MoveCollectionsToCollectionForUseCollectionPostMoveToAnotherCollectionModal(
    $data: MoveCollectionsToCollectionInput!
  ) {
    moveCollectionsToCollection(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation MovePostsToCollectionForUseCollectionPostMoveToAnotherCollectionModal(
    $data: MovePostsToCollectionInput!
  ) {
    movePostsToCollection(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  query IsCollectionsPermissionLessThanParentCollectionPermissionForCollectionPost(
    $data: IsCollectionsPermissionLessThanParentCollectionPermissionInput!
  ) {
    isCollectionsPermissionLessThanParentCollectionPermission(data: $data)
  }
`;

// eslint-disable-next-line
gql`
  query IsPostsPermissionLessThanParentCollectionPermissionForCollectionPost(
    $data: IsPostsPermissionLessThanParentCollectionPermissionInput!
  ) {
    isPostsPermissionLessThanParentCollectionPermission(data: $data)
  }
`;

export const useCollectionPostMoveToAnotherCollectionModal = ({
  collectionId,
  onCloseParent,
}: Props) => {
  const moveDialog = useDisclosure();
  const permissionDialog = useDisclosure();
  const { selectedCollectionIds, selectedPostIds, exitSelectMode } =
    useJuiceboxSelectContext();
  const navigate = useNavigate();

  const [moveCollectionsToCollection] =
    useMoveCollectionsToCollectionForUseCollectionPostMoveToAnotherCollectionModalMutation();
  const [movePostsToCollection] =
    useMovePostsToCollectionForUseCollectionPostMoveToAnotherCollectionModalMutation();
  const [isCollectionsPermissionLessThanParentCollectionPermission] =
    useIsCollectionsPermissionLessThanParentCollectionPermissionForCollectionPostLazyQuery();
  const [isPostsPermissionLessThanParentCollectionPermission] =
    useIsPostsPermissionLessThanParentCollectionPermissionForCollectionPostLazyQuery();

  const movedToCollectionRef =
    useRef<CollectionFragmentUpdatePostPermissionAlertViewFragment>();

  const { cache } = useApolloClient();

  const onAfterPermissionUpdated = useCallback(() => {
    selectedCollectionIds.forEach((collectionId) => {
      evictObject(cache, collectionId, 'CollectionModel');
    });
    selectedPostIds.forEach((postId) => {
      evictObject(cache, postId, 'PostModel');
    });
    exitSelectMode();
    onCloseParent?.();
  }, [
    selectedCollectionIds,
    selectedPostIds,
    exitSelectMode,
    onCloseParent,
    cache,
  ]);

  const handleOpenPermissionDialog = async () => {
    if (!movedToCollectionRef.current) return;
    const collectionsPermissionResponse =
      await isCollectionsPermissionLessThanParentCollectionPermission({
        variables: {
          data: {
            parentCollectionId: movedToCollectionRef.current.id,
            collectionIds: selectedCollectionIds,
          },
        },
      });

    const postsPermissionResponse =
      await isPostsPermissionLessThanParentCollectionPermission({
        variables: {
          data: {
            collectionId: movedToCollectionRef.current.id,
            postIds: selectedPostIds,
          },
        },
      });

    if (
      collectionsPermissionResponse.data
        ?.isCollectionsPermissionLessThanParentCollectionPermission ||
      postsPermissionResponse.data
        ?.isPostsPermissionLessThanParentCollectionPermission
    ) {
      permissionDialog.onOpen();
    } else {
      onAfterPermissionUpdated();
    }
  };

  const renderUpdateCollectionPostPermissionAlert = useCallback(() => {
    if (!movedToCollectionRef.current) return null;
    return !permissionDialog.isOpen ? null : (
      <UpdatePostPermissionAlertView
        isOpen={permissionDialog.isOpen}
        onClose={permissionDialog.onClose}
        parentCollection={movedToCollectionRef.current}
        collectionIds={selectedCollectionIds}
        postIds={selectedPostIds}
        onCallback={onAfterPermissionUpdated}
      />
    );
  }, [
    permissionDialog.isOpen,
    permissionDialog.onClose,
    selectedCollectionIds,
    selectedPostIds,
    onAfterPermissionUpdated,
  ]);

  const onMoveCollectionAndPost = useCallback(
    async (toCollection) => {
      movedToCollectionRef.current = toCollection;
      await moveCollectionsToCollection({
        variables: {
          data: {
            collectionIds: selectedCollectionIds,
            toCollectionId: toCollection.id,
          },
        },
      });

      await movePostsToCollection({
        variables: {
          data: {
            postIds: selectedPostIds,
            toCollectionId: toCollection.id,
            fromCollectionId: collectionId,
          },
        },
      });

      await handleOpenPermissionDialog();

      if (toCollection) {
        toast({
          position: 'bottom-center',
          shouldShowCloseButton: false,
          sx: {
            bgcolor: theme.colors?.utility['purple-1'],
            color: theme.colors?.utility['purple-4'],
            borderRadius: theme.spacing(6),
            padding: theme.spacing(5, 6),
            alignItems: 'center',
            '& .MuiButtonBase-root': {
              padding: 0,
            },
          },
          message: '',
          icon: toCollection ? (
            <Box sx={{ width: theme.spacing(12), height: theme.spacing(20) }}>
              <CollectionThumbnail
                sx={{
                  borderRadius: 1,
                  border: `4px solid ${theme.colors?.primary.parchment}`,
                }}
                collection={toCollection}
                showEmptyText={false}
              />
            </Box>
          ) : undefined,
          title: `${
            selectedCollectionIds.length + selectedPostIds.length
          } items moved`,
          actionButtons: [
            {
              variant: 'text',
              children: `Go to collection`,
              onClick: () => {
                navigate(PlotRoutes.collection(toCollection.id));
              },
              sx: {
                color: theme.colors?.primary.black,
                ...theme.typography['headline-sm'],
              },
            },
          ],
        });
      }
      moveDialog.onClose();
    },
    // eslint-disable-next-line
    [
      collectionId,
      moveDialog,
      movePostsToCollection,
      navigate,
      selectedCollectionIds,
      selectedPostIds,
    ],
  );

  const renderMoveToAnotherCollectionModal = useCallback(() => {
    return !moveDialog.isOpen ? null : (
      <PostMoveToAnotherCollection
        key={collectionId}
        currentCollectionId={collectionId}
        isOpen={moveDialog.isOpen}
        onClose={moveDialog.onClose}
        onMove={(toCollection) => {
          if (toCollection) {
            onMoveCollectionAndPost(toCollection);
          }
        }}
        collectionIds={selectedCollectionIds}
        postIds={selectedPostIds}
      />
    );
  }, [
    moveDialog.isOpen,
    collectionId,
    moveDialog.onClose,
    onMoveCollectionAndPost,
    selectedCollectionIds,
    selectedPostIds,
  ]);

  return {
    onMoveCollectionAndPost,
    renderMoveToAnotherCollectionModal,
    showMoveCollection: moveDialog.onOpen,
    hideMoveCollection: moveDialog.onClose,
    renderUpdateCollectionPostPermissionAlert,
  };
};
