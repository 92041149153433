import { Box, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { ExtendedSocialPostTypeForFilter, Platform } from 'graphql/generated';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

interface FilterByPlatformsProps {
  selectedPlatforms: Platform[];
  selectedTypes: ExtendedSocialPostTypeForFilter[];
  type?: 'accordion' | 'normal';
  renderTitle?: () => React.ReactNode;
  onChange: (values: {
    selectedPlatforms: Platform[];
    selectedTypes: ExtendedSocialPostTypeForFilter[];
  }) => void;
}

export const FilterByPlatforms = ({
  selectedPlatforms = [],
  selectedTypes = [],
  type = 'accordion',
  renderTitle,
  onChange,
}: FilterByPlatformsProps) => {
  const Icon =
    socialListeningFilterIconMap[SocialListeningFiltersKey.Platforms];

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    if (type === 'normal') {
      return <Typography variant="subhead-lg">Platforms</Typography>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon size={16} />
        </Box>

        <Typography variant="subhead-lg">Platforms</Typography>
      </Box>
    );
  }, [Icon, renderTitle, type]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      defaultOpen={type === 'normal'}
      sx={{
        ...(type === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(type === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      dropDownOptions={[
        {
          label: Platform.Tiktok,
          value: Platform.Tiktok,
          onClick: () => {
            onChange({
              selectedPlatforms: selectedPlatforms.includes(Platform.Tiktok)
                ? selectedPlatforms.filter((o) => o !== Platform.Tiktok)
                : [...selectedPlatforms, Platform.Tiktok],
              selectedTypes,
            });
          },
          isChecked: selectedPlatforms.includes(Platform.Tiktok),
        },
        {
          label: Platform.Instagram,
          value: Platform.Instagram,
          renderOption: () => {
            return (
              <Box>
                <CheckboxMenuItem
                  label="Instagram"
                  value={Platform.Instagram}
                  checked={selectedPlatforms.includes(Platform.Instagram)}
                  onClick={() => {
                    if (selectedPlatforms.includes(Platform.Instagram)) {
                      onChange({
                        selectedPlatforms: selectedPlatforms.filter(
                          (o) => o !== Platform.Instagram,
                        ),
                        selectedTypes: [],
                      });
                    } else {
                      onChange({
                        selectedPlatforms: [
                          ...selectedPlatforms,
                          Platform.Instagram,
                        ],
                        selectedTypes: Object.values(
                          ExtendedSocialPostTypeForFilter,
                        ),
                      });
                    }
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    pl: 6,
                  }}
                >
                  {(
                    [
                      [ExtendedSocialPostTypeForFilter.Video, 'Reels'],
                      [ExtendedSocialPostTypeForFilter.Photo, 'Photos'],
                      [ExtendedSocialPostTypeForFilter.Carousel, 'Carousel'],
                      [ExtendedSocialPostTypeForFilter.Story, 'Stories'],
                    ] as [ExtendedSocialPostTypeForFilter, string][]
                  ).map(([type, label]) => {
                    return (
                      <CheckboxMenuItem
                        key={type}
                        label={label}
                        value={type}
                        checked={selectedTypes.includes(type)}
                        onClick={() => {
                          onChange({
                            selectedPlatforms: Array.from(
                              new Set([
                                ...selectedPlatforms,
                                Platform.Instagram,
                              ]),
                            ),
                            selectedTypes: selectedTypes.includes(type)
                              ? selectedTypes.filter((o) => o !== type)
                              : [...selectedTypes, type],
                          });
                        }}
                      />
                    );
                  })}
                </Box>
              </Box>
            );
          },
        },
      ]}
    />
  );
};
