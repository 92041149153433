import {
  Badge,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconCustomStory } from 'components/icons/components/custom/IconCustomStory';
import { SortOrder } from 'graphql/generated';
import { SocialListeningFiltersKey } from 'pages/socialMediaListening/constant';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { FilterBy } from './FilterBy/FilterBy';
import { SortBy } from './SortBy/SortBy';
import { SocialMediaListeningFilterSelectionProps } from './types';

export const SocialMediaListeningFilterSelection = ({
  filterByOptions,
  onFilterUpdated,
  sortByOptions,
  onSortUpdated,
  selectedFilters,
  selectedSortBy,
  showExpiredStories,
  setShowExpiredStories,
}: SocialMediaListeningFilterSelectionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // remove the keys that are not needed for the filter count
  const newSelectedFilters = useMemo(() => {
    const filters = { ...selectedFilters };
    delete filters[SocialListeningFiltersKey.ExpiredStories];
    delete filters[SocialListeningFiltersKey.Types];
    return filters;
  }, [selectedFilters]);

  // count the number of filters selected
  const selectedFilterCount = Object.values(newSelectedFilters).reduce<number>(
    (acc, value) => {
      if (Array.isArray(value) && value.length > 0) {
        return acc + 1;
      }
      if (typeof value === 'string' && value.trim() !== '') {
        return acc + 1;
      }
      if (typeof value === 'boolean' && value) {
        return acc + 1;
      }
      if (typeof value === 'number' && value > 0.5) {
        return acc + 1;
      }
      return acc;
    },
    0,
  );

  const isFilterSelected = selectedFilterCount > 0;

  return (
    <>
      <Badge
        badgeContent={selectedFilterCount}
        color="primary"
        invisible={!isFilterSelected}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: theme.colors?.primary.black,
            minWidth: 16,
            height: 16,
            fontSize: 11,
            padding: 0,
          },
        }}
      >
        <Button
          onClick={handleClick}
          sx={{
            display: 'flex',
            backgroundColor:
              theme.colors?.utility[isFilterSelected ? 400 : 275],
            p: 3,
            borderRadius: 3,
            gap: 1,
            alignItems: 'center',
            '&:hover': {
              backgroundColor:
                theme.colors?.utility[isFilterSelected ? 400 : 275],
            },
          }}
        >
          <IconBoldSetting5
            size={12}
            color={theme.colors?.utility[isFilterSelected ? 800 : 700]}
          />
          <Typography
            variant="body-xl"
            fontWeight={isFilterSelected ? 600 : 500}
            color={theme.colors?.utility[isFilterSelected ? 800 : 700]}
          >
            Sort & Filter
          </Typography>
        </Button>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            p: 3,
          },
        }}
      >
        <FilterBy
          selectedFilters={selectedFilters}
          filterByOptions={filterByOptions}
          onFilterUpdated={onFilterUpdated}
        />

        <SortBy
          currentSortBy={selectedSortBy}
          options={sortByOptions}
          onOptionClick={(key) => {
            if (key !== selectedSortBy) {
              onSortUpdated?.(key, SortOrder.Desc);
            }
          }}
        />

        <Divider sx={{ mx: 3 }} />
        <MenuItem
          sx={{
            borderRadius: theme.spacing(3),
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2, 3),
            width: '100%',
            color: theme.colors?.primary.black,
          }}
          disableRipple
          onClick={() => {
            setShowExpiredStories(!showExpiredStories);
          }}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              <IconCustomStory />
            </Box>
            <Typography
              variant="body-lg"
              color={theme.colors?.primary.black}
              fontWeight={500}
            >
              Show expired stories
            </Typography>
          </Box>
          <IconBoldTickCircle
            size={16}
            style={{ opacity: showExpiredStories ? 1 : 0 }}
          />
        </MenuItem>
      </Menu>
    </>
  );
};
