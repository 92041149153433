import { PopoverProps, SxProps } from '@mui/material';
import { SingleSelectFilter } from 'components/common/FilterSelection';
import {
  TaskPriorityButton,
  TaskPriorityLabel,
  taskPriorityOptions,
} from 'features/task';
import { TaskPriority } from 'graphql/generated';

export type Props = {
  componentsProps?: {
    popover?: Partial<PopoverProps>;
    icon?: {
      size?: number;
      color?: string;
    };
    priorityButton?: {
      btnText?: string;
      sx?: SxProps;
    };
  };
  onChange?: (value: TaskPriority) => void;
  value?: TaskPriority;
  showIconOnly?: boolean;
};

export const PrioritySelectButton = ({
  value,
  componentsProps = {},
  onChange,
  showIconOnly,
}: Props) => {
  return (
    <SingleSelectFilter
      shouldShowSearch={false}
      renderTrigger={() =>
        showIconOnly ? (
          <TaskPriorityLabel
            priority={value}
            useShortLabel
            componentsProps={{
              icon: componentsProps.icon,
            }}
          />
        ) : (
          <TaskPriorityButton
            priority={value}
            sx={componentsProps.priorityButton?.sx}
            btnText={componentsProps.priorityButton?.btnText}
            componentsProps={{
              icon: componentsProps?.icon,
            }}
          />
        )
      }
      options={taskPriorityOptions}
      onFilterUpdated={(priority) => onChange?.(priority as TaskPriority)}
      popoverProps={componentsProps.popover}
    />
  );
};
