/**
 * This component handles the attachments that are pending to be created
 * together with a comment. It handles uploading the attachments to Uploadcare.
 */

import { Box, IconButton, SxProps } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { AttachmentPreview, AttachmentPreviewProps } from 'features/attachment';
import { AttachmentFragmentCommentAttachmentListFragment } from 'graphql/generated';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export type CommentAttachmentProps = {
  attachment: AttachmentFragmentCommentAttachmentListFragment;
  sx?: SxProps;
  componentsProps?: {
    attachmentPreview?: Pick<AttachmentPreviewProps, 'sx' | 'componentsProps'>;
  };
  onRemove?: () => void;
};

export const CommentAttachment = (props: CommentAttachmentProps) => {
  const { attachment, sx, componentsProps, onRemove } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { backgroundLocation, secondaryLocation } =
    (location.state as {
      backgroundLocation?: Location;
      secondaryLocation?: Location;
    }) || {};

  return (
    <Box
      sx={{
        ...sx,
        position: 'relative',
        cursor: 'pointer',
        ':hover': {
          '.remove-button': {
            opacity: 1,
          },
        },
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Box
        onClick={() => {
          navigate(PlotRoutes.attachment(attachment.id), {
            state: {
              backgroundLocation: backgroundLocation || location,
              secondaryLocation:
                secondaryLocation ||
                (backgroundLocation ? location : undefined),
            },
          });
        }}
      >
        <AttachmentPreview
          attachment={attachment}
          {...componentsProps?.attachmentPreview}
          sx={{
            height: 52,
            width: 52,
            borderRadius: '8px',
            border: `1px solid ${theme.colors?.utility[275]}`,
            ...componentsProps?.attachmentPreview?.sx,
          }}
        />
      </Box>
      {onRemove && (
        <IconButton
          className="remove-button"
          sx={{
            position: 'absolute',
            top: -8,
            right: -8,
            p: 0,
            width: 16,
            height: 16,
            color: theme.colors?.primary.black,
            opacity: 0,
          }}
          onClick={onRemove}
        >
          <IconBoldCloseCircle size={16} />
        </IconButton>
      )}
    </Box>
  );
};
