import { gql } from '@apollo/client';
import { TextareaAutosize } from '@mui/base';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PlotRoutes } from 'Routes';
import { useUserContext } from 'contexts/users/User.context';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import {
  PostCreationSource,
  PostFragmentNoteTitleFragment,
  useUpdateNoteTitleMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import {
  CSSProperties,
  ChangeEvent,
  FocusEventHandler,
  ForwardedRef,
  KeyboardEventHandler,
  forwardRef,
  useEffect,
} from 'react';
import { useLocation } from 'react-router';

// eslint-disable-next-line
gql`
  mutation UpdateNoteTitle($data: UpdatePostInput!) {
    updatePost(data: $data) {
      id
      title
    }
  }
`;

// Including `color` in the fragment is anti-pattern, but we need this in case we are creating a new note
// with a random initial color
export const POST_FRAGMENT_NOTE_TITLE = gql`
  fragment PostFragmentNoteTitle on PostModel {
    id
    title
    color
  }
`;

export type NoteTitleProps = {
  note: PostFragmentNoteTitleFragment;
  autoFocus?: boolean;
  placeholder?: string;
  readonly?: boolean;
  style?: {
    main?: CSSProperties;
    placeholder?: CSSProperties;
  };
  disableUrlUpdate?: boolean;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  refetchData?: VoidFunction;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
};

export const NoteTitle = forwardRef(
  (props: NoteTitleProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const {
      note,
      autoFocus,
      placeholder,
      readonly,
      style,
      disableUrlUpdate,
      onKeyDown,
      refetchData,
      onFocus,
    } = props;

    const { isMobileAppWebView } = useUserContext();

    const updateUrl = (title: string) => {
      const newUrl = `${window.location.origin}${PlotRoutes.juiceboxNote({
        id: note.id,
        title: title.replaceAll(' ', '-'),
      })}${window.location.search}`;

      window.history.replaceState(null, '', newUrl);
    };

    // update url with note title at load
    useEffect(() => {
      if (!disableUrlUpdate) {
        updateUrl(note.title || '');
      }
    }, []); // eslint-disable-line

    const textAreaPlaceholderStyle = makeStyles({
      textarea: {
        '&::placeholder': {
          ...style?.placeholder,
        },
      },
    });

    const location = useLocation();
    const { taskId } = (location.state as any) || {};
    const { collectionId } = useCollectionIdFromParams();

    const [updateNoteTitle] = useUpdateNoteTitleMutation();
    const onChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
      await updateNoteTitle({
        variables: {
          data: {
            postId: note.id,
            data: {
              collectionId,
              taskId,
              // contentIdeaId,
              title: e.target.value,
              color: note.color,
              source: isMobileAppWebView
                ? PostCreationSource.Mobile
                : PostCreationSource.Web,
            },
          },
        },
        optimisticResponse: {
          updatePost: {
            id: note.id,
            __typename: 'PostModel',
            title: e.target.value,
          },
        },
      });

      // update url param id
      if (!disableUrlUpdate) {
        updateUrl(e.target.value);
      }

      if (note.title === '' && refetchData) {
        refetchData();
      }
    };

    return readonly ? (
      <Typography sx={style?.main}>{note.title}</Typography>
    ) : (
      <TextareaAutosize
        ref={ref}
        autoFocus={autoFocus}
        placeholder={placeholder}
        defaultValue={note.title || ''}
        className={textAreaPlaceholderStyle().textarea}
        style={{
          ...style?.main,
        }}
        onChange={debounce(onChange, 300)}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
      />
    );
  },
);
