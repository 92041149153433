import { gql } from '@apollo/client';
import { IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { toast } from 'components/common/Toast';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import {
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostSaveToContentCalendarButtonFragment,
  useAddPostsToContentCalendarForPostContentCalendarButtonMutation,
} from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { PostPreview } from '../preview';

export const POST_FRAGMENT_POST_SAVE_TO_CONTENT_CALENDAR_BUTTON = gql`
  fragment PostFragmentPostSaveToContentCalendarButton on PostModel {
    id
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation AddPostsToContentCalendarForPostContentCalendarButton(
    $data: AddPostsToContentCalendarInput!
  ) {
    addPostsToContentCalendar(data: $data) {
      message
      success
    }
  }
`;

interface PostSaveToContentCalendarButtonProps {
  renderBtn?: () => React.ReactNode;
  post: PostFragmentPostSaveToContentCalendarButtonFragment;
  canShowToast?: boolean;
  onAfterAddedToContentCalendar?: () => void;
}

export const PostSaveToContentCalendarButton = ({
  renderBtn,
  post,
  canShowToast,
  onAfterAddedToContentCalendar,
}: PostSaveToContentCalendarButtonProps) => {
  const navigate = useNavigate();
  const [addPostsToContentCalendar] =
    useAddPostsToContentCalendarForPostContentCalendarButtonMutation();

  const onAddPostsToContentCalendar = async () => {
    const response = await addPostsToContentCalendar({
      variables: {
        data: {
          data: {
            postIds: [post.id],
          },
        },
      },
    });

    onAfterAddedToContentCalendar?.();

    if (response.data?.addPostsToContentCalendar.success) {
      if (canShowToast) {
        toast({
          position: 'bottom-center',
          shouldShowCloseButton: false,
          sx: {
            bgcolor: theme.colors?.utility['yellow-1'],
            color: theme.colors?.primary.black,
            borderRadius: theme.spacing(4),
            padding: theme.spacing(4),
            alignItems: 'center',
            '& .MuiButtonBase-root': {
              padding: 0,
            },
          },
          message: '',
          title: 'Added!',
          icon: (
            <PostPreview
              post={post}
              sx={{
                width: 55,
                height: 85,
                borderRadius: 3,
                overflow: 'hidden',
                border: `4px solid ${theme.colors?.primary.white}`,
              }}
            />
          ),
          actionButtons: [
            {
              variant: 'text',
              children: `Go to content calendar`,
              onClick: () => {
                navigate(PlotRoutes.contentCalendar());
              },
              sx: {
                color: theme.colors?.utility['yellow-4'],
                padding: 0,
              },
            },
          ],
        });
      }
    } else {
      toast({
        message: 'Failed to add post to content calendar',
        type: 'error',
      });
    }
  };

  return renderBtn ? (
    <IconButton
      disableRipple
      sx={{
        padding: 0,
      }}
      onClick={onAddPostsToContentCalendar}
    >
      {renderBtn()}
    </IconButton>
  ) : (
    <IconButton
      sx={{
        padding: theme.spacing(1.5, 3),
        bgcolor: theme.colors?.utility['yellow-1'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
        width: 'fit-content',
        gap: 1,
      }}
      disableRipple
      onClick={onAddPostsToContentCalendar}
    >
      <IconOutlineCalendar size={16} color={theme.colors?.primary.black} />
      <Typography variant="headline-xs" color={theme.colors?.primary.black}>
        Add to Content Calendar
      </Typography>
    </IconButton>
  );
};
