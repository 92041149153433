import { gql } from '@apollo/client';
import {
  useRemovePostsFromCollectionsForCollectionSendToCollectionFormMutation,
  useSavePostsToCollectionsForCollectionSendToCollectionFormMutation,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  mutation SavePostsToCollectionsForCollectionSendToCollectionForm(
    $data: SavePostsToCollectionsInput!
  ) {
    savePostsToCollections(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation RemovePostsFromCollectionsForCollectionSendToCollectionForm(
    $data: RemovePostsFromCollectionsInput!
  ) {
    removePostsFromCollections(data: $data) {
      success
      message
    }
  }
`;

export const usePostHandlers = () => {
  const [savePostsToCollections] =
    useSavePostsToCollectionsForCollectionSendToCollectionFormMutation();
  const [removePostsFromCollections] =
    useRemovePostsFromCollectionsForCollectionSendToCollectionFormMutation();

  const onSavePostsToCollections = async (
    postIds: string[],
    collectionIds: string[],
  ) => {
    return savePostsToCollections({
      variables: {
        data: {
          postIds,
          collectionIds,
        },
      },
    });
  };

  const onRemovePostsFromCollections = async (
    postIds: string[],
    collectionIds: string[],
  ) => {
    return removePostsFromCollections({
      variables: {
        data: {
          postIds,
          collectionIds,
        },
      },
    });
  };

  return {
    onSavePostsToCollections,
    onRemovePostsFromCollections,
  };
};
