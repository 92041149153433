import { Box, Divider, IconButton } from '@mui/material';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import {
  TaskPermission,
  ViewEntityType,
  useGetTaskForTaskDetailViewQuery,
  useViewEntityForTaskDetailViewMutation,
} from 'graphql/generated';
import { useTaskPermissions } from 'hooks/permissions/useTaskPermissions';
import { useEffect } from 'react';
import { theme } from 'styles/theme';
import { TaskDetailViewSkeleton } from './TaskDetailViewSkeleton';
import { NestedTaskNavigationContextProvider } from './contexts';
import { useSubtaskHandlers, useTaskHandlers } from './hooks';
import {
  MediaSection,
  TaskHeadingSection,
  TaskLocationNavigatorSection,
  TaskSubtaskSection,
} from './sections';
import { TaskDetailViewProps } from './types';

export const TaskDetailView = (props: TaskDetailViewProps) => {
  const {
    taskId,
    hideLocationNavigator,
    sx,
    onAfterTaskDelete,
    onGoBackOutOfStack,
  } = props;

  const { data } = useGetTaskForTaskDetailViewQuery({
    variables: {
      id: taskId || '',
    },
    skip: !taskId,
    // FIXME: Work around for revalidating list of resources
    // I'm not sure why evicting a resource from the cache doesn't work
    fetchPolicy: 'cache-and-network',
  });
  const task = data?.task;
  const myPermissions = useTaskPermissions(task?.id);
  const canUpdate = myPermissions.includes(TaskPermission.Update);

  const [viewTask] = useViewEntityForTaskDetailViewMutation();
  useEffect(() => {
    if (taskId) {
      viewTask({
        variables: {
          data: {
            entityId: taskId,
            entityType: ViewEntityType.Task,
          },
        },
      });
    }
  }, []); // eslint-disable-line -- run once on mount

  // These handlers got very long so I put them in separate hooks
  const { onTaskUpdate, onTaskDelete, taskDeleteConfirmationDialog } =
    useTaskHandlers({
      task,
      onAfterTaskDelete,
    });

  const { onSubtaskCreate, onSubtaskUpdate, onSubtaskDelete } =
    useSubtaskHandlers({ task });

  // NOTE: Not allowing subtasks of subtasks for now
  const isSubtask = Boolean(task?.parentTaskId);

  return (
    <NestedTaskNavigationContextProvider
      onGoBackOutOfStack={onGoBackOutOfStack}
    >
      {!task ? (
        <TaskDetailViewSkeleton />
      ) : (
        <Box
          key={task.id}
          display="flex"
          flexDirection="column"
          bgcolor="white"
          width="100%"
          padding={8}
          gap={6}
          borderRadius="inherit"
          sx={sx}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            gap={2}
            mb={-2}
          >
            {!hideLocationNavigator && (
              <TaskLocationNavigatorSection task={task} />
            )}
            {onTaskDelete && (
              <IconButton
                size="small"
                onClick={onTaskDelete}
                sx={{
                  color: theme.colors?.utility[500],
                  flexShrink: 0,
                }}
              >
                <IconLinearTrash />
              </IconButton>
            )}
          </Box>
          <TaskHeadingSection
            task={task}
            onTaskUpdate={canUpdate ? onTaskUpdate : undefined}
          />
          {!isSubtask ? (
            <>
              <Divider
                sx={{
                  borderBottom: `3px solid ${theme.colors?.utility[300]}`,
                }}
              />
              <TaskSubtaskSection
                onSubtaskCreate={canUpdate ? onSubtaskCreate : undefined}
                onSubtaskUpdate={canUpdate ? onSubtaskUpdate : undefined}
                onSubtaskDelete={canUpdate ? onSubtaskDelete : undefined}
                task={task}
                subtasks={task.subtasks}
              />
            </>
          ) : null}
          <Divider
            sx={{ borderBottom: `3px solid ${theme.colors?.utility[300]}` }}
          />
          <MediaSection task={task} />
        </Box>
      )}
      {taskDeleteConfirmationDialog}
    </NestedTaskNavigationContextProvider>
  );
};
