import { Components, Theme } from '@mui/material';
import { IconBoldMinusSquare } from 'components/icons/components/bold/IconBoldMinusSquare';
import { IconBoldTickSquare } from 'components/icons/components/bold/IconBoldTickSquare';
import { IconOutlineSquare } from 'components/icons/components/outline/IconOutlineSquare';

export const checkboxOverrides: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    icon: <IconOutlineSquare />,
    checkedIcon: <IconBoldTickSquare />,
    indeterminateIcon: <IconBoldMinusSquare />,
  },
  styleOverrides: {
    root: ({ theme }) => {
      return {
        borderRadius: 16,
        '&.Mui-checked': {
          color: theme.colors?.primary.black,
        },
        '&.MuiCheckbox-indeterminate': {
          color: theme.colors?.utility[800],
        },
        '&.Mui-disabled': {
          color: theme.colors?.utility[500],
        },
      };
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        '& svg': {
          width: 20,
          height: 20,
        },
      },
    },
  ],
};
