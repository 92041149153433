import { Box } from '@mui/material';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { typography } from 'components/common/Typography/styles';
import { IconOutlineTickSquare } from 'components/icons/components/outline/IconOutlineTickSquare';
import { SearchableEntityType } from 'graphql/generated';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme/theme';

export const MentionNodeView = ({ node }: NodeViewProps) => {
  const location = useLocation();
  const state = useMemo(
    () =>
      location.state as { backgroundLocation?: Location; redirect?: boolean },
    [location],
  );

  const [type, setType] = React.useState<SearchableEntityType | null>(null);
  const [name, setName] = React.useState<string>('');
  const [itemId, setItemId] = React.useState<string>('');

  React.useEffect(() => {
    setType(node.attrs['type']);
    setName(node.attrs['name']);
    setItemId(node.attrs['id']);
  }, [node.attrs]);

  const renderTagPrefix = React.useCallback(() => {
    const iconSize = 16;
    const iconColor = theme.colors?.primary.maroon;

    const iconAlignStyle: React.CSSProperties = {
      alignSelf: 'flex-end',
      position: 'absolute',
      left: 0,
    };

    if (type === SearchableEntityType.Tasks) {
      return (
        <IconOutlineTickSquare
          size={iconSize}
          color={iconColor}
          style={iconAlignStyle}
        />
      );
    }

    if (type === SearchableEntityType.Users) {
      return <Box component="span">@</Box>;
    }

    return '';
  }, [type]);

  const { href, useBackgroundLocation } = useMemo(() => {
    let useBackgroundLocation = false;
    let href = '';

    switch (type) {
      case SearchableEntityType.Tasks: {
        useBackgroundLocation = true;
        href = `/tasks/${itemId}`;
        break;
      }
      default: {
        break;
      }
    }

    return { href, useBackgroundLocation };
  }, [type, itemId]);

  if (!name || !type) return;

  return (
    <NodeViewWrapper as="span">
      <Link
        to={{ pathname: href }}
        target={!useBackgroundLocation ? '_blank' : undefined}
        state={
          useBackgroundLocation
            ? { backgroundLocation: state?.backgroundLocation || location }
            : undefined
        }
        className="mention-node"
        style={{
          ...typography['body-xl'],
          textDecoration: 'underline',
          display: 'inline-flex',
          color: theme.colors?.primary.maroon,
          fontSize: 'inherit !important',
          position: 'relative',
          paddingLeft:
            type === SearchableEntityType.Users ? 0 : theme.spacing(4.5),
        }}
      >
        {renderTagPrefix()}
        {name}
      </Link>
    </NodeViewWrapper>
  );
};
