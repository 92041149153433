import { gql } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import { useGetRecentSearchQueriesForJuiceboxRecentSearchResultQuery } from 'graphql/generated';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { MutableRefObject } from 'react';
import { JuiceboxRecentSearchItem } from '../searchItem';
import { JuiceboxSearchResultHeader } from '../searchResultHeader';

gql`
  query GetRecentSearchQueriesForJuiceboxRecentSearchResult(
    $take: Int
    $after: String
  ) {
    getRecentSearchQueries(take: $take, after: $after) {
      data {
        query
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

interface Props {
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
  onItemSelect: (query: string) => void;
}

export const JuiceboxRecentSearchResult = ({ elRefs, onItemSelect }: Props) => {
  const variables = {
    take: 20,
  };
  const { data: recentsSearchData, fetchMore } =
    useGetRecentSearchQueriesForJuiceboxRecentSearchResultQuery({
      variables,
    });

  return (
    <>
      <JuiceboxSearchResultHeader title="Recent Searches" />
      <InfiniteScroll
        style={{
          flexDirection: 'column',
          display: 'flex',
          gap: theme.spacing(1),
          marginTop: theme.spacing(-2),
        }}
        threshold={100}
        loadMore={() => {
          if (!recentsSearchData?.getRecentSearchQueries.pageInfo.hasNextPage) {
            return;
          }

          fetchMore({
            variables: {
              ...variables,
              after:
                recentsSearchData?.getRecentSearchQueries.pageInfo.endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              const newQueries =
                fetchMoreResult.getRecentSearchQueries.data || [];
              const pageInfo = fetchMoreResult.getRecentSearchQueries.pageInfo;

              return {
                ...prev,
                getRecentSearchQueries: {
                  ...fetchMoreResult.getRecentSearchQueries,
                  data: [
                    ...(prev.getRecentSearchQueries.data || []),
                    ...newQueries,
                  ],
                  pageInfo: {
                    hasNextPage: pageInfo.hasNextPage,
                    endCursor: pageInfo.endCursor,
                  },
                },
              };
            },
          });
        }}
        useWindow={false}
        hasMore={recentsSearchData?.getRecentSearchQueries.pageInfo.hasNextPage}
        loader={
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress
              size={24}
              sx={{ color: theme.colors?.primary.maroon }}
            />
          </Box>
        }
      >
        {recentsSearchData?.getRecentSearchQueries.data.map(
          ({ query }, index) => {
            return query ? (
              <JuiceboxRecentSearchItem
                key={index}
                query={query}
                ref={(el: HTMLElement) => {
                  elRefs.current[index] = el;
                }}
                onItemSelect={onItemSelect}
              />
            ) : null;
          },
        )}
      </InfiniteScroll>
    </>
  );
};
