import TiptapLink, { LinkOptions } from '@tiptap/extension-link';
import { ClickPlugin, HoverPlugin, PastePlugin } from './plugins';

export type LinkCustomOptions = LinkOptions & {
  showTooltip: boolean;
};

/**
 * This is a custom extension on the default tiptap's link extension,
 * to support additional features:
 * 1. Render a follow-up popover after user paste a link into the editor
 * 2. Render a tooltip on hover to allow user to edit the link
 */
export const Link = TiptapLink.extend<LinkCustomOptions>({
  addOptions() {
    // Extend the default options
    return {
      ...this.parent?.(),
      showTooltip: true,
    };
  },

  addProseMirrorPlugins() {
    const editor = this.editor;
    const originalPlugins = this.parent?.() || [];

    return [
      ...originalPlugins.filter(
        (plugin) =>
          // @ts-ignore
          !plugin.key?.startsWith('handleClick') &&
          // @ts-ignore
          !plugin.key?.startsWith('handlePaste'),
      ),
      ClickPlugin(),
      PastePlugin(),
      ...(this.options.showTooltip ? [HoverPlugin(editor)] : []),
    ];
  },
});
