import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { CollaborationEditor } from 'features/collaboration';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import {
  NoteColor,
  NoteRichTextEditor,
  NoteRichTextEditorMode,
  NoteRichTextEditorProps,
  NoteRichTextEditorRef,
  NoteRichTextEditorSkeleton,
  NoteTitle,
  NoteTitleProps,
  getNoteColorFromColor,
  isNoteEmpty,
} from 'features/note';
import { BOTTOM_PADDING } from 'features/note/components/richTextEditor/NoteRichTextEditor';
import { NOTE_COLORS } from 'features/note/constants/noteColors';
import { PostCollaborativeViewers } from 'features/post';
import {
  PostCreationSource,
  PostFragmentCollaborativeNoteViewFragment,
  PostPermission,
  useUpdateNoteBodyMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import { NoteToolBar } from '../../components/richTextEditor/components';

// eslint-disable-next-line
gql`
  mutation UpdateNoteBody($data: UpdatePostInput!) {
    updatePost(data: $data) {
      id
      body
    }
  }
`;

export interface CollaborativeNoteViewRef {
  title: HTMLTextAreaElement | null;
  content: NoteRichTextEditorRef | null;
  focusTitle: HTMLTextAreaElement['focus'];
  focusContent: NoteRichTextEditorRef['focus'];
}

export type CollaborativeNoteViewProps = {
  note: PostFragmentCollaborativeNoteViewFragment;
  refetchData?: VoidFunction;
  componentsProps?: {
    title?: Omit<NoteTitleProps, 'note' | 'onKeyDown' | 'onCreateNote'>;
    content?: Omit<
      NoteRichTextEditorProps,
      'note' | 'onKeyDown' | 'onCreateNote'
    >;
  };
};

export const CollaborativeNoteView = forwardRef(
  (
    props: CollaborativeNoteViewProps,
    ref: ForwardedRef<CollaborativeNoteViewRef>,
  ) => {
    const { note, refetchData, componentsProps = {} } = props;

    // Check if the note is editable
    // Note that even with Comment permission, the note is functionality-wise editable
    // because in order for the comment extension to work, the editor must be editable
    const isNoteEditable = note.myPermissions?.includes(PostPermission.Update);
    const isNoteCommentable = note.myPermissions?.includes(
      PostPermission.Comment,
    );

    const themeColor = useMemo(() => {
      return getNoteColorFromColor(note.color) || NOTE_COLORS[0];
    }, [note.color]);

    const titleRef = useRef<HTMLTextAreaElement>(null);
    const contentRef = useRef<NoteRichTextEditorRef>(null);

    useImperativeHandle<CollaborativeNoteViewRef, CollaborativeNoteViewRef>(
      ref,
      () => ({
        focusTitle: (options) => {
          titleRef.current?.focus(options);
        },
        focusContent: (...props) => {
          contentRef.current?.focus(...props);
        },
        get title() {
          return titleRef.current;
        },
        get content() {
          return contentRef.current;
        },
      }),
    );

    const roomId = useMemo(() => {
      if (isNoteEmpty(note)) {
        // this is at the empty note stage
        return null;
      }

      if (note.roomId) {
        // liveblocks room is already created and connected
        return note.roomId;
      }

      // liveblocks room is not yet created
      return `note-${note.id}`;
    }, [note]);

    const handleContentKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'ArrowUp') {
        const { selection } = contentRef.current?.editor?.state || {};
        if (selection?.$from.parentOffset === 0 && selection?.$head.pos < 2) {
          titleRef.current?.focus();
        }
      }

      // Check if we need to scroll the container when user press enter
      // This aims to keep the cursor higher than the bottom of the container
      if (e.key === 'Enter') {
        // See NoteDetailContentView for where we are rendering that.
        // I feel like we should move it into this component & we won't have to use querySelector like this.
        const scrollableContainer = document.querySelector(
          '.note-view-container',
        );

        if (scrollableContainer) {
          const scrollBottom =
            scrollableContainer.scrollTop + scrollableContainer.clientHeight;

          if (
            scrollBottom >
            scrollableContainer.scrollHeight - BOTTOM_PADDING
          ) {
            scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
          }
        }
      }
    };

    const handleTitleKeyDown = (
      e: React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
      if (e.key === 'Enter') {
        // prevent from default behavior
        e.preventDefault();
      }
      // click enter on post title should jump into post body, not adding a new line to the title.
      if (['ArrowDown', 'Enter'].includes(e.key)) {
        // const textarea = ref.current?.title;
        if (!titleRef) return;

        const currentLine = titleRef.current?.value
          .substring(0, titleRef.current?.selectionStart)
          .split('\n').length;
        const totalLines = titleRef.current?.value.split('\n').length;

        if (currentLine === totalLines) {
          // Pressed Arrow Down at the last line
          contentRef.current?.focus('start', { scrollIntoView: true });
        }
      }
    };

    const location = useLocation();
    const { taskId } = (location.state as any) || {};
    const { collectionId } = useCollectionIdFromParams();
    const { isMobileAppWebView } = useUserContext();

    const [updateNoteBody] = useUpdateNoteBodyMutation();
    const handleUpdateBody = useMemo(
      () =>
        debounce(async (body: string) => {
          if (body !== note.body) {
            await updateNoteBody({
              variables: {
                data: {
                  postId: note.id,
                  data: {
                    collectionId,
                    taskId,
                    // contentIdeaId,
                    body,
                    color: note.color,
                    source: isMobileAppWebView
                      ? PostCreationSource.Mobile
                      : PostCreationSource.Web,
                  },
                },
              },
              optimisticResponse: {
                updatePost: {
                  id: note.id,
                  __typename: 'PostModel',
                  body,
                },
              },
            });
          }

          if (refetchData && isNoteEmpty(note)) {
            refetchData();
          }
        }, 1000),
      [collectionId, note, refetchData, updateNoteBody, taskId], // eslint-disable-line
    );

    const shouldAutoFocusTitle = useMemo(() => {
      return note && !note.body;
    }, [note]);

    return (
      <Box
        key={note.id}
        className="note-view"
        sx={{
          px: 6,
          mt: 'auto',
          mb: 'auto',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
          '.note-view-title': {
            width: '100%',
            maxWidth: 740,
          },
        }}
        gap={4}
      >
        <Box
          className="note-view-title"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <NoteTitle
            note={note}
            ref={titleRef}
            readonly={!isNoteEditable}
            placeholder="Don't overthink it..."
            autoFocus={shouldAutoFocusTitle}
            onFocus={(event) => {
              if (shouldAutoFocusTitle) {
                // Select all text when the title is focused
                event.target.select();
              }
            }}
            onKeyDown={handleTitleKeyDown}
            refetchData={refetchData}
            style={{
              main: {
                ...theme.typography['headline-xl'],
                backgroundColor: 'transparent',
                borderStyle: 'none',
                borderColor: 'transparent',
                overflow: 'auto',
                outline: 'none',
                color: themeColor.textColor,
                resize: 'none',
                padding: 0,
                width: '100%',
              },
              placeholder: {
                color: theme.colors?.utility[600],
              },
            }}
          />
        </Box>

        <CollaborationEditor
          roomId={roomId}
          fallBack={<NoteRichTextEditorSkeleton />}
          renderOnlineUsers={(onlineUsers) => {
            return (
              <PostCollaborativeViewers
                postId={note.id}
                onlineUsers={onlineUsers}
              />
            );
          }}
          renderEditor={(collaborationExtensions, provider) => {
            return (
              <NoteRichTextEditor
                key={note.id}
                ref={contentRef}
                provider={provider}
                extraExtensions={collaborationExtensions}
                className="note-view-body"
                defaultContent={note.body}
                themeColor={themeColor}
                placeholder="A place to draft your juicy ideas. Press '/' for commands..."
                onKeyDown={handleContentKeyDown}
                {...componentsProps.content}
                editable={
                  componentsProps.content?.editable &&
                  // Functionality-wise, the note is editable even if the user only has comment permission
                  // because the comment extension requires the editor to be editable
                  (isNoteEditable || isNoteCommentable)
                }
                componentsProps={componentsProps.content?.componentsProps}
                onContentChange={handleUpdateBody}
                mode={
                  !isNoteEditable && isNoteCommentable
                    ? NoteRichTextEditorMode.CommentOnly
                    : NoteRichTextEditorMode.Full
                }
                renderToolbar={(editor, isBubbleMenuVisible) => {
                  return isNoteEditable || !isNoteCommentable ? (
                    <NoteToolBar
                      isMobileApp={isMobileAppWebView}
                      editor={editor}
                      themeColor={themeColor}
                      noteId={note?.id}
                      collectionId={collectionId}
                      extraOptions={[
                        {
                          icon: (
                            <NoteColor
                              note={note}
                              defaultValue={NOTE_COLORS[0]}
                              refetchData={refetchData}
                            />
                          ),
                        },
                      ]}
                      sx={{
                        // FIXME: This should not be "fixed", but relatively positioned to the container
                        position: 'fixed',
                        alignSelf: 'center',
                        bottom: isBubbleMenuVisible ? -200 : theme.spacing(6),
                        transition: 'all 0.3s',
                        visibility: isBubbleMenuVisible ? 'hidden' : 'visible',
                      }}
                    />
                  ) : null;
                }}
              />
            );
          }}
        />
      </Box>
    );
  },
);
