/**
 * This hook is generated by ChatGPT.
 */

import { useState, useEffect, useRef, useCallback } from 'react';
import { mouseMoveManager } from './MouseMoveManager';

export type UseSmartHoverProps = {
  /**
   * The time in milliseconds to wait before considering the user is truly hovering onto the element.
   */
  enterDelay?: number;

  /**
   * The time in milliseconds to wait before considering the user is truly leaving the element.
   */
  leaveDelay?: number;

  /**
   * The threshold in pixels to consider the user is moving the mouse.
   */
  movementThreshold?: number;
};

export const useSmartHover = (props: UseSmartHoverProps = {}) => {
  const { enterDelay = 80, leaveDelay = 80, movementThreshold = 5 } = props;

  const [isMoving, setIsMoving] = useState(false);
  const lastMousePosRef = useRef<{
    x: number;
    y: number;
  } | null>(null);
  const movementTimeout = useRef<NodeJS.Timeout | null>(null);

  const [isHovered, setIsHovered] = useState(false);
  const isHoveredRef = useRef(isHovered);
  isHoveredRef.current = isHovered;

  const enterTimeout = useRef<NodeJS.Timeout | null>(null);
  const leaveTimeout = useRef<NodeJS.Timeout | null>(null);

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      const currentMousePos = { x: event.clientX, y: event.clientY };

      if (lastMousePosRef.current) {
        const deltaX = currentMousePos.x - lastMousePosRef.current.x;
        const deltaY = currentMousePos.y - lastMousePosRef.current.y;
        const delta = Math.sqrt(deltaX ** 2 + deltaY ** 2);

        if (delta > movementThreshold && !isHoveredRef.current) {
          setIsMoving(true);
        }
      }

      lastMousePosRef.current = currentMousePos;

      if (movementTimeout.current) {
        clearTimeout(movementTimeout.current);
      }
      movementTimeout.current = setTimeout(() => {
        setIsMoving(false);
      }, enterDelay);
    },
    [enterDelay, movementThreshold],
  );

  const onMouseEnter = useCallback(
    (e: React.MouseEvent) => {
      if (leaveTimeout.current) {
        clearTimeout(leaveTimeout.current);
      }

      enterTimeout.current = setTimeout(() => {
        setIsHovered(true);
      }, enterDelay);
    },
    [enterDelay],
  );

  const onMouseLeave = useCallback(
    (e: React.MouseEvent) => {
      if (enterTimeout.current) {
        clearTimeout(enterTimeout.current);
      }

      leaveTimeout.current = setTimeout(() => {
        setIsHovered(false);
      }, leaveDelay);
    },
    [leaveDelay],
  );

  useEffect(() => {
    mouseMoveManager.addListener(onMouseMove);
    return () => {
      mouseMoveManager.removeListener(onMouseMove);
    };
  }, [onMouseMove, onMouseEnter, onMouseLeave]);

  const isTrulyHovered = isHovered && !isMoving;

  return {
    isHovered: isTrulyHovered,
    onMouseEnter,
    onMouseLeave,
  };
};
