import { gql } from '@apollo/client';
import { Avatar, Box, SxProps, Tooltip, Typography } from '@mui/material';
import { USER_FRAGMENT_AVATAR_GROUP } from 'components/common/AvatarGroup/types';
import { PostFragmentPostCreatorFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

export const POST_FRAGMENT_POST_CREATOR = gql`
  fragment PostFragmentPostCreator on PostModel {
    id
    creator {
      id
      ...UserFragmentAvatarGroup
    }
  }
  ${USER_FRAGMENT_AVATAR_GROUP}
`;

export type PostCreatorProps = {
  post: PostFragmentPostCreatorFragment;
  sx?: SxProps;
};

export const PostCreator = (props: PostCreatorProps) => {
  const { post, sx } = props;

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
          },
        },
      }}
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            py: 2,
            px: 3,
            borderRadius: 1,
            bgcolor: theme.colors?.primary.black,
          }}
        >
          <Typography variant="subhead-sm" sx={{ opacity: 0.6 }}>
            Added by
          </Typography>
          <Typography variant="subhead-lg" textAlign="center">
            {getFullName(post.creator)}
          </Typography>
        </Box>
      }
    >
      <Avatar
        sx={{ width: 24, height: 24, ...sx }}
        src={post.creator.avatarUrl || ''}
        alt={getFullName(post.creator)}
      />
    </Tooltip>
  );
};
