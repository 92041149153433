// return the scale based on width and height of the element

interface Props {
  viewportWidth: number;
  viewportHeight: number;
  maxOriginalBoundingBoxWidth: number;
  maxOriginalBoundingBoxHeight: number;
}

export const getScale = ({
  maxOriginalBoundingBoxHeight,
  maxOriginalBoundingBoxWidth,
  viewportHeight,
  viewportWidth,
}: Props) => {
  // Calculate the scale required to fit the content width and height within the adjusted viewport width and height
  const scaleToFitWidth = viewportWidth / maxOriginalBoundingBoxWidth;
  const scaleToFitHeight = viewportHeight / maxOriginalBoundingBoxHeight;

  // Choose the smaller scale to ensure the content fits within the adjusted viewport
  const scale = Math.min(scaleToFitWidth, scaleToFitHeight);

  return scale; // Return the calculated scale
};
