import { Box, BoxProps, Typography } from '@mui/material';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { theme } from 'styles/theme/theme';
import { StyledCircle } from './styles';

export const EmptySearchResult = ({ ...rest }: BoxProps) => {
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      alignItems="center"
      py={5}
      gap={3}
      {...rest}
    >
      <StyledCircle p={5}>
        <IconOutlineSearchNormal1 />
      </StyledCircle>
      <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
        <Typography variant="headline-sm" color={theme.colors?.utility[1000]}>
          No result found
        </Typography>
        <Typography variant="subhead-xl" color={theme.colors?.utility[800]}>
          Try using different keywords.
        </Typography>
      </Box>
    </Box>
  );
};
