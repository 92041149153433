/**
 * This component is a temporary solution to display the publish URLs in the ContentIdeaDetailView.
 * It should be removed once we move on to the complete Phase 3 implementation.
 */

import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { platformIconMap } from 'features/socialPost';
import {
  ContentIdeaPublishMetadataFragmentContentIdeaPublishMetadataPublishUrlCustomFieldFragment,
  useUpdateContentIdeaPublishMetadataForContentIdeaPublishMetadataPublishUrlCustomFieldMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { TextField } from '../field';

export const CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PUBLISH_METADATA_URL_CUSTOM_FIELD = gql`
  fragment ContentIdeaPublishMetadataFragmentContentIdeaPublishMetadataPublishUrlCustomField on ContentIdeaPublishMetadataModel {
    id
    platform
    publishUrl
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaPublishMetadataForContentIdeaPublishMetadataPublishUrlCustomField(
    $data: UpdateContentIdeaPublishMetadataInput!
  ) {
    updateContentIdeaPublishMetadata(data: $data) {
      id
      ...ContentIdeaPublishMetadataFragmentContentIdeaPublishMetadataPublishUrlCustomField
    }
  }
  ${CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PUBLISH_METADATA_URL_CUSTOM_FIELD}
`;

export type ContentIdeaPublishMetadataPublishUrlCustomFieldProps = {
  contentIdeaPublishMetadata: ContentIdeaPublishMetadataFragmentContentIdeaPublishMetadataPublishUrlCustomFieldFragment;
};

export const ContentIdeaPublishMetadataPublishUrlCustomField = (
  props: ContentIdeaPublishMetadataPublishUrlCustomFieldProps,
) => {
  const { contentIdeaPublishMetadata } = props;

  const [updateContentIdeaPublishMetadata] =
    useUpdateContentIdeaPublishMetadataForContentIdeaPublishMetadataPublishUrlCustomFieldMutation();

  const Icon = platformIconMap[contentIdeaPublishMetadata.platform];

  return (
    <Box
      key={contentIdeaPublishMetadata.id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box sx={{ width: 128 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1.5,
            alignItems: 'center',
            color: theme.colors?.utility[700],
            textAlign: 'left',
          }}
        >
          {Icon && <Icon size={16} />}
          <Typography variant="subhead-lg">
            {contentIdeaPublishMetadata.platform} URL
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          p: 2,
          '&:hover': {
            bgcolor: theme.colors?.utility['250'],
          },
          borderRadius: 3,
        }}
      >
        <TextField
          value={contentIdeaPublishMetadata.publishUrl || ''}
          onChange={(value) => {
            updateContentIdeaPublishMetadata({
              variables: {
                data: {
                  contentIdeaPublishMetadataId: contentIdeaPublishMetadata.id,
                  data: {
                    publishUrl: value,
                  },
                },
              },
            });
          }}
        />
      </Box>
    </Box>
  );
};
