import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

type Props = {
  label: string;

  componentProps?: {
    sx?: SxProps;
  };
};

const generateColorsSx = (label: string) => {
  const colors = [
    {
      backgroundColor: theme.colors?.utility['blue-1'],
      color: theme.colors?.utility.blueberry,
    },
    {
      backgroundColor: theme.colors?.utility['purple-1'],
      color: theme.colors?.utility['purple-4'],
    },
    {
      backgroundColor: theme.colors?.utility['green-1'],
      color: theme.colors?.utility['green-4'],
    },
    {
      backgroundColor: theme.colors?.utility['orange-1'],
      color: theme.colors?.utility['orange-4'],
    },
    {
      backgroundColor: theme.colors?.utility['yellow-1'],
      color: theme.colors?.utility['yellow-4'],
    },
  ];

  const colorIndex =
    label.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) %
    colors.length;
  return colors[colorIndex];
};

export const CompetitorLabel = ({ label, componentProps }: Props) => {
  return (
    <Typography
      variant="body-xl"
      fontWeight={600}
      sx={{
        padding: theme.spacing(1, 2),
        borderRadius: 1,
        backgroundColor: generateColorsSx(label).backgroundColor,
        color: generateColorsSx(label).color,
        cursor: 'pointer',
        ...componentProps?.sx,
      }}
    >
      {label}
    </Typography>
  );
};
