import { Box, Divider, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { StyleInput, StyledPopover } from './styles';
import { SingleSelectFilterProps } from './types';

export const SingleSelectFilter = ({
  options,
  renderTrigger,
  popoverProps,
  inputProps,
  onQueryChange,
  onFilterUpdated,
  placeholder,
  shouldShowSearch = true,
  readOnly,
}: SingleSelectFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [query, setQuery] = useState('');
  const debouncedSetQuery = useMemo(
    () =>
      debounce((q: string) => {
        setQuery(q);
      }, 300),
    [onQueryChange],
  );

  // Filter the options by query
  const filteredOptions = useMemo(() => {
    return options.filter((o) => {
      return o.label.toLowerCase().includes(query.toLowerCase());
    });
  }, [query, options]);

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        component="button"
        type="button"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        disabled={readOnly}
      >
        {renderTrigger()}
      </Box>
      <StyledPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...popoverProps}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        {shouldShowSearch && (
          <>
            <StyleInput
              disableUnderline
              placeholder={placeholder || 'Filter by'}
              autoFocus
              {...inputProps}
              onChange={(e) => debouncedSetQuery(e.target.value)}
            />
            <Divider sx={{ borderColor: theme.colors?.utility[300], my: 2 }} />
          </>
        )}
        <Box sx={{ maxHeight: 300, overflowX: 'auto' }}>
          {!filteredOptions.length ? (
            <Typography
              variant="body-md"
              color={theme.colors?.utility[600]}
              px={3}
            >
              No options
            </Typography>
          ) : (
            filteredOptions.map((option, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    cursor: option.isDisabled ? 'auto' : 'pointer',
                    position: 'relative',
                  }}
                  onClick={() => {
                    if (option.isDisabled) {
                      return;
                    }
                    onFilterUpdated(option.value);
                    onClose();
                  }}
                >
                  <>{option.render()}</>
                </Box>
              );
            })
          )}
        </Box>
      </StyledPopover>
    </>
  );
};
