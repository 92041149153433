import { Box } from '@mui/material';
import { theme } from 'styles/theme';
import { gql } from '@apollo/client';
import { USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW } from 'features/user-profile';
import { PermissionSelector, PermissionMemberItem } from 'features/permission';
import { SuggestedInviteMemberFragmentPostPermissionSuggestedMemberFragment } from 'graphql/generated';

export const SUGGESTED_INVITE_MEMBER_FRAGMENT_POST_PERMISSION_SUGGESTED_MEMBER = gql`
  fragment SuggestedInviteMemberFragmentPostPermissionSuggestedMember on SuggestedInviteMember {
    permissionLevel
    user {
      ...UserProfileFragmentUserProfilePermissionItemView
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW}
`;

type PostPermissionSuggestedMemberProps = {
  entityOrganizationId: string;
  suggestedInviteMember: SuggestedInviteMemberFragmentPostPermissionSuggestedMemberFragment;
  onPermissionChange: (
    suggestedInviteMember: SuggestedInviteMemberFragmentPostPermissionSuggestedMemberFragment,
    remove?: boolean,
  ) => void;
};

export const PostPermissionSuggestedMember = (
  props: PostPermissionSuggestedMemberProps,
) => {
  const { entityOrganizationId, suggestedInviteMember, onPermissionChange } =
    props;

  return (
    <PermissionMemberItem
      userProfile={suggestedInviteMember.user}
      entityOrganizationId={entityOrganizationId}
      type="custom"
      endAdornmentProps={{
        custom: {
          endAdornment: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.colors?.primary.black,
              }}
            >
              <PermissionSelector
                entityOrganizationId={entityOrganizationId}
                userOrganizationId={suggestedInviteMember.user.organization.id}
                isDisabledUser={!!suggestedInviteMember.user.isDisabled}
                initialValue={suggestedInviteMember.permissionLevel}
                onPermissionChange={(permission) => {
                  if (permission !== suggestedInviteMember.permissionLevel) {
                    onPermissionChange({
                      ...suggestedInviteMember,
                      permissionLevel: permission,
                    });
                  }
                }}
                selectPaperSx={{
                  backgroundColor: 'rgba(250, 243, 236, 1);',
                }}
                showRemove
                onRemove={() => {
                  onPermissionChange(suggestedInviteMember, true);
                }}
              />
            </Box>
          ),
        },
      }}
    />
  );
};
