/**
 * This view is used to display a post as the header of a section.
 * For example:
 * - Comment list
 * - Task list grouped by post
 */

import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { PostIcon } from 'features/post/components/icon';
import { PostPreview } from 'features/post/components/preview';
import {
  PostFragmentPostIconFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostSectionHeaderViewFragment,
  PostType,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_POST_SECTION_HEADER_VIEW = gql`
  fragment PostFragmentPostSectionHeaderView on PostModel {
    id
    urlMetadata {
      metadata {
        title
      }
    }
    ...PostFragmentPostPreview
    ...PostFragmentPostIcon
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentPostIconFragmentDoc}
`;

export type PostSectionHeaderViewProps = {
  post: PostFragmentPostSectionHeaderViewFragment;
  sx?: SxProps;
  previewAlignment?: 'left' | 'right';
};

export const PostSectionHeaderView = (props: PostSectionHeaderViewProps) => {
  const { post, sx, previewAlignment = 'right' } = props;

  const urlMetadata = post.urlMetadata?.[0];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 3,
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1,
          order: previewAlignment === 'left' ? 1 : 0,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            px: 1.5,
            py: 1,
            borderRadius: 50,
            bgcolor: '#23060312',
            color: theme.colors?.utility[800],
            maxWidth: '100%',
          }}
        >
          <PostIcon post={post} size={12} style={{ flexShrink: 0 }} />
          <Typography
            variant="headline-xxs"
            color={theme.colors?.utility[900]}
            sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {urlMetadata?.url
              ? post.type === PostType.Attachment
                ? urlMetadata.url?.split('/')?.pop()
                : new URL(urlMetadata.url)?.hostname.replace('www.', '')
              : post.title}
          </Typography>
        </Box>
        {urlMetadata?.metadata?.['title'] && (
          <Typography
            color={theme.colors?.utility[700]}
            variant="subhead-lg"
            sx={{
              ' -webkit-line-clamp': 3,
              overflow: 'hidden',
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              textAlign: 'left',
            }}
          >
            {urlMetadata.metadata['title']}
          </Typography>
        )}
      </Box>
      {/* Don't show preview for twitter because it doesn't look right */}
      {post.type !== PostType.Note &&
        urlMetadata?.metadata?.site?.toLowerCase() !== 'twitter' && (
          <Box
            sx={{
              flexShrink: 0,
              height: post.type === PostType.Attachment ? 40 : 80,
              width: 50,
            }}
          >
            <PostPreview
              post={post}
              sx={{
                height: post.type === PostType.Attachment ? 40 : 80,
                width: 50,
                borderRadius: 1,
                boxShadow:
                  '0px 1.77391px 3.54783px -1.33043px rgba(24, 39, 75, 0.08), 0px 1.33043px 1.77391px -1.33043px rgba(24, 39, 75, 0.12)',
              }}
            />
          </Box>
        )}
    </Box>
  );
};
