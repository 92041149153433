import { IconOutlineTextalignCenter } from 'components/icons/components/outline/IconOutlineTextalignCenter';
import { IconOutlineTextalignLeft } from 'components/icons/components/outline/IconOutlineTextalignLeft';
import { IconOutlineTextalignRight } from 'components/icons/components/outline/IconOutlineTextalignRight';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';

export type MediaAction = {
  tooltip: string;
  icon: ({
    size,
    style,
    ...rest
  }: React.HTMLAttributes<SVGElement> & {
    size?: number | undefined;
  }) => React.ReactSVGElement;
  action?: (updateAttributes: (o: Record<string, any>) => any) => void;
  isActive?: (attrs: Record<string, any>) => boolean;
  delete?: (d: () => void) => void;
};

export const mediaActions: MediaAction[] = [
  {
    tooltip: 'Align left',
    action: (updateAttributes) =>
      updateAttributes({
        dataAlign: 'left',
        className: 'margin-left',
        dataFloat: null,
      }),
    icon: IconOutlineTextalignLeft,
    isActive: (attrs) => attrs.dataAlign === 'left',
  },
  {
    tooltip: 'Align center',
    action: (updateAttributes) =>
      updateAttributes({
        dataAlign: 'center',
        className: 'margin-center',
        dataFloat: null,
      }),
    icon: IconOutlineTextalignCenter,
    isActive: (attrs) => attrs.dataAlign === 'center',
  },
  {
    tooltip: 'Align right',
    action: (updateAttributes) =>
      updateAttributes({
        dataAlign: 'right',
        className: 'margin-right',
        dataFloat: null,
      }),
    icon: IconOutlineTextalignRight,
    isActive: (attrs) => attrs.dataAlign === 'right',
  },
  {
    tooltip: 'Delete',
    icon: IconOutlineTrash,
    delete: (deleteNode) => deleteNode(),
  },
];
