import { gql } from '@apollo/client';
import { useDeleteTaskForTaskContextMenuMutation } from 'graphql/generated';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeleteTaskForTaskContextMenu($data: DeleteTaskInput!) {
    deleteTask(data: $data) {
      message
      success
    }
  }
`;

export const useTaskHandlers = () => {
  const [deleteTask] = useDeleteTaskForTaskContextMenuMutation();

  const onDeleteTasks = async (id: string) => {
    await deleteTask({
      variables: {
        data: {
          taskId: id,
        },
      },
      update: (cache) => {
        evictObject(cache, id, 'TaskModel');
      },
    });
  };

  return {
    onDeleteTasks,
  };
};
