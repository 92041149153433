import { gql } from '@apollo/client';
import { IconButton, SxProps, TableCell } from '@mui/material';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { JuiceboxTableBaseTableViewContext } from 'features/juiceboxTable/views/baseTable/types';
import { CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIconFragment } from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIcon on CollectionModel {
    id
    isDisabled
  }
`;

export type JuiceboxTableCollectionTableRowCellSelectIconProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowCellSelectIconFragment;
  isSelected: boolean;
  context?: JuiceboxTableBaseTableViewContext;
  sx?: SxProps;

  isExpanded?: boolean;
  onToggleExpand?: (expanded: boolean) => void;
};

export const JuiceboxTableCollectionTableRowCellSelectIcon = (
  props: JuiceboxTableCollectionTableRowCellSelectIconProps,
) => {
  const {
    collection,
    isSelected,
    context,
    sx = {},

    isExpanded,
    onToggleExpand,
  } = props;

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <TableCell
      sx={{ textAlign: 'right', ...sx }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {onToggleExpand && (
        <IconButton
          disableRipple
          onClick={(e) => {
            onToggleExpand(!isExpanded);
          }}
          sx={{
            transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
          }}
        >
          <IconLinearArrowRight size={16} />
        </IconButton>
      )}
      {/*{collection.id === context?.context.collection?.id &&*/}
      {/*  (isSelected ? (*/}
      {/*    <IconButton disableRipple>*/}
      {/*      <IconBoldTickCircle size={24} color={theme.colors?.primary.black} />*/}
      {/*    </IconButton>*/}
      {/*  ) : (*/}
      {/*    <Box*/}
      {/*      onClick={handleSelect}*/}
      {/*      sx={{*/}
      {/*        m: 2,*/}
      {/*        ml: 2.5,*/}
      {/*        cursor: 'pointer',*/}
      {/*        width: theme.spacing(5),*/}
      {/*        height: theme.spacing(5),*/}
      {/*        border: `1px solid ${theme.colors?.utility[800]}`,*/}
      {/*        borderRadius: theme.spacing(5),*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ))}*/}
    </TableCell>
  );
};
