import { createContext } from '@dwarvesf/react-utils';
import React, { useMemo, useState } from 'react';
import { GenerateId } from 'utils/generateId';
import { COMMAND_TYPE } from './constants';
import { useCollectionCommands } from './hooks/useCollectionCommands';
import { useCommandListeners } from './hooks/useCommandListeners';
import { useContentIdeaCommands } from './hooks/useContentIdeaCommands';
import useKeyboardCommands from './hooks/useKeyboardCommands';
import { usePostCommands } from './hooks/usePostCommands';
import { useSearchCommands } from './hooks/useSearchCommands';
import { useTaskCommands } from './hooks/useTaskCommands';
import {
  ActiveCommandState,
  CommandContextValues,
  CommandDataMap,
  CommandHandlerBaseContext,
  CommandHandlerContextMap,
} from './types';

const [Provider, useCommandContext] = createContext<CommandContextValues>({
  name: 'Command',
});

export const CommandContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Collect all commands
  const postCommands = usePostCommands();
  const searchCommands = useSearchCommands();
  const taskCommands = useTaskCommands();
  const collectionCommands = useCollectionCommands();
  const contentIdeaCommands = useContentIdeaCommands();

  const [disabledCommands, setDisabledCommands] = useState<COMMAND_TYPE[]>([]);

  const commands = useMemo(() => {
    return [
      ...postCommands,
      ...searchCommands,
      ...taskCommands,
      ...collectionCommands,
      ...contentIdeaCommands,
    ];
  }, [
    postCommands,
    searchCommands,
    taskCommands,
    collectionCommands,
    contentIdeaCommands,
  ]);

  // Keep track of active commands
  const [activeCommands, setActiveCommands] = useState<ActiveCommandState[]>(
    [],
  );

  // Controllers for listeners that should be triggered when an active command is updated
  const {
    commandListeners,
    registerCommandListener,
    unregisterCommandListener,
  } = useCommandListeners();

  // Trigger a command by commandType
  const triggerCommand = <T extends COMMAND_TYPE>(
    commandType: T,
    context?: CommandHandlerContextMap[T] & CommandHandlerBaseContext<T>,
  ) => {
    if (disabledCommands.includes(commandType)) return;

    const command = commands.find((c) => c.type === commandType);

    // Generate a new activeCommand and push to the activeCommands array
    if (command) {
      // @ts-ignore
      setActiveCommands((o) => {
        // Clean-up activeCommands before triggering new ones
        // Active commands that have status `triggered`, `completed` or `error` will be removed
        o = o.filter(
          (c) => !['triggered', 'completed', 'error'].includes(c.status),
        );

        return [
          ...o,
          {
            id: GenerateId.create(),
            type: command.type,
            keys: command.keys,
            status: 'triggered',
            triggeredByShortcut: context?.triggeredByShortcut,
            context,
          },
        ];
      });
    }
  };

  // Update data of active command based on id
  const updateActiveCommand: CommandContextValues['updateActiveCommand'] = (
    id,
    data,
  ) => {
    setActiveCommands((o) => {
      const newO = [...o];
      const index = newO.findIndex((c) => c.id === id);

      if (index >= 0) {
        newO[index] = {
          ...newO[index],
          ...data,
        };

        // Trigger listeners by updated command's type
        commandListeners.current[newO[index].type]?.forEach((listener) =>
          listener(newO[index]),
        );

        // If a command is completed, call onCompleted callback (if provided)
        // TODO: Not sure if this is the best way to do this, or should we simply add a
        // onStatusUpdate, onUpdate type of generic callback?
        if (newO[index].status === 'completed') {
          newO[index].context?.onCompleted?.(data.data);
        }
      }

      return newO;
    });

    // If a command is completed or error, set a timeout to remove itself after 3s
    if (data.status === 'completed' || data.status === 'error') {
      setTimeout(() => {
        setActiveCommands((o) => o.filter((c) => c.id !== id));
      }, 3000);
    }
  };

  // Get an active command based on id
  const getActiveCommandById = (id: string) => {
    return activeCommands.find((command) => command.id === id);
  };

  // Call a hook where we listen to keyboard input
  // and trigger a command
  useKeyboardCommands(commands, (commandType) => {
    triggerCommand(commandType, {
      triggeredByShortcut: true,
    });
  });

  const broadcastByCommandType = <T extends COMMAND_TYPE>(
    commandType: T,
    data?: CommandDataMap[T],
  ) => {
    const command = commands.find((c) => c.type === commandType);

    if (command) {
      // Trigger listeners by updated command's type
      commandListeners.current[command.type]?.forEach((listener) =>
        listener({
          status: 'completed',
          data,
        }),
      );
    }
  };

  const disableCommands = (commandTypes: COMMAND_TYPE[]) => {
    setDisabledCommands([...disabledCommands, ...commandTypes]);
  };

  const enableCommands = (commandTypes: COMMAND_TYPE[]) => {
    setDisabledCommands((prevDisabledCommands) =>
      prevDisabledCommands.filter(
        (disabledCommand) => !commandTypes.includes(disabledCommand),
      ),
    );
  };

  return (
    <Provider
      value={{
        commands,
        activeCommands,
        updateActiveCommand,
        triggerCommand,
        getActiveCommandById,
        registerCommandListener,
        unregisterCommandListener,
        broadcastByCommandType,
        disableCommands,
        enableCommands,
      }}
    >
      {children}
      {activeCommands.map((activeCommand) => {
        const commandWithHandler = commands.find(
          (c) => c.type === activeCommand.type,
        );

        if (commandWithHandler) {
          return (
            <commandWithHandler.Handler
              commandId={activeCommand.id}
              key={activeCommand.id}
              context={activeCommand.context}
            />
          );
        }

        return null;
      })}
    </Provider>
  );
};

export { useCommandContext };
