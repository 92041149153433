import { Box, Typography } from '@mui/material';
import { useBrandDataForSocialListeningOnboarding } from 'features/socialMediaListening/hooks';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';
import { SocialMediaListeningOnboardingCountrySelectionInput } from './SocialMediaListeningOnboardingCountrySelectionInput';

type Props = {
  brandId: string;
  selectedCountries?: string[];
  onNext: () => void;
};

export const SocialMediaListeningOnboardingCountrySelection = ({
  selectedCountries,
  brandId,
  onNext,
}: Props) => {
  const [selectedValues, setSelectedValues] = useState<
    {
      code: string;
      name: string;
    }[]
  >([]);

  const { onboardingActions } = useBrandDataForSocialListeningOnboarding({
    brandId,
  });
  const { handleBrandRegionsUpdate, savingBrandData } = onboardingActions;

  const onSubmit = async () => {
    const regions = selectedValues.map((x) => x.code);
    await handleBrandRegionsUpdate(regions);
    onNext();
  };

  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        What's your region?
      </Typography>

      <Typography variant="body-xl" fontWeight={500}>
        This is what we need your region for
      </Typography>

      <SocialMediaListeningOnboardingCountrySelectionInput
        selectedCountries={selectedCountries}
        onCountriesUpdated={(countries) => {
          setSelectedValues(countries);
        }}
        sx={{
          mt: 4,
          width: '100vw',
          maxWidth: 720,
        }}
      />
      <RenderNextButton loading={savingBrandData} onNextClick={onSubmit} />
    </Box>
  );
};
