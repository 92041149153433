import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Chip, IconButton, MenuItem, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconOutlineArrowUp2 } from 'components/icons/components/outline/IconOutlineArrowUp2';
import { fieldNameIconMap, getCustomStatusStyles } from 'features/contentIdea';
import {
  ContentIdeaFieldFragmentContentCalendarFilterFragment,
  useGetMeForContentCalendarFilterByPlatformsQuery,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { KeyboardEventHandler, useId } from 'react';
import { theme } from 'styles/theme';
import { StyledIconContainer, StyledMenuItemContainer } from '../../../styles';
import { platformIconMap } from '../../../types';

// eslint-disable-next-line
gql`
  query GetMeForContentCalendarFilterByPlatforms {
    me {
      id
      organization {
        id
        socialAuthors {
          id
          platform
          platformHandle
        }
      }
      joinedOrganizations {
        id
        socialAuthors {
          id
          platform
          platformHandle
        }
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type ContentCalendarFilterByPlatformsProps = {
  selectedPlatforms: string[];
  selectedSocialAuthorIds: string[];
  field: ContentIdeaFieldFragmentContentCalendarFilterFragment;
  onSelectPlatform: (selectedPlatform: string) => void;
  onSelectSocialAuthor: (selectedSocialAuthorId: string) => void;
};

export const ContentCalendarFilterByPlatforms = (
  props: ContentCalendarFilterByPlatformsProps,
) => {
  const {
    selectedPlatforms = [],
    selectedSocialAuthorIds = [],
    field,
    onSelectPlatform,
    onSelectSocialAuthor,
  } = props;

  const { data: meData } = useGetMeForContentCalendarFilterByPlatformsQuery();
  const me = meData?.me;
  const socialAuthors = [
    ...(me?.organization?.socialAuthors || []),
    ...(me?.joinedOrganizations.flatMap((org) => org.socialAuthors) || []),
  ]
    // Dedupe by id
    .filter(
      (author, i, arr) => arr.findIndex((a) => a?.id === author?.id) === i,
    );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuItemId = useId();

  const handleToggleDropDown = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = (ev) => {
    if (
      (ev.key !== 'ArrowRight' && ev.key !== 'Enter') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    ev.stopPropagation();
    onOpen();
  };

  const FilterIcon = field.icon ? fieldNameIconMap[field.icon] : null;

  return (
    <>
      <MenuItem
        id={menuItemId}
        onKeyDown={handleItemKeyDown}
        onClick={handleToggleDropDown}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: theme.spacing(4),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            minWidth: theme.spacing(50),
            width: '100%',
          }}
        >
          <Box sx={{ width: '90%' }}>
            <StyledMenuItemContainer>
              <StyledIconContainer>
                <FilterIcon size={16} />
              </StyledIconContainer>

              <Typography variant="subhead-lg">
                Platforms{' '}
                {(selectedPlatforms.length > 0 ||
                  selectedSocialAuthorIds.length > 0) && (
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[600]}
                  >
                    ({selectedPlatforms.length + selectedSocialAuthorIds.length}
                    )
                  </Typography>
                )}
              </Typography>
            </StyledMenuItemContainer>
          </Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleToggleDropDown();
            }}
          >
            {isOpen ? (
              <IconOutlineArrowUp2 size={16} />
            ) : (
              <IconLinearArrowDown size={16} />
            )}
          </IconButton>
        </Box>
      </MenuItem>
      {isOpen &&
        field.options.map((option) => {
          const socialAuthorsByPlatform = socialAuthors.filter(
            (author) => author!.platform === option.value,
          );

          const extraInfo = platformIconMap.find(
            (p) => p.name === option.value,
          );

          // FIXME: THIS IS A HACK FOR STATUS COLORS
          const customStyles = getCustomStatusStyles(
            option.value,
            option.bgcolor || '',
          );

          const labelRender = (
            <Chip
              variant="filled-borderless-color-dodge"
              sx={{
                borderRadius: 1,
                // Override color dodge CSS if text color is available
                ...(extraInfo?.color || customStyles.color
                  ? {
                      '.MuiChip-label': {
                        filter: 'none !important',
                        color: customStyles.color,
                      },
                    }
                  : {}),
                ...customStyles,
              }}
              icon={extraInfo?.icon}
              label={
                <Typography variant="headline-xs" color={extraInfo?.color}>
                  {option.label}
                </Typography>
              }
            />
          );

          if (socialAuthorsByPlatform.length === 0) {
            return (
              <CheckboxMenuItem
                key={option.value}
                value={option.value}
                label={labelRender}
                onClick={() => {
                  onSelectPlatform(option.value);
                }}
                checked={selectedPlatforms.includes(option.value)}
              />
            );
          }

          return (
            <DropDownMoreMenuItem
              key={option.value}
              label={labelRender}
              dropDownOptions={socialAuthorsByPlatform.map((author) => {
                return {
                  label: author!.platformHandle,
                  value: author!.id,
                  isChecked: selectedSocialAuthorIds.includes(author!.id),
                  onClick: () => {
                    onSelectSocialAuthor(author!.id);
                  },
                };
              })}
              renderHeaderAsOption
              onClick={() => {
                const areAllSocialAuthorsSelected =
                  socialAuthorsByPlatform.every((author) =>
                    selectedSocialAuthorIds.includes(author!.id),
                  );

                if (areAllSocialAuthorsSelected) {
                  socialAuthorsByPlatform.forEach((author) => {
                    onSelectSocialAuthor(author!.id);
                  });
                } else {
                  socialAuthorsByPlatform
                    .filter((a) => !selectedSocialAuthorIds.includes(a!.id))
                    .forEach((author) => {
                      onSelectSocialAuthor(author!.id);
                    });
                }
              }}
            />
          );
        })}
    </>
  );
};
