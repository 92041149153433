import { Box, styled } from '@mui/material';

export const StyledNameContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const StyledLogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  backgroundColor: theme.colors?.primary.maroon,
  borderRadius: '4px',
}));

export const StyledTab = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: theme.spacing(3),
}));

export const StyledShortCutContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const StyledShortCutWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.colors?.utility[400],
}));
