import {
  Box,
  BoxProps,
  Button,
  OutlinedInput,
  Popover,
  Typography,
} from '@mui/material';
import { Editor } from '@tiptap/react';
import { CustomController } from 'components/common/form/CustomController';
import { IconOutlineClose } from 'components/icons/components/outline/IconOutlineClose';
import React from 'react';
import { theme } from 'styles/theme/theme';
import { useHyperlinkHandlers } from './hooks/useHyperlinkHandlers';
import { StyledCloseButton } from './styles';

interface Props extends React.PropsWithChildren, BoxProps {
  editor: Editor | null;
}

export const HyperlinkPopoverWrapper = ({
  children,
  editor,
  ...rest
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const {
    getSelectedText,
    control,
    handleFormSubmit,
    linkText,
    open,
    setLinkText,
  } = useHyperlinkHandlers(editor);

  React.useEffect(() => {
    const selectedText = getSelectedText();
    setLinkText(selectedText);
  }, [getSelectedText]); //eslint-disable-line

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={(e) => {
          if (editor) {
            const previousUrl = editor?.getAttributes('link').href;
            if (previousUrl) {
              editor?.chain().focus().unsetLink().run();
              return;
            }
            setAnchorEl(e.currentTarget);
            open.onToggle();
          }
        }}
        {...rest}
      >
        {children}
      </Box>
      <Popover
        open={open.isOpen}
        anchorEl={anchorEl}
        onClose={open.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            boxShadow: '0px 3px 12px -2px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <form autoComplete="off" onSubmit={handleFormSubmit}>
          <Box
            display="flex"
            flexDirection="column"
            width="571px"
            padding="32px"
            gap={8}
          >
            <Box display="flex" flexDirection="column" gap={6}>
              <Typography
                variant="headline-md"
                color={theme.colors?.utility[1000]}
              >
                Add link
              </Typography>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <CustomController
                    name="text"
                    rules={{ required: 'Required' }}
                    control={control}
                    defaultValue={linkText}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <Typography
                            variant="headline-xs"
                            color={theme.colors?.utility[1000]}
                          >
                            Text
                          </Typography>
                          <OutlinedInput
                            {...field}
                            {...fieldState}
                            error={Boolean(fieldState.error)}
                            placeholder="Enter text to show"
                          />
                        </>
                      );
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={2}>
                  <CustomController
                    name="url"
                    rules={{ required: 'Required' }}
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <Typography variant="headline-xs" mb={1} mt={3}>
                            Hyperlink
                          </Typography>
                          <OutlinedInput
                            {...field}
                            {...fieldState}
                            error={Boolean(fieldState.error)}
                            required
                            placeholder="Enter the link"
                            autoFocus
                          />
                        </>
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                variant="text"
                onClick={open.onClose}
                size="small"
                sx={{ color: theme.colors?.utility[700] }}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" size="small">
                Add
              </Button>
            </Box>
            <StyledCloseButton onClick={open.onClose}>
              <IconOutlineClose size={24} color={theme.colors?.utility[1000]} />
            </StyledCloseButton>
          </Box>
        </form>
      </Popover>
    </>
  );
};
