import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionMultiPostPreviewFragment,
  PostFragmentPostPreviewFragmentDoc,
} from 'graphql/generated';
import {
  CardStack,
  CardStackAlt,
  CardStackAltProps,
  CardStackProps,
  Grid,
  GridProps,
  CardStackSquare,
  CardStackSquareProps,
  CardProps,
  Card,
} from './variants';

export const POST_FRAGMENT_COLLECTION_MULTI_POST_PREVIEW = gql`
  fragment PostFragmentCollectionMultiPostPreview on PostModel {
    id
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export const COLLECTION_FRAGMENT_COLLECTION_MULTI_POST_PREVIEW = gql`
  fragment CollectionFragmentCollectionMultiPostPreview on CollectionModel {
    id
    posts(postCount: 3) {
      id
      ...PostFragmentCollectionMultiPostPreview
    }
  }
  ${POST_FRAGMENT_COLLECTION_MULTI_POST_PREVIEW}
`;

export type CollectionMultiPostPreviewProps = {
  collection: CollectionFragmentCollectionMultiPostPreviewFragment;
} & (
  | ({
      variant: 'grid';
    } & Omit<GridProps, 'collection'>)
  | ({ variant: 'card' } & CardProps)
  | ({
      variant: 'card-stack';
    } & Omit<CardStackProps, 'collection'>)
  | ({ variant: 'card-stack-alt' } & CardStackAltProps)
  | ({ variant: 'card-stack-square' } & CardStackSquareProps)
  | {
      variant: undefined;
    }
);

export const CollectionMultiPostPreview = (
  props: CollectionMultiPostPreviewProps,
) => {
  const { collection, variant = 'grid', ...rest } = props;

  switch (variant) {
    case 'grid': {
      return <Grid collection={collection} {...rest} />;
    }
    case 'card': {
      return <Card collection={collection} {...rest} />;
    }
    case 'card-stack': {
      return <CardStack collection={collection} {...rest} />;
    }
    case 'card-stack-alt': {
      return <CardStackAlt collection={collection} {...rest} />;
    }
    case 'card-stack-square': {
      return <CardStackSquare collection={collection} {...rest} />;
    }
    default: {
      return null;
    }
  }
};
