import { Box, Dialog, Typography, styled } from '@mui/material';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.colors?.utility[1000],
  marginBottom: theme.spacing(1),
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.colors?.utility[900],
}));

export const StyledDropZoneContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '150px',
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    position: 'relative',
    boxShadow: '0px 3px 12px -2px rgba(0, 0, 0, 0.1)',
  },
}));

export const StyledIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: theme.spacing(4),
  cursor: 'pointer',
  right: theme.spacing(4),
}));
