import { gql } from '@apollo/client';
import { Box, Chip, Typography } from '@mui/material';
import { AvatarGroup } from 'components/common/AvatarGroup';
import { Tooltip } from 'components/common/Tooltip';
import { ContentIdeaPreview } from 'features/contentIdea/components';
import { platformIconMap } from 'features/contentIdea/constants';
import {
  getCustomEventCardStylesBasedOnStatus,
  getCustomPillarChipStylesBasedOnStatus,
  getCustomStatusStyles,
} from 'features/contentIdea/utils';
import {
  ContentIdeaFragmentContentIdeaCalendarEventPublishedViewFragmentDoc,
  ContentIdeaFragmentContentIdeaCalendarEventViewFragment,
  ContentIdeaFragmentContentIdeaContextMenuFragmentDoc,
  ContentIdeaFragmentContentIdeaPreviewFragmentDoc,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { Event, EventProps } from 'react-big-calendar';
import { theme } from 'styles/theme';
import { ContentIdeaCalendarEventPublishedView } from './ContentIdeaCalendarEventPublishedView';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_CALENDAR_EVENT_VIEW = gql`
  fragment ContentIdeaFragmentContentIdeaCalendarEventView on ContentIdeaModel {
    id
    name
    dueDate {
      id
      value {
        id
        date
      }
    }
    status {
      id
      value {
        id
        select {
          id
          label
          value
          bgcolor
        }
      }
    }
    platforms {
      id
      value {
        id
        multiSelect {
          id
          label
          value
          bgcolor
        }
      }
    }
    pillars {
      id
      value {
        id
        multiSelect {
          id
          label
          value
          bgcolor
        }
      }
    }
    owners {
      id
      value {
        id
        users {
          id
          ...UserFragmentAvatarGroup
        }
      }
    }
    ...ContentIdeaFragmentContentIdeaPreview
    ...ContentIdeaFragmentContentIdeaCalendarEventPublishedView
    ...ContentIdeaFragmentContentIdeaContextMenu
  }
  ${ContentIdeaFragmentContentIdeaPreviewFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
  ${ContentIdeaFragmentContentIdeaCalendarEventPublishedViewFragmentDoc}
  ${ContentIdeaFragmentContentIdeaContextMenuFragmentDoc}
`;

export type ContentIdeaCalendarEventViewProps = EventProps & {
  onClick?: () => void;
};

export const ContentIdeaCalendarEventView = (
  props: ContentIdeaCalendarEventViewProps,
) => {
  const { event, onClick } = props;

  const { contentIdea } = event as Event & {
    contentIdea: ContentIdeaFragmentContentIdeaCalendarEventViewFragment;
  };

  if (contentIdea.status.value?.select?.value === 'Published') {
    return <ContentIdeaCalendarEventPublishedView {...props} />;
  }

  const customEventCardStyles = getCustomEventCardStylesBasedOnStatus(
    contentIdea.status.value?.select?.value || '',
    '',
  );
  const customStatusStyles = getCustomStatusStyles(
    contentIdea.status.value?.select?.value || '',
    '',
  );
  const customPillarStyles = getCustomPillarChipStylesBasedOnStatus(
    contentIdea.status.value?.select?.value || '',
  );

  const isCancelled = contentIdea.status.value?.select?.value === 'Cancelled';

  return (
    <Box
      onClick={onClick}
      sx={{
        p: 1.5,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1.5,
        height: '100%',
        ...customEventCardStyles,
      }}
      id={contentIdea.id}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 4,
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          minHeight: 12,
        }}
      >
        <AvatarGroup
          max={3}
          users={contentIdea.owners.value?.users || []}
          avatarSize={12}
          sx={{
            gap: 1,
            opacity: isCancelled ? 0.5 : 1,
          }}
        />
        {(contentIdea.platforms.value?.multiSelect || []).length > 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 0.5,
              borderRadius: 0.5,
              bgcolor: '#2306030D',
              color: theme.colors?.primary.black,
              opacity: isCancelled ? 0.5 : 1,
            }}
          >
            {(contentIdea.platforms.value?.multiSelect || []).map(
              (platform) => {
                const Icon = platformIconMap[platform.value];

                return <Icon key={platform.id} size={10} />;
              },
            )}
          </Box>
        )}
      </Box>
      <Typography
        variant="headline-xxs"
        color={
          isCancelled ? theme.colors?.utility[400] : theme.colors?.primary.black
        }
        sx={{
          whiteSpace: 'normal',
          display: '-webkit-box',
          overflow: 'hidden',
          '-webkit-box-orient': 'vertical',
          ' -webkit-line-clamp': 3,
        }}
      >
        {contentIdea.name}
      </Typography>
      <Chip
        sx={{
          borderRadius: 1,
          height: 'auto',
          span: {
            py: 0.5,
            px: 1,
          },
          ...(customStatusStyles.color
            ? {
                '& .MuiChip-label': {
                  filter: 'none !important',
                  color: customStatusStyles.color,
                },
              }
            : {}),
          ...customStatusStyles,
        }}
        variant="filled-borderless"
        label={
          <Typography variant="headline-xs" fontSize={11}>
            {contentIdea.status.value?.select?.label}
          </Typography>
        }
      />
      {contentIdea.pillars.value?.multiSelect &&
        contentIdea.pillars.value.multiSelect.length > 0 && (
          <Tooltip
            title={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {contentIdea.pillars.value.multiSelect.map((pillar) => (
                  <Typography variant="subhead-sm" key={pillar.id}>
                    {pillar.label}
                  </Typography>
                ))}
              </Box>
            }
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1.5,
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Chip
                variant="filled-borderless-color-dodge"
                sx={{
                  bgcolor: customPillarStyles.backgroundColor,
                  borderRadius: 1,
                  height: 'auto',
                  '.MuiChip-label': {
                    py: 0.5,
                    px: 1,
                    filter: 'none !important',
                  },
                  overflow: 'hidden',
                }}
                label={
                  <Typography
                    variant="headline-xs"
                    fontSize={11}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: customPillarStyles.color,
                    }}
                  >
                    {contentIdea.pillars.value.multiSelect[0].label}
                  </Typography>
                }
              />
              {contentIdea.pillars.value.multiSelect.length > 1 && (
                <Box
                  sx={{
                    px: 1.5,
                    borderRadius: 1,
                    bgcolor: '#2306031A',
                    color: '#6B6B6B',
                    opacity: isCancelled ? 0.5 : 1,
                  }}
                >
                  <Typography variant="headline-xs" fontSize={11}>
                    +{contentIdea.pillars.value.multiSelect.length - 1}
                  </Typography>
                </Box>
              )}
            </Box>
          </Tooltip>
        )}
      <ContentIdeaPreview
        // NOTE: We need this to ensure the ContentIdeaPreview is re-rendered properly when contentIdea changes
        // react-big-calendar is doing some behind-the-scene memoization that causes the ContentIdeaPreview to not re-render
        key={contentIdea.id}
        contentIdea={contentIdea}
        readOnly
        sx={{
          flex: 1,
          width: '100%',
          aspectRatio: '7 / 8',
          borderRadius: 2,
          overflow: 'hidden',
          opacity: isCancelled ? 0.4 : 1,
        }}
      />
    </Box>
  );
};
