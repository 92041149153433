import { Node, ResolvedPos } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';

/**
 * This method accepts a resolved position, and tries to find all consecutive link marks
 * from that position, and returns them as an array, including their start and end positions.
 *
 * For example:
 *
 * El: <p><a href="...">Hello<strong>this<em>is</em></strong> a link mark that nests other marks</a> in a paragraph</p>
 * Output: [{ textNode, from, to }, { textNode, from, to }, ...]
 *
 * This is because of the way tiptap handles marks. When you have nested marks
 * which creates nested text nodes (<strong> and <em> in this case),
 * the link marks are split into multiple marks, and each mark is applied to each text node.
 *
 * So if we want to update the href of the link mark, we need to find all the nested text nodes,
 * and update the href of each link mark on these nodes.
 *
 * @param view
 * @param resolvedPos
 */
export const getAllConsecutiveLinkMarks = (
  view: EditorView,
  resolvedPos: ResolvedPos,
) => {
  const { doc, schema } = view.state;
  const { link } = schema.marks;

  const linkMarks: {
    textNode: Node;
    from: number;
    to: number;
  }[] = [];

  let pos = resolvedPos.pos;
  let node = resolvedPos.nodeAfter;

  while (node && node.isText) {
    const linkMark = link.isInSet(node.marks);

    if (linkMark) {
      linkMarks.push({
        textNode: node,
        from: pos,
        to: pos + node.nodeSize,
      });
    }

    pos += node.nodeSize;
    node = doc.nodeAt(pos);
  }

  return linkMarks;
};

/**
 * This method accepts a view, a resolved position, and a set of attributes,
 * and updates all consecutive link marks with the given attributes.
 *
 * Original attrs should be preserved.
 *
 * @param view
 * @param resolvedPos
 * @param attrs
 */
export const updateAllConsecutiveLinkMarks = (
  view: EditorView,
  resolvedPos: ResolvedPos,
  attrs: Record<string, any>,
) => {
  const { state } = view;
  const { link } = state.schema.marks;

  const tr = state.tr;
  const linkMarks = getAllConsecutiveLinkMarks(view, resolvedPos);

  linkMarks.forEach(({ from, to }) => {
    tr.removeMark(from, to, link);
    tr.addMark(from, to, link.create({ ...attrs }));
  });

  view.dispatch(tr);
};

/**
 * This method accepts a view, and a resolved position,
 * and removes all consecutive link marks.
 *
 * @param view
 * @param resolvedPos
 */
export const removeAllConsecutiveLinkMarks = (
  view: EditorView,
  resolvedPos: ResolvedPos,
) => {
  const { state } = view;
  const { link } = state.schema.marks;

  const tr = state.tr;
  const linkMarks = getAllConsecutiveLinkMarks(view, resolvedPos);

  linkMarks.forEach(({ from, to }) => {
    tr.removeMark(from, to, link);
  });

  view.dispatch(tr);
};

/**
 * This method accepts the view & a range (from selection).
 * It will return all the ranges of POSSIBLE text nodes that are within the specified range,
 * including text nodes that are partially covered by the range. If a text node is partially covered,
 * it will adjust the start and end positions of the range to match the selection.
 *
 * For example:
 *
 * <p>Hi <strong> there!</strong>. I'm a <em>paragraph</em></p>
 *
 * If the selection is from "Hi" upto "I'm", the output will looks similar to this:
 * [
 *   { from: 1, to: 3 }, // "Hi"
 *   { from: 4, to: 10 }, // " there!"
 *   { from: 11, to: 15 }, // ". I'm"
 * ]
 *
 * @param view
 * @param from
 * @param to
 * @returns
 */
export const findTextNodeRangesInRange = (
  view: EditorView,
  from: number,
  to: number,
) => {
  const textRanges: {
    from: number;
    to: number;
  }[] = [];

  view.state.doc.descendants((node, pos) => {
    if (node.isText) {
      const nodeEndPos = pos + node.nodeSize;

      // Check if the node is within the specified range
      if (pos < to && nodeEndPos > from) {
        // Adjust the start and end positions if the selection partially covers the node
        const rangeFrom = Math.max(pos, from);
        const rangeTo = Math.min(nodeEndPos, to);

        textRanges.push({ from: rangeFrom, to: rangeTo });
      }
    }
    return true;
  });

  return textRanges;
};
