import { DrawerProps } from '@mui/material';
import { ReactNode } from 'react';

export enum TabName {
  CUSTOM_UI = 'Custom UI',
  SETTINGS = 'Settings',
  SUPPORT = 'Support',
  CREATIVE_JUICEBOX = 'Creative Juicebox',
  TRENDS = 'Trends',
  SOCIAL_LISTENING = 'Social Listening',
  ALL_TASKS = 'Tasks',
  CONTENT_CALENDAR = 'Content Calendar',
  REGISTER_TO_TEXT = 'Register to Text',
  DOWNLOAD_THE_MOBILE_APP = 'Download the Mobile App',
  LOG_OUT = 'Log Out',
  TOP_AUDIOS = 'Top Audios',
}

type DrawerTabTypeLink = {
  type: 'link';
  href: string;
  state?: any;
  icon: ({
    size,
    style,
  }: React.HTMLAttributes<SVGElement> & {
    size?: number | undefined;
  }) => React.ReactSVGElement;
};

type DrawerTabTypeComponent = {
  type: 'component';
  renderComponent: () => ReactNode;
};

type DrawerTabTypeButton = {
  type: 'button';
  icon: ({
    size,
    style,
  }: React.HTMLAttributes<SVGElement> & {
    size?: number | undefined;
  }) => React.ReactSVGElement;
};

type DrawerTabType =
  | DrawerTabTypeLink
  | DrawerTabTypeComponent
  | DrawerTabTypeButton;

export type DrawerTab = DrawerTabType & {
  name: TabName;
  shortCut?: string;
  tooltip?: string;
  beta?: boolean;
  new?: boolean;
  color?: string;
  paywall?: ReactNode;
  onClick?: VoidFunction;
  disabled?: boolean;
  loading?: boolean;
};

export interface LeftDrawerProps {
  topTabs: DrawerTab[];
  tabs: DrawerTab[];
  isOpen: boolean;
  onClose: () => void;
  variant?: DrawerProps['variant'];
}
