import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import {
  CommentContainerView,
  CommentContainerViewSkeleton,
  CommentSidebar,
  CommentSidebarProps,
  useTaskCommentHandlers,
} from 'features/comments';
import {
  CommentFilter as CommentFilterType,
  CommentFragmentCommentContainerViewFragmentDoc,
  CommentInputData,
  SortOrder,
  useGetCommentsForTaskCommentListViewQuery,
} from 'graphql/generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { isCuid } from 'utils/helpers';

export const COMMENT_FRAGMENT_TASK_COMMENT_LIST_VIEW = gql`
  fragment CommentFragmentTaskCommentListView on CommentModel {
    id
    ...CommentFragmentCommentContainerView
  }
  ${CommentFragmentCommentContainerViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCommentsForTaskCommentListView($filters: CommentFilter!) {
    comments(filters: $filters) {
      id
      ...CommentFragmentTaskCommentListView
    }
  }
  ${COMMENT_FRAGMENT_TASK_COMMENT_LIST_VIEW}
`;

export type TaskCommentListViewProps = {
  taskId: string;
  sx?: SxProps;
  componentsProps?: CommentSidebarProps['componentsProps'];
};

export const TaskCommentListView = (props: TaskCommentListViewProps) => {
  const { taskId, sx, componentsProps } = props;

  const { onCreateTaskComment, onReactToComment, onReadAllTaskComments } =
    useTaskCommentHandlers();

  const [filters, setFilters] = useState<CommentFilterType>({
    taskId,
    query: '',
    filterOnlyParentThreads: true,
    sortBy: {
      field: 'createdAt',
      order: SortOrder.Asc,
    },
  });

  const { data, loading } = useGetCommentsForTaskCommentListViewQuery({
    variables: {
      filters,
    },
    fetchPolicy: 'cache-and-network',
    // skip the query if the postId is not a cuid
    skip: !isCuid(taskId),
  });
  const comments = useMemo(() => data?.comments || [], [data]);
  const isFirstLoading = loading && !comments.length;

  // update taskId in filters when going from task to subtask
  useEffect(() => {
    setFilters({
      ...filters,
      taskId,
    });
  }, [taskId]); // eslint-disable-line

  useEffect(() => {
    if (taskId) {
      onReadAllTaskComments(taskId);
    }
  }, []); // eslint-disable-line

  const renderComments = useCallback(() => {
    if (isFirstLoading) {
      return <CommentContainerViewSkeleton />;
    }

    if (!comments.length) {
      return (
        <Box my={4} display="flex" justifyContent="center">
          <Typography color={theme.colors?.utility[800]} variant="body-lg">
            No comments
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          '.mention-node': {
            color: `${theme.colors?.primary.maroon} !important`,
          },
          '& a': {
            color: `${theme.colors?.primary.maroon} !important`,
          },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ wordBreak: 'break-word', py: 2 }}>
          {comments.map((comment) => {
            return (
              <CommentContainerView
                key={comment.id}
                comment={comment}
                onReact={(commentId, emoji) => {
                  onReactToComment(commentId, emoji);
                }}
                onReply={(data) => {
                  onCreateTaskComment(
                    {
                      taskId,
                      data,
                    },
                    filters,
                  );
                }}
              />
            );
          })}
        </Box>
      </Box>
    );
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify({
      comments,
      filters,
      isFirstLoading,
      taskId,
      onCreateTaskComment,
      onReactToComment,
    }),
  ]);

  const onCreateComment = (data: CommentInputData) => {
    onCreateTaskComment(
      {
        taskId,
        data,
      },

      filters,
    );
  };

  return (
    <CommentSidebar
      sx={sx}
      componentsProps={componentsProps}
      filters={filters}
      onSetFilters={setFilters}
      onCreateComment={onCreateComment}
      renderComments={renderComments}
      canShowSearch
      key={taskId}
    />
  );
};
