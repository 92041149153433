import TaskList from '@tiptap/extension-task-list';
import { PostThemeColor } from 'features/note/constants/noteColors';
import {
  BlockFloatingMenu,
  Column,
  ColumnList,
  Comment,
  CommentExtensionOptions,
  EnsureEmptyBottomParagraph,
  MasonryExtension,
  MasonryExtensionExternalAttrs,
  MasonryWrapperExtension,
  TaskItem,
  TaskItemExtensionExternalAttrs,
} from 'features/tiptap';

type CustomExtensionOptions = {
  taskItem?: TaskItemExtensionExternalAttrs;
  masonry?: MasonryExtensionExternalAttrs;
  comment?: CommentExtensionOptions;
  themeColor?: PostThemeColor;
};

export const generateCustomExtensions = (options?: CustomExtensionOptions) => {
  return [
    TaskList,
    // Set custom attrs ONE time, so we can apply it to the nodes
    // when they first load. For future changes, we will use the
    // note.attrs instead. See line 163.
    TaskItem.configure({
      nested: false,
      externalAttrs: options?.taskItem,
    }),
    MasonryExtension.configure({
      externalAttrs: options?.masonry,
    }),
    MasonryWrapperExtension,
    Comment.configure({
      ...options?.comment,
    }),
    ColumnList,
    Column,
    BlockFloatingMenu,
    EnsureEmptyBottomParagraph,
  ];
};
