import { Box, Typography } from '@mui/material';
import { IconLinearArrowDown1 } from 'components/icons/components/linear/IconLinearArrowDown1';
import { useTrendExampleNavigatorHandler } from 'features/trending/hooks';
import { theme } from 'styles/theme';

interface Props {
  btnText: string;
  onShowMoreExamples: () => void;
}

export const TrendingDetailSimilarExampleButtonView = ({
  btnText,
  onShowMoreExamples,
}: Props) => {
  const { onShowNextTrendExample, onShowPreviousTrendExample } =
    useTrendExampleNavigatorHandler();

  const onKeyDown = (e) => {
    e.stopPropagation();
    onShowMoreExamples();
    onShowNextTrendExample();
  };

  const onKeyUp = (e) => {
    e.stopPropagation();
    onShowMoreExamples();
    onShowPreviousTrendExample();
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        padding: theme.spacing(1.5, 3),
        bgcolor: theme.colors?.primary.parchment,
        width: 'fit-content',
        borderRadius: 25,
        cursor: 'pointer',
      }}
      onClick={onKeyDown}
    >
      <Box
        sx={{
          backgroundColor: theme.colors?.utility[400],
          borderRadius: 1,
          border: `1px solid ${theme.colors?.utility[500]}`,
          height: 20,
          width: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onKeyUp}
      >
        <IconLinearArrowDown1
          size={12}
          color={theme.colors?.primary.black}
          style={{ transform: 'rotate(180deg)' }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.colors?.utility[400],
          borderRadius: 1,
          border: `1px solid ${theme.colors?.utility[500]}`,
          height: 20,
          width: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onKeyDown}
      >
        <IconLinearArrowDown1 size={12} color={theme.colors?.primary.black} />
      </Box>

      <Typography variant="headline-sm">{btnText}</Typography>
    </Box>
  );
};
