import { styled } from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { theme } from 'styles/theme';

export const StyledIconButton = styled(IconButtonWithTooltip)`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #23060399 !important;
  color: ${theme.colors?.primary.parchment};

  &.Mui-disabled {
    background-color: ${theme.colors?.utility[500]} !important;
  }
`;

export const tooltipStyle = {
  zIndex: '2000 !important',
};
