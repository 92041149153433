import { Fragment } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';
import { GenerateId } from 'utils/generateId';
import { ATTR_BLOCK_ID, ATTR_POST_ID, NODE_EMBED } from '../extensions';
import { buildColumn, buildColumnBlock } from '../extensions/column/utils';
import { checkIfSelectionIsInsideAColumnBlock } from '../utils';

export const usePostNodeHandlers = () => {
  const handleAddPostsToEditor = (view: EditorView, postIds: string[]) => {
    const { schema, selection, doc, tr } = view.state;

    // for case user is selecting a text, then paste a link (cmd+V) => automatically create an anchor link
    const selectionValue = doc.textBetween(selection.from, selection.to, ' ');
    if (selectionValue) {
      return;
    }

    if (postIds.length === 1) {
      const postNode = schema.nodes[NODE_EMBED].create({
        [ATTR_BLOCK_ID]: GenerateId.create(),
        [ATTR_POST_ID]: postIds[0],
      });

      tr.replaceSelectionWith(postNode);
      view.dispatch(tr);
    } else if (postIds.length > 1) {
      // First, check if the current selection sits inside a column block,
      // because we can't insert a column-list block inside a column block
      const columnable = checkIfSelectionIsInsideAColumnBlock(selection);

      if (columnable) {
        const MAX_COLUMN = 2;
        const columns: Fragment[][] = new Array(MAX_COLUMN)
          .fill(null)
          .map(() => []);

        postIds.forEach((postId, index) => {
          // Max 2 columns so we'll cycle through the columns
          columns[index % MAX_COLUMN].push(
            schema.nodes[NODE_EMBED].create({
              [ATTR_BLOCK_ID]: GenerateId.create(),
              [ATTR_POST_ID]: postId,
            }).toJSON(),
          );
        });

        const columnListNode = schema.nodeFromJSON(
          buildColumnBlock({
            content: columns.map((c) => buildColumn({ content: c })),
          }),
        );

        tr.replaceSelectionWith(columnListNode);
      } else {
        // Insert the posts as separate nodes
        const postNodes = postIds.map((postId) =>
          schema.nodes[NODE_EMBED].create({
            [ATTR_BLOCK_ID]: GenerateId.create(),
            [ATTR_POST_ID]: postId,
          }),
        );

        tr.insert(tr.selection.from, postNodes);
      }

      view.dispatch(tr);
    }
  };

  return { handleAddPostsToEditor };
};
