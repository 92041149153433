import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PostPreview } from 'features/post';
import {
  PostFragmentPostPreviewFragmentDoc,
  useGetPostByIdsForDndPostDragPreviewQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetPostByIdsForDndPostDragPreview($input: GetPostByIdsInput!) {
    postByIds(input: $input) {
      id
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export type DndPostDragPreviewProps = {
  postIds: string[];
};

export const DndPostDragPreview = (props: DndPostDragPreviewProps) => {
  const { postIds = [] } = props;

  // NOTE: We should be using cache data here
  const { data } = useGetPostByIdsForDndPostDragPreviewQuery({
    variables: {
      input: {
        postIds,
      },
    },
    skip: !postIds.length,
  });
  const posts = data?.postByIds || [];

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'visible',
        width: 120,
        height: 180,
      }}
    >
      {posts.slice(0, 3).map((post, index) => {
        return (
          <PostPreview
            key={post.id}
            post={post}
            sx={{
              position: 'absolute',
              top: index * 10,
              left: index * 10,
              zIndex: 3 - index,
              borderRadius: 4,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
        );
      })}
    </Box>
  );
};
