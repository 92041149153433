import { Box, Typography } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import {
  AccessRequestAdminList,
  AccessRequestAdminListProps,
} from 'features/accessRequest/components';
import { theme } from 'styles/theme';

interface AccessRequestSentViewProps {
  componentsProps: {
    adminList: AccessRequestAdminListProps;
  };
}

export const AccessRequestSentView = ({
  componentsProps,
}: AccessRequestSentViewProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb: 3,
        transition: `all 0.3s ease-in-out`,
      }}
    >
      <IconBoldTickCircle size={56} color={theme.colors?.utility['green-2']} />
      <Typography
        variant="headline-xl"
        fontSize={40}
        letterSpacing="-0.8px"
        color={theme.colors?.primary.black}
        mt={7}
      >
        Request sent
      </Typography>
      <Typography
        variant="subhead-xl"
        color={theme.colors?.primary.black}
        mt={5}
        mb={3}
      >
        You will be notified once they approved. Feel free to send them a
        message to move things along! 💌
      </Typography>
      <AccessRequestAdminList {...componentsProps.adminList} />
    </Box>
  );
};
