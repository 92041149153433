import { Box, BoxProps, Typography } from '@mui/material';
import { useLayoutEffect, useRef } from 'react';

export type InputHorizontalAutosizeProps = BoxProps<'input'> & {
  minWidth?: number;
};

/**
 * Input component that adjusts its width based on the content.
 */
export const InputHorizontalAutosize = (
  props: InputHorizontalAutosizeProps,
) => {
  const { value, minWidth = 320, sx, ...rest } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Dummy container that render the name invisibly to calculate the width
  const dummyNameContainerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    // Calculate the input width based on the organization name
    // It's mostly speculation, but let's see
    const input = inputRef.current;

    if (input && dummyNameContainerRef.current) {
      const inputWidth = !value
        ? minWidth
        : Math.max(dummyNameContainerRef.current.clientWidth, 40);
      input.style.width = `${inputWidth}px`;
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box ref={inputRef} component="input" {...rest} value={value} sx={sx} />
      <Typography
        ref={dummyNameContainerRef}
        // @ts-ignore
        // For some reason BoxProps<'input'>['sx'] is not assignable to TypographyProps['sx']
        sx={{
          ...sx,
          display: 'inline',
          whiteSpace: 'preserve nowrap',
          opacity: 0,
          pointerEvents: 'none',
          position: 'absolute',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
