import { Box, SxProps, Typography } from '@mui/material';
import { UserFragmentAvatarGroupFragment } from 'graphql/generated';
import { theme } from 'styles/theme/theme';
import { getFullName } from 'utils/users';
import { Avatar, AvatarProps } from './Avatar';

export type AvatarWithNameProps = {
  user: UserFragmentAvatarGroupFragment;
  avatarSize?: number;
  shouldHideEmail?: boolean;
  componentsProps?: {
    sx?: SxProps;
    avatar?: Pick<AvatarProps, 'sx' | 'disableTooltip'>;
    name?: {
      sx?: SxProps;
    };
    email?: {
      sx?: SxProps;
    };
  };
};

export const AvatarWithName = (props: AvatarWithNameProps) => {
  const {
    user,
    avatarSize = 40,
    shouldHideEmail,
    componentsProps = {},
  } = props;

  const isInvitationPending =
    user.hasSignedUp !== undefined && !user.hasSignedUp;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
      sx={componentsProps?.sx}
    >
      <Avatar user={user} size={avatarSize} {...componentsProps.avatar} />
      <Box display="grid">
        <Typography
          variant="headline-xs"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            ...componentsProps.name?.sx,
          }}
        >
          {getFullName(user)}
        </Typography>

        {!shouldHideEmail && (
          <Typography
            variant="subhead-sm"
            color={theme.colors?.utility[700]}
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              paddingRight: '2px',
              fontStyle: !isInvitationPending ? 'normal' : 'italic',
              ...componentsProps.email?.sx,
            }}
          >
            {user.email} {isInvitationPending && '· Invited'}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
