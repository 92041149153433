import { useRenderSocialListeningPostVideo } from 'features/socialMediaListening/hooks';
import { GetSocialPostForSocialMediaListeningPostDetailQuery } from 'graphql/generated';
import { forwardRef, useEffect, useImperativeHandle } from 'react';

export const SocialMediaListeningPostDetailVideo = forwardRef<
  {
    seekVideoTime: (time: number) => void;
    currentVideoTime: number;
  },
  {
    post: GetSocialPostForSocialMediaListeningPostDetailQuery['socialPost'];
    onHeightSet: (renderedHeight: number) => void;
  }
>(({ post, onHeightSet }, ref) => {
  const {
    renderVideoPlayer,
    seekVideoTime,
    currentVideoTime,
    renderedVideoHeight,
  } = useRenderSocialListeningPostVideo({
    post,
  });

  useImperativeHandle(
    ref,
    () => ({
      seekVideoTime,
      currentVideoTime,
    }),
    [seekVideoTime, currentVideoTime],
  );

  useEffect(() => {
    onHeightSet(renderedVideoHeight);
  }, [renderedVideoHeight, onHeightSet]);

  return renderVideoPlayer();
});
