import { gql } from '@apollo/client';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import {
  ContentIdeaFragmentContentIdeaNameFragment,
  ContentIdeaPermission,
  useUpdateContentIdeaForContentIdeaNameMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_NAME = gql`
  fragment ContentIdeaFragmentContentIdeaName on ContentIdeaModel {
    id
    name
    myPermissions
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaForContentIdeaName($data: UpdateContentIdeaInput!) {
    updateContentIdea(data: $data) {
      id
      ...ContentIdeaFragmentContentIdeaName
    }
  }
  ${CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_NAME}
`;

export type ContentIdeaNameProps = TextareaAutosizeProps & {
  contentIdea: ContentIdeaFragmentContentIdeaNameFragment;
};

export const ContentIdeaName = (props: ContentIdeaNameProps) => {
  const { contentIdea, style, ...rest } = props;

  const [updateContentIdea] = useUpdateContentIdeaForContentIdeaNameMutation();

  const onChange = useMemo(
    () =>
      debounce((name: string) => {
        updateContentIdea({
          variables: {
            data: {
              contentIdeaId: contentIdea.id,
              data: {
                name,
              },
            },
          },
          optimisticResponse: {
            updateContentIdea: {
              ...contentIdea,
              name,
            },
          },
        });
      }, 300),
    [contentIdea, updateContentIdea],
  );

  // Workaround to update the textarea value when the contentIdea.name changes
  // as we are using `defaultValue` instead of `value` to avoid blocking user input
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (ref.current && ref.current.value !== contentIdea.name && !isFocused) {
      ref.current.value = contentIdea.name;
    }
  }, [contentIdea.name]); // eslint-disable-line

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  return (
    <TextareaAutosize
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      defaultValue={contentIdea.name}
      placeholder="This is a juicy idea"
      onChange={(e) => onChange(e.target.value)}
      style={{
        ...typography['headline-sm'],
        backgroundColor: 'transparent',
        outline: 0,
        border: 0,
        resize: 'none',
        width: '100%',
        ...style,
      }}
      onFocus={(event) => {
        event.target.select();
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      readOnly={!canEdit}
      {...rest}
    />
  );
};
