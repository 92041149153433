import { Box, SxProps, Typography } from '@mui/material';
import { IconBoldFlag } from 'components/icons/components/bold/IconBoldFlag';
import {
  taskPriorityBackgroundColor,
  taskPriorityText,
  taskPriorityTextColor,
} from 'features/task/constants';
import { TaskPriority } from 'graphql/generated';
import { theme } from 'styles/theme';

type Props = {
  priority?: TaskPriority | null;
  sx?: SxProps;
  btnText?: string;
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
  };
};

export const TaskPriorityButton = (props: Props) => {
  const { priority, sx, componentsProps = {}, btnText = 'Priority' } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: theme.spacing(2, 2.5),
        gap: 2,
        borderRadius: 3,
        backgroundColor: theme.colors?.utility[250],
        ...sx,
      }}
    >
      {priority ? (
        <Box
          display="flex"
          width="fit-content"
          sx={{
            p: 1,
            borderRadius: 1,
            color: taskPriorityTextColor[priority],
            backgroundColor: taskPriorityBackgroundColor[priority],
            width: 24,
            height: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
        >
          <Typography variant="body-xl" lineHeight={1}>
            {taskPriorityText[priority]}
          </Typography>
        </Box>
      ) : (
        <IconBoldFlag
          size={componentsProps?.icon?.size || 24}
          color={componentsProps?.icon?.color || theme.colors?.utility[900]}
        />
      )}
      <Typography variant="body-xl" color={theme.colors?.utility[900]}>
        {priority ? `${priority}` : btnText}
      </Typography>
    </Box>
  );
};
