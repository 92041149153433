import { gql } from '@apollo/client';
import { Box, TableCell, Typography } from '@mui/material';
import { TopicLabel } from 'features/topic';
import {
  TopicFragmentTopicInfoTableCellFragment,
  TopicFragmentTopicLabelFragmentDoc,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment TopicFragmentTopicInfoTableCell on TopicModel {
    id
    name
    brandId
    ...TopicFragmentTopicLabel
  }
  ${TopicFragmentTopicLabelFragmentDoc}
`;

type Props = {
  brandId: string;
  topics: TopicFragmentTopicInfoTableCellFragment[];
};

export const TopicInfoTableCell = ({ brandId, topics }: Props) => {
  const brandTopic = topics.find((topic) => topic.brandId === brandId);
  if (!brandTopic || !topics.length) {
    return <TableCell>-</TableCell>;
  }
  return (
    <TableCell>
      <Box>
        <Typography mb={1} display="block" variant="body-lg">
          {brandTopic.name}
        </Typography>
        <TopicLabel topic={brandTopic} />
      </Box>
    </TableCell>
  );
};
