import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme';

type Props = {
  selectedTabIndex: number;
  options: React.ReactNode[];
  onTabSelect: (index: number) => void;
  componentProps?: {
    sx?: SxProps;
  };
};

export const TabSelector = ({
  options,
  selectedTabIndex,
  onTabSelect,
  componentProps,
}: Props) => {
  return (
    <Box
      sx={{
        pb: 0,
        minHeight: 'unset',
        px: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: theme.spacing(1),
        height: theme.spacing(9),
        borderRadius: theme.spacing(2),
        gap: `${theme.spacing(2)} !important`,
        backgroundColor: theme.colors?.utility[300],
        width: 'fit-content',
        ...componentProps?.sx,
      }}
    >
      {options.map((option, index) => (
        <Box
          key={index}
          onClick={() => onTabSelect(index)}
          className={`tab-select-btn ${
            index === selectedTabIndex ? 'selected' : ''
          }`}
          sx={{
            cursor: 'pointer',
            ...(index === selectedTabIndex
              ? {
                  backgroundColor: theme.colors?.primary.black,
                  color: theme.colors?.primary.white,
                  padding: theme.spacing(1, 2),
                  borderRadius: theme.spacing(2),
                }
              : { px: theme.spacing(2) }),
          }}
        >
          <Typography fontWeight={600} variant="body-lg">
            {option}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
