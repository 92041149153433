import { Box } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import { useCheckRenderingWidget } from 'components/common/Iframely/hooks/useCheckRenderingWidget';
import { Image } from 'components/common/Image';
import { TrendingSocialMediaPreview } from 'features/trending/components';
import { TrendingAudioPreview } from 'features/trending/components/preview/TrendingAudioPreview';
import { useMemo, useRef } from 'react';
import { TrendingDetailActionButtonsView } from '../ActionButtons';
import { TrendingDetailMainViewProps } from './types';

export const TrendingDetailMainView = (props: TrendingDetailMainViewProps) => {
  const { trend, componentProps } = props;
  const urlMetadata = trend.trendExamples?.[0].urlMetadata;
  const videoRef = useRef<HTMLVideoElement>();

  const { mimeType, url, hasPreviewableSocialMedia } = useMemo(
    () => ({
      url: urlMetadata?.url,
      mimeType: urlMetadata?.metadata?.type || '',
      hasPreviewableSocialMedia:
        (urlMetadata.metadata?.medias || [])?.length > 0,
    }),
    [urlMetadata],
  );

  const { customRenderingElement } = useCheckRenderingWidget({
    mimeType,
    url,
    hasPreviewableSocialMedia,
  });

  const renderTrendCustomView = useMemo(() => {
    const renderTrendActionButton = (
      urlMetadataId: string,
      {
        savedContentCalendarTotal,
        totalSavedCount,
      }: { savedContentCalendarTotal: number; totalSavedCount: number },
    ) => {
      return (
        <TrendingDetailActionButtonsView
          {...componentProps.actionButtons}
          trend={trend}
          urlMetadataId={urlMetadataId}
          totalCounts={{
            contentCalendar: savedContentCalendarTotal,
            trends: totalSavedCount,
          }}
        />
      );
    };

    if (trend.trendAudio) {
      return (
        <>
          <TrendingAudioPreview trendAudio={trend.trendAudio} />
          <Box
            sx={{
              display: 'flex',
              marginBottom: 19,
            }}
          >
            {renderTrendActionButton(trend.trendAudio.urlMetadata.id, {
              savedContentCalendarTotal:
                trend.trendAudioSavedCount?.savedContentCalendarTotal || 0,
              totalSavedCount: trend.trendAudioSavedCount?.totalSavedCount || 0,
            })}
          </Box>
        </>
      );
    }

    if (
      trend.trendExamples?.[0].urlMetadata.metadata?.medias?.length &&
      trend.trendExamples?.[0]
    ) {
      return (
        <Box
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gap: 1,
            }}
          >
            <TrendingSocialMediaPreview
              trendAudio={trend?.trendAudio ?? undefined}
              trendExample={trend.trendExamples[0]}
              ref={videoRef}
            />
            {trend?.trendAudio && (
              <TrendingAudioPreview
                trendAudio={trend.trendAudio}
                variant="detail"
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              // @ts-ignore
              marginBottom: trend?.trendAudio?.coverUrl ? 19 : 0,
            }}
          >
            {renderTrendActionButton(urlMetadata.id, {
              savedContentCalendarTotal:
                trend.trendExamples[0].savedContentCalendarTotal,
              totalSavedCount: trend.trendExamples[0].totalSavedCount,
            })}
          </Box>
        </Box>
      );
    }

    return (
      <>
        <Box
          key={trend.id}
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: 4,
          }}
          id={trend.id}
          className="animate__animated animate__fadeIn"
        >
          {customRenderingElement === 'img' ? (
            <Image
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                maxHeight: 650,
                borderRadius: 'inherit',
              }}
              src={url}
            />
          ) : (
            <Iframely
              key={urlMetadata.id}
              componentsProps={{
                iframelyEmbedWidget: {
                  componentsProps: {
                    noPreview: {
                      sx: {
                        padding: 4,
                        width: 300,
                        height: 200,
                        borderRadius: 4,
                      },
                    },
                  },
                  sx: {
                    overflow: 'unset',
                  },
                },
              }}
              urlMetadata={urlMetadata}
              url={urlMetadata.url}
            />
          )}
        </Box>
        {renderTrendActionButton(urlMetadata.id, {
          savedContentCalendarTotal:
            trend.trendExamples[0].savedContentCalendarTotal,
          totalSavedCount: trend.trendExamples[0].totalSavedCount,
        })}
      </>
    );
  }, [
    customRenderingElement,
    trend,
    urlMetadata,
    url,
    componentProps.actionButtons,
  ]);

  return renderTrendCustomView;
};
