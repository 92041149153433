import { gql } from '@apollo/client';
import { Box, Link, SxProps, Typography } from '@mui/material';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening/hooks';
import {
  SocialPostFragmentSocialPostPlatformFragmentDoc,
  SocialPostFragmentSocialPostThumbnailFragment,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { SocialPostPlatform } from '../platform';

// eslint-disable-next-line
gql`
  fragment SocialPostFragmentSocialPostThumbnail on SocialPostModel {
    id
    videoUrl
    thumbnailUrl
    platformCreateTime
    ...SocialPostFragmentSocialPostPlatform
    ...SocialPostFragmentUseSocialListeningPostLinkGenerator
  }
  ${SocialPostFragmentSocialPostPlatformFragmentDoc}
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

type SocialPostThumbnailProps = {
  socialPost: SocialPostFragmentSocialPostThumbnailFragment;
  sx?: SxProps;
  componentProps?: {
    hidePlatform?: boolean;
    hideCreatedTime?: boolean;
    thumbnailFallback?: React.ReactNode;
  };
};

export const SocialPostThumbnail = (props: SocialPostThumbnailProps) => {
  const { socialPost, sx, componentProps } = props;

  const { generateLink } = useSocialListeningPostLinkGenerator();

  const createdTimeHumanReadable = useMemo(() => {
    return moment(socialPost.platformCreateTime).format('YYYY-M-D');
  }, [socialPost.platformCreateTime]);

  // We will use videoUrl if thumbnailUrl is not available
  const [thumbnailHasError, setThumbnailHasError] = useState(false);
  const [videoHasError, setVideoHasError] = useState(false);

  return (
    <Link href={generateLink(socialPost)} target="_blank">
      <Box sx={{ position: 'relative' }}>
        {!componentProps?.hidePlatform && (
          <SocialPostPlatform
            socialPost={socialPost}
            componentProps={{
              sx: {
                position: 'absolute',
                left: theme.spacing(2),
                top: theme.spacing(2),
                svg: {
                  color: socialPost.thumbnailUrl
                    ? theme.colors?.primary.white
                    : theme.colors?.utility[700],
                },
              },
            }}
          />
        )}

        {socialPost.thumbnailUrl && !thumbnailHasError ? (
          <Box
            component="img"
            sx={{
              width: theme.spacing(15),
              height: theme.spacing(21),
              objectFit: 'cover',
              borderRadius: theme.spacing(3),
              ...sx,
            }}
            src={socialPost.thumbnailUrl}
            onError={() => setThumbnailHasError(true)}
          />
        ) : socialPost.videoUrl && !videoHasError ? (
          <Box
            component="video"
            sx={{
              width: theme.spacing(15),
              height: theme.spacing(21),
              objectFit: 'cover',
              borderRadius: theme.spacing(3),
              ...sx,
            }}
            src={socialPost.videoUrl}
            onError={() => setVideoHasError(true)}
            autoPlay={false}
          />
        ) : (
          <Box
            sx={{
              width: theme.spacing(15),
              height: theme.spacing(21),
              bgcolor: theme.colors?.utility[400],
              objectFit: 'cover',
              borderRadius: theme.spacing(3),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              ...sx,
            }}
          >
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              {componentProps?.thumbnailFallback || `Can't load thumbnail`}
            </Typography>
          </Box>
        )}

        {!componentProps?.hideCreatedTime && (
          <Typography
            variant="headline-xxs"
            color={
              socialPost.thumbnailUrl
                ? theme.colors?.primary.white
                : theme.colors?.utility[700]
            }
            sx={{
              position: 'absolute',
              left: theme.spacing(2),
              bottom: theme.spacing(2),
            }}
          >
            {createdTimeHumanReadable}
          </Typography>
        )}
      </Box>
    </Link>
  );
};
