import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { PlotRoutes } from 'Routes';
import { toast } from 'components/common/Toast';
import { useCollectionPermissionUpdateCollectionPermissionToParentCollectionPermission } from 'features/collectionPermission';
import { PostMoveToAnotherCollection } from 'features/post/views/moveToAnotherCollection';
import {
  CollectionFragmentCollectionMultiPostPreviewFragment,
  CollectionFragmentUpdatePostPermissionAlertViewFragment,
  useMoveCollectionsToCollectionForUseMoveToAnotherCollectionModalMutation,
  useRemoveCollectionsParentForUseMoveToAnotherCollectionModalMutation,
} from 'graphql/generated';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';
import { CollectionMultiPostPreview } from '../components';
import { CUSTOM_COLLECTION } from '../constants';

type Props = {
  collectionIds: string[];
  currentCollectionId: string;
  onAfterMove?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation MoveCollectionsToCollectionForUseMoveToAnotherCollectionModal(
    $data: MoveCollectionsToCollectionInput!
  ) {
    moveCollectionsToCollection(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation RemoveCollectionsParentForUseMoveToAnotherCollectionModal(
    $data: RemoveCollectionsParentInput!
  ) {
    removeCollectionsParent(data: $data) {
      success
      message
    }
  }
`;

export const useMoveToAnotherCollectionModal = ({
  currentCollectionId,
  collectionIds,
  onAfterMove,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const location = useLocation();

  const isCustomCollection = collectionIds.some((collectionId) =>
    Object.values(CUSTOM_COLLECTION).includes(
      collectionId as CUSTOM_COLLECTION,
    ),
  );

  const [moveCollectionsToCollection] =
    useMoveCollectionsToCollectionForUseMoveToAnotherCollectionModalMutation();

  const [removeCollectionsParent] =
    useRemoveCollectionsParentForUseMoveToAnotherCollectionModalMutation();

  const {
    onOpenUpdateCollectionPermissionDialog,
    renderUpdateCollectionPermissionAlert,
  } =
    useCollectionPermissionUpdateCollectionPermissionToParentCollectionPermission();

  const { cache } = useApolloClient();

  const renderCollectionMovedToast = (
    toCollection: CollectionFragmentUpdatePostPermissionAlertViewFragment,
    onNavigate: () => void,
  ) => {
    toast({
      position: 'bottom-center',
      shouldShowCloseButton: false,
      sx: {
        bgcolor: theme.colors?.utility['purple-1'],
        color: theme.colors?.utility['purple-4'],
        borderRadius: theme.spacing(6),
        padding: theme.spacing(5, 6),
        alignItems: 'center',
        '& .MuiButtonBase-root': {
          padding: 0,
        },
      },
      message: '',
      icon: toCollection ? (
        <CollectionMultiPostPreview
          sx={{ width: theme.spacing(15), height: theme.spacing(15) }}
          collection={
            toCollection as unknown as CollectionFragmentCollectionMultiPostPreviewFragment
          }
          variant="card-stack-square"
        />
      ) : undefined,
      title: 'Collection moved!',
      actionButtons: [
        {
          variant: 'text',
          children: `Go to ${toCollection.name}`,
          onClick: onNavigate,
          sx: {
            color: theme.colors?.primary.black,
            ...theme.typography['headline-sm'],
          },
        },
      ],
    });
  };

  const onMoveCollection = useCallback(
    async (
      collectionIds: string[],
      toCollection: CollectionFragmentUpdatePostPermissionAlertViewFragment,
    ) => {
      await moveCollectionsToCollection({
        variables: {
          data: {
            collectionIds,
            toCollectionId: toCollection.id,
          },
        },
      });

      if (toCollection) {
        await onOpenUpdateCollectionPermissionDialog(
          collectionIds,
          toCollection,
          () => {
            collectionIds.forEach((collectionId) => {
              evictObject(cache, collectionId, 'CollectionModel');
            });
            onAfterMove?.();
          },
        );
        renderCollectionMovedToast(toCollection, () =>
          navigate(PlotRoutes.collection(toCollection.id)),
        );
      }
      onClose();
    },
    [moveCollectionsToCollection, onClose, onAfterMove], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onMoveCollectionToRoot = useCallback(async () => {
    await removeCollectionsParent({
      variables: { data: { collectionIds } },
    });

    renderCollectionMovedToast(
      {
        id: '',
        name: 'Creative Juicebox',
      },
      () => navigate(PlotRoutes.juicebox()),
    );
    collectionIds.forEach((collectionId) => {
      evictObject(cache, collectionId, 'CollectionModel');
    });
    onAfterMove?.();
    onClose();
  }, [
    removeCollectionsParent,
    collectionIds,
    onAfterMove,
    onClose,
    navigate,
    cache,
  ]);

  const render = useCallback(() => {
    const isCjbRoute = location.pathname.includes(PlotRoutes.juicebox());

    return (
      <>
        {isCustomCollection || !isOpen ? null : (
          <PostMoveToAnotherCollection
            collectionIds={collectionIds}
            isOpen={isOpen}
            onClose={onClose}
            onMove={(toCollection) => {
              if (toCollection) {
                onMoveCollection(collectionIds, toCollection);
              } else {
                onMoveCollectionToRoot();
              }
            }}
            currentCollectionId={currentCollectionId}
            componentsProps={{
              layout: {
                context: isCjbRoute ? 'root' : 'parent',
              },
            }}
          />
        )}

        {renderUpdateCollectionPermissionAlert()}
      </>
    );
  }, [
    location.pathname,
    isCustomCollection,
    isOpen,
    collectionIds,
    onClose,
    currentCollectionId,
    renderUpdateCollectionPermissionAlert,
    onMoveCollection,
    onMoveCollectionToRoot,
  ]);

  return {
    onMoveCollection,
    renderMoveToAnotherCollectionModal: render,
    showMoveCollection: onOpen,
    hideMoveCollection: onClose,
  };
};
