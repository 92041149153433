import { gql } from '@apollo/client';
import {
  InternalOrganizationRole,
  useGetMyPendingOrganizationAccessRequestsForAdminForPendingActionsQuery,
} from 'graphql/generated';
import React, { useContext, useMemo } from 'react';
import { useUserContext } from '../User.context';

// eslint-disable-next-line
gql`
  query GetMyPendingOrganizationAccessRequestsForAdminForPendingActions {
    myPendingOrganizationAccessRequestsForAdmin {
      id
      user {
        id
        email
      }
    }
  }
`;

type PendingActionContextProps = {
  areTherePendingUserRequests: boolean;
};

const PendingActionContext = React.createContext<PendingActionContextProps>({
  areTherePendingUserRequests: false,
});

export const usePendingActions = () => {
  return useContext(PendingActionContext);
};

export const PendingActionsProvider = ({ children }) => {
  const { user } = useUserContext();
  const { data } =
    useGetMyPendingOrganizationAccessRequestsForAdminForPendingActionsQuery({
      skip: !user || user.role !== InternalOrganizationRole.Admin,
    });

  const areTherePendingUserRequests = useMemo(() => {
    if (user && user.role === InternalOrganizationRole.Admin) {
      return (
        (data?.myPendingOrganizationAccessRequestsForAdmin || []).length > 0
      );
    }

    return false;
  }, [user, data?.myPendingOrganizationAccessRequestsForAdmin]);

  return (
    <PendingActionContext.Provider
      value={{
        areTherePendingUserRequests,
      }}
    >
      {children}
    </PendingActionContext.Provider>
  );
};
