import { OrganizationBillingModel } from 'graphql/generated';

export type UseIsPaidMemberLimitReachedProps = {
  organizationBilling?: Pick<
    OrganizationBillingModel,
    'organizationMemberUsage' | 'organizationMemberLimit'
  >;
};

export const useIsPaidMemberLimitReached = (
  props: UseIsPaidMemberLimitReachedProps,
) => {
  const { organizationBilling } = props;

  if (!organizationBilling) {
    return false;
  }

  // If organizationMemberLimit is null, it means there is no limit
  if (organizationBilling.organizationMemberLimit === null) {
    return false;
  }

  return (
    organizationBilling.organizationMemberUsage >=
    (organizationBilling.organizationMemberLimit || 1)
  );
};
