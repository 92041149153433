import { Box, Button, Dialog, SxProps, Typography } from '@mui/material';
import { theme } from 'styles/theme/theme';

export interface Props {
  open: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  sx?: SxProps;
}

export const ConfirmationDialog = (props: Props) => {
  const {
    open,
    title = 'Are you sure?',
    subtitle,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onConfirm,
    onCancel,
    sx,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={(event) => {
        // @ts-ignore
        event?.preventDefault?.();
        // @ts-ignore
        event?.stopPropagation?.();

        onCancel?.();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          p: 6,
          maxWidth: 325,
          borderRadius: 6,
          ...(sx || {}),
        },
      }}
      sx={{
        zIndex: 1999,
      }}
    >
      <Typography variant="headline-lg" mb={3}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body-lg" color={theme.colors?.utility[700]}>
          {subtitle}
        </Typography>
      )}
      <Box display="flex" flexDirection="column" mt={6} gap={3}>
        <Button
          variant="primary"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          sx={{
            bgcolor: `${theme.colors?.utility['pink-3']} !important`,
            borderColor: `${theme.colors?.utility['pink-3']} !important`,
          }}
        >
          {confirmText}
        </Button>
        <Button
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            onCancel?.();
          }}
          sx={{ color: theme.colors?.utility[800] }}
        >
          {cancelText}
        </Button>
      </Box>
    </Dialog>
  );
};
