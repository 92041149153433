import { gql } from '@apollo/client';
import { Box, Skeleton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Image } from 'components/common/Image';
import { PostIcon } from 'features/post';
import {
  PostFragmentJuiceboxAttachmentSearchItemFragment,
  PostFragmentPostIconFragmentDoc,
  useCreateSearchHitForJuiceboxFilterSuggestionViewMutation,
} from 'graphql/generated';
import { ResourceUploadType } from 'hooks/useResourceUploadQueue';
import { useResolveResourcePreview } from 'hooks/useResourceUploadQueue/useResolveResourcePreview';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment PostFragmentJuiceboxAttachmentSearchItem on PostModel {
    id
    ...PostFragmentPostIcon
    attachment {
      id
      name
      url
      metaData {
        mimeType
      }
    }
  }
  ${PostFragmentPostIconFragmentDoc}
`;

interface Props {
  attachment: PostFragmentJuiceboxAttachmentSearchItemFragment;
  query?: string;
}

export const JuiceboxAttachmentSearchItem = forwardRef(
  (
    { attachment: attachmentPost, query }: Props,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const { attachment } = attachmentPost;
    const [createAttachmentSearchHit] =
      useCreateSearchHitForJuiceboxFilterSuggestionViewMutation();
    const location = useLocation();

    const { url, title, mimeType } = useMemo(() => {
      return {
        url: attachment?.url || '',
        title: attachment?.name || '',
        mimeType: attachment?.metaData.mimeType || '',
        fileExtension: attachment?.name?.split('.')?.pop()?.toUpperCase() || '',
      };
    }, [attachment]);

    const { isLoading: isLoadingPreview, previewUrl } =
      useResolveResourcePreview({
        iconSize: 16,
        type: ResourceUploadType.Attachment,
        content: url,
        name: title,
        mimeType,
      });

    return (
      <Link
        to={PlotRoutes.juice(attachmentPost.id)}
        state={{
          backgroundLocation: location,
        }}
      >
        <Box
          ref={ref}
          tabIndex={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            borderRadius: 3,
            padding: theme.spacing(2, 3),
            ':hover': {
              backgroundColor: theme.colors?.utility[400],
            },
            '&:focus': {
              backgroundColor: theme.colors?.utility[400],
              outline: 'none',
            },
          }}
          onClick={() => {
            createAttachmentSearchHit({
              variables: {
                data: {
                  post: {
                    id: attachmentPost.id,
                  },
                  query,
                },
              },
            });
          }}
        >
          {isLoadingPreview ? (
            <Skeleton
              variant="rounded"
              width={24}
              height={36}
              sx={{ flexShrink: 0 }}
            />
          ) : previewUrl ? (
            <Image
              sx={{
                borderRadius: theme.spacing(2),
                objectFit: 'cover',
                height: 36,
                width: 24,
                flexShrink: 0,
              }}
              src={previewUrl}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 24,
                height: 24,
                minWidth: 24,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: 1,
              }}
            >
              <PostIcon
                post={attachmentPost}
                mimeType={attachment?.metaData?.mimeType || ''}
                size={16}
                style={{ color: theme.colors?.primary.black }}
              />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Typography
              variant="subhead-lg"
              color={theme.colors?.primary.black}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: 525,
              }}
            >
              {attachment?.name?.split('.').shift() || attachment?.url}
              {attachment?.name?.split('.').shift() || attachment?.url}
            </Typography>
            <Typography
              variant="subhead-sm"
              color={theme.colors?.utility[800]}
              textTransform={
                attachment?.metaData?.mimeType ? 'uppercase' : 'capitalize'
              }
            >
              {attachment?.metaData?.mimeType?.split('/')?.pop() ||
                'Attachment'}
            </Typography>
          </Box>
        </Box>
      </Link>
    );
  },
);
