import { Box, CircularProgress } from '@mui/material';
import { theme } from 'styles/theme/theme';

interface Props {
  size?: number;
  value?: number;
}

export const CircularProgressWithTrack = (props: Props) => {
  const { size = 16, value = 0 } = props;

  return (
    <Box position="relative">
      <CircularProgress
        variant="determinate"
        size={size}
        value={100}
        sx={{
          color: theme.colors?.utility[400],
        }}
      />
      <CircularProgress
        variant="determinate"
        size={size}
        value={value}
        sx={{
          color: theme.colors?.primary.maroon,
          position: 'absolute',
          left: 0,
        }}
      />
    </Box>
  );
};
