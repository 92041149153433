import { SxProps } from '@mui/material';
import { PostThemeColor } from 'features/note/constants';
import {
  ATTR_NOTE_COMMENT_ANCHOR_ID,
  CommentExtensionOptions,
  CommentInputPopover,
} from 'features/tiptap';
import { CommentThreadsPopover } from 'features/tiptap/extensions/comment/components/CommentThreadsPopover';
import {
  CommentInputData,
  PostAnnotationFragmentAnnotationFragment,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export type UseCommentHandlersProps = {
  themeColor?: PostThemeColor;
  onCreateComment?: (
    comment: CommentInputData,
    noteCommentAnchorId: string,
  ) => Promise<void>;
  onCommentDeleted?: () => void;
};

export const useCommentHandlers = (props: UseCommentHandlersProps) => {
  const {
    themeColor,
    onCreateComment: _onCreateComment,
    onCommentDeleted,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { postAnnotationToPop, postAnnotationToHighlight } = (location.state ||
    {}) as {
    postAnnotationToPop?: PostAnnotationFragmentAnnotationFragment;
    postAnnotationToHighlight?: PostAnnotationFragmentAnnotationFragment;
  };

  const [newNoteCommentAnchorId, setNewNoteCommentAnchorId] = useState('');
  const onFinishAddCommentRef = useRef<() => void>();
  const onCancelAddCommentRef = useRef<() => void>();

  const onTriggerAddComment: CommentExtensionOptions['onTriggerAddComment'] = (
    props,
  ) => {
    const { noteCommentAnchorId, onCancelAddComment, onFinishAddComment } =
      props;

    setNewNoteCommentAnchorId(noteCommentAnchorId);
    onFinishAddCommentRef.current = onFinishAddComment;
    onCancelAddCommentRef.current = onCancelAddComment;
  };

  const onCreateComment = async (comment: CommentInputData) => {
    if (newNoteCommentAnchorId) {
      await _onCreateComment?.(comment, newNoteCommentAnchorId);
      onFinishAddCommentRef.current?.();
    }

    setNewNoteCommentAnchorId('');
  };

  const [focusedNoteCommentAnchorId, setFocusedNoteCommentAnchorId] =
    useState('');

  const onCommentMarkClick = (noteCommentAnchorId: string) => {
    setFocusedNoteCommentAnchorId(noteCommentAnchorId);
  };

  // When the user clicks on a comment in the comment sidebar, if there's a matching
  // comment in the editor, scroll to it and show comment threads popover
  useEffect(() => {
    if (postAnnotationToPop) {
      document
        .querySelector(
          `[${ATTR_NOTE_COMMENT_ANCHOR_ID}*="${postAnnotationToPop.noteCommentAnchorId}"]`,
        )
        ?.scrollIntoView();
      setFocusedNoteCommentAnchorId(
        postAnnotationToPop.noteCommentAnchorId || '',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postAnnotationToPop?.noteCommentAnchorId]);

  // Highlight the comment in the editor when the user hover on a comment in the comment sidebar
  // This styles should be applied to the editor's container
  const commentHighlightStyles: SxProps = postAnnotationToHighlight
    ? {
        [`[${ATTR_NOTE_COMMENT_ANCHOR_ID}*="${postAnnotationToHighlight?.noteCommentAnchorId}"]`]:
          {
            backgroundColor: `${themeColor?.textCommentedHighlightedBackgroundColor} !important`,
          },
      }
    : {};

  return {
    commentHighlightStyles,
    handlersRender: (
      <>
        {newNoteCommentAnchorId && (
          <CommentInputPopover
            noteCommentAnchorId={newNoteCommentAnchorId}
            onClose={() => {
              setNewNoteCommentAnchorId('');
              onCancelAddCommentRef.current?.();
            }}
            onCreateComment={onCreateComment}
          />
        )}
        {focusedNoteCommentAnchorId && (
          <CommentThreadsPopover
            noteCommentAnchorId={focusedNoteCommentAnchorId}
            onClose={() => {
              setFocusedNoteCommentAnchorId('');
              navigate(location.pathname, {
                state: {
                  ...((location.state || {}) as any),
                  postAnnotationToPop: undefined,
                },
                replace: true,
              });
            }}
            onCommentDeleted={onCommentDeleted}
          />
        )}
      </>
    ),
    onTriggerAddComment,
    onCommentMarkClick,
  };
};
