import { Box, FormLabel, SxProps } from '@mui/material';
import { ComponentProps } from 'react';
import { Controller } from 'react-hook-form';
import { typography } from 'components/common/Typography/styles';
import { theme } from 'styles/theme/theme';

export const CustomController = (props: {
  // FIXME: Put this as any for now because Control<FieldValues> is not generic
  // and will cause type erros when we pass in control with a defined defaultValues.
  // Will need to spend more time looking into this.
  control: any;
  rules?: ComponentProps<typeof Controller>['rules'];
  label?: string;
  name: string;
  id?: string;
  labelGap?: number;
  render: ComponentProps<typeof Controller>['render'];
  defaultValue?: string;
  sx?: SxProps;
}) => {
  const {
    control,
    rules,
    name,
    id,
    label,
    labelGap = 2,
    render,
    defaultValue,
    sx,
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ fieldState, ...rest }) => {
        return (
          <Box display="flex" flexDirection="column" width="100%" sx={sx}>
            {label && (
              <FormLabel
                required={!!rules?.required}
                htmlFor={id || name}
                sx={{
                  ...typography['headline-xs'],
                  color: theme.colors?.primary.black,
                  lineHeight: 'normal',
                  mb: labelGap,
                }}
              >
                {label}
              </FormLabel>
            )}
            {render({ fieldState, ...rest })}
            {fieldState.error?.message && (
              <Box
                component="small"
                sx={{ color: theme.colors?.utility.red, mt: 2 }}
              >
                {fieldState.error?.message}
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
};
