import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconLogoPlotLogo } from 'components/icons/components/logo/IconLogoPlotLogo';
import { IconOutlineMenu1 } from 'components/icons/components/outline/IconOutlineMenu1';
import { usePendingActions } from 'contexts/users/PendingActions';
import { useUserContext } from 'contexts/users/User.context';
import { BillingLimitProgressView } from 'features/billing';
import { UserProfileModel } from 'graphql/generated';
import { Fragment, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme/theme';
import {
  StyledLogoContainer,
  StyledNameContainer,
  StyledShortCutContainer,
  StyledShortCutWrapper,
  StyledTab,
} from './styles';
import { LeftDrawerProps, TabName } from './types';

export const DRAWER_WIDTH = '285px';

export const LeftDrawer = ({
  tabs,
  isOpen,
  variant,
  topTabs,
  onClose,
}: LeftDrawerProps) => {
  const { user } = useUserContext();

  const { areTherePendingUserRequests } = usePendingActions();

  const pendingNotifications = useCallback(
    (tabName: TabName) => {
      if (tabName === TabName.SETTINGS) {
        return areTherePendingUserRequests;
      }
      return false;
    },
    [areTherePendingUserRequests],
  );

  useEffect(() => {
    const element = document.getElementById('site-header-menu');
    if (element) {
      element.style.display = isOpen ? 'none' : 'block';
    }
  }, [isOpen]);

  return (
    <Fragment key="left-drawer">
      <Drawer
        variant={variant}
        anchor="left"
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true,
        }}
        SlideProps={{
          unmountOnExit: false,
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            padding: '24px',
            width: DRAWER_WIDTH,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: theme.colors?.utility[250],
            border: `1px solid ${theme.colors?.utility[300]}`,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="column" gap={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <StyledNameContainer gap={2}>
                    <StyledLogoContainer>
                      <IconLogoPlotLogo
                        size={12}
                        color={theme.colors?.primary.white}
                      />
                    </StyledLogoContainer>
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.utility[1000]}
                    >
                      Plot
                    </Typography>
                  </StyledNameContainer>
                </Link>
                <IconButton onClick={onClose}>
                  <IconOutlineMenu1 color={theme.colors?.utility[800]} />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap={4} marginTop={4}>
              {topTabs.map((tab, idx) => {
                return tab.type === 'link' ? (
                  <Link
                    to={tab.disabled ? '#' : tab.href}
                    key={idx}
                    style={{
                      opacity: tab.disabled ? 0.5 : 1,
                      pointerEvents: tab.disabled ? 'none' : 'auto',
                    }}
                  >
                    <Box
                      sx={{
                        cursorEvent: tab.disabled ? 'none' : 'auto',
                        display: 'flex',
                        gap: theme.spacing(2),
                        borderTop: tab.paywall
                          ? `1px solid ${theme.colors?.utility[400]}`
                          : 'none',
                        borderBottom: tab.paywall
                          ? `1px solid ${theme.colors?.utility[400]}`
                          : 'none',
                        padding: tab.paywall
                          ? theme.spacing(4, 0)
                          : theme.spacing(0),
                        flexDirection: 'column',
                      }}
                    >
                      <StyledTab>
                        <Badge
                          color="primary"
                          variant="dot"
                          badgeContent={pendingNotifications(tab.name) ? 1 : 0}
                        >
                          {tab.loading ? (
                            <CircularProgress
                              size={16}
                              sx={{
                                color: tab.color ?? theme.colors?.utility[900],
                              }}
                            />
                          ) : (
                            <tab.icon
                              size={16}
                              color={tab.color ?? theme.colors?.utility[900]}
                            />
                          )}
                        </Badge>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={2}
                          flex={1}
                        >
                          <Typography
                            variant="subhead-md"
                            color={theme.colors?.utility[900]}
                            width="100%"
                          >
                            {tab.name}
                          </Typography>
                          {tab.beta && (
                            <Chip
                              sx={{
                                ...typography['headline-xxs'],
                                bgcolor: theme.colors?.utility['blue-1'],
                                color: theme.colors?.utility['blue-4'],
                                height: 'unset',
                                px: 2,
                                py: 1,
                                border: 'none',
                                ml: 'auto',
                                span: {
                                  padding: 0,
                                },
                              }}
                              label="Beta"
                            />
                          )}
                          {tab.new && (
                            <Chip
                              sx={{
                                ...typography['headline-xxs'],
                                bgcolor: theme.colors?.utility['purple-1'],
                                color: theme.colors?.utility['purple-4'],
                                height: 'unset',
                                px: 2,
                                py: 1,
                                border: 'none',
                                ml: 'auto',
                                span: {
                                  padding: 0,
                                },
                              }}
                              label="New"
                            />
                          )}
                        </Box>
                        {tab.shortCut && (
                          <Tooltip title={tab.tooltip || ''} placement="top">
                            <StyledShortCutContainer>
                              <StyledShortCutWrapper>
                                <Typography
                                  variant="subhead-md"
                                  color={theme.colors?.utility[900]}
                                >
                                  {tab.shortCut}
                                </Typography>
                              </StyledShortCutWrapper>
                            </StyledShortCutContainer>
                          </Tooltip>
                        )}
                      </StyledTab>

                      {tab.paywall && <Box>{tab.paywall}</Box>}
                    </Box>
                  </Link>
                ) : tab.type === 'component' ? (
                  <Box
                    key={idx}
                    sx={{
                      pointerEvents: tab.disabled ? 'none' : 'auto',
                    }}
                  >
                    {tab.renderComponent()}
                  </Box>
                ) : (
                  <Fragment key={idx} />
                );
              })}
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            marginTop={theme.spacing(20)}
            gap={4}
          >
            {user && (
              <Link to={PlotRoutes.account()} style={{ display: 'block' }}>
                <Box display="flex" gap={3} alignItems="center" mb={5}>
                  {/* Converting MeModel to UserProfileModel */}
                  <Avatar
                    user={user as unknown as UserProfileModel}
                    size={16}
                  />
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography
                      variant="body-lg"
                      color={theme.colors?.utility[900]}
                      fontWeight={500}
                    >
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Typography
                      variant="body-md"
                      color={theme.colors?.utility[700]}
                    >
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                    my: theme.spacing(2),
                    width: '100%',
                  }}
                />
              </Link>
            )}
            {tabs.map((tab, idx) => {
              return tab.type === 'link' ? (
                <Link
                  to={tab.disabled ? '#' : tab.href}
                  key={idx}
                  state={tab.state}
                  style={{
                    opacity: tab.disabled ? 0.5 : 1,
                    cursor: tab.disabled ? 'not-allowed' : 'auto',
                    pointerEvents: tab.disabled ? 'none' : 'auto',
                  }}
                >
                  <StyledTab onClick={tab.onClick}>
                    <Badge
                      color="primary"
                      variant="dot"
                      badgeContent={pendingNotifications(tab.name) ? 1 : 0}
                    >
                      {tab.loading ? (
                        <CircularProgress
                          size={16}
                          sx={{
                            color: tab.color ?? theme.colors?.utility[900],
                          }}
                        />
                      ) : (
                        <tab.icon
                          size={16}
                          color={tab.color ?? theme.colors?.utility[900]}
                        />
                      )}
                    </Badge>

                    <Typography
                      variant="subhead-md"
                      color={tab.color ?? theme.colors?.utility[900]}
                    >
                      {tab.name}
                    </Typography>

                    {tab.name === TabName.SETTINGS &&
                      areTherePendingUserRequests && (
                        <Chip
                          sx={{
                            ...typography['headline-xxs'],
                            bgcolor: theme.colors?.utility['yellow-1'],
                            color: theme.colors?.utility['yellow-4'],
                            height: 'unset',
                            px: 2,
                            py: 1,
                            border: 'none',
                            ml: 'auto',
                            span: {
                              padding: 0,
                            },
                          }}
                          label="Approval Pending"
                        />
                      )}
                  </StyledTab>
                </Link>
              ) : tab.type === 'component' ? (
                <Box
                  key={idx}
                  sx={{
                    pointerEvents: tab.disabled ? 'none' : 'auto',
                    cursor: tab.disabled ? 'not-allowed' : 'auto',
                    opacity: tab.disabled ? 0.5 : 1,
                  }}
                >
                  {tab.renderComponent()}
                </Box>
              ) : tab.type === 'button' ? (
                <StyledTab
                  key={idx}
                  onClick={tab.disabled ? () => {} : tab.onClick}
                  sx={{
                    opacity: tab.disabled ? 0.5 : 1,
                    pointerEvents: tab.disabled ? 'none' : 'auto',
                  }}
                >
                  {tab.loading ? (
                    <CircularProgress
                      size={16}
                      sx={{
                        color: tab.color ?? theme.colors?.utility[900],
                      }}
                    />
                  ) : (
                    <tab.icon
                      size={16}
                      color={tab.color ?? theme.colors?.utility[900]}
                    />
                  )}
                  <Typography
                    variant="subhead-md"
                    color={tab.color ?? theme.colors?.utility[900]}
                  >
                    {tab.name}
                  </Typography>
                </StyledTab>
              ) : (
                <Fragment key={idx} />
              );
            })}

            {user && (
              <BillingLimitProgressView
                organizationBilling={user.organization.billing}
              />
            )}
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  );
};
