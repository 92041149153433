import { gql, useApolloClient } from '@apollo/client';
import { Box, IconButton, TooltipProps } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconCustomFiSrSmileWink } from 'components/icons/components/custom/IconCustomFiSrSmileWink';
import { useUserContext } from 'contexts/users/User.context';
import {
  PostFragmentPostReactionButtonFragment,
  UserReactionFragmentPostReactionButtonFragment,
  useReactToPostForPostReactionButtonMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { GenerateId } from 'utils/generateId';
import { postReactionOptions } from './constants';

export const USER_REACTION_FRAGMENT_POST_REACTION_BUTTON = gql`
  fragment UserReactionFragmentPostReactionButton on UserReactionModel {
    id
    emoji
    user {
      id
      # NOTE: We are not using this field in this component, but it's required to keep the fragment in sync with
      # PostReactionList, or cache manipulation will not work properly.
      # TODO: Find a way to make sure PostReactionButton and PostReactionList share the same fragment
      # and cache manipulation logic
      firstName
    }
  }
`;

export const POST_FRAGMENT_POST_REACTION_BUTTON = gql`
  fragment PostFragmentPostReactionButton on PostModel {
    id
    userReactions {
      id
      ...UserReactionFragmentPostReactionButton
    }
  }
  ${USER_REACTION_FRAGMENT_POST_REACTION_BUTTON}
`;

// eslint-disable-next-line
gql`
  mutation ReactToPostForPostReactionButton($data: ReactToPostInput!) {
    reactToPost(data: $data) {
      success
      message
    }
  }
`;

export type PostReactionButtonProps = {
  post: PostFragmentPostReactionButtonFragment;
  componentsProps?: {
    tooltip?: Partial<TooltipProps>;
  };
  renderButton?: () => JSX.Element;
};

export const PostReactionButton = (props: PostReactionButtonProps) => {
  const { post, componentsProps = {}, renderButton } = props;

  const client = useApolloClient();
  const { user } = useUserContext();

  const currentUserReaction = (post.userReactions || []).find(
    (reaction) => reaction?.user?.id === user?.id,
  );

  const [reactToPost] = useReactToPostForPostReactionButtonMutation();
  const onReact = (emoji: string) => {
    reactToPost({
      variables: {
        data: {
          postId: post.id,
          emoji,
        },
      },
    });

    modifyObject(client.cache, post.id, 'PostModel', {
      userReactions: (cachedUserReactions) => {
        // Remove existing reaction if user has reacted to the post
        // Otherwise push in a new reaction object

        const isReactingToSameEmoji = emoji === currentUserReaction?.emoji;

        // Filter out current user reaction (if any)
        const newUserReactions = cachedUserReactions.filter((r) => {
          if (r.__ref) {
            const cachedUserReaction = client.cache.readFragment({
              id: r.__ref,
              fragment: USER_REACTION_FRAGMENT_POST_REACTION_BUTTON,
            }) as UserReactionFragmentPostReactionButtonFragment;

            if (cachedUserReaction.user.id !== user?.id) {
              return true;
            }

            return cachedUserReaction?.emoji !== currentUserReaction?.emoji;
          }

          if (r.user.id !== user?.id) {
            return true;
          }

          return r.emoji !== currentUserReaction?.emoji;
        });

        // Return the reduced list of reactions if user react on the same emoji as current reaction
        // That means we simply remove the current reaction
        if (isReactingToSameEmoji) {
          return newUserReactions;
        }

        // Otherwise push a new reaction object
        // Use optimistic UI to update the cache
        return [
          ...newUserReactions,
          client.cache.writeFragment({
            data: {
              __typename: 'UserReactionModel',
              id: GenerateId.create(),
              emoji,
              user: {
                __typename: 'UserModel',
                id: user?.id,
                // NOTE: We are not using this field in this component, but it's required to keep the fragment in sync with
                // PostReactionList, or cache manipulation will not work properly.
                // TODO: Find a way to make sure PostReactionButton and PostReactionList share the same fragment
                // and cache manipulation logic
                firstName: user?.firstName,
              },
            },
            fragment: USER_REACTION_FRAGMENT_POST_REACTION_BUTTON,
            fragmentName: 'UserReactionFragmentPostReactionButton',
          }),
        ];
      },
    });
  };

  return (
    <Tooltip
      // NOTE: Small delay here to prevent tooltip from disappearing too fast
      // as the anchor element is very small
      leaveDelay={500}
      title={
        <Box display="flex" alignItems="center" gap={1}>
          {postReactionOptions.map((reaction) => (
            <Tooltip
              key={reaction.emoji}
              title={reaction.tooltip}
              placement="top"
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onReact(reaction.emoji);
                }}
                sx={{
                  fontSize: 24,
                  width: 36,
                  height: 36,
                  borderRadius: 6,
                  color: theme.colors?.primary.white,
                  '&:hover': {
                    bgcolor: reaction.hoverBgColor,
                  },
                }}
              >
                {reaction.emoji}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      }
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            p: 1,
            bgcolor: 'rgb(255, 255, 255, 0.8)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 18px 88px -4px rgba(24, 39, 75, 0.14), 0px 8px 28px -6px rgba(24, 39, 75, 0.12)',
            borderRadius: 50,
          },
        },
      }}
      {...componentsProps.tooltip}
    >
      <Box>
        {renderButton ? (
          renderButton()
        ) : (
          <IconButton
            size="small"
            sx={{
              width: 24,
              height: 24,
              display: currentUserReaction ? 'block !important' : 'inherit',
              opacity: currentUserReaction ? '1 !important' : 'inherit',
            }}
          >
            {currentUserReaction?.emoji || (
              <IconCustomFiSrSmileWink size={16} />
            )}
          </IconButton>
        )}
      </Box>
    </Tooltip>
  );
};
