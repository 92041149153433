import { useApolloClient } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { toast } from 'components/common/Toast';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  PlotFeatureType,
  useRoleBasedUserAccessContext,
} from 'contexts/RoleBasedUserAccess.context';
import { PostPreview } from 'features/post';
import { useCreatePostHandlerForSocialListening } from 'features/socialMediaListening/hooks';
import {
  PlotFeature,
  SocialPostFragmentSocialListeningContentCalendarSaveButtonFragment,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

type SocialListeningContentCalendarSaveButtonProps = {
  renderBtn?: () => React.ReactNode;
  socialPost: SocialPostFragmentSocialListeningContentCalendarSaveButtonFragment;
  socialPostEngagementSuggestionId: string;
  onAfterAddedToContentCalendar?: () => void;
};

export const SocialListeningContentCalendarSaveButton = ({
  renderBtn,
  socialPost,
  socialPostEngagementSuggestionId,
  onAfterAddedToContentCalendar,
}: SocialListeningContentCalendarSaveButtonProps) => {
  const navigate = useGuardNavigate();
  const client = useApolloClient();
  const { checkCanAccess } = useRoleBasedUserAccessContext();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const canAccess = checkCanAccess(PlotFeatureType.ContentCalendar);
  const isContentCalendarEnabled = isFeatureEnabled(
    PlotFeature.ContentCalendar,
  );
  const [isSaved, setIsSaved] = useState(socialPost.isSavedToContentCalendar);

  const { addToContentCalendar } = useCreatePostHandlerForSocialListening({
    socialListeningPost: socialPost,
    socialPostEngagementSuggestionId,
  });

  const onAddTrendToContentCalendar = async () => {
    if (socialPost.isSavedToContentCalendar) return;

    const response = await addToContentCalendar();
    if (response.data?.addToContentCalendarForSocialListeningPost) {
      modifyObject(client.cache, socialPost.id, 'SocialPostModel', {
        totalPostSaves: () => socialPost.totalPostSaves + 1,
        totalContentCalendarSaves: () =>
          socialPost.totalContentCalendarSaves + 1,
        isSavedToContentCalendar: () => true,
      });

      setIsSaved(true);

      onAfterAddedToContentCalendar?.();
      toast({
        position: 'bottom-center',
        shouldShowCloseButton: false,
        sx: {
          bgcolor: theme.colors?.utility['yellow-1'],
          color: theme.colors?.primary.black,
          borderRadius: theme.spacing(4),
          padding: theme.spacing(4),
          alignItems: 'center',
          '& .MuiButtonBase-root': {
            padding: 0,
          },
        },
        message: '',
        title: 'Added!',
        icon: (
          <PostPreview
            post={response.data.addToContentCalendarForSocialListeningPost}
            sx={{
              width: 55,
              height: 85,
              borderRadius: 3,
              overflow: 'hidden',
              border: `4px solid ${theme.colors?.primary.white}`,
            }}
          />
        ),
        actionButtons: [
          {
            variant: 'text',
            children: `Go to content calendar`,
            onClick: () => {
              navigate(PlotRoutes.contentCalendar());
            },
            sx: {
              color: theme.colors?.utility['yellow-4'],
              padding: 0,
            },
          },
        ],
      });
    } else {
      toast({
        message: 'Failed to add trend to content calendar',
        type: 'error',
      });
    }
  };

  if (!isContentCalendarEnabled) {
    return null;
  }

  if (renderBtn) {
    return (
      <IconButton
        disableRipple
        sx={{
          padding: 0,
        }}
        disabled={!canAccess || !isContentCalendarEnabled}
        onClick={onAddTrendToContentCalendar}
      >
        {renderBtn()}
      </IconButton>
    );
  }
  const content = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="fit-content"
      flexDirection="column"
      gap={2}
    >
      <IconButtonWithTooltip
        tooltip="Add to content calendar"
        sx={{
          padding: 2.5,
          bgcolor: canAccess
            ? theme.colors?.utility['yellow-1']
            : theme.colors?.utility[500],
          width: 'fit-content',
          '&.Mui-disabled': {
            bgcolor: theme.colors?.utility[500],
          },
        }}
        disableRipple
        disabled={!canAccess}
        onClick={onAddTrendToContentCalendar}
      >
        {!isSaved ? (
          <IconOutlineCalendar
            color={
              canAccess
                ? theme.colors?.primary.black
                : theme.colors?.utility[800]
            }
          />
        ) : (
          <IconBoldCalendar
            color={
              canAccess
                ? theme.colors?.utility['yellow-4']
                : theme.colors?.utility[800]
            }
          />
        )}
      </IconButtonWithTooltip>
      <Typography variant="body-md" color={theme.colors?.utility[700]}>
        {socialPost.totalContentCalendarSaves}{' '}
        {socialPost.totalContentCalendarSaves > 1 ? 'saves' : 'save'}
      </Typography>
    </Box>
  );

  return canAccess ? (
    content
  ) : (
    <Tooltip
      title={
        <Typography variant="body-md">
          <Link
            style={{ textDecoration: 'underline' }}
            to={PlotRoutes.contentCalendarRequestAccess()}
          >
            Request Admin{' '}
          </Link>
          for All Plot Features
          <br /> to save this post
        </Typography>
      }
      enterDelay={canAccess ? 999999 : 0}
    >
      {content}
    </Tooltip>
  );
};
