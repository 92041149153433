import { gql } from '@apollo/client';
import { Avatar, Box, Typography } from '@mui/material';
import { OrganizationProfileFragmentOrganizationAvatarWithNameFragment } from 'graphql/generated';
import { theme } from 'styles/theme/theme';

export const ORGANIZATION_PROFILE_FRAGMENT_ORGANIZATION_AVATAR_WITH_NAME_FRAGMENT = gql`
  fragment OrganizationProfileFragmentOrganizationAvatarWithName on OrganizationProfileModel {
    id
    name
    users {
      id
    }
  }
`;

export type OrganizationAvatarWithNameProps = {
  organization: OrganizationProfileFragmentOrganizationAvatarWithNameFragment;
  avatarSize?: number;
  avatarUrl?: string;
};

export const OrganizationAvatarWithName = (
  props: OrganizationAvatarWithNameProps,
) => {
  const { organization, avatarSize = 40, avatarUrl } = props;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
      <Avatar
        src={avatarUrl}
        alt={organization.name}
        sx={{
          width: avatarSize,
          height: avatarSize,
        }}
      />
      <Box display="grid">
        <Typography
          variant="headline-xs"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {organization.name}
        </Typography>
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[700]}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingRight: '2px',
          }}
        >
          {organization.users.length} members
        </Typography>
      </Box>
    </Box>
  );
};
