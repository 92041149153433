import { ResourceUploadItem } from 'hooks/useResourceUploadQueue';
import { useEffect, useState } from 'react';

export type LocalFileSupported = 'image' | 'video' | 'pdf';

export const useLocalFileType = (resource: ResourceUploadItem) => {
  const [type, setType] = useState<LocalFileSupported>();

  const fileType = (resource?.resource?.content as File)?.type;

  useEffect(() => {
    if (/^image/.test(fileType)) {
      setType('image');
    }
    if (/^video/.test(fileType?.trim())) {
      setType('video');
    }
    if (/^application\/pdf/.test(fileType)) {
      setType('pdf');
    }
  }, [fileType]);

  return {
    type,
  };
};
