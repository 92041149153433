import { AnalyticsBrowser } from '@segment/analytics-next';
import LogRocket from 'logrocket';
import { createContext } from '@dwarvesf/react-utils';
import { useEffect, useMemo } from 'react';

const [AnalyticsContextProvider, useAnalyticsContext] =
  createContext<AnalyticsBrowser>();

export { useAnalyticsContext };

type Props = {
  writeKey: string;
  children: React.ReactNode;
};
export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = useMemo(
    () => {
      let result: AnalyticsBrowser;

      if (process.env.REACT_APP_PROXY_SEGMENT === 'true') {
        result = AnalyticsBrowser.load(
          { writeKey, cdnURL: process.env.REACT_APP_SEGMENT_CDN_PROXY },
          {
            initialPageview: true,
            integrations: {
              'Segment.io': {
                apiHost: process.env.REACT_APP_SEGMENT_API_PROXY || '',
                protocol: 'https',
              },
            },
          },
        );
      } else {
        result = AnalyticsBrowser.load({ writeKey }, { initialPageview: true });
      }
      const oldTrack = result.track;
      const oldIdentify = result.identify;
      return {
        ...result,
        track: (event: string, properties?: any) => {
          oldTrack(event, properties);
          LogRocket.track(event, properties);
        },
        identify: (userId: string, properties?: any) => {
          oldIdentify(userId, properties);
          LogRocket.identify(userId, properties);
        },
      } as AnalyticsBrowser;
    }, // This tracks the page navigations per user
    [writeKey],
  );

  useEffect(() => {
    LogRocket.getSessionURL((sessionURL) => {
      analytics.track('LogRocket', {
        sessionURL,
      });
    });
  }, [analytics]);

  return (
    <AnalyticsContextProvider value={analytics}>
      {children}
    </AnalyticsContextProvider>
  );
};
