/**
 * TODO: Look into the use of this context & rename it to something more meaningful.
 */

import { createContext } from '@dwarvesf/react-utils';
import { Dispatch, SetStateAction, useState } from 'react';

interface ContextValues {
  setHideAnnotations: Dispatch<SetStateAction<boolean>>;
  hideAnnotations: boolean;
}

const [Provider, usePostManager] = createContext<ContextValues>({
  name: 'post-manager',
});

const PostManagerProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [hideAnnotations, setHideAnnotations] = useState(false);

  return (
    <Provider
      value={{
        hideAnnotations,
        setHideAnnotations,
      }}
    >
      {children}
    </Provider>
  );
};

export { PostManagerProvider, usePostManager };
