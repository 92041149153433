import { PostModel } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { isPostDownloadable } from '../utils';

export const useIsPostDownloadable = (post: {
  urlMetadata?: Pick<PostModel['urlMetadata'][0], 'metadata' | 'url'>[];
}) => {
  const [isDownloadable, setIsDownloadable] = useState<boolean>(false);

  useEffect(() => {
    isPostDownloadable(post)
      .then((res) => {
        setIsDownloadable(res);
      })
      .catch(() => {
        setIsDownloadable(false);
      });
  }, [post]);

  return {
    isPostDownloadable: isDownloadable,
  };
};
