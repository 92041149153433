import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PostFragmentPostUnreadCommentBadgeFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_POST_UNREAD_COMMENT_BADGE = gql`
  fragment PostFragmentPostUnreadCommentBadge on PostModel {
    id
    unreadCommentCount
  }
`;

type PostUnreadCommentBadgeProps = {
  post: PostFragmentPostUnreadCommentBadgeFragment;
};

export const PostUnreadCommentBadge = (props: PostUnreadCommentBadgeProps) => {
  const { post } = props;

  if (post.unreadCommentCount === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        width: 'fit-content',
        height: 'fit-content',
        padding: theme.spacing(1, 2),
        background: theme.colors?.utility['pink-3'],
        borderRadius: 100,
        ...theme.typography['headline-xxs'],
        color: theme.colors?.primary.white,
        whiteSpace: 'nowrap',
      }}
    >
      {post.unreadCommentCount} unread
    </Box>
  );
};
