import { Box, Typography } from '@mui/material';
import { IconBoldClock } from 'components/icons/components/bold/IconBoldClock';
import {
  AccessRequestAdminList,
  AccessRequestAdminListProps,
} from 'features/accessRequest/components';
import { theme } from 'styles/theme';

interface AccessRequestPendingViewProps {
  workspaceName: string;
  componentsProps: {
    adminList: AccessRequestAdminListProps;
  };
}

export const AccessRequestPendingView = ({
  workspaceName,
  componentsProps,
}: AccessRequestPendingViewProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb: 3,
        gap: 5,
        transition: `all 0.3s ease-in-out`,
      }}
    >
      <IconBoldClock size={56} color={theme.colors?.utility[500]} />
      <Typography
        variant="headline-xl"
        fontSize={40}
        letterSpacing="-1.6px"
        color={theme.colors?.primary.black}
        fontWeight={500}
        mt={1}
      >
        Awaiting approval
      </Typography>
      <Typography variant="subhead-xl" color={theme.colors?.primary.black}>
        The admin(s) received your request to join <b>{workspaceName}</b>{' '}
        Workspace:
      </Typography>
      <AccessRequestAdminList {...componentsProps.adminList} />
      <Typography variant="subhead-xl" color={theme.colors?.primary.black}>
        You will be notified once they approved. Feel free to send them a
        message to move things along! 💌
      </Typography>
    </Box>
  );
};
