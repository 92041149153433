import { Components, Theme } from '@mui/material';
import { typography } from '../../Typography/styles';

export const outlinedInputOverrides: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        borderColor: theme.colors?.utility[400],
        borderRadius: '8px',
        '& textarea': {
          fontFamily: typography['body-lg'].fontFamily,
          padding: 0,
        },
      };
    },
    input: ({ theme }) => {
      return {
        ...typography['body-lg'],
        padding: '12px',
        '&.Mui-disabled': {
          backgroundColor: theme.colors?.utility[250],
        },
      };
    },
    adornedStart: {
      paddingLeft: '12px',
    },
    adornedEnd: {
      paddingRight: '12px',
    },
  },
};
