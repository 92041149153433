import { Plugin } from '@tiptap/pm/state';
import { findTextNodeRangesInRange } from '../utils';

/**
 * When a new link is pasted into the editor, we will render a (one-time?) popover
 * that allows the user to convert the link into an embed.
 * We'll be hi-jacking the handlePaste method of the link extension to achieve this.
 */
export const PastePlugin = () =>
  new Plugin({
    props: {
      handlePaste(view, event) {
        const text = event.clipboardData?.getData('text/plain') || '';
        let url: URL | null = null;

        try {
          url = new URL(text);
        } catch {
          return false;
        }

        if (url && url.host) {
          // If user is selecting some text, we'll convert the selected text into a link
          // while preserving the text's content.
          // We'll have to look for all the consecutive text nodes with the selection,
          // and create link marks for each of them.
          if (!view.state.selection.empty) {
            const { from, to } = view.state.selection;
            const tr = view.state.tr;

            const ranges = findTextNodeRangesInRange(view, from, to);

            // Loop these ranges and create link marks for each of them
            ranges.forEach(({ from, to }) => {
              tr.addMark(
                from,
                to,
                view.state.schema.marks.link.create({
                  href: url!.toString(),
                  target: '_blank',
                }),
              );
            });

            view.dispatch(tr);
          } else {
            // Otherwise, we'll just insert the link,
            const tr = view.state.tr;

            const originalFrom = view.state.selection.from;

            tr.insert(
              originalFrom,
              view.state.schema.text(text, [
                view.state.schema.marks.link.create({
                  href: url!.toString(),
                  target: '_blank',
                }),
              ]),
            );

            view.dispatch(tr);
          }

          return true;
        }

        return false;
      },
    },
  });
