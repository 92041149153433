import { Components, Theme } from '@mui/material';

export const skeletonOverrides: Components<Theme>['MuiSkeleton'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        background: theme.colors?.utility[400],
      };
    },
  },
};
