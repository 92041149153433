import { gql } from '@apollo/client';
import { SxProps } from '@mui/material';
import {
  AvatarGroupType,
  USER_FRAGMENT_AVATAR_GROUP,
} from 'components/common/AvatarGroup/types';
import { UserProfileFragmentAssigneeListFragment } from 'graphql/generated';

export const USER_PROFILE_FRAGMENT_ASSIGNEE_LIST = gql`
  fragment UserProfileFragmentAssigneeList on UserProfileModel {
    ...UserFragmentAvatarGroup
  }
  ${USER_FRAGMENT_AVATAR_GROUP}
`;

export interface AssigneeListProps {
  assignees: UserProfileFragmentAssigneeListFragment[];
  onChange: (assignees: UserProfileFragmentAssigneeListFragment[]) => void;
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
    avatarGroup?: Omit<AvatarGroupType, 'users'>;
  };
  shouldShowEmptyText?: boolean;
  sx?: SxProps;
}

export interface AssigneeItemProps {
  onSelectOption: VoidFunction;
  isSelected: boolean;
  assignee: UserProfileFragmentAssigneeListFragment;
}
