import { Box, BoxProps, styled } from '@mui/material';

export const StyledContainer = styled(Box)<BoxProps>(() => ({
  width: '100%',
  height: '100%',
  minHeight: 'inherit',
  overflow: 'hidden',
  backgroundColor: 'white',
  position: 'relative',
}));
