import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import moment from 'moment/moment';
import {
  UserFragmentAvatarGroupFragmentDoc,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
} from 'graphql/generated';

export const USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW = gql`
  fragment UserProfileFragmentUserProfilePermissionItemView on UserProfileModel {
    id
    ...UserFragmentAvatarGroup
    organization {
      id
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

type UserProfilePermissionItemViewProps = {
  userProfile: UserProfileFragmentUserProfilePermissionItemViewFragment;
  lastSeen?: string;
  isGuest?: boolean;
  isUserNotAllowedToBeInvited?: boolean;
};

export const UserProfilePermissionItemView = (
  props: UserProfilePermissionItemViewProps,
) => {
  const {
    userProfile,
    isGuest,
    lastSeen = '',
    isUserNotAllowedToBeInvited,
  } = props;

  const lastSeenStr = lastSeen ? moment(lastSeen).fromNow() : '';

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexGrow: 1,
        py: 3,
      }}
    >
      <Avatar user={userProfile} size={36} />
      <Box display="flex" flexDirection="column" flexGrow={1} gap={0.5}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="headline-sm" color={theme.colors?.primary.black}>
            {getFullName(userProfile)}
          </Typography>
          {isGuest && (
            <Typography
              variant="body-lg"
              color={theme.colors?.utility['orange-3']}
              display="flex"
              sx={{
                borderRadius: theme.spacing(4),
                fontWeight: 600,
                p: theme.spacing(0.5, 2),
                background: 'rgba(246, 229, 216, 0.8)',
              }}
            >
              Guest
            </Typography>
          )}
          {lastSeenStr && (
            <Typography
              variant="body-lg"
              fontWeight={500}
              color={theme.colors?.utility[800]}
            >
              • Viewed {lastSeenStr}
            </Typography>
          )}
        </Box>
        <Typography
          fontWeight={500}
          variant="body-lg"
          color={theme.colors?.utility['900']}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingRight: '2px',
            fontStyle: isUserNotAllowedToBeInvited ? 'italic' : 'normal',
          }}
        >
          {userProfile.email}{' '}
          {!userProfile.hasSignedUp
            ? '• Invited'
            : userProfile.isDisabled
            ? '• Disabled'
            : ''}
        </Typography>
      </Box>
    </Box>
  );
};
