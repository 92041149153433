import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PostInfoView } from 'features/post/views/info';
import {
  PostFragmentPostDetailSidebarViewInfoTabContentFragment,
  PostFragmentPostInfoViewFragmentDoc,
} from 'graphql/generated';

export const POST_FRAGMENT_POST_DETAIL_SIDEBAR_VIEW_INFO_TAB_CONTENT = gql`
  fragment PostFragmentPostDetailSidebarViewInfoTabContent on PostModel {
    id
    ...PostFragmentPostInfoView
  }
  ${PostFragmentPostInfoViewFragmentDoc}
`;

interface Props {
  post?: PostFragmentPostDetailSidebarViewInfoTabContentFragment;
}

export const InfoTabContent = ({ post }: Props) => {
  if (!post) {
    return null;
  }
  return (
    <Box
      sx={{
        padding: 6,
        pt: 3,
        overflow: 'auto',
      }}
    >
      <PostInfoView post={post} />
    </Box>
  );
};
