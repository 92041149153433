import { Box, Skeleton } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const UserCellSkeleton = () => {
  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Skeleton
        variant="circular"
        width={theme.spacing(5)}
        height={theme.spacing(5)}
      />
      <Skeleton
        variant="rounded"
        width={theme.spacing(70)}
        height={theme.spacing(1.5)}
      />
    </Box>
  );
};
