import { Box, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearDollarCircle } from 'components/icons/components/linear/IconLinearDollarCircle';
import { SocialPostMentionSource } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

interface SLAAnalyticsFilterBySourceProps {
  renderTitle?: () => React.ReactNode;
  selectedSources: SocialPostMentionSource[];
  onChange: (selectedSources: SocialPostMentionSource[]) => void;
  variant?: 'normal' | 'accordion';
}

export const SLAAnalyticsFilterBySource = ({
  renderTitle,
  selectedSources,
  onChange,
  variant = 'accordion',
}: SLAAnalyticsFilterBySourceProps) => {
  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconLinearDollarCircle
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-lg">Organic/Paid</Typography>
      </Box>
    );
  }, [renderTitle]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      label={renderLabel}
      sx={{
        ...(variant === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(variant === 'normal' && {
            display: 'none',
          }),
        },
      }}
      defaultOpen={variant === 'normal'}
      dropDownOptions={Object.values(SocialPostMentionSource).map((source) => {
        return {
          label: source,
          value: source,
          renderOption: () => (
            <CheckboxMenuItem
              disableRipple
              value={source}
              label={source}
              onClick={() => {
                onChange(
                  selectedSources.includes(source)
                    ? selectedSources.filter((o) => o !== source)
                    : [...selectedSources, source],
                );
              }}
              checked={selectedSources.includes(source)}
            />
          ),
        };
      })}
    />
  );
};
