import { Fragment, Slice } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { Extension } from '@tiptap/react';
import { buildColumn, buildColumnBlock } from '../column/utils';
import { EXTENSION_DROP, NODE_COLUMN_LIST, NODE_EMBED } from '../constants';
import { getDroppedOnNode } from '../dropcursor/utils';

export type DropOptions = {
  disableFileDrop?: boolean;
};

export const Drop = Extension.create<DropOptions>({
  name: EXTENSION_DROP,

  addOptions() {
    return {
      allowedTypes: {},
      disableFileDrop: false,
    };
  },

  addProseMirrorPlugins() {
    const disableFileDrop = this.options.disableFileDrop;

    const dropPlugin = new Plugin({
      key: new PluginKey('dropPlugin'),
      props: {
        handleDrop(view, event, slice) {
          // Check if the drop event contains files and media is disabled
          if ((event.dataTransfer?.files || []).length > 0 && disableFileDrop) {
            return false;
          }

          const { docPos, node, dropSide, nodeFrom, nodeTo } = getDroppedOnNode(
            view,
            event,
          );

          const content = getContent(view, event, slice);

          if (content.size === 0) {
            return false;
          }

          event.preventDefault();

          switch (dropSide) {
            case 'top':
            case 'bottom': {
              const dropPos = dropSide === 'top' ? nodeFrom : nodeTo;

              if (dropPos !== undefined && dropPos >= 0) {
                // Create a transaction to delete the original node and insert the new one
                const transaction = view.state.tr.insert(dropPos, content);
                transaction.deleteSelection();

                view.dispatch(transaction);

                return true;
              }

              break;
            }
            case 'left':
            case 'right': {
              // Check if the node is inside a column list
              const resolvedPos = view.state.doc.resolve(docPos);
              const parentNode = resolvedPos.parent;

              // If dragging into a column list, create a new column
              // and insert it into the column list
              if (parentNode && parentNode.type.name === NODE_COLUMN_LIST) {
                const dropPos = dropSide === 'left' ? nodeFrom : nodeTo;

                const columnNode = view.state.schema.nodeFromJSON(
                  buildColumn({
                    content: content.toJSON(),
                  }),
                );
                const transaction = view.state.tr.insert(dropPos, columnNode);
                transaction.deleteSelection();

                view.dispatch(transaction);

                return true;
              }

              // TODO: If dragging next to a column node, also create a new column
              // and insert it into the column list before the first column, or next to the final column,
              // depending on the dropSide
              // if (node?.type.name === NODE_COLUMN_LIST) {
              //   ...
              // }

              // If dragging next to a columnable node
              if (node?.attrs.columnable === 'true') {
                let columnListObjectContent = [
                  buildColumn({
                    content: [node.toJSON()],
                  }),
                  buildColumn({
                    content: content.toJSON(),
                  }),
                ];
                if (dropSide === 'left') {
                  columnListObjectContent = columnListObjectContent.reverse();
                }

                const columnListObject = buildColumnBlock({
                  content: columnListObjectContent,
                });
                const columnListNode =
                  view.state.schema.nodeFromJSON(columnListObject);

                // Replace the droppedOnNode with the new column list created
                const transaction = view.state.tr.replaceRangeWith(
                  nodeFrom,
                  nodeTo,
                  columnListNode,
                );

                // Delete the original node
                transaction.deleteSelection();

                view.dispatch(transaction);

                return true;
              }

              // Should not do anything if dragging next to a non-columnable node

              break;
            }
            default: {
              break;
            }
          }

          return false;
        },
      },
    });

    return [dropPlugin];
  },
});

const getContent = (
  view: EditorView,
  event: DragEvent,
  slice: Slice,
): Fragment => {
  // Check if there are files in the dataTransfer
  if (event.dataTransfer?.files?.length) {
    return Fragment.fromArray(
      Array.from(event.dataTransfer.files).map((file) => {
        return view.state.schema.nodes[NODE_EMBED].create({
          file,
        });
      }),
    );
  }

  return slice.content;
};
