import {
  Box,
  Typography,
  Radio,
  Button,
  Grid,
  IconButton,
  Avatar,
  Skeleton,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { HoverPlayableVideo } from 'features/socialMediaListening/views/postsGrid/postCard/HoverPlayableVideo';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

export const CompetitorTopPostsSkeleton = () => {
  return (
    <Box
      display="flex"
      p={8}
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '70vh',
          minHeight: 600,
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          mb={4}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Top posts
          </Typography>

          <Skeleton
            sx={{
              width: theme.spacing(20),
              height: theme.spacing(4),
            }}
          />
        </Box>

        <Grid container spacing={4} columns={20}>
          {new Array(5).fill(0).map(() => (
            <Grid
              item
              xs={10}
              md={5}
              lg={4}
              sx={{
                height: 300,
              }}
            >
              <Skeleton
                sx={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  borderRadius: theme.spacing(2),
                  backgroundColor: theme.colors?.utility[275],
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
