import { gql } from '@apollo/client';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import {
  CreateFilePostInput,
  CreateLinkPostInput,
  CreateNotePostInput,
  PostCreationSource,
  useCreateFilePostForPostCommandMutation,
  useCreateLinkPostForPostCommandMutation,
  useCreateNotePostForPostCommandMutation,
} from 'graphql/generated';
import { POST_FRAGMENT_POST_COMMAND } from '../types';

// eslint-disable-next-line
gql`
  mutation CreateLinkPostForPostCommand($data: CreateLinkPostInput!) {
    createLinkPost(data: $data) {
      ...PostFragmentPostCommand
    }
  }
  ${POST_FRAGMENT_POST_COMMAND}
`;

// eslint-disable-next-line
gql`
  mutation CreateFilePostForPostCommand($data: CreateFilePostInput!) {
    createFilePost(data: $data) {
      ...PostFragmentPostCommand
    }
  }
  ${POST_FRAGMENT_POST_COMMAND}
`;

// eslint-disable-next-line
gql`
  mutation CreateNotePostForPostCommand($data: CreateNotePostInput!) {
    createNotePost(data: $data) {
      ...PostFragmentPostCommand
    }
  }
  ${POST_FRAGMENT_POST_COMMAND}
`;

export const usePostHandlers = () => {
  const { collectionId } = useCollectionIdFromParams();

  const [createLinkPost] = useCreateLinkPostForPostCommandMutation();
  const [createFilePost] = useCreateFilePostForPostCommandMutation();
  const [createNotePost] = useCreateNotePostForPostCommandMutation();

  const onCreateLinkPost = async (input: CreateLinkPostInput) => {
    const res = await createLinkPost({
      variables: {
        data: {
          collectionId,
          source: PostCreationSource.Web,
          ...input,
        },
      },
    });

    return res.data?.createLinkPost;
  };

  const onCreateFilePost = async (input: CreateFilePostInput) => {
    const res = await createFilePost({
      variables: {
        data: {
          collectionId,
          source: PostCreationSource.Web,
          ...input,
        },
      },
    });

    return res.data?.createFilePost;
  };

  const onCreateNotePost = async (input: CreateNotePostInput) => {
    const res = await createNotePost({
      variables: {
        data: {
          collectionId,
          source: PostCreationSource.Web,
          ...input,
        },
      },
    });

    return res.data?.createNotePost;
  };

  return {
    onCreateLinkPost,
    onCreateFilePost,
    onCreateNotePost,
  };
};
