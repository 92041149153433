import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { IconLinearLock1 } from 'components/icons/components/linear/IconLinearLock1';
import { useUserContext } from 'contexts/users/User.context';
import { BillingUpgradeModal } from 'features/billing';
import { useMeMutations } from 'features/me';
import { PermissionLevelMap } from 'features/permission/components/permissionSelector';
import { GeneralPermission, PermissionLevel } from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { GeneralPermissionLevelSelector } from '../generalPermissionLevelSelector';

export type GeneralPermissionSelectorProps = {
  entityType: 'collection' | 'post';
  organizationId: string;
  organizationName?: string;
  initialGeneralPermission: GeneralPermission;
  initialPermissionLevel?: PermissionLevel;
  readonly?: boolean;
  hideSetDefault?: boolean;
  onGeneralPermissionChange?: (
    generalPermission: GeneralPermission,
    permissionLevel?: PermissionLevel,
  ) => void;
  componentProps?: {
    sx?: SxProps;
  };
  visiblePermissionLevels?: PermissionLevel[];
};

export const GeneralPermissionSelector = (
  props: GeneralPermissionSelectorProps,
) => {
  const {
    entityType,
    organizationId,
    hideSetDefault,
    organizationName,
    initialGeneralPermission,
    initialPermissionLevel,
    readonly,
    onGeneralPermissionChange,
    componentProps,
    visiblePermissionLevels,
  } = props;
  const [generalPermission, setGeneralPermission] = useState(
    initialGeneralPermission,
  );
  const [permissionLevel, setPermissionLevel] = useState(
    initialPermissionLevel,
  );

  const { user, orgBilling, joinedOrgBillings } = useUserContext();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const organizationBilling = useMemo(() => {
    if (orgBilling?.organizationId === organizationId) {
      return orgBilling;
    }
    return joinedOrgBillings?.find((j) => j.organizationId === organizationId);
  }, [orgBilling, joinedOrgBillings, organizationId]);

  const isOrganizationAllowedToHaveMoreMembers = useMemo(() => {
    return organizationBilling?.organizationMemberLimit !== null &&
      organizationBilling?.organizationMemberLimit !== undefined
      ? organizationBilling?.organizationMemberLimit > 1
      : true;
  }, [organizationBilling?.organizationMemberLimit]);

  const [loading, setLoading] = useState(false);

  const defaultGeneralPermission = useMemo(() => {
    switch (entityType) {
      case 'collection':
        return user?.personalSettings?.collectionGeneralPermissionDefault;
      case 'post':
        return user?.personalSettings?.postGeneralPermissionDefault;
      default:
        return undefined;
    }
  }, [user, entityType]);

  const { updateUserPersonalSettings } = useMeMutations();

  const handleSetDefault = async (
    defaultGeneralPermission: GeneralPermission,
  ) => {
    setLoading(true);
    await updateUserPersonalSettings(
      entityType === 'collection'
        ? {
            collectionGeneralPermissionDefault: defaultGeneralPermission,
          }
        : { postGeneralPermissionDefault: defaultGeneralPermission },
    );
    setLoading(false);
  };

  const renderGeneralPermission = (value: GeneralPermission) => {
    return (
      <Box display="flex" gap={1} alignItems="center" sx={{}}>
        {value === GeneralPermission.OrganizationMembers ? (
          <IconBoldBuildings size={16} color={theme.colors?.primary.black} />
        ) : (
          <IconLinearLock1 size={16} color={theme.colors?.primary.black} />
        )}
        <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
          {value === GeneralPermission.InviteOnly
            ? 'Invite only'
            : `Everyone ${
                organizationName
                  ? `at ${organizationName}`
                  : 'in this organization'
              }`}
        </Typography>
      </Box>
    );
  };

  return readonly ? (
    <Box
      display="flex"
      alignItems="center"
      gap={theme.spacing(1)}
      bgcolor="rgba(35, 6, 3, 0.10)"
      color={theme.colors?.utility[800]}
      borderRadius={theme.spacing(4)}
      p={theme.spacing(1, 2)}
      sx={componentProps?.sx}
    >
      <Box
        sx={{
          borderRadius: 100,
          background: `rgba(35, 6, 3, 0.10)`,
          padding: theme.spacing(1, 3),
        }}
      >
        {renderGeneralPermission(generalPermission)}
      </Box>
      {generalPermission !== GeneralPermission.InviteOnly && (
        <Typography
          variant="headline-xs"
          textTransform="lowercase"
          color={theme.colors?.utility[800]}
        >
          {PermissionLevelMap[permissionLevel ?? PermissionLevel.View].label}
        </Typography>
      )}
    </Box>
  ) : (
    <Box
      sx={{ p: 3, ...componentProps?.sx }}
      display="flex"
      justifyContent="space-between"
    >
      <Select
        size="medium"
        sx={{
          ...typography['body-lg'],
          cursor: 'pointer',
          display: 'flex',
          width: 'fit-content',
          borderRadius: theme.spacing(4),
          color: theme.colors?.primary.black,
          fontWeight: 500,
          p: theme.spacing(1, 2),
          backgroundColor: 'rgba(35, 6, 3, 0.10);',
          '& .MuiSelect-select': {
            padding: '4px 26px 2px 12px !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        value={generalPermission}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            sx: {
              left: 250,
              mt: 5,
              ml: -5,
              boxShadow: '0px 3px 12px -2px rgba(0, 0, 0, 0.10)',
              p: 1,
              width: 500,
              borderRadius: 2,
            },
          },
        }}
        onChange={(e) => {
          const selectedValue = e.target.value as GeneralPermission;

          if (
            selectedValue === GeneralPermission.OrganizationMembers &&
            !isOrganizationAllowedToHaveMoreMembers
          ) {
            return;
          }

          setGeneralPermission(selectedValue);
          const permissionLevel = isOrganizationAllowedToHaveMoreMembers
            ? undefined
            : PermissionLevel.View;

          onGeneralPermissionChange?.(selectedValue, permissionLevel);
        }}
        renderValue={renderGeneralPermission}
      >
        <MenuItem value={GeneralPermission.InviteOnly}>
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" gap={2} alignItems="center">
              <Box
                sx={{
                  p: 1,
                  borderRadius: theme.spacing(1),
                  backgroundColor: theme.colors?.utility[400],
                  color: theme.colors?.primary.black,
                  height: theme.spacing(6),
                  width: theme.spacing(6),
                }}
              >
                <IconBoldLock size={16} />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.primary.black}
                >
                  Invite only
                </Typography>
                <Typography
                  fontWeight={500}
                  variant="body-lg"
                  color={theme.colors?.utility['800']}
                >
                  Only you and people invited can access
                </Typography>
              </Box>
            </Box>

            {!hideSetDefault &&
              isOrganizationAllowedToHaveMoreMembers &&
              (defaultGeneralPermission === GeneralPermission.InviteOnly ? (
                <Typography
                  variant="headline-xxs"
                  color={theme.colors?.utility[700]}
                >
                  Default
                </Typography>
              ) : loading ? (
                <CircularProgress size={12} />
              ) : (
                <Typography
                  variant="headline-xxs"
                  sx={{
                    cursor: 'pointer',
                    pointerEvents: 'all',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetDefault(GeneralPermission.InviteOnly);
                  }}
                >
                  Set as Default
                </Typography>
              ))}
          </Box>
        </MenuItem>

        <MenuItem
          value={GeneralPermission.OrganizationMembers}
          disabled={!isOrganizationAllowedToHaveMoreMembers}
          sx={{
            '&.Mui-disabled': {
              opacity: 1,
              cursor: 'pointer',
            },
          }}
        >
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              sx={
                !isOrganizationAllowedToHaveMoreMembers
                  ? {
                      opacity: 0.38,
                      cursor: 'none',
                    }
                  : {}
              }
            >
              <Box
                sx={{
                  p: 1,
                  borderRadius: theme.spacing(1),
                  backgroundColor: theme.colors?.primary.black,
                  color: theme.colors?.primary.white,
                  height: theme.spacing(6),
                  width: theme.spacing(6),
                }}
              >
                <IconBoldBuildings size={16} />
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.primary.black}
                >
                  Everyone at {organizationName}
                </Typography>
                <Typography
                  fontWeight={500}
                  variant="body-lg"
                  color={theme.colors?.utility['800']}
                >
                  {isOrganizationAllowedToHaveMoreMembers
                    ? 'Everyone in your organization can access'
                    : 'Disabled in your current plan'}
                </Typography>
              </Box>
            </Box>

            {!isOrganizationAllowedToHaveMoreMembers ? (
              organizationBilling?.hasUsedFreeTrial ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={onOpen}
                  sx={{
                    bgcolor: theme.colors?.primary.black,
                    borderRadius: theme.spacing(2),
                    cursor: 'pointer',
                    pointerEvents: 'all',
                    ':hover': {
                      bgcolor: theme.colors?.primary.black,
                    },
                  }}
                >
                  Upgrade
                </Button>
              ) : (
                <Typography
                  variant="headline-xxs"
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    pointerEvents: 'all',
                  }}
                  onClick={onOpen}
                >
                  Start Free Trial
                </Typography>
              )
            ) : defaultGeneralPermission ===
              GeneralPermission.OrganizationMembers ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {!hideSetDefault && (
                  <Typography
                    variant="headline-xxs"
                    color={theme.colors?.utility[700]}
                  >
                    Default
                  </Typography>
                )}
              </>
            ) : loading ? (
              <CircularProgress size={12} />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {!hideSetDefault && (
                  <Typography
                    variant="headline-xxs"
                    sx={{
                      cursor: 'pointer',
                      pointerEvents: 'all',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSetDefault(GeneralPermission.OrganizationMembers);
                    }}
                  >
                    Set as Default
                  </Typography>
                )}
              </>
            )}
          </Box>
        </MenuItem>
      </Select>

      {generalPermission === GeneralPermission.OrganizationMembers && (
        <Box display="flex" width="fit-content">
          <GeneralPermissionLevelSelector
            visiblePermissionLevels={visiblePermissionLevels}
            entityType={entityType}
            hideDefaultPermission={hideSetDefault}
            entityOrganizationId={organizationId}
            userOrganizationId={organizationId}
            initialValue={permissionLevel || PermissionLevel.View}
            onPermissionChange={(permission) => {
              setPermissionLevel(permission);
              onGeneralPermissionChange?.(
                GeneralPermission.OrganizationMembers,
                permission,
              );
            }}
          />
        </Box>
      )}

      {organizationBilling && (
        <BillingUpgradeModal isOpen={isOpen} onClose={onClose} />
      )}
    </Box>
  );
};
