import { gql } from '@apollo/client';
import { TASK_FRAGMENT_TASK_NAME } from 'features/task';
import {
  POST_FRAGMENT_ATTACHMENT,
  POST_FRAGMENT_URL_METADATA,
} from 'features/post/components';
import { CollectionFragmentCollectionBreadcrumbsFragmentDoc } from 'graphql/generated';

export const NOTE_SEARCH_HIT_FRAGMENT_ITEM_NAME = gql`
  fragment NoteSearchHitFragmentItemName on PostSearchHit {
    item {
      id
      title
    }
  }
`;

export const LINK_SEARCH_HIT_FRAGMENT_ITEM_NAME = gql`
  fragment LinkSearchHitFragmentItemName on PostSearchHit {
    item {
      id
      ...PostFragmentUrlMetadata
    }
  }
  ${POST_FRAGMENT_URL_METADATA}
`;

export const ATTACHMENT_SEARCH_HIT_FRAGMENT_ITEM_NAME = gql`
  fragment AttachmentSearchHitFragmentItemName on PostSearchHit {
    item {
      id
      ...PostFragmentAttachment
    }
  }
  ${POST_FRAGMENT_ATTACHMENT}
`;

export const TASK_SEARCH_HIT_FRAGMENT_ITEM_NAME = gql`
  fragment TaskSearchHitFragmentItemName on TaskSearchHitV2 {
    item {
      id
      ...TaskFragmentTaskName
    }
  }
  ${TASK_FRAGMENT_TASK_NAME}
`;

export const COLLECTION_SEARCH_HIT_FRAGMENT_ITEM_NAME = gql`
  fragment CollectionSearchHitFragmentItemName on CollectionSearchHit {
    item {
      id
      ...CollectionFragmentCollectionBreadcrumbs
    }
  }
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;
