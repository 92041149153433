import { gql } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import {
  CollectionFragmentJuiceboxCollectionSearchItemFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  SearchType,
  SearchableEntityType,
  useGetCollectionsForJuiceboxCollectionSearchResultQuery,
} from 'graphql/generated';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { MutableRefObject, useMemo, useState } from 'react';
import { JuiceboxCollectionSearchItem } from '../searchItem';
import { JuiceboxSearchResultHeader } from '../searchResultHeader';

interface Props {
  query: string;
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
}

// eslint-disable-next-line
gql`
  query GetCollectionsForJuiceboxCollectionSearchResult(
    $data: UniversalSearchInput!
    $postCount: Int
    $previewableFirst: Boolean
  ) {
    universalSearch(data: $data) {
      collectionSearchResult {
        totalCount
        hits {
          item {
            ...CollectionFragmentJuiceboxCollectionSearchItem
            posts(postCount: $postCount, previewableFirst: $previewableFirst) {
              ...PostFragmentPostPreview
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CollectionFragmentJuiceboxCollectionSearchItemFragmentDoc}
  ${PostFragmentPostPreviewFragmentDoc}
`;

export const JuiceboxCollectionSearchResult = ({ query, elRefs }: Props) => {
  const [hasMoreCollections, setHasMoreCollections] = useState(true);
  const variables = {
    entityTypes: [SearchableEntityType.Collections],
    limit: 10,
    searchType: SearchType.SmartFullText,
    text: query,
  };

  const { data: collectionsData, fetchMore } =
    useGetCollectionsForJuiceboxCollectionSearchResultQuery({
      variables: {
        postCount: 3,
        previewableFirst: true,
        data: {
          ...variables,
        },
      },
    });

  const totalCollectionCount = useMemo(
    () =>
      collectionsData?.universalSearch.collectionSearchResult.totalCount || 0,
    [collectionsData?.universalSearch.collectionSearchResult.totalCount],
  );

  const collections = useMemo(
    () =>
      collectionsData?.universalSearch.collectionSearchResult.hits.map(
        ({ item }) => item,
      ) || [],
    [collectionsData?.universalSearch.collectionSearchResult.hits],
  );

  return (
    <>
      <JuiceboxSearchResultHeader
        title={`Collections(${totalCollectionCount})`}
      />
      <InfiniteScroll
        style={{
          flexDirection: 'column',
          display: 'flex',
          gap: theme.spacing(1),
          marginTop: theme.spacing(-2),
        }}
        threshold={100}
        loadMore={() => {
          if (!hasMoreCollections || !collections.length) {
            return;
          }

          fetchMore({
            variables: {
              data: {
                ...variables,
                after:
                  String(
                    collectionsData?.universalSearch.pageInfo.endCursor || 0,
                  ) || '0',
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                fetchMoreResult.universalSearch.collectionSearchResult.hits
                  .length === 0
              ) {
                setHasMoreCollections(false);
              }
              return {
                ...fetchMoreResult,
                universalSearch: {
                  ...fetchMoreResult.universalSearch,
                  collectionSearchResult: {
                    ...fetchMoreResult.universalSearch.collectionSearchResult,
                    totalCount:
                      fetchMoreResult.universalSearch.collectionSearchResult
                        .totalCount,
                    hits: [
                      ...(prev.universalSearch.collectionSearchResult?.hits ||
                        []),
                      ...(fetchMoreResult.universalSearch.collectionSearchResult
                        ?.hits || []),
                    ],
                  },
                },
              };
            },
          });
        }}
        useWindow={false}
        hasMore={hasMoreCollections}
        loader={
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress
              size={24}
              sx={{ color: theme.colors?.primary.maroon }}
            />
          </Box>
        }
      >
        {collections.map((collection, index) => {
          return (
            <JuiceboxCollectionSearchItem
              collection={collection}
              posts={collection.posts}
              query={query}
              ref={(el: HTMLElement) => {
                elRefs.current[index] = el;
              }}
            />
          );
        })}
      </InfiniteScroll>
    </>
  );
};
