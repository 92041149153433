import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { UpdatePostPermissionAlertView } from 'features/post-permission';
import {
  CollectionFragmentUpdatePostPermissionAlertViewFragment,
  useIsCollectionsPermissionLessThanParentCollectionPermissionLazyQuery,
} from 'graphql/generated';
import { useState } from 'react';

// eslint-disable-next-line
gql`
  query IsCollectionsPermissionLessThanParentCollectionPermission(
    $data: IsCollectionsPermissionLessThanParentCollectionPermissionInput!
  ) {
    isCollectionsPermissionLessThanParentCollectionPermission(data: $data)
  }
`;

export const useCollectionPermissionUpdateCollectionPermissionToParentCollectionPermission =
  () => {
    const [parentCollection, setParentCollection] =
      useState<CollectionFragmentUpdatePostPermissionAlertViewFragment>();

    const [collectionIds, setCollectionIds] = useState<string[]>([]);
    const [onCallback, setOnCallback] = useState<VoidFunction>();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isCollectionsPermissionLessThanParentCollectionPermission] =
      useIsCollectionsPermissionLessThanParentCollectionPermissionLazyQuery();

    const handleOpenDialog = async (
      collectionIds: string[],
      parentCollection: CollectionFragmentUpdatePostPermissionAlertViewFragment,
      onCallback?: VoidFunction,
    ) => {
      setParentCollection(parentCollection);
      setCollectionIds(collectionIds);
      setOnCallback(() => onCallback);

      const response =
        await isCollectionsPermissionLessThanParentCollectionPermission({
          variables: {
            data: {
              parentCollectionId: parentCollection.id,
              collectionIds,
            },
          },
        });

      if (
        response.data?.isCollectionsPermissionLessThanParentCollectionPermission
      ) {
        onOpen();
      } else {
        onCallback?.();
      }
    };

    const renderUpdateCollectionPermissionAlert = () => {
      if (!parentCollection || !collectionIds.length) return;

      return !isOpen ? null : (
        <UpdatePostPermissionAlertView
          isOpen={isOpen}
          onClose={onClose}
          collectionIds={collectionIds}
          parentCollection={parentCollection}
          onCallback={onCallback}
        />
      );
    };

    return {
      onOpenUpdateCollectionPermissionDialog: handleOpenDialog,
      renderUpdateCollectionPermissionAlert,
    };
  };
