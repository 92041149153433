import { Box, ListItemButton, Typography } from '@mui/material';
import { ReactSVGElement } from 'react';
import { theme } from 'styles/theme/theme';

export enum MenuItemType {
  Header,
  Option,
}

export type CommandItemProps = {
  type: MenuItemType;
  Icon?: (...params: any) => ReactSVGElement;
  title?: string;
  subTitle?: string;
  selected?: boolean;
  onClick?: VoidFunction;
};

export const CommandMenuItem = ({
  type,
  Icon,
  title,
  subTitle,
  selected,
  onClick,
}: CommandItemProps) => {
  return type === MenuItemType.Header ? (
    <Box sx={{ py: theme.spacing(2), px: theme.spacing(3) }}>
      <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
        {title}
      </Typography>
    </Box>
  ) : (
    <ListItemButton
      selected={selected}
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        borderRadius: theme.spacing(2),
        minWidth: theme.spacing(62),
        py: theme.spacing(2),
        px: theme.spacing(3),
      }}
    >
      {Icon && (
        <Box
          sx={{
            bgcolor: 'rgba(35, 6, 3, 0.05)',
            width: theme.spacing(6),
            height: theme.spacing(6),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: theme.spacing(1),
          }}
        >
          <Icon size={16} color={theme.colors?.primary.black} />
        </Box>
      )}

      <Box
        sx={{ gap: theme.spacing(1), display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
          {title}
        </Typography>
        <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
          {subTitle}
        </Typography>
      </Box>
    </ListItemButton>
  );
};
