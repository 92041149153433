import { gql } from '@apollo/client';
import {
  PostFragmentCollectionPostDndPostCardFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc,
  PostFragmentPostTitleFragmentDoc,
} from 'graphql/generated';

// This should always take the fragment that contains the most fields
// because command is a global action that everything is listening to
// NOTE: This is still very confusing. Need more thought on this
export const POST_FRAGMENT_POST_COMMAND = gql`
  fragment PostFragmentPostCommand on PostModel {
    id
    ...PostFragmentCollectionPostDndPostCard
    ...PostFragmentJuiceboxTablePostTableRowWithContextMenu
    ...PostFragmentPostTitle
  }
  ${PostFragmentCollectionPostDndPostCardFragmentDoc}
  ${PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc}
  ${PostFragmentPostTitleFragmentDoc}
`;
