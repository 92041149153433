import { gql } from '@apollo/client';
import { Skeleton } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import {
  PostFragmentPostPreviewFragmentDoc,
  useGetPostForEmbedNodeViewPostRendererQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetPostForEmbedNodeViewPostRenderer($id: String!) {
    post(id: $id) {
      type
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export const PostRenderer = (props: { postId: string }) => {
  const { postId } = props;

  const { data, loading } = useGetPostForEmbedNodeViewPostRendererQuery({
    variables: {
      id: postId,
    },
  });
  const post = data?.post;
  const urlMetadata = post?.urlMetadata[0];

  if (!post || loading) {
    return <Skeleton variant="rectangular" sx={{ height: 200 }} />;
  }

  return <Iframely url={urlMetadata?.url || ''} urlMetadata={urlMetadata} />;
};
