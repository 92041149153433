import { gql } from '@apollo/client';
import { Box, SxProps, Typography, TypographyProps } from '@mui/material';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import { CollectionPermissionSummaryView } from 'features/collectionPermission';
import { CollectionThumbnail } from 'features/collection/components';
import { useIsCollectionSavedToContentCalendar } from 'features/collection/hooks';
import { getNoteColorFromColor } from 'features/note';
import {
  CollectionFragmentCollectionListItemViewFragment,
  CollectionFragmentCollectionPermissionSummaryFragmentDoc,
  CollectionFragmentCollectionSavedToContentCalendarFragmentDoc,
  CollectionFragmentCollectionThumbnailFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  PostType,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_COLLECTION_LIST_ITEM_VIEW = gql`
  fragment PostFragmentCollectionListItemView on PostModel {
    id
    ...PostFragmentPostPreview
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionListItemView on CollectionModel {
    id
    name
    ...CollectionFragmentCollectionPermissionSummary
    ...CollectionFragmentCollectionThumbnail
    ...CollectionFragmentCollectionSavedToContentCalendar
  }
  ${CollectionFragmentCollectionPermissionSummaryFragmentDoc}
  ${CollectionFragmentCollectionThumbnailFragmentDoc}
  ${CollectionFragmentCollectionSavedToContentCalendarFragmentDoc}
`;

export type CollectionListItemViewProps = {
  collection: CollectionFragmentCollectionListItemViewFragment;
  sx?: SxProps;
  isSmartSearchResult?: boolean;
  componentsProps?: {
    name?: TypographyProps;
  };
  renderName?: (
    collection: CollectionFragmentCollectionListItemViewFragment,
  ) => JSX.Element;
};

export const CollectionListItemView = (props: CollectionListItemViewProps) => {
  const {
    collection,
    sx,
    isSmartSearchResult = false,
    componentsProps = {},
    renderName,
  } = props;

  // Use first post for thumbnail
  const postForThumbnail = collection.previewPost;

  const { calendarIcon } = useIsCollectionSavedToContentCalendar({
    collection,
  });

  const thumbnailRender = useMemo(() => {
    let render: React.ReactNode = null;
    const color = getNoteColorFromColor(postForThumbnail?.color || 'gray');

    if (postForThumbnail?.type === PostType.Note) {
      render = <IconLinearNote2 size={16} />;
    } else {
      render = (
        <CollectionThumbnail
          collection={collection}
          sx={{ borderRadius: 'none' }}
          showEmptyText={false}
        />
      );
    }

    return (
      <Box
        sx={{
          width: 36,
          height: 36,
          borderRadius: 1,
          overflow: 'hidden',
          color: color?.textColor,
          bgcolor: color?.color || 'rgba(35, 6, 3, 0.05)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexShrink: 0,
        }}
      >
        {render}
      </Box>
    );
  }, [postForThumbnail]); // eslint-disable-line

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        gap: 2,
        overflow: 'hidden',
        ...sx,
      }}
    >
      {thumbnailRender}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1,
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <Box display="flex" gap={2} alignItems="center" width="100%">
          {renderName ? (
            renderName(collection)
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: '100%',
              }}
            >
              {calendarIcon}
              <Typography
                variant="subhead-lg"
                {...componentsProps.name}
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  ...componentsProps.name?.sx,
                }}
              >
                {collection.name}
              </Typography>
            </Box>
          )}
          {isSmartSearchResult && (
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(1),
                justifyContent: 'center',
                alignItems: 'center',
                padding: theme.spacing(1, 2),
                bgcolor: 'rgba(35, 6, 3, 0.1)',
                borderRadius: theme.spacing(2),
              }}
            >
              <IconCustomSparkles
                size={12}
                color={theme.colors?.utility[700]}
              />
              <Typography
                variant="headline-xxs"
                color={theme.colors?.utility[700]}
              >
                Suggested
              </Typography>
            </Box>
          )}
        </Box>

        <CollectionPermissionSummaryView
          collection={collection}
          componentsProps={{
            organizationBadge: {
              sx: {
                px: 1.5,
                py: 0.5,
                span: {
                  fontSize: 11,
                },
              },
            },
            avatar: {
              size: 12,
            },
          }}
        />
      </Box>
    </Box>
  );
};
