// Check if a string contains content or just HTML tags
export function hasContent(input: string): boolean {
  // Regex to detect common tags like img, video, etc.
  const commonTagRegex =
    /<(img|video|audio|iframe|object|embed|canvas|svg|input|select|textarea)[^>]*>/i;

  // Check if input contains meaningful HTML content
  if (commonTagRegex.test(input)) {
    return true;
  }

  // Remove all HTML tags from the input
  const strippedInput = input.replace(/<\/?[^>]+(>|$)/g, '').trim();

  // Check if there's any meaningful content left after removing HTML tags
  return strippedInput.length > 0;
}

// Remove empty HTML tags from the input string and return the result
export function removeEmptyTags(input: string): string {
  // Use regex to remove empty HTML tags
  const regex = /<[^>]*>\s*<\/[^>]*>/g;
  return input.replaceAll(regex, '');
}

// Function to check and process the HTML input string
export function removeEmptyValueTag(input?: string): string {
  if (!input || typeof input !== 'string') {
    return '';
  }

  const hasTextContent = hasContent(input);

  if (!hasTextContent) {
    return '';
  }

  return removeEmptyTags(input);
}

export function containsHTMLTags(input?: string): boolean {
  if (!input) {
    return false;
  }

  const htmlTagRegex = /<\/?[a-z][\s\S]*>/i; // Regular expression to match HTML tags

  return htmlTagRegex.test(input);
}

export const parseToHtml = (content?: string): string => {
  if (!content) {
    return '';
  }

  const isContentIsHtml = containsHTMLTags(content);
  if (isContentIsHtml) {
    return content;
  }

  return `<p>${content}</p>`;
};

export function extractTextFromHtml(html?: string): string {
  if (!html) {
    return '';
  }
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Extract and return the text content
  return tempElement.textContent || '';
}
