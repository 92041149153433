import { gql } from '@apollo/client';
import {
  CollectionInviteType,
  GeneralPermission,
  PermissionLevel,
  useUpdateCollectionPermissionsMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateCollectionPermissions(
    $data: UpdateCollectionPermissionsInputV2!
  ) {
    updateCollectionPermissionsV2(data: $data) {
      message
      success
    }
  }
`;

export type UpdateCollectionPermissionsDataInput = {
  inviteMembers?: {
    members: { email: string; permission: PermissionLevel }[];
  };
  removeMembers?: {
    userIds: string[];
  };
  generalPermission?: {
    generalPermission: GeneralPermission;
    permissionLevel?: PermissionLevel;
  };
  note?: string;
  shouldCreateComment?: boolean;
  inviteType?: CollectionInviteType;
};

type Props = {
  collectionIds: string[];
};

export const useCollectionPermissionMutations = ({ collectionIds }: Props) => {
  const [updateCollectionPermissions] =
    useUpdateCollectionPermissionsMutation();

  return {
    updateCollectionPermissions: async (
      data: UpdateCollectionPermissionsDataInput,
      options: { skipRefetch?: boolean } = {},
    ) => {
      const refetchQueries: string[] = ['GetMe']; // to update org billing usage

      if (!options.skipRefetch) {
        refetchQueries.push('GetCollectionPermissionForDialogView');
      } else if (data.shouldCreateComment && data.note?.trim()) {
        refetchQueries.push('GetCommentsForCollectionCommentListView');
      }
      await updateCollectionPermissions({
        variables: {
          data: {
            input: {
              inviteMembers: data.inviteMembers
                ? {
                    memberInvites: [],
                    message: data.note?.trim(),
                    shouldCreateComment: data.shouldCreateComment,
                    emailInvites: data.inviteMembers.members.flatMap((member) =>
                      collectionIds.map((collectionId) => ({
                        email: member.email,
                        permission: member.permission,
                        collectionId,
                      })),
                    ),
                    inviteType: data.inviteType,
                  }
                : undefined,
              removeMembers: data.removeMembers
                ? {
                    collectionIds,
                    userIds: data.removeMembers.userIds,
                  }
                : undefined,
              generalPermission: data.generalPermission
                ? {
                    collectionIds,
                    generalPermission: data.generalPermission.generalPermission,
                    permissionLevel: data.generalPermission.permissionLevel,
                  }
                : undefined,
            },
          },
        },
        refetchQueries,
        update: (cache) => {
          if (data.generalPermission) {
            collectionIds.forEach((id) => {
              modifyObject(cache, id, 'CollectionModel', {
                generalPermission: () =>
                  data.generalPermission?.generalPermission,
                permissionLevel: () => data.generalPermission?.permissionLevel,
              });
            });
          }
        },
      });
    },
  };
};
