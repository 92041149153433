import { gql } from '@apollo/client';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { useUserContext } from 'contexts/users/User.context';
import { ContentIdeaFragmentContentIdeaInviteAssigneeUserModalFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_INVITE_ASSIGNEE_MODAL = gql`
  fragment ContentIdeaFragmentContentIdeaInviteAssigneeUserModal on ContentIdeaModel {
    id
    name
  }
`;

interface ContentIdeaInviteAssigneeUserModalProps {
  invitedUserIds: string[];
  onClose: () => void;
  contentIdea: ContentIdeaFragmentContentIdeaInviteAssigneeUserModalFragment;
  onContinue: () => void;
}

export const ContentIdeaInviteAssigneeUserModal = ({
  invitedUserIds,
  onClose,
  contentIdea,
  onContinue,
}: ContentIdeaInviteAssigneeUserModalProps) => {
  const { user } = useUserContext();
  const users = [
    ...(user?.organization.users || []),
    ...(user?.organization.externalUsers || []),
  ];

  return (
    <Dialog
      open
      maxWidth="md"
      disableEscapeKeyDown
      PaperProps={{
        sx: {
          padding: 8,
          borderRadius: 6,
          background: `rgba(250, 243, 236, 0.80)`,
          backdropFilter: `blur(20px)`,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          fontWeight: 400,
          maxWidth: 500,
        },
      }}
    >
      <Typography variant="headline-xl" letterSpacing="-1.12px" fontSize={28}>
        Invite assigned user?
      </Typography>
      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Typography component="span" variant="subhead-xl" fontSize={20}>
          The person you assigned
        </Typography>
        {invitedUserIds.map((userId) => {
          const invitedUser = users.find((u) => u.id === userId);
          if (!invitedUser) return null;
          return (
            <Box component="span" sx={{ display: 'flex', gap: 1 }}>
              <Avatar user={invitedUser} size={24} />
              <Typography variant="headline-md" fontSize={20} component="span">
                {getFullName(invitedUser)}
              </Typography>
            </Box>
          );
        })}
        <Typography component="span" variant="subhead-xl" fontSize={20}>
          isn’t in this content idea yet. Invite them to ensure they see this
          idea
        </Typography>
      </Box>
      <Typography variant="subhead-xl" fontSize={20} mt={2}>
        They will also <b>gain access</b> to everything within{' '}
        <b>{contentIdea.name}</b>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Button
          variant="text"
          fullWidth
          onClick={onContinue}
          sx={{
            color: theme.colors?.primary.parchment,
            bgcolor: theme.colors?.primary.black,
            borderRadius: 2,
            padding: theme.spacing(2, 4),
            ':hover': {
              bgcolor: theme.colors?.primary.black,
            },
          }}
        >
          Continue
        </Button>
        <Button
          fullWidth
          variant="text"
          sx={{ color: theme.colors?.primary.black }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
    </Dialog>
  );
};
