// eslint-disable-next-line @typescript-eslint/no-unused-expressions
import { SxProps } from '@mui/material';
import { theme } from 'styles/theme';
import { BrandSocialPostEngagementStatus } from 'graphql/generated';
import { gql } from '@apollo/client';

gql`
  mutation UpdateBrandSocialPostEngagementForSocialPostBrandEngagementStatus(
    $data: UpdateBrandSocialPostEngagementInput!
  ) {
    updateBrandSocialPostEngagement(data: $data) {
      id
      status
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentBrandSocialPostEngagementStatus on SocialPostModel {
    id
    brandSocialPostEngagement(brandId: $brandId) {
      id
      status
    }
  }
`;

const commonSx: SxProps = {
  cursor: 'pointer',
  p: theme.spacing(0.5, 2),
  borderRadius: 1,
};

export const SocialPostStatusData = {
  [BrandSocialPostEngagementStatus.NotStarted]: {
    label: 'Not Started',
    sx: {
      backgroundColor: theme.colors?.utility[400],
      color: theme.colors?.utility[800],
      ...commonSx,
    },
  },
  [BrandSocialPostEngagementStatus.ForResearch]: {
    label: 'For Research',
    sx: {
      backgroundColor: theme.colors?.utility[400],
      color: theme.colors?.utility[800],
      ...commonSx,
    },
  },
  [BrandSocialPostEngagementStatus.Pass]: {
    label: 'Pass',
    sx: {
      backgroundColor: theme.colors?.utility['pink-1'],
      color: theme.colors?.utility['pink-4'],
      ...commonSx,
    },
  },
  [BrandSocialPostEngagementStatus.Responded]: {
    label: 'Responded',
    sx: {
      backgroundColor: theme.colors?.utility['green-1'],
      color: theme.colors?.utility['green-3'],
      ...commonSx,
    },
  },
};
