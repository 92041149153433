import { Node, ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react';
import { NodeSelection } from 'prosemirror-state';
import { MasonryComponent } from './components/MasonryComponent';

export const MasonryExtensionName = 'masonry';

export const MasonryExtension = Node.create({
  name: MasonryExtensionName,
  group: 'block',

  addAttributes() {
    return {
      id: {
        default: null,
      },
      'data-components': {
        default: null,
      },
      externalAttrs: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: MasonryExtensionName, node: this.name, priority: 51 }];
  },

  renderHTML({ HTMLAttributes }) {
    const { externalAttrs, ...attributes } = HTMLAttributes;

    return [
      MasonryExtensionName,
      mergeAttributes(this.options.HTMLAttributes, attributes, {
        'data-components':
          typeof HTMLAttributes['data-components'] === 'string'
            ? HTMLAttributes['data-components']
            : JSON.stringify(HTMLAttributes['data-components']),
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MasonryComponent);
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        if (
          this.editor.state.selection instanceof NodeSelection &&
          this.editor.state.selection.node.type.name === MasonryExtensionName
        ) {
          // insert a new paragraph after the masonry node
          return this.editor
            .chain()
            .insertContentAt(this.editor.state.selection.to, {
              type: 'paragraph',
            })
            .focus(this.editor.state.selection.to + 1, { scrollIntoView: true })
            .run();
        }
        return false;
      },
      Backspace: () => {
        // -1/+1 to include the masonry wrapper node
        const nodeToDelete = NodeSelection.findFrom(
          this.editor.state.doc.resolve(this.editor.state.selection.from - 1),
          -1,
        );

        // this prevents deleting the image when a user selects and deletes contents after the image
        const noSelectedContent =
          this.editor.state.selection.content().toJSON() === null;

        if (
          noSelectedContent &&
          nodeToDelete instanceof NodeSelection &&
          nodeToDelete.node.type.name === MasonryExtensionName
        ) {
          // delete masonry node, -1/+1 to include the masonry wrapper node
          return this.editor
            .chain()
            .deleteRange({
              from: nodeToDelete.from - 1,
              to: nodeToDelete.to + 1,
            })
            .run();
        }
        return false;
      },
      Delete: () => {
        const nodeToDelete = NodeSelection.findFrom(
          this.editor.state.doc.resolve(this.editor.state.selection.to + 1),
          1,
        );
        if (
          nodeToDelete instanceof NodeSelection &&
          nodeToDelete.node.type.name === MasonryExtensionName
        ) {
          // delete masnory node, -1/+1 to include the masnory wrapper node
          return this.editor
            .chain()
            .deleteRange({
              from: nodeToDelete.from - 1,
              to: nodeToDelete.to + 1,
            })
            .run();
        }
        return false;
      },
    };
  },
});
