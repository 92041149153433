import { Components, Theme } from '@mui/material';

export const chipOverrides: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        border: `1px solid ${theme.colors?.utility[400]}`,

        // For Select
        '& .MuiList-root': {
          padding: 0,
        },
      };
    },
  },
  variants: [
    {
      props: {
        variant: 'filled',
      },
      style: ({ theme }) => {
        return {
          background: theme.colors?.utility[250],
          border: `1px solid ${theme.colors?.utility[400]}`,
        };
      },
    },
    {
      props: {
        variant: 'outlined',
      },
      style: ({ theme }) => {
        return {
          background: 'transparent',
          border: `1px solid ${theme.colors?.utility[400]}`,
        };
      },
    },
    {
      props: {
        variant: 'filled-borderless',
      },
      style: ({ theme }) => {
        return {
          background: theme.colors?.utility[300],
          border: `1px solid transparent`,
          color: theme.colors?.utility[800],
        };
      },
    },
    {
      props: {
        variant: 'filled-borderless-color-dodge',
      },
      style: ({ theme }) => {
        return {
          background: theme.colors?.utility[300],
          border: `1px solid transparent`,
          color: theme.colors?.utility[800],
          height: 24,
          '.MuiChip-label': {
            background: 'inherit',
            backgroundClip: 'text',
            color: 'transparent',
            filter: 'invert(1) grayscale(1) contrast(999)',
            padding: theme.spacing(0, 2),
          },
          '.MuiChip-deleteIcon': {
            width: 16,
            height: 16,
          },
        };
      },
    },
    {
      props: {
        variant: 'dashed-outlined',
      },
      style: ({ theme }) => {
        return {
          background: 'transparent',
          border: `1px dashed ${theme.colors?.utility[400]}`,
        };
      },
    },
  ],
};
