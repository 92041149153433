import { gql } from '@apollo/client';

export const NOTE_SEARCH_HIT_FRAGMENT_SEARCH_TYPE = gql`
  fragment NoteSearchHitFragmentSearchType on PostSearchHit {
    type
  }
`;

export const LINK_SEARCH_HIT_FRAGMENT_SEARCH_TYPE = gql`
  fragment LinkSearchHitFragmentSearchType on PostSearchHit {
    type
  }
`;

export const ATTACHMENT_SEARCH_HIT_FRAGMENT_SEARCH_TYPE = gql`
  fragment AttachmentSearchHitFragmentSearchType on PostSearchHit {
    type
  }
`;

export const TASK_SEARCH_HIT_FRAGMENT_SEARCH_TYPE = gql`
  fragment TaskSearchHitFragmentSearchType on TaskSearchHitV2 {
    type
  }
`;

export const COLLECTION_SEARCH_HIT_FRAGMENT_SEARCH_TYPE = gql`
  fragment CollectionSearchHitFragmentSearchType on CollectionSearchHit {
    type
  }
`;
