import { gql } from '@apollo/client';
import { SxProps } from '@mui/material';
import { URL_METADATA_FRAGMENT_IFRAMELY } from 'components/common/Iframely/types';
import { ATTACHMENT_FRAGMENT_ATTACHMENT_PREVIEW } from 'features/attachment';
import { PostFragmentPostNotePreviewViewFragment } from 'graphql/generated';

export const POST_FRAGMENT_POST_NOTE_PREVIEW_VIEW = gql`
  fragment PostFragmentPostNotePreviewView on PostModel {
    id
    title
    color
    updatedAt
    urlMetadata {
      ...URLMetadataFragmentIframely
    }
  }
  ${ATTACHMENT_FRAGMENT_ATTACHMENT_PREVIEW}
  ${URL_METADATA_FRAGMENT_IFRAMELY}
`;

export interface PostNotePreviewViewProps {
  post: PostFragmentPostNotePreviewViewFragment;
  sx?: SxProps;
  componentsProps?: {
    title?: { sx?: SxProps };
    subtitle?: { sx?: SxProps };
    attachments?: { sx?: SxProps };
    attachmentItem?: { sx?: SxProps };
  };
  showAuthor?: boolean;
}
