import { Box, Button, Input, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledContainer = styled(Box)(() => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(6, 5),
  },
}));

export const StyledContentWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}));

export const StyledProjectChipWrapper = styled(Box)(() => ({
  width: 'fit-content',
  '& .project-chip': {
    borderRadius: '100px !important',
  },
  '& span': {
    color: `${theme.colors?.utility[700]} !important`,
    ...theme.typography['headline-xxs'],
  },
}));

export const StyledTaskNameInput = styled(Input)(() => ({
  ...theme.typography['headline-md'],
  fontSize: 20,
  color: theme.colors?.utility[1000],
}));

export const StyledButton = styled(Button)(() => ({
  padding: theme.spacing(2),
  ...theme.typography['headline-xs'],
}));

export const StyledButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,
  padding: theme.spacing(5, 6, 6),
}));
