import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconOutlineCloseCircle } from 'components/icons/components/outline/IconOutlineCloseCircle';
import { useUserContext } from 'contexts/users/User.context';
import {
  CreateTaskInput,
  TaskFragmentCreateNewTaskFragmentDoc,
  TaskFragmentCreateTaskModalFragment,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { CreateNewTask } from '../CreateNewTask';
import { CreateTaskModalOptions } from './types';

const TASK_FRAGMENT_CREATE_TASK_MODAL = gql`
  fragment TaskFragmentCreateTaskModal on TaskModel {
    id
    ...TaskFragmentCreateNewTask
  }
  ${TaskFragmentCreateNewTaskFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateTaskForCreateTaskModal($data: CreateTaskInput!) {
    createTask(data: $data) {
      ...TaskFragmentCreateTaskModal
    }
  }
  ${TASK_FRAGMENT_CREATE_TASK_MODAL}
`;

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onTaskCreated: (task: TaskFragmentCreateTaskModalFragment) => void;
  options: CreateTaskModalOptions;
  onCreateTask?: (task: CreateTaskInput) => void;
}

export const CreateTaskModal = ({
  open,
  onClose,
  onTaskCreated,
  options,
  onCreateTask,
}: Props) => {
  const isMobileView = useMediaQueryMobile();
  const { user } = useUserContext();
  const analytics = useAnalytics();

  const [createdTask, setCreatedTask] =
    React.useState<TaskFragmentCreateTaskModalFragment>();
  const [successToastOpen, setSuccessToastOpen] = React.useState(true);

  useEffect(() => {
    if (open) {
      analytics.track(EventName.NewTaskShortCutOpened);
    }
  }, [open]); // eslint-disable-line

  const onCloseSnackbar = () => {
    setSuccessToastOpen(false);
    setCreatedTask(undefined);
  };

  if (!user) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobileView}
        PaperProps={{
          sx: {
            maxWidth: theme.spacing(200),
            borderRadius: isMobileView ? 0 : 5,
            boxShadow: `0px 3px 12px -2px rgba(0, 0, 0, 0.10)`,
          },
        }}
      >
        <CreateNewTask
          onTaskCreated={(task) => {
            setCreatedTask(task);
            setSuccessToastOpen(true);
            onTaskCreated(task);
          }}
          onCancel={onClose}
          options={options}
          onCreateTask={onCreateTask}
        />
      </Dialog>

      {successToastOpen && createdTask && (
        <Snackbar
          sx={{
            minWidth: theme.spacing(100),
          }}
          ContentProps={{
            sx: {
              bgcolor: theme.colors?.primary.white,
              color: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
            },
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={successToastOpen}
          onClose={onCloseSnackbar}
          message={
            <Box display="flex" pr={11} gap={2}>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: theme.spacing(1.6),
                  top: theme.spacing(1),
                }}
                onClick={onCloseSnackbar}
              >
                <IconOutlineCloseCircle
                  size={24}
                  color={theme.colors?.utility[700]}
                />
              </IconButton>
              <Box
                sx={{
                  width: theme.spacing(8),
                  height: theme.spacing(8),
                  borderRadius: theme.spacing(4),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                bgcolor={theme.colors?.utility['light-green']}
              >
                <IconBoldTickCircle color={theme.colors?.utility.green} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                gap={4}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'column' }}
                  gap={1.5}
                >
                  <Typography
                    variant="headline-sm"
                    color={theme.colors?.utility[1000]}
                  >
                    Task created successfully
                  </Typography>
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[800]}
                  >
                    You've successfully created a new task
                  </Typography>
                </Box>
                <Link to={PlotRoutes.task(createdTask.id)}>
                  <Button variant="secondary" size="small">
                    View task
                  </Button>
                </Link>
              </Box>
            </Box>
          }
        />
      )}
    </>
  );
};
