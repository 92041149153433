import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  CreatorTrackingList,
  PostBrandSentiments,
  SocialMediaListeningPostCreatorProfileDetail,
} from 'features/socialMediaListening/components';
import { useSLBrandPermissions } from 'features/socialMediaListening/hooks';
import {
  PlotFeature,
  SocialPostFragmentPostVideoDialogContentTabsFragment,
  SocialPostFragmentSocialMediaListeningPostSummaryFragmentDoc,
  SocialPostType,
} from 'graphql/generated';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialMediaListeningPostSummaryView } from '../../../SocialMediaListeningPostSummaryView';
import { SocialPostCommunityComments } from './SocialPostCommunityComments';
import { VideoHighlights } from './VideoHighlights';
import { VideoTranscript } from './VideoTranscript';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentPostVideoDialogContentTabs on SocialPostModel {
    ...SocialPostFragmentSocialMediaListeningPostSummary
  }
  ${SocialPostFragmentSocialMediaListeningPostSummaryFragmentDoc}
`;

type ContentTabsProps = {
  brandId: string;
  currentVideoTimeInSeconds: number;
  post: SocialPostFragmentPostVideoDialogContentTabsFragment;
  componentProps?: {
    hideTabs?: TabType[];
    postSummary?: {
      containerSx?: SxProps;
    };
    videoHighlights?: {
      /**
       * time in seconds
       */
      onHighlightClicked?: (time: number) => void;
    };
  };
};

type TabType = 'summary' | 'transcript' | 'creator' | 'comments';

export const SocialMediaListeningContentTabs = ({
  brandId,
  post,
  currentVideoTimeInSeconds,
  componentProps,
}: ContentTabsProps) => {
  const [params] = useSearchParams();
  const selectedTab = params.get('tab');
  const { hasFullAccess } = useSLBrandPermissions({ brandId });

  const [tab, setTab] = useState<TabType>(
    (selectedTab || 'summary') as TabType,
  );

  const { isFeatureEnabled } = useFeatureFlagContext();
  const isSignalMiningEnabled = isFeatureEnabled(PlotFeature.SignalMining);

  return (
    <Box
      sx={{
        width: '100%',

        '& .video-dialog-tabs': {
          width: '100%',
          height: `calc(100% - ${theme.spacing(20)})`,
        },
        '& .MuiTabPanel-root': {
          height: '100%',
          overflowY: 'auto',
        },
        '& button.MuiTab-root': {
          pb: 0,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Tabs
        className="video-dialog-tabs"
        tab={tab}
        onChange={(val) => {
          setTab(val as TabType);
        }}
        tabs={[
          {
            key: 'summary',
            label: <Box>Summary</Box>,
            content: (
              <Box display="flex" gap={4} flexDirection="column">
                <SocialMediaListeningPostSummaryView
                  brandId={brandId}
                  post={post}
                  componentProps={{
                    preventVideoDialog: true,
                    container: {
                      sx: {
                        backgroundColor: theme.colors?.utility[275],
                        ...(componentProps?.postSummary?.containerSx || {}),
                      },
                    },
                    videoContainer: {
                      hide: true,
                    },
                    videoHighlights: {
                      hide: true,
                    },
                  }}
                />

                <PostBrandSentiments socialPost={post} />

                {isSignalMiningEnabled && (
                  <Box
                    sx={{
                      borderTop: `1px solid ${theme.colors?.utility[400]}`,
                      pt: 4,
                    }}
                  >
                    <Typography
                      variant="body-lg"
                      color={theme.colors?.utility[700]}
                      fontWeight={600}
                      display="flex"
                      alignItems="center"
                      gap={2}
                    >
                      <IconCustomSparkles size={16} />
                      Categories
                    </Typography>
                  </Box>
                )}

                {post.socialPostEngagementSuggestions?.[0]?.videoSummary && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{
                      borderTop: `1px solid ${theme.colors?.utility[400]}`,
                      pt: 6,
                    }}
                  >
                    <Typography
                      variant="body-lg"
                      color={theme.colors?.utility[600]}
                      fontWeight={600}
                    >
                      Summary
                    </Typography>

                    <Typography
                      variant="body-xl"
                      color={theme.colors?.utility[800]}
                      fontWeight={500}
                    >
                      {post.socialPostEngagementSuggestions?.[0]
                        ?.videoSummary ||
                        post.contextualInformation?.detailedSummary}
                    </Typography>
                  </Box>
                )}
                {post.type === SocialPostType.Video && (
                  <VideoHighlights
                    onHighlightClicked={
                      componentProps?.videoHighlights?.onHighlightClicked
                    }
                    post={post}
                  />
                )}
              </Box>
            ),
          },
          ...(!componentProps?.hideTabs?.includes('creator')
            ? [
                {
                  key: 'creator',
                  label: <Box>Creator</Box>,
                  content: (
                    <Box display="flex" gap={4} flexDirection="column">
                      {post.creator && (
                        <SocialMediaListeningPostCreatorProfileDetail
                          creator={post.creator}
                          componentProps={{
                            profileContainer: {
                              sx: {
                                backgroundColor: 'rgba(240, 183, 134, 0.15)',
                                borderRadius: 3,
                                p: 4,
                              },
                            },
                            renderCreatorTrackingButton: () =>
                              hasFullAccess && (
                                <CreatorTrackingList
                                  brandId={brandId}
                                  creator={post.creator!}
                                />
                              ),
                          }}
                        />
                      )}
                    </Box>
                  ),
                },
              ]
            : []),
          ...(post.audioTranscription.length &&
          !componentProps?.hideTabs?.includes('transcript')
            ? [
                {
                  key: 'transcript',
                  label: <Box>Transcript</Box>,
                  content: (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {tab === 'transcript' && (
                        <Box>
                          <VideoTranscript
                            currentDurationMs={currentVideoTimeInSeconds * 1000}
                            post={post}
                          />
                        </Box>
                      )}
                    </>
                  ),
                },
              ]
            : []),
          {
            key: 'comments',
            label: <Box>Top Comments</Box>,
            content: (
              <SocialPostCommunityComments comments={post.communityComments} />
            ),
          },
        ]}
      />
    </Box>
  );
};
