import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Divider, SxProps } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import {
  CommentContainerViewSkeleton,
  CommentInviteMentionUserDialog,
  CommentSidebar,
  CommentSidebarProps,
  PostCommentView,
  useCollectionCommentHandlers,
  useCommentInviteMemberHandlers,
  useCommentPermissionRequestHandlers,
} from 'features/comments';
import { getMentionedUserIds } from 'features/tiptap';
import {
  CollectionPermission,
  CommentFilter as CommentFilterType,
  CommentFragmentCollectionCommentViewFragment,
  CommentFragmentCollectionCommentViewFragmentDoc,
  CommentFragmentPostCommentViewFragment,
  CommentInputData,
  GeneralPermission,
  SortOrder,
  useGetCollectionForInviteMemberHandlersQuery,
  useGetCommentsForCollectionCommentListViewQuery,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { isCuid } from 'utils/helpers';
import { CollectionCommentView } from '../collectionComment';

export const COMMENT_FRAGMENT_COLLECTION_COMMENT_LIST_VIEW = gql`
  fragment CommentFragmentCollectionCommentListView on CommentModel {
    id
    ...CommentFragmentCollectionCommentView
  }
  ${CommentFragmentCollectionCommentViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCommentsForCollectionCommentListView($filters: CommentFilter!) {
    comments(filters: $filters) {
      id
      ...CommentFragmentCollectionCommentListView
    }
  }
  ${COMMENT_FRAGMENT_COLLECTION_COMMENT_LIST_VIEW}
`;

export type CollectionCommentListViewProps = {
  collectionId: string;
  sx?: SxProps;
  componentsProps?: CommentSidebarProps['componentsProps'];
};

export const CollectionCommentListView = (
  props: CollectionCommentListViewProps,
) => {
  const { collectionId, sx, componentsProps } = props;

  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>([]);
  const [commentHtml, setCommentHtml] = useState<string>('');
  const [parentThreadId, setParentThreadId] = useState<string>('');

  const {
    isOpen: isOpenInviteMentionUserDialog,
    onClose: onCloseInviteMentionUserDialog,
    onOpen: onOpenInviteMentionUserDialog,
  } = useDisclosure();

  const isMobileView = useMediaQueryMobile();
  const { data: collectionData } = useGetCollectionForInviteMemberHandlersQuery(
    {
      variables: {
        collectionId,
      },
      skip: !isCuid(collectionId),
    },
  );
  const myPermission = collectionData?.collection.myPermissions || [];

  const { user } = useUserContext();
  const { onCreateCollectionComment } = useCollectionCommentHandlers();
  const { onInviteCollectionMembers } = useCommentInviteMemberHandlers();
  const { onCreatePermissionRequest } = useCommentPermissionRequestHandlers();

  const [filters, setFilters] = useState<CommentFilterType>({
    collectionId,
    query: '',
    // we only need parent comment to avoid duplication. we get child comments as field from parent comment
    filterOnlyParentThreads: true,
    sortBy: {
      field: 'createdAt',
      order: SortOrder.Asc,
    },
  });

  const { data, loading } = useGetCommentsForCollectionCommentListViewQuery({
    variables: {
      filters,
    },
    fetchPolicy: 'cache-and-network',
    // skip the query if the postId is not a cuid
    skip: !isCuid(collectionId),
  });
  const comments = useMemo(() => data?.comments || [], [data]);
  const isFirstLoading = loading && !comments.length;

  // update collectionId in filters when going from one collection to section
  useEffect(() => {
    setFilters({
      ...filters,
      collectionId,
    });
  }, [collectionId]); // eslint-disable-line

  const { postWiseComments, collectionWiseComments } = useMemo(() => {
    const postWiseComments: {
      postId: string;
      comments: CommentFragmentPostCommentViewFragment[];
    }[] = [];
    const collectionWiseComments: {
      collectionId: string;
      comments: CommentFragmentCollectionCommentViewFragment[];
    }[] = [];

    comments?.forEach((comment) => {
      const commentPostId = comment.postId;
      const commentCollectionId = comment.collectionId;

      if (!commentPostId && commentCollectionId) {
        const existingEntry = collectionWiseComments.find(
          (g) => g.collectionId === commentCollectionId,
        );

        if (existingEntry) {
          existingEntry.comments.push(comment);
        } else if (commentCollectionId === collectionId) {
          // make sure to show the parent collection comments first
          collectionWiseComments.unshift({
            collectionId: commentCollectionId,
            comments: [comment],
          });
        } else {
          collectionWiseComments.push({
            collectionId: commentCollectionId,
            comments: [comment],
          });
        }
      } else if (commentPostId) {
        const existingEntry = postWiseComments.find(
          (g) => g.postId === commentPostId,
        );

        if (existingEntry) {
          existingEntry.comments.push(comment);
        } else {
          postWiseComments.push({
            postId: commentPostId,
            comments: [comment],
          });
        }
      }
    });

    return { postWiseComments, collectionWiseComments };
  }, [comments]); // eslint-disable-line

  const onCreateComment = (data: CommentInputData) => {
    onCreateCollectionComment(
      {
        collectionId,
        data,
      },

      filters,
    );

    onCloseInviteMentionUserDialog();
  };

  const onInviteMentioneUsersAndCreateComment = (data: CommentInputData) => {
    const collection = collectionData?.collection;

    setCommentHtml(data.comment);
    setParentThreadId(data.parentThreadId || '');

    // The list of mentioned user ids that are not invited to the collection
    const invitedMemberIds: string[] = [];

    // Get mentioned user ids from the comment html
    const mentionIds = getMentionedUserIds(data.comment).filter(
      (id) => id !== user?.id && id !== collectionData?.collection.creator.id,
    );

    // This is the list of members that are already invited to the collection
    const inviteMembers = collection?.inviteMembers || [];

    // Removed the mentioned user ids that are already into the collection
    const filteredMentionIds: string[] = mentionIds.filter(
      (mentionId) =>
        !inviteMembers.some((member) => member.user.id === mentionId),
    );

    const users = [
      ...(user?.organization.externalUsers || []),
      ...(user?.organization.users || []),
    ];

    for (const mentionId of filteredMentionIds) {
      const member = users.find((u) => u.id === mentionId);

      // If general permission is set to "Org" level, check the collection organization id against the mentioned user's organization id
      // If the mentioned user is not in the same organization as the collection, add it to the `invitedMemberIds`; if they are in the same organization, it means the user already has access to the collection
      if (
        member &&
        collection?.generalPermission.includes(
          GeneralPermission.OrganizationMembers,
        )
      ) {
        if (member.organization.id !== collection.organizationId) {
          invitedMemberIds.push(mentionId);
        }
      } else {
        // This case only happens when the collection is private
        invitedMemberIds.push(mentionId);
      }
    }

    setMentionedUserIds(invitedMemberIds);

    if (invitedMemberIds.length > 0) {
      onOpenInviteMentionUserDialog();
    } else {
      onCreateComment(data);
    }
  };

  const renderComments = useCallback(
    () => {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            '.mention-node': {
              color: `${theme.colors?.primary.maroon} !important`,
            },
            '& a': {
              color: `${theme.colors?.primary.maroon} !important`,
            },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isFirstLoading && <CommentContainerViewSkeleton />}
          {postWiseComments.map(({ postId, comments }, index) => {
            return (
              <>
                <PostCommentView comments={comments} postId={postId} />
                {index !== postWiseComments.length - 1 && (
                  <Divider
                    sx={{
                      borderBottomColor: theme.colors?.utility[275],
                      marginBottom: theme.spacing(3),
                    }}
                  />
                )}
              </>
            );
          })}
          {collectionWiseComments.length > 0 && postWiseComments.length > 0 && (
            <Divider
              sx={{
                borderBottomColor: theme.colors?.utility[275],
              }}
            />
          )}
          {collectionWiseComments.map(
            ({ collectionId: commentCollectionId, comments }) => {
              return (
                <CollectionCommentView
                  key={commentCollectionId}
                  shouldExpand={collectionId === commentCollectionId}
                  comments={comments}
                  collectionId={commentCollectionId}
                  onReply={onInviteMentioneUsersAndCreateComment}
                />
              );
            },
          )}
        </Box>
      );
    },
    // eslint-disable-next-line
    [
      // eslint-disable-next-line
      JSON.stringify({
        isFirstLoading,
        isMobileView,
        postWiseComments,
        collectionWiseComments,
        onInviteMentioneUsersAndCreateComment,
      }),
    ],
  );

  return (
    <>
      <CommentSidebar
        sx={sx}
        canComment={myPermission.includes(CollectionPermission.Comment)}
        componentsProps={componentsProps}
        filters={filters}
        onSetFilters={setFilters}
        onCreateComment={onInviteMentioneUsersAndCreateComment}
        renderComments={renderComments}
        canShowSearch
        key={collectionId}
      />
      {isOpenInviteMentionUserDialog && (
        <CommentInviteMentionUserDialog
          type="collection"
          mentionedUserIds={mentionedUserIds}
          variant={
            myPermission.includes(CollectionPermission.Update)
              ? 'invite'
              : 'request-access'
          }
          onCreateComment={() => {
            onCreateComment({ comment: commentHtml, parentThreadId });
          }}
          onInviteOrRequestAccessAndCreateComment={() => {
            onCreateComment({ comment: commentHtml, parentThreadId });
            if (myPermission.includes(CollectionPermission.Update)) {
              onInviteCollectionMembers({
                collectionId,
                userIds: mentionedUserIds,
              });
            } else {
              // request access
              onCreatePermissionRequest(
                mentionedUserIds.map((userId) => ({
                  userId,
                  collectionId,
                })),
              );
            }
          }}
        />
      )}
    </>
  );
};
