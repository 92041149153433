import { gql } from '@apollo/client';
import {
  BillingPlanName,
  useCreateFreeTrialSubscriptionMutation,
  useNotifyAdminForBillingMutation,
  useRequestAdminToUpgradeMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';
import { useUserContext } from 'contexts/users/User.context';

// eslint-disable-next-line
gql`
  mutation createFreeTrialSubscription($planName: String!) {
    createFreeTrialSubscription(planName: $planName) {
      id
    }
  }
`;

gql`
  mutation NotifyAdminForBilling {
    notifyAdminForBilling {
      success
      message
    }
  }
`;

gql`
  mutation RequestAdminToUpgrade($data: RequestAdminToUpgradeInput!) {
    requestAdminToUpgrade(data: $data) {
      success
      message
    }
  }
`;

export const useBillingMutations = () => {
  const { orgBilling } = useUserContext();

  const [createFreeTrialSubscription] =
    useCreateFreeTrialSubscriptionMutation();

  const [notifyAdminForBilling] = useNotifyAdminForBillingMutation();

  const [requestAdminToUpgrade] = useRequestAdminToUpgradeMutation();

  return {
    createFreeTrialSubscription: async (planName: BillingPlanName) => {
      return createFreeTrialSubscription({
        variables: {
          planName,
        },
      });
    },
    notifyAdminForBilling: async () => {
      return notifyAdminForBilling();
    },
    requestAdminToUpgrade: async (requestedPlan: BillingPlanName) => {
      return requestAdminToUpgrade({
        variables: {
          data: {
            plan: requestedPlan,
          },
        },
        update: (cache) => {
          if (orgBilling) {
            modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
              requestedAdminToUpgradePlans: () => {
                if (orgBilling.requestedAdminToUpgradePlans) {
                  return [
                    ...orgBilling.requestedAdminToUpgradePlans,
                    requestedPlan,
                  ];
                }
              },
            });
          }
        },
      });
    },
  };
};
