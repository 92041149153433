import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Avatar as MUIAvatar,
  Typography,
  Divider,
} from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useUserContext } from 'contexts/users/User.context';
import { GeneralPermission } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { PermissionUpdatesDialogProps } from './types';

export const CollectionPermissionUpdatesDialog = ({
  addedUserEmails,
  removedUserIds,
  generalPermissionUpdate,
  collection,
  onBackPress,
  onClickYes,
  onClickCustomSelect,
  loading,
}: PermissionUpdatesDialogProps) => {
  const { user } = useUserContext();
  const allUsers = useMemo(
    () => [
      ...(user?.organization.users || []),
      ...(user?.organization.externalUsers || []),
    ],
    [user],
  );

  const getMemberById = (id: string) => {
    return allUsers.find((x) => x.id === id);
  };

  const getMemberByEmail = (email: string) => {
    return allUsers.find((x) => x.email === email);
  };

  const getRowForEveryoneAtOrganization = () => {
    return (
      <Box display="flex" gap={2} alignItems="center">
        <IconBoldBuildings />
        <Typography variant="body-xl">
          Everyone at {user?.organization.name}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
      }}
    >
      <Box display="flex" gap={6} flexDirection="column">
        <Box display="flex" gap={1} alignItems="center">
          <IconButton onClick={onBackPress}>
            <IconOutlineArrowLeft color={theme.colors?.primary.black} />
          </IconButton>
          <Box display="flex" alignItems="center">
            <Typography variant="headline-lg" fontSize={theme.spacing(7)}>
              Are you sure?
            </Typography>
          </Box>
        </Box>

        {(!!addedUserEmails.length ||
          generalPermissionUpdate ===
            GeneralPermission.OrganizationMembers) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 6,
            }}
          >
            <Box>
              <Typography variant="body-xl">
                🟢 These users will <b>gain access</b> to everything within{' '}
                {collection.name}
              </Typography>
            </Box>

            {generalPermissionUpdate ===
              GeneralPermission.OrganizationMembers &&
              getRowForEveryoneAtOrganization()}

            {addedUserEmails.map((email) => {
              const user = getMemberByEmail(email);
              return (
                <Box display="flex" gap={2} alignItems="center">
                  {user ? (
                    <Avatar user={user} size={24} />
                  ) : (
                    <MUIAvatar
                      sx={{ width: theme.spacing(6), height: theme.spacing(6) }}
                    />
                  )}
                  <Typography variant="body-lg" fontWeight={600}>
                    {user ? getFullName(user) : email}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}

        {(generalPermissionUpdate === GeneralPermission.OrganizationMembers ||
          addedUserEmails.length > 0) &&
          (generalPermissionUpdate === GeneralPermission.InviteOnly ||
            removedUserIds.length > 0) && <Divider />}

        {(!!removedUserIds.length ||
          generalPermissionUpdate === GeneralPermission.InviteOnly) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 6,
            }}
          >
            <Box>
              <Typography variant="body-xl">
                ⛔️ These users will <b>lose access</b> to everything within{' '}
                {collection.name}
              </Typography>
            </Box>

            {generalPermissionUpdate === GeneralPermission.InviteOnly &&
              getRowForEveryoneAtOrganization()}

            {removedUserIds.map((memberId) => {
              const user = getMemberById(memberId);
              if (!user) return null;
              return (
                <Box display="flex" gap={2} alignItems="center">
                  <Avatar user={user} size={24} />
                  <Typography variant="body-lg" fontWeight={600}>
                    {getFullName(user)}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
      <Box display="flex" gap={2} flexDirection="column">
        <Button
          fullWidth
          onClick={onClickYes}
          sx={{
            borderRadius: 2,
            backgroundColor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
            '&:hover': { backgroundColor: theme.colors?.primary.black },
          }}
          disabled={loading}
          startIcon={loading ? <CircularProgress size="1rem" /> : null}
        >
          Yes I'm sure
        </Button>
        <Button
          variant="text"
          fullWidth
          sx={{
            color: theme.colors?.primary.black,
          }}
          onClick={onClickCustomSelect}
        >
          Choose what to update
        </Button>
      </Box>
    </Box>
  );
};
