import { Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearEdit } from 'components/icons/components/linear/IconLinearEdit';
import { IconOutlineMore } from 'components/icons/components/outline/IconOutlineMore';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { theme } from 'styles/theme/theme';

interface Props {
  onSetEditedComment: VoidFunction;
  onDeletePostComment: VoidFunction;
  onToggleMenu: (isOpen: boolean) => void;
}

export const MoreActions = ({
  onSetEditedComment,
  onDeletePostComment,
  onToggleMenu,
}: Props) => {
  return (
    <ContextMenu
      onClose={() => {
        onToggleMenu(false);
      }}
      sx={{
        zIndex: 1999,
        '& .MuiPaper-root': { zIndex: 1999 },
      }}
      renderButton={() => {
        return (
          <IconOutlineMore
            style={{
              cursor: 'pointer',
              color: theme.colors?.utility[700],
            }}
            onClick={() => {
              onToggleMenu(true);
            }}
          />
        );
      }}
      options={[
        {
          renderOption: () => (
            <Box display="flex" gap={2} alignItems="center">
              <IconLinearEdit size={16} />
              <Typography variant="headline-xs">Edit</Typography>
            </Box>
          ),
          onClick: onSetEditedComment,
        },
        {
          renderOption: () => (
            <Box display="flex" gap={2} alignItems="center">
              <IconOutlineTrash
                size={16}
                color={theme.colors?.utility['red-1']}
              />
              <Typography
                variant="headline-xs"
                color={theme.colors?.utility['red-1']}
              >
                Delete
              </Typography>
            </Box>
          ),
          onClick: onDeletePostComment,
        },
      ]}
    />
  );
};
