import { gql } from '@apollo/client';
import {
  BrandSocialPostEngagementStatus,
  useUpdateBrandSocialPostEngagementMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

gql`
  mutation UpdateBrandSocialPostEngagement(
    $data: UpdateBrandSocialPostEngagementInput!
  ) {
    updateBrandSocialPostEngagement(data: $data) {
      id
    }
  }
`;

export const useBrandSocialPostEngagementMutations = () => {
  const [updateBrandSocialPostEngagement] =
    useUpdateBrandSocialPostEngagementMutation();

  return {
    updateBrandSocialPostEngagementStatus: async (
      brandId: string,
      socialPostId: string,
      status: BrandSocialPostEngagementStatus,
    ) => {
      await updateBrandSocialPostEngagement({
        variables: {
          data: {
            brandId,
            socialPostId,
            status,
          },
        },
        update: (cache, result) => {
          modifyObject(
            cache,
            result.data?.updateBrandSocialPostEngagement.id ?? '',
            'BrandSocialPostEngagementModel',
            {
              status: () => status,
            },
          );
        },
      });
    },
  };
};
