import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { createRecordWithType } from 'utils/types';

export const typography = createRecordWithType<TypographyStyleOptions>()({
  'headline-xl': {
    fontFamily: 'Good Sans',
    fontSize: 48,
  },
  'headline-lg': {
    fontFamily: 'TT Commons Pro',
    fontSize: 24,
    fontWeight: 600,
  },
  'headline-md': {
    fontFamily: 'TT Commons Pro',
    fontSize: 18,
    fontWeight: 600,
  },
  'headline-sm': {
    fontFamily: 'TT Commons Pro',
    fontSize: 16,
    fontWeight: 600,
  },
  'headline-xs': {
    fontFamily: 'TT Commons Pro',
    fontSize: 14,
    fontWeight: 600,
  },
  'headline-xxs': {
    fontFamily: 'TT Commons Pro',
    fontSize: 12,
    fontWeight: 600,
  },
  'subhead-xl': {
    fontFamily: 'TT Commons Pro',
    fontSize: 16,
    fontWeight: 500,
  },
  'subhead-lg': {
    fontFamily: 'TT Commons Pro',
    fontSize: 14,
    fontWeight: 500,
  },
  'subhead-md': {
    fontFamily: 'TT Commons Pro',
    fontSize: 13,
    fontWeight: 500,
  },
  'subhead-sm': {
    fontFamily: 'TT Commons Pro',
    fontSize: 12,
    fontWeight: 500,
  },
  'subhead-xs': {
    fontFamily: 'TT Commons Pro',
    fontSize: 11,
    fontWeight: 500,
  },
  'subhead-xxs': {
    fontFamily: 'TT Commons Pro',
    fontSize: 10,
    fontWeight: 500,
  },
  'body-xl': {
    fontFamily: 'TT Commons Pro',
    fontSize: 16,
  },
  'body-lg': {
    fontFamily: 'TT Commons Pro',
    fontSize: 14,
  },
  'body-md': {
    fontFamily: 'TT Commons Pro',
    fontSize: 13,
  },
  'body-sm': {
    fontFamily: 'TT Commons Pro',
    fontSize: 12,
  },
  'body-xs': {
    fontFamily: 'TT Commons Pro',
    fontSize: 11,
  },
  'hand-md': {
    fontFamily: 'La Belle Aurore',
    fontSize: 18,
  },
});
