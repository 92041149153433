/**
 * This is a custom menu item component for the Platforms field.
 * It is not a pure menu item & will have complex rendering & behavior.
 */

import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  MenuItemProps,
  Typography,
} from '@mui/material';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconOutlineArrowDown2 } from 'components/icons/components/outline/IconOutlineArrowDown2';
import { IconOutlineArrowUp2 } from 'components/icons/components/outline/IconOutlineArrowUp2';
import { useUserContext } from 'contexts/users/User.context';
import {
  ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelectFragmentDoc,
  ContentIdeaFragmentContentIdeaPlatformsMenuItemFragment,
  Platform,
  useUpdateContentIdeaPublishMetadataForContentIdeaPlatformsMenuItemMutation,
} from 'graphql/generated';
import { useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { ContentIdeaBadgeMenuItem } from '../badgeMenuItem';

// TODO: Should have 1 uniform enum
export const platformOptionToSupportedPlatformEnumMap = {
  Tiktok: Platform.Tiktok,
  Instagram: Platform.Instagram,
};

export const CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PLATFORMS_MENU_ITEM = gql`
  fragment ContentIdeaPublishMetadataFragmentContentIdeaPlatformsMenuItem on ContentIdeaPublishMetadataModel {
    id
    platform
    socialAuthor {
      id
      platformHandle
    }
  }
`;

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_PLATFORMS_MENU_ITEM = gql`
  fragment ContentIdeaFragmentContentIdeaPlatformsMenuItem on ContentIdeaModel {
    id
    platforms {
      id
      contentIdeaField {
        id
      }
      ...ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelect
    }
    contentIdeaPublishMetadata {
      id
      ...ContentIdeaPublishMetadataFragmentContentIdeaPlatformsMenuItem
    }
  }
  ${ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelectFragmentDoc}
  ${CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PLATFORMS_MENU_ITEM}
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaPublishMetadataForContentIdeaPlatformsMenuItem(
    $data: UpdateContentIdeaPublishMetadataInput!
  ) {
    updateContentIdeaPublishMetadata(data: $data) {
      id
      ...ContentIdeaPublishMetadataFragmentContentIdeaPlatformsMenuItem
    }
  }
  ${CONTENT_IDEA_PUBLISH_METADATA_FRAGMENT_CONTENT_IDEA_PLATFORMS_MENU_ITEM}
`;

export type ContentIdeaPlatformsMenuItemProps = MenuItemProps & {
  contentIdea: ContentIdeaFragmentContentIdeaPlatformsMenuItemFragment;
  label: string;
  Icon?: React.FC<any>;
  bgcolor?: string;
  selected?: boolean;
};

export const ContentIdeaPlatformsMenuItem = (
  props: ContentIdeaPlatformsMenuItemProps,
) => {
  const {
    contentIdea,
    value,
    label,
    Icon,
    bgcolor,
    selected: isPlatformSelected,
    onClick,
    ...rest
  } = props;

  const contentIdeaPublishMetadataForCurrentPlatform =
    contentIdea.contentIdeaPublishMetadata.find(
      (metadata) =>
        metadata.platform ===
        platformOptionToSupportedPlatformEnumMap[value as string],
    );

  const { user } = useUserContext();
  const socialAuthors = user?.organization.socialAuthors || [];
  const socialAuthorsForCurrentPlatform = socialAuthors.filter(
    (author) =>
      author.platform ===
      platformOptionToSupportedPlatformEnumMap[value as string],
  );

  const { isOpen: isSubMenuOpen, onToggle: toggleSubMenu } = useDisclosure();
  const [loadingOptionId, setLoadingOptionId] = useState<string | null>(null);

  const [updateContentIdeaPublishMetadata] =
    useUpdateContentIdeaPublishMetadataForContentIdeaPlatformsMenuItemMutation();

  const ref = useRef<HTMLLIElement | null>(null);
  const simulateMenuItemClick = () => {
    if (ref.current) {
      // @ts-ignore -- all we need is the target & currentTarget
      onClick?.({
        target: ref.current,
        currentTarget: ref.current,
        stopPropagation: () => {},
        preventDefault: () => {},
      });
    }
  };

  // Render basic menu item if no social authors are available
  if (socialAuthorsForCurrentPlatform.length === 0) {
    return (
      <ContentIdeaBadgeMenuItem
        label={label}
        Icon={Icon}
        bgcolor={bgcolor}
        selected={isPlatformSelected}
        onClick={onClick}
        {...rest}
      />
    );
  }

  return (
    <>
      <MenuItem
        {...rest}
        ref={ref}
        key={value as string}
        value={value}
        onClick={toggleSubMenu}
        sx={{
          width: 240,
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          borderRadius: 2,
          px: '12px !important',
          py: '8px !important',
        }}
      >
        <Chip
          icon={Icon && <Icon size={16} />}
          sx={{
            bgcolor: bgcolor || theme.colors?.utility['400'],
            borderRadius: 1,
          }}
          label={<Typography variant="headline-xs">{label}</Typography>}
          variant="filled-borderless-color-dodge"
        />
        <Box sx={{ ml: 'auto' }}>
          {isSubMenuOpen ? (
            <IconOutlineArrowUp2 size={16} />
          ) : (
            <IconOutlineArrowDown2 size={16} />
          )}
        </Box>
      </MenuItem>
      {contentIdea && isSubMenuOpen && (
        <Box
          sx={{
            width: 240,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {socialAuthorsForCurrentPlatform.map((author) => {
            const isSocialAuthorSelected =
              contentIdeaPublishMetadataForCurrentPlatform?.socialAuthor?.id ===
              author.id;

            return (
              <MenuItem
                key={author.id}
                onClick={async () => {
                  setLoadingOptionId(author.id);

                  if (contentIdeaPublishMetadataForCurrentPlatform) {
                    await updateContentIdeaPublishMetadata({
                      variables: {
                        data: {
                          contentIdeaPublishMetadataId:
                            contentIdeaPublishMetadataForCurrentPlatform.id,
                          data: {
                            socialAuthorId: isSocialAuthorSelected
                              ? null
                              : author.id,
                          },
                        },
                      },
                    });

                    // Select the platform if the author is selected
                    if (!isSocialAuthorSelected && !isPlatformSelected) {
                      simulateMenuItemClick();
                    }

                    // Unselect the platform if the author is unselected
                    if (isSocialAuthorSelected && isPlatformSelected) {
                      simulateMenuItemClick();
                    }
                  }

                  setLoadingOptionId(null);
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  gap: 1,
                  px: '12px !important',
                  py: '8px !important',
                  borderRadius: 2,
                }}
              >
                <Typography
                  variant="headline-xs"
                  sx={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {author.platformHandle}
                </Typography>
                {loadingOptionId === author.id ? (
                  <CircularProgress
                    size={14}
                    sx={{ color: theme.colors?.primary.black }}
                  />
                ) : (
                  isSocialAuthorSelected && <IconCustomTick size={14} />
                )}
              </MenuItem>
            );
          })}
        </Box>
      )}
    </>
  );
};
