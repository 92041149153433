import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconOutlineUserAdd } from 'components/icons/components/outline/IconOutlineUserAdd';
import { useUserContext } from 'contexts/users/User.context';
import { UserFragmentAddUsersToOrgViewManageUsersListFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const NEW_USER_ID = 'new_user_id';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment UserFragmentAddUsersToOrgViewManageUsersList on UserProfileModel {
    id
    firstName
    lastName
    email
    avatarUrl
  }
`;

type Props = {
  users: UserFragmentAddUsersToOrgViewManageUsersListFragment[];
  selectedEmails: string[];
  onRemoveEmail: (email: string) => void;
  onAddEmail: (email: string) => void;
};

export const ManageUsersList = ({
  users,
  selectedEmails,
  onRemoveEmail,
  onAddEmail,
}: Props) => {
  const { user } = useUserContext();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {users.length > 0 ? (
        <Box p={4}>
          <Box pb={2}>
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[800]}
              fontWeight={600}
            >
              Members at {user?.organization.name}
            </Typography>
          </Box>
          {users.map((user) => (
            <Box
              display="flex"
              gap={3}
              alignItems="center"
              py={2}
              key={user.id}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  background: 'rgba(35, 6, 3, 0.05)',
                  p: 1,
                  borderRadius: theme.spacing(1),
                }}
              >
                <IconOutlineUserAdd size={16} />
              </Box>
              <Typography
                display="flex"
                flex={1}
                variant="body-lg"
                fontWeight={500}
                color={theme.colors?.utility[900]}
              >
                {user.email}
              </Typography>
              {user.id === NEW_USER_ID && (
                <Box display="flex">
                  {selectedEmails.includes(user.email) ? (
                    <IconButton
                      disableRipple
                      onClick={() => onRemoveEmail(user.email)}
                      sx={{ py: 1.5 }}
                    >
                      <IconBoldTickCircle size={20} />
                    </IconButton>
                  ) : (
                    <Box
                      sx={{
                        mr: 2,
                        width: theme.spacing(4.5),
                        height: theme.spacing(4.5),
                        borderRadius: theme.spacing(5),
                        border: `2px solid ${theme.colors?.utility['800']}`,
                        cursor: 'pointer',
                      }}
                      onClick={() => onAddEmail(user.email)}
                    />
                  )}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[1000]}
            fontWeight={600}
          >
            Invite your team members here!
          </Typography>
          <Typography
            variant="body-sm"
            color={theme.colors?.utility[700]}
            fontWeight={500}
            pb={8}
          >
            Invite & Collaborate at Plot.
          </Typography>
        </Box>
      )}
    </>
  );
};
