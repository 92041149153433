/**
 * Iframely's self-hosted iframe generates some random styles that doesn't
 * work really well with our masonry.
 *
 * This method aims to override the random styles so they could look better.
 * Not 100% fool-proof.
 */
export const getCustomPostPreviewStyles = (site?: string) => {
  switch (site?.toLowerCase()) {
    case 'twitter': {
      return {
        border: 'none',
        '.iframely-responsive': {
          marginTop: '-10px !important',
          marginBottom: '-10px !important',
        },
      };
    }
    default: {
      return {};
    }
  }
};
