import { Box, SxProps } from '@mui/material';
import { PostPreview } from 'features/post';
import { CollectionFragmentCollectionMultiPostPreviewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { PLACEHOLDER_BACKGROUND_COLORS } from '../constants';

export type CardStackAltProps = {
  collection: CollectionFragmentCollectionMultiPostPreviewFragment;
  sx?: SxProps;
  componentsProps?: {
    card?: {
      sx: SxProps;
    };
  };
};

export const CardStackAlt = (props: CardStackAltProps) => {
  const { collection, sx, componentsProps = {} } = props;
  const { posts } = collection;

  const renderPost = (index: number) => {
    const post = posts[index];

    if (!post) {
      return (
        <Box
          sx={{ height: '100%', bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index] }}
        />
      );
    }

    return (
      <PostPreview
        key={post.id}
        post={post}
        sx={{ height: '100%', bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index] }}
        variant="compact"
        componentsProps={{
          postNotePreviewCompact: {
            sx: {
              p: 6,
            },
            componentsProps: {
              icon: {
                size: 16,
              },
              title: {
                sx: {
                  fontSize: 16,
                },
              },
            },
          },
        }}
      />
    );
  };

  const defaultCardStyles: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '42.5%',
    aspectRatio: '7 / 10',
    borderRadius: 2,
    border: `2px solid ${theme.colors?.primary.white}`,
    overflow: 'hidden',
    boxShadow:
      '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
    ...componentsProps.card?.sx,
  };

  return (
    <Box
      sx={{
        // Set zIndex to 0 to prevent its children from messing with other elements
        zIndex: 0,
        position: 'relative',
        aspectRatio: '4 / 3',
        display: 'flex',
        ...sx,
      }}
    >
      <Box
        sx={{
          ...defaultCardStyles,
          transform: 'translate(-50%, -50%) rotate(10deg)',
          zIndex: 2,
        }}
      >
        {renderPost(0)}
      </Box>
      <Box
        sx={{
          ...defaultCardStyles,
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        {renderPost(1)}
      </Box>
      <Box
        sx={{
          ...defaultCardStyles,
          transform: 'translate(-50%, -50%) rotate(-10deg)',
          zIndex: 0,
        }}
      >
        {renderPost(2)}
      </Box>
    </Box>
  );
};
