import { Box } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { Sentiment } from 'graphql/generated';

type Props = {
  size: number;
  sentiment: Sentiment;
};

export const SentimentIcon = ({ sentiment, size }: Props) => {
  if (sentiment === Sentiment.Positive) {
    return (
      <Tooltip title="Positive">
        <Box
          width={size}
          height="auto"
          component="img"
          src="/images/sentiments/positive.png"
        />
      </Tooltip>
    );
  }

  if (sentiment === Sentiment.Negative) {
    return (
      <Tooltip title="Negative">
        <Box
          width={size}
          height="auto"
          component="img"
          src="/images/sentiments/negative.png"
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={sentiment === Sentiment.Neutral ? 'Neutral' : 'None'}>
      <Box
        width={size}
        height="auto"
        component="img"
        src="/images/sentiments/neutral.png"
      />
    </Tooltip>
  );
};
