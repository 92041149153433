import { gql } from '@apollo/client';
import { useGetTaskPermissionsQuery } from 'graphql/generated';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line
gql`
  query GetTaskPermissions($id: String!) {
    task(id: $id) {
      id
      myPermissions
    }
  }
`;

export const useTaskPermissions = (id: string) => {
  const data = useGetTaskPermissionsQuery({
    variables: {
      id,
    },
    skip: !id,
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });

  if (data.error) {
    return [];
  }

  return data.data?.task.myPermissions || [];
};
