import { Box, Divider } from '@mui/material';
import { JuiceboxFilter } from 'features/juicebox/hooks';
import { ShowMoreType } from 'features/juicebox/views';
import {
  GetFiltersSearchForJuiceboxFilterSuggestionViewQuery,
  GetUniversalSearchForJuiceboxFilterSuggestionViewQuery,
} from 'graphql/generated';
import { MutableRefObject } from 'react';
import { theme } from 'styles/theme';
import {
  JuiceboxAttachmentSearchItem,
  JuiceboxCollectionSearchItem,
  JuiceboxLinkSearchItem,
  JuiceboxNoteSearchItem,
} from '../searchItem';
import { JuiceboxSearchResultHeader } from '../searchResultHeader';
import { JuiceboxFilterSearchResult } from './JuiceboxFilterSearchResult';

interface Props {
  universalSearchData:
    | GetUniversalSearchForJuiceboxFilterSuggestionViewQuery['universalSearch']
    | null;
  filtersSearchData: GetFiltersSearchForJuiceboxFilterSuggestionViewQuery['filtersSearch'];
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
  componentProps?: {
    filterSearchItem?: {
      onSelectFilter: (filter: JuiceboxFilter) => void;
    };
    searchResultHeader?: {
      onShowMore: (type: ShowMoreType) => void;
    };
  };
}

/*
  TODO: this component is incorrectly named. This is not rendering contextual search, it simply rendered universal search results which may or may not be contextual
  depending on the users paid plan
*/
export const JuiceboxContextualSearchResult = ({
  universalSearchData,
  filtersSearchData,
  elRefs,
  componentProps = {},
}: Props) => {
  const hasUniversalSearchData =
    universalSearchData &&
    (universalSearchData.noteSearchResult.hits.length > 0 ||
      universalSearchData.linkSearchResult.hits.length > 0 ||
      universalSearchData.attachmentSearchResult.hits.length > 0 ||
      universalSearchData.collectionSearchResult.hits.length > 0);

  const hasFiltersSearchData =
    filtersSearchData &&
    ((filtersSearchData.createdBy || []).length > 0 ||
      (filtersSearchData.sharedBy || []).length > 0 ||
      (filtersSearchData.collections || []).length > 0 ||
      (filtersSearchData.type || []).length > 0);

  const initialElIndex =
    (filtersSearchData.collections?.length || 0) +
    (filtersSearchData.createdBy?.length || 0) +
    (filtersSearchData.sharedBy?.length || 0) +
    (filtersSearchData.type?.length || 0) +
    (filtersSearchData.reaction?.length || 0) +
    (filtersSearchData.dateModified?.length || 0);

  let elIndex = initialElIndex;

  return (
    <>
      <JuiceboxFilterSearchResult
        elRefs={elRefs}
        filtersSearchData={filtersSearchData}
        componentProps={componentProps}
      />

      {hasFiltersSearchData && hasUniversalSearchData && (
        <Divider
          sx={{
            borderBottom: `1px solid ${theme.colors?.utility[400]}`,
            mx: 3,
          }}
        />
      )}

      {(universalSearchData?.collectionSearchResult.hits || []).length > 0 && (
        <>
          <JuiceboxSearchResultHeader
            title={`Collections(
                ${universalSearchData?.collectionSearchResult.totalCount}
                )`}
            onShowMore={() => {
              componentProps.searchResultHeader?.onShowMore(
                ShowMoreType.Collections,
              );
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', my: -2 }}>
            {universalSearchData?.collectionSearchResult.hits.map(
              ({ item }) => (
                <JuiceboxCollectionSearchItem
                  collection={item}
                  posts={item.posts}
                  ref={(el) => {
                    elRefs.current[elIndex] = el;
                    elIndex++;
                  }}
                />
              ),
            )}
          </Box>
        </>
      )}
      {(universalSearchData?.noteSearchResult.hits || []).length > 0 && (
        <>
          <JuiceboxSearchResultHeader
            title={`Notes(
                ${universalSearchData?.noteSearchResult.totalCount}
                )`}
            onShowMore={() => {
              componentProps.searchResultHeader?.onShowMore(ShowMoreType.Notes);
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', my: -2 }}>
            {universalSearchData?.noteSearchResult.hits.map(({ item }) => (
              <JuiceboxNoteSearchItem
                note={item}
                ref={(el) => {
                  elRefs.current[elIndex] = el;
                  elIndex++;
                }}
              />
            ))}
          </Box>
        </>
      )}
      {(universalSearchData?.linkSearchResult.hits || []).length > 0 && (
        <>
          <JuiceboxSearchResultHeader
            title={`Links(
              ${universalSearchData?.linkSearchResult.totalCount}
              )`}
            onShowMore={() => {
              componentProps.searchResultHeader?.onShowMore(ShowMoreType.Links);
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', my: -2 }}>
            {universalSearchData?.linkSearchResult.hits.map(({ item }) => (
              <JuiceboxLinkSearchItem
                link={item}
                ref={(el) => {
                  elRefs.current[elIndex] = el;
                  elIndex++;
                }}
              />
            ))}
          </Box>
        </>
      )}

      {(universalSearchData?.attachmentSearchResult.hits || []).length > 0 && (
        <>
          <JuiceboxSearchResultHeader
            title={`Attachments(
              ${universalSearchData?.attachmentSearchResult.totalCount}
              )`}
            onShowMore={() => {
              componentProps.searchResultHeader?.onShowMore(
                ShowMoreType.Attachments,
              );
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', my: -2 }}>
            {universalSearchData?.attachmentSearchResult.hits.map(
              ({ item }) => (
                <JuiceboxAttachmentSearchItem
                  attachment={item}
                  ref={(el) => {
                    elRefs.current[elIndex] = el;
                    elIndex++;
                  }}
                />
              ),
            )}
          </Box>
        </>
      )}
    </>
  );
};
