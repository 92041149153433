import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { CompetitorSentimentTableDataProps as CompetitorSentimentThemesProps } from './types';

export const CompetitorSentimentThemes = ({
  claimMetrics,
  componentProps,
  onClickTheme,
}: CompetitorSentimentThemesProps) => {
  const { mainColor = theme.colors?.primary.black, highlightColor } =
    componentProps || {};

  return (
    <Box
      p={6}
      color={theme.colors?.primary.black}
      display="flex"
      gap={3}
      flexDirection="column"
      sx={{
        maxHeight: theme.spacing(150),
        overflowY: 'auto',
      }}
    >
      <Box display="flex" gap={6} justifyContent="space-between" width="100%">
        <Typography variant="headline-sm" color={theme.colors?.utility[1000]}>
          Themes
        </Typography>
        <Typography variant="headline-sm" color={theme.colors?.utility[1000]}>
          Number of mentions
        </Typography>
      </Box>

      {claimMetrics.length ? (
        <>
          {claimMetrics.map((claimData, index) => (
            <Box
              display="flex"
              key={index}
              gap={6}
              justifyContent="space-between"
              width="100%"
            >
              <Box
                flex={1}
                onClick={() => {
                  onClickTheme(claimData.attribute);
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    fontWeight: 600,
                    color: mainColor,
                    backgroundColor: highlightColor,
                    width: 'fit-content',
                    p: theme.spacing(1.5, 3),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <Typography variant="body-xl">
                    {claimData.attribute}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body-xl"
                  color={theme.colors?.utility[700]}
                  fontWeight={500}
                >
                  {claimData.numberOfMentions}
                </Typography>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 3,
            height: 100,
          }}
        >
          <Typography
            variant="body-lg"
            color={theme.colors?.utility[700]}
            width="100%"
            display="flex"
            justifyContent="center"
            mt={4}
          >
            No data available
          </Typography>
        </Box>
      )}
    </Box>
  );
};
