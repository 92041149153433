import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Divider,
  MenuItem,
  SxProps,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { StyledMenu } from 'components/common/ContextMenu/styles';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineTickCircle } from 'components/icons/components/outline/IconOutlineTickCircle';
import { useUserContext } from 'contexts/users/User.context';
import { BillingUpgradeModal } from 'features/billing';
import React, { useMemo } from 'react';
import { theme } from 'styles/theme';
import { useMembersHandlers } from '../../../../pages/org/settings/members/hooks/useMembersHandlers';

type OrganizationMembersContextMenuProps = {
  memberId: string;
  isUserDisabled?: boolean;
  renderButton: ((open?: boolean) => React.ReactNode) | false;
  tooltip?: Omit<TooltipProps, 'children'>;
  sx?: SxProps;
  disabled?: boolean;
};

export const OrganizationMembersContextMenu = (
  props: OrganizationMembersContextMenuProps,
) => {
  const { memberId, disabled, isUserDisabled, renderButton, tooltip, sx } =
    props;
  const { orgBilling } = useUserContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    isOpen: pricingModalOpen,
    onOpen: pricingModalOnOpen,
    onClose: pricingModalOnClose,
  } = useDisclosure();

  const {
    disableUsers,
    enableUsers,
    userEnableConfirmationDialog,
    userDisableConfirmationDialog,
  } = useMembersHandlers();

  const menuOptions = useMemo(
    () => ({
      enable: {
        label: 'Enable Access',
        icon: IconOutlineTickCircle,
        disabled: !isUserDisabled,
        color: theme.colors?.primary.black,
        onClick: () => {
          enableUsers([memberId]);
        },
      },
      disable: {
        label: 'Remove Members',
        icon: IconLinearTrash,
        disabled: isUserDisabled,
        color: theme.colors?.utility['pink-3'],
        onClick: () => {
          disableUsers([memberId]);
        },
      },
    }),
    [disableUsers, enableUsers, isUserDisabled, memberId],
  );

  const canAddMember = useMemo(() => {
    return (
      // Null organizationMemberLimit = unlimited members
      !orgBilling?.organizationMemberLimit ||
      orgBilling.organizationMemberUsage < orgBilling.organizationMemberLimit
    );
  }, [orgBilling]);

  const highestLevelAllowed = useMemo(() => {
    if (canAddMember) {
      return 'enable';
    }

    return 'disable';
  }, [canAddMember]);

  const { availableOptions, paidOptions } = useMemo(() => {
    const options = Object.keys(menuOptions);

    let availableOptions: string[] = [];
    const paidOptions: string[] = [];

    for (const { index, option } of options.map((option, index) => ({
      index,
      option,
    }))) {
      if (highestLevelAllowed === option) {
        availableOptions = options.slice(index, options.length);
        break;
      }

      paidOptions.push(option);
    }

    return {
      availableOptions,
      paidOptions,
    };
  }, [highestLevelAllowed, menuOptions]);

  return (
    <>
      {renderButton !== false && (
        <Tooltip title="" {...tooltip}>
          <Box
            display="flex"
            width="fit-content"
            onClick={handleClick}
            sx={{
              ...(disabled
                ? {
                    opacity: 0.5,
                  }
                : {}),
            }}
          >
            {renderButton(open)}
          </Box>
        </Tooltip>
      )}
      {!disabled && (
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: { width: theme.spacing(67) },
          }}
        >
          {availableOptions.map((option) => {
            const Icon = menuOptions[option].icon;
            return (
              <MenuItem
                value={option}
                key={option}
                disabled={menuOptions[option].disabled}
                onClick={() => {
                  handleClose();
                  menuOptions[option].onClick();
                }}
              >
                <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
                  <Box display="flex">
                    <Icon size={16} color={menuOptions[option].color} />
                  </Box>
                  <Typography
                    variant="body-lg"
                    color={menuOptions[option].color}
                  >
                    {menuOptions[option].label}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })}

          {paidOptions.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(4),
                padding: theme.spacing(2, 3),
              }}
            >
              <Divider />
              <Box>
                <Typography variant="headline-xs" fontWeight={500}>
                  To enable access for members{' '}
                  <Typography
                    variant="headline-xs"
                    sx={{
                      fontWeight: 600,
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={pricingModalOnOpen}
                  >
                    {orgBilling?.hasUsedFreeTrial
                      ? 'Upgrade Now'
                      : 'Start Free Trial'}
                  </Typography>
                </Typography>

                <BillingUpgradeModal
                  isOpen={pricingModalOpen}
                  onClose={pricingModalOnClose}
                />
              </Box>

              {paidOptions.map((option) => {
                const Icon = menuOptions[option].icon;

                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={theme.spacing(2)}
                    sx={{
                      opacity: 0.6,
                    }}
                  >
                    <Box display="flex">
                      <Icon size={16} color={menuOptions[option].color} />
                    </Box>
                    <Typography
                      variant="body-lg"
                      color={menuOptions[option].color}
                    >
                      {menuOptions[option].label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
        </StyledMenu>
      )}
      {userEnableConfirmationDialog}
      {userDisableConfirmationDialog}
    </>
  );
};
