import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { SearchParams } from 'features/post/constants';
import {
  TaskListWithKeyboardShortcutsCommandHelper,
  TaskListWithKeyboardShortcutsCommandHelperProps,
  TaskListWithKeyboardShortcutsListView,
} from 'features/taskListWithKeyboardShortcuts';
import {
  PostFragmentTaskHeadingSectionFragmentDoc,
  PostFragmentTasksTabContentFragment,
} from 'graphql/generated';
import { useState } from 'react';

export const POST_FRAGMENT_TASKS_TAB_CONTENT = gql`
  fragment PostFragmentTasksTabContent on PostModel {
    ...PostFragmentTaskHeadingSection
  }
  ${PostFragmentTaskHeadingSectionFragmentDoc}
`;

type Props = {
  post?: PostFragmentTasksTabContentFragment;
};

export const TasksTabContent = (props: Props) => {
  const { post } = props;

  const [helperState, setHelperState] = useState<
    TaskListWithKeyboardShortcutsCommandHelperProps['state']
  >({
    createTaskGlobal: true,
  });

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        <TaskListWithKeyboardShortcutsListView
          filters={{
            postIds: [post?.id || ''],
          }}
          skipLoading={!post}
          sx={{
            flex: 1,
          }}
          onFocusedTaskChange={(task, canShiftOrUnShift = true) => {
            if (!task) {
              setHelperState({
                createTaskGlobal: true,
              });

              return;
            }

            if (!canShiftOrUnShift) {
              setHelperState({
                createTask: true,
              });
            } else if (task.parentTaskId) {
              setHelperState({
                createSubtask: true,
                unshiftSubtask: true,
              });
            } else {
              setHelperState({
                createTask: true,
                shiftTask: true,
              });
            }
          }}
          componentsProps={{
            listItem: {
              componentsProps: {
                link(task) {
                  return {
                    to: {
                      pathname: PlotRoutes.task(task?.id),
                      search: `?${SearchParams.postId}=${post?.id}`,
                    },
                  };
                },
              },
            },
          }}
        />
      </Box>
      <TaskListWithKeyboardShortcutsCommandHelper state={helperState} />
    </Box>
  );
};
