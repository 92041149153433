import { Box, Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';
import { useSmartHover } from 'hooks/useSmartHover';
import {
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { theme } from 'styles/theme';

export type MoreMenuItemProps = {
  label: ReactNode;
  hideMenu?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  endIconPosition?: 'start' | 'end';
  MenuProps?: Omit<MenuProps, 'open' | 'onClose' | 'anchorEl' | 'onKeyDown'>;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
} & Omit<MenuItemProps, 'onKeyDown' | 'onMouseEnter' | 'onMouseLeave'>;

export const MoreMenuItem = (props: MoreMenuItemProps) => {
  const {
    label,
    hideMenu,
    startIcon,
    endIcon,
    sx,
    children,
    id,
    MenuProps,
    onMenuOpen,
    onMenuClose,
    ...other
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (isOpen) {
      onMenuOpen?.();
    } else {
      // Timeout to prevent flickering when closing
      // 200ms should follow animation duration
      setTimeout(() => {
        onMenuClose?.();
      }, 200);
    }
  }, [isOpen, onMenuOpen, onMenuClose]);

  const menuItemRef = useRef<HTMLLIElement | null>(null);
  const menuItemId = useId();
  const normMenuItemId = id ?? menuItemId;

  const { isHovered, onMouseEnter, onMouseLeave } = useSmartHover({
    enterDelay: 200,
    leaveDelay: 200,
  });
  useEffect(() => {
    if (isHovered) {
      open();
    } else {
      close();
    }
  }, [isHovered]); // eslint-disable-line

  const shouldFlipHorizontal = (initial: 'left' | 'right') => {
    if (menuItemRef.current) {
      const { right } = menuItemRef.current.getBoundingClientRect();

      // TODO: make sure the menu goes all the way right or left before flipping
      if (window.innerWidth - right < 300) {
        return initial === 'right' ? 'left' : 'right';
      }
    }
    return initial;
  };

  const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = (ev) => {
    if (
      (ev.key !== 'ArrowRight' && ev.key !== 'Enter') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    ev.stopPropagation();
    setIsOpen(true);
  };

  const handleMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    if (
      (ev.key !== 'ArrowLeft' && ev.key !== 'Escape') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    setIsOpen(false);
  };

  return (
    <MenuItem
      onKeyDown={handleItemKeyDown}
      ref={menuItemRef}
      sx={{
        justifyContent: 'space-between',
        borderRadius: theme.spacing(3),
        width: '100%',
        ...sx,
      }}
      id={normMenuItemId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...other}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: '100%',
        }}
      >
        {startIcon}
        {label}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        {endIcon}
      </Box>
      {!hideMenu && (
        <Menu
          disableRestoreFocus
          onKeyDown={handleMenuKeyDown}
          sx={{
            pointerEvents: 'none',
            '& .MuiList-root': {
              pointerEvents: 'auto',
              p: '12px !important', // Fix this to make it consistent with other similar dropdowns/menus
            },
            ...MenuProps?.sx,
          }}
          PaperProps={{
            sx: { ...MenuProps?.sx },
          }}
          MenuListProps={{
            ...MenuProps?.MenuListProps,
            'aria-labelledby': normMenuItemId,
          }}
          anchorEl={menuItemRef.current}
          open={isOpen}
          onClose={close}
          anchorOrigin={
            MenuProps?.anchorOrigin ?? {
              vertical: 'top',
              horizontal: shouldFlipHorizontal('right'),
            }
          }
          transformOrigin={
            MenuProps?.transformOrigin ?? {
              vertical: 'top',
              horizontal: shouldFlipHorizontal('left'),
            }
          }
        >
          {children}
        </Menu>
      )}
    </MenuItem>
  );
};
