import { gql } from '@apollo/client';
import {
  CollectionFragmentJuiceboxCollectionSearchItemFragmentDoc,
  PostFragmentJuiceboxAttachmentSearchItemFragmentDoc,
  PostFragmentJuiceboxLinkSearchItemFragmentDoc,
  PostFragmentJuiceboxNoteSearchItemFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';

export enum ShowMoreType {
  RecentOpened = 'RecentOpened',
  RecentSearch = 'RecentSearch',
  Notes = 'Notes',
  Collections = 'Collections',
  Links = 'Links',
  Attachments = 'Attachments',
}

gql`
  query GetFiltersSearchForJuiceboxFilterSuggestionView(
    $data: FilterSearchInput!
  ) {
    filtersSearch(data: $data) {
      collections {
        id
        name
      }
      createdBy {
        ...UserFragmentAvatarGroup
      }
      sharedBy {
        ...UserFragmentAvatarGroup
      }
      type
      reaction
      dateModified
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

gql`
  query GetRecentlyOpenedQueriesForJuiceboxFilterSuggestionView(
    $searchableEntityTypes: [SearchableEntityType!]!
    $take: Int
    $postCount: Int
    $previewableFirst: Boolean
  ) {
    getSearchesClicked(
      searchableEntityTypes: $searchableEntityTypes
      take: $take
    ) {
      data {
        collection {
          ...CollectionFragmentJuiceboxCollectionSearchItem
          posts(postCount: $postCount, previewableFirst: $previewableFirst) {
            ...PostFragmentPostPreview
          }
        }
        post {
          ...PostFragmentJuiceboxNoteSearchItem
          ...PostFragmentJuiceboxAttachmentSearchItem
          ...PostFragmentJuiceboxLinkSearchItem
        }
      }
    }
  }
  ${CollectionFragmentJuiceboxCollectionSearchItemFragmentDoc}
  ${PostFragmentJuiceboxNoteSearchItemFragmentDoc}
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentJuiceboxAttachmentSearchItemFragmentDoc}
  ${PostFragmentJuiceboxLinkSearchItemFragmentDoc}
`;

gql`
  query GetRecentSearchQueriesForJuiceboxFilterSuggestionView($take: Int) {
    getRecentSearchQueries(take: $take) {
      data {
        query
      }
    }
  }
`;

gql`
  query GetUniversalSearchForJuiceboxFilterSuggestionView(
    $data: UniversalSearchInput!
    $postCount: Int
    $previewableFirst: Boolean
  ) {
    universalSearch(data: $data) {
      noteSearchResult {
        hits {
          item {
            ...PostFragmentJuiceboxNoteSearchItem
          }
        }
        totalCount
      }
      linkSearchResult {
        hits {
          item {
            ...PostFragmentJuiceboxLinkSearchItem
          }
        }
        totalCount
      }
      collectionSearchResult {
        totalCount
        hits {
          item {
            ...CollectionFragmentJuiceboxCollectionSearchItem
            posts(postCount: $postCount, previewableFirst: $previewableFirst) {
              ...PostFragmentPostPreview
            }
          }
        }
      }
      attachmentSearchResult {
        hits {
          item {
            ...PostFragmentJuiceboxAttachmentSearchItem
          }
        }
        totalCount
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PostFragmentJuiceboxNoteSearchItemFragmentDoc}
  ${PostFragmentJuiceboxLinkSearchItemFragmentDoc}
  ${CollectionFragmentJuiceboxCollectionSearchItemFragmentDoc}
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentJuiceboxAttachmentSearchItemFragmentDoc}
`;

gql`
  mutation CreateSearchHitForJuiceboxFilterSuggestionView(
    $data: SearchHitClickInput!
  ) {
    createSearchClicked(data: $data) {
      query
    }
  }
`;
