import { PARAMS_REGEX } from '../../form/RichTextEditor/regex';

type LinkMeta = {
  embedLink: string;
  thumbNail: string;
};

export default class ResourceLinkParser {
  static knownLinkMap = {
    loom: {
      regex: /loom\.com\/share\//g,
    },
    youtube: {
      regex: /youtube\.com\/|youtu\.be\//g,
    },
    vimeo: {
      regex: /vimeo\.com\//g,
    },
    figma: {
      regex: /figma\.com\/file\//g,
    },
  };

  static isFavicon = (url: string) => {
    return url.includes(
      'https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON',
    );
  };

  static getUrlFavicon = (url: string, size = 64) => {
    return `https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=${size}&url=${url}`;
  };

  static knownLinkType(
    link: string,
  ): keyof typeof ResourceLinkParser.knownLinkMap | undefined {
    let app: keyof typeof ResourceLinkParser.knownLinkMap;
    for (app in ResourceLinkParser.knownLinkMap) {
      const integration = ResourceLinkParser.knownLinkMap[app];
      const match = link.match(integration.regex);
      if (match) {
        return app;
      }
    }
  }

  static getLinkMeta(link: string, iconSize = 64): LinkMeta {
    const knownType = ResourceLinkParser.knownLinkType(link);

    if (knownType) {
      const parser = ResourceLinkParser[knownType];
      return parser(link);
    }

    return {
      embedLink: link,
      thumbNail: ResourceLinkParser.getUrlFavicon(link, iconSize),
    };
  }

  static loom(link: string): LinkMeta {
    const videoIDRegex = /\/share\/([a-zA-Z0-9]+)/;
    const match = link.match(videoIDRegex);

    const id = !match || match.length < 2 ? null : match[1];

    return {
      embedLink: `https://www.loom.com/embed/${id}`,
      thumbNail: `https://cdn.loom.com/sessions/thumbnails/${id}-00001.jpg`,
    };
  }

  static youtube(link: string): LinkMeta {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = link.match(regExp);
    let id = '';
    if (match && match[7].length === 11) {
      id = match[7];
    }

    return {
      embedLink: `https://www.youtube.com/embed/${id}`,
      thumbNail: `https://img.youtube.com/vi/${id}/mqdefault.jpg`,
    };
  }

  static vimeo(link: string): LinkMeta {
    const match = link.match(/https:\/\/vimeo\.com\/(\d+)/);
    const id = match && match[1] ? match[1] : null;

    return {
      embedLink: `https://player.vimeo.com/video/${id}`,
      thumbNail: `https://vumbnail.com/${id}.jpg`,
    };
  }

  static figma(link: string): LinkMeta {
    const idMatch = link.match(
      /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/,
    );

    let id = '';

    if (idMatch) {
      id = idMatch[3];
    }

    const params: Record<string, string> = {};
    let match;
    while ((match = PARAMS_REGEX.exec(link))) {
      params[match[1]] = match[2];
    }
    const nodeId = params['node-id'];

    return {
      embedLink: `https://www.figma.com/embed?embed_host=astra&url=https://www.figma.com/file/${id}${
        nodeId ? `&node-id=${nodeId}` : ''
      }`,
      thumbNail: ResourceLinkParser.getUrlFavicon(link),
    };
  }
}
