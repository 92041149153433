import { useImmer } from 'use-immer';

export type SelectMode = 'selectedAll' | 'deselectedAll';

export type NestedCollectionsPermissionType = {
  collectionId: string;
  parentCollectionId?: string;
  mode: SelectMode;
  excludedChildCollectionIds: string[];
  excludedPostIds: string[];
  nestedEntities: NestedCollectionsPermissionType[];
};

export const useCollectionPermissionCollectionTreeSelectionState = () => {
  const [permissionData, setPermissionData] = useImmer<
    NestedCollectionsPermissionType[]
  >([]);

  const updatePermissionData = (
    data: Omit<NestedCollectionsPermissionType, 'nestedEntities'>,
  ) => {
    // Recursive update function for modifying the draft
    // so we can maintain references across multiple levels of nested entities
    const updateDraftRecursively = (
      newValue: any,
      obj: any,
      keys: (number | string)[],
    ) => {
      if (keys.length === 1) {
        obj[keys[0]] = newValue;
        return;
      }

      updateDraftRecursively(newValue, obj[keys[0]], keys.slice(1));
    };

    setPermissionData((draft) => {
      // Recursive update
      // 1. If collectionId matches, overwrite
      // 2. If incoming data object is a child of the current item, update the child
      // 3. Else, recursively find & update the children
      const traverse = (
        item: NestedCollectionsPermissionType,
        keys: (number | string)[],
      ) => {
        if (item.collectionId === data.collectionId) {
          updateDraftRecursively({ ...item, ...data }, draft, keys);

          return;
        }

        if (item.collectionId === data.parentCollectionId) {
          const nestedItem = item.nestedEntities.find(
            (nestedEntity) => nestedEntity.collectionId === data.collectionId,
          );

          if (nestedItem) {
            updateDraftRecursively({ ...nestedItem, ...data }, draft, [
              ...keys,
              'nestedEntities',
              item.nestedEntities.indexOf(nestedItem),
            ]);
          } else {
            item.nestedEntities.push({ ...data, nestedEntities: [] });
          }

          return;
        }

        item.nestedEntities.forEach((nestedItem, index) => {
          traverse(nestedItem, [...keys, 'nestedEntities', index]);
        });
      };

      // If incoming data has no parent, add it to the root if it doesn't exist
      if (
        !data.parentCollectionId &&
        !draft.find((item) => item.collectionId === data.collectionId)
      ) {
        draft.push({ ...data, nestedEntities: [] });
      } else {
        draft.forEach((item, index) => {
          traverse(item, [index]);
        });
      }
    });
  };

  return {
    updatePermissionData,
    permissionData,
  };
};
