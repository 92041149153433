import { Box, Button, Grid, SxProps, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { Tooltip } from 'components/common/Tooltip';
import { TooltipForTruncatedText } from 'components/common/TooltipForTruncatedText';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearEye } from 'components/icons/components/linear/IconLinearEye';
import { IconLinearEyeSlash } from 'components/icons/components/linear/IconLinearEyeSlash';
import { theme } from 'styles/theme';

type HashtagType = [string, string]; // hashtag & engagement rate

type Props = {
  title: string;
  renderFilter: () => React.ReactNode;
  hashtagValues: HashtagType[];
  disabledHashtagValues: string[];
  onHashtagClick: (hashtag: string) => void;
  onHideHashtag: (hashtag: string) => void;
  onUnhideHashtag: (hashtag: string) => void;
  showHiddenHashtags?: boolean;
  componentsProps?: {
    sx?: SxProps;
  };
};

export const SLATopHashtags = ({
  title,
  renderFilter,
  hashtagValues,
  disabledHashtagValues,
  onHashtagClick,
  onHideHashtag,
  onUnhideHashtag,
  showHiddenHashtags = false,
  componentsProps,
}: Props) => {
  // Function to distribute data into columns
  const distributeDataIntoColumns = (
    data: typeof hashtagValues,
    columns: number,
  ) => {
    const columnData: (typeof hashtagValues)[] = Array.from(
      { length: columns },
      () => [],
    );
    data.forEach((item, index) => {
      columnData[index % columns].push(item);
    });
    return columnData;
  };

  // Assuming 2 columns for the grid
  const columns = 2;
  const columnData = distributeDataIntoColumns(hashtagValues, columns);
  const hasHashtagData = hashtagValues.length > 0;

  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        ...componentsProps?.sx,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          variant="headline-sm"
          color={theme.colors?.utility[700]}
          pb={1}
          display="flex"
          alignItems="center"
          gap={2}
        >
          Top hashtags
          <Tooltip title="The ranking of hashtags is based on the engagement of the posts that the hashtag was used on ">
            <Box display="flex" alignItems="center">
              <IconBoldInfoCircle size={16} />
            </Box>
          </Tooltip>
        </Typography>

        {renderFilter()}
      </Box>

      <Grid container spacing={2}>
        {hasHashtagData && (
          <>
            {columnData.map((column, columnIndex) => (
              <Grid item xs={12} sm={6} key={columnIndex}>
                {column.map(([hashtag, percentage], index) => (
                  <RightClickContextMenuWrapper
                    renderMenu={(props) => (
                      <ContextMenu
                        options={[
                          ...(!disabledHashtagValues.includes(hashtag)
                            ? [
                                {
                                  onClick: () => onHideHashtag(hashtag),
                                  renderOption: () => (
                                    <Button
                                      sx={{
                                        color: theme.colors?.utility['pink-3'],
                                        borderRadius: theme.spacing(2),
                                        display: 'flex',
                                        gap: 2,
                                        p: theme.spacing(1, 2),
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          backgroundColor:
                                            'rgba(128, 5, 50, 0.08)',
                                          p: theme.spacing(1),
                                          borderRadius: theme.spacing(1),
                                        }}
                                      >
                                        <IconLinearEyeSlash size={16} />
                                      </Box>
                                      <Typography
                                        fontWeight={500}
                                        variant="body-xl"
                                      >
                                        Hide hashtag
                                      </Typography>
                                    </Button>
                                  ),
                                },
                              ]
                            : []),
                          ...(disabledHashtagValues.includes(hashtag)
                            ? [
                                {
                                  onClick: () => onUnhideHashtag(hashtag),
                                  renderOption: () => (
                                    <Button
                                      sx={{
                                        color: theme.colors?.primary.black,
                                        borderRadius: theme.spacing(2),
                                        display: 'flex',
                                        gap: 2,
                                        p: theme.spacing(1, 2),
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          backgroundColor:
                                            theme.colors?.utility[275],
                                          p: theme.spacing(1),
                                          borderRadius: theme.spacing(1),
                                        }}
                                      >
                                        <IconLinearEye size={16} />
                                      </Box>
                                      <Typography
                                        fontWeight={500}
                                        variant="body-xl"
                                      >
                                        Unhide hashtag
                                      </Typography>
                                    </Button>
                                  ),
                                },
                              ]
                            : []),
                        ]}
                        renderButton={false}
                        {...props}
                      />
                    )}
                  >
                    <Typography
                      variant="headline-sm"
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      key={index}
                      my={3}
                      sx={{
                        cursor: 'pointer',
                      }}
                      color={
                        disabledHashtagValues.includes(hashtag)
                          ? theme.colors?.utility[700]
                          : theme.colors?.primary.black
                      }
                      onClick={() => onHashtagClick(hashtag)}
                    >
                      <TooltipForTruncatedText title={hashtag}>
                        <Box
                          flex={1}
                          component="span"
                          display="flex"
                          alignItems="center"
                          gap={2}
                          pr={2}
                          color={
                            disabledHashtagValues.includes(hashtag)
                              ? theme.colors?.utility[700]
                              : theme.colors?.primary.black
                          }
                        >
                          {hashtag}
                          {disabledHashtagValues.includes(hashtag) && (
                            <IconLinearEyeSlash size={16} />
                          )}
                        </Box>
                      </TooltipForTruncatedText>

                      <Box
                        flex={1}
                        component="span"
                        color={theme.colors?.utility[800]}
                        textAlign="right"
                        mr={4}
                      >
                        {percentage}
                      </Box>
                    </Typography>
                  </RightClickContextMenuWrapper>
                ))}
              </Grid>
            ))}
          </>
        )}
        {!hasHashtagData && (
          <Typography
            ml={2}
            mt={4}
            minHeight={140}
            variant="body-lg"
            color={theme.colors?.utility[700]}
            display="flex"
            justifyContent="center"
            width="100%"
          >
            No hashtags found
          </Typography>
        )}
      </Grid>
    </Box>
  );
};
