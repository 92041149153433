import { Box, Skeleton } from '@mui/material';

export const JuiceboxSearchItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        px: 3,
      }}
    >
      <Skeleton variant="rounded" height={24} width={24} animation={false} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Skeleton variant="text" width={200} animation={false} />
        <Skeleton variant="text" width={100} animation={false} />
      </Box>
    </Box>
  );
};
