import { createContext } from '@dwarvesf/react-utils';
import { Dispatch, SetStateAction, useState } from 'react';

interface ContextValues {
  setCanAnnotate: Dispatch<SetStateAction<boolean>>;
  canAnnotate: boolean;
}

const [Provider, useAnnotationManager] = createContext<ContextValues>({
  name: 'annotation-manager',
});

const AnnotationManagerProvider: React.FC<{
  children: React.ReactNode;
  canAnnotateDefault?: boolean;
}> = ({ children, canAnnotateDefault = false }) => {
  const [canAnnotate, setCanAnnotate] = useState(canAnnotateDefault);

  return (
    <Provider
      value={{
        canAnnotate,
        setCanAnnotate,
      }}
    >
      {children}
    </Provider>
  );
};

export { AnnotationManagerProvider, useAnnotationManager };
