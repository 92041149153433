import { gql } from '@apollo/client';
import { SxProps } from '@mui/material';
import { USER_PROFILE_FRAGMENT_ASSIGNEE_LIST } from 'contexts/commands/hooks/useTaskCommands/components/CreateNewTask/components/AssigneeList/types';
import { TASK_FRAGMENT_TASK_SUBTASK_SECTION } from './sections';

export const TASK_FRAGMENT_TASK_DETAIL_VIEW = gql`
  fragment TaskFragmentTaskDetailView on TaskModel {
    id
    name
    description
    startDate
    endDate
    status
    priority
    ...TaskFragmentTaskSubtaskSection
    taskMembers {
      ...UserProfileFragmentAssigneeList
    }
    subtasks {
      ...TaskFragmentTaskSubtaskSection
    }
    parentTaskId
    isPreview
  }
  ${USER_PROFILE_FRAGMENT_ASSIGNEE_LIST}
  ${TASK_FRAGMENT_TASK_SUBTASK_SECTION}
`;

// eslint-disable-next-line
gql`
  query GetTaskForTaskDetailView($id: String!) {
    task(id: $id) {
      ...TaskFragmentTaskDetailView
    }
  }
  ${TASK_FRAGMENT_TASK_DETAIL_VIEW}
`;

// eslint-disable-next-line
gql`
  mutation ViewEntityForTaskDetailView($data: ViewEntityInput!) {
    viewEntity(data: $data) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetTaskWithTaskMembersForTaskDetailView($id: String!) {
    task(id: $id) {
      id
      taskMembers {
        ...UserProfileFragmentAssigneeList
      }
    }
  }
  ${USER_PROFILE_FRAGMENT_ASSIGNEE_LIST}
`;

export interface TaskDetailViewProps {
  taskId: string;
  onAfterTaskDelete?: () => void;
  sx?: SxProps;
  /**
   * Custom callback to allow control over the back button.
   * Please note that this ONLY RUNS when the location stack is empty.
   * If not provided, the default behavior is to go back to the previous page `navigate(-1)`.
   * See `NestedTaskNavigationContext`.
   */
  onGoBackOutOfStack?: () => void;
  hideLocationNavigator?: boolean;
}
