import { wrap } from 'comlink';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CommandHandler } from 'contexts/commands/types';
import { PostDownloadWorker } from 'features/post';
import FileSaver from 'file-saver';
import { PostFragmentPostCommandFragment } from 'graphql/generated';
import { useEffect } from 'react';
import { StatusPopup } from './StatusPopup';

const worker = new Worker(
  new URL('../../../../../features/post/workers/download.ts', import.meta.url),
);
const wrappedWorker = wrap<PostDownloadWorker>(worker);

const downloadPosts = async (
  posts: Pick<PostFragmentPostCommandFragment, 'id' | 'urlMetadata'>[],
) => {
  const response = await wrappedWorker.download(posts);
  const { blob, errors, canDownload } = response;

  if (errors.length > 0) {
    console.log(errors);
  }

  if (canDownload) {
    FileSaver.saveAs(blob, 'download.zip');

    return true;
  }

  return false;
};

const downloadPost = async (
  post: Pick<PostFragmentPostCommandFragment, 'id' | 'urlMetadata'>,
) => {
  if (!post.urlMetadata.length) {
    return false;
  }

  const response = await wrappedWorker.downloadSinglePost({
    id: post.id,
    urlMetadata: post.urlMetadata[0],
  });
  const { blob, error, canDownload, name } = response;

  if (error) {
    console.log(error);
  }

  if (canDownload && blob) {
    FileSaver.saveAs(blob, name);

    return true;
  }

  return false;
};

export type PostDownloadHandlerContext = {
  posts?: Pick<PostFragmentPostCommandFragment, 'id' | 'urlMetadata'>[];
  canDownloadSinglePost?: boolean;
};

export const PostDownloadHandler: CommandHandler<COMMAND_TYPE.POST_DOWNLOAD> = (
  props,
) => {
  const {
    commandId,
    context: { posts = [], canDownloadSinglePost = false } = {},
  } = props;

  const { updateActiveCommand, getActiveCommandById } = useCommandContext();
  const activeCommand = getActiveCommandById(commandId);

  // Transition to loading status immediately on mount, as we'll be waiting for
  // all the posts to be processed & compressed
  useEffect(() => {
    if (activeCommand?.status === 'triggered') {
      updateActiveCommand(commandId, {
        status: 'loading',
      });

      if (canDownloadSinglePost && posts.length === 1) {
        downloadPost(posts[0]).then((ok) => {
          if (ok) {
            updateActiveCommand(commandId, {
              status: 'completed',
            });
          } else {
            updateActiveCommand(commandId, {
              status: 'error',
            });
          }
        });
      } else {
        downloadPosts(posts).then((ok) => {
          if (ok) {
            updateActiveCommand(commandId, {
              status: 'completed',
            });
          } else {
            updateActiveCommand(commandId, {
              status: 'error',
            });
          }
        });
      }
    }
  }, []); // eslint-disable-line

  return (
    <StatusPopup
      commandId={commandId}
      messages={{
        loading: '...Compressing files 🗳️',
        completed: 'Files are ready for download! 📥',
        error:
          '⚠️ Something went wrong or no downloadable posts. Please try again!',
      }}
    />
  );
};
