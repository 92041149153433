import { Box, SxProps } from '@mui/material';
import React from 'react';
import { theme } from 'styles/theme/theme';

type ProgressBarProps = {
  variant?: 'percentage' | 'number';
  completed: number;
  total: number;
  filledColor?: string;
  bgColor?: string;
  sx?: SxProps;
};

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    variant = 'percentage',
    completed,
    total,
    filledColor = theme.colors?.primary.maroon,
    bgColor = theme.colors?.utility[300],
    sx,
  } = props;

  const render = () => {
    switch (variant) {
      case 'percentage': {
        return (
          <Box
            sx={{
              borderRadius: 1,
              overflow: 'hidden',
              height: theme.spacing(2),
              width: '100%',
              background: bgColor,
              ...sx,
            }}
          >
            <Box
              sx={{
                borderRadius: 1,
                overflow: 'hidden',
                height: '100%',
                width: `${(completed / total) * 100}%`,
                background: filledColor,
              }}
            />
          </Box>
        );
      }
      case 'number': {
        return (
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(1),
              height: theme.spacing(2),
              ...sx,
            }}
          >
            {Array(total)
              .fill(0)
              .map((t, index) => (
                <Box
                  key={index}
                  sx={{
                    borderRadius: 1,
                    overflow: 'hidden',
                    height: '100%',
                    width: `${100 / total}%`,
                    background: index < completed ? filledColor : bgColor,
                  }}
                />
              ))}
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  return render();
};
