import { NOTE_COLORS, PostThemeColor } from 'features/note/constants';

export const getHexColorFromCssValue = (
  cssValue?: string,
): string | undefined => {
  const bodyStyle = getComputedStyle(document.body);
  const match = cssValue?.match(/var\((--[^)]+)\)/);
  if (match) {
    const varName = match[1];
    const colorHex = bodyStyle.getPropertyValue(varName);
    return colorHex;
  }
  return cssValue;
};

export const getNoteColorFromColor = (
  color: string,
): PostThemeColor | undefined => {
  const colorHex = color?.includes('var')
    ? getHexColorFromCssValue(color)
    : color;
  return NOTE_COLORS.find((c) => {
    const themeHex = c.color?.includes('var')
      ? getHexColorFromCssValue(c.color)
      : c.color;
    return themeHex?.toUpperCase() === colorHex?.toUpperCase();
  });
};

export const getRandomNoteColor = (options?: {
  excludeColors?: [string];
}): PostThemeColor => {
  const { excludeColors } = options || {};

  let filteredColorPalette: PostThemeColor[];

  if (excludeColors) {
    filteredColorPalette = NOTE_COLORS.filter(
      (palette) => !excludeColors.includes(palette.color!),
    );
  } else {
    filteredColorPalette = NOTE_COLORS;
  }

  const min = 0;
  const max = filteredColorPalette.length;

  const random = Math.floor(Math.random() * (max - min) + min);

  return filteredColorPalette[random];
};
