import { Box, Button, Dialog, DialogProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import React, { ReactNode, useEffect, useState } from 'react';
import { theme } from 'styles/theme';

export type PermissionsDialogViewProps = {
  customBtn?: ReactNode;
  disabled?: boolean;
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  // you can pass in different permission views from different feature
  renderDialogBody: (isOpen: boolean) => ReactNode;
  hasPendingChanges?: boolean;

  // this is used when confirmation dialog is opend (for onConfirm method of dialog)
  onPressSaveChanges?: VoidFunction;

  componentsProps?: { dialog?: Pick<DialogProps, 'PaperProps'> };
};

export const PermissionDialogLayout = (props: PermissionsDialogViewProps) => {
  const {
    customBtn,
    disabled,
    isOpen,
    onClose,
    onOpen,
    renderDialogBody,
    hasPendingChanges,
    onPressSaveChanges,
    componentsProps = {},
  } = props;

  const [isMainModalVisible, setIsMainModalVisible] = useState(true);

  useEffect(() => {
    setIsMainModalVisible(true);
  }, [isOpen]);

  const { dialog: confirmModal, onOpen: openConfirmModal } =
    useConfirmationDialog({
      sx: {
        '& .MuiButton-primary': {
          borderRadius: theme.spacing(2),
          border: 'none',
          backgroundColor: `${theme.colors?.primary.black} !important`,
        },
        '& .MuiButton-primary:hover': {
          border: 'none',
          backgroundColor: `${theme.colors?.primary.black} !important`,
        },
      },
    });

  const { disableCommands, enableCommands } = useCommandContext();

  const showDialog = () => {
    onOpen();
  };

  const hideDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (hasPendingChanges) {
      setIsMainModalVisible(false);
      openConfirmModal({
        title: 'Your changes were not saved. Save before leaving?',
        confirmText: 'Save changes',
        cancelText: 'Continue without saving',
        onConfirm: () => {
          onPressSaveChanges?.();
          onClose();
        },
        onCancel: () => onClose(),
      });
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (disabled) {
      disableCommands([
        COMMAND_TYPE.POST_PASTE_A_LINK,
        COMMAND_TYPE.POST_UPLOAD_FILES,
        COMMAND_TYPE.POST_WRITE_A_NOTE,
      ]);
    } else {
      enableCommands([
        COMMAND_TYPE.POST_PASTE_A_LINK,
        COMMAND_TYPE.POST_UPLOAD_FILES,
        COMMAND_TYPE.POST_WRITE_A_NOTE,
      ]);
    }

    return () => {
      enableCommands([
        COMMAND_TYPE.POST_PASTE_A_LINK,
        COMMAND_TYPE.POST_UPLOAD_FILES,
        COMMAND_TYPE.POST_WRITE_A_NOTE,
      ]);
    };
  }, [disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {customBtn ? (
        <Box onClick={showDialog} display="flex">
          {customBtn}
        </Box>
      ) : (
        <Tooltip
          title={
            disabled ? (
              <Box display="flex" gap={1} alignItems="center">
                <IconBoldInfoCircle size={16} />
                <Typography
                  variant="body-lg"
                  fontWeight={500}
                  color={theme.colors?.primary.parchment}
                >
                  Only users with edit access can share
                </Typography>
              </Box>
            ) : (
              'Share'
            )
          }
        >
          <Box display="flex">
            <Button
              disabled={disabled}
              onClick={showDialog}
              variant="primary"
              sx={{ borderRadius: theme.spacing(2) }}
            >
              Share
            </Button>
          </Box>
        </Tooltip>
      )}

      <Dialog
        open={isOpen}
        onClose={hideDialog}
        PaperProps={{
          ...componentsProps?.dialog?.PaperProps,
          sx: {
            width: 500,
            height: 570,
            borderRadius: theme.spacing(6),
            display: isMainModalVisible ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            p: 0,
            boxShadow: 'none',
            backgroundColor: 'transparent',
            border: 'none',
            overflow: 'hidden',
            ...componentsProps?.dialog?.PaperProps?.sx,
          },
          // Stop event propagation in case this component is rendered
          // from within another component that handles click (e.g. link)
          onClick: (e) => e.stopPropagation(),
        }}
      >
        {renderDialogBody(isOpen)}
      </Dialog>
      {confirmModal}
    </>
  );
};
