import {
  Box,
  LinearProgress,
  Skeleton,
  SxProps,
  Typography,
} from '@mui/material';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconBoldVolumeHigh } from 'components/icons/components/bold/IconBoldVolumeHigh';
import { IconBoldVolumeSlash } from 'components/icons/components/bold/IconBoldVolumeSlash';
import { IconLinearMaximize4 } from 'components/icons/components/linear/IconLinearMaximize4';
import { IconLinearMinimize } from 'components/icons/components/linear/IconLinearMinimize';
import { IconOutlineBackward5Seconds } from 'components/icons/components/outline/IconOutlineBackward5Seconds';
import { IconOutlineForward5Seconds } from 'components/icons/components/outline/IconOutlineForward5Seconds';
import { useEffect, useRef, useState } from 'react';
import { FullScreen } from 'react-full-screen';
import { theme } from 'styles/theme';
import { PlaybackSpeedSelect } from '../PlaybackSpeedSelect';
import { StyledIconButton } from '../styles';
import { useVideoControls } from './useVideoControls';

type Props = {
  videoUrl?: string;
  videoSx?: SxProps;
  videoControlsSx?: SxProps;
};

export const useVideoPlayer = ({
  videoUrl,
  videoSx,
  videoControlsSx,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const {
    onSeekTime,
    handleFullScreen,
    onFullScreen,
    onSeekBackward,
    onSeekForward,
    onSeekbarClicked,
    setDuration,
    isFullScreen,
    setIsFullScreen,
    duration,
  } = useVideoControls(videoRef);

  // current time of video
  const [currentTime, setCurrentTime] = useState(0);
  // update seekbar value according to current time
  const [seekbarValue, setSeekbarValue] = useState(0);
  // set default normal playback speed
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);

  const [renderVideoTimeMarkers, setRenderVideoTimeMarkers] = useState<
    {
      timeInSeconds: number;
      renderUI: () => React.ReactNode;
    }[]
  >([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!videoRef || !videoRef.current) return;

    const video = videoRef.current;
    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime);
      setSeekbarValue((video.currentTime / video.duration) * 100);
    };
    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
    // eslint-disable-next-line
  }, [videoRef.current, currentTime, duration]);

  useEffect(() => {
    const handleResize = () => {
      if (videoRef.current) {
        setVideoHeight(videoRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const videoUI = (
    <Box position="relative">
      <FullScreen
        handle={handleFullScreen}
        onChange={(isFullScreen) => {
          if (!isFullScreen) {
            setIsFullScreen(false);
          }
        }}
        className="fullscreen"
      >
        <Box
          sx={{
            visibility: loading ? 'visible' : 'hidden',
            position: 'absolute',
            inset: 0,
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: 4,
            }}
          />
        </Box>
        <Box
          ref={videoRef}
          sx={{
            visibility: loading ? 'hidden' : 'visible',
            width: '100%',
            height: '100%',
            borderRadius: 4,
            ...videoSx,
          }}
          onLoadedMetadata={() => {
            if (videoRef.current) {
              setDuration(videoRef.current.duration);
            }
          }}
          onLoadedData={() => {
            setLoading(false);
            if (videoRef.current) {
              setVideoHeight(videoRef.current.offsetHeight);
            }
          }}
          onLoadStart={() => {
            setLoading(true);
          }}
          component="video"
          src={videoUrl}
          onError={(e) => console.error(e)}
          onClick={() => {
            if (videoRef.current) {
              if (videoRef.current.paused) {
                videoRef.current.play();
              } else {
                videoRef.current.pause();
              }
            }
          }}
        />
        <VideoControls
          videoRef={videoRef}
          duration={duration}
          currentTime={currentTime}
          seekbarValue={seekbarValue}
          onSeekbarClicked={onSeekbarClicked}
          onSeekBackward={onSeekBackward}
          onSeekForward={onSeekForward}
          playVideo={playVideo}
          playbackSpeed={playbackSpeed}
          setPlaybackSpeed={setPlaybackSpeed}
          isFullScreen={isFullScreen}
          onFullScreen={onFullScreen}
          renderVideoTimeMarkers={renderVideoTimeMarkers}
          videoControlsSx={videoControlsSx}
        />
      </FullScreen>
    </Box>
  );

  return {
    videoUI,
    videoHeight,
    currentTime,
    controls: {
      seekTime: onSeekTime,
    },
    UI: { setRenderVideoTimeMarkers },
  };
};

const VideoControls = (props: {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  duration: number;
  currentTime: number;
  seekbarValue: number;
  onSeekbarClicked: (e: React.MouseEvent<HTMLDivElement>) => void;
  onSeekBackward: () => void;
  onSeekForward: () => void;
  playVideo: () => void;
  playbackSpeed: number;
  setPlaybackSpeed: React.Dispatch<React.SetStateAction<number>>;
  isFullScreen: boolean;
  onFullScreen: () => void;
  renderVideoTimeMarkers: {
    timeInSeconds: number;
    renderUI: () => React.ReactNode;
  }[];
  videoControlsSx?: SxProps;
}) => {
  const {
    videoRef,
    duration,
    currentTime,
    seekbarValue,
    onSeekbarClicked,
    onSeekBackward,
    onSeekForward,
    playVideo,
    playbackSpeed,
    setPlaybackSpeed,
    isFullScreen,
    onFullScreen,
    renderVideoTimeMarkers,
    videoControlsSx,
  } = props;

  const [hidden, setHidden] = useState(false);
  const fadeOutTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const onMouseMove = () => {
      if (fadeOutTimeoutRef.current) {
        clearTimeout(fadeOutTimeoutRef.current);
      }

      fadeOutTimeoutRef.current = setTimeout(() => {
        setHidden(true);
      }, 3000);

      setHidden(false);
    };

    const onMouseLeave = () => {
      fadeOutTimeoutRef.current = setTimeout(() => {
        setHidden(true);
      }, 3000);
    };

    videoRef.current?.addEventListener('mousemove', onMouseMove);
    videoRef.current?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      if (fadeOutTimeoutRef.current) {
        clearTimeout(fadeOutTimeoutRef.current);
      }

      videoRef.current?.removeEventListener('mousemove', onMouseMove);
      videoRef.current?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, []); // eslint-disable-line

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: theme.spacing(2),
        width: 'calc(100% - 20px)',
        borderRadius: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        height: theme.spacing(10),
        backdropFilter: `blur(2px)`,
        alignItems: 'center',
        display: 'flex',
        padding: theme.spacing(0, 2),
        transition: 'opacity 0.3s',
        opacity: hidden ? 0 : 1,
        ...videoControlsSx,
      }}
    >
      {!!renderVideoTimeMarkers.length && !!duration && videoRef.current && (
        <Box
          sx={{
            height: '1px',
            position: 'absolute',
            top: theme.spacing(-1),
            width: 'inherit',
          }}
        >
          {renderVideoTimeMarkers.map((marker) => {
            return (
              <Box
                sx={{
                  position: 'absolute',
                  left: `${(marker.timeInSeconds / duration) * 100}%`,
                  bottom: 0,
                  transform: 'translateX(-50%)',
                }}
              >
                {marker.renderUI()}
              </Box>
            );
          })}
        </Box>
      )}
      <LinearProgress
        variant="determinate"
        value={seekbarValue || 0}
        onClick={onSeekbarClicked}
        sx={{
          cursor: 'pointer',
          borderRadius: 1,
          height: theme.spacing(1),
          position: 'absolute',
          top: 0,
          width: 'inherit',
          bgcolor: theme.colors?.utility[700],
          '& .MuiLinearProgress-bar': {
            bgcolor: theme.colors?.primary.white,
          },
        }}
        classes={{}}
      />
      <Box display="flex" gap={1.5} alignItems="center" justifyContent="center">
        <StyledIconButton
          disableRipple
          onClick={() =>
            videoRef.current?.paused ? playVideo() : videoRef.current?.pause()
          }
        >
          {videoRef.current?.paused ? (
            <IconBoldPlayCircle color={theme.colors?.primary.white} />
          ) : (
            <IconBoldPauseCircle color={theme.colors?.primary.white} />
          )}
        </StyledIconButton>
        <StyledIconButton disableRipple onClick={onSeekBackward}>
          <IconOutlineBackward5Seconds color={theme.colors?.primary.white} />
        </StyledIconButton>
        <StyledIconButton disableRipple onClick={onSeekForward}>
          <IconOutlineForward5Seconds color={theme.colors?.primary.white} />
        </StyledIconButton>
      </Box>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        color={theme.colors?.primary.white}
      >
        <Typography variant="body-sm" fontWeight={600}>
          {new Date(currentTime * 1000).toISOString().slice(14, 19)} /{' '}
          {new Date(duration * 1000).toISOString().slice(14, 19)}
        </Typography>
      </Box>
      <Box
        display="flex"
        gap={1.5}
        alignItems="center"
        justifyContent="flex-end"
      >
        <StyledIconButton
          disableRipple
          onClick={() => {
            if (videoRef.current) {
              videoRef.current.muted = !videoRef.current.muted;
            }
          }}
        >
          {videoRef.current?.muted ? (
            <IconBoldVolumeSlash color={theme.colors?.primary.white} />
          ) : (
            <IconBoldVolumeHigh color={theme.colors?.primary.white} />
          )}
        </StyledIconButton>
        <PlaybackSpeedSelect
          onPlaybackSpeedChanged={(speed) => {
            setPlaybackSpeed(speed);
            if (!videoRef || !videoRef.current) return;
            videoRef.current.playbackRate = speed as number;
          }}
          playbackSpeed={playbackSpeed}
        />
        <StyledIconButton disableRipple onClick={onFullScreen}>
          {isFullScreen ? (
            <IconLinearMinimize color={theme.colors?.primary.white} />
          ) : (
            <IconLinearMaximize4
              size={16}
              color={theme.colors?.primary.white}
            />
          )}
        </StyledIconButton>
      </Box>
    </Box>
  );
};
