import { Box, Skeleton, Typography } from '@mui/material';
import { IconBoldArrowUp } from 'components/icons/components/bold/IconBoldArrowUp';
import { IconBoldChart1 } from 'components/icons/components/bold/IconBoldChart1';
import { IconBoldEmojiHappy } from 'components/icons/components/bold/IconBoldEmojiHappy';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldTag } from 'components/icons/components/bold/IconBoldTag';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { IconBoldVideoPlay } from 'components/icons/components/bold/IconBoldVideoPlay';
import { theme } from 'styles/theme';

export const CompetitorSummarySkeleton = () => {
  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="headline-sm"
        color={theme.colors?.utility[700]}
        pb={1}
      >
        Summary
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldUser size={14} />
          Followers
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(16),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldHeart size={14} />
          Average likes
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(16),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldVideoPlay size={14} />
          Average video views
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(16),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldChart1 size={14} />
          Engagement rate
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(16),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldArrowUp size={14} />
          Number of posts/week
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(16),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldTag size={14} />
          Brand mentions/week
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(16),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <IconBoldEmojiHappy size={14} />
          Sentiment
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(16),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>
    </Box>
  );
};
