import { gql } from '@apollo/client';
import {
  useAddPostToCollectionForPostCollectionListMutation,
  useRemovePostFromCollectionForPostCollectionListMutation,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  mutation AddPostToCollectionForPostCollectionList(
    $data: AddPostToCollectionInput!
  ) {
    addPostToCollection(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation RemovePostFromCollectionForPostCollectionList(
    $data: RemovePostFromCollectionInput!
  ) {
    removePostFromCollection(data: $data) {
      success
      message
    }
  }
`;

export const usePostHandlers = () => {
  const [addPostToCollection] =
    useAddPostToCollectionForPostCollectionListMutation();
  const [removePostFromCollection] =
    useRemovePostFromCollectionForPostCollectionListMutation();

  const onTogglePostCollection = async (
    postId: string,
    collectionId: string,
    willAdd: boolean,
  ) => {
    if (willAdd) {
      await addPostToCollection({
        variables: {
          data: {
            postId,
            collectionId,
          },
        },
      });
    } else {
      await removePostFromCollection({
        variables: {
          data: {
            postId,
            collectionId,
          },
        },
      });
    }
  };

  return { onTogglePostCollection };
};
