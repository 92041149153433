import { Box, IconButton, IconButtonProps, TooltipProps } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import { Tooltip } from '../Tooltip';

interface Props extends IconButtonProps {
  tooltip?: NonNullable<ReactNode>;
  tooltipWhenDisabled?: NonNullable<ReactNode>;
  placement?: TooltipProps['placement'];
  tooltipPopperProps?: TooltipProps['PopperProps'];
}

export const IconButtonWithTooltip = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      tooltip = '',
      tooltipWhenDisabled = '',
      disabled,
      placement,
      tooltipPopperProps,
      ...rest
    } = props;

    return (
      <Tooltip
        title={disabled ? tooltipWhenDisabled : tooltip}
        disableHoverListener={disabled && !tooltipWhenDisabled}
        placement={placement}
        PopperProps={tooltipPopperProps}
      >
        <Box>
          <IconButton ref={ref} {...rest} disabled={disabled} />
        </Box>
      </Tooltip>
    );
  },
);
