import { gql } from '@apollo/client';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Avatar } from 'components/common/AvatarGroup';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import { PostPreview } from 'features/post';
import {
  getTaskStatusIcon,
  getTaskStatusText,
  taskPriorityBackgroundColor,
  taskPriorityText,
  taskPriorityTextColor,
} from 'features/task';
import {
  PostFragmentPostPreviewFragmentDoc,
  TaskFragmentTaskPeekingCardViewFragment,
  useGetTaskForTaskPeekingCardViewQuery,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { extractTextFromHtml } from 'utils/html';
import { getFullName } from 'utils/users';

const TASK_FRAGMENT_TASK_PEEKING_CARD_VIEW = gql`
  fragment TaskFragmentTaskPeekingCardView on TaskModel {
    id
    name
    startDate
    endDate
    status
    priority
    taskMembers {
      ...UserFragmentAvatarGroup
    }
    parentTask {
      id
      name
    }
    posts {
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetTaskForTaskPeekingCardView($taskId: String!) {
    task(id: $taskId) {
      ...TaskFragmentTaskPeekingCardView
    }
  }
  ${TASK_FRAGMENT_TASK_PEEKING_CARD_VIEW}
`;

const MAX_POST_TO_SHOW = 3;

export const TaskPeekingCardView = ({ taskId }: { taskId: string }) => {
  const { data } = useGetTaskForTaskPeekingCardViewQuery({
    variables: {
      taskId,
    },
  });
  const task = data?.task;

  if (!task) return null;

  const { Icon: StatusIcon, color: statusIconColor } = getTaskStatusIcon(
    task.status,
  );
  return (
    <Box
      sx={{
        padding: theme.spacing(6, 4, 6, 6),
      }}
    >
      <Typography
        variant="headline-md"
        fontSize={20}
        color={theme.colors?.primary.black}
      >
        {extractTextFromHtml(task.name)}
        {task.parentTask && (
          <Typography
            variant="headline-md"
            fontSize={20}
            color={theme.colors?.utility[700]}
          >
            /{extractTextFromHtml(task.parentTask.name)}
          </Typography>
        )}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 6 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3.5 }}>
          <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
            Status
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <StatusIcon color={statusIconColor} size={24} />
            <Typography variant="subhead-xl" color={theme.colors?.utility[900]}>
              {getTaskStatusText(task.status)}
            </Typography>
          </Box>
          <Divider sx={{ borderColor: theme.colors?.utility[500], mb: 4 }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3.5 }}>
          <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
            Timeline
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <IconBoldCalendar
              color={theme.colors?.utility['blue-4']}
              size={24}
            />
            <Typography variant="subhead-xl" color={theme.colors?.utility[900]}>
              {`${
                task.startDate ? moment(task.startDate).format('MMM DD') : ''
              }${task.startDate && task.endDate ? ' - ' : ''}${
                task.endDate ? moment(task.endDate).format('MMM DD') : ''
              }`}
            </Typography>
          </Box>
          <Divider sx={{ borderColor: theme.colors?.utility[500], mb: 4 }} />
        </Box>
        {task.priority && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3.5 }}>
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              Priority
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <Box
                sx={{
                  ...theme.typography['subhead-xl'],
                  color: taskPriorityTextColor[task.priority],
                  backgroundColor: taskPriorityBackgroundColor[task.priority],
                  width: 24,
                  height: 24,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 1,
                  padding: 1,
                }}
              >
                {taskPriorityText[task.priority]}
              </Box>
              <Typography
                variant="subhead-xl"
                color={theme.colors?.utility[900]}
              >
                {task.priority}
              </Typography>
            </Box>
            <Divider sx={{ borderColor: theme.colors?.utility[500], mb: 4 }} />
          </Box>
        )}
        {task.taskMembers.length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              Assignees
            </Typography>
            {task.taskMembers.map((member) => (
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar user={member} size={24} />
                <Typography
                  variant="subhead-xl"
                  color={theme.colors?.utility[900]}
                >
                  {getFullName(member)}
                </Typography>
              </Box>
            ))}
            <Divider sx={{ borderColor: theme.colors?.utility[500], mb: 4 }} />
          </Box>
        )}
        {[...(task?.posts || [])].length > 0 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3.5 }}>
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              Connected Media
            </Typography>
            <Box sx={{ display: 'flex', gap: 2.5 }}>
              {task.posts?.slice(0, MAX_POST_TO_SHOW)?.map((post) => (
                <Link to={PlotRoutes.task(task.id)}>
                  <PostPreview
                    post={post}
                    sx={{
                      height: 60,
                      width: 60,
                      borderRadius: 1.5,
                    }}
                  />
                </Link>
              ))}
              {(task.posts || [])?.length > MAX_POST_TO_SHOW && (
                <Tooltip
                  title={
                    <PostsPreviewTooltipView posts={task.posts} task={task} />
                  }
                  placement="right-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        borderRadius: 3,
                        background: theme.colors?.utility[300],
                        padding: 0,
                        boxShadow:
                          '0px 18px 88px -4px rgba(24, 39, 75, 0.14), 0px 8px 28px -6px rgba(24, 39, 75, 0.12)',
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: 60,
                      width: 60,
                      borderRadius: 1.5,
                      ...theme.typography['headline-md'],
                      color: theme.colors?.primary.black,
                      backgroundColor: theme.colors?.utility[500],
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    +{(task.posts || [])?.slice(MAX_POST_TO_SHOW).length}
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const PostsPreviewTooltipView = ({
  posts,
  task,
}: {
  task: Pick<TaskFragmentTaskPeekingCardViewFragment, 'id' | 'name'>;
  posts: TaskFragmentTaskPeekingCardViewFragment['posts'];
}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.colors?.utility[400],
          padding: theme.spacing(4),
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderTopRightRadius: 'inherit',
          borderTopLeftRadius: 'inherit',
        }}
      >
        <Typography variant="headline-xs" color={theme.colors?.primary.black}>
          Connected Media
        </Typography>
        <Typography
          variant="subhead-sm"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.colors?.utility[700],
          }}
        >
          {extractTextFromHtml(task.name)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '92px 92px 92px',
          gap: 1,
          maxHeight: 300,
          overflowY: 'auto',
          width: 'fit-content',
          padding: 1.5,
        }}
      >
        {posts?.map((post) => (
          <Link to={PlotRoutes.task(task.id)}>
            <PostPreview
              post={post}
              sx={{
                height: 140,
                width: 92,
                borderRadius: 3,
                boxShadow:
                  'box-shadow: 0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
              }}
            />
          </Link>
        ))}
      </Box>
    </>
  );
};
