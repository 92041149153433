import { Box, Typography } from '@mui/material';
import { IconLinearRefresh2 } from 'components/icons/components/linear/IconLinearRefresh2';
import { ForwardedRef, forwardRef } from 'react';
import { theme } from 'styles/theme';

interface Props {
  query: string;
  onItemSelect: (query: string) => void;
}

export const JuiceboxRecentSearchItem = forwardRef(
  ({ query, onItemSelect }: Props, ref: ForwardedRef<HTMLElement>) => {
    return (
      <Box
        ref={ref}
        tabIndex={0}
        display="flex"
        alignItems="center"
        gap={1.5}
        sx={{
          cursor: 'pointer',
          borderRadius: 3,
          padding: theme.spacing(2, 3),
          ':hover': {
            backgroundColor: theme.colors?.utility[400],
          },
          '&:focus': {
            backgroundColor: theme.colors?.utility[400],
            outline: 'none',
          },
        }}
        onClick={() => onItemSelect(query)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: 1,
          }}
        >
          <IconLinearRefresh2 size={16} color={theme.colors?.primary.black} />
        </Box>
        <Typography variant="headline-md" color={theme.colors?.utility[1100]}>
          {query}
        </Typography>
      </Box>
    );
  },
);
