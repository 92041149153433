import { Box } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import { theme } from 'styles/theme/theme';

interface Props {
  id: string;
  url: string;
}

export const IframelyComponent = ({ id, url }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.colors?.utility[400]}`,
        borderRadius: 3,
        overflow: 'hidden',
        bgcolor: 'white',
        position: 'relative',
        transition: '0.2s all',
        ':hover': {
          opacity: 0.6,
        },
      }}
    >
      <Iframely url={url} fullWidth urlMetadata={{ id, url }} />
    </Box>
  );
};
