import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { DndDragItem, DndDragItemTypes } from 'features/dnd';
import { useJuiceboxSelectContext } from 'features/juicebox';
import { PostMasonryCardView } from 'features/post';
import {
  PostFragmentCollectionPostDndPostCardFragment,
  PostFragmentCollectionThumbnailFragmentDoc,
  PostFragmentPostContextMenuFragmentDoc,
  PostFragmentPostCreatorFragmentDoc,
  PostFragmentPostNewBadgeFragmentDoc,
  PostFragmentPostPermissionSummaryFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  PostFragmentPostReactionListFragmentDoc,
  PostFragmentPostTitleFragmentDoc,
  PostFragmentPostUnreadCommentBadgeFragmentDoc,
  PostPermission,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_COLLECTION_POST_DND_POST_CARD = gql`
  fragment PostFragmentCollectionPostDndPostCard on PostModel {
    id
    # Need this because there are commands that depend on this data
    # See COMMAND_TYPE.SEND_TO_COLLECTION
    collections {
      id
    }
    description
    ...PostFragmentPostPreview
    ...PostFragmentPostUnreadCommentBadge
    ...PostFragmentPostNewBadge
    ...PostFragmentPostContextMenu
    ...PostFragmentPostReactionList
    ...PostFragmentPostCreator
    ...PostFragmentCollectionThumbnail
    ...PostFragmentPostPermissionSummary
    ...PostFragmentPostTitle
  }
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentPostUnreadCommentBadgeFragmentDoc}
  ${PostFragmentPostNewBadgeFragmentDoc}
  ${PostFragmentPostContextMenuFragmentDoc}
  ${PostFragmentPostReactionListFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
  ${PostFragmentPostCreatorFragmentDoc}
  ${PostFragmentCollectionThumbnailFragmentDoc}
  ${PostFragmentPostPermissionSummaryFragmentDoc}
  ${PostFragmentPostTitleFragmentDoc}
`;

export const POST_DRAGGABLE_PREFIX = 'draggable-post';

export type CollectionPostDndPostCardProps = {
  post: PostFragmentCollectionPostDndPostCardFragment;
  currentCollectionId?: string;
  isSmartSearch?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const CollectionPostDndPostCard = (
  props: CollectionPostDndPostCardProps,
) => {
  const { post, currentCollectionId, isSmartSearch = false, onClick } = props;

  const {
    isSelectModeActive,
    selectedPostIds,
    selectedCollectionIds,
    onSelectPost,
  } = useJuiceboxSelectContext();
  const selected = selectedPostIds.includes(post.id);

  return (
    <DndDragItem
      type={DndDragItemTypes.Post}
      item={{
        ...post,
        selectedPostIds: [...selectedPostIds, post.id],
        selectedCollectionIds,
      }}
      isDragging={(monitor) =>
        // @ts-ignore
        [...selectedPostIds, monitor.getItem()?.id].includes(post.id)
      }
      useCustomDragLayer
      sx={{
        position: 'relative',
        borderRadius: 4,
        ...(isSmartSearch && {
          background:
            'linear-gradient(#faf3ec 0 0) padding-box, linear-gradient(146.79deg, rgba(141, 112, 163, 0.7) 1.07%, rgba(115, 148, 197, 0.7) 24.73%, rgba(111, 164, 157, 0.7) 49.37%, rgba(138, 161, 101, 0.7) 70.57%, rgba(211, 133, 67, 0.7) 99.65%) border-box',
          border: '5px solid transparent',
        }),
        ...(selectedPostIds.length + selectedCollectionIds.length > 0 && {
          color: theme.colors?.primary.black,
          outline: `2px solid ${
            selected ? theme.colors?.primary.black : theme.colors?.utility[300]
          }`,
          outlineOffset: 3,
          backgroundColor: theme.colors?.primary.white,
        }),
      }}
      canDrag={post.myPermissions.includes(PostPermission.Read)}
    >
      <PostMasonryCardView
        post={post}
        currentCollectionId={currentCollectionId}
        onClick={onClick}
        sx={{
          ...(isSelectModeActive && {
            '*': {
              pointerEvents: 'none !important',
            },
          }),
        }}
        componentsProps={{
          disableContextMenu: isSelectModeActive,
        }}
        {...(isSelectModeActive && {
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
            onSelectPost(post, e.shiftKey);
            onClick?.(e);
          },
        })}
      />
      {selected && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 2,
            backgroundColor: theme.colors?.primary.white,
            borderRadius: '100%',
            height: 20,
            width: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconBoldTickCircle size={16} />
        </Box>
      )}
    </DndDragItem>
  );
};
