import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { CompetitorCreatorSocialPostContentFilter } from 'graphql/generated';
import { theme } from 'styles/theme';

interface Props {
  creatorProfilePictureUrl?: string;
  selectedContentType: CompetitorCreatorSocialPostContentFilter;
  onChange: (contentType: CompetitorCreatorSocialPostContentFilter) => void;
}

export const BrandCompetitorContentTypeSelector = ({
  creatorProfilePictureUrl,
  selectedContentType,
  onChange,
}: Props) => {
  const options = [
    {
      label: 'Owned analytics',
      value: CompetitorCreatorSocialPostContentFilter.Owned,
      onClick: () => onChange(CompetitorCreatorSocialPostContentFilter.Owned),
    },
    {
      label: 'User Generated Content',
      value: CompetitorCreatorSocialPostContentFilter.Ugc,
      onClick: () => onChange(CompetitorCreatorSocialPostContentFilter.Ugc),
    },
  ];
  return (
    <ContextMenu
      PaperProps={{
        sx: {
          background: 'rgba(255, 255, 255, 0.80)',
          backdropFilter: 'blur(20px)',
          border: 'none',
          width: '20%',
        },
        style: {
          boxShadow:
            '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
        },
      }}
      options={options.map(({ label, value, onClick }) => ({
        renderOption: () => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="subhead-lg">{label}</Typography>
            {selectedContentType === value && <IconCustomTick size={16} />}
          </Box>
        ),
        onClick,
      }))}
      renderButton={() => (
        <IconButton
          sx={{
            backgroundColor: theme.colors?.utility[300],
            color: theme.colors?.primary.black,
            borderRadius: theme.spacing(25),
            display: 'flex',
            gap: 2,
            p: theme.spacing(2.5, 3),
            alignItems: 'center',
          }}
          disableRipple={false}
        >
          <Avatar
            src={creatorProfilePictureUrl || ''}
            sx={{
              width: theme.spacing(6),
              height: theme.spacing(6),
            }}
          />
          <Typography variant="headline-lg" color={theme.colors?.utility[800]}>
            {options.find(({ value }) => value === selectedContentType)?.label}
          </Typography>
          <IconLinearArrowDown size={16} />
        </IconButton>
      )}
    />
  );
};
