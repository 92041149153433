import { gql } from '@apollo/client';
import { SxProps, TableCell } from '@mui/material';
import { CollectionPermissionSummaryView } from 'features/collectionPermission';
import {
  CollectionFragmentCollectionPermissionSummaryFragmentDoc,
  CollectionFragmentJuiceboxTableCollectionTableRowCellPermissionFragment,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CollectionFragmentJuiceboxTableCollectionTableRowCellPermission on CollectionModel {
    id
    ...CollectionFragmentCollectionPermissionSummary
  }
  ${CollectionFragmentCollectionPermissionSummaryFragmentDoc}
`;

export type JuiceboxTableCollectionTableRowCellPermissionProps = {
  collection: CollectionFragmentJuiceboxTableCollectionTableRowCellPermissionFragment;
  sx?: SxProps;
};

export const JuiceboxTableCollectionTableRowCellPermission = (
  props: JuiceboxTableCollectionTableRowCellPermissionProps,
) => {
  const { collection, sx = {} } = props;

  return (
    <TableCell sx={{ ...sx }}>
      <CollectionPermissionSummaryView collection={collection} />
    </TableCell>
  );
};
