import React from 'react';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, BoxProps, Popover } from '@mui/material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

export type Emoji = {
  native: string;
};

interface Props extends React.PropsWithChildren, BoxProps {
  onEmojiSelect: (emoji: Emoji) => void;
  maxEmojiSelect?: number;
}

type EmojiPickerWrapperRef = {
  close: () => void;
};

export const EmojiPickerWrapper = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<EmojiPickerWrapperRef>) => {
    const { children, onEmojiSelect, maxEmojiSelect, ...rest } = props;
    const [emojis, setEmojis] = React.useState<Emoji[]>([]);
    const open = useDisclosure();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    React.useEffect(() => {
      if (emojis.length === maxEmojiSelect) {
        setEmojis([]);
        open.onClose();
      }
    }, [emojis, maxEmojiSelect, open]);

    React.useImperativeHandle(ref, () => ({
      close: () => {
        open.onClose();
      },
    }));

    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            open.onToggle();
          }}
          {...rest}
        >
          {children}
        </Box>
        <Popover
          open={open.isOpen}
          anchorEl={anchorEl}
          onClose={open.onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{ zIndex: 1999 }}
        >
          <Box minWidth={352}>
            <Picker
              theme="light"
              data={data}
              onEmojiSelect={(emoji: Emoji) => {
                onEmojiSelect?.(emoji);
                setEmojis((pre) => [...pre, emoji]);
              }}
            />
          </Box>
        </Popover>
      </>
    );
  },
);
