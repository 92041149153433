import { gql } from '@apollo/client';
import {
  CollectionFragmentJuiceboxCollectionSearchItemFragmentDoc,
  PostFragmentJuiceboxAttachmentSearchItemFragmentDoc,
  PostFragmentJuiceboxLinkSearchItemFragmentDoc,
  PostFragmentJuiceboxNoteSearchItemFragmentDoc,
  PostFragmentPostPreviewFragmentDoc,
  useGetRecentlyOpenedQueriesForJuiceboxSearchResultQuery,
  PostType,
  SearchableEntityType,
} from 'graphql/generated';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { Box, CircularProgress } from '@mui/material';
import { MutableRefObject } from 'react';
import {
  JuiceboxAttachmentSearchItem,
  JuiceboxCollectionSearchItem,
  JuiceboxLinkSearchItem,
  JuiceboxNoteSearchItem,
  JuiceboxSearchItemSkeleton,
} from '../searchItem';
import { JuiceboxSearchResultHeader } from '../searchResultHeader';

gql`
  query GetRecentlyOpenedQueriesForJuiceboxSearchResult(
    $searchableEntityTypes: [SearchableEntityType!]!
    $take: Int
    $after: String
    $postCount: Int
    $previewableFirst: Boolean
  ) {
    getSearchesClicked(
      searchableEntityTypes: $searchableEntityTypes
      take: $take
      after: $after
    ) {
      data {
        collection {
          ...CollectionFragmentJuiceboxCollectionSearchItem
          posts(postCount: $postCount, previewableFirst: $previewableFirst) {
            ...PostFragmentPostPreview
          }
        }
        post {
          ...PostFragmentJuiceboxNoteSearchItem
          ...PostFragmentJuiceboxAttachmentSearchItem
          ...PostFragmentJuiceboxLinkSearchItem
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CollectionFragmentJuiceboxCollectionSearchItemFragmentDoc}
  ${PostFragmentJuiceboxNoteSearchItemFragmentDoc}
  ${PostFragmentPostPreviewFragmentDoc}
  ${PostFragmentJuiceboxAttachmentSearchItemFragmentDoc}
  ${PostFragmentJuiceboxLinkSearchItemFragmentDoc}
`;

interface Props {
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
}

export const JuiceboxRecentlyOpenedSearchResult = ({ elRefs }: Props) => {
  const variables = {
    searchableEntityTypes: [
      SearchableEntityType.Collections,
      SearchableEntityType.Posts,
    ],
    take: 20,
    postCount: 3,
    previewableFirst: true,
  };
  const {
    data: recentlyOpenedData,
    fetchMore,
    loading: isRecentDataLoading,
  } = useGetRecentlyOpenedQueriesForJuiceboxSearchResultQuery({
    variables,
  });
  return (
    <>
      <JuiceboxSearchResultHeader title="Recently Opened" />
      {isRecentDataLoading ? (
        [...Array(5)].map(() => <JuiceboxSearchItemSkeleton />)
      ) : (
        <InfiniteScroll
          style={{
            flexDirection: 'column',
            display: 'flex',
            gap: theme.spacing(1),
            marginTop: theme.spacing(-2),
          }}
          threshold={100}
          loadMore={() => {
            if (!recentlyOpenedData?.getSearchesClicked.pageInfo.hasNextPage) {
              return;
            }

            fetchMore({
              variables: {
                ...variables,
                after:
                  recentlyOpenedData?.getSearchesClicked.pageInfo.endCursor,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                return {
                  ...fetchMoreResult,
                  getSearchesClicked: {
                    ...fetchMoreResult.getSearchesClicked,
                    data: [
                      ...(prev.getSearchesClicked.data || []),
                      ...(fetchMoreResult.getSearchesClicked.data || []),
                    ],
                    pageInfo: {
                      hasNextPage:
                        fetchMoreResult.getSearchesClicked.pageInfo.hasNextPage,
                      endCursor:
                        fetchMoreResult.getSearchesClicked.pageInfo.endCursor,
                    },
                  },
                };
              },
            });
          }}
          useWindow={false}
          hasMore={recentlyOpenedData?.getSearchesClicked.pageInfo.hasNextPage}
          loader={
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress
                size={24}
                sx={{ color: theme.colors?.primary.maroon }}
              />
            </Box>
          }
        >
          {recentlyOpenedData?.getSearchesClicked.data.map((data, index) => {
            if (data.collection) {
              return (
                <JuiceboxCollectionSearchItem
                  collection={data.collection}
                  posts={data.collection.posts}
                  ref={(el: HTMLElement) => {
                    elRefs.current[index] = el;
                  }}
                />
              );
            }
            if (data.post && data.post.type === PostType.Note) {
              return (
                <JuiceboxNoteSearchItem
                  note={data.post}
                  ref={(el: HTMLElement) => {
                    elRefs.current[index] = el;
                  }}
                />
              );
            }
            if (data.post && data.post.type === PostType.Attachment) {
              return (
                <JuiceboxAttachmentSearchItem
                  attachment={data.post}
                  ref={(el: HTMLElement) => {
                    elRefs.current[index] = el;
                  }}
                />
              );
            }
            if (data.post && data.post.type === PostType.Link) {
              return (
                <JuiceboxLinkSearchItem
                  link={data.post}
                  ref={(el: HTMLElement) => {
                    elRefs.current[index] = el;
                  }}
                />
              );
            }
          })}
        </InfiniteScroll>
      )}
    </>
  );
};
