import { Box, SxProps } from '@mui/material';
import { Editor } from '@tiptap/react';
import { IconLinearDocumentUpload } from 'components/icons/components/linear/IconLinearDocumentUpload';
import { IconOutlineBulletedList } from 'components/icons/components/outline/IconOutlineBulletedList';
import { IconOutlineCode2 } from 'components/icons/components/outline/IconOutlineCode2';
import { IconOutlineNumberedList } from 'components/icons/components/outline/IconOutlineNumberedList';
import { IconOutlineTask } from 'components/icons/components/outline/IconOutlineTask';
import { PostThemeColor } from 'features/note/constants/noteColors';
import { useFileUploadHandlers } from 'features/tiptap';
import { ReactNode, useMemo } from 'react';
import { theme } from 'styles/theme';
import { StyledIconButton } from './styles';
import { useNoteToolbarWithMobileApp } from './useNoteToolbarWithMobileApp';

export const ToolbarCommand = {
  DocumentUpload: 'DocumentUpload',
  TaskList: 'TaskList',
  BulletedList: 'BulletedList',
  NumberedList: 'NumberedList',
  CodeBlock: 'CodeBlock',
  ChangeColor: 'ChangeColor',
};

type NoteToolBarOption = {
  icon: ReactNode;
  onClick?: VoidFunction;
  enabled?: boolean;
  command?: string;
};

export type NoteToolBarProps = {
  sx: SxProps;
  themeColor?: PostThemeColor;
  editor: Editor | null;
  isMobileApp?: boolean;
  extraOptions?: NoteToolBarOption[];
  noteId: string;
  collectionId?: string;
};

export const NoteToolBar = ({
  sx,
  themeColor,
  editor,
  isMobileApp,
  extraOptions = [],
  noteId,
  collectionId,
}: NoteToolBarProps) => {
  const isCodeBlock = editor?.isActive('codeBlock');
  const isBulletList = editor?.isActive('bulletList');

  const { handleMediaUpload } = useFileUploadHandlers();

  const options: NoteToolBarOption[] = useMemo(() => {
    return [
      {
        icon: (
          <StyledIconButton tooltip="Upload image/video">
            <IconLinearDocumentUpload size={24} />
          </StyledIconButton>
        ),
        onClick: () => {
          if (!editor) {
            return;
          }

          const input = document.createElement('input');
          input.type = 'file';
          input.multiple = true;
          input.onchange = (e) => {
            const fileList = Array.from(
              (e.target as HTMLInputElement).files || [],
            );

            handleMediaUpload(editor.view, fileList);
          };

          input.click();
        },
        command: ToolbarCommand.DocumentUpload,
      },
      {
        icon: (
          <StyledIconButton tooltip="Task list">
            <IconOutlineTask size={24} />
          </StyledIconButton>
        ),
        onClick: () => {
          editor?.chain().focus().toggleTaskList().run();
        },
        command: ToolbarCommand.TaskList,
      },
      {
        icon: (
          <StyledIconButton tooltip="Bulleted list">
            <IconOutlineBulletedList size={24} />
          </StyledIconButton>
        ),
        onClick: () => editor?.chain().focus().toggleBulletList().run(),
        enabled: isBulletList,
        command: ToolbarCommand.BulletedList,
      },
      {
        icon: (
          <StyledIconButton tooltip="Numbered list">
            <IconOutlineNumberedList size={24} />
          </StyledIconButton>
        ),
        onClick: () => editor?.chain().focus().toggleOrderedList().run(),
        command: ToolbarCommand.NumberedList,
      },
      {
        icon: (
          <StyledIconButton tooltip="Code">
            <IconOutlineCode2 size={24} />
          </StyledIconButton>
        ),
        onClick: () => editor?.chain().focus().toggleCodeBlock().run(),
        enabled: isCodeBlock,
        command: ToolbarCommand.CodeBlock,
      },
      ...extraOptions,
    ];
  }, [editor, extraOptions, handleMediaUpload, isBulletList, isCodeBlock]);

  useNoteToolbarWithMobileApp({
    options,
    noteId,
    collectionId,
    enabled: isMobileApp,
  });

  // hide webview note toolbar in mobile app
  if (isMobileApp) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 4,
        py: 2.5,
        borderRadius: theme.spacing(3),
        color: theme.colors?.utility[900],
        bgcolor: themeColor?.barBgColor,
        backdropFilter: 'blur(25px)',
        gap: theme.spacing(6),
        border: `1px solid ${themeColor?.barBorderColor}`,
        ...sx,
      }}
    >
      {options.map((item, idx) => {
        return (
          <Box key={idx} onClick={item?.onClick} sx={{ cursor: 'pointer' }}>
            {item.icon}
          </Box>
        );
      })}
    </Box>
  );
};
