import { Box } from '@mui/material';
import { GetSocialPostForSocialMediaListeningPostDetailQuery } from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';

export const SocialMediaListeningPostDetailImage = ({
  post,
  onHeightSet,
}: {
  post: GetSocialPostForSocialMediaListeningPostDetailQuery['socialPost'];
  onHeightSet: (renderedHeight: number) => void;
}) => {
  const imageRef = useRef<HTMLImageElement>();
  const [renderedHeight, setRenderedHeight] = useState(0);

  useEffect(() => {
    if (imageRef.current) {
      setRenderedHeight(imageRef.current.offsetHeight);

      const resizeObserver = new ResizeObserver((entries) => {
        setRenderedHeight(entries[0].contentRect.height);
      });
      resizeObserver.observe(imageRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [imageRef]);

  useEffect(() => {
    onHeightSet(renderedHeight);
  }, [renderedHeight, onHeightSet]);

  return (
    <Box position="relative">
      <Box
        ref={imageRef}
        component="img"
        src={post.thumbnailUrl || ''}
        sx={{
          maxHeight: 600,
          width: '-webkit-fill-available',
          borderRadius: 4,
        }}
      />
    </Box>
  );
};
