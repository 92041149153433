/**
 * This component handles the attachments that are pending to be created
 * together with a comment. It handles uploading the attachments to Uploadcare.
 */

import { Box, CircularProgress, IconButton, SxProps } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import cuid from 'cuid';
import { AttachmentPreview, AttachmentPreviewProps } from 'features/attachment';
import { ResourceUploadItem } from 'hooks/useResourceUploadQueue';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

export type CommentPendingAttachmentProps = {
  resource: ResourceUploadItem;
  sx?: SxProps;
  componentsProps?: {
    attachmentPreview?: AttachmentPreviewProps;
  };
  onRemove?: () => void;
};

export const CommentPendingAttachment = (
  props: CommentPendingAttachmentProps,
) => {
  const { resource, sx, componentsProps, onRemove } = props;

  const url = useMemo(() => {
    if (resource.uploadedInfo) {
      return resource.uploadedInfo.url;
    }

    return URL.createObjectURL(resource.resource.content as File);
  }, [resource]);

  const metaData = useMemo(() => {
    if (resource.uploadedInfo) {
      return resource.uploadedInfo.metaData || {};
    }

    // This is not supposed to happen. Content should always be a File here
    if (typeof resource.resource.content === 'string') {
      return {};
    }

    return {
      mimeType: resource.resource.content.type,
    };
  }, [resource]);

  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        position: 'relative',
        ':hover': {
          '.remove-button': {
            opacity: 1,
          },
        },
      }}
    >
      <AttachmentPreview
        attachment={{
          id: resource.resource.id || cuid(),
          url,
          name: resource.resource.name,
          metaData,
        }}
        {...componentsProps?.attachmentPreview}
        sx={{
          height: 52,
          width: 52,
          borderRadius: '8px',
          border: `1px solid ${theme.colors?.utility[275]}`,
          display: 'inline-flex',
          ...componentsProps?.attachmentPreview?.sx,
        }}
      />
      <IconButton
        className="remove-button"
        sx={{
          position: 'absolute',
          top: -8,
          right: -8,
          p: 0,
          width: 16,
          height: 16,
          color: theme.colors?.primary.black,
          opacity: 0,
        }}
        onClick={onRemove}
      >
        <IconBoldCloseCircle size={16} />
      </IconButton>
      {['uploading', 'waiting'].includes(resource.uploadStatus) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            bgcolor: '#230603B2',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            variant="determinate"
            value={resource?.uploadProgress}
            size={16}
            sx={{
              color: theme.colors?.primary.parchment,
            }}
          />
        </Box>
      )}
    </Box>
  );
};
