import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { IconButton } from '@mui/material';
import { ContextMenu, ContextMenuOption } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconLinearArrow } from 'components/icons/components/linear/IconLinearArrow';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconOutlineSetting2 } from 'components/icons/components/outline/IconOutlineSetting2';
import { BrandContentFilterSettingsDialogView } from 'features/brand/views';
import {
  BrandFragmentBrandContentFilterSettingsDialogViewFragmentDoc,
  BrandFragmentBrandMoreContextMenuFragment,
} from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';

// eslint-disable-next-line
gql`
  fragment BrandFragmentBrandMoreContextMenu on BrandModel {
    id
    ...BrandFragmentBrandContentFilterSettingsDialogView
  }
  ${BrandFragmentBrandContentFilterSettingsDialogViewFragmentDoc}
`;

export type BrandMoreContextMenuProps = Pick<
  ContextMenuProps,
  'disablePortal' | 'sx'
> & {
  brand: BrandFragmentBrandMoreContextMenuFragment;
};

export const BrandMoreContextMenu = ({
  brand,
  ...rest
}: BrandMoreContextMenuProps) => {
  const navigate = useNavigate();
  const contentFilterSettingsDialog = useDisclosure();
  return (
    <>
      <ContextMenu
        PaperProps={{
          sx: {
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12) !important',
            border: 'none',
          },
        }}
        renderButton={() => {
          return (
            <IconButton size="small">
              <IconLinearMore size={24} />
            </IconButton>
          );
        }}
        options={[
          {
            renderOption: () => (
              <ContextMenuOption
                label="Update parsing limit"
                icon={<IconLinearArrow size={16} />}
              />
            ),
            onClick: contentFilterSettingsDialog.onOpen,
          },
          {
            renderOption: () => (
              <ContextMenuOption
                label="Go to brand settings"
                icon={<IconOutlineSetting2 size={16} />}
              />
            ),
            onClick: () => {
              navigate(
                PlotRoutes.socialListeningSettings({
                  tab: 'general',
                  brandId: brand.id,
                }),
              );
            },
          },
        ]}
        {...rest}
      />
      {contentFilterSettingsDialog.isOpen && (
        <BrandContentFilterSettingsDialogView
          brand={brand}
          open={contentFilterSettingsDialog.isOpen}
          onClose={contentFilterSettingsDialog.onClose}
        />
      )}
    </>
  );
};
