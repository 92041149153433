import { Box, BoxProps } from '@mui/material';
import { DndDragItemTypes } from 'features/dnd/constants';
import { ReactNode, useEffect, useRef } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

export type DndDragItemProps<T> = BoxProps & {
  type: DndDragItemTypes;
  item: T & { id: string };
  children?: ReactNode;
  canDrag?: boolean;
  useCustomDragLayer?: boolean;

  /**
   * Override method to determine if the item is currently being dragged.
   * Used for multi-select drag and drop.
   */
  isDragging?: (monitor: DragSourceMonitor<unknown, unknown>) => boolean;
};

export const DndDragItem = <T,>(props: DndDragItemProps<T>) => {
  const {
    type,
    item,
    children,
    canDrag = true,
    sx,
    useCustomDragLayer,
    isDragging,
    ...rest
  } = props;

  const itemRef = useRef(item);
  itemRef.current = item;

  const [collected, drag, dragPreview] = useDrag(() => ({
    type,
    item: () => {
      return itemRef.current;
    },
    canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging,
  }));

  // Use empty image as a drag preview so browsers don't draw it
  // and we can draw whatever we want on the custom drag layer instead.
  useEffect(() => {
    if (useCustomDragLayer) {
      dragPreview(getEmptyImage(), { captureDraggingState: true });
    }
  }, [useCustomDragLayer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      ref={drag}
      sx={{
        opacity: collected.isDragging ? 0.5 : 1,
        cursor: collected.isDragging ? 'move' : 'pointer',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
