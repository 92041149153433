import { Box, SxProps, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { CUSTOM_COLLECTION } from 'features/collection';
import { JuiceboxTableCollectionTableRowWithContextMenu } from 'features/juiceboxTable/components';
import { JuiceboxTablePostTableRowWithContextMenu } from 'features/juiceboxTable/components/postTableRow';
import {
  CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragment,
  PostFragmentJuiceboxTablePostTableRowWithContextMenuFragment,
  PostType,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { CSSProperties } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';
import { columns } from './types';

const fixedHeaderContent = (sx?: SxProps, nestedLevel = 0) => {
  return (
    <Box display="flex" flex={1} width="100%" my={2} pl={nestedLevel * 6}>
      {columns.map((column) => (
        <Box
          display="flex"
          key={column.dataKey}
          style={{
            ...column.style,
          }}
          sx={{
            px: 4,
            ...(sx || {}),
          }}
        >
          <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
            {column.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export type JuiceboxTableBaseTableViewProps = {
  currentCollectionId?: string;
  collections: CollectionFragmentJuiceboxTableCollectionTableRowWithContextMenuFragment[];
  posts: PostFragmentJuiceboxTablePostTableRowWithContextMenuFragment[];
  hasMore?: boolean;
  loadMore?: VoidFunction;
  style?: CSSProperties;
  nestedLevel?: number;
  hideHeader?: boolean;
  componentProps?: {
    header?: {
      sx?: SxProps;
    };
  };
  context?: 'parent' | 'root';
};

export const JuiceboxTableBaseTableView = (
  props: JuiceboxTableBaseTableViewProps,
) => {
  const {
    hideHeader,
    currentCollectionId = '',
    collections,
    posts,
    hasMore,
    loadMore,
    style,
    componentProps,
    nestedLevel = 0,
    context = 'parent',
  } = props;

  const navigate = useGuardNavigate();
  const location = useLocation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{ overflow: 'auto', ...style }}
    >
      <InfiniteScroll
        useWindow={false}
        loadMore={() => {
          if (loadMore && hasMore) {
            loadMore();
          }
        }}
        hasMore={hasMore}
        threshold={100}
      >
        {!hideHeader &&
          fixedHeaderContent(componentProps?.header?.sx, nestedLevel)}
        <Box display="flex" width="100%" flexDirection="column">
          {collections.map((collection) => {
            return (
              <JuiceboxTableCollectionTableRowWithContextMenu
                key={collection.id}
                collection={collection}
                nestedLevel={nestedLevel}
                onClick={() => {
                  navigate(PlotRoutes.collection(collection.id), {
                    state: location.state,
                  });
                }}
                componentsProps={{
                  disableContextMenu: Object.values(CUSTOM_COLLECTION).includes(
                    collection.id as CUSTOM_COLLECTION,
                  ),
                }}
                context={context}
              />
            );
          })}
          {posts.map((post) => {
            return (
              <JuiceboxTablePostTableRowWithContextMenu
                key={post.id}
                collectionId={currentCollectionId}
                post={post}
                nestedLevel={nestedLevel}
                onClick={() => {
                  navigate(
                    {
                      pathname:
                        post.type === PostType.Note
                          ? PlotRoutes.juiceboxNote({
                              id: post.id,
                              title: post.title,
                            })
                          : PlotRoutes.juice(post.id),
                    },
                    {
                      state: {
                        ...(location?.state || ({} as any)),
                        backgroundLocation: location,
                      },
                    },
                  );
                }}
              />
            );
          })}
        </Box>
      </InfiniteScroll>
    </Box>
  );
};
