import { createContext } from '@dwarvesf/react-utils';
import { SetStateAction, useEffect, useState } from 'react';
import { ZoomOption } from '../../Annotation/types';
import { useZoomManager } from './ZoomManager.context';

interface ContextValues {
  viewportWidth: number;
  setViewportWidth: React.Dispatch<SetStateAction<number>>;
  viewportHeight: number;
  setViewportHeight: React.Dispatch<SetStateAction<number>>;

  /**
   * Offset to the viewport final sizes.
   * This is new & implemented to support CustomDocument where we have to
   * render the sidebar together with the document.
   * With this offset, we should be able to modify the viewport sizes by simply
   * set them inside CustomDocument & let the ViewManager handle the rest.
   */
  viewportOffset: { width: number; height: number };
  setViewportOffset: React.Dispatch<
    SetStateAction<{ width: number; height: number }>
  >;

  /**
   * Maximum width of a item.
   * This will help when we need to calculate an annotation's relative position
   * to a item
   */
  maxBoundingBoxWidth: number;
  setMaxBoundingBoxWidth: React.Dispatch<SetStateAction<number>>;

  /**
   * Maximum width of a item.
   * This will help when we need to calculate an annotation's relative position
   * to a item
   */
  maxOriginalBoundingBoxWidth: number;
  setMaxOriginalBoundingBoxWidth: React.Dispatch<SetStateAction<number>>;

  /**
   * Maximum height of a item.
   * This will help when we need to calculate an annotation's relative position
   * to a item
   */
  maxBoundingBoxHeight: number;
  setMaxBoundingBoxHeight: React.Dispatch<SetStateAction<number>>;

  /**
   * Maximum height of a item.
   * This will help when we need to calculate an annotation's relative position
   * to a item
   */
  maxOriginalBoundingBoxHeight: number;
  setMaxOriginalBoundingBoxHeight: React.Dispatch<SetStateAction<number>>;

  reset: () => void;
}

const [Provider, useViewManager] = createContext<ContextValues>({
  name: 'view-manager',
});

const ViewManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [_viewportWidth, setViewportWidth] = useState(0);
  const [_viewportHeight, setViewportHeight] = useState(0);
  const [viewportOffset, setViewportOffset] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const viewportWidth = _viewportWidth - viewportOffset.width;
  const viewportHeight = _viewportHeight - viewportOffset.height;

  const [maxBoundingBoxWidth, setMaxBoundingBoxWidth] = useState(0);
  const [maxOriginalBoundingBoxWidth, setMaxOriginalBoundingBoxWidth] =
    useState(0);

  const [maxBoundingBoxHeight, setMaxBoundingBoxHeight] = useState(0);
  const [maxOriginalBoundingBoxHeight, setMaxOriginalBoundingBoxHeight] =
    useState(0);

  const { selectedZoomFilter, viewSizes, setScale } = useZoomManager();

  const reset = () => {
    setMaxBoundingBoxWidth(0);
    setMaxOriginalBoundingBoxWidth(0);
    setMaxOriginalBoundingBoxHeight(0);
  };

  // Listen to selectedZoomFilter change.
  // For FIT_TO_WIDTH option, we need custom logic handling
  useEffect(() => {
    // We need these values (& like, immediately) for scale calculation in the zoom manager
    // so we push them with ref
    viewSizes.current.viewportWidth = viewportWidth;
    viewSizes.current.maxOriginalBoundingBoxWidth = maxOriginalBoundingBoxWidth;

    if (viewportWidth && maxOriginalBoundingBoxWidth) {
      if (selectedZoomFilter === ZoomOption.FIT_TO_WIDTH) {
        const viewportPadding = 0;

        // remove padding from viewportWidth
        const originalViewportWidth = viewportWidth - viewportPadding;
        const scaleToFitWidth =
          originalViewportWidth / maxOriginalBoundingBoxWidth;

        setScale(scaleToFitWidth);
      } else if (selectedZoomFilter === ZoomOption.ACTUAL_SIZE) {
        setScale(1);
      }
    }

    if (viewportHeight && maxOriginalBoundingBoxHeight) {
      if (selectedZoomFilter === ZoomOption.FIT_TO_HEIGHT) {
        const viewportPadding = 0;

        // remove padding from viewportHeight
        const originalViewportHeight = viewportHeight - viewportPadding;
        const scaleToFitHeight =
          originalViewportHeight / maxOriginalBoundingBoxHeight;

        setScale(scaleToFitHeight);
      } else if (selectedZoomFilter === ZoomOption.ACTUAL_SIZE) {
        setScale(1);
      }
    }

    // Fit asset to screen based on all available dimensions
    if (
      viewportWidth &&
      viewportHeight &&
      maxOriginalBoundingBoxWidth &&
      maxOriginalBoundingBoxHeight
    ) {
      if (selectedZoomFilter === ZoomOption.FIT_TO_SCREEN) {
        const viewportPadding = 0;

        // remove padding from viewportHeight
        const originalViewportHeight = viewportHeight - viewportPadding;
        const scaleToFitHeight =
          originalViewportHeight / maxOriginalBoundingBoxHeight;

        // remove padding from viewportWidth
        const originalViewportWidth = viewportWidth - viewportPadding;
        const scaleToFitWidth =
          originalViewportWidth / maxOriginalBoundingBoxWidth;

        const scale = Math.min(scaleToFitHeight, scaleToFitWidth);

        setScale(scale);
      }
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    selectedZoomFilter,
    viewportWidth,
    viewportHeight,
    maxOriginalBoundingBoxWidth,
    maxOriginalBoundingBoxHeight,
  ]);

  return (
    <Provider
      value={{
        viewportWidth,
        setViewportWidth,
        viewportHeight,
        setViewportHeight,
        viewportOffset,
        setViewportOffset,

        maxBoundingBoxWidth,
        setMaxBoundingBoxWidth,
        maxOriginalBoundingBoxWidth,
        setMaxOriginalBoundingBoxWidth,

        maxBoundingBoxHeight,
        setMaxBoundingBoxHeight,
        maxOriginalBoundingBoxHeight,
        setMaxOriginalBoundingBoxHeight,

        reset,
      }}
    >
      {children}
    </Provider>
  );
};

export { ViewManagerProvider, useViewManager };
