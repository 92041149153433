import { Box, BoxProps, Button, Typography } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { ReactNode, forwardRef } from 'react';
import { useUserContext } from '../../../../contexts/users/User.context';

export const COLLECTION_MENU_ITEM_ADD_NEW_VALUE_KEY = 'add-new-collection';

export type CollectionMenuItemAddNewViewProps = BoxProps<'li'> & {
  label: string;
  disabled?: boolean;
  renderWarning?: () => ReactNode;
};

export const CollectionMenuItemAddNewView = forwardRef(
  (props: CollectionMenuItemAddNewViewProps, ref) => {
    const { orgBilling } = useUserContext();

    const { label, disabled = false, renderWarning, ...rest } = props;

    return (
      <Box
        ref={ref}
        component="li"
        sx={{
          p: '8px 10px !important',
          borderRadius: 3,
          gap: 2,
          transition: 'all 0.2s',
          '&.Mui-focused, :hover': {
            backgroundColor: '#2306031A !important',
          },
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#2306031A !important',
          },
        }}
        {...rest}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          rest.onClick?.(e);
        }}
        disabled={
          orgBilling?.collectionUsageLimit
            ? orgBilling.collectionUsageCount >= orgBilling.collectionUsageLimit
            : disabled
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: 36,
              height: 36,
              borderRadius: 1,
              overflow: 'hidden',
              bgcolor: 'rgba(35, 6, 3, 0.05)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
            }}
          >
            <IconLinearAdd size={16} />
          </Box>
          <Box display="grid" sx={{ wordBreak: 'break-word' }}>
            <Typography variant="subhead-lg">{label}</Typography>
            {renderWarning?.()}
          </Box>
        </Box>
        <Button
          variant="pill"
          size="small"
          sx={{ py: 1, px: 2, fontSize: 12 }}
          type="button"
          disabled={
            orgBilling?.collectionUsageLimit
              ? orgBilling.collectionUsageCount >=
                orgBilling.collectionUsageLimit
              : disabled
          }
        >
          Create
        </Button>
      </Box>
    );
  },
);
