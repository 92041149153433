import { gql } from '@apollo/client';
import {
  BillingPlanName,
  OrganizationBillingFragmentBannerViewFragment,
  OrganizationBillingFragmentFreeTrialFragmentDoc,
  OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc,
  BillingSubscriptionStatus,
} from 'graphql/generated';
import { SxProps } from '@mui/material';
import { useMemo } from 'react';
import {
  BillingFreeTrialIndicator,
  BillingOrganizationMemberLimitIndicator,
} from '../../components';

// eslint-disable-next-line
gql`
  fragment OrganizationBillingFragmentBannerView on OrganizationBillingModel {
    plan
    subscription {
      id
      status
    }
    ...OrganizationBillingFragmentOrganizationMemberPaywall
    ...OrganizationBillingFragmentFreeTrial
  }
  ${OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc}
  ${OrganizationBillingFragmentFreeTrialFragmentDoc}
`;

type BillingBannerViewProps = {
  organizationBilling: OrganizationBillingFragmentBannerViewFragment;
  sx?: SxProps;
};

export const BillingBannerView = (props: BillingBannerViewProps) => {
  const { organizationBilling, sx } = props;

  const displayBannerType: 'freeTrial' | 'membership' | null = useMemo(() => {
    if (
      organizationBilling.plan !== BillingPlanName.Free &&
      // do not show member banner for enterprise account
      organizationBilling.plan !== BillingPlanName.Enterprise &&
      organizationBilling.organizationMemberLimit &&
      organizationBilling.organizationMemberUsage >=
        organizationBilling.organizationMemberLimit
    ) {
      return 'membership';
    }

    if (
      organizationBilling.plan === BillingPlanName.Free ||
      organizationBilling.subscription?.status ===
        BillingSubscriptionStatus.Trialing
    ) {
      return 'freeTrial';
    }

    return null;
  }, [organizationBilling]);

  return displayBannerType === 'freeTrial' ? (
    <BillingFreeTrialIndicator
      organizationBilling={organizationBilling}
      sx={sx}
    />
  ) : displayBannerType === 'membership' ? (
    <BillingOrganizationMemberLimitIndicator
      organizationBilling={organizationBilling}
      variant="banner"
      sx={sx}
    />
  ) : null;
};
