import { Box, IconButton, Typography } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { theme } from 'styles/theme';

export const AddButton = ({ btnText }: { btnText: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <IconButton
        style={{
          backgroundColor: theme.colors?.utility[300],
          width: 20,
          height: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconLinearAdd
          size={16}
          color={theme.colors?.utility[800]}
          style={{
            flexShrink: 0,
          }}
        />
      </IconButton>
      <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
        {btnText}
      </Typography>
    </Box>
  );
};
