import {
  Box,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = TextFieldProps & {
  maxWords: number;
  onEnter?: (value: string) => void;
  helperText?: React.ReactNode;
  componentProps?: {
    wordUsage?: {
      sx?: SxProps;
    };
  };
  children?: React.ReactNode;
};

export const WordLimitedInputArea = (props: Props) => {
  const {
    children,
    maxWords,
    onChange,
    onEnter,
    helperText,
    componentProps,
    ...otherProps
  } = props;
  const [wordCount, setWordCount] = useState(0);
  const [value, setValue] = useState('');

  useEffect(() => {
    if ((props?.defaultValue as string)?.length > 0) {
      const words = (props.defaultValue as string)
        .split(' ')
        .filter((word) => word);
      setWordCount(words.length);
      setValue(props.defaultValue as string);
    }
  }, [props.defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const words = event.target.value.split(' ').filter((word) => word);
    if (maxWords && words.length > maxWords) {
      // If the new word count exceeds the maximum, prevent the update
      event.preventDefault();
    } else {
      setWordCount(words.length);
      setValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      // Ignore Shift + Enter events
      if (event.shiftKey) {
        return;
      }

      if (onEnter) {
        onEnter(value.trim());
      }
    }
  };

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <TextField
        autoFocus
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        {...otherProps}
      />
      <Box display="flex" justifyContent="space-between">
        {!!helperText && helperText}
        <Typography
          variant="body-xl"
          fontWeight={500}
          sx={{
            textAlign: 'right',
            ...(componentProps?.wordUsage?.sx || {}),
          }}
        >
          {wordCount}/{maxWords} words
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
