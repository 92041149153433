import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const CompetitorSentimentSkeleton = () => {
  return (
    <Box>
      <Card
        sx={{
          borderRadius: 5,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="headline-lg" fontSize={theme.spacing(5)}>
            Sentiment
          </Typography>
          <Box display="flex" gap={3} alignItems="center">
            <Box display="flex" alignItems="center" gap={3}>
              <Skeleton width={theme.spacing(30)} height={theme.spacing(10)} />
              <Skeleton width={theme.spacing(30)} height={theme.spacing(10)} />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 6,
            borderBottom: `1px solid ${theme.colors?.utility[275]}`,
          }}
        />

        <Grid container spacing={3} columns={12} mt={6}>
          {new Array(3).fill(0).map(() => (
            <Grid item xs={4}>
              <Skeleton
                sx={{ height: theme.spacing(150), transform: 'unset' }}
              />
            </Grid>
          ))}
        </Grid>
      </Card>
      <Typography
        variant="subhead-xl"
        color={theme.colors?.utility[600]}
        ml="auto"
        width="100%"
        textAlign="right"
        display="block"
        mt={2}
      >
        {/* TODO:Competitor: update timestamp here */}
        {/* Last updated {moment().subtract(1, 'days').fromNow()} */}
      </Typography>
    </Box>
  );
};
