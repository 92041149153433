import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderVariant } from '../types';
import { getDefaultVariantBasedOnRoutePathname } from '../utils';

// Switch variant when users scroll down a certain amount
const VARIANT_SWITCH_THRESHOLD = 100;

/**
 * This hook returns header variant based on:
 * 1. Route path (e.g. /projects will have transparent variant)
 * 2. Scroll position of the header. When users are on project page and scroll down, we'll want to show light variant
 * after surpassing a certain threshold
 */
export const useVariant = () => {
  const { pathname, state } = useLocation();

  const defaultVariant = useMemo(
    () =>
      getDefaultVariantBasedOnRoutePathname(
        // @ts-ignore
        state?.backgroundLocation?.pathname || pathname,
      ),
    [pathname, state],
  );
  const [variant, setVariant] = useState<HeaderVariant>(defaultVariant);

  // Switch variant from transparent to light if users scroll down a certain distance
  useEffect(() => {
    if (defaultVariant !== 'transparent') {
      return;
    }

    const onScroll = () => {
      if (window.scrollY >= VARIANT_SWITCH_THRESHOLD) {
        setVariant('light');
      } else {
        setVariant('transparent');
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [variant, defaultVariant]);

  useEffect(() => {
    setVariant(defaultVariant);
  }, [defaultVariant]);

  return {
    variant,
  };
};
