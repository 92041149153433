import { gql } from '@apollo/client';
import { SxProps, Typography } from '@mui/material';
import {
  ListeningTopicType,
  TopicFragmentTopicLabelFragment,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// Chip text & bg colors
export const colors = {
  [ListeningTopicType.BrandMention]: {
    color: theme.colors?.utility['blue-3'],
    bgcolor: theme.colors?.utility['blue-1'],
  },
  [ListeningTopicType.TopicEngagement]: {
    color: theme.colors?.utility['yellow-3'],
    bgcolor: theme.colors?.utility['yellow-1'],
  },
  [ListeningTopicType.CreatorTracking]: {
    color: theme.colors?.utility['purple-3'],
    bgcolor: theme.colors?.utility['purple-1'],
  },
};

export const typeToLabel = {
  [ListeningTopicType.BrandMention]: 'Brand Mention',
  [ListeningTopicType.TopicEngagement]: 'Topic Insights',
  [ListeningTopicType.CreatorTracking]: 'Creator Tracking',
};

export const TOPIC_FRAGMENT_TOPIC_LABEL = gql`
  fragment TopicFragmentTopicLabel on TopicModel {
    id
    type
  }
`;

export type TopicLabelProps = {
  topic: TopicFragmentTopicLabelFragment;
  sx?: SxProps;
};

export const TopicLabel = (props: TopicLabelProps) => {
  const { topic, sx } = props;

  const { color, bgcolor } = colors[topic.type] || {};
  const label = typeToLabel[topic.type];

  if (!color || !bgcolor || !label) {
    return null;
  }

  return (
    <Typography
      variant="headline-xxs"
      sx={{
        py: 0.5,
        px: 2,
        color,
        bgcolor,
        borderRadius: 100,
        ...sx,
      }}
    >
      {label}
    </Typography>
  );
};
