import React from 'react';
import { BillingPlanName } from 'graphql/generated';
import { IconOutlineHashtag } from 'components/icons/components/outline/IconOutlineHashtag';
import { IconLinearFolderOpen } from 'components/icons/components/linear/IconLinearFolderOpen';
import { IconOutlineProfile2user } from 'components/icons/components/outline/IconOutlineProfile2user';
import { IconCustomMobile } from 'components/icons/components/custom/IconCustomMobile';
import { IconOutlineBuilding } from 'components/icons/components/outline/IconOutlineBuilding';
import { IconLinearDocumentUpload } from 'components/icons/components/linear/IconLinearDocumentUpload';
import { IconOutlineFlash1 } from 'components/icons/components/outline/IconOutlineFlash1';
import { IconOutlineMagicpen } from 'components/icons/components/outline/IconOutlineMagicpen';
import { IconLinearVideoTick } from 'components/icons/components/linear/IconLinearVideoTick';
import { gql } from '@apollo/client';

// eslint-disable-next-line
gql`
  query GetBillingAvailablePrices {
    getAvailablePrices {
      name
      productId
      prices {
        id
        currency
        recurringInterval
        amount
        unitAmountDecimal
      }
    }
  }
`;

const iconSize = 18;

export type PlanFeature = {
  icon?: React.ReactNode;
  name: string;
  description?: string;
  isComingSoon?: boolean;
  isBold?: boolean;
};

export type Plan = {
  emoji: string;
  name: string;
  description: string;
  isTrialable?: boolean;
  isSuggested?: boolean;
  value: BillingPlanName;
  features: PlanFeature[];
};

export const plans: Plan[] = [
  {
    emoji: '🏠',
    name: 'Starter',
    description:
      'For individuals to save ideas, links, and assets for themselves.',
    value: BillingPlanName.Free,
    features: [
      {
        icon: <IconOutlineHashtag size={iconSize} />,
        name: 'No Collections',
      },
      {
        icon: <IconLinearFolderOpen size={iconSize} />,
        name: 'File storage: 5GB',
      },
      {
        icon: <IconOutlineBuilding size={iconSize} />,
        name: 'Unlimited organization members',
      },
      {
        icon: <IconOutlineProfile2user size={iconSize} />,
        name: 'Up to 50 guests',
        description: 'Each guest can create up to 50 posts.',
      },
      {
        icon: <IconCustomMobile size={iconSize} />,
        name: 'Mobile App',
      },
    ],
  },
  {
    emoji: '👨‍💻',
    name: 'Basic',
    description:
      'For individuals who want to share and store store assets, links, ideas.',
    value: BillingPlanName.Basic,
    isTrialable: true,
    features: [
      {
        icon: <IconOutlineHashtag size={iconSize} />,
        name: 'Unlimited Collections',
      },
      {
        icon: <IconLinearFolderOpen size={iconSize} />,
        name: 'File storage: 100GB',
      },
      {
        icon: <IconOutlineBuilding size={iconSize} />,
        name: 'Up to 5 organization members',
      },
      {
        icon: <IconOutlineProfile2user size={iconSize} />,
        name: 'Up to 100 guests',
        description: 'Each guest can create up to 100 posts.',
      },
      {
        icon: <IconLinearDocumentUpload size={iconSize} />,
        name: 'File delivery size max: 50MB',
      },
      {
        icon: <IconOutlineFlash1 size={iconSize} />,
        name: 'Unlimited Trends',
      },
      {
        icon: <IconCustomMobile size={iconSize} />,
        name: 'Mobile App',
      },
    ],
  },
  {
    emoji: '🧃',
    name: 'Business',
    description:
      'For teams who need advanced features for storage and organization.',
    value: BillingPlanName.Standard,
    isTrialable: true,
    isSuggested: true,
    features: [
      {
        name: 'Everything in 👨‍💻, and',
        isBold: true,
      },
      {
        icon: <IconLinearFolderOpen size={iconSize} />,
        name: 'File storage: 1TB',
      },
      {
        icon: <IconOutlineBuilding size={iconSize} />,
        name: 'Unlimited organization members',
      },
      {
        icon: <IconOutlineProfile2user size={iconSize} />,
        name: 'Up to 250 guests',
        description: 'Each guest can create up to 250 posts.',
      },
      {
        icon: <IconLinearDocumentUpload size={iconSize} />,
        name: 'File delivery size max: 100MB',
      },
      {
        icon: <IconOutlineMagicpen size={iconSize} />,
        name: 'AI Capabilities (Search and Suggestion)',
      },
    ],
  },
  {
    emoji: '🏢',
    name: 'Enterprise',
    value: BillingPlanName.Enterprise,
    description: 'Advanced tools & support to run your entire organization.',
    features: [
      {
        name: 'Everything in 🧃, and',
        isBold: true,
      },
      {
        icon: <IconLinearVideoTick size={iconSize} />,
        name: 'AI Video Social Listening',
      },
      {
        icon: <IconOutlineFlash1 size={iconSize} />,
        name: 'Custom Trends',
      },
      {
        icon: <IconLinearFolderOpen size={iconSize} />,
        name: 'Custom Storage',
      },
    ],
  },
];
