import { theme } from 'styles/theme/theme';
import {
  LinearProgress as MuiLinearProgress,
  linearProgressClasses,
} from '@mui/material';

export const LinearProgress = (props) => {
  return (
    <MuiLinearProgress
      {...props}
      sx={{
        backgroundColor: theme.colors?.utility[300],
        [`.${linearProgressClasses.bar1Indeterminate}`]: {
          backgroundColor: theme.colors?.primary.maroon,
        },
        [`.${linearProgressClasses.bar2Indeterminate}`]: {
          backgroundColor: theme.colors?.primary.maroon,
        },
      }}
    />
  );
};
