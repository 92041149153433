import { useEffect, useState } from 'react';
import { SelectedImage } from '../ImageSelector/ImageSelector';
import { CoverType } from '../types';

/**
 * Util hook to load image locally as data url for further consumption
 * in the ImageEditor component.
 *
 * Main function of this hook is to output loaded images as data urls,
 * because we are using react-avatar-editor for editing, and it needs
 * un-tainted (meaning no cors stuff) image to work.
 *
 * @returns null
 */
export const useLoadImageLocally = ({
  data,
  onLoad,
}: {
  data?: SelectedImage;
  onLoad: (image: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data || !data.data) {
      return;
    }

    setIsLoading(true);

    // If image type is UNSPLASH
    // download the image & convert to data url manually
    if (data.type === CoverType.UNSPLASH) {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;

        context!.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL('image/png');

        onLoad(dataUrl);
        setIsLoading(false);
      };
      image.src = data.data.urls.raw;
    } else {
      // If image type is File,
      // read it as data url
      const reader = new FileReader();
      reader.onload = (e) => {
        onLoad(e.target?.result as string);
        setIsLoading(false);
      };
      reader.readAsDataURL(data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { isLoading };
};
