import { Mention as TipTapMention } from '@tiptap/extension-mention';
import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react';
import { MentionNodeView } from './MentionNodeView';

export const Mention = TipTapMention.extend({
  inline: true,
  priority: 101,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            'data-id': attributes.id,
          };
        },
      },
      type: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-type'),
        renderHTML: (attributes) => {
          if (!attributes.type) {
            return {};
          }
          return {
            'data-type': attributes.type,
          };
        },
      },
      name: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-name'),
        renderHTML: (attributes) => {
          if (!attributes.name) {
            return {};
          }
          return {
            'data-name': attributes.name,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type]`,
      },
      {
        tag: `span[data-id]`,
      },
      {
        tag: `span[data-name]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const name = node.attrs.name || '';
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      name,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MentionNodeView);
  },
});
