import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialMediaListeningOnboardingCompleted = () => {
  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        You did it! Sit back and relax while we work our magic
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        We’ll email you when we’re ready. 💌
      </Typography>

      <Box>
        <Box
          component="img"
          src="/gif/onboarding-success.gif"
          height="60vh"
          width="100%"
          sx={{
            objectFit: 'cover',
            borderRadius: theme.spacing(3),
            overflow: 'hidden',
          }}
        />
      </Box>
    </Box>
  );
};
