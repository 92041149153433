import React from 'react';

export const useListViewKeyboardHandler = ({
  containerRef,
  data,
  onEnter,
}: {
  containerRef: React.RefObject<HTMLElement>;
  data: any[];
  onEnter: (index: number) => void;
}) => {
  const [curIndex, setCurIndex] = React.useState<number>(0);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowUp') {
        const newIndex = curIndex > 0 ? curIndex - 1 : data.length - 1;
        setCurIndex(newIndex);

        // Scroll item into view
        const container = containerRef.current;
        const item = container?.children[newIndex] as HTMLElement;
        if (item) {
          item.scrollIntoView({
            block: 'nearest',
          });
        }

        return true;
      }

      if (event.key === 'ArrowDown') {
        const newIndex = (curIndex + 1) % data.length;
        setCurIndex(newIndex);

        // Scroll item into view
        const container = containerRef.current;
        const item = container?.children[newIndex] as HTMLElement;
        if (item) {
          item.scrollIntoView({
            block: 'nearest',
          });
        }

        return true;
      }

      if ((event.key === 'Enter' || event.key === 'Tab') && data?.[curIndex]) {
        onEnter?.(curIndex);
        return true;
      }

      return false;
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [curIndex, data, onEnter]); // eslint-disable-line

  return {
    curIndex,
    setCurIndex,
  };
};
