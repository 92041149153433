import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  PostFragmentPostPermissionDialogViewFragmentDoc,
  useGetPostForUsePostPermissionsDialogViewQuery,
} from 'graphql/generated';
import { PostPermissionDialogView } from '../views';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetPostForUsePostPermissionsDialogView($postId: String!) {
    post(id: $postId) {
      id
      ...PostFragmentPostPermissionDialogView
    }
  }
  ${PostFragmentPostPermissionDialogViewFragmentDoc}
`;

type UsePostPermissionDialogViewProps = {
  postId: string;
};

export const usePostPermissionDialogView = ({
  postId,
}: UsePostPermissionDialogViewProps) => {
  const {
    isOpen: isOpenPermissionDialog,
    onOpen: onOpenPermissionDialog,
    onClose: onClosePermissionDialog,
  } = useDisclosure();

  const { data: postData } = useGetPostForUsePostPermissionsDialogViewQuery({
    variables: {
      postId,
    },
    // only call mutation when the dialog is opened
    skip: !isOpenPermissionDialog,
  });
  const post = postData?.post;

  const renderContent = () => {
    if (!post) return null;

    return !isOpenPermissionDialog ? null : (
      <PostPermissionDialogView
        post={post}
        open={isOpenPermissionDialog}
        onClose={onClosePermissionDialog}
        // eslint-disable-next-line react/jsx-no-useless-fragment
        renderCustomButton={() => <></>}
      />
    );
  };

  return {
    renderContent,
    openDialog: onOpenPermissionDialog,
  };
};
