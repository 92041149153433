import { gql } from '@apollo/client';
import { useGetPostViewersQuery } from 'graphql/generated';
import { UserInfo } from 'features/collaboration';
import { useEffect, useMemo } from 'react';
import { PostViewers, POST_FRAGMENT_POST_VIEWERS } from './PostViewers';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetPostViewers($id: String!) {
    post(id: $id) {
      ...PostFragmentPostViewers
    }
    ${POST_FRAGMENT_POST_VIEWERS}
  }
`;

type PostCollaborativeViewersProps = {
  postId: string;
  onlineUsers: UserInfo[];
};

export const PostCollaborativeViewers = (
  props: PostCollaborativeViewersProps,
) => {
  const { postId, onlineUsers } = props;

  const { data, loading, refetch } = useGetPostViewersQuery({
    variables: {
      id: postId,
    },
  });

  const isFirstLoading = loading && !data;

  useEffect(() => {
    // waiting about 3 seconds to make sure our webhooks was called and DB was updated
    setTimeout(() => {
      refetch();
    }, 3000);
  }, [onlineUsers, refetch]);

  const post = useMemo(() => {
    return (
      data?.post || {
        id: postId,
        viewers: [],
      }
    );
  }, [postId, data]);

  return isFirstLoading ? null : (
    <PostViewers post={post} onlineUsers={onlineUsers} />
  );
};
