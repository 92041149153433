import { gql } from '@apollo/client';
import { useClipboard, useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { BottomSheet } from 'components/common/BottomSheet/BottomSheet';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { toast } from 'components/common/Toast';
import { IconBoldArrowLeft1 } from 'components/icons/components/bold/IconBoldArrowLeft1';
import { IconBoldCloseSquare } from 'components/icons/components/bold/IconBoldCloseSquare';
import { IconBoldSidebarLeft } from 'components/icons/components/bold/IconBoldSidebarLeft';
import { IconBoldSidebarRight } from 'components/icons/components/bold/IconBoldSidebarRight';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearMessage } from 'components/icons/components/linear/IconLinearMessage';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { PostManagerProvider } from 'features/juicebox/contexts';
import {
  NOTE_COLORS,
  NoteDetailContentView,
  getNoteColorFromColor,
} from 'features/note';
import {
  PostFragmentNoteDetailContentViewFragmentDoc,
  PostFragmentPostDetailContentViewFragmentDoc,
  PostFragmentPostDetailSidebarViewFragmentDoc,
  PostFragmentPostPreviewWindowViewFragment,
  PostType,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { PostDetailContentView, PostDetailSidebarView } from '../detail';

export const POST_FRAGMENT_POST_PREVIEW_WINDOW_VIEW = gql`
  fragment PostFragmentPostPreviewWindowView on PostModel {
    id
    color
    type
    ...PostFragmentPostDetailContentView
    ...PostFragmentNoteDetailContentView
    ...PostFragmentPostDetailSidebarView
  }
  ${PostFragmentPostDetailContentViewFragmentDoc}
  ${PostFragmentNoteDetailContentViewFragmentDoc}
  ${PostFragmentPostDetailSidebarViewFragmentDoc}
`;

export type PostPreviewWindowViewProps = {
  post: PostFragmentPostPreviewWindowViewFragment;
  sx?: SxProps;

  onClose: () => void;

  /**
   * Used to manually trigger refetching of data when a new note is created
   */
  refetchData?: () => void;
};

export const PostPreviewWindowView = (props: PostPreviewWindowViewProps) => {
  const { post, sx, onClose } = props;

  const url = post.urlMetadata?.[0]?.url;
  const { onCopy } = useClipboard(url);

  const themeColor = useMemo(() => {
    // TODO: currently only note have color picker
    // if note has color; use it else use random color for post
    if (post?.color && post.type === PostType.Note) {
      return getNoteColorFromColor(post.color);
    }

    // Get a random color from the set of predefine colors
    return NOTE_COLORS[Math.floor(Math.random() * NOTE_COLORS.length)];
  }, [post?.color]); // eslint-disable-line

  const isMobileView = useMediaQueryMobile();
  const {
    isOpen: isSidebarOpen,
    onClose: closeSidebar,
    onToggle: toggleSidebar,
  } = useDisclosure({
    defaultIsOpen: true,
  });

  return (
    <PostManagerProvider>
      <Box
        sx={{
          width: 1024,
          height: 768,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}
      >
        <Box
          sx={{
            bgcolor: theme.colors?.primary.white,
            py: 5,
            px: 6,
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `2px solid ${theme.colors?.utility[300]}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <IconButton
              size="small"
              sx={{ p: 0, color: theme.colors?.primary.black }}
              onClick={onClose}
            >
              <IconBoldArrowLeft1 />
            </IconButton>
            <Typography variant="headline-xs">{post.title}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 4,
              alignItems: 'center',
              button: { p: 0, color: theme.colors?.primary.black },
            }}
          >
            <IconButton size="small" onClick={toggleSidebar}>
              <IconLinearMessage />
            </IconButton>
            {post.type !== PostType.Note && (
              <IconButton
                size="small"
                onClick={() => {
                  onCopy();
                  toast({
                    type: 'success',
                    message: 'Copied to clipboard',
                  });
                }}
              >
                <IconOutlineLink2 />
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() => {
                window.open(
                  post.type === PostType.Note
                    ? PlotRoutes.juiceboxNote({
                        id: post.id,
                      })
                    : PlotRoutes.juice(post.id),
                  '_blank',
                );
              }}
            >
              <IconLinearExport2 />
            </IconButton>
            <IconButton size="small" onClick={onClose}>
              <IconBoldCloseSquare />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
          <Box
            sx={{
              flex: 1,
              color: themeColor?.textColor,
              bgcolor: themeColor?.color,
              position: 'relative',
            }}
          >
            {post.type === PostType.Note ? (
              <NoteDetailContentView
                note={post}
                allowNextAndPrevNavigation={false}
                refetchData={props.refetchData}
                componentsProps={{
                  noteView: {
                    componentsProps: {
                      content: {
                        componentsProps: {
                          toolbar: {
                            sx: {
                              mb: 6,
                            },
                          },
                        },
                      },
                    },
                  },
                }}
              />
            ) : (
              <PostDetailContentView
                post={post}
                allowNextAndPrevNavigation={false}
              />
            )}
            {!isMobileView && (
              <IconButtonWithTooltip
                tooltip="Toggle sidebar"
                size="small"
                onClick={toggleSidebar}
                sx={{
                  color: 'inherit',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  m: 6,
                  p: 0,
                  transition: '0.2s all',
                  opacity: 0.4,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                {isSidebarOpen ? (
                  <IconBoldSidebarRight />
                ) : (
                  <IconBoldSidebarLeft />
                )}
              </IconButtonWithTooltip>
            )}
          </Box>
          {!isMobileView ? (
            <Box
              sx={{
                width: isSidebarOpen ? '33.33%' : 0,
                transition: '0.2s all',
              }}
            >
              <PostDetailSidebarView post={post} />
            </Box>
          ) : (
            <BottomSheet
              onClose={closeSidebar}
              isOpen={isSidebarOpen}
              showCloseButton
              sx={{
                zIndex: 1400,
              }}
            >
              <PostDetailSidebarView post={post} />
            </BottomSheet>
          )}
        </Box>
      </Box>
    </PostManagerProvider>
  );
};
