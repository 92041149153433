import { Box, Grid, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

type SocialPostCommentCardViewSkeletonProps = {
  numberOfCards?: number;
};

export const SocialPostCommentCardViewSkeleton = (
  props: SocialPostCommentCardViewSkeletonProps,
) => {
  const { numberOfCards = 1 } = props;

  return (
    <Grid container spacing={8}>
      {Array(numberOfCards)
        .fill(0)
        .map((card, index) => {
          return (
            <Grid item key={`${card}-${index}`} xs={12} sm={6} md={4} xl={3}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(2),
                }}
              >
                <Skeleton
                  variant="rounded"
                  width={theme.spacing(37)}
                  height={theme.spacing(49)}
                  sx={{
                    bgcolor: theme.colors?.utility[600],
                  }}
                />
                <Skeleton
                  variant="rounded"
                  height={theme.spacing(20)}
                  width={theme.spacing(48)}
                  sx={{
                    bgcolor: theme.colors?.utility[600],
                  }}
                />
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};
