import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { FALLBACK_UNGROUP_ID } from 'features/task/constants';
import { TaskListItemViewSkeleton } from 'features/task/views';
import {
  TaskFilters,
  TaskFragmentTaskListItemViewFragment,
  TaskGroupBy,
  useGetTaskGroupsForTaskListWithKeyboardShortcutsGroupListViewQuery,
} from 'graphql/generated';
import { useMemo } from 'react';
import { TaskListWithKeyboardShortcutsGroupListItemView } from '../groupListItem';
import { TaskListWithKeyboardShortcutsListViewProps } from '../list';

// eslint-disable-next-line
gql`
  query GetTaskGroupsForTaskListWithKeyboardShortcutsGroupListView(
    $groupBy: TaskGroupBy!
    $after: String
    $taskSearchArgs: GetTasksArgs!
  ) {
    taskGroups(
      groupBy: $groupBy
      after: $after
      taskSearchArgs: $taskSearchArgs
    ) {
      data {
        id
        tasks {
          meta {
            totalCount
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export type TaskListWithKeyboardShortcutsGroupListViewProps = {
  groupBy: TaskGroupBy;
  taskFilters?: TaskFilters;
  sx?: SxProps;
  componentsProps?: {
    taskList?: Pick<
      TaskListWithKeyboardShortcutsListViewProps,
      'componentsProps'
    >;
  };
  onTaskGroupChange?: (
    groupId: string,
    tasks: TaskFragmentTaskListItemViewFragment[],
  ) => void;
} & Pick<TaskListWithKeyboardShortcutsListViewProps, 'onFocusedTaskChange'>;

export const TaskListWithKeyboardShortcutsGroupListView = (
  props: TaskListWithKeyboardShortcutsGroupListViewProps,
) => {
  const {
    groupBy,
    taskFilters = {},
    sx,
    componentsProps = {},
    onTaskGroupChange,
    onFocusedTaskChange,
  } = props;

  const { user } = useUserContext();

  const { data, loading } =
    useGetTaskGroupsForTaskListWithKeyboardShortcutsGroupListViewQuery({
      variables: {
        groupBy,
        taskSearchArgs: {
          filters: taskFilters,
        },
      },
    });
  const taskGroups = useMemo(() => data?.taskGroups?.data || [], [data]);

  // Some custom processing of taskGroups
  const finalTaskGroups = useMemo(() => {
    let groups = [...taskGroups];

    // Here we hard-code some filtering depending on the groupBy value
    switch (groupBy) {
      case TaskGroupBy.Post: {
        groups = groups.filter((group) => group.tasks.meta.totalCount > 0);

        break;
      }
      case TaskGroupBy.Owner: {
        // Always push current user's group (if exists) to the end
        const currentUserGroup = groups.find((group) => group.id === user?.id);

        if (currentUserGroup) {
          groups = groups
            .filter((group) => group.id !== user?.id)
            .concat([currentUserGroup]);
        }

        break;
      }
      default: {
        break;
      }
    }

    // Push group with id FALLBACK_UNGROUP_ID to the end of the list
    // if it exists.
    const fallbackGroup = groups.find(
      (group) => group.id === FALLBACK_UNGROUP_ID,
    );

    if (fallbackGroup) {
      groups = groups
        .filter((group) => group.id !== FALLBACK_UNGROUP_ID)
        .concat([fallbackGroup]);
    }

    return groups;
  }, [taskGroups, groupBy, user]);

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" py={3} px={6} gap={4}>
        <TaskListItemViewSkeleton />
        <TaskListItemViewSkeleton />
        <TaskListItemViewSkeleton />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, ...sx }}>
      {finalTaskGroups.map((taskGroup) => {
        return (
          <TaskListWithKeyboardShortcutsGroupListItemView
            key={taskGroup.id}
            groupId={taskGroup.id}
            groupBy={groupBy}
            taskFilters={taskFilters}
            onTaskListChange={(tasks) =>
              onTaskGroupChange?.(taskGroup.id, tasks)
            }
            onFocusedTaskChange={onFocusedTaskChange}
            componentsProps={{
              taskList: componentsProps?.taskList,
            }}
          />
        );
      })}
    </Box>
  );
};
