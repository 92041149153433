import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid } from '@mui/material';
import { BrandQuestionsInboundCommentInsights } from 'features/brandCompetitors';
import {
  SLAAnalyticsFilters,
  SLAAnalyticsSelectedFilterItem,
  SLABrandContentTypeSelector,
  SLABrandInboundCreatorDemographicsView,
  SLABrandInboundGraphView,
  SLABrandInboundSentimentsView,
  SLABrandInboundSummaryView,
  SLABrandInboundTopHashtagsView,
  SLABrandInboundTopPostsView,
} from 'features/socialListeningAnalytics';
import { SOCIAL_LISTENING_ANALYTICS_BRAND_FILTERS } from 'features/socialListeningAnalytics/constants';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
  useGetBrandForSlaBrandAnalyticsInboundViewQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { SLABrandInboundTopCreatorsView } from '../slaBrandInboundTopCreators';
import { SLABrandTopCreatorPostsView } from '../slaBrandTopCreatorPosts';

type Props = {
  brandId: string;
};

export const SLABrandAnalyticsInboundView = ({ brandId }: Props) => {
  const [selectedCreatorId, setSelectedCreatorId] = useState('');
  const { value: _selectedFilters, setValue: setSelectedFilters } =
    useLocalStorage<
      Partial<
        BrandInboundFiltersInput & {
          generation?: SocialPostGenerationDemographic[];
          gender?: SocialPostGenderDemographic[];
        }
      >
    >(SOCIAL_LISTENING_ANALYTICS_BRAND_FILTERS + brandId, {});

  const selectedFilters = useMemo(() => {
    return _selectedFilters as BrandInboundFiltersInput & {
      generation?: SocialPostGenerationDemographic[];
      gender?: SocialPostGenderDemographic[];
    };
  }, [_selectedFilters]);
  const topCreatorPostsDisclosure = useDisclosure();

  const { data: brandData } = useGetBrandForSlaBrandAnalyticsInboundViewQuery({
    variables: { brandId },
    skip: !brandId,
  });
  const brand = brandData?.brand;

  const updateFilters = useCallback(
    (
      newFilters: Partial<
        BrandInboundFiltersInput & {
          generation?: SocialPostGenerationDemographic[];
          gender?: SocialPostGenderDemographic[];
        }
      >,
    ) => {
      setSelectedFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters, ...newFilters };
        if (JSON.stringify(updatedFilters) !== JSON.stringify(prevFilters)) {
          return updatedFilters;
        }
        return prevFilters;
      });
    },
    [setSelectedFilters],
  );

  const renderSelectedFilters = useMemo(() => {
    if (
      !selectedFilters.sources?.length &&
      !selectedFilters.gender?.length &&
      !selectedFilters.generation?.length
    ) {
      return null;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        {(!!selectedFilters.gender?.length ||
          !!selectedFilters.generation?.length) && (
          <SLAAnalyticsSelectedFilterItem
            selectedFilters={{
              gender: selectedFilters.gender,
              generation: selectedFilters.generation,
            }}
            onChange={updateFilters}
            type="demographic"
          />
        )}
        {!!selectedFilters.sources?.length && (
          <SLAAnalyticsSelectedFilterItem
            selectedFilters={selectedFilters}
            onChange={updateFilters}
            type="source"
          />
        )}
      </Box>
    );
  }, [selectedFilters, updateFilters]);

  const brandInboundFilters = useMemo(() => {
    const { gender, generation, ...rest } = selectedFilters;
    return {
      ...rest,
      brandId,
      contentType: selectedFilters?.contentType ?? BrandContentType.Owned,
      dateRange: {
        endDate: selectedFilters.dateRange?.endDate || moment().toDate(),
        startDate:
          selectedFilters.dateRange?.startDate ||
          moment().subtract(7, 'day').startOf('day').toDate(),
      },
    } as BrandInboundFiltersInput;
  }, [brandId, selectedFilters]);

  return (
    <Box>
      <Box
        sx={{
          py: 4,
          my: 2,
          borderBottom: `1px solid ${theme.colors?.utility[300]}`,
          borderTop: `1px solid ${theme.colors?.utility[300]}`,
          position: 'sticky',
          top: 64,
          zIndex: 10,
          backgroundColor: theme.colors?.primary.white,
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {brand && (
            <SLABrandContentTypeSelector
              brand={brand}
              contentType={
                selectedFilters?.contentType ?? BrandContentType.Owned
              }
              onChange={(value) => {
                updateFilters({
                  contentType: value,
                });
              }}
            />
          )}

          <SLAAnalyticsFilters
            brandId={brandId}
            hideOptions={{
              category: true,
            }}
            contentType={selectedFilters.contentType}
            onChange={updateFilters}
            selectedFilters={selectedFilters}
          />
        </Box>
        {renderSelectedFilters}
      </Box>

      <Grid mt={2} container spacing={6} columns={12}>
        <Grid item xs={12} lg={4}>
          <Box
            display="flex"
            flexDirection="column"
            gap={6}
            width="100%"
            flex={1}
            height="100%"
          >
            <SLABrandInboundSummaryView filters={brandInboundFilters} />
            {brand && (
              <SLABrandInboundTopHashtagsView
                brand={brand}
                filters={brandInboundFilters}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <SLABrandInboundTopPostsView filters={brandInboundFilters} />
        </Grid>
      </Grid>

      {selectedFilters.contentType === BrandContentType.Ugc && (
        <Box
          my={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <SLABrandInboundTopCreatorsView
            filters={brandInboundFilters}
            onSelectCreator={(creatorId) => {
              setSelectedCreatorId(creatorId);
              topCreatorPostsDisclosure.onOpen();
            }}
          />
          <SLABrandInboundCreatorDemographicsView
            filters={selectedFilters}
            onChange={({ selectedGender, selectedGeneration }) => {
              updateFilters({
                gender: selectedGender,
                generation: selectedGeneration,
              });
            }}
          />
        </Box>
      )}

      <Box my={6}>
        <SLABrandInboundGraphView filters={brandInboundFilters} />
      </Box>

      {selectedCreatorId && brand && (
        <SLABrandTopCreatorPostsView
          brand={brand}
          filters={brandInboundFilters}
          creatorId={selectedCreatorId}
          isOpen={topCreatorPostsDisclosure.isOpen}
          onClose={topCreatorPostsDisclosure.onClose}
        />
      )}

      <SLABrandInboundSentimentsView
        filters={brandInboundFilters}
        hideInPosts={selectedFilters.contentType === BrandContentType.Owned}
      />

      <BrandQuestionsInboundCommentInsights filters={brandInboundFilters} />
    </Box>
  );
};
