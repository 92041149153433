import { useClipboard } from '@dwarvesf/react-hooks';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Radio,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearMaximize4 } from 'components/icons/components/linear/IconLinearMaximize4';
import { IconLinearMinimize } from 'components/icons/components/linear/IconLinearMinimize';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { IconOutlineCopy } from 'components/icons/components/outline/IconOutlineCopy';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningPostDetailView,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening';
import { HoverPlayableVideo } from 'features/socialMediaListening/views/postsGrid/postCard/HoverPlayableVideo';
import { StaticImage } from 'features/socialMediaListening/views/postsGrid/postCard/StaticImage';
import { platformIconMap } from 'features/socialPost';
import {
  BrandFragmentBrandCreatorPostsFragment,
  SocialPostType,
  SortByInputForSocialPostsByCreatorsForCompetitor,
  useGetCompetitorForBrandCreatorPostsQuery,
  useGetCreatorForBrandCreatorPostsQuery,
  useGetSocialPostsByCreatorsForCompetitorForBrandCompetitorPostsQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

type Props = {
  competitorId: string;
  competitorBrand: BrandFragmentBrandCreatorPostsFragment;
  creatorId: string;
  isOpen: boolean;
  onClose: () => void;
  defaultDateRange?: {
    startDate: Date;
    endDate: Date;
  };
};

export const BrandCreatorPostsView = ({
  competitorId,
  competitorBrand,
  creatorId,
  isOpen,
  onClose,
  defaultDateRange,
}: Props) => {
  const { generateLink } = useSocialListeningPostLinkGenerator();
  const [sortKey, setSortKey] = useState(
    SortByInputForSocialPostsByCreatorsForCompetitor.PlayCount,
  );
  const [copied, setCopied] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [currentPostId, setCurrentPostId] = useState('');

  const handleCopyLink = () => {
    onCopyLink();
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const { data: creatorData } = useGetCreatorForBrandCreatorPostsQuery({
    variables: { data: { creatorId } },
    skip: !creatorId,
    fetchPolicy: 'cache-and-network',
  });
  const creator = creatorData?.getCreator;

  const { onCopy: onCopyLink } = useClipboard(
    `${window.location.origin}${PlotRoutes.socialListeningCompetitor(
      competitorId,
      competitorBrand.id,
      creator?.platform,
      { creatorId },
    )}`,
  );

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().toDate(),
  });

  useEffect(() => {
    if (defaultDateRange) {
      setDateRange(defaultDateRange);
    }
  }, [defaultDateRange]);

  const { data: competitorData } = useGetCompetitorForBrandCreatorPostsQuery({
    variables: {
      competitorId,
      data: {
        creatorId,
        competitorId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    },
    skip: !creatorId,
    fetchPolicy: 'cache-and-network',
  });
  const competitor = competitorData?.competitor;

  const {
    data: socialPostsData,
    loading: socialPostsLoading,
    fetchMore: fetchMoreSocialPosts,
  } = useGetSocialPostsByCreatorsForCompetitorForBrandCompetitorPostsQuery({
    variables: {
      data: {
        competitorId,
        creatorId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        sortBy: sortKey,
      },
    },
    skip: !creatorId,
    fetchPolicy: 'cache-and-network',
  });
  const socialPosts = socialPostsData?.getSocialPostsByCreatorsForCompetitor;

  const handleBackClick = () => {
    if (currentPostId) {
      setCurrentPostId('');
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: fullScreen ? '100vw' : '90vw',
          height: fullScreen ? '100vh' : '90vh',
          backgroundColor: '#FAF3EC88',
          maxWidth: 'unset',
          maxHeight: 'unset',
          backdropFilter: 'blur(20px)',
          borderRadius: fullScreen ? 0 : 4,
          margin: fullScreen ? 0 : 'auto',
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.colors?.primary.parchment,
            borderBottom: `1px solid ${theme.colors?.utility[200]}`,
            p: 4,
          }}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <IconButton onClick={handleBackClick} disableRipple>
              <IconOutlineArrowLeft size={20} />
            </IconButton>
            <Typography
              variant="body-xl"
              fontWeight={600}
              color={theme.colors?.utility[700]}
            >
              {competitorBrand.name}'s Top Creators /
            </Typography>
            <Typography variant="body-xl" fontWeight={600}>
              {creator?.nickName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={3}>
            <Typography
              variant="body-lg"
              color={theme.colors?.primary.black}
              sx={{
                borderRadius: 20,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                backgroundColor: 'rgba(35, 6, 3, 0.10)',
                p: theme.spacing(1, 3),
                cursor: 'pointer',
              }}
              onClick={handleCopyLink}
            >
              <IconOutlineCopy size={16} />
              {copied ? 'Copied' : 'Copy link'}
            </Typography>
            <IconButton
              onClick={() => setFullScreen(!fullScreen)}
              disableRipple
            >
              {fullScreen ? (
                <IconLinearMinimize
                  style={{ color: theme.colors?.utility[700] }}
                  size={20}
                />
              ) : (
                <IconLinearMaximize4
                  style={{ color: theme.colors?.utility[700] }}
                  size={20}
                />
              )}
            </IconButton>
            <IconButton onClick={onClose} disableRipple>
              <IconBoldCloseCircle
                style={{ color: theme.colors?.utility[700] }}
                size={24}
              />
            </IconButton>
          </Box>
        </Box>
        {!currentPostId && (
          <Box
            p={3}
            flexGrow={1}
            overflow="auto"
            sx={{ backgroundColor: '#FAF3ECCC' }}
          >
            <InfiniteScroll
              loadMore={() => {
                fetchMoreSocialPosts({
                  variables: {
                    data: {
                      competitorId,
                      creatorId,
                      startDate: dateRange.startDate,
                      endDate: dateRange.endDate,
                      sortBy: sortKey,
                      skip: socialPosts?.data?.length,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      getSocialPostsByCreatorsForCompetitor: {
                        ...fetchMoreResult.getSocialPostsByCreatorsForCompetitor,
                        data: [
                          ...prev.getSocialPostsByCreatorsForCompetitor.data,
                          ...fetchMoreResult
                            .getSocialPostsByCreatorsForCompetitor.data,
                        ],
                      },
                    };
                  },
                });
              }}
              hasMore={
                socialPostsData?.getSocialPostsByCreatorsForCompetitor.pageInfo
                  .hasNextPage
              }
              threshold={50}
              useWindow={false}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                gap={2}
                mb={3}
                justifyContent="space-between"
                width="100%"
                sx={{
                  p: 4,
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography variant="headline-lg" mr={4}>
                      {creator?.nickName}
                    </Typography>
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.utility[700]}
                    >
                      @{creator?.handle}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body-xl"
                    fontWeight={600}
                    mt={2}
                    color={theme.colors?.utility[700]}
                  >
                    {socialPosts?.meta.totalCount} posts
                  </Typography>
                </Box>

                <SocialMediaListeningFilterByDateRangeButton
                  onChange={(range) => {
                    if (range[0] && range[1]) {
                      setDateRange({
                        startDate: range[0],
                        endDate: range[1],
                      });
                    }
                  }}
                  selectedDateRange={[dateRange.startDate, dateRange.endDate]}
                />
              </Box>

              <Box sx={{ m: 4 }}>
                <Grid
                  container
                  spacing={2}
                  mb={3}
                  sx={{
                    backgroundColor: theme.colors?.primary.parchment,
                    borderRadius: 2,
                    p: 4,
                  }}
                >
                  <Grid item xs={6}>
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Number of brand mentions
                        <Tooltip title="Number of brand mentions">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                      >
                        {socialPosts?.meta.totalCount}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      borderLeft: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      pl={4}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Number of followers
                        <Tooltip title="Number of followers creator has">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                      >
                        {creator?.followerCount
                          ? formatBigNumber(creator?.followerCount)
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                  my: 4,
                }}
              />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                m={4}
                mb={2}
              >
                <Typography variant="headline-sm">Posts</Typography>
                <ContextMenu
                  sx={{
                    '& .MuiMenuItem-root': {
                      p: 0,
                      color: theme.colors?.primary.black,
                    },
                  }}
                  options={[
                    {
                      renderOption: () => (
                        <Box display="flex" alignItems="center">
                          <Radio
                            checked={
                              sortKey ===
                              SortByInputForSocialPostsByCreatorsForCompetitor.PlayCount
                            }
                          />
                          <Typography variant="body-lg">Play Count</Typography>
                        </Box>
                      ),
                      onClick: () => {
                        setSortKey(
                          SortByInputForSocialPostsByCreatorsForCompetitor.PlayCount,
                        );
                      },
                    },
                    {
                      renderOption: () => (
                        <Box display="flex" alignItems="center">
                          <Radio
                            checked={
                              sortKey ===
                              SortByInputForSocialPostsByCreatorsForCompetitor.EngagementRate
                            }
                          />
                          <Typography variant="body-lg">
                            Engagement Rate
                          </Typography>
                        </Box>
                      ),
                      onClick: () => {
                        setSortKey(
                          SortByInputForSocialPostsByCreatorsForCompetitor.EngagementRate,
                        );
                      },
                    },
                    {
                      renderOption: () => (
                        <Box display="flex" alignItems="center">
                          <Radio
                            checked={
                              sortKey ===
                              SortByInputForSocialPostsByCreatorsForCompetitor.LikeCount
                            }
                          />
                          <Typography variant="body-lg">Like Count</Typography>
                        </Box>
                      ),
                      onClick: () => {
                        setSortKey(
                          SortByInputForSocialPostsByCreatorsForCompetitor.LikeCount,
                        );
                      },
                    },
                  ]}
                  renderButton={() => (
                    <Button
                      sx={{
                        backgroundColor: theme.colors?.utility[275],
                        color: theme.colors?.primary.black,
                        borderRadius: theme.spacing(2),
                        display: 'flex',
                        gap: 2,
                        p: theme.spacing(1, 2),
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: theme.colors?.utility[275],
                        },
                      }}
                      disableRipple={false}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          backgroundColor: 'rgba(35, 6, 3, 0.05);',
                          p: theme.spacing(1),
                          borderRadius: theme.spacing(1),
                        }}
                      >
                        <IconLinearSort size={16} />
                      </Box>
                      <Typography fontWeight={500} variant="body-xl">
                        Sort by
                      </Typography>
                    </Button>
                  )}
                />
              </Box>

              {!socialPostsLoading && (
                <Box sx={{ m: 4 }}>
                  {socialPosts?.data?.length ? (
                    <Grid container spacing={2}>
                      {socialPosts?.data?.map((post) => {
                        const Icon = platformIconMap[post.platform];

                        const renderActionsTopRight = (isHovered: boolean) => (
                          <Box
                            display="flex"
                            gap={2}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {isHovered && post.creator?.handle && (
                              <IconButton
                                disableRipple
                                sx={{
                                  p: 1,
                                  backgroundColor: 'rgba(35, 6, 3, 0.10)',
                                  color: theme.colors?.primary.white,
                                  backdropFilter: 'blur(4px)',
                                }}
                                onClick={() => {
                                  window.open(
                                    generateLink(post) || '',
                                    '_blank',
                                  );
                                }}
                              >
                                <IconLinearExport2 size={16} />
                              </IconButton>
                            )}
                            {Icon && (
                              <Icon
                                size={16}
                                style={{
                                  color: theme.colors?.primary.white,
                                }}
                              />
                            )}
                          </Box>
                        );

                        const renderActionsBottom = () => (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                          >
                            <Box
                              display="flex"
                              gap={1}
                              alignItems="center"
                              ml={2}
                              width="70%"
                              maxWidth="70%"
                            >
                              <Avatar
                                src={post.creator?.profilePictureUrl || ''}
                                sx={{
                                  width: theme.spacing(4),
                                  height: theme.spacing(4),
                                }}
                              />
                              <Typography
                                color={theme.colors?.primary.parchment}
                                variant="body-lg"
                                fontWeight={600}
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {post.creator?.handle}
                              </Typography>
                            </Box>

                            {post.type === SocialPostType.Video && (
                              <Box
                                mr={2}
                                display="flex"
                                gap={1}
                                alignItems="center"
                              >
                                <IconBoldPlay
                                  size={16}
                                  color={theme.colors?.primary.parchment}
                                />
                                <Typography
                                  color={theme.colors?.primary.parchment}
                                  variant="body-lg"
                                  fontWeight={600}
                                >
                                  {formatBigNumber(post.playCount || 0)}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        );

                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            lg={2.4}
                            key={post.id}
                          >
                            <Box
                              sx={{
                                position: 'relative',
                              }}
                              onClick={() => setCurrentPostId(post.id)}
                            >
                              {post.type === SocialPostType.Video ? (
                                <HoverPlayableVideo
                                  thumbnailUrl={post.thumbnailUrl || ''}
                                  videoUrl={post.videoUrl || ''}
                                  renderActionsTopRight={renderActionsTopRight}
                                  renderActionsBottom={renderActionsBottom}
                                />
                              ) : (
                                <StaticImage
                                  thumbnailUrl={post.thumbnailUrl || ''}
                                  renderActionsTopRight={renderActionsTopRight}
                                  renderActionsBottom={renderActionsBottom}
                                />
                              )}
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="body-xl"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        No posts found
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </InfiniteScroll>
          </Box>
        )}
        {currentPostId && competitor?.forBrand && (
          <Box
            px={12}
            sx={{
              backgroundColor: theme.colors?.primary.parchment,
              minHeight: theme.spacing(100),
              overflow: 'auto',
            }}
          >
            <Box
              p={4}
              mt={8}
              sx={{
                backgroundColor: theme.colors?.primary.white,
                borderRadius: theme.spacing(4),
              }}
            >
              <SocialMediaListeningPostDetailView
                brandId={competitor?.forBrand.id}
                socialPostId={currentPostId}
                componentProps={{
                  modalView: true,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
