import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { SocialPostFragmentPostVideoDialogVideoTranscriptFragment } from 'graphql/generated';
import { useEffect, useRef } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentPostVideoDialogVideoTranscript on SocialPostModel {
    id
    audioTranscription {
      start
      end
      speaker
      sentence
    }
  }
`;

type Props = {
  currentDurationMs: number;
  post: SocialPostFragmentPostVideoDialogVideoTranscriptFragment;
};

export const VideoTranscript = ({ post, currentDurationMs }: Props) => {
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const transcripts = post.audioTranscription;

  useEffect(() => {
    const currentTranscriptIndex = transcripts.findIndex(
      (transcript, index) =>
        currentDurationMs >= transcript.start &&
        (index === transcripts.length - 1 ||
          currentDurationMs < transcripts[index + 1].start),
    );

    if (currentTranscriptIndex !== -1 && refs.current[currentTranscriptIndex]) {
      refs.current?.[currentTranscriptIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [currentDurationMs]);

  if (!transcripts.length) {
    return (
      <Box width="100%" height="100%">
        <Typography
          variant="body-lg"
          color={theme.colors?.utility[600]}
          fontWeight={600}
        >
          No transcript available
        </Typography>
      </Box>
    );
  }

  return (
    <Box width="100%" height="100%" sx={{ overflowY: 'auto' }}>
      {transcripts.map((transcript, index) => {
        const isCurrentTranscript =
          currentDurationMs >= transcript.start &&
          (index === transcripts.length - 1 ||
            currentDurationMs < transcripts[index + 1].start);

        const isSameSpeakerAsPrevious =
          index > 0 && transcript.speaker === transcripts[index - 1].speaker;

        return (
          <Box
            ref={(el) => (refs.current[index] = el as HTMLDivElement)}
            key={index}
            width="100%"
            sx={{ display: 'flex', gap: 1, flexDirection: 'column', mb: 4 }}
          >
            <Box>
              {!isSameSpeakerAsPrevious && (
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[600]}
                  fontWeight={600}
                >
                  {transcript.speaker}
                </Typography>
              )}
            </Box>
            <Box width="100%">
              <Box display="flex" gap={2} width="100%">
                <Typography
                  display="inline-block"
                  variant="body-sm"
                  color={theme.colors?.utility[500]}
                  fontWeight={500}
                  flex={1}
                  mt={1}
                >
                  {new Date(transcript.start).toISOString().slice(14, 19)}
                </Typography>

                <Typography
                  display="inline-block"
                  flex={4}
                  variant="body-xl"
                  color={theme.colors?.utility[800]}
                  fontWeight={isCurrentTranscript ? 700 : 500}
                >
                  {transcript.sentence}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
