import { Box, SxProps } from '@mui/material';
import { theme } from 'styles/theme/theme';

export type CommandKeyProps = {
  children: string;
  sx?: SxProps;
};

export const CommandKey = (props: CommandKeyProps) => {
  const { children, sx } = props;

  return (
    <Box
      sx={{
        bgcolor: theme.colors?.utility[300],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
        color: theme.colors?.utility[800],
        border: `1px solid ${theme.colors?.utility[500]}`,
        fontWeight: 500,
        fontSize: 12,
        px: 1.5,
        py: 1,
        lineHeight: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
