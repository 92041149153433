import { useEffect } from 'react';
import { Updater, useImmer } from 'use-immer';

interface SessionStorageReturn<T> {
  value: T;
  setValue: Updater<T>;
  removeKey: VoidFunction;
}

function getSessionStorageValue<T>(key: string, defaultValue: T): T {
  const saved = sessionStorage.getItem(key);
  return saved ? (JSON.parse(saved) as T) : defaultValue;
}

export function useSessionStorage<T>(
  key: string,
  defaultValue: T,
): SessionStorageReturn<T> {
  const [value, setValue] = useImmer<T>(() => {
    return getSessionStorageValue<T>(key, defaultValue);
  });

  useEffect(() => {
    setValue(getSessionStorageValue<T>(key, defaultValue));
  }, [JSON.stringify({ defaultValue, key })]);

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  const removeKey = () => {
    sessionStorage.removeItem(key);
  };

  return { value, setValue, removeKey };
}
