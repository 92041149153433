import { gql } from '@apollo/client';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { AvatarGroup } from 'components/common/AvatarGroup';
import { useUserContext } from 'contexts/users/User.context';
import { CollectionMultiPostPreview } from 'features/collection';
import {
  CollectionFragmentCollectionMultiPostPreviewFragmentDoc,
  useGetCollectionForInvitationThroughLinkInvitedToCollectionViewQuery,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { getCustomOperationContext } from 'utils/apollo';

// eslint-disable-next-line
gql`
  query GetCollectionForInvitationThroughLinkInvitedToCollectionView(
    $id: String!
  ) {
    collection(id: $id) {
      id
      name
      totalPosts
      creator {
        id
        ...UserFragmentAvatarGroup
      }
      inviteMembers {
        id
        user {
          id
          ...UserFragmentAvatarGroup
        }
        createdByUser {
          id
          ...UserFragmentAvatarGroup
        }
      }
      ...CollectionFragmentCollectionMultiPostPreview
    }
  }
  ${CollectionFragmentCollectionMultiPostPreviewFragmentDoc}
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type InvitationThroughLinkInvitedToCollectionViewProps = {
  collectionId: string;
  onClose: () => void;
};

export const InvitationThroughLinkInvitedToCollectionView = (
  props: InvitationThroughLinkInvitedToCollectionViewProps,
) => {
  const { collectionId, onClose } = props;

  const navigate = useNavigate();

  const { user } = useUserContext();

  const { data } =
    useGetCollectionForInvitationThroughLinkInvitedToCollectionViewQuery({
      variables: {
        id: collectionId,
      },
      pollInterval: 1000,
      context: getCustomOperationContext({
        suppressTopLevelToast: true,
      }),
    });
  const collection = data?.collection;

  useEffect(() => {
    // No permission to view collection or cannot find collection
    if (!data || data.collection === null) {
      const timeout = setTimeout(() => {
        onClose();
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [data]); // eslint-disable-line

  const invitedByUser = collection?.inviteMembers.find(
    (m) => m.user.id === user?.id,
  )?.createdByUser;

  if (!collection) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 6,
          padding: 15,
          bgcolor: theme.colors?.secondary['dark-maroon'],
          color: theme.colors?.primary.parchment,
          border: 0,
        },
      }}
    >
      <CollectionMultiPostPreview
        variant="card-stack-alt"
        collection={collection}
        sx={{
          width: 350,
          mb: 10,
        }}
        componentsProps={{
          card: {
            sx: {
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: theme.colors?.primary.parchment,
            },
          },
        }}
      />
      <Typography variant="headline-xl" mb={2} fontSize={28}>
        {collection.name}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 8,
        }}
      >
        {invitedByUser && (
          <Typography
            variant="headline-sm"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {' '}
            Invited by {invitedByUser.firstName} •{' '}
          </Typography>
        )}
        <AvatarGroup
          users={[
            collection.creator,
            ...collection.inviteMembers.map((m) => m.user),
          ]}
          max={4}
          avatarSize={20}
        />
        <Typography
          variant="headline-sm"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {' '}
          • {collection.totalPosts} posts
        </Typography>
      </Box>
      <Button
        variant="primary-alt"
        sx={{
          mb: 2,
        }}
        onClick={() => {
          onClose();
          navigate(PlotRoutes.collection(collection.id));
        }}
      >
        <Typography variant="headline-sm">Go to Collection</Typography>
      </Button>
      <Button variant="text" onClick={onClose}>
        <Typography
          variant="headline-sm"
          sx={{
            textAlign: 'center',
            color: theme.colors?.primary.parchment,
          }}
        >
          Back to Plot
        </Typography>
      </Button>
    </Dialog>
  );
};
