import { Box } from '@mui/material';
import { MediaErrorBoundary } from 'components/common/Media/components/MediaErrorBoundary';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { IconOutlineVideo } from 'components/icons/components/outline/IconOutlineVideo';
import { lazy } from 'react';
import { resolveFileIcon } from 'utils/fileIcons';
import { CustomImagePreview } from './CustomImagePreview';
import { CustomVideoPreview } from './CustomVideoPreview';
import { NoPreviewUI } from '../NoPreviewUI';

const CustomPDFPreview = lazy(() => import('./CustomPDFPreview'));

export type CustomRenderingElement =
  | 'img'
  | 'video'
  | 'pdf'
  | 'audio'
  | 'socialMediaPreview';

interface Props {
  element?: CustomRenderingElement;
  url: string;
}

export const CustomRenderingWidget = (props: Props) => {
  const { element, url } = props;

  switch (element) {
    case 'img': {
      const getErrorMessage = () => {
        return (
          <Box sx={{ py: 8, display: 'flex' }}>
            <IconOutlineImage
              style={{ width: 64, height: 64, margin: 'auto' }}
            />
          </Box>
        );
      };

      return (
        <MediaErrorBoundary getErrorMessage={getErrorMessage}>
          <CustomImagePreview url={url} />
        </MediaErrorBoundary>
      );
    }
    case 'video': {
      const getErrorMessage = () => {
        return (
          <Box sx={{ py: 8, display: 'flex' }}>
            <IconOutlineVideo
              style={{ width: 64, height: 64, margin: 'auto' }}
            />
          </Box>
        );
      };

      return (
        <MediaErrorBoundary getErrorMessage={getErrorMessage}>
          <CustomVideoPreview url={url} />
        </MediaErrorBoundary>
      );
    }
    case 'pdf': {
      const getErrorMessage = () => {
        const iconSrc = resolveFileIcon('', 'application/pdf');

        return (
          <Box sx={{ py: 8, display: 'flex' }}>
            <Box
              component="img"
              src={iconSrc}
              sx={{ width: 64, height: 64, m: 'auto' }}
            />
          </Box>
        );
      };

      return (
        <MediaErrorBoundary getErrorMessage={getErrorMessage}>
          <CustomPDFPreview url={url} />
        </MediaErrorBoundary>
      );
    }
    case 'audio':
      return <NoPreviewUI url={url} />;
    default: {
      return null;
    }
  }
};
