import { OrganizationPermission } from 'graphql/generated';
import { useOrganizationPermissions } from 'hooks/permissions/useOrganizationPermissions';

interface Props {
  children: React.ReactNode;
  permissions: OrganizationPermission[];
}

export const OrgPermissionGate = (props: Props) => {
  const { permissions, children } = props;

  const myPermissions = useOrganizationPermissions();

  if (permissions.every((p) => myPermissions.includes(p))) {
    // eslint-disable-next-line -- prevent TS error
    return <>{children}</>;
  }

  return null;
};
