import { Box, Skeleton } from '@mui/material';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { theme } from 'styles/theme';
import { INNER_CONTENT_MAX_WIDTH } from './NoteRichTextEditor';

export const NoteRichTextEditorSkeleton = () => {
  const isMobileView = useMediaQueryMobile();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={8}
      width={isMobileView ? undefined : INNER_CONTENT_MAX_WIDTH}
    >
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        {Array(5)
          .fill({})
          .map((item, idx) => {
            return (
              <Skeleton
                key={idx}
                sx={{
                  width: '100%',
                  height: theme.spacing(6),
                  bgcolor: theme.colors?.utility[500],
                  transform: 'scale(1)',
                }}
              />
            );
          })}
      </Box>
    </Box>
  );
};
