import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useUserContext } from 'contexts/users/User.context';
import { OrganizationBillingFragmentTrendsPaywallFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingTrendsPaywallProps = {
  organizationBilling: OrganizationBillingFragmentTrendsPaywallFragment;
};

export const BillingTrendsPaywall = (props: BillingTrendsPaywallProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { organizationBilling } = props;
  const { isAlertBannerVisible } = useUserContext();

  const [watchingDemo, setWatchingDemo] = useState(false);

  const {
    isOpen: updgradeOpen,
    onOpen: updgradeOnOpen,
    onClose: updgradeOnClose,
  } = useDisclosure();

  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  useEffect(() => {
    setHeaderSx({
      borderBottom: `1px solid ${theme.colors?.primary.parchment}`,
      color: theme.colors?.primary.parchment,
      bgcolor: theme.colors?.utility.blueberry,
      '& svg': {
        color: theme.colors?.primary.parchment,
      },
    });

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.parchment}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          ⚡ Trends
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
    };
  }, [location.pathname]); // eslint-disable-line

  return organizationBilling.trendsEnabled ? null : (
    <Box
      sx={{
        width: '100%',
        height: isAlertBannerVisible ? 'calc(100vh - 132px)' : '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: theme.colors?.utility['blueberry'],
        position: 'relative',
      }}
    >
      {!watchingDemo && (
        <>
          <Box
            p={14}
            display="flex"
            gap={2.5}
            sx={{
              borderRadius: 11,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              maxWidth: theme.spacing(100),
              position: 'absolute',
              zIndex: 2,
              border: `${theme.spacing(3)} solid rgba(255, 255, 255, 0.05)`,
              width: theme.spacing(101),
              height: theme.spacing(107),
            }}
          >
            <Box position="absolute" sx={{ inset: 0 }}>
              <Typography
                fontSize={theme.spacing(20)}
                position="absolute"
                top="-60px"
                right="-56px"
              >
                ⚡
              </Typography>
              <Typography
                fontSize={theme.spacing(20)}
                position="absolute"
                bottom="111px"
                right="-70px"
                // rotate by 90 degrees and flip on x axis
                sx={{ transform: 'rotate(-20deg) scaleX(1)' }}
              >
                🔥
              </Typography>

              <Typography
                fontSize={theme.spacing(20)}
                position="absolute"
                top="70px"
                left="-73px"
                // rotate by 90 degrees and flip on x axis
                sx={{ transform: 'rotate(-12deg) scaleX(1)' }}
              >
                🌊
              </Typography>

              <Typography
                fontSize={theme.spacing(20)}
                position="absolute"
                bottom="-35px"
                left="-73px"
                // rotate by 90 degrees and flip on x axis
                sx={{ transform: 'rotate(-20deg) scaleX(-1)' }}
              >
                🤳
              </Typography>
            </Box>
          </Box>

          <Box
            p={14}
            display="flex"
            gap={2.5}
            sx={{
              background: 'rgba(35, 6, 3, 0.30)',
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.20) inset',
              backdropFilter: 'blur(24px)',
              borderRadius: 11,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 3,
              maxWidth: theme.spacing(100),
              position: 'relative',
              zIndex: 2,
              border: `${theme.spacing(3)} solid rgba(255, 255, 255, 0.05)`,
            }}
          >
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.20)',
                borderRadius: 20,
                p: theme.spacing(1.5, 3),
              }}
            >
              <Typography
                variant="body-sm"
                color={theme.colors?.primary.parchment}
              >
                Unlimited Trends
              </Typography>
            </Box>

            <Typography
              variant="headline-lg"
              fontSize={theme.spacing(7)}
              color={theme.colors?.primary.parchment}
              fontWeight={600}
              textAlign="center"
            >
              Stay updated to the current trends
            </Typography>
            <Typography
              variant="body-xl"
              color={theme.colors?.primary.parchment}
              fontWeight={500}
              textAlign="center"
            >
              Finally, a place to turn your ideas into execution. Manage your
              production calendar in Plot.
            </Typography>

            <Button
              fullWidth
              variant="contained"
              sx={{
                bgcolor: theme.colors?.primary.black,
                ':hover': {
                  bgcolor: theme.colors?.primary.black,
                },
              }}
              onClick={updgradeOnOpen}
            >
              {organizationBilling.hasUsedFreeTrial
                ? 'Upgrade'
                : 'Upgrade or Start Free Trial'}
            </Button>

            <Button
              fullWidth
              sx={{
                bgcolor: 'transparent',
                color: theme.colors?.primary.white,
                borderRadius: theme.spacing(2),
                ':hover': {
                  bgcolor: 'transparent',
                  color: theme.colors?.primary.white,
                },
                p: theme.spacing(6, 3),
                mr: 2,
                flexShrink: 0,
                height: 36,
              }}
              onClick={() => {
                setWatchingDemo(true);
              }}
            >
              <Typography
                variant="body-xl"
                fontWeight={600}
                color={theme.colors?.primary.parchment}
              >
                Watch Demo
              </Typography>
            </Button>

            <BillingUpgradeModal
              isOpen={updgradeOpen}
              onClose={updgradeOnClose}
            />
          </Box>
        </>
      )}

      {watchingDemo && (
        <Box width="80vw" position="relative">
          <Box
            position="absolute"
            top={theme.spacing(-10)}
            right={theme.spacing(-10)}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setWatchingDemo(false);
            }}
          >
            <IconBoldCloseCircle
              size={36}
              color={theme.colors?.primary.parchment}
            />
          </Box>
          <Box
            autoPlay
            controls
            component="video"
            width="80vw"
            src="/videos/TrendsDemo.mp4"
            sx={{
              borderRadius: theme.spacing(6),
              overflow: 'hidden',
            }}
          />
        </Box>
      )}
    </Box>
  );
};
