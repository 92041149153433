import { gql } from '@apollo/client';
import { Box, TextField, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { typography } from 'components/common/Typography/styles';
import { useGetLanguagesForFilterByLanguageQuery } from 'graphql/generated';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

type FilterByLanguageProps = {
  selectedLanguages: string[];
  type?: 'accordion' | 'normal';
  renderTitle?: () => React.ReactNode;
  onChange: (values: { selectedLanguages: string[] }) => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetLanguagesForFilterByLanguage {
    languages {
      name
    }
  }
`;

export const FilterByLanguage = ({
  selectedLanguages,
  type = 'accordion',
  onChange,
  renderTitle,
}: FilterByLanguageProps) => {
  const { data } = useGetLanguagesForFilterByLanguageQuery();

  const dropdownOptions = useMemo(() => {
    const languages = data?.languages ?? [];

    return languages.map((language) => ({
      label: language.name,
      value: language.name,
      onClick: () => {
        onChange({
          selectedLanguages: selectedLanguages.includes(language.name)
            ? selectedLanguages.filter((o) => o !== language.name)
            : [...selectedLanguages, language.name],
        });
      },
      isChecked: selectedLanguages.includes(language.name),
    }));
  }, [data, selectedLanguages, onChange]);

  const [optionsSearch, setOptionsSearch] = useState('');
  const filteredOptions = useMemo(() => {
    if (!optionsSearch) {
      return dropdownOptions;
    }

    const searchRegex = new RegExp(optionsSearch, 'gi');
    return dropdownOptions.filter((option) =>
      searchRegex.test(option.label?.toString() ?? ''),
    );
  }, [optionsSearch, dropdownOptions]);

  const Icon =
    socialListeningFilterIconMap[SocialListeningFiltersKey.Languages];

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    if (type === 'normal') {
      return <Typography variant="subhead-lg">Language</Typography>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon size={16} />
        </Box>

        <Typography variant="subhead-lg">Language</Typography>
      </Box>
    );
  }, [Icon, renderTitle, type]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      defaultOpen={type === 'normal'}
      sx={{
        ...(type === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(type === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      renderDropDownOptionSearchInput={() => (
        <TextField
          variant="outlined"
          placeholder="Search"
          sx={{
            '.MuiOutlinedInput-root': {
              py: '6px !important',
              pl: '40px !important',

              input: {
                ...typography['subhead-lg'],
                p: '0 !important',
                '::placeholder': {
                  color: theme.colors?.utility[600],
                },
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
          value={optionsSearch}
          onChange={(ev) => {
            setOptionsSearch(ev.target.value);
          }}
          onKeyDown={(ev) => {
            ev.stopPropagation();
          }}
        />
      )}
      dropDownOptions={filteredOptions}
    />
  );
};
