import { Tooltip as MuiToolTip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';
import { createStyles, makeStyles } from '@mui/styles';
import { typography } from 'components/common/Typography/styles';
import React from 'react';
import { theme } from 'styles/theme/theme';

export const toolTipStyles = {
  bgcolor: theme.colors?.primary.black,
  color: theme.colors?.primary.parchment,
  padding: theme.spacing(2, 3),
  borderRadius: '8px',
  fontFamily: typography['subhead-sm'],
  maxWidth: 500,
};

const useStyles = makeStyles(() =>
  createStyles({
    tooltipPlacementTop: {
      margin: `${theme.spacing(1)} 0 !important`,
    },
    tooltipPlacementBottom: {
      margin: `${theme.spacing(1)} 0 !important`,
    },
  }),
);

export type CustomTooltipProps = TooltipProps & {};

export const Tooltip: React.FC<CustomTooltipProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiToolTip
      PopperProps={{
        sx: {
          // allow the modal to be on top
          zIndex: 9999,
          ...props.PopperProps?.sx,
        },
      }}
      {...props}
      classes={classes}
      componentsProps={{
        ...props.componentsProps,
        tooltip: {
          sx: {
            ...toolTipStyles,
            ...props.componentsProps?.tooltip?.sx,
          },
        },
      }}
    >
      {props.children}
    </MuiToolTip>
  );
};
