import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { theme } from 'styles/theme';
import { TableHeaders } from './consts';

export const BrandCompetitorsListViewSkeleton = () => {
  return (
    <Table
      sx={{
        mt: 8,
        overflowX: 'auto',
        '& td': {
          border: 'none',
        },
      }}
    >
      <TableHead>
        <TableRow>
          {TableHeaders.map((header, index) => (
            <TableCell
              sx={{
                ...header.sx,
                ...(header.canSort ? { cursor: 'pointer' } : {}),
              }}
            >
              <Box display="flex" alignItems="center" gap={1} component="span">
                {header.label}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {new Array(3).fill(0).map(() => {
          return (
            <TableRow
              sx={{
                height: theme.spacing(22),
                '&:hover': {
                  backgroundColor: theme.colors?.utility[275],
                  cursor: 'pointer',
                },
              }}
            >
              <TableCell>
                <Skeleton
                  sx={{ width: theme.spacing(2), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  variant="circular"
                  sx={{ width: theme.spacing(10), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...typography['headline-sm'],
                }}
              >
                <Skeleton
                  sx={{ width: theme.spacing(30), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 600,
                }}
              >
                <Skeleton
                  sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 600,
                }}
              >
                <Skeleton
                  sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 600,
                }}
              >
                <Skeleton
                  sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 600,
                }}
              >
                <Skeleton
                  sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 500,
                }}
              >
                <Box component="span" fontWeight={600}>
                  <Skeleton
                    sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                  />
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 500,
                }}
              >
                <Skeleton
                  sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...typography['body-lg'],
                  fontWeight: 500,
                }}
              >
                <Skeleton
                  sx={{ width: theme.spacing(20), height: theme.spacing(10) }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
