import { gql } from '@apollo/client';
import { Box, IconButton, TableCell, Typography } from '@mui/material';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening';
import {
  Platform,
  SocialPostFragmentLinkTableCellFragment,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentLinkTableCell on SocialPostModel {
    ...SocialPostFragmentUseSocialListeningPostLinkGenerator
  }
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

type Props = {
  platform: Platform;
  socialPost: SocialPostFragmentLinkTableCellFragment;
};

export const LinkTableCell = ({ platform, socialPost }: Props) => {
  const { generateLink } = useSocialListeningPostLinkGenerator();

  if (platform === Platform.Instagram) {
    return (
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          color={theme.colors?.utility[800]}
        >
          <IconCustomInstagram size={16} />
          <Typography fontWeight={500} variant="body-lg">
            Instagram
          </Typography>
          <Box component="a" href={generateLink(socialPost)} target="_blank">
            <IconButton sx={{ p: 1 }} disableRipple>
              <IconLinearExport2 size={16} />
            </IconButton>
          </Box>
        </Box>
      </TableCell>
    );
  }

  if (platform === Platform.Tiktok) {
    return (
      <TableCell
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          color={theme.colors?.utility[800]}
        >
          <IconCustomTiktok size={16} />
          <Typography fontWeight={500} variant="body-lg">
            Tiktok
          </Typography>
          <Box component="a" href={generateLink(socialPost)} target="_blank">
            <IconButton sx={{ p: 1 }} disableRipple>
              <IconLinearExport2 size={16} />
            </IconButton>
          </Box>
        </Box>
      </TableCell>
    );
  }

  return null;
};
