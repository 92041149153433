import { Box, IconButton, TableCell } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import React from 'react';
import { theme } from 'styles/theme';

type Props = {
  selected: boolean;
  onToggle: () => void;
};

export const SelectionStateTableCell = ({ onToggle, selected }: Props) => {
  return (
    <TableCell
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {selected && (
        <IconButton
          disableRipple
          onClick={() => {
            onToggle();
          }}
          sx={{
            m: 0,
            p: 0,
          }}
        >
          <IconBoldTickCircle size={24} color={theme.colors?.primary.black} />
        </IconButton>
      )}
      {!selected && (
        <Box
          onClick={() => {
            onToggle();
          }}
          sx={{
            m: 0,
            p: 0,
            cursor: 'pointer',
            width: theme.spacing(5),
            height: theme.spacing(5),
            border: `1px solid ${theme.colors?.utility[800]}`,
            borderRadius: theme.spacing(5),
          }}
        />
      )}
    </TableCell>
  );
};
