import { gql } from '@apollo/client';
import { DropTarget } from 'features/dnd';
import {
  ContentIdeaFieldFragmentContentIdeaDetailViewFragment,
  ContentIdeaFieldFragmentContentIdeaDetailViewFragmentDoc,
  useUpdateContentIdeaFieldForContentIdeaDetailViewMutation,
} from 'graphql/generated';
import { useRef } from 'react';

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaFieldForContentIdeaDetailView(
    $data: UpdateContentIdeaFieldInput!
  ) {
    updateContentIdeaField(data: $data) {
      id
      ...ContentIdeaFieldFragmentContentIdeaDetailView
    }
  }
  ${ContentIdeaFieldFragmentContentIdeaDetailViewFragmentDoc}
`;

export const useContentIdeaHandlers = (props: {
  remainingFields: ContentIdeaFieldFragmentContentIdeaDetailViewFragment[];
}) => {
  const { remainingFields } = props;

  // Using ref here because `onFieldSortEnd` will be called inside a closure
  // in `DndSortableDropItem`. Ref will ensure we always have the latest value
  const remainingFieldsRef = useRef(remainingFields);
  remainingFieldsRef.current = remainingFields;

  const [updateContentIdeaField] =
    useUpdateContentIdeaFieldForContentIdeaDetailViewMutation();
  const onFieldSortEnd = async (
    dropTarget: DropTarget | null,
    item: unknown,
  ) => {
    const droppedField =
      item as ContentIdeaFieldFragmentContentIdeaDetailViewFragment;

    if (!dropTarget || !droppedField) {
      return;
    }

    let prevFieldIndex: number;
    let prevField: ContentIdeaFieldFragmentContentIdeaDetailViewFragment | null =
      null;

    let nextFieldIndex: number;
    let nextField: ContentIdeaFieldFragmentContentIdeaDetailViewFragment | null =
      null;

    if (dropTarget.side === 'top') {
      nextFieldIndex = remainingFieldsRef.current.findIndex(
        (field) => field.id === dropTarget.id,
      );
      prevFieldIndex = nextFieldIndex - 1;
    } else {
      prevFieldIndex = remainingFieldsRef.current.findIndex(
        (field) => field.id === dropTarget.id,
      );
      nextFieldIndex = prevFieldIndex + 1;
    }

    prevField = remainingFieldsRef.current[prevFieldIndex];
    nextField = remainingFieldsRef.current[nextFieldIndex];

    // Skip if any of the prevField or nextField is the same as the item
    if (
      prevField?.id === droppedField.id ||
      nextField?.id === droppedField.id
    ) {
      return;
    }

    const DEFAULT_SORT_ORDER = 999999;
    let newSortOrder =
      nextField && !prevField
        ? nextField.sortOrder - 1
        : nextField && prevField
        ? (nextField.sortOrder + prevField.sortOrder) / 2
        : !nextField && prevField
        ? prevField.sortOrder + 1
        : DEFAULT_SORT_ORDER;

    // Ensure uniqueness of sort order by modifying the sort order very slightly
    if (
      remainingFieldsRef.current.some(
        (field) => field.sortOrder === newSortOrder,
      )
    ) {
      newSortOrder = newSortOrder + (Math.random() * 2 - 1) * 1e-2;
    }

    updateContentIdeaField({
      variables: {
        data: {
          contentIdeaFieldId: droppedField.id,
          data: {
            sortOrder: newSortOrder,
          },
        },
      },
      optimisticResponse: {
        updateContentIdeaField: {
          ...droppedField,
          sortOrder: newSortOrder,
        },
      },
    });
  };

  return {
    onFieldSortEnd,
  };
};
