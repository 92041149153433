import { Box, Typography, styled } from '@mui/material';
import { TaskStatus } from 'graphql/generated';
import React from 'react';
import { getTaskStatusIcon } from './utils';

const StyledContainer = styled(Box)<{ selected: boolean }>(
  ({ selected, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    padding: theme.spacing(2, 3),
    borderRadius: '12px',
    cursor: 'pointer',
    color: 'white',
    ':hover': {
      backgroundColor: theme.colors?.utility[275],
    },
    backgroundColor: selected ? theme.colors?.utility[275] : 'transparent',
  }),
);

const StyledStatusText = styled(Typography)(({ theme }) => ({
  color: theme.colors?.utility[900],
  fontWeight: 600,
}));

type Props = {
  label: string;
  value: string;
  selected: boolean;
};

export const TaskStatusSelectItem = React.forwardRef((props: Props, ref) => {
  const { label, value, selected, ...rest } = props;
  const { Icon, color, size } = getTaskStatusIcon(value as TaskStatus);

  return (
    <StyledContainer
      selected={selected}
      ref={ref}
      {...rest}
      // Not using Mui classes here
      className=""
      gap={1.5}
    >
      <Box display="flex" alignItems="center">
        <Icon color={color} size={size} />
      </Box>
      <StyledStatusText variant="body-md">{label}</StyledStatusText>
    </StyledContainer>
  );
});
