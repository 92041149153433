import { gql } from '@apollo/client';
import { CollectionFragmentCollectionCardViewFragmentDoc } from 'graphql/generated';
import { POST_FRAGMENT_POST_COMMAND } from '../usePostCommands/types';

export const COLLECTION_FRAGMENT_COLLECTION_COMMAND = gql`
  fragment CollectionFragmentCollectionCommand on CollectionModel {
    id
    ...CollectionFragmentCollectionCardView
  }
  ${CollectionFragmentCollectionCardViewFragmentDoc}
`;

export const POST_FRAGMENT_COLLECTION_COMMAND = gql`
  fragment PostFragmentCollectionCommand on PostModel {
    id
    ...PostFragmentPostCommand
  }
  ${POST_FRAGMENT_POST_COMMAND}
`;
