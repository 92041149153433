import { NodeSelection, Plugin } from '@tiptap/pm/state';
import { liftTarget } from '@tiptap/pm/transform';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Node, ReactRenderer, mergeAttributes } from '@tiptap/react';
import { NODE_COLUMN, NODE_COLUMN_LIST } from '../constants';
import { Resizer } from './components';

export const ColumnList = Node.create({
  name: NODE_COLUMN_LIST,

  group: 'block',

  isolating: true,

  // only ColumnNode is allowed as column list's children
  content: `${NODE_COLUMN}+`,

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const attrs = mergeAttributes(node.attrs, HTMLAttributes, {
      class: NODE_COLUMN_LIST,
      'data-type': this.name,
    });

    return ['div', attrs, 0];
  },

  addProseMirrorPlugins() {
    const editor = this.editor;

    return [
      // Remove itself when it's empty, or only contains 1 child node.
      // This plugin also ensures that the single child node is preserved
      new Plugin({
        appendTransaction(_transactions, oldState, newState) {
          // no changes
          if (newState.doc === oldState.doc) {
            return;
          }

          const tr = newState.tr;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            if (node.type.name === NODE_COLUMN_LIST) {
              if (node.childCount === 1) {
                // + 1 to step into the child node
                const resolvedPos = tr.doc.resolve(pos + 1);
                const selection = new NodeSelection(resolvedPos);
                const range = selection.$from.blockRange(selection.$to);

                if (range) {
                  let target = liftTarget(range);
                  if (target === null) {
                    // Fallback to manual depth calculation
                    target = Math.max(resolvedPos.depth - 1, 0);
                  }

                  if (target >= 0) {
                    tr.lift(range, target);
                    modified = true;
                  }
                }
              } else if (node.childCount === 0) {
                // Delete the empty custom node
                tr.delete(pos, pos + node.nodeSize);
                modified = true;
              }
            }
          });

          if (modified) {
            return tr;
          }
        },
      }),

      // Add handle bars between the columns to allow resizing
      new Plugin({
        props: {
          decorations: ({ doc }) => {
            const decorations: Decoration[] = [];

            doc.descendants((node, pos) => {
              if (node.type.name === NODE_COLUMN_LIST) {
                node.descendants((childNode, childPos, _parent, index) => {
                  if (childNode.type.name === NODE_COLUMN) {
                    // Do not add resizer to the last column
                    if (index === node.childCount - 1) {
                      return;
                    }

                    decorations.push(
                      Decoration.widget(
                        pos + childPos + childNode.nodeSize + 1,
                        () => {
                          return new ReactRenderer(Resizer, {
                            props: {
                              node: childNode,
                              parentNode: node,
                              editor,
                              getPos: () => pos + childPos + 1,
                              getParentPos: () => pos,
                            },
                            editor,
                          }).element;
                        },
                        {},
                      ),
                    );
                  }
                });
              }
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
