import { useDisclosure, usePrevious } from '@dwarvesf/react-hooks';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDrawerTabs } from './useDrawerTabs';

const ROUTES_WITH_DRAWER_OPEN_ON_DEFAULT = ['/'];
const ROUTES_WITH_PERSISTENT_DRAWER = ['/'];

/**
 * Default state for different routes
 */
const defaultOpenState = {
  persistent: true,
  temporary: false,
};

export const useLeftDrawer = () => {
  const location = useLocation();
  const isMobileView = useMediaQueryMobile();

  const { topTabs, bottomTabs } = useDrawerTabs();

  const {
    isOpen: isOpenDrawer,
    onClose: onCloseDrawer,
    onToggle: onToggleDrawer,
    onOpen: onOpenDrawer,
  } = useDisclosure({
    defaultIsOpen:
      !isMobileView &&
      ROUTES_WITH_DRAWER_OPEN_ON_DEFAULT.includes(location.pathname),
  });

  // Auto close drawer on route change & the route matches a subset of routes as well as on mobile view
  useEffect(() => {
    if (
      ROUTES_WITH_DRAWER_OPEN_ON_DEFAULT.includes(location.pathname) &&
      !isMobileView
    ) {
      onOpenDrawer();
    } else {
      onCloseDrawer();
    }
  }, [location.pathname]); // eslint-disable-line

  // Should not allow inline drawer on mobile
  const isPersistentDrawer = useMemo(
    () =>
      !isMobileView &&
      ROUTES_WITH_PERSISTENT_DRAWER.includes(location.pathname),
    [isMobileView, location.pathname],
  );

  // NOTE: This is a workaround
  // When the pathname changes, the drawer type change is immediate (if any, persistent to temporary or vice versa).
  // However, open state change is always 1 render loop behind (as we are updating the open state in line 36 with useEffect).
  // This causes a flickering problem, due to differences in:
  // 1. Rendering layout (for persistent, it's inline, for temporary, it's overlay/popover)
  // 2. Open state (for persistent, it's open by default, for temporary, it's closed by default)
  // So if a persistent drawer is open, and we navigate to a route with a temporary drawer,
  // the temporary drawer overlay will briefly show up (type changes first), then immediately close (open state changes).
  //
  // Here, we will be hard-coding the drawer state based on the new drawer type.
  // This will ensure the change in drawer type (& rendering) is immediate together with the route change.
  const previousPathname = usePrevious(location.pathname);
  const hasPathnameChanged = previousPathname !== location.pathname;
  const finalOpenState = hasPathnameChanged
    ? isPersistentDrawer
      ? defaultOpenState.persistent
      : defaultOpenState.temporary
    : isOpenDrawer;

  return {
    tabs: bottomTabs,
    topTabs,
    onCloseDrawer,
    onOpenDrawer,
    isOpenDrawer: finalOpenState,
    onToggleDrawer,
    isPersistentDrawer,
  };
};
