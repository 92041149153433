/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Box, SxProps } from '@mui/material';
import { useViewManager } from 'components/common/Media/contexts/ViewManager.context';
import { useZoomManager } from 'components/common/Media/contexts/ZoomManager.context';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ImageContainer, ImageWrapper } from './styles';

type Props = {
  imageName: string;
  imageUrl: string;

  onRenderDimensions?: (dimensions: { width: number; height: number }) => void;

  componentProps?: {
    imageContainer?: {
      sx: SxProps;
    };
    imageWrapper?: {
      sx: SxProps;
    };
    img?: {
      sx: SxProps;
    };
  };
};

export const CustomSocialMediaImage = ({
  imageUrl: _imageUrl,
  imageName,
  componentProps,
  onRenderDimensions,
}: Props) => {
  const [lastRenderedWidth, setLastRenderedWidth] = useState(0);
  const [imageUrl, setImageUrl] = useState(_imageUrl);
  const imageRef = useRef<HTMLImageElement>(null);
  const {
    setMaxBoundingBoxWidth,
    setMaxOriginalBoundingBoxWidth,
    maxOriginalBoundingBoxWidth,
    maxBoundingBoxWidth,
    setMaxBoundingBoxHeight,
    maxBoundingBoxHeight,
    setMaxOriginalBoundingBoxHeight,
    maxOriginalBoundingBoxHeight,
  } = useViewManager();
  const { scale } = useZoomManager();

  useLayoutEffect(() => {
    if (imageRef.current) {
      onRenderDimensions?.({
        width: maxBoundingBoxWidth,
        height: maxBoundingBoxHeight,
      });
    }
  }, [
    onRenderDimensions,
    scale,
    imageUrl,
    imageRef.current,
    maxBoundingBoxWidth,
    maxBoundingBoxHeight,
  ]);

  const handleImageSizing = () => {
    if (
      imageRef?.current &&
      scale &&
      maxOriginalBoundingBoxWidth &&
      maxOriginalBoundingBoxHeight
    ) {
      // Calculate scaled image width based on scale value
      const currentWidth = maxOriginalBoundingBoxWidth * scale * 0.7;
      const currentHeight = maxOriginalBoundingBoxHeight * scale * 0.7;

      setMaxBoundingBoxWidth(currentWidth);
      setMaxBoundingBoxHeight(currentHeight);

      if (lastRenderedWidth !== currentWidth) {
        setLastRenderedWidth(currentWidth);
      }

      imageRef.current.style.width = `${currentWidth}px`;
      imageRef.current.style.height = `${currentHeight}px`;
    }
  };

  useEffect(() => {
    handleImageSizing();
    // eslint-disable-next-line
  }, [
    scale,
    imageUrl,
    onRenderDimensions,
    lastRenderedWidth,
    _imageUrl,
    maxOriginalBoundingBoxWidth,
  ]);

  if (!imageUrl) return null;

  return (
    <ImageContainer
      sx={{ ...(componentProps?.imageContainer?.sx || {}) }}
      key={_imageUrl}
    >
      <ImageWrapper sx={{ ...(componentProps?.imageWrapper?.sx || {}) }}>
        <Box
          component="img"
          className="react-custom-annotation"
          ref={imageRef}
          src={imageUrl}
          draggable={false}
          style={{
            objectFit: 'contain',
            borderRadius: 18,
          }}
          sx={{ ...(componentProps?.img?.sx || {}) }}
          alt={imageName}
          onLoad={() => {
            if (!imageRef.current) {
              return;
            }

            setMaxOriginalBoundingBoxWidth(imageRef.current.naturalWidth);
            setMaxOriginalBoundingBoxHeight(imageRef.current.naturalHeight);
          }}
          onError={() => {
            if (imageUrl.includes('https://ucarecdn.com')) {
              // Convert the image to a renderable format using uploadcare CDN in case of an error
              const newUrl = imageUrl.replace(
                /\/([^/]+)(?:\/([^/]+))?$/,
                (_, id, filename) =>
                  `/${id}/-/format/auto/-/quality/smart${
                    filename ? `/${filename}` : ''
                  }`,
              );

              setImageUrl(newUrl);
            }
          }}
        />
      </ImageWrapper>
    </ImageContainer>
  );
};
