import { Box, SxProps } from '@mui/material';
import { RichTextEditorRef } from 'components/common/form/RichTextEditor/RichTextEditor';
import {
  CommentFilter,
  CommentInput,
  CommentSearch,
} from 'features/comments/components';
import {
  CommentFilter as CommentFilterType,
  CommentInputData,
} from 'graphql/generated';
import _ from 'lodash';
import { useMemo, useRef } from 'react';
import { theme } from 'styles/theme';

export type CommentSidebarProps = {
  canComment?: boolean;
  filters: CommentFilterType;
  componentsProps?: {
    searchBox?: {
      sx?: SxProps;
    };
    commentListContainer?: {
      sx?: SxProps;
    };
    commentInputContainer?: {
      sx?: SxProps;
    };
  };
  canShowSearch?: boolean;
  sx?: SxProps;
  onSetFilters: (filters: CommentFilterType) => void;
  renderComments: () => JSX.Element;
  onCreateComment: (comment: CommentInputData) => void;
};

export const CommentSidebar = ({
  componentsProps,
  filters,
  canShowSearch,
  canComment = true,
  sx,
  onCreateComment,
  onSetFilters,
  renderComments,
}: CommentSidebarProps) => {
  const commentInputRef = useRef<RichTextEditorRef | null>(null);

  const debouncedSetQuery = useMemo(
    () =>
      _.debounce(
        (val) =>
          onSetFilters({
            ...filters,
            query: val,
            filterOnlyParentThreads: val === '',
          }),
        300,
      ),
    [], // eslint-disable-line
  );

  return (
    <Box
      sx={{
        display: 'contents',
        minHeight: '100%',
        ...sx,
      }}
    >
      {canShowSearch && (
        <CommentSearch
          sx={{
            position: 'relative',
            zIndex: 1,
            bgcolor: theme.colors?.primary.white,
            ...componentsProps?.searchBox?.sx,
          }}
          InputProps={{
            endAdornment: (
              <CommentFilter filters={filters} onSetFilters={onSetFilters} />
            ),
          }}
          onChange={(e) => {
            debouncedSetQuery(e.target.value);
          }}
        />
      )}
      <Box
        sx={{
          flex: 1,
          ...componentsProps?.commentListContainer?.sx,
        }}
      >
        {renderComments()}
      </Box>
      {canComment && (
        <Box
          sx={{
            wordBreak: 'break-word',
            width: '100%',
            bgcolor: 'white',
            '& .comment-user': {
              mb: 1,
            },
            borderTop: `1px solid ${theme.colors?.utility[275]}`,
            ...componentsProps?.commentInputContainer?.sx,
          }}
        >
          <CommentInput
            commentInputRef={commentInputRef}
            editorStyle={{
              maxHeight: 200,
              overflowY: 'auto',
            }}
            onCreateComment={(comment) => {
              onCreateComment(comment);
              commentInputRef?.current?.clear();
            }}
          />
        </Box>
      )}
    </Box>
  );
};
