import { useEffect } from 'react';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { ActiveCommandState, CommandDataMap } from 'contexts/commands/types';

/**
 * This hook is used to register a callback function for a given command type.
 * When the command is completed & updated with response data (e.g. a post is created),
 * the callback function will be called.
 *
 * See Command.context > types.ts to understand the expected data response for each command.
 */
export const useUpdateQueryByCommand = <T extends COMMAND_TYPE>(
  commandType: T,
  updateQuery: (data: CommandDataMap[T]) => void,
) => {
  const { registerCommandListener, unregisterCommandListener } =
    useCommandContext();

  useEffect(() => {
    const callback = (activeCommand: ActiveCommandState) => {
      if (activeCommand.status === 'completed' && activeCommand.data) {
        updateQuery(activeCommand.data as CommandDataMap[T]);
      }
    };

    registerCommandListener(commandType, callback);

    return () => {
      unregisterCommandListener(commandType, callback);
    };
  }, [commandType, updateQuery]); // eslint-disable-line
};
