import { gql } from '@apollo/client';
import {
  PostFilterType,
  PostFiltersForSmartSearch,
  PostFragmentCollectionPostDndPostCardFragmentDoc,
  SortType,
  useGetPostsSmartSearchForPostSmartSearchResultViewQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForPostSmartSearchResultView(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $sortType: SortType
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      sortType: $sortType
    ) {
      data {
        score
        type
        item {
          id
          ...PostFragmentCollectionPostDndPostCard
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${PostFragmentCollectionPostDndPostCardFragmentDoc}
`;

export const usePostSmartSearchQuery = (props: {
  filters: PostFiltersForSmartSearch;
  sortType?: SortType;
  canSkip?: boolean;
}) => {
  const { filters, sortType, canSkip } = props;

  const {
    data,
    loading,
    fetchMore: _fetchMore,
    updateQuery,
  } = useGetPostsSmartSearchForPostSmartSearchResultViewQuery({
    variables: {
      filters: {
        ...filters,
        query: filters.query,
        filterType: filters.filterType || PostFilterType.OrganizationPosts,
        collectionId: filters.collectionId,
      },
      sortType,
    },
    fetchPolicy: 'cache-and-network',
    skip: canSkip,
  });

  const fetchMore = async () => {
    _fetchMore({
      variables: {
        ...filters,
        query: filters.query,
        filterType: filters.filterType || PostFilterType.OrganizationPosts,
        collectionId: filters.collectionId,
        after: data?.postsSmartSearch.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          postsSmartSearch: {
            ...fetchMoreResult.postsSmartSearch,
            data: [
              ...(prev.postsSmartSearch.data || []),
              ...(fetchMoreResult.postsSmartSearch.data || []),
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.item.id === e.item.id), //de-dupe
            ),
          },
        };
      },
    });
  };

  return {
    data,
    loading,
    fetchMore,
    updateQuery,
  };
};
