import { theme } from 'styles/theme';

export const toastStyle = {
  bgcolor: theme.colors?.primary.black,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5, 3),
  span: {
    color: theme.colors?.primary.parchment,
    ...theme.typography['headline-xs'],
  },
};
