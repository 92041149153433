import { Plugin } from '@tiptap/pm/state';
import { Node, mergeAttributes } from '@tiptap/react';
import { NODE_COLUMN, NODE_COLUMN_LIST } from '../constants';

export const Column = Node.create({
  name: NODE_COLUMN,

  group: 'block',

  isolating: true,

  content: `block*`,

  addAttributes() {
    return {
      flex: {
        default: '1',
        parseHTML: (element) => {
          const flex = parseFloat(element.getAttribute('flex') || '1');

          return Number.isNaN(flex) ? '1' : flex;
        },
        renderHTML: (attributes) => {
          return {
            flex:
              !attributes.flex ||
              attributes.flex === 'NaN' ||
              Number.isNaN(attributes.flex)
                ? '1'
                : attributes.flex,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const attrs = mergeAttributes(node.attrs, HTMLAttributes, {
      'data-type': this.name,
      class: NODE_COLUMN,
    });

    return ['div', attrs, 0];
  },

  addProseMirrorPlugins() {
    return [
      // Remove itself when it's empty, or its parent is no longer column-list
      new Plugin({
        appendTransaction(_transactions, oldState, newState) {
          // no changes
          if (newState.doc === oldState.doc) {
            return;
          }

          const tr = newState.tr;
          let modified = false;

          newState.doc.descendants((node, pos) => {
            const resolvedPos = tr.doc.resolve(pos);

            if (node.type.name === NODE_COLUMN) {
              // Column is empty
              // Delete the empty custom node
              if (node.childCount === 0) {
                tr.delete(pos, pos + node.nodeSize);

                modified = true;

                // Column is no longer in a column list
              } else if (resolvedPos.parent.type.name !== NODE_COLUMN_LIST) {
                // Push all children of this column to a slice
                const slice = node.content;

                // Replace the column with its children
                tr.replaceWith(pos, pos + node.nodeSize, slice);

                modified = true;
              }
            }
          });

          if (modified) {
            return tr;
          }
        },
      }),
    ];
  },
});
