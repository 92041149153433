import { gql } from '@apollo/client';
import { Typography } from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu';
import { IconLinearFilter } from 'components/icons/components/linear/IconLinearFilter';
import { FormattedContentIdeaPrimaryField } from 'features/contentIdea';
import {
  ContentIdeaFieldFragmentContentCalendarFilterFragmentDoc,
  ContentIdeaFieldOptionFragmentContentCalendarFilterFragmentDoc,
  ContentIdeaPrimaryField,
  FieldType,
  useGetContentIdeaFieldsForContentCalendarFilterQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { Updater } from 'use-immer';
import { StyledIconContainer, StyledMenuItemContainer } from '../styles';
import { ContentCalendarFilters } from '../types';
import { customSetSelectedFilters } from '../utils';
import {
  ContentCalendarFilter,
  ContentCalendarFilterByCollections,
  ContentCalendarFilterByPeriod,
} from './filters';
import { ContentCalendarFilterByOwners } from './filters/owners/ContentCalendarFilterByOwners';
import { ContentCalendarFilterByPlatforms } from './filters/platforms';

type ContentCalendarFilterMenuProps = {
  selectedFilters: ContentCalendarFilters;
  setSelectedFilters: Updater<ContentCalendarFilters>;
};

// eslint-disable-next-line
gql`
  fragment ContentIdeaFieldOptionFragmentContentCalendarFilter on ContentIdeaFieldOptionModel {
    id
    label
    value
    bgcolor
  }
`;

// eslint-disable-next-line
gql`
  fragment ContentIdeaFieldFragmentContentCalendarFilter on ContentIdeaFieldModel {
    id
    name
    icon
    type
    options {
      ...ContentIdeaFieldOptionFragmentContentCalendarFilter
    }
  }
  ${ContentIdeaFieldOptionFragmentContentCalendarFilterFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetContentIdeaFieldsForContentCalendarFilter(
    $filters: ContentIdeaFieldFilters
  ) {
    contentIdeaFields(filters: $filters) {
      ...ContentIdeaFieldFragmentContentCalendarFilter
    }
  }
  ${ContentIdeaFieldFragmentContentCalendarFilterFragmentDoc}
`;

export const ContentCalendarFilterMenu = (
  props: ContentCalendarFilterMenuProps,
) => {
  const { selectedFilters, setSelectedFilters } = props;

  const { data: contentIdeaFieldsData } =
    useGetContentIdeaFieldsForContentCalendarFilterQuery({
      variables: {
        filters: {
          isPrimaryField: true,
        },
      },
    });
  const contentIdeaFields = contentIdeaFieldsData?.contentIdeaFields || [];
  const filters = contentIdeaFields.filter((f) => {
    return [
      FormattedContentIdeaPrimaryField.Pillars,
      FormattedContentIdeaPrimaryField.Status,
    ].includes(f.name as ContentIdeaPrimaryField);
  });

  const ownersFilter = contentIdeaFields.find(
    (f) => f.name === FormattedContentIdeaPrimaryField.Owners,
  ) || { name: FormattedContentIdeaPrimaryField.Owners, type: FieldType.Users };

  const platformsFilter = contentIdeaFields.find(
    (f) => f.name === FormattedContentIdeaPrimaryField.Platforms,
  ) || {
    id: '',
    name: FormattedContentIdeaPrimaryField.Platforms,
    type: FieldType.MultiSelect,
    options: [],
  };

  const dueDateFilter = contentIdeaFields.find(
    (f) => f.name === FormattedContentIdeaPrimaryField.DueDate,
  ) || { name: FormattedContentIdeaPrimaryField.DueDate, type: FieldType.Date };

  return (
    <MoreMenuItem
      disableRipple
      label={
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconLinearFilter size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">Filter by</Typography>
        </StyledMenuItemContainer>
      }
      endIcon={
        <Typography
          variant="subhead-lg"
          color={theme.colors?.utility[600]}
          sx={{
            textAlign: 'right',
            width: theme.spacing(24),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {Object.keys(selectedFilters)
            .filter(
              (f) =>
                (selectedFilters[f]?.selected &&
                  selectedFilters[f]?.selected!.length > 0) ||
                (selectedFilters[f]?.type === FieldType.Date &&
                  selectedFilters[f]?.startDate &&
                  selectedFilters[f]?.endDate),
            )
            .map((f) => {
              if (selectedFilters[f]?.type === FieldType.Date) {
                return `${selectedFilters[f]?.name}`;
              }

              return `${selectedFilters[f]?.name} (${selectedFilters[f].selected?.length})`;
            })
            .join(', ')}
        </Typography>
      }
      sx={{
        width: '100%',
      }}
      MenuProps={{
        sx: {
          minWidth: 266,
        },
      }}
    >
      <ContentCalendarFilterByOwners
        selectedUserIds={selectedFilters[ownersFilter.name]?.selected || []}
        selectedOrganizationIds={
          selectedFilters['Organizations']?.selected || []
        }
        onSelectUser={(selectedUserId) => {
          customSetSelectedFilters({
            setSelectedFilters,
            fieldType: ownersFilter.type,
            fieldName: ownersFilter.name,
            value: selectedUserId,
          });
        }}
        onSelectOrganization={(selectedOrganizationId) => {
          customSetSelectedFilters({
            setSelectedFilters,
            fieldType: FieldType.MultiSelect,
            fieldName: 'Organizations',
            value: selectedOrganizationId,
          });
        }}
      />

      {filters
        .filter(
          (filter) =>
            filter.name.replace(/\s/g, '') !==
            FormattedContentIdeaPrimaryField.DueDate,
        )
        .map((filter) => {
          return (
            <ContentCalendarFilter
              key={filter.id}
              filter={filter}
              selectedOptions={selectedFilters[filter.name]?.selected || []}
              onSelectFilter={(fieldName, selected) => {
                customSetSelectedFilters({
                  setSelectedFilters,
                  fieldType: filter.type,
                  fieldName: filter.name,
                  value: selected,
                });
              }}
            />
          );
        })}

      <ContentCalendarFilterByPlatforms
        selectedPlatforms={
          selectedFilters[platformsFilter.name]?.selected || []
        }
        selectedSocialAuthorIds={
          selectedFilters['Social Authors']?.selected || []
        }
        field={platformsFilter}
        onSelectPlatform={(selectedPlatform) => {
          customSetSelectedFilters({
            setSelectedFilters,
            fieldType: FieldType.MultiSelect,
            fieldName: platformsFilter.name,
            value: selectedPlatform,
          });
        }}
        onSelectSocialAuthor={(selectedSocialAuthorId) => {
          customSetSelectedFilters({
            setSelectedFilters,
            fieldType: FieldType.MultiSelect,
            fieldName: 'Social Authors',
            value: selectedSocialAuthorId,
          });
        }}
      />

      <ContentCalendarFilterByPeriod
        selectedPeriodLabel={selectedFilters[dueDateFilter.name]?.label || ''}
        selectedPeriodCustomStartDate={
          selectedFilters[dueDateFilter.name]?.label === 'Custom Range'
            ? selectedFilters[dueDateFilter.name]?.startDate || null
            : null
        }
        selectedPeriodCustomEndDate={
          selectedFilters[dueDateFilter.name]?.label === 'Custom Range'
            ? selectedFilters[dueDateFilter.name]?.endDate || null
            : null
        }
        onSelectedPeriodLabel={(selectedPeriodLabel, startDate, endDate) => {
          customSetSelectedFilters({
            setSelectedFilters,
            fieldType: dueDateFilter.type,
            fieldName: dueDateFilter.name,
            value: selectedPeriodLabel,
            startDate,
            endDate,
          });
        }}
      />

      <ContentCalendarFilterByCollections
        selectedCollections={selectedFilters['Collections']?.collections || []}
        onSelectCollection={(selectedCollection) => {
          customSetSelectedFilters({
            setSelectedFilters,
            fieldType: FieldType.Collection,
            fieldName: 'Collections',
            collection: selectedCollection,
          });
        }}
      />
    </MoreMenuItem>
  );
};
