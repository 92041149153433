import { Box, Button, OutlinedInput, Typography } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

interface FilterByMinimumPlayCountProps {
  onChange: (value: number) => void;
  selectedMinimumPlayCount: number;
  renderTitle?: () => React.ReactNode;
  type?: 'accordion' | 'normal';
}

export const FilterByMinimumPlayCount = ({
  onChange,
  selectedMinimumPlayCount = 0,
  renderTitle,
  type = 'accordion',
}: FilterByMinimumPlayCountProps) => {
  const [internalValue, setInternalValue] = useState<
    number | string | undefined
  >(selectedMinimumPlayCount);

  useEffect(() => {
    setInternalValue(selectedMinimumPlayCount);
  }, [selectedMinimumPlayCount]);

  const renderDefaultTitle = useMemo(() => {
    return (
      <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
        Minimum Play Count
      </Typography>
    );
  }, []);

  const inputRender = useMemo(() => {
    return (
      <Box
        sx={{
          width: '100%',
          // NOTE: This is for styling when this component is being rendered
          // independently as part of the selected filters UI on top of the page
          p: type === 'accordion' ? 3 : 0,
        }}
      >
        <OutlinedInput
          value={internalValue}
          type="number"
          placeholder="e.g: 10000"
          sx={{
            width: '100%',
            bgcolor: theme.colors?.utility[300],
            borderColor: theme.colors?.utility[400],
          }}
          onChange={(e) => {
            // Only accept numbers [0-9]
            const value = e.target.value;

            if (
              value !== '' &&
              value !== null &&
              value !== undefined &&
              !/^\d+$/.test(value)
            ) {
              return;
            }

            setInternalValue(
              value !== '' && value !== null && value !== undefined
                ? Number(value)
                : undefined,
            );
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            mt: 4,
          }}
        >
          <Button
            variant="text"
            size="small"
            sx={{
              color: theme.colors?.primary.black,
            }}
            onClick={() => {
              setInternalValue(selectedMinimumPlayCount);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary-alt"
            size="small"
            sx={{
              borderRadius: 50,
            }}
            disabled={
              internalValue === '' ||
              internalValue === null ||
              internalValue === undefined ||
              internalValue === selectedMinimumPlayCount
            }
            onClick={() => {
              onChange(
                internalValue !== '' &&
                  internalValue !== null &&
                  internalValue !== undefined
                  ? Number(internalValue)
                  : 0,
              );
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    );
  }, [type, internalValue, selectedMinimumPlayCount, onChange]);

  if (type === 'normal') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {renderTitle?.() ?? renderDefaultTitle}
        <Box>{inputRender}</Box>
      </Box>
    );
  }

  const Icon =
    socialListeningFilterIconMap[SocialListeningFiltersKey.MinimumPlayCount];

  return (
    <CollapsibleSection
      sx={{
        padding: theme.spacing(2, 3), // To match the padding of MenuItem
        borderRadius: 3,
        ':hover': {
          background: 'rgba(0, 0, 0, 0.04)',
        },
      }}
      renderHeaderTitle={() =>
        renderTitle?.() ?? (
          <Box display="flex" alignItems="center" gap={2} width="100%">
            <Box
              display="flex"
              alignItems="center"
              sx={{
                p: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(1),
              }}
            >
              <Icon size={16} />
            </Box>
            {renderDefaultTitle}
          </Box>
        )
      }
      divider={false}
    >
      {inputRender}
    </CollapsibleSection>
  );
};
