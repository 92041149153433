import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box } from '@mui/material';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { IconLinearArrowDown2 } from 'components/icons/components/linear/IconLinearArrowDown2';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useUserContext } from 'contexts/users/User.context';
import { BillingCollectionLimitIndicator } from 'features/billing';
import {
  PostFragmentCollectionPostDndHoveringActionPopupFragment,
  PostFragmentCollectionPostDndPostCardFragmentDoc,
  PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc,
  PostPermission,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { usePostHandlers } from './usePostHandlers';

export const POST_FRAGMENT_COLLECTION_POST_DND_HOVERING_ACTION_POPUP = gql`
  fragment PostFragmentCollectionPostDndHoveringActionPopup on PostModel {
    id
    myPermissions
    ...PostFragmentCollectionPostDndPostCard
    ...PostFragmentJuiceboxTablePostTableRowWithContextMenu
  }
  ${PostFragmentCollectionPostDndPostCardFragmentDoc}
  ${PostFragmentJuiceboxTablePostTableRowWithContextMenuFragmentDoc}
`;

export type CollectionPostDndHoveringActionPopupProps = {
  selectedPosts: PostFragmentCollectionPostDndHoveringActionPopupFragment[];
};

export const CollectionPostDndHoveringActionPopup = (
  props: CollectionPostDndHoveringActionPopupProps,
) => {
  const { selectedPosts } = props;

  const { orgBilling } = useUserContext();

  const {
    isOpen: isPopupVisible,
    onOpen: showPopup,
    onClose: hidePopup,
  } = useDisclosure();

  useEffect(() => {
    if (selectedPosts.length > 0) {
      showPopup();
    } else {
      hidePopup();
    }
  }, [selectedPosts]); // eslint-disable-line

  const { activeCommands, triggerCommand } = useCommandContext();
  const { onDeletePosts, deletePostsConfirmationDialog } = usePostHandlers();

  const [isDownloadingPosts, setIsDownloadingPosts] = useState(false);
  useEffect(() => {
    if (activeCommands?.some((c) => c.type === COMMAND_TYPE.POST_DOWNLOAD)) {
      setIsDownloadingPosts(true);
    } else {
      setIsDownloadingPosts(false);
    }
  }, [activeCommands]);

  const isAllowedToOrganize = useMemo(() => {
    return (
      orgBilling?.collectionUsageLimit == null ||
      orgBilling?.collectionUsageLimit > 0
    );
  }, [orgBilling]);

  const canDelete = selectedPosts.every((p) =>
    p.myPermissions.includes(PostPermission.Delete),
  );

  return (
    <>
      <Box
        sx={{
          zIndex: 1,
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          transition: '0.2s ease-in-out',
          ...(isPopupVisible
            ? {
                opacity: 1,
                bottom: 24,
              }
            : { opacity: 0, bottom: 0, pointerEvents: 'none' }),
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        }}
      >
        {[
          {
            Icon: IconLinearArrowDown2,
            tooltip: 'Download',
            disabled: isDownloadingPosts,
            onClick: () => {
              triggerCommand(COMMAND_TYPE.POST_DOWNLOAD, {
                posts: selectedPosts,
              });
            },
          },
          {
            Icon: IconBoldSave,
            tooltip: 'Save to collections',
            disabled: !isAllowedToOrganize,
            tooltipWhenDisabled: orgBilling ? (
              <BillingCollectionLimitIndicator
                organizationBilling={orgBilling}
                variant="text"
                customText="to enable organizing posts"
              />
            ) : (
              ''
            ),
            onClick: () => {
              triggerCommand(COMMAND_TYPE.SEND_TO_COLLECTION, {
                initialValues: {
                  posts: selectedPosts,
                },
              });
            },
          },
          {
            Icon: IconLinearTrash,
            tooltip: 'Delete',
            disabled: !canDelete,
            tooltipWhenDisabled:
              'To delete posts, only select posts you have full access to',
            onClick: () => {
              onDeletePosts(selectedPosts.map((p) => p.id));
            },
          },
        ].map(
          (
            { disabled, Icon, tooltip, tooltipWhenDisabled, onClick },
            index,
          ) => {
            return (
              <IconButtonWithTooltip
                key={index}
                tooltip={tooltip}
                onClick={onClick}
                disabled={disabled}
                tooltipWhenDisabled={tooltipWhenDisabled}
                sx={{
                  p: 3,
                  bgcolor: theme.colors?.primary.black,
                  color: theme.colors?.primary.parchment,
                  boxShadow:
                    '0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12)',
                  '&:hover': {
                    bgcolor: theme.colors?.primary.black,
                    opacity: 0.8,
                  },
                  '&:disabled': {
                    bgcolor: theme.colors?.utility[400],
                  },
                }}
              >
                <Icon size={24} />
              </IconButtonWithTooltip>
            );
          },
        )}
      </Box>

      {deletePostsConfirmationDialog}
    </>
  );
};
