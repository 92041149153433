import { Box, Tooltip, TooltipProps, Typography } from '@mui/material';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  JuiceboxAttachmentSearchResult,
  JuiceboxCollectionSearchResult,
  JuiceboxContextualSearchResult,
  JuiceboxLinkSearchResult,
  JuiceboxNoteSearchResult,
  JuiceboxRecentSearchResult,
  JuiceboxRecentlyOpenedSearchResult,
  JuiceboxSearchItemSkeleton,
  JuiceboxTraditionalSearchResult,
} from 'features/juicebox/components';
import { JuiceboxFilter, useKeyboardNavigation } from 'features/juicebox/hooks';
import {
  SearchType,
  SearchableEntityType,
  useGetFiltersSearchForJuiceboxFilterSuggestionViewQuery,
  useGetRecentSearchQueriesForJuiceboxFilterSuggestionViewQuery,
  useGetRecentlyOpenedQueriesForJuiceboxFilterSuggestionViewQuery,
  useGetUniversalSearchForJuiceboxFilterSuggestionViewQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { ShowMoreType } from './types';

interface Props {
  isOpen: boolean;
  children: TooltipProps['children'];
  query: string;
  onUpdateQuery: (query: string) => void;
  onSelectFilter: (filter: JuiceboxFilter) => void;
  onClose: VoidFunction;
}

export const JuiceboxFilterSuggestionView = ({
  children,
  isOpen,
  query,
  onUpdateQuery,
  onSelectFilter,
  onClose,
}: Props) => {
  const [showMore, setShowMore] = useState<ShowMoreType | null>(null);
  const [isEmptyResult, setEmptyResult] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const { disableCommands, enableCommands } = useCommandContext();
  const { itemRefs, setFocusedElIndex } = useKeyboardNavigation({
    enabled: isOpen,
    onEnterClick: onClose,
  });

  // Debounce query update
  const debouncedSetQuery = debounce((newQuery) => {
    setDebouncedQuery(newQuery);
  }, 250); // 250ms delay

  useEffect(() => {
    if (query && isOpen) {
      debouncedSetQuery(query);
    }
    // Cancel the debounce on useEffect cleanup
    return () => {
      debouncedSetQuery.cancel();
    };
  }, [query, isOpen]); // eslint-disable-line

  const { data: filtersSearchData, loading: isFiltersSearchLoading } =
    useGetFiltersSearchForJuiceboxFilterSuggestionViewQuery({
      variables: {
        data: {
          query: debouncedQuery,
        },
      },
      skip: !isOpen,
    });

  const { data: universalSearchData, loading: isUniversalSearchLoading } =
    useGetUniversalSearchForJuiceboxFilterSuggestionViewQuery({
      variables: {
        postCount: 3,
        previewableFirst: true,
        data: {
          entityTypes: [
            SearchableEntityType.Attachments,
            SearchableEntityType.Collections,
            SearchableEntityType.Links,
            SearchableEntityType.Notes,
          ],
          limit: 20,
          searchType: SearchType.SmartFullText,
          text: debouncedQuery,
        },
      },
      skip: debouncedQuery === '',
    });

  const { data: recentSearchData, loading: isRecentSearchLoading } =
    useGetRecentSearchQueriesForJuiceboxFilterSuggestionViewQuery({
      variables: {
        take: 3,
      },
      skip: debouncedQuery !== '' || !isOpen,
      fetchPolicy: 'cache-and-network',
    });

  const { data: recentlyOpenedData, loading: isRecentlyOpenedLoading } =
    useGetRecentlyOpenedQueriesForJuiceboxFilterSuggestionViewQuery({
      variables: {
        take: 3,
        searchableEntityTypes: [
          SearchableEntityType.Posts,
          SearchableEntityType.Collections,
        ],
        postCount: 3,
        previewableFirst: true,
      },
      skip: debouncedQuery !== '' || !isOpen,
    });

  useEffect(() => {
    if (!isOpen || !query) {
      setShowMore(null);
      setFocusedElIndex(-1);
      itemRefs.current = [];
    }
  }, [isOpen, query]); // eslint-disable-line

  useEffect(() => {
    if (showMore || query) {
      setFocusedElIndex(-1);
    }
  }, [showMore, query]); // eslint-disable-line

  // Disable commands when the search suggestion dialog is open
  useEffect(() => {
    const commands = [
      COMMAND_TYPE.POST_WRITE_A_NOTE,
      COMMAND_TYPE.POST_AFTER_CREATION,
      COMMAND_TYPE.CREATE_TASK,
      COMMAND_TYPE.SEND_TO_COLLECTION,
    ];
    if (isOpen) {
      disableCommands(commands);
    }

    return () => {
      enableCommands(commands);
    };
  }, [isOpen]);

  const renderShowMore = useMemo(() => {
    if (showMore === 'RecentOpened') {
      return <JuiceboxRecentlyOpenedSearchResult elRefs={itemRefs} />;
    }
    if (showMore === 'RecentSearch') {
      return (
        <JuiceboxRecentSearchResult
          elRefs={itemRefs}
          onItemSelect={onUpdateQuery}
        />
      );
    }
    if (showMore === 'Notes') {
      return <JuiceboxNoteSearchResult query={query} elRefs={itemRefs} />;
    }
    if (showMore === 'Collections') {
      return <JuiceboxCollectionSearchResult query={query} elRefs={itemRefs} />;
    }
    if (showMore === 'Links') {
      return <JuiceboxLinkSearchResult query={query} elRefs={itemRefs} />;
    }
    if (showMore === 'Attachments') {
      return <JuiceboxAttachmentSearchResult query={query} elRefs={itemRefs} />;
    }
  }, [showMore, query]); // eslint-disable-line

  const isFilterSearchDataEmpty =
    filtersSearchData?.filtersSearch?.createdBy?.length === 0 &&
    filtersSearchData?.filtersSearch?.sharedBy?.length === 0 &&
    filtersSearchData?.filtersSearch?.collections?.length === 0 &&
    filtersSearchData?.filtersSearch?.type?.length === 0 &&
    filtersSearchData?.filtersSearch.reaction?.length === 0 &&
    filtersSearchData?.filtersSearch.dateModified?.length === 0;

  useEffect(() => {
    if (debouncedQuery) {
      const isEmptyResult =
        universalSearchData?.universalSearch.noteSearchResult.hits.length ===
          0 &&
        universalSearchData?.universalSearch.linkSearchResult.hits.length ===
          0 &&
        universalSearchData?.universalSearch.attachmentSearchResult.hits
          .length === 0 &&
        universalSearchData?.universalSearch.collectionSearchResult.hits
          .length === 0 &&
        isFilterSearchDataEmpty;

      setEmptyResult(isEmptyResult);
    } else {
      const isEmptyResult =
        recentSearchData?.getRecentSearchQueries.data.length === 0 &&
        isFilterSearchDataEmpty &&
        recentlyOpenedData?.getSearchesClicked.data.length === 0;

      setEmptyResult(isEmptyResult);
    }
  }, [
    debouncedQuery,
    universalSearchData,
    isFilterSearchDataEmpty,
    recentSearchData,
    recentlyOpenedData,
    filtersSearchData,
  ]);

  const renderFilterData = useMemo(() => {
    return (
      <JuiceboxTraditionalSearchResult
        recentSearchData={recentSearchData?.getRecentSearchQueries.data || []}
        recentlyOpenedData={recentlyOpenedData?.getSearchesClicked.data || []}
        filtersSearchData={filtersSearchData?.filtersSearch || {}}
        elRefs={itemRefs}
        componentProps={{
          filterSearchItem: {
            onSelectFilter,
          },
          recentSearchItem: {
            onItemSelect: onUpdateQuery,
          },
          searchResultHeader: {
            onShowMore: (option) => {
              setShowMore(option);
              itemRefs.current = [];
            },
          },
        }}
      />
    );
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    recentSearchData,
    recentlyOpenedData,
    filtersSearchData,
    isEmptyResult,
    debouncedQuery,
  ]);

  const renderUniversalSearch = useMemo(() => {
    return (
      <JuiceboxContextualSearchResult
        elRefs={itemRefs}
        filtersSearchData={filtersSearchData?.filtersSearch || {}}
        universalSearchData={universalSearchData?.universalSearch || null}
        componentProps={{
          filterSearchItem: {
            onSelectFilter,
          },
          searchResultHeader: {
            onShowMore: (option) => {
              setShowMore(option);
              itemRefs.current = [];
            },
          },
        }}
      />
    );
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify({ filtersSearchData, universalSearchData }),
    isEmptyResult,
    debouncedQuery,
  ]);

  return (
    <Tooltip
      open={isOpen}
      placement="bottom-start"
      PopperProps={{
        style: {
          zIndex: 99999,
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            '::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 0,
            msOverflowStyle: 'none',
          },
          style: {
            display: isEmptyResult ? 'none' : 'flex',
            minWidth: 600,
            borderRadius: theme.spacing(3),
            backgroundColor: `rgba(255, 255, 255, 0.90)`,
            backdropFilter: `blur(20px)`,
            padding: theme.spacing(5, 2),
            marginTop: theme.spacing(6),
            maxHeight: 365,
            overflowY: 'auto',
            flexDirection: 'column',
            gap: theme.spacing(4),
            boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
          },
        },
      }}
      title={
        // eslint-disable-next-line
        <>
          {isRecentSearchLoading ||
          isRecentlyOpenedLoading ||
          isFiltersSearchLoading ||
          isUniversalSearchLoading ? (
            [...Array(5)].map(() => <JuiceboxSearchItemSkeleton />)
          ) : isEmptyResult ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subhead-xl"
                color={theme.colors?.primary.black}
              >
                We couldn't find any result matching{' '}
                <b>{debouncedQuery ? `"${debouncedQuery}"` : ''}</b>
              </Typography>
            </Box>
          ) : showMore ? (
            renderShowMore
          ) : debouncedQuery ? (
            renderUniversalSearch
          ) : (
            renderFilterData
          )}
        </>
      }
    >
      {children}
    </Tooltip>
  );
};
