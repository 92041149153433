import { IconButton } from '@mui/material';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { theme } from 'styles/theme';

type HeaderSearchButtonProps = {
  iconColor?: string;
};

export const HeaderSearchButton = (props: HeaderSearchButtonProps) => {
  const { iconColor } = props;

  const { triggerCommand } = useCommandContext();

  const isMobileView = useMediaQueryMobile();

  return (
    <IconButton
      onClick={() => triggerCommand(COMMAND_TYPE.SEARCH)}
      {...(isMobileView ? { size: 'small' } : {})}
    >
      <IconOutlineSearchNormal1
        size={isMobileView ? 20 : 24}
        color={iconColor ?? theme.colors?.primary.black}
      />
    </IconButton>
  );
};
