import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearMaximize4 } from 'components/icons/components/linear/IconLinearMaximize4';
import { IconLinearMinimize } from 'components/icons/components/linear/IconLinearMinimize';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningPostDetailView,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening';
import { HoverPlayableVideo } from 'features/socialMediaListening/views/postsGrid/postCard/HoverPlayableVideo';
import { StaticImage } from 'features/socialMediaListening/views/postsGrid/postCard/StaticImage';
import { platformIconMap } from 'features/socialPost/constants';
import {
  BrandInboundFiltersInput,
  PaginatedBrandInboundFiltersInputSortField,
  SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragmentDoc,
  SignalDefinitionFragmentSlaBrandInboundGraphPostsViewFragment,
  SocialPostType,
  SortOrder,
  useGetBrandInboundSocialPostsForSlaBrandInboundGraphPostsViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SignalDefinitionFragmentSLABrandInboundGraphPostsView on SignalDefinitionModel {
    ...SignalDefinitionFragmentSLAAnalyticsFilterByCategory
  }
  ${SignalDefinitionFragmentSlaAnalyticsFilterByCategoryFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandInboundSocialPostsForSLABrandInboundGraphPostsView(
    $data: PaginatedBrandInboundFiltersInputForSocialPosts!
  ) {
    getBrandInboundSocialPosts(data: $data) {
      data {
        id
        platform
        thumbnailUrl
        videoUrl
        type
        playCount
        creator {
          id
          handle
          profilePictureUrl
        }
        ...SocialPostFragmentUseSocialListeningPostLinkGenerator
      }
      meta {
        totalCount
        totalEngagement
        totalViewCount
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

type Props = {
  signalDefinitionId?: string;
  signalCategoryOptionId?: string;
  allSignalDefinitions: SignalDefinitionFragmentSlaBrandInboundGraphPostsViewFragment[];
  filters: BrandInboundFiltersInput;
  isOpen: boolean;
  onClose: () => void;
};

export const SLABrandInboundGraphPostsDialogView = ({
  signalDefinitionId,
  signalCategoryOptionId,
  allSignalDefinitions,
  filters,
  isOpen,
  onClose,
}: Props) => {
  const { generateLink } = useSocialListeningPostLinkGenerator();
  const [sortKey, setSortKey] = useState(
    PaginatedBrandInboundFiltersInputSortField.TotalEngagement,
  );
  const [fullScreen, setFullScreen] = useState(false);
  const [currentPostId, setCurrentPostId] = useState('');

  const [dateRange, setDateRange] = useState({
    startDate: moment(filters.dateRange.startDate).toDate(),
    endDate: moment(filters.dateRange.endDate).toDate(),
  });

  const signalDefinition = allSignalDefinitions.find(
    (x) =>
      x.id === signalDefinitionId ||
      x.options.find((o) => o.id === signalCategoryOptionId),
  );

  const signalCategoryOption = signalCategoryOptionId
    ? signalDefinition?.options.find((o) => o.id === signalCategoryOptionId)
    : undefined;

  const baseFilter = {
    ...filters,
    signalDefinitionIds: signalDefinitionId ? [signalDefinitionId] : [],
    signalCategoryOptionIds: signalCategoryOptionId
      ? [signalCategoryOptionId]
      : [],
    dateRange: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    },
    sortBy: {
      field: sortKey,
      order: SortOrder.Desc,
    },
    take: 10,
  };

  const {
    data: socialPostsData,
    loading: socialPostsLoading,
    fetchMore: fetchMoreSocialPosts,
    refetch: refetchSocialPosts,
  } = useGetBrandInboundSocialPostsForSlaBrandInboundGraphPostsViewQuery({
    variables: {
      data: { ...baseFilter },
    },
  });
  const socialPosts = socialPostsData?.getBrandInboundSocialPosts;

  useEffect(() => {
    refetchSocialPosts();
  }, [sortKey]);

  const handleBackClick = () => {
    if (currentPostId) {
      setCurrentPostId('');
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: fullScreen ? '100vw' : '90vw',
          height: fullScreen ? '100vh' : '90vh',
          backgroundColor: '#FAF3EC88',
          maxWidth: 'unset',
          maxHeight: 'unset',
          backdropFilter: 'blur(20px)',
          borderRadius: fullScreen ? 0 : 4,
          margin: fullScreen ? 0 : 'auto',
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.colors?.primary.parchment,
            borderBottom: `1px solid ${theme.colors?.utility[200]}`,
            p: 4,
          }}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <IconButton onClick={handleBackClick} disableRipple>
              <IconOutlineArrowLeft size={20} />
            </IconButton>
            <Typography variant="body-xl" fontWeight={600}>
              {signalDefinition?.title}
            </Typography>
            {signalCategoryOption && (
              <Typography
                color={theme.colors?.utility[700]}
                variant="body-xl"
                fontWeight={600}
              >
                / {signalCategoryOption?.label}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" gap={3}>
            <IconButton
              onClick={() => setFullScreen(!fullScreen)}
              disableRipple
            >
              {fullScreen ? (
                <IconLinearMinimize
                  style={{ color: theme.colors?.utility[700] }}
                  size={20}
                />
              ) : (
                <IconLinearMaximize4
                  style={{ color: theme.colors?.utility[700] }}
                  size={20}
                />
              )}
            </IconButton>
            <IconButton onClick={onClose} disableRipple>
              <IconBoldCloseCircle
                style={{ color: theme.colors?.utility[700] }}
                size={24}
              />
            </IconButton>
          </Box>
        </Box>

        {!currentPostId && (
          <Box
            p={3}
            flexGrow={1}
            overflow="auto"
            sx={{ backgroundColor: '#FAF3ECCC' }}
          >
            <InfiniteScroll
              loadMore={() => {
                fetchMoreSocialPosts({
                  variables: {
                    data: {
                      ...baseFilter,
                      after:
                        socialPosts?.data?.length &&
                        socialPosts?.data &&
                        socialPosts.data.length > 0
                          ? socialPosts.data[socialPosts.data.length - 1]?.id
                          : undefined,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      getBrandInboundSocialPosts: {
                        ...fetchMoreResult.getBrandInboundSocialPosts,
                        data: [
                          ...prev.getBrandInboundSocialPosts.data,
                          ...fetchMoreResult.getBrandInboundSocialPosts.data,
                        ],
                      },
                    };
                  },
                });
              }}
              hasMore={
                socialPostsData?.getBrandInboundSocialPosts.pageInfo.hasNextPage
              }
              threshold={50}
              useWindow={false}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                gap={2}
                mb={3}
                justifyContent="space-between"
                width="100%"
                sx={{
                  p: 4,
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography variant="headline-lg" mr={4}>
                      {signalDefinition?.title}
                    </Typography>
                    {signalCategoryOption && (
                      <Typography
                        color={theme.colors?.utility[700]}
                        variant="headline-lg"
                      >
                        / {signalCategoryOption?.label}
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    variant="body-xl"
                    fontWeight={600}
                    mt={2}
                    color={theme.colors?.utility[700]}
                  >
                    {socialPosts?.meta.totalCount} posts
                  </Typography>
                </Box>

                <SocialMediaListeningFilterByDateRangeButton
                  onChange={(range) => {
                    if (range[0] && range[1]) {
                      setDateRange({
                        startDate: range[0],
                        endDate: range[1],
                      });
                    }
                  }}
                  selectedDateRange={[dateRange.startDate, dateRange.endDate]}
                />
              </Box>

              <Box sx={{ m: 4 }}>
                <Grid
                  container
                  spacing={2}
                  mb={3}
                  sx={{
                    backgroundColor: theme.colors?.primary.parchment,
                    borderRadius: 2,
                    p: 4,
                  }}
                >
                  <Grid item xs={4}>
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Total Post Count
                        <Tooltip title="Total number of posts">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                        fontFamily="Good Sans"
                      >
                        {socialPosts?.meta.totalCount}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      borderLeft: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      pl={4}
                      fontFamily="Good Sans"
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Total view count
                        <Tooltip title="Total view count of posts">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                        fontFamily="Good Sans"
                      >
                        {socialPosts?.meta.totalViewCount
                          ? formatBigNumber(socialPosts.meta.totalViewCount)
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      borderLeft: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      pl={4}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Total engagement
                        <Tooltip title="Total engagement of posts">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                        fontFamily="Good Sans"
                      >
                        {socialPosts?.meta.totalEngagement
                          ? formatBigNumber(socialPosts.meta.totalEngagement)
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                  my: 4,
                }}
              />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                m={4}
                mb={2}
              >
                <Typography variant="headline-sm">Posts</Typography>
                <ContextMenu
                  sx={{
                    '& .context-menu-item': {
                      p: 0,
                      color: theme.colors?.primary.black,
                    },
                  }}
                  options={[
                    {
                      renderOption: () => (
                        <RadioMenuItem
                          label="Total engagement"
                          value={
                            PaginatedBrandInboundFiltersInputSortField.TotalEngagement
                          }
                          checked={
                            sortKey ===
                            PaginatedBrandInboundFiltersInputSortField.TotalEngagement
                          }
                          sx={{
                            p: `${theme.spacing(2, 3)}`,
                          }}
                        />
                      ),
                      onClick: () => {
                        setSortKey(
                          PaginatedBrandInboundFiltersInputSortField.TotalEngagement,
                        );
                      },
                    },
                    {
                      renderOption: () => (
                        <RadioMenuItem
                          label="Total impressions"
                          value={
                            PaginatedBrandInboundFiltersInputSortField.TotalImpressions
                          }
                          checked={
                            sortKey ===
                            PaginatedBrandInboundFiltersInputSortField.TotalImpressions
                          }
                          sx={{
                            p: `${theme.spacing(2, 3)}`,
                          }}
                        />
                      ),
                      onClick: () => {
                        setSortKey(
                          PaginatedBrandInboundFiltersInputSortField.TotalImpressions,
                        );
                      },
                    },
                  ]}
                  renderButton={() => (
                    <Button
                      sx={{
                        backgroundColor: theme.colors?.utility[275],
                        color: theme.colors?.primary.black,
                        borderRadius: theme.spacing(2),
                        display: 'flex',
                        gap: 2,
                        p: theme.spacing(1, 2),
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: theme.colors?.utility[275],
                        },
                      }}
                      disableRipple={false}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          backgroundColor: 'rgba(35, 6, 3, 0.05);',
                          p: theme.spacing(1),
                          borderRadius: theme.spacing(1),
                        }}
                      >
                        <IconLinearSort size={16} />
                      </Box>
                      <Typography fontWeight={500} variant="body-xl">
                        Sort by
                      </Typography>
                    </Button>
                  )}
                />
              </Box>

              {!socialPostsLoading && (
                <Box sx={{ m: 4 }}>
                  {socialPosts?.data?.length ? (
                    <Grid container spacing={2}>
                      {socialPosts?.data?.map((post) => {
                        const Icon = platformIconMap[post.platform];

                        const renderActionsTopRight = (isHovered: boolean) => (
                          <Box
                            display="flex"
                            gap={2}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            {isHovered && post.creator?.handle && (
                              <IconButton
                                disableRipple
                                sx={{
                                  p: 1,
                                  backgroundColor: 'rgba(35, 6, 3, 0.10)',
                                  color: theme.colors?.primary.white,
                                  backdropFilter: 'blur(4px)',
                                }}
                                onClick={() => {
                                  window.open(
                                    generateLink(post) || '',
                                    '_blank',
                                  );
                                }}
                              >
                                <IconLinearExport2 size={16} />
                              </IconButton>
                            )}
                            {Icon && (
                              <Icon
                                size={16}
                                style={{
                                  color: theme.colors?.primary.white,
                                }}
                              />
                            )}
                          </Box>
                        );

                        const renderActionsBottom = () => (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                          >
                            <Box
                              display="flex"
                              gap={1}
                              alignItems="center"
                              ml={2}
                              width="70%"
                              maxWidth="70%"
                            >
                              <Avatar
                                src={post.creator?.profilePictureUrl || ''}
                                sx={{
                                  width: theme.spacing(4),
                                  height: theme.spacing(4),
                                }}
                              />
                              <Typography
                                color={theme.colors?.primary.parchment}
                                variant="body-lg"
                                fontWeight={600}
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {post.creator?.handle}
                              </Typography>
                            </Box>

                            {post.type === SocialPostType.Video && (
                              <Box
                                mr={2}
                                display="flex"
                                gap={1}
                                alignItems="center"
                              >
                                <IconBoldPlay
                                  size={16}
                                  color={theme.colors?.primary.parchment}
                                />
                                <Typography
                                  color={theme.colors?.primary.parchment}
                                  variant="body-lg"
                                  fontWeight={600}
                                >
                                  {formatBigNumber(post.playCount || 0)}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        );

                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            lg={2.4}
                            key={post.id}
                          >
                            <Box
                              sx={{
                                position: 'relative',
                              }}
                              onClick={() => setCurrentPostId(post.id)}
                            >
                              {post.type === SocialPostType.Video ? (
                                <HoverPlayableVideo
                                  thumbnailUrl={post.thumbnailUrl || ''}
                                  videoUrl={post.videoUrl || ''}
                                  renderActionsTopRight={renderActionsTopRight}
                                  renderActionsBottom={renderActionsBottom}
                                />
                              ) : (
                                <StaticImage
                                  thumbnailUrl={post.thumbnailUrl || ''}
                                  renderActionsTopRight={renderActionsTopRight}
                                  renderActionsBottom={renderActionsBottom}
                                />
                              )}
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="body-xl"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        No posts found
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </InfiniteScroll>
          </Box>
        )}
        {currentPostId && (
          <Box
            px={12}
            sx={{
              backgroundColor: theme.colors?.primary.parchment,
              minHeight: theme.spacing(100),
              overflow: 'auto',
            }}
          >
            <Box
              p={4}
              mt={8}
              sx={{
                backgroundColor: theme.colors?.primary.white,
                borderRadius: theme.spacing(4),
              }}
            >
              <SocialMediaListeningPostDetailView
                brandId={filters.brandId}
                socialPostId={currentPostId}
                componentProps={{
                  modalView: true,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
