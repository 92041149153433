import { GetSocialPostForSocialMediaListeningPostDetailQuery } from 'graphql/generated';
import { useCarousel } from 'components/common/Media/components/Carousel/useCarousel';
import { useEffect } from 'react';

export const SocialMediaListeningPostDetailCarousel = ({
  post,
  onHeightSet,
}: {
  post: GetSocialPostForSocialMediaListeningPostDetailQuery['socialPost'];
  onHeightSet: (renderedHeight: number) => void;
}) => {
  const { renderCarousel, renderedHeight } = useCarousel(post.carouselMedias);

  useEffect(() => {
    onHeightSet(renderedHeight);
  }, [renderedHeight, onHeightSet]);

  return renderCarousel();
};
