/**
 * This component have two main functionalities:
 * 1. Allow users with update permission (aka view's owner) to apply changes to the current view, OR
 * 2. Allow users to create a new view with the changes they made.
 *
 * I'll need to rethink the name.
 */

import { gql } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconOutlineTickCircle } from 'components/icons/components/outline/IconOutlineTickCircle';
import { useContentCalendarView } from 'features/contentCalendarView';
import {
  ContentCalendarViewPermission,
  useUpdateContentCalendarViewForApplyChangesButtonMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation UpdateContentCalendarViewForApplyChangesButton(
    $data: UpdateContentCalendarViewInput!
  ) {
    updateContentCalendarView(data: $data) {
      id
      filters
    }
  }
`;

export type ApplyChangesButtonProps = {
  selectedFilters: Record<string, object>;
  onSaveAsNewView?: () => void;
};

export const ApplyChangesButton = (props: ApplyChangesButtonProps) => {
  const { selectedFilters, onSaveAsNewView } = props;

  // We'll be using `view` search param to determine if we are using a view.
  const [params] = useSearchParams();
  const contentCalendarViewId = params.get('view') || '';

  const { contentCalendarView } = useContentCalendarView(contentCalendarViewId);
  const canUpdate = contentCalendarView?.myPermissions.includes(
    ContentCalendarViewPermission.Update,
  );

  const [updateContentCalendarView] =
    useUpdateContentCalendarViewForApplyChangesButtonMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onSaveChanges = async () => {
    setIsLoading(true);

    await updateContentCalendarView({
      variables: {
        data: {
          contentCalendarViewId,
          data: {
            filters: JSON.stringify(selectedFilters),
          },
        },
      },
    });

    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        mt: 2,
        mb: 4,
        mx: 3,
        p: 3,
        bgcolor: theme.colors?.utility[300],
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        gap: 3,
      }}
    >
      <Typography variant="body-lg" textAlign="center">
        Unsaved changes to the View
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        <Button
          variant="tertiary"
          startIcon={<IconLinearAdd size={14} />}
          size="small"
          onClick={onSaveAsNewView}
          sx={{
            bgcolor: '#2306031A',
            px: 2,
          }}
          disabled={isLoading}
        >
          Save as New
        </Button>
        {canUpdate && (
          <Button
            variant="primary-alt"
            startIcon={<IconOutlineTickCircle size={14} />}
            size="small"
            onClick={onSaveChanges}
            sx={{
              px: 2,
            }}
            disabled={isLoading}
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};
