/**
 * This component types, fragments, logic, etc. are nearly identical to the PostReactionButton component,
 * with very small differences. The lenghthy part is mostly about cache manipulation.
 *
 * I'll circle back to this later to see if I can refactor things to be less lengthy.
 */

import { gql, useApolloClient } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconCustomFiRrSmileWink } from 'components/icons/components/custom/IconCustomFiRrSmileWink';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import {
  PostFragmentPostReactionListFragment,
  UserReactionFragmentPostReactionListFragment,
  useReactToPostForPostReactionListMutation,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { PostReactionButton } from '../reactionButton';
import { postReactionOptions } from './constants';

const MAX_VISIBLE_USER_NAMES = 3;

export const USER_REACTION_FRAGMENT_POST_REACTION_LIST = gql`
  fragment UserReactionFragmentPostReactionList on UserReactionModel {
    id
    emoji
    user {
      id
      firstName
    }
  }
`;

export const POST_FRAGMENT_POST_REACTION_LIST = gql`
  fragment PostFragmentPostReactionList on PostModel {
    id
    userReactions {
      id
      ...UserReactionFragmentPostReactionList
    }
  }
  ${USER_REACTION_FRAGMENT_POST_REACTION_LIST}
`;

// eslint-disable-next-line
gql`
  mutation ReactToPostForPostReactionList($data: ReactToPostInput!) {
    reactToPost(data: $data) {
      success
      message
    }
  }
`;

export type PostReactionListProps = {
  post: PostFragmentPostReactionListFragment;
  sx?: SxProps;
  variant?: 'full' | 'hide-zero';
  componentsProps?: {
    reactionButton?: {
      sx?: SxProps;
    };
  };
};

export const PostReactionList = (props: PostReactionListProps) => {
  const { post, sx, variant = 'full', componentsProps = {} } = props;

  const client = useApolloClient();
  const { user } = useUserContext();

  const currentUserReaction = (post.userReactions || []).find(
    (reaction) => reaction?.user?.id === user?.id,
  );

  const [reactToPost] = useReactToPostForPostReactionListMutation();
  const onReact = (emoji: string) => {
    reactToPost({
      variables: {
        data: {
          postId: post.id,
          emoji,
        },
      },
    });

    modifyObject(client.cache, post.id, 'PostModel', {
      userReactions: (cachedUserReactions) => {
        // Remove existing reaction if user has reacted to the post
        // Otherwise push in a new reaction object

        const isReactingToSameEmoji = emoji === currentUserReaction?.emoji;

        // Filter out current user reaction (if any)
        const newUserReactions = cachedUserReactions.filter((r) => {
          if (r.__ref) {
            const cachedUserReaction = client.cache.readFragment({
              id: r.__ref,
              fragment: USER_REACTION_FRAGMENT_POST_REACTION_LIST,
            }) as UserReactionFragmentPostReactionListFragment;

            if (cachedUserReaction.user.id !== user?.id) {
              return true;
            }

            return cachedUserReaction?.emoji !== currentUserReaction?.emoji;
          }

          if (r.user.id === user?.id) {
            return true;
          }

          return r.emoji !== currentUserReaction?.emoji;
        });

        // Return the reduced list of reactions if user react on the same emoji as current reaction
        // That means we simply remove the current reaction
        if (isReactingToSameEmoji) {
          return newUserReactions;
        }

        // Otherwise push a new reaction object
        // Use optimistic UI to update the cache
        return [
          ...newUserReactions,
          client.cache.writeFragment({
            data: {
              __typename: 'UserReactionModel',
              id: GenerateId.create(),
              emoji,
              user: {
                __typename: 'UserModel',
                id: user?.id,
                firstName: user?.firstName,
              },
            },
            fragment: USER_REACTION_FRAGMENT_POST_REACTION_LIST,
            fragmentName: 'UserReactionFragmentPostReactionList',
          }),
        ];
      },
    });
  };

  const finalReactions =
    variant === 'hide-zero'
      ? postReactionOptions.filter((reaction) => {
          const reactionCount = (post.userReactions || []).filter(
            (r) => r.emoji === reaction.emoji,
          ).length;

          return reactionCount > 0;
        })
      : postReactionOptions;

  return (
    <Box display="flex" gap={2} alignItems="center" sx={sx}>
      {/* Show emoji reaction button when we are using hide-zero variant AND not all reactions have count > 0 */}
      {variant === 'hide-zero' && finalReactions.length < 4 && (
        <PostReactionButton
          post={post}
          renderButton={() => {
            return (
              <Box
                component="button"
                {...props}
                sx={{
                  flexShrink: 0,
                  position: 'relative',
                  borderRadius: 50,
                  overflow: 'hidden',
                  color: theme.colors?.primary.white,
                  bgcolor: 'rgba(35, 6, 3, 0.05)',
                  py: 1.5,
                  px: 3,
                  border: '2px solid rgba(35, 6, 3, 0.05)',
                  display: 'flex',
                  gap: 1.5,
                  alignItems: 'center',
                  ...componentsProps.reactionButton?.sx,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <IconCustomFiRrSmileWink size={14} />
                <Typography variant="headline-xs">+</Typography>
              </Box>
            );
          }}
        />
      )}
      {finalReactions.map((reaction) => {
        const reactionCount = (post.userReactions || []).filter(
          (r) => r.emoji === reaction.emoji,
        ).length;
        const isSelected = currentUserReaction?.emoji === reaction.emoji;

        const usersWhoReacted = (post.userReactions || []).filter(
          (r) => r.emoji === reaction.emoji,
        );
        const hiddenUserCount = Math.max(
          0,
          usersWhoReacted.length - MAX_VISIBLE_USER_NAMES,
        );

        return (
          <Tooltip
            key={reaction.emoji}
            componentsProps={
              reactionCount === 0
                ? {}
                : {
                    tooltip: {
                      sx: {
                        borderRadius: 3,
                        px: 4,
                        py: 6,
                        bgcolor: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(20px)',
                        color: theme.colors?.primary.black,
                        boxShadow:
                          '0px 18px 88px -4px rgba(24, 39, 75, 0.14), 0px 8px 28px -6px rgba(24, 39, 75, 0.12)',
                        width: 256,
                      },
                    },
                  }
            }
            placement="top"
            title={
              reactionCount === 0 ? (
                reaction.tooltip
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 4,
                  }}
                >
                  <Box
                    sx={{
                      width: 64,
                      height: 64,
                      borderRadius: 50,
                      bgcolor: reaction.hoverBgColor,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 28,
                    }}
                  >
                    {reaction.emoji}
                  </Box>
                  <Typography variant="body-lg" textAlign="center">
                    <b>
                      {usersWhoReacted
                        .map((r) =>
                          r.user.id === user?.id ? 'You' : r.user.firstName,
                        )
                        .join(', ')}
                      {hiddenUserCount > 0 && ` and ${hiddenUserCount} others`}
                    </b>{' '}
                    reacted with <b>"{reaction.tooltip}"</b>
                  </Typography>
                </Box>
              )
            }
          >
            <Box
              component="button"
              sx={{
                flexShrink: 0,
                position: 'relative',
                borderRadius: 50,
                overflow: 'hidden',
                color: 'inherit',
                bgcolor: 'rgba(35, 6, 3, 0.05)',
                '&:hover': {
                  bgcolor: reaction.hoverBgColor,
                },
                py: 1.5,
                px: 3,
                border: '2px solid rgba(35, 6, 3, 0.05)',
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
                ...componentsProps.reactionButton?.sx,
                ...(isSelected
                  ? {
                      bgcolor: reaction.selectedBgColor,
                      color: reaction.selectedTextColor,
                    }
                  : {}),
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onReact(reaction.emoji);
              }}
            >
              <Box component="span">{reaction.emoji}</Box>
              <Typography variant="headline-xs">{reactionCount}</Typography>
            </Box>
          </Tooltip>
        );
      })}
    </Box>
  );
};
