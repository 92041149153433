import { gql } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { PostPreview } from 'features/post/components';
import {
  CollectionFragmentCollectionCardCompactViewFragmentDoc,
  CollectionFragmentCollectionListItemViewFragmentDoc,
  CollectionFragmentCollectionPeekingCardViewFragment,
  CollectionFragmentCollectionPermissionSummaryFragmentDoc,
  PostFilterType,
  PostFragmentPostPreviewFragmentDoc,
  PostType,
  useGetPostsForCollectionPeekingCardViewQuery,
} from 'graphql/generated';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { CollectionCardCompactView } from '../cardCompact';
import { CollectionListItemView } from '../listItem';

const MINIMUM_GRID_ITEM_COUNT = 6;

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionPeekingCardView on CollectionModel {
    id
    name
    childCollections {
      id
      parentCollectionId
      ...CollectionFragmentCollectionCardCompactView
      ...CollectionFragmentCollectionPermissionSummary
    }
    ...CollectionFragmentCollectionListItemView
    ...CollectionFragmentCollectionPermissionSummary
  }
  ${CollectionFragmentCollectionListItemViewFragmentDoc}
  ${CollectionFragmentCollectionCardCompactViewFragmentDoc}
  ${CollectionFragmentCollectionPermissionSummaryFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetPostsForCollectionPeekingCardView(
    $filters: PostFilters!
    $take: Int!
  ) {
    posts(filters: $filters, take: $take) {
      data {
        id
        ...PostFragmentPostPreview
      }
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export type CollectionPeekingCardViewProps = {
  collection: CollectionFragmentCollectionPeekingCardViewFragment;
};

export const CollectionPeekingCardView = (
  props: CollectionPeekingCardViewProps,
) => {
  const { collection } = props;

  // Calculate how many posts to render by subtracting the number of child collections from the minimum grid item count.
  // Because we want to render at least 6 items, and we prioritize child collections over posts.
  const postAmountToRender = Math.max(
    0,
    MINIMUM_GRID_ITEM_COUNT - collection.childCollections.length,
  );
  const { data: postData } = useGetPostsForCollectionPeekingCardViewQuery({
    variables: {
      filters: {
        collectionId: collection.id,
        filterType: PostFilterType.OrganizationPosts,
      },
      take: postAmountToRender,
    },
    skip: !postAmountToRender,
  });
  const posts = postData?.posts.data ?? [];

  return (
    <Box
      sx={{
        minWidth: 320,
        color: theme.colors?.primary.black,
        bgcolor: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(20px)',
        borderRadius: 3,
        overflow: 'hidden',
        boxShadow:
          '0px 18px 88px -4px rgba(24, 39, 75, 0.14), 0px 8px 28px -6px rgba(24, 39, 75, 0.12)',
      }}
    >
      <Box
        sx={{
          py: 4,
          px: 6,
          bgcolor: 'rgba(35, 6, 3, 0.1)',
          backdropFilter: 'blur(25px)',
          display: 'flex',
        }}
      >
        <CollectionListItemView
          collection={collection}
          componentsProps={{
            name: {
              sx: {
                fontWeight: 600,
              },
            },
          }}
        />
      </Box>
      {(collection.childCollections.length > 0 || posts.length > 0) && (
        <Box
          sx={{
            maxHeight: 256,
            p: 1.5,
            overflow: 'auto',
          }}
        >
          <Grid container spacing={1}>
            {collection.childCollections.map((childCollection) => {
              return (
                // The height is set to 120px because we should fit exactly 2 rows of grid items
                // in the 256px container height.
                <Grid
                  item
                  xs={4}
                  key={childCollection.id}
                  height={120}
                  position="relative"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Link to={PlotRoutes.collection(childCollection.id)}>
                    <CollectionCardCompactView
                      collection={childCollection}
                      sx={{ borderRadius: 2, overflow: 'hidden' }}
                    />
                  </Link>
                </Grid>
              );
            })}
            {posts.map((post) => (
              <Grid item xs={4} key={post.id} height={120}>
                <Link
                  to={
                    post.type === PostType.Note
                      ? PlotRoutes.juiceboxNote({ id: post.id })
                      : PlotRoutes.juice(post.id)
                  }
                >
                  <PostPreview
                    post={post}
                    variant="compact"
                    sx={{ borderRadius: 2, overflow: 'hidden' }}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};
