/* eslint-disable react/no-unstable-nested-components */
import { Box, Typography } from '@mui/material';
import { Editor } from '@tiptap/react';
import { useMemo } from 'react';
import { EmojiPickerWrapper } from 'components/common/EmojiPicker';
import { IconBoldCodeCircle } from 'components/icons/components/bold/IconBoldCodeCircle';
import { IconBoldEmojiHappy } from 'components/icons/components/bold/IconBoldEmojiHappy';
import { IconBoldTaskSquare } from 'components/icons/components/bold/IconBoldTaskSquare';
import { IconBoldTextBold } from 'components/icons/components/bold/IconBoldTextBold';
import { IconBoldTextItalic } from 'components/icons/components/bold/IconBoldTextItalic';
import { IconBoldTextUnderline } from 'components/icons/components/bold/IconBoldTextUnderline';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { theme } from 'styles/theme/theme';
import { useRichTextStyles } from '../../styles';
import { HyperlinkPopoverWrapper } from './components';
import { StyledToolBar } from './styles';

interface ToolBarProps {
  editor: Editor | null;
  isBubble?: boolean;
}

export const ToolBar = (props: ToolBarProps) => {
  const { editor, isBubble } = props;
  const classes = useRichTextStyles();

  const isBold = editor?.isActive('bold');
  const isItalic = editor?.isActive('italic');
  const isUnderline = editor?.isActive('underline');
  const isCodeBlock = editor?.isActive('codeBlock');
  const isBulletList = editor?.isActive('bulletList');

  const options = useMemo(
    () => [
      {
        key: 'text-bold',
        icon: IconBoldTextBold,
        onClick: () => editor?.chain().focus().toggleBold().run(),
        enabled: isBold,
      },
      {
        key: 'text-italic',
        icon: IconBoldTextItalic,
        onClick: () => editor?.chain().focus().toggleItalic().run(),
        enabled: isItalic,
      },
      {
        key: 'text-underline',
        icon: IconBoldTextUnderline,
        onClick: () => editor?.chain()?.focus().toggleUnderline().run(),
        enabled: isUnderline,
      },
      {
        key: 'code-snippet',
        icon: IconBoldCodeCircle,
        onClick: () => editor?.chain().focus().toggleCodeBlock().run(),
        enabled: isCodeBlock,
      },
      {
        key: 'list-view',
        icon: IconBoldTaskSquare,
        onClick: () => editor?.chain().focus().toggleBulletList().run(),
        enabled: isBulletList,
      },
      {
        key: 'emoji',
        icon: (props) => {
          return (
            <EmojiPickerWrapper
              onEmojiSelect={(emoji) => {
                editor?.commands?.insertContent(emoji?.native);
              }}
            >
              <IconBoldEmojiHappy {...props} />
            </EmojiPickerWrapper>
          );
        },
        onClick: () => {},
      },
      {
        key: 'hyperlink',
        icon: (props) => {
          return (
            <HyperlinkPopoverWrapper editor={editor}>
              <IconOutlineLink2 {...props} />
            </HyperlinkPopoverWrapper>
          );
        },
        onClick: () => {},
      },
      {
        key: 'mention',
        icon: (props) => {
          return (
            <Box
              display="flex"
              width={theme.spacing(4)}
              height={theme.spacing(4)}
              alignItems="flex-end"
              justifyContent="center"
              onClick={() => {
                editor?.commands?.insertContent('@');
              }}
            >
              <Typography variant="headline-sm" {...props}>
                @
              </Typography>
            </Box>
          );
        },
        onClick: () => {},
      },
    ],
    [editor, isBold, isItalic, isUnderline, isCodeBlock, isBulletList],
  );

  const optionsRender = useMemo(() => {
    return options.map((item) => {
      const { icon: Icon } = item;
      return (
        <Box
          key={item.key}
          className={classes.toolbarIcon}
          onClick={() => {
            item?.onClick();
          }}
        >
          <Icon
            size={16}
            color={
              item.enabled
                ? theme.colors?.utility[800]
                : theme.colors?.utility[600]
            }
          />
        </Box>
      );
    });
  }, [classes.toolbarIcon, options]);

  return (
    <StyledToolBar
      sx={
        isBubble
          ? {
              backgroundColor: theme.colors?.utility[100],
              boxShadow: theme.shadows[3],
              borderRadius: '4px',
              paddingX: theme.spacing(2),
              paddingY: theme.spacing(1),
            }
          : undefined
      }
    >
      {optionsRender}
    </StyledToolBar>
  );
};
