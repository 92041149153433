import moment from 'moment';

/**
 * Return a shorten humanized timestamp for a given date.
 *
 * - 1s ago
 * - 1m ago
 * - 1h ago
 * - 1d ago
 * - ...
 */
export const getShortHumanizedTimestamp = (date: moment.Moment) => {
  const now = moment();

  const diffSeconds = now.diff(date, 'seconds');
  if (diffSeconds < 60) {
    return `${diffSeconds}s ago`;
  }

  const diffMinutes = now.diff(date, 'minutes');
  if (diffMinutes < 60) {
    return `${diffMinutes}m ago`;
  }

  const diffHours = now.diff(date, 'hours');
  if (diffHours < 24) {
    return `${diffHours}h ago`;
  }

  const diffDays = now.diff(date, 'days');
  if (diffDays < 30) {
    return `${diffDays}d ago`;
  }

  const diffMonths = now.diff(date, 'months');
  if (diffMonths < 12) {
    return `${diffMonths}mo ago`;
  }

  const diffYears = now.diff(date, 'years');
  return `${diffYears}y ago`;

  return date.fromNow();
};
