import { gql } from '@apollo/client';
import { Box, CircularProgress } from '@mui/material';
import {
  PostFragmentJuiceboxAttachmentSearchItemFragmentDoc,
  SearchType,
  SearchableEntityType,
  useGetAttachmentsForJuiceboxAttachmentSearchResultQuery,
} from 'graphql/generated';
import { MutableRefObject, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { JuiceboxAttachmentSearchItem } from '../searchItem';
import { JuiceboxSearchResultHeader } from '../searchResultHeader';

// eslint-disable-next-line
gql`
  query GetAttachmentsForJuiceboxAttachmentSearchResult(
    $data: UniversalSearchInput!
  ) {
    universalSearch(data: $data) {
      attachmentSearchResult {
        hits {
          item {
            ...PostFragmentJuiceboxAttachmentSearchItem
          }
        }
        totalCount
      }
      pageInfo {
        endCursor
      }
    }
  }
  ${PostFragmentJuiceboxAttachmentSearchItemFragmentDoc}
`;

interface Props {
  query: string;
  elRefs: MutableRefObject<(HTMLElement | null)[]>;
}

export const JuiceboxAttachmentSearchResult = ({ elRefs, query }: Props) => {
  const [hasMoreAttachments, setHasMoreAttachments] = useState(true);
  const variables = {
    entityTypes: [SearchableEntityType.Attachments],
    limit: 10,
    searchType: SearchType.SmartFullText,
    text: query,
  };

  const { data: attachmentsData, fetchMore } =
    useGetAttachmentsForJuiceboxAttachmentSearchResultQuery({
      variables: {
        data: {
          ...variables,
        },
      },
    });

  const totalAttachmentCount = useMemo(
    () =>
      attachmentsData?.universalSearch.attachmentSearchResult.totalCount || 0,
    [attachmentsData?.universalSearch],
  );

  const attachments = useMemo(
    () =>
      attachmentsData?.universalSearch.attachmentSearchResult.hits.map(
        ({ item }) => item,
      ) || [],
    [attachmentsData?.universalSearch.attachmentSearchResult.hits],
  );
  return (
    <>
      <JuiceboxSearchResultHeader title={`Links(${totalAttachmentCount})`} />
      <InfiniteScroll
        style={{
          flexDirection: 'column',
          display: 'flex',
          gap: theme.spacing(1),
          marginTop: theme.spacing(-2),
        }}
        threshold={100}
        loadMore={() => {
          if (!hasMoreAttachments || !attachments.length) {
            return;
          }

          fetchMore({
            variables: {
              data: {
                ...variables,
                after:
                  String(
                    attachmentsData?.universalSearch.pageInfo.endCursor || 0,
                  ) || '0',
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                fetchMoreResult.universalSearch.attachmentSearchResult.hits
                  .length === 0
              ) {
                setHasMoreAttachments(false);
              }
              return {
                ...fetchMoreResult,
                universalSearch: {
                  ...fetchMoreResult.universalSearch,
                  attachmentSearchResult: {
                    ...fetchMoreResult.universalSearch.attachmentSearchResult,
                    totalCount:
                      fetchMoreResult.universalSearch.attachmentSearchResult
                        .totalCount,
                    hits: [
                      ...(prev.universalSearch.attachmentSearchResult?.hits ||
                        []),
                      ...(fetchMoreResult.universalSearch.attachmentSearchResult
                        ?.hits || []),
                    ],
                  },
                },
              };
            },
          });
        }}
        useWindow={false}
        hasMore={hasMoreAttachments}
        loader={
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress
              size={24}
              sx={{ color: theme.colors?.primary.maroon }}
            />
          </Box>
        }
      >
        {attachments.map((attachment, index) => (
          <JuiceboxAttachmentSearchItem
            attachment={attachment}
            query={query}
            ref={(el: HTMLElement) => {
              elRefs.current[index] = el;
            }}
          />
        ))}
      </InfiniteScroll>
    </>
  );
};
