import { gql } from '@apollo/client';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentCollectionMultiPostPreviewFragmentDoc,
  CommentFragmentCommentContainerViewFragmentDoc,
} from 'graphql/generated';

export const COLLECTION_FRAGMENT_COLLECTION_COMMENT_VIEW = gql`
  fragment CollectionFragmentCollectionCommentView on CollectionModel {
    id
    name
    myPermissions
    parentCollectionId
    ...CollectionFragmentCollectionMultiPostPreview
    ...CollectionFragmentCollectionBreadcrumbs
  }
  ${CollectionFragmentCollectionMultiPostPreviewFragmentDoc}
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

export const COMMENT_FRAGMENT_COLLECTION_COMMENT_VIEW = gql`
  fragment CommentFragmentCollectionCommentView on CommentModel {
    id
    ...CommentFragmentCommentContainerView
  }
  ${CommentFragmentCommentContainerViewFragmentDoc}
`;
