import { Box, Typography, styled } from '@mui/material';
import { UserProfileFragmentAssigneeListFragment } from 'graphql/generated';
import { Avatar } from 'components/common/AvatarGroup';
import { getFullName } from 'utils/users';

const StyledContainer = styled(Box)<{ selected: boolean }>(
  ({ selected, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
    borderRadius: '12px',
    cursor: 'pointer',
    color: 'white',
    ':hover': {
      backgroundColor: theme.colors?.utility[275],
    },
    gap: 8,
    backgroundColor: selected ? theme.colors?.utility[275] : 'transparent',
  }),
);

const StyledAssigneeNameText = styled(Typography)(({ theme }) => ({
  color: theme.colors?.utility[900],
  fontWeight: 600,
}));

const StyledAssigneeEmailText = styled(Typography)(({ theme }) => ({
  color: theme.colors?.utility[700],
  fontWeight: 500,
  flex: 1,
}));

type Props = {
  selected?: boolean;
  user: UserProfileFragmentAssigneeListFragment;
};

export const TaskAssigneeSelectItem = (props: Props) => {
  const { user, selected = false, ...rest } = props;

  const fullName = getFullName(user);

  return (
    <StyledContainer {...rest} selected={selected} className="">
      <Avatar user={user} size={20} />
      {fullName && (
        <StyledAssigneeNameText variant="body-md">
          {fullName}
        </StyledAssigneeNameText>
      )}
      <StyledAssigneeEmailText variant="body-sm">
        {user.email}
      </StyledAssigneeEmailText>
    </StyledContainer>
  );
};
