import { gql, useApolloClient } from '@apollo/client';
import { Box, Chip, Typography, createFilterOptions } from '@mui/material';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { useContentIdeaFieldOptionHandlers } from 'features/contentIdea/hooks';
import { getCustomStatusStyles } from 'features/contentIdea/utils';
import {
  ContentIdeaFieldOptionFragmentMultiSelectFieldFragment,
  useGetContentIdeaFieldForMultiSelectFieldQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { ContentIdeaBadgeMenuItem } from '../badgeMenuItem';

const filter =
  createFilterOptions<ContentIdeaFieldOptionFragmentMultiSelectFieldFragment>();

const CONTENT_IDEA_FIELD_OPTION_FRAGMENT_MULTI_SELECT_FIELD = gql`
  fragment ContentIdeaFieldOptionFragmentMultiSelectField on ContentIdeaFieldOptionModel {
    id
    value
    label
    bgcolor
  }
`;

const CONTENT_IDEA_FIELD_MODEL_FRAGMENT_MULTISELECT_FIELD = gql`
  fragment ContentIdeaFieldModelFragmentMultiSelectField on ContentIdeaFieldModel {
    id
    name
    allowNewOptions
    options {
      ...ContentIdeaFieldOptionFragmentMultiSelectField
    }
  }
  ${CONTENT_IDEA_FIELD_OPTION_FRAGMENT_MULTI_SELECT_FIELD}
`;

// eslint-disable-next-line
gql`
  query GetContentIdeaFieldForMultiSelectField($id: String!) {
    contentIdeaField(id: $id) {
      ...ContentIdeaFieldModelFragmentMultiSelectField
    }
  }
  ${CONTENT_IDEA_FIELD_MODEL_FRAGMENT_MULTISELECT_FIELD}
`;

export const optionBgColors = [
  '#E0E4CA',
  '#E6E6FA',
  '#BED7DC',
  '#F0EBE3',
  '#F3D0D7',
  '#E3DFFD',
  '#EED3D9',
  '#CCD3CA',
  '#DBC4F0',
  '#f6e5d8',
  '#adbb96',
];

export type MultiSelectFieldProps = {
  id: string;
  value: string[];
  onChange: (
    value: string[],
    optimisticResponseOptions?: {
      multiSelect?: ContentIdeaFieldOptionFragmentMultiSelectFieldFragment[];
    },
  ) => void;
  renderTrigger?: (props: {
    isOpen: boolean;
    options: ContentIdeaFieldOptionFragmentMultiSelectFieldFragment[];
    onDelete: (value: string) => void;
    onClear: () => void;
  }) => JSX.Element;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    context: {
      option: { label: string; value: string };
      options: ContentIdeaFieldOptionFragmentMultiSelectFieldFragment[];
      selected: boolean;
    },
  ) => JSX.Element;
  readOnly?: boolean;
};

export const MultiSelectField = (props: MultiSelectFieldProps) => {
  const { value, id, onChange, renderTrigger, renderOption, readOnly } = props;
  const client = useApolloClient();

  const { onCreateOption, onDeleteOption, onUpdateOption } =
    useContentIdeaFieldOptionHandlers({
      onAfterOptionCreated: (option) => {
        const newOptionRef = client.cache.writeFragment({
          data: option,
          fragment: CONTENT_IDEA_FIELD_MODEL_FRAGMENT_MULTISELECT_FIELD,
          fragmentName: 'ContentIdeaFieldModelFragmentMultiSelectField',
        });
        modifyObject(client.cache, id, 'ContentIdeaFieldModel', {
          options: (cachedOptions = []) => [...cachedOptions, newOptionRef],
        });
      },
    });

  const [newOption, setNewOption] =
    useState<ContentIdeaFieldOptionFragmentMultiSelectFieldFragment | null>(
      null,
    );
  const [inputValue, setInputValue] = useState('');

  const { data } = useGetContentIdeaFieldForMultiSelectFieldQuery({
    variables: {
      id,
    },
    skip: !id,
  });
  const options = data?.contentIdeaField?.options || [];

  const onCreateNewOption = async () => {
    if (!newOption) {
      return;
    }
    const contentIdeaFieldId = data?.contentIdeaField.id || '';
    await onCreateOption({
      contentIdeaFieldId,
      data: {
        value: newOption.value,
        label: newOption.label,
        bgcolor: newOption.bgcolor,
      },
    });
  };

  const onDelete = (valueToDelete: string) => {
    const newValue = value.filter((v) => v !== valueToDelete);
    const newOptions = options.filter((o) =>
      newValue.some((v) => v === o.value),
    );
    onChange(
      newOptions.map((o) => o.value),
      { multiSelect: newOptions },
    );
  };

  const onClear = () => {
    onChange([]);
  };

  const allowNewOptions = useMemo(
    () => data?.contentIdeaField.allowNewOptions ?? false,
    [data?.contentIdeaField?.allowNewOptions],
  );

  return (
    <AutocompletePopup
      readOnly={readOnly}
      defaultValue={options.filter((o) => value.some((v) => v === o.value))}
      multiple
      options={options}
      placeholder="Search"
      onChange={async (e, value) => {
        e.stopPropagation();
        if (newOption) {
          await onCreateNewOption();
        }
        if (Array.isArray(value)) {
          const newOptions = options.filter((o) =>
            value.some((v) => v.value === o.value),
          );
          onChange(
            value.map((v) => v.value ?? v),
            { multiSelect: newOptions },
          );
        }
        setNewOption(null);
        setInputValue('');
      }}
      renderTrigger={
        renderTrigger
          ? (open) =>
              renderTrigger({
                isOpen: open || false,
                options,
                onDelete,
                onClear,
              })
          : () => {
              if (value.length === 0) {
                return (
                  <Typography
                    variant="subhead-lg"
                    color={theme.colors?.utility[600]}
                  >
                    Empty
                  </Typography>
                );
              }

              return (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  {value.map((v) => {
                    const option = options.find((o) => o.value === v);

                    if (!option) {
                      return null;
                    }

                    // FIXME: THIS IS A HACK FOR STATUS COLORS
                    const customStyles = getCustomStatusStyles(
                      option.value,
                      option.bgcolor || '',
                    );

                    return (
                      <Chip
                        sx={{
                          borderRadius: 1,
                          ...(customStyles.color
                            ? {
                                '.MuiChip-label': {
                                  filter: 'none !important',
                                  color: customStyles.color,
                                },
                              }
                            : {}),
                          ...customStyles,
                        }}
                        label={
                          <Typography variant="headline-xs">
                            {option.label}
                          </Typography>
                        }
                        onDelete={readOnly ? undefined : () => onDelete(v)}
                        variant="filled-borderless-color-dodge"
                      />
                    );
                  })}
                </Box>
              );
            }
      }
      triggerWrapperSx={{
        width: '100%',
      }}
      renderOption={(props, option, state) => {
        const selected = state.selected;

        const fieldOption = options.find((o) => o.value === option.value);

        if (!fieldOption) {
          return (
            <Box
              key={option.value}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '& .MuiButtonBase-root': {
                  padding: 0,
                },
              }}
            >
              <Typography variant="headline-xs">Create</Typography>
              <ContentIdeaBadgeMenuItem
                key={option.value}
                {...props}
                {...option}
                selected={selected}
              />
            </Box>
          );
        }

        if (renderOption) {
          return renderOption(props, {
            option,
            options,
            selected,
          });
        }

        return (
          <ContentIdeaBadgeMenuItem
            key={option.value}
            {...props}
            {...option}
            showMoreOption
            selected={selected}
            onDeleteOption={() => {
              onDeleteOption(fieldOption.id);
            }}
            onUpdateOption={(newLabel) => {
              onUpdateOption(
                {
                  contentIdeaFieldOptionId: fieldOption.id,
                  data: {
                    value: newLabel,
                  },
                },
                options,
              );
            }}
          />
        );
      }}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setInputValue(value);
        } else {
          setInputValue('');
        }
      }}
      {...(allowNewOptions
        ? {
            filterOptions: (_options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              const isExisting = _options.some(
                (option) =>
                  inputValue.trim().toLowerCase() ===
                  option.label.toLowerCase(),
              );

              if (inputValue.trim() !== '' && !isExisting) {
                const option = {
                  id: inputValue,
                  value: inputValue,
                  label: inputValue,
                  bgcolor:
                    newOption?.bgcolor ??
                    optionBgColors[
                      Math.floor(Math.random() * optionBgColors.length)
                    ],
                };
                if (option.value !== newOption?.value) {
                  setNewOption(option);
                }
                filtered.push(option);
              } else {
                setNewOption(null);
              }

              return filtered;
            },
          }
        : {})}
      // eslint-disable-next-line
      PaperComponent={({ children, ...rest }) => {
        return (
          <Box
            {...rest}
            sx={{
              '.MuiAutocomplete-listbox': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
            }}
          >
            {children}
            {allowNewOptions && (
              <Box
                sx={{
                  px: 3,
                  pt: 3,
                }}
              >
                <Typography
                  variant="subhead-lg"
                  color={theme.colors?.utility[600]}
                >
                  Select an Option or Create One
                </Typography>
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
};
