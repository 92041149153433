import { gql } from '@apollo/client';
import {
  ATTACHMENT_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW,
  COLLECTION_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW,
  LINK_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW,
  NOTE_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW,
  TASK_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW,
} from 'features/search/views/listItem/types';

export const NOTE_SEARCH_RESULT_FRAGMENT_SECTION_VIEW = gql`
  fragment NoteSearchResultFragmentSectionView on PostSearchResult {
    totalCount
    hits {
      ...NoteSearchHitFragmentListItemView
    }
  }
  ${NOTE_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW}
`;

export const LINK_SEARCH_RESULT_FRAGMENT_SECTION_VIEW = gql`
  fragment LinkSearchResultFragmentSectionView on PostSearchResult {
    totalCount
    hits {
      ...LinkSearchHitFragmentListItemView
    }
  }
  ${LINK_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW}
`;

export const ATTACHMENT_SEARCH_RESULT_FRAGMENT_SECTION_VIEW = gql`
  fragment AttachmentSearchResultFragmentSectionView on PostSearchResult {
    totalCount
    hits {
      ...AttachmentSearchHitFragmentListItemView
    }
  }
  ${ATTACHMENT_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW}
`;

export const TASK_SEARCH_RESULT_FRAGMENT_SECTION_VIEW = gql`
  fragment TaskSearchResultFragmentSectionView on TaskSearchResult {
    totalCount
    hits {
      ...TaskSearchHitFragmentListItemView
    }
  }
  ${TASK_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW}
`;

export const COLLECTION_SEARCH_RESULT_FRAGMENT_SECTION_VIEW = gql`
  fragment CollectionSearchResultFragmentSectionView on CollectionSearchResult {
    totalCount
    hits {
      ...CollectionSearchHitFragmentListItemView
    }
  }
  ${COLLECTION_SEARCH_HIT_FRAGMENT_LIST_ITEM_VIEW}
`;
