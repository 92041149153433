import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { CollaborationRoom, useOthersMapped } from 'features/collaboration';
import { CollectionCollaborativeViewers } from 'features/collection/components';
import { CollectionFragmentCollectionCollaborationsViewFragment } from 'graphql/generated';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionCollaborationsView on CollectionModel {
    id
    roomId
    parentCollectionId
  }
`;

type CollectionCollaborationsViewProps = {
  collection: CollectionFragmentCollectionCollaborationsViewFragment;
};

export const CollectionCollaborationsView = (
  props: CollectionCollaborationsViewProps,
) => {
  const { collection } = props;

  const roomId = useMemo(() => {
    if (collection.roomId) {
      // liveblocks room is already created and connected
      return collection.roomId;
    }

    // liveblocks room is not yet created
    return `collection-${collection.id}`;
  }, [collection]);

  return (
    <CollaborationRoom roomId={roomId} initialPresence={{ cursor: null }}>
      <CollectionCollaborationsViewDetails collection={collection} />
    </CollaborationRoom>
  );
};

const CollectionCollaborationsViewDetails = (
  props: CollectionCollaborationsViewProps,
) => {
  const { collection } = props;

  const location = useLocation();

  const onlineUsers = useOthersMapped((other) => other.info);
  const { addRenderMenuExtraLeft, removeRenderMenuExtraLeft } =
    useCustomHeaderContext();

  useEffect(() => {
    const render = () => (
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        mr={2}
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <Box marginRight={theme.spacing(2)}>
          <CollectionCollaborativeViewers
            collectionId={collection.id}
            onlineUsers={onlineUsers.map(([_, data]) => data)}
          />
        </Box>
      </Box>
    );
    addRenderMenuExtraLeft(render, 0);

    return () => {
      removeRenderMenuExtraLeft(render, 0);
    };
  }, [location.pathname, onlineUsers, collection]); // eslint-disable-line

  return null;
};
