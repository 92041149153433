import { gql, useApolloClient } from '@apollo/client';
import {
  DeleteTaskInput,
  NewTaskInput,
  TaskFragmentTaskDetailViewFragment,
  TaskStatus,
  UpdateTaskInput,
  useCreateSubtaskForTaskDetailViewMutation,
  useDeleteSubtaskForTaskDetailViewMutation,
  useUpdateSubtaskForTaskDetailViewMutation,
} from 'graphql/generated';
import { evictObject, modifyObject } from 'utils/apollo';
import { TASK_FRAGMENT_TASK_SUBTASK_SECTION } from '../sections';

// eslint-disable-next-line
gql`
  mutation CreateSubtaskForTaskDetailView($data: CreateTaskInput!) {
    createTask(data: $data) {
      parentTaskId
      sortOrder
      ...TaskFragmentTaskSubtaskSection
    }
  }
  ${TASK_FRAGMENT_TASK_SUBTASK_SECTION}
`;

// eslint-disable-next-line
gql`
  mutation UpdateSubtaskForTaskDetailView($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      id
      name
      status
      startDate
      endDate
      priority
      taskMembers {
        id
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeleteSubtaskForTaskDetailView($data: DeleteTaskInput!) {
    deleteTask(data: $data) {
      success
      message
    }
  }
`;

interface Props {
  task?: TaskFragmentTaskDetailViewFragment;
}

export const useSubtaskHandlers = (props: Props) => {
  const { task } = props;

  const client = useApolloClient();

  const [createSubtask] = useCreateSubtaskForTaskDetailViewMutation();
  const [updateSubtask] = useUpdateSubtaskForTaskDetailViewMutation();
  const [deleteSubtask] = useDeleteSubtaskForTaskDetailViewMutation();

  const onSubtaskCreate = async (newTask: NewTaskInput) => {
    await createSubtask({
      variables: {
        data: {
          newTask: {
            ...newTask,
            parentTaskId: task?.id,
            isPreview: task?.isPreview,
          },
        },
      },
      update: (cache, data) => {
        modifyObject(cache, task?.id || '', 'TaskModel', {
          subtasks: (cachedSubtasks = []) => {
            const newSubtaskRef = cache.writeFragment({
              data: data.data?.createTask,
              fragment: TASK_FRAGMENT_TASK_SUBTASK_SECTION,
              fragmentName: 'TaskFragmentTaskSubtaskSection',
            });

            return [newSubtaskRef, ...cachedSubtasks];
          },
        });
      },
    });
  };

  const onSubtaskUpdate = (data: UpdateTaskInput) => {
    return updateSubtask({
      variables: {
        data,
      },
      optimisticResponse: {
        updateTask: {
          id: data.taskId,
          __typename: 'TaskModel',
          name: data.data.name || '',
          status: data.data?.status || TaskStatus.ToDo,
          startDate: data.data?.startDate,
          priority: data.data?.priority,
          endDate: data.data?.endDate,
          taskMembers: (data.data?.memberIds || []).map((id) => ({
            id,
            __typename: 'UserProfileModel',
          })),
        },
      },
    });
  };

  const onSubtaskDelete = (data: DeleteTaskInput) => {
    deleteSubtask({
      variables: {
        data,
      },
    });

    evictObject(client.cache, data.taskId, 'TaskModel');
  };

  return {
    onSubtaskCreate,
    onSubtaskDelete,
    onSubtaskUpdate,
  };
};
