import { Box, SxProps, Typography } from '@mui/material';
import { theme } from 'styles/theme';

type Props = {
  topics: string[];
  maxTopicsToShow?: number;
  componentProps?: {
    chip?: {
      sx?: SxProps;
    };
  };
};

export const SocialMediaListeningTopicChips = (props: Props) => {
  const { topics, maxTopicsToShow, componentProps } = props;

  const topicsToRender = maxTopicsToShow
    ? topics.slice(0, maxTopicsToShow)
    : topics;

  return (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {topicsToRender.map((topic, index) => (
        <Typography
          key={index}
          variant="body-lg"
          fontWeight={600}
          sx={{
            p: theme.spacing(1, 2),
            backgroundColor: theme.colors?.utility[300],
            color: theme.colors?.primary.black,
            borderRadius: 1,
            ...(componentProps?.chip?.sx || {}),
          }}
        >
          {topic}
        </Typography>
      ))}
      {maxTopicsToShow && topics.length > maxTopicsToShow && (
        <Typography
          variant="body-lg"
          fontWeight={600}
          sx={{
            p: theme.spacing(1, 2),
            backgroundColor: theme.colors?.utility[300],
            color: theme.colors?.primary.black,
            borderRadius: 1,
            ...(componentProps?.chip?.sx || {}),
          }}
        >
          +{topics.length - maxTopicsToShow}
        </Typography>
      )}
    </Box>
  );
};
