import { gql } from '@apollo/client';
import { Box, Button, TextField, Typography } from '@mui/material';
import {
  ContentCalendarViewFragmentContentCalendarViewListItemViewFragmentDoc,
  useCreateContentCalendarViewForCreateViewFormMutation,
} from 'graphql/generated';
import { FormEvent, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation CreateContentCalendarViewForCreateViewForm(
    $data: CreateContentCalendarViewInput!
  ) {
    createContentCalendarView(data: $data) {
      id
      ...ContentCalendarViewFragmentContentCalendarViewListItemView
    }
  }
  ${ContentCalendarViewFragmentContentCalendarViewListItemViewFragmentDoc}
`;

export type CreateViewFormProps = {
  selectedFilters: Record<string, object>;
  onAfterSubmit?: () => void;
  onCancel?: () => void;
};

export const CreateViewForm = (props: CreateViewFormProps) => {
  const { selectedFilters, onAfterSubmit, onCancel } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [createContentCalendarView] =
    useCreateContentCalendarViewForCreateViewFormMutation();
  const canSubmit = name.trim().length > 0 && !isLoading;

  const onSubmit = async (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!canSubmit) {
      return;
    }

    setIsLoading(true);

    await createContentCalendarView({
      variables: {
        data: {
          data: {
            name,
            filters: JSON.stringify(selectedFilters),
          },
        },
      },
      update: (_, result) => {
        const contentCalendarViewId =
          result.data?.createContentCalendarView?.id;
        if (contentCalendarViewId) {
          params.set('view', contentCalendarViewId);
          navigate(
            {
              pathname: location.pathname,
              search: params.toString(),
            },
            {
              state: location.state,
              replace: true,
            },
          );
        }
      },
    });

    onAfterSubmit?.();
    setIsLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        px: 3,
        py: 2,
      }}
    >
      <Typography variant="headline-xs" color={theme.colors?.utility[700]}>
        Create View
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Untitled View"
        onChange={(e) => setName(e.target.value)}
        autoFocus
        sx={{
          borderRadius: 2,
          bgcolor: theme.colors?.utility[300],
          fieldset: {
            borderColor: theme.colors?.utility[400],
          },
          input: {
            py: 2,
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button size="small" variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="primary-alt"
          type="submit"
          disabled={!canSubmit}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
