import { gql } from '@apollo/client';
import { IconButton, Typography } from '@mui/material';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearMicrophone } from 'components/icons/components/linear/IconLinearMicrophone';
import { PostFragmentPostSocialPostButtonFragment } from 'graphql/generated';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment PostFragmentPostSocialPostButton on PostModel {
    id
    socialPostId
    socialPostEngagementSuggestion {
      id
      topic {
        id
        brandId
      }
    }
  }
`;

type PostSocialPostButtonProps = {
  post: PostFragmentPostSocialPostButtonFragment;
};

export const PostSocialPostButton = (props: PostSocialPostButtonProps) => {
  const { post } = props;

  const onClick = () => {
    if (
      !post.socialPostId ||
      !post.socialPostEngagementSuggestion?.topic?.brandId
    ) {
      return;
    }

    window.open(
      PlotRoutes.socialListeningPost(
        post.socialPostId,
        post.socialPostEngagementSuggestion?.topic?.brandId,
      ),
      '_blank',
    );
  };

  return !post.socialPostId ||
    !post.socialPostEngagementSuggestion?.topic?.brandId ? null : (
    <IconButton
      aria-label="See on Social Listening"
      sx={{
        padding: theme.spacing(1.5, 3),
        bgcolor: theme.colors?.utility['blue-1'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
        width: 'fit-content',
        gap: 1,
      }}
      disableRipple
      onClick={onClick}
    >
      <IconLinearMicrophone size={16} color={theme.colors?.primary.black} />
      <Typography variant="headline-xs" color={theme.colors?.primary.black}>
        See on Social Listening
      </Typography>
      <IconLinearExport2 size={15} color={theme.colors?.primary.black} />
    </IconButton>
  );
};
