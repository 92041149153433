import { gql } from '@apollo/client';
import { Box, Button, IconButton, SxProps } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { typography } from 'components/common/Typography/styles';
import { IconBoldEdit } from 'components/icons/components/bold/IconBoldEdit';
import { IconCustomInstagram } from 'components/icons/components/custom/IconCustomInstagram';
import { CreatorAvatar } from 'features/creator';
import {
  BrandFragmentBrandConnectedCreatorsFragment,
  CreatorFragmentCreatorAvatarFragmentDoc,
} from 'graphql/generated';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

export const BRAND_FRAGMENT_BRAND_CONNECTED_CREATORS = gql`
  fragment BrandFragmentBrandConnectedCreators on BrandModel {
    id
    creators {
      id
      ...CreatorFragmentCreatorAvatar
    }
  }
  ${CreatorFragmentCreatorAvatarFragmentDoc}
`;

export type BrandConnectedCreatorsProps = {
  brand: BrandFragmentBrandConnectedCreatorsFragment;
  sx?: SxProps;
};

export const BrandConnectedCreators = (props: BrandConnectedCreatorsProps) => {
  const { brand, sx } = props;

  const navigate = useNavigate();

  const hasLinkedSocials = brand.creators.length > 0;

  if (!hasLinkedSocials) {
    return (
      <Button
        type="button"
        startIcon={<IconCustomInstagram size={16} />}
        variant="tertiary"
        sx={{
          ...typography['subhead-sm'],
        }}
        onClick={() => {
          navigate(
            PlotRoutes.socialListeningSettings({
              brandId: brand.id,
            }),
          );
        }}
      >
        Connect socials
      </Button>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: theme.colors?.utility[275],
        borderRadius: 1,
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        px: 3,
        py: 1.75,
        ...sx,
      }}
    >
      {brand.creators.map((creator) => {
        return <CreatorAvatar key={creator.id} creator={creator} size={28} />;
      })}
      <IconButton
        size="small"
        onClick={() => {
          navigate(
            PlotRoutes.socialListeningSettings({
              brandId: brand.id,
            }),
          );
        }}
        sx={{
          p: 0,
        }}
      >
        <IconBoldEdit size={16} />
      </IconButton>
    </Box>
  );
};
