import { gql } from '@apollo/client';
import { Badge, Box, SxProps, Typography } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { PostReactionList } from 'features/post';
import {
  PostFragmentPostDetailSidebarViewCommentsTabContentFragmentDoc,
  PostFragmentPostDetailSidebarViewFragment,
  PostFragmentPostDetailSidebarViewInfoTabContentFragmentDoc,
  PostFragmentTaskHeadingSectionFragmentDoc,
} from 'graphql/generated';
import { forwardRef, useEffect, useState } from 'react';
import { theme } from 'styles/theme/theme';
import {
  CommentsTabContent,
  InfoTabContent,
  TasksTabContent,
} from './tabContent';

export const POST_FRAGMENT_POST_DETAIL_SIDEBAR_VIEW = gql`
  fragment PostFragmentPostDetailSidebarView on PostModel {
    id
    ...PostFragmentPostDetailSidebarViewCommentsTabContent
    ...PostFragmentTasksTabContent
    ...PostFragmentPostDetailSidebarViewInfoTabContent
  }
  ${PostFragmentPostDetailSidebarViewCommentsTabContentFragmentDoc}
  ${PostFragmentTaskHeadingSectionFragmentDoc}
  ${PostFragmentPostDetailSidebarViewInfoTabContentFragmentDoc}
`;

export enum SidebarTab {
  COMMENTS = 'comments',
  TASKS = 'tasks',
  INFO = 'info',
}

export type PostDetailSidebarViewProps = {
  post?: PostFragmentPostDetailSidebarViewFragment;
  unreadCommentCount?: number;
  tab?: SidebarTab;
  sx?: SxProps;
};

// The Slide - parent of Sidebar is transition components, require the first child element to forward its ref to the DOM node
// Check more details here: https://mui.com/material-ui/transitions/#child-requirement
export const PostDetailSidebarView = forwardRef<
  HTMLDivElement,
  PostDetailSidebarViewProps
>((props, ref) => {
  const { post, tab: _tab, unreadCommentCount = 0, sx } = props;

  const [tab, setTab] = useState<SidebarTab>(_tab || SidebarTab.INFO);

  useEffect(() => {
    if (_tab) {
      setTab(_tab);
    }
  }, [_tab]);

  const tabOptions = [
    {
      key: SidebarTab.INFO,
      label: <Typography variant="headline-sm">Info</Typography>,
      content: <InfoTabContent post={post} />,
    },
    {
      key: SidebarTab.COMMENTS,
      label: (
        <Badge
          color="primary"
          variant="dot"
          invisible={unreadCommentCount === 0}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: theme.colors?.primary.maroon,
              color: theme.colors?.primary.white,
              height: 5,
              minWidth: 5,
              mt: 1,
              mr: -1,
            },
          }}
        >
          <Typography variant="headline-sm">Comments</Typography>
        </Badge>
      ),
      content: <CommentsTabContent post={post} />,
    },
    {
      key: SidebarTab.TASKS,
      label: <Typography variant="headline-sm">Tasks</Typography>,
      content: <TasksTabContent post={post} />,
    },
  ];

  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
        bgcolor: 'white',
        color: theme.colors?.primary.black,
        borderRadius: 6,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          '.sidebar-tabs': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            '.MuiTabs-root': {
              px: 6,
              pt: 6,
              borderBottom: 'none',
              '.MuiTab-root': {
                minHeight: 24,
                pb: 4,
              },
            },
            '.MuiTabPanel-root': {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              padding: 0,
              '&[hidden]': {
                display: 'none',
              },
            },
            '.MuiTabs-indicator': {
              display: 'none',
            },
          },
        }}
      >
        <Tabs
          tab={tab}
          onChange={(val) => setTab(val as SidebarTab)}
          tabs={tabOptions}
          className="sidebar-tabs"
        />
      </Box>
      {post && tab !== SidebarTab.TASKS && (
        <Box
          sx={{
            px: 6,
            py: 4,
            borderTop: `2px solid ${theme.colors?.utility[250]}`,
          }}
        >
          <PostReactionList post={post} />
        </Box>
      )}
    </Box>
  );
});
