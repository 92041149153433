import { UserProfileModel } from 'graphql/generated';

export const getShortName = (
  user: Pick<UserProfileModel, 'firstName' | 'lastName' | 'email'>,
) => {
  if (user.firstName || user.lastName) {
    return `${user?.firstName} ${user.lastName.at(0)}.`.trim();
  }

  return user.email;
};

export const getFullName = (
  user: Pick<UserProfileModel, 'firstName' | 'lastName' | 'email'>,
) => {
  if (user.firstName || user.lastName) {
    return `${user.firstName} ${user.lastName}`.trim();
  }

  return user.email;
};

/**
 * This method receives a list of users & combine the users' name into a string. For example:
 *
 * ```
 * const users = [
 *   {
 *     id: 1,
 *     firstName: 'User',
 *     lastName: 'A',
 *   },
 *   {
 *     id: 2,
 *     firstName: 'User',
 *     lastName: 'B',
 *   },
 * ]
 *
 * combineUserNames(users, 1) -> "You and User B".
 * ```
 *
 * If `firstName` and `lastName` of a user is not available, we'll fallback to use email instead.
 *
 * @param users
 * @param currentUserId
 * @returns string
 */
export const combineUserNames = (
  users: Pick<UserProfileModel, 'id' | 'firstName' | 'lastName' | 'email'>[],
  currentUserId?: string,
) => {
  // Dedupe by id
  const combinedNames = users
    .filter(
      (user, index, self) => self.findIndex((u) => u.id === user.id) === index,
    )
    .map((user) =>
      user.id === currentUserId ? 'You' : getFullName(user) || user.email,
    );
  const lastName = combinedNames.slice(-1)[0];

  return `${combinedNames.slice(0, combinedNames.length - 1).join(', ')}${
    combinedNames.length > 1 ? ' and ' : ''
  }${lastName}`;
};
