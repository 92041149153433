import { Box, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

export const LeaderboardDetailViewSkeleton = () => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 4,
          flexWrap: 'wrap',
          mb: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Skeleton
            variant="circular"
            width={48}
            height={48}
            sx={{
              bgcolor: theme.colors?.primary.parchment,
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Skeleton
              variant="rectangular"
              width={256}
              height={24}
              sx={{
                bgcolor: theme.colors?.primary.parchment,
              }}
            />
            <Skeleton
              variant="rectangular"
              width={128}
              height={16}
              sx={{
                bgcolor: theme.colors?.primary.parchment,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 6,
          py: 6,
          px: 8,
          borderRadius: 4,
          bgcolor: theme.colors?.primary.parchment,

          '> *': {
            width: '33%',

            '&:not(:last-child)': {
              borderRight: '2px solid #23060312',
            },
          },

          [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',

            '> *': {
              width: '100%',
              border: 'none !important',
            },
          },
        }}
      >
        {new Array(3).fill(null).map((_, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Skeleton variant="rectangular" width={64} height={16} />
              <Skeleton variant="rectangular" width={144} height={32} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
