import { gql } from '@apollo/client';
import { useAutocomplete } from '@mui/material';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionFragmentCollectionMenuItemViewFragmentDoc,
  GetPostCollectionsForUseAutocompleteInfiniteCollectionsQuery,
  useGetPostCollectionsForUseAutocompleteInfiniteCollectionsQuery,
} from 'graphql/generated';
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

// This ListBoxComponent is to prevent the scroll to top issue with autocomplete options when new options are dynamically added
// Reference: https://github.com/mui/material-ui/issues/30249
interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {}
type NullableUlElement = HTMLUListElement | null;
const ListBoxComponent = forwardRef(
  (props: ListBoxProps, ref: ForwardedRef<HTMLUListElement>) => {
    const { children, ...rest } = props;

    const innerRef = useRef<HTMLUListElement>(null);

    useImperativeHandle<NullableUlElement, NullableUlElement>(
      ref,
      () => innerRef.current,
    );

    return (
      // eslint-disable-next-line jsx-a11y/aria-role
      <ul {...rest} ref={innerRef} role="list-box">
        {children}
      </ul>
    );
  },
);

type Props = {
  postIds: string[];
  searchStr: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const COLLECTION_FRAGMENT_USE_AUTOCOMPLETE_INFINITE_COLLECTIONS = gql`
  fragment CollectionFragmentUseAutocompleteInfiniteCollections on CollectionModel {
    id
    myPermissions
    ...CollectionFragmentCollectionMenuItemView
    ...CollectionFragmentCollectionBreadcrumbs
  }
  ${CollectionFragmentCollectionMenuItemViewFragmentDoc}
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetPostCollectionsForUseAutocompleteInfiniteCollections(
    $input: GetPostCollectionInput!
  ) {
    postCollections(input: $input) {
      meta {
        totalCount
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
        hasPreviousPage
      }
      data {
        type
        item {
          ...CollectionFragmentUseAutocompleteInfiniteCollections
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT_USE_AUTOCOMPLETE_INFINITE_COLLECTIONS}
`;

export const useAutocompleteInfiniteCollections = ({
  postIds,
  searchStr,
}: Props) => {
  const [hasMore, setHasMore] = useState(true);
  const [makeApiCalls, setMakeApiCalls] = useState(false);
  const [lastCursor, setLastCursor] = useState('');

  const {
    data: collectionsData,
    fetchMore,
    loading: fetchingCollections,
    refetch: refetchCollections,
  } = useGetPostCollectionsForUseAutocompleteInfiniteCollectionsQuery({
    variables: {
      input: {
        postIds,
        query: searchStr,
      },
    },
    skip: !makeApiCalls,
    fetchPolicy: 'cache-and-network',
  });

  const loadCollectionsData = () => {
    setMakeApiCalls(true);
  };

  const fetchMoreCollections = async () => {
    if (
      fetchingCollections ||
      !hasMore ||
      lastCursor === collectionsData?.postCollections.pageInfo.endCursor
    ) {
      return;
    }

    setLastCursor(collectionsData?.postCollections.pageInfo.endCursor || '');
    await fetchMore({
      variables: {
        input: {
          postIds,
          query: searchStr,
          after: collectionsData?.postCollections.pageInfo.endCursor,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        setHasMore(fetchMoreResult.postCollections.pageInfo.hasNextPage);

        return {
          ...prev,
          postCollections: {
            meta: fetchMoreResult.postCollections.meta,
            pageInfo: {
              ...fetchMoreResult.postCollections.pageInfo,
            },
            data: [
              ...prev.postCollections.data,
              ...fetchMoreResult.postCollections.data.filter(
                (x) =>
                  !prev.postCollections.data.some(
                    (y) => y.item.id === x.item.id,
                  ),
              ),
            ],
          },
        };
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const autoCompleteProps: {
    ListboxProps: ReturnType<
      ReturnType<typeof useAutocomplete>['getListboxProps']
    >;
    ListBoxComponent: React.ForwardRefExoticComponent<
      ListBoxProps & React.RefAttributes<HTMLUListElement>
    >;
  } = {
    ListboxProps: {
      className: 'options-container',
      onScrollCapture: (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget;
        const currentScrollPos =
          listboxNode.scrollTop + listboxNode.clientHeight;
        if (
          Math.abs(currentScrollPos - listboxNode.scrollHeight) < 200 &&
          !fetchingCollections
        ) {
          fetchMoreCollections();
        }
      },
    },
    ListBoxComponent,
  };

  // Save down every collection that is fetched from the server
  // because we need them to show selected options in the autocomplete
  const [allCollectionSearchHits, setAllCollectionSearchHits] = useState<
    GetPostCollectionsForUseAutocompleteInfiniteCollectionsQuery['postCollections']['data']
  >([]);

  useEffect(() => {
    if (collectionsData?.postCollections.data) {
      setAllCollectionSearchHits((prev) =>
        // Dedupe by id, prioritize the latest collection
        [
          ...prev.filter(
            (prevCollectionSearchHit) =>
              !collectionsData.postCollections.data.some(
                (newCollectionSearchHit) =>
                  newCollectionSearchHit.item.id ===
                  prevCollectionSearchHit.item.id,
              ),
          ),
          ...collectionsData.postCollections.data,
        ],
      );
    }
  }, [collectionsData]);

  return {
    autoCompleteProps,
    collectionSearchHits: collectionsData?.postCollections.data.filter(
      // Dedupe by id
      // FIXME: This is a workaround as right now the query is returning duplicate collections
      // in smart & regular search hit type. This should be removed once the query is fixed
      (collectionSearchHit, index, self) =>
        index ===
        self.findIndex((t) => t.item.id === collectionSearchHit.item.id),
    ),
    allCollectionSearchHits,
    loadCollectionsData,
    refetchCollections,
    fetchingCollections,
  };
};
