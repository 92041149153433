/**
 * NOTE: This component name & location is not adhering to our feature-based approach,
 * but we're keeping it here for now until we can migrate organization/settings related components/layouts/...
 * into the organization feature folder.
 */

import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, Typography } from '@mui/material';
import { OrgPermissionGate } from 'components/common/PermissionGate';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLogoPlotLogo } from 'components/icons/components/logo/IconLogoPlotLogo';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import { useUserContext } from 'contexts/users/User.context';
import {
  OrganizationPermission,
  useUpdateOrganizationForOrganizationLayoutNameSectionMutation,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation UpdateOrganizationForOrganizationLayoutNameSection(
    $data: UpdateOrganizationInput!
  ) {
    updateOrganization(data: $data) {
      id
      name
    }
  }
`;

export const OrganizationLayoutNameSection = () => {
  const { user } = useUserContext();

  const [updateOrganization] =
    useUpdateOrganizationForOrganizationLayoutNameSectionMutation();

  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      name: user?.organization.name || '',
    },
  });

  const name = watch('name');
  const nameInputRef = useRef<HTMLInputElement | null>(null);
  const { ref: rhfNameInputRef, ...rhfNameInputRest } = register('name');

  // Dummy container that render the name invisibly to calculate the width
  const dummyNameContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    isOpen: isEditing,
    onOpen: startEditing,
    onClose: stopEditing,
  } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit(async (values) => {
    try {
      setIsSubmitting(true);

      await updateOrganization({
        variables: {
          data: {
            organizationId: user?.organization.id || '',
            data: {
              name: values.name,
            },
          },
        },
      });

      stopEditing();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  });

  useEffect(() => {
    // Calculate the input width based on the organization name
    // It's mostly speculation, but let's see
    const input = nameInputRef.current;
    if (input && dummyNameContainerRef.current) {
      const inputWidth = !name
        ? 320
        : Math.max(dummyNameContainerRef.current.clientWidth, 40);
      input.style.width = `${inputWidth}px`;
    }
  }, [isEditing, name]);

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
      onSubmit={onSubmit}
    >
      <Typography
        variant="headline-xxs"
        color={theme.colors?.utility[700]}
        textTransform="uppercase"
      >
        Organization
      </Typography>
      <Box display="flex" gap={3} alignItems="center">
        <Box
          sx={{
            background: theme.colors?.primary.maroon,
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 1,
            height: 'fit-content',
          }}
        >
          <IconLogoPlotLogo color={theme.colors?.primary.white} size={20} />
        </Box>
        <Box
          sx={{
            position: 'relative',
            borderBottom: `1px dashed ${theme.colors?.utility[500]}`,
            ...(isEditing && { borderColor: theme.colors?.primary.black }),
          }}
        >
          <Box
            {...rhfNameInputRest}
            ref={(r: any) => {
              rhfNameInputRef(r);
              nameInputRef.current = r;
            }}
            component="input"
            defaultValue={user?.organization.name}
            readOnly={!isEditing}
            maxLength={80}
            placeholder="Your company's name"
            sx={{
              ...theme.typography['headline-xl'],
              fontSize: 24,
              fontWeight: 600,
              border: 'none',
              lineHeight: 1.5,
              px: 0,
              outline: 'none !important',
              maxWidth: 320,
              ...(!isEditing && {
                opacity: 0,
                pointerEvents: 'none',
                position: 'absolute',
              }),
            }}
          />
          <Typography
            ref={dummyNameContainerRef}
            sx={{
              ...theme.typography['headline-xl'],
              fontSize: 24,
              fontWeight: 600,
              display: 'inline',
              lineHeight: 1.5,
              whiteSpace: 'preserve nowrap',
              ...(isEditing && {
                opacity: 0,
                pointerEvents: 'none',
                position: 'absolute',
              }),
            }}
          >
            {name}
          </Typography>
        </Box>

        <OrgPermissionGate permissions={[OrganizationPermission.Update]}>
          {isEditing ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                size="small"
                sx={{
                  color: theme.colors?.utility[500],
                  p: 0,
                }}
                onClick={() => {
                  reset({ name: user?.organization.name });
                  stopEditing();
                }}
              >
                <IconBoldCloseCircle />
              </IconButton>
              <IconButton
                size="small"
                sx={{
                  color: theme.colors?.primary.black,
                  p: 0,
                }}
                type="submit"
                disabled={isSubmitting}
              >
                <IconBoldTickCircle />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              size="small"
              sx={{
                color: theme.colors?.utility[500],
                '&:hover': {
                  color: theme.colors?.primary.black,
                },
              }}
              onClick={() => {
                startEditing();
                setTimeout(() => {
                  nameInputRef.current?.focus();
                });
              }}
            >
              <IconOutlineEdit2 />
            </IconButton>
          )}
        </OrgPermissionGate>
      </Box>
    </Box>
  );
};
