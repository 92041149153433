import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import {
  getTrendCategoryIcon,
  getTrendCategoryLabel,
  getTrendCategoryStyles,
} from 'features/trending/constants';
import { TrendCategory } from 'graphql/generated';

interface Props {
  onRemoveFilter?: VoidFunction;
  filter: TrendCategory;
  sx?: SxProps;
}

export const TrendingFilterPill = ({ filter, onRemoveFilter, sx }: Props) => {
  const { backgroundColor, cancelIconColor, color } =
    getTrendCategoryStyles(filter);
  return (
    <Box
      sx={{
        padding: `6px 12px 6px 16px`,
        borderRadius: 25,
        backgroundColor,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        minWidth: 100,
        ...sx,
      }}
    >
      {getTrendCategoryIcon(filter)}
      <Typography variant="headline-sm" color={color}>
        {getTrendCategoryLabel(filter)}
      </Typography>
      {onRemoveFilter && (
        <IconButton
          sx={{ padding: 0, cursor: 'pointer', ml: 3 }}
          onClick={onRemoveFilter}
        >
          <IconBoldCloseCircle size={20} color={cancelIconColor} />
        </IconButton>
      )}
    </Box>
  );
};
