import {
  Avatar,
  Box,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import { MoreMenuItem } from 'components/common/Menu';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconLinearUserRemove } from 'components/icons/components/linear/IconLinearUserRemove';
import { useUserContext } from 'contexts/users/User.context';
import {
  BillingOrganizationMemberLimitIndicator,
  useIsPaidMemberLimitReached,
} from 'features/billing';
import { GeneralPermission, InternalOrganizationRole } from 'graphql/generated';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

const InternalOrganizationRolesMap = {
  [InternalOrganizationRole.Admin]: {
    label: 'Admin',
    type: 'Paid seat',
    points: [
      'Manage billing',
      'Full organization access',
      'Collaborator privileges',
    ],
  },
  [InternalOrganizationRole.User]: {
    label: 'All-Access Member',
    type: 'Paid seat',
    points: [
      'Full organization access',
      'Have edit, view or comment privileges',
    ],
  },
  [InternalOrganizationRole.SocialListeningUser]: {
    label: 'Social Listening Member',
    type: 'Included',
    points: ['Access only to Social Listening', 'No access to core platform'],
  },
};

export type OrgRoleSelectorProps = {
  renderCustomButton?: (role: InternalOrganizationRole) => React.ReactNode;
  isDisabledUser?: boolean;
  minimalView?: boolean;
  canUpdate?: boolean;
  viewOnly?: boolean;
  initialValue: InternalOrganizationRole;
  selectPaperSx?: SxProps;

  extendedSLMenu?: {
    onSLBrandChange: (brandId: string, type: 'add' | 'remove') => void;
    userInfo: {
      id: string;
      orgId: string;
    };
  };

  componentProps?: {
    selectButtonProps?: {
      sx?: SxProps;
    };
  };
  /**
   * This prop is used to instruct the component to consider paywall limits
   * when rendering & selecting role options.
   */
  showPaywall?: boolean;

  onRoleChange: (role: InternalOrganizationRole) => void;
};

export const OrgRoleSelector = (props: OrgRoleSelectorProps) => {
  const {
    renderCustomButton,
    isDisabledUser,
    onRoleChange,
    initialValue,
    selectPaperSx = {},
    minimalView,
    canUpdate = true,
    viewOnly,
    extendedSLMenu,
    componentProps,
    showPaywall = false,
  } = props;

  const { onSLBrandChange, userInfo } = extendedSLMenu || {};

  const { orgBilling, user } = useUserContext();
  const socialListeningEnabled = Boolean(orgBilling?.socialListeningEnabled);
  const paidMemberLimitReached = useIsPaidMemberLimitReached({
    organizationBilling: orgBilling,
  });

  const allSLBrands = useMemo(() => user?.socialListeningBrands || [], [user]);
  const brandIdsThatUserhaveAccessTo = useMemo(
    () =>
      allSLBrands.filter((slBrand) => {
        // if user belongs to a brand member invite
        if (slBrand.inviteMembers.find((u) => u.user.id === userInfo?.id)) {
          return true;
        }

        // if user belongs to the brand and SL Brand is accessible to the overall org
        if (
          slBrand.generalPermission === GeneralPermission.OrganizationMembers &&
          user?.organization.id === userInfo?.orgId
        ) {
          return true;
        }

        return false;
      }),
    [allSLBrands, userInfo, user], // eslint-disable-line
  );

  const [currentRole, setCurrentRole] = useState<
    InternalOrganizationRole | undefined
  >(initialValue);
  const [selectedSLBrandIds, setSelectedSLBrandIds] = useState<string[]>([]);

  const rolesToRender = useMemo(() => {
    const roles = [InternalOrganizationRole.User];

    // Only show Admin role if user is an admin
    if (user?.role === InternalOrganizationRole.Admin) {
      roles.unshift(InternalOrganizationRole.Admin);
    }

    if (socialListeningEnabled) {
      // If paid member limit is reached, move SL user to the top
      if (paidMemberLimitReached) {
        return [InternalOrganizationRole.SocialListeningUser, ...roles];
      }

      return [...roles, InternalOrganizationRole.SocialListeningUser];
    }

    return roles;
  }, [socialListeningEnabled, paidMemberLimitReached, user?.role]);

  if (viewOnly) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {currentRole && (
          <Box
            sx={{
              color: minimalView
                ? theme.colors?.utility[800]
                : theme.colors?.primary.white,
              backgroundColor: minimalView
                ? 'transparent'
                : theme.colors?.utility[800],
              display: 'flex',
              p: theme.spacing(1, 3),
              borderRadius: theme.spacing(4),
              width: 'fit-content',
            }}
          >
            <Typography variant="headline-xs">
              {InternalOrganizationRolesMap[currentRole]
                ? InternalOrganizationRolesMap[currentRole].label
                : currentRole}
            </Typography>
          </Box>
        )}
      </>
    );
  }

  return isDisabledUser ? (
    <Box
      sx={{
        bgcolor: theme.colors?.utility[800],
        borderRadius: theme.spacing(25),
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(1.25, 2),
      }}
    >
      <IconLinearUserRemove size={14} color={theme.colors?.primary.white} />
      <Typography variant="headline-xs" color={theme.colors?.primary.white}>
        Disabled
      </Typography>
    </Box>
  ) : (
    <Select
      size="small"
      sx={{
        '& .MuiSelect-select': {
          padding: '2px 22px 2px 8px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiSelect-icon': {
          color: minimalView
            ? theme.colors?.utility[800]
            : theme.colors?.primary.white,
        },

        backgroundColor: minimalView
          ? 'transparent'
          : theme.colors?.utility[800],
        borderRadius: theme.spacing(4),
        p: theme.spacing(1, 2),
        color: minimalView
          ? theme.colors?.utility[800]
          : theme.colors?.primary.white,
        ...componentProps?.selectButtonProps?.sx,
      }}
      value={initialValue}
      MenuProps={{
        PaperProps: {
          sx: {
            boxShadow: '0px 6px 12px -2px rgba(0, 0, 0, 0.30)',
            p: 1,
            borderRadius: 4,
            ...selectPaperSx,
          },
        },
      }}
      onChange={(e) => {
        // If paywall is enabled and the user has hit the member limit, don't allow them to change the role
        if (showPaywall && paidMemberLimitReached) {
          return;
        }

        const newRole = e.target.value as InternalOrganizationRole;
        setCurrentRole(newRole);

        if (newRole === InternalOrganizationRole.SocialListeningUser) {
          onRoleChange(newRole);
          // const allBrandIds =
          // user?.socialListeningBrands.map((b) => b.id) || [];
          // setSelectedSLBrandIds(allBrandIds);
        } else {
          onRoleChange(newRole);
        }
      }}
      renderValue={(value: string) =>
        renderCustomButton ? (
          renderCustomButton(value as InternalOrganizationRole)
        ) : (
          <Typography variant="headline-xs">
            {InternalOrganizationRolesMap[value]
              ? InternalOrganizationRolesMap[value].label
              : value}
          </Typography>
        )
      }
    >
      {rolesToRender
        .filter((role) =>
          [
            InternalOrganizationRole.Admin,
            InternalOrganizationRole.User,
            ...(!extendedSLMenu && socialListeningEnabled
              ? [InternalOrganizationRole.SocialListeningUser]
              : []),
          ].includes(role),
        )
        .map((role, index) => {
          const isThisRoleOptionDisabledByPaywall =
            showPaywall &&
            role !== InternalOrganizationRole.SocialListeningUser &&
            paidMemberLimitReached;

          const rolePointsRender = InternalOrganizationRolesMap[
            role
          ].points.map((point) => (
            <Box display="flex">
              <Typography variant="body-sm">• {point}</Typography>
            </Box>
          ));

          const disabled = isThisRoleOptionDisabledByPaywall || !canUpdate;

          return (
            <MenuItem
              value={role}
              key={index}
              disabled={disabled}
              sx={{
                width: theme.spacing(72),
                '&.Mui-selected, &:hover': {
                  backgroundColor: `${theme.colors?.utility[275]} !important`,
                  borderRadius: theme.spacing(3),
                },
                '&.Mui-selected': {
                  backgroundColor: `${theme.colors?.utility[400]} !important`,
                },
              }}
            >
              <Box display="flex" alignItems="center" flex={1}>
                <Box
                  display="flex"
                  gap={1}
                  flexDirection="column"
                  ml={3}
                  flex={1}
                >
                  <Typography
                    variant="body-lg"
                    color={theme.colors?.primary.black}
                    sx={{ fontWeight: 600 }}
                  >
                    {InternalOrganizationRolesMap[role].label}
                  </Typography>
                  <Typography
                    variant="body-sm"
                    color={theme.colors?.utility[700]}
                    sx={{ fontWeight: 500 }}
                  >
                    {!isThisRoleOptionDisabledByPaywall && rolePointsRender}
                    {isThisRoleOptionDisabledByPaywall && (
                      <>
                        <Typography
                          variant="body-sm"
                          sx={{
                            display: 'block',
                          }}
                        >
                          You've hit your member limit.
                        </Typography>
                        {orgBilling && (
                          <BillingOrganizationMemberLimitIndicator
                            organizationBilling={orgBilling}
                            variant="text"
                            customText="to add more members"
                            renderNoPaywallHit={() => {
                              return rolePointsRender;
                            }}
                            sx={{
                              pointerEvents: 'auto',
                            }}
                          />
                        )}
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          );
        })}
      {extendedSLMenu && socialListeningEnabled && (
        <MoreMenuItem
          onClick={() => {
            let newRole = currentRole;
            if (currentRole !== InternalOrganizationRole.SocialListeningUser) {
              newRole = InternalOrganizationRole.SocialListeningUser;
            }

            setCurrentRole(newRole);
            if (newRole) {
              onRoleChange(newRole);
            }
          }}
          disableRipple
          sx={{
            minWidth: 240,
            width: '100%',
            backgroundColor:
              currentRole === InternalOrganizationRole.SocialListeningUser
                ? `${theme.colors?.utility[400]} !important`
                : 'transparent',
          }}
          label={
            <Box width="100%">
              <Box display="flex" alignItems="center" flex={1}>
                <Box
                  display="flex"
                  gap={1}
                  flexDirection="column"
                  ml={3}
                  flex={1}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}
                  >
                    <Typography
                      variant="body-lg"
                      color={theme.colors?.primary.black}
                      sx={{ fontWeight: 600 }}
                    >
                      {
                        InternalOrganizationRolesMap[
                          InternalOrganizationRole.SocialListeningUser
                        ].label
                      }
                    </Typography>
                    <Typography
                      variant="body-lg"
                      color={theme.colors?.utility[700]}
                    >
                      {InternalOrganizationRolesMap[
                        InternalOrganizationRole.SocialListeningUser
                      ]
                        ? InternalOrganizationRolesMap[
                            InternalOrganizationRole.SocialListeningUser
                          ].type
                        : ''}
                    </Typography>
                  </Box>

                  <Typography
                    variant="body-sm"
                    color={theme.colors?.utility[700]}
                    sx={{ fontWeight: 500 }}
                  >
                    {InternalOrganizationRolesMap[
                      InternalOrganizationRole.SocialListeningUser
                    ].points.map((point) => (
                      <Box display="flex">
                        <Typography variant="body-sm">• {point}</Typography>
                      </Box>
                    ))}
                  </Typography>
                </Box>
              </Box>
            </Box>
          }
          hideMenu={
            !(
              currentRole === InternalOrganizationRole.SocialListeningUser &&
              Boolean(user?.socialListeningBrands.length)
            )
          }
        >
          {currentRole === InternalOrganizationRole.SocialListeningUser &&
            Boolean(user?.socialListeningBrands.length) && (
              <Box
                sx={{
                  width: 350,
                  py: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                {user?.socialListeningBrands.map((brand) => {
                  const everyOneAtOrgCanAccessBrand =
                    brand.generalPermission ===
                      GeneralPermission.OrganizationMembers &&
                    user.organization.id === userInfo?.orgId;
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      key={brand.id}
                      gap={2}
                      sx={{
                        p: 2,
                        borderRadius: theme.spacing(3),
                        opacity: everyOneAtOrgCanAccessBrand ? 0.5 : 1,
                        cursor: everyOneAtOrgCanAccessBrand
                          ? 'default'
                          : 'pointer',
                        '&:hover': {
                          backgroundColor: everyOneAtOrgCanAccessBrand
                            ? 'transparent'
                            : theme.colors?.utility[275],
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (everyOneAtOrgCanAccessBrand) {
                          return;
                        }

                        if (selectedSLBrandIds.includes(brand.id)) {
                          const updatedBrandIds = selectedSLBrandIds.filter(
                            (id) => id !== brand.id,
                          );
                          setSelectedSLBrandIds(updatedBrandIds);

                          if (currentRole) {
                            onSLBrandChange?.(brand.id, 'remove');
                          }
                        } else {
                          setSelectedSLBrandIds((prev) => [...prev, brand.id]);

                          if (currentRole) {
                            onSLBrandChange?.(brand.id, 'add');
                          }
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width: theme.spacing(7),
                          height: theme.spacing(6),
                          px: 2,
                        }}
                      >
                        {brandIdsThatUserhaveAccessTo.find(
                          (b) => b.id === brand.id,
                        ) ? (
                          <IconCustomTick size={12} />
                        ) : (
                          <Box />
                        )}
                      </Box>
                      <Avatar
                        src={brand.creators[0]?.profilePictureUrl || ''}
                        sx={{
                          width: 16,
                          height: 16,
                        }}
                      />
                      <Typography variant="body-lg" flex={1}>
                        {brand.name}
                      </Typography>
                      {everyOneAtOrgCanAccessBrand && (
                        <Typography
                          variant="body-xs"
                          sx={{
                            backgroundColor: theme.colors?.utility[400],
                            borderRadius: theme.spacing(4),
                            p: theme.spacing(1, 2),
                          }}
                        >
                          Everyone at {user.organization.name}
                        </Typography>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
        </MoreMenuItem>
      )}
    </Select>
  );
};
