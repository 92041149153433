import { Box, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

export const BrandCompetitorInfoSkeleton = () => {
  return (
    <Box display="flex" gap={3} alignItems="center">
      <Box
        sx={{
          width: theme.spacing(18),
          height: theme.spacing(18),
          borderRadius: theme.spacing(100),
          border: `2px solid ${theme.colors?.utility[300]}`,
          backgroundColor: theme.colors?.utility[275],
        }}
      />
      <Box display="flex" gap={0} flexDirection="column">
        <Skeleton
          sx={{
            width: theme.spacing(40),
            height: theme.spacing(12),
          }}
        />
        <Skeleton
          sx={{
            width: theme.spacing(18),
            height: theme.spacing(12),
          }}
        />
      </Box>
    </Box>
  );
};
