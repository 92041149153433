import { gql } from '@apollo/client';
import { COMMENT_FRAGMENT_COMMENT_CONTAINER_VIEW } from 'features/comments/views/container/types';
import { CommentInputData } from 'graphql/generated';

export interface AnnotationInput {
  comment: CommentInputData;
}

const USER_FRAGMENT_ANNOTATION = gql`
  fragment UserProfileFragmentAnnotation on UserProfileModel {
    id
    ...UserFragmentAvatarGroup
  }
`;

// TODO: make it more generic when all the annotations managed in the same model
export const POST_ANNOTATION_FRAGMENT_ANNOTATION = gql`
  fragment PostAnnotationFragmentAnnotation on PostAnnotationModel {
    id
    pageNumber
    time
    x
    y
    noteCommentAnchorId
    createdAt
    updatedAt
    isResolved
    postId
    createdBy {
      id
      ...UserProfileFragmentAnnotation
    }
    comments {
      ...CommentFragmentCommentContainerView
    }
  }
  ${USER_FRAGMENT_ANNOTATION}
  ${COMMENT_FRAGMENT_COMMENT_CONTAINER_VIEW}
`;

export type MousePosition = {
  x: number | null;
  y: number | null;
};

export enum ZoomOption {
  FIT_TO_WIDTH = 'Fit to Width',
  FIT_TO_HEIGHT = 'Fit to Height',
  ACTUAL_SIZE = 'Actual Size',
  FIT_TO_SCREEN = 'Fit to Screen',
}

export const BASE_MAX_ZOOM_SCALE = 2;
export const BASE_MIN_ZOOM_SCALE = 0.25;

export const PlaybackSpeed = [0.5, 1, 1.5, 1.75, 2];
