import { Box, Skeleton } from '@mui/material';
import { UserCellSkeleton } from './UserCellSkeleton';

export const UserListSkeleton = () => {
  return (
    <Box pt={2} px={4} py={6} gap={4} display="flex" flexDirection="column">
      <UserCellSkeleton />
      <Skeleton variant="rectangular" width="auto" height={1} />
      <UserCellSkeleton />
      <Skeleton variant="rectangular" width="auto" height={1} />
      <UserCellSkeleton />
      <Skeleton variant="rectangular" width="auto" height={1} />
      <UserCellSkeleton />
    </Box>
  );
};
