/**
 * This component is nearly identify to PermissionSelector,
 * but it's exclusively used for general permission level (see GeneralPermissionSelector).
 *
 * It contains internal logic that allows users to set a default general permission level to their personal settings.
 */

import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  CircularProgress,
  Divider,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { IconBoldTick } from 'components/icons/components/bold/IconBoldTick';
import { useUserContext } from 'contexts/users/User.context';
import { BillingUpgradeModal } from 'features/billing';
import { useMeMutations } from 'features/me';
import { PermissionLevel } from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { PermissionLevelMap } from '../permissionSelector';

export type GeneralPermissionLevelSelectorProps = {
  entityType: 'collection' | 'post';
  entityOrganizationId: string;
  userOrganizationId: string;
  initialValue?: PermissionLevel;
  onPermissionChange?: (permission: PermissionLevel) => void;
  visiblePermissionLevels?: PermissionLevel[];
  hideDefaultPermission?: boolean;
};

export const GeneralPermissionLevelSelector = (
  props: GeneralPermissionLevelSelectorProps,
) => {
  const {
    entityType,
    entityOrganizationId,
    userOrganizationId,
    initialValue,
    onPermissionChange,
    visiblePermissionLevels,
    hideDefaultPermission = false,
  } = props;

  const { user, orgBilling, joinedOrgBillings } = useUserContext();

  const organizationBilling = useMemo(() => {
    if (orgBilling?.organizationId === entityOrganizationId) {
      return orgBilling;
    }
    return joinedOrgBillings?.find(
      (j) => j.organizationId === entityOrganizationId,
    );
  }, [orgBilling, joinedOrgBillings, entityOrganizationId]);

  const isGuest = useMemo(() => {
    return userOrganizationId !== entityOrganizationId;
  }, [userOrganizationId, entityOrganizationId]);

  const highestLevelAllowed = useMemo(() => {
    if (isGuest && organizationBilling?.guestPermission) {
      return organizationBilling?.guestPermission;
    }

    return PermissionLevel.Full;
  }, [isGuest, organizationBilling]);
  const {
    isOpen: pricingModalOpen,
    onOpen: pricingModalOnOpen,
    onClose: pricingModalOnClose,
  } = useDisclosure();

  const [currentPermission, setCurrentPermission] = useState<
    PermissionLevel | undefined
  >(initialValue);

  useEffect(() => {
    setCurrentPermission(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (currentPermission) {
      onPermissionChange?.(currentPermission);
    }
  }, [currentPermission]);

  const { availableOptions, paidOptions } = useMemo(() => {
    const options = Object.keys(PermissionLevelMap) as PermissionLevel[];

    let availableOptions: PermissionLevel[] = [];
    const paidOptions: PermissionLevel[] = [];

    for (const { index, option } of options.map((option, index) => ({
      index,
      option,
    }))) {
      if (highestLevelAllowed === option) {
        availableOptions = options.slice(index, options.length);
        break;
      }

      paidOptions.push(option);
    }

    return {
      availableOptions: visiblePermissionLevels?.length
        ? availableOptions.filter((option) =>
            visiblePermissionLevels.includes(option),
          )
        : availableOptions,
      paidOptions: visiblePermissionLevels?.length
        ? paidOptions.filter((option) =>
            visiblePermissionLevels.includes(option),
          )
        : paidOptions,
    };
  }, [highestLevelAllowed, visiblePermissionLevels]);

  const { updateUserPersonalSettings } = useMeMutations();
  const [loading, setLoading] = useState(false);

  const handleSetDefault = async (
    defaultGeneralPermissionLevel: PermissionLevel,
  ) => {
    setLoading(true);
    await updateUserPersonalSettings(
      entityType === 'collection'
        ? {
            collectionGeneralPermissionDefaultPermissionLevel:
              defaultGeneralPermissionLevel,
          }
        : {
            postGeneralPermissionDefaultPermissionLevel:
              defaultGeneralPermissionLevel,
          },
    );
    setLoading(false);
  };

  const defaultGeneralPermissionLevel = useMemo(() => {
    switch (entityType) {
      case 'collection':
        return user?.personalSettings
          ?.collectionGeneralPermissionDefaultPermissionLevel;
      case 'post':
        return user?.personalSettings
          ?.postGeneralPermissionDefaultPermissionLevel;
      default:
        return undefined;
    }
  }, [user, entityType]);

  return (
    <Select
      size="small"
      fullWidth
      sx={{
        '& .MuiSelect-select': {
          padding: '4px 26px 2px 12px !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
      value={currentPermission}
      onClick={(e) => {
        e.stopPropagation();
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            boxShadow: '0px 6px 12px -2px rgba(0, 0, 0, 0.30)',
            p: 1,
            borderRadius: 4,
          },
        },
      }}
      onChange={(e) => {
        setCurrentPermission(e.target.value as PermissionLevel);
      }}
      renderValue={(value: string) => (
        <Typography variant="headline-xs" color={theme.colors?.utility[800]}>
          {PermissionLevelMap[value]
            ? PermissionLevelMap[value].label
            : value || 'No access'}
        </Typography>
      )}
    >
      {availableOptions.map((permission) => (
        <MenuItem value={permission} key={permission}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
            gap={4}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                sx={{ opacity: currentPermission === permission ? 1 : 0 }}
              >
                <IconBoldTick size={16} />
              </Box>
              <Box display="flex" gap={1} flexDirection="column" ml={3}>
                <Typography
                  variant="body-lg"
                  color={theme.colors?.primary.black}
                  sx={{
                    fontWeight: currentPermission === permission ? 600 : 500,
                  }}
                >
                  {PermissionLevelMap[permission].label}
                </Typography>

                <Typography
                  variant="body-sm"
                  color={theme.colors?.utility[800]}
                  sx={{
                    fontWeight: currentPermission === permission ? 600 : 500,
                  }}
                >
                  {PermissionLevelMap[permission].info}
                </Typography>
              </Box>
            </Box>

            {!hideDefaultPermission && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {defaultGeneralPermissionLevel === permission ? (
                  <Typography
                    variant="headline-xxs"
                    color={theme.colors?.utility[700]}
                  >
                    Default
                  </Typography>
                ) : loading ? (
                  <CircularProgress size={12} />
                ) : (
                  <Typography
                    variant="headline-xxs"
                    sx={{
                      cursor: 'pointer',
                      pointerEvents: 'all',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSetDefault(permission);
                    }}
                  >
                    Set as Default
                  </Typography>
                )}
              </>
            )}
          </Box>
        </MenuItem>
      ))}

      {paidOptions.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(4),
            padding: theme.spacing(2, 3),
          }}
        >
          <Divider />
          <Box>
            <Typography variant="headline-xs">
              <Typography
                variant="headline-xs"
                sx={{
                  fontWeight: 600,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={pricingModalOnOpen}
              >
                {organizationBilling?.hasUsedFreeTrial
                  ? 'Upgrade Now'
                  : 'Start Free Trial'}
              </Typography>{' '}
              to enable:
            </Typography>

            <BillingUpgradeModal
              isOpen={pricingModalOpen}
              onClose={pricingModalOnClose}
            />
          </Box>

          {paidOptions.map((option) => (
            <Box
              key={option}
              display="flex"
              gap={1}
              flexDirection="column"
              ml={7}
            >
              <Typography variant="body-lg" color={theme.colors?.utility[700]}>
                {PermissionLevelMap[option].label}
              </Typography>

              <Typography variant="body-sm" color={theme.colors?.utility[700]}>
                {PermissionLevelMap[option].info}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Select>
  );
};
