import { createTheme } from '@mui/material/styles';
import { buttonOverrides } from 'components/common/Button/styles';
import { chipOverrides } from 'components/common/Chip/styles';
import { paperOverrides } from 'components/common/Paper/styles';
import { skeletonOverrides } from 'components/common/Skeleton/styles';
import { typography } from 'components/common/Typography/styles';
import { autocompleteOverrides } from 'components/common/form/Autocomplete/styles';
import { checkboxOverrides } from 'components/common/form/Checkbox/styles';
import { outlinedInputOverrides } from 'components/common/form/OutlinedInput/styles';
import { radioOverrides } from 'components/common/form/Radio/styles';
import {
  menuItemOverrides,
  selectOverrides,
} from 'components/common/form/Select/styles';
import { switchOverrides } from 'components/common/form/Switch/styles';
import { colors } from './colors';

export const theme = createTheme({
  colors,
  typography,
  spacing: 4,
  components: {
    MuiButton: buttonOverrides,
    MuiOutlinedInput: outlinedInputOverrides,
    MuiSelect: selectOverrides,
    MuiMenuItem: menuItemOverrides,
    MuiCheckbox: checkboxOverrides,
    MuiRadio: radioOverrides,
    MuiSwitch: switchOverrides,
    MuiPaper: paperOverrides,
    MuiAutocomplete: autocompleteOverrides,
    MuiSkeleton: skeletonOverrides,
    MuiChip: chipOverrides,
  },
});
