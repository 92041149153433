import { Box, Typography } from '@mui/material';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import {
  SLPageBrandSelector,
  useBrandDetails,
} from 'features/socialMediaListening';
import { BrandParsedHours } from 'features/socialMediaListening/components/brandParsedHours';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const SocialMediaListeningAnalyticsPage = () => {
  const { brandId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    setRenderTitle,
    setHeaderSx,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
  } = useCustomHeaderContext();

  const { brand } = useBrandDetails({ brandId });

  useEffect(() => {
    const renderMenuExtraLeft = () => (
      <SocialListeningUserPermissionsView brandId={brandId} />
    );
    addRenderMenuExtraLeft(renderMenuExtraLeft, 1);

    setHeaderSx({
      borderBottom: `1px solid transparent`,
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          📣 Social Listening
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
      removeRenderMenuExtraLeft(renderMenuExtraLeft);
    };
  }, [location.pathname]); // eslint-disable-line

  return (
    <Box mt={18} mx={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={2}
        >
          <SLPageBrandSelector
            generateUrl={(brandId) =>
              PlotRoutes.socialListeningAnalyticsInbound(brandId)
            }
          />
        </Box>
        <Box marginLeft="auto" display="flex" alignItems="center" columnGap={2}>
          <BrandParsedHours />
          {brand && (
            <Box
              borderRadius={10}
              bgcolor={theme.colors?.utility[275]}
              padding={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 'center',
              }}
              component="button"
              onClick={() => {
                navigate(
                  PlotRoutes.socialListeningSettings({
                    brandId: brand.id,
                  }),
                );
              }}
            >
              <IconBoldSettings2 size={22} color={theme.colors?.utility[600]} />
            </Box>
          )}
        </Box>
      </Box>

      <Outlet />
    </Box>
  );
};
