import { Box } from '@mui/material';
import { NodeViewProps } from '@tiptap/react';
import { typography } from 'components/common/Typography/styles';
import { theme } from 'styles/theme';
import { ATTR_BLOCK_ID } from '../../constants';

export type CaptionProps = {} & NodeViewProps;

export const Caption = (props: CaptionProps) => {
  const { node, extension, updateAttributes } = props;

  return (
    <Box
      className="caption-container"
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
      }}
    >
      <Box
        component="input"
        placeholder="Write a caption"
        defaultValue={node.attrs.caption}
        name={`${node.attrs[ATTR_BLOCK_ID]}-caption`}
        sx={{
          ...typography['body-xl'],
          textAlign: 'center',
          color: 'inherit',
          width: '100%',
          p: 0,
          m: 0,
          bgcolor: 'transparent',
          border: 0,
          outline: 0,
          lineHeight: 1,
          fontStyle: 'italic',
          '::placeholder': {
            color: theme.colors?.utility[600],
          },
        }}
        readOnly={!extension.options.editable}
        onChange={(e) =>
          updateAttributes({
            caption: e.target.value,
          })
        }
        autoComplete="off"
      />
    </Box>
  );
};
