import { gql } from '@apollo/client';
import { Box, Popover } from '@mui/material';
import { AnnotationPopoverContent } from 'components/common/Annotation/Common/RenderAnnotation/AnnotationPopover';
import {
  PostAnnotationFragmentAnnotationFragmentDoc,
  useGetPostAnnotationsForCommentThreadsPopoverQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { ATTR_NOTE_COMMENT_ANCHOR_ID } from '../../constants';

// eslint-disable-next-line
gql`
  query GetPostAnnotationsForCommentThreadsPopover(
    $filters: PostAnnotationFilters
  ) {
    postAnnotations(filters: $filters) {
      id
      ...PostAnnotationFragmentAnnotation
    }
  }
  ${PostAnnotationFragmentAnnotationFragmentDoc}
`;

export type CommentThreadsPopoverProps = {
  noteCommentAnchorId: string;

  /**
   * In case we want a custom anchor element (e.g. clicking on the comment count decorators)
   */
  anchorEl?: Element | null;

  onClose: () => void;

  onCommentDeleted?: () => void;
};

export const CommentThreadsPopover = (props: CommentThreadsPopoverProps) => {
  const { noteCommentAnchorId, anchorEl, onClose, onCommentDeleted } = props;

  const internalAnchorEl =
    anchorEl ||
    document.querySelector(
      // NOTE: See Comment extension. We are using "btn-" as a prefix for the noteCommentAnchorIds
      // passed by the comment count decorators. So when we are looking for the anchor element,
      // we have to differentiate between finding the node in the editor body, or the button decorators.
      noteCommentAnchorId.includes('btn-')
        ? `[${ATTR_NOTE_COMMENT_ANCHOR_ID}*="${noteCommentAnchorId}"]`
        : `[${ATTR_NOTE_COMMENT_ANCHOR_ID}*="${noteCommentAnchorId}"]:not([${ATTR_NOTE_COMMENT_ANCHOR_ID}^="btn-"])`,
    );

  const noteCommentAnchorIds = noteCommentAnchorId

    .replace('btn-', '')
    .split('|');
  const { data, loading } = useGetPostAnnotationsForCommentThreadsPopoverQuery({
    variables: {
      filters: {
        noteCommentAnchorIds,
      },
    },
  });
  const postAnnotations = data?.postAnnotations || [];

  if (loading || postAnnotations.length === 0) {
    return null;
  }

  return (
    <Popover
      open={Boolean(internalAnchorEl)}
      anchorEl={internalAnchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      PaperProps={{
        sx: {
          position: 'relative',
          p: 4,
          maxWidth: 340,
          maxHeight: '50vh',
          '.comment-input-wrapper': {
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1.5,
          },
          '.comment-user': {
            pt: 2,
          },
        },
      }}
      onClose={onClose}
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
    >
      {postAnnotations.map((annotation, index) => {
        return (
          <Box
            key={annotation.id}
            sx={{
              ...(index > 0 && {
                borderTop: `1px solid ${theme.colors?.utility[275]}`,
                pt: 2,
              }),
            }}
          >
            <AnnotationPopoverContent
              annotation={annotation}
              componentsProps={{
                commentInput: {
                  autoFocus: false,
                },
              }}
              onCommentDeleted={onCommentDeleted}
            />
          </Box>
        );
      })}
    </Popover>
  );
};
