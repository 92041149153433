import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledCommentContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  zIndex: 1,
}));

export const StyledCommentWrapper = styled(Box)(() => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(3),
  boxShadow: `0px 1px 4px 0px rgba(0, 0, 0, 0.16)`,
  backgroundColor: theme.colors?.primary.white,
  zIndex: 99,
  maxHeight: 250,
  overflowY: 'auto',
  border: `1px solid ${theme.colors?.utility[300]}`,
}));

export const Bubble = styled(Box)(() => ({
  backgroundColor: theme.colors?.primary.white,
  height: 28,
  width: 28,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0px 3px 12px -3px rgba(0, 0, 0, 0.16)`,
  borderRadius: '100px 100px 100px 0px',
  cursor: 'auto',
}));

export const StyledCommentInputWrapper = styled(Box)(() => ({
  '& .comment-input-wrapper': {
    alignItems: 'flex-start !important',
    gap: theme.spacing(2),
    '& .comment-actions': {
      marginTop: theme.spacing(1.3),
      gap: theme.spacing(1),
    },
    '& .comment-user': {
      marginTop: theme.spacing(2),
    },
  },
}));

export const inputStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  width: '100%',
  borderRadius: theme.spacing(5),
  backgroundColor: theme.colors?.utility[275],
  padding: theme.spacing(1, 2.5),
};

export const editorStyle = {
  maxWidth: 275,
  minWidth: 275,
  ...theme.typography['subhead-xl'],
  color: theme.colors?.utility[900],
  padding: theme.spacing(1, 1, 1, 0),
};
