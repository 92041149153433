import { gql, useApolloClient } from '@apollo/client';
import { useDeletePostsForCollectionPostDndHoveringActionPopupMutation } from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useParams } from 'react-router-dom';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeletePostsForCollectionPostDndHoveringActionPopup(
    $data: DeletePostsInput!
  ) {
    deletePosts(data: $data) {
      success
      message
    }
  }
`;

export const usePostHandlers = () => {
  const { collectionId = '' } = useParams();

  const client = useApolloClient();

  const [deletePosts] =
    useDeletePostsForCollectionPostDndHoveringActionPopupMutation();

  const {
    dialog: deletePostsConfirmationDialog,
    onOpen: openDeletePostsConfirmationDialog,
  } = useConfirmationDialog();

  const onDeletePosts = async (postIds: string[]) => {
    try {
      openDeletePostsConfirmationDialog({
        title: 'Delete Posts',
        subtitle: 'Are you sure you want to delete the selected posts?',
        onConfirm: () => {
          deletePosts({
            variables: {
              data: {
                postIds,
                collectionId,
              },
            },
          });

          postIds.forEach((postId) => {
            evictObject(client.cache, postId, 'PostModel');
          });
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return {
    onDeletePosts,
    deletePostsConfirmationDialog,
  };
};
