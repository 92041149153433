import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useState } from 'react';
import {
  BillingPlanName,
  UserFragmentAccessRequestAdminListFragment,
} from 'graphql/generated';
import { ManageMembers } from 'pages/billing/checkout/components/ManageMembers';
import { BillingUpdateMemberConfirmationDialog } from './BillingUpdateMemberConfirmationDialog';
import { useAllBillingPlans } from '../../hooks';

type BillingCanUpgradeDialogProps = {
  plan: BillingPlanName;
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  selectedType: 'upgrade' | 'freeTrial';
};

export const BillingCanUpgradeDialog = (
  props: BillingCanUpgradeDialogProps,
) => {
  const { plan, isOpen, onClose, onOpen, selectedType = 'upgrade' } = props;

  const { user, orgBilling } = useUserContext();
  const allPlans = useAllBillingPlans();

  const {
    isOpen: editMemberOpen,
    onClose: editMemberOnClose,
    onOpen: editMemberOnOpen,
  } = useDisclosure();

  const {
    isOpen: confirmationOpen,
    onClose: confirmationOnClose,
    onOpen: confirmationOnOpen,
  } = useDisclosure();

  const [usersToBeDisabled, setUsersToBeDisabled] = useState<
    UserFragmentAccessRequestAdminListFragment[]
  >([]);
  const [usersToBeEnabled, setUsersToBeEnabled] = useState<
    UserFragmentAccessRequestAdminListFragment[]
  >([]);

  const handleManageMembers = (selectedMembers: string[]) => {
    setUsersToBeDisabled(
      (user?.organization.users ?? []).filter(
        (u) => !selectedMembers.includes(u.id),
      ),
    );
    setUsersToBeEnabled(
      (user?.organization.users ?? []).filter((u) =>
        selectedMembers.includes(u.id),
      ),
    );

    editMemberOnClose();
    confirmationOnOpen();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            width: '376px',
            padding: theme.spacing(8),
            bgcolor: theme.colors?.primary.white,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(6),
          },
        }}
      >
        <Button
          sx={{
            color: theme.colors?.primary.black,
            p: 0,
          }}
          startIcon={<IconOutlineArrowLeft />}
          onClick={onClose}
        >
          Back to Plans
        </Button>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(3),
          }}
        >
          <Typography variant="headline-xl" fontSize={24} fontWeight={500}>
            <Typography
              component="span"
              textTransform="capitalize"
              variant="headline-xl"
              fontSize={24}
              fontWeight={500}
            >
              {plan} Plan
            </Typography>{' '}
            allows up to {allPlans[plan]?.organizationMemberLimit} organization
            members.
          </Typography>
          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            Your organization currently has{' '}
            <b>{orgBilling?.organizationMemberUsage} members</b>.
          </Typography>
          <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
            Consider upgrading to the other plan to add more members, or remove
            some to stay on the{' '}
            <Typography
              component="span"
              textTransform="capitalize"
              variant="subhead-xl"
              color={theme.colors?.utility[700]}
            >
              {plan} Plan.
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              ...theme.typography['headline-sm'],
              bgcolor: theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              color: theme.colors?.primary.white,
              ':hover': { bgcolor: theme.colors?.primary.black },
            }}
            onClick={() => {
              onClose();
              editMemberOnOpen();
            }}
          >
            Edit members
          </Button>

          <Button
            fullWidth
            sx={{
              textDecoration: 'underline',
              ':hover': { textDecoration: 'underline' },
            }}
            onClick={onClose}
          >
            Explore other plans instead
          </Button>
        </Box>
      </Dialog>

      <BillingUpdateMemberConfirmationDialog
        isOpen={confirmationOpen}
        onClose={confirmationOnClose}
        usersToBeDisabled={usersToBeDisabled}
        usersToBeEnabled={usersToBeEnabled}
        type={selectedType}
        onGoBack={() => {
          confirmationOnClose();
          editMemberOnOpen();
        }}
      />

      <ManageMembers
        isOpen={editMemberOpen}
        onClose={editMemberOnClose}
        selectionLimit={allPlans[plan]?.organizationMemberLimit ?? 5}
        activeOrgUsers={user?.organization.users ?? []}
        isNotClosable
        onGoBack={() => {
          editMemberOnClose();
          onOpen();
        }}
        subDescription={`Only ${allPlans[plan]?.organizationMemberLimit} members would be allowed in your organization`}
        onCustomSubmit={handleManageMembers}
      />
    </>
  );
};
