import { Box, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { SocialPostStatusData } from 'features/socialPost';
import { BrandSocialPostEngagementStatus } from 'graphql/generated';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

interface FilterByStatusesProps {
  onToggle: (value: BrandSocialPostEngagementStatus[]) => void;
  options: BrandSocialPostEngagementStatus[];
  selectedOptions: BrandSocialPostEngagementStatus[];
  renderTitle?: () => React.ReactNode;
  type?: 'accordion' | 'normal';
}

export const FilterByStatuses = ({
  onToggle,
  options = [],
  selectedOptions = [],
  renderTitle,
  type = 'accordion',
}: FilterByStatusesProps) => {
  const Icon = socialListeningFilterIconMap[SocialListeningFiltersKey.Statuses];

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    if (type === 'normal') {
      return <Typography variant="subhead-lg">Status</Typography>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon size={16} />
        </Box>

        <Typography variant="subhead-lg">Status</Typography>
      </Box>
    );
  }, [Icon, renderTitle, type]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      defaultOpen={type === 'normal'}
      sx={{
        ...(type === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(type === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      dropDownOptions={options.map((option) => ({
        label: (
          <Box
            sx={{
              ...SocialPostStatusData[option].sx,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[800]}
              fontWeight={600}
            >
              {SocialPostStatusData[option].label}
            </Typography>
          </Box>
        ),
        onClick: () => {
          onToggle(
            selectedOptions.includes(option)
              ? selectedOptions.filter((o) => o !== option)
              : [...selectedOptions, option],
          );
        },
        isChecked: selectedOptions.includes(option),
      }))}
    />
  );
};
