export type PostMessageParams = {
  message: string;
  // can be object
  // we will stringify it on web
  // send the message (string) to mobile webview
  // then parse on mobile side
  data?: any;
  options?: {
    behavior?: 'openLinkInModalScreen';
    presentation?: string;
  };
};

export const MessageFromWebAppToMobileType = 'Webapp';

export const postMessageToMobileApp = ({
  message,
  options,
  data,
}: PostMessageParams) => {
  // for case enable apple pay => normal postMessage will not work, have to use webkit.messageHandlers
  (window as any)?.webkit?.messageHandlers?.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: MessageFromWebAppToMobileType,
      message,
      options,
      data,
    }),
  );
};
