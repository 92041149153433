import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select/RadioMenuItem';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { SLASentimentClaims } from 'features/socialListeningAnalytics/components/slaSentimentClaims';
import { SentimentThemeCardWithDateRange } from 'features/socialMediaListening/views/analytics/inbound/sections/sentimentThemeCard/SentimentThemeCardWithDateRange';
import {
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForSentimentMetrics,
  Sentiment,
  SentimentMedium,
  useGetBrandInboundSentimentMetricsQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { SLABrandInboundSentimentClaimModalView } from '../slaBrandInboundSentimentClaimModal/SLABrandInboundSentimentClaimModalView';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandInboundSentimentMetrics(
    $data: BrandInboundFiltersInputForSentimentMetrics!
  ) {
    brandInboundSentimentMetrics(data: $data) {
      sentimentMetrics {
        averageCountPerDay
        chartDataPoints {
          Y
          X
        }
        totalCount
      }
      claimMetrics {
        numberOfMentions
        attribute
      }
    }
  }
`;

type Props = {
  filters: BrandInboundFiltersInput;
  hideInPosts?: boolean;
};

export const SLABrandInboundSentimentsView = ({
  filters,
  hideInPosts,
}: Props) => {
  const [medium, setMedium] = useState<SentimentMedium>(
    hideInPosts ? SentimentMedium.InComments : SentimentMedium.InPosts,
  );

  const [sentiment, setSentiment] = useState(Sentiment.Positive);
  const [selectedClaim, setSelectedClaim] = useState('');

  const themeDialogDisclosure = useDisclosure();

  useEffect(() => {
    setMedium(
      hideInPosts && medium === SentimentMedium.InPosts
        ? SentimentMedium.InComments
        : medium,
    );
  }, [hideInPosts]);

  const generatePayload = (
    sentiment: Sentiment,
  ): BrandInboundFiltersInputForSentimentMetrics => ({
    ...filters,
    sentiment,
    medium,
  });

  const {
    data: positiveSentimentMetricsData,
    loading: positiveSentimentMetricsLoading,
  } = useGetBrandInboundSentimentMetricsQuery({
    variables: {
      data: generatePayload(Sentiment.Positive),
    },
  });

  const {
    data: neutralSentimentMetricsData,
    loading: neutralSentimentMetrics,
  } = useGetBrandInboundSentimentMetricsQuery({
    variables: {
      data: generatePayload(Sentiment.Neutral),
    },
  });

  const {
    data: negativeSentimentMetricsData,
    loading: negativeSentimentMetricsLoading,
  } = useGetBrandInboundSentimentMetricsQuery({
    variables: {
      data: generatePayload(Sentiment.Negative),
    },
  });

  const isLoading =
    positiveSentimentMetricsLoading ||
    neutralSentimentMetrics ||
    negativeSentimentMetricsLoading;

  return (
    <Box my={6}>
      <Card
        sx={{
          borderRadius: 5,
          p: 6,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Typography variant="headline-lg" fontSize={theme.spacing(5)}>
            Sentiment
          </Typography>
          <Box display="flex" gap={3} alignItems="center">
            <Box display="flex" alignItems="center" gap={3}>
              <ContextMenu
                sx={{
                  '& .context-menu-item': {
                    p: 0,
                    color: theme.colors?.primary.black,
                  },
                }}
                options={[
                  ...(!hideInPosts
                    ? [
                        {
                          renderOption: () => (
                            <RadioMenuItem
                              label="In Posts"
                              value={SentimentMedium.InPosts}
                              checked={medium === SentimentMedium.InPosts}
                              sx={{
                                p: `${theme.spacing(2, 3)}`,
                              }}
                            />
                          ),
                          onClick: () => {
                            setMedium(SentimentMedium.InPosts);
                          },
                        },
                      ]
                    : []),
                  {
                    renderOption: () => (
                      <RadioMenuItem
                        label="In Comments"
                        value={SentimentMedium.InComments}
                        checked={medium === SentimentMedium.InComments}
                        sx={{
                          p: `${theme.spacing(2, 3)}`,
                        }}
                      />
                    ),
                    onClick: () => {
                      setMedium(SentimentMedium.InComments);
                    },
                  },
                ]}
                renderButton={() => (
                  <Button
                    sx={{
                      backgroundColor: theme.colors?.utility[275],
                      color: theme.colors?.primary.black,
                      borderRadius: theme.spacing(2),
                      display: 'flex',
                      gap: 2,
                      p: theme.spacing(1, 2),
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: theme.colors?.utility[275],
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        backgroundColor: 'rgba(35, 6, 3, 0.05);',
                        p: theme.spacing(1),
                        borderRadius: theme.spacing(1),
                      }}
                    >
                      <IconLinearSort size={16} />
                    </Box>
                    <Typography fontWeight={500} variant="body-xl">
                      {medium === SentimentMedium.InPosts
                        ? 'In Posts'
                        : 'In Comments'}
                    </Typography>
                  </Button>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 6,
            borderBottom: `1px solid ${theme.colors?.utility[275]}`,
          }}
        />

        <Grid container spacing={3} columns={12} mt={6}>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Positive',
                subTitle:
                  'Favorable comments or discussions about your topic, brand, or product on social.',
                chartDataPoints:
                  positiveSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  positiveSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Positive}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['green-4'],
                highlightColor: 'rgba(224, 228, 202, 0.50)',
              }}
              renderTableData={() => (
                <SLASentimentClaims
                  claimMetrics={
                    positiveSentimentMetricsData?.brandInboundSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Positive);
                    setSelectedClaim(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['green-4'],
                    highlightColor: 'rgba(224, 228, 202, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Neutral',
                subTitle:
                  'Neither positive nor negative discussions about your topic, brand or product on social.',
                chartDataPoints:
                  neutralSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  neutralSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Neutral}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['blue-4'],
                highlightColor: 'rgba(202, 223, 228, 0.50)',
              }}
              renderTableData={() => (
                <SLASentimentClaims
                  claimMetrics={
                    neutralSentimentMetricsData?.brandInboundSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Neutral);
                    setSelectedClaim(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['blue-4'],
                    highlightColor: 'rgba(202, 223, 228, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <SentimentThemeCardWithDateRange
              chartData={{
                title: 'Negative',
                subTitle:
                  'Unfavorable comments or discussions about your topic, brand, or product on social.',
                chartDataPoints:
                  negativeSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.chartDataPoints || [],
                averageCount:
                  negativeSentimentMetricsData?.brandInboundSentimentMetrics
                    ?.sentimentMetrics.totalCount || 0,
              }}
              sentiment={Sentiment.Negative}
              dateRange={[
                filters.dateRange.startDate,
                filters.dateRange.endDate,
              ]}
              componentProps={{
                containerSx: {
                  boxShadow: 'none',
                  border: 'none',
                  mx: 4,
                },
                mainColor: theme.colors?.utility['pink-4'],
                highlightColor: 'rgba(228, 202, 202, 0.50)',
              }}
              renderTableData={() => (
                <SLASentimentClaims
                  claimMetrics={
                    negativeSentimentMetricsData?.brandInboundSentimentMetrics
                      ?.claimMetrics || []
                  }
                  onClickTheme={(theme) => {
                    setSentiment(Sentiment.Negative);
                    setSelectedClaim(theme);
                    themeDialogDisclosure.onOpen();
                  }}
                  componentProps={{
                    mainColor: theme.colors?.utility['pink-4'],
                    highlightColor: 'rgba(228, 202, 202, 0.50)',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Card>

      <SLABrandInboundSentimentClaimModalView
        {...themeDialogDisclosure}
        filters={filters}
        medium={medium}
        sentiment={sentiment}
        selectedAttribute={selectedClaim}
      />
    </Box>
  );
};
