import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Badge, Box, Button, SxProps, Typography } from '@mui/material';
import { LinearProgress } from 'components/common/LinearProgress';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { useUserContext } from 'contexts/users/User.context';
import {
  PermissionCreateRequestDialog,
  PermissionDialogLayout,
} from 'features/permission';
import {
  GeneralPermission,
  PostFragmentPostPermissionDialogViewFragment,
  UserFragmentAvatarGroupFragmentDoc,
  useGetPostPermissionForDialogViewQuery,
} from 'graphql/generated';
import { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import {
  POST_FRAGMENT_POST_PERMISSION_DIALOG_BODY,
  PostPermissionDialogBody,
} from './PostPermissionDialogBody';

export const POST_FRAGMENT_POST_PERMISSION_DAILOG_VIEW = gql`
  fragment PostFragmentPostPermissionDialogView on PostModel {
    id
    permissionLevel
    generalPermission
    title
    inviteMembers {
      id
    }
    permissionRequests {
      id
    }
    creator {
      ...UserFragmentAvatarGroup
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

type PostPermissionDialogViewProps = {
  post: PostFragmentPostPermissionDialogViewFragment;
  open?: boolean;
  onClose?: () => void;
  renderCustomButton?: () => React.ReactNode;
  componentsProps?: {
    shareBtn?: {
      sx?: SxProps;
    };
  };
};

export const PostPermissionDialogView = (
  props: PostPermissionDialogViewProps,
) => {
  const {
    post,
    open,
    onClose,
    renderCustomButton,
    componentsProps = {},
  } = props;
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const updateBtnRef = useRef<HTMLButtonElement>(null);
  const { user } = useUserContext();

  const {
    isOpen: isOpenPermissionDialog,
    onOpen: onOpenPermissionDialog,
    onClose: onClosePermissionDialog,
  } = useDisclosure();

  const {
    isOpen: isOpenCreateRequestDialog,
    onOpen: onOpenCreateRequestDialog,
    onClose: onCloseCreateRequestDialog,
  } = useDisclosure();

  const handleClosePermissionDialog = () => {
    onClosePermissionDialog();
    onClose?.();
  };

  useEffect(() => {
    if (open) {
      onOpenPermissionDialog();
    } else {
      onClosePermissionDialog();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PermissionDialogLayout
        isOpen={isOpenPermissionDialog}
        onOpen={onOpenPermissionDialog}
        onClose={handleClosePermissionDialog}
        customBtn={
          renderCustomButton ? (
            renderCustomButton()
          ) : (
            <Badge
              variant="dot"
              invisible={
                post.creator.id === user?.id
                  ? post?.permissionRequests?.length === 0
                  : true
              }
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: theme.colors?.utility['pink-3'],
                  width: 12,
                  height: 12,
                  borderRadius: 50,
                },
              }}
            >
              <Button
                sx={{
                  ...theme.typography['headline-sm'],
                  color: theme.colors?.primary.parchment,
                  bgcolor: theme.colors?.primary.black,
                  borderRadius: 2,
                  padding: theme.spacing(2, 4),
                  ':hover': {
                    bgcolor: theme.colors?.primary.black,
                  },
                  ...componentsProps.shareBtn?.sx,
                }}
                startIcon={
                  post?.generalPermission === GeneralPermission.InviteOnly ? (
                    post.inviteMembers.length === 0 ? (
                      <IconBoldLock
                        size={16}
                        color={theme.colors?.primary.parchment}
                      />
                    ) : (
                      <IconCustomUsers
                        size={16}
                        color={theme.colors?.primary.parchment}
                      />
                    )
                  ) : (
                    <IconBoldBuildings
                      size={16}
                      color={theme.colors?.primary.parchment}
                    />
                  )
                }
              >
                Share
              </Button>
            </Badge>
          )
        }
        hasPendingChanges={hasPendingChanges}
        onPressSaveChanges={() => {
          setHasPendingChanges(false);
          updateBtnRef.current?.click();
          handleClosePermissionDialog();
        }}
        renderDialogBody={(isOpen) => (
          <PostPermissionDialogData
            postId={post.id}
            shouldRefetch={isOpen}
            setHasPendingChanges={setHasPendingChanges}
            onCallbackAfterUpdate={handleClosePermissionDialog}
            componentsProps={{
              dialogBody: {
                updateBtnRef,
                onRequestCreateAccess: () => {
                  handleClosePermissionDialog();
                  onOpenCreateRequestDialog();
                },
              },
            }}
          />
        )}
      />
      <PermissionCreateRequestDialog
        isOpen={isOpenCreateRequestDialog}
        onClose={onCloseCreateRequestDialog}
        title={post?.title || 'this post'}
        user={post.creator}
        postId={post.id}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const GET_POST_PERMISSION_FOR_DAILOG_VIEW = gql`
  query GetPostPermissionForDialogView($postId: String!) {
    post(id: $postId) {
      ...PostFragmentPostPermissionDialogBody
    }
  }
  ${POST_FRAGMENT_POST_PERMISSION_DIALOG_BODY}
`;

type PostPermissionDialogDataProps = {
  postId: string;
  shouldRefetch: boolean;
  setHasPendingChanges?: (state: boolean) => void;
  onCallbackAfterUpdate?: VoidFunction;
  componentsProps: {
    dialogBody: {
      updateBtnRef?: React.RefObject<HTMLButtonElement>;
      onRequestCreateAccess: VoidFunction;
    };
  };
};

export const PostPermissionDialogData = (
  props: PostPermissionDialogDataProps,
) => {
  const {
    postId,
    shouldRefetch,
    setHasPendingChanges,
    componentsProps,
    onCallbackAfterUpdate,
  } = props;

  const { data, loading, refetch } = useGetPostPermissionForDialogViewQuery({
    variables: {
      postId,
    },
    fetchPolicy: 'network-only',
  });

  const post = data?.post;

  useEffect(() => {
    if (shouldRefetch) {
      // this allows us to pull data everytime the modal loads to prevent using old data
      // otherwise it will only load once at the very beginning
      refetch();
    }
  }, [shouldRefetch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <LinearProgress />;
  }

  if (!post) {
    return (
      <Box>
        <Typography>Error getting post data</Typography>
      </Box>
    );
  }

  return (
    <PostPermissionDialogBody
      onRequestCreateAccess={componentsProps.dialogBody.onRequestCreateAccess}
      post={post}
      setHasPendingChanges={setHasPendingChanges}
      updateBtnRef={componentsProps.dialogBody?.updateBtnRef}
      onCallbackAfterUpdate={onCallbackAfterUpdate}
    />
  );
};
