import { gql } from '@apollo/client';
import { Editor, getMarkType } from '@tiptap/react';
import { ATTR_NOTE_COMMENT_ANCHOR_ID, MARK_COMMENT } from 'features/tiptap';
import { useGetPostAnnotationsForUseValidateCommentsLazyQuery } from 'graphql/generated';
import { debounce } from 'lodash';
import { useMemo } from 'react';

// eslint-disable-next-line
gql`
  query GetPostAnnotationsForUseValidateComments(
    $filters: PostAnnotationFilters
  ) {
    postAnnotations(filters: $filters) {
      id
      noteCommentAnchorId
    }
  }
`;

export type UseValidateCommentsProps = {
  editor: Editor | null;
  content: string;
};

export const useValidateComments = (props: UseValidateCommentsProps) => {
  const { editor, content } = props;

  const [getPostAnnotations] =
    useGetPostAnnotationsForUseValidateCommentsLazyQuery();

  const revalidate = useMemo(
    () =>
      debounce(async () => {
        if (!editor) {
          return;
        }

        // Use regex to find all noteCommentAnchorId in the html content
        // Format: <span {...} data-noteCommentAnchorId="noteCommentAnchorId" {...}>...</span>
        const noteCommentAnchorIds =
          content
            // See: ATTR_NOTE_COMMENT_ANCHOR_ID
            .match(/data-notecommentanchorid="([^"]+)"/g)
            ?.map((match) => {
              return match
                .replace(`${ATTR_NOTE_COMMENT_ANCHOR_ID}="`, '')
                .replace('"', '');
            })
            .flatMap((noteCommentAnchorId) => noteCommentAnchorId.split('|'))
            // Remove duplicates
            .filter((noteCommentAnchorId, index, self) => {
              return self.indexOf(noteCommentAnchorId) === index;
            }) || [];

        const response = await getPostAnnotations({
          variables: {
            filters: {
              noteCommentAnchorIds,
            },
          },
        });

        const postAnnotations = response.data?.postAnnotations || [];
        const noteCommentAnchorIdsInDB = postAnnotations.map(
          (postAnnotation) => postAnnotation.noteCommentAnchorId,
        );
        const noteCommentAnchorIdsNotInDB = noteCommentAnchorIds.filter(
          (noteCommentAnchorId) =>
            !noteCommentAnchorIdsInDB.includes(noteCommentAnchorId),
        );

        // Remove comment marks that are not in the database
        const { state } = editor;
        const { tr } = state;

        state.doc.descendants((node, pos) => {
          const type = getMarkType(MARK_COMMENT, state.schema);
          const commentMark = node.marks.find(
            (mark) => mark.type.name === MARK_COMMENT,
          );

          if (commentMark) {
            // Find and remove the noteCommentAnchorId that are not in the database:
            // 1. Remove the mark if attr data-notecommentanchorid only has one single value, OR
            // 2. Remove the value from the attr data-notecommentanchorid if it has multiple values
            const currentMarkNoteCommentAnchorIds =
              commentMark.attrs[ATTR_NOTE_COMMENT_ANCHOR_ID].split('|');
            const updatedMarkNoteCommentAnchorIds =
              currentMarkNoteCommentAnchorIds.filter(
                (noteCommentAnchorId) =>
                  !noteCommentAnchorIdsNotInDB.includes(noteCommentAnchorId),
              );

            if (updatedMarkNoteCommentAnchorIds.length === 0) {
              tr.removeMark(pos, pos + node.nodeSize);
            } else {
              tr.addMark(
                pos,
                pos + node.nodeSize,
                type.create({
                  [ATTR_NOTE_COMMENT_ANCHOR_ID]:
                    updatedMarkNoteCommentAnchorIds.join('|'),
                }),
              );
            }
          }
        });

        editor.view.dispatch(tr);
      }, 300),
    [editor, content, getPostAnnotations],
  );

  return {
    revalidate,
  };
};
