import { gql } from '@apollo/client';
import {
  ContentIdeaFieldOptionFragmentMultiSelectFieldFragmentDoc,
  ContentIdeaFieldOptionFragmentSelectFieldFragmentDoc,
} from 'graphql/generated';

/**
 * These are a set of reusable fragments that can be used to query the value of a ContentIdeaFieldValueModel
 * based on the type of field it is.
 */

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_TEXT = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldText on ContentIdeaFieldValueModel {
    id
    value {
      id
      text
    }
  }
`;

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_SELECT = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldSelect on ContentIdeaFieldValueModel {
    id
    value {
      id
      select {
        ...ContentIdeaFieldOptionFragmentSelectField
      }
    }
  }
  ${ContentIdeaFieldOptionFragmentSelectFieldFragmentDoc}
`;

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_MULTI_SELECT = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldMultiSelect on ContentIdeaFieldValueModel {
    id
    value {
      id
      multiSelect {
        ...ContentIdeaFieldOptionFragmentMultiSelectField
      }
    }
  }
  ${ContentIdeaFieldOptionFragmentMultiSelectFieldFragmentDoc}
`;

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_DATE = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldDate on ContentIdeaFieldValueModel {
    id
    value {
      id
      date
    }
  }
`;

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_CHECKBOX = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldCheckbox on ContentIdeaFieldValueModel {
    id
    value {
      id
      checkbox
    }
  }
`;

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_TASKS = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldTasks on ContentIdeaFieldValueModel {
    id
    value {
      id
      tasks {
        id
      }
    }
  }
`;

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_USERS = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldUsers on ContentIdeaFieldValueModel {
    id
    value {
      id
      users {
        id
      }
    }
  }
`;

export const CONTENT_IDEA_FIELD_VALUE_FRAGMENT_CONTENT_IDEA_FIELD_COLLECTION = gql`
  fragment ContentIdeaFieldValueFragmentContentIdeaFieldCollection on ContentIdeaFieldValueModel {
    id
    value {
      id
      collection {
        id
      }
    }
  }
`;
