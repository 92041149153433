import { COMMAND_KEYS, COMMAND_TYPE } from '../../constants';
import { Command } from '../../types';
import { CreateContentIdeaHandler } from './components/CreateContentIdeaHandler';

export const useContentIdeaCommands = (): Command[] => {
  return [
    {
      type: COMMAND_TYPE.CREATE_CONTENT_IDEA,
      keys: COMMAND_KEYS[COMMAND_TYPE.CREATE_CONTENT_IDEA],
      Handler: CreateContentIdeaHandler as any,
    },
  ];
};
