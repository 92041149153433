import { Dialog, IconButton, Typography } from '@mui/material';
import { Iframely } from 'components/common/Iframely';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { useState } from 'react';

type BillingDowngradeModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

export const BillingDowngradeModal = (props: BillingDowngradeModalProps) => {
  const { isOpen, onClose } = props;

  const [error, setError] = useState<string>();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          position: 'relative',
          height: '80%',
          maxWidth: '87%',
          width: '100%',
          p: 8,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <IconButton
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onClick={onClose}
      >
        <IconBoldCloseCircle />
      </IconButton>
      <Iframely
        url="https://plotworkspace.typeform.com/to/zUF9e3Zx"
        onErrorOrNoPreview={() => setError('Error. Please try again.')}
      />

      {error && <Typography variant="subhead-md">{error}</Typography>}
    </Dialog>
  );
};
