/* blockId */
export const EXTENSION_BLOCK_WRAPPER = 'blockWrapper';

export const EXTENSION_BLOCK_FLOATING_MENU = 'blockFloatingMenu';

/* column */
export const NODE_COLUMN_LIST = 'columnList';
export const NODE_COLUMN = 'column';

/* command menu */
export const EXTENSION_COMMAND_MENU = 'commandMenu';

/* command menu */
export const MARK_COMMENT = 'comment';

/* drop */
export const EXTENSION_DROP = 'drop';

/* dropcursor */
export const EXTENSION_DROPCURSOR = 'dropcursor';

/* embed */
export const NODE_EMBED = 'embed';

/* emoji */
export const EXTENSION_EMOJI = 'emoji';

/* emoji */
export const NODE_POST = 'post';

/* media */
export const NODE_MEDIA = 'media';

export const EXTENSION_ENSURE_EMPTY_BOTTOM_PARAGRAPH =
  'ensureEmptyBottomParagraph';

export const EXTENSION_FILE_PASTE = 'filePaste';

export const EXTENSION_INPUT_TRANSFORMATION = 'inputTransformation';

export const NODE_TASK_ITEM = 'taskItem';

export const ATTR_BLOCK_ID = 'data-block-id';

export const ATTR_NOTE_COMMENT_ANCHOR_ID = 'data-notecommentanchorid';

export const ATTR_POST_ID = 'post-id';

/* disableEnter */
export const EXTENSION_DISABLE_ENTER = 'disableEnter';
