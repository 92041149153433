import { gql } from '@apollo/client';
import { Box, SxProps } from '@mui/material';
import {
  SocialPostFragmentSocialPostPlatformFragment,
  Platform,
} from 'graphql/generated';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { theme } from 'styles/theme';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';

// eslint-disable-next-line
gql`
  fragment SocialPostFragmentSocialPostPlatform on SocialPostModel {
    id
    platform
  }
`;

const SocialPostPlatformMeta = {
  [Platform.Instagram]: {
    icon: <IconBoldInstagram color={theme.colors?.primary.white} size={16} />,
  },
  [Platform.Tiktok]: {
    icon: <IconCustomTiktok color={theme.colors?.primary.white} size={16} />,
  },
  [Platform.Reel]: {
    icon: <IconBoldInstagram color={theme.colors?.primary.white} size={16} />,
  },
};

type SocialPostPlatformProps = {
  socialPost: SocialPostFragmentSocialPostPlatformFragment;
  componentProps?: {
    sx?: SxProps;
  };
};

export const SocialPostPlatform = (props: SocialPostPlatformProps) => {
  const { socialPost, componentProps } = props;

  const platformRender = SocialPostPlatformMeta[socialPost.platform];

  return <Box sx={componentProps?.sx}>{platformRender.icon}</Box>;
};
