import { gql, useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Menu, MenuItem, SxProps, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconLinearDocumentUpload } from 'components/icons/components/linear/IconLinearDocumentUpload';
import { IconLinearNote2 } from 'components/icons/components/linear/IconLinearNote2';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { GenerateId } from 'utils/generateId';
import { PostPreview, PostPreviewProps } from 'features/post';
import { usePostPermissionUpdatePostPermissionToCollectionPermission } from 'features/post-permission';
import {
  ContentIdeaFragmentContentIdeaPreviewFragment,
  PostFragmentPostCommandFragment,
  PostFragmentPostPreviewFragmentDoc,
  useSavePostsToCollectionsForContentIdeaPreviewMutation,
  useUpdateCollectionPreviewForContentIdeaPreviewMutation,
} from 'graphql/generated';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_PREVIEW = gql`
  fragment ContentIdeaFragmentContentIdeaPreview on ContentIdeaModel {
    id
    collection {
      id
    }
    moodboard {
      id
      value {
        id
        collection {
          id
        }
      }
    }
    previewPost {
      id
      post {
        ...PostFragmentPostPreview
      }
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation SavePostsToCollectionsForContentIdeaPreview(
    $data: SavePostsToCollectionsInput!
  ) {
    savePostsToCollections(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCollectionPreviewForContentIdeaPreview(
    $data: UpdateCollectionPreviewInput!
  ) {
    updateCollectionPreview(data: $data) {
      id
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export type ContentIdeaPreviewProps = {
  contentIdea: ContentIdeaFragmentContentIdeaPreviewFragment;
  sx?: SxProps;
  componentProps?: {
    postPreview?: Omit<PostPreviewProps, 'post'>;
  };
  readOnly?: boolean;
};

export const ContentIdeaPreview = (props: ContentIdeaPreviewProps) => {
  const { contentIdea, sx, componentProps = {}, readOnly = false } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { backgroundLocation, secondaryLocation } =
    (location.state as {
      backgroundLocation?: any;
      secondaryLocation?: any;
    }) || {};

  const client = useApolloClient();

  const { triggerCommand } = useCommandContext();

  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure();

  const moodboardCollectionId =
    contentIdea.moodboard?.value?.collection?.id || '';
  const [savePostsToCollections] =
    useSavePostsToCollectionsForContentIdeaPreviewMutation();
  const [updateCollectionPreview] =
    useUpdateCollectionPreviewForContentIdeaPreviewMutation();

  /**
   * Cache update function to add new posts to the collection.
   */
  const onPostsAdded = async (posts: PostFragmentPostCommandFragment[]) => {
    // when new post is added, update content idea moodboard collection cache
    modifyObject(client.cache, moodboardCollectionId, 'CollectionModel', {
      posts: (cachedPosts = []) => [...cachedPosts, ...posts],
    });

    // set the first post as the preview post of the content idea's Moodboard collection
    updateCollectionPreview({
      variables: {
        data: {
          collectionId: contentIdea.moodboard?.value?.collection?.id || '',
          postId: posts[0].id,
        },
      },
      update: (cache, { data }) => {
        if (data?.updateCollectionPreview) {
          modifyObject(cache, contentIdea.id, 'ContentIdeaModel', {
            previewPost: () => ({
              id: data.updateCollectionPreview!.id,
              post: data.updateCollectionPreview,
            }),
          });
        }
      },
    });
  };

  /**
   * Additional logic to update post permissions when adding new posts from CJB command flow.
   */
  const { onOpenUpdatePostPermissionDialog, renderUpdatePostPermissionAlert } =
    usePostPermissionUpdatePostPermissionToCollectionPermission();
  const onPostsAddedFromCJB = async (
    posts: PostFragmentPostCommandFragment[],
  ) => {
    await savePostsToCollections({
      variables: {
        data: {
          collectionIds: [moodboardCollectionId],
          postIds: posts.map((i) => i.id),
        },
      },
    });

    onOpenUpdatePostPermissionDialog(
      posts.map((i) => i.id),
      moodboardCollectionId,
    );

    onPostsAdded(posts);
  };

  return (
    <>
      <Box
        ref={anchorElRef}
        component="button"
        type="button"
        onClick={openMenu}
        sx={{
          color: '#23060326',
          bgcolor: '#2306030D',
          ...sx,
          // FIXME: This is a hack to ensure this whole component doesn't receive or block
          // any mouse-event when it's read-only. I'll find a better way later if possible.
          ...(readOnly
            ? {
                pointerEvents: 'none',
                '*': { pointerEvents: 'none !important' },
              }
            : {}),
        }}
        disabled={readOnly}
      >
        {contentIdea.previewPost ? (
          // if post is available, show the post preview
          contentIdea.previewPost.post ? (
            <PostPreview
              key={contentIdea.previewPost.id}
              post={contentIdea.previewPost.post}
              {...componentProps.postPreview}
            />
          ) : (
            // else show a placeholder
            <Box
              sx={{
                bgcolor: theme.colors?.primary.parchment,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                ...componentProps.postPreview?.sx,
              }}
            >
              <IconBoldLock size={14} color={theme.colors?.utility[700]} />
              <Typography
                variant="subhead-sm"
                color={theme.colors?.utility[800]}
              >
                The post
                <br /> is private
              </Typography>
            </Box>
          )
        ) : (
          !readOnly && <IconBoldAddCircle size={20} />
        )}
      </Box>
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 3,
            bgcolor: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
          },
        }}
      >
        {[
          [
            IconOutlineLink,
            'Paste a link or image',
            () => {
              triggerCommand(COMMAND_TYPE.POST_PASTE_A_LINK, {
                shouldShowAfterCreationDialog: false,
                collectionId: moodboardCollectionId,
                onCompleted: onPostsAdded,
              });
            },
          ],
          [
            IconLinearDocumentUpload,
            'Upload media',
            () => {
              triggerCommand(COMMAND_TYPE.POST_UPLOAD_FILES, {
                shouldShowAfterCreationDialog: false,
                collectionId: moodboardCollectionId,
                onCompleted: onPostsAdded,
              });
            },
          ],
          [
            IconCustomCreativeJuiceBox,
            'Add from Creative Juicebox',
            () => {
              triggerCommand(COMMAND_TYPE.POST_SELECT_FROM_CJB, {
                submitButtonLabel: 'Continue',
                hardcodedContentIdeaCollectionId: contentIdea.collection.id,
                onCompleted: onPostsAddedFromCJB,
              });
            },
          ],
          [
            IconLinearNote2,
            'Create a Note',
            () => {
              navigate(
                `${PlotRoutes.juicePreview(GenerateId.create())}${
                  location.search
                }`,
                {
                  state: {
                    ...((location.state as any) || {}),
                    isCreatingNewNote: true,
                    backgroundLocation: backgroundLocation || location,
                    secondaryLocation:
                      secondaryLocation ||
                      (backgroundLocation ? location : undefined),
                    collectionId: moodboardCollectionId,
                  },
                },
              );
            },
          ],
        ].map(([Icon, label, onClick], index) => {
          return (
            <MenuItem
              key={index}
              sx={{
                borderRadius: 3,
                p: 3,
              }}
              onClick={() => {
                // @ts-ignore
                onClick();
                closeMenu();
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Icon size={16} />
                <Typography variant="headline-xs" fontSize={13}>
                  {label as string}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>

      {renderUpdatePostPermissionAlert()}
    </>
  );
};
