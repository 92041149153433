import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearElementEqual } from 'components/icons/components/linear/IconLinearElementEqual';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import { IconOutlineTimer } from 'components/icons/components/outline/IconOutlineTimer';
import { SortType } from 'graphql/generated';
import { Dispatch, SetStateAction } from 'react';
import { theme } from 'styles/theme';

interface Props {
  setSortType: Dispatch<SetStateAction<SortType | undefined>>;
  sortType?: SortType;
  shouldShowRelevancy?: boolean;
}

export const JuiceboxSortBySelectButtonFilter = ({
  setSortType,
  sortType,
  shouldShowRelevancy,
}: Props) => {
  const sortByOptions = [
    {
      Icon: IconOutlineTimer,
      text: 'Recent Activity',
      value: SortType.RecentActivity,
    },
    {
      Icon: IconOutlineCalendar1,
      text: 'Date Created',
      value: SortType.DateCreated,
    },
    ...(shouldShowRelevancy
      ? [
          {
            Icon: IconLinearElementEqual,
            text: 'Relevancy',
            value: SortType.Relevance,
          },
        ]
      : []),
  ];

  return (
    <ContextMenu
      PaperProps={{
        style: {
          minWidth: 266,
          borderRadius: theme.spacing(3),
          boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
          paddingTop: theme.spacing(3),
        },
      }}
      renderButton={() => (
        <IconButton
          sx={{
            backgroundColor: theme.colors?.utility['purple-4'],
            height: 40,
            width: 40,
            ':hover': {
              backgroundColor: theme.colors?.utility['purple-4'],
            },
          }}
        >
          <IconLinearSort color={theme.colors?.utility['purple-1']} />
        </IconButton>
      )}
      options={sortByOptions.map((option, index) => ({
        onClick: () => {
          setSortType(option.value);
        },
        sx: {
          borderRadius: 3,
          mt: index === 0 ? 3 : 0,
          padding: theme.spacing(3, 4),
          mb: 0.5,
          bgcolor:
            sortType === option.value ? `rgba(35, 6, 3, 0.1)` : 'transparent',
          ':hover': {
            backgroundColor: `rgba(35, 6, 3, 0.1)`,
          },
        },
        renderOption: () => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                width: 24,
                height: 24,
                borderRadius: 1,
                bgcolor: theme.colors?.utility['400'],
              }}
            >
              <option.Icon size={16} />
            </Box>
            <Typography variant="subhead-lg">{option.text}</Typography>
          </Box>
        ),
      }))}
      renderExtraTop={() => (
        <Typography
          variant="headline-xs"
          color={theme.colors?.utility[700]}
          paddingLeft={theme.spacing(4)}
        >
          Sort by
        </Typography>
      )}
    />
  );
};
