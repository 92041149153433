import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum AuthRequestedBy {
  MobileInappBrowser = 'mobileInappBrowser',
}

export const SESSION_STORAGE_AUTH_REQUESTED_BY = 'requestedBy';
export const SESSION_STORAGE_AUTH_REDIRECT_URI = 'redirectURI';

export const useAuthForMobileInappBrowser = () => {
  const [params] = useSearchParams();
  const requestedBy =
    params.get('requestedBy') ||
    sessionStorage.getItem(SESSION_STORAGE_AUTH_REQUESTED_BY) ||
    '';
  const redirectURI =
    params.get('redirectURI') ||
    sessionStorage.getItem(SESSION_STORAGE_AUTH_REDIRECT_URI) ||
    '';

  const redirectToMobile = useCallback(
    ({
      accessToken,
      refreshToken,
      isSignUp,
    }: {
      accessToken: string;
      refreshToken: string;
      isSignUp?: boolean;
    }) => {
      if (redirectURI) {
        window.location.replace(
          `${redirectURI}?accessToken=${accessToken}&refreshToken=${refreshToken}${
            isSignUp ? '&isSignUp=true' : ''
          }`,
        );
      }
    },
    [redirectURI],
  );

  // Set the requestedBy to mobileInappBrowser to session storage
  // This should help cover whenever we forgot to maintain the params during navigation
  useEffect(() => {
    if (redirectURI && requestedBy === AuthRequestedBy.MobileInappBrowser) {
      sessionStorage.setItem(SESSION_STORAGE_AUTH_REQUESTED_BY, requestedBy);
      sessionStorage.setItem(SESSION_STORAGE_AUTH_REDIRECT_URI, redirectURI);
    }
  }, [requestedBy, redirectURI]);

  const isInMobileInAppBrowser =
    requestedBy === AuthRequestedBy.MobileInappBrowser;

  return {
    isInMobileInAppBrowser,
    redirectURI,
    redirectToMobile,
  };
};
