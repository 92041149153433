import { gql, useApolloClient } from '@apollo/client';
import { useClipboard } from '@dwarvesf/react-hooks';
import { Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearEdit } from 'components/icons/components/linear/IconLinearEdit';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineCopy } from 'components/icons/components/outline/IconOutlineCopy';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import {
  ContentCalendarViewFragmentContentCalendarViewContextMenuFragment,
  ContentCalendarViewPermission,
  useDeleteContentCalendarViewForContentCalendarViewContextMenuMutation,
  useDuplicateContentCalendarViewForContentCalendarViewContextMenuMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { evictObject } from 'utils/apollo';

export const CONTENT_CALENDAR_VIEW_FRAGMENT_CONTENT_CALENDAR_VIEW_CONTEXT_MENU = gql`
  fragment ContentCalendarViewFragmentContentCalendarViewContextMenu on ContentCalendarViewModel {
    id
    myPermissions
  }
`;

// eslint-disable-next-line
gql`
  mutation DeleteContentCalendarViewForContentCalendarViewContextMenu(
    $data: DeleteContentCalendarViewInput!
  ) {
    deleteContentCalendarView(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DuplicateContentCalendarViewForContentCalendarViewContextMenu(
    $data: DuplicateContentCalendarViewInput!
  ) {
    duplicateContentCalendarView(data: $data) {
      id
    }
  }
`;

export type ContentCalendarViewContextMenuProps = {
  contentCalendarView: ContentCalendarViewFragmentContentCalendarViewContextMenuFragment;

  // Note: We need to expose this request outside because this component doesn't
  // handle renaming logic.
  onRename: () => void;
};

export const ContentCalendarViewContextMenu = (
  props: ContentCalendarViewContextMenuProps,
) => {
  const { contentCalendarView, onRename } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const canUpdate = contentCalendarView.myPermissions.includes(
    ContentCalendarViewPermission.Update,
  );
  const canDelete = contentCalendarView.myPermissions.includes(
    ContentCalendarViewPermission.Delete,
  );

  const { onCopy: onCopyLink } = useClipboard(
    `${window.location.origin}${PlotRoutes.contentCalendar()}?view=${
      contentCalendarView.id
    }`,
  );

  const {
    onOpen: openDeleteConfirmationDialog,
    dialog: deleteConfirmationDialog,
  } = useConfirmationDialog();

  const client = useApolloClient();
  const [deleteContentCalendarView] =
    useDeleteContentCalendarViewForContentCalendarViewContextMenuMutation();
  const [duplicateContentCalendarView] =
    useDuplicateContentCalendarViewForContentCalendarViewContextMenuMutation();

  const onDuplicate = () => {
    duplicateContentCalendarView({
      variables: {
        data: {
          contentCalendarViewId: contentCalendarView.id,
        },
      },
      update: (_, { data }) => {
        if (data?.duplicateContentCalendarView) {
          params.set('view', data.duplicateContentCalendarView.id);
          navigate(
            {
              pathname: location.pathname,
              search: params.toString(),
            },
            {
              state: location.state,
              replace: true,
            },
          );

          // HACK
          // Manually trigger a refetch on this query because it's the ONLY place
          // If there are multiple in the future, we should consider adding a refetchQueries property to this component
          client.refetchQueries({
            include: ['GetContentCalendarViewsForManageViewsSection'],
          });
        }
      },
    });
  };

  const options = [
    {
      icon: IconOutlineLink,
      label: 'Copy Link',
      onClick: onCopyLink,
      closeOnClick: true,
    },
    {
      icon: IconLinearEdit,
      label: 'Rename View',
      onClick: onRename,
      disabled: !canUpdate,
      closeOnClick: true,
    },
    {
      icon: IconOutlineCopy,
      label: 'Duplicate View',
      onClick: onDuplicate,
      closeOnClick: true,
    },
    {
      icon: IconLinearTrash,
      label: 'Delete View',
      onClick: () => {
        openDeleteConfirmationDialog({
          title: 'Are you sure?',
          subtitle:
            'Deleting this view will delete this configuration permanently.',
          confirmText: 'Delete View',
          onConfirm: () => {
            evictObject(
              client.cache,
              contentCalendarView.id,
              'ContentCalendarViewModel',
            );
            deleteContentCalendarView({
              variables: {
                data: {
                  contentCalendarViewId: contentCalendarView.id,
                },
              },
            });

            // If we are deleting the current view, navigate to default view.
            if (params.get('view') === contentCalendarView.id) {
              params.delete('view');
              navigate(
                {
                  pathname: location.pathname,
                  search: params.toString(),
                },
                {
                  replace: true,
                  state: location.state,
                },
              );
            }
          },
        });
      },
      disabled: !canDelete,
      closeOnClick: true,
    },
  ];

  return (
    <>
      <ContextMenu
        renderButton={() => (
          <IconButton size="small">
            <IconLinearMore size={20} />
          </IconButton>
        )}
        options={options.map((option) => {
          const Icon = option.icon;

          return {
            // isDivider: option.isDivider,
            renderOption: () => (
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {Icon && <Icon size={16} />}
                </Box>
                <Typography variant="headline-xs">{option.label}</Typography>
              </Box>
            ),
            onClick: option.onClick,
            disabled: option.disabled,
            closeOnClick: option.closeOnClick,
          };
        })}
      />
      {deleteConfirmationDialog}
    </>
  );
};
