import { gql } from '@apollo/client';
import { useDebounce } from '@dwarvesf/react-hooks';
import { Box, Card, TextField, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import {
  BrandInboundFiltersInput,
  BrandInboundFiltersInputForCommentInsights,
  CommentEnrichmentType,
  CommentInsightsCategoryType,
  CommentTargetType,
  Platform,
  SocialPostCommentFragmentCommentInsightsTableFragmentDoc,
  SortOrder,
  useGetBrandInboundSocialPostCommentInsightsQuery,
} from 'graphql/generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { CompetitorCommentInsightsFilters } from './commentInsightsFilters';
import { CommentsInsightsTableForBrandInbound } from './commentsInsightsTable/CommentsInsightsTableForBrandInbound';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandInboundSocialPostCommentInsights(
    $data: BrandInboundFiltersInputForCommentInsights!
    $brandId: String!
  ) {
    getBrandInboundCommentInsights(data: $data) {
      meta {
        totalCount
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      data {
        ...SocialPostCommentFragmentCommentInsightsTable
      }
    }
  }
  ${SocialPostCommentFragmentCommentInsightsTableFragmentDoc}
`;

type BrandQuestionsInboundCommentInsightsProps = {
  filters: BrandInboundFiltersInput;
};

export const BrandQuestionsInboundCommentInsights = ({
  filters,
}: BrandQuestionsInboundCommentInsightsProps) => {
  const [sortData, setSortData] = useState<{
    key: string;
    order: SortOrder;
  }>();

  // TODO: Use values from `filters` as default values on mount
  // I don't dare to do this atm because I'm not sure it would break existing functionality on user's side
  // and I don't have time to test it rn. Not the scope of this PR.
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState<[Date, Date]>([
    filters.dateRange.startDate,
    filters.dateRange.endDate,
  ]);
  const [selectedTargetTypes, setSelectedTargetTypes] = useState<
    CommentTargetType[]
  >([]);
  const [selectedEnrichmentTypes, setSelectedEnrichmentTypes] = useState<
    CommentEnrichmentType[]
  >([]);
  const [searchStr, setSearchStr] = useState('');
  const debouncedSearchStr = useDebounce(searchStr, 500);

  useEffect(() => {
    setSelectedDateRange([
      filters.dateRange.startDate,
      filters.dateRange.endDate,
    ]);
  }, [filters.dateRange]);

  const payload: BrandInboundFiltersInputForCommentInsights = useMemo(
    () => ({
      ...filters,
      dateRange: {
        startDate: selectedDateRange[0],
        endDate: selectedDateRange[1],
      },
      organizationSocialPostCommentLabelIds: selectedLabels,
      categories: selectedCategories,
      commentText: debouncedSearchStr,
      // topicIds: selectedTopicIds,
      commentTargetTypes: selectedTargetTypes,
      commentEnrichmentTypes: selectedEnrichmentTypes,
    }),
    [
      filters,
      selectedDateRange,
      selectedLabels,
      selectedCategories,
      debouncedSearchStr,
      // selectedTopicIds,
      selectedTargetTypes,
      selectedEnrichmentTypes,
    ],
  );

  const sortBy = useMemo(
    () =>
      sortData
        ? {
            sortBy: {
              field: sortData?.key,
              order: sortData?.order,
            },
          }
        : {},
    [sortData],
  );

  const {
    data: commentInsightsData,
    loading,
    fetchMore,
  } = useGetBrandInboundSocialPostCommentInsightsQuery({
    variables: {
      data: {
        ...payload,
        take: 5,
      },
      brandId: filters.brandId,
      ...sortBy,
    },
    skip: !filters.brandId,
  });

  const onUpdateFilters = useCallback((filters: {
    labels: string[];
    categories: string[];
    products: string[];
    dateRange: [Date, Date];
    topicIds?: string[];
    commentEnrichmentTypes?: CommentEnrichmentType[];
    commentTargetTypes?: CommentTargetType[];
  }) => {
    if (filters.topicIds) {
      setSelectedTopicIds(filters.topicIds);
    }

    setSelectedTargetTypes(filters.commentTargetTypes || []);
    setSelectedEnrichmentTypes(filters.commentEnrichmentTypes || []);
    setSelectedLabels(filters.labels);
    setSelectedCategories(filters.categories);
    setSelectedProducts(filters.products);
    setSelectedDateRange(filters.dateRange);
  }, []);
  
  return (
    <Card
      sx={{
        borderRadius: 5,
        my: 2,
        p: 6,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box width="100%" display="flex" justifyContent="space-between">
        <Typography variant="headline-lg" fontSize={theme.spacing(5)}>
          Comment Insights
        </Typography>
        <CompetitorCommentInsightsFilters
          commentInsightsCategoryType={
            CommentInsightsCategoryType.BrandQuestionInsights
          }
          hideTopics
          currentDateRange={selectedDateRange}
          platforms={[Platform.Instagram, Platform.Tiktok]}
          currentBrandId={filters.brandId}
          trackingBrandId={filters.brandId}
          onUpdateFilters={onUpdateFilters}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        gap={3}
      >
        <TextField
          variant="outlined"
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
          InputProps={{
            startAdornment: (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                  borderRadius: theme.spacing(100),
                  backgroundColor: theme.colors?.utility[250],
                }}
              >
                <IconOutlineSearchNormal1 size={16} />
              </Box>
            ),
          }}
          placeholder="Search"
          sx={{
            my: theme.spacing(4),
            width: '100%',
            borderRadius: theme.spacing(5),
            backgroundColor: theme.colors?.utility[250],
            '& .MuiOutlinedInput-root': {
              borderRadius: `${theme.spacing(5)} !important`,
              border: 'none',
            },
            '& .MuiInputBase-input': {
              ...typography['headline-sm'],
              borderRadius: `${theme.spacing(5)} !important`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        <Typography
          textAlign="right"
          variant="body-lg"
          color={theme.colors?.utility[700]}
          style={{
            textDecoration: 'italic',
            minWidth: theme.spacing(22),
          }}
        >
          {commentInsightsData?.getBrandInboundCommentInsights.meta.totalCount}{' '}
          comments
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 6,
          borderBottom: `1px solid ${theme.colors?.utility[275]}`,
        }}
      />

      <CommentsInsightsTableForBrandInbound
        sortData={sortData}
        setSortData={setSortData}
        currentBrandId={filters.brandId}
        trackingBrandId={filters.brandId}
        comments={
          commentInsightsData?.getBrandInboundCommentInsights.data || []
        }
        isLoading={loading}
        onLoadMore={() => {
          fetchMore({
            variables: {
              data: {
                ...payload,
                take: 5,
              },
              ...sortBy,
              after:
                commentInsightsData?.getBrandInboundCommentInsights.pageInfo
                  .endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              return {
                ...fetchMoreResult,
                getBrandInboundCommentInsights: {
                  ...fetchMoreResult.getBrandInboundCommentInsights,
                  data: [
                    ...prev.getBrandInboundCommentInsights.data,
                    ...fetchMoreResult.getBrandInboundCommentInsights.data,
                  ],
                },
              };
            },
          });
        }}
        hasMore={
          !!commentInsightsData?.getBrandInboundCommentInsights.pageInfo
            .hasNextPage
        }
      />
    </Card>
  );
};
