import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowLeft } from 'components/icons/components/linear/IconLinearArrowLeft';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import {
  CUSTOM_COLLECTION,
  useCollectionIdFromParams,
} from 'features/collection';
import {
  PostFiltersForSmartSearch,
  PostFilterType,
  PostType,
  SortType,
  useGetPostsSmartSearchForPostNavigationSearchParamsQuery,
} from 'graphql/generated';
import { useEffect, useMemo, useRef } from 'react';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
const POST_FRAGMENT_USE_NAVIGATE_POST_SEARCH_PARAMS = gql`
  fragment PostFragmentUseNavigatePostSearchParams on PostModel {
    id
    type
  }
`;

// eslint-disable-next-line
gql`
  query GetPostsSmartSearchForPostNavigationSearchParams(
    $filters: PostFiltersForSmartSearch!
    $take: Int
    $after: String
    $before: String
    $sortType: SortType
    $sortBy: SortByInputData
  ) {
    postsSmartSearch(
      filters: $filters
      take: $take
      after: $after
      before: $before
      sortType: $sortType
      sortBy: $sortBy
    ) {
      data {
        item {
          ...PostFragmentUseNavigatePostSearchParams
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${POST_FRAGMENT_USE_NAVIGATE_POST_SEARCH_PARAMS}
`;

/**
 * This hook aims to provide the next and previous post navigation in the Post Detail page.
 * Therefore, we are relying on postId and collectionId that can be found in the route params.
 */
export const useNavigatePostSearchParams = () => {
  const [params] = useSearchParams();

  // See useJuiceboxFilter for where we are setting the filters in the state
  const location = useLocation();
  const locationState = useMemo(() => {
    return (
      (location.state as {
        filters?: PostFiltersForSmartSearch;
        sortType?: SortType;
        query?: string;
      }) || {}
    );
  }, [location.state]);

  const prevLinkRef = useRef<HTMLAnchorElement>(null);
  const nextLinkRef = useRef<HTMLAnchorElement>(null);

  const { collectionId = '' } = useCollectionIdFromParams();
  const { id: postId = '' } = useParams();

  const { filters, sortType } = useMemo(() => {
    let _filterType: PostFilterType | undefined;
    let _collectionId: string | undefined;
    // eslint-disable-next-line default-case
    switch (collectionId) {
      case CUSTOM_COLLECTION.ALL_POSTS:
        _filterType = PostFilterType.OrganizationPosts;
        break;
      case CUSTOM_COLLECTION.MY_POSTS:
        _filterType = PostFilterType.MyPosts;
        break;
      case CUSTOM_COLLECTION.SAVED:
        _filterType = PostFilterType.MyFavoritePosts;
        break;
      case CUSTOM_COLLECTION.SAVED_TRENDS:
        _filterType = PostFilterType.MySavedTrends;
        break;
      default:
        _filterType = PostFilterType.OrganizationPosts;
        _collectionId = collectionId;
    }

    const existingFilters = locationState.filters;
    const filters = {
      ...existingFilters,
      filterType: existingFilters?.filterType ?? _filterType,
      collectionIds: existingFilters?.collectionIds?.length
        ? existingFilters.collectionIds
        : _collectionId
        ? [_collectionId]
        : [],
    };

    const sortType = locationState.sortType ?? SortType.DateCreated;

    return {
      filters,
      sortType,
    };
  }, [locationState, collectionId]);

  /**
   * If location state does not have `query`, we can use the normal pagination logic.
   */
  const { data: prevPostData } =
    useGetPostsSmartSearchForPostNavigationSearchParamsQuery({
      variables: {
        filters,
        sortType,
        take: 1,
        before: postId,
      },
      skip: !!locationState.query || !postId,
    });
  const { data: nextPostData } =
    useGetPostsSmartSearchForPostNavigationSearchParamsQuery({
      variables: {
        filters,
        sortType,
        take: 1,
        after: postId,
      },
      skip: !!locationState.query || !postId,
    });
  const prevPostWithoutQuery = prevPostData?.postsSmartSearch.data?.[0]?.item;
  const nextPostWithoutQuery = nextPostData?.postsSmartSearch.data?.[0]?.item;

  /**
   * If location state has `query`, we need to do things differently,
   * because on the backend, postsSmartSearch with query IS NOT PAGINATED!
   * It only supports fetching 50 posts, and the pagination logic is simply missing.
   * So we need to fetch all posts with the query, and then find the next and previous post manually here.
   * TODO: Fix the backend query
   */
  const { data: postDataWithQuery } =
    useGetPostsSmartSearchForPostNavigationSearchParamsQuery({
      variables: {
        filters: {
          ...filters,
          query: locationState.query,
        },
        sortType,
        take: 50,
      },
      skip: !locationState.query || !postId,
    });
  const postsWithQuery = postDataWithQuery?.postsSmartSearch.data.map(
    (d) => d.item,
  );

  // Consolidate & get final prevPost and nextPost
  const { prevPost, nextPost } = useMemo(() => {
    if (locationState.query) {
      const index = postsWithQuery?.findIndex((p) => p.id === postId) ?? -1;
      return {
        prevPost: postsWithQuery?.[index - 1],
        nextPost: postsWithQuery?.[index + 1],
      };
    }

    return {
      prevPost: prevPostWithoutQuery,
      nextPost: nextPostWithoutQuery,
    };
  }, [
    postId,
    locationState,
    prevPostWithoutQuery,
    nextPostWithoutQuery,
    postsWithQuery,
  ]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // List of input element types to exclude
      // Here we exclude the div element because richtext editor is a div element
      const excludedInputTypes = ['input', 'textarea', 'select', 'div'];

      // Check if any input element is focused
      const isInputFocused =
        document.activeElement instanceof HTMLElement &&
        excludedInputTypes.includes(
          document.activeElement.tagName.toLowerCase(),
        );

      if (!isInputFocused) {
        if (event.key === 'ArrowLeft' && prevLinkRef.current) {
          prevLinkRef.current.click();
        } else if (event.key === 'ArrowRight' && nextLinkRef.current) {
          nextLinkRef.current.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [prevLinkRef, nextLinkRef]);

  const renderPrevBtn = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {prevPost && (
          <Link
            to={{
              pathname:
                prevPost.type === PostType.Note
                  ? PlotRoutes.juiceboxNote({ id: prevPost.id })
                  : PlotRoutes.juice(prevPost.id),
              search: params.toString(),
            }}
            replace
            state={{
              ...((location.state || {}) as any),
              backgroundLocation: (
                location.state as { backgroundLocation?: Location }
              )?.backgroundLocation,
            }}
            ref={prevLinkRef}
          >
            <Box
              className="qwertyuiop"
              sx={{
                cursor: 'pointer',
              }}
              position="absolute"
              top="50%"
              left={theme.spacing(5)}
              width={theme.spacing(6)}
              height={theme.spacing(6)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                position="absolute"
                sx={{
                  inset: 0,
                  borderRadius: theme.spacing(10),
                  backgroundColor: theme.colors?.primary.white,
                  opacity: 0.2,
                }}
              />
              <IconLinearArrowLeft size={20} />
            </Box>
          </Link>
        )}
      </>
    );
  };

  const renderNextBtn = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {nextPost && (
          <Link
            to={{
              pathname:
                nextPost.type === PostType.Note
                  ? PlotRoutes.juiceboxNote({ id: nextPost.id })
                  : PlotRoutes.juice(nextPost.id),
              search: params.toString(),
            }}
            replace
            state={{
              ...((location.state || {}) as any),
              backgroundLocation: (
                location.state as { backgroundLocation?: Location }
              )?.backgroundLocation,
            }}
            ref={nextLinkRef}
          >
            <Box
              className="qwertyuiop"
              sx={{
                cursor: 'pointer',
              }}
              position="absolute"
              top="50%"
              right={theme.spacing(5)}
              width={theme.spacing(6)}
              height={theme.spacing(6)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                position="absolute"
                sx={{
                  inset: 0,
                  borderRadius: theme.spacing(10),
                  backgroundColor: theme.colors?.primary.white,
                  opacity: 0.2,
                }}
              />
              <IconLinearArrowRight size={20} />
            </Box>
          </Link>
        )}
      </>
    );
  };

  return {
    renderPrevBtn,
    renderNextBtn,
    prevPost,
    nextPost,
  };
};
