import { Box, Drawer, IconButton, SxProps } from '@mui/material';
import { IconOutlineCrossXClose } from 'components/icons/components/outline/IconOutlineCrossXClose';
import { MOBILE_WIDTH_MD } from 'constants/mediaSizes';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import React, { ReactNode } from 'react';
import { theme } from 'styles/theme';

interface BottomSheetProps {
  isOpen: boolean;
  children: ReactNode;
  showCloseButton?: boolean;
  sx?: SxProps;
  onClose?: () => void;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  children,
  showCloseButton,
  sx,
  onClose,
}) => {
  const isMobileView = useMediaQueryMobile(MOBILE_WIDTH_MD);

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      transitionDuration={{ enter: 150, exit: 200 }}
      sx={{
        '& .MuiPaper-root': {
          height: '90vh',
          m: 0,
          p: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
        },
        ...sx,
      }}
    >
      <Box position="relative">
        {isMobileView && showCloseButton && (
          <Box
            sx={{
              position: 'absolute',
              right: theme.spacing(4),
              top: theme.spacing(3),
              color: theme.colors?.utility[500],
              zIndex: 9,
            }}
          >
            <IconButton onClick={onClose}>
              <IconOutlineCrossXClose size={20} />
            </IconButton>
          </Box>
        )}
        {children}
      </Box>
    </Drawer>
  );
};
