import { Box } from '@mui/material';
import { Editor } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { ReactRenderer } from '@tiptap/react';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearGrid4 } from 'components/icons/components/linear/IconLinearGrid4';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { throttle } from 'lodash';
import {
  WebViewRichTextEditorCommandType,
  WebViewRichTextEditorEventType,
} from 'pages/webViewRichTextEditor/types';
import { useState } from 'react';
import { theme } from 'styles/theme';
import tippy from 'tippy.js';
import { NODE_EMBED } from '../../constants';
import {
  removeAllConsecutiveLinkMarks,
  updateAllConsecutiveLinkMarks,
} from '../utils';

export type HoverMenuProps = {
  href: string;
  onChange: (href: string) => void;
  onRemove: () => void;
  onHover?: () => void;
  onConvertToEmbed?: () => void;
};

/**
 * A hover menu that allows the user to edit the link.
 * This component will be rendered as a tooltip when user hovers over a link using tippy.js.
 *
 * @param props
 * @returns
 */
const HoverMenu = (props: HoverMenuProps) => {
  const { href, onChange, onRemove, onHover, onConvertToEmbed } = props;

  const canConvertToEmbed = Boolean(onConvertToEmbed);
  const isInRNWebView = Boolean((window as any)?.ReactNativeWebView);

  return (
    <Box
      className="hover-menu-container"
      sx={{
        borderRadius: '12px',
        display: 'flex',
        gap: '6px',
        p: '4px',
        backgroundColor: 'rgba(250, 243, 236, 0.5)',
        backdropFilter: 'blur(25px)',
        border: '1.5px solid rgba(15, 15, 15, 0.10)',
        svg: {
          color: theme.colors?.primary.black,
        },
      }}
      onMouseEnter={onHover}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          ...(canConvertToEmbed
            ? {
                '&::after': {
                  content: '""',
                  width: '1px',
                  height: '50%',
                  backgroundColor: theme.colors?.utility[600],
                  display: 'block',
                  position: 'absolute',
                  top: '25%',
                  right: '-2px',
                },
              }
            : {}),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            alignItems: 'center',
            pl: 2,
          }}
        >
          <IconOutlineLink size={16} color={theme.colors?.utility[500]} />
          <Box
            component="input"
            defaultValue={href}
            onChange={(e) => onChange(e.target.value)}
            sx={{
              p: 0,
              color: 'inherit',
              backgroundColor: 'transparent',
              border: '0 !important',
              outline: '0 !important',
              width: 226,
              // NOTE: Temporary fix for showing this on mobile
              // because for some reason the right edge of the webview is blocked from receiving events???
              maxWidth: '40vw',
            }}
            autoComplete="off"
          />
        </Box>
        <IconButtonWithTooltip
          size="small"
          tooltip="Remove Link"
          onClick={onRemove}
        >
          <IconBoldCloseCircle size={24} />
        </IconButtonWithTooltip>
      </Box>
      {canConvertToEmbed && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButtonWithTooltip
            size="small"
            tooltip="Embed Link"
            onClick={onConvertToEmbed}
          >
            <IconLinearGrid4
              style={{
                transform: 'rotate(270deg)',
              }}
            />
          </IconButtonWithTooltip>
        </Box>
      )}
      {isInRNWebView && (
        <IconButtonWithTooltip
          size="small"
          tooltip="Open link"
          onClick={() => {
            // @ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: WebViewRichTextEditorEventType.ExecuteCommand,
                command: WebViewRichTextEditorCommandType.OpenLink,
                url: href,
              }),
            );
          }}
        >
          <IconLinearExport2 size={24} />
        </IconButtonWithTooltip>
      )}
    </Box>
  );
};

/**
 * When user hovers over a link, we'll render a tooltip that allows the user to edit the link
 */
export const HoverPlugin = (editor: Editor) => {
  let hoverTooltipEnterTimeout: ReturnType<typeof setTimeout> | null = null;
  let hoverTooltipLeaveTimeout: ReturnType<typeof setTimeout> | null = null;

  const tippyInstance = tippy('body', {
    content: '',
    placement: 'bottom-start',
    interactive: true,
    followCursor: 'horizontal',
    trigger: 'manual',
  })[0];

  const canConvertToEmbed = editor.extensionManager.extensions.some(
    (ext) => ext.name === NODE_EMBED,
  );

  return new Plugin({
    props: {
      handleDOMEvents: {
        mouseover: throttle((view: EditorView, event: MouseEvent) => {
          let a: HTMLElement | undefined = event.target as HTMLElement;
          const els: HTMLElement[] = [];

          while (a.nodeName !== 'DIV') {
            els.push(a);
            a = a.parentNode as HTMLElement;
          }

          a = els.find((value) => value.nodeName === 'A');

          if (a) {
            // Ignore if link has certain classes
            if (['mention-node'].some((c) => a?.classList.contains(c))) {
              return;
            }

            if (hoverTooltipEnterTimeout) {
              clearTimeout(hoverTooltipEnterTimeout);
              hoverTooltipEnterTimeout = null;
            }

            if (hoverTooltipLeaveTimeout) {
              clearTimeout(hoverTooltipLeaveTimeout);
              hoverTooltipLeaveTimeout = null;
            }

            const component = new ReactRenderer(
              (props) => {
                // We need to store the position of the text node that contains the link mark,
                // and save it here. This value should only be set ONCE per tooltip render for a particular link,
                // because as soon as users make any change to the `href`, `posAtDOM` will no longer be able
                // to find the correct position of the link node.
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const [posAtDOM] = useState(view.posAtDOM(a!, 0));
                const resolvedPos = view.state.doc.resolve(posAtDOM);

                return (
                  <HoverMenu
                    {...props}
                    href={a!.getAttribute('href')}
                    onChange={(href: string) => {
                      updateAllConsecutiveLinkMarks(view, resolvedPos, {
                        href,
                      });
                    }}
                    onRemove={() => {
                      removeAllConsecutiveLinkMarks(view, resolvedPos);
                      tippyInstance.hide();
                    }}
                    onHover={() =>
                      hoverTooltipLeaveTimeout &&
                      clearTimeout(hoverTooltipLeaveTimeout)
                    }
                    onConvertToEmbed={
                      canConvertToEmbed
                        ? () => {
                            const parentPos = resolvedPos.before();
                            const parentNode = view.state.doc.nodeAt(parentPos);

                            if (a!.getAttribute('href') && parentNode) {
                              // Replace the link node with an embed node
                              const tr = view.state.tr;
                              tr.replaceWith(
                                parentPos,
                                parentPos + parentNode.nodeSize,
                                view.state.schema.nodes[NODE_EMBED].create({
                                  src: a!.getAttribute('href'),
                                }),
                              );

                              view.dispatch(tr);
                            }

                            tippyInstance.hide();
                          }
                        : undefined
                    }
                  />
                );
              },
              {
                props: {},
                editor,
              },
            );

            const clientRect = JSON.parse(
              JSON.stringify(a.getBoundingClientRect()),
            );

            hoverTooltipEnterTimeout = setTimeout(() => {
              tippyInstance.setProps({
                getReferenceClientRect: () => clientRect,
                content: component.element,
              });
              tippyInstance.show();
            }, 300);
          } else {
            // Hide the tooltip if user is not hovering onto a link anymore, OR
            // if user is not hovering onto the tooltip itself

            if (hoverTooltipEnterTimeout) {
              clearTimeout(hoverTooltipEnterTimeout);
              hoverTooltipEnterTimeout = null;
            }

            // Avoid duplicate hide calls
            if (hoverTooltipLeaveTimeout) {
              return;
            }

            hoverTooltipLeaveTimeout = setTimeout(() => {
              tippyInstance.hide();
            }, 1000);
          }
        }, 50),
      },
    },
  });
};
