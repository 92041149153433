import { useDisclosure } from '@dwarvesf/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Editor } from '@tiptap/react';
import { FormEvent, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { UrlForm } from '../type';

export const useHyperlinkHandlers = (editor: Editor | null) => {
  const [linkText, setLinkText] = useState<string>('');
  const open = useDisclosure();

  const getSelectedText = useCallback(() => {
    if (!editor) {
      return '';
    }
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    const text = state.doc.textBetween(from, to, '');
    return text;
  }, [editor]);

  const onSubmit: SubmitHandler<UrlForm> = useCallback(
    (data: UrlForm) => {
      if (editor) {
        editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: data.url })
          .command(({ editor, tr }) => {
            if (data.text !== linkText && data.text) {
              tr.insertText(data.text);
              editor?.commands.setLink({ href: data.url, target: '_blank' });
            }
            return true;
          })
          .run();
        open.onClose();
      }
    },
    [editor, linkText, open],
  );

  const { handleSubmit, control } = useForm<UrlForm>({
    resolver: yupResolver(
      yup.object().shape({
        url: yup
          .string()
          .trim()
          .required('Url is Required field!')
          .url('Enter valid Link!'),
      }),
    ),
  });

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)(event);
  };

  return {
    getSelectedText,
    setLinkText,
    handleFormSubmit,
    open,
    control,
    linkText,
  };
};
