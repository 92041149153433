import { Box, Grid, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

export const TrendingDetailViewSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
        height: '100%',
      }}
    >
      <Grid container spacing={8} height="100%">
        <Grid item md={6} height="100%">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 3,
              height: '100%',
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                borderRadius: 4,
              }}
              width={theme.spacing(82)}
              height={theme.spacing(140)}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            display="flex"
            flex="2"
            sx={{
              height: '100%',
              minHeight: '100%',
              borderRadius: 4,
              background: `rgba(35, 6, 3, 0.04)`,
              padding: theme.spacing(4, 8),
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Skeleton variant="circular" height={16} width={16} />
              <Skeleton
                variant="text"
                width={theme.spacing(24)}
                height={theme.spacing(5)}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
              }}
            >
              <Skeleton
                variant="rectangular"
                width={theme.spacing(35)}
                height={theme.spacing(7)}
                sx={{
                  borderRadius: 25,
                }}
              />
              <Skeleton variant="rectangular" width="60%" height={30} />
              <Box
                sx={{
                  display: 'grid',
                }}
              >
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="60%" />
              </Box>

              <Skeleton
                variant="text"
                width={theme.spacing(25)}
                sx={{
                  my: 2,
                }}
              />
              {[...Array(3)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    gap: 2,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: theme.colors?.utility[250],
                    borderRadius: theme.spacing(3),
                    p: theme.spacing(4),
                  }}
                >
                  <Box
                    sx={{
                      display: 'grid',
                      gap: 1,
                    }}
                  >
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      gap={2}
                      alignItems="center"
                    >
                      <Skeleton variant="circular" height={18} width={18} />
                      <Skeleton variant="text" width={theme.spacing(30)} />
                      {/* take random number from 1 to 3 */}
                      {[...Array(Math.floor(Math.random() * 3) + 1)].map(
                        (_, index) => (
                          <Box
                            key={index}
                            display="flex"
                            alignItems="center"
                            mx={theme.spacing(0.5)}
                            sx={{
                              backgroundColor: theme.colors?.utility[400],
                              p: theme.spacing(1, 2),
                              borderRadius: theme.spacing(4),
                            }}
                          >
                            <Skeleton
                              variant="rounded"
                              width={
                                Math.floor(Math.random() * (150 - 90 + 1)) + 90
                              }
                              height={theme.spacing(4)}
                            />
                          </Box>
                        ),
                      )}
                    </Box>
                    <Skeleton
                      variant="text"
                      width={theme.spacing(24)}
                      height={theme.spacing(2)}
                    />
                  </Box>

                  <Skeleton variant="rounded" height={16} width={16} />
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          height: '100%',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Skeleton
          variant="rectangular"
          width={theme.spacing(30)}
          height={theme.spacing(7)}
          sx={{
            borderRadius: 25,
            mt: -5,
            flexShrink: 0,
          }}
        />
      </Box>
    </Box>
  );
};
