import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Collapse,
  Divider,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { theme } from 'styles/theme/theme';
import { Tooltip } from 'components/common/Tooltip';

interface Props {
  headerTitle?: string;
  renderHeaderTitle?: (isOpen?: boolean) => React.ReactNode;
  renderHeaderInfo?: () => React.ReactNode;
  headerFullWidth?: boolean;
  children: React.ReactNode;
  defaultIsOpen?: boolean;
  headerTitleClass?: string;
  divider?: boolean;
  sx?: SxProps<Theme>;
  allowToggle?: boolean;
  expandToolTipTitle?: string;
  collapseToolTipTitle?: string;
  onStateUpdated?: (expanded: boolean) => void;
  hideIcon?: boolean;
  iconPosition?: 'left' | 'right';
  iconSize?: number;
  iconColor?: string;
}

export const CollapsibleSection = (props: Props) => {
  const {
    headerTitle,
    renderHeaderInfo,
    headerFullWidth,
    defaultIsOpen = false,
    children,
    headerTitleClass,
    renderHeaderTitle,
    divider = true,
    sx = {},
    allowToggle = true,
    expandToolTipTitle,
    collapseToolTipTitle,
    onStateUpdated,
    hideIcon,
    iconPosition = 'right',
    iconColor,
    iconSize,
  } = props;

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });

  useEffect(() => {
    onStateUpdated?.(isOpen);
  }, [isOpen]); // eslint-disable-line

  return (
    <Box display="flex" flexDirection="column">
      <Box
        component="button"
        type="button"
        display="flex"
        flexDirection={iconPosition === 'left' ? 'row-reverse' : 'row'}
        alignItems="center"
        justifyContent={
          iconPosition === 'left'
            ? 'flex-end'
            : headerFullWidth
            ? 'space-between'
            : undefined
        }
        gap={2}
        onClick={(e) => {
          if (!e.defaultPrevented && allowToggle) {
            onToggle();
          }
        }}
        mb={isOpen ? 1 : 0}
        borderRadius={2}
        sx={{ ...sx }}
      >
        {renderHeaderTitle ? (
          renderHeaderTitle(isOpen)
        ) : (
          <Typography
            variant="headline-xs"
            color={theme.colors?.utility[900]}
            className={headerTitleClass}
          >
            {headerTitle}
          </Typography>
        )}
        <Box display="flex" gap={2} alignItems="center">
          {renderHeaderInfo && (
            <Typography variant="body-md" className="header-info">
              {renderHeaderInfo()}
            </Typography>
          )}
          {!hideIcon && !!allowToggle && (
            <Tooltip
              title={(isOpen ? collapseToolTipTitle : expandToolTipTitle) ?? ''}
              placement="top"
            >
              <IconLinearArrowDown
                onClick={(e) => {
                  e.stopPropagation();
                  onToggle();
                }}
                size={iconSize || 16}
                style={{
                  transform: isOpen ? 'rotate(180deg)' : '',
                  color: iconColor || theme.colors?.utility[1000],
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
      {divider && (
        <Divider
          orientation="horizontal"
          sx={{
            mt: isOpen ? 8 : 4,
            borderBottomColor: theme.colors?.utility[300],
          }}
        />
      )}
    </Box>
  );
};
