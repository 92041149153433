import { OrganizationBillingFragmentGuestPaywallFragment } from 'graphql/generated';
import { SxProps, Typography } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { BillingUpgradeModal } from '../upgradeModal';

type BillingGuestLimitIndicatorProps = {
  organizationBilling: OrganizationBillingFragmentGuestPaywallFragment;
  moreGuestUsage?: number;
  variant?: 'banner' | 'compact' | 'text';
  sx?: SxProps;
  renderNoPaywallHit?: () => ReactNode;
};

export const BillingGuestLimitIndicator = (
  props: BillingGuestLimitIndicatorProps,
) => {
  const {
    organizationBilling,
    moreGuestUsage,
    variant = 'text',
    sx,
    renderNoPaywallHit,
  } = props;

  const guestUsage = useMemo(() => {
    if (moreGuestUsage) {
      return moreGuestUsage + organizationBilling.guestUsage;
    }
    return organizationBilling.guestUsage;
  }, [moreGuestUsage, organizationBilling.guestUsage]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const renderVariant = () => {
    if (variant === 'text') {
      return !organizationBilling.guestLimit ||
        guestUsage < organizationBilling.guestLimit ? (
        renderNoPaywallHit ? (
          <>{renderNoPaywallHit()}</>
        ) : null
      ) : (
        <Typography variant="body-sm" sx={{ ...sx }}>
          <Typography
            variant="body-sm"
            style={{
              fontWeight: 500,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={onOpen}
          >
            {organizationBilling.hasUsedFreeTrial
              ? 'Upgrade Now'
              : 'Start Free Trial'}
          </Typography>{' '}
          to add more than {organizationBilling.guestLimit} guest
          {guestUsage > 1 ? 's' : ''}
        </Typography>
      );
    }

    return null;
  };

  return !organizationBilling.guestLimit || guestUsage === 0 ? (
    renderNoPaywallHit ? (
      <>{renderNoPaywallHit()}</>
    ) : null
  ) : (
    <>
      {renderVariant()}

      <BillingUpgradeModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
