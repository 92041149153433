import { gql } from '@apollo/client';

export const POST_FRAGMENT_URL_METADATA = gql`
  fragment PostFragmentUrlMetadata on PostModel {
    urlMetadata {
      id
      # TODO: update this when features/urlMetadata fragment
      url
      metadata {
        title
        description
        site
        medium
        type
        thumbnail {
          href
        }
      }
    }
  }
`;
