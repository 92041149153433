import MUIAvatarGroup from '@mui/material/AvatarGroup';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Typography, styled } from '@mui/material';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: `0px 3px 12px -2px rgba(0, 0, 0, 0.1)`,
    borderRadium: theme.spacing(2),
    backgroundColor: theme.colors?.primary.white,
    padding: theme.spacing(3),
  },
}));

export const StyledUserName = styled(Typography)(({ theme }) => ({
  color: theme.colors?.utility[900],
  fontWeight: 600,
}));

export const StyledUserEmail = styled(Typography)(({ theme }) => ({
  color: theme.colors?.utility[700],
}));

export const StyledMuiAvatarGroup = styled(MUIAvatarGroup)(({ theme }) => ({
  width: 'fit-content',
}));
