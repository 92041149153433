import { Box, BoxProps, Typography } from '@mui/material';
import {
  BubbleMenu,
  Editor,
  EditorContent,
  EditorContentProps,
  EditorOptions,
  useEditor,
} from '@tiptap/react';
import { UseEditorExtensionsProps, useEditorExtensions } from 'features/tiptap';
import React from 'react';
import { theme } from 'styles/theme/theme';
import { hasContent, parseToHtml } from 'utils/html';
import { ToolBar } from './components';
import { RichTextContainer } from './styles';
import { RichTextEditorArgs } from './type';

export type RichTextValue = {
  html: string;
  text?: string;
};

export type RichTextEditorRef = {
  focus: () => void;
  clear: () => void;
  setDefaultContent: (content: string) => void;
  editor: Editor | null;
};

export type RichTextEditorProps = Omit<
  EditorContentProps,
  'editor' | 'ref' | 'onChange'
> & {
  placeholder?: string;
  placeholderContainerProps?: BoxProps;
  containerProps?: BoxProps;
  editable?: boolean;
  hideToolBar?: boolean;
  enableBubbleMenu?: boolean;
  defaultContent?: string;
  editorContentRef?: React.ForwardedRef<HTMLDivElement>;
  borderless?: boolean;
  editorProps?: EditorOptions['editorProps'];
  useEditorExtensionsProps?: UseEditorExtensionsProps;
  onChange?: (value: RichTextValue) => void;
  onStateChange?: ({ htmlContent, textContent }: RichTextEditorArgs) => void;
};

export const RichTextEditor = React.forwardRef(
  (
    {
      placeholder,
      placeholderContainerProps,
      content,
      defaultContent,
      onChange,
      containerProps,
      editable = true,
      hideToolBar,
      enableBubbleMenu = true,
      onStateChange,
      editorContentRef,
      borderless,
      className,
      autoFocus = false,
      editorProps = {},
      useEditorExtensionsProps,
      ...rest
    }: RichTextEditorProps,
    ref: React.ForwardedRef<RichTextEditorRef>,
  ) => {
    const { extensions } = useEditorExtensions({
      placeholder: {
        placeholder,
      },
      mention: true,
      command: false,
      embed: {
        editable,
      },
      ...useEditorExtensionsProps,
    });

    // Workaround so that we always have the latest version of onChange.
    // Using onChange directly in useEditor will cache the onChange method,
    // resulting in unknown side-effects
    const hasMountedRef = React.useRef(false);
    const onChangeRef = React.useRef(onChange);
    React.useEffect(() => {
      onChangeRef.current = onChange;
    }, [onChange]);

    const editor = useEditor(
      {
        extensions,
        onUpdate({ editor }) {
          const textValue = editor.getText();
          const htmlValue = editor.getHTML();

          // Ignore first update as it's most likely the default content
          if (hasMountedRef.current) {
            onChangeRef.current?.({ html: htmlValue, text: textValue });
          } else {
            hasMountedRef.current = true;
          }

          if (onStateChange) {
            onStateChange({
              htmlContent: htmlValue,
              textContent: textValue,
            });
          }
        },
        editorProps: {
          attributes: {
            class: 'focus:outline-none prose mx-auto',
          },
          ...editorProps,
        },
        content: parseToHtml(defaultContent) ?? parseToHtml(content),
      },
      [content],
    );

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        editor?.commands.focus();
      },
      clear: () => {
        editor?.commands.clearContent();
      },
      setDefaultContent: (content: string) => {
        editor?.commands.setContent(parseToHtml(content));
      },
      editor,
    }));

    React.useEffect(() => {
      if (editor) {
        editor.setEditable(editable);
      }
    }, [editor, editable]); // eslint-disable-line

    React.useEffect(() => {
      if (editor && autoFocus) {
        editor?.commands.focus('end');
      }
    }, [editor, autoFocus]); // eslint-disable-line -- run once on editor mount

    const isEmptyValue = !hasContent(defaultContent || '');

    // TODO: fix tiptap cannot show placeholder when disable
    // ...?

    return (
      <RichTextContainer {...containerProps}>
        {editable && !hideToolBar && <ToolBar editor={editor} />}
        {editable && hideToolBar && editor && enableBubbleMenu && (
          <div>
            <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
              <ToolBar editor={editor} isBubble />
            </BubbleMenu>
          </div>
        )}
        {!editable && isEmptyValue ? (
          <Box {...placeholderContainerProps}>
            <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
              {placeholder}
            </Typography>
          </Box>
        ) : (
          <EditorContent
            {...rest}
            onClick={() => {
              editor?.commands.focus();
              editor?.commands.scrollIntoView();
            }}
            editor={editor}
            ref={editorContentRef}
            style={{ ...rest.style, flex: 1 }}
            multiple
            className={[
              className,
              !borderless && editable ? 'with-border' : '',
            ].join(' ')}
          />
        )}
      </RichTextContainer>
    );
  },
);
