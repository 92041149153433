import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import {
  GeneralPermission,
  PostFragmentPostPermissionSummaryFragment,
  PostInviteMemberFragmentPostPermissionMemberFragmentDoc,
} from 'graphql/generated';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconLinearLock1 } from 'components/icons/components/linear/IconLinearLock1';
import { AvatarGroup } from 'components/common/AvatarGroup';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { theme } from 'styles/theme';

export const POST_FRAGMENT_POST_PERMISSION_SUMMARY = gql`
  fragment PostFragmentPostPermissionSummary on PostModel {
    id
    generalPermission
    organizationName
    inviteMembers {
      ...PostInviteMemberFragmentPostPermissionMember
    }
  }
  ${PostInviteMemberFragmentPostPermissionMemberFragmentDoc}
`;

type PostPermissionSummaryProps = {
  post: PostFragmentPostPermissionSummaryFragment;
  componentProps?: {
    sx?: SxProps;
    generalPermission?: {
      sx?: SxProps;
    };
    inviteMembers?: {
      sx?: SxProps;
    };
  };
};

export const PostPermissionSummary = (props: PostPermissionSummaryProps) => {
  const { post, componentProps } = props;

  const Icon =
    post.generalPermission === GeneralPermission.OrganizationMembers
      ? IconBoldBuildings
      : IconLinearLock1;

  const renderGeneralPermission = () => {
    return (
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{ ...componentProps?.generalPermission?.sx }}
      >
        <Icon
          size={16}
          style={{
            flexShrink: 0,
          }}
        />

        <Typography variant="headline-xxs" sx={componentProps?.sx}>
          {post.generalPermission === GeneralPermission.InviteOnly
            ? 'Private'
            : post.organizationName}
        </Typography>
      </Box>
    );
  };

  const renderInviteMembers = () => {
    return post.inviteMembers.length === 0 ? null : (
      <Box
        display="flex"
        gap={theme.spacing(2)}
        alignItems="center"
        sx={{ ...componentProps?.inviteMembers?.sx }}
      >
        <IconLinearAdd size={16} />
        <AvatarGroup
          users={post.inviteMembers.map((m) => m.user)}
          avatarSize={16}
        />
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      gap={theme.spacing(2)}
      alignItems="center"
      p={theme.spacing(1, 2)}
      borderRadius={theme.spacing(25)}
      sx={{ ...componentProps?.sx }}
    >
      {renderGeneralPermission()} {renderInviteMembers()}
    </Box>
  );
};
