import { DetailedSentiment, FollowersScale } from 'graphql/generated';

export const socialPostNotificationConfigFollowersScaleOptions = [
  {
    label: '1 - 10K followers',
    value: FollowersScale.Nano,
  },
  {
    label: '10K - 100K followers',
    value: FollowersScale.Micro,
  },
  {
    label: '100K - 1M followers',
    value: FollowersScale.Mega,
  },
  {
    label: '1M+ followers',
    value: FollowersScale.Macro,
  },
];

export const socialPostNotificationConfigRelevancyOptions = [
  {
    label: 'Above 70%',
    value: 0.7,
  },
  {
    label: 'Above 80%',
    value: 0.8,
  },
  {
    label: 'Above 90%',
    value: 0.9,
  },
  {
    label: '100%',
    value: 1,
  },
];

export const socialPostNotificationConfigSentimentOptions = [
  {
    label: 'Positive',
    value: DetailedSentiment.Positive,
  },
  {
    label: 'Slightly Positive',
    value: DetailedSentiment.SlighlyPositive,
  },
  {
    label: 'Neutral',
    value: DetailedSentiment.Neutral,
  },
  {
    label: 'Slightly Negative',
    value: DetailedSentiment.SlightlyNegative,
  },
  {
    label: 'Negative',
    value: DetailedSentiment.Negative,
  },
];
