import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PermissionMemberItem, PermissionSelector } from 'features/permission';
import { USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW } from 'features/user-profile';
import { PostInviteMemberFragmentPostPermissionMemberFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const POST_INVITE_MEMBER_FRAGMENT_POST_PERMISSION_MEMBER = gql`
  fragment PostInviteMemberFragmentPostPermissionMember on PostInviteMemberModel {
    id
    lastSeen
    permissionLevel
    user {
      ...UserProfileFragmentUserProfilePermissionItemView
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW}
`;

type PostPermissionMemberProps = {
  entityOrganizationId: string;
  postInviteMember: PostInviteMemberFragmentPostPermissionMemberFragment;
  onPermissionChange: (
    postInviteMember: PostInviteMemberFragmentPostPermissionMemberFragment,
    remove?: boolean,
  ) => void;
  readonly?: boolean;
};

export const PostPermissionMember = (props: PostPermissionMemberProps) => {
  const {
    entityOrganizationId,
    postInviteMember,
    onPermissionChange,
    readonly,
  } = props;

  return (
    <PermissionMemberItem
      userProfile={postInviteMember.user}
      entityOrganizationId={entityOrganizationId}
      type="custom"
      endAdornmentProps={{
        custom: {
          endAdornment: (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: theme.colors?.primary.black,
              }}
            >
              <PermissionSelector
                entityOrganizationId={entityOrganizationId}
                userOrganizationId={postInviteMember.user.organization.id}
                isDisabledUser={!!postInviteMember.user.isDisabled}
                readonly={readonly}
                initialValue={postInviteMember.permissionLevel}
                onPermissionChange={(permission) => {
                  if (permission !== postInviteMember.permissionLevel) {
                    onPermissionChange({
                      ...postInviteMember,
                      permissionLevel: permission,
                    });
                  }
                }}
                selectPaperSx={{
                  backgroundColor: 'rgba(250, 243, 236, 1);',
                }}
                showRemove
                onRemove={() => {
                  onPermissionChange(postInviteMember, true);
                }}
              />
            </Box>
          ),
        },
      }}
    />
  );
};
