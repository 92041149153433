import { useUserContext } from 'contexts/users/User.context';
import { ReactRenderer } from '@tiptap/react';
import tippy, {
  GetReferenceClientRect,
  Instance as TippyInstance,
} from 'tippy.js';
import { useState } from 'react';
import { useSearchHandlers } from 'features/search';
import { SearchableEntityType } from 'graphql/generated';
import { MentionList, MentionListRef } from './MentionList';

export type UseMentionSuggestionProps = {
  onMention?: (entityType: SearchableEntityType, entity: unknown) => void;
};

export const useMentionSuggestion = (props: UseMentionSuggestionProps) => {
  const { onMention } = props;

  const { user } = useUserContext();

  const [loading, setLoading] = useState(false);

  // this allows key Enter to revert to default when Mention is not active
  const [disableSubmit, setDisableSubmit] = useState(false);

  const { onSearch } = useSearchHandlers([SearchableEntityType.Users]);

  const suggestion = {
    char: '@',
    allowSpaces: true,
    items: async ({ query }) => {
      setLoading(true);
      if (!query) {
        return [
          ...(user?.organization.users || []),
          ...(user?.organization.externalUsers || []),
        ];
      }

      const searchRes = await onSearch(query);
      setLoading(false);

      return searchRes?.data?.searchEntities.userSearchHits ?? [];
    },
    render: () => {
      let component: ReactRenderer<any> | undefined;
      let popup: TippyInstance | undefined;

      let lastKeyDown = '';

      return {
        onBeforeStart: () => {
          setDisableSubmit(false);
        },
        onStart: (props) => {
          let componentRef: MentionListRef | null = null;

          component = new ReactRenderer(
            (props) => (
              <MentionList
                {...props}
                ref={(ref) => {
                  componentRef = ref;
                }}
                loading={loading}
                onMention={onMention}
                disableSubmit={disableSubmit}
              />
            ),
            {
              props,
              editor: props?.editor,
            },
          );

          // Need to set ref redirectly like this because previously, setting ref when rendering the component
          // is not reliable (setting ref directly in `ref` props of `MentionList`)
          component.ref = componentRef;

          if (!props.clientRect) {
            return;
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })[0];
        },

        onUpdate(props) {
          component?.updateProps(props);
          popup?.setProps({
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          });
        },

        onKeyDown(props) {
          const isDoubleSpace = props.event.key === ' ' && lastKeyDown === ' ';

          if (isDoubleSpace || props.event.key === 'Escape') {
            popup?.hide();
            popup = undefined;
            component = undefined;
            props.event.preventDefault();
            setDisableSubmit(true);
            return true;
          }

          lastKeyDown = props.event.key;

          if (!component?.ref) {
            return false;
          }

          return component?.ref?.onKeyDown(props);
        },

        onExit() {
          popup?.destroy();
          component?.destroy();

          // Remove references to the old popup and component upon destruction/exit.
          // (This should prevent redundant calls to `popup.destroy()`, which Tippy
          // warns in the console is a sign of a memory leak, as the `suggestion`
          // plugin seems to call `onExit` both when a suggestion menu is closed after
          // a user chooses an option, *and* when the editor itself is destroyed.)
          popup = undefined;
          component = undefined;
        },
      };
    },
  };

  return {
    suggestion,
  };
};
