import { Box } from '@mui/material';

export const ImageRenderer = (props: { src: string }) => {
  const { src } = props;

  return (
    <Box
      component="img"
      src={src}
      sx={{
        width: '100%',
      }}
    />
  );
};
