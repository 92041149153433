import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SLABrandInboundTopPostsViewSkeleton = () => {
  return (
    <Box
      display="flex"
      p={8}
      sx={{
        height: '100%',
        width: '100%',
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          minHeight: 580,
          height: 100,
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          mb={4}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Top posts
          </Typography>

          <Skeleton
            sx={{
              width: theme.spacing(20),
              height: theme.spacing(4),
            }}
          />
        </Box>

        <Grid container spacing={2} columns={20}>
          {new Array(5).fill(0).map(() => (
            <Grid
              item
              xs={10}
              md={5}
              lg={4}
              sx={{
                height: theme.spacing(65),
              }}
            >
              <Skeleton
                sx={{
                  display: 'block',
                  width: '100%',
                  transform: 'none',
                  minHeight: theme.spacing(65),
                  borderRadius: theme.spacing(2),
                  backgroundColor: theme.colors?.utility[275],
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
