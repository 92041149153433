/**
 * Ref:
 * - https://github.com/ueberdosis/tiptap/blob/develop/packages/core/src/commands/setMark.ts#L97
 */

import { noop } from '@dwarvesf/react-utils';
import { Box, Typography } from '@mui/material';
import { Node } from '@tiptap/pm/model';
import { Plugin } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import {
  getMarkType,
  Mark,
  mergeAttributes,
  ReactRenderer,
} from '@tiptap/react';
import { IconCustomMessage } from 'components/icons/components/custom/IconCustomMessage';
import { GenerateId } from 'utils/generateId';
import { theme } from 'styles/theme';
import { ATTR_NOTE_COMMENT_ANCHOR_ID, MARK_COMMENT } from '../constants';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      /**
       * Add a comment to the selection
       */
      addComment: () => ReturnType;
    };
  }
}

export type CommentExtensionOptions = {
  onTriggerAddComment?: (props: {
    noteCommentAnchorId: string;
    onCancelAddComment?: () => void;
    onFinishAddComment?: () => void;
  }) => void;
  onClick?: (noteCommentAnchorId: string) => void;
};

export const Comment = Mark.create<CommentExtensionOptions>({
  name: MARK_COMMENT,

  addOptions() {
    return {
      onTriggerAddComment: noop,
      onClick: noop,
    };
  },

  addAttributes() {
    return {
      [ATTR_NOTE_COMMENT_ANCHOR_ID]: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute(ATTR_NOTE_COMMENT_ANCHOR_ID);
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-withcomment="true"]',
        priority: 100,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const noteCommentAnchorId =
      HTMLAttributes[ATTR_NOTE_COMMENT_ANCHOR_ID] || '';
    const commentThreadCount = noteCommentAnchorId.split('|').length;

    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-withcomment': true,
        class: `comment-thread-count-${commentThreadCount}`,
      }),
      0,
    ];
  },

  // @ts-ignore
  addCommands() {
    return {
      addComment: () => (view) => {
        const { editor, state, tr } = view;
        const { doc, selection } = state;
        const { ranges } = selection;

        const type = getMarkType(MARK_COMMENT, state.schema);
        const noteCommentAnchorId = GenerateId.create();

        ranges.forEach((range) => {
          const from = range.$from.pos;
          const to = range.$to.pos;

          doc.nodesBetween(selection.from, selection.to, (node, pos) => {
            // Skip inline nodes
            if (!node.isInline) {
              return true;
            }

            // Skip hard-break nodes
            if (node.type.name === 'hardBreak') {
              return true;
            }

            const trimmedFrom = Math.max(pos, from);
            const trimmedTo = Math.min(pos + node.nodeSize, to);
            const someHasMark = node.marks.find(
              (mark) => mark.type.name === MARK_COMMENT,
            );

            // if there is already a mark of this type
            // we know that we have to merge its attributes
            // otherwise we add a fresh new mark
            if (someHasMark) {
              node.marks.forEach((mark) => {
                if (mark.type === type) {
                  tr.addMark(
                    trimmedFrom,
                    trimmedTo,
                    type.create({
                      ...mark.attrs,
                      [ATTR_NOTE_COMMENT_ANCHOR_ID]: `${mark.attrs[ATTR_NOTE_COMMENT_ANCHOR_ID]}|${noteCommentAnchorId}`,
                    }),
                  );
                }
              });
            } else {
              tr.addMark(
                trimmedFrom,
                trimmedTo,
                type.create({
                  [ATTR_NOTE_COMMENT_ANCHOR_ID]: noteCommentAnchorId,
                }),
              );
            }
          });
        });

        // Use setTimeout as a hack to push the execution of onTriggerAddComment to the end of the event loop,
        // because we are depending on the DOM to be updated with the new anchorId
        setTimeout(() => {
          this.options.onTriggerAddComment?.({
            noteCommentAnchorId,
            onCancelAddComment: () => {
              // On cancel, we should remove the comment node,
              // Use editor chain command because commands.unsetMark will not work
              editor.chain().undo().run();
            },
          });
        });

        return false;
      },
    };
  },

  addProseMirrorPlugins() {
    const editor = this.editor;

    return [
      // This plugin is used to handle click events on comment marks
      new Plugin({
        props: {
          handleClick: (view, pos, event) => {
            if (event.button !== 0) {
              return false;
            }

            // Check if event target is a comment mark
            if (
              event.target instanceof HTMLElement &&
              event.target.getAttribute('data-withcomment') === 'true'
            ) {
              const noteCommentAnchorId = event.target.getAttribute(
                ATTR_NOTE_COMMENT_ANCHOR_ID,
              );

              if (noteCommentAnchorId) {
                this.options.onClick?.(noteCommentAnchorId);
              }

              return true;
            }

            return false;
          },
        },
      }),
      // This plugin adds a set of decorations to show how many comments there are in the nearest block node
      // (e.g. paragraph, heading, etc.)
      new Plugin({
        props: {
          decorations: ({ doc }) => {
            const decorations: any[] = [];

            // Keep track of the anchor ids that we have already counted
            const countedNoteCommentAnchorIds: string[] = [];

            doc.descendants((node, pos) => {
              if (node.isBlock) {
                // Find all note comment anchor ids in the descendant nodes
                const noteCommentAnchorIds: string[] = [];

                const traverseNode = (node: Node) => {
                  const commentMark = node.marks.find(
                    (mark) => mark.type.name === MARK_COMMENT,
                  );

                  if (commentMark) {
                    noteCommentAnchorIds.push(
                      ...((
                        commentMark.attrs[ATTR_NOTE_COMMENT_ANCHOR_ID] || ''
                      ).split('|') || []),
                    );
                  }

                  node.descendants((child) => {
                    traverseNode(child);
                  });
                };

                traverseNode(node);

                const dedupedNoteCommentAnchorIds = Array.from(
                  new Set(noteCommentAnchorIds),
                ).filter((id) => !countedNoteCommentAnchorIds.includes(id));

                if (dedupedNoteCommentAnchorIds.length) {
                  decorations.push(
                    Decoration.widget(pos + 1, () => {
                      return new ReactRenderer(
                        () => {
                          const combinedAttr = `btn-${dedupedNoteCommentAnchorIds.join(
                            '|',
                          )}`;

                          return (
                            <Box
                              component="button"
                              sx={{
                                display: 'flex',
                                gap: 1,
                                position: 'absolute',
                                top: 0,
                                right: -40,
                                color: 'inherit',
                                p: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                  bgcolor: 'rgba(0, 0, 0, 0.1)',
                                },
                                borderRadius: 1,
                              }}
                              // This will make this button a find-able anchor element so that
                              // we can show the comment threads popover when the user clicks on it
                              data-notecommentanchorid={combinedAttr}
                              onClick={() => {
                                this.options.onClick?.(combinedAttr);
                              }}
                            >
                              <IconCustomMessage size={16} />
                              <Typography
                                variant="subhead-lg"
                                width={theme.spacing(2)}
                              >
                                {dedupedNoteCommentAnchorIds.length}
                              </Typography>
                            </Box>
                          );
                        },
                        {
                          props: {},
                          editor,
                        },
                      ).element;
                    }),
                  );

                  countedNoteCommentAnchorIds.push(
                    ...dedupedNoteCommentAnchorIds,
                  );
                }

                // Stop traversing the descendants
                return false;
              }
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
