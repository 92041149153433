import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { Image } from 'components/common/Image';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconCustomMobile } from 'components/icons/components/custom/IconCustomMobile';
import { MOBILE_APP_APP_STORE_URL } from 'components/layouts/LeftDrawer/components';
import { useUserContext } from 'contexts/users/User.context';
import { postMessageToMobileApp } from 'features/billing/utils';
import { BillingPlanName } from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { theme } from 'styles/theme';
import { useBillingPlanData } from '../../hooks';

type BillingFreeTrialSuccessModalProps = {
  isOpen: boolean;
};

export const BillingFreeTrialSuccessModal = (
  props: BillingFreeTrialSuccessModalProps,
) => {
  const { isOpen } = props;

  const isMobileView = useMediaQueryMobile();

  const { isMobileAppWebView, isEnterpriseOrganization } = useUserContext();
  const { numberOfTrialDays } = useBillingPlanData(
    isEnterpriseOrganization
      ? BillingPlanName.Enterprise
      : BillingPlanName.Standard,
  );

  const handleRefresh = () => {
    if (isMobileAppWebView) {
      postMessageToMobileApp({ message: 'backToMobileApp' });
      return;
    }
    window.location.reload();
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          m: 0,
          p: 10,
          bgcolor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          maxWidth: '100%',
          width: 800,
          height: 800,
          color: theme.colors?.primary.white,
          [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            p: 0,
            overflow: 'hidden',
            color: theme.colors?.primary.black,
            borderRadius: 0,
          },
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          [theme.breakpoints.down('sm')]: {
            overflowY: 'auto',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: theme.spacing(12),
            width: '100%',
            position: 'relative',
            bgcolor: theme.colors?.utility['orange-2'],
            borderRadius: 6,
            boxShadow:
              '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
            [theme.breakpoints.down('sm')]: {
              borderRadius: 0,
              height: '100%',
            },
          }}
        >
          <Typography
            sx={{
              fontSize: theme.spacing(18),
              position: 'absolute',
              top: '-53px',
              left: '19%',
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(14),
                top: '20%',
                left: '80%',
              },
            }}
          >
            🫐
          </Typography>
          <Typography
            sx={{
              fontSize: theme.spacing(18),
              position: 'absolute',
              bottom: '-49px',
              left: '12%',
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(14),
                top: '55%',
                bottom: 'unset',
                left: '4%',
              },
            }}
          >
            🍉
          </Typography>
          <Typography
            sx={{
              fontSize: theme.spacing(18),
              position: 'absolute',
              bottom: '60px',
              right: '-4%',
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.spacing(14),
                top: '10%',
                left: '30%',
                bottom: 'unset',
                right: 'unset',
              },
            }}
          >
            🍋
          </Typography>
          <Box
            sx={{
              width: 220,
              mt: -24,
              mb: 8,
              borderRadius: theme.spacing(3),
              overflow: 'hidden',
              boxShadow:
                '0px 10px 32px -4px #18274B1A, 0px 6px 14px -6px #18274B1F',
              aspectRatio: '4 / 5',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                mt: 0,
              },
            }}
          >
            <Image
              src="/gif/cake-megan_1.gif"
              sx={{
                display: 'block',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
            />
          </Box>

          <Typography
            variant="headline-xl"
            sx={{
              mb: 4,
              [theme.breakpoints.down('sm')]: {
                fontSize: 28,
                fontWeight: '500',
                zIndex: 2,
              },
            }}
          >
            Supercharged!
          </Typography>

          <Typography
            variant="headline-md"
            color={theme.colors?.primary.white}
            textAlign="center"
          >
            Enjoy your free {numberOfTrialDays} day trial. Happy Plotting.
          </Typography>

          {isMobileView ? (
            <Button
              variant="primary-alt"
              startIcon={<IconCustomMobile size={20} />}
              onClick={() => {
                if (isMobileAppWebView) {
                  postMessageToMobileApp({ message: 'backToMobileApp' });
                  return;
                }
                window.open(MOBILE_APP_APP_STORE_URL, '_blank');
              }}
              sx={{
                mt: 8,
                mb: 3,
                py: 3,
              }}
              fullWidth
            >
              {isMobileAppWebView
                ? 'Back to the mobile app'
                : 'Download the mobile app'}
            </Button>
          ) : (
            <Box
              sx={{
                my: 8,
                px: 6,
                py: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 3,
                bgcolor: '#0000000D',
                borderRadius: 3,
                color: theme.colors?.primary.black,
              }}
            >
              <Box
                component="img"
                alt="Download mobile app QR"
                src="/images/download-mobile-app-qr.png"
                sx={{ width: 64, height: 64 }}
              />
              <Typography variant="headline-md" fontSize={20}>
                Scan to download the app
              </Typography>
            </Box>
          )}

          {!isMobileAppWebView && (
            <Box component="button" type="button" onClick={handleRefresh}>
              <Typography
                variant="headline-sm"
                sx={{
                  textDecoration: 'underline',
                  color: theme.colors?.primary.white,
                  [theme.breakpoints.down('sm')]: {
                    color: theme.colors?.primary.black,
                  },
                }}
              >
                Back to Plot
              </Typography>
            </Box>
          )}

          {isMobileView && !isMobileAppWebView && (
            <IconButton
              onClick={handleRefresh}
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
              }}
            >
              <IconBoldCloseCircle />
            </IconButton>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
