import { gql } from '@apollo/client';
import { IconBoldCalendar1 } from 'components/icons/components/bold/IconBoldCalendar1';
import { CollectionFragmentCollectionSavedToContentCalendarFragment } from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionSavedToContentCalendar on CollectionModel {
    id
    breadcrumbsFromRoot {
      id
      contentIdeaId
    }
  }
`;
interface Props {
  collection?: CollectionFragmentCollectionSavedToContentCalendarFragment;
  componentsProps?: {
    icon?: {
      size?: number;
      color?: string;
    };
  };
}

export const useIsCollectionSavedToContentCalendar = ({
  collection,
  componentsProps = {},
}: Props) => {
  const savedToContentIdeaId = useMemo(() => {
    return collection?.breadcrumbsFromRoot?.find(
      (item) => item.contentIdeaId !== null,
    )?.contentIdeaId;
  }, [collection?.breadcrumbsFromRoot]);

  return {
    savedToContentCalendar: !!savedToContentIdeaId,
    contentIdeaId: savedToContentIdeaId,
    calendarIcon: savedToContentIdeaId ? (
      <IconBoldCalendar1
        size={16}
        color={theme.colors?.primary.black}
        style={{
          marginTop: theme.spacing(0.5),
          flexShrink: 0,
        }}
        {...componentsProps.icon}
      />
    ) : null,
  };
};
