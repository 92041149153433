import { gql } from '@apollo/client';
import { Avatar, Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { useUserContext } from 'contexts/users/User.context';
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandFragmentForUseBrandSelector on BrandModel {
    id
    name
    generalPermission
    permissionLevel
    competitorAnalysisEnabled
    organizationId
    creators {
      id
      handle
      profilePictureUrl
    }
    inviteMembers {
      id
      permissionLevel
      user {
        id
        email
      }
    }
  }
`;

export const useSocialListeningBrandSelector = () => {
  const [currentBrandId, setCurrentBrandId] = useState<string>('');
  const { user } = useUserContext();

  const slBrands = useMemo(() => user?.socialListeningBrands || [], [user]);

  const { value, setValue } = useSessionStorage(
    'CURRENT_SELECTED_BRAND',
    currentBrandId,
  );

  const handleBrandSelect = (brandId: string) => {
    setValue(brandId);
    setCurrentBrandId(brandId);
  };

  useEffect(() => {
    if (!slBrands.length) {
      return;
    }

    if (!value || !slBrands.find((brand) => brand.id === value)) {
      handleBrandSelect(slBrands[0]?.id || '');
    } else {
      handleBrandSelect(value);
    }
  }, [slBrands, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const BrandSelectorComponent = () => {
    const currentBrand = useMemo(
      () =>
        user?.socialListeningBrands.find(
          (brand) => brand.id === currentBrandId,
        ) || user?.socialListeningBrands?.[0],
      [user?.socialListeningBrands, currentBrandId], // eslint-disable-line react-hooks/exhaustive-deps
    );

    if (!currentBrand) {
      return null;
    }

    return (
      <Box>
        <ContextMenu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          renderButton={() => (
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              sx={{
                cursor: 'pointer',
                backgroundColor: theme.colors?.utility[300],
                borderRadius: theme.spacing(6),
                padding: theme.spacing(2, 4),
              }}
            >
              <Avatar
                sx={{
                  height: 24,
                  width: 24,
                }}
                src={
                  currentBrand.creators[0]?.profilePictureUrl ||
                  currentBrand.creators[1]?.handle ||
                  ''
                }
              />
              <Typography
                variant="body-xl"
                color={theme.colors?.primary.black}
                sx={{
                  fontWeight: 600,
                }}
              >
                {currentBrand.name}
              </Typography>
              <IconLinearArrowDown size={16} />
            </Box>
          )}
          options={(user?.socialListeningBrands || []).map((brand) => ({
            renderOption: () => (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{
                  flex: 1,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleBrandSelect(brand.id);
                }}
              >
                <Avatar
                  sx={{
                    height: 24,
                    width: 24,
                  }}
                  src={
                    brand.creators[0]?.profilePictureUrl ||
                    brand.creators[1]?.handle ||
                    ''
                  }
                />
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[800]}
                  fontWeight={600}
                >
                  {brand.name}
                </Typography>
              </Box>
            ),
            onClick: () => {
              handleBrandSelect(brand.id);
            },
          }))}
        />
      </Box>
    );
  };

  return {
    currentBrandId,
    setCurrentBrandId,
    handleBrandSelect,
    renderBrandSelector: BrandSelectorComponent,
  };
};
