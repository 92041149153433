import { gql } from '@apollo/client';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  CustomStackedBarChart,
  CustomStackedBarChartDataPoint,
  colors as chartColors,
} from 'components/common/CustomStackedBarChart';
import { typography } from 'components/common/Typography/styles';
import {
  TopicLabel,
  TopicMoreContextMenu,
  TopicPauseContextMenu,
} from 'features/topic/components';
import {
  TopicFragmentTopicBreakdownTableViewFragment,
  TopicFragmentTopicLabelFragmentDoc,
  TopicFragmentTopicMoreContextMenuFragmentDoc,
  TopicStatus,
  useGetAggregatedTopicDurationUsageForTopicBreakdownTableViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

export const TOPIC_FRAGMENT_TOPIC_BREAKDOWN_TABLE_VIEW = gql`
  fragment TopicFragmentTopicBreakdownTableView on TopicModel {
    id
    name
    status
    resetOnNextCycle
    type
  }
`;

// eslint-disable-next-line
gql`
  query GetAggregatedTopicDurationUsageForTopicBreakdownTableView(
    $filters: AggregatedTopicDurationUsageFilters!
    $brandId: String!
  ) {
    aggregatedTopicDurationUsage(filters: $filters, brandId: $brandId) {
      topicId
      topic {
        id
        ...TopicFragmentTopicBreakdownTableView
        ...TopicFragmentTopicMoreContextMenu
        ...TopicFragmentTopicLabel
      }
      durationUsed
      totalParsedPostsCount
      totalPositivelyParsedPostsCount
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_BREAKDOWN_TABLE_VIEW}
  ${TopicFragmentTopicMoreContextMenuFragmentDoc}
  ${TopicFragmentTopicLabelFragmentDoc}
`;

export type TopicBreakdownTableViewProps = {
  brandId: string;
};

export const TopicBreakdownTableView = (
  props: TopicBreakdownTableViewProps,
) => {
  const { brandId } = props;

  const { data: aggregatedTopicDurationUsageData } =
    useGetAggregatedTopicDurationUsageForTopicBreakdownTableViewQuery({
      variables: {
        brandId,
        filters: {
          // By default, we show the current month's data
          dateRange: {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
          },
        },
      },
    });
  const aggregatedTopicDurationUsage = useMemo(
    () => aggregatedTopicDurationUsageData?.aggregatedTopicDurationUsage ?? [],
    [aggregatedTopicDurationUsageData],
  );

  const [highlightedChartDataPointIndex, setHighlightedChartDataPointIndex] =
    useState<number | null>(null);
  const { chartData, totalHours, totalPostsParsed, totalPositivelyMatched } =
    useMemo(() => {
      const totalHours =
        aggregatedTopicDurationUsage.reduce(
          (acc, usage) => acc + usage.durationUsed,
          0,
        ) / 3600; // in hour

      const totalPostsParsed = aggregatedTopicDurationUsage.reduce(
        (acc, usage) => acc + usage.totalParsedPostsCount,
        0,
      );
      const totalPositivelyMatched = aggregatedTopicDurationUsage.reduce(
        (acc, usage) => acc + usage.totalPositivelyParsedPostsCount,
        0,
      );

      return {
        chartData: [
          ...aggregatedTopicDurationUsage.map((usage, index) => {
            return {
              label: usage.topic.name,
              value: usage.durationUsed / 3600,
              item: usage.topic,
              color: chartColors[index % chartColors.length],
            };
          }),
        ] as CustomStackedBarChartDataPoint<TopicFragmentTopicBreakdownTableViewFragment>[],
        totalHours,
        totalPostsParsed,
        totalPositivelyMatched,
      };
    }, [aggregatedTopicDurationUsage]);

  if (!aggregatedTopicDurationUsageData) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="subhead-xl"
        mb={3}
        color={theme.colors?.utility[900]}
      >
        Total hours parsed:{' '}
        <Box component="b" sx={{ color: theme.colors?.primary.black }}>
          {totalHours.toFixed(1)}
        </Box>
      </Typography>
      <Box
        sx={{
          maxWidth: 360,
          mb: 4,
        }}
      >
        <CustomStackedBarChart
          data={chartData}
          highlightedIndex={highlightedChartDataPointIndex ?? undefined}
        />
      </Box>
      <Table
        sx={{
          'th, td': {
            borderBottomColor: theme.colors?.utility[300],
            p: 2.5,
            color: theme.colors?.utility[800],
          },
        }}
      >
        <TableHead>
          <TableRow>
            {[
              {
                key: 'name',
                label: 'Topic Name',
              },
              {
                key: 'totalHours',
                label: 'Total hours',
              },
              {
                key: 'totalPostsParsed',
                label: 'Total posts parsed',
              },
              {
                key: 'positivelyMatched',
                label: 'Positively matched',
              },
              {
                key: 'actions',
                label: '',
              },
            ].map((column) => {
              return (
                <TableCell
                  key={column.key}
                  sx={{
                    ...typography['headline-xs'],
                    color: theme.colors?.utility[700],
                  }}
                >
                  {column.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Order by: active -> paused -> deleted */}
          {[
            ...aggregatedTopicDurationUsage.filter(
              (i) => i.topic.status === TopicStatus.Active,
            ),
            ...aggregatedTopicDurationUsage.filter(
              (i) => i.topic.status === TopicStatus.Paused,
            ),
            ...aggregatedTopicDurationUsage.filter(
              (i) => i.topic.status === TopicStatus.Deleted,
            ),
          ].map((usage, index) => {
            const isTopicPaused = usage.topic.status === TopicStatus.Paused;
            const isTopicDeleted = usage.topic.status === TopicStatus.Deleted;

            return (
              <TableRow
                key={usage.topic.id}
                sx={{
                  ':hover': {
                    background: `color-mix(in srgb, ${chartData[index].color} 20%, transparent)`,

                    '.actions': {
                      opacity: 1,
                    },
                  },
                }}
                onMouseEnter={() => setHighlightedChartDataPointIndex(index)}
                onMouseLeave={() => setHighlightedChartDataPointIndex(null)}
              >
                <TableCell>
                  <Typography
                    variant="subhead-xl"
                    color={theme.colors?.primary.black}
                  >
                    {usage.topic.name}{' '}
                  </Typography>
                  <TopicLabel topic={usage.topic} />
                  <Typography
                    variant="subhead-xl"
                    color={theme.colors?.utility[700]}
                  >
                    {isTopicPaused &&
                      ` • Paused ${
                        usage.topic.resetOnNextCycle
                          ? `till ${moment()
                              .add(1, 'month')
                              .startOf('month')
                              .format('MM-DD-YYYY')}`
                          : 'manually'
                      }`}
                    {isTopicDeleted && ' • Deleted'}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subhead-xl">
                    {(usage.durationUsed / 3600).toFixed(1)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subhead-xl">
                    {usage.totalParsedPostsCount}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subhead-xl">
                    {usage.totalPositivelyParsedPostsCount}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    className="actions"
                    sx={{
                      opacity: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <TopicPauseContextMenu topic={usage.topic} disablePortal />
                    <TopicMoreContextMenu topic={usage.topic} disablePortal />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell>
              <Typography
                variant="subhead-xl"
                fontWeight={600}
                color={theme.colors?.primary.black}
              >
                Total
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subhead-xl">
                {totalHours.toFixed(1)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subhead-xl">{totalPostsParsed}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subhead-xl">
                {totalPositivelyMatched}
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
