import { gql } from '@apollo/client';
import { createContext } from '@dwarvesf/react-utils';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import {
  CollectionFragmentJuiceboxSelectContextFragment,
  CollectionFragmentJuiceboxSelectContextFragmentDoc,
  CollectionFragmentSelectModeFragmentDoc,
  PostFragmentJuiceboxSelectContextFragment,
  PostFragmentJuiceboxSelectContextFragmentDoc,
  PostFragmentSelectModeFragmentDoc,
  useGetCollectionByIdsForJuiceboxSelectContextLazyQuery,
  useGetPostByIdsForJuiceboxSelectContextLazyQuery,
} from 'graphql/generated';
import { ReactNode, useEffect, useState } from 'react';
import { useSelectMode } from '../../hooks/useSelectMode';

// eslint-disable-next-line
gql`
  fragment CollectionFragmentJuiceboxSelectContext on CollectionModel {
    id
    isPinnedToParent
    isPinnedToRoot
    ...CollectionFragmentSelectMode
  }
  ${CollectionFragmentSelectModeFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment PostFragmentJuiceboxSelectContext on PostModel {
    id
    ...PostFragmentSelectMode
    pinnedToCollections {
      id
      parentCollectionId
    }
    isFavorite
    urlMetadata {
      id
      url
      metadata {
        type
        site
        medias {
          type
          url
        }
      }
    }
  }
  ${PostFragmentSelectModeFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetPostByIdsForJuiceboxSelectContext($input: GetPostByIdsInput!) {
    postByIds(input: $input) {
      ...PostFragmentJuiceboxSelectContext
    }
  }
  ${PostFragmentJuiceboxSelectContextFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCollectionByIdsForJuiceboxSelectContext(
    $input: GetCollectionByIdsInput!
  ) {
    collectionByIds(input: $input) {
      ...CollectionFragmentJuiceboxSelectContext
    }
  }
  ${CollectionFragmentJuiceboxSelectContextFragmentDoc}
`;

export type JuiceboxSelectContextValues = {
  /**
   * Target collections that would be counted in select mode.
   * These will be used for internal logic of useSelectMode hook.
   */
  collections: CollectionFragmentJuiceboxSelectContextFragment[];
  addCollections: (
    collections: CollectionFragmentJuiceboxSelectContextFragment[],
  ) => void;
  /**
   * Target posts that would be counted in select mode.
   * These will be used for internal logic of useSelectMode hook.
   */
  posts: PostFragmentJuiceboxSelectContextFragment[];
  addPosts: (posts: PostFragmentJuiceboxSelectContextFragment[]) => void;

  /**
   * Get selected posts or collections from server
   */
  getSelectedPosts: () => Promise<PostFragmentJuiceboxSelectContextFragment[]>;
  getSelectedCollections: () => Promise<
    CollectionFragmentJuiceboxSelectContextFragment[]
  >;
} & ReturnType<typeof useSelectMode>;

const [Provider, _useJuiceboxSelectContext] =
  createContext<JuiceboxSelectContextValues>();

export const JuiceboxSelectContextProvider = (props: {
  children: ReactNode;
}) => {
  const { children } = props;

  const { collectionId = '' } = useCollectionIdFromParams();

  const [collections, setCollections] = useState<
    CollectionFragmentJuiceboxSelectContextFragment[]
  >([]);
  const [posts, setPosts] = useState<
    PostFragmentJuiceboxSelectContextFragment[]
  >([]);

  const useSelectModeValues = useSelectMode({
    collections,
    posts,
  });

  const [getCollectionByIds] =
    useGetCollectionByIdsForJuiceboxSelectContextLazyQuery({
      fetchPolicy: 'cache-and-network',
      returnPartialData: true,
      partialRefetch: true,
    });
  const [getPostByIds] = useGetPostByIdsForJuiceboxSelectContextLazyQuery({
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
    partialRefetch: true,
  });

  const addCollections = (
    newCollections: CollectionFragmentJuiceboxSelectContextFragment[],
  ) => {
    setCollections(
      [...newCollections, ...collections].filter(
        (collection, index, self) =>
          self.findIndex((c) => c.id === collection.id) === index,
      ),
    );
  };

  const addPosts = (newPosts: PostFragmentJuiceboxSelectContextFragment[]) => {
    setPosts(
      [...newPosts, ...posts].filter(
        (post, index, self) =>
          self.findIndex((p) => p.id === post.id) === index,
      ),
    );
  };

  useEffect(() => {
    // Reset collections and posts when collectionId changes
    useSelectModeValues.exitSelectMode();
    setCollections([]);
    setPosts([]);
  }, [collectionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSelectedPosts = async () => {
    const { data } = await getPostByIds({
      variables: {
        input: {
          postIds: useSelectModeValues.selectedPostIds,
        },
      },
      onCompleted: (data) => {
        addPosts(data.postByIds);
      },
    });

    return data?.postByIds || [];
  };

  const getSelectedCollections = async () => {
    const { data } = await getCollectionByIds({
      variables: {
        input: {
          collectionIds: useSelectModeValues.selectedCollectionIds,
        },
      },
      onCompleted: (data) => {
        addCollections(data.collectionByIds);
      },
    });

    return data?.collectionByIds || [];
  };

  return (
    <Provider
      value={{
        collections,
        addCollections,
        posts,
        addPosts,
        getSelectedPosts,
        getSelectedCollections,
        ...useSelectModeValues,
      }}
    >
      {children}
    </Provider>
  );
};

const useJuiceboxSelectContext = () => {
  try {
    return _useJuiceboxSelectContext();
  } catch (error) {
    return {
      collections: [],
      addCollections: () => {},
      posts: [],
      addPosts: () => {},
      getSelectedPosts: () => Promise.resolve([]),
      getSelectedCollections: () => Promise.resolve([]),
      selectedCollectionIds: [],
      selectedPostIds: [],
      isSelectMode: false,
      enterSelectMode: () => {},
      exitSelectMode: () => {},
      toggleSelectMode: () => {},
      selectAll: () => {},
      unselectAll: () => {},
      isSelected: () => false,
      isSelectModeActive: false,
      onSelectPost: () => {},
      onSelectCollection: () => {},
    } as JuiceboxSelectContextValues;
  }
};

export { useJuiceboxSelectContext };
