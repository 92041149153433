import { Box, BoxProps } from '@mui/material';
import { DndDragItemTypes } from 'features/dnd/constants';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';

export type DropTarget = {
  id: string;
  side: 'top' | 'bottom';
};

export type DndSortableDropItemProps = Omit<BoxProps, 'onDrop'> & {
  accept: DndDragItemTypes[];
  children?: ReactNode;
  onDrop?: (
    dropTarget: DropTarget | null,
    item: unknown & { id: string },
    monitor: DropTargetMonitor<unknown & { id: string }, unknown>,
  ) => void;
};

export const DndSortableDropItem = (props: DndSortableDropItemProps) => {
  const { accept, children, sx, onDrop, ...rest } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const [dropTarget, setDropTarget] = useState<DropTarget | null>(null);
  const dropTargetRef = useRef(dropTarget);
  dropTargetRef.current = dropTarget;

  const [collected, drop] = useDrop<
    unknown & { id: string },
    unknown,
    {
      isOver: boolean;
      canDrop: boolean;
    }
  >(() => ({
    accept,
    drop: (...props) => {
      return onDrop?.(dropTargetRef.current, ...props);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const itemEls = Array.from(
        ref.current.querySelectorAll('[data-item-id]'),
      );
      const offset = monitor.getClientOffset();

      if (offset) {
        for (const itemEl of itemEls) {
          const rect = itemEl.getBoundingClientRect();

          // If itemEl === ref.current, skip
          if (itemEl.getAttribute('data-item-id') === (item as any).id) {
            continue;
          }

          // If offset lies within the upper half of the element, set the drop position to the top of the element
          if (offset.y > rect.top && offset.y < rect.top + rect.height / 2) {
            setDropTarget({
              id: itemEl.getAttribute('data-item-id') || '',
              side: 'top',
            });

            return;
          }

          // If offset lies within the lower half of the element, set the drop position to the bottom of the element
          if (offset.y > rect.top + rect.height / 2 && offset.y < rect.bottom) {
            setDropTarget({
              id: itemEl.getAttribute('data-item-id') || '',
              side: 'bottom',
            });

            return;
          }
        }
      }
    },
  }));

  useEffect(() => {
    if (!collected.canDrop) {
      setDropTarget(null);
    }
  }, [collected.canDrop]);

  drop(ref);

  return (
    <Box
      ref={ref}
      sx={{
        // These margin & padding match the size of the handle in `DndSortableDragItem`
        ml: '-24px',
        pl: '24px',
        '> *': {
          pointerEvents: collected.isOver ? 'none' : 'auto',
        },
        [`[data-item-id=${dropTarget?.id}]`]: {
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 1,
            pointerEvents: 'none',
            height: '4px',
            backgroundColor:
              collected.isOver && dropTarget ? '#F8E8CA' : 'transparent',
            // NOTE: These styles are hard-coded for the ContentIdeaDetailView & fields right now.
            // This most likely need customization for other use-cases.
            ...(dropTarget?.side === 'top'
              ? {
                  top: 0,
                  marginTop: '-6px',
                }
              : {
                  bottom: 0,
                  marginBottom: '-6px',
                }),
          },
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
