import { ClientSideSuspense } from '@liveblocks/react';
import { Presence, RoomProvider } from 'features/collaboration';
import { ReactNode } from 'react';

type RoomProps = {
  roomId: string;
  initialPresence: Presence;
  fallBack?: ReactNode;
  children: ReactNode;
};

export const CollaborationRoom = (props: RoomProps) => {
  const { roomId, initialPresence, fallBack, children } = props;

  return (
    <RoomProvider id={roomId} initialPresence={initialPresence}>
      <ClientSideSuspense fallback={fallBack || null}>
        {() => children}
      </ClientSideSuspense>
    </RoomProvider>
  );
};
