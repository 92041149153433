import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@mui/material';
import { theme } from 'styles/theme';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { AccessRequestAdminList } from 'features/accessRequest';
import {
  BillingPlanName,
  useDisableUsersMutation,
  UserFragmentAccessRequestAdminListFragment,
} from 'graphql/generated';
import { PlotRoutes } from 'Routes';
import { useState } from 'react';
import { modifyObject } from 'utils/apollo';
import { useUserContext } from 'contexts/users/User.context';
import { useBillingMutations } from '../../hooks';

type BillingUpdateMemberConfirmationDialogProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  usersToBeDisabled: UserFragmentAccessRequestAdminListFragment[];
  usersToBeEnabled: UserFragmentAccessRequestAdminListFragment[];
  type: 'upgrade' | 'freeTrial';
  onGoBack: VoidFunction;
};

export const BillingUpdateMemberConfirmationDialog = (
  props: BillingUpdateMemberConfirmationDialogProps,
) => {
  const {
    isOpen,
    onClose,
    usersToBeDisabled,
    usersToBeEnabled,
    type,
    onGoBack,
  } = props;

  const { orgBilling } = useUserContext();

  const navigate = useGuardNavigate();

  const [disableUsersMutation] = useDisableUsersMutation();
  const { createFreeTrialSubscription } = useBillingMutations();

  const [loading, setLoading] = useState(false);

  const handleClose = async () => {
    setLoading(true);

    await disableUsersMutation({
      variables: {
        data: {
          userIds: usersToBeDisabled.map((u) => u.id),
        },
      },
      update: (cache) => {
        if (usersToBeDisabled.length > 0) {
          usersToBeDisabled.forEach((user) => {
            modifyObject(cache, user.id, 'UserProfileModel', {
              isDisabled: () => true,
            });
          });

          if (orgBilling) {
            modifyObject(cache, orgBilling.id, 'OrganizationBillingModel', {
              organizationMemberUsage: () => {
                return (
                  orgBilling.organizationMemberUsage - usersToBeDisabled.length
                );
              },
            });
          }
        }
      },
    });

    if (type === 'upgrade') {
      setLoading(false);
      onClose();
      navigate(PlotRoutes.billingCheckout({ planName: BillingPlanName.Basic }));
    } else if (type === 'freeTrial') {
      await createFreeTrialSubscription(BillingPlanName.Basic);
      setLoading(false);
      navigate(0);
    }
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          p: 8,
          width: '465px',
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(6),
        },
      }}
    >
      <Typography variant="headline-lg">
        You are making changes to the organization
      </Typography>

      <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
        🟢 The following users will be enabled from the Organization:
      </Typography>

      <AccessRequestAdminList admins={usersToBeEnabled} />

      <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
        🔴 The following users will be removed from the Organization:
      </Typography>

      <AccessRequestAdminList admins={usersToBeDisabled} />

      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(3),
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button disabled={loading} onClick={onGoBack}>
          View Members
        </Button>

        <Button
          variant="contained"
          disabled={loading}
          sx={{
            bgcolor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
            borderRadius: theme.spacing(2),
            ':hover': {
              bgcolor: theme.colors?.primary.black,
            },
          }}
          onClick={handleClose}
          endIcon={loading && <CircularProgress size={14} />}
        >
          {type === 'upgrade' ? 'Continue to Payment' : 'Start a Free Trial'}
        </Button>
      </Box>
    </Dialog>
  );
};
