import { Typography, Box, styled } from '@mui/material';
import { typography } from 'components/common/Typography/styles';

export const StyledRequestText = styled(Typography)(({ theme }) => ({
  ...typography['body-md'],
  fontWeight: 450,
  color: theme.colors?.utility[800],
}));

export const StyledRequestBoldText = styled(Typography)(({ theme }) => ({
  ...typography['body-md'],
  fontWeight: 600,
  color: theme.colors?.utility[1000],
}));

export const StyledCellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  padding: `0px ${theme.spacing(4)}`,
  '&:hover': {
    background: theme.colors?.utility[275],
  },
}));

export const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
  padding: `${theme.spacing(4)} 0px`,
  borderBottom: `1px solid ${theme.colors?.utility[300]}`,
  cursor: 'pointer',
}));

export const StyledDesText = styled(Typography)(({ theme }) => ({
  fontSize: 10,
  fontWeight: 600,
  color: theme.colors?.utility[700],
  fontFamily: 'TT Commons Pro',
}));
