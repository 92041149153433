import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { NODE_TASK_ITEM } from '../constants';
import { TaskNodeView } from './components/TaskNodeView';
import {
  TaskItemOptions,
  TaskNodeAttributes,
  TaskNodeAttributesArray,
} from './types';
import { splitTaskListItem } from './utils';

export const inputRegex = /^\s*(\[([( |x])?\])\s$/;

export const TaskItem = Node.create<TaskItemOptions>({
  name: NODE_TASK_ITEM,

  addOptions() {
    return {
      nested: false,
      externalAttrs: {},
      HTMLAttributes: {},
    };
  },

  content() {
    return this.options.nested ? 'paragraph block*' : 'paragraph+';
  },

  defining: true,

  addAttributes() {
    return {
      externalAttrs: {
        default: null,
      },
      ...Object.fromEntries(
        TaskNodeAttributesArray.map((item) => {
          return [
            item,
            {
              item: {
                default: null,
                parseHTML: (element) => element.getAttribute(item),
                renderHTML: (attributes) => {
                  if (!attributes?.[item]) {
                    return {};
                  }
                  return {
                    item: attributes?.[item],
                  };
                },
              },
            },
          ];
        }),
      ),
    };
  },

  parseHTML() {
    return [
      {
        tag: `li[data-type="${this.name}"]`,
        priority: 51,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { externalAttrs, ...attributes } = HTMLAttributes;

    return [
      'li',
      mergeAttributes(this.options.HTMLAttributes, attributes, {
        'data-type': this.name,
      }),
      [
        'label',
        [
          'input',
          {
            type: 'checkbox',
          },
        ],
        ['span'],
      ],
      ['div', 0],
    ];
  },

  // @ts-ignore
  addCommands() {
    return {
      splitTaskListItem,
    };
  },

  addKeyboardShortcuts() {
    const shortcuts = {
      Enter: () => this.editor.commands.splitTaskListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    };

    if (!this.options.nested) {
      return shortcuts;
    }

    return {
      ...shortcuts,
      Tab: () => this.editor.commands.sinkListItem(this.name),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer((props) => {
      const id = props.node.attrs[TaskNodeAttributes.id];

      return <TaskNodeView {...props} key={id} />;
    });
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => ({
          checked: match[match.length - 1] === 'x',
        }),
      }),
    ];
  },
});
