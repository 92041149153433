import { Box, Typography } from '@mui/material';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { SearchListItemView } from 'features/search';
import {
  AttachmentSearchResultFragmentSectionViewFragment,
  LinkSearchResultFragmentSectionViewFragment,
  NoteSearchResultFragmentSectionViewFragment,
  SearchableEntityType,
  TaskSearchResultFragmentSectionViewFragment,
  CollectionSearchResultFragmentSectionViewFragment,
} from 'graphql/generated';
import React, { MouseEvent, useMemo } from 'react';
import { theme } from 'styles/theme';

export type SearchSectionViewSearchResult =
  | NoteSearchResultFragmentSectionViewFragment
  | LinkSearchResultFragmentSectionViewFragment
  | AttachmentSearchResultFragmentSectionViewFragment
  | TaskSearchResultFragmentSectionViewFragment
  | CollectionSearchResultFragmentSectionViewFragment;

type SearchSectionProps = {
  type: SearchableEntityType;
  searchResult: SearchSectionViewSearchResult;
  searchString?: string;
  elRefs: React.MutableRefObject<(HTMLElement | null)[]>;
  initialIndex: number;
  onHeaderClick?: (...params: any[]) => void;
};

export const SearchSectionView = (props: SearchSectionProps) => {
  const {
    type,
    searchResult,
    searchString,
    initialIndex,
    elRefs,
    onHeaderClick,
  } = props;
  let elIndex = initialIndex;

  const { headingLabel } = useMemo(() => {
    switch (type) {
      case SearchableEntityType.Notes:
        return {
          headingLabel: `Notes (${searchResult.totalCount})`,
        };
      case SearchableEntityType.Links:
        return {
          headingLabel: `Links (${searchResult.totalCount})`,
        };
      case SearchableEntityType.Attachments:
        return {
          headingLabel: `Attachments (${searchResult.totalCount})`,
        };
      case SearchableEntityType.Tasks:
        return {
          headingLabel: `Tasks (${searchResult.totalCount})`,
        };
      case SearchableEntityType.Collections:
        return {
          headingLabel: `Collections (${searchResult.totalCount})`,
        };
      default:
        return {
          headingLabel: '',
        };
    }
  }, [type, searchResult]);

  const isLinkSection = useMemo(() => {
    return type === SearchableEntityType.Links;
  }, [type]);

  const isAttachmentSection = useMemo(() => {
    return type === SearchableEntityType.Attachments;
  }, [type]);

  const handleHeaderClick = (e: MouseEvent<HTMLElement>) => {
    if (e && onHeaderClick) {
      e.stopPropagation();
      onHeaderClick();
    }
  };

  return searchResult.hits.length === 0 ? null : (
    <>
      <Box
        px={3}
        my={2}
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={handleHeaderClick}
      >
        <Typography
          color={theme.colors?.utility[700]}
          fontWeight={600}
          variant="body-xs"
        >
          {headingLabel}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography
            fontWeight={600}
            variant="body-xs"
            color={theme.colors?.primary.maroon}
          >
            Show more
          </Typography>
          <Box display="flex" color={theme.colors?.primary.maroon}>
            <IconLinearArrowRight size={12} />
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={isLinkSection || isAttachmentSection ? 'row' : 'column'}
        gap={isLinkSection || isAttachmentSection ? 1 : 0}
        maxWidth="100%"
        overflow="auto"
      >
        {searchResult.hits.map((hit) => (
          <SearchListItemView
            key={hit.item.id}
            ref={(el) => {
              elRefs.current[elIndex] = el;
              elIndex++;
            }}
            searchHit={hit}
            type={type}
            variant={
              isLinkSection
                ? 'preview'
                : isAttachmentSection
                ? 'thumbnail-only'
                : 'list'
            }
            searchString={searchString}
          />
        ))}
      </Box>
    </>
  );
};
