import { gql } from '@apollo/client';
import { CollaborationEditor } from 'features/collaboration';
import { NoteRichTextEditor } from 'features/note';
import {
  ContentIdeaFragmentContentIdeaNoteFragment,
  ContentIdeaPermission,
  useUpdateContentIdeaForContentIdeaNoteMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { ContentIdeaCollaborativeViewers } from '../viewers';

// eslint-disable-next-line
gql`
  fragment ContentIdeaFragmentContentIdeaNote on ContentIdeaModel {
    id
    noteContent
    roomId
    myPermissions
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaForContentIdeaNote($data: UpdateContentIdeaInput!) {
    updateContentIdea(data: $data) {
      id
      noteContent
    }
  }
`;

type ContentIdeaNoteProps = {
  contentIdea: ContentIdeaFragmentContentIdeaNoteFragment;
};

export const ContentIdeaNote = (props: ContentIdeaNoteProps) => {
  const { contentIdea } = props;

  const roomId = useMemo(() => {
    if (contentIdea.roomId) {
      return contentIdea.roomId;
    }

    return `contentIdea-${contentIdea.id}`;
  }, [contentIdea.roomId, contentIdea.id]);

  const [updateContentIdea] = useUpdateContentIdeaForContentIdeaNoteMutation();
  const handleContentUpdate = useMemo(
    () =>
      debounce(async (content: string) => {
        if (content !== contentIdea.noteContent) {
          await updateContentIdea({
            variables: {
              data: {
                contentIdeaId: contentIdea.id,
                data: {
                  noteContent: content,
                },
              },
            },
            optimisticResponse: {
              updateContentIdea: {
                id: contentIdea.id,
                __typename: 'ContentIdeaModel',
                noteContent: content,
              },
            },
          });
        }
      }, 1000),
    [contentIdea.id, contentIdea.noteContent, updateContentIdea],
  );

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  return (
    <CollaborationEditor
      roomId={roomId}
      renderOnlineUsers={(onlineUsers) => {
        return (
          <ContentIdeaCollaborativeViewers
            contentIdeaId={contentIdea.id}
            onlineUsers={onlineUsers}
          />
        );
      }}
      renderEditor={(collaborationExtensions, provider) => {
        return (
          <NoteRichTextEditor
            key={contentIdea.id}
            placeholder="A safe place for half-baked ideas. Please '/' for commands"
            provider={provider}
            extraExtensions={collaborationExtensions}
            className="note-view-body"
            defaultContent={contentIdea.noteContent}
            onContentChange={handleContentUpdate}
            editable={canEdit}
          />
        );
      }}
    />
  );
};
