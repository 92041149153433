/**
 * EXPERIMENTAL!!!
 * This class is generated by ChatGPT.
 * This is a singleton class that listens to mouse move events and notifies all registered listeners.
 * It's used to avoid adding multiple event listeners for mouse move events.
 */

class MouseMoveManager {
  private static instance: MouseMoveManager;
  private listeners: Set<(event: MouseEvent) => void> = new Set();
  private isListening: boolean = false;

  private constructor() {
    // Empty constructor
  }

  public static getInstance(): MouseMoveManager {
    if (!MouseMoveManager.instance) {
      MouseMoveManager.instance = new MouseMoveManager();
    }
    return MouseMoveManager.instance;
  }

  public addListener(listener: (event: MouseEvent) => void): void {
    this.listeners.add(listener);
    if (!this.isListening) {
      this.startListening();
    }
  }

  public removeListener(listener: (event: MouseEvent) => void): void {
    this.listeners.delete(listener);
    if (this.listeners.size === 0 && this.isListening) {
      this.stopListening();
    }
  }

  private startListening(): void {
    this.isListening = true;
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  private stopListening(): void {
    this.isListening = false;
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  private handleMouseMove = (event: MouseEvent): void => {
    this.listeners.forEach((listener) => listener(event));
  };
}

export const mouseMoveManager = MouseMoveManager.getInstance();
