import { NodeSelection, Plugin, PluginKey } from '@tiptap/pm/state';
import { Box } from '@mui/material';
// @ts-ignore
import { EditorView, __serializeForClipboard } from '@tiptap/pm/view';
import { Extension } from '@tiptap/react';
import { IconCustomNounMoveButton } from 'components/icons/components/custom/IconCustomNounMoveButton';
import { throttle } from 'lodash';
import { useEffect, useRef } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { theme } from 'styles/theme';
import { blockAtCoords } from '../blockWrapper/utils';
import { ATTR_BLOCK_ID, EXTENSION_BLOCK_FLOATING_MENU } from '../constants';

let rootMenuContainer: Root | null = null;
let menuContainer: Element | null = null;
let currentPosition: {
  left: number;
  top: number;
} | null = null;
let currentNodePos: number | null = null;
let initialPos: number | null = null;
let editorView: EditorView | null = null;

const FloatingMenu = ({ position }) => {
  const handleRef = useRef<HTMLDivElement | null>(null);

  const handleDragStart = (event) => {
    if (!editorView || currentNodePos === null || initialPos === null) return;

    const node = editorView.state.doc.nodeAt(currentNodePos);

    if (!node) {
      return;
    }

    editorView.dispatch(
      editorView.state.tr.setSelection(
        NodeSelection.create(editorView.state.doc, initialPos),
      ),
    );

    const slice = editorView.state.selection.content();
    const { dom, text } = __serializeForClipboard(editorView, slice);

    event.dataTransfer.clearData();
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'copyMove';
    event.dataTransfer.setData('text/html', dom.innerHTML);
    event.dataTransfer.setData('text/plain', text);
    event.dataTransfer.setDragImage(
      // element from node
      editorView.nodeDOM(initialPos),
      0,
      0,
    );

    // eslint-disable-next-line no-param-reassign
    editorView.dragging = { slice, move: event.ctrlKey };

    // Attach "is-dragging" class to the tiptap container
    const tiptapContainer = document.querySelector('.ProseMirror');
    if (tiptapContainer) {
      tiptapContainer.classList.add('is-dragging');
    }
  };

  const handleDragEnd = () => {
    // Remove "is-dragging" class from the tiptap container
    const tiptapContainer = document.querySelector('.ProseMirror');
    if (tiptapContainer) {
      tiptapContainer.classList.remove('is-dragging');
    }
  };

  useEffect(() => {
    if (handleRef.current) {
      handleRef.current.addEventListener('dragstart', handleDragStart);
      handleRef.current.addEventListener('dragend', handleDragEnd);
    }

    return () => {
      if (handleRef.current) {
        handleRef.current.removeEventListener('dragstart', handleDragStart);
        handleRef.current.removeEventListener('dragend', handleDragEnd);
      }
    };
  }, [position]);

  if (!position) {
    return null;
  }

  return (
    <Box
      className="block-floating-menu"
      ref={handleRef}
      style={{
        position: 'absolute',
        left: position.left,
        top: position.top,
        transform: 'translateX(-100%)',
        zIndex: 1000,
        width: 28,
        height: 28,
        cursor: 'grab',
      }}
      draggable
    >
      <Box
        style={{
          position: 'absolute',
          top: 2,
          left: 5,
          width: 18,
          height: 24,
          borderRadius: '4px',
          backgroundColor: 'rgba(250, 243, 236, 0.40)',
          backdropFilter: 'blur(25px)',
          pointerEvents: 'none',
        }}
      />
      <IconCustomNounMoveButton
        size={28}
        style={{
          fill: theme.colors?.primary.black,
          position: 'relative',
        }}
      />
    </Box>
  );
};

const renderFloatingMenu = () => {
  // Mount the floating menu container
  if (!menuContainer) {
    menuContainer = document.createElement('div');
    menuContainer.classList.add('block-floating-menu-container');
  }

  if (!rootMenuContainer) {
    rootMenuContainer = createRoot(menuContainer);
  }

  document.body.appendChild(menuContainer);

  if (rootMenuContainer) {
    rootMenuContainer.render(<FloatingMenu position={currentPosition} />);
  }
};

const updateFloatingMenuPosition = throttle(
  (
    view: EditorView,
    event: {
      left: number;
      top: number;
    },
  ) => {
    const { left, top } = event;
    let { node, docPos } = blockAtCoords(
      view,
      {
        clientX: left,
        clientY: top,
      },
      {
        left: -28, // to include the floating menu button on the left
      },
    );

    if (docPos < 0) {
      currentPosition = null;
      currentNodePos = null;

      renderFloatingMenu();

      return;
    }

    while (node && node.attrs.draggable !== 'true') {
      const resolvedPos = view.state.doc.resolve(docPos);

      if (resolvedPos.depth === 0) {
        break;
      }

      const parentPos = resolvedPos.before();
      const parent = view.state.doc.nodeAt(parentPos);

      if (parent) {
        docPos = parentPos;
        node = parent;
      } else {
        break;
      }
    }

    const shouldRender =
      node &&
      node.isBlock &&
      node.attrs[ATTR_BLOCK_ID] &&
      node.attrs.draggable === 'true' &&
      docPos < view.state.doc.nodeSize - 2; // Avoid rendering the menu if user is hovering at the bottom of the document

    if (shouldRender) {
      const coords = view.coordsAtPos(docPos);
      currentPosition = {
        left: coords.left,
        top: coords.top,
      };
      currentNodePos = docPos;
      initialPos = docPos;
      renderFloatingMenu();
    } else {
      currentPosition = null;
      currentNodePos = null;
      initialPos = null;
      renderFloatingMenu();
    }
  },
  100,
);

export const BlockFloatingMenu = Extension.create({
  name: EXTENSION_BLOCK_FLOATING_MENU,

  onDestroy() {
    if (menuContainer) {
      menuContainer.remove();
    }
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('blockFloatingMenuPlugin'),
        view(view) {
          editorView = view;
          return {
            update() {
              renderFloatingMenu();
            },
          };
        },
        props: {
          handleDOMEvents: {
            touchend: (view, event) => {
              const { clientX: left, clientY: top } = event.touches[0];
              updateFloatingMenuPosition(view, { left, top });
            },
            mousemove: throttle((view, event) => {
              const { clientX: left, clientY: top } = event;
              updateFloatingMenuPosition(view, { left, top });
            }, 100),
            dragstart: () => {
              return true;
            },
          },
        },
      }),
    ];
  },
});
