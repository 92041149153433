import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { AvatarGroup } from 'components/common/AvatarGroup';
import {
  taskPriorityText,
  taskStatusParentEventTextColor,
} from 'features/task';
import { EventProps } from 'react-big-calendar';
import { theme } from 'styles/theme/theme';
import { extractTextFromHtml } from 'utils/html';
import { TaskPeekingCardView } from '../peekingCard';
import { EventRendererWrapper } from './styles';
import { TaskCustomEvent } from './types';

export type TaskCalendarEventViewProps = EventProps & {
  onClick?: () => void;
};

export const TaskCalendarEventView = (props: TaskCalendarEventViewProps) => {
  const { event, onClick } = props;
  const customEvent = event as TaskCustomEvent;
  const task = customEvent.task;

  return customEvent.isLoading ? (
    <Skeleton variant="rounded" width="100%" height="100%" />
  ) : (
    <Tooltip
      title={<TaskPeekingCardView taskId={customEvent.task.id} />}
      placement="bottom-start"
      componentsProps={{
        tooltip: {
          sx: {
            borderRadius: 3,
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(20px)`,
            maxWidth: 380,
            minWidth: 380,
            boxShadow: `-1px -1px 0px 0px #E0E0E0 inset, -1px -1px 0px 0px #E0E0E0`,
          },
        },
      }}
    >
      {/* NOTE: This could need refactoring when we implement drag & drop for calendar */}
      <EventRendererWrapper taskStatus={task.status} onClick={onClick}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            overflow: 'hidden',
          }}
        >
          {task.priority && (
            <Typography variant="headline-xxs">
              {taskPriorityText[task.priority]}
            </Typography>
          )}
          <Typography
            variant="headline-xxs"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {extractTextFromHtml(task.name)}
            {task.parentTask ? (
              <Box
                component="span"
                sx={{
                  color: taskStatusParentEventTextColor[task.status],
                  ...theme.typography['headline-xxs'],
                }}
              >
                &nbsp;/&nbsp;{extractTextFromHtml(task.parentTask.name)}
              </Box>
            ) : (
              ''
            )}
          </Typography>
        </Box>
        {task.taskMembers.length > 0 ? (
          <Box sx={{ flexShrink: 0 }}>
            <AvatarGroup users={task.taskMembers} avatarSize={20} />
          </Box>
        ) : null}
      </EventRendererWrapper>
    </Tooltip>
  );
};
