import { gql } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { platformIconMap } from 'features/socialPost';
import {
  Platform,
  SocialAuthorFragmentSocialAuthorListSectionFragment,
  useCreateSocialAuthorForSocialAuthorListSectionMutation,
  useDeleteSocialAuthorForSocialAuthorListSectionMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';

export const SOCIAL_AUTHOR_FRAGMENT_SOCIAL_AUTHOR_LIST_SECTION = gql`
  fragment SocialAuthorFragmentSocialAuthorListSection on SocialAuthorModel {
    id
    platform
    profileUrl
  }
`;

// eslint-disable-next-line
gql`
  mutation CreateSocialAuthorForSocialAuthorListSection(
    $data: CreateSocialAuthorInput!
  ) {
    createSocialAuthor(data: $data) {
      id
      ...SocialAuthorFragmentSocialAuthorListSection
    }
  }
  ${SOCIAL_AUTHOR_FRAGMENT_SOCIAL_AUTHOR_LIST_SECTION}
`;

// eslint-disable-next-line
gql`
  mutation DeleteSocialAuthorForSocialAuthorListSection(
    $data: DeleteSocialAuthorInput!
  ) {
    deleteSocialAuthor(data: $data) {
      success
      message
    }
  }
`;

export type SocialAuthorListSectionProps = {
  platform: Platform;
  socialAuthors: SocialAuthorFragmentSocialAuthorListSectionFragment[];

  // Refetch me query in SocialsPage
  refetch: () => void;
  componentsProps?: {
    listItem?: Pick<SocialAuthorListItemProps, 'componentsProps'>;
  };
};

export const SocialAuthorListSection = (
  props: SocialAuthorListSectionProps,
) => {
  const { platform, socialAuthors, refetch, componentsProps = {} } = props;

  const [isAddingNewSocial, setIsAddingNewSocial] = useState(false);

  const [createSocialAuthor] =
    useCreateSocialAuthorForSocialAuthorListSectionMutation();
  const onAddNewSocial = async (profileUrl: string) => {
    await createSocialAuthor({
      variables: {
        data: {
          data: {
            platform,
            url: profileUrl,
          },
        },
      },
    });

    refetch();
    setIsAddingNewSocial(false);
  };

  const [deleteSocialAuthor] =
    useDeleteSocialAuthorForSocialAuthorListSectionMutation();
  const onRemoveSocial = async (socialAuthorId: string) => {
    await deleteSocialAuthor({
      variables: {
        data: {
          socialAuthorId,
        },
      },
      update: (cache) => {
        evictObject(cache, socialAuthorId, 'SocialAuthorModel');
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 6,
      }}
    >
      {socialAuthors.map((socialAuthor) => {
        return (
          <SocialAuthorListItem
            key={platform}
            platform={socialAuthor.platform}
            profileUrl={socialAuthor.profileUrl || ''}
            disabled
            onRemove={() => onRemoveSocial(socialAuthor.id)}
            {...componentsProps.listItem}
          />
        );
      })}
      {isAddingNewSocial && (
        <SocialAuthorListItem
          platform={platform}
          onSubmit={onAddNewSocial}
          onRemove={() => setIsAddingNewSocial(false)}
          autoFocus
          {...componentsProps.listItem}
        />
      )}
      <Button
        variant="text"
        startIcon={<IconBoldAddCircle size={24} />}
        onClick={() => {
          setIsAddingNewSocial(true);
        }}
        sx={{
          py: 0,
          px: 1,
          color: theme.colors?.primary.black,
        }}
      >
        <Typography variant="subhead-lg">Add more</Typography>
      </Button>
    </Box>
  );
};

export type SocialAuthorListItemProps = {
  platform: Platform;
  profileUrl?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onSubmit?: (profileUrl: string) => Promise<void>;
  onRemove?: () => void;
  componentsProps?: {
    input?: Omit<TextFieldProps, 'value' | 'onChange'>;
    icon?: {
      size?: number;
      color?: string;
    };
  };
};

export const SocialAuthorListItem = (props: SocialAuthorListItemProps) => {
  const {
    platform,
    profileUrl,
    disabled,
    autoFocus,
    onSubmit,
    onRemove,
    componentsProps = {},
  } = props;

  const [value, setValue] = useState(profileUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { dialog: deleteDialog, onOpen: openDeleteDialog } =
    useConfirmationDialog();

  const internalOnSubmit = async () => {
    // Validate value as url

    const platformUrlRegexMap = {
      [Platform.Tiktok]:
        /^https?:\/\/(?:www\.)?tiktok\.com\/([^\?#]+)(?:\?[^#]*)?(?:#.*)?$/i,
      [Platform.Instagram]:
        /^https?:\/\/(?:www\.)?instagram\.com\/([^\?#]+)(?:\?[^#]*)?(?:#.*)?$/i,
    };

    if (!value) {
      setErrorMessage('Please enter a URL');
      return;
    }

    const matchingRegex = platformUrlRegexMap[platform];

    if (!matchingRegex || !value.match(matchingRegex)) {
      setErrorMessage('Invalid URL');
      return;
    }

    setIsLoading(true);

    try {
      await onSubmit?.(value);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const Icon = platformIconMap[platform];

  return (
    <>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
      >
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}
        >
          {Icon && <Icon size={24} {...componentsProps.icon} />}
          <TextField
            defaultValue={value}
            disabled={disabled}
            onChange={(e) => setValue(e.target.value)}
            autoFocus={autoFocus}
            type="url"
            sx={{
              flex: 1,
            }}
            {...componentsProps.input}
            onBlur={internalOnSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                internalOnSubmit();
              }
            }}
          />
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <IconButton
              size="small"
              onClick={() => {
                if (!profileUrl) {
                  onRemove?.();
                } else {
                  openDeleteDialog({
                    onConfirm: onRemove,
                  });
                }
              }}
            >
              <IconBoldCloseCircle
                size={24}
                color={theme.colors?.utility[600]}
              />
            </IconButton>
          )}
        </Box>
        {errorMessage && (
          <Typography
            variant="subhead-sm"
            color={theme.colors?.utility['pink-3']}
          >
            {errorMessage}
          </Typography>
        )}
      </Box>
      {deleteDialog}
    </>
  );
};
