import { gql } from '@apollo/client';
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  BillingInvoice,
  BillingPlanName,
  useGetBillingInvoicesMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { theme } from 'styles/theme/theme';
import { Parser } from 'utils/parser';
import { useUserContext } from 'contexts/users/User.context';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { StyledTableCell, StyledTableHeadCell } from '../../styles';

// eslint-disable-next-line
gql`
  mutation GetBillingInvoices {
    getInvoices {
      id
      created
      amountDue
      status
      hostedInvoiceUrl
      description
      quantity
      interval
    }
  }
`;

export const BillingInvoices = () => {
  const { orgBilling } = useUserContext();
  const isMobileView = useMediaQueryMobile();

  const [getBillingVoices, { loading: loadingInvoices }] =
    useGetBillingInvoicesMutation();
  const [invoices, setInvoices] = useImmer<BillingInvoice[]>([]);

  useEffect(() => {
    if (
      orgBilling?.billingCustomerId &&
      orgBilling?.subscription &&
      invoices.length === 0
    ) {
      getBillingVoices().then((res) => {
        if (res.data?.getInvoices) {
          setInvoices(res.data.getInvoices);
        }
      });
    }
  }, [
    getBillingVoices,
    invoices,
    setInvoices,
    orgBilling?.billingCustomerId,
    orgBilling?.subscription,
  ]);

  return !loadingInvoices && invoices.length === 0 ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        m: 25,
      }}
    >
      <Typography
        variant={isMobileView ? 'headline-xs' : 'headline-lg'}
        color={theme.colors?.utility[500]}
      >
        No Billing History Found
      </Typography>
    </Box>
  ) : (
    <Box mt={8}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {[
                'Date',
                'Description',
                'Collaborator Seats',
                'Invoice Total',
                'Status',
              ].map((column, index) => (
                <StyledTableHeadCell key={`${column}-${index}`}>
                  {column}
                </StyledTableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingInvoices && (
              <TableRow>
                <TableCell>
                  <Skeleton variant="rounded" width="100%" height={16} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" width="100%" height={16} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" width="100%" height={16} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" width="100%" height={16} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" width="100%" height={16} />
                </TableCell>
              </TableRow>
            )}
            {invoices.length > 0 &&
              invoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  <StyledTableCell>
                    {moment(invoice.created * 1000).format('MMM DD, YYYY')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {invoice.description
                      .toLowerCase()
                      .includes(BillingPlanName.Standard)
                      ? BillingPlanName.Standard
                      : BillingPlanName.Free}{' '}
                    — {invoice.interval}ly
                  </StyledTableCell>
                  <StyledTableCell>{invoice.quantity}</StyledTableCell>
                  <StyledTableCell>
                    {Parser.toCurrency(invoice.amountDue / 100)}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body-md"
                      color={theme.colors?.utility[800]}
                      textTransform="capitalize"
                    >
                      {invoice.status}
                    </Typography>
                    {invoice.hostedInvoiceUrl && (
                      <Typography
                        variant="body-md"
                        color={theme.colors?.primary.maroon}
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          window.open(invoice.hostedInvoiceUrl!, '_blank')
                        }
                      >
                        Invoice
                      </Typography>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
