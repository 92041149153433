import { gql } from '@apollo/client';
import {
  LinkedPostFragmentTrendLinkPostsFragmentDoc,
  useCreatePostForTrendMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreatePostForTrend($data: CreatePostForTrendInput!) {
    createPostForTrend(data: $data) {
      id
      isFavoriteTrend
      creator {
        id
      }
      urlMetadata {
        id
      }
      ...LinkedPostFragmentTrendLinkPosts
    }
  }
  ${LinkedPostFragmentTrendLinkPostsFragmentDoc}
`;

export const useCreatePostHandler = () => {
  const [createPostForTrend, { loading: isLoadingCreatePostForTrend }] =
    useCreatePostForTrendMutation();

  const onCreatePostForTrend = (trendId: string, urlMetadataId?: string) => {
    return createPostForTrend({
      variables: {
        data: {
          trendId,
          urlMetadataId,
        },
      },
      update: (cache, res) => {
        modifyObject(cache, trendId, 'TrendModel', {
          linkedPosts: (posts) => {
            return [res.data?.createPostForTrend, ...posts];
          },
        });
      },
    });
  };

  return { onCreatePostForTrend, isLoadingCreatePostForTrend };
};
