import { gql } from '@apollo/client';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { IconBoldTick } from 'components/icons/components/bold/IconBoldTick';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconOutlineCloseCircle } from 'components/icons/components/outline/IconOutlineCloseCircle';
import { useUserContext } from 'contexts/users/User.context';
import { ContentCalendarViewContextMenu } from 'features/contentCalendarView/components';
import {
  ContentCalendarViewFragmentContentCalendarViewContextMenuFragmentDoc,
  ContentCalendarViewFragmentContentCalendarViewListItemViewFragment,
  useUpdateContentCalendarViewForContentCalendarViewListItemViewMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';

export const CONTENT_CALENDAR_VIEW_FRAGMENT_CONTENT_CALENDAR_VIEW_LIST_ITEM_VIEW = gql`
  fragment ContentCalendarViewFragmentContentCalendarViewListItemView on ContentCalendarViewModel {
    id
    name
    creator {
      id
      firstName
      lastName
    }
    ...ContentCalendarViewFragmentContentCalendarViewContextMenu
  }

  ${ContentCalendarViewFragmentContentCalendarViewContextMenuFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentCalendarViewForContentCalendarViewListItemView(
    $data: UpdateContentCalendarViewInput!
  ) {
    updateContentCalendarView(data: $data) {
      id
      name
    }
  }
`;

export type ContentCalendarViewListItemViewProps = {
  contentCalendarView: ContentCalendarViewFragmentContentCalendarViewListItemViewFragment;
};

export const ContentCalendarViewListItemView = (
  props: ContentCalendarViewListItemViewProps,
) => {
  const { contentCalendarView } = props;

  const [params] = useSearchParams();
  const currentlyUsedContentCalendarViewId = params.get('view') || '';

  const { user } = useUserContext();

  // Controls for renaming
  const [isRenaming, setIsRenaming] = useState(false);
  const [newViewName, setNewViewName] = useState(contentCalendarView.name);
  const [updateContentCalendarView] =
    useUpdateContentCalendarViewForContentCalendarViewListItemViewMutation();

  if (isRenaming) {
    return (
      <Box
        component="form"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
        onSubmit={async (event) => {
          event.preventDefault();

          await updateContentCalendarView({
            variables: {
              data: {
                contentCalendarViewId: contentCalendarView.id,
                data: {
                  name: newViewName,
                },
              },
            },
          });

          setIsRenaming(false);
        }}
      >
        <TextField
          placeholder="Untitled View"
          defaultValue={contentCalendarView.name}
          sx={{
            flex: 1,
            borderRadius: 2,
            bgcolor: theme.colors?.utility[300],
            fieldset: {
              border: 'none',
            },
            input: {
              py: 2,
            },
          }}
          onChange={(e) => setNewViewName(e.target.value)}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          autoFocus
        />
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <IconButton
            size="small"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              setIsRenaming(false);
              setNewViewName(contentCalendarView.name);
            }}
          >
            <IconOutlineCloseCircle
              size={20}
              color={theme.colors?.utility[700]}
            />
          </IconButton>
          <IconButton
            size="small"
            type="submit"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <IconBoldTickCircle size={20} color={theme.colors?.primary.black} />
          </IconButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Typography variant="subhead-lg">{contentCalendarView.name}</Typography>
        {user?.id !== contentCalendarView.creator.id ? (
          <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
            by {contentCalendarView.creator.firstName}
          </Typography>
        ) : null}
      </Box>
      {contentCalendarView.id === currentlyUsedContentCalendarViewId ? (
        <IconBoldTick size={16} />
      ) : null}
      <ContentCalendarViewContextMenu
        contentCalendarView={contentCalendarView}
        onRename={() => {
          setIsRenaming(true);
        }}
      />
    </Box>
  );
};
