import { Box, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SLABrandInboundSummaryViewSkeleton = () => {
  return (
    <Box
      display="flex"
      gap={2}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="headline-sm"
        color={theme.colors?.utility[700]}
        pb={1}
      >
        Summary
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>

      <Typography
        variant="headline-sm"
        display="flex"
        width="100%"
        justifyContent="space-between"
      >
        <Box
          flex={1}
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
        <Box flex={1} component="span" color={theme.colors?.utility[800]}>
          <Skeleton
            sx={{
              width: theme.spacing(24),
              height: theme.spacing(8),
            }}
          />
        </Box>
      </Typography>
    </Box>
  );
};
