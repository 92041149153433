/**
 * This custom mark aims to improve the ux of the code mark.
 * 1. Allow users to hit right arrow -> continue editing in the same paragraph
 * 2. ...
 */

import { Code as TiptapCode } from '@tiptap/extension-code';

export const Code = TiptapCode.extend({
  addKeyboardShortcuts() {
    return {
      ArrowRight: () => {
        const { selection } = this.editor.state;
        const { empty, $head } = selection;

        if (!empty) {
          return false;
        }

        const { textOffset, nodeBefore, nodeAfter } = $head;

        if (
          textOffset === 0 &&
          nodeBefore?.marks.some((mark) => mark.type.name === this.name)
        ) {
          // If nodeAfter is defined, return false & let the default arrow right behavior happen
          if (nodeAfter) {
            return false;
          }

          // If nodeAfter is not defined, insert an empty text node
          this.editor.chain().unsetCode().insertContent(' ').run();

          return true;
        }

        return false;
      },
    };
  },
});
