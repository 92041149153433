import { gql } from '@apollo/client';
import { useGetNonWorkDomainsQuery } from 'graphql/generated';
import { useCallback, useMemo } from 'react';

// eslint-disable-next-line
gql`
  query GetNonWorkDomains {
    nonWorkDomains
  }
`;

export const useCheckOrgDomainUtils = () => {
  const { data } = useGetNonWorkDomainsQuery();
  const nonWorkDomains = useMemo(() => data?.nonWorkDomains || [], [data]);

  const isWorkDomain = useCallback(
    (domain: string) => {
      if (!domain) {
        throw new Error('Email is required');
      }
      return !Array.from(nonWorkDomains).some((nonWorkDomain) => {
        // Convert wildcard domain patterns to regex
        const regexPattern = nonWorkDomain
          .replace(/([.+?^=!:${}()|\[\]/\\])/g, '\\$1')
          .replace(/\*/g, '.*');
        const regex = new RegExp(`^${regexPattern}$`, 'i');
        return regex.test(domain);
      });
    },
    [nonWorkDomains],
  );

  const getSuggestedEmails = useCallback(
    (orgDomain: string, searchStr: string) => {
      if (!searchStr) {
        return [];
      }

      if (searchStr.includes('@')) {
        return [searchStr];
      }

      if (isWorkDomain(orgDomain)) {
        return [`${searchStr}@${orgDomain}`];
      }

      return [
        `${searchStr}@gmail.com`,
        `${searchStr}@yahoo.com`,
        `${searchStr}@outlook.com`,
      ];
    },
    [isWorkDomain],
  );

  return { isWorkDomain, getSuggestedEmails };
};
