import { gql } from '@apollo/client';
import { Box, BoxProps, Typography } from '@mui/material';
import { StopEventBox } from 'components/common/StopEventBox';
import { Tooltip } from 'components/common/Tooltip';
import {
  MeFragmentUserQueriesFragment,
  UserFragmentAvatarGroupFragmentDoc,
  UserReactionFragmentReactionListFragment,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import { getFullName } from 'utils/users';

export const USER_REACTION_FRAGMENT_REACTION_LIST = gql`
  fragment UserReactionFragmentReactionList on UserReactionModel {
    id
    emoji
    userId
    user {
      id
      ...UserFragmentAvatarGroup
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type ReactionListProps = BoxProps & {
  userReactions: UserReactionFragmentReactionListFragment[];
  user: MeFragmentUserQueriesFragment | null;
  onClickEmoji: (emoji: string) => void;
};

export const ReactionList = ({
  userReactions,
  user,
  onClickEmoji,
  ...rest
}: ReactionListProps) => {
  const groupedReactions = useMemo(
    () =>
      (userReactions || [])?.reduce<{
        [emoji: string]: {
          count: number;
          users: Array<UserReactionFragmentReactionListFragment['user']>;
        };
      }>((acc, item) => {
        const { emoji, user } = item;
        acc[emoji] = {
          count: (acc[emoji]?.count || 0) + 1,
          users: [...(acc[emoji]?.users || []), user],
        };
        return acc;
      }, {}),
    [userReactions],
  );

  return (
    <StopEventBox display="flex" gap={2} width="100%" {...rest}>
      {Object.keys(groupedReactions).length > 0 &&
        Object.keys(groupedReactions).map((emoji) => {
          const userReacted = userReactions?.find(
            (each) => each.emoji === emoji && each.userId === user?.id,
          );
          const users = groupedReactions[emoji].users;
          return (
            <Tooltip
              key={emoji}
              title={
                <Box textAlign="center">
                  <Typography fontSize={30} color={theme.colors?.utility[700]}>
                    {emoji}
                  </Typography>
                  <Box>
                    <Typography
                      variant="body-md"
                      color={theme.colors?.primary.white}
                    >
                      {users
                        .map(
                          (user, index) =>
                            (users.length > 1 && index === users.length - 1
                              ? 'and '
                              : '') + getFullName(user),
                        )
                        .join(', ')}
                    </Typography>
                    <Typography
                      variant="body-md"
                      color={theme.colors?.utility[700]}
                    >
                      {' '}
                      reacted with {emoji}
                    </Typography>
                  </Box>
                </Box>
              }
            >
              <Box
                key={emoji}
                display="flex"
                gap={2}
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                  p: theme.spacing(0, 2),
                  borderRadius: 4,
                  border: userReacted
                    ? `1px solid ${theme.colors?.utility[700]}`
                    : `1px solid ${theme.colors?.utility[400]}`,
                  backgroundColor: theme.colors?.utility['blue-1'],
                }}
                onClick={() => onClickEmoji(emoji)}
              >
                <Typography
                  variant="body-xl"
                  color={theme.colors?.utility[700]}
                >
                  {emoji}
                </Typography>
                <Typography
                  variant="body-xs"
                  color={theme.colors?.utility['blue-1']}
                  fontWeight={600}
                >
                  {groupedReactions[emoji].count}
                </Typography>
              </Box>
            </Tooltip>
          );
        })}
    </StopEventBox>
  );
};
