import { Breadcrumbs, styled } from '@mui/material';
import { theme } from 'styles/theme/theme';

export const StyledBreadCrumb = styled(Breadcrumbs)(() => ({
  marginBottom: theme.spacing(2),
  '& li': {
    color: theme.colors?.utility[700],
    '& a': {
      fontSize: 13,
      color: theme.colors?.utility[700],
      textDecoration: 'none',
    },
  },
}));
