import { gql } from '@apollo/client';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { IconLinearLock1 } from 'components/icons/components/linear/IconLinearLock1';
import { FALLBACK_UNGROUP_ID } from 'features/task';
import { useGetPermittedCollectionsForOrganizationMembersPermittedCollectionsQuery } from 'graphql/generated';
import { theme } from 'styles/theme';
import {
  PermittedCollectionListItem,
  PermittedCollectionListItemSkeleton,
} from './PermittedCollectionListItem';

const MAX_VISIBLE_COLLECTIONS = 1;

// eslint-disable-next-line
gql`
  query GetPermittedCollectionsForOrganizationMembersPermittedCollections(
    $filters: PermittedCollectionFilters!
  ) {
    permittedCollections(filters: $filters) {
      id
      permissionLevel
      collection {
        id
        name
        parentCollection {
          id
          name
        }
      }
    }
  }
`;

export type OrganizationMembersPermittedCollectionsProps = {
  userId: string;
};

export const OrganizationMembersPermittedCollections = (
  props: OrganizationMembersPermittedCollectionsProps,
) => {
  const { userId } = props;

  const { data, loading } =
    useGetPermittedCollectionsForOrganizationMembersPermittedCollectionsQuery({
      variables: {
        filters: {
          userId,
        },
      },
    });
  const permittedCollections = data?.permittedCollections || [];

  const visiblePermittedCollections = [
    {
      id: FALLBACK_UNGROUP_ID,
      collection: {
        id: FALLBACK_UNGROUP_ID,
        name: 'Individual Posts and Notes',
      },
    },
    ...permittedCollections,
  ].slice(0, MAX_VISIBLE_COLLECTIONS);

  const hiddenPermittedCollectionCount = Math.max(
    permittedCollections.length - visiblePermittedCollections.length,
    0,
  );

  return (
    <Tooltip
      placement="bottom-start"
      title={
        <Box
          sx={{
            py: 3,
            px: 6,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {loading ? (
            <PermittedCollectionListItemSkeleton />
          ) : (
            [
              {
                id: FALLBACK_UNGROUP_ID,
                collection: {
                  id: FALLBACK_UNGROUP_ID,
                  name: 'Individual Posts and Notes',
                },
              },
              ...permittedCollections,
            ].map((pc) => {
              return (
                <Box
                  key={pc.id}
                  sx={{
                    py: 3,
                    '&:not(:last-child)': {
                      borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                    },
                  }}
                >
                  <PermittedCollectionListItem
                    userId={userId}
                    permittedCollection={pc}
                  />
                </Box>
              );
            })
          )}
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            width: 220,
            maxHeight: 360,
            overflow: 'auto',
            bgcolor: 'white',
            boxShadow:
              '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
            color: theme.colors?.primary.black,
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
        }}
      >
        {visiblePermittedCollections.map((pc) => {
          return (
            <Chip
              variant="filled-borderless"
              key={pc.id}
              icon={pc.collection ? undefined : <IconLinearLock1 size={14} />}
              label={
                <Typography variant="subhead-lg">
                  {pc.collection ? (
                    <>
                      {pc.collection.name}
                      {pc.collection.parentCollection && (
                        <Box
                          component="span"
                          color={theme.colors?.utility[800]}
                        >
                          {` / ${pc.collection.parentCollection.name}`}
                        </Box>
                      )}
                    </>
                  ) : (
                    'No Access'
                  )}
                </Typography>
              }
            />
          );
        })}
        {hiddenPermittedCollectionCount > 0 && (
          <Chip
            variant="filled-borderless"
            label={
              <Typography variant="subhead-lg">
                +{hiddenPermittedCollectionCount} collections
              </Typography>
            }
          />
        )}
      </Box>
    </Tooltip>
  );
};
