import { Button, SxProps } from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { theme } from 'styles/theme';

interface Props {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { size?: number }
  >;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  sx?: SxProps;
}

export const JuiceboxFilterButton = ({
  Icon,
  text,
  disabled,
  onClick,
  sx = {},
}: Props) => {
  return (
    <Button
      variant="pill-reverse"
      disabled={disabled}
      startIcon={<Icon size={16} color={theme.colors?.utility['purple-4']} />}
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        border: 'none',
        backgroundColor: theme.colors?.utility['300'],
        color: theme.colors?.utility['purple-4'],
        ...theme.typography['subhead-xl'],
        minWidth: 155,
        py: 2.5,
        whiteSpace: 'nowrap',
        ':hover': {
          backgroundColor: theme.colors?.utility['300'],
        },
        '& .MuiButton-endIcon': {
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        },
        ...sx,
      }}
      endIcon={
        <IconLinearArrowDown
          size={16}
          color={theme.colors?.utility['purple-4']}
        />
      }
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
