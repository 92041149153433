import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Popover, SxProps, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconOutlineProfileCircle } from 'components/icons/components/outline/IconOutlineProfileCircle';
import { useMemo, useRef, useState } from 'react';
import { useUserContext } from 'contexts/users/User.context';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { StyledTextField } from 'components/common/form/Autocomplete/AutocompletePopup/styles';
import { theme } from 'styles/theme';

export type SocialMediaListeningFilterByUserButtonProps = {
  selectedUsers: string[];
  onChange: (userIds: string[]) => void;
  componentProps?: {
    sx?: SxProps;
  };
};

export const SocialMediaListeningFilterByUserButton = (
  props: SocialMediaListeningFilterByUserButtonProps,
) => {
  const { selectedUsers, onChange, componentProps } = props;

  const { user } = useUserContext();

  const [searchStr, setSearchStr] = useState('');

  const users = useMemo(() => {
    if (user?.organization.users) {
      return user?.organization.users.filter((user) => {
        return (
          user.firstName.includes(searchStr) ||
          user.lastName.includes(searchStr) ||
          user.email.includes(searchStr)
        );
      });
    }

    return [];
  }, [searchStr, user?.organization.users]);

  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  return (
    <>
      <Button
        ref={anchorElRef}
        variant="tertiary"
        startIcon={<IconOutlineProfileCircle size={16} />}
        endIcon={<IconLinearArrowDown size={16} />}
        sx={{
          borderRadius: 2,
          ...componentProps?.sx,
        }}
        onClick={openPopover}
      >
        <Typography variant="subhead-xl">
          {selectedUsers.length > 0
            ? `${selectedUsers.length} user${
                selectedUsers.length > 1 ? 's' : ''
              } selected`
            : 'Commentors'}
        </Typography>
      </Button>
      <Popover
        open={isPopoverOpen}
        onClose={closePopover}
        anchorEl={anchorElRef.current}
        PaperProps={{
          sx: {
            mt: 2,
            width: 291,
            maxHeight: 400,
            bgcolor: theme.colors?.utility[300],
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            bgcolor: theme.colors?.utility[300],
            padding: theme.spacing(6, 6, 3, 6),
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <StyledTextField
            defaultValue={searchStr}
            placeholder="Search"
            onChange={(event) => {
              setSearchStr(event.target.value);
            }}
            sx={{ p: 0 }}
          />
        </Box>

        <Box sx={{ padding: theme.spacing(0, 4, 4, 4) }}>
          {users.map((user) => {
            const checked = selectedUsers.includes(user.id);

            return (
              <CheckboxMenuItem
                key={user.id}
                value={user.id}
                checked={checked}
                onClick={() => {
                  if (!checked) {
                    onChange([...selectedUsers, user.id]);
                  } else {
                    onChange(selectedUsers.filter((x) => x !== user.id));
                  }
                }}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AvatarWithName user={user} avatarSize={16} />
                  </Box>
                }
              />
            );
          })}
        </Box>
      </Popover>
    </>
  );
};
