import { Box, Typography } from '@mui/material';
import { IconBoldArrowUp } from 'components/icons/components/bold/IconBoldArrowUp';
import { IconBoldChart1 } from 'components/icons/components/bold/IconBoldChart1';
import { IconBoldEmojiHappy } from 'components/icons/components/bold/IconBoldEmojiHappy';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldNote2 } from 'components/icons/components/bold/IconBoldNote2';
import { IconBoldUser } from 'components/icons/components/bold/IconBoldUser';
import { IconBoldVideo } from 'components/icons/components/bold/IconBoldVideo';
import { IconBoldVideoPlay } from 'components/icons/components/bold/IconBoldVideoPlay';
import { IconCustomChart3 } from 'components/icons/components/custom/IconCustomChart3';
import {
  CompetitorCreatorSocialPostContentFilter,
  useGetCompetitorFlatCreatorForCompetitorsSummaryQuery,
} from 'graphql/generated';
import { useCallback } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { CompetitorSummarySkeleton } from './CompetitorSummarySkeleton';
import { CompetitorSummaryProps } from './types';

export const CompetitorSummary = ({ filters }: CompetitorSummaryProps) => {
  const { data: competitorData, loading } =
    useGetCompetitorFlatCreatorForCompetitorsSummaryQuery({
      variables: {
        data: filters,
      },
      skip: !filters.brandId || !filters.creatorId,
      fetchPolicy: 'cache-and-network',
    });
  const competitorFlatCreator =
    competitorData?.competitorFlatCreatorAnalyticSummary;

  const summaryData = [
    ...(filters.contentFilter === CompetitorCreatorSocialPostContentFilter.Owned
      ? [
          {
            icon: IconBoldUser,
            label: (
              <>
                Followers •{' '}
                <Typography
                  component="span"
                  variant="body-xl"
                  fontWeight={600}
                  color={theme.colors?.utility['pink-3']}
                >
                  LIVE
                </Typography>
              </>
            ),
            value: formatBigNumber(
              competitorFlatCreator?.trackingCreator.followerCount || 0,
            ),
          },
        ]
      : []),
    {
      icon: IconBoldNote2,
      label: 'Total number of posts',
      value: formatBigNumber(competitorFlatCreator?.totalPosts || 0),
    },
    {
      icon: IconBoldArrowUp,
      label:
        filters.contentFilter === CompetitorCreatorSocialPostContentFilter.Owned
          ? 'Number of posts/week'
          : 'Brand mentions/week',
      value:
        parseFloat(
          (
            competitorFlatCreator?.averageSocialPostCreationPerWeek || 0
          ).toFixed(2),
        ) || 0,
    },
    {
      icon: IconCustomChart3,
      label: 'Total Engagement',
      value: formatBigNumber(competitorFlatCreator?.totalEngagements || 0),
    },
    {
      icon: IconBoldVideo,
      label: 'Total view count',
      value: formatBigNumber(competitorFlatCreator?.totalViewCount || 0),
    },
    {
      icon: IconBoldHeart,
      label: 'Average likes',
      value: formatBigNumber(competitorFlatCreator?.averageDigg || 0),
    },
    {
      icon: IconBoldVideoPlay,
      label: 'Average view count',
      value: formatBigNumber(competitorFlatCreator?.averageVideoViews || 0),
    },
    {
      icon: IconBoldChart1,
      label: 'Engagement rate',
      value:
        parseFloat(
          (competitorFlatCreator?.averageEngagementRate || 0).toFixed(1),
        ) || 0,
    },
    {
      icon: IconBoldEmojiHappy,
      label: 'Sentiment',
      value: competitorFlatCreator?.averageCreatorSentiment,
    },
  ];

  const getRenderSummary = useCallback(
    (icon: any, label: React.ReactNode, value: string | number) => {
      const Icon = icon;
      return (
        <Typography
          variant="headline-sm"
          display="flex"
          width="100%"
          justifyContent="space-between"
        >
          <Box
            flex={1}
            component="span"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <Icon size={14} />
            {label}
          </Box>
          <Box flex={1} component="span" color={theme.colors?.utility[800]}>
            {value}
          </Box>
        </Typography>
      );
    },
    [],
  );

  if (loading) {
    return <CompetitorSummarySkeleton />;
  }

  return (
    <Box
      display="flex"
      gap={4}
      flexDirection="column"
      p={8}
      sx={{
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Typography
        variant="headline-sm"
        color={theme.colors?.utility[700]}
        pb={1}
      >
        {filters.contentFilter ===
        CompetitorCreatorSocialPostContentFilter.Owned
          ? 'Brand'
          : 'UGC'}{' '}
        Summary
      </Typography>
      {summaryData.map((item) =>
        getRenderSummary(item.icon, item.label, item.value || 0),
      )}
    </Box>
  );
};
