import { gql, useApolloClient } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { useUserContext } from 'contexts/users/User.context';
import {
  GeneralPermissionSelector,
  PermissionApproveRequest,
  PermissionCreateRequest,
  PermissionMemberItem,
  useCopyLink,
  usePermissionAddMemberInput,
  usePermissionUserSearch,
} from 'features/permission';
import {
  POST_INVITE_MEMBER_FRAGMENT_POST_PERMISSION_MEMBER,
  PostPermissionMember,
} from 'features/post-permission/components';
import {
  UpdatePostPermissionsDataInput,
  usePostPermissionMutations,
} from 'features/post-permission/hooks';
import { USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW } from 'features/user-profile';
import {
  GeneralPermission,
  PermissionLevel,
  PermissionRequestFragmentPermissionApproveRequestFragmentDoc,
  PostFragmentPostPermissionDialogBodyFragment,
  PostInviteMemberFragmentPostPermissionMemberFragment,
  PostPermission,
  PostType,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { theme } from 'styles/theme';
import { useImmer } from 'use-immer';
import { evictObject, modifyObject } from 'utils/apollo';
import { getFullName } from 'utils/users';

export const POST_FRAGMENT_POST_PERMISSION_DIALOG_BODY = gql`
  fragment PostFragmentPostPermissionDialogBody on PostModel {
    id
    type
    generalPermission
    permissionLevel
    organizationId
    organizationName
    myPermissions
    permissionRequests {
      ...PermissionRequestFragmentPermissionApproveRequest
    }
    creator {
      ...UserProfileFragmentUserProfilePermissionItemView
    }
    inviteMembers {
      ...PostInviteMemberFragmentPostPermissionMember
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW}
  ${POST_INVITE_MEMBER_FRAGMENT_POST_PERMISSION_MEMBER}
  ${PermissionRequestFragmentPermissionApproveRequestFragmentDoc}
`;

type PostPermissionDialogBodyProps = {
  post: PostFragmentPostPermissionDialogBodyFragment;
  setHasPendingChanges?: (state: boolean) => void;
  updateBtnRef?: React.RefObject<HTMLButtonElement>;
  onCallbackAfterUpdate?: VoidFunction;
  onRequestCreateAccess: VoidFunction;
};

export const PostPermissionDialogBody = (
  props: PostPermissionDialogBodyProps,
) => {
  const {
    post,
    setHasPendingChanges,
    updateBtnRef,
    onCallbackAfterUpdate,
    onRequestCreateAccess,
  } = props;
  const { renderCopyButton, renderCopyLinkToast } = useCopyLink();

  const { user, isWorkOrganization } = useUserContext();
  const navigate = useGuardNavigate();
  const location = useLocation();

  const originalInviteMembers: PostInviteMemberFragmentPostPermissionMemberFragment[] =
    useMemo(() => {
      return post.inviteMembers;
    }, [post.inviteMembers]);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [inviteMembers, setInviteMembers] = useImmer<
    PostInviteMemberFragmentPostPermissionMemberFragment[]
  >(originalInviteMembers);

  const [inputValue, setInputValue] = useState('');
  const [newSelectedUsers, setNewSelectedUsers] = useState<
    UserProfileFragmentUserProfilePermissionItemViewFragment[]
  >([]);
  const [newSelectedUsersPermission, setNewSelectedUsersPermission] =
    useState<PermissionLevel>(PermissionLevel.View);
  const [note, setNote] = useState('');
  const [shouldCreateComment, setShouldCreateComment] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const {
    renderInput,
    toggleUserSelect,
    resetInput,
    renderPermissionSelectView,
  } = usePermissionAddMemberInput({
    entityOrganizationId: post.organizationId,
    onSearchStrUpdate: setInputValue,
    onMembersUpdate: setNewSelectedUsers,
    onPermissionUpdate: setNewSelectedUsersPermission,
    onShouldCreateCommentUpdate: setShouldCreateComment,
    onNoteUpdate: setNote,
  });

  const [overallPermission, setOverallPermission] = useImmer<{
    generalPermission: GeneralPermission;
    permission?: PermissionLevel;
  }>({
    generalPermission: post.generalPermission,
    permission: post.permissionLevel,
  });

  // update inviteMembers whenever post data changes
  useEffect(() => {
    setInviteMembers(originalInviteMembers);
  }, [originalInviteMembers]); // eslint-disable-line react-hooks/exhaustive-deps

  const invitedUser = useMemo(() => {
    if (
      post.creator.id === user?.id ||
      post.myPermissions.includes(PostPermission.Update)
    ) {
      return null;
    }
    const _invitedUser = inviteMembers.find((m) => m.user.id === user?.id);

    return _invitedUser || user;
  }, [inviteMembers, post, user]);

  // update general permission whenever post data changes
  useEffect(() => {
    setOverallPermission({
      generalPermission: post.generalPermission,
      permission: post.permissionLevel,
    });
  }, [post.generalPermission, post.permissionLevel]); // eslint-disable-line react-hooks/exhaustive-deps

  const { suggestedUsers } = usePermissionUserSearch({
    searchStr: inputValue,
    existingMembers: inviteMembers.map((x) => x.user),
  });
  const hasFullAccess = post.myPermissions.includes(PostPermission.Update);

  const [loading, setLoading] = useState(false);

  const filterExistingMembers = useMemo(() => {
    return inviteMembers.filter(
      (m) =>
        m.user.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        (getFullName(m.user).toLowerCase().includes(inputValue.toLowerCase()) &&
          m.id !== user?.id),
    );
  }, [inviteMembers, inputValue, user]);

  const handleInviteMemberPermissionChange = (
    postInviteMember: PostInviteMemberFragmentPostPermissionMemberFragment,
    remove?: boolean,
  ) => {
    const memberIndex = inviteMembers.findIndex(
      (c) => c.id === postInviteMember.id,
    );

    if (memberIndex === -1) {
      return;
    }

    if (remove) {
      setInviteMembers((draft) => {
        draft.splice(memberIndex, 1);
      });
    } else {
      setInviteMembers((draft) => {
        draft[memberIndex].permissionLevel = postInviteMember.permissionLevel;
      });
    }
  };

  const hasExistingMemberDataChanged = useMemo(() => {
    return (
      JSON.stringify(originalInviteMembers) !== JSON.stringify(inviteMembers)
    );
  }, [originalInviteMembers, inviteMembers]);

  const hasOverallPermissionDataChanged = useMemo(() => {
    return (
      post.generalPermission !== overallPermission.generalPermission ||
      post.permissionLevel !== overallPermission.permission
    );
  }, [post.generalPermission, post.permissionLevel, overallPermission]);

  const isThereNewInvite = useMemo(() => {
    return newSelectedUsers.length > 0;
  }, [newSelectedUsers]);

  const { updatePostPermissions } = usePostPermissionMutations({
    postIds: [post.id],
  });
  const client = useApolloClient();

  const updatePermissions = async () => {
    const updatedUserList: {
      user: UserProfileFragmentUserProfilePermissionItemViewFragment;
      permissionLevel: PermissionLevel;
    }[] = [];
    const userIdsToBeRemoved: string[] = [];

    if (hasExistingMemberDataChanged) {
      updatedUserList.push(
        ...inviteMembers.map((n) => ({
          user: n.user,
          permissionLevel: n.permissionLevel,
        })),
      );

      const inviteMemberIds = inviteMembers.map((m) => m.id);
      userIdsToBeRemoved.push(
        ...originalInviteMembers
          .filter((m) => !inviteMemberIds.includes(m.id))
          .map((m) => m.user.id),
      );
    }

    if (isThereNewInvite) {
      updatedUserList.push(
        ...newSelectedUsers.map((n) => ({
          user: n,
          permissionLevel: newSelectedUsersPermission,
        })),
      );
    }

    // We should send an inbox notification to the user from this dialog
    // This dialog is used when a user attempts to invite someone to a single post
    const inputData: UpdatePostPermissionsDataInput = {
      sendNotification: true,
    };

    if (updatedUserList.length > 0) {
      inputData.inviteMembers = {
        members: updatedUserList,
      };
    }

    if (userIdsToBeRemoved.length > 0) {
      inputData.removeMembers = {
        userIds: userIdsToBeRemoved,
      };
    }

    if (hasOverallPermissionDataChanged) {
      inputData.generalPermission = {
        generalPermission: overallPermission.generalPermission,
        permissionLevel: overallPermission.permission,
      };
    }

    inputData.note = note;
    inputData.shouldCreateComment = shouldCreateComment;

    setLoading(true);
    await updatePostPermissions(inputData);

    modifyObject(client.cache, post.id, 'PostModel', {
      inviteMembers: () => [
        ...inviteMembers,
        ...newSelectedUsers.map((n) => ({
          user: { ...n },
          permissionLevel: newSelectedUsersPermission,
          id: n.id,
          __typename: 'PostInviteMemberModel',
        })),
      ],
      generalPermission: () => overallPermission.generalPermission,
      permissionLevel: () => overallPermission.permission,
    });

    resetInput();
    setLoading(false);

    // when user removes his/her access to the post, we gracefully return them back to the previous location
    if (
      user &&
      post.creator.id !== user.id &&
      overallPermission.generalPermission === GeneralPermission.InviteOnly &&
      (userIdsToBeRemoved.includes(user.id) ||
        !updatedUserList.map((u) => u.user.id).includes(user.id))
    ) {
      evictObject(client.cache, post.id, 'PostModel');
      navigate((location?.state as any)?.backgroundLocation);
    } else if (onCallbackAfterUpdate) {
      onCallbackAfterUpdate();
    }
  };

  const isUpdateDisabled =
    (!hasExistingMemberDataChanged &&
      !isThereNewInvite &&
      !hasOverallPermissionDataChanged) ||
    loading;

  useEffect(() => {
    setHasPendingChanges?.(!isUpdateDisabled);
  }, [isUpdateDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.onfocus = () => {
        setIsInputFocused(true);
      };
      searchInputRef.current.onblur = () => {
        setTimeout(() => {
          setIsInputFocused(false);
        }, 200);
      };
    }
  }, [searchInputRef]);

  const renderMembers = useMemo(() => {
    if (inputValue) {
      return (
        <>
          {filterExistingMembers.length > 0 && (
            <Box sx={{ p: theme.spacing(4, 6) }}>
              <Box mb={2}>
                <Typography
                  fontWeight={600}
                  variant="body-lg"
                  color={theme.colors?.utility['800']}
                >
                  In this {post.type === PostType.Note ? 'note' : 'post'}
                </Typography>
              </Box>

              {filterExistingMembers.map((inviteMember) => (
                <PostPermissionMember
                  key={inviteMember.id}
                  entityOrganizationId={post.organizationId}
                  postInviteMember={inviteMember}
                  onPermissionChange={handleInviteMemberPermissionChange}
                  readonly={!hasFullAccess}
                />
              ))}
            </Box>
          )}

          <Box
            sx={{
              p: theme.spacing(4, 6),
              paddingTop: filterExistingMembers.length === 0 ? 4 : 0,
            }}
          >
            {suggestedUsers.length === 0 && inputValue !== '' && (
              <Typography
                fontWeight={600}
                variant="body-lg"
                color={theme.colors?.utility['800']}
              >
                Keep typing a valid email...
              </Typography>
            )}
            {suggestedUsers.length > 0 && (
              <>
                <Box mb={4}>
                  <Typography
                    fontWeight={600}
                    variant="body-lg"
                    color={theme.colors?.utility['800']}
                  >
                    Not in this {post.type === PostType.Note ? 'note' : 'post'}
                  </Typography>
                </Box>
                {suggestedUsers.slice(0, 5).map((suggestedUser) =>
                  suggestedUser ? (
                    <PermissionMemberItem
                      key={suggestedUser.id}
                      userProfile={suggestedUser}
                      entityOrganizationId={post.organizationId}
                      totalMembers={
                        newSelectedUsers.filter(
                          (u) =>
                            !user?.organization.users
                              .filter(
                                (user) => user.shouldBeCountedTowardPaywall,
                              )
                              .some(
                                (internalUser) => internalUser.id === u.id,
                              ) && u.organization.id === post.organizationId,
                        ).length
                      }
                      totalGuests={
                        newSelectedUsers.filter((u) => {
                          if (isWorkOrganization) {
                            return (
                              !user?.organization.externalUsers
                                .filter(
                                  (user) => user.shouldBeCountedTowardPaywall,
                                )
                                .some((guest) => guest.id === u.id) &&
                              u.organization.id !== post.organizationId
                            );
                          }
                          // everyone is a guests for non-work organization
                          return true;
                        }).length
                      }
                      type="suggested"
                      readonly={!hasFullAccess}
                      endAdornmentProps={{
                        suggested: {
                          isSelected: !!newSelectedUsers.find(
                            (u) => u.email === suggestedUser.email,
                          ),
                          toggleSelect: toggleUserSelect,
                        },
                      }}
                    />
                  ) : null,
                )}
              </>
            )}
          </Box>
        </>
      );
    }
    if (inviteMembers.length === 0 && isInputFocused) {
      return (
        <Box sx={{ p: theme.spacing(4, 6) }}>
          <Box mb={4}>
            <Typography
              fontWeight={600}
              variant="body-lg"
              color={theme.colors?.utility['800']}
            >
              Suggested
            </Typography>
          </Box>
          {suggestedUsers.slice(0, 6).map((suggestedUser) =>
            user ? (
              <PermissionMemberItem
                key={suggestedUser.id}
                userProfile={suggestedUser}
                entityOrganizationId={post.organizationId}
                totalMembers={
                  newSelectedUsers.filter(
                    (u) =>
                      !user?.organization.users
                        .filter((user) => user.shouldBeCountedTowardPaywall)
                        .some((internalUser) => internalUser.id === u.id) &&
                      u.organization.id === post.organizationId,
                  ).length
                }
                totalGuests={
                  newSelectedUsers.filter((u) => {
                    if (isWorkOrganization) {
                      return (
                        !user?.organization.externalUsers
                          .filter((user) => user.shouldBeCountedTowardPaywall)
                          .some((guest) => guest.id === u.id) &&
                        u.organization.id !== post.organizationId
                      );
                    }
                    // everyone is a guests for non-work organization
                    return true;
                  }).length
                }
                readonly={!hasFullAccess}
                type="suggested"
                endAdornmentProps={{
                  suggested: {
                    isSelected: !!newSelectedUsers.find(
                      (u) => u.email === suggestedUser.email,
                    ),
                    toggleSelect: toggleUserSelect,
                  },
                }}
              />
            ) : null,
          )}
        </Box>
      );
    }
    return (
      <Box sx={{ p: theme.spacing(4, 6) }}>
        {post.creator && (
          <PermissionMemberItem
            userProfile={post.creator}
            entityOrganizationId={post.organizationId}
            type="owner"
          />
        )}

        {filterExistingMembers.map((inviteMember) => (
          <PostPermissionMember
            key={inviteMember.id}
            entityOrganizationId={post.organizationId}
            postInviteMember={inviteMember}
            onPermissionChange={handleInviteMemberPermissionChange}
            readonly={!hasFullAccess}
          />
        ))}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isInputFocused,
    inviteMembers,
    post,
    inputValue,
    suggestedUsers,
    filterExistingMembers,
    hasFullAccess,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          borderRadius: theme.spacing(6, 6, 0, 0),
          display: 'flex',
          flexDirection: 'column',
          backdropFilter: 'blur(20px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: `rgba(250, 243, 236, 0.85)`,
            gap: 2,
            padding: theme.spacing(4, 6),
            ...(newSelectedUsers.length
              ? { paddingLeft: 4, alignItems: 'flex-start' }
              : {}),
            maxHeight: 110,
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 0,
            msOverflowStyle: 'none',
          }}
        >
          {hasFullAccess ? (
            renderInput({
              sx: { width: '100%' },
              inputRef: searchInputRef,
            })
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
              }}
            >
              <IconBoldInfoCircle
                size={16}
                color={theme.colors?.utility[600]}
                style={{
                  marginTop: 2,
                }}
              />
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[600]}
              >
                Only people with full access can change permissions
              </Typography>
            </Box>
          )}
          {renderCopyButton()}
        </Box>
        <Box
          sx={{
            overflowY: 'auto',
            backgroundColor: 'rgba(250, 243, 236, 0.8)',
            height: 408,
          }}
        >
          {!inputValue && newSelectedUsers.length ? (
            renderPermissionSelectView({})
          ) : (
            <>
              {(invitedUser ||
                ((post.permissionRequests || [])?.length > 0 &&
                  post.creator.id === user?.id)) && (
                <Box
                  padding={theme.spacing(6, 6, 0, 6)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 6,
                  }}
                >
                  {invitedUser ? (
                    <PermissionCreateRequest
                      entityOrganizationId={post.organizationId}
                      onRequestCreateAccess={onRequestCreateAccess}
                      permissionLevel={invitedUser['permissionLevel']}
                      isRequestCreated={post.permissionRequests?.some(
                        (r) => r.user.id === user?.id,
                      )}
                    />
                  ) : (
                    post.creator.id === user?.id &&
                    (post.permissionRequests || []).length > 0 && (
                      <>
                        <Typography
                          variant="headline-xs"
                          color={theme.colors?.utility[700]}
                        >
                          {(post.permissionRequests || []).length} person
                          requested access
                        </Typography>

                        {post.permissionRequests?.map((request) => (
                          <PermissionApproveRequest
                            entityOrganizationId={post.organizationId}
                            request={request}
                          />
                        ))}
                      </>
                    )
                  )}
                  <Divider
                    sx={{
                      borderColor: 'rgba(35, 6, 3, 0.07)',
                      borderWidth: 0.5,
                    }}
                  />
                </Box>
              )}

              {renderMembers}
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          backdropFilter: hasFullAccess ? 'blur(20px)' : 'none',
        }}
      >
        {post.generalPermission && (
          <GeneralPermissionSelector
            entityType="post"
            organizationId={post.organizationId}
            organizationName={post.organizationName}
            initialGeneralPermission={post.generalPermission}
            readonly={!hasFullAccess}
            initialPermissionLevel={
              post.permissionLevel || PermissionLevel.View
            }
            onGeneralPermissionChange={(generalPermission, permissionLevel) => {
              if (
                post.generalPermission !== generalPermission ||
                post.permissionLevel !== permissionLevel
              ) {
                setOverallPermission((draft) => {
                  draft.generalPermission = generalPermission;
                  draft.permission = permissionLevel;
                });
              }
            }}
            componentProps={{
              sx: {
                bgcolor: 'rgba(250, 243, 236, 0.85)',
                backdropFilter: 'blur(20px)',
                p: theme.spacing(4, 6),
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: hasFullAccess ? 0 : theme.spacing(0, 0, 6, 6),
              },
            }}
          />
        )}

        <Button
          ref={updateBtnRef}
          disabled={isUpdateDisabled}
          variant="primary-alt"
          fullWidth
          onClick={updatePermissions}
          sx={{
            height: 42,
            borderRadius: theme.spacing(0, 0, 6, 6),
            opacity: hasFullAccess ? 1 : 0,
          }}
          startIcon={loading ? <CircularProgress size="1rem" /> : null}
        >
          Update
        </Button>
      </Box>
      {renderCopyLinkToast()}
    </Box>
  );
};
