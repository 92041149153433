import { Box } from '@mui/material';
import { Node } from '@tiptap/pm/model';
import { Editor } from '@tiptap/react';
import { clamp, throttle } from 'lodash';
import { MouseEventHandler, useRef } from 'react';
import { NODE_COLUMN } from '../../constants';

export type ResizerProps = {
  node: Node;

  /**
   * This should always be the column list node
   */
  parentNode: Node;

  editor: Editor;
  getPos: () => number;
  getParentPos: () => number;
};

export const Resizer = (props: ResizerProps) => {
  const { parentNode, editor, getPos, getParentPos } = props;

  const columnListNode = parentNode;
  const columnListWidthRef = useRef(0);

  // Keep track of the original data when user starts dragging
  const mouseDownOriginalDataRef = useRef<{
    pos: { x: number; y: number };
    totalFlex: number;
    combinedFlex: number;
    currentColumnFlex: number;
    currentColumnPos: number;
    currentColumnNode: Node | null;
    nextColumnFlex: number;
    nextColumnPos: number;
    nextColumnNode: Node | null;
  }>({
    pos: { x: 0, y: 0 },
    totalFlex: 0,
    combinedFlex: 0,
    currentColumnFlex: 0,
    currentColumnPos: 0,
    currentColumnNode: null,
    nextColumnFlex: 0,
    nextColumnPos: 0,
    nextColumnNode: null,
  });

  const onMouseDown: MouseEventHandler = (event) => {
    const columnListNodeDOM = editor.view.nodeDOM(getParentPos()) as Element;
    columnListWidthRef.current = columnListNodeDOM.clientWidth;

    // Disable pointer events on the document to prevent other elements from capturing the mouse event
    const docEl = document.querySelector(
      '.tiptap.ProseMirror',
    ) as HTMLDivElement;
    if (docEl) {
      docEl.style.pointerEvents = 'none';
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    /**
     * The below code is to get current column and next column related data: node, pos, etc.
     * and save it to the ref so that we can use it in onMouseMove.
     *
     * These data will be the original reference when we need to calculate the new flex of the columns
     * once users start dragging.
     */
    const resolvedColumnPos = editor.view.state.doc.resolve(getPos());
    const currentColumnNode = editor.view.state.doc.nodeAt(
      resolvedColumnPos.pos,
    );

    if (!currentColumnNode) {
      return;
    }

    const nextColumnPos = resolvedColumnPos.pos + currentColumnNode.nodeSize;
    const nextColumnNode = editor.view.state.doc.nodeAt(nextColumnPos);

    if (!nextColumnNode) {
      return;
    }

    // Calculate total flex-basis of all columns
    // Need to loop through all columns to get the total flex-basis
    let totalFlex = 0;
    columnListNode.descendants((node) => {
      if (node.type.name === NODE_COLUMN) {
        totalFlex += Number.isNaN(node.attrs.flex) ? 1 : node.attrs.flex;
      }
    });

    const currentColumnFlex = Number.isNaN(currentColumnNode.attrs.flex)
      ? 1
      : currentColumnNode.attrs.flex;
    const nextColumnFlex = Number.isNaN(nextColumnNode.attrs.flex)
      ? 1
      : nextColumnNode.attrs.flex;

    // Keep track of combined flex of these 2 columns
    // When we calculate the new flex of these 2 columns, we need to make sure they will not exceed this value
    const combinedFlex = currentColumnFlex + nextColumnFlex;

    mouseDownOriginalDataRef.current = {
      pos: { x: event.clientX, y: event.clientY },
      totalFlex,
      combinedFlex,
      currentColumnFlex,
      currentColumnPos: resolvedColumnPos.pos,
      currentColumnNode,
      nextColumnFlex,
      nextColumnPos,
      nextColumnNode,
    };
  };

  const onMouseUp = () => {
    const docEl = document.querySelector(
      '.tiptap.ProseMirror',
    ) as HTMLDivElement;
    if (docEl) {
      docEl.style.pointerEvents = 'auto';
    }

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  // Calculate new width based on user's drag
  // We'll use flex-basis in number (1, 2, 3, etc.) because it'll allow us to add more columns
  // without having to recalculate the width
  const onMouseMove = throttle((event: MouseEvent) => {
    const {
      pos: { x },
      totalFlex,
      combinedFlex,
      currentColumnFlex: originalCurrentColumnFlex,
      currentColumnPos,
      currentColumnNode,
      nextColumnPos,
      nextColumnNode,
    } = mouseDownOriginalDataRef.current;

    if (!currentColumnNode || !nextColumnNode) {
      return;
    }

    // Percentage of width from current pivot point -> mouse
    const widthPercentageDelta =
      ((event.clientX - x) / columnListWidthRef.current) * 100;
    const flexDelta = (widthPercentageDelta / 100) * totalFlex;

    // Clamp: [1/10 of the total flex, combinedFlex - 1/10 of the total flex]
    const currentColumnFlex = clamp(
      originalCurrentColumnFlex + flexDelta,
      totalFlex / 10,
      combinedFlex - totalFlex / 10,
    );
    const nextColumnFlex = combinedFlex - currentColumnFlex;

    // Update the flex of the columns
    const tr = editor.view.state.tr;
    tr.setNodeMarkup(currentColumnPos, undefined, {
      ...currentColumnNode.attrs,
      flex: currentColumnFlex,
    });
    tr.setNodeMarkup(nextColumnPos, undefined, {
      ...nextColumnNode.attrs,
      flex: nextColumnFlex,
    });

    editor.view.dispatch(tr);
  }, 100);

  return (
    <Box
      component="button"
      className="column-resizer"
      sx={{
        cursor: 'ew-resize',
        width: 30,
        height: '100%',
        display: 'flex',
        '&:hover': {
          '.column-resizer-line': {
            opacity: 1,
          },
        },
      }}
      onMouseDown={onMouseDown}
      draggable
    >
      <Box
        className="column-resizer-line"
        sx={{
          width: 4,
          height: '100%',
          backgroundColor: 'rgba(250, 243, 236, 0.40)',
          opacity: 0,
          transition: 'opacity 0.2s',
          mx: 'auto',
        }}
      />
    </Box>
  );
};
