import { Box } from '@mui/material';
import { AnnotationPopover } from 'components/common/Annotation/Common/RenderAnnotation/AnnotationPopover';
import { StyledAnnotationAvatar } from 'components/common/Annotation/Common/RenderAnnotation/styles';
import { Avatar } from 'components/common/AvatarGroup';
import { PostAnnotationFragmentAnnotationFragment } from 'graphql/generated';
import { useRef } from 'react';

interface Props {
  duration: number;
  onAnnotationClicked: (
    annotation: PostAnnotationFragmentAnnotationFragment | null,
  ) => void;
  clickedAnnotation: PostAnnotationFragmentAnnotationFragment | null;
  annotations: PostAnnotationFragmentAnnotationFragment[];
}

export const RenderSeekbarHighlight = ({
  duration,
  onAnnotationClicked,
  clickedAnnotation,
  annotations,
}: Props) => {
  const bubbleRefs = useRef<HTMLDivElement[]>([]);

  const canShowPopover =
    clickedAnnotation && !clickedAnnotation.x && !clickedAnnotation.y;

  return (
    <Box sx={{ position: 'relative' }}>
      {annotations.map((annotation, i) => (
        <>
          <StyledAnnotationAvatar
            style={{
              position: 'absolute',
              left: `${(annotation.time! / duration) * 100}%`,
              bottom: 0,
              opacity: annotation.isResolved ? 0.7 : 1,
            }}
            className="annotation__icon"
            onClick={() => {
              onAnnotationClicked(annotation);
            }}
            ref={(el: HTMLDivElement) => (bubbleRefs.current[i] = el)}
          >
            <Avatar user={annotation.createdBy} size={24} />
          </StyledAnnotationAvatar>
          {canShowPopover && clickedAnnotation.id === annotation.id && (
            <AnnotationPopover
              anchorEl={bubbleRefs.current[i]}
              annotation={clickedAnnotation}
              onClose={() => {
                onAnnotationClicked(null);
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          )}
        </>
      ))}
    </Box>
  );
};
