import { Components, Theme } from '@mui/material';

export const autocompleteOverrides: Components<Theme>['MuiAutocomplete'] = {
  styleOverrides: {
    root: () => {
      return {
        '& .MuiOutlinedInput-root': {
          padding: 4,
        },
      };
    },
    paper: () => {
      return {
        '& .MuiList-root': {
          padding: 0,
        },
      };
    },
    listbox: () => {
      return {
        padding: 0,
      };
    },
  },
};
