import { Box, Portal, Snackbar, Typography } from '@mui/material';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { useState } from 'react';
import { theme } from 'styles/theme';

export const useRenderPostReportedSnackbar = () => {
  const [showToast, setShowToast] = useState(false);

  const onShowToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const renderToast = () => (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          width: 'fit-content',
          '& .MuiPaper-root': {
            minWidth: 'fit-content',
          },
        }}
        ContentProps={{
          sx: {
            bgcolor: theme.colors?.primary.black,
            borderRadius: theme.spacing(3),
            border: 'none',
            ...theme.typography['headline-sm'],
            padding: theme.spacing(0, 8),
            color: theme.colors?.primary.white,
          },
        }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => setShowToast(false)}
        message={
          <Box display="flex" alignItems="center" gap={2}>
            <IconBoldInfoCircle size={16} />{' '}
            <Typography variant="headline-sm">
              Post reported and removed from your feed
            </Typography>
          </Box>
        }
      />
    </Portal>
  );

  return { renderToast, showToast: onShowToast };
};
