import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CommandHandler } from 'contexts/commands/types';
import {
  OrganizationBillingFragmentUserContextFragment,
  PostFragmentPostCommandFragment,
  useGetCollectionForPostUploadFilesHandlerQuery,
} from 'graphql/generated';
import {
  ResourceUploadItem,
  useResourceUploadQueue,
  ResourceData,
  ResourceUploadType,
} from 'hooks/useResourceUploadQueue';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useRef, useState } from 'react';
import { useImmer } from 'use-immer';
import {
  BillingFileDeliveryPaywall,
  BillingUpgradeModal,
} from 'features/billing';
import { useUserContext } from 'contexts/users/User.context';
import { Parser } from 'utils/parser';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import { gql } from '@apollo/client';
import { usePostHandlers } from '../hooks/usePostHandlers';
import { StatusPopup } from './StatusPopup';

// eslint-disable-next-line
gql`
  query GetCollectionForPostUploadFilesHandler($id: String!) {
    collection(id: $id) {
      id
      organizationId
    }
  }
`;

/**
 * Type of a file with id, it can be uuid to mark the file add to note value
 */
export type FileWithId = {
  file: File;
  id?: string;
};

/**
 * Type of a command handler for file upload flow
 */
export type PostUploadFilesHandlerCallbacks = {
  onUploadDone?: (resource: ResourceUploadItem, commandId?: string) => void;
  onUploadProgress?: (resource: ResourceUploadItem, progress: number) => void;
  onUploadStart?: VoidFunction;
};

export type PostUploadFilesHandlerContext = {
  initialFiles?: FileWithId[];
  skipFileBrowser?: boolean;
  skipCreatePost?: boolean;
  callbacks?: PostUploadFilesHandlerCallbacks;
  shouldShowAfterCreationDialog?: boolean;
  taskId?: string;
  collectionId?: string;
};

export const PostUploadFilesHandler: CommandHandler<
  COMMAND_TYPE.POST_UPLOAD_FILES
> = (props) => {
  const analytics = useAnalytics();

  const { commandId, context } = props;

  const { updateActiveCommand, triggerCommand } = useCommandContext();

  const { collectionId: collectionIdFromParams } = useCollectionIdFromParams();
  const collectionId = context?.collectionId ?? collectionIdFromParams;

  const { orgBilling, joinedOrgBillings } = useUserContext();

  const { data: collectionData, loading: collectionDataLoading } =
    useGetCollectionForPostUploadFilesHandlerQuery({
      variables: {
        id: collectionId ?? '',
      },
      skip: !collectionId,
    });

  const [collectionOrgBilling, setCollectionOrgBilling] =
    useState<OrganizationBillingFragmentUserContextFragment>();

  // make sure to get the billing from the organization where collection belongs
  useEffect(() => {
    if (!collectionId) {
      setCollectionOrgBilling(orgBilling);
    } else if (collectionId && !collectionDataLoading && collectionData) {
      if (
        collectionData.collection.organizationId === orgBilling?.organizationId
      ) {
        setCollectionOrgBilling(orgBilling);
      } else {
        setCollectionOrgBilling(
          joinedOrgBillings?.find(
            (j) =>
              j.organizationId === collectionData.collection.organizationId,
          ) ?? orgBilling,
        );
      }
    }
  }, [
    collectionId,
    collectionData,
    collectionDataLoading,
    joinedOrgBillings,
    orgBilling,
  ]);

  const {
    isOpen: upgradeOpen,
    onOpen: upgradeOnOpen,
    onClose: upgradeOnClose,
  } = useDisclosure();

  const { onCreateFilePost } = usePostHandlers();
  const [createdPosts, setCreatedPosts] = useState<
    PostFragmentPostCommandFragment[]
  >([]);

  // Analytics
  const [postCreationStartAt, setPostCreationStartAt] = useState<number>();

  const [filesToBeUploaded, setFilesToBeUploaded] = useImmer<ResourceData[]>(
    [],
  );

  const { allResources, addResourcesToUploadQueue, clearAllResources } =
    useResourceUploadQueue({
      progressHandler: {
        onUploadDone: (resource: ResourceUploadItem) => {
          if (!resource.uploadedInfo?.url || !resource.uploadedInfo.metaData) {
            return;
          }

          // auto create post after upload file done
          if (!context?.skipCreatePost) {
            onCreateFilePost({
              attachment: {
                name: resource.resource.name,
                url: resource.uploadedInfo?.url,
                metaData: resource.uploadedInfo.metaData,
              },
              taskId: context?.taskId,
              // Even though we do have a logic to get collectionId in onCreateFilePost, we will
              // provide collectionId here to ensure that the collectionId is included in the closure
              // of the callback function. This is to prevent uploading files to the wrong collection
              // when users are uploading multiple files & switching collections in between.
              collectionId: resource.resource.collectionId,
            })
              .then((post) => {
                if (post) {
                  setCreatedPosts((pre) => [...pre, post]);
                }
              })
              .catch(() => {
                updateActiveCommand(commandId, {
                  status: 'error',
                });

                // Reset when error
                setPostCreationStartAt(undefined);
              });
          }
          context?.callbacks?.onUploadDone?.(resource, commandId);
        },
        onUploadStart: () => {
          updateActiveCommand(commandId, { status: 'loading' });
        },
        onUploadProgress: context?.callbacks?.onUploadProgress,
      },
    });

  useEffect(() => {
    if (
      allResources.length > 0 &&
      createdPosts.length === allResources.length
    ) {
      if (
        createdPosts.length > 0 &&
        context?.shouldShowAfterCreationDialog !== false
      ) {
        // Trigger after-creation command
        triggerCommand(COMMAND_TYPE.POST_AFTER_CREATION, {
          initialValues: {
            posts: createdPosts,
          },
          postCreationStartAt,
        });
        // reset
        setCreatedPosts([]);
      }

      updateActiveCommand(commandId, {
        status: 'completed',
        data: createdPosts,
      });

      // upload and create done for all attachments
      clearAllResources();
      setFilesToBeUploaded([]);

      // Reset postCreationStartAt when all attachments are uploaded & posts are created
      setPostCreationStartAt(undefined);
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    allResources.length,
    clearAllResources,
    commandId,
    createdPosts,
    triggerCommand,
    updateActiveCommand,
  ]);

  // Analytics -- Track when we start uploading attachments
  useEffect(() => {
    // If there are attachments and we haven't started tracking yet
    if (allResources.length > 0 && !postCreationStartAt) {
      setPostCreationStartAt(performance.now());
    }
  }, [allResources.length]); // eslint-disable-line

  useEffect(() => {
    if (context?.initialFiles?.length) {
      const resources: ResourceData[] = context?.initialFiles
        ?.filter((i) => i)
        .map((file?: FileWithId) => ({
          id: file?.id,
          type: ResourceUploadType.Attachment,
          content: file?.file ?? '',
          name: file?.file.name ?? '',
          size: file?.file.size ?? 0,
          collectionId,
        }));

      setFilesToBeUploaded(resources);
    }
  }, [context?.initialFiles]); // eslint-disable-line

  const inputRef = useRef<HTMLInputElement>(null);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = Array.from(e.target.files as ArrayLike<File>).map((file) => ({
      type: ResourceUploadType.Attachment,
      content: file,
      name: file.name,
      size: file.size,
      collectionId,
    }));

    setFilesToBeUploaded(files);
  };

  useEffect(() => {
    if (!context?.skipFileBrowser) {
      analytics.track(EventName.UploadFilePostOpened);
      inputRef.current?.click();
    }
  }, [context?.skipFileBrowser]); // eslint-disable-line -- trigger flow on mount

  const uploadFiles = (resource: ResourceData[]) => {
    if (collectionOrgBilling?.fileStorageLimitInMb) {
      const totalSize = Parser.toMb(
        resource.map((f) => f.size ?? 0).reduce((accum, size) => accum + size),
      );

      if (
        collectionOrgBilling.fileStorageUsedInMb + totalSize <
        collectionOrgBilling.fileStorageLimitInMb
      ) {
        addResourcesToUploadQueue(resource);
      } else {
        upgradeOnOpen();
      }
    } else {
      addResourcesToUploadQueue(resource);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        multiple
        onChange={onChange}
        onClick={(event) => {
          // @ts-ignore -- this allows input to be able to choose the same file
          event.target.value = null;
        }}
        style={{ display: 'none' }}
      />

      {collectionOrgBilling && (
        <BillingFileDeliveryPaywall
          organizationBilling={collectionOrgBilling}
          files={filesToBeUploaded}
          onUpdateFiles={setFilesToBeUploaded}
          onReset={() => {
            setFilesToBeUploaded([]);
          }}
          onCancel={() => {
            setFilesToBeUploaded([]);
            inputRef.current?.click();
          }}
          onContinue={() => uploadFiles(filesToBeUploaded)}
        />
      )}

      <BillingUpgradeModal isOpen={upgradeOpen} onClose={upgradeOnClose} />

      {allResources.length > 0 && (
        <StatusPopup
          commandId={commandId}
          attachments={allResources}
          messages={{
            loading: 'Adding your assets ...',
            completed: 'Assets added',
            error: 'Your assets is down the drain. Please try again!',
          }}
          showStatusIcon
        />
      )}
    </>
  );
};
