import { Box } from '@mui/material';
import { Image } from 'components/common/Image';
import {
  AttachmentSearchHitFragmentItemSuffixFragment,
  CollectionSearchHitFragmentItemSuffixFragment,
  LinkSearchHitFragmentItemSuffixFragment,
  NoteSearchHitFragmentItemSuffixFragment,
  SearchableEntityType,
  TaskSearchHitFragmentItemSuffixFragment,
} from 'graphql/generated';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

type SearchItemSuffixProps = {
  searchHit:
    | NoteSearchHitFragmentItemSuffixFragment
    | LinkSearchHitFragmentItemSuffixFragment
    | AttachmentSearchHitFragmentItemSuffixFragment
    | TaskSearchHitFragmentItemSuffixFragment
    | CollectionSearchHitFragmentItemSuffixFragment;
  type: SearchableEntityType;
};

export const SearchItemSuffix = (props: SearchItemSuffixProps) => {
  const { searchHit, type } = props;

  const attachments = useMemo(() => {
    const data: {
      url: string;
      id: string;
    }[] = [];

    if (type === SearchableEntityType.Notes) {
      const note = searchHit as NoteSearchHitFragmentItemSuffixFragment;

      if (note.item.urlMetadata && note.item.urlMetadata.length > 0) {
        data.push(...note.item.urlMetadata);
      }
    } else if (type === SearchableEntityType.Collections) {
      const collection =
        searchHit as CollectionSearchHitFragmentItemSuffixFragment;

      const urlMetadata = collection.item.posts.flatMap(
        (post) => post.urlMetadata,
      );

      if (urlMetadata.length > 0) {
        data.push(...urlMetadata);
      }
    }

    return data;
  }, [searchHit, type]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2.5),
      }}
    >
      {attachments.map((item, idx) => {
        const parsedUrl = new URL(item.url);
        // TODO: remove it after fix blob url in backend
        if (parsedUrl?.protocol === 'blob:') {
          return null;
        }
        return (
          <Image
            key={idx}
            src={item.url}
            sx={{
              width: 'auto',
              height: theme.spacing(9),
              borderRadius: theme.spacing(3),
              border: `1px solid rgba(250, 243, 236, 0.50)`,
              overflow: 'hidden',
              objectFit: 'cover',
            }}
          />
        );
      })}
    </Box>
  );
};
