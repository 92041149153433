import {
  Box,
  Button,
  IconButton,
  Select,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { CustomController } from 'components/common/form/CustomController';
import { RadioMenuItem } from 'components/common/form/Select';
import { typography } from 'components/common/Typography/styles';
import { IconOutlineEdit2 } from 'components/icons/components/outline/IconOutlineEdit2';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme';

export type TopicRuleOption = {
  label: string;
  value: number;
};

export type TopicMinimumProcessingRuleInputProps = {
  label: string;
  value: number;
  options: TopicRuleOption[];
  sx?: SxProps;
  renderCustomValueLabel?: (customValue: number) => string;
  onChange: (value: number) => void;
  readOnly?: boolean;
};

export const TopicMinimumProcessingRuleInput = (
  props: TopicMinimumProcessingRuleInputProps,
) => {
  const {
    label,
    value,
    options = [],
    sx,
    renderCustomValueLabel,
    onChange,
    readOnly,
  } = props;

  // Custom value is selected if the value is provided but not found in the options
  const isCustomValueSelected =
    !!value && !options.some((option) => option.value === value);

  const [customValue, setCustomValue] = useState<number | undefined>(
    isCustomValueSelected ? value : undefined,
  );
  const [showCustomValueInput, setShowCustomValueInput] = useState(false);

  const {
    control,
    handleSubmit: handleSubmitCustomValue,
    reset: resetCustomValueForm,
  } = useForm({
    defaultValues: {
      customValue,
    },
  });

  useEffect(() => {
    if (isCustomValueSelected) {
      setCustomValue(value);
      resetCustomValueForm({ customValue: value });
    }
  }, [isCustomValueSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitCustomValue = handleSubmitCustomValue((values) => {
    const customValue = values.customValue
      ? Number.parseInt(values.customValue as unknown as string) // cast to string because TextField returns string
      : undefined;

    if (customValue) {
      setCustomValue(customValue);
      onChange(customValue);
    }

    setShowCustomValueInput(false);
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        ...sx,
      }}
    >
      <Typography variant="subhead-xl">{label}</Typography>
      <Select
        value={value}
        disabled={readOnly}
        sx={{
          bgcolor: theme.colors?.utility[300],
          height: '32px',
          borderRadius: 1,
          '.MuiSelect-select': {
            minHeight: '32px !important',
            lineHeight: '32px !important',
            py: '4px !important',
            pr: '32px !important',
            pl: '12px !important',
          },
          fieldset: {
            border: `1px solid ${theme.colors?.utility[400]}`,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              p: 3,
              minWidth: '266px !important',
            },
          },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        }}
        onChange={(event) =>
          onChange(Number.parseInt(event.target.value as string))
        }
        renderValue={(value) => {
          const selectedOption = options.find(
            (option) => option.value === value,
          );

          if (customValue && isCustomValueSelected) {
            return renderCustomValueLabel?.(customValue) || 'Custom';
          }

          return selectedOption?.label;
        }}
      >
        {options.map((option) => (
          <RadioMenuItem
            key={option.value}
            value={option.value}
            label={option.label}
            checked={option.value === value}
            sx={{
              '.MuiTypography-root': {
                fontWeight: 400,
              },
            }}
          />
        ))}
        <RadioMenuItem
          value={customValue}
          label={
            customValue ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 4,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="subhead-md">
                  {renderCustomValueLabel?.(customValue) || 'Custom'}
                </Typography>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowCustomValueInput(true);
                  }}
                >
                  <IconOutlineEdit2 size={16} />
                </IconButton>
              </Box>
            ) : (
              'Custom'
            )
          }
          checked={isCustomValueSelected}
          sx={{
            '.MuiTypography-root': {
              fontWeight: 400,
            },
          }}
          onClick={(e) => {
            // If custom value has not been selected and there's no existing custom value,
            // show the custom value input and prevent the default behavior of selecting
            if (!isCustomValueSelected) {
              e.preventDefault();
              e.stopPropagation();
              setShowCustomValueInput(!showCustomValueInput);
            }
          }}
        />
        {showCustomValueInput && (
          <Box
            component="form"
            onSubmit={onSubmitCustomValue}
            sx={{
              px: 3,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mt: 2,
            }}
          >
            <CustomController
              name="customValue"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  variant="outlined"
                  placeholder="Add a number"
                  autoFocus
                  sx={{
                    bgcolor: theme.colors?.utility[300],
                    borderRadius: 2,
                    borderColor: theme.colors?.utility[400],
                    input: {
                      ...typography['subhead-lg'],
                    },
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  setShowCustomValueInput(false);
                  resetCustomValueForm();
                }}
                sx={{
                  color: theme.colors?.primary.black,
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary-alt"
                size="small"
                sx={{
                  borderRadius: 50,
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        )}
      </Select>
    </Box>
  );
};
