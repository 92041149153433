import { Box, TextareaAutosizeProps } from '@mui/material';
import { TextareaAutosize } from 'components/common/form/TextareaAutosize';
import { theme } from 'styles/theme';

export type TextFieldProps = Omit<
  TextareaAutosizeProps,
  'value' | 'onChange'
> & {
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
};

export const TextField = (props: TextFieldProps) => {
  const { value, onChange, ...rest } = props;

  return (
    <Box
      sx={{
        'textarea::placeholder': {
          color: theme.colors?.utility[600],
        },
        lineHeight: 0,
      }}
    >
      <TextareaAutosize
        placeholder="Empty"
        defaultValue={value}
        onChange={(event) => onChange(event.target.value)}
        variant="inline"
        style={{
          background: 'transparent',
        }}
        maxRows={999}
        {...rest}
      />
    </Box>
  );
};
