import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Divider,
  IconButton,
  Switch,
  SxProps,
  Typography,
} from '@mui/material';
import { IconOutlineCloseCircle } from 'components/icons/components/outline/IconOutlineCloseCircle';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useUserContext } from 'contexts/users/User.context';
import {
  CommentContainerView,
  CommentContainerViewSkeleton,
  CommentFilter,
  CommentInviteMentionUserDialog,
  CommentSearch,
  CommentSidebar,
  CommentSidebarProps,
  useCommentInviteMemberHandlers,
  useCommentPermissionRequestHandlers,
  usePostCommentHandlers,
} from 'features/comments';
import { usePostManager } from 'features/juicebox/contexts';
import { getMentionedUserIds } from 'features/tiptap';
import {
  CommentFilter as CommentFilterType,
  CommentFragmentPostCommentViewFragmentDoc,
  CommentInputData,
  GeneralPermission,
  PostPermission,
  PostType,
  SortOrder,
  useGetCommentsForPostCommentListViewQuery,
  useGetPostForInviteMemberHandlersQuery,
} from 'graphql/generated';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { getCustomOperationContext } from 'utils/apollo';

export const COMMENT_FRAGMENT_POST_COMMENT_LIST_VIEW = gql`
  fragment CommentFragmentPostCommentListView on CommentModel {
    id
    ...CommentFragmentPostCommentView
  }
  ${CommentFragmentPostCommentViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCommentsForPostCommentListView($filters: CommentFilter!) {
    comments(filters: $filters) {
      id
      ...CommentFragmentPostCommentListView
    }
  }
  ${COMMENT_FRAGMENT_POST_COMMENT_LIST_VIEW}
`;

export type PostCommentListViewProps = {
  postId: string;
  sx?: SxProps;
  componentsProps?: CommentSidebarProps['componentsProps'];
  hasAnnotations?: boolean;
};

export const PostCommentListView = (props: PostCommentListViewProps) => {
  const { postId, sx, componentsProps, hasAnnotations } = props;
  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>([]);
  const [commentHtml, setCommentHtml] = useState<string>('');
  const [parentThreadId, setParentThreadId] = useState<string>('');

  const { setHideAnnotations, hideAnnotations } = usePostManager();

  const isMobileView = useMediaQueryMobile();
  const { data: postData } = useGetPostForInviteMemberHandlersQuery({
    variables: {
      postId,
    },
  });
  const myPermissions = postData?.post?.myPermissions || [];
  const { user } = useUserContext();

  const { onReactToComment, onReadAllPostComments, onCreatePostComment } =
    usePostCommentHandlers();
  const { onInvitePostMembers } = useCommentInviteMemberHandlers();
  const { onCreatePermissionRequest } = useCommentPermissionRequestHandlers();
  const searchState = useDisclosure();

  const {
    isOpen: isOpenInviteMentionUserDialog,
    onClose: onCloseInviteMentionUserDialog,
    onOpen: onOpenInviteMentionUserDialog,
  } = useDisclosure();

  const [filters, setFilters] = useState<CommentFilterType>({
    postId,
    query: '',
    filterOnlyParentThreads: true,
    sortBy: {
      field: 'createdAt',
      order: SortOrder.Asc,
    },
  });

  const { data, loading } = useGetCommentsForPostCommentListViewQuery({
    variables: {
      filters,
    },
    fetchPolicy: 'cache-and-network',
    context: getCustomOperationContext({
      suppressTopLevelToast: true,
    }),
  });
  const comments = useMemo(() => data?.comments || [], [data]);
  const isFirstLoading = loading && !comments.length;

  // make sure to rerender when postId changes
  useEffect(() => {
    setFilters({ ...filters, postId });
  }, [postId]); // eslint-disable-line

  useEffect(() => {
    onReadAllPostComments(postId);
  }, []); // eslint-disable-line

  const [newCommentId, setNewCommentId] = useState<string>('');

  const debouncedSetQuery = useMemo(
    () =>
      _.debounce(
        (val) =>
          setFilters({
            ...filters,
            query: val,
            filterOnlyParentThreads: val === '',
          }),
        300,
      ),
    [], // eslint-disable-line
  );

  const onCreateComment = (data: CommentInputData) => {
    onCreatePostComment(
      {
        postId,
        data,
      },
      filters,
      (id) => {
        setNewCommentId(id);
      },
    );
    onCloseInviteMentionUserDialog();
  };

  const onInviteMentioneUsersAndCreateComment = (data: CommentInputData) => {
    setCommentHtml(data.comment);
    setParentThreadId(data.parentThreadId || '');
    // The list of mentioned user ids that are not invited to the post
    const invitedMemberIds: string[] = [];

    // Get mentioned user ids from the comment html
    const mentionIds = getMentionedUserIds(data.comment).filter(
      (id) => id !== user?.id && id !== postData?.post?.creator.id,
    );

    // This is the list of members that are already invited to the post
    const inviteMembers = postData?.post?.inviteMembers || [];

    // Removed the mentioned user ids that are already into the post
    const filteredMentionIds: string[] = mentionIds.filter(
      (mentionId) =>
        !inviteMembers.some((member) => member.user.id === mentionId),
    );

    const users = [
      ...(user?.organization.externalUsers || []),
      ...(user?.organization.users || []),
    ];

    for (const mentionId of filteredMentionIds) {
      const member = users.find((u) => u.id === mentionId);

      // If general permission is set to "Org" level, check the post organization id against the mentioned user's organization id
      // If the mentioned user is not in the same organization as the post, add it to the `invitedMemberIds`; if they are in the same organization, it means the user already has access to the post
      if (
        member &&
        postData?.post?.generalPermission.includes(
          GeneralPermission.OrganizationMembers,
        )
      ) {
        if (member.organization.id !== postData.post.organizationId) {
          invitedMemberIds.push(mentionId);
        }
      } else {
        // This case only happens when the post is private
        invitedMemberIds.push(mentionId);
      }
    }

    setMentionedUserIds(invitedMemberIds);

    if (invitedMemberIds.length > 0) {
      onOpenInviteMentionUserDialog();
    } else {
      onCreateComment(data);
    }
  };

  const commentsRender = useMemo(() => {
    if (isFirstLoading) {
      return <CommentContainerViewSkeleton />;
    }

    if (comments.length === 0) {
      return (
        <Box my={isMobileView ? 8 : 4} display="flex" justifyContent="center">
          <Typography color={theme.colors?.utility[800]} variant="body-lg">
            No comments
          </Typography>
        </Box>
      );
    }

    return (
      <>
        {comments.map((comment, index) => {
          return (
            <Box key={comment.id} sx={{ wordBreak: 'break-word' }}>
              <CommentContainerView
                canComment={myPermissions.includes(PostPermission.Comment)}
                comment={comment}
                onReact={(commentId, emoji) => {
                  onReactToComment(commentId, emoji);
                }}
                onReply={(data) => {
                  onInviteMentioneUsersAndCreateComment({
                    ...data,
                    parentThreadId: comment.id,
                  });
                }}
                // Scroll to the latest comment when it mounts
                ref={(ref) => {
                  if (ref && newCommentId === comment.id) {
                    ref.scrollIntoView(true);
                    setNewCommentId('');
                  }
                }}
              />
              {index !== comments.length - 1 && (
                <Divider
                  sx={{
                    borderBottomColor: theme.colors?.utility[275],
                    mx: 4,
                  }}
                />
              )}
            </Box>
          );
        })}
      </>
    );
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify({
      isFirstLoading,
      postId,
      comments,
      newCommentId,
      filters,
      myPermissions,
      onInviteMentioneUsersAndCreateComment,
      onReactToComment,
    }),
  ]);

  const showSearch = !(isMobileView && comments?.length <= 5 && !filters.query);

  const renderComments = useCallback(
    () => {
      return (
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          overflow="hidden"
          sx={{
            '.mention-node': {
              color: `${theme.colors?.primary.maroon} !important`,
            },
            '& a': {
              color: `${theme.colors?.primary.maroon} !important`,
            },
          }}
        >
          {commentsRender}
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentsRender, isMobileView, showSearch],
  );

  return (
    <>
      <Box
        sx={{
          p: 6,
          pt: 2,
        }}
      >
        <CommentSearch
          sx={{
            position: 'relative',
            zIndex: 1,
            bgcolor: theme.colors?.primary.white,
            display: searchState.isOpen ? 'block' : 'none',
            ...componentsProps?.searchBox?.sx,
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{
                  padding: 0,
                }}
                disableRipple
                onClick={searchState.onClose}
              >
                <IconOutlineCloseCircle
                  size={16}
                  color={theme.colors?.utility[700]}
                />
              </IconButton>
            ),
          }}
          onChange={(e) => {
            debouncedSetQuery(e.target.value);
          }}
        />

        <Box
          sx={{
            display: searchState.isOpen ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            sx={{
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: theme.colors?.utility[275],
              borderRadius: '100%',
            }}
            onClick={searchState.onOpen}
          >
            <IconOutlineSearchNormal1
              size={16}
              color={theme.colors?.utility[700]}
            />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography
              variant="headline-sm"
              sx={{
                color: hasAnnotations
                  ? theme.colors?.primary.black
                  : theme.colors?.utility[500],
              }}
            >
              Hide Annotations
            </Typography>
            <Switch
              checked={hideAnnotations}
              onChange={(e) =>
                hasAnnotations && setHideAnnotations(e.target.checked)
              }
            />
            <CommentFilter filters={filters} onSetFilters={setFilters} />
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          borderWidth: 0.5,
          borderBottomColor: theme.colors?.utility[300],
          mx: 6,
        }}
      />
      <CommentSidebar
        sx={sx}
        canComment={myPermissions.includes(PostPermission.Comment)}
        componentsProps={componentsProps}
        filters={filters}
        onSetFilters={setFilters}
        onCreateComment={onInviteMentioneUsersAndCreateComment}
        renderComments={renderComments}
      />
      {isOpenInviteMentionUserDialog && (
        <CommentInviteMentionUserDialog
          type={postData?.post?.type === PostType.Note ? 'note' : 'post'}
          variant={
            myPermissions.includes(PostPermission.Update)
              ? 'invite'
              : 'request-access'
          }
          mentionedUserIds={mentionedUserIds}
          onCreateComment={() =>
            onCreateComment({ comment: commentHtml, parentThreadId })
          }
          onInviteOrRequestAccessAndCreateComment={() => {
            onCreateComment({ comment: commentHtml, parentThreadId });
            if (myPermissions.includes(PostPermission.Update)) {
              onInvitePostMembers({
                postId,
                userIds: mentionedUserIds,
              });
            } else {
              onCreatePermissionRequest(
                mentionedUserIds.map((userId) => ({
                  userId,
                  postId,
                })),
              );
            }
          }}
        />
      )}
    </>
  );
};
