import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledMenuItemContainer = styled(Box)(() => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

export const StyledIconContainer = styled(Box)(() => ({
  backgroundColor: theme.colors?.utility[300],
  borderRadius: theme.spacing(2),
  width: theme.spacing(7),
  height: theme.spacing(7),
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
