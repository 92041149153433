import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  ClickAwayListener,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { RichTextEditorRef } from 'components/common/form/RichTextEditor/RichTextEditor';
import { IconBoldExport } from 'components/icons/components/bold/IconBoldExport';
import { GenerateId } from 'utils/generateId';
import {
  TaskAssignee,
  TaskAssigneeProps,
  TaskContextMenu,
  TaskContextMenuProps,
  TaskDueDate,
  TaskDueDateProps,
  TaskName,
  TaskNameProps,
  TaskPriority,
  TaskPriorityProps,
  TaskStatus,
} from 'features/task/components';
import {
  SearchableEntityType,
  TaskFragmentTaskAssigneeFragmentDoc,
  TaskFragmentTaskContextMenuFragmentDoc,
  TaskFragmentTaskDueDateFragmentDoc,
  TaskFragmentTaskListItemViewFragment,
  TaskFragmentTaskNameFragmentDoc,
  TaskFragmentTaskPriorityComponentFragmentDoc,
  TaskFragmentTaskStatusFragmentDoc,
  UserFragmentAvatarGroupFragment,
  useUpdateTaskForTaskListItemViewMutation,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

export const TASK_FRAGMENT_TASK_LIST_ITEM_VIEW = gql`
  fragment TaskFragmentTaskListItemView on TaskModel {
    id
    ...TaskFragmentTaskName
    ...TaskFragmentTaskStatus
    ...TaskFragmentTaskAssignee
    ...TaskFragmentTaskPriorityComponent
    ...TaskFragmentTaskDueDate
    ...TaskFragmentTaskContextMenu
  }
  ${TaskFragmentTaskNameFragmentDoc}
  ${TaskFragmentTaskStatusFragmentDoc}
  ${TaskFragmentTaskAssigneeFragmentDoc}
  ${TaskFragmentTaskPriorityComponentFragmentDoc}
  ${TaskFragmentTaskDueDateFragmentDoc}
  ${TaskFragmentTaskContextMenuFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UpdateTaskForTaskListItemView($data: UpdateTaskInput!) {
    updateTask(data: $data) {
      id
      ...TaskFragmentTaskListItemView
    }
  }
  ${TASK_FRAGMENT_TASK_LIST_ITEM_VIEW}
`;

export type TaskListItemViewProps = {
  task?: TaskFragmentTaskListItemViewFragment;
  sx?: SxProps;
  onChange?: (task: TaskFragmentTaskListItemViewFragment) => void;

  isFocused?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;

  canNavigate?: boolean;
  showIcon?: boolean;

  readOnly?: boolean;

  componentsProps?: {
    contextMenu?: Pick<TaskContextMenuProps, 'onAfterTaskDeleted'>;
    name?: Omit<TaskNameProps, 'task'>;
    content?: {
      sx?: SxProps;
    };
    priority?: Omit<TaskPriorityProps, 'task'>;
    dueDate?: Omit<TaskDueDateProps, 'task'>;
    assignee?: Omit<TaskAssigneeProps, 'task'>;
    link?: (
      task: TaskFragmentTaskListItemViewFragment,
    ) => Partial<LinkProps> & {
      icon?: {
        size?: number;
      };
    };
    actionButtonContainer?: {
      sx?: SxProps;
    };
  };
};

export const TaskListItemView = (props: TaskListItemViewProps) => {
  const {
    task,
    sx,
    onChange,
    isFocused: externalIsFocused,
    onFocus: externalOnFocus,
    onBlur: externalOnBlur,
    showIcon = false,
    readOnly = false,
    canNavigate = true,

    componentsProps = {},
  } = props;

  const location = useLocation();
  const locationState = (location.state as any) || {};

  const {
    isOpen: internalIsFocused,
    onOpen: internalOnFocus,
    onClose: internalOnBlur,
  } = useDisclosure();
  const isFocused = externalIsFocused ?? internalIsFocused;

  useEffect(() => {
    if (isFocused) {
      setTimeout(() => {
        taskNameRef.current?.focus();
      });
    }
  }, [isFocused]);

  const onClickAway = () => {
    if (!isFocused) {
      return;
    }

    if (externalIsFocused !== undefined) {
      externalOnBlur?.();
    } else {
      internalOnBlur();
    }
  };

  const taskNameRef = useRef<RichTextEditorRef>(null);

  // Need to use ref because onMentionInTaskName will be cached.
  // If we use task directly, it will be stale.
  const [updateTask] = useUpdateTaskForTaskListItemViewMutation();
  const taskRef = useRef<TaskFragmentTaskListItemViewFragment>();
  taskRef.current = task;

  // Custom onMention handler for task name
  // If a user is mentioned in task name, add them to task members
  const onMentionInTaskName = (
    entityType: SearchableEntityType,
    entity: unknown,
  ) => {
    // Only handle users for now
    if (entityType === SearchableEntityType.Users) {
      const user = entity as UserFragmentAvatarGroupFragment;

      if (!taskRef.current) {
        return;
      }

      // If external onChange is provided, call it
      if (onChange) {
        onChange({
          ...taskRef.current,
          taskMembers: [...(taskRef.current?.taskMembers || []), user],
        });
      } else {
        // Else call update task mutation directly

        updateTask({
          variables: {
            data: {
              taskId: taskRef.current.id,
              data: {
                memberIds: [...(taskRef.current?.taskMembers || []), user].map(
                  (member) => member.id,
                ),
              },
            },
          },
          optimisticResponse: {
            updateTask: {
              ...taskRef.current,
              taskMembers: [...(taskRef.current?.taskMembers || []), user],
            },
          },
        });
      }
    }
  };

  if (!task) {
    return null;
  }

  const linkProps = componentsProps?.link?.(task);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <RightClickContextMenuWrapper
        sx={{
          borderRadius: 4,
          px: 2,
          py: 1.5,
          bgcolor: isFocused ? theme.colors?.utility[275] : 'transparent',
          transition: 'all 0.2s',
          '&:hover': {
            bgcolor: theme.colors?.utility[275],

            // Show link button on hover
            '.link': {
              opacity: 0.4,
            },
          },
          ...sx,
        }}
        onClick={
          externalIsFocused !== undefined ? externalOnFocus : internalOnFocus
        }
        renderMenu={(props) => (
          <TaskContextMenu
            task={task}
            renderButton={false}
            {...props}
            {...componentsProps.contextMenu}
          />
        )}
        disabled={!canNavigate || readOnly}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            flexDirection: isFocused ? 'column' : 'row',
            ...componentsProps?.content?.sx,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Box mt={0.9}>
              <TaskStatus
                task={task}
                onChange={
                  onChange
                    ? (status) => onChange({ ...task, status })
                    : undefined
                }
                readOnly={readOnly}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                width: '100%',
              }}
            >
              <TaskName
                task={task}
                ref={taskNameRef}
                onChange={
                  onChange ? (name) => onChange({ ...task, name }) : undefined
                }
                {...componentsProps?.name}
                autoFocus={isFocused}
                useEditorExtensionsProps={{
                  ...componentsProps?.name?.useEditorExtensionsProps,
                  mention: {
                    onMention: onMentionInTaskName,
                  },
                }}
                editable={!readOnly}
              />
              {/* Show end date below name when task is not focused */}
              {!isFocused && task.endDate && !showIcon && (
                <Typography
                  variant="subhead-sm"
                  color={theme.colors?.utility[700]}
                >
                  {moment(task.endDate).format('MMM D')}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              pt: 0.5,
              width: isFocused ? '100%' : 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2,
              '& button': {
                color: theme.colors?.primary.black,
              },
              ...componentsProps?.actionButtonContainer?.sx,
            }}
          >
            {showIcon || isFocused ? (
              <TaskDueDate
                task={task}
                onChange={
                  onChange
                    ? (endDate) => onChange({ ...task, endDate })
                    : undefined
                }
                {...componentsProps?.dueDate}
                sx={{
                  opacity: task.endDate ? 1 : 0.2,
                  ...componentsProps?.dueDate?.sx,
                }}
                readOnly={readOnly}
              />
            ) : null}
            {task.priority || isFocused || showIcon ? (
              <TaskPriority
                task={task}
                onChange={
                  onChange
                    ? (priority) => onChange({ ...task, priority })
                    : undefined
                }
                {...componentsProps?.priority}
                sx={{
                  opacity: task.priority ? 1 : 0.2,
                  ...componentsProps?.dueDate?.sx,
                }}
                readOnly={readOnly}
              />
            ) : null}
            {showIcon || isFocused || task.taskMembers.length > 0 ? (
              <TaskAssignee
                task={task}
                onChange={
                  onChange
                    ? (assigneeIds, assignees) =>
                        onChange({ ...task, taskMembers: assignees })
                    : undefined
                }
                {...componentsProps?.assignee}
                sx={{
                  opacity: task.taskMembers.length > 0 ? 1 : 0.2,
                  ...componentsProps?.assignee?.sx,
                }}
                readOnly={readOnly}
              />
            ) : null}
            {canNavigate && !readOnly && (
              <Link
                to={PlotRoutes.task(task?.id)}
                state={{
                  ...locationState,
                  // Keep background location & replace secondary location if we are
                  // coming from a 2nd layer
                  // @ts-ignore
                  backgroundLocation:
                    locationState.backgroundLocation || location,
                  secondaryLocation: locationState.backgroundLocation
                    ? location
                    : undefined,
                  /**
                   * Location stack is used to keep track of all the task detail views open
                   * in succession. Task -> subtask -> ...
                   *
                   * As there can be multiple task detail views open at the same time,
                   * we need to make sure that each time we open a new task detail view,
                   * a unique location stack id is generated.
                   */
                  uniqueLocationStackId: GenerateId.create(),
                }}
                style={{
                  display: 'flex',
                }}
                {...linkProps}
              >
                <IconButton
                  size="small"
                  className="link"
                  sx={{ p: 0, opacity: isFocused || showIcon ? 0.4 : 0 }}
                >
                  <IconBoldExport size={linkProps?.icon?.size || 16} />
                </IconButton>
              </Link>
            )}
          </Box>
        </Box>
      </RightClickContextMenuWrapper>
    </ClickAwayListener>
  );
};
