import { UrlMetadataFragmentIframelyFragment } from 'graphql/generated';
import { IFramelyResponse, IframelyMedia } from './hooks/useIframelyAPI';

/**
 * Iframely's self-hosted iframe generates some random styles that doesn't
 * work really well.
 *
 * This method aims to override the random styles so they could look better.
 * Not 100% fool-proof.
 */
export const getCustomStyles = (site?: string) => {
  let styles = {};

  switch (site?.toLocaleLowerCase()) {
    case 'facebook': {
      styles = {
        minWidth: 360,
        bgcolor: 'white',
      };

      break;
    }
    case 'instagram': {
      styles = {
        minWidth: 360,
      };

      break;
    }
    case 'tiktok': {
      styles = {
        minWidth: 360,
      };

      break;
    }
    default: {
      styles = {
        width: '100%',
        maxWidth: 740,
      };

      break;
    }
  }

  return styles;
};

/**
 * This method converts the response we got from Iframely API
 * to UrlMetadata model on our end.
 */
export const convertIframelyResponseToUrlMetadata = (res: IFramelyResponse) => {
  const metadata: UrlMetadataFragmentIframelyFragment['metadata'] = {
    site: res.meta?.site || null,
    medium: res.meta?.medium || null,
    type: null,
    thumbnail: null,
  };

  // Get object thumbnail url
  let iframelyMediaForThumbnail: IframelyMedia | undefined;
  if (
    Array.isArray(res.links?.thumbnail) &&
    (res.links?.thumbnail || []).length > 0
  ) {
    iframelyMediaForThumbnail = res.links?.thumbnail[0];
  } else if (
    Array.isArray(res.links?.image) &&
    (res.links?.thumbnail || []).length > 0
  ) {
    iframelyMediaForThumbnail = res.links?.image[0];
  }

  if (iframelyMediaForThumbnail) {
    metadata.thumbnail = [
      {
        ...iframelyMediaForThumbnail,
        mediaSize: iframelyMediaForThumbnail.media,
      },
    ];
  }

  // Get file type
  if (res.links?.file) {
    // We'll want to avoid getting the "reader" object
    // as in some scenarios like PDF, it's actually the google docs wrapper, which has mimeType of text/html
    metadata.type = res.links.file.filter(
      (f) => !f.rel.includes('reader'),
    )[0].type;
  }

  return {
    id: '',
    url: res.url,
    metadata,
  } as UrlMetadataFragmentIframelyFragment;
};
