import { Box, Button, Typography } from '@mui/material';
import { IconLinearRedo } from 'components/icons/components/linear/IconLinearRedo';
import { PostAnnotationInputData } from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme/theme';
import { StyledCommentInput, StyledIconLinearRedo } from './styles';

interface Props {
  videoRef: HTMLVideoElement;
  selectedTimeForAnnotation: number;
  onCreateAnnotation?: (annotation: PostAnnotationInputData) => void;
  onClose: VoidFunction;
}

export const AddTimeComment = ({
  videoRef,
  selectedTimeForAnnotation,
  onCreateAnnotation,
  onClose,
}: Props) => {
  const [comment, setComment] = useState('');

  const onAddTimeAnnotation = () => {
    if (!comment.trim()) return;
    onCreateAnnotation?.({
      comment: {
        comment,
      },
      time:
        selectedTimeForAnnotation === 0
          ? videoRef?.currentTime!
          : selectedTimeForAnnotation,
    });
    setComment('');
    onClose();
  };
  return (
    <Box
      sx={{
        padding: '12px 60px',
        backgroundColor: theme.colors?.utility[1000],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 4,
      }}
    >
      <StyledCommentInput
        fullWidth
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Add a comment"
        size="small"
        autoFocus
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onAddTimeAnnotation();
          }
        }}
        endAdornment={
          <StyledIconLinearRedo onClick={onAddTimeAnnotation}>
            <IconLinearRedo size={16} />
          </StyledIconLinearRedo>
        }
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            borderRadius: 5,
            backgroundColor: theme.colors?.utility[900],
            padding: '3px 8px 6px 8px',
            width: 'fit-content',
          }}
        >
          <Typography
            variant="body-sm"
            fontWeight={600}
            color={theme.colors?.primary.white}
            whiteSpace="nowrap"
          >
            Comment at{' '}
            {`${Math.floor(
              selectedTimeForAnnotation === 0
                ? videoRef.currentTime!
                : selectedTimeForAnnotation / 60,
            )}:${`0${Math.floor(
              selectedTimeForAnnotation === 0
                ? videoRef.currentTime!
                : selectedTimeForAnnotation % 60,
            )}`.slice(-2)}`}
          </Typography>
        </Box>
        <Button
          variant="secondary-reverse"
          sx={{
            border: 'none',
            color: theme.colors?.utility[600],
            fontSize: 12,
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
