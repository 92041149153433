import { Box, BoxProps } from '@mui/material';
import { theme } from 'styles/theme/theme';
import { useListViewKeyboardHandler } from 'hooks/keyboard/useListViewKeyboardHandler';
import { useRef } from 'react';

export type SelectionListProps = BoxProps & {
  data: any[];
  renderItem: ({ item, idx, props }) => React.ReactElement;
  onSelectItem?: (item: any, idx: number) => void;
};

export const SelectionList = ({
  data,
  renderItem,
  onSelectItem,
  ...rest
}: SelectionListProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { curIndex, setCurIndex } = useListViewKeyboardHandler({
    containerRef: ref,
    data,
    onEnter: (index: number) => {
      onSelectItem?.(data?.[index], index);
    },
  });

  return (
    <Box
      ref={ref}
      display="flex"
      flexDirection="column"
      overflow="auto"
      {...rest}
    >
      {data?.map((item, idx) => {
        const isSelected = curIndex === idx;
        const props = {
          style: {
            backgroundColor: isSelected
              ? theme.colors?.utility[275]
              : 'transparent',
          },
          onClick: () => {
            onSelectItem?.(item, idx);
          },
          onMouseOver: () => {
            setCurIndex(idx);
          },
        };
        return renderItem?.({ item, idx, props });
      })}
    </Box>
  );
};
