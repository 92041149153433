import { Box, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Avatar } from 'components/common/AvatarGroup';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { LinkedPostsProps } from './types';

const ClickableLinkWrapper = ({
  postId,
  children,
  allowClick,
}: {
  postId: string;
  children: React.ReactNode;
  allowClick: boolean;
}) => {
  if (allowClick) {
    return <Link to={PlotRoutes.juice(postId)}>{children}</Link>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export const TrendLinkedPosts = ({
  linkedPosts,
  minimalView,
}: LinkedPostsProps) => {
  return (
    <>
      <Typography variant="body-xl" fontWeight={600} mb={2}>
        All Saves
      </Typography>

      {linkedPosts.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            minHeight: 200,
          }}
        >
          <Typography variant="headline-sm" color={theme.colors?.utility[600]}>
            No Saves Yet
          </Typography>
        </Box>
      ) : (
        linkedPosts.map((post) => (
          <ClickableLinkWrapper postId={post.id} allowClick={!minimalView}>
            <Box
              id={post.id}
              sx={{
                display: 'flex',
                position: 'relative',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: minimalView
                  ? 'transparent'
                  : theme.colors?.utility[250],
                borderRadius: theme.spacing(3),
                p: theme.spacing(4),
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 1,
                }}
              >
                <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
                  <Avatar user={post.creator} size={16} />
                  <Typography variant="body-xl" fontWeight={600}>
                    {getFullName(post.creator)}
                  </Typography>
                  {!!post.collections?.length && <Box component="span">in</Box>}
                  {post.collections?.map((collection, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      mx={theme.spacing(0.5)}
                      sx={{
                        backgroundColor: theme.colors?.utility[400],
                        p: theme.spacing(1, 2),
                        borderRadius: theme.spacing(4),
                      }}
                    >
                      <Link
                        to={PlotRoutes.collection(collection.id)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        {collection.contentIdeaId ||
                        collection.parentCollection?.contentIdeaId ? (
                          <IconBoldCalendar
                            size={16}
                            color={theme.colors?.primary.black}
                            style={{ flexShrink: 0 }}
                          />
                        ) : null}
                        <Typography variant="subhead-lg">
                          {collection.name}
                        </Typography>
                        {collection.parentCollection && (
                          <Typography
                            variant="subhead-lg"
                            sx={{ color: theme.colors?.utility[800] }}
                          >
                            {' '}
                            / {collection.parentCollection.name}
                          </Typography>
                        )}
                      </Link>
                    </Box>
                  ))}
                </Box>
                <Typography
                  component="div"
                  className="time-saved"
                  variant="body-sm"
                  fontWeight={500}
                  color={theme.colors?.utility[700]}
                >
                  {moment(post.createdAt).fromNow()}
                </Typography>
              </Box>

              <Link to={PlotRoutes.juice(post.id)}>
                <IconButtonWithTooltip
                  tooltip="Go to Post"
                  disableRipple
                  sx={{
                    color: theme.colors?.primary.black,
                    padding: 0,
                  }}
                >
                  <IconLinearExport2 size={12} />
                </IconButtonWithTooltip>
              </Link>
            </Box>
          </ClickableLinkWrapper>
        ))
      )}
    </>
  );
};
