import { useApolloClient } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconCustomPinOutline } from 'components/icons/components/custom/IconCustomPinOutline';
import { IconLinearArrowDown2 } from 'components/icons/components/linear/IconLinearArrowDown2';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import { IconOutlineArrowSwapHorizontal } from 'components/icons/components/outline/IconOutlineArrowSwapHorizontal';
import { IconOutlineSaveRemove } from 'components/icons/components/outline/IconOutlineSaveRemove';
import { IconOutlineTickCircle } from 'components/icons/components/outline/IconOutlineTickCircle';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  useCheckIfCustomCollection,
  useMoveToAnotherCollectionModal,
} from 'features/collection';
import {
  useCollectionPostContextMenuHandlers,
  useCollectionPostMoveToAnotherCollectionModal,
  useJuiceboxSelectContext,
} from 'features/juicebox';
import { JuiceboxPostsOrganizePopover } from 'features/juicebox/components/postsOrganizePopover';
import { usePinnedEntityMutations } from 'features/pinnedEntity';
import { useMoveToAnotherCollectionModalForPost } from 'features/post/hooks';
import {
  GetCollectionsSmartSearchForGridViewAllCollectionsSectionDocument,
  GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';

type JuiceboxCollectionPostContextMenuViewProps = Omit<
  ContextMenuProps,
  'options' | 'renderButton'
> & {
  collectionId: string;
};

export const JuiceboxCollectionPostContextMenuView = (
  props: JuiceboxCollectionPostContextMenuViewProps,
) => {
  const { collectionId, ...rest } = props;
  const isCustomCollection =
    useCheckIfCustomCollection(collectionId) || !collectionId;
  const {
    selectedPostIds,
    selectedCollectionIds,
    exitSelectMode,
    posts,
    collections,
    getSelectedPosts,
    getSelectedCollections,
  } = useJuiceboxSelectContext();
  const {
    isOpen: isPostOrganizePopoverOpen,
    onOpen: openPostOrganizePopover,
    onClose: closePostOrganizePopover,
  } = useDisclosure();

  const isSelectedPostsPinnedToParent = useMemo(() => {
    return selectedPostIds.every((postId) =>
      (
        posts.find((post) => post.id === postId)?.pinnedToCollections || []
      ).find((c) => c.parentCollectionId === collectionId),
    );
  }, [selectedPostIds, collectionId, posts]);

  const isSelectedCollectionsPinnedToParent = useMemo(() => {
    return selectedCollectionIds.every(
      (collectionId) =>
        collections.find((collection) => collection.id === collectionId)
          ?.isPinnedToParent,
    );
  }, [selectedCollectionIds, collections]);

  const isSelectedCollectionsPinnedToRoot = useMemo(() => {
    return selectedCollectionIds.every(
      (collectionId) =>
        collections.find((collection) => collection.id === collectionId)
          ?.isPinnedToRoot,
    );
  }, [selectedCollectionIds, collections]);

  const client = useApolloClient();
  const { triggerCommand } = useCommandContext();

  const getItemCountText = (count) => `${count} item${count > 1 ? 's' : ''}`;

  const onAfterActionPerformed = () => {
    exitSelectMode();
    rest?.onClose?.({}, 'backdropClick');
  };

  const {
    onPinManyCollectionsToParent,
    onPinManyPostsToCollection,
    onPinManyCollectionsToRoot,
    onUnpinManyCollectionsFromParent,
    onUnpinManyPostsFromCollection,
    onUnpinManyCollectionsFromRoot,
  } = usePinnedEntityMutations();
  const { onDeletePosts, onDeleteCollections, onRemovePostsFromCollection } =
    useCollectionPostContextMenuHandlers();

  const { renderMoveToAnotherCollectionModal, showMoveCollection } =
    useMoveToAnotherCollectionModal({
      collectionIds: selectedCollectionIds,
      currentCollectionId: collectionId,
      onAfterMove: onAfterActionPerformed,
    });

  const { dialog: deleteDialog, onOpen: openDeleteConfirmationDialog } =
    useConfirmationDialog();

  const {
    renderMoveToAnotherCollectionModal:
      renderMoveCollectionPostToAnotherCollectionModal,
    showMoveCollection: onShowMoveCollectionPost,
    renderUpdateCollectionPostPermissionAlert,
  } = useCollectionPostMoveToAnotherCollectionModal({
    collectionId,
    onCloseParent: onAfterActionPerformed,
  });

  const { renderMoveToAnotherPostModal, showMovePost } =
    useMoveToAnotherCollectionModalForPost({
      postIds: selectedPostIds,
      currentCollectionId: collectionId,
      onMoved: () => {
        onAfterActionPerformed();
        selectedPostIds.map((postId) =>
          evictObject(client.cache, postId, 'PostModel'),
        );
      },
    });

  const collectionMenuOptions = [
    {
      title: `Move ${getItemCountText(selectedCollectionIds.length)}`,
      icon: IconOutlineArrowSwapHorizontal,
      onClick: showMoveCollection,
      closeOnClick: false,
    },
    {
      isDivider: true,
    },
    ...(isCustomCollection
      ? []
      : [
          {
            title: `${
              isSelectedCollectionsPinnedToParent ? 'Unpin' : 'Pin'
            } ${getItemCountText(
              selectedCollectionIds.length,
            )} to this Collection`,
            icon: IconCustomPinOutline,
            onClick: () => {
              if (isSelectedCollectionsPinnedToParent) {
                onUnpinManyCollectionsFromParent(selectedCollectionIds);
              } else {
                onPinManyCollectionsToParent(selectedCollectionIds);
              }

              // Refetch for pinning status
              getSelectedCollections();

              exitSelectMode();
            },
          },
        ]),

    {
      title: `${
        isSelectedCollectionsPinnedToRoot ? 'Unpin' : 'Pin'
      } ${getItemCountText(selectedCollectionIds.length)} to Creative Juicebox`,
      icon: IconCustomPinOutline,
      onClick: () => {
        if (isSelectedCollectionsPinnedToRoot) {
          onUnpinManyCollectionsFromRoot(selectedCollectionIds, [
            GetCollectionsSmartSearchForGridViewAllCollectionsSectionDocument,
          ]);
        } else {
          onPinManyCollectionsToRoot(selectedCollectionIds, [
            GetPinnedEntitiesToParentForGridViewPinnedCollectionsAndPostsSectionDocument,
          ]);
        }

        // Refetch for pinning status
        getSelectedCollections();

        exitSelectMode();
      },
    },
    {
      isDivider: true,
    },
    {
      title: `Delete ${getItemCountText(selectedCollectionIds.length)}`,
      icon: IconOutlineTrash,
      color: theme.colors?.utility['pink-3'],
      closeOnClick: false,
      onClick: () => {
        openDeleteConfirmationDialog({
          subtitle: `Once it removed, ${getItemCountText(
            selectedCollectionIds.length,
          )} will no longer be accessible.`,
          onConfirm: () => {
            onDeleteCollections(selectedCollectionIds);
            onAfterActionPerformed();
          },
        });
      },
    },
  ];

  const postMenuOptions = [
    {
      title: 'Organize',
      icon: IconLinearSave,
      onClick: openPostOrganizePopover,
      closeOnClick: false,
    },
    ...(isCustomCollection
      ? []
      : [
          {
            title: `Move ${getItemCountText(selectedPostIds.length)}`,
            icon: IconOutlineArrowSwapHorizontal,
            onClick: showMovePost,
            closeOnClick: false,
          },
        ]),
    {
      title: `Download ${getItemCountText(selectedPostIds.length)}`,
      icon: IconLinearArrowDown2,
      onClick: () => {
        triggerCommand(COMMAND_TYPE.POST_DOWNLOAD, {
          posts: posts.filter((post) => selectedPostIds.includes(post.id)),
          canDownloadSinglePost: selectedPostIds.length === 1,
        });
        exitSelectMode();
      },
    },
    {
      isDivider: true,
    },
    ...(isCustomCollection
      ? []
      : [
          {
            title: `${
              isSelectedPostsPinnedToParent ? 'Unpin' : 'Pin'
            } ${getItemCountText(selectedPostIds.length)} to this Collection`,
            icon: IconCustomPinOutline,
            onClick: () => {
              if (isSelectedPostsPinnedToParent) {
                onUnpinManyPostsFromCollection(collectionId, selectedPostIds);
              } else {
                onPinManyPostsToCollection(collectionId, selectedPostIds);
              }

              // Refetch for pinning status
              getSelectedPosts();

              exitSelectMode();
            },
          },
          {
            isDivider: true,
          },
        ]),
    ...(isCustomCollection
      ? []
      : [
          {
            title: `Remove ${getItemCountText(
              selectedPostIds.length,
            )} from Collection`,
            icon: IconOutlineSaveRemove,
            color: theme.colors?.utility['pink-3'],
            onClick: () => {
              onRemovePostsFromCollection(selectedPostIds, collectionId);
              exitSelectMode();
            },
          },
        ]),
    {
      title: `Delete ${getItemCountText(selectedPostIds.length)}`,
      icon: IconOutlineTrash,
      color: theme.colors?.utility['pink-3'],
      closeOnClick: false,
      onClick: () => {
        openDeleteConfirmationDialog({
          subtitle: `Once it removed, ${getItemCountText(
            selectedPostIds.length,
          )} will no longer be accessible.`,
          onConfirm: () => {
            onDeletePosts(selectedPostIds);
            onAfterActionPerformed();
          },
        });
      },
    },
  ];

  const collectionPostOptions = [
    {
      title: `Move ${getItemCountText(
        selectedPostIds.length + selectedCollectionIds.length,
      )}`,
      icon: IconOutlineArrowSwapHorizontal,
      onClick: onShowMoveCollectionPost,
      closeOnClick: false,
    },
    {
      isDivider: true,
    },
    {
      title: `${
        isSelectedCollectionsPinnedToParent && isSelectedPostsPinnedToParent
          ? 'Unpin'
          : 'Pin'
      } ${getItemCountText(
        selectedPostIds.length + selectedCollectionIds.length,
      )} to this Collection`,
      icon: IconCustomPinOutline,
      onClick: () => {
        if (
          isSelectedCollectionsPinnedToParent &&
          isSelectedPostsPinnedToParent
        ) {
          onUnpinManyCollectionsFromParent(selectedCollectionIds);
          onUnpinManyPostsFromCollection(collectionId, selectedPostIds);
        } else {
          onPinManyCollectionsToParent(selectedCollectionIds);
          onPinManyPostsToCollection(collectionId, selectedPostIds);
        }

        // Refetch for pinning status
        getSelectedPosts();
        getSelectedCollections();

        exitSelectMode();
      },
    },
    {
      isDivider: true,
    },
    {
      title: `Delete ${getItemCountText(
        selectedPostIds.length + selectedCollectionIds.length,
      )}`,
      icon: IconOutlineTrash,
      closeOnClick: false,
      color: theme.colors?.utility['pink-3'],
      onClick: () =>
        openDeleteConfirmationDialog({
          subtitle: `Once removed, these items will no longer be accessible.`,
          onConfirm: () => {
            onDeleteCollections(selectedCollectionIds);
            onDeletePosts(selectedPostIds);
            onAfterActionPerformed();
          },
        }),
    },
  ];
  const menuOptions = [
    {
      title: 'Deselect All',
      icon: IconOutlineTickCircle,
      color: theme.colors?.primary.black,
      onClick: exitSelectMode,
    },
    ...(selectedPostIds.length && selectedCollectionIds.length
      ? collectionPostOptions
      : selectedCollectionIds.length
      ? collectionMenuOptions
      : postMenuOptions),
  ];

  return (
    <>
      <ContextMenu
        renderButton={false}
        options={menuOptions.map((option) => {
          const Icon = option.icon;
          return {
            // @ts-ignore
            isDivider: option?.isDivider,
            renderOption: () => (
              <Box display="flex" gap={2} alignItems="center">
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {Icon && <Icon size={16} color={option.color} />}
                </Box>
                <Typography variant="subhead-lg" color={option.color}>
                  {option.title}
                </Typography>
              </Box>
            ),
            onClick: option.onClick,
            //   @ts-ignore
            closeOnClick: option?.closeOnClick,
          };
        })}
        MenuListProps={{
          sx: {
            minWidth: 266,
            gap: `${theme.spacing(2)} !important`,
          },
        }}
        disableRestoreFocus
        sx={{
          '& .MuiMenu-paper': {
            backdropFilter: 'blur(20px)',
            backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
          },
          ...(rest.sx || {}),
        }}
        {...rest}
        {...(isPostOrganizePopoverOpen
          ? { open: false }
          : // Otherwise, if renderButton is false -> open state would probably be controlled from outside
            // If nothing is specified, open state should be controlled by `ContextMenu` itself
            {})}
      />
      {deleteDialog}
      {renderMoveCollectionPostToAnotherCollectionModal()}
      {renderUpdateCollectionPostPermissionAlert()}
      {renderMoveToAnotherCollectionModal()}
      {renderMoveToAnotherPostModal()}
      {isPostOrganizePopoverOpen && (
        <JuiceboxPostsOrganizePopover
          postIds={selectedPostIds}
          currentCollectionId={collectionId}
          open
          onClose={() => {
            // Refetch for pinning status
            getSelectedPosts();

            closePostOrganizePopover();
          }}
          isFavoritePosts={selectedPostIds.every(
            (postId) => posts.find((post) => post.id === postId)?.isFavorite,
          )}
          {...rest}
        />
      )}
    </>
  );
};
