/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Box, SxProps } from '@mui/material';
import { Cursor } from 'components/common/Annotation/Common/Cursor';
import { RenderAnnotation } from 'components/common/Annotation/Common/RenderAnnotation';
import { RenderSelector } from 'components/common/Annotation/Common/RenderSelector';
import { MousePosition } from 'components/common/Annotation/types';
import { useViewManager } from 'components/common/Media/contexts/ViewManager.context';
import { useZoomManager } from 'components/common/Media/contexts/ZoomManager.context';
import { usePostManager } from 'features/juicebox/contexts';
import { PostAnnotationFragmentAnnotationFragment } from 'graphql/generated';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ImageContainer, ImageWrapper } from './styles';
import { ImageAnnotationInput } from './types';

type Props = {
  imageName: string;
  imageUrl: string;
  canAnnotate?: boolean;
  annotations?: PostAnnotationFragmentAnnotationFragment[];
  onCreateAnnotation?: (input: ImageAnnotationInput) => void;
  renderDefaultView?: () => React.ReactElement;

  onRenderDimensions?: (dimensions: { width: number; height: number }) => void;

  componentProps?: {
    imageContainer?: {
      sx: SxProps;
    };
    imageWrapper?: {
      sx: SxProps;
    };
    img?: {
      sx: SxProps;
    };
  };
};

export const CustomImage = ({
  imageUrl: _imageUrl,
  imageName,
  annotations = [],
  canAnnotate,
  onCreateAnnotation,
  renderDefaultView,
  componentProps,
  onRenderDimensions,
}: Props) => {
  const [imageUrl, setImageUrl] = useState(_imageUrl);
  const imageRef = useRef<HTMLImageElement>(null);
  const {
    setMaxBoundingBoxWidth,
    setMaxOriginalBoundingBoxWidth,
    maxOriginalBoundingBoxWidth,
    maxBoundingBoxWidth,
    setMaxBoundingBoxHeight,
    setMaxOriginalBoundingBoxHeight,
    maxOriginalBoundingBoxHeight,
  } = useViewManager();
  const { scale } = useZoomManager();
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: null,
    y: null,
  });
  const { hideAnnotations } = usePostManager();

  useLayoutEffect(() => {
    if (imageRef.current) {
      onRenderDimensions?.({
        width: imageRef.current.offsetWidth,
        height: imageRef.current.offsetHeight,
      });
    }
  }, [onRenderDimensions, scale, mousePosition, imageUrl, imageRef.current]); // eslint-disable-line

  useEffect(() => {
    if (
      imageRef?.current &&
      scale &&
      maxOriginalBoundingBoxWidth &&
      maxOriginalBoundingBoxHeight
    ) {
      // Calculate scaled image width based on scale value
      const currentWidth = maxOriginalBoundingBoxWidth * scale;
      const currentHeight = maxOriginalBoundingBoxHeight * scale;

      setMaxBoundingBoxWidth(currentWidth);
      setMaxBoundingBoxHeight(currentHeight);

      imageRef.current.style.width = `${currentWidth}px`;
      imageRef.current.style.height = `${currentHeight}px`;
    }
    // eslint-disable-next-line
  }, [scale]);

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    if (!(event.target instanceof HTMLImageElement)) {
      return;
    }

    if (x && y) {
      setMousePosition({ x: null, y: null });
      return;
    }

    if (!canAnnotate) return;
    const { clientX, clientY } = event;
    const rect = event.currentTarget.getBoundingClientRect();
    const startX = clientX - rect.left;
    const startY = clientY - rect.top;

    // Calculate the scale of current document against original document
    const scale = maxBoundingBoxWidth / maxOriginalBoundingBoxWidth;
    setMousePosition({ x: startX / scale, y: startY / scale });
  };

  const onAddAnnotation = (annotation: ImageAnnotationInput) => {
    if (onCreateAnnotation) {
      onCreateAnnotation(annotation);
      setMousePosition({ x: null, y: null });
    }
  };

  const { x, y } = mousePosition;

  if (!imageUrl) return null;

  return (
    <ImageContainer sx={{ ...(componentProps?.imageContainer?.sx || {}) }}>
      {canAnnotate && <Cursor />}
      <ImageWrapper sx={{ ...(componentProps?.imageWrapper?.sx || {}) }}>
        {renderDefaultView?.()}
        <Box
          component="img"
          className="react-custom-annotation"
          ref={imageRef}
          src={imageUrl}
          draggable={false}
          style={{
            width: 0,
            height: 0,
            objectFit: 'contain',
            borderRadius: 18,
          }}
          sx={{ ...(componentProps?.img?.sx || {}) }}
          alt={imageName}
          onLoad={() => {
            if (!imageRef.current) {
              return;
            }

            setMaxOriginalBoundingBoxWidth(imageRef.current.naturalWidth);
            setMaxOriginalBoundingBoxHeight(imageRef.current.naturalHeight);
          }}
          onError={() => {
            if (imageUrl.includes('https://ucarecdn.com')) {
              // Convert the image to a renderable format using uploadcare CDN in case of an error
              const newUrl = imageUrl.replace(
                /\/([^/]+)(?:\/([^/]+))?$/,
                (_, id, filename) =>
                  `/${id}/-/format/auto/-/quality/smart${
                    filename ? `/${filename}` : ''
                  }`,
              );

              setImageUrl(newUrl);
            }
          }}
          onMouseDown={onMouseDown}
        />
        <RenderSelector
          x={x}
          y={y}
          onCreateAnnotation={onAddAnnotation}
          onCancel={() => setMousePosition({ x: null, y: null })}
        />
        {scale &&
          !hideAnnotations &&
          annotations.map((annotation, index) => (
            <RenderAnnotation annotation={annotation} key={index} />
          ))}
      </ImageWrapper>
    </ImageContainer>
  );
};
