import { Box, BoxProps } from '@mui/material';

export type StopEventBoxProps = BoxProps &
  React.PropsWithChildren & {
    onStopEventClick?: (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => void;
  };

export const StopEventBox = ({
  children,
  onStopEventClick,
  ...rest
}: StopEventBoxProps) => {
  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onStopEventClick?.(e);
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
