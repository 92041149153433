import { gql } from '@apollo/client';
import { Box, Menu, SxProps } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import { useCollectionIdFromParams } from 'features/collection/hooks/useCollectionIdFromParams';
import {
  NOTE_COLORS,
  PostThemeColor,
} from 'features/note/constants/noteColors';
import {
  PostCreationSource,
  PostFragmentNoteColorFragment,
  useUpdateNoteColorMutation,
} from 'graphql/generated';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation UpdateNoteColor($data: UpdatePostInput!) {
    updatePost(data: $data) {
      id
      color
    }
  }
`;

export const POST_FRAGMENT_NOTE_COLOR = gql`
  fragment PostFragmentNoteColor on PostModel {
    id
    color
  }
`;

type NoteColorProps = {
  note: PostFragmentNoteColorFragment;
  defaultValue?: PostThemeColor;
  sx?: SxProps;
  refetchData?: VoidFunction;
};

export const NoteColor = (props: NoteColorProps) => {
  const { note, defaultValue, sx, refetchData } = props;

  const location = useLocation();
  const { taskId } = (location.state as any) || {};
  const { collectionId } = useCollectionIdFromParams();
  const { isMobileAppWebView } = useUserContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [updateNoteColor] = useUpdateNoteColorMutation();
  const handleColorChange = async (color?: PostThemeColor) => {
    if (color?.color) {
      await updateNoteColor({
        variables: {
          data: {
            postId: note.id,
            data: {
              collectionId,
              taskId,
              // contentIdeaId,
              color: color.color,
              source: isMobileAppWebView
                ? PostCreationSource.Mobile
                : PostCreationSource.Web,
            },
          },
        },
        optimisticResponse: {
          updatePost: {
            id: note.id,
            __typename: 'PostModel',
            color: color.color,
          },
        },
      });

      if (refetchData) {
        refetchData();
      }
    }

    handleClose();
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          width: theme.spacing(6),
          height: theme.spacing(6),
          borderRadius: theme.spacing(6),
          border: `1.5px solid ${theme.colors?.utility[900]}`,
          bgcolor: note.color ?? defaultValue,
          ...sx,
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            border: 'none',
          },
        }}
        elevation={0}
        MenuListProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            backgroundColor: 'transparent',
            paddingBottom: theme.spacing(5),
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {NOTE_COLORS.map((color) => (
          <Box
            key={color?.color}
            onClick={() => handleColorChange(color)}
            sx={{
              cursor: 'pointer',
              width: theme.spacing(6),
              height: theme.spacing(6),
              borderRadius: theme.spacing(6),
              border: `1.5px solid ${color?.itemBorderColor}`,
              bgcolor: color?.color,
              ...sx,
            }}
          />
        ))}
      </Menu>
    </Box>
  );
};
