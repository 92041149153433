/**
 * NOTE: This component might be converted into a reusable popup in the future.
 * Update with care.
 */

import { Box, CircularProgress, Portal, Typography } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { useCommandContext } from 'contexts/commands/Command.context';
import { ResourceUploadItem } from 'hooks/useResourceUploadQueue';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import { FilePreview } from './components/FilePreview';

interface Props {
  commandId: string;
  attachments?: ResourceUploadItem[];
  messages?: {
    loading?: string;
    completed?: string;
    error?: string;
  };
  showStatusIcon?: boolean;
}

export const StatusPopup = (props: Props) => {
  const { commandId, attachments, messages, showStatusIcon } = props;

  const { getActiveCommandById } = useCommandContext();
  const activeCommand = getActiveCommandById(commandId);

  const totalProgress = useMemo(() => {
    if (!attachments) {
      return 0;
    }
    const totalPoint = attachments.length * 100;
    const curPoint = attachments.reduce((preValue, item) => {
      return preValue + (item?.uploadProgress ?? 0);
    }, 0);
    return (curPoint / totalPoint) * 100;
  }, [attachments]);

  const { message, bgcolor, icon, color } = useMemo(() => {
    switch (activeCommand?.status) {
      case 'loading': {
        return {
          message: messages?.loading,
          bgcolor: theme.colors?.utility['yellow-4'],
          color: theme.colors?.primary.parchment,
          icon: (
            <CircularProgress
              variant="determinate"
              value={totalProgress}
              size={20}
              thickness={6}
              sx={{
                boxShadow: `inset 0 0 0 3px ${theme.colors?.utility[600]}`,
                color: theme.colors?.utility[800],
                borderRadius: '50%',
              }}
            />
          ),
        };
      }
      case 'completed': {
        return {
          message: messages?.completed,
          bgcolor: theme.colors?.utility['light-green'],
          icon: (
            <IconBoldTickCircle size={24} color={theme.colors?.utility[800]} />
          ),
        };
      }
      case 'error': {
        return {
          message: messages?.error,
          bgcolor: theme.colors?.utility['light-red'],
          icon: null,
        };
      }
      default: {
        return {};
      }
    }
  }, [
    activeCommand?.status,
    messages?.completed,
    messages?.error,
    messages?.loading,
    totalProgress,
  ]);

  if (!message && !bgcolor && !icon) {
    return null;
  }

  return (
    <Portal>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          margin: 5,
          marginBottom: 20,
          zIndex: 999999,
        }}
        display="flex"
        flexDirection="column"
        gap={2}
        alignItems="flex-end"
      >
        <Box display="flex" gap={2.5}>
          {attachments?.map((item, idx) => {
            return (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '54px',
                  height: '54px',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  bg: 'red',
                  position: 'relative',
                }}
              >
                <FilePreview resource={item} />
                <CircularProgress
                  variant="determinate"
                  value={item?.uploadProgress}
                  size={16}
                  sx={{
                    boxShadow: `inset 0 0 0 1px rgba(255, 255, 255, 0.3)`,
                    color: theme.colors?.primary.white,
                    borderRadius: '50%',
                  }}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            color: theme.colors?.primary.black,
            py: 2,
            px: 6,
            borderRadius: 8,
            bgcolor,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.spacing(1),
            border: `2px solid rgba(35, 6, 3, 0.10)`,
          }}
        >
          <Typography
            variant="headline-sm"
            color={color ?? theme.colors?.utility[800]}
          >
            {message}
          </Typography>
          {showStatusIcon ? icon : null}
        </Box>
      </Box>
    </Portal>
  );
};
