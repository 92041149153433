import { AttachmentMetaDataInput } from 'graphql/generated';

export enum UploadStatus {
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  ERROR = 'error',
  WAITING = 'waiting',
}

export type ResourceUploadItem = {
  resource: ResourceData;
  uploading: boolean;
  uploadProgress: number;
  uploadStatus: UploadStatus;
  uploadedInfo?: {
    metaData?: AttachmentMetaDataInput;
    url: string;
    size?: number;
  };
  autoFocusFirstBatchItemOnAdd: boolean;
};

export enum ResourceUploadType {
  Attachment = 'Attachment',
  Link = 'Link',
}

export type ResourceData = {
  id?: string;
  type: ResourceUploadType;
  content: File | string;
  name: string;
  size?: number;

  // See PostUploadFilesHandlers.tsx::L153 for usage
  collectionId?: string;
};

export type Labels = {
  heading?: string;
  addLinkLabel?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
};
