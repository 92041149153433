import { useDisclosure } from '@dwarvesf/react-hooks';
import { IconButton, Popover } from '@mui/material';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { useRef } from 'react';
import { theme } from 'styles/theme';
import {
  ContentCalendarFilterByOwnersInnerContent,
  ContentCalendarFilterByOwnersInnerContentProps,
} from '../sortAndFilterButton/options/filters/owners/ContentCalendarFilterByOwnersInnerContent';

export type ContentCalendarFilterByOwnersButtonProps = Omit<
  ContentCalendarFilterByOwnersInnerContentProps,
  'sx'
> & {};

export const ContentCalendarFilterByOwnersButton = (
  props: ContentCalendarFilterByOwnersButtonProps,
) => {
  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  return (
    <>
      <IconButton
        ref={anchorElRef}
        onClick={openPopover}
        size="small"
        sx={{
          bgcolor: theme.colors?.utility[300],
          borderRadius: theme.spacing(2),
          color: theme.colors?.utility[900],
          p: 2,
          ':hover': {
            bgcolor: theme.colors?.utility[300],
          },
        }}
      >
        <IconCustomUsers size={16} />
      </IconButton>
      <Popover
        anchorEl={anchorElRef.current}
        open={isPopoverOpen}
        onClose={closePopover}
        PaperProps={{
          sx: {
            p: 2,
            width: 270,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ContentCalendarFilterByOwnersInnerContent
          {...props}
          sx={{
            maxHeight: 470,
          }}
        />
      </Popover>
    </>
  );
};
