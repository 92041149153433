import { Button, Dialog, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

interface Props {
  open: boolean;
  onAgreeOverageCharges: () => void;
}

export const UpdateHourLimitDialog = ({
  open,
  onAgreeOverageCharges,
}: Props) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          padding: 8,
          borderRadius: 6,
          maxWidth: 388,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          boxShadow:
            '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)',
        },
      }}
    >
      <Typography variant="headline-xl" fontSize={24} fontWeight={500}>
        Overage Charges Will Apply
      </Typography>

      <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
        You're nearing your monthly hour credit. By clicking 'Agree Charges',
        you accept charges of $150 per additional 25 hours used, which will be
        billed to your payment method.
      </Typography>
      <Link to={PlotRoutes.socialListeningSettings()}>
        <Button variant="primary-alt" fullWidth sx={{ borderRadius: 2, mt: 3 }}>
          Manage hour usage
        </Button>
      </Link>
      <Button
        variant="text"
        onClick={onAgreeOverageCharges}
        sx={{
          p: 0,
        }}
      >
        Agree Charges
      </Button>
    </Dialog>
  );
};
