import { gql } from '@apollo/client';
import { SxProps, Typography } from '@mui/material';
import { SocialPostCommentFragmentSocialPostCommentTextFragment } from 'graphql/generated';

// eslint-disable-next-line
gql`
  fragment SocialPostCommentFragmentSocialPostCommentText on SocialPostCommentModel {
    id
    text
  }
`;

export type SocialPostCommentTextProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentTextFragment;
  sx?: SxProps;
};

export const SocialPostCommentText = (props: SocialPostCommentTextProps) => {
  const { socialPostComment, sx } = props;

  return (
    <Typography variant="subhead-lg" sx={sx}>
      {socialPostComment.text}
    </Typography>
  );
};
