import { gql } from '@apollo/client';
import { useUserContext } from 'contexts/users/User.context';
import { GenerateId } from 'utils/generateId';
import {
  CollectionInviteType,
  PermissionLevel,
  useInviteCollectionMembersMutation,
  useInvitePostMembersMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

//eslint-disable-next-line
gql`
  mutation InviteCollectionMembers($data: InviteCollectionMembersInput!) {
    inviteCollectionMembers(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation InvitePostMembers($data: InvitePostMembersInput!) {
    invitePostMembers(data: $data) {
      success
      message
    }
  }
`;

//eslint-disable-next-line
gql`
  query GetPostForInviteMemberHandlers($postId: String!) {
    post(id: $postId) {
      id
      type
      myPermissions
      generalPermission
      organizationId
      creator {
        id
      }
      inviteMembers {
        id
        user {
          id
        }
      }
    }
  }
`;

//eslint-disable-next-line
gql`
  query GetCollectionForInviteMemberHandlers($collectionId: String!) {
    collection(id: $collectionId) {
      id
      myPermissions
      generalPermission
      organizationId
      creator {
        id
      }
      inviteMembers {
        id
        user {
          id
        }
      }
    }
  }
`;

export const useCommentInviteMemberHandlers = () => {
  const { user } = useUserContext();

  const users = [
    ...(user?.organization?.users || []),
    ...(user?.organization.externalUsers || []),
  ];

  const [inviteCollectionMembers] = useInviteCollectionMembersMutation();
  const [invitePostMembers] = useInvitePostMembersMutation();
  const onInviteCollectionMembers = async ({
    userIds,
    collectionId,
  }: {
    userIds: string[];
    collectionId: string;
  }) => {
    inviteCollectionMembers({
      variables: {
        data: {
          memberInvites: userIds.map((userId) => ({
            userId,
            collectionId,
            permission: PermissionLevel.View,
          })),
          emailInvites: [],
          inviteType: CollectionInviteType.CollectionInvite,
        },
      },
      update: (cache, { data }) => {
        if (!data?.inviteCollectionMembers.success) return;
        modifyObject(cache, collectionId, 'CollectionModel', {
          inviteMembers: (members) => {
            return [
              ...members,
              ...userIds.map((userId) => ({
                __typename: 'CollectionInviteMemberModel',
                id: GenerateId.create(),
                user: users.find((user) => user.id === userId),
                permissionLevel: PermissionLevel.View,
              })),
            ];
          },
        });
      },
    });
  };

  const onInvitePostMembers = async ({
    userIds,
    postId,
  }: {
    userIds: string[];
    postId: string;
  }) => {
    invitePostMembers({
      variables: {
        data: {
          memberInvites: userIds.map((userId) => ({
            userId,
            postId,
            permission: PermissionLevel.View,
          })),
          emailInvites: [],
        },
      },
      update: (cache, { data }) => {
        if (!data?.invitePostMembers.success) return;
        modifyObject(cache, postId, 'PostModel', {
          inviteMembers: (members) => {
            return [
              ...members,
              ...userIds.map((userId) => ({
                __typename: 'PostInviteMemberModel',
                id: GenerateId.create(),
                user: users.find((user) => user.id === userId),
                permissionLevel: PermissionLevel.View,
              })),
            ];
          },
        });
      },
    });
  };

  return { onInviteCollectionMembers, onInvitePostMembers };
};
