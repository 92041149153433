import { gql } from '@apollo/client';
import { Avatar, Box, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import {
  BrandContentType,
  BrandFragmentSlaBrandContentTypeSelectorFragment,
  CreatorFragmentCreatorAvatarFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandFragmentSLABrandContentTypeSelector on BrandModel {
    id
    name
    creators {
      id
      ...CreatorFragmentCreatorAvatar
    }
  }
  ${CreatorFragmentCreatorAvatarFragmentDoc}
`;

const contentTypeToColor = {
  [BrandContentType.Owned]: {
    color: theme.colors?.utility['teal-3'],
    backgroundColor: theme.colors?.utility['teal-1'],
  },
  [BrandContentType.Ugc]: {
    color: theme.colors?.utility['blue-3'],
    backgroundColor: theme.colors?.utility['blue-1'],
  },
};

type Props = {
  brand: BrandFragmentSlaBrandContentTypeSelectorFragment;
  contentType: BrandContentType;
  onChange: (contentType: BrandContentType) => void;
};

export const SLABrandContentTypeSelector = ({
  brand,
  contentType,
  onChange,
}: Props) => {
  const creatorProfilePicture =
    brand.creators.find((c) => c.profilePictureUrl)?.profilePictureUrl || '';

  return (
    <ContextMenu
      options={[
        {
          renderCustomComponent: () => (
            <Box
              minWidth={250}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              sx={{
                cursor: 'pointer',
                p: theme.spacing(2, 4),
                borderRadius: theme.spacing(2),
                '&:hover': {
                  backgroundColor: theme.colors?.utility[275],
                },
              }}
              onClick={() => onChange(BrandContentType.Owned)}
            >
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="body-lg" fontWeight={500}>
                  Owned analytics
                </Typography>
                <Typography
                  variant="body-xs"
                  sx={{
                    ...contentTypeToColor[BrandContentType.Owned],
                    p: theme.spacing(1, 2),
                    borderRadius: theme.spacing(5),
                    fontWeight: 600,
                    width: 'fit-content',
                  }}
                >
                  Brand Content
                </Typography>
              </Box>
              {contentType === BrandContentType.Owned && (
                <IconCustomTick size={12} />
              )}
            </Box>
          ),
        },
        {
          renderCustomComponent: () => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              sx={{
                cursor: 'pointer',
                p: theme.spacing(2, 4),
                borderRadius: theme.spacing(2),
                '&:hover': {
                  backgroundColor: theme.colors?.utility[275],
                },
              }}
              onClick={() => onChange(BrandContentType.Ugc)}
            >
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="body-lg" fontWeight={500}>
                  User Generated Content
                </Typography>
                <Typography
                  variant="body-xs"
                  sx={{
                    ...contentTypeToColor[BrandContentType.Ugc],
                    p: theme.spacing(1, 2),
                    borderRadius: theme.spacing(5),
                    fontWeight: 600,
                    width: 'fit-content',
                  }}
                >
                  Brand Mention
                </Typography>
              </Box>
              {contentType === BrandContentType.Ugc && (
                <IconCustomTick size={12} />
              )}
            </Box>
          ),
        },
      ]}
      renderButton={() => (
        <Box
          display="flex"
          alignItems="center"
          gap={3}
          sx={{
            backgroundColor: theme.colors?.utility[275],
            p: theme.spacing(2, 4),
            borderRadius: theme.spacing(20),
            cursor: 'pointer',
          }}
        >
          <Avatar
            sx={{
              width: 24,
              height: 24,
            }}
            src={creatorProfilePicture}
          />
          <Typography variant="headline-lg" color={theme.colors?.utility[800]}>
            {brand.name}
          </Typography>
          <Typography
            variant="body-xl"
            sx={{
              ...contentTypeToColor[contentType],
              p: theme.spacing(1, 2),
              borderRadius: theme.spacing(5),
              fontWeight: 600,
            }}
          >
            {contentType === BrandContentType.Owned
              ? 'Owned analytics'
              : 'User Generated Content'}
          </Typography>

          <IconLinearArrowDown size={16} />
        </Box>
      )}
    />
  );
};
