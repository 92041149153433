import { Box } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { SocialMediaListeningCreatorProfile } from 'features/socialMediaListening/components';
import { TrackUntrackCreatorButton } from 'features/socialMediaListening/components/trackUntrackCreatorButton';
import { CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment } from 'graphql/generated';

type Props = {
  updatingCreatorPlatformIds: string[];
  trackedCreators: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[];
  usersListToDisplay: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[];
  showDetailsOnHover?: boolean;
  handleCreatorTracking: (
    creatorPlatformId: string,
    handle: string,
    track: boolean,
  ) => void;
};

export const CreatorsListing = ({
  showDetailsOnHover,
  trackedCreators,
  usersListToDisplay,
  updatingCreatorPlatformIds,
  handleCreatorTracking,
}: Props) => {
  return (
    <>
      {usersListToDisplay.map((creator) => (
        <Box display="flex" justifyContent="space-between" width="100%">
          <SocialMediaListeningCreatorProfile
            creator={
              creator as unknown as CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment
            }
            componentProps={{
              showDetailsOnHover,
              containerSx: {
                sx: {
                  '& .handle': {
                    ...typography['body-xl'],
                  },
                  '& .nickName': {
                    ...typography['body-lg'],
                  },
                },
              },
            }}
          />

          <TrackUntrackCreatorButton
            updating={updatingCreatorPlatformIds.includes(creator.platformId)}
            tracking={
              !!trackedCreators.find((c) => c.platformId === creator.platformId)
            }
            onChange={(track) => {
              handleCreatorTracking(creator.platformId, creator.handle, track);
            }}
          />
        </Box>
      ))}
    </>
  );
};
