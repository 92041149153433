import { SxProps } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { theme } from 'styles/theme';

export const TableHeaders: {
  label: React.ReactNode;
  key: string;
  canSort?: boolean;
  sx: SxProps;
}[] = [
  {
    label: '#',
    key: '#',
    sx: {
      width: 8,
      color: theme.colors?.utility[700],
    },
  },
  {
    label: 'Logo',
    key: 'Logo',
    sx: {
      ...typography['body-lg'],
      fontWeight: 600,
      color: theme.colors?.utility[700],
      width: theme.spacing(18),
    },
  },
  {
    label: 'Brand',
    key: 'Brand',
    sx: {
      ...typography['body-lg'],
      fontWeight: 600,
      color: theme.colors?.utility[700],
      minWidth: theme.spacing(60),
      width: theme.spacing(60),
      maxWidth: theme.spacing(60),
    },
  },
  {
    label: 'Followers',
    key: 'followerCount',
    canSort: true,
    sx: {
      ...typography['body-lg'],
      fontWeight: 600,
      color: theme.colors?.utility[700],
      minWidth: theme.spacing(36),
    },
  },
  {
    label: 'Total Impressions',
    key: 'totalImpressions',
    canSort: true,
    sx: {
      ...typography['body-lg'],
      fontWeight: 600,
      color: theme.colors?.utility[700],
      minWidth: theme.spacing(45),
    },
  },
  {
    label: 'Engagement rate',
    key: 'engagementRate',
    canSort: true,
    sx: {
      ...typography['body-lg'],
      fontWeight: 600,
      color: theme.colors?.utility[700],
      minWidth: theme.spacing(45),
    },
  },
  {
    label: 'Average video views',
    key: 'averageVideoViews',
    canSort: true,
    sx: {
      ...typography['body-lg'],
      fontWeight: 600,
      color: theme.colors?.utility[700],
      minWidth: theme.spacing(46),
    },
  },
  {
    label: 'Average likes',
    key: 'averageDigg',
    canSort: true,
    sx: {
      ...typography['body-lg'],
      fontWeight: 600,
      color: theme.colors?.utility[700],
      minWidth: theme.spacing(36),
    },
  },
  {
    label: 'Top Posts',
    key: 'Top Posts',
    sx: {
      ...typography['body-lg'],
      minWidth: theme.spacing(44),
      fontWeight: 600,
      color: theme.colors?.utility[700],
    },
  },
  {
    label: 'Average # of posts',
    key: 'averageSocialPostCreationPerWeek',
    canSort: true,
    sx: {
      ...typography['body-lg'],
      minWidth: theme.spacing(44),
      fontWeight: 600,
      color: theme.colors?.utility[700],
    },
  },
  {
    label: '# of brand mentions',
    key: 'averageBrandMentionedPerWeek',
    canSort: true,
    sx: {
      ...typography['body-lg'],
      minWidth: theme.spacing(46),
      fontWeight: 600,
      color: theme.colors?.utility[700],
    },
  },
  {
    label: 'Sentiment',
    key: 'Sentiment',
    sx: {
      ...typography['body-lg'],
      minWidth: theme.spacing(40),
      fontWeight: 600,
      color: theme.colors?.utility[700],
    },
  },
];
