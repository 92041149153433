import { Components, Theme } from '@mui/material';

export const paperOverrides: Components<Theme>['MuiPaper'] = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        border: `1px solid ${theme.colors?.utility[300]}`,
        borderRadius: 12,

        // For Select
        '& .MuiList-root': {
          padding: 0,
        },
      };
    },
  },
};
