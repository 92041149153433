import { Box, styled } from '@mui/material';

export const StyledFormContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '75px',
}));

export const StyledContentContainer = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
}));
