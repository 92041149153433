import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Popover,
  Radio,
  TextField,
  Typography,
  radioClasses,
} from '@mui/material';
import { IconBoldArrowDown } from 'components/icons/components/bold/IconBoldArrowDown';
import { IconBoldCalendar } from 'components/icons/components/bold/IconBoldCalendar';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  CollectionBreadcrumbs,
  CollectionMenuItemAddNewView,
  CollectionMenuItemView,
  CollectionMenuItemViewProps,
} from 'features/collection';
import { useAutocompleteInfiniteCollections } from 'features/collection/hooks/useAutocompleteInfiniteCollections';
import { ContentCalendarCollectionMenuItem } from 'features/contentCalendar';
import {
  CollectionFragmentCollectionBreadcrumbsFragmentDoc,
  CollectionPermission,
  ContentIdeaFragmentContentIdeaCollectionFragment,
  ContentIdeaPermission,
  SearchHitType,
  useUpdateContentIdeaForContentIdeaCollectionMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

interface ContentIdeaCollectionProps {
  contentIdea: ContentIdeaFragmentContentIdeaCollectionFragment;
}

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_COLLECTION = gql`
  fragment ContentIdeaFragmentContentIdeaCollection on ContentIdeaModel {
    id
    myPermissions
    rootCollection {
      id
      collection {
        ...CollectionFragmentCollectionBreadcrumbs
      }
    }
  }
  ${CollectionFragmentCollectionBreadcrumbsFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentIdeaForContentIdeaCollection(
    $data: UpdateContentIdeaInput!
  ) {
    updateContentIdea(data: $data) {
      id
      ...ContentIdeaFragmentContentIdeaCollection
    }
  }
  ${CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_COLLECTION}
`;

export const ContentIdeaCollection = ({
  contentIdea,
}: ContentIdeaCollectionProps) => {
  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure();

  const selectedCollectionId = contentIdea.rootCollection?.id;

  const [updateContentIdea] =
    useUpdateContentIdeaForContentIdeaCollectionMutation();

  const { triggerCommand } = useCommandContext();

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  const [query, setQuery] = useState('');
  const debouncedSetQuery = useMemo(() => debounce(setQuery, 300), []);

  const {
    autoCompleteProps,
    collectionSearchHits = [],
    fetchingCollections,
    loadCollectionsData,
  } = useAutocompleteInfiniteCollections({
    postIds: [],
    searchStr: query,
  });

  useEffect(() => {
    loadCollectionsData();
  }, []); // eslint-disable-line

  // By default, this should only return parent-level collections
  const collections = useMemo(() => {
    return collectionSearchHits
      .map((h) => {
        return {
          ...h.item,
          searchHitType: h.type,
        };
      })
      .filter((c) => c.myPermissions.includes(CollectionPermission.Update));
  }, [collectionSearchHits]);

  const sortedCollections = useMemo(() => {
    return collections
      .filter(
        (collection, index, self) =>
          self.findIndex((c) => c.id === collection.id) === index,
      )
      .sort((a, b) => {
        // Push selected collections to the top
        const aIsSelected = selectedCollectionId === a.id ? 1 : 0;
        const bIsSelected = selectedCollectionId === b.id ? 1 : 0;

        return bIsSelected - aIsSelected;
      });
  }, [collections]); // eslint-disable-line

  const onUpdateContentIdea = (collectionId?: string) => {
    updateContentIdea({
      variables: {
        data: {
          contentIdeaId: contentIdea.id,
          data: {
            rootCollectionId:
              selectedCollectionId === collectionId
                ? null
                : collectionId || null,
          },
        },
      },
      update: (cache, { data }) => {
        if (!data?.updateContentIdea) return;
        modifyObject(cache, contentIdea.id, 'ContentIdeaModel', {
          rootCollection: () => data.updateContentIdea.rootCollection,
        });
      },
    });
  };

  const commonMenuItemProps: Omit<
    Partial<CollectionMenuItemViewProps>,
    'onClick'
  > = {
    selectIconPosition: 'start',
    componentProps: {
      listItem: {
        sx: {
          maxWidth: 240,
        },
      },
      menu: {
        sx: {
          '& .MuiPaper-root': {
            boxShadow:
              'rgba(24, 39, 75, 0.12) 0px 12px 42px -4px, rgba(24, 39, 75, 0.12) 0px 8px 18px -6px !important',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(24px)',
            width: 310,
          },
        },
      },
    },
    renderSelectIcon: (isSelected) => (
      <Radio
        checked={isSelected}
        size="small"
        disableRipple
        sx={{
          padding: 0,
          '& .MuiSvgIcon-root': {
            color: `${theme.colors?.primary.black} !important`,
          },
          [`& .${radioClasses.root}`]: {
            '& svg': {
              width: '16px',
              height: '16px',
            },
          },
        }}
      />
    ),
  };

  const showContentCalendarMenuItem =
    !query || 'content calendar'.startsWith(query.toLowerCase());
  const isEmpty =
    !fetchingCollections &&
    collections.length === 0 &&
    !showContentCalendarMenuItem;

  return (
    <>
      <Box
        ref={anchorElRef}
        component="button"
        type="button"
        onClick={openMenu}
        disabled={!canEdit}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: 25,
            background: `rgba(35, 6, 3, 0.07)`,
            padding: theme.spacing(1, 2.5),
            gap: 2,
          }}
        >
          {contentIdea.rootCollection ? (
            contentIdea.rootCollection.collection ? (
              <>
                <IconBoldCalendar
                  color={theme.colors?.primary.black}
                  size={16}
                  style={{ flexShrink: 0 }}
                />
                <CollectionBreadcrumbs
                  collection={contentIdea.rootCollection.collection}
                  onCollectionClick={() => {}}
                  delimiter="/"
                  ellipse
                />
              </>
            ) : (
              <>
                <IconBoldLock size={16} color={theme.colors?.utility[700]} />
                <Typography
                  variant="headline-xs"
                  color={theme.colors?.utility[700]}
                >
                  Private Collection
                </Typography>
              </>
            )
          ) : (
            <>
              <IconBoldCalendar
                color={theme.colors?.primary.black}
                size={16}
                style={{ flexShrink: 0 }}
              />
              <Typography
                variant="headline-xs"
                color={theme.colors?.primary.black}
              >
                Content Calendar
              </Typography>
            </>
          )}
          {canEdit && (
            <IconBoldArrowDown
              size={16}
              color={theme.colors?.utility[600]}
              style={{ flexShrink: 0 }}
            />
          )}
        </Box>
      </Box>
      <Popover
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorElRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: -16,
        }}
        disablePortal
        PaperProps={{
          sx: {
            borderRadius: 3,
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(20px)`,
            border: 'none',
            boxShadow:
              '0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 0,
            msOverflowStyle: 'none',
          },
        }}
      >
        <Box
          sx={{
            px: 2,
          }}
        >
          <TextField
            autoFocus
            placeholder="Search"
            onChange={(e) => debouncedSetQuery(e.target.value)}
            sx={{
              px: 2,
              mt: 4,
              width: '100%',
              '.MuiOutlinedInput-root': {
                height: '32px',
                borderRadius: 100,
                py: '6px !important',
                px: '12px !important',
                bgcolor: theme.colors?.primary.white,
                boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',

                input: {
                  ...theme.typography['subhead-lg'],
                  p: '0 !important',
                },

                '.MuiOutlinedInput-notchedOutline': {
                  display: 'none !important',
                },
              },
            }}
          />
        </Box>
        <autoCompleteProps.ListBoxComponent
          {...autoCompleteProps.ListboxProps}
          style={{
            margin: 0,
            padding: theme.spacing(2),
            minWidth: 300,
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          {showContentCalendarMenuItem ? (
            <ContentCalendarCollectionMenuItem
              selectedCollectionIds={
                contentIdea.rootCollection?.id
                  ? [contentIdea.rootCollection.id]
                  : []
              }
              allowSelect
              onClick={(selectedCollection) =>
                onUpdateContentIdea(selectedCollection?.id)
              }
              {...commonMenuItemProps}
            />
          ) : null}
          {sortedCollections.map((collection) => (
            <CollectionMenuItemView
              key={collection.id}
              collection={collection}
              onClick={(selectedCollection) =>
                onUpdateContentIdea(selectedCollection.id)
              }
              selectedCollectionIds={[selectedCollectionId || '']}
              shouldShowBreadcrumbsForRoot
              {...commonMenuItemProps}
              componentProps={{
                ...commonMenuItemProps.componentProps,
                listItem: {
                  ...commonMenuItemProps.componentProps?.listItem,
                  isSmartSearchResult:
                    collection.searchHitType === SearchHitType.Smart,
                },
              }}
            />
          ))}
          {isEmpty && !!query && (
            <CollectionMenuItemAddNewView
              label={query}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                p: 2,
              }}
              onClick={() => {
                triggerCommand(COMMAND_TYPE.CREATE_COLLECTION, {
                  initialValues: {
                    name: query,
                    posts: [],
                  },
                  onCompleted: (collection) => {
                    onUpdateContentIdea(collection.id);
                  },
                  onSelectExistingCollection: (collectionId) => {
                    onUpdateContentIdea(collectionId);
                  },
                });
              }}
            />
          )}
        </autoCompleteProps.ListBoxComponent>
      </Popover>
    </>
  );
};
