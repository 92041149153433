import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Popover, SxProps, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { Platform } from 'graphql/generated';
import { useRef } from 'react';
import { theme } from 'styles/theme';

export type SocialMediaListeningFilterByPlatformsButtonProps = {
  selectedPlatforms: Platform[];
  onChange: (platforms: Platform[]) => void;
  componentProps?: {
    sx?: SxProps;
  };
};

export const SocialMediaListeningFilterByPlatformsButton = (
  props: SocialMediaListeningFilterByPlatformsButtonProps,
) => {
  const { selectedPlatforms, onChange, componentProps } = props;

  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  const platforms = selectedPlatforms.filter((p) => p !== Platform.Reel);

  return (
    <>
      <Button
        ref={anchorElRef}
        variant="tertiary"
        startIcon={<IconLinearMonitorMobile size={16} />}
        endIcon={<IconLinearArrowDown size={16} />}
        sx={{
          borderRadius: 2,
          ...componentProps?.sx,
        }}
        onClick={openPopover}
      >
        <Typography variant="subhead-xl">
          {platforms.length > 0 ? platforms.join(' & ') : 'Platforms'}
        </Typography>
      </Button>
      <Popover
        open={isPopoverOpen}
        onClose={closePopover}
        anchorEl={anchorElRef.current}
        PaperProps={{
          sx: {
            mt: 2,
            p: 4,
            width: 270,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {[
          {
            label: Platform.Tiktok,
            value: Platform.Tiktok,
            Icon: IconCustomTiktok,
          },
          {
            label: Platform.Instagram,
            value: Platform.Instagram,
            Icon: IconBoldInstagram,
          },
        ].map(({ label, value, Icon }) => {
          const checked = selectedPlatforms.includes(value);

          return (
            <CheckboxMenuItem
              key={value}
              value={value}
              checked={checked}
              onClick={() => {
                if (!checked) {
                  onChange([...selectedPlatforms, value]);
                } else {
                  onChange(selectedPlatforms.filter((x) => x !== value));
                }
              }}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Icon size={16} />
                  <Typography variant="subhead-lg">{label}</Typography>
                </Box>
              }
            />
          );
        })}
      </Popover>
    </>
  );
};
