import { JSONContent } from '@tiptap/react';
import { NODE_COLUMN, NODE_COLUMN_LIST } from '../constants';

export const buildNode = (content: JSONContent): JSONContent => {
  return { ...content };
};

export const buildColumn = (content: JSONContent) =>
  buildNode({ ...content, type: NODE_COLUMN });

export const buildColumnBlock = (content: JSONContent) =>
  buildNode({ ...content, type: NODE_COLUMN_LIST });
