import { Box, styled } from '@mui/material';

export const StyledCell = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  padding: `${theme.spacing(4)} 0px`,
  borderBottom: `1px solid ${theme.colors?.utility[300]}`,
  cursor: 'pointer',
}));
