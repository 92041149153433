import { Box, IconButton, Typography } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconCustomTodo } from 'components/icons/components/custom/IconCustomTodo';
import { UserProfilePermissionItemView } from 'features/user-profile';
import { UserProfileFragmentUserProfilePermissionItemViewFragment } from 'graphql/generated';
import { ReactNode, useMemo } from 'react';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import {
  BillingGuestLimitIndicator,
  BillingOrganizationMemberLimitIndicator,
} from 'features/billing';

type PermissionMemberItemProps = {
  entityOrganizationId: string;
  totalMembers?: number;
  totalGuests?: number;
  userProfile: UserProfileFragmentUserProfilePermissionItemViewFragment;
  lastSeen?: string;
  type: 'owner' | 'suggested' | 'custom';
  endAdornmentProps?: {
    suggested?: {
      isSelected?: boolean;
      toggleSelect: (
        userProfile: UserProfileFragmentUserProfilePermissionItemViewFragment,
      ) => void;
    };
    custom?: {
      endAdornment: ReactNode;
    };
  };
  readonly?: boolean;
};
export const PermissionMemberItem = (props: PermissionMemberItemProps) => {
  const {
    entityOrganizationId,
    totalMembers,
    totalGuests,
    userProfile,
    lastSeen,
    type,
    endAdornmentProps,
    readonly,
  } = props;

  const { user, isWorkOrganization, orgBilling, joinedOrgBillings } =
    useUserContext();

  const organizationBilling = useMemo(() => {
    if (orgBilling?.organizationId === entityOrganizationId) {
      return orgBilling;
    }
    return joinedOrgBillings?.find(
      (j) => j.organizationId === entityOrganizationId,
    );
  }, [orgBilling, joinedOrgBillings, entityOrganizationId]);

  // if non-work organization, everyone is a guest unless he/she adds in member in setting page
  const isGuest = useMemo(() => {
    if (isWorkOrganization) {
      return userProfile.organization.id !== entityOrganizationId;
    }

    return user?.organization.users.every(
      (internalUser) => internalUser.id !== userProfile.id,
    );
  }, [
    user?.organization.users,
    isWorkOrganization,
    userProfile,
    entityOrganizationId,
  ]);

  const isAlreadyJoinedOrganization = useMemo(() => {
    if (isGuest) {
      return user?.organization.externalUsers.some(
        (u) => u.id === userProfile.id,
      );
    }
    return user?.organization.users.some((u) => u.id === userProfile.id);
  }, [
    isGuest,
    user?.organization.externalUsers,
    user?.organization.users,
    userProfile.id,
  ]);

  const isUserNotAllowedToBeInvited = useMemo(() => {
    return isGuest
      ? !!userProfile.isDisabled
      : !userProfile.hasSignedUp || !!userProfile.isDisabled;
  }, [isGuest, userProfile.hasSignedUp, userProfile.isDisabled]);

  const renderEndAdornment = () => {
    switch (type) {
      case 'owner': {
        return (
          <Typography
            variant="body-lg"
            fontWeight={600}
            color={theme.colors?.utility[800]}
            mr={2}
          >
            Owner
          </Typography>
        );
      }
      case 'suggested': {
        const suggestedProps = endAdornmentProps!.suggested!;

        const selectOption = (
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => suggestedProps.toggleSelect(userProfile)}
          >
            {suggestedProps.isSelected ? (
              <IconBoldTickCircle
                size={20}
                color={theme.colors?.primary.black}
              />
            ) : (
              <IconCustomTodo size={20} color={theme.colors?.primary.black} />
            )}
          </IconButton>
        );

        return isUserNotAllowedToBeInvited ? null : isAlreadyJoinedOrganization ? (
          selectOption
        ) : !organizationBilling ? null : isGuest ? (
          <BillingGuestLimitIndicator
            organizationBilling={organizationBilling}
            moreGuestUsage={totalGuests}
            variant="text"
            sx={{
              maxWidth: theme.spacing(29),
              textAlign: 'right',
            }}
            renderNoPaywallHit={() => selectOption}
          />
        ) : (
          <BillingOrganizationMemberLimitIndicator
            organizationBilling={organizationBilling}
            moreMemberUsage={totalMembers}
            variant="text"
            sx={{
              maxWidth: theme.spacing(29),
              textAlign: 'right',
            }}
            renderNoPaywallHit={() => selectOption}
          />
        );
      }
      case 'custom': {
        const customProps = endAdornmentProps!.custom!;

        return customProps.endAdornment;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        filter: isUserNotAllowedToBeInvited ? 'grayscale(1)' : 'grayscale(0)',
        opacity: isUserNotAllowedToBeInvited ? 0.6 : 1,
      }}
    >
      <UserProfilePermissionItemView
        userProfile={userProfile}
        isGuest={isGuest}
        lastSeen={lastSeen}
        isUserNotAllowedToBeInvited={isUserNotAllowedToBeInvited}
      />

      {!readonly && renderEndAdornment()}
    </Box>
  );
};
