import moment, { Duration } from 'moment';

export const formatDuration = (duration: Duration) => {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return [
    // Hide hours if it's 0
    hours && `0${hours}`.slice(-2),
    `0${minutes}`.slice(-2),
    `0${seconds}`.slice(-2),
  ]
    .filter(Boolean)
    .join(':');
};

export class DateUtils {
  static fromNowInDays(input: moment.MomentInput) {
    const m = moment(input);
    const today = moment();

    const duration = moment.duration({ from: today, to: m });

    const numberOfDays = Math.floor(duration.asDays());

    return `${numberOfDays} day${numberOfDays > 1 ? 's' : ''}`;
  }
}
