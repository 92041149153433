import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Typography,
} from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconOutlineVideo } from 'components/icons/components/outline/IconOutlineVideo';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { PlotFeature } from 'graphql/generated';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const SocialListeningSidebarLink = () => {
  const { isFeatureEnabled } = useFeatureFlagContext();
  const competitorAnalysisEnabled = isFeatureEnabled(
    PlotFeature.CompetitorAnalysis,
  );
  const { user } = useUserContext();
  const hasSocialListeningData = !!user?.socialListeningBrands.length;
  const slBrands = user?.socialListeningBrands || [];

  return (
    <Accordion
      defaultExpanded
      className="sl-header"
      sx={{
        '& .MuiAccordionDetails-root': {
          pr: 0,
          pl: 5,
          py: 2,
        },
        '&.MuiPaper-root.sl-header': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: 'none',
        },
        '& .MuiAccordionSummary-root.sl-header': {
          minHeight: 'unset !important',
          my: 0,
          mx: 0,
          padding: 0,
        },
        '& .MuiAccordionSummary-content': {
          margin: `0 !important`,
        },
      }}
    >
      <AccordionSummary
        className="sl-header"
        expandIcon={<IconLinearArrowDown size={16} />}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <IconOutlineVideo size={20} />
          <Typography
            ml={3}
            variant="subhead-md"
            color={theme.colors?.utility[900]}
            width="100%"
          >
            Social Listening
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {slBrands.map((slBrand) => (
          <Accordion
            key={slBrand.id}
            sx={{
              '& .MuiAccordionDetails-root': {
                pt: 1,
                pl: 4,
              },
              '&.MuiPaper-root': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none',
                my: 0,
              },
              '&.MuiPaper-root::before': {
                display: 'none',
              },
              '& .MuiAccordionSummary-root': {
                minHeight: `${theme.spacing(8)} !important`,
                my: 0,
              },
              '& .MuiAccordionSummary-content': {},
            }}
          >
            <AccordionSummary
              expandIcon={<IconLinearArrowDown size={16} />}
              id="brand-header"
              sx={{
                mx: 0,
                px: 0,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  ml: 0,
                  px: 0,
                }}
              >
                <Avatar
                  src={
                    slBrand?.creators?.[0]?.profilePictureUrl ||
                    slBrand?.creators?.[1]?.profilePictureUrl ||
                    ''
                  }
                  sx={{
                    width: theme.spacing(3.5),
                    height: theme.spacing(3.5),
                  }}
                />
                <Typography
                  component={Link}
                  onClick={(e) => e.stopPropagation()}
                  to={PlotRoutes.socialListeningForBrand(slBrand.id)}
                  ml={2}
                  variant="subhead-md"
                  color={theme.colors?.utility[900]}
                  width="100%"
                >
                  {slBrand.name}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {hasSocialListeningData ? (
                <>
                  <Box
                    component={Link}
                    to={PlotRoutes.socialListeningForBrand(slBrand.id)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 0,
                      pb: 2,
                    }}
                  >
                    <Typography
                      ml={3}
                      variant="subhead-md"
                      width="100%"
                      sx={{
                        color: theme.colors?.utility[700],
                      }}
                    >
                      Engagement
                    </Typography>
                  </Box>
                  <Box
                    component={Link}
                    to={PlotRoutes.socialListeningAnalyticsInbound(slBrand.id)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 0,
                      pb: 2,
                    }}
                  >
                    <Typography
                      ml={3}
                      variant="subhead-md"
                      width="100%"
                      sx={{
                        color: theme.colors?.utility[700],
                      }}
                    >
                      Brand Analytics
                    </Typography>
                  </Box>
                  {(competitorAnalysisEnabled ||
                    (user.organization.id !== slBrand.organizationId &&
                      slBrand.competitorAnalysisEnabled)) && (
                    <Box
                      component={Link}
                      to={PlotRoutes.socialListeningCompetitors(slBrand.id)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 0,
                        pb: 2,
                      }}
                    >
                      <Typography
                        ml={3}
                        variant="subhead-md"
                        width="100%"
                        sx={{
                          color: theme.colors?.utility[700],
                        }}
                      >
                        Competitor Analytics
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  component={Link}
                  to={PlotRoutes.socialListeningIntro()}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    pb: 1,
                  }}
                >
                  <Typography
                    ml={3}
                    variant="subhead-md"
                    width="100%"
                    sx={{
                      color: theme.colors?.utility[700],
                    }}
                  >
                    Intro
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
