import { gql } from '@apollo/client';
import {
  CreatePermissionRequestsInput,
  CreatePermissionRequestsInputData,
  useCreatePermissionRequestsMutation,
} from 'graphql/generated';

//eslint-disable-next-line
gql`
  mutation CreatePermissionRequests($data: CreatePermissionRequestsInput!) {
    createPermissionRequests(data: $data) {
      id
    }
  }
`;

export const useCommentPermissionRequestHandlers = () => {
  const [createPermissionRequests] = useCreatePermissionRequestsMutation();
  const onCreatePermissionRequest = (
    input: CreatePermissionRequestsInputData[],
  ) => {
    createPermissionRequests({
      variables: {
        data: {
          input,
        },
      },
    });
  };

  return { onCreatePermissionRequest };
};
