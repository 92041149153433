import { gql } from '@apollo/client';
import {
  ContentIdeaFieldValueFragmentContentIdeaFieldTextFragmentDoc,
  ContentIdeaFragmentContentIdeaAudioFragment,
  ContentIdeaPermission,
  FieldType,
} from 'graphql/generated';
import { ContentIdeaField, TextFieldProps } from '../field';

export const CONTENT_IDEA_FRAGMENT_CONTENT_IDEA_AUDIO = gql`
  fragment ContentIdeaFragmentContentIdeaAudio on ContentIdeaModel {
    id
    myPermissions
    audio {
      id
      contentIdeaField {
        id
      }
      ...ContentIdeaFieldValueFragmentContentIdeaFieldText
    }
  }
  ${ContentIdeaFieldValueFragmentContentIdeaFieldTextFragmentDoc}
`;

export type ContentIdeaAudioProps = {
  contentIdea: ContentIdeaFragmentContentIdeaAudioFragment;
  renderInput?: Omit<TextFieldProps, 'value' | 'onChange'>;
};

export const ContentIdeaAudio = (props: ContentIdeaAudioProps) => {
  const { contentIdea, renderInput } = props;

  const canEdit = contentIdea.myPermissions.includes(
    ContentIdeaPermission.Update,
  );

  return (
    <ContentIdeaField
      id={contentIdea.audio.contentIdeaField.id}
      valueId={contentIdea.audio.id}
      value={{
        text: contentIdea.audio.value?.text || '',
      }}
      readOnly={!canEdit}
      type={FieldType.Text}
      placeholder={canEdit ? 'Add a audio link...' : 'Empty'}
      {...renderInput}
    />
  );
};
