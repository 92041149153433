import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Dialog,
  IconButton,
  Input,
  InputProps,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { CommandHandler } from 'contexts/commands/types';
import { useCollectionIdFromParams } from 'features/collection';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { theme } from 'styles/theme/theme';
import { validateUrl } from 'utils/helpers';
import { usePostHandlers } from '../hooks/usePostHandlers';
import { StatusPopup } from './StatusPopup';

export type PostPasteALinkHandlerContext = {
  shouldShowAfterCreationDialog?: boolean;
  collectionId?: string;
  taskId?: string;
  componentsProps?: {
    input?: InputProps;
  };
};

export const PostPasteALinkHandler: CommandHandler<
  COMMAND_TYPE.POST_PASTE_A_LINK
> = (props) => {
  const analytics = useAnalytics();
  const isMobileView = useMediaQueryMobile();

  const { commandId, context } = props;
  const { triggerCommand, updateActiveCommand } = useCommandContext();

  const { collectionId: collectionIdFromParams } = useCollectionIdFromParams();
  const collectionId = context?.collectionId ?? collectionIdFromParams;

  const {
    isOpen: isPasteALinkDialogOpen,
    onOpen: openPasteALinkDialog,
    onClose: closePasteALinkDialog,
  } = useDisclosure();

  const { onCreateLinkPost } = usePostHandlers();

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      url: '',
    },
  });

  const triggerUploadFilesCommand = (files: File[]) => {
    triggerCommand(COMMAND_TYPE.POST_UPLOAD_FILES, {
      skipFileBrowser: true,
      initialFiles: files.map((i) => ({ file: i })),
      taskId: context?.taskId,
      collectionId,
    });
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    // If there's an url in the clipboard, set it to url value
    const url = e.clipboardData.getData('text');
    if (validateUrl(url)) {
      setValue('url', validateUrl(url));
    } else {
      // If there are images (screenshots) in the clipboard, trigger upload files command
      const items = e.clipboardData.items;
      const files: File[] = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.type === 'image/png') {
          const file = item.getAsFile();

          if (file) {
            files.push(file);
          }
        }
      }

      if (files.length > 0) {
        triggerUploadFilesCommand(files);
      }
    }

    e.clipboardData.clearData();
    navigator.clipboard.writeText('');
  };

  const internalOnSubmit = handleSubmit(async (values) => {
    closePasteALinkDialog();
    reset();

    try {
      const startAt = performance.now();

      updateActiveCommand(commandId, {
        status: 'loading',
      });
      const post = await onCreateLinkPost({
        url: values.url,
        taskId: context?.taskId,
        collectionId,
      });
      updateActiveCommand(commandId, {
        status: 'completed',
        data: post ? [post] : [],
      });

      if (post && context?.shouldShowAfterCreationDialog !== false) {
        // Trigger after-creation command
        triggerCommand(COMMAND_TYPE.POST_AFTER_CREATION, {
          initialValues: {
            posts: [post],
          },
          postCreationStartAt: startAt,
        });
      }
    } catch {
      updateActiveCommand(commandId, {
        status: 'error',
      });
    }
  });

  // Read existing data from clipboard when this command is triggered by shortcut
  // Check this only in desktop, on mobile it throws an error
  useEffect(() => {
    if (
      isPasteALinkDialogOpen &&
      !isMobileView &&
      context?.triggeredByShortcut
    ) {
      // Check if there's any existing url in the clipboard
      // and pre-fill the input
      navigator?.clipboard?.readText().then((text) => {
        try {
          const parsedURL = new URL(text);
          setValue('url', parsedURL.href);

          // Clear clipboard
          navigator.clipboard.writeText('');
        } catch {
          // Do nothing
        }
      });

      // Check if there's any existing screenshots in the clipboard
      // and trigger upload files command
      navigator?.clipboard?.read().then(async (clipboardItems) => {
        const files: File[] = [];

        for (let i = 0; i < clipboardItems.length; i++) {
          const clipboardItem = clipboardItems[i];

          if (clipboardItem.types.includes('image/png')) {
            // eslint-disable-next-line
            const blob = await clipboardItem.getType('image/png');
            const file = new File([blob], 'image.png', {
              type: blob.type,
              lastModified: Date.now(),
            });
            files.push(file);
          }
        }

        if (files.length > 0) {
          triggerUploadFilesCommand(files);

          // Clear clipboard
          navigator.clipboard.writeText('');
        }
      });
    }
  }, [isPasteALinkDialogOpen, isMobileView]); // eslint-disable-line

  useEffect(() => {
    openPasteALinkDialog();
    analytics.track(EventName.LinkPostOpened);
  }, []); // eslint-disable-line -- triger flow on mount

  const isValidUrl = validateUrl(watch('url')) !== '';

  return (
    <>
      <Dialog
        open={isPasteALinkDialogOpen}
        onClose={() => {
          closePasteALinkDialog();
          reset();
        }}
        PaperProps={{
          sx: {
            width: '100vw',
            maxWidth: isMobileView ? '90vw' : '75vw',
            borderRadius: '200px',
            margin: 0,
          },
        }}
      >
        <Box
          component="form"
          sx={{
            px: isMobileView ? 5 : 10,
            py: 3,
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={internalOnSubmit}
        >
          <Input
            {...register('url', {
              validate: async (text) => {
                return Boolean(validateUrl(text)) || 'Invalid url';
              },
            })}
            sx={{
              ...typography['headline-xl'],
              fontSize: isMobileView ? 22 : 28,
              width: '100%',
            }}
            placeholder="paste a link, image or screenshot"
            autoFocus
            disableUnderline
            onPaste={onPaste}
            {...context?.componentsProps?.input}
            endAdornment={
              context?.componentsProps?.input?.endAdornment ? (
                <IconButton
                  sx={{
                    p: 0,
                    ...(isValidUrl && {
                      '& svg': {
                        color: theme.colors?.primary.black,
                      },
                    }),
                  }}
                >
                  {context?.componentsProps?.input?.endAdornment}
                </IconButton>
              ) : (
                !isMobileView && (
                  <Box
                    component="button"
                    type="button"
                    onClick={internalOnSubmit}
                  >
                    <Typography
                      variant="headline-xl"
                      fontSize={28}
                      color={theme.colors?.utility[600]}
                      whiteSpace="nowrap"
                      fontWeight={400}
                      pl={4}
                    >
                      enter ↪
                    </Typography>
                  </Box>
                )
              )
            }
          />
          {errors.url?.message && (
            <Box component="small" sx={{ color: theme.colors?.utility.red }}>
              {errors.url?.message || ''}
            </Box>
          )}
        </Box>
      </Dialog>

      <StatusPopup
        commandId={commandId}
        messages={{
          loading: '...Saving one juicy idea for later 🍹',
          completed: 'Added to your Juicebox! 🧃',
          error: '⚠️ Your post is down the drain. Please try again.',
        }}
      />
    </>
  );
};
