import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { IconButton } from '@mui/material';
import { ContextMenu, ContextMenuOption } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconLinearArrow } from 'components/icons/components/linear/IconLinearArrow';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineSetting2 } from 'components/icons/components/outline/IconOutlineSetting2';
import { TopicContentFilterSettingsDialogView } from 'features/topic/views';
import {
  ListeningTopicType,
  TopicFragmentTopicContentFilterSettingsDialogViewFragmentDoc,
  TopicFragmentTopicMoreContextMenuFragment,
  TopicStatus,
  useDeleteTopicForTopicMoreContextMenuMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

export const TOPIC_FRAGMENT_TOPIC_MORE_CONTEXT_MENU = gql`
  fragment TopicFragmentTopicMoreContextMenu on TopicModel {
    id
    type
    ...TopicFragmentTopicContentFilterSettingsDialogView
  }
  ${TopicFragmentTopicContentFilterSettingsDialogViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteTopicForTopicMoreContextMenu($topicId: String!) {
    deleteTopic(topicId: $topicId) {
      success
      message
    }
  }
`;

export type TopicMoreContextMenuProps = Pick<
  ContextMenuProps,
  'disablePortal' | 'sx'
> & {
  topic: TopicFragmentTopicMoreContextMenuFragment;
};

export const TopicMoreContextMenu = (props: TopicMoreContextMenuProps) => {
  const { topic, ...rest } = props;

  const navigate = useNavigate();

  const [deleteTopic] = useDeleteTopicForTopicMoreContextMenuMutation();
  const canDeleteTopic = topic.type !== ListeningTopicType.BrandMention;
  const {
    dialog: deleteConfirmationDialog,
    onOpen: openDeleteConfirmationDialog,
  } = useConfirmationDialog();

  const {
    isOpen: isTopicContentFilterSettingsDialogOpen,
    onOpen: openTopicContentFilterSettingsDialog,
    onClose: closeTopicContentFilterSettingsDialog,
  } = useDisclosure();

  return (
    <>
      <ContextMenu
        renderButton={() => {
          return (
            <IconButton size="small">
              <IconLinearMore size={24} />
            </IconButton>
          );
        }}
        options={[
          {
            renderOption: () => (
              <ContextMenuOption
                label="Update parsing limit"
                icon={<IconLinearArrow size={16} />}
              />
            ),
            onClick: () => {
              openTopicContentFilterSettingsDialog();
            },
          },
          {
            renderOption: () => (
              <ContextMenuOption
                label="Go to topic settings"
                icon={<IconOutlineSetting2 size={16} />}
              />
            ),
            onClick: () => {
              navigate(
                PlotRoutes.socialListeningSettings({
                  brandId: topic.brand.id,
                  tab: 'topics',
                  topicId: topic.id,
                }),
              );
            },
          },
          ...(canDeleteTopic
            ? [
                {
                  renderOption: () => (
                    <ContextMenuOption
                      label="Delete topic"
                      icon={<IconLinearTrash size={16} />}
                      sx={{
                        color: theme.colors?.utility['pink-3'],
                      }}
                      componentsProps={{
                        iconContainer: {
                          sx: {
                            bgcolor: theme.colors?.utility['pink-1'],
                          },
                        },
                      }}
                    />
                  ),
                  onClick: () => {
                    openDeleteConfirmationDialog({
                      onConfirm: () => {
                        deleteTopic({
                          variables: {
                            topicId: topic.id,
                          },
                          update: (cache) => {
                            modifyObject(cache, topic.id, 'TopicModel', {
                              status: () => TopicStatus.Deleted,
                            });
                          },
                        });
                      },
                    });
                  },
                },
              ]
            : []),
        ]}
        {...rest}
      />
      {deleteConfirmationDialog}
      <TopicContentFilterSettingsDialogView
        topic={topic}
        open={isTopicContentFilterSettingsDialogOpen}
        onClose={closeTopicContentFilterSettingsDialog}
      />
    </>
  );
};
