import { Box, CircularProgress, Switch, Typography } from '@mui/material';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { SocialMediaListeningKeywords } from 'features/socialMediaListening/components';
import { useBrandSearchTermsForSocialListeningOnboarding } from 'features/socialMediaListening/hooks';
import { TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

type SocialMediaListeningOnboardingKeywordsProps = {
  brandId?: string;
  topic?: TopicFragmentForUseTopicDataForSocialListeningOnboardingFragment;
  onNext: () => void;
};

export const SocialMediaListeningOnboardingKeywords = ({
  brandId,
  topic,
  onNext,
}: SocialMediaListeningOnboardingKeywordsProps) => {
  const existingKeywords = topic?.searchTerms.map((term) => term.term) || [];

  const [loadingAIKeywords, setLoadingAIKeywords] = useState(false);
  const [aiGeneratedKeywords, setAiGeneratedKeywords] = useState<string[]>([]);
  const [manuallyAddedKeywords, setManuallyAddedKeywords] =
    useState<string[]>(existingKeywords);
  const [allowingPlotToImprove, setAllowingPlotToImprove] = useState(
    !!topic?.allowToUpdateSearchTerm,
  );

  const { searchTermActions } =
    useBrandSearchTermsForSocialListeningOnboarding();
  const {
    handleCreateSearchTerms,
    getAIKeywordsForTopic,
    getAIKeywordsForBrand,
  } = searchTermActions;

  useEffect(() => {
    setLoadingAIKeywords(true);
    if (brandId) {
      getAIKeywordsForBrand(brandId)
        .then((keywords) => {
          setAiGeneratedKeywords(
            (keywords.data?.getAIKeywordsForBrand || []).filter(
              (k) => !existingKeywords.includes(k),
            ),
          );
        })
        .finally(() => {
          setLoadingAIKeywords(false);
        });
    } else if (topic?.id) {
      getAIKeywordsForTopic(topic.id)
        .then((keywords) => {
          setAiGeneratedKeywords(
            (keywords.data?.getAIKeywordsForTopic || []).filter(
              (k) => !existingKeywords.includes(k),
            ),
          );
        })
        .finally(() => {
          setLoadingAIKeywords(false);
        });
    }
  }, [topic?.id]);

  const handleCreateSearchTermsForTopic = async () => {
    if (!topic?.id) return;
    await handleCreateSearchTerms(
      topic.id,
      [...aiGeneratedKeywords, ...manuallyAddedKeywords],
      allowingPlotToImprove,
    );
    onNext();
  };

  return (
    <Box
      width="60vw"
      maxHeight="65vh"
      display="flex"
      gap={4}
      flexDirection="column"
      color={theme.colors?.primary.parchment}
    >
      <Typography
        variant="headline-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
      >
        Keywords. 🔎
      </Typography>
      <Typography variant="body-xl" fontWeight={500}>
        These are the keywords Plot generated for you. Take a look, you can
        delete any that you’re not interested in and add additional ones.
      </Typography>

      {loadingAIKeywords && (
        <Typography
          variant="body-xl"
          display="flex"
          alignItems="center"
          gap={2}
          my={4}
        >
          <CircularProgress
            size={theme.spacing(3)}
            sx={{
              color: theme.colors?.primary.parchment,
            }}
          />
          Generating AI keywords...
        </Typography>
      )}

      {!!aiGeneratedKeywords.length && (
        <>
          <Typography
            variant="headline-sm"
            display="flex"
            alignItems="center"
            gap={2}
          >
            <IconCustomSparkles size={16} />
            AI Generated
          </Typography>

          <SocialMediaListeningKeywords
            componentProps={{
              container: {
                sx: { my: 0 },
              },
              createKeyword: {
                hideCreateKeyword: true,
              },
            }}
            keywords={aiGeneratedKeywords}
            updateKeywords={setAiGeneratedKeywords}
          />

          <Box
            sx={{ borderTop: `1px solid rgba(250, 243, 236, 0.10)` }}
            my={6}
          />
        </>
      )}

      <Typography variant="headline-sm">Manually added</Typography>
      <SocialMediaListeningKeywords
        componentProps={{
          container: {
            sx: { my: 0 },
          },
        }}
        keywords={manuallyAddedKeywords}
        updateKeywords={setManuallyAddedKeywords}
      />
      <Box sx={{ borderTop: `1px solid rgba(250, 243, 236, 0.10)` }} my={6} />

      <Box display="flex" justifyContent="space-between">
        <Typography variant="body-xl" fontWeight={500}>
          Allow Plot to update this list to improve results
        </Typography>
        <Switch
          checked={allowingPlotToImprove}
          onChange={() => setAllowingPlotToImprove((prev) => !prev)}
          sx={{
            '& .MuiSwitch-thumb': {
              backgroundColor: allowingPlotToImprove
                ? theme.colors?.primary.black
                : theme.colors?.primary.parchment,
            },
            '& .MuiSwitch-track': {
              backgroundColor: `${theme.colors?.utility[500]} !important`,
            },
          }}
        />
      </Box>
      <RenderNextButton onNextClick={handleCreateSearchTermsForTopic} />
    </Box>
  );
};
