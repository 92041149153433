import { Box, BoxProps, Dialog, Input } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Editor } from '@tiptap/react';
import { CustomController } from 'components/common/form/CustomController';
import { useHyperlinkHandlers } from 'components/common/form/RichTextEditor/components/ToolBar/components/HyperlinkPopover/hooks/useHyperlinkHandlers';
import { theme } from 'styles/theme/theme';

interface Props extends React.PropsWithChildren, BoxProps {
  editor: Editor | null;
}

const useStyles = makeStyles(() => ({
  backDrop: {
    background: `rgba(250, 243, 236, 0.40)`,
    backdropFilter: `blur(5px)`,
  },
}));

export const HyperlinkDialog = ({ children, editor, ...rest }: Props) => {
  const classes = useStyles();

  const { control, handleFormSubmit, open } = useHyperlinkHandlers(editor);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          if (editor) {
            const previousUrl = editor?.getAttributes('link').href;
            if (previousUrl) {
              editor?.chain().focus().unsetLink().run();
              return;
            }
            open.onOpen();
          }
        }}
        {...rest}
      >
        {children}
      </Box>
      <Dialog
        open={open.isOpen}
        onClose={open.onClose}
        fullWidth
        maxWidth="xl"
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            padding: theme.spacing(2, 10),
            borderRadius: '200px',
          },
        }}
      >
        <form autoComplete="off" onSubmit={handleFormSubmit}>
          <CustomController
            name="url"
            rules={{ required: 'Required' }}
            control={control}
            sx={{
              '& small': {
                marginTop: 0,
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <Input
                  {...field}
                  error={!!fieldState.error?.message}
                  autoFocus
                  fullWidth
                  disableUnderline
                  placeholder="paste the link"
                  sx={{
                    ...theme.typography['headline-xl'],
                    fontSize: 28,
                    padding: 0,
                    '& ::placeholder': {
                      color: theme.colors?.utility[400],
                      opacity: 1,
                    },
                  }}
                />
              );
            }}
          />
        </form>
      </Dialog>
    </>
  );
};
