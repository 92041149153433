import { Box, SxProps } from '@mui/material';
import { PostPreview } from 'features/post';
import { CollectionFragmentCollectionMultiPostPreviewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { PLACEHOLDER_BACKGROUND_COLORS } from '../constants';

export type CardStackSquareProps = {
  collection: CollectionFragmentCollectionMultiPostPreviewFragment;
  sx?: SxProps;
  componentsProps?: {
    card?: {
      sx: SxProps;
    };
  };
};

export const CardStackSquare = (props: CardStackSquareProps) => {
  const { collection, sx, componentsProps = {} } = props;
  const { posts } = collection;

  const renderPost = (index: number) => {
    const post = posts?.[index];

    if (!post) {
      return (
        <Box
          sx={{ height: '100%', bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index] }}
        />
      );
    }

    return (
      <PostPreview
        key={post.id}
        post={post}
        sx={{ height: '100%', bgcolor: PLACEHOLDER_BACKGROUND_COLORS[index] }}
        variant="compact"
        componentsProps={{
          postNotePreviewCompact: {
            sx: {
              p: 6,
            },
            componentsProps: {
              icon: {
                size: 16,
              },
              title: {
                sx: {
                  fontSize: 16,
                },
              },
            },
          },
        }}
      />
    );
  };

  const defaultCardStyles: SxProps = {
    position: 'absolute',
    width: '100%',
    aspectRatio: '10 / 10',
    borderRadius: 2,
    border: `2px solid ${theme.colors?.primary.white}`,
    overflow: 'hidden',
    boxShadow:
      '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
    ...componentsProps.card?.sx,
  };

  return (
    <Box
      sx={{
        // Set zIndex to 0 to prevent its children from messing with other elements
        zIndex: 0,
        position: 'relative',
        aspectRatio: '1 / 1',
        display: 'flex',
        ...sx,
      }}
    >
      <Box
        sx={{
          ...defaultCardStyles,
          zIndex: 2,
        }}
      >
        {renderPost(0)}
      </Box>
      <Box
        sx={{
          ...defaultCardStyles,
          transform: 'translate(-8%, -13%) rotate(-6deg)',
          zIndex: 1,
        }}
      >
        {renderPost(1)}
      </Box>
      <Box
        sx={{
          ...defaultCardStyles,
          transform: 'translate(-17%, -23%) rotate(-12deg)',
          zIndex: 0,
        }}
      >
        {renderPost(2)}
      </Box>
    </Box>
  );
};
