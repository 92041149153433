/**
 * This component is used to prompt the user to apply changes to the current view
 * when it's being unmounted. Users can:
 * 1. Save the changes to the current view, OR
 * 2. Create a new view with the changes they made.
 */

import { gql, useApolloClient } from '@apollo/client';
import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { useContentCalendarView } from 'features/contentCalendarView';
import {
  ContentCalendarViewPermission,
  useCreateContentCalendarViewForApplyChangesPromptDialogMutation,
  useUpdateContentCalendarViewForApplyChangesPromptDialogMutation,
} from 'graphql/generated';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation CreateContentCalendarViewForApplyChangesPromptDialog(
    $data: CreateContentCalendarViewInput!
  ) {
    createContentCalendarView(data: $data) {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateContentCalendarViewForApplyChangesPromptDialog(
    $data: UpdateContentCalendarViewInput!
  ) {
    updateContentCalendarView(data: $data) {
      id
      filters
    }
  }
`;

export type ApplyChangesPromptDialogProps = {
  open: boolean;
  selectedFilters: Record<string, object>;
  onAfterSave: () => void;
  onClose: () => void;
};

export const ApplyChangesPromptDialog = (
  props: ApplyChangesPromptDialogProps,
) => {
  const { open, selectedFilters, onAfterSave, onClose } = props;

  const location = useLocation();
  const navigate = useNavigate();

  // We'll be using `view` search param to determine if we are using a view.
  const [params] = useSearchParams();
  const contentCalendarViewId = params.get('view') || '';
  const { contentCalendarView } = useContentCalendarView(contentCalendarViewId);
  const canUpdate = contentCalendarView?.myPermissions.includes(
    ContentCalendarViewPermission.Update,
  );

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Controls for updating the current view
   */
  const [updateContentCalendarView] =
    useUpdateContentCalendarViewForApplyChangesPromptDialogMutation();
  const onSaveChanges = async () => {
    setIsLoading(true);

    await updateContentCalendarView({
      variables: {
        data: {
          contentCalendarViewId,
          data: {
            filters: JSON.stringify(selectedFilters),
          },
        },
      },
    });

    setIsLoading(false);
    onAfterSave();
  };

  /**
   * Controls for creating new views
   */
  const [isCreatingNewView, setIsCreatingNewView] = useState(false);
  const [newViewName, setNewViewName] = useState('');

  const canSubmitNewView = newViewName.trim().length > 0 && !isLoading;

  const client = useApolloClient();
  const [createContentCalendarView] =
    useCreateContentCalendarViewForApplyChangesPromptDialogMutation();
  const onCreateNewView = async () => {
    if (!canSubmitNewView) {
      return;
    }

    setIsLoading(true);

    await createContentCalendarView({
      variables: {
        data: {
          data: {
            name: newViewName,
            filters: JSON.stringify(selectedFilters),
          },
        },
      },
      update: (_, result) => {
        const contentCalendarViewId =
          result.data?.createContentCalendarView?.id;
        if (contentCalendarViewId) {
          params.set('view', contentCalendarViewId);
          navigate(
            {
              pathname: location.pathname,
              search: params.toString(),
            },
            {
              state: location.state,
              replace: true,
            },
          );
        }
      },
    });

    // HACK
    // Manually trigger a refetch on this query because it's the ONLY place
    // If there are multiple in the future, we should consider adding a refetchQueries property to this component
    client.refetchQueries({
      include: ['GetContentCalendarViewsForManageViewsSection'],
    });

    setIsLoading(false);
    onAfterSave();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 8,
          display: 'flex',
          flexDirection: 'column',
          width: 360,
        },
      }}
    >
      {isCreatingNewView ? (
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onSubmit={(event) => {
            event.preventDefault();
            onCreateNewView();
          }}
        >
          <Typography variant="headline-xl" fontSize={24} mb={3}>
            Add a Title
          </Typography>
          <Typography
            variant="subhead-xl"
            color={theme.colors?.utility[700]}
            mb={6}
          >
            You can always rename a view.
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Untitled View"
            onChange={(e) => setNewViewName(e.target.value)}
            autoFocus
            sx={{
              mb: 6,
              borderRadius: 2,
              bgcolor: theme.colors?.utility[300],
              fieldset: {
                borderColor: theme.colors?.utility[400],
              },
              input: {
                fontSize: 16,
              },
            }}
          />
          <Button
            variant="primary-alt"
            sx={{
              mb: 3,
            }}
            type="submit"
            disabled={isLoading}
          >
            Save Changes
          </Button>
          <Button variant="text" onClick={() => setIsCreatingNewView(false)}>
            Cancel
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="headline-xl" fontSize={24} mb={3}>
            You made changes to the current view
          </Typography>
          <Typography
            variant="subhead-xl"
            color={theme.colors?.utility[700]}
            mb={6}
          >
            {canUpdate
              ? 'You can either save the changes or create a new view.'
              : 'This view was created by another user. You can create a new view with your changes.'}
          </Typography>
          {canUpdate && (
            <Button
              variant="primary-alt"
              sx={{
                mb: 3,
              }}
              onClick={onSaveChanges}
              disabled={isLoading}
            >
              Save Changes
            </Button>
          )}
          <Button
            variant={canUpdate ? 'secondary' : 'primary-alt'}
            sx={{
              mb: 3,
              ...(canUpdate
                ? {
                    color: theme.colors?.primary.black,
                    borderColor: theme.colors?.primary.black,
                  }
                : {}),
            }}
            onClick={() => setIsCreatingNewView(true)}
            disabled={isLoading}
          >
            Create a new view
          </Button>
          <Button variant="text" onClick={onClose}>
            Continue without saving
          </Button>
        </>
      )}
    </Dialog>
  );
};
