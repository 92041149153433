import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid } from '@mui/material';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import {
  BrandCompetitorContentTypeSelector,
  BrandCompetitorCreatorSelector,
  BrandCompetitorFilterButton,
  BrandCompetitorInfo,
  BrandCompetitorInfoSkeleton,
} from 'features/brandCompetitors';
import { CompetitorCreatorDemographics } from 'features/brandCompetitors/components/competitorCreatorDemographics';
import { CompetitorTopCreators } from 'features/brandCompetitors/components/competitorTopCreators';
import {
  SLPageBrandSelector,
  SocialMediaListeningFilterByDateRangeButton,
} from 'features/socialMediaListening';
import { BrandParsedHours } from 'features/socialMediaListening/components/brandParsedHours';
import {
  CompetitorCreatorSocialPostContentFilter,
  CompetitorFlatAnalyticFiltersInput,
  Platform,
  SocialPostGenderDemographic,
  SocialPostGenerationDemographic,
  SocialPostMentionSource,
  useGetCompetitorForBrandCompetitorDetailQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { BrandCompetitorGraph } from '../../components/brandCompetitorGraph';
import { CompetitorCommentInsights } from '../../components/commentInsights';
import { CompetitorSentiment } from '../../components/competitorSentiment';
import { CompetitorSummary } from '../../components/competitorSummary/CompetitorSummary';
import { CompetitorTopHashtags } from '../../components/competitorTopHashtags';
import { CompetitorTopPosts } from '../../components/competitorTopPosts';
import { BrandCreatorPostsView } from '../brandCreatorPosts/BrandCreatorPostsView';
import { BrandHashtagPostsView } from '../brandHashtagPosts/BrandHashtagPosts';

type Props = {
  currentBrandId: string;
  competitorId: string;
  platform: Platform;
};

export const BrandCompetitorDetailView = ({
  currentBrandId,
  competitorId,
  platform,
}: Props) => {
  const {
    isOpen: isCreatorPostsOpen,
    onOpen: onCreatorPostsOpen,
    onClose: onCreatorPostsClose,
  } = useDisclosure();
  const {
    isOpen: isHashtagPostsOpen,
    onOpen: onHashtagPostsOpen,
    onClose: onHashtagPostsClose,
  } = useDisclosure();

  const navigate = useNavigate();
  const [currentPlatform, setCurrentPlatform] = useState(platform);
  // this is the creator id that is selected from the filter bar
  const [selectedPlatformCreatorId, setSelectedPlatformCreatorId] =
    useState('');
  const [selectedHashtag, setSelectedHashtag] = useState('');
  const [selectedContentType, setSelectedContentType] = useState(
    CompetitorCreatorSocialPostContentFilter.Owned,
  );
  const [selectedSources, setSelectedSources] = useState<
    SocialPostMentionSource[]
  >([]);
  const [selectedGender, setSelectedGender] = useState<
    SocialPostGenderDemographic[]
  >(
    Object.values(SocialPostGenderDemographic).filter(
      (g) => g !== SocialPostGenderDemographic.NotSpecified,
    ),
  );
  const [selectedGeneration, setSelectedGeneration] = useState<
    SocialPostGenerationDemographic[]
  >(
    Object.values(SocialPostGenerationDemographic).filter(
      (g) =>
        ![
          SocialPostGenerationDemographic.NotSpecified,
          SocialPostGenerationDemographic.GenAlpha,
        ].includes(g),
    ),
  );

  // this is the creator id that is selected from the top creators section
  const [selectedCreatorId, setSelectedCreatorId] = useState('');

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(7, 'days').startOf('day').toDate(),
    moment().startOf('day').toDate(),
  ]);

  const [params] = useSearchParams();
  const creatorId = params.get('creatorId');
  const hashtag = params.get('hashtag');

  useEffect(() => {
    if (creatorId) {
      setSelectedCreatorId(creatorId);
      onCreatorPostsOpen();
    }
  }, [creatorId]); // eslint-disable-line

  useEffect(() => {
    if (hashtag) {
      setSelectedHashtag(hashtag);
      onHashtagPostsOpen();
    }
  }, [hashtag]); // eslint-disable-line

  const { data: competitorData, loading: loadingCompetitorData } =
    useGetCompetitorForBrandCompetitorDetailQuery({
      variables: {
        competitorId,
      },
    });

  useEffect(() => {
    setSelectedPlatformCreatorId(
      competitorData?.competitor.trackingBrand?.creators.find(
        (creator) => creator.platform === platform,
      )?.id || '',
    );
  }, [competitorData?.competitor.trackingBrand?.creators, platform]);

  useEffect(() => {
    navigate(
      PlotRoutes.socialListeningCompetitor(
        competitorId,
        currentBrandId,
        currentPlatform,
        {
          creatorId: creatorId || undefined,
          hashtag: selectedHashtag || undefined,
        },
      ),
      {
        replace: true,
      },
    );
  }, [currentPlatform]); // eslint-disable-line

  const platformCreator = useMemo(() => {
    return competitorData?.competitor.trackingBrand?.creators.find(
      (creator) => creator.platform === currentPlatform,
    );
  }, [competitorData?.competitor.trackingBrand?.creators, currentPlatform]);

  const baseCompetitorFilters: CompetitorFlatAnalyticFiltersInput =
    useMemo(() => {
      return {
        competitorId,
        contentFilter: selectedContentType,
        creatorId: selectedPlatformCreatorId,
        dateRange: {
          startDate: dateRange[0],
          endDate: dateRange[1],
        },
        sources: selectedSources,
      };
    }, [
      competitorId,
      selectedContentType,
      selectedPlatformCreatorId,
      dateRange,
      selectedSources,
    ]);

  return (
    <Box mt={16} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={4}
        px={8}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          my={2}
        >
          <SLPageBrandSelector
            generateUrl={(brandId) =>
              PlotRoutes.socialListeningCompetitors(brandId)
            }
          />
        </Box>
        <Box marginLeft="auto" display="flex" alignItems="center" columnGap={2}>
          <BrandParsedHours />
          {currentBrandId && (
            <Box
              borderRadius={10}
              bgcolor={theme.colors?.utility[275]}
              padding={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: 'center',
              }}
              component="button"
              onClick={() => {
                navigate(
                  PlotRoutes.socialListeningSettings({
                    brandId: currentBrandId,
                  }),
                );
              }}
            >
              <IconBoldSettings2 size={22} color={theme.colors?.utility[600]} />
            </Box>
          )}
        </Box>
      </Box>

      <Box px={8}>
        {loadingCompetitorData || !competitorData ? (
          <BrandCompetitorInfoSkeleton />
        ) : (
          <BrandCompetitorInfo
            competitor={competitorData.competitor}
            currentPlatform={currentPlatform}
          />
        )}
      </Box>

      <Box
        sx={{
          borderBottom: `1px solid ${theme.colors?.utility[300]}`,
          borderTop: `1px solid ${theme.colors?.utility[300]}`,
          position: 'sticky',
          top: 64,
          zIndex: 10,
          backgroundColor: theme.colors?.utility[200],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          p: theme.spacing(4, 8),
          my: 6,
        }}
      >
        <BrandCompetitorContentTypeSelector
          creatorProfilePictureUrl={platformCreator?.profilePictureUrl ?? ''}
          selectedContentType={selectedContentType}
          onChange={setSelectedContentType}
        />
        <Box display="flex" alignItems="center" gap={4}>
          {competitorData?.competitor && (
            <BrandCompetitorCreatorSelector
              competitor={competitorData?.competitor}
              selectedCreatorId={selectedPlatformCreatorId}
              onChange={(creatorId, platform) => {
                setSelectedPlatformCreatorId(creatorId);
                setCurrentPlatform(platform);
              }}
            />
          )}
          <SocialMediaListeningFilterByDateRangeButton
            onChange={(range) => {
              if (range[0] && range[1]) {
                setDateRange(range as [Date, Date]);
              }
            }}
            selectedDateRange={dateRange}
            componentProps={{
              startIcon: (
                <Box
                  sx={{
                    height: 24,
                    width: 24,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                  }}
                >
                  <IconOutlineCalendar1 size={16} />
                </Box>
              ),
            }}
          />
          {selectedContentType ===
            CompetitorCreatorSocialPostContentFilter.Ugc && (
            <BrandCompetitorFilterButton
              onUpdateFilters={(filters) => {
                if (filters.sources) {
                  setSelectedSources(filters.sources);
                }
                if (filters.gender) {
                  setSelectedGender(filters.gender);
                }
                if (filters.generation) {
                  setSelectedGeneration(filters.generation);
                }
              }}
              selectedFilters={{
                sources: selectedSources,
                gender: selectedGender,
                generation: selectedGeneration,
              }}
            />
          )}
        </Box>
      </Box>

      <Box px={8} display="flex" flexDirection="column" gap={6}>
        <Grid container spacing={6} columns={12}>
          <Grid item xs={12} lg={4}>
            <Box display="flex" flexDirection="column" gap={6} width="100%">
              <CompetitorSummary
                filters={{
                  brandId: currentBrandId,
                  ...baseCompetitorFilters,
                }}
              />
              <CompetitorTopHashtags
                filters={baseCompetitorFilters}
                currentPlatform={currentPlatform}
                onHashtagClick={(hashtag) => {
                  setSelectedHashtag(hashtag);
                  onHashtagPostsOpen();
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <CompetitorTopPosts
              brandId={currentBrandId}
              filters={baseCompetitorFilters}
            />
          </Grid>
        </Grid>

        {selectedContentType ===
          CompetitorCreatorSocialPostContentFilter.Ugc && (
          <Grid container rowSpacing={8} columns={12}>
            <Grid item xs={12}>
              <CompetitorTopCreators
                filters={baseCompetitorFilters}
                brandId={currentBrandId}
                onCreatorIdSelect={(creatorId) => {
                  setSelectedCreatorId(creatorId);
                  onCreatorPostsOpen();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CompetitorCreatorDemographics
                filters={{
                  ...baseCompetitorFilters,
                  gender: selectedGender,
                  generation: selectedGeneration,
                }}
                onChange={(data) => {
                  setSelectedGender(data.selectedGender);
                  setSelectedGeneration(data.selectedGeneration);
                }}
              />
            </Grid>
          </Grid>
        )}

        <BrandCompetitorGraph filters={baseCompetitorFilters} />

        <CompetitorSentiment
          currentBrandId={currentBrandId}
          trackingBrandId={competitorData?.competitor.trackingBrand?.id || ''}
          currentPlatform={currentPlatform}
          filters={baseCompetitorFilters}
          hideInPosts={
            selectedContentType ===
            CompetitorCreatorSocialPostContentFilter.Owned
          }
        />

        <CompetitorCommentInsights
          currentBrandId={currentBrandId}
          trackingBrandId={competitorData?.competitor.trackingBrand?.id || ''}
          platform={currentPlatform}
          filters={baseCompetitorFilters}
        />
        {competitorData?.competitor.trackingBrand &&
          selectedCreatorId &&
          isCreatorPostsOpen && (
            <BrandCreatorPostsView
              competitorId={competitorId}
              competitorBrand={competitorData?.competitor.trackingBrand}
              creatorId={selectedCreatorId}
              isOpen={isCreatorPostsOpen}
              onClose={onCreatorPostsClose}
              defaultDateRange={{
                startDate: dateRange[0],
                endDate: dateRange[1],
              }}
            />
          )}
        {competitorData?.competitor.trackingBrand && isHashtagPostsOpen && (
          <BrandHashtagPostsView
            competitorBrand={competitorData?.competitor.trackingBrand}
            hashtag={selectedHashtag}
            platform={currentPlatform}
            isOpen={isHashtagPostsOpen}
            onClose={() => {
              onHashtagPostsClose();
            }}
            baseFilters={baseCompetitorFilters}
          />
        )}
      </Box>
    </Box>
  );
};
