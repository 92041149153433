import { Box, SxProps, Typography } from '@mui/material';
import { IconCustomFiSrSad } from 'components/icons/components/custom/IconCustomFiSrSad';
import { IconOutlineDocument1 } from 'components/icons/components/outline/IconOutlineDocument1';
import { UPLOAD_CARE_CDN_URL } from 'constants/uploadCare';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { getOriginFromUrl } from 'utils/link';

export type NoPreviewUIProps = {
  url: string;
  sx?: SxProps;
};

export const NoPreviewUI = (props: NoPreviewUIProps) => {
  const { url, sx } = props;

  const { isUploadcareFile, fileName, fileExtension } = useMemo(() => {
    let isUploadcareFile = false;
    let fileName = '';
    let fileExtension = '';

    if (url?.startsWith(UPLOAD_CARE_CDN_URL)) {
      isUploadcareFile = true;
      fileName = url.split('/').pop() || '';
      fileExtension = fileName.split('.').pop() || '';
    }

    return {
      isUploadcareFile,
      fileName,
      fileExtension,
    };
  }, [url]);

  return (
    <Box
      sx={{
        m: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minHeight: 200,
        gap: 3,
        backgroundColor: theme.colors?.secondary['dark-cream'],
        ...sx,
      }}
      onClick={() => window.open(url, '_blank')}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 50,
          color: theme.colors?.primary.black,
          bgcolor: 'rgba(35, 6, 3, 0.15)',
          gap: 1,
          px: 3,
          py: 1,
        }}
      >
        {isUploadcareFile && <IconOutlineDocument1 size={12} />}
        <Typography variant="subhead-sm" textAlign="center">
          {isUploadcareFile
            ? fileExtension.toUpperCase()
            : getOriginFromUrl(url)}
        </Typography>
      </Box>
      <Typography
        className="subtitle"
        variant="subhead-lg"
        textAlign="center"
        color={theme.colors?.utility[800]}
        sx={{
          wordBreak: 'break-word',
        }}
      >
        {isUploadcareFile ? (
          fileName
        ) : (
          <>
            We can't show a preview <IconCustomFiSrSad size={10} />
          </>
        )}
      </Typography>
    </Box>
  );
};
