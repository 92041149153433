import { Box, Button, Card, Container, Typography } from '@mui/material';
import { useRequestBrandPermission } from 'features/socialMediaListening/views/permissionRequest';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const RequestBrandAccessPage: React.FC = () => {
  const { brandId } = useParams<{ brandId: string }>();
  const [searchParams] = useSearchParams();
  const brandName = searchParams.get('brandName');
  const brandAvatar = searchParams.get('brandAvatar');
  const { onRequestAccess } = useRequestBrandPermission();

  const navigate = useNavigate();

  useEffect(() => {
    if (brandId && brandName) {
      onRequestAccess(brandId, brandName);
    }
  }, [brandId, brandName]); // eslint-disable-line

  return (
    <Box
      sx={{
        backgroundColor: theme.colors?.primary.parchment,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 3,
      }}
    >
      <Box
        width={theme.spacing(160)}
        mx="auto"
        mb={4}
        px={4}
        display="flex"
        justifyContent="flex-start"
      >
        <Box
          component="img"
          color={theme.colors?.primary.black}
          sx={{
            width: theme.spacing(30),
          }}
          src="/images/plot-logo-with-name.png"
        />
      </Box>
      <Container
        sx={{
          width: theme.spacing(160),
        }}
      >
        <Card
          sx={{
            backgroundColor: theme.colors?.primary.white,
            borderRadius: 6,
            padding: 8,
            gap: 2,
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="headline-md" fontWeight={600}>
            {brandName ? `${brandName}'s Social Listening` : 'Social Listening'}
          </Typography>
          <Typography
            variant="headline-lg"
            fontSize={theme.spacing(12)}
            fontWeight={400}
          >
            Log in to view this page
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              navigate(PlotRoutes.auth());
            }}
            sx={{
              mt: 2,
              p: 2,
              backgroundColor: theme.colors?.primary.black,
              color: theme.colors?.primary.white,
              borderRadius: theme.spacing(2),
              border: 'none',
              '&:hover': {
                backgroundColor: theme.colors?.primary.black,
                color: theme.colors?.primary.white,
              },
            }}
          >
            <Typography variant="headline-md">
              Log in
            </Typography>
          </Button>
        </Card>
      </Container>
    </Box>
  );
};
