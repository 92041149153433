import { Box, BoxProps, Typography } from '@mui/material';
import {
  TopicMoreContextMenu,
  TopicPauseContextMenu,
} from 'features/topic/components';
import {
  TopicFragmentTopicBreakdownPopoverViewFragment,
  TopicStatus,
} from 'graphql/generated';
import moment from 'moment';
import { theme } from 'styles/theme';

export type ListItemProps = Pick<
  BoxProps,
  'sx' | 'onMouseOver' | 'onMouseOut'
> & {
  topic: TopicFragmentTopicBreakdownPopoverViewFragment;
  durationUsedInHour: number;
};

export const ListItem = (props: ListItemProps) => {
  const { topic, durationUsedInHour, sx, ...rest } = props;

  const isTopicPaused = topic.status === TopicStatus.Paused;
  const isTopicDeleted = topic.status === TopicStatus.Deleted;

  const canShowActions = !isTopicDeleted;

  return (
    <Box
      {...rest}
      sx={[
        {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          py: 2,
          px: 3,
          borderRadius: 3,
          overflow: 'hidden',
          '::before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: theme.colors?.utility[300],
            opacity: 0,
          },
          '&:hover': {
            '::before': {
              opacity: 0.2,
            },
            '.actions': {
              opacity: 1,
            },
          },
        },
        // @ts-ignore
        sx || {},
      ]}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          position: 'relative',
        }}
      >
        <Typography
          variant="subhead-xl"
          {...(isTopicPaused || isTopicDeleted
            ? {
                color: theme.colors?.utility[700],
              }
            : {})}
        >
          {topic.name}
          {isTopicPaused &&
            ` • Paused ${
              topic.resetOnNextCycle
                ? `till ${moment()
                    .add(1, 'month')
                    .startOf('month')
                    .format('MM-DD-YYYY')}`
                : 'manually'
            }`}
          {isTopicDeleted && ' • Deleted'}
        </Typography>
        <Typography variant="subhead-lg" color={theme.colors?.utility[800]}>
          {durationUsedInHour.toFixed(1)} hour
          {durationUsedInHour > 1 ? 's' : ''} parsed
        </Typography>
      </Box>
      {canShowActions && (
        <Box
          className="actions"
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: 0,
          }}
        >
          <TopicPauseContextMenu topic={topic} disablePortal />
          <TopicMoreContextMenu topic={topic} disablePortal />
        </Box>
      )}
    </Box>
  );
};
