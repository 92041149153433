import { BillingPlanName, useGetBillingPlanQuery } from 'graphql/generated';
import { gql } from '@apollo/client';

gql`
  query GetBillingPlan($plan: String!) {
    billingPlan(plan: $plan) {
      name
      numberOfTrialDays
      organizationMemberLimit
    }
  }
`;

export const useBillingPlanData = (plan: BillingPlanName) => {
  const planData = useGetBillingPlanQuery({
    variables: {
      plan,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    numberOfTrialDays: planData.data?.billingPlan.numberOfTrialDays ?? 7,
    organizationMemberLimit: planData.data?.billingPlan.organizationMemberLimit,
  };
};

export const useAllBillingPlans = () => {
  const freePlan = useGetBillingPlanQuery({
    variables: {
      plan: BillingPlanName.Free,
    },
    fetchPolicy: 'cache-first',
  });

  const basicPlan = useGetBillingPlanQuery({
    variables: {
      plan: BillingPlanName.Basic,
    },
    fetchPolicy: 'cache-first',
  });

  const businessPlan = useGetBillingPlanQuery({
    variables: {
      plan: BillingPlanName.Standard,
    },
    fetchPolicy: 'cache-first',
  });

  const enterprisePlan = useGetBillingPlanQuery({
    variables: {
      plan: BillingPlanName.Enterprise,
    },
    fetchPolicy: 'cache-first',
  });

  return {
    [BillingPlanName.Free]: freePlan.data?.billingPlan,
    [BillingPlanName.Basic]: basicPlan.data?.billingPlan,
    [BillingPlanName.Standard]: businessPlan.data?.billingPlan,
    [BillingPlanName.Enterprise]: enterprisePlan.data?.billingPlan,
  };
};
